import { useState } from "react";
import SideNavOne from "../../../../SideNavBar";
import ServiceCost from "./ServiceCost";
import uuid from "react-uuid";
import { API_ENDPOINTS } from "../../../schedulerModule/configs/config";
import useFetch from "../../../../hooks/useFetch";

const ServiceCostContainer = () => {
  const [show, setShow] = useState(false);
  const [costType, setCostType] = useState("");

  const costTypeId = uuid();
  const unitTypeId = uuid();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCostTypeChange = (e) => setCostType(e.target.value);

  const { data, isLoading } = useFetch(API_ENDPOINTS.GET_ALL_SERVICES_COST);
  const { costs: costList } = data;

  return (
    <>
      <SideNavOne>
        <ServiceCost
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          costList={costList}
          isLoading={isLoading}
          costTypeId={costTypeId}
          unitTypeId={unitTypeId}
          costType={costType}
          handleCostTypeChange={handleCostTypeChange}
        />
      </SideNavOne>
    </>
  );
};
export default ServiceCostContainer;
