import { Card } from "react-bootstrap";
import camera from "../../Assets/svgs/camera.svg";
import serviceImg from "../../Assets/svgs/service-img.svg";

const ServiceCard = () => {
  return (
    <Card className="custom-border">
      <Card.Body className="p-1 d-flex align-items-center">
        <div className="service-card">
          <img className="h-100 w-100 d-block" src={serviceImg} alt="" />
          <img className="camera" src={camera} alt="upload" />
        </div>
        &nbsp;&nbsp;&nbsp;
        <div>
          <h1 className="head mb-1">Laser Hair Removal</h1>
          <p className="text-light-grey card-detail mb-0">ID 890383</p>
          <p className="text-secondary card-detail mb-0">df2354fsd</p>
          <p className="text-secondary card-detail mb-0">Business Centre</p>
        </div>
      </Card.Body>
    </Card>
  );
};
export default ServiceCard;
