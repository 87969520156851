import React from "react";
import SideNavone from "../../SideNavBar/index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateusername } from "../../Store/Auth/actions";
import { useEffect } from "react";
import Header from "../BusinessProfile/Header";
import InputTextField from "../BusinessProfile/TextField";
import { Button } from "@mui/material";
import { ClipLoader } from "react-spinners";

function Editusername() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const alldata = useSelector((state) => state.Auth?.alluserdataafterlogin);

  const dispatch = useDispatch();
  const flag = true;
  const handleFirstNameChange = (event) => {
    const value = event.target.value;
    setFirstName(value);
    setFirstNameError(validateName(value));
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;
    setLastName(value);
    setLastNameError(validateName(value));
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }

    if (firstName === "") {
      setFirstNameError("First Name is required.");
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Last Name is required.");
    } else {
      setLastNameError("");
    }

    return ""; // No validation error
  };

  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const updatefirstandlastname = () => {
    if (!validateName()) {
      // setLoading(true)
      dispatch(
        updateusername({
          id: registersuccessId,
          firstName: firstName,
          lastName: lastName,
          modifiedBy: registersuccessId,
          setLoading,
        })
      );
      // setLoading(false)

    }
  };
  useEffect(() => {
    setFirstName(alldata.firstName);
    setLastName(alldata.lastName);
  }, [alldata]);

  return (
    <div>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-md-4">
          <div>
            <p className=" ps-3 sub_text_color_account_alltext_show_actual">
              Edit User Name
            </p>

            <div className="ps-2 pe-2 w-100">
              <InputTextField
                id="firstName"
                label="First Name"
                name="firstName"
                placeholder="Enter First Name"
                //size={20}
                variant="filled"
                value={firstName}
                onChange={handleFirstNameChange}
                autoFocused
                sx={{
                  // maxWidth: "300px",
                  backgroundColor: "#fff",
                }}
              />
              {firstNameError && (
                <h6
                  style={{ color: "red", fontSize: "80%" }}
                  className="error-message pt-2"
                >
                  {firstNameError}
                </h6>
              )}
            </div>
            <div className="p-2 w-100 ">
              <InputTextField
                id="lastName"
                label="Last Name"
                name="lastName"
                placeholder="Enter Last Name"
                //size={20}
                variant="filled"
                value={lastName}
                onChange={handleLastNameChange}
                autoFocused
                sx={{
                  // maxWidth: "300px",
                  backgroundColor: "#fff",
                }}
              />
              {lastNameError && (
                <h6
                  style={{ color: "red", fontSize: "80%" }}
                  className="error-message pt-2"
                >
                  {lastNameError}
                </h6>
              )}
            </div>
          </div>
          <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
            <div className="p-2">
              <Button
                type="submit"
                sx={{
                  width: "150px",
                  backgroundColor: "#538dff",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                onClick={updatefirstandlastname}
                disabled={firstNameError || lastNameError}
                variant="contained"
              >
                {loading ? <ClipLoader size={14} /> : "Save & Continue"}
              </Button>
            </div>
          </div>
        </div>
      </SideNavone>
    </div>
  );
}

export default Editusername;
