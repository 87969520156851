// export function convertTo24HourFormat(time12Hour) {
//   console.log(time12Hour,"time12Hourtime12Hour")
//   const splittedTime = time12Hour.split(":");
//   console.log(splittedTime,"time12Hourtime12Hour")

//   var result;
//   const hour = parseInt(splittedTime[0]);
//   const period = splittedTime[2].toLowerCase();

//   if (period === "pm" && hour < 12) {
//     result = 12 + parseInt(splittedTime[0]);
//   } else if (period === "am" && hour === 12) {
//     // If it's 12:xx AM, convert to 00:xx in 24-hour format
//     result = 0;
//   } else {
//     result = hour;
//   }
//   return `${result.toString().padStart(2, "0")}:${splittedTime[1]}:00`;
// }
export function convertTo24HourFormat(time12hr) {
  const [time, modifier] = time12hr.split(' ');

  let [hours, minutes] = time.split(':');

  if (modifier === 'am' && hours === '12') {
    hours = '00';
  } else if (modifier === 'pm' && hours !== '12') {
    hours = String(parseInt(hours, 10) + 12);
  }

  return `${hours}:${minutes}:00`;
}

export function convertTo12HourFormat(time24Hour) {
  const splittedTime = time24Hour.split(":");
  const hour = parseInt(splittedTime[0]);
  const minutes = splittedTime[1];

  let period = "am";

  if (hour >= 12) {
    period = "pm";
  }

  const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

  return `${formattedHour.toString().padStart(2, "0")}:${minutes} ${period}`;
}
