import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GroupForm from './GroupForm';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGroupDetails } from '../../../store/servicesActions';
import { Pagination, Stack } from '@mui/material';

const GroupList = ({ fetchUniqueGroup }) => {

    const [columns, setColumns] = useState([
        { id: 1, name: 'Group Name', width: '200px' },
        { id: 2, name: 'Type', width: '300px' },
        { id: 3, name: 'SKU', width: '300px' },
    ]);

    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
    const [enlistGroupService, setEnlistGroupService] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const { id } = useParams();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };

    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };

    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
        setDraggedColumnIndex(columnIndex)
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
        setDraggedColumnIndex(null)
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const addGroupFunc = () => {
        setEnlistGroupService(!enlistGroupService);
    }

    // Calculate the index range of data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRows = rows.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (store?.allGroups) {
            setRows(() => store?.allGroups?.length > 0 && store?.allGroups.map((v, i) => {
                return (
                    {
                        id: v?.groupId,
                        data: [{ id: 1, groupName: v?.groupName }, { id: 2, groupType: v?.groupType }, { id: 3, groupSKU: v?.groupSKU }, { groupLocation: v?.groupLocation }]
                    })
            }))
        }
    }, [store?.allGroups]);

    return (
        <>
            {!enlistGroupService ?
                <>
                    <div className='package_list_container mt-3' style={{ maxHeight: '50vh', height: 'auto', overflow: 'scroll', width: '97%' }}>
                        <div className="p-0 position-relative" style={{ border: '1px solid lightgray', borderRadius: '4px', backgroundColor: 'white', overflow: '' }} >
                            <div className='d-flex justify-content-between px-2 py-1'>
                                <h6 className='text-dark ms-3 pt-2'>Group 1</h6>
                                <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light' onClick={addGroupFunc}>Add</button>
                            </div>
                            <table className="table group_form_container w-100 mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                        </th>
                                        {columns.map((column, index) => {
                                            if (index < 1) {
                                                return (
                                                    <th
                                                        id={`sticky_col${column?.id}`}
                                                        key={column.id}
                                                    >{column.name}</th>
                                                );
                                            }

                                            return (
                                                <th
                                                    // key={column.id}
                                                    draggable
                                                    onDragStart={(e) => handleColumnDragStart(e, index)}
                                                    onDragOver={handleColumnDragOver}
                                                    onDrop={(e) => handleColumnDrop(e, index)}

                                                    onMouseOver={() => setColumnId(column.id)}
                                                    onMouseOut={() => setColumnId('')}
                                                    className={isDraggingRow ? 'dragging-row' : ''}

                                                    style={{
                                                        width:
                                                            column?.id == 1 && "100px" ||
                                                            column?.id == 2 && "300p"
                                                    }}
                                                >
                                                    <div className='d-flex justify-content-between'>
                                                        {column.name}

                                                        <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', positition: 'relative' }}>
                                                            <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                                            </button>
                                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                                <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </th>
                                            );
                                        })}
                                        {/* <th style={{ width: '70px' }}>
                                            Action
                                        </th> */}
                                        <th className='addcol_btn_th'
                                            style={{ width: '73px' }}
                                        >
                                            <div className="dropdown">
                                                <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                                </button>
                                                <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                                    {dropdown_columns && dropdown_columns.map((val, ind) => {
                                                        return (
                                                            <div key={val?.id}>
                                                                <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => (
                                        <>
                                            <tr
                                                key={row?.id}
                                                draggable
                                                onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                                onDragOver={handleRowDragOver}
                                                onDrop={(e) => handleRowDrop(e, rowIndex)}
                                                handleDragEnd={handleDragEnd}
                                                className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                                            >
                                                <td>
                                                    <div className="form-check ps-4 text-center">
                                                        <input type="checkbox" value="" id="flexCheckDefault"
                                                            className="form-check-input checkbox_btn"
                                                            checked={selectedRows.includes(row.id)}
                                                            onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        />
                                                    </div>
                                                </td>

                                                {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                                    return (
                                                        <>
                                                            {cellValue.id === 1 &&
                                                                <td key={columnIndex}
                                                                    id={`sticky_col${columnIndex}`}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                                    onClick={() => {
                                                                        fetchUniqueGroup(row?.id)
                                                                    }}
                                                                >
                                                                    {cellValue?.groupName}
                                                                </td>
                                                            }

                                                            {cellValue.id === 2 &&
                                                                <td key={columnIndex}
                                                                    style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                                >
                                                                    {cellValue?.groupType}
                                                                </td>
                                                            }

                                                            {cellValue.id === 3 &&
                                                                <td key={columnIndex}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                                >
                                                                    {cellValue?.groupSKU}
                                                                </td>
                                                            }

                                                            {cellValue.id === 7 &&
                                                                <td key={columnIndex}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                                >
                                                                    <input type='text' placeholder='' value={''} />
                                                                </td>
                                                            }

                                                            {cellValue.id === 8 &&
                                                                <td key={columnIndex}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                                >
                                                                    <select>
                                                                        <option value="" className='w-100'>Select</option>
                                                                        <option value="Option 1">option A</option>
                                                                        <option value="Option 2">option B</option>
                                                                        <option value="Option 2">option C</option>
                                                                    </select>
                                                                </td>
                                                            }

                                                            {cellValue.id === 9 &&
                                                                <td key={columnIndex}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                                >
                                                                    <input type='number' placeholder='' value={''} />
                                                                </td>
                                                            }

                                                            {cellValue.id === 10 &&
                                                                <td key={columnIndex}
                                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                                >
                                                                    <input type='file' placeholder='' value={''} />
                                                                </td>
                                                            }

                                                        </>
                                                    )
                                                })}
                                                <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='row pc_display mt-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <select
                                className='items_per_page_select'
                                onChange={(e) => setItemsPerPage(e.target.value)}
                            >
                                <option disabled selected>Items Per page</option>
                                <option value={5}>Default</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={rows?.length}>All</option>
                            </select>

                            <Stack spacing={2} className=''>
                                <Pagination
                                    count={Math.ceil(rows.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                />
                            </Stack>
                        </div>
                    </div>
                </> :
                <GroupForm addGroupFunc={addGroupFunc} />
            }
        </>
    );
};

export default GroupList;
