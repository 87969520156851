// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reusable_alignReusableBtn__ey959 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Reusable_reusableBtn__4J76W {
  width: 100%;
  height: 50px;
  /* background: ; */
  border-radius: 15px;
  filter: drop-shadow(0px 9px 25px rgba(117, 180, 255, 0.1));
  /* Text */
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/MobileHomePage/Reusable/Reusable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,0DAA0D;EAC1D,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".alignReusableBtn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.reusableBtn {\n  width: 100%;\n  height: 50px;\n  /* background: ; */\n  border-radius: 15px;\n  filter: drop-shadow(0px 9px 25px rgba(117, 180, 255, 0.1));\n  /* Text */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignReusableBtn": `Reusable_alignReusableBtn__ey959`,
	"reusableBtn": `Reusable_reusableBtn__4J76W`
};
export default ___CSS_LOADER_EXPORT___;
