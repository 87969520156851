import * as Yup from "yup";

const teamMange = [
  {
    id: "permissionId",
    label: " Permission ID",
    sort: true,
    minWidth: 160,
    color: "#7b7d84",
    border: "1px solid #ededed",
    borderTop: "4px soild #548dff",
    align: "center",
    // class: "px-1 text-left  upper-case table-header-item font-size-xs",
  },
  {
    id: "permission",
    label: "Permission",
    // class: "text-left  upper-case table-header-item font-size-xs",
    minWidth: 170,
    align: "center",
    color: "#7b7d84",
    border: "1px solid #ededed",
  },
  {
    id: "description",
    label: "Description",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    border: "1px solid #ededed",

    minWidth: 500,
  },
  {
    id: "actions",
    label: "Actions",
    // class: "text-center  upper-case table-header-item font-size-xs",
    border: "1px solid #ededed",
    color: "#7b7d84",
    align: "center",
    minWidth: 170,
  },
];

export const OnSearch = (searchedVal, data, setRowData) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      if (!match) {
        match = row.name
          ? row.name.toLowerCase().includes(searchedVal.toLowerCase())
          : "";
      }
      // if (!match) {
      //   match = row.id
      //     ? row.id.toLowerCase().includes(searchedVal.toLowerCase())
      //     : "";
      // }

      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData(data);
  }
};
export const teamInitialValue = (action, editData) => {
  const initialValues = {
    name: action === "edit" ? editData.name : "",
    description: action === "edit" ? editData.teamDescription : "",
  };
  return initialValues;
};
export const validationSchema = Yup.object({
  name: Yup.string().required("Enter the Team Name"),
  description: Yup.string().required("Team is required"),
});
export const formateData = (values) => {
  return {
    name: values.name,
    description: values.description, 
  };
};

export { teamMange };
