import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// Front
// import Layout from './layout/reducer';

// Authentication Module
import Auth from "./Auth/reducer";
import Adviser from "./Adviser/reducer";
import Business from "./Business/reducers";
import Department from "./Department/reducers";
import Team from "./Team/reducers";
import User from "./User/reducers";
import { calendarReducer } from "../Calendar/scheduler/store/calendarSlice";
import { counterReducer } from "../Calendar/scheduler/store/dateSlice";
import { modelReducer } from "../Calendar/scheduler/store//modelSlice";
import { alertReducer } from "../Calendar/scheduler/store/alertSlice";
import { bookingReducer } from "../Calendar/scheduler/store/bookingSlice";
import { servicesReducer } from "../Pages/InventoryServices/store/ServicesSlice";
import { staffReducer } from "../Pages/Dashboard/MainComponent/store/StaffSlice";
import { documentReducer } from "./document/slice";
import { meetspaceReducer } from "./MeetSpace/MeetSpaceSlice";
import { LOGOUT } from "./Auth/actionTypes";
import { removeAllCookies, removeAllLocalStorage } from "../utils/helper";
import { StaffApi } from "../Feature/api/StaffApi";
import Staff from "./Staff/reducers";
import { roleReducer } from "../Pages/BusinessProfile/User/store/reducer";
import { calendarSettingsReducer } from "./CalendarSettings/slice";
import { dynamicFormSlice } from "./dynamicForm/slice";
import { staffDynamicFormSlice } from "./dynamicForm/staffSlice";
import dynamicFormReducer from "./DynamicForms/redux/reducers/dynamicForms.reducers";
import { serviceDynamicFormSlice } from "./dynamicForm/serviceSlice";

// import persistReducer from 'redux-persist/es/persistReducer';
// import AuthReducer

// const authPersistConfig = {
//     key: 'auth',
//     storage: storage,
//     keyPrefix: 'redux-',
//     whitelist: ['auth']
//   };

const resettable = (reducer) => (state, action) => {
  if (action.type === LOGOUT) {
    removeAllLocalStorage();
    removeAllCookies();
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    Auth: resettable(Auth),
    Adviser: resettable(Adviser),
    Business: resettable(Business),
    Department: resettable(Department),
    [StaffApi.reducerPath]: StaffApi.reducer,
    Team: resettable(Team),
    User: resettable(User),
    calendarReducer: resettable(calendarReducer),
    counterReducer: resettable(counterReducer),
    modelReducer: resettable(modelReducer),
    alertReducer: resettable(alertReducer),
    bookingReducer: resettable(bookingReducer),
    servicesReducer: resettable(servicesReducer),
    staffReducer: resettable(staffReducer),
    staffReducer: resettable(staffReducer),
    documentReducer: resettable(documentReducer),
    calendarSettingReducer: resettable(calendarSettingsReducer),
    meetspaceReducer: resettable(meetspaceReducer),
    StaffReducer: resettable(Staff),
    roleReducer: resettable(roleReducer),
    DynamicForm: resettable(dynamicFormSlice),
    StaffDynamicForm: resettable(staffDynamicFormSlice),
    ServiceDynamicForm: resettable(serviceDynamicFormSlice),
    DynamicFormReducer: resettable(dynamicFormReducer),
  });

export default rootReducer;
