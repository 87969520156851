import { Col, Form, Row } from "react-bootstrap";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";
import { FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishStatusServices from "../../../../Componenets/publishStatusServices/PublishStatusServices";

const ServiceDetail = () => {
  return (
    <Row className="dynamic_formContainer service-add-detail px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head my-3">Service Details</h6>
          <Row>
            <Col className="pr-6px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">
                  Service Name
                </span>
                <input
                  className="input custom-border"
                  placeholder="Enter Service Name"
                  type="text"
                />
              </div>
            </Col>
            <Col className="pl-6px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">Service SKU</span>
                <input
                  className="input custom-border"
                  placeholder="Enter Service SKU"
                  type="text"
                />
              </div>
            </Col>
            <Col md={12} className="mt-12px">
              <div className="input-group">
                <span className="input-label text-light-grey">
                  Short Description
                </span>
                <input
                  className="input custom-border"
                  placeholder="Enter Short Description"
                  type="text"
                />
              </div>
            </Col>
            <h6 className="head select-service-type mt-4 mb-3">
              Select service type for your service
            </h6>
            <div className="mb-2">
              <Form.Check
                className="form-check"
                type="checkbox"
                id="checkbox"
                label="Business Centre"
              />
            </div>

            <Col className="pr-6px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">SKU</span>
                <input
                  className="input custom-border"
                  placeholder="Enter SKU"
                  type="text"
                />
              </div>
            </Col>
            <Col className="pl-6px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">Duration</span>
                <input
                  className="input custom-border"
                  placeholder="Enter Duration"
                  type="text"
                />
              </div>
            </Col>
            <Col className="pr-6px mt-12px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">Currency</span>
                <input
                  className="input custom-border"
                  placeholder="Enter Currency"
                  type="text"
                />
              </div>
            </Col>
            <Col className="pl-6px mt-12px price-input-wrapper" md={6}>
              <div className="input-group position-relative">
                <span className="input-label text-light-grey">Price</span>
                <input
                  className="input custom-border"
                  placeholder="Enter Price"
                  type="text"
                />
                <FormControl fullWidth>
                  <Select
                    className="right-select-input group-input"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{
                          fontSize: "18px",
                          color: "#aaaaaa",
                          marginLeft: "-45px",
                        }}
                      />
                    )}
                    value={10}
                  >
                    <MenuItem value={10}>Per Hour</MenuItem>
                    <MenuItem value={20}>Per Day</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Col>

            <div className="mt-4 mb-3 d-flex align-items-center">
              <Form.Check
                className="form-check"
                type="checkbox"
                id="checkbox1"
                label="Infield"
              />
            </div>
            <div className="d-flex align-items-center">
              <Form.Check
                className="form-check"
                type="checkbox"
                id="checkbox2"
                label="Online"
              />
            </div>
            <div className="d-flex justify-content-end gap-3 mt-5">
              <button className="cancel-btn">Cancel</button>
              <button className="save-btn">Save</button>
            </div>
          </Row>
        </div>
      </Col>
      <PublishStatusServices />
    </Row>
  );
};
export default ServiceDetail;
