import React, { useEffect, useState } from "react";
import SideNavone from "../../../../SideNavBar";
// import "./AddBusiness.scss";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { validateForm } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "../../Uploader";
import { validationSchema } from "../../Business/helper";
import OwnerDetail from "../OwnerDetail/OwnerDetail";
import { Button, IconButton } from "@mui/material";
import {
  fetchCountryRequest,
  fetchIndustryRequest,
  fetchTypeRequest,
} from "../../../../Store/Business/actions";
import { fetchBusinessTypeBinder } from "../../../../Store/Business/saga";
import { ClipLoader } from "react-spinners";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import checkPermission, { customSnackBar } from "../../../../utils";

const AddBusinessInfo = (props) => {
  const {
    values,
    errors,
    onSubmit,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    action,
    loading,
    loader,
    isMobile,
  } = props;
  const history = useHistory();
  const location=useLocation()
  const dispatch = useDispatch();
  var countryList = useSelector((state) => state?.Business?.countryList);
  var typeList = useSelector((state) => state?.Business?.businessTypeList);
  var industryList = useSelector((state) => state?.Business?.industryList);
  const [permission,setPermissions]=useState(null)
  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchIndustryRequest());
    dispatch(fetchTypeRequest());
  }, []);
const result = checkPermission("Business", "create").then((res) => {
  setPermissions(res);
});
useEffect(() => {
  if (permission === false && location.pathname.includes("/add-new-business")) {
    customSnackBar("You have not a Permssion To Add Business");
  }
}, [permission]);

  return (
    <SideNavone
    businessPermission={permission}>
      <div
        className="padding_left_right hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <div className="row">

          {isMobile ? (
            <div className={`${!isMobile ? "d-none " : " col-4"}`}>

              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                size="small"
                sx={{ mr: 5 }}
              // className="mb-3"
              >
                <KeyboardBackspaceIcon sx={{ fontSize: 24, color: "#AAAAAA" }} />
              </IconButton></div>
          ) : null}
          <div className={`${!isMobile ? "col-md-12  px-2 py-1 text-center " : " col-4 "}`}>

            <Dropzone
              name="image"
              upload={values.image}
              setFieldValue={setFieldValue}
              actionState={action}
              touched={touched.image}
              errors={errors.image}
              validationSchema={validationSchema}
            />
          </div>
        </div>
        <span className=" pt-3 text_heading_owner">Organization Details</span>

        <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 ">
          <label className="label_text_business">Organization Name</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="business-name"
            label="Organization Name"
            name="businessName"
            value={values.businessName}
            
            onChange={handleChange}
            placeholder="Enter Organization Name"
            error={touched.businessName && errors.businessName}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
          // placeholder="Enter Organization Name"
          // onChange={(event) => {
          //     setBusinessName(event.target.value);
          //     clearError('businessName'); // Clear the error
          //   }}
          />
        </div>
        {errors?.businessName && (
          <span className="text-danger font_size_password">
            {errors.businessName}
          </span>
        )}

        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Organization Type</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="business-type"
            label="Organization Type"
            name="businessType"
            placeholder="Enter Organization Type"
            onChange={handleChange}
            value={values.businessType}
            error={touched.businessType && errors.businessType}
            // placeholder="Enter Organization Type"
            // onChange={(event) => {
            //     setBusinessType(event.target.value)
            //         clearError('businessType'); // Clear the error
            //   }}
          />
        </div> */}
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Organization Type</label>

          <select
            type="text"
            className="form-control1 changecolor  m-0  p-0 "
            id="businessType"
            name="businessType"
            value={values.businessType}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            onChange={(e) => setFieldValue("businessType", e.target.value)}
          >
            <option disabled >Select Organization Type</option>
            {typeList &&
              typeList?.map((v, i) => {
                return (
                  <option key={i} value={v?.id}>
                    {v?.name}
                  </option>
                );
              })}
          </select>
        </div>
        {errors.businessType && (
          <span className="text-danger font_size_password">
            {errors.businessType}
          </span>
        )}

        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Industry</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            placeholder="Industry Type"
            id="industry"
            label="Industry"
            name="industry"
            onChange={handleChange}
            value={values.industry}
            // //size={20}
            variant="filled"
            error={touched.industry && errors.industry}
          />
        </div>
        {errors.industry && (
          <span className="text-danger font_size_password">{errors.industry}</span>
        )} */}
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business"> Industry Type</label>

          <select
            type="text"
            className="custom-dropdown changecolor m-0  p-0 "
            id="industry"
            name="industry"
            value={values.industry}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            onChange={(e) => setFieldValue("industry", e.target.value)}
          >
            <option className="text-black-50" disabled >
              Select Industry Type
            </option>
            {industryList &&
              industryList?.map((v, i) => {
                return (
                  <option key={i} value={v?.id}>
                    {v?.name}
                  </option>
                );
              })}
          </select>
        </div>
        {errors.industry && (
          <span className="text-danger font_size_password">
            {errors.industry}
          </span>
        )}
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Website</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="website"
            label="Website"
            name="website"
            value={values.website}
            onChange={handleChange}
            placeholder="Enter URL"
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            // //size={20}
            error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
          />
        </div>
        {errors.website && (
          <span className="text-danger font_size_password">
            {errors.website}
          </span>
        )}
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Country ID</label>

          <select
            type="text"
            name="countryId"
            className="form-control1 changecolor m-0  p-0 "
            id="countryId"
            value={values.countryId}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            onChange={(e) => setFieldValue("countryId", e.target.value)}
          >
            <option value={""}>Select Country ID</option>
            {countryList?.countries &&
              countryList?.countries?.map((v, i) => {
                return (
                  <option key={i} value={v?.id}>
                    {v?.name}
                  </option>
                );
              })}
          </select>
        </div>
        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Organization Description</label>
          <textarea
            className="form-control1 input_style_fix"
            rows={3}
            type="text"
            id="description"
            label="Description"
            name="description"
            onChange={handleChange}
            placeholder="Type Description Here..."
            // //size={20}
            value={values.description}
            error={touched.description && errors.description}
            // onChange={(event) => setBusinessDes(event.target.value)}
          ></textarea>
        </div> */}

        {loader ? (
          <ClipLoader color="#b2b2b2" size={30} />
        ) : (
          <OwnerDetail
            values={values}
            isMobile={isMobile}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            action={action}
            onSubmit={onSubmit}
          />
        )}
        <div className={`${!isMobile ? " w-100 d-flex mt-4 flex-wrap justify-content-end " : " w-100 mt-4 "}`}>

          <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#c0c0c0",
                  color: "#ffffff",
                },
              }}
              onClick={() => {
                history.push("/business/");
              }}
              variant="contained"
              className=" btn btn-secondary padding_next_secondary_button"
            >
              {"Cancel"}
            </Button>
          </div>
          <div>
            <Button
              sx={{
                width: isMobile ? "100%" : "150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              disabled={loading}
            >
              {loading ? <ClipLoader color="#fffff" size={30} /> : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </SideNavone>
  );
};

export default AddBusinessInfo;
