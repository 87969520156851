import React, { useEffect, useRef, useState } from "react";
// import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { post } from "../../../../utils/requests";
import { DOCUMENT_TO_URL_STAGING } from "../../../../Constants/DynamicFormConstants";

const SignatureField = ({ id, label, handleChange }) => {
  const signatureRef = useRef();

  const [signatureImage, setSignatureImage] = useState("");
  const [SignatureURL, setSignatureURL] = useState("");

  const handleEnd = () => {
    const canvas = signatureRef.current.getCanvas();
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], `signature-${id}.png`, {
          type: "image/png",
        });
        setSignatureImage(file);
      }
    });
  };

  const handleClear = () => {
    signatureRef.current.clear();
    setSignatureImage("");
    handleChange(id, "");
  };

  const handleUpload = async () => {
    if (!signatureImage) {
      toast.error("No signature available to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("Id", 0);
    formData.append("serviceName", "onboarding");
    formData.append("source", "Plexaar");
    formData.append("Category", "main");
    formData.append("FileType", "i"); // Adjust the file type based on your requirements
    formData.append("Files", signatureImage);
    formData.append("CreatedBy", 0);

    await post(DOCUMENT_TO_URL_STAGING, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        toast.success("Signature uploaded successfully.");
        // setSignatureURL(response.data.paths[0]);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (SignatureURL) handleChange(id, SignatureURL);
    // eslint-disable-next-line
  }, [SignatureURL]);
  return (
    <div className="float-label signature">
      <label htmlFor={id}>{label}</label>
      {/* <SignatureCanvas
        ref={signatureRef}
        canvasProps={{ id: id, className: "signature-canvas" }}
        onEnd={handleEnd}
      /> */}
      <div>
        <button type="button" onClick={handleClear} className="clear-signature">
          Clear
        </button>

        <button
          type="button"
          onClick={handleUpload}
          className="upload-signature"
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default SignatureField;
