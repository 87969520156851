import React, { useCallback, useEffect, useRef, useState } from "react";
import InputTextField from "../../TextField";
import DescriptionField from "../../TextField/descriptionField";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  businessAddressInitialValue,
  formateUpdateBusinessAddress,
  formateUpdateData,
  validationSchemaOfBusinessAddress,
} from "../../Business/helper";
// import "../AddBusiness/AddBusiness.scss";
import {
  addBusinessAddressesRequest,
  updateBusinessRequest,
} from "../../../../Store/Business/actions";
import "../../../schedulerModule/components/TimeSlot/Timeslot.scss";
import { formateData, formateDataUpdate, initialValues } from "../helper";
import { businessDatas } from "../../User/helper";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, IconButton, TextField } from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Times from "./Times";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { handlechange } from "../../../../Calendar/scheduler/store/dateSlice";
import Info from "./info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { convertToDateTime } from "../../../schedulerModule/components/TimeSlot/helper";
import { customSnackBar } from "../../../../utils";
import CustomizedSnackbars from "../../../../Calendar/scheduler/components/AlertToast";
import instance from "../../../../utils/utils";

const BusinessSchdular = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef();

  const history = useHistory();
  const location = useLocation();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [business, setBusiness] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [scheduleRecord, setScheduleDRecord] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const actions = location?.state?.action;
  const editData = scheduleData.data || "";
 

  let myData = {};
  useEffect(() => {
    if (actions !== "new" || actions !== "add-schdule") {
      const businessData = localStorage.getItem("businessRecord");
      myData = JSON.parse(businessData);
      // setBusiness(JSON.parse(business));
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const actionState = scheduleData.actionState || "new";
  useEffect(() => {
    if (actionState === "edit") {
      const businessDataUpdate = localStorage.getItem(
        "businessScularUpdatedRecord"
      );
      myData = JSON.parse(businessDataUpdate);
      setScheduleDRecord(businessDataUpdate);
    }
  }, []);
  const busiessId =
    actions === "new"
      ? id
      : actions === "add-schdule"
      ? selectedBusiness.id
      : myData.id;
  const AddSchedule = async (values) => {
    setLoading(true);

    const data = formateData(values, id);

    await instance

      .post(
        `/provideravailability_svc/pb/business/`,
        { data, timeZone: "Europe/London" }
      )
      .then((res) => {
        if(res?.data?.statusCode===0){
          if (actions === "new") {
            history.push("/business/");
          } else if (actions === "add-schdule") {
            history.push(`/calendar`);
          } else {
            history.push(`/business-profile/${selectedBusiness.id}`);
          }
          setLoading(false);
        }
        else{
          setLoading(false);

          customSnackBar(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const UpdateSchedule = async (values) => {
    setLoading(true);

    const data = formateDataUpdate(values, id);
    await instance

      .post(
        `/provideravailability_svc/pb/update/business/`,
        { data }
      )
      .then((res) => {
        if(res?.data?.statusCode===0){
        if (actions === "new") {
          history.push("/business/");
        } else if (actions === "add-schdule") {
          history.push(`/calendar`);
        } else {
          history.push(`/business-profile/${selectedBusiness.id}`);
        }
        setLoading(false);
      }
      else{
        setLoading(false);

        customSnackBar(res?.data?.message);
      }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const fetchBusinessSchdular = useCallback(async (formik) => {
    if (id!==0) {
    setLoader(true);
    await instance
      .get(
       `/provideravailability_svc/pb/business?businessId=${id}`
      )
      .then((response) => {
        if (response?.data?.result?.length > 0) {
          const data = {
            data: response?.data?.result,
            actionState: "edit",
          };
            
            formik.setFieldValue();
          setScheduleData(data);
          setLoader(false)
        } else {
          customSnackBar("Business Schedualr not exist");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoader(false));
    }
    else{
      setLoader(false); // Stop the loader

    }
  }, [id]);
  const handleSubmit = (values, handles, result) => {
    if (actionState === "edit") {
      UpdateSchedule(values);
    } else {
      AddSchedule(values);
    }
    // updateBusiness(values, handles, result);
  }; useEffect(() => {
  if (fetchBusinessSchdular && formRef && formRef.current) {
      fetchBusinessSchdular(formRef.current);
}
}, [fetchBusinessSchdular, formRef]);


  return (
    <SideNavone>
      {loader ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader color="#548DFF" />{" "}
        </div>
      ) : (
        <div
          className="padding_left_right hide_scrollbar  mt-2 "
          style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
        >
          <div className="row">
            {isMobile ? (
              <div className={`${!isMobile ? "d-none " : " col-4"}`}>
                <IconButton
                  onClick={() => {
                    history.push("/business/");
                  }}
                  size="small"
                  sx={{ mr: 5 }}
                  // className="mb-3"
                >
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 24, color: "#AAAAAA" }}
                  />
                </IconButton>
              </div>
            ) : null}
          </div>
          <Formik
            enableReinitialize
        innerRef={(f) => (formRef.current = f)}
            initialValues={initialValues(id, actions, actionState, editData)}
            // validationSchema={{}}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(options) => {
              const {
                touched,
                values,
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                prevScheduleItems,
                validateForm,
              } = options;
              return (
                <Form onSubmit={handleSubmit}>
                  <Info
                    // slotStates={slotStates}
                    // handleClickActive={handleClickActive}
                    {...options}
                    actionState={actionState}
                    isMobile={isMobile}
                    scheduleData={editData||[]}
                    // scheduleData={scheduleData && scheduleData.data || []}          
                            />{" "}
                  <div
                    className={`${
                      !isMobile
                        ? " w-100 d-flex mt-4 flex-wrap justify-content-end "
                        : " w-100 mt-4"
                    }`}
                  >
                    <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          if (actions === "new") {
                            history.push("/business/");
                          } else if (actions === "add-schdule") {
                            history.push(`/calendar`);
                          } else {
                            history.push(
                              `/business-profile/${selectedBusiness.id}`
                            );
                          }
                        }}
                        variant="contained"
                        className=" btn btn-secondary padding_next_secondary_button"
                      >
                        {"Cancel"}
                      </Button>
                    </div>
                    <div>
                      <Button
                        sx={{
                          width: isMobile ? "100%" : "150px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        className="padding_next_primary_button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="contained"
                        disabled={loading}
                      >
                        {loading ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : actionState === "new" ? (
                          !isMobile ? (
                            "Create"
                          ) : (
                            "Save & Continue"
                          )
                        ) : (
                          "Update"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </SideNavone>
  );
};

export default BusinessSchdular;
