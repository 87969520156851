import React from "react";
import Styles from "./Stepper.module.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



const StepCount = ({ section, handleClick, steps }) => {
  return (
    <div className={Styles.stepCountContainer}>
      <Stepper  activeStep={section-1} colorScheme="red">
        {steps?.map((step, index) => (
          <Step key={index}>
            <Box direction="column" alignItems="center">
            
              <Box flexShrink={0} className={Styles.textBox}>
                <StepLabel className={Styles.stepTitle}>
                  {step.sectionTitle}
                </StepLabel>
              </Box>
            </Box>

            {/* <StepSeparator className={Styles.stepSeprator} /> */}
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default StepCount;
