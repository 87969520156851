import React, { useCallback, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addsecondaryemail,
  addsecondaryphonenumber,
  loginUser,
  registerapi,
} from "../../../Store/Auth/actions";
import InputField from "../../../Shared/InputField";
import Headerauth from "../Headerauth/Headerauth";
import "../Login/Login.scss";
import { DEVICE_ID } from "../../../Constants/ImgConstants";
import "react-phone-input-2/lib/bootstrap.css";
import SideNavone from "../../../SideNavBar";
import Header from "../../BusinessProfile/Header";
import InputTextField from "../../BusinessProfile/TextField";
import PhoneInput from "react-phone-input-2";
import { ClipLoader } from "react-spinners";
import { Button } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Addsecondaymobile(props) {
  const flag = true;
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const [value, setValue] = useState();

  const addphonenumber = (event) => {
    const valuew = event.target.value;
    setValue(valuew);
  };
  // registered user id --------------------------------
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);
  const handleSubmit =async () => {
    await handleReCaptchaVerify();
  if(token){
    // Check if the token is available
    dispatch(
      addsecondaryphonenumber({
        id: registersuccessId,
        mobileNumber: value,
        type: 2,
        modifiedBy: registersuccessId,
        raptchaToken: token?token:"string",

        setIsLoading,
      })
    );
  }
    // history.push(`/password/${email}`);
  };
  return (
    <>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-md-4">
          <div>
            <div>
              <p className=" ps-3 sub_text_color_account_alltext_show_actual">
                Add Secondary Number
              </p>
            </div>
            <div className="p-2 w-100">
             
                <PhoneInput
                  //  inputStyle={{color:'green'}}
                  //  containerStyle={{backgroundColor:"#000"}}
                  buttonStyle={{ borderRight: "1px solid #DCDCDC" }}
                  //  dropdownStyle={{height:'50px'}}
                  inputStyle={{
                    width: "100%",
                    height: "46px",
                    color: " #616161",
                    border: "1px solid #DCDCDC",
                    borderRadius: "8px",
                    paddingLeft: "70px",
                    // paddingRight: "40px",
                    transition:
                      "background-color 0.3s, color 0.3s, border-color 0.3s",
                  }}
                  country={"pk"}
                  inputProps={{
                    onFocus: (e) => {
                      e.target.style.backgroundColor = "white";
                    },
                    onBlur: (e) => {
                      e.target.style.backgroundColor = "";
                    },
                  }}
                  value={value}
                  onChange={setValue}
                  // className="phone-input"
                />
             
            </div>
          </div>
          <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
            <div className="p-2">
              <Button
                sx={{
                  // width: "300px",
                  backgroundColor: "#538dff",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                onClick={handleSubmit}
                variant="contained"
              >
                
                {isLoading ? (
                  <ClipLoader color="#fffff" size={30} />

                ) : (
                  "Save and Continue"
                  )}{" "}
              </Button>
            </div>
          </div>
        </div>
      </SideNavone>
    </>
  );
}
