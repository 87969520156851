import React, { useRef, useState } from "react";
import Details from "../details";
import Pricing from "../pricing";
import Attributes from "../attributes";
import { RiFileCopyLine } from "react-icons/ri";
import ServicesTabMenuLinks from "../ServicesTabMenuLinks";
import InventoryTabMenu from "../../InventoryTabMenu";
import { BiArrowBack } from "react-icons/bi";
import Cost from "../cost";
import GroupsAndPkgs from "../group";
import { useParams } from "react-router-dom";
import { getRequest } from "../../request";
import SideNavone from "../../../../SideNavBar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const DetailForm = () => {
  const [NewTabId, setNewTabId] = useState(1);

  const history = useHistory();
  const store = useSelector((state) => state?.servicesReducer);
  const [isCopied, setIsCopied] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(true);
  const textRef = useRef();

  const handleCopy = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setIsCopied(true))
      .catch((error) => console.error("Error copying text:", error));
  };

  const copyToClipboard = () => {
    const textToCopy = store?.serviceToUpdate[0]?.serviceUrl;

    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setShowCopyIcon(false);

      setTimeout(() => {
        setIsCopied(false);
        setShowCopyIcon(true);
      }, 5000);
    });
  };

  const resetCopyState = () => {
    setIsCopied(!isCopied);
  };

  const handleTabId = (id) => {
    setNewTabId(id);
  };

  return (
    <SideNavone>
      <div className="inventory_service hide_scrollbar">
        <div
          className="row service_header_mobile d-sm-none d-flex g-0"
          style={{ backgroundColor: "white" }}
        >
          <div className="col-12 px-3 mt-2">
            <div className="col-11 d-flex justify-content-between p-0">
              <h4 className="p-0 m-0">
                <BiArrowBack
                  style={{ fontSize: "20px", color: "gray" }}
                  onClick={() => {
                    history.push("/all-services");
                  }}
                />{" "}
                &nbsp;{store?.serviceToUpdate[0]?.serviceName}
              </h4>
              <button
                type="button"
                className="btn btn-sm py-0 px-3 form_btns theme_btn_color"
              >
                Publish
              </button>
            </div>
            <p className="text-muted mb-0">
              expert.one/service/messi/messi-hair-cut &nbsp;
              <RiFileCopyLine className="theme_icon_color" size="24px" />{" "}
            </p>
          </div>
        </div>

        <div className="container px-sm-0">
          <div className="row bg-light  d-sm-flex d-none  mt-3 g-0">
            <div className="col-12 d-flex justify-content-between align-items-center service_publish px-3 py-4">
              <div>
                <h3 className="p-0 m-0">
                  {store?.serviceToUpdate[0]?.serviceName}
                </h3>
                <p className="text-muted p-0 m-0" ref={textRef}>
                  {store?.serviceToUpdate[0]?.serviceUrl} &nbsp;
                  {showCopyIcon ? (
                    <RiFileCopyLine
                      onClick={copyToClipboard}
                      className="theme_icon_color"
                      size="24px"
                      role="button"
                    />
                  ) : isCopied ? (
                    <>URL Copied!</>
                  ) : null}
                </p>
              </div>
              <div
                type="button"
                className="btn btn-primary px-4 form_btns theme_btn_color"
              >
                Publish
              </div>
            </div>
          </div>

          <ServicesTabMenuLinks />
          <InventoryTabMenu handleTabId={handleTabId} />
          {NewTabId === 1 && <Details />}
          {NewTabId === 2 && <Pricing />}
          {NewTabId === 3 && <Cost />}
          {NewTabId === 4 && <Attributes />}
          {NewTabId === 5 && <GroupsAndPkgs />}
          {/* {NewTabId === 6 && <Details />} */}
        </div>
      </div>
    </SideNavone>
  );
};

export default DetailForm;
