import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";

import StaffListTable from "../../Componenets/Customer/CustomerListTable";
import {
  useGetStaffsQuery,
  useSearchStaffListMutation,
} from "../../Feature/api/StaffApi";
import SideNavone from "../../SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SkeletonTable from "../BusinessProfile/SkeltonTableSimple/SkeltonTable";
import { ClipLoader } from "react-spinners";
import {
  getAllCustomer,
  getAllStaff,
  searchCustomerList,
  searchStaffList,
} from "../Dashboard/MainComponent/store/staffActions";

const BusinessTeam = () => {
  const business = useSelector((state) => state.Business?.selectedBusiness);
  const bussinessId = business?.id;
  var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const store = useSelector((state) => state?.staffReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [filterService, setFilterService] = useState([]);
  const [staffSortedData, setStaffSortedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const userid = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // const {
  //   isLoading,
  //   isSuccess,
  //   data: GetStaff,
  // } = useGetStaffsQuery({ userID, bussinessId, page });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          store?.filterTextCustomer === "" &&
          store?.firstNameCustomer === "" &&
          store?.lastName === "" &&
          store?.email === "" &&
          store?.mobileNumber === ""
        ) {
          setIsLoading(true);

          // Dispatch the async thunk and wait for it to complete
          await dispatch(
            getAllCustomer({
              id: business?.id,
              userid: parseInt(userID),
              page,
              pageSize: itemsPerPage,
              setIsLoading,
            })
          );
          setIsLoading(false);
        } else {
          // Dispatch the async thunk and wait for it to complete
          await dispatch(
            searchCustomerList({
              id: business?.id,
              userid: parseInt(userid),
              text: store?.filterTextCustomer,
              firstName: store?.firstNameCustomer
                ? store?.firstNameCustomer
                : "",
              lastName: store?.lastName ? store?.lastName : "",
              email: store?.email ? store?.email : "",
              number: store?.mobileNumber ? store?.mobileNumber : "",
            })
          );
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error:", error);
      } finally {
      }
    };

    fetchData();
  }, [
    business?.id,
    userid,
    dispatch,
    page,
    itemsPerPage,
    store?.filterTextCustomer,
    store?.firstNameCustomer,
    store?.lastName,
    store?.email,
    store?.mobileNumber,
  ]);

  const handleChange = (event, value) => {
    console.log(value, itemsPerPage, "VALUEESSSSSS");
    setPage(value);
  };
  const handleChangeRowsPerPage = (event, value) => {
    console.log(event.target.value, "VALUEESSSSSS");

    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(page);
  };
  const handleAddClick = () => {
    history.push("/edit-business-team/column", {
      state: staffSortedData,
    });
    // history.push(`staff-list/${bussinessId}/columns-list`);

    // navigate("/staff/edit-staff", { state: staffSortedData });
  };

  console.log("count is", store?.count);

  return (
    <>
      <SideNavone handleClick={handleAddClick}>
        <Grid px={2} pt={3} pb={5}>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ClipLoader color="#548DFF" />
            </div>
          ) : (
            <>
              <Grid item sm={12} md={12} lg={12}>
                {business === null ? (
                  <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                    <h5 className="m-0">
                      Business or Provider Schedule is not added or selected
                    </h5>
                    <Button
                      style={{ backgroundColor: "#5599FF" }}
                      className="col-4 py-3 my-3"
                      variant="contained"
                      onClick={() => history.push("/businessScheduler")}
                    >
                      Add Schedule
                    </Button>
                  </div>
                ) : (
                  <StaffListTable
                    staffData={filterService}
                    staffSortedData={staffSortedData}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    updateState={setStaffSortedData}
                  />
                )}
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                mt={6}
                mb={2}
              >
                <Box display="flex" justifyContent="space-between">
                  {/* <Pagination
                    count={Math.ceil(store?.totalcount / itemsPerPage)}
                    variant="outlined"
                    // page={page}
                    shape="rounded"
                    onChange={handleChange}
                  /> */}

                  <Stack spacing={2} className="">
                    <Pagination
                      sx={{
                        ".css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root":
                          {
                            border: "none",
                            color: "#969696",
                            minWidth: "25px",
                            borderRadius: "0px",
                            position: 'static',
                          },
                        ".css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                          {
                            backgroundColor: "transparent",
                            color: "black",
                          },
                      }}
                      count={Math.ceil(store?.count / itemsPerPage)}
                      page={page}
                      onChange={handleChange}
                      variant="outlined"
                      siblingCount={9}
                      boundaryCount={0}
                    />
                  </Stack>
                  <div>
                    <select
                      className="items_per_page_select"
                      onChange={(e) =>
                        setItemsPerPage(parseInt(e.target.value))
                      }
                      value={itemsPerPage}
                    >
                      <option disabled>Items Per page</option>
                      <option value={10} selected>
                        10
                      </option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      {/* <option value={store?.count}>All</option>{" "} */}
                    </select>
                    &nbsp;&nbsp;&nbsp;<span className="per-page-text">Per Page</span>
                  </div>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </SideNavone>
    </>
  );
};

export default BusinessTeam;
