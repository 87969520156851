// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageinput_container {
    height: 130px;
    width: 130px;
    background-color: #FFF;
    border: 1px solid #5599FF;
    border-radius: 10px;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageinput_uploadImage {
    padding-top: 10px;
    color: #616161;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}

.imageinput_pixels {
    padding-top: 4px;
    color: #A4A4A4;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}

.imageinput_preview {
    height: 130px;
    width: 130px;
    border: 1px solid #5599FF;
    border-radius: 10px;
    /* margin-right: 10px; */
    position: relative;
}

.imageInput_button {
    z-index: 10000;
    background-color: #FFF;
    border: none;
    outline: none;
    position: absolute;
    top: 0.7px;
    right: 0.7px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: x-small;
}`, "",{"version":3,"sources":["webpack://./src/Pages/DynamicForm/dynamicFormView/imageInput/imageInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,6BAA6B;IAC7B,+BAA+B;IAC/B,kBAAkB;AACtB","sourcesContent":[".imageinput_container {\n    height: 130px;\n    width: 130px;\n    background-color: #FFF;\n    border: 1px solid #5599FF;\n    border-radius: 10px;\n    /* margin: 0 auto; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.imageinput_uploadImage {\n    padding-top: 10px;\n    color: #616161;\n    font-family: Roboto;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    margin: 0;\n}\n\n.imageinput_pixels {\n    padding-top: 4px;\n    color: #A4A4A4;\n    font-family: Roboto;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 400;\n    margin: 0;\n}\n\n.imageinput_preview {\n    height: 130px;\n    width: 130px;\n    border: 1px solid #5599FF;\n    border-radius: 10px;\n    /* margin-right: 10px; */\n    position: relative;\n}\n\n.imageInput_button {\n    z-index: 10000;\n    background-color: #FFF;\n    border: none;\n    outline: none;\n    position: absolute;\n    top: 0.7px;\n    right: 0.7px;\n    border-top-right-radius: 10px;\n    border-bottom-left-radius: 10px;\n    font-size: x-small;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
