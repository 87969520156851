import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Avatar, Tooltip, Menu, MenuItem, Box, Button } from "@mui/material";
import instance from "../../../utils/utils";
import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
import {
  AppointmentDetailsIcon,
  RightAngle,
} from "../AppointmentDetails/Svgs/svgs";
import dayjs from "dayjs";

import { MoreOptionsVertSvg } from "../../../Assets/svgs";
import Venue from "../bookingDetails/Venue";
import {
  appoitmentsDetails,
  singleBookingAppoitmentDetails,
} from "../store/bookingSlice";
import { getOpenBooking } from "../store/calendarSlice";
import { customSnackBar } from "../../../utils";
import { ClipLoader } from "react-spinners";
import {
  customerImageURL,
  providerImageURL,
  serviceImageURL,
} from "../../../utils/imageUrlsExtension";
const OpenBooking = ({ callBackHandler, setSelectSlotFlag }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  let openBooking = useSelector((state) => state.calendarReducer.openBooking);
  const { customerData } = useSelector((state) => state.bookingReducer);
  let selectedService = useSelector(
    (state) => state.bookingReducer.servicesData
  );
  let slotData = useSelector((state) => state.bookingReducer.slotsData);

  const options = {
    weekday: "long", // Get the full day name (e.g., "Monday")
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheckin, setIsLoadingCheckIn] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState({ visible: false });
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);

  const [address, setAddress] = useState(null);
  const [openAction, setOpenAction] = useState(false);

  let bookingId = useSelector((state) => state.bookingReducer.bookingId);
  let plexaarSalesOrderId = useSelector(
    (state) => state.bookingReducer.plexarSaleOrderId
  );

  let appointmentId = useSelector(
    (state) => state.bookingReducer.appointmentId
  );
  const action = bookingData?.createdPlatforms;
  const handleMenuActionClick = (event) => {
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(columnValue);
    // setSelecteData(data);
  };

  const handleProfileClick = () => {
    if (bookingData?.plexaarcustomerId === 0) {
      //   customSnackBar("Customer Id not exsit");
    } else {
      const customerId =
        bookingData?.createdPlatforms === "expert"
          ? bookingData?.customerId
          : bookingData?.plexaarcustomerId;
      history.push(
        `/customer-profile-view/${bookingData?.plexaarcustomerId}`,
        action
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let serviceSku = "";
      if (
        selectedService[0] &&
        selectedService[0]?.attributes &&
        selectedService[0]?.attributes.length > 0
      ) {
        serviceSku = selectedService[0].attributes[0].attributeSku;
      } else {
        serviceSku = selectedService[0].serviceSKU;
      }
      try {
        // Dispatch the async thunk and wait for it to complete
        await dispatch(
          getOpenBooking({ id: customerData.customerId, sku: serviceSku })
        );
      } catch (error) {
        // Handle errors if needed
        console.error("Error:", error);
      } finally {
      }
    };

    fetchData();
  }, [customerData.customerId, selectedService]);

  const handleMenuClose = () => {
    setOpenAction(false);
    setAnchorEl(null);
  };
  //   const fetchBookingData = useCallback(async () => {
  //     if (plexaarSalesOrderId !== 0) {
  //       setIsLoading(true);
  //       await instance
  //         .get(
  //           `/booking_svc/pb/salesOrder_appointments/?plexaarSalesOrderId=${plexaarSalesOrderId}&timeZone=Europe%2FLondon`
  //         )
  //         .then((response) => {
  //           console.log(response, "responseresponseresponseresponse");
  //           setBookingData(response?.data?.data);
  //           setAddress(response?.data?.result?.address);
  //           //   dispatch(BookingDetails(response?.data?.result));
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         })
  //         .finally(() => setIsLoading(false));
  //     } else {
  //       setIsLoading(false); // Stop the loader
  //     }
  //   }, [dispatch, plexaarSalesOrderId]);
  //   useEffect(() => {
  //     fetchBookingData();
  //   }, [fetchBookingData]);

  const handleEvents = async (openBookingDetail) => {
    console.log(
      openBookingDetail,
      "openBookingDetailopenBookingDetailopenBookingDetail"
    );
    setIsLoadingCheckIn((prevState) => ({
      ...prevState,
      [openBookingDetail.appointments[0].plexaarBookingId]: true,
    }));
    const data = {
      // businessId: bookingData?.businessId,
      bookingId: openBookingDetail?.plexaarBookingId,
      plexaarAppointmentId: openBookingDetail?.appointments[0]
        ?.plexaarAppointmentId
        ? openBookingDetail?.appointments[0]?.plexaarAppointmentId
        : 0,
      expertAppointmentId: openBookingDetail?.appointments[0]
        ?.expertAppointmentId
        ? openBookingDetail?.appointments[0]?.expertAppointmentId
        : 0,
      // sku:bookingData?.serviceSKU?bookingData?.serviceSKU:"",
      sku:
        openBookingDetail?.attributes &&
        openBookingDetail?.attributes.length > 0
          ? openBookingDetail?.attributes[0].attributeSku
          : openBookingDetail?.serviceSKU,
      oldDate: {
        date: slotData.bookingDate,
        timeFrom: `${slotData.timeFrom}:00`,
        timeTo: `${slotData.timeTo}:00`,
      },
      newDate: {
        date: slotData.bookingDate,
        timeFrom: `${slotData.timeFrom}:00`,
        timeTo: `${slotData.timeTo}:00`,
      },
      isEdit: false,
      salesOrderId: openBookingDetail?.plexaarSalesOrderId,
      // bookingDate: events.bookingDate,
      // timeFrom: `${events.timeFrom}:00`,
      // timeTo: `${events.timeTo}:00`,
      isExpert: openBookingDetail?.isExpert,
      providerId: openBookingDetail?.providerId,
      providerIdNew: slotData.resourceId ?? 0,

      isCancel: false,
      isOpen: true,
      modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,

      customerId: openBookingDetail.isExpert
        ? openBookingDetail?.customerId
        : openBookingDetail?.plexaarcustomerId,
      timeZone: "Europe/London",
    };
    // setIsLoadingCheckIn(true);

    await instance
      .post(`/provideravailability_svc/pb/edit/booking-slot`, data)
      .then((res) => {
        customSnackBar("Booking Updated Successfully");
        // dispatch(
        //   openToast({
        //     message: "Booking Updated Successfully",
        //     status: "success",
        //   })
        // );
        window.window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // setIsLoadingCheckIn(false);
        setIsLoading((prevState) => ({
          ...prevState,
          [openBookingDetail.appointments[0].plexaarBookingId]: false,
        }));
        history.push(`/calendar`);
      })
      .catch((err) => {
        customSnackBar(err.message);
        // setIsLoadingCheckIn(false);
        setIsLoading((prevState) => ({
          ...prevState,
          [openBookingDetail.appointments[0].plexaarBookingId]: false,
        }));
        // dispatch(openToast({ message: err.message, status: "error" }));
      });
  };

  if (isLoading || openBooking.length === 0) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className="d-flex "
      >
        {/* Add your loader component here */}
        <ClipLoader color="#548DFF" />
      </div>
    );
  } else if (openBooking.length === 0) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div style={{ marginLeft: "10px" }}>Open Booking does not exist.</div>
      </div>
    );
  } else {
    return (
      <>
        <DialogBox
          {...dialog}
          header={dialog.header}
          message={dialog.message}
        />
        <div class="appointment-detail-section container-fluid">
          <div class="d-flex justify-content-around">
            <div class="col-12 col-md-7">
              <div className="col-md-12 px-2 pt-3 pb-1" id="style-2">
                <div className="d-flex padding-left-right ">
                  <div
                    className="pt-2"
                    onClick={() => callBackHandler()}
                    role="button"
                  >
                    <i id="icon_left_color" className="fas fa-chevron-left"></i>
                  </div>

                  <Container className="m-auto mb-3">
                    <p className="openbookingheading">
                      <span>
                        {openBooking?.at(0)?.customerFirstName}{" "}
                        {openBooking?.at(0)?.customerLastName}
                      </span>{" "}
                      has following bookings.
                    </p>
                    <Grid item xl={1} lg={3} md={6} sm={12} className="">
                      {openBooking.map((bookingData, index) => (
                        <div className="card card-cont py-2 ps-2 pe-0 appointment-detail-container mb-3">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex ">
                              {bookingData?.serviceImage ? (
                                <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    alt={bookingData?.serviceName}
                                    src={serviceImageURL(
                                      bookingData?.serviceId
                                    )}
                                    variant="rounded"
                                    style={{
                                      borderRadius: 50,
                                      width: "60px",
                                      height: "60px",
                                      backgroundColor: "#F0F0F0",
                                      color: "#B5B5B5"
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    sx={{
                                      width: "60px",
                                      height: "60px",
                                      borderRadius: 50,
                                    }}
                                  >
                                    S
                                  </Avatar>
                                </div>
                              )}
                              <div className="appointment-service-name_flex">
                                <div className="appointment-service-name_holder">
                                  <div className={`d-flex pt-1`}>
                                    <div className="d-flex flex-column">
                                      <p className="m-0 mx-2 openbooking-servicename spand ps-0">
                                        {bookingData.serviceName
                                          ? bookingData.serviceName
                                          : bookingData?.servicePayload
                                              ?.serviceName}
                                        {"  "}
                                        {bookingData?.attributes &&
                                        bookingData?.attributes?.length > 0 ? (
                                          <span className="pt-0 size-sml non-capitalized ps-1">
                                            (
                                            {
                                              bookingData?.attributes[0]
                                                .duration
                                            }{" "}
                                            mins )
                                          </span>
                                        ) : (
                                          <span className="pt-0 size-sml non-capitalized ps-1">
                                            ({bookingData.duration}{" "}mins )
                                          </span>
                                        )}
                                      </p>{" "}
                                      {bookingData?.attributes?.length > 0 && ( // Added safe navigation operator
                                        <div className="d-flex pt-0">
                                          <div className="d-flex flex-column">
                                            <span className="m-0 mx-2 spand ps-0">
                                              <Tooltip
                                                title={
                                                  bookingData?.attributes[0]
                                                    ?.value
                                                }
                                              >
                                                <p className="m-0 mx-0 appointment-details_attributes">
                                                  {
                                                    bookingData?.attributes[0]
                                                      ?.value
                                                  }{" "}
                                                  {""} sessions are remaining
                                                </p>
                                              </Tooltip>
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      <p className="m-0 mx-2 spand ps-0 openbooking-order">
                                        <span className="pt-0 size-sml non-capitalized">
                                          Order :
                                        </span>{" "}
                                        {bookingData?.plexaarBookingId}
                                      </p>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="mt-2 ms-0 mb-1 horizontal-line_appointment" />
                            <div className=" d-flex">
                              <div className="ms-1 serviceprice">
                                {bookingData?.currency === "usd" ? "$" : "£"}
                                {bookingData?.attributes?.length > 0
                                  ? bookingData.attributes[0].attributePrice +
                                    " "
                                  : bookingData?.totalAmount}
                              </div>
                              <div
                                className="d-flex flex-wrap cursor-pointer"
                                onClick={(event) =>
                                  handleMenuActionClick(event)
                                }
                              >
                                <MoreOptionsVertSvg />
                              </div>
                            </div>
                          </div>

                          {/* Attribute section */}
                          {/* {bookingData?.attributes.length > 0 ? (
                            <div>
                              {bookingData?.attributes &&
                                bookingData?.attributes.length > 0 && (
                                  <div className="pt-1">
                                    <div className="d-flex ps-0 flex-wrap justify-content">
                                      <span className="book size-sml p-1">
                                        Attributes
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center my-2">
                                      <div className="d-flex align-items-center">
                                        <AttributesIcon />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <hr className="mt-3 ms-0 mb-1 horizontal-line_appointment" />
                            </div>
                          ) : null} */}
                          {/* Customer Name */}
                          <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                          {/* <div>
                            <div className="d-flex flex-wrap align-items-center my-1 justify-content-between pe-0">
                              <div className="d-flex  flex-wrap justify-content">
                                <h1 className="book pt-1 size-sml">
                                  Customer Name
                                </h1>
                              </div>
                              <div
                                className="d-flex flex-wrap cursor-pointer"
                                onClick={(event) =>
                                  handleMenuActionClick(event)
                                }
                              >
                                <MoreOptionsVertSvg />
                              </div>
                            </div>
                            <div>
                              <div className="flex-wrap align-items-center d-flex justify-content-start">
                                <div className="provider-details">
                                  {bookingData.providerImage ? (
                                    <Avatar
                                      alt={bookingData?.customerFirstName}
                                      src={customerImageURL(bookingData?.customerId)}
                                      className="rounded-circle"
                                      style={{ width: 30, height: 30 }}
                                    />
                                  ) : (
                                    <Avatar
                                      className="rounded-circle"
                                      style={{
                                        backgroundColor: "#5599FF",
                                        width: 30,
                                        height: 30,
                                        color: "white",
                                      }}
                                    >
                                      {bookingData?.customerFirstName?.charAt(
                                        0
                                      )}
                                    </Avatar>
                                  )}
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap justify-content-between ">
                                    <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                      <span className="spand pt-1 ps-0 provider-name">
                                        {bookingData?.customerFirstName +
                                          " " +
                                          bookingData?.customerLastName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* Appointment Details */}
                          {/* <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" /> */}
                          <div className="mt-0">
                            {/* <span className="book size-sml">
                              Appointment Details
                            </span> */}
                            <div className="d-flex gap-2">
                              {bookingData?.appointments.map((appointment) =>
                                appointment?.status === "isCompleted" ? (
                                  <div
                                    className="pt-2 appointment-completed"
                                    key={appointment.id}
                                  >
                                    <>
                                      <span className="upcoming-next">
                                        {dayjs(
                                          appointment.bookingDate,
                                          "YYYY-MM-DD"
                                        ).format("D MMM")}
                                      </span>
                                      <span
                                        style={{ fontSize: "8.5px" }}
                                        className="m-0 upcoming-next-small justify-content-center completed-status"
                                      >
                                        Completed
                                      </span>
                                    </>
                                  </div>
                                ) : appointment?.status === "created" &&
                                  appointment?.bookingDate ? (
                                  <div
                                    className="pt-2 appointment-upcoming"
                                    key={appointment.id}
                                  >
                                    <span className="upcoming-next">
                                      {dayjs(
                                        appointment.bookingDate,
                                        "YYYY-MM-DD"
                                      ).format("D MMM")}
                                    </span>
                                    <span
                                      style={{}}
                                      className="m-0 upcoming-next-small justify-content-center upcoming-text"
                                    >
                                      Upcoming
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="book-new-session pt-1"
                                    onClick={() => {
                                      handleEvents(bookingData);
                                    }}
                                  >
                                    {isLoadingCheckin[
                                      appointment.plexaarBookingId
                                    ] ? (
                                      <ClipLoader color="#FFD705" size={12} />
                                    ) : (
                                      <>
                                        <span className="upcoming-next">
                                          Book
                                        </span>
                                        <span className="m-0 upcoming-next-small justify-content-center">
                                          Next Session
                                        </span>
                                      </>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          {/* Provider Name*/}
                          {/* <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" /> */}
                          {/* <div>
                            <div className="d-flex flex-wrap justify-content-between pe-0">
                              <div className="d-flex flex-wrap justify-content">
                                <h1 className="book pt-1 size-sml">
                                  Provider Name
                                </h1>
                              </div>
                            </div>
                            <div>
                              <div className="flex-wrap align-items-center d-flex justify-content-start">
                                <div className="provider-details">
                                  {bookingData.providerImage ? (
                                    <Avatar
                                      alt={bookingData?.providerName}
                                      src={providerImageURL(bookingData?.providerId)}
                                      className="rounded-circle"
                                      style={{ width: 30, height: 30 }}
                                    />
                                  ) : (
                                    <Avatar
                                      className="rounded-circle"
                                      style={{
                                        backgroundColor: "#5599FF",
                                        width: 30,
                                        height: 30,
                                        color: "white",
                                      }}
                                    >
                                      {bookingData?.providerName?.charAt(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap justify-content-between ">
                                    <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                      <span className="spand pt-1 ps-0 provider-name">
                                        {bookingData?.providerName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          {/* Venue*/}
                          {/* <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" /> */}
                          {/* {bookingData?.venue !== "" && (
                            <Venue VenueData={bookingData?.venue} />
                          )} */}

                          {/* {openAction && (
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <MenuItem onClick={handleProfileClick}>
                                <Box
                                  component="div"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  Customer Profile
                                </Box>
                              </MenuItem>
                            </Menu>
                          )} */}
                        </div>
                      ))}
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3 w-100">
            <h4
              style={{
                color: "#66666691",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              or
            </h4>

            <div className="w-80 row justify-content-center align-items-center py-2">
              <Button
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#FFD705",
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  borderRadius: "8px",
                  fontWeight: 700,
                }}
                className="col-4 py-2 my-2"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectSlotFlag("payment");
                }}
              >
                {"Continue with new order"}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default OpenBooking;
