// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal-section {
  border: 1px solid #dcdcdc;
  overflow-y: auto;
}

.size-sml {
  font-size: 12px;
}

.portal-card {
  min-width: 103px !important;
  min-height: 100px;
  border-radius: 10px !important;
  padding: 30px !important;
  background: #fbfcff;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); */
  border: 1px solid #c1c1c140 !important;
  box-shadow: 0px 5px 9px 0px #b0b0b040;
}
`, "",{"version":3,"sources":["webpack://./src/Calendar/scheduler/AppointmentDetails/portal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,8BAA8B;EAC9B,wBAAwB;EACxB,mBAAmB;EACnB,8CAA8C;EAC9C,sCAAsC;EACtC,qCAAqC;AACvC","sourcesContent":[".portal-section {\n  border: 1px solid #dcdcdc;\n  overflow-y: auto;\n}\n\n.size-sml {\n  font-size: 12px;\n}\n\n.portal-card {\n  min-width: 103px !important;\n  min-height: 100px;\n  border-radius: 10px !important;\n  padding: 30px !important;\n  background: #fbfcff;\n  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); */\n  border: 1px solid #c1c1c140 !important;\n  box-shadow: 0px 5px 9px 0px #b0b0b040;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
