import {
  SmallTextField,
  LongTextField,
  EmailTextField,
  DateField,
  NumberField,
  DropDownField,
  VideoDocumentField,
  AudioDocumentField,
  ImageUploadField,
  DocumentUploadField,
  SignatureField,
} from "./fields/dynamicFields";

import {
  DATE_QUESTION,
  DROPDOWN_QUESTION,
  EMAIL_QUESTION,
  LABEL_QUESTION,
  LONG_TEXT_QUESTION,
  MCQ_QUESTION,
  NUMBER_QUESTION,
  SMALL_TEXT_QUESTION,
  STATEMENT_QUESTION,
  VIDEO_DOCUMENT_QUESTION,
  AUDIO_DOCUMENT_QUESTION,
  IMAGE_QUESTION,
  DOCUMENT_QUESTION,
  SIGNATURE_QUESTION,
} from "../../Constants/DynamicFormConstants";

export const renderQuestion = (
  questions,
  handleChange,
  renderChildQuestions,
  formData
) => {
  const { question } = questions;
  const { id, contentObject, answers } = question;
  const { questionType, questionText } = contentObject;

  switch (questionType) {
    case MCQ_QUESTION:
      return (
        <DropDownField
          handleChange={handleChange}
          id={id}
          label={questionText}
          contentObject={contentObject}
          renderChildQuestions={renderChildQuestions}
          answer={answers}
          formData={formData}
        />
      );

    case DROPDOWN_QUESTION:
      return (
        <DropDownField
          handleChange={handleChange}
          id={id}
          label={questionText}
          contentObject={contentObject}
          renderChildQuestions={renderChildQuestions}
          answer={answers}
          formData={formData}
        />
      );

    case SMALL_TEXT_QUESTION:
    case LABEL_QUESTION:
      return (
        <SmallTextField
          handleChange={handleChange}
          id={id}
          label={questionText}
          answer={answers}
          formData={formData}
        />
      );

    case LONG_TEXT_QUESTION:
    case STATEMENT_QUESTION:
      return (
        <LongTextField
          handleChange={handleChange}
          id={id}
          label={questionText}
          statement={contentObject?.statement}
          answer={answers}
          formData={formData}
        />
      );
    case DATE_QUESTION:
      return (
        <DateField
          handleChange={handleChange}
          id={id}
          label={questionText}
          answer={answers}
          formData={formData}
        />
      );
    case EMAIL_QUESTION:
      return (
        <EmailTextField
          handleChange={handleChange}
          id={id}
          label={questionText}
          answer={answers}
          formData={formData}
        />
      );

    case NUMBER_QUESTION:
      return (
        <NumberField
          handleChange={handleChange}
          id={id}
          label={questionText}
          answer={answers}
          formData={formData}
        />
      );
    case VIDEO_DOCUMENT_QUESTION:
      return (
        <VideoDocumentField
          handleChange={handleChange}
          id={id}
          label={questionText}
          formData={formData}
        />
      );
    case AUDIO_DOCUMENT_QUESTION:
      return (
        <AudioDocumentField
          handleChange={handleChange}
          id={id}
          label={questionText}
          formData={formData}
        />
      );

    case DOCUMENT_QUESTION:
      return (
        <DocumentUploadField
          handleChange={handleChange}
          id={id}
          label={questionText}
          formData={formData}
        />
      );
    case IMAGE_QUESTION:
      return (
        <ImageUploadField
          handleChange={handleChange}
          id={id}
          label={questionText}
          formData={formData}
        />
      );

    case SIGNATURE_QUESTION:
      return (
        <SignatureField
          handleChange={handleChange}
          id={id}
          label={questionText}
          formData={formData}
        />
      );

    default:
      return null;
  }
};
