import React, { useState } from "react";
import "./NextGenFeatures.css";
import { feature2, feature3, feature1 } from "../../Assets";

export const NextGenFeatures = () => {
  const [data, setData] = useState([
    {
      icon: feature1,
      title: "Automation Mastery",
      detail:
        "Unmatched process streamlining and task automation capabilities.",
    },
    {
      icon: feature2,
      title: "Compliance Supremacy",
      detail:
        "Comprehensive adherence to regulatory requirements & industry standards.",
    },
    {
      icon: feature3,
      title: "Scalability Prowess",
      detail:
        "Effortless expansion & flexibility to accommodate business growth.",
    },
  ]);
  return (
    <div className="nextGenFeatureContainer">
      {data.map((item, index) => (
        <div className="nextGenFeaturesData pt-3 ps-3 pe-2">
            <div className="nextGenImage ps-1">
              <img src={item.icon} alt="" />
            </div>
            <p className="nextGenHeading">{item.title}</p>

          <p className="nextGenDetail">{item.detail}</p>
        </div>
      ))}
    </div>
  );
};
