import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  addSelectedBusiness,
  fetchBusinessFailure,
  fetchBusinessRequest,
} from "../../../Store/Business/actions";
import { BsThreeDots } from "react-icons/bs";
import { Button } from "@mui/material";
import { resetdate } from "../store/dateSlice";
import { permissionModule } from "../../../Store/Auth/actions";

const BusinessList = () => {
  const all_business = useSelector((state) => state.Business?.data);
  var userID = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const error = useSelector((state) => state.Business?.error);
  const dispatch = useDispatch();
  const history=useHistory()

  useEffect(() => {
    // setLoading(true)
    dispatch(fetchBusinessRequest(userID));
    if (error) {
      dispatch(fetchBusinessFailure(error));
    }
  }, [userID]);

  return (
    <div className="form-container  hide_scrollbar pc_display">
      <div className="row mt-5">
      <div className="row mx-4 pt-4 subs ">Choice Business</div>

        <div className="col-12 d-flex ">
          {/* <ul className="list-group w-100"> */}

            {all_business.length > 0 &&
              all_business.map((v, i) => {
                return (
                  // <Link
                  //   className="text-decoration-none"
                  //   to={`/calendar/${v.id}`}
                  // >
                  //   <li className="list-group-item d-flex flex-wrap justify-content-between align-items-center">
                  //     <div className="d-flex flex-wrap align-items-center bls_sec1">
                  //       <img
                  //         src="/logo192.png"
                  //         alt="No image"
                  //         className="me-2"
                  //         style={{ height: "30px", width: "30px" }}
                  //       />
                  //       <span>{v?.name}</span>
                  //     </div>
                  //     <div className="form-check d-flex align-items-center p-0 m-0">
                  //       <span className="me-2">Active</span>
                  //       <input
                  //         className="form-check-input m-0"
                  //         type="radio"
                  //         name={`flexRadio`}
                  //         id={`flexRadio`}
                  //       />
                  //     </div>
                  //   </li>
                  // </Link>
                  <>
                    <div className="col-md-3">
                  <div className="row mt-4 ms-4   mb-3 pt-0 ">
                    <div className="card ">
                      <div className="d-flex mt-3">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="me-2">
                            {" "}
                            <img
                              style={{ width: "41px", height: "41px" }}
                              src="/logo192.png"
                              alt="no-data"
                            />
                          </div>
                          <div className="ms-2">
                            <h6 className="mt-1 me-5 chat ">
                              {v?.name.charAt(0).toUpperCase() +
                              v?.name.slice(1)}
                            </h6>

                          </div>
                          <div className="position text-end">
                            {" "}
                            <BsThreeDots />{" "}
                          </div>
                        </div>
                      </div>
                    
                      <div>
                        {" "}
                        <hr
                          style={{
                            background: "#DCDCDC",
                            height: "1px",
                            width: "100%",
                            left: "20px",
                            top: "320px",
                            border: "0.7px",
                          }}
                        />
                      </div>
                      <div className="d-flex flex-wrap mb-3 justify-content-between">
                        <div className=" ">
                          <img
                            className="vector "
                            src="../images/Group 29441.png"
                            alt="no-data"
                          />
                          &nbsp; &nbsp;
                          <img
                            className="vector1 "
                            src="../images/Vector (2).jpg"
                            alt="no data"
                          />
                        </div>

                        <div className="pe-0 ps-4 text-end">
                          <div className="d-flex flex-wrap pe-0 me-0">
                            <div className="mb-2 mb-md-0">
                              {/* {showUpgradeButton && ( */}
                                <Button
                                  sx={{
                                    backgroundColor: "#538dff",
                                    height: "30px",
                                    borderRadius: "6px",
                                    textTransform: "capitalize",
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    // dispatch(resetdate());
                                    dispatch(addSelectedBusiness(v));
                                    
                                    history.push( "/calendar");
                                  }}
                                >
                                  {"Select"}
                                </Button>
                            </div>
                      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </>
                );
              })}
          {/* </ul> */}
        </div>
        
      </div>
    </div>
  );
};

export default BusinessList;