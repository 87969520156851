import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SideNavone from "../../../../../SideNavBar";
import { ClipLoader } from "react-spinners";
import { Avatar } from "@mui/material";
import "./Dtable.scss";
import { useDispatch, useSelector } from "react-redux";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import dayjs from "dayjs";
import {
  singleBookingAppoitmentDetails,
  singleBookingDetails,
} from "../../../../../Calendar/scheduler/store/bookingSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { customSnackBar } from "../../../../../utils";
import instance from "../../../../../utils/utils";

const CustomerBookig = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const id=1
  const [data, setData] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(true);
  const strAscending = [...data].sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );
  const selectedBusiness = useSelector(
    (state) => state.Business.selectedBusiness
  );
  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        setLoading(true);
        const response = await instance.get(
          `${process.env.REACT_APP_BASE_URL}/booking_svc/pb/booking/?customerId=${id}&businessId=${selectedBusiness?.id}`,
          config
        );

        setData(response?.data?.result);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateStatusBasedOnIsComplete = (stepsArray) => {
    return stepsArray.map((step) => {
      if (step.is_complete) {
        return { ...step, status: "Complete" };
      }
      return step;
    });
  };
  const updatedProcess = updateStatusBasedOnIsComplete(data);

  const isStepComplete = (stepId) => {
    const matchedStep = updatedProcess.find(
      (step) => step.current_step === stepId
    );
    if (matchedStep?.is_complete) {
      return matchedStep?.status === "Complete";
    }
  };

  const toggleCard = (data) => {
    if (data.appointments[0].bookingDate !== null) {
      dispatch(singleBookingDetails(data?.plexaarBookingId));
      dispatch(
        singleBookingAppoitmentDetails(
          data?.appointments[0]?.plexaarAppointmentId
        )
      );

      const toggle = {
        flag: true,
      };
      history.push(`/scheduler/booking_details/${selectedBusiness.id}`, toggle);
    }else{
      customSnackBar("Booking is Cancelled")
    }
  };

  return (
    <SideNavone>
      <div
        className="hide_scrollbar padding_left_right pt-1 d-flex"
        style={{
          height: "100vh",
          overflow: "auto",
          paddingBottom: "140px",
          position: "relative",
        }}
      >
        <div
          className="me-2 pt-3 col-md-1"
          onClick={() => history.goBack()}
          role="button"
        >
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>
        <div className="d-flex col-md-11 flex-column">
          {!loading && data?.length === 0 && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Customer haven't any Booking.
            </div>
          )}

          {!loading &&
            strAscending?.length > 0 &&
            strAscending?.map((vl, index) => (
              <div key={vl.plexaarBookingId}>
                <div className="pt-1">
                  <div
                    className="background_color_booking_list col-md-12 col-12"
                    onClick={() => toggleCard(vl)}
                  >
                    <div className="d-flex justify-content-between">
                      <div key={index} className="d-flex ">
                        {vl?.serviceImage ? (
                          <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                            <Avatar
                              alt={vl?.serviceName}
                              src={vl?.serviceImage}
                              variant="rounded"
                              style={{
                                borderRadius: 50,
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </div>
                        ) : (
                          <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: 50,
                              }}
                            >
                              S
                            </Avatar>
                          </div>
                        )}
                        <div className="d-flex mt-2 justify-content-between ">
                          <div
                            style={{
                              textAlign: "left",
                              textTransform: "capitalize",
                            }}
                            className=" justify-content-start"
                          >
                            <div
                              className={`d-flex ${
                                vl &&
                                vl.attributes &&
                                vl.attributes.length === 0
                                  ? "pt-2"
                                  : "pt-2"
                              }`}
                            >
                              <div className="d-flex flex-column">
                                <span className="m-0 mx-2 selected_servicename">
                                  {vl?.serviceName}
                                </span>{" "}
                                <span
                                  style={{
                                    fontSize: "8.5px",
                                    lineHeight: "10px",
                                  }}
                                  className="m-0 mx-2 selected_servicename"
                                >
                                  Session remaining
                                </span>
                                <span
                                  style={{
                                    fontSize: "8.5px",
                                    lineHeight: "10px",
                                  }}
                                  className="m-0 mx-2 selected_servicename"
                                >
                                  Order: {vl.plexaarBookingId}
                                </span>
                              </div>
                              <span
                                style={{ fontSize: "10px" }}
                                className="businessId"
                              >
                                ({vl?.bookingDuration}Min)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr
                        className="mt-3 ms-0 mb-1"
                        style={{ color: "#c4c4c4", margin: "15.6px 0 21.4px" }}
                      />
                      <div className="d-flex justify-content-end pt-2">
                        <span
                          className="ms-1"
                          style={{
                            color: "#4D4D4D",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 500,
                          }}
                        >
                          {vl?.currency === null ? "£" : vl.currencySymbol}
                          {vl?.totalPrice}{" "}
                        </span>
                        <MoreVertOutlinedIcon sx={{ paddingBottom: "5px" }} />
                      </div>
                    </div>
                    <hr
                      className="mt-3 ms-0 mb-1"
                      style={{ color: "#c4c4c4", margin: "15.6px 0 21.4px" }}
                    />
                    <div
                      style={{
                        width: "80px",
                        height: "50px",
                        border: "1px solid #f1c40f",
                        borderRadius: "10px",
                      }}
                      className="pt-2"
                    >
                      {vl.appointments[0].bookingDate !== null ? (
                        <>
                          <span
                            style={{
                              color: "#4D4D4D",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {dayjs(
                              vl.appointments[0].bookingDate,
                              "YYYY-MM-DD"
                            ).format("D MMM")}
                          </span>
                          <span
                            style={{ fontSize: "8.5px" }}
                            className="m-0 mx-2 selected_servicename d-flex justify-content-center"
                          >
                            Upcoming
                          </span>
                        </>
                      ) : (
                        <span
                          style={{ fontSize: "8.5px" }}
                          className="m-0 mx-2 my-2 selected_servicename d-flex justify-content-center"
                        >
                          Cancelled
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </SideNavone>
  );
};

export default CustomerBookig;
