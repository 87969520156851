// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in;
  height: 40px;
  margin: 0 0px 0 0;
  padding: 2px 0px;
  border-radius: 6px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b2bac5;
  background-color: #F5BF00;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .selected {
    width: 150px;
    height: 40px;
    background-color:#F5BF00;
    padding: none !important;
    color: white !important;
    /* text-overflow: none; */
  }
  .selected .box-data
  {
    color: white !important;
    font-family: "Roboto", sans-serif;
    margin: 0px;
    background: #F5BF00;
  }
  .box-data {
    /* background-color: #f2f2f2; */
    border-radius: 5px;
    font-size: 16px;
    color: #333;
  }
  .booking_date_title{
    color: #FFF;
font-family: "Roboto", sans-serif;;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0;
  }`, "",{"version":3,"sources":["webpack://./src/Calendar/scheduler/bookingDetails/CardList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iCAAiC;IACjC,kBAAkB;IAClB,eAAe;IACf,4BAA4B;EAC9B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB;EACA;IACE,YAAY;IACZ,YAAY;IACZ,wBAAwB;IACxB,wBAAwB;IACxB,uBAAuB;IACvB,yBAAyB;EAC3B;EACA;;IAEE,uBAAuB;IACvB,iCAAiC;IACjC,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,+BAA+B;IAC/B,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;EACA;IACE,WAAW;AACf,iCAAiC;AACjC,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,SAAS;EACP","sourcesContent":[".box {\n    display: inline-block;\n    font-family: \"Roboto\", sans-serif;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: all 0.3s ease-in;\n  height: 40px;\n  margin: 0 0px 0 0;\n  padding: 2px 0px;\n  border-radius: 6px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: #b2bac5;\n  background-color: #F5BF00;\n  width: 130px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  }\n  .selected {\n    width: 150px;\n    height: 40px;\n    background-color:#F5BF00;\n    padding: none !important;\n    color: white !important;\n    /* text-overflow: none; */\n  }\n  .selected .box-data\n  {\n    color: white !important;\n    font-family: \"Roboto\", sans-serif;\n    margin: 0px;\n    background: #F5BF00;\n  }\n  .box-data {\n    /* background-color: #f2f2f2; */\n    border-radius: 5px;\n    font-size: 16px;\n    color: #333;\n  }\n  .booking_date_title{\n    color: #FFF;\nfont-family: \"Roboto\", sans-serif;;\nfont-size: 12px;\nfont-style: normal;\nfont-weight: 500;\nline-height: normal;\nmargin: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
