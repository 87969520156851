import React, {  useEffect, useState } from "react";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  businessAddressInitialValue,
  formateUpdateBusinessAddress,
  validationSchemaOfBusinessAddress,
} from "../../Business/helper";
import BusinessAddress from "./BusinessAddress";
// import "../AddBusiness/AddBusiness.scss";
import {
  addBusinessAddressesRequest,
} from "../../../../Store/Business/actions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BusinessAddressMain = (props) => {
  const dispatch = useDispatch();
  const history=useHistory()
  var data = useSelector((state) => state.Business.addedAddress);
  var userInfo = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  var businessResult = useSelector((state) => state?.Business?.result);

  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const updateBusiness = (values, handle) => {
    let id = businessResult ? businessResult.id : "";
    const data = formateUpdateBusinessAddress(values);
    dispatch(addBusinessAddressesRequest({ data, setLoading, id }));
  };
  const handleSubmit = (values, handles, result) => {
    updateBusiness(values, handles, result);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <SideNavone>
      <div
        className="padding_left_right hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <div className="row">
          {isMobile ? (
            <div className={`${!isMobile ? "d-none " : " col-4"}`}>
              <IconButton
                onClick={() => {
                  history.push("/business/");
                }}
                size="small"
                sx={{ mr: 5 }}
                // className="mb-3"
              >
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 24, color: "#AAAAAA" }}
                />
              </IconButton>
            </div>
          ) : null}
         
        </div>
        <Formik
          initialValues={businessAddressInitialValue(data, userInfo)}
          validationSchema={validationSchemaOfBusinessAddress}
          onSubmit={(values, handles) => {
            handleSubmit(values, handles);
          }}
        >
          {(options) => {
            return (
              <Form onSubmit={handleSubmit}>
                <BusinessAddress loading={loading} isMobile={isMobile}{...options} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </SideNavone>
  );
};

export default BusinessAddressMain;
