import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import BookingDetailPage from "../AppointmentDetails/AppointmentDetails.jsx";
import SideNavone from "../../../SideNavBar";

export default function BookingDetails() {
  return (
    <div>
      <SideNavone>
        <BookingDetailPage />
      </SideNavone>
    </div>
  );
}
