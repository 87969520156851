// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navBarDesign {
  padding-left: 250px;
  position: absolute;
  width: 100%;
  top: 0;
  background: transparent !important;
}
.navBarDesign .innerNavBody {
  width: 100%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
}
.navBarDesign .innerNavBody .userTopImg {
  width: 45px;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/Shared/TopBar/style.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,kCAAA;AACF;AAAE;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAEJ;AADI;EACE,WAAA;EACA,iBAAA;AAGN","sourcesContent":["@import \"../../Assets/Scss/veriables.scss\";\n\n.navBarDesign {\n  padding-left: 250px;\n  position: absolute;\n  width: 100%;\n  top: 0;\n  background: transparent !important;\n  .innerNavBody {\n    width: 100%;\n    display: flex;\n    flex-wrap: inherit;\n    align-items: center;\n    justify-content: space-between;\n    padding: 18px 24px;\n    .userTopImg {\n      width: 45px;\n      margin-left: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
