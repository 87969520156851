import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageInput from "../../../../DynamicForm/dynamicFormView/imageInput";
import "../../../../DynamicForm/viewPage.css";
import TextField from "../../../../DynamicForm/dynamicFormView/textFieldInput";
import DropDown from "../../../../DynamicForm/dynamicFormView/dropdown";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../../../Store/dynamicForm/staffSlice";
import { customSnackBar } from "../../../../../utils";
import CustomPhoneInput from "../../../../DynamicForm/dynamicFormView/phoneInput";
import instance from "../../../../../utils/utils";

const AddCustomerForm = ({
  isModal = false,
  handleFormSubmission,
  handleSelectedCustomer,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.StaffDynamicForm.form);
  const isLoading = useSelector((state) => state.StaffDynamicForm.isLoading);
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const selectedBusinessId = useSelector(
    (state) => state.StaffDynamicForm.selectedBusiness
  );
  const [formData, setFormData] = useState({
    businessId: 0,
    nationalTaxNumber: 0,
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    imageUrl: "",
    qualification: "",
    designation: "",
    nationality: "",
    kinFirstName: "",
    kinLastName: "",
    kinMobile: "",
    kinEmail: "",
    kinAddress: "",
    countryId: 188,
    createdby: 0,
  });

  const handleFieldChange = (columnName, columnValue) => {
    // console.log(columnName,columnValue);
    // Find the index of the column in formData
    setFormData((prevData) => ({
      ...prevData,
      [columnName]: columnValue,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requiredFieldsEmpty = formState.sections.some((section) =>
      section.containers.some((container) =>
        container.fields.some(
          (field) => field.isRequired && !formData[field.columnName]
          // !formData.find((item) => item.columnName === field.columnName)
          //   ?.columnValue
        )
      )
    );
    if (requiredFieldsEmpty) {
      customSnackBar("Please fill in all required fields.");
      return;
    }
    try {
      dispatch(setIsLoadingTrue());
      const response = await instance.post(
        `/staff_svc/pv/staff/addStaffDynamically`,
        { ...formData, businessId: selectedBusinessId, createdBy: userID }
      );
      if (response.data.code === 0) {
        if (isModal) {
          handleSelectedCustomer(response.data.result.customer);
          handleFormSubmission();
        } else {
          history.goBack();
        }
        customSnackBar("Added Staff Successfully");
      } else {
        const message = response?.data?.message;
        customSnackBar(`Error Occured: ${message}`);
      }
    } catch (e) {
      customSnackBar(e);
    } finally {
      dispatch(setIsLoadingFalse());
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <form
          className={isModal ? "dynamic_formModal" : "dynamic_formContainer"}
          onSubmit={handleFormSubmit}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div
              className="pt-2"
              onClick={() => history.goBack()}
              role="button"
            >
              <i id="icon_left_color" className="fas fa-chevron-left"></i>
            </div>
            {/* <p className="dynamic_heading">You are Previewing this page</p>{" "} */}
          </div>
          {formState?.sections?.map((item, index) => (
            <div
              key={index}
              className={`${index !== 0 ? "dynamicform_section" : "mt-1"}`}
            >
              <span className="dynamicform_sectionTitle">
                {item.sectionTitle}
                <span
                  className={index === 0 ? "dynamic_formCompulsory" : "d-none"}
                >
                  {" (Compulsory to fill)"}
                </span>
              </span>
              {item.containers?.map((cont, i) => (
                <div
                  key={i}
                  className="d-flex align-items-center justify-content-center dynamicform_container"
                >
                  {cont.fields?.map((field, ind) => {
                    if (field.fieldType === "image") {
                      return (
                        <ImageInput
                          key={ind}
                          onImageChange={(selectedFile) =>
                            handleFieldChange(field.columnName, "selectedFile")
                          }
                        />
                      );
                    } else if (field.hasOption === true) {
                      // const findIndexOfOptions =  formData.findIndex(
                      //   (item) => item.columnName === field.columnName
                      // );
                      return (
                        <DropDown
                          key={ind}
                          isRequired={field.isRequired}
                          id={field.columnName}
                          options={field.options}
                          placeholder={`Select ${field.title}`}
                          value={
                            field?.options[
                              field?.options?.findIndex(
                                (item) =>
                                  parseInt(formData[field.columnName]) ===
                                  item?.optionId
                              )
                            ]?.optionName
                          }
                          onChange={(value) => {
                            const id = field.options.findIndex(
                              (item) => item.optionName === value
                            );
                            const val =
                              field.fieldType === "int"
                                ? field.options[id].optionId
                                : field.options[id].optionId.toString();
                            handleFieldChange(field.columnName, val);
                          }}
                          labelKey={field.title}
                          className={
                            ind === 1 && cont.fields.length === 2
                              ? "ms-2"
                              : (ind === 1 && cont.fields.length === 3) ||
                                (ind === 1 && cont.fields.length === 4)
                              ? "mx-2"
                              : ind === 3
                              ? "ms-2"
                              : ""
                          }
                        />
                      );
                    } else if (
                      field.columnName === "mobile" ||
                      field.columnName === "kin Mobile"
                    ) {
                      return (
                        <CustomPhoneInput
                          title={field.title}
                          isRequired={field.isRequired}
                          classNameParent={`
                            ${
                              ind === 1 && cont.fields.length === 2
                                ? "ms-2"
                                : (ind === 1 && cont.fields.length === 3) ||
                                  (ind === 1 && cont.fields.length === 4)
                                ? "mx-2"
                                : ind === 3
                                ? "ms-2"
                                : ""
                            }`}
                          value={
                            formData[field.columnName] || ""
                            // formData.find(
                            //   (item) => item.columnName === field.columnName
                            // )?.columnValue || ""
                          }
                          onChange={(value) =>
                            handleFieldChange(field.columnName, value)
                          }
                        />
                      );
                    } else {
                      return (
                        <TextField
                          key={ind}
                          label={field.title}
                          isRequired={field.isRequired}
                          placeholder={`Enter ${field.title}`}
                          type={
                            field.fieldType === "string"
                              ? "text"
                              : field.fieldType === "int" ||
                                field.fieldType === "float"
                              ? "number"
                              : field.fieldType === "date" ||
                                field.fieldType === "dob"
                              ? "date"
                              : "text"
                          }
                          value={
                            formData[field.columnName] || ""
                            // formData.find(
                            //   (item) => item.columnName === field.columnName
                            // )?.columnValue || ""
                          }
                          classNameParent={
                            ind === 1 && cont.fields.length === 2
                              ? "ms-2"
                              : (ind === 1 && cont.fields.length === 3) ||
                                (ind === 1 && cont.fields.length === 4)
                              ? "mx-2"
                              : ind === 3
                              ? "ms-2"
                              : ""
                          }
                          onHandleChange={(e) =>
                            handleFieldChange(field.columnName, e.target.value)
                          }
                        />
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          ))}
          <div className="d-flex justify-content-center ">
            <button type="submit" className="dynamic_AddNewCustomer">
              Add New Staff
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AddCustomerForm;
