import React, { useEffect, useState } from "react";
import { Avatar, Divider, Switch } from "@mui/material";

import Permissions from "./Permissions";
import { useDispatch } from "react-redux";
import { selectRole } from "./store/action";
import { DataNotFound } from "../../../utils";

const Services = ({
  service,
  handleChangeCheckBox,
  isChecked,
  showNewRole,
  handleAddNewRole,
  showPermissions,
  handlePermissionClick,
  selectedOption,
  handleChanges,
  role,
  createdRoles,
  businessId,
  selectedRole,
  setSelectedRole,
  handleRoles,
  selectedSwitch
}) => {
  const [newdata, setNewData] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    setNewData(service);
  }, [service]);

return (
    <>
      <span className=" pt-3 text_heading_owner">Product</span>
      <div>
        <span className="span m-auto">Select product for this user</span>
      </div>
      <div className="card px-3 py-2 me-2 min-h-[250px] card-cont">
        {newdata && newdata?.length > 0 ? (
          newdata?.map((data, index) => {
            return (
              <div key={index}>
                <div className="d-flex pt-3 align-items-center justify-content-between">
                  <div className="d-flex">
                    <div>
                      <Avatar
                        src={
                          data?.serviceImage
                            ? data?.serviceImage
                            : "/images/Group 29410.svg"
                        }
                        alt="not-found"
                        variant="rounded"
                        className=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: 10,
                        }}
                      />
                    </div>
                    <div className="font-size-sm pt-2 text-capitalize">
                      <span className="bolds text-black small">
                        {data?.name}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <Switch
                      // {...}
                      onChange={() => {
                        handleChangeCheckBox(index, data);
                      }}
                      checked={selectedSwitch.includes(data.id)}
                      // checked={isChecked[index] || false}
                      color="primary"
                      className="d-flex justify-content-center"
                    />
                  </div>
                </div>
                {selectedSwitch && selectedSwitch.includes(data.id) && (
                  <div>
                    <Permissions
                      role={role}
                      serviceId={data?.id}
                      productIndex={index}
                      businessId={businessId}
                      createdRoles={createdRoles}
                      showNewRole={showNewRole}
                      handleRoles={handleRoles}
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                      handleAddNewRole={handleAddNewRole}
                      showPermissions={showPermissions}
                      selectedSwitch={selectedSwitch}
                      handlePermissionClick={handlePermissionClick}
                      selectedOptions={selectedOption ? selectedOption : []}
                      handleChanges={handleChanges}
                    />
                  </div>
                )}
                <Divider style={{ marginTop: "10px", width: "100%" }} />
              </div>
            );
          })
        ) : (
          <div>
            {/* Insert your message or component for data not found */}
            <DataNotFound />
          </div>
        )}
      </div>
    </>
  );
};

export default Services;
