import React, { useEffect, useState } from "react";
import { Plans, formateData } from "./helper";
import { Button } from "@mui/material";
import axios from "axios";
import { GET_PLAN, GET_PLAN_ENTITIES } from "../../../utils/GraphQLQueries";
import { success } from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const SubscribePlan = ({ data, location }) => {
  const history = useHistory();

  const planSet =
    location &&
    location?.state &&
    location?.state?.state &&
    location?.state?.state?.planSet;
  const id = location && location?.state && location?.state?.id;
  const [loading, setLoading] = useState();
  const createSubscription = async (values) => {
    setLoading(true);
    const businessId = id; // Assuming 'id' is defined somewhere
    const data = formateData(values); // Assuming 'formateData' is a correct function name

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: `
        
              mutation{
                createSubscription
                (
                  businessId: "${businessId}",
                  startDate: "${data.startDate}",
                  endDate: "${data.endDate}",
                  planId: "${data.planId}",
                )
                {
                  subscription{
                    id
                    status
                    planId{
                      name
                      isDeleted
                      id
                      productId{
                        name
                        createdAt
                        id
                        createdAt
                        updatedAt
                      }
                    }
                    endDate
                    createdAt
                    updatedAt
                    isDeleted
                    BusinessId
                  }
                }
              }
          `,
        }
      );

      if (response.status === 200) {
        success("Successfully Subscribed");
        history.push("/subscribe");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = (values) => {
    createSubscription(values);
  };
  return (
    <>
      {planSet.map((row) => {
        return (
          <div className="card1 col-md-3 col-12  ms-0">
            {row.name === "Pro" ? (
              <div className="col-md-12  d-flex  justify-content-center">
                {" "}
                <span
                  className="spans"
                  style={{
                    textTransform: "uppercase",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  MOST POPULAR
                </span>
              </div>
            ) : null}
            <div className="d-flex ba mt-3 pt-2  justify-content-center">
              {row.name.charAt(0).toUpperCase() +
                row.name
                  .slice(1)
                  .replace(/(\s\w)/g, (match) => match.toUpperCase())}
            </div>
            <div className="dol mt-3 pt-3">
              <div className="row">
                <div className="col-md-6 text-end">
                  {row.currency === "USD" ? "$" : null}
                  {row.price}
                </div>
                <div className="col-md-4 mt-3 text-start me-0 pe-0  ps-0 ms-0 month">
                  /month
                </div>
              </div>
            </div>
            <div className="col-md-10 ms-4 mt-4 pt-4 pt-1">
              <div className=" row">
                <div className="d-flex">
                  <Button
                    sx={{
                      backgroundColor: "#5599FF",
                      height: "30px",
                      width: "200px",
                      //   maxWidth: "1000px",
                      borderRadius: "6px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => {
                      //   navigate("/calender");
                    }}
                  >
                    {"Start Trial"}
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="d-flex buy mt-3 pt-2  justify-content-center cursor-pointer"
              style={{ cursor: "pointer" }}
              onClick={() => {
                submitForm(row);
              }}
            >
              Buy Now
            </div>
            <div className="row pt-3">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                {" "}
                {row.value}
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr ms-2" />
            </div>
            <div className="row  ">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckCheckedDisabled"
                    checked
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckCheckedDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr" />
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input tick"
                    type="checkbox"
                    value=""
                    id="flexCheckDisabled"
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr" />
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input tick"
                    type="checkbox"
                    value=""
                    id="flexCheckDisabled"
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr " />
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input tick"
                    type="checkbox"
                    value=""
                    id="flexCheckDisabled"
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr " />
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input tick"
                    type="checkbox"
                    value=""
                    id="flexCheckDisabled"
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr " />
            </div>
            <div className="row mt-0 pt-0">
              <div className="col-md-12 d-flex justify-content-center ps-0 use">
                <div className="form-check">
                  <input
                    className="form-check-input tick"
                    type="checkbox"
                    value=""
                    id="flexCheckDisabled"
                    disabled
                  />
                  <label
                    className="form-check-label"
                    for="flexCheckDisabled"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <hr className="hr" />
            </div>
          </div>
        );
      })}
    </>
  );
};
export default SubscribePlan;
