import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { base_url } from "../../base_url";
import { postRequest, putRequest } from "../../request";
import { useParams } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusinessFailure,
  fetchBusinessRequest,
  fetchCountryRequest,
} from "../../../../Store/Business/actions";
import { useHistory } from "react-router-dom";
import { disableMiniLoader, enableMiniLoader } from "../../store/ServicesSlice";
import { BntLoader } from "../../../../Componenets/loaders";

const StaffServiceForm = ({ sources }) => {
  const [businessId, setBusinessId] = useState();
  const [source, setSource] = useState();
  const [countryId, setCountryId] = useState([1]);
  const [activate, setActivate] = useState(true);
  const [isCountryAssigned, setIsCountryAssigned] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const all_business = useSelector((state) => state.Business?.data);
  const countryList = useSelector((state) => state?.Business?.countryList);
  const service_store = useSelector(
    (state) => state?.servicesReducer?.serviceToUpdate[0]
  );
  const store = useSelector((state) => state?.servicesReducer);

  const service_store_expert = useSelector(
    (state) => state?.servicesReducer?.expertServiceToUpdate[0]
  );
  var userDetail = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  useEffect(() => {
    dispatch(fetchCountryRequest());
  }, []);
 
  const assignBusiness = async () => {
    dispatch(enableMiniLoader());

    const data = {
      services: [
        {
          serviceId: parseInt(id),
          sku:
            sources === 2
              ? service_store_expert?.serviceSKU
              : service_store?.serviceSKU || "",
          name:
            sources === 2
              ? service_store_expert?.serviceName
              : service_store?.serviceName || "",
          inHouseSku:
            sources === 2
              ? service_store_expert?.isInHouseSKU
              : service_store?.isInHouseSKU || "",
          inClinicSku:
            sources === 2
              ? service_store_expert?.inClinicSku
              : service_store?.inClinicSku || "",
          isOnlineSku:
            sources === 2
              ? service_store_expert?.isOnlineSku
              : service_store?.isOnlineSku || "",
          image: "string",
          duration:
            sources === 2
              ? service_store_expert?.duration
              : service_store?.duration || 0,
          availableSeats:
            sources === 2
              ? service_store_expert?.availableSeats
              : service_store?.availableSeats || 0,
          price: sources === 2 ? service_store_expert?.actualPrice : 0,
          currencyId: 0,
          isOnline:
            sources === 2
              ? service_store_expert?.isOnline
              : service_store?.isOnline || true,
          isInClinic:
            sources === 2
              ? service_store_expert?.isInClinic
              : service_store?.isInClinic || true,
          isInHouse:
            sources === 2
              ? service_store_expert?.isInHouse
              : service_store?.isInHouse || true,
          shortDesc:
            sources === 2
              ? service_store_expert?.shortDesc
              : service_store?.shortDesc || "string",
          hasAttribute:
            sources === 2
              ? service_store_expert?.hasAttributes
              : service_store?.hasAttributes || true,
        },
      ],
      countryId:
        sources === 2
          ? service_store_expert?.countryId !== null && service_store_expert?.countryId.length !== 0
            ? service_store_expert?.countryId
            : [1]
          : service_store?.countryId !== null && service_store_expert?.countryId.length !== 0
          ? [service_store?.countryId]
          : [1],
      businessId: parseInt(businessId),
      createdBy: 0,
      isExpert: parseInt(source) === 1 ? false : true,
    };

    const res = await postRequest(
      `${base_url}/Service/AddBusinessServiceNew`,
      data
    );
    if (res?.message === "Success") {
      alert("Business assigned successfully");
      dispatch(disableMiniLoader());

      history.push("/all-services");

      // activeBusiness()
    } else {
      if (res?.message === "Same data already exists") {
        alert(res?.message);
        dispatch(disableMiniLoader());

        history.push("/all-services");
      } else {
        alert(res?.message);
        dispatch(disableMiniLoader());
      }
    }
  };
  // const activeBusiness = async () => {
  //     const data = {
  //         serviceId: parseInt(id),
  //         businessId: parseInt(businessId),
  //         status:true
  //     }
  //     const res = await putRequest(`${base_url}/Service/UpdateBusinessServiceStatus`, data);
  //     if (res?.message === "succeeded") {
  //         alert('Business assigned successfully');
  //         history.push('/all-services');
  //     }
  //     else {
  //         alert(res?.message)
  //     }
  // }
  const assignCountry = async () => {
    dispatch(enableMiniLoader());

    const data = {
      serviceId: parseInt(id),
      countryId: countryId,
      createdBy: 0,
    };
    const res = await postRequest(
      `${base_url}/Service/AddServiceCountry`,
      data
    );
    if (res?.message === "Success") {
      setIsCountryAssigned(true);
      alert("Country assigned successfully");
      dispatch(disableMiniLoader());
    } else {
      alert(res?.message);
      if (res?.message === "Already Exists") {
        setIsCountryAssigned(true);
        dispatch(disableMiniLoader());
      }
    }
  };

  useEffect(() => {
    if (userDetail) {
      dispatch(fetchBusinessRequest(userDetail));
    }
  }, [userDetail]);
  useEffect(() => {}, [businessId]);
  return (
    <>
      {isCountryAssigned ? (
        <div className="container">
          <div className="row mt-5">
            <h3 className="mb-4">Business Assignment</h3>
            <hr />
            <select
              className="form-control"
              onChange={(e) => setBusinessId(e.target.value)}
            >
              <option>Select Business</option>
              {all_business &&
                all_business.map((v, i) => {
                  return <option value={v?.id}>{v?.name}</option>;
                })}
            </select>
          </div>

          <div className="row mt-3">
            <select
              className="form-control"
              onChange={(e) => setSource(e.target.value)}
            >
              <option>Select Source</option>

              <option value={1}>Plexaar</option>
              <option value={2}>Expert</option>
            </select>
          </div>
          <div className="d-flex flex-rows align-items-center py-2">
            <p className="p-0 m-0">Activate</p>
            <input
              type="checkbox"
              checked={activate}
              onClick={(e) => {
                setActivate(!activate);
              }}
              className="pe-3"
              style={{ marginLeft: "10px" }}
            ></input>
          </div>
          <div className="row">
            <button
              className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1 mt-3"
              onClick={assignBusiness}
            >
              <AddBoxIcon className="addbox_icon me-1" />
              Assign Business
            </button>
            {store?.miniLoading && <BntLoader />}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row mt-5">
            <h3 className="mb-4">Country Assignment</h3>
            <hr />
            <select
              className="form-control"
              onChange={(e) => setCountryId([parseInt(e.target.value)])}
            >
              <option value={""}>Select Country ID</option>
              {countryList?.countries &&
                countryList?.countries?.map((v, i) => {
                  return (
                    <option key={i} value={v?.id}>
                      {v?.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="row">
            <button
              className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1 mt-3"
              onClick={assignCountry}
            >
              <AddBoxIcon className="addbox_icon me-1" />
              Assign Country
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StaffServiceForm;
