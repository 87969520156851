import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "../bookingDetails/style.css";
import "./portal.css";

import { customSnackBar } from "../../../utils";
import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
import { slotAxios } from "../../axios";
import {
  BeforeAndAfterIcon,
  ChatIcon,
  DocumentsIcon,
  NotesIcon,
  PrinterIcon,
  ReminderBellIcon,
  ShareIcon,
} from "./Svgs/svgs";
import instance from "../../../utils/utils";

const Portal = () => {
  const history = useHistory();
  const { id } = useParams();

  let bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const [dialog, setDialog] = useState({ visible: false });
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);




  const portalData = [
    
    {
      title: "Notes",
      icon: <NotesIcon />,
      onClick: () => history.push("/note"),
    },
    {
      title: "Documents",
      icon: <DocumentsIcon />,
      onClick: () => history.push("/dynamic-from"),
    },
    {
      title: "Before / After",
      icon: <BeforeAndAfterIcon />,
      onClick: {},
    },
    {
      title: "Reminders",
      icon: <ReminderBellIcon />,
      onClick: {},
    },
    {
      title: "Chat",
      icon: <ChatIcon />,
      onClick: () => history.push("/chat"),
    },
  ];

  return (
    <>
    
      <div className="card px-3 card-cont p-3 portal-section mb-4">
        <div className="d-flex flex-wrap justify-content-between pe-0">
          <div className="d-flex ps-1 flex-wrap justify-content">
            <h1 className="book pt-1 size-sml">Portal</h1>
          </div>
          <div>
            <PrinterIcon />
            &nbsp;
            <ShareIcon />
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-wrap">
            {portalData.map((data) => (
              <div className="pe-3" onClick={data.onClick}>
                <div className="card px-2 py-2 hover pt-2 border-1 p-3 portal-card">
                  <div className="d-flex flex-wrap justify-content-center align-items-center flex-column w-100">
                    <div className=" ms-0 pt-2 d-flex flex-wrap justify-content-center align-items-center flex-column">
                      {data.icon}
                    </div>
                    <span className="spanF pt-4 mt-0">{data.title}</span>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="pe-3">
              <div
                className="card px-2 py-2 hover pt-2  border-1 p-3"
                style={{
                  minWidth: "103px",
                  minHeight: "100px",
                  borderRadius: "10px",
                  padding: "30px",
                  background: "#fbfcff",
                  boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center flex-column w-100"
                  onClick={(e) => {
                    bookingData?.appointments[0]?.isCompleted === true
                      ? handleUpdateAlert()
                      : handleUpdateBooking();
                  }}
                >
                  <div className=" ms-0 pt-2 d-flex flex-wrap justify-content-center align-items-center flex-column">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0013 5.83203L17.5013 2.33203H7.0013C6.38246 2.33203 5.78897 2.57786 5.35139 3.01545C4.9138 3.45303 4.66797 4.04653 4.66797 4.66536V23.332C4.66797 23.9509 4.9138 24.5444 5.35139 24.9819C5.78897 25.4195 6.38246 25.6654 7.0013 25.6654H21.0013C21.6201 25.6654 22.2136 25.4195 22.6512 24.9819C23.0888 24.5444 23.3346 23.9509 23.3346 23.332"
                        stroke="#FFD705"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.33203 21H10.4987"
                        stroke="#FFD705"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.468 11.2014C21.9321 10.7373 22.5616 10.4766 23.218 10.4766C23.8743 10.4766 24.5038 10.7373 24.968 11.2014C25.4321 11.6656 25.6928 12.2951 25.6928 12.9514C25.6928 13.6078 25.4321 14.2373 24.968 14.7014L19.8346 19.8348L15.168 21.0014L16.3346 16.3348L21.468 11.2014Z"
                        stroke="#FFD705"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="spanF pt-4 mt-0"> Modify</span>
                </div>
              </div>
            </div> */}
            {/* <div className="pe-3">
              <div
                className="card px-2 py-2 hover pt-2  border-1 p-3"
                style={{
                  minWidth: "103px",
                  minHeight: "100px",
                  borderRadius: "10px",
                  padding: "30px",
                  background: "#fbfcff",
                  boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center flex-column w-100"
                  onClick={(e) => {
                    bookingData?.appointments[0]?.isCompleted === true
                      ? handleUpdateAlert()
                      : handleBookingCancelConfirmation();
                  }}
                >
                  <div className=" ms-0 pt-2 d-flex flex-wrap justify-content-center align-items-center flex-column">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9987 25.6654C20.442 25.6654 25.6654 20.442 25.6654 13.9987C25.6654 7.55538 20.442 2.33203 13.9987 2.33203C7.55538 2.33203 2.33203 7.55538 2.33203 13.9987C2.33203 20.442 7.55538 25.6654 13.9987 25.6654Z"
                        stroke="#FFD705"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.71484 5.71484L22.2815 22.2815"
                        stroke="#FFD705"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="spanF pt-4 mt-0"> Cancel Booking</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Portal;
