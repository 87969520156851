import moment from "moment";

export const lists = [
  {
    title: "User",
  },
  {
    title: "Automated Scheduling",
  },
  {
    title: "Unlimited Bots",
  },
  {
    title: "Advance Dashboard ",
  },
  {
    title: "Unlimited Project Use",
  },
  {
    title: "Unlimited Project Use",
  },
  {
    title: "Advance Insight",
  },
];
export const Plans = [
  {
    price: "33",
    currency: "$",
    package: "Basic",
    value: 3,
  },
  {
    price: "44",
    currency: "$",
    package: "Standard",
    value: 7,
  },
  {
    price: "60",
    currency: "$",
    package: "Pro",
    value: 24,
  },
  {
    price: "100",
    currency: "$",
    package: "Enterprice",
    value: " 50+",
  },
];
const currentDate = moment();
const startDate = currentDate.format("MM/DD/YYYY"); // Use default format

// Calculate endDate by adding 30 days to the startDate
const endDate = currentDate.clone().add(30, "days");
const formattedEndDate = endDate.format("MM/DD/YYYY"); // Use default format
export const formateData = (values, id) => {
  return {
    planId:values.id,
    // subscriptionId:5,
    // subscriptionId:values.subscriptionSet[0].id,
    status:"active",
    startDate:startDate,
    endDate: formattedEndDate,
  };
};