import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import Times from "./Times";
import { monthOptions } from "../TimeSlot/helper";
import { FormateDate } from "../../utils";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";

const RepeatedDates = (props) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleUpdateDatesTimeSlots,
    setErrors,
  } = props;

  const [activeMonth, setActiveMonth] = useState(null);
  const [lazyLoadedMonths, setLazyLoadedMonths] = useState([]);

  useEffect(() => {
    if (activeMonth !== null && !lazyLoadedMonths.includes(activeMonth)) {
      const startDate = dayjs(values.startDate);
      const selectedMonth = monthOptions.find(
        (option) => option.id === activeMonth
      );
      const endDate = startDate.add(selectedMonth.days, "day");
      handleUpdateDatesTimeSlots(
        startDate,
        endDate,
        values,
        setFieldValue,
        selectedMonth
      );
      setLazyLoadedMonths((prev) => [...prev, activeMonth]);
    }
  }, [activeMonth, lazyLoadedMonths, values.startDate]);

  useEffect(() => {
    const startDate = dayjs(values.startDate);
    const endDate = startDate.add(30, "day");
    handleUpdateDatesTimeSlots(startDate, endDate, values, setFieldValue);
    setActiveMonth(1);
  }, []);

  function splitArrayIntoChunks(array, chunkSize) {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }

  const arrayOfObjects = values.scheduleItems;
  const chunkSize = 30;
  const splitArray = splitArrayIntoChunks(arrayOfObjects, chunkSize);
  const handleChangeActiveMonth = (event, value) => {
    setActiveMonth(value ? value.id : null);
  };

  const handleSelectUnSelectSlot = (index, slot, setFieldValue) => {
    const selectedMonth = activeMonth || slot.month;

    const currentSlot = values.scheduleItems.find(
      (slot) => slot.month === selectedMonth || slot.active === true
    );
    setFieldValue(`scheduleItems.${index}`, {
      ...slot,
      startTime: slot.active === true ? [""] : currentSlot.startTime,
      endTime: slot.active === true ? [""] : currentSlot.endTime,
      active: slot.active === true ? slot.active === false : !slot.active,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <span className="schedule_text_mobile pt-2">
          {" "}
          <SyncOutlinedIcon
            className="text-primary"
            sx={{ fontSize: "18px" }}
          />
          Repeat
        </span>{" "}
        <div className="autocomplete">
          <Autocomplete
            disablePortal
            options={monthOptions}
            defaultValue={monthOptions[0]}
            getOptionLabel={(option) => option.value}
            onChange={handleChangeActiveMonth}
            sx={{
              width: 150,
              backgroundColor: "#59F",
              borderRadius: "10px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    color: "white",
                    height: "30px",
                    fontSize: "12px",
                    paddingTop: "10px",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
      {splitArray.map((weekDays, weekIndex) => (
        <div className="scroll-container card mt-3" key={weekIndex}>
          <div className="card_mobile d-flex justify-content-between ">
            <div>
              <span className="schedule_text_mobile">
                Month {weekIndex + 1}
              </span>
            </div>
            <div className="  ms-3 me-3 d-flex justify-content-evenly">
              <div className="row  pe-5 me-1">
                <div className="col-md-5 "></div>
                <div className="col-md-7 ps-4 d-flex justify-content-evenly">
                  <div className="col-md-1">
                    <div className="time">From</div>
                  </div>
                  <div className="col-md-6">
                    <div className="time pe-5 ms-4"> &nbsp;&nbsp;&nbsp; To</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {weekDays.map((slot, index) => (
            <div key={index}>
              <div>
                <div className="d-flex">
                  <div className="d-flex align-items-start justify-content-start flex-wrap mt-3">
                    <div className="card-text-mobile mt-3 me-4">
                      <label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={slot.active}
                          checked={slot.active}
                          onChange={() =>
                            handleSelectUnSelectSlot(index, slot, setFieldValue)
                          }
                          style={{
                            marginBottom: "10px",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        {slot.date && FormateDate(slot.date)}
                      </label>
                    </div>
                    <Times
                      values={values}
                      slot={slot}
                      scheduleIndex={index}
                      setFieldValue={setFieldValue}
                      startTimeValues={values.scheduleItems[index].startTime}
                      startTimeTouched={
                        touched &&
                        touched.scheduleItems &&
                        touched.scheduleItems[index] &&
                        touched.scheduleItems[index].startTime
                      }
                      startTimeErrors={
                        errors &&
                        errors.scheduleItems &&
                        errors.scheduleItems[index] &&
                        errors.scheduleItems[index].startTime
                      }
                      endTimeValues={values.scheduleItems[index].endTime}
                      endTimeTouched={
                        touched &&
                        touched.scheduleItems &&
                        touched.scheduleItems[index] &&
                        touched.scheduleItems[index].endTime
                      }
                      endTimeErrors={
                        errors &&
                        errors.scheduleItems &&
                        errors.scheduleItems[index] &&
                        errors.scheduleItems[index].endTime
                      }
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RepeatedDates;
