import React from "react";
import Styles from "./InputField.module.css";

const TextQuestion = ({
  text,
  id,
  onInputChange,
  formId,
  inputType,
  section,
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value;
    onInputChange(id, value, formId, text, inputType, section, 1);
  };

  return (
    <div className={Styles.textQuestionContainer}>
      <p>{text}</p>
      <input type="text" placeholder="Answer" onChange={handleInputChange} />
    </div>
  );
};

export default TextQuestion;
