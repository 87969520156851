import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React from "react";
import { useLocation } from "react-router-dom";
import SideNavone from "../../SideNavBar";
import { styled } from "@mui/material/styles";

import { useState } from "react";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  useAddNewColumnPreferenceStaffMutation,
  useGetColumnNamesByServiceNameStaffQuery,
} from "../../Feature/api/StaffApi";
import AddStaffColumn from "../../Componenets/Customer/AddCustomerColumn";
import InsertStaffColumn from "../../Componenets/Customer/InsertCustomerColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRequest, postRequest } from "../../Componenets/request";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#6B6B6B",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#A4A4A4",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    width: "100%",
    padding: "2px 6px",
  },
}));
const EditBusiness = () => {
  const serviceName = "expert customer";
  var userId = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const location = useLocation();
  // const data = location.state;
  const [success, setSuccess] = useState(false);
  const [filterService, setFilterService] = useState([]);
  const [staffSortedData, setStaffSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { isLoading, isSuccess, data: GetStaff } = useGetStaffsQuery();
  const history = useHistory();
  const store = useSelector((state) => state?.staffReducer);
  const [columnNames, setColumnNames] = useState([]);
  const [cols, setCols] = useState([]);
  const userid = useSelector((state) => state?.Auth?.userloginsuccessyasir?.id);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const {
  //   isLoading,
  //   isSuccess,
  //   data: GetStaff,
  // } = useGetColumnNamesByServiceNameStaffQuery(userId);
  const [
    updateColumnPreferrence,
    { isLoading: isUpdateColumnPreferrenceLoading },
  ] = useAddNewColumnPreferenceStaffMutation();
  const getColumnNames = async () => {
    const res = await getRequest(
      `business_svc/pb/retrieve_list_configuration/?businessId=${selectedBusiness.id}&userId=${userid}&moduleName=Business`
    );
    setColumnNames(res?.result);
  };

  useEffect(() => {
    getColumnNames();
  }, [userid]);
  useEffect(() => {
    if (columnNames) {
      const data = columnNames.slice().sort((a, b) => a.position - b.position);

      setStaffSortedData(data);
      setLoading(false);
    }
  }, [columnNames]);

  const hanldeSaveChanges = async () => {
    const filteredColumns = staffSortedData
      .filter((el) => el.isShown)
      .map((el) => ({
        columnId: el.columnId,
        isSticky: el.isSticky,
      }));

    const payload = {
      moduleName: "Business",
      userId: userid,
      configuration: filteredColumns,
    };

    const result = await postRequest(
      `business_svc/pb/update_list_configuration/`,
      payload
    );
    if (result?.code === 0) {
      alert("Columns list updated successfully");
      history.push(`/businesses`);
    } else {
      alert(result?.message);
    }
  };

  return (
    <>
      <SideNavone>
        <div
          style={{ height: "100vh", overflow: "auto", paddingBottom: "220px" }}
        >
          <Grid className="" container rowSpacing={1.5} bgcolor="#FBFCFF">
            <Grid
              item
              xs={12}
              bgcolor="#FFF"
              border="#DCDCDC"
              px={2}
              py={1.4}
              mt={2}
            >
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Typography>Edit Column</Typography>
                <div className="col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3">
                  <button
                    // type="submit"
                    className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
                    onClick={hanldeSaveChanges}
                  >
                    {/* {isUpdateColumnPreferrenceLoading ? (
                          <CircularProgress size={25} color="white" />
                        ) : ( */}
                    <>
                      <AddBoxIcon className="addbox_icon me-1" />
                      Save Changes
                    </>
                    {/* )} */}
                  </button>
                </div>
                {/* <Button variant="contained"               className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
       onClick={() => hanldeSaveChanges()}>
                      {isUpdateColumnPreferrenceLoading ? (
                        <CircularProgress size={25} color="white" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12} mx={1.5}>
              <Box component="div">
                <Typography sx={{ color: "#000" }} mb={1.5}>
                  Added Columns
                </Typography>
                <AddStaffColumn
                  data={staffSortedData}
                  updateData={setStaffSortedData}
                  // setCols={setCols}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mx={1.5}>
              <Box component="div">
                <Typography sx={{ color: "#000" }} mb={1.5}>
                  Insert Columns
                </Typography>
                <InsertStaffColumn
                  data={staffSortedData}
                  updateData={setStaffSortedData}
                  // setCols={setCols}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SideNavone>

      {/* {state?.isActive && <Toaster state={state} />} */}
    </>
  );
};

export default EditBusiness;
