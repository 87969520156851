import React, { useState } from "react";

import BusinessMap from "./BusinessMap";
import BusinessAddress from "./BusinessAddress";
import Header from "../Header";
import { businessList } from "../../../utils/constant";
import { Formik, Form } from "formik";
import {
  businessInitialValue,
  formateData,
  formateUpdateData,
  validationSchema,
} from "./helper";
import BusinessInfo from "./BusinessInfo";
import Stepper from "./stepperButton";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addBusinessRequest,
  addBusinessSuccess,
  updateBusinessSuccess,
} from "../../../Store/Business/actions";
import SideNavone from "../../../SideNavBar";
import MenuTabList from "../CustomTabs/MenuTabList";
import { markStepAsComplete } from "../../OnBoarding/store/reducers";
import { onBoardingAxios } from "../../../Calendar/axios";
import { setProcess } from "../../OnBoarding/store/entitySlice";
const AddBusiness = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const action = location && location?.state&& location?.state?.data&&location.state?.data?.action;
  const editData = location &&location?.state&& location?.state?.data&& location.state?.data?.data;
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState(
    (editData &&
      editData.businessAddress &&
      editData.businessAddress.addressName) ||
      ""
  );
  const stepId=location &&location?.state&& location?.state?.record&& location?.state?.record?.item?.id;
  const stepRecord=location &&location?.state&& location?.state?.record&& location?.state?.record?.item;
  const processStatus=location&& location?.state&& location?.state?.record&& location?.state?.record?.processStatus;
  const step=location &&location?.state&& location?.state?.record&& location?.state?.record?.step;

  const [result, setResult] = useState([]);
  const [actionState, setActionState] = useState("new");
  const [loading, setLoading] = useState(false);
  const flag = true;
  const steps = ["Step 1", "Step 2", "Step 3"];
  var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id)
  var userEmail = useSelector((state) => state.Auth.userloginsuccessyasir.primaryEmail)
  var userInfo=useSelector((state) => state.Auth.userloginsuccessyasir)

  // const handleCompleteClick = () => {
  //   const updatedStep = {
  //     id: stepId,
  //     status: 'Complete',
  //   };
  //   dispatch(markStepAsComplete( [updatedStep] ));

  // };
  const handleCompleteClick = () => {
    const record = {
      is_complete:true
    };
    onBoardingAxios
    .patch(`/pb/onboarding-processes/${processStatus.id}/`,record)
    .then((response) => {
      if(response?.status===200){
       dispatch(setProcess(response?.data))
        // setProcess(response?.data)

      }

      // setEntity(response?.data);
    })
    .catch((error) => {
      console.error(error);
    });

  };
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  // const handleAddressChange = (newAddress) => {
  //   if (editData && editData?.id) {
  //     setAddress(editData.businessAddress.addressName);
  //   } else {
  //     setAddress(newAddress);
  //   }
  // };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const record = dispatch(addBusinessSuccess());
  const postBusiness = (values, handles) => {
    // setLoading(true);
    values["userId"]=userID
    values['userEmail']=userEmail
    values['userInfo']=userInfo

    const data = formateData(values,userInfo);
  
    dispatch(
      addBusinessRequest({
        data,
        setLoading,
        setResult,
        handleNext,
        setActionState,
        stepId,
        handleCompleteClick,
        step,
        stepRecord,
        history
      })
    ); // Dispatch the addBusinessRequest action
  };

  const updateBusiness = (values, handle) => {
    values["userId"]=userID
    values["userEmail"]=userEmail
    values['userInfo']=userInfo
    let id;
    if (
      actionState === "edit" ||
      (action && action === "edit" && editData && editData.id)
    ) {
      id = editData ? editData.id : result.id;
    }
    const data = formateUpdateData(values);
    const jsonData = JSON.stringify(data);
    dispatch(updateBusinessSuccess({ data, setLoading, id, history }));
  };
  const handleSubmit = (values, handles, result) => {
    values["userId"]=userID
    values["userEmail"]=userEmail

    if (
      actionState === "edit" ||
      (action && action === "edit" && editData && editData.id)
    ) {
      updateBusiness(values, handles, result);
    } else {
      postBusiness(values, handles);
    }
  };
  return (
    <>
    <SideNavone>
      <div>
        <Header
          flag={flag}
          heading={
            action && action === "edit"
              ? "Update Business"
              : businessList.CREATE_BUSINESS
          }
        />
      </div>
      {/* <MenuTabList type="addBussiness" /> */}

      <div className="ms-md-2 me-md-3 ps-md-5 pe-md-5 pt-4 hide_scrollbar " style={{
        height: "100vh",
        overflow: "auto",
        paddingBottom: "180px"
      }}>
        <div className="w-100">
          <Formik
            initialValues={businessInitialValue(editData, action)}
            validationSchema={validationSchema}
            onSubmit={(values, handles) => {
              handleSubmit(values, handles);
            }}
          >
            {(options) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {activeStep === 0 && (
                    <div>
                      <BusinessInfo
                        loading={loading}
                        onSubmit={handleSubmit}
                        action={action}
                        {...options}
                      />
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <BusinessMap
                        loading={loading}
                        onSubmit={handleSubmit}
                        address={address}
                        action={action}
                        editData={editData}
                        result={result}
                        setAddress={setAddress}
                        {...options}
                      />
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div>
                      <BusinessAddress
                        loading={loading}
                        action={action}
                        editData={editData}
                        onSubmit={handleSubmit}
                        address={address}
                        {...options}
                      />
                    </div>
                  )}
                  <div>
                    <Stepper
                      loading={loading}
                      activeStep={activeStep}
                      steps={steps}
                      actions={action}
                      businessId={editData}
                      action={actionState}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      {...options}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
   
      </SideNavone>
       </>
  );
};

export default AddBusiness;
