import React, { useState, useEffect } from "react";
import StepCount from "./../Stepper/Stepper";
import QuestionSection from "../QuestionSection/QuestionSection";
import { getQuestions, uploadAnswers, uploadMedia } from "../../axios/axios";
import styles from "./QuestionPage.module.css";
import { Button } from "@mui/material";

const QuestionPage = () => {
  const [data, setData] = useState();
  const [uploadedData, setUploadedData] = useState();

  const [section, setSection] = useState(1);
  const [loading, setLoading] = useState(false);

  const apiCall = async (section) => {
    try {
      const res = await getQuestions(section);
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    apiCall(section);
  }, [section]);

  const handleClick = () => {
    let response = uploadAnswers(inputValues);
    setSection((prevStep) => prevStep + 1);
    setInputValues([]);
  };

  const [inputValues, setInputValues] = useState([]);

  const handleInputChange = (
    questionId,
    answer,
    formId,
    question,
    questionType,
    sectionNum,
    userId
  ) => {
    const inputIndex = inputValues.findIndex(
      (input) => input?.questionId === questionId
    );

    if (inputIndex !== -1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues[inputIndex] = {
        questionId,
        answer,
        formId,
        question,
        questionType,
        sectionNum,
        userId,
      };
      setInputValues(updatedInputValues);
    } else {
      setInputValues((prevInputValues) => [
        ...prevInputValues,
        {
          questionId,
          answer,
          formId,
          question,
          questionType,
          sectionNum,
          userId,
        },
      ]);
    }
  };

  const handleUploadMedia = async (id, media, formId, text, inputType) => {
    setLoading(true);

    let response = await uploadMedia(id, media);
    if (response?.error === false) {
      setLoading(false);
      setUploadedData(response);
      handleInputChange(
        id,
        response?.result?.answer,
        formId,
        text,
        inputType,
        section,
        1
      );
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <StepCount
        section={section}
        handleClick={handleClick}
        steps={data?.result?.totalSections}
      />
      <QuestionSection
        questionsData={data?.result?.currentSection?.questionList}
        onInputChange={handleInputChange}
        formId={data?.result?.id}
        section={section}
        handleUploadMedia={handleUploadMedia}
        uploadedData={uploadedData}
      />
      <div className={styles.buttonContainer}>
        {data?.currentSection === data?.totalSection ? (
          loading ? (
            <Button variant="contained">
              Loading
            </Button>
          ) : (
            <Button
            variant="contained"
              onClick={handleClick}
            >
              Save
            </Button>
          )
        ) : loading ? (
          <Button variant="contained">
            Loading
          </Button>
        ) : (
          <Button
          variant="contained"
            onClick={handleClick}
          >
            Save & Continue
          </Button>
        )}
      </div>
    </>
  );
};

export default QuestionPage;
