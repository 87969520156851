import { createSlice } from "@reduxjs/toolkit";
import { getAllCustomer, getAllCustomerDynamicForm, getAllStaff, searchCustomerList, searchStaffList } from "./staffActions";
const initialState = {
  allStaff: [],
  allCustomer: [],
  isLoading: false,
  miniLoading: false,
  filterText: "",
  filterTextCustomer:"",
  firstNameCustomer:"",
  lastName:"",
  email:"",
  mobileNumber:"",
  errorMessage: "",
  totalcount: 0,
  count: 0,
  staffSearchList: "",
  customerSearchList: "",
  customerDynamicList:""
};

const CreateSlice = createSlice({
    name: "staffs",
    initialState,
    reducers: {
      loadingAction: (state) => {
        state.isLoading = !state?.isLoading;
      },
      miniLoadingAction: (state) => {
        state.miniLoading = !state?.isLoading;
      },
      searchText: (state, action) => {

        state.filterText = action.payload ? action.payload : "";
      },
      searchTextCustomer: (state, action) => {
        state.filterTextCustomer = action.payload ? action.payload : "";
      },searchTextFirstName: (state, action) => {
        state.firstNameCustomer = action.payload ? action.payload : "";
      },searchTextLastName: (state, action) => {
        state.lastName = action.payload ? action.payload : "";
      },searchTextEmail: (state, action) => {
        state.email = action.payload ? action.payload : "";
      },searchTextnumber: (state, action) => {
        state.mobileNumber = action.payload ? action.payload : "";
      },
    },
    extraReducers(builder) {
      // Get all services
      builder.addCase(getAllStaff.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getAllStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allStaff = action?.payload?.staff
          ? [...action?.payload?.staff]
          : [];
        state.totalcount = action?.payload?.totalcount;
        state.allStaff = action?.payload?.staff
          ? [...action?.payload?.staff]
          : [];
      });
      builder.addCase(getAllStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload;
        state.allStaff = [];
      });
  
      // Search customer list
      builder.addCase(searchCustomerList.fulfilled, (state, action) => {
        state.customerSearchList = action?.payload?.customerList
          ? [...action?.payload?.customerList]
          : [];
      });
  
      // Search staff list
      builder.addCase(searchStaffList.fulfilled, (state, action) => {
        state.staffSearchList = action?.payload?.staffList
          ? [...action?.payload?.staffList]
          : [];
      });
  
      // Get all customers
      builder.addCase(getAllCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCustomer = action.payload?.customer
          ? [...action.payload.customer]
          : [];
        state.count = action?.payload?.totalcount;
      });
      builder.addCase(getAllCustomerDynamicForm.fulfilled, (state, action) => {
        state.customerDynamicList = action?.payload?.form
          ? [...action?.payload?.form]
          : [];
      });
    },
  });
  

  

export const { loadingAction, miniLoadingAction, searchText,searchTextCustomer,searchTextFirstName,searchTextLastName,searchTextEmail,searchTextnumber } =
  CreateSlice.actions;
export const staffReducer = CreateSlice.reducer;
