import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { putRequest } from '../../../request';
import { base_url } from '../../../base_url';
import { useSelector } from 'react-redux';

const DetailFormMobile = () => {
    const [serviceName, setServiceName] = useState('');
    const [serviceSKU, setServiceSKU] = useState('');
    const [serviceURL, setServiceURL] = useState('');
    const [serviceDuration, setServiceDuration] = useState('');
    const [inHouseSKU, setInHouseSKU] = useState('inhouse');
    const [inClinicSKU, setInClinicSKU] = useState('inclinic');
    const [inOnlineSKU, setInOnlineSKU] = useState('online');
    const [isInHouse, setIsInHouse] = useState(false);
    const [isClinical, setIsClinical] = useState(false);
    const [isOnline, setIsOnline] = useState(false);

    const { id } = useParams();
    const history = useHistory();
    const store = useSelector(state => state?.servicesReducer);

    const updateData = async () => {
        const data = {
            id: id,
            sku: serviceSKU,
            name: serviceName,
            url: serviceURL,
            duration: serviceDuration,
            isInHouseSKU: inHouseSKU,
            isInHouse: isInHouse,
            isInClinicSKU: inClinicSKU,
            isInClinic: isClinical,
            isOnlineSKU: inOnlineSKU,
            isOnline: isOnline,
            short_Desc: 'none',
            long_Desc: 'none',
            consentForm: 'string',
            parentId: 0,
            bookedSeats: 0,
            availableSeats: 0,
            countryId: 188,
            maxSesssion: 0,
            hasPrice: true,
            isAddOn: true,
            hasSession: true,
            hasAttribute: true,
            hasProductCompulsory: true,
            isRequiredDOB: true,
            isRequiredGenderPreference: true,
            hasPackage: true,
            hasProductOptional: true,
            isActive: store?.serviceToUpdate[0]?.isActive
        }

        const res = await putRequest(`${base_url}/Service/UpdateService`, data);
        if (res?.message === "succeeded") {
            alert('Service Updated Successfully');
            history.push('/all-services');
        }
    }

    useEffect(() => {
        if (store?.serviceToUpdate && store?.serviceToUpdate.length > 0) {
            setServiceName(store?.serviceToUpdate[0]?.serviceName);
            setServiceSKU(store?.serviceToUpdate[0]?.serviceSKU);
            setServiceURL(store?.serviceToUpdate[0]?.serviceUrl);
            setServiceDuration(store?.serviceToUpdate[0]?.duration);
            setInHouseSKU(store?.serviceToUpdate[0]?.isInHouseSKU);
            setInClinicSKU(store?.serviceToUpdate[0]?.isInClinicSKU);
            setInOnlineSKU(store?.serviceToUpdate[0]?.isOnlineSKU);
            setIsInHouse(store?.serviceToUpdate[0]?.isInHouse);
            setIsClinical(store?.serviceToUpdate[0]?.isInClinic);
            setIsOnline(store?.serviceToUpdate[0]?.isOnline);
        }
    }, [store?.serviceToUpdate.length > 0])

    return (
        <>
            <div className='container detail_form hide_scrollbar mobile_display'>
                <form className='create_form'>
                    <div className="row create_form_row">
                        <div className="col-12 g-0">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service Name</label>
                                <input type="text" className="form-control" placeholder="Enter Service Name" name="service-name"
                                    onChange={(e) => setServiceName(e.target.value)}
                                    value={serviceName}
                                />
                            </div>
                        </div>
                        <div className="col-12 g-0 mt-3">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service SKU</label>
                                <input type="text" className="form-control" placeholder="Enter service SKU" name="sku"
                                    onChange={(e) => setServiceSKU(e.target.value)}
                                    value={serviceSKU}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row">
                        <div className="col-12 g-0 mt-3">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service URL</label>
                                <div className="input-group">
                                    <span className="input-group-text span_input_url pe-3">https://abc/service/</span>
                                    <input type="text" className="form-control" placeholder="service url..."
                                        onChange={(e) => setServiceURL('http://plexaar/services/' + e.target.value)}
                                        value={serviceURL}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 g-0 mt-3">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service URL</label>
                                <div className="input-group">
                                    <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                        onChange={(e) => setServiceDuration(e.target.value)}
                                        value={serviceDuration}
                                    >
                                        <option value="1">1 Minute</option>
                                        <option value="2">2 Minutes</option>
                                        <option value="3">3 Minutes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 rounded">
                        <div className='col-12'>
                            <div className="form-check ps-0">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    onChange={(e) => setIsInHouse(e.target.checked)}
                                    checked={isInHouse ?? false}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault">
                                    Is Inhouse
                                </label>
                            </div>
                            {/* <input type='text' className='form-control w-75 mt-2' placeholder='Enter Inhouse SKU'
                                onChange={(e) => setInHouseSKU(e.target.value)}
                                value={inHouseSKU}
                                disabled
                            /> */}
                        </div>

                        <div className='col-12 mt-2'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    onChange={(e) => setIsOnline(e.target.checked)}
                                    checked={isOnline ?? false}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault">
                                    Is Online
                                </label>
                            </div>
                            {/* <input type='text' className='form-control w-75 mt-2' placeholder='Enter Online SKU'
                                onChange={(e) => setInOnlineSKU(e.target.value)}
                                value={inOnlineSKU}
                                disabled
                            /> */}
                        </div>

                        <div className='col-12 mt-2'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    onChange={(e) => setIsClinical(e.target.checked)}
                                    checked={isClinical ?? false}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault">
                                    Is Clinincal
                                </label>
                            </div>
                            {/* <input type='text' className='form-control w-75 mt-2' placeholder='Enter Clinical SKU'
                                onChange={(e) => setInClinicSKU(e.target.value)}
                                value={inClinicSKU}
                                disabled
                            /> */}
                        </div>
                    </div>
                </form>

                <div className='row mt-4'>
                    <div className='col-12 d-flex justify-content-center'>
                        <button type="button" className="btn btn btn-sm text-dark cancel_btn px-5 me-3"
                            onClick={() => history.push('/all-services')}
                        >Cancel</button>
                        <button type="button" className="btn btn btn-sm theme_btn_color px-5"
                            onClick={updateData}
                        >{id ? 'Update' : 'Create'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailFormMobile