import AllCandidates from "./events/all-candidates";
import AllEvents from "./events/all-events";
import CreateSlot from "./create-slot";
import EventDetails from "./events/event-details";
import CandidateDetails from "./events/candidate-details";
import BookedEventList from "./booked-events/booked-events-list";
import BookedEventDetail from "./booked-events/booked-event-detail";

export const meetSpaceRoutes = [
  {
    path: "/all-candidates",
    component: AllCandidates,
    ispublic: false,
    exact: true,
  },
  {
    path: "/all-events",
    component: AllEvents,
    ispublic: false,
    exact: true,
  },
  {
    path: "/create-slot",
    component: CreateSlot,
    ispublic: false,
    exact: true,
  },
  {
    path: "/event-details/:eid?",
    component: EventDetails,
    ispublic: false,
    exact: true,
  },
  {
    path: "/candidate-details",
    component: CandidateDetails,
    ispublic: false,
    exact: true,
  },
  {
    path: "/booked-events",
    component: BookedEventList,
    ispublic: false,
    exact: true,
  },
  {
    path: "/booked-event-detail",
    component: BookedEventDetail,
    ispublic: false,
    exact: true,
  },
];
