// import React, { createContext, useCallback, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Avatar, SvgIcon, Tooltip } from "@mui/material";
// import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
// import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// import { bookingAxios, salesOrderAxios, slotAxios } from "../../axios";
// import CalendarApp from "../components/calenderWidgetSlot";
// import EventDialog from "../components/EventDialog";
// import EventUpdateDialog from "../components/EventUpdateDialog";
// import moment from "moment";
// import { momentLocalizer } from "react-big-calendar";
// import { handleOpenAlert, openToast } from "../store/alertSlice";
// import { getCalendarData, getSlotsData } from "../store/calendarSlice";
// import { openmodel } from "../store/modelSlice";
// import { useHistory } from "react-router-dom";
// import "../../assets/scheduler/css/calendar.css";
// import {
//   useLocation,
//   useParams,
// } from "react-router-dom/cjs/react-router-dom.min";
// import { customSnackBar } from "../../../utils";
// import { ClipLoader } from "react-spinners";
// import CalendarSkeleton from "../components/skeleton";
// import instance from "../../../utils/utils";

// const localizer = momentLocalizer(moment);
// export const LabelContext = createContext();

// export default function Updatebooking({ setSelectSlotFlag }) {
//   let bookingData = useSelector((state) => state.bookingReducer.bookingData);
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   const selectedBusiness = useSelector(
//     (state) => state.Business?.selectedBusiness
//   );
//   const location = useLocation();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const bookingDetails = location && location?.state;
//   const history = useHistory();
//   const {
//     startTime,
//     endTime,
//     resources,
//     combinedData,
//     bgevents: allbgevent,
//   } = useSelector((state) => state.calendarReducer);
//   const {
//     responseStatus,
//     updateStatus,
//     deleteStatus,
//     successMessage,
//     updateMessage,
//     deleteMessage,
//     isLoadingPost,
//     isLoadingUpdsate,
//     isLoadingDelete,
//   } = useSelector((state) => state.modelReducer);
//   let dateApi = useSelector((state) => state.counterReducer.dateApi);
//   // let allevent = useSelector((state) => state.calendarReducer.events);
//   const [eventsData, setEventDate] = useState([]);
//   const [modelType, setModelTypeg] = useState("");
//   const [clinicId, setClinicId] = useState("9106");
//   const [loading, setLoading] = useState(false);

//   var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
//   const { customerData, isExpert, isPrivate } = useSelector(
//     (state) => state.bookingReducer
//   );
//   const views = {
//     DAY: "day",
//     WEEK: "week",
//     MONTH: "month",
//     AGENDA: "agenda",
//   };
//   const allViews = Object.keys(views).map((k) => views[k]);

//   //First Api Call on Start After Each time any dependency Change Api Call
//   useEffect(() => {
//     if (isLoadingPost === false) {
//       if (responseStatus === "success") {
//         dispatch(
//           handleOpenAlert({ message: successMessage, status: responseStatus })
//         );
//       }
//       if (responseStatus === "error") {
//         dispatch(
//           handleOpenAlert({ message: successMessage, status: responseStatus })
//         );
//       }
//     }
//   }, [isLoadingPost]);

//   useEffect(() => {
//     if (isLoadingUpdsate === false) {
//       if (updateStatus === "success") {
//         dispatch(
//           handleOpenAlert({ message: updateMessage, status: updateStatus })
//         );
//       }
//       if (updateStatus === "error") {
//         dispatch(
//           handleOpenAlert({ message: updateMessage, status: updateStatus })
//         );
//       }
//     }
//   }, [isLoadingUpdsate]);
//   useEffect(() => {
//     if (isLoadingDelete === false) {
//       if (deleteStatus === "success") {
//         dispatch(
//           handleOpenAlert({ message: deleteMessage, status: deleteStatus })
//         );
//       }
//       if (deleteStatus === "error") {
//         dispatch(
//           handleOpenAlert({ message: deleteMessage, status: deleteStatus })
//         );
//       }
//     }
//   }, [isLoadingDelete]);
//   function calculateDuration(startTime, endTime) {
//     // Convert start time to Date object
//     const startDate = new Date(`1970-01-01T${startTime}`);

//     // Convert end time to Date object
//     const endDate = new Date(`1970-01-01T${endTime}`);

//     // Calculate the duration in milliseconds
//     const duration = endDate - startDate;

//     // Convert duration to minutes
//     const durationInMinutes = Math.floor(duration / 1000 / 60);

//     return durationInMinutes;
//   }
//   const extractResourceIds = useCallback(() => {
//     const extractedIds = [];
//     resources.forEach((obj) => {
//       const currentId = obj.resourceId;
//       extractedIds.push(currentId);
//     });
//     return extractedIds;
//   }, [resources]);
//   useEffect(() => {
//     var data = {};
//     const extractedIds = extractResourceIds();
//     console.log(
//       bookingData.dateJson.timeFrom,
//       "bookingDatabookingDatabookingData"
//     );
//     if (bookingData?.bookingDuration !== null) {
//       data = {
//         providerId: extractedIds,
//         date: dateApi,
//         // slotDuration: parseInt(bookingData?.bookingDuration),
//         sku:
//           bookingData?.attributes && bookingData?.attributes.length > 0
//             ? bookingData?.attributes[0].attributeSku
//             : bookingData?.serviceSKU,
//         hasBreak: false,
//         page: 1,
//         pageSize: 100,
//         timeZone: "Europe/London",
//         businessId: selectedBusiness?.id,
        // editing: {
        //   timeFrom: bookingData.appointments[0]?.timeFrom,
        //   timeTo: bookingData.appointments[0]?.timeTo,
        //   date: bookingData.appointments[0]?.bookingDate,
        // },
//       };
//     } else {
//       data = {
//         providerId: extractedIds,
//         date: dateApi,
//         // slotDuration: parseInt(
//         //   calculateDuration(bookingData?.timeFrom, bookingData?.timeTo)
//         // ),
//         sku:
//           bookingData?.attributes && bookingData?.attributes.length > 0
//             ? bookingData?.attributes[0].attributeSku
//             : bookingData?.serviceSKU,
//         // sku:bookingData?.serviceSKU,
//         hasBreak: false,
//         page: 1,
//         pageSize: 100,
//         timeZone: "Europe/London",
//         businessId: selectedBusiness?.id,
        // editing: {
        //   timeFrom: bookingData.appointments[0]?.timeFrom,
        //   timeTo: bookingData.appointments[0]?.timeTo,
        //   date: bookingData.appointments[0]?.bookingDate,
        // },
//       };
//     }
//     const data2 = {
//       id: id,
//     };

//     const record = {
//       providerId: extractedIds,
//       bookingDateStart: dateApi,
//       is_business: true,
//       bookingDateEnd: dateApi,
//       timeZone: "Europe/London",
//       businessId: selectedBusiness?.id,
//     };

//     dispatch(getCalendarData(record));
//     dispatch(getSlotsData({ data, setLoading }));
//   }, [dateApi, clinicId]);
//   function subtractOneHour(timeString) {
//     // Create a Date object from the input time string
//     const initialTime = new Date(`2023-09-05T${timeString}:00`);

//     // Subtract 1 hour (3600 seconds) from the initial time
//     const newTime = new Date(initialTime.getTime() - 3600 * 1000); // 3600 seconds * 1000 milliseconds

//     // Format the new time as a string (HH:mm)
//     const formattedTime = newTime.toLocaleTimeString("en-US", {
//       hour12: false,
//       hour: "2-digit",
//       minute: "2-digit",
//     });

//     return formattedTime;
//   }

//   //Onclick Event Function Call
//   async function handleEvents(events) {
//     const currentDate = new Date();
//     const datetime = new Date(events.start);
//     const modifiedEvents = {
//       ...events,
//       bookingDate: `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
//         .toString()
//         .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`,
//     };

//     // Now, modifiedEvents has the additional property bookingDate
//     // You can use modifiedEvents instead of events in your further logic

//     // Example: Logging the modifiedEvents
//     // events.bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
//     //   .toString()
//     //   .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
//     if (datetime < currentDate) {
//       customSnackBar("Slot is in past");
//       // dispatch(
//       //   openToast({
//       //     message: "Slot is in past",
//       //     status: "error",
//       //   })
//       // );
//     } else if (events.title === "Available Slots") {
//       // const data = {
//       //   bookingId: bookingData?.bookingId,
//       //   plexaarSalesOrderId: bookingData?.plexaarSalesOrderId,
//       //   bookingDate: events.bookingDate,
//       //   timeFrom: `${events.timeFrom}:00`,
//       //   timeTo: `${events.timeTo}:00`,
//       //   timeZone:"Europe/London",
//       // };
//       // await bookingAxios
//       //   .patch(`/pb/booking/`, data)
//       const data = {
//         businessId: bookingData?.businessId,
//         bookingId: bookingData?.plexaarBookingId,
//         plexaarAppointmentId: bookingData?.appointments[0]?.plexaarAppointmentId
//           ? bookingData?.appointments[0]?.plexaarAppointmentId
//           : 0,
//         expertAppointmentId: bookingData?.appointments[0]?.expertAppointmentId
//           ? bookingData?.appointments[0]?.expertAppointmentId
//           : 0,
//         // sku:bookingData?.serviceSKU?bookingData?.serviceSKU:"",
//         sku:
//           bookingData?.attributes && bookingData?.attributes.length > 0
//             ? bookingData?.attributes[0].attributeSku
//             : bookingData?.serviceSKU,
//         oldDate: {
//           date: bookingData?.appointments[0]?.bookingDate,
//           timeFrom: `${bookingData.appointments[0]?.timeFrom}`,
//           timeTo: `${bookingData.appointments[0]?.timeTo}`,
//         },
//         newDate: {
//           date: modifiedEvents.bookingDate,
//           timeFrom: `${events.timeFrom}:00`,
//           timeTo: `${events.timeTo}:00`,
//         },
//         isEdit: true,
//         salesOrderId: bookingData?.plexaarSalesOrderId,
//         // bookingDate: events.bookingDate,
//         // timeFrom: `${events.timeFrom}:00`,
//         // timeTo: `${events.timeTo}:00`,
//         isExpert: bookingData?.isExpert,
//         providerId: bookingData?.providerId,
//         providerIdNew: events.resourceId ?? 0,

//         isCancel: false,
//         isOpen: false,
//         modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,

        // customerId: bookingData.isExpert
        //   ? bookingData?.customerId
        //   : bookingData?.plexaarcustomerId,
//         timeZone: "Europe/London",
//       };

//       await instance
//         .post(`/provideravailability_svc/pb/edit/booking-slot`, data)
//         .then((res) => {
//           customSnackBar("Booking Updated Successfully");
//           // dispatch(
//           //   openToast({
//           //     message: "Booking Updated Successfully",
//           //     status: "success",
//           //   })
//           // );
//           window.window.scrollTo({
//             top: 0,
//             behavior: "smooth",
//           });
//           history.push(`/calendar`);
//         })
//         .catch((err) => {
//           customSnackBar(err.message);
//           // dispatch(openToast({ message: err.message, status: "error" }));
//         });
//     } else {
//       customSnackBar("Slot is already booked");
//       // dispatch(
//       //   openToast({
//       //     message: "Slot is already booked",
//       //     status: "warning",
//       //   })
//       // );
//     }
//   }

//   //Clinic Change Functions
//   const handleChangeClinic = (id) => {
//     setClinicId(id);
//   };
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       var time = moment().format("LT");
//       const existing = document.getElementsByClassName("my-div-time")[0];
//       if (existing) {
//         existing.innerHTML = `${time}`;
//       }
//     }, 10000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, []);

//   useEffect(() => {
//     window.setTimeout(() => {
//       handleCurrentIndicator();
//     }, 220);
//   }, []);
//   const handleCurrentIndicator = () => {
//     var time = moment().format("LT");
//     const existingDiv = document?.getElementsByClassName(
//       "rbc-current-time-indicator"
//     )[0];
//     if (existingDiv) {
//       const newDiv = document.createElement("div");
//       newDiv.className =
//         "my-div-time -mt-2 text-xs -ml-20 px-2 border-2 w-fit border-red-500 rounded-full";
//       newDiv.textContent = `${time}`;
//       existingDiv.appendChild(newDiv);
//     }
//   };
//   const handleBackButtonFunction = () => {
//     const toggle = {
//       flag: false,
//     };
//     history.push(`/scheduler/booking_details/${selectedBusiness?.id}`, toggle);
//   };
//   return (
//     <div className="mx-4 mt-2" id="style-2">
//       <div className="row justify-content-center align-items-center">
//         {!isExpanded ? (
//           <div
//             className="py-1 px-4 mb-2"
//             style={{
//               borderRadius: "8px",
//               border: "0.6px solid #DCDCDC",
//               background: "#FFF",
//               // marginLeft: "40px",
//             }}
//           >
//             <div>
//               <div className="d-flex justify-content-between align-items-center my-2">
//                 <Tooltip title="Back">
//                   <div
//                     className="pt-2"
//                     onClick={handleBackButtonFunction}
//                     role="button"
//                   >
//                     <i id="icon_left_color" className="fas fa-chevron-left"></i>
//                   </div>
//                 </Tooltip>
//                 <div className="d-flex align-items-center">
//                   <Avatar
//                     alt="Remy Sharp"
//                     src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                   <p className="m-0 mx-2 selected_servicename">
//                     {bookingData.serviceName}
//                   </p>
//                 </div>
//                 <div className="d-flex justify-content-around align-items-center">
//                   <div className="d-flex justify-content-end align-items-center">
//                     <SvgIcon
//                       component={HourglassEmptyOutlinedIcon}
//                       style={{ color: "#bfbfbf", fontSize: "16px" }}
//                     />
//                     <span
//                       className="ms-1"
//                       style={{
//                         color: "#4D4D4D",
//                         textAlign: "center",
//                         fontFamily: "Poppins",
//                         fontSize: "12px",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                       }}
//                     >
//                       {bookingData.bookingDuration !== null
//                         ? bookingData.bookingDuration
//                         : 0 + " Min "}

//                       {/* {bookingData.bookingDuration + " Min "} */}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="d-flex align-items-center justify-content-center">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="16"
//                     height="16"
//                     viewBox="0 0 22 22"
//                     fill="none"
//                   >
//                     <path
//                       d="M1.83398 7.79626H20.1673M5.50065 15.1296H7.33398M9.62565 15.1296H13.2923"
//                       stroke="#BDBDBD"
//                       strokeWidth="1.5"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                     <path
//                       d="M5.90398 3.21289H16.0882C19.3515 3.21289 20.1673 4.01956 20.1673 7.23706V14.7629C20.1673 17.9804 19.3515 18.7871 16.0973 18.7871H5.90398C2.64982 18.7962 1.83398 17.9896 1.83398 14.7721V7.23706C1.83398 4.01956 2.64982 3.21289 5.90398 3.21289Z"
//                       stroke="#BDBDBD"
//                       strokeWidth="1.5"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>
//                   <span
//                     className="ms-1"
//                     style={{
//                       color: "#4D4D4D",
//                       textAlign: "center",
//                       fontFamily: "Poppins",
//                       fontSize: "12px",
//                       fontStyle: "normal",
//                       fontWeight: 500,
//                     }}
//                   >
//                     {bookingData.currency === null
//                       ? "£"
//                       : bookingData.currency === "USD"
//                       ? "$"
//                       : "£"}
//                     {bookingData.totalAmount}
//                   </span>
//                 </div>
//                 <div className="d-flex justify-content-end align-items-center">
//                   <span
//                     style={{
//                       color: "#616161",
//                       fontFamily: "Poppins",
//                       fontSize: "10px",
//                       fontStyle: "normal",
//                       fontWeight: 400,
//                     }}
//                   >
//                     Package quantity
//                   </span>{" "}
//                   <div
//                     className="ms-3"
//                     style={{
//                       borderRadius: "8px",
//                       backgroundColor: "#F6F6F6",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       padding: "0 10px",
//                     }}
//                   >
//                     <div
//                       className="mx-2"
//                       style={{
//                         color: "#4D4D4D",
//                         textAlign: "center",
//                         fontFamily: "Poppins",
//                         fontSize: "10px",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                       }}
//                     >
//                       {bookingData?.quantity}
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   onClick={() => setIsExpanded(!isExpanded)}
//                   role="button"
//                   className="d-flex justify-content-end align-items-center"
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="15"
//                     height="8"
//                     viewBox="0 0 18 10"
//                     fill="none"
//                   >
//                     <path
//                       d="M2.35352 1.62181L9.06569 8.33398L15.7779 1.62181"
//                       stroke="#FFD705"
//                       strokeWidth="3"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div
//             className="py-1 px-4 mb-2"
//             style={{
//               borderRadius: "8px",
//               border: "0.6px solid #DCDCDC",
//               background: "#FFF",
//               // marginLeft: "40px",
//             }}
//           >
//             <div
//               style={{
//                 color: "#AAA",
//                 fontFamily: "Roboto",
//                 fontSize: "12px",
//                 fontStyle: "normal",
//                 fontWeight: 400,
//               }}
//             >
//               Service Details
//               <div className="d-flex justify-content-between align-items-center my-2">
//                 <Tooltip title="Back">
//                   <div
//                     className="pt-2"
//                     onClick={handleBackButtonFunction}
//                     role="button"
//                   >
//                     <i id="icon_left_color" className="fas fa-chevron-left"></i>
//                   </div>
//                 </Tooltip>
//                 <div className="d-flex align-items-center">
//                   <Avatar
//                     alt="Remy Sharp"
//                     src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                   <p className="m-0 mx-2 selected_servicename">
//                     {bookingData.serviceName}
//                   </p>
//                 </div>
//                 <div className="d-flex justify-content-around align-items-center">
//                   <div className="d-flex justify-content-end align-items-center">
//                     <SvgIcon
//                       component={HourglassEmptyOutlinedIcon}
//                       style={{ color: "#bfbfbf", fontSize: "16px" }}
//                     />
//                     <span
//                       className="ms-1"
//                       style={{
//                         color: "#4D4D4D",
//                         textAlign: "center",
//                         fontFamily: "Poppins",
//                         fontSize: "12px",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                       }}
//                     >
//                       {bookingData.bookingDuration !== null
//                         ? bookingData.bookingDuration
//                         : 0 + " Min "}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="d-flex align-items-center justify-content-center">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="16"
//                     height="16"
//                     viewBox="0 0 22 22"
//                     fill="none"
//                   >
//                     <path
//                       d="M1.83398 7.79626H20.1673M5.50065 15.1296H7.33398M9.62565 15.1296H13.2923"
//                       stroke="#BDBDBD"
//                       strokeWidth="1.5"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                     <path
//                       d="M5.90398 3.21289H16.0882C19.3515 3.21289 20.1673 4.01956 20.1673 7.23706V14.7629C20.1673 17.9804 19.3515 18.7871 16.0973 18.7871H5.90398C2.64982 18.7962 1.83398 17.9896 1.83398 14.7721V7.23706C1.83398 4.01956 2.64982 3.21289 5.90398 3.21289Z"
//                       stroke="#BDBDBD"
//                       strokeWidth="1.5"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>
//                   <span
//                     className="ms-1"
//                     style={{
//                       color: "#4D4D4D",
//                       textAlign: "center",
//                       fontFamily: "Poppins",
//                       fontSize: "12px",
//                       fontStyle: "normal",
//                       fontWeight: 500,
//                     }}
//                   >
//                     {bookingData.currency === null
//                       ? "£"
//                       : bookingData.currency === "USD"
//                       ? "$"
//                       : "£"}
//                     {bookingData.totalAmount}
//                   </span>
//                 </div>
//                 <div className="d-flex justify-content-end align-items-center">
//                   <span
//                     style={{
//                       color: "#616161",
//                       fontFamily: "Poppins",
//                       fontSize: "10px",
//                       fontStyle: "normal",
//                       fontWeight: 400,
//                     }}
//                   >
//                     Package quantity
//                   </span>{" "}
//                   <div
//                     className="ms-3"
//                     style={{
//                       borderRadius: "8px",
//                       backgroundColor: "#F6F6F6",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       padding: "0 10px",
//                     }}
//                   >
//                     <div
//                       className="mx-2"
//                       style={{
//                         color: "#4D4D4D",
//                         textAlign: "center",
//                         fontFamily: "Poppins",
//                         fontSize: "10px",
//                         fontStyle: "normal",
//                         fontWeight: 500,
//                       }}
//                     >
//                       {bookingData?.quantity}
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   onClick={() => setIsExpanded(!isExpanded)}
//                   role="button"
//                   className="d-flex justify-content-end align-items-center my-1"
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="15"
//                     height="8"
//                     viewBox="0 0 18 10"
//                     fill="none"
//                   >
//                     <path
//                       d="M15.752 8.37819L9.03978 1.66602L2.32761 8.37819"
//                       stroke="#FFD705"
//                       strokeWidth="3"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>
//                 </div>
//               </div>
//               <hr className="mb-1" />
//               Customer Details
//               <div className="d-flex justify-content-between align-items-center my-2">
//                 <div className="d-flex align-items-center">
//                   <Avatar
//                     alt="Remy Sharp"
//                     src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                   <p className="m-0 mx-2 selected_servicename">
//                     {bookingData.customerFirstName}{" "}
//                     {bookingData.customerLastName}
//                   </p>
//                 </div>
//               </div>
//               <hr className="mb-1" />
//               Provider Details
//               <div className="d-flex justify-content-between align-items-center my-2">
//                 <div className="d-flex align-items-center">
//                   <Avatar
//                     alt="Remy Sharp"
//                     src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                   <p className="m-0 mx-2 selected_servicename">
//                     {bookingData.providerName}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}{" "}
//         {loading ? (
//           <CalendarSkeleton />
//         ) : (
//           <div
//             className="w-100 rbc-time-content"
//             style={{
//               boxShadow:
//                 "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
//               borderRadius: "10px",
//               height: !isExpanded ? "82vh" : "56vh",
//               overflow: "auto",
//             }}
//           >
//             <div className="col-12 overflow_calender">
//               <CalendarApp
//                 localizer={localizer}
//                 allevent={combinedData}
//                 allbgevent={allbgevent}
//                 resources={resources}
//                 views={allViews}
//                 startTime={startTime}
//                 endTime={endTime}
//                 //   draghandleEvents={draghandleEvents}
//                 calenderEvents={handleEvents}
//                 handleSlots={() => {}}
//                 //   handleFilter={handleFilter}
//               />

//               {/* <EventDialog
//                 modelType={modelType}
//                 clinicId={clinicId}
//                 handleChangeClinic={handleChangeClinic}
//               />
//               <EventUpdateDialog /> */}
//             </div>
//           </div>
//         )}
//         {/* <Button color="primary" className="col-4 py-3 my-5" variant="contained">
//           Submit
//         </Button> */}
//       </div>
//     </div>
//   );
// }

// // <div
// //          className="my-3 w-full px-3 py-2 d-flex justify-content-center align-items-center"
// //          style={{
// //            boxShadow:
// //              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
// //            borderRadius: "10px",
// //          }}
// //        >
// //          <div className="w-100">
// //            <div className="w-100 d-flex justify-content-between align-items-center px-2">
// //              <p className="m-0 selected_service">Service Details</p>
// //              {/* <SvgIcon component={AddIcon} style={{ borderRadius:"3px", color:"#ffffff", background:'#5599FF'}} onClick={e=>{ e.preventDefault();handleAddMore()}}/> */}
// //            </div>
// //            <div className="w-100 px-2 py-1">
// //              <div className="w-100 row justify-content-center align-items-center my-1">
// //                <div className="col-7 d-flex align-items-center">
// //                  <Avatar
// //                    alt="Remy Sharp"
// //                    style={{ width: 38, height: 38 }}
// //                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
// //                  />
// //                  <p className="m-0 mx-2 selected_servicename">
// //                    {bookingData?.servicePayload?.serviceName}
// //                  </p>
// //                </div>
// //                <div className="col-2 d-flex justify-content-end align-items-center">
// //                  <SvgIcon
// //                    component={HourglassEmptyOutlinedIcon}
// //                    style={{ color: "#bfbfbf", fontSize: "20px" }}
// //                  />
// //                  <p className="m-0 mx-1 selected_servicecount">
// //                    {bookingData?.servicePayload?.bookingDuration}
// //                  </p>
// //                </div>
// //                <div className="col-2 d-flex justify-content-end align-items-center">
// //                  <SvgIcon
// //                    component={PaymentOutlinedIcon}
// //                    style={{ color: "#bfbfbf", fontSize: "20px" }}
// //                  />
// //                  <p className="m-0 ml-3 selected_servicecount">
// //                    {bookingData?.totalPrice}$
// //                  </p>
// //                </div>
// //                <div className="col-1 d-flex justify-content-end align-items-center">
// //                  <SvgIcon
// //                    component={MoreVertOutlinedIcon}
// //                    style={{
// //                      color: "#bfbfbf",
// //                      cursor: "pointer",
// //                      fontSize: "20px",
// //                    }}
// //                  />
// //                </div>
// //              </div>
// //              <hr className="my-2" />
// //              <div>
// //                <p className="m-0 selected_service">Provider name</p>
// //                <div className="w-100 row justify-content-between align-items-center my-1">
// //                  <div className="col-4 d-flex align-items-center">
// //                    <Avatar
// //                      alt="Remy Sharp"
// //                      style={{ width: 38, height: 38 }}
// //                      src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0="
// //                    />
// //                    <p className="m-0 mx-2 selected_servicename">
// //                      {bookingDetails?.providerName}
// //                    </p>
// //                  </div>
// //                  <div className="col-1 d-flex justify-content-end align-items-center">
// //                    <SvgIcon
// //                      component={MoreVertOutlinedIcon}
// //                      style={{
// //                        color: "#bfbfbf",
// //                        cursor: "pointer",
// //                        fontSize: "20px",
// //                      }}
// //                    />
// //                  </div>
// //                </div>
// //                <hr className="my-2" />
// //
// //                <p className="m-0 selected_service">Customer Name </p>
// //                <div className="w-100 row justify-content-between align-items-center my-1">
// //                  <div className="col-4 d-flex align-items-center">
// //                    <Avatar
// //                      alt="Remy Sharp"
// //                      style={{ width: 38, height: 38 }}
// //                      src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0="
// //                    />
// //                    <p className="m-0 mx-2 selected_servicename">
// //                      {bookingDetails?.customerName}
// //                    </p>
// //                  </div>
// //                </div>
// //              </div>
// //            </div>
// //          </div>
// //          {/* <SlotPicking/> */}
// //        </div> */}
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, SvgIcon, Tooltip } from "@mui/material";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { bookingAxios, salesOrderAxios, slotAxios } from "../../axios";
import CalendarApp from "../components/calenderWidgetSlot";
import EventDialog from "../components/EventDialog";
import EventUpdateDialog from "../components/EventUpdateDialog";
import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import { handleOpenAlert, openToast } from "../store/alertSlice";
import { getCalendarData, getSlotsData } from "../store/calendarSlice";
import { openmodel } from "../store/modelSlice";
import { useHistory } from "react-router-dom";
import "../../assets/scheduler/css/calendar.css";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { customSnackBar } from "../../../utils";
import { ClipLoader } from "react-spinners";
import CalendarSkeleton from "../components/skeleton";

const localizer = momentLocalizer(moment);
export const LabelContext = createContext();

export default function Updatebooking({ setSelectSlotFlag }) {
  let bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const bookingDetails = location && location?.state;
  const history = useHistory();
  const {
    startTime,
    endTime,
    resources,
    combinedData,
    bgevents: allbgevent,
  } = useSelector((state) => state.calendarReducer);
  const {
    responseStatus,
    updateStatus,
    deleteStatus,
    successMessage,
    updateMessage,
    deleteMessage,
    isLoadingPost,
    isLoadingUpdsate,
    isLoadingDelete,
  } = useSelector((state) => state.modelReducer);
  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  // let allevent = useSelector((state) => state.calendarReducer.events);
  const [eventsData, setEventDate] = useState([]);
  const [modelType, setModelTypeg] = useState("");
  const [clinicId, setClinicId] = useState("9106");
  const [loading, setLoading] = useState(false);

  var userInfo = useSelector(
    (state) => state.Auth.userloginsuccessyasir?.users
  );
  console.log(userInfo, "userInfouserInfouserInfo");
  const { customerData, isExpert, isPrivate } = useSelector(
    (state) => state.bookingReducer
  );
  const views = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    AGENDA: "agenda",
  };
  const allViews = Object.keys(views).map((k) => views[k]);

  //First Api Call on Start After Each time any dependency Change Api Call
  useEffect(() => {
    if (isLoadingPost === false) {
      if (responseStatus === "success") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
      if (responseStatus === "error") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
    }
  }, [isLoadingPost]);

  useEffect(() => {
    if (isLoadingUpdsate === false) {
      if (updateStatus === "success") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
      if (updateStatus === "error") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
    }
  }, [isLoadingUpdsate]);
  useEffect(() => {
    if (isLoadingDelete === false) {
      if (deleteStatus === "success") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
      if (deleteStatus === "error") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
    }
  }, [isLoadingDelete]);
  function calculateDuration(startTime, endTime) {
    // Convert start time to Date object
    const startDate = new Date(`1970-01-01T${startTime}`);

    // Convert end time to Date object
    const endDate = new Date(`1970-01-01T${endTime}`);

    // Calculate the duration in milliseconds
    const duration = endDate - startDate;

    // Convert duration to minutes
    const durationInMinutes = Math.floor(duration / 1000 / 60);

    return durationInMinutes;
  }
  const extractResourceIds = useCallback(() => {
    const extractedIds = [];
    resources.forEach((obj) => {
      console.log(obj);
      const currentId = obj.resourceId;
      extractedIds.push(currentId);
    });
    return extractedIds;
  }, [resources]);

  const getCalendarEventsData = useCallback(async () => {
    const record = {
      providerId: [],
      bookingDateStart: dateApi,
      is_business: true,
      bookingDateEnd: dateApi,
      timeZone: "Europe/London",
      businessId: selectedBusiness?.id,
    };
    await dispatch(getCalendarData(record));
  }, [dispatch, dateApi, selectedBusiness]);

  const getSlotsEventData = useCallback(async () => {
    let data;
    if (bookingData?.bookingDuration !== null) {
      data = {
        providerId: [],
        date: dateApi,
        // slotDuration: parseInt(bookingData?.bookingDuration),
        sku:
          bookingData?.attributes && bookingData?.attributes.length > 0
            ? bookingData?.attributes[0].attributeSku
            : bookingData?.serviceSKU,
        hasBreak: false,
        page: 1,
        pageSize: 100,
        timeZone: "Europe/London",
        businessId: selectedBusiness?.id,
        editing: {
          timeFrom: bookingData.appointments[0]?.timeFrom,
          timeTo: bookingData.appointments[0]?.timeTo,
          date: bookingData.appointments[0]?.bookingDate,
        },
      };
    } else {
      data = {
        providerId: [],
        date: dateApi,
        // slotDuration: parseInt(
        //   calculateDuration(bookingData?.timeFrom, bookingData?.timeTo)
        // ),
        sku:
          bookingData?.attributes && bookingData?.attributes.length > 0
            ? bookingData?.attributes[0].attributeSku
            : bookingData?.serviceSKU,
        // sku:bookingData?.serviceSKU,
        hasBreak: false,
        page: 1,
        pageSize: 100,
        timeZone: "Europe/London",
        businessId: selectedBusiness?.id,
      };
    }
    await dispatch(getSlotsData({ data, setLoading }));
  }, [
    dispatch,
    dateApi,
    selectedBusiness,
    bookingData.attributes,
    bookingData?.serviceSKU,
    bookingData?.bookingDuration,
  ]);

  useEffect(() => {
    const openCalendar = async () => {
      setLoading(true)
      if (dateApi && selectedBusiness.id) {
        await getCalendarEventsData();
        await getSlotsEventData();
      }
      setLoading(false)

    };
    openCalendar();

    // dispatch(getCalendarData(record));
    // dispatch(getSlotsData({ data, setLoading }));

    // var data = {};
    // const extractedIds = extractResourceIds();

    // if (bookingData?.bookingDuration !== null) {
    //   data = {
    //     providerId: extractedIds,
    //     date: dateApi,
    //     // slotDuration: parseInt(bookingData?.bookingDuration),
    //     sku:
    //       bookingData?.attributes && bookingData?.attributes.length > 0
    //         ? bookingData?.attributes[0].attributeSku
    //         : bookingData?.serviceSKU,
    //     hasBreak: false,
    //     page: 1,
    //     pageSize: 100,
    //     timeZone: "Europe/London",
    //     businessId: selectedBusiness?.id,
    //   };
    // } else {
    //   data = {
    //     providerId: extractedIds,
    //     date: dateApi,
    //     // slotDuration: parseInt(
    //     //   calculateDuration(bookingData?.timeFrom, bookingData?.timeTo)
    //     // ),
    //     sku:
    //       bookingData?.attributes && bookingData?.attributes.length > 0
    //         ? bookingData?.attributes[0].attributeSku
    //         : bookingData?.serviceSKU,
    //     // sku:bookingData?.serviceSKU,
    //     hasBreak: false,
    //     page: 1,
    //     pageSize: 100,
    //     timeZone: "Europe/London",
    //     businessId: selectedBusiness?.id,
    //   };
    // }
    // console.log(bookingData, "bookingDatabookingDatabookingData");
    // const data2 = {
    //   id: id,
    // };

    // const record = {
    //   providerId: extractedIds,
    //   bookingDateStart: dateApi,
    //   is_business: true,
    //   bookingDateEnd: dateApi,
    //   timeZone: "Europe/London",
    //   businessId: selectedBusiness?.id,
    // };
  }, [
    getCalendarEventsData,
    getSlotsEventData,
    selectedBusiness.id,
    dateApi,
    clinicId,
  ]);
  function subtractOneHour(timeString) {
    // Create a Date object from the input time string
    const initialTime = new Date(`2023-09-05T${timeString}:00`);

    // Subtract 1 hour (3600 seconds) from the initial time
    const newTime = new Date(initialTime.getTime() - 3600 * 1000); // 3600 seconds * 1000 milliseconds

    // Format the new time as a string (HH:mm)
    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedTime;
  }

  //Onclick Event Function Call
  async function handleEvents(events) {
    console.log(events, "EVETS");
    const currentDate = new Date();
    const datetime = new Date(events.start);
    const modifiedEvents = {
      ...events,
      bookingDate: `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`,
    };

    // Now, modifiedEvents has the additional property bookingDate
    // You can use modifiedEvents instead of events in your further logic

    // Example: Logging the modifiedEvents
    // events.bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    if (datetime < currentDate) {
      customSnackBar("Slot is in past");
      // dispatch(
      //   openToast({
      //     message: "Slot is in past",
      //     status: "error",
      //   })
      // );
    } else if (events.title === "Available Slots") {
      // const data = {
      //   bookingId: bookingData?.bookingId,
      //   plexaarSalesOrderId: bookingData?.plexaarSalesOrderId,
      //   bookingDate: events.bookingDate,
      //   timeFrom: `${events.timeFrom}:00`,
      //   timeTo: `${events.timeTo}:00`,
      //   timeZone:"Europe/London",
      // };
      // await bookingAxios
      //   .patch(`/pb/booking/`, data)
      const data = {
        businessId: bookingData?.businessId,
        bookingId: bookingData?.plexaarBookingId,
        plexaarAppointmentId: bookingData?.appointments[0]?.plexaarAppointmentId
          ? bookingData?.appointments[0]?.plexaarAppointmentId
          : 0,
        expertAppointmentId: bookingData?.appointments[0]?.expertAppointmentId
          ? bookingData?.appointments[0]?.expertAppointmentId
          : 0,
        // sku:bookingData?.serviceSKU?bookingData?.serviceSKU:"",
        sku:
          bookingData?.attributes && bookingData?.attributes.length > 0
            ? bookingData?.attributes[0].attributeSku
            : bookingData?.serviceSKU,
        oldDate: {
          date: bookingData?.appointments[0]?.bookingDate,
          timeFrom: `${bookingData.appointments[0]?.timeFrom}`,
          timeTo: `${bookingData.appointments[0]?.timeTo}`,
        },
        newDate: {
          date: modifiedEvents.bookingDate,
          timeFrom: `${events.timeFrom}:00`,
          timeTo: `${events.timeTo}:00`,
        },
        isEdit: true,
        salesOrderId: bookingData?.plexaarSalesOrderId,
        // bookingDate: events.bookingDate,
        // timeFrom: `${events.timeFrom}:00`,
        // timeTo: `${events.timeTo}:00`,
        isExpert: bookingData?.isExpert,
        providerId: bookingData?.providerId,
        providerIdNew: events.resourceId ?? 0,

        isCancel: false,
        isOpen: false,
        modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,

        customerId: bookingData.isExpert
        ? bookingData?.customerId
        : bookingData?.plexaarcustomerId,
        timeZone: "Europe/London",
      };
      console.log(
        data,
        "modifiedEventsmodifiedEventsmodifiedEventsmodifiedEvents"
      );
      await slotAxios
        .post(`/pb/edit/booking-slot`, data)
        .then((res) => {
          customSnackBar("Booking Updated Successfully");
          // dispatch(
          //   openToast({
          //     message: "Booking Updated Successfully",
          //     status: "success",
          //   })
          // );
          window.window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          history.push(`/calendar`);
        })
        .catch((err) => {
          customSnackBar(err.message);
          // dispatch(openToast({ message: err.message, status: "error" }));
        });
    } else {
      customSnackBar("Slot is already booked");
      // dispatch(
      //   openToast({
      //     message: "Slot is already booked",
      //     status: "warning",
      //   })
      // );
    }
  }

  //Clinic Change Functions
  const handleChangeClinic = (id) => {
    setClinicId(id);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      var time = moment().format("LT");
      const existing = document.getElementsByClassName("my-div-time")[0];
      if (existing) {
        existing.innerHTML = `${time}`;
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      handleCurrentIndicator();
    }, 220);
  }, []);
  const handleCurrentIndicator = () => {
    var time = moment().format("LT");
    const existingDiv = document?.getElementsByClassName(
      "rbc-current-time-indicator"
    )[0];
    if (existingDiv) {
      const newDiv = document.createElement("div");
      newDiv.className =
        "my-div-time -mt-2 text-xs -ml-20 px-2 border-2 w-fit border-red-500 rounded-full";
      newDiv.textContent = `${time}`;
      existingDiv.appendChild(newDiv);
    }
  };
  const handleBackButtonFunction = () => {
    const toggle = {
      flag: false,
    };
    history.push(`/scheduler/booking_details/${selectedBusiness?.id}`, toggle);
  };
  return (
    <div className="mx-4 mt-2" id="style-2">
      <div className="row justify-content-center align-items-center">
        {!isExpanded ? (
          <div
            className="py-1 px-4 mb-2"
            style={{
              borderRadius: "8px",
              border: "0.6px solid #DCDCDC",
              background: "#FFF",
              // marginLeft: "40px",
            }}
          >
            <div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <Tooltip title="Back">
                  <div
                    className="pt-2"
                    onClick={handleBackButtonFunction}
                    role="button"
                  >
                    <i id="icon_left_color" className="fas fa-chevron-left"></i>
                  </div>
                </Tooltip>
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="m-0 mx-2 selected_servicename">
                    {bookingData.serviceName}
                  </p>
                </div>
                <div className="d-flex justify-content-around align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <SvgIcon
                      component={HourglassEmptyOutlinedIcon}
                      style={{ color: "#bfbfbf", fontSize: "16px" }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "#4D4D4D",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {bookingData.bookingDuration !== null
                        ? bookingData.bookingDuration
                        : 0 + " Min "}

                      {/* {bookingData.bookingDuration + " Min "} */}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1.83398 7.79626H20.1673M5.50065 15.1296H7.33398M9.62565 15.1296H13.2923"
                      stroke="#BDBDBD"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.90398 3.21289H16.0882C19.3515 3.21289 20.1673 4.01956 20.1673 7.23706V14.7629C20.1673 17.9804 19.3515 18.7871 16.0973 18.7871H5.90398C2.64982 18.7962 1.83398 17.9896 1.83398 14.7721V7.23706C1.83398 4.01956 2.64982 3.21289 5.90398 3.21289Z"
                      stroke="#BDBDBD"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    className="ms-1"
                    style={{
                      color: "#4D4D4D",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {bookingData.currency === null
                      ? "£"
                      : bookingData.currency === "USD"
                      ? "$"
                      : "£"}
                    {bookingData.totalAmount}
                  </span>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <span
                    style={{
                      color: "#616161",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    Package quantity
                  </span>{" "}
                  <div
                    className="ms-3"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#F6F6F6",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      className="mx-2"
                      style={{
                        color: "#4D4D4D",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {bookingData?.quantity}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setIsExpanded(!isExpanded)}
                  role="button"
                  className="d-flex justify-content-end align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 18 10"
                    fill="none"
                  >
                    <path
                      d="M2.35352 1.62181L9.06569 8.33398L15.7779 1.62181"
                      stroke="#FFD705"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="py-1 px-4 mb-2"
            style={{
              borderRadius: "8px",
              border: "0.6px solid #DCDCDC",
              background: "#FFF",
              // marginLeft: "40px",
            }}
          >
            <div
              style={{
                color: "#AAA",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              Service Details
              <div className="d-flex justify-content-between align-items-center my-2">
                <Tooltip title="Back">
                  <div
                    className="pt-2"
                    onClick={handleBackButtonFunction}
                    role="button"
                  >
                    <i id="icon_left_color" className="fas fa-chevron-left"></i>
                  </div>
                </Tooltip>
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="m-0 mx-2 selected_servicename">
                    {bookingData.serviceName}
                  </p>
                </div>
                <div className="d-flex justify-content-around align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <SvgIcon
                      component={HourglassEmptyOutlinedIcon}
                      style={{ color: "#bfbfbf", fontSize: "16px" }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "#4D4D4D",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {bookingData.bookingDuration !== null
                        ? bookingData.bookingDuration
                        : 0 + " Min "}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1.83398 7.79626H20.1673M5.50065 15.1296H7.33398M9.62565 15.1296H13.2923"
                      stroke="#BDBDBD"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.90398 3.21289H16.0882C19.3515 3.21289 20.1673 4.01956 20.1673 7.23706V14.7629C20.1673 17.9804 19.3515 18.7871 16.0973 18.7871H5.90398C2.64982 18.7962 1.83398 17.9896 1.83398 14.7721V7.23706C1.83398 4.01956 2.64982 3.21289 5.90398 3.21289Z"
                      stroke="#BDBDBD"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    className="ms-1"
                    style={{
                      color: "#4D4D4D",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {bookingData.currency === null
                      ? "£"
                      : bookingData.currency === "USD"
                      ? "$"
                      : "£"}
                    {bookingData.totalAmount}
                  </span>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <span
                    style={{
                      color: "#616161",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    Package quantity
                  </span>{" "}
                  <div
                    className="ms-3"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#F6F6F6",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      className="mx-2"
                      style={{
                        color: "#4D4D4D",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {bookingData?.quantity}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setIsExpanded(!isExpanded)}
                  role="button"
                  className="d-flex justify-content-end align-items-center my-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 18 10"
                    fill="none"
                  >
                    <path
                      d="M15.752 8.37819L9.03978 1.66602L2.32761 8.37819"
                      stroke="#FFD705"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <hr className="mb-1" />
              Customer Details
              <div className="d-flex justify-content-between align-items-center my-2">
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="m-0 mx-2 selected_servicename">
                    {bookingData.customerFirstName}{" "}
                    {bookingData.customerLastName}
                  </p>
                </div>
              </div>
              <hr className="mb-1" />
              Provider Details
              <div className="d-flex justify-content-between align-items-center my-2">
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="m-0 mx-2 selected_servicename">
                    {bookingData.providerName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}{" "}
        {loading ? (
          <CalendarSkeleton />
        ) : (
          <div
            className="w-100 rbc-time-content"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              borderRadius: "10px",
              height: !isExpanded ? "82vh" : "56vh",
              overflow: "auto",
            }}
          >
            <div className="col-12 overflow_calender">
              <CalendarApp
                localizer={localizer}
                allevent={combinedData}
                allbgevent={allbgevent}
                resources={resources}
                views={allViews}
                startTime={startTime}
                endTime={endTime}
                //   draghandleEvents={draghandleEvents}
                calenderEvents={handleEvents}
                handleSlots={() => {}}
                //   handleFilter={handleFilter}
              />

              {/* <EventDialog
                modelType={modelType}
                clinicId={clinicId}
                handleChangeClinic={handleChangeClinic}
              />
              <EventUpdateDialog /> */}
            </div>
          </div>
        )}
        {/* <Button color="primary" className="col-4 py-3 my-5" variant="contained">
          Submit
        </Button> */}
      </div>
    </div>
  );
}

// <div
//          className="my-3 w-full px-3 py-2 d-flex justify-content-center align-items-center"
//          style={{
//            boxShadow:
//              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
//            borderRadius: "10px",
//          }}
//        >
//          <div className="w-100">
//            <div className="w-100 d-flex justify-content-between align-items-center px-2">
//              <p className="m-0 selected_service">Service Details</p>
//              {/* <SvgIcon component={AddIcon} style={{ borderRadius:"3px", color:"#ffffff", background:'#5599FF'}} onClick={e=>{ e.preventDefault();handleAddMore()}}/> */}
//            </div>
//            <div className="w-100 px-2 py-1">
//              <div className="w-100 row justify-content-center align-items-center my-1">
//                <div className="col-7 d-flex align-items-center">
//                  <Avatar
//                    alt="Remy Sharp"
//                    style={{ width: 38, height: 38 }}
//                    src="https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg"
//                  />
//                  <p className="m-0 mx-2 selected_servicename">
//                    {bookingData?.servicePayload?.serviceName}
//                  </p>
//                </div>
//                <div className="col-2 d-flex justify-content-end align-items-center">
//                  <SvgIcon
//                    component={HourglassEmptyOutlinedIcon}
//                    style={{ color: "#bfbfbf", fontSize: "20px" }}
//                  />
//                  <p className="m-0 mx-1 selected_servicecount">
//                    {bookingData?.servicePayload?.bookingDuration}
//                  </p>
//                </div>
//                <div className="col-2 d-flex justify-content-end align-items-center">
//                  <SvgIcon
//                    component={PaymentOutlinedIcon}
//                    style={{ color: "#bfbfbf", fontSize: "20px" }}
//                  />
//                  <p className="m-0 ml-3 selected_servicecount">
//                    {bookingData?.totalPrice}$
//                  </p>
//                </div>
//                <div className="col-1 d-flex justify-content-end align-items-center">
//                  <SvgIcon
//                    component={MoreVertOutlinedIcon}
//                    style={{
//                      color: "#bfbfbf",
//                      cursor: "pointer",
//                      fontSize: "20px",
//                    }}
//                  />
//                </div>
//              </div>
//              <hr className="my-2" />
//              <div>
//                <p className="m-0 selected_service">Provider name</p>
//                <div className="w-100 row justify-content-between align-items-center my-1">
//                  <div className="col-4 d-flex align-items-center">
//                    <Avatar
//                      alt="Remy Sharp"
//                      style={{ width: 38, height: 38 }}
//                      src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0="
//                    />
//                    <p className="m-0 mx-2 selected_servicename">
//                      {bookingDetails?.providerName}
//                    </p>
//                  </div>
//                  <div className="col-1 d-flex justify-content-end align-items-center">
//                    <SvgIcon
//                      component={MoreVertOutlinedIcon}
//                      style={{
//                        color: "#bfbfbf",
//                        cursor: "pointer",
//                        fontSize: "20px",
//                      }}
//                    />
//                  </div>
//                </div>
//                <hr className="my-2" />
//
//                <p className="m-0 selected_service">Customer Name </p>
//                <div className="w-100 row justify-content-between align-items-center my-1">
//                  <div className="col-4 d-flex align-items-center">
//                    <Avatar
//                      alt="Remy Sharp"
//                      style={{ width: 38, height: 38 }}
//                      src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0="
//                    />
//                    <p className="m-0 mx-2 selected_servicename">
//                      {bookingDetails?.customerName}
//                    </p>
//                  </div>
//                </div>
//              </div>
//            </div>
//          </div>
//          {/* <SlotPicking/> */}
//        </div> */}
