// import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
// import SideNavone from '../../../../../SideNavBar';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// const ColumnList = () => {
//     const [columns, setColumns] = useState([]);
//     const [showColumnList, setShowColumnList] = useState([]);
//     const [draggedItems, setDraggedItems] = useState([]);

//     const store = useSelector(state => state?.servicesReducer);

//     useEffect(() => {
//         if (store?.allServices) {
//             const columns = Object.keys(store?.allServices[0]);
//             setColumns(columns);
//         }
//     }, [store?.allServices]);

//     const handleColumnButtonClick = (i) => {
//         if (showColumnList.includes(i)) {
//             const updatedList = showColumnList.filter(item => item !== i);
//             setShowColumnList(updatedList);
//         } else {
//             setShowColumnList([...showColumnList, i]);
//         }
//     };

//     const handleDragEnd = (result) => {
//         if (!result.destination) {
//             return;
//         }
//         const updatedItems = Array.from(draggedItems);
//         const [movedItem] = updatedItems.splice(result.source.index, 1);
//         updatedItems.splice(result.destination.index, 0, movedItem);
//         setDraggedItems(updatedItems);
//     };

//     const saveChanges = () => {
//         const updatedDraggedItems = draggedItems.map((item, index) => ({
//             ...item,
//             position: (index + 1).toString(),
//         }));
//         setDraggedItems(updatedDraggedItems);
//     }

//     const uniqueDiv = document.getElementById('uniqueDiv');

//     return (
//         <SideNavone>
//             <div className='conainer list_col_container'>
//                 <h3 className='ps-4 mt-5'>Added Columns</h3>
//                 <DragDropContext onDragEnd={handleDragEnd}>
//                     <Droppable droppableId="droppable" direction="horizontal">
//                         {(provided) => (
//                             <div className='px-4 d-flex position-relative scrollable_div'>
//                                 <button
//                                     className='btn btn-warning h-100'
//                                     style={{ position: 'absolute', left: '24px' }}
//                                     onClick={() => {
//                                         // Replace 'uniqueDiv' with the actual ID or reference to your scrollable div
//                                         const uniqueDiv = document.getElementById('uniqueDiv');

//                                         uniqueDiv.scrollTo({
//                                             left: uniqueDiv.scrollLeft - 100, // Subtracting 100 pixels to scroll left
//                                             behavior: 'smooth',
//                                         });
//                                     }}
//                                 >
//                                     <ArrowBackIosNewIcon sx={{ color: 'white' }} />
//                                 </button>

//                                 <ul
//                                     id='uniqueDiv'
//                                     className='d-flex m-0 scrollable_col_list px-4'
//                                     {...provided.droppableProps}
//                                     ref={provided.innerRef}
//                                 >
//                                     {draggedItems.map((item, index) => (
//                                         <Draggable key={item?.id} draggableId={item?.id} index={index}
//                                         >
//                                             {(provided) => (
//                                                 <div
//                                                     className='bg-secondary text-light rounded py-2 px-4 me-1'
//                                                     style={{
//                                                         height: '50px',
//                                                         width: '120px',
//                                                         backgroundColor: 'green',
//                                                         margin: '0 10px', // Adjust the spacing between items
//                                                         display: 'flex',
//                                                         alignItems: 'center',
//                                                         justifyContent: 'center',
//                                                         color: 'white',
//                                                         borderRadius: '5px'
//                                                     }}
//                                                     {...provided.draggableProps}
//                                                     {...provided.dragHandleProps}
//                                                     ref={provided.innerRef}
//                                                 >
//                                                     {item.content}
//                                                 </div>
//                                             )}
//                                         </Draggable>
//                                     ))}
//                                     {provided.placeholder}
//                                 </ul>
//                                 <button
//                                     className='btn btn-warning h-100'
//                                     style={{ position: 'absolute', right: '24px' }}
//                                     onClick={() => {
//                                         uniqueDiv.scrollTo({
//                                             left: uniqueDiv.scrollLeft + 100, // Adding 100 pixels to scroll right
//                                             behavior: 'smooth',
//                                         });
//                                     }}
//                                 >
//                                     <ArrowForwardIosIcon sx={{ color: 'white' }} />
//                                 </button>

//                                 <hr className='my-3' />
//                             </div>
//                         )}
//                     </Droppable>
//                 </DragDropContext>

//                 <div className='row mt-4 px-4'>
//                     <div className='col-sm-6 col-12 d-flex justify-content-sm-start justify-content-center g-0'>
//                         <h3 className=''>Columns List</h3>
//                     </div>
//                     <div className='col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3'>
//                         <button type='submit' className='add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1'
//                             onClick={saveChanges}
//                         ><AddBoxIcon className='addbox_icon me-1'
//                             />Save Changes</button>
//                     </div>
//                     <hr className='my-3' />
//                 </div>

//                 <div className="w-100 d-flex flex-wrap justify-content-center">
//                     {columns.length > 0 && columns.map((v, i) => {
//                         return (
//                             <div key={v} className='col_btn_div pe-3 mt-3'>
//                                 <button
//                                     className={`btn h-100 w-100 pe-3 ${showColumnList.includes(i) ? 'selectedColumn' : ''}`}
//                                     onClick={() => {
//                                         handleColumnButtonClick(i);
//                                         if (draggedItems.find(val => val?.id == `${i}`)) {
//                                             const filtered = draggedItems.filter(el => el?.id != `${i}`);
//                                             setDraggedItems(filtered);
//                                         }
//                                         else {
//                                             setDraggedItems([...draggedItems, { id: `${i}`, position: i + 1, content: v }]);
//                                         }
//                                     }}
//                                 >
//                                     {v}
//                                 </button>
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//         </SideNavone>
//     )
// }

// export default ColumnList

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React from "react";
import { useLocation } from "react-router-dom";
import SideNavone from "../../../../../SideNavBar";
import { styled } from "@mui/material/styles";

import { useState } from "react";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import AddServiceColumn from "./AddServiceCoulmn";
import InsertServiceColumn from "./EditServiceColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { postRequest, getRequest } from "../../../../../Componenets/request";
//   import { getRequest, postRequest } from "../../../Componenets/request";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#6B6B6B",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#A4A4A4",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    width: "100%",
    padding: "2px 6px",
  },
}));
const ServicesColumnList = () => {
  const serviceName = "expert customer";
  const id = 1;
  var userId = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const location = useLocation();
  // const data = location.state;
  const [success, setSuccess] = useState(false);
  const [filterService, setFilterService] = useState([]);
  const [staffSortedData, setStaffSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { isLoading, isSuccess, data: GetStaff } = useGetStaffsQuery();
  const history = useHistory();
  const store = useSelector((state) => state?.staffReducer);
  const [columnNames, setColumnNames] = useState([]);
  const userid = useSelector((state) => state?.Auth?.userloginsuccessyasir?.id);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const {
  //   isLoading,
  //   isSuccess,
  //   data: GetStaff,
  // } = useGetColumnNamesByServiceNameStaffQuery(userId);

  const getColumnNames = async () => {
    const res = await getRequest(
      `/serviceinventory_svc/pv/ListingPreferences/getAllListingPreferenceByUserId?id=${userid}&businessId=${selectedBusiness.id}`
    );
    setColumnNames(res?.result?.listingPreference);
  };
  console.log(columnNames, staffSortedData, "columnNamescolumnNames");
  useEffect(() => {
    getColumnNames();
  }, [userid]);
  useEffect(() => {
    if (columnNames) {
      const data = columnNames.slice().sort((a, b) => a.position - b.position);
      setStaffSortedData(data);
      setLoading(false);
    }
  }, [columnNames]);

  const hanldeSaveChanges = async () => {
    // const dataWithoutServiceNameAndColumnName = staffSortedData.map((item) => {
    //   const { serviceName, columnName, ...rest } = item;
    //   console.log(GetStaff?.result?.columnNames,"restrestrestrest")
    //   return rest;
    // });
    const dataWithoutServiceNameAndColumnName = staffSortedData.reduce(
      (uniqueData, item) => {
        const existingItem = uniqueData.find(
          (uniqueItem) => uniqueItem.position === item.position
        );

        // Rename the 'id' property to 'columnId'
        const updatedItem = { ...item, columnId: item.id };
        delete updatedItem.id;

        if (!existingItem) {
          uniqueData.push(updatedItem);
        }

        return uniqueData;
      },
      []
    );

    const classes = dataWithoutServiceNameAndColumnName
      .map((i) => {
        if (i.isShown === true) {
          return { columnId: i.columnId, isSticky: i.isSticky };
        }
        return null;
      })
      .filter((it) => it !== null);
    const payload = {
      entityId: userid,
      columns: classes,
      createdBy: userid,
    };
    const result = await postRequest(
      `/serviceinventory_svc/pv/ListingPreferences/addUserListingPreference`,
      payload
    );
    console.log(result, "resultresultresult");
    if (result?.code === 0) {
      alert("Columns list updated successfully");
      history.push(`/dynamic-services`);
    } else {
      alert(result?.message);
    }
    // const code = result.data.code;
    // const message = result.data.message;
    // if (code === 0) {
    //   dispatch(
    //     setNotifications({
    //       type: "success",
    //       message: message,
    //       isActive: false,
    //     })
    //   );
    //   setTimeout(() => {
    //     navigate("/staff");
    //   });
    // } else {
    //   dispatch(
    //     setNotifications({
    //       type: "error",
    //       message: message,
    //       isActive: true,
    //     })
    //   );
    // }
  };
  return (
    <>
      <SideNavone>
        <div
          style={{ height: "100vh", overflow: "auto", paddingBottom: "220px" }}
        >
          <Grid className="" container rowSpacing={1.5} bgcolor="#FBFCFF">
            <Grid
              item
              xs={12}
              bgcolor="#FFF"
              border="#DCDCDC"
              px={2}
              py={1.4}
              mt={2}
            >
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Typography>Edit Column</Typography>
                <div className="col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3">
                  <button
                    // type="submit"
                    className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
                    onClick={hanldeSaveChanges}
                  >
                    {/* {isUpdateColumnPreferrenceLoading ? (
                      <CircularProgress size={25} color="white" />
                    ) : ( */}
                    <>
                      <AddBoxIcon className="addbox_icon me-1" />
                      Save Changes
                    </>
                    {/* )} */}
                  </button>
                </div>
                {/* <Button variant="contained"               className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
   onClick={() => hanldeSaveChanges()}>
                  {isUpdateColumnPreferrenceLoading ? (
                    <CircularProgress size={25} color="white" />
                  ) : (
                    "Save Changes"
                  )}
                </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12} mx={1.5}>
              <Box component="div">
                <Typography sx={{ color: "#000" }} mb={1.5}>
                  Added Columns
                </Typography>
                <AddServiceColumn
                  data={staffSortedData}
                  updateData={setStaffSortedData}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mx={1.5}>
              <Box component="div">
                <Typography sx={{ color: "#000" }} mb={1.5}>
                  Insert Columns
                </Typography>
                <InsertServiceColumn
                  data={staffSortedData}
                  updateData={setStaffSortedData}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SideNavone>

      {/* {state?.isActive && <Toaster state={state} />} */}
    </>
  );
};

export default ServicesColumnList;
