import React, { useEffect, useState } from "react";
import SideNavone from "../../../../SideNavBar";
import { Avatar, Button, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceAttributesExpert,
  getServiceToUpdateExpert,
} from "../../store/servicesActions";
import {
    useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DataNotFound } from "../../../../utils";
import { ClipLoader } from "react-spinners";

const ServiceAttribute = ({ businessImage, result, action, editData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
const history=useHistory()
  const service_store_expert = useSelector(
    (state) => state?.servicesReducer?.expertServiceToUpdate[0]
  );
  const service_store_expert_attributes = useSelector(
    (state) => state?.servicesReducer?.serviceAttributesExpert
  );
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [loader, setIsLoader] = useState(false);

  const handleAttributeClick = (attributeId) => {
    // Toggle the selection state of the clicked attribute
    setSelectedAttributes((prevSelected) =>
      prevSelected.includes(attributeId)
        ? prevSelected.filter((id) => id !== attributeId)
        : [...prevSelected, attributeId]
    );
  };
  useEffect(() => {
    if (location.state) {
      dispatch(getServiceToUpdateExpert(location.state));
    }
  }, [location.state]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (service_store_expert) {
          setIsLoading(true);
          await dispatch(
            getServiceAttributesExpert({
              id: "LH-31425827",
              //   id: service_store_expert?.serviceSKU,
              setIsLoading,
            })
          );
          setIsLoading(false);
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [service_store_expert, dispatch]);
  return (
    <SideNavone>
    <div
      className="padding_left_right hide_scrollbar  mt-2 "
      style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
    >
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader color="#548DFF" />
        </div>
      ) : (
        <>
<div className="d-flex">
        <div className="pt-2" onClick={()=>history.push("all-services")} role="button">
          <i id="" style={{color:"#5599ff"}}className="fas fa-chevron-left"></i>
        </div>
          <div className="col-md-12 border_rad_new px-2 pb-2 pt-2 mt-1 mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex ">
                {service_store_expert ? (
                  <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                    <Avatar
                      alt={service_store_expert?.serviceName}
                      src={service_store_expert?.serviceImage}
                      variant="rounded"
                      style={{
                        borderRadius: 50,
                        width: "40px",
                        height: "40px",
                        marginRight: 10,
                      }}
                    />
                  </div>
                ) : (
                  <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                        marginRight: 2,
                        borderRadius: 50,
                      }}
                    >
                      S
                    </Avatar>
                  </div>
                )}
                <div className="d-flex ms-2 justify-content-between ">
                  <div
                    style={{ textAlign: "left", textTransform: "capitalize" }}
                    className="d-flex flex-column justify-content-start"
                  >
                    <span className="text-black businessId">
                      {service_store_expert?.serviceName}
                    </span>
                    <span style={{ fontSize: "10px" }} className="businessId">
                      Expert Centre
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <BsThreeDotsVertical fontSize={15} color="#BEC3C7" />
              </div>
            </div>
          </div>
          </div>

          <span className=" pt-3 text-black-50 text_heading_owner">
            Please Select At least one Options
          </span>
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 mb-2 pt-2">
            {service_store_expert_attributes &&
            service_store_expert_attributes.length > 0 ? (
              service_store_expert_attributes.map((attribute, index) => (
                <>
                <div
                  key={attribute.id}
                  className={`d-flex justify-content-between ${
                    selectedAttributes.includes(attribute.attributeType)
                      ? "selected-attribute"
                      : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAttributeClick(attribute.attributeType)}
                >
                  <div className="d-flex">
                    <div className="d-flex justify-content-between">
                      <div
                        style={{
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                        className="d-flex flex-column justify-content-start"
                      >
                        <span className="text-black ms-2 mt-1 text-capitalize businessId">
                          {attribute?.attributeType}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pt-1">
                    <div className="d-flex justify-content-between">
                      <div
                        style={{
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                        className="d-flex flex-column justify-content-start"
                      >
                        <span className="text-black ms-3 text-capitalize businessId">
                          ${attribute?.attributePrice}
                        </span>
                        <span
                          style={{ fontSize: "10px" }}
                          className="businessId"
                        >
                          ({attribute?.duration} mins)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <Divider style={{ marginTop: "5px", width: "100%" }} />
                </>
              ))
            ) : (
              <div>
                {/* Insert your message or component for data not found */}
                <DataNotFound />
              </div>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={() => {
                // handleSubmit();
              }}
              variant="contained"
              disabled={loader}
            >
              {loader ? <ClipLoader color="#fffff" size={30} /> : "Next"}
            </Button>
          </div>
        </>
      )}
    </div>
  </SideNavone>
  );
};

export default ServiceAttribute;
