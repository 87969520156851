import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import SideNavone from "../../SideNavBar";
import LeftSection from "./NotesLeftSection";
import RightSection from "./NotesRightSection";
import ChatNotes from "../../Componenets/ChatNotes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./notes.css";
import axios from "axios";
import { copyToClipboard, customSnackBar } from "../../utils";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import AudioWaveform from "./AudioWaveform";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import NotesLoading from "../../Componenets/NotesLoading";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { ArrowBack } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StopIcon from "@mui/icons-material/Stop";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ZoomableImageModal from "./ImageModal";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import { MdCameraAlt } from "react-icons/md";
import { FaVideo, FaVideoSlash } from "react-icons/fa";
import instance from "../../utils/utils";
import DialogBox from "../BusinessProfile/DialogBox";
// import wavesurfer from "wavesurfer.js";
const Notes = ({ flag, setSelectSlotFlag, callBackHandler }) => {
  const history = useHistory();
  const [media, setMedia] = useState({
    recordedVideoBlob: undefined,
    recorderAudioBlob: undefined,
    capturedImageBlob: undefined,
    selectedVideo: null,
    selectedAudio: null,
    selectedImage: null,
  });
  const [permissions, setPermissions] = useState({
    isAudioRecording: false,
    isVideoRecording: false,
    isCapturingImage: false,
  });
  const [isMediaDisplayOpen, setIsMediaDisplayOpen] = useState(false);
  console.log(
    isMediaDisplayOpen,
    "isMediaDisplayOpenisMediaDisplayOpenisMediaDisplayOpen"
  );
  const [timer, setTimer] = useState(0);
  const [isOpenedCamera, setIsOpenedCamera] = useState(false);
  const canvasRef = useRef(null);
  const bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const [messageInput, setMessageInput] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [notes, setNotes] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [dialog, setDialog] = useState({ visible: false });

  const [showFileDialog, setShowFileDialog] = useState(false);
  const [moreOptions, setMoreOptions] = useState(null);
  const [loading, SetLoading] = useState(false);
  const recorderRef = useRef(null);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);
  const messagesBoxRef = useRef();

  const fileInputRef = useRef(null);
  const [showMenu, setShowMenu] = useState(null);

  const [selectedImages, setSelectedImage] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [readMoreMap, setReadMoreMap] = useState({});

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };
  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const toggleReadMore = (messageId) => {
    setReadMoreMap((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };
  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  const MediaDisplay = ({ file, type }) => {
    console.log(file, type, "file, typefile, type");
    if (type === "image") {
      return (
        <div className="">
          {/* First Image */}
          <div>
            <img
              src={URL.createObjectURL(file)}
              alt="Selected"
              width="250px"
              height="250px"
              style={{
                borderRadius: "5px",
              }}
            />
          </div>

          {/* Second Image */}
          <div className="d-flex justify-content-center pt-3">
            <img
              src={URL.createObjectURL(file)}
              alt="Selected"
              width="100px"
              height="100px"
              style={{
                borderRadius: "5px",
                marginRight: "10px",
              }}
            />

            <div
              onClick={() => {
                customSnackBar("Working On it.");
              }}
              style={{
                padding: "40px",
                backgroundColor: "#5599FF1A",
                cursor: "pointer",
                borderRadius: "5px",
                height: "100px",
                width: "100px",
              }}
            >
              <input
                id="image-input"
                type="file"
                accept="image/*,.gif"
                onChange={handleImageFile}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <AddCircleOutlineRoundedIcon color="primary" />
            </div>
          </div>
        </div>
      );
    } else if (type === "video") {
      return (
        <div className="">
          <div>
            <video
              width={400}
              height={300}
              controls
              style={{
                borderRadius: "5px",
              }}
            >
              <source src={URL.createObjectURL(file)} type="video/mp4" />
            </video>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <video
              width={100}
              height={100}
              controls
              style={{
                borderRadius: "5px",
                marginRight: "10px",
              }}
            >
              <source src={URL.createObjectURL(file)} type="video/mp4" />
            </video>

            <div
              onClick={() => {
                customSnackBar("Working On it.");
              }}
              style={{
                padding: "40px",
                backgroundColor: "#5599FF1A",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <input
                id="image-input"
                type="file"
                accept="image/*,.gif"
                onChange={handleImageFile}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <AddCircleOutlineRoundedIcon color="primary" />
            </div>
          </div>
        </div>
      );
    } else if (type === "audio") {
      return (
        <div className="">
          {/* First Audio */}
          <div>
            <div className="audio-container">
              <div className="noPreview p-3">
                <p>No preview available</p>
              </div>
              {/* <div className="audioPreview">No preview available</div>
              <audio className="audioPlayer" controls>
                <source src={URL.createObjectURL(file)} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio> */}
            </div>
          </div>

          {/* Second Audio */}
          <div className="d-flex justify-content-center pt-3">
            <div className="audio-container">
              <div className="noPreview2 p-3">
                <p>No preview available</p>
              </div>
              {/* <audio controls>
                <source src={URL.createObjectURL(file)} type="audio/mpeg" />
              </audio>
              <div className="no-preview-label">No Preview Available</div> */}
            </div>

            <div
              onClick={() => {
                customSnackBar("Working On it.");
              }}
              style={{
                padding: "40px",
                backgroundColor: "#5599FF1A",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <input
                id="image-input"
                type="file"
                accept="image/*,.gif"
                onChange={handleImageFile}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <AddCircleOutlineRoundedIcon color="primary" />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const ImageBlob = (blob) => {
    if (media.capturedImageBlob && media.capturedImageBlob instanceof Blob) {
      var url = window.URL.createObjectURL(media.capturedImageBlob);
      return (
        <img
          src={url}
          style={{
            borderRadius: "5px",
            marginTop: "20px",
            width: "80%",
            height: "100%",
          }}
          alt="Selected"
          width="500px"
          height="500px"
        />
      );
    } else {
      return null;
    }
  };

  const AudioBlob = () => {
    if (media.recorderAudioBlob && media.recorderAudioBlob instanceof Blob) {
      var url = window.URL.createObjectURL(media.recorderAudioBlob);
      return (
        <audio controls>
          <source src={url} type="audio/mpeg" />
        </audio>
      );
    } else {
      return null;
    }
  };

  const handleOpenCamera = () => {
    setIsOpenedCamera(true);
    handleStartCameraForCapture();
  };

  // const handleCloseCamera = () => {
  //   setIsOpenedCamera(false);
  //   setPermissions(setAllPermissionsToFalse);
  // };
  const handleCloseCamera = () => {
    setIsOpenedCamera(false);
    setPermissions(setAllPermissionsToFalse);

    // Stop tracks and release resources
    const tracks = cameraRef.current.srcObject?.getTracks();
    tracks && tracks.forEach((track) => track.stop());
    console.log(
      tracks,
      cameraRef.current.srcObject,
      mediaStream?.getTracks(),
      mediaStream,
      "recorderRefrecorderRefrecorderRef"
    );

    cameraRef.current.srcObject = null;
  };

  const VideoBlob = () => {
    if (media.recordedVideoBlob && media.recordedVideoBlob instanceof Blob) {
      var url = window.URL.createObjectURL(media.recordedVideoBlob);
      return (
        <video
          style={{
            borderRadius: "5px",
            marginTop: "20px",
            width: "80%",
            height: "100%",
          }}
          alt="Selected"
          width={"80%"}
          height={"100%"}
          controls
        >
          <source src={url} type="video/mp4" />
        </video>
      );
    } else {
      return null;
    }
  };

  const fetchNotesData = useCallback(async () => {
    try {
      setFetchedData(true);
      let url = `/notes_svc/pv/notes/?id=${
        bookingData.plexaarBookingId === null
          ? bookingData.plexaarSalesOrderId
          : bookingData.plexaarBookingId
      }&type=booking&timezone=Europe%2FLondon&plexaar_sales_order_id=${
        bookingData.plexaarSalesOrderId
      }`;

      if (bookingData.purchaseOrderId) {
        url += `&purchase_order_id=${bookingData.purchaseOrderId}`;
      }

      url += `&getting_by_type=provider&getting_by=${bookingData.providerId}&timezone=Europe/London`;

      // if (bookingData.purchaseOrderId !== null) {
      //   url += `&purchase_order_id=${bookingData.purchaseOrderId}`;
      // }
      // if (bookingData.plexaarBookingId !== null) {
      //   url += `&type=booking&getting_by_type=provider&getting_by=${bookingData.providerId}&timezone=Europe/London`;
      // }
      const response = await instance.get(url);
      const data = await response.data;
      setNotes(data.result);
    } catch (e) {
    } finally {
      setFetchedData(false);
    }
  }, [bookingData]);
  useEffect(() => {
    fetchNotesData();
  }, [fetchNotesData]);
  const setAllPermissionsToFalse = {
    isAudioRecording: false,
    isVideoRecording: false,
    isCapturingImage: false,
  };

  const setAllMediaToNull = {
    recordedVideoBlob: undefined,
    recorderAudioBlob: undefined,
    capturedImageBlob: undefined,
    selectedVideo: null,
    selectedAudio: null,
    selectedImage: null,
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!messageInput.trim() && !Object.values(media).some(Boolean)) return;

    SetLoading(true);

    const fd = new FormData();
    fd.append("created_by", bookingData.providerId);
    fd.append("created_for", bookingData?.plexaarBookingId === null
    ? bookingData.plexaarSalesOrderId
    : bookingData.plexaarBookingId);
    fd.append("created_by_type", 1);
    fd.append( "created_for_type",
    bookingData.plexaarBookingId === null ? 5 : 4);
    fd.append("is_shown", true);
    fd.append("created_by_name", bookingData?.providerName);
    fd.append("created_by_img_url", bookingData?.providerImage);
    fd.append("is_expert0_plexaar1", true);

    const uploadFile = async (file, fileType, noteType) => {
      const formData = new FormData();
      formData.append("Id", bookingData.providerId);
      formData.append("serviceName", "notes");
      formData.append("source", "Plexaar");
      formData.append("Files", file, `attachment.${fileType}`);
      formData.append("Category", "main");
      formData.append("FileType", fileType);
      formData.append("CreatedBy", bookingData.providerId);

      const response = await instance.post(
        `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.code === 0) {
        fd.append(noteType, response.data.paths[0]);
      } else {
        throw new Error("Error uploading file");
        setIsMediaDisplayOpen(false);
      }
    };

    try {
      if (media.selectedImage || media.capturedImageBlob) {
        await uploadFile(
          media.selectedImage || media.capturedImageBlob,
          "jpg",
          "image"
        );
        fd.append("note_type", 2);
      } else if (media.selectedAudio || media.recorderAudioBlob) {
        await uploadFile(
          media.selectedAudio || media.recorderAudioBlob,
          "mp3",
          "audio"
        );
        fd.append("note_type", 3);
      } else if (media.selectedVideo || media.recordedVideoBlob) {
        if (media.selectedVideo.size > 30 * 1024 * 1024) {
          customSnackBar("Video file size exceeds the 30MB limit.");
        }
        await uploadFile(
          media.selectedVideo || media.recordedVideoBlob,
          "mp4",
          "video"
        );
        // SetLoading(false);

        fd.append("note_type", 4);
      } else {
        fd.append("note_type", 1);
        fd.append("text", messageInput);
      }

      const response = await instance.post(`/notes_svc/pv/notes/`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.code === 0) {
        setMedia(setAllMediaToNull);
        setMessageInput("");
        setPermissions(setAllPermissionsToFalse);
        setIsMediaDisplayOpen(false);

        messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
        fetchNotesData();
      }
    } catch (error) {
      customSnackBar(error.message);
    }

    setSelectedType(null);
    SetLoading(false);
  };

  const handleImageFile = async (e) => {
    console.log(e.target.files, " e.target.files e.target.files");
    const file = e.target.files?.[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file && allowedFileTypes.includes(file.type)) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedImage: file,
      }));
      setPermissions(setAllPermissionsToFalse);
      setShowFileDialog(false);
      handleCloseMenu();
      setIsMediaDisplayOpen(true);
    } else {
      customSnackBar("Format not supported");
    }
  };

  const handleAudioFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedAudio: file,
      }));
      setPermissions(setAllPermissionsToFalse);
      setShowFileDialog(false);
      handleCloseMenu();
      setIsMediaDisplayOpen(true);
    }
  };

  const handleVideoFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedVideo: file,
      }));
      setPermissions(setAllPermissionsToFalse);
      setShowFileDialog(false);
      handleCloseMenu();
      setIsMediaDisplayOpen(true);
    }
  };

  const handleStartRecording = async () => {
    // try {
    //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //     recorderRef.current = new MediaRecorder(stream);
    //     const chunks = [];
    //   recorderRef.current.ondataavailable = (e) => {
    //       if(e.data.size > 0){
    //           chunks.push(e.data);
    //       }
    //   };
    //   recorderRef.current.onstop = () => {
    //       const recorded = new Blob(chunks, {type: 'audio/wav'});
    //       setMedia((prev)=>({
    //           ...setAllMediaToNull,
    //           recorderAudioBlob: recorded,
    //       }));
    //   };
    //     recorderRef.current.start();
    //     setPermissions((prevState) => ({
    //       ...setAllPermissionsToFalse,
    //       isAudioRecording: true,
    //     }));
    //     setSelectedType("voice");
    //     setMediaStream(stream); // Store the media stream in state
    //   } catch (error) {
    //       customSnackBar(`Failed to access microphone:${error}`);
    //       setPermissions(setAllPermissionsToFalse);
    //   }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let startTime = Date.now();
      let totalSeconds = 0;

      recorder.onstart = () => {
        startTime = Date.now();
        setTimer(0);
        const intervalId = setInterval(() => {
          totalSeconds = Math.floor((Date.now() - startTime) / 1000);
          setTimer(totalSeconds);
          // setTimer(Math.floor((Date.now() - startTime) / 1000));
          // if (totalSeconds >= 60) {
          //   // Automatically stop recording after 3 minutes (180 seconds)
          //   handleStopRecording();
          // }
        }, 1000);
        recorder.intervalId = intervalId;
      };
      recorder.start();
      setPermissions((prevState) => ({
        ...setAllPermissionsToFalse,
        isAudioRecording: true,
      }));
      setMediaStream(stream); // Store the media stream in state

      recorderRef.current = recorder;
    } catch (error) {
      customSnackBar(`Failed to access microphone:${error}`);
      setPermissions(setAllPermissionsToFalse);
    }
  };
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleStopRecording = () => {
    if (!recorderRef.current || !permissions.isAudioRecording) return;

    const recorder = recorderRef.current;
    recorder.ondataavailable = (e) => {
      const chunks = [];
      chunks.push(e.data);
      const recordedBlob = new Blob(chunks, { type: "audio/webm" });
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        recorderAudioBlob: recordedBlob,
      }));
      setIsMediaDisplayOpen(true);

      // Stop tracks and release resources
      const tracks = mediaStream?.getTracks();
      tracks && tracks.forEach((track) => track.stop());
      console.log(tracks, mediaStream, "trackstrackstracks");
      setMediaStream(null);
    };

    clearInterval(recorder.intervalId);
    setPermissions(setAllPermissionsToFalse);
    if (recorderRef.current && recorderRef.current.srcObject) {
      const stream = recorderRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      recorderRef.current.srcObject = null;
    }
    // recorderRef.current.stop();
    recorder.stop();
  };

  const handleStartCameraForCapture = async () => {
    try {
      setPermissions((prevState) => ({
        ...setAllPermissionsToFalse,
        isCapturingImage: true,
      }));
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (cameraRef.current) {
        cameraRef.current.srcObject = stream;
      }
    } catch (err) {
      customSnackBar("Error Accessing Camers:", err);
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const captureImage = async () => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      console.log("cameraref", cameraRef.current.getBoundingClientRect());
      console.log("canvasref", canvasRef.current.getBoundingClientRect());
      // console.log("heightcamera", cameraRef.current.height);

      canvasRef.current.width = cameraRef.current.getBoundingClientRect().width;
      canvasRef.current.height =
        cameraRef.current.getBoundingClientRect().height;
      console.log("canvasref2", canvasRef.current);
      // canvasRef.current.bottom = cameraRef.current.getBoundingClientRect().bottom;
      // canvasRef.current.top = cameraRef.current.getBoundingClientRect().top;
      // canvasRef.current.left = cameraRef.current.getBoundingClientRect().left;
      // canvasRef.current.right = cameraRef.current.getBoundingClientRect().right;
      // canvasRef.current.x = cameraRef.current.getBoundingClientRect().x;
      // canvasRef.current.y = cameraRef.current.getBoundingClientRect().y;

      // Draw the entire image onto the canvas
      context.drawImage(cameraRef.current, 0, 0, 1470, 460);
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          setMedia((prevMedia) => ({
            ...setAllMediaToNull,
            capturedImageBlob: blob,
          }));
        } else {
          customSnackBar("Failed to create a blob from the canvas image.");
        }
      }, "image/jpeg");
      setPermissions(setAllPermissionsToFalse);
      setIsOpenedCamera(false);
      setIsMediaDisplayOpen(true);

      if (cameraRef.current) {
        const stream = cameraRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        cameraRef.current.srcObject = null;
      }
    } else {
      customSnackBar("Canvas reference is null or undefined");
    }
  };

  const handleStartCameraRecording = async () => {
    setIsOpenedCamera(true);
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      isVideoRecording: true,
    }));
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          if (!videoRef.current.paused) {
            videoRef.current.pause();
          }
          videoRef.current.play();
        };
        const recorder = new MediaRecorder(stream);
        const chunks = [];
        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };
        recorder.start();
        recorderRef.current = recorder;
        setSelectedType("camera");
        setMediaStream(stream); // Store the media stream in state
      } else {
        customSnackBar("videoRef.current is null or undefined");
        setPermissions(setAllPermissionsToFalse);
        setIsOpenedCamera(false);
      }
    } catch (error) {
      customSnackBar("Failed to access camera:", error);
      setPermissions(setAllPermissionsToFalse);
      setIsOpenedCamera(false);
    }
  };

  const handleStopCameraRecording = () => {
    // setPermissions(setAllPermissionsToFalse);

    if (!recorderRef.current || !permissions.isVideoRecording) return;
    const chunks = [];
    if (recorderRef.current.ondataavailable) {
      recorderRef.current.ondataavailable = (e) => {
        chunks.push(e.data);
      };
    }

    recorderRef.current.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: "video/webm" });
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        recordedVideoBlob: recordedBlob,
      }));
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        isVideoRecording: false,
      }));
      setIsMediaDisplayOpen(true);
      setShowFileDialog(false);
      setIsOpenedCamera(false);
      setMediaStream(null);
      setSelectedType(null);

      // Stop tracks and release resources
      const tracks = mediaStream?.getTracks();
      tracks && tracks.forEach((track) => track.stop());
      setMediaStream(null);

      // Additional check to stop camera
      // if (videoRef.current) {
      //   videoRef.current.srcObject = null;
      // }
    };

    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      console.log(
        tracks,
        videoRef,
        mediaStream?.getTracks(),
        mediaStream,
        "recorderRefrecorderRefrecorderRef"
      );
      tracks.forEach((track) => track.stop());
    }

    recorderRef.current.stop();
    // videoRef.current.stop();
  };

  const handleEditNote = () => {
    customSnackBar("We Are Working On It");
  };

  const handleDeleteNote = async (id) => {
    try {
      setFetchedData(true);
      const response = await instance.delete(
        `/notes_svc/pv/notes/?id=${id}&created_by=${bookingData.providerId}&created_by_type=1`
      );
      // const response = await instance.delete(`/notes_svc/pv/notes/?id=${id}`);
      // const data = await response.data;
      // setNotes(data.result);
      if (response.status === 200) {
        fetchNotesData();
      }
    } catch (error) {
      customSnackBar(error);
    } finally {
      setFetchedData(false);
    }
  };
  const renderDropdown = (item, position) => {
    console.log(item, "itemitem");
    return (
      <div
        className={`${position} pb-4 dropown`}
        style={{
          position: "relative",
        }}
        role="button"
        onClick={() => setMoreOptions(moreOptions ? null : item.id)}
      >
        <svg
          width="3"
          height="13"
          viewBox="0 0 3 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="#CCCCCC" />
          <circle cx="1.5" cy="6.5" r="1.5" fill="#CCCCCC" />
          <circle cx="1.5" cy="11.5" r="1.5" fill="#CCCCCC" />
        </svg>
        {moreOptions === item.id && (
          <div
            className="d-flex flex-column px-2 py-1"
            style={{
              position: "absolute",
              top: "-10%",
              right:
                position === "me-2"
                  ? item.created_by !== bookingData.providerId
                    ? ""
                    : "16px"
                  : "",
              left:
                position === "ms-2"
                  ? item.created_by !== bookingData.providerId
                    ? "16px"
                    : ""
                  : "",
              borderRadius: "10px",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
              zIndex: "1000000000",
              width: "120px",
            }}
          >
            <button
              className=" btn px-1 py-1 "
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
              onClick={handleEditNote}
            >
              <div className="d-flex">
                <img
                  src="/icons/notesedit.svg"
                  className="img-fluid pe-2"
                  alt=""
                />
                Edit
              </div>
            </button>
            {/* <hr
            className=" ms-0 mb-1"
            style={{
              color: "#c4c4c4",
              margin: "0 0 21.4px",
            }}
          /> */}
            <button
              className="btn   px-1 py-1"
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
              onClick={() => copyToClipboard(item?.notesContent?.text_data)}
            >
              <div className="d-flex">
                <img
                  src="/icons/notescopy.svg"
                  className="img-fluid pe-2"
                  alt=""
                />
                Copy
              </div>
            </button>
            {/* <hr
            className=" ms-0 mb-1"
            style={{
              color: "#c4c4c4",
              margin: "0 0 21.4px",
            }}
          /> */}
            <button
              className="btn   px-1 py-1"
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
              onClick={() => handleNoteDeletion(item.id)}
            >
              <div className="d-flex">
                <img
                  src="/icons/notesdelete.svg"
                  className="img-fluid pe-2"
                  alt=""
                />
                Delete
              </div>
            </button>
            {/* <hr
            className=" ms-0 mb-1"
            style={{
              color: "#c4c4c4",
              margin: "0 0 21.4px",
            }}
          /> */}
            <button
              className="btn   px-1 py-1"
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
            >
              <div className="d-flex">
                <img
                  src="/icons/notesshare.svg"
                  className="img-fluid pe-2"
                  alt=""
                />
                Share
              </div>
            </button>
            {/* <hr
            className=" ms-0 mb-1"
            style={{
              color: "#c4c4c4",
              margin: "0 0 21.4px",
            }}
          /> */}
            <button
              className="btn   px-1 py-1"
              style={{
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
            >
              <div className="d-flex">
                <img
                  src="/icons/notesselect.svg"
                  className="img-fluid pe-2"
                  alt=""
                />
                Select Notes
              </div>
            </button>
          </div>
        )}
      </div>
    );
  };
  const handleNoteDeletion = (id) => {
    // if (
    //   viewPermssion.canCompleteJob ||
    //   viewPermssion.canCompleteJob === null ||
    //   viewPermssion.canCompleteJob === undefined
    // ) {
    setDialog({
      visible: true,
      header: "Complete Job",
      message: "Are you sure you want to complete the job?",
      key: Math.random().toString(36).substring(7),
      ok: () => handleDeleteNote(id),
    });
    // } else {
    //   customSnackBar("Permission Denied");
    // }
  };
  var component = (
    <>
      <DialogBox
        {...dialog}
        header={"Delete Note"}
        message={"Do you want to delete the note?"}
      />
      <div className="notes_height m-0">
        {isOpenedCamera ? (
          <div className="h-100 pt-3 w-100 d-flex flex-column">
            <IconButton
              onClick={handleCloseCamera}
              sx={{
                backgroundColor: "transparent",
                padding: "2px",
                borderRadius: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              style={{
                position: "absolute",
                /* left: 0; */
                right: 40,
                zIndex: 99999,
              }}
            >
              <RxCross2
                style={{
                  color: "#667781",
                  fontSize: "40px",
                }}
              />
            </IconButton>
            {permissions.isVideoRecording ? (
              <video
                ref={videoRef}
                style={{ display: "block", height: "65vh", width: "100%" }}
              />
            ) : (
              <div>
                <video
                  ref={cameraRef}
                  style={{ display: "block", height: "65vh", width: "100%" }}
                  autoPlay
                />
                <canvas
                  ref={canvasRef}
                  style={{ display: "none", height: "65vh", width: "100%" }}
                ></canvas>
              </div>
            )}
            <div className="d-flex justify-content-evenly my-2">
              {permissions.isVideoRecording ? (
                <Tooltip title="Stop Video">
                  <IconButton
                    onClick={handleStopCameraRecording}
                    sx={{
                      backgroundColor: "#dc3545",
                      padding: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "#dc3545",
                      },
                    }}
                  >
                    <FaVideoSlash
                      style={{
                        color: "#ffff",
                        fontSize: "40px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Start Video">
                    <IconButton
                      onClick={handleStartCameraRecording}
                      sx={{
                        backgroundColor: "#548dff",
                        padding: "20px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#548dff",
                        },
                      }}
                    >
                      <FaVideo
                        style={{
                          color: "#ffff",
                          fontSize: "40px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Capture Image">
                    <IconButton
                      onClick={captureImage}
                      sx={{
                        backgroundColor: "#548dff",
                        padding: "20px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#548dff",
                        },
                      }}
                    >
                      <MdCameraAlt
                        style={{
                          color: "#ffff",
                          fontSize: "40px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  {/* <button className="btn btn-success" onClick={captureImage}>
              Capture Image
            </button> */}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="row p-0">
            <div class="col-12 col-md-3 py-3 ps-5">
              <LeftSection bookingData={bookingData} />
            </div>
            <div className="col-12 col-md-6 d-flex pt-3 justify-content-center">
              <Box
                sx={{
                  position: "relative",
                  width: "95%",
                  height: "85vh",
                  backgroundColor: "#ffff",
                  borderRadius: "10px",
                  boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
                  paddingBottom: "10px",
                }}
              >
                {isMediaDisplayOpen ? (
                  <div
                    style={{ height: "68vh" }}
                    className="d-flex pt-4 justify-content-center"
                  >
                    <IconButton
                      onClick={() => {
                        setIsMediaDisplayOpen(false);
                        setMedia(setAllMediaToNull);
                        setMessageInput("");
                        setPermissions(setAllPermissionsToFalse);
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        padding: "2px",
                        borderRadius: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      style={{
                        position: "absolute",
                        /* left: 0; */
                        right: 40,
                        zIndex: 99999,
                      }}
                    >
                      <RxCross2
                        style={{
                          color: "#667781",
                          fontSize: "40px",
                        }}
                      />
                    </IconButton>
                    {media.selectedImage && isMediaDisplayOpen && (
                      <MediaDisplay file={media.selectedImage} type="image" />
                    )}
                    {media.selectedAudio && isMediaDisplayOpen && (
                      <MediaDisplay file={media.selectedAudio} type="audio" />
                    )}
                    {media.selectedVideo && isMediaDisplayOpen && (
                      <MediaDisplay file={media.selectedVideo} type="video" />
                    )}
                    {<ImageBlob />}
                    {<AudioBlob />}
                    {<VideoBlob />}
                  </div>
                ) : (
                  <>
                    <IconButton
                      className="me-1"
                      // color={"primary"}
                      onClick={() =>
                        flag === "booking"
                          ? callBackHandler()
                          : history.goBack()
                      }
                      // onClick={() => history.goBack()}
                      sx={{
                        position: "absolute",
                        top: "1%",
                        left: "0.5%",
                        color: "#FFD705",
                        border: "0.5px solid #FFD705",
                        zIndex: 999999,
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                    <div
                      ref={messagesBoxRef}
                      style={{
                        height:
                          media.selectedImage !== null &&
                          media.selectedAudio !== null &&
                          media.selectedVideo !== null &&
                          media.recorderAudioBlob !== undefined &&
                          media.recordedVideoBlob !== undefined &&
                          media.capturedImageBlob !== undefined
                            ? "68vh"
                            : (media.recorderAudioBlob !== undefined ||
                                media.selectedAudio !== null) &&
                              media.selectedImage === null &&
                              media.selectedVideo === null &&
                              media.recordedVideoBlob === undefined &&
                              media.capturedImageBlob === undefined
                            ? "65vh"
                            : "72vh",
                        overflowY: "auto",
                        //   marginBottom: 2,
                        display: "flex",
                        flexDirection: "column-reverse",
                        scrollbarWidth: "thin",
                        scrollbarColor: "transparent transparent",
                        // position: "relative",
                        "&::-webkit-scrollbar": {
                          width: "1px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {/* <AudioWaveform fileURL={ "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3" }/> */}
                      <List>
                        {fetchedData ? (
                          <NotesLoading />
                        ) : (
                          notes.map((item, index) => {
                            console.log(item, "itemitem");
                            const isSender =
                              item.created_by === bookingData.providerId;
                            const messageTimestamp = new Date(
                              item.created_at
                            ).toLocaleTimeString();
                            const isReadMore = readMoreMap[item?.id];
                            // forurl();
                            return (
                              <div
                                className={`ps-4 pe-4 d-flex ${
                                  isSender
                                    ? "justify-content-end"
                                    : "justify-content-start"
                                } 
                `}
                              >
                                <ListItem
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection:
                                      item.created_by === bookingData.providerId
                                        ? "row-reverse"
                                        : "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      paddingTop: "16px",
                                      flexDirection:
                                        item.created_by ===
                                        bookingData.providerId
                                          ? "row"
                                          : "row-reverse",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      {" "}
                                      {/* {isSender !== bookingData.providerId && ( */}
                                      <Avatar
                                        // src={}
                                        style={{
                                          width: 35,
                                          height: 35,
                                          marginRight: "10px",
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </div>
                                    {isSender
                                      ? renderDropdown(item, "me-2")
                                      : renderDropdown(item, "ms-2")}
                                  </div>
                                  <div
                                    className={
                                      item.created_by === bookingData.providerId
                                        ? "text-end pt-3"
                                        : "text-start pt-3"
                                    }
                                  >
                                    <>
                                      <div className="text-right me-0">
                                        <div
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            fontSize: "10px",
                                            marginRight: "5px",
                                            marginLeft: "0px",
                                            paddingBottom: "8px",
                                            color: "rgb(171 166 166)",
                                          }}
                                        >
                                          {item.created_at === undefined
                                            ? "Just Now"
                                            : moment(
                                                messageTimestamp,
                                                "HH:mm:ss"
                                              ).format("HH:mm, DD MMMM YYYY")}
                                        </div>

                                        <div
                                          style={{
                                            marginLeft: "0px",
                                            marginRight: "0px",
                                            backgroundColor:
                                              item.created_by ===
                                              bookingData.providerId
                                                ? "#ECF3FE"
                                                : "#F6F6F6",
                                            padding: "2px 10px 2px 10px",
                                            borderRadius:
                                              item.created_by ===
                                              bookingData.providerId
                                                ? "16px 0px 16px 16px"
                                                : "0px 16px 16px 16px",
                                            // width: "fit-content",
                                            display: "inline-block",
                                            maxWidth: "100%",
                                            textAlign:
                                              item.created_by ===
                                              bookingData.providerId
                                                ? "right"
                                                : "left",
                                            // height: "80px",
                                            color:
                                              item.created_by ===
                                              bookingData.providerId
                                                ? "black"
                                                : "black",
                                          }}
                                        >
                                          {item.notesContent.audio_url !==
                                          null ? (
                                            <audio
                                              style={{
                                                padding: "5px",
                                                // maxWidth: "200px",
                                                // maxHeight: "200px",
                                                borderRadius: "16px",
                                              }}
                                              controls
                                              controlsList="nodownload"
                                            >
                                              <source
                                                src={
                                                  item.notesContent.audio_url
                                                }
                                                type="audio/webm"
                                              />
                                            </audio>
                                          ) : null}

                                          {item.notesContent.video_url !==
                                          null ? (
                                            <video
                                              controls
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                                maxWidth: "300px",
                                                padding: "10px",
                                                borderRadius: "16px",
                                              }}
                                            >
                                              <source
                                                src={
                                                  item.notesContent.video_url
                                                }
                                                type="video/mp4"
                                              />
                                            </video>
                                          ) : null}
                                          {item.notesContent.image_url !==
                                          null ? (
                                            <img
                                              src={item.notesContent.image_url}
                                              alt="Uploaded"
                                              style={{
                                                padding: "10px",
                                                maxWidth: "200px",
                                                maxHeight: "200px",
                                                borderRadius: "16px",
                                              }}
                                              onClick={() =>
                                                handleImageClick(
                                                  item.notesContent.image_url
                                                )
                                              }
                                            />
                                          ) : null}

                                          {item.notesContent.text_data !==
                                          null ? (
                                            <div className="d-flex align-items-center">
                                              {/* {item.created_by !== bookingData.providerId && (
                            <Avatar
                              src={item.created_by_img_url}
                              sx={{
                                width: 30,
                                height: 30,
                                marginRight: "10px",
                              }}
                            />
                          )} */}

                                              <ListItemText>
                                                {/* <div
                                    key={item?.id}
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item.created_by_name}
                                  </div> */}
                                                <Typography
                                                  sx={{
                                                    fontFamily: "Roboto",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    alignItems: "center",
                                                    display: "inline",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  {item.notesContent.text_data
                                                    .length > 1000 ? (
                                                    <>
                                                      {isReadMore
                                                        ? item.notesContent.text_data
                                                            .match(/.{1,60}/g) // Split the text into chunks of 60 characters
                                                            .map(
                                                              (
                                                                chunk,
                                                                index
                                                              ) => (
                                                                <React.Fragment
                                                                  key={index}
                                                                >
                                                                  {chunk}
                                                                  <br />
                                                                </React.Fragment>
                                                              )
                                                            )
                                                        : item.notesContent.text_data.slice(
                                                            0,
                                                            60
                                                          )}
                                                      {item.notesContent
                                                        .text_data.length >
                                                        60 && (
                                                        <span
                                                          onClick={() =>
                                                            toggleReadMore(
                                                              item.id
                                                            )
                                                          }
                                                          className="read-or-hide"
                                                        >
                                                          {isReadMore
                                                            ? "Read Less"
                                                            : "...Read More"}
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    item.notesContent.text_data
                                                      .match(/.{1,60}/g) // Split the text into chunks of 60 characters
                                                      .map((chunk, index) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          {chunk}
                                                          <br />
                                                        </React.Fragment>
                                                      ))
                                                  )}
                                                </Typography>
                                              </ListItemText>
                                              {/* {item.created_by === bookingData.providerId && (
                            <Avatar
                              src={item.created_by_img_url}
                              sx={{
                                width: 30,
                                height: 30,
                                marginLeft: "10px",
                              }}
                            />
                          )} */}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </ListItem>
                              </div>
                            );
                          })
                        )}
                      </List>
                    </div>
                  </>
                )}

                <hr
                  className="mt-3 ms-0 mb-3"
                  style={{
                    color: "#DCE3E9",
                    border: `1px solid #DCE3E9`,
                    margin: "15.6px 0 21.4px",
                  }}
                />

                <form onSubmit={handleSendMessage}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    {permissions.isAudioRecording ? (
                      <div
                        style={{
                          backgroundColor: "#FBFCFF",
                          //  height: "1.4375em",
                          borderRadius: "6px",
                          border: `0.4px solid #DCDCDC`,
                        }}
                        className=" w-100 d-flex justify-content-between"
                      >
                        <div
                          style={{ fontSize: "14px", color: "#535353" }}
                          className="mt-2 ms-3"
                        >
                          {formatTime()}
                        </div>
                        <div>
                          <IconButton
                            onClick={handleStopRecording}
                            color="inherit"
                          >
                            <CheckCircleOutlineRoundedIcon color="success" />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <OutlinedInput
                        // label="Message"
                        fullWidth
                        placeholder="Type Here..."
                        size="small"
                        variant="standard"
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        sx={{
                          marginRight: 1,
                          backgroundColor: "#FBFCFF",
                          borderRadius: "6px",
                          border: `1px solid ${
                            isFocused ? "#DCDCDC" : "#DCDCDC"
                          }`,
                          ".css-111djrg-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              border: "none",
                            },
                        }}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">
                            <div
                              // color="primary"
                              component="span"
                              aria-label="Attach File"
                              onClick={handleOpenMenu}
                              className="input_btn"
                            >
                              <img
                                src="/icons/notesclip.svg"
                                className="input_icon"
                              />
                            </div>
                            {!isMediaDisplayOpen && (
                              <div
                                // color={"primary"}
                                onClick={handleOpenCamera}
                                className="me-0 input_btn"
                                aria-label="Start Camera"

                                // sx={{
                                //   border: "1px solid #548DFF",
                                // }}
                              >
                                <img
                                  src="/icons/notescamera.svg"
                                  className="input_icon"
                                />
                              </div>
                            )}
                            {!permissions.isAudioRecording ? (
                              <div
                                className={`input_btn me-1${
                                  isMediaDisplayOpen ? "d-none" : ""
                                }`}
                                // color={"primary"}
                                onClick={handleStartRecording}
                                aria-label={"Start Recording"}
                                sx={{
                                  border: "none",
                                  boxshadow: "none",
                                }}
                              >
                                <img
                                  src="/icons/notesmic.svg"
                                  className={`${
                                    isMediaDisplayOpen ? "d-none" : ""
                                  } input_icon `}
                                  ref={recorderRef}
                                />
                              </div>
                            ) : (
                              <div className=" me-1"></div>
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                      />
                    )}

                    <Button
                      sx={{
                        backgroundColor: "#5599FF",
                        height: "35px",
                        width: "20px",
                        minWidth: "40px",
                        borderRadius: "4px",
                        textTransform: "capitalize",
                        zIndex: "99999",
                      }}
                      variant="contained"
                      type="submit"
                      aria-label="Send Message"
                      disabled={
                        !messageInput &&
                        !media.selectedVideo &&
                        !media.recorderAudioBlob &&
                        !media.selectedImage &&
                        !media.selectedAudio &&
                        !media.recordedVideoBlob &&
                        !media.capturedImageBlob
                      }
                      onClick={handleSendMessage}
                    >
                      {loading ? (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: "white",
                          }}
                        />
                      ) : (
                        <SendIcon
                          sx={{
                            fontSize: "14px",

                            ".css-owiivq-MuiSvgIcon-root": {
                              color: "white",
                              height: "20px",
                              width: "20px",
                              fontSize: "10px",
                            },
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                </form>
              </Box>

              <Box>
                <Menu
                  sx={{
                    ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                      {
                        borderRadius: "10px",
                        position: "absolute",
                        // top: "1%",
                        // left: "0.5%",
                        top: "1%",
                        left: "0.5%",
                        padding: "8px 0px 8px 0px",
                      },
                  }}
                  anchorEl={showMenu}
                  open={Boolean(showMenu)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                  //  onClick={()=>{
                  //   handleCloseMenu()
                  // }}
                  >
                    <label htmlFor="file-input">
                      <input
                        id="file-input"
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleVideoFile(e)}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <div className="d-flex">
                        {/* <VideoCallIcon color="info" sx={{ fontSize: "20px" }} /> */}
                        <Typography sx={{ fontSize: "12px" }}>
                          Select Video
                        </Typography>
                      </div>
                    </label>
                  </MenuItem>
                  <MenuItem
                  //  onClick={()=>{
                  //   handleCloseMenu()
                  // }}
                  >
                    <label htmlFor="image-input">
                      <input
                        id="image-input"
                        type="file"
                        accept="image/*,.gif"
                        onChange={handleImageFile}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      {/* <IconButton
                        color="primary"
                        component="span"
                        aria-label="Select Image"
                      > */}
                      <div className="d-flex">
                        {/* <ImageIcon color="primary" sx={{ fontSize: "20px" }} /> */}
                        <Typography sx={{ fontSize: "12px" }}>
                          Select Image
                        </Typography>
                      </div>
                      {/* </IconButton> */}
                    </label>
                  </MenuItem>
                  {/* <MenuItem onClick={() => fileInputRef.current?.click()}>
            <input
              id="image-input"
              type="file"
              // accept="image/*"
              onChange={(e) => handleImageFile(e)}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <ImageIcon color="primary" sx={{ fontSize: "30px" }} />
            <Typography>Select Image</Typography>
          </MenuItem> */}

                  <MenuItem
                  // onClick={()=>{
                  //   handleCloseMenu()
                  // }}
                  >
                    <label htmlFor="audio-input">
                      <input
                        id="audio-input"
                        type="file"
                        accept="audio/*"
                        onChange={(e) => handleAudioFile(e)}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <div className="d-flex">
                        {/* <AudiotrackIcon
                  color="warning"
                  size="12px"
                  sx={{ fontSize: "20px" }}
                /> */}
                        <Typography sx={{ fontSize: "12px" }}>
                          Select Audio
                        </Typography>
                      </div>
                    </label>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
            <div class="col-12 col-md-3 py-3 pe-5">
              <RightSection bookingData={bookingData} />
            </div>
          </div>
        )}
        {selectedImages && (
          <ZoomableImageModal
            imageUrl={selectedImages}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </>
  );
  if (flag === "booking") {
    return <>{component}</>;
  } else {
    return (
      <>
        <SideNavone>{component}</SideNavone>
      </>
    );
  }
};
export default Notes;
// import React, { useState, useRef, useEffect, useCallback } from "react";
// import SideNavone from "../../SideNavBar";
// import ChatNotes from "../../Componenets/ChatNotes";
// import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { customSnackBar } from "../../utils";
// import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
// import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
// import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import {
//   Avatar,
//   Box,
//   Button,
//   CircularProgress,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   InputAdornment,
//   List,
//   ListItem,
//   ListItemText,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   TextField,
//   Tooltip,
//   Typography,
// } from "@mui/material";

// import NotesLoading from "../../Componenets/NotesLoading";
// import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
// import { ArrowBack } from "@material-ui/icons";
// import MicIcon from "@mui/icons-material/Mic";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import SendIcon from "@mui/icons-material/Send";
// import ImageIcon from "@mui/icons-material/Image";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// import StopIcon from "@mui/icons-material/Stop";
// import VideoCallIcon from "@mui/icons-material/VideoCall";
// import AudiotrackIcon from "@mui/icons-material/Audiotrack";
// import ZoomableImageModal from "./ImageModal";
// import moment from "moment";
// import { RxCross2 } from "react-icons/rx";
// import { MdCameraAlt } from "react-icons/md";
// import { FaVideo, FaVideoSlash } from "react-icons/fa";
// import instance from "../../utils/utils";

// const Notes = ({ flag, setSelectSlotFlag, callBackHandler }) => {
//   const history = useHistory();
//   const [media, setMedia] = useState({
//     recordedVideoBlob: undefined,
//     recorderAudioBlob: undefined,
//     capturedImageBlob: undefined,
//     selectedVideo: null,
//     selectedAudio: null,
//     selectedImage: null,
//   });
//   const [permissions, setPermissions] = useState({
//     isAudioRecording: false,
//     isVideoRecording: false,
//     isCapturingImage: false,
//   });
//   const [isMediaDisplayOpen, setIsMediaDisplayOpen] = useState(false);

//   const [timer, setTimer] = useState(0);
//   const [isOpenedCamera, setIsOpenedCamera] = useState(false);
//   const canvasRef = useRef(null);
//   const bookingData = useSelector((state) => state.bookingReducer.bookingData);
//   const [messageInput, setMessageInput] = useState("");
//   const [selectedType, setSelectedType] = useState(null);
//   const [fetchedData, setFetchedData] = useState(false);
//   const [mediaStream, setMediaStream] = useState(null);
//   const [notes, setNotes] = useState([]);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isReadMore, setIsReadMore] = useState(true);

//   const [showFileDialog, setShowFileDialog] = useState(false);
//   const [moreOptions, setMoreOptions] = useState(null);
//   const [loading, SetLoading] = useState(false);
//   const recorderRef = useRef(null);
//   const videoRef = useRef(null);
//   const cameraRef = useRef(null);
//   const messagesBoxRef = useRef();

//   const fileInputRef = useRef(null);
//   const [showMenu, setShowMenu] = useState(null);

//   const [selectedImages, setSelectedImage] = useState(null);
//   const [openAction, setOpenAction] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [readMoreMap, setReadMoreMap] = useState({});

//   const handleImageClick = (imageUrl) => {
//     setSelectedImage(imageUrl);
//   };
//   const handleOpenMenu = (event) => {
//     setShowMenu(event.currentTarget);
//   };

//   const toggleReadMore = (messageId) => {
//     setReadMoreMap((prev) => ({
//       ...prev,
//       [messageId]: !prev[messageId],
//     }));
//   };

//   const handleCloseMenu = () => {
//     setShowMenu(null);
//   };
//   const handleCloseModal = () => {
//     setSelectedImage(null);
//   };
//   const MediaDisplay = ({ file, type }) => {
//     if (type === "image") {
//       return (
//         <div className="">
//           {/* First Image */}
//           <div>
//             <img
//               src={URL.createObjectURL(file)}
//               alt="Selected"
//               width="500"
//               height="400"
//               style={{
//                 borderRadius: "5px",
//               }}
//             />
//           </div>

//           {/* Second Image */}
//           <div className="d-flex justify-content-center pt-3">
//             <img
//               src={URL.createObjectURL(file)}
//               alt="Selected"
//               width="100"
//               height="100"
//               style={{
//                 borderRadius: "5px",
//                 marginRight: "10px",
//               }}
//             />

//             <div
//               onClick={() => {
//                 customSnackBar("Working On it.");
//               }}
//               style={{
//                 padding: "40px",
//                 backgroundColor: "#5599FF1A",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               <input
//                 id="image-input"
//                 type="file"
//                 accept="image/*,.gif"
//                 onChange={handleImageFile}
//                 style={{ display: "none" }}
//                 ref={fileInputRef}
//               />
//               <AddCircleOutlineRoundedIcon color="primary" />
//             </div>
//           </div>
//         </div>
//       );
//     } else if (type === "video") {
//       return (
//         <div className="">
//           <div>
//             <video
//               width={600}
//               height={400}
//               controls
//               style={{
//                 borderRadius: "5px",
//               }}
//             >
//               <source src={URL.createObjectURL(file)} type="video/mp4" />
//             </video>
//           </div>
//           <div className="d-flex justify-content-center pt-3">
//             <video
//               width={100}
//               height={100}
//               controls
//               style={{
//                 borderRadius: "5px",
//                 marginRight: "10px",
//               }}
//             >
//               <source src={URL.createObjectURL(file)} type="video/mp4" />
//             </video>

//             <div
//               onClick={() => {
//                 customSnackBar("Working On it.");
//               }}
//               style={{
//                 padding: "40px",
//                 backgroundColor: "#5599FF1A",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               <input
//                 id="image-input"
//                 type="file"
//                 accept="image/*,.gif"
//                 onChange={handleImageFile}
//                 style={{ display: "none" }}
//                 ref={fileInputRef}
//               />
//               <AddCircleOutlineRoundedIcon color="primary" />
//             </div>
//           </div>
//         </div>
//       );
//     } else if (type === "audio") {
//       return (
//         <div className="">
//           {/* First Audio */}
//           <div>
//             <div className="audio-container">
//               <audio controls>
//                 <source src={URL.createObjectURL(file)} type="audio/mpeg" />
//               </audio>
//             </div>
//           </div>

//           {/* Second Audio */}
//           <div className="d-flex justify-content-center pt-3">
//             <div className="audio-container">
//               <audio controls>
//                 <source src={URL.createObjectURL(file)} type="audio/mpeg" />
//               </audio>
//               <div className="no-preview-label">No Preview Available</div>
//             </div>

//             <div
//               onClick={() => {
//                 customSnackBar("Working On it.");
//               }}
//               style={{
//                 padding: "40px",
//                 backgroundColor: "#5599FF1A",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               <input
//                 id="image-input"
//                 type="file"
//                 accept="image/*,.gif"
//                 onChange={handleImageFile}
//                 style={{ display: "none" }}
//                 ref={fileInputRef}
//               />
//               <AddCircleOutlineRoundedIcon color="primary" />
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return null;
//     }
//   };

//   const ImageBlob = (blob) => {
//     if (media.capturedImageBlob && media.capturedImageBlob instanceof Blob) {
//       var url = window.URL.createObjectURL(media.capturedImageBlob);
//       return (
//         <img
//           src={url}
//           style={{
//             borderRadius: "5px",
//             marginTop: "20px",
//             width: "80%",
//             height: "100%",
//           }}
//           alt="Selected"
//           width="500px"
//           height="500px"
//         />
//       );
//     } else {
//       return null;
//     }
//   };

//   const AudioBlob = () => {
//     if (media.recorderAudioBlob && media.recorderAudioBlob instanceof Blob) {
//       var url = window.URL.createObjectURL(media.recorderAudioBlob);
//       return (
//         <audio controls>
//           <source src={url} type="audio/mpeg" />
//         </audio>
//       );
//     } else {
//       return null;
//     }
//   };

//   const handleOpenCamera = () => {
//     setIsOpenedCamera(true);
//     handleStartCameraForCapture();
//   };

//   // const handleCloseCamera = () => {
//   //   setIsOpenedCamera(false);
//   //   setPermissions(setAllPermissionsToFalse);
//   // };
//   const handleCloseCamera = () => {
//     setIsOpenedCamera(false);
//     setPermissions(setAllPermissionsToFalse);

//     // Stop tracks and release resources
//     const tracks = cameraRef.current.srcObject?.getTracks();
//     tracks && tracks.forEach((track) => track.stop());

//     cameraRef.current.srcObject = null;
//   };

//   const VideoBlob = () => {
//     if (media.recordedVideoBlob && media.recordedVideoBlob instanceof Blob) {
//       var url = window.URL.createObjectURL(media.recordedVideoBlob);
//       return (
//         <video
//           style={{
//             borderRadius: "5px",
//             marginTop: "20px",
//             width: "80%",
//             height: "100%",
//           }}
//           alt="Selected"
//           width={"80%"}
//           height={"100%"}
//           controls
//         >
//           <source src={url} type="video/mp4" />
//         </video>
//       );
//     } else {
//       return null;
//     }
//   };

//   const fetchNotesData = useCallback(async () => {
//     console.log("I AM HERE",bookingData);
//     try {
//       setFetchedData(true);
//       let url = `/notes_svc/pv/notes/?id=${bookingData.plexaarBookingId}&type=booking&timezone=Europe%2FLondon&plexaar_sales_order_id=${bookingData.plexaarSalesOrderId}`;

//       if (bookingData.purchaseOrderId !== null) {
//         url += `&purchase_order_id=${bookingData.purchaseOrderId}`;
//       }
//       // let url =

//       //   bookingData.plexaarBookingId === null
//       //     ? `/notes_svc/pv/notes/?id=${bookingData.plexaarSalesOrderId}&type=booking&timezone=Europe%2FLondon&plexaar_sales_order_id=${bookingData.plexaarSalesOrderId}`
//       //     : `/notes_svc/pv/notes/?id=${
//       //         bookingData.plexaarBookingId === null
//       //           ? bookingData.plexaarSalesOrderId
//       //           : bookingData.plexaarBookingId
//       //       }`;

//       // if (bookingData.plexaarBookingId !== null) {
//       //   url += `&plexaar_sales_order_id=${bookingData.plexaarSalesOrderId}`;
//       // }
//       // if (bookingData.plexaarBookingId !== null) {
//       //   url += `&type=booking&getting_by_type=provider&getting_by=${bookingData.providerId}&timezone=Europe/London`;
//       // }
//       const response = await instance.get(url);
//       const data = await response.data;
//       setNotes(data.result);
//     } catch (e) {
//     } finally {
//       setFetchedData(false);
//     }
//   }, [bookingData]);
//   useEffect(() => {
//     fetchNotesData();
//   }, [fetchNotesData]);
//   const setAllPermissionsToFalse = {
//     isAudioRecording: false,
//     isVideoRecording: false,
//     isCapturingImage: false,
//   };

//   const setAllMediaToNull = {
//     recordedVideoBlob: undefined,
//     recorderAudioBlob: undefined,
//     capturedImageBlob: undefined,
//     selectedVideo: null,
//     selectedAudio: null,
//     selectedImage: null,
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();

//     if (!messageInput.trim() && !Object.values(media).some(Boolean)) return;

//     SetLoading(true);

//     const fd = new FormData();
//     fd.append("created_by", bookingData.providerId);
//     fd.append(
//       "created_for",
//       bookingData?.plexaarBookingId === null
//         ? bookingData.plexaarSalesOrderId
//         : bookingData.plexaarBookingId
//     );
//     fd.append("created_by_type", 1);
//     fd.append(
//       "created_for_type",
//       bookingData.plexaarBookingId === null ? 5 : 4
//     );
//     // fd.append("is_shown", bookingData.plexaarBookingId === null ? true : false);
//     fd.append("is_shown", true);
//     fd.append("created_by_name", bookingData?.providerName);
//     fd.append("created_by_img_url", bookingData?.providerImage);
//     fd.append("is_expert0_plexaar1", true);

//     const uploadFile = async (file, fileType, noteType) => {
//       const formData = new FormData();
//       formData.append("Id", bookingData.providerId);
//       formData.append("serviceName", "notes");
//       formData.append("source", "Plexaar");
//       formData.append("Files", file, `attachment.${fileType}`);
//       formData.append("Category", "main");
//       formData.append("FileType", fileType);
//       formData.append("CreatedBy", bookingData.providerId);

//       const response = await instance.post(
//         `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       if (response.data.code === 0) {
//         fd.append(noteType, response.data.paths[0]);
//       } else {
//         throw new Error("Error uploading file");
//         setIsMediaDisplayOpen(false);
//       }
//     };

//     try {
//       if (media.selectedImage || media.capturedImageBlob) {
//         await uploadFile(
//           media.selectedImage || media.capturedImageBlob,
//           "jpg",
//           "image"
//         );
//         fd.append("note_type", 2);
//       } else if (media.selectedAudio || media.recorderAudioBlob) {
//         await uploadFile(
//           media.selectedAudio || media.recorderAudioBlob,
//           "mp3",
//           "audio"
//         );
//         fd.append("note_type", 3);
//       } else if (media.selectedVideo || media.recordedVideoBlob) {
//         if (media.selectedVideo.size > 30 * 1024 * 1024) {
//           customSnackBar("Video file size exceeds the 30MB limit.");
//         }
//         await uploadFile(
//           media.selectedVideo || media.recordedVideoBlob,
//           "mp4",
//           "video"
//         );
//         // SetLoading(false);

//         fd.append("note_type", 4);
//       } else {
//         fd.append("note_type", 1);
//         fd.append("text", messageInput);
//       }

//       const response = await instance.post(`/notes_svc/pv/notes/`, fd, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       if (response.data.code === 0) {
//         setMedia(setAllMediaToNull);
//         setMessageInput("");
//         setPermissions(setAllPermissionsToFalse);
//         setIsMediaDisplayOpen(false);

//         messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
//         fetchNotesData();
//       }
//     } catch (error) {
//       customSnackBar(error.message);
//     }

//     setSelectedType(null);
//     SetLoading(false);
//   };

//   const handleImageFile = async (e) => {
//     const file = e.target.files?.[0];
//     const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

//     if (file && allowedFileTypes.includes(file.type)) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedImage: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//       setShowFileDialog(false);
//       handleCloseMenu();
//       setIsMediaDisplayOpen(true);
//     } else {
//       customSnackBar("Format not supported");
//     }
//   };

//   const handleAudioFile = (e) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedAudio: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//       setShowFileDialog(false);
//       handleCloseMenu();
//       setIsMediaDisplayOpen(true);
//     }
//   };

//   const handleVideoFile = (e) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedVideo: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//       setShowFileDialog(false);
//       handleCloseMenu();
//       setIsMediaDisplayOpen(true);
//     }
//   };

//   const handleStartRecording = async () => {
//     // try {
//     //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     //     recorderRef.current = new MediaRecorder(stream);
//     //     const chunks = [];
//     //   recorderRef.current.ondataavailable = (e) => {
//     //       if(e.data.size > 0){
//     //           chunks.push(e.data);
//     //       }
//     //   };
//     //   recorderRef.current.onstop = () => {
//     //       const recorded = new Blob(chunks, {type: 'audio/wav'});
//     //       setMedia((prev)=>({
//     //           ...setAllMediaToNull,
//     //           recorderAudioBlob: recorded,
//     //       }));
//     //   };
//     //     recorderRef.current.start();
//     //     setPermissions((prevState) => ({
//     //       ...setAllPermissionsToFalse,
//     //       isAudioRecording: true,
//     //     }));
//     //     setSelectedType("voice");
//     //     setMediaStream(stream); // Store the media stream in state
//     //   } catch (error) {
//     //       customSnackBar(`Failed to access microphone:${error}`);
//     //       setPermissions(setAllPermissionsToFalse);
//     //   }
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const recorder = new MediaRecorder(stream);
//       let startTime = Date.now();
//       let totalSeconds = 0;

//       recorder.onstart = () => {
//         startTime = Date.now();
//         setTimer(0);
//         const intervalId = setInterval(() => {
//           totalSeconds = Math.floor((Date.now() - startTime) / 1000);
//           setTimer(totalSeconds);
//           // setTimer(Math.floor((Date.now() - startTime) / 1000));
//           // if (totalSeconds >= 60) {
//           //   // Automatically stop recording after 3 minutes (180 seconds)
//           //   handleStopRecording();
//           // }
//         }, 1000);
//         recorder.intervalId = intervalId;
//       };
//       recorder.start();
//       setPermissions((prevState) => ({
//         ...setAllPermissionsToFalse,
//         isAudioRecording: true,
//       }));
//       setMediaStream(stream); // Store the media stream in state

//       recorderRef.current = recorder;
//     } catch (error) {
//       customSnackBar(`Failed to access microphone:${error}`);
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };
//   const formatTime = () => {
//     const minutes = Math.floor(timer / 60);
//     const seconds = timer % 60;

//     const formattedMinutes = String(minutes).padStart(2, "0");
//     const formattedSeconds = String(seconds).padStart(2, "0");

//     return `${formattedMinutes}:${formattedSeconds}`;
//   };

//   const handleStopRecording = () => {
//     if (!recorderRef.current || !permissions.isAudioRecording) return;

//     const recorder = recorderRef.current;
//     recorder.ondataavailable = (e) => {
//       const chunks = [];
//       chunks.push(e.data);
//       const recordedBlob = new Blob(chunks, { type: "audio/webm" });
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         recorderAudioBlob: recordedBlob,
//       }));
//       setIsMediaDisplayOpen(true);

//       // Stop tracks and release resources
//       const tracks = mediaStream?.getTracks();
//       tracks && tracks.forEach((track) => track.stop());
//       setMediaStream(null);
//     };

//     clearInterval(recorder.intervalId);
//     setPermissions(setAllPermissionsToFalse);
//     if (recorderRef.current && recorderRef.current.srcObject) {
//       const stream = recorderRef.current.srcObject;
//       const tracks = stream.getTracks();
//       tracks.forEach((track) => track.stop());
//       recorderRef.current.srcObject = null;
//     }
//     // recorderRef.current.stop();
//     recorder.stop();
//   };

//   const handleStartCameraForCapture = async () => {
//     try {
//       setPermissions((prevState) => ({
//         ...setAllPermissionsToFalse,
//         isCapturingImage: true,
//       }));
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//       });
//       if (cameraRef.current) {
//         cameraRef.current.srcObject = stream;
//       }
//     } catch (err) {
//       customSnackBar("Error Accessing Camers:", err);
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const captureImage = async () => {
//     if (canvasRef.current) {
//       const context = canvasRef.current.getContext("2d");
//       context.drawImage(cameraRef.current, 0, 0, 1000, 1000);
//       canvasRef.current.toBlob((blob) => {
//         if (blob) {
//           setMedia((prevMedia) => ({
//             ...setAllMediaToNull,
//             capturedImageBlob: blob,
//           }));
//         } else {
//           customSnackBar("Failed to create a blob from the canvas image.");
//         }
//       }, "image/jpeg");
//       setPermissions(setAllPermissionsToFalse);
//       setIsOpenedCamera(false);
//       setIsMediaDisplayOpen(true);

//       if (cameraRef.current) {
//         const stream = cameraRef.current.srcObject;
//         if (stream) {
//           const tracks = stream.getTracks();
//           tracks.forEach((track) => track.stop());
//         }
//         cameraRef.current.srcObject = null;
//       }
//     } else {
//       customSnackBar("Canvas reference is null or undefined");
//     }
//   };

//   const handleStartCameraRecording = async () => {
//     setIsOpenedCamera(true);
//     setPermissions((prevPermissions) => ({
//       ...setAllPermissionsToFalse,
//       isVideoRecording: true,
//     }));
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         audio: true,
//         video: true,
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.onloadedmetadata = () => {
//           if (!videoRef.current.paused) {
//             videoRef.current.pause();
//           }
//           videoRef.current.play();
//         };
//         const recorder = new MediaRecorder(stream);
//         const chunks = [];
//         recorder.ondataavailable = (e) => {
//           chunks.push(e.data);
//         };
//         recorder.start();
//         recorderRef.current = recorder;
//         setSelectedType("camera");
//         setMediaStream(stream); // Store the media stream in state
//       } else {
//         customSnackBar("videoRef.current is null or undefined");
//         setPermissions(setAllPermissionsToFalse);
//         setIsOpenedCamera(false);
//       }
//     } catch (error) {
//       customSnackBar("Failed to access camera:", error);
//       setPermissions(setAllPermissionsToFalse);
//       setIsOpenedCamera(false);
//     }
//   };

//   const handleStopCameraRecording = () => {
//     if (!recorderRef.current || !permissions.isVideoRecording) return;

//     const chunks = [];
//     if (recorderRef.current.ondataavailable) {
//       recorderRef.current.ondataavailable = (e) => {
//         chunks.push(e.data);
//       };
//     }

//     recorderRef.current.onstop = () => {
//       const recordedBlob = new Blob(chunks, { type: "video/webm" });
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         recordedVideoBlob: recordedBlob,
//       }));
//       setIsMediaDisplayOpen(true);
//       // setPermissions(setAllPermissionsToFalse);
//       setPermissions((prevPermissions) => ({
//         ...prevPermissions,
//         isVideoRecording: false,
//       }));
//       setShowFileDialog(false);
//       setIsOpenedCamera(false);
//       setMediaStream(null);
//       setSelectedType(null);

//       // Stop tracks and release resources
//       const tracks = mediaStream?.getTracks();
//       tracks && tracks.forEach((track) => track.stop());
//       setMediaStream(null);

//       // Additional check to stop camera
//       // if (videoRef.current) {
//       //   videoRef.current.srcObject = null;
//       // }
//     };

//     if (videoRef.current && videoRef.current.srcObject) {
//       const stream = videoRef.current.srcObject;
//       const tracks = stream.getTracks();

//       tracks.forEach((track) => track.stop());
//     }

//     recorderRef.current.stop();
//     // videoRef.current.stop();
//   };

//   const handleEditNote = () => {
//     customSnackBar("We Are Working On It");
//   };

//   const handleDeleteNote = async (id) => {
//     try {
//       setFetchedData(true);
//       const response = await instance.delete(`/notes_svc/pv/notes/?id=${id}`);
//       // const data = await response.data;
//       // setNotes(data.result);
//       if (response.status === 200) {
//         fetchNotesData();
//       }
//     } catch (error) {
//       customSnackBar(error);
//     } finally {
//       setFetchedData(false);
//     }
//   };
//   const renderDropdown = (item, position) => (
//     <div
//       className={`${position} mb-4 dropown`}
//       style={{
//         position: "relative",
//       }}
//       role="button"
//       onClick={() => setMoreOptions(moreOptions ? null : item.id)}
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         height="16"
//         viewBox="0 -960 960 960"
//         width="16"
//       >
//         <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
//       </svg>
//       {moreOptions === item.id && (
//         <div
//           className="d-flex flex-column px-2 py-1"
//           style={{
//             position: "absolute",
//             top: "-10%",
//             right:
//               position === "me-2"
//                 ? item.created_by !== bookingData.providerId
//                   ? ""
//                   : "16px"
//                 : "",
//             left:
//               position === "ms-2"
//                 ? item.created_by !== bookingData.providerId
//                   ? "16px"
//                   : ""
//                 : "",
//             borderRadius: "10px",
//             border: "0.5px solid gray",
//             backgroundColor: "white",
//             zIndex: "1000000000",
//           }}
//         >
//           <button
//             className=" btn  px-4 py-1 "
//             style={{
//               backgroundColor: "transparent",
//               fontSize: "12px",
//             }}
//             onClick={handleEditNote}
//           >
//             <div className="d-flex">
//               <ModeEditRoundedIcon color="info" size={14} />
//               Edit
//             </div>
//           </button>
//           <hr
//             className=" ms-0 mb-1"
//             style={{
//               color: "#c4c4c4",
//               margin: "0 0 21.4px",
//             }}
//           />
//           <button
//             className="btn   px-4 py-1"
//             style={{
//               backgroundColor: "transparent",
//               fontSize: "12px",
//             }}
//             onClick={() => handleDeleteNote(item.id)}
//           >
//             <div className="d-flex">
//               <DeleteOutlineRoundedIcon size={14} color="error" />
//               Delete
//             </div>
//           </button>
//         </div>
//       )}
//     </div>
//   );

//   var component = (
//     <>
//       {" "}
//       {isOpenedCamera ? (
//         <div className="h-100 pt-3 w-100 d-flex flex-column">
//           <IconButton
//             onClick={handleCloseCamera}
//             sx={{
//               backgroundColor: "transparent",
//               padding: "2px",
//               borderRadius: "20%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               "&:hover": {
//                 backgroundColor: "transparent",
//               },
//             }}
//             style={{
//               position: "absolute",
//               /* left: 0; */
//               right: 40,
//               zIndex: 99999,
//             }}
//           >
//             <RxCross2
//               style={{
//                 color: "#667781",
//                 fontSize: "40px",
//               }}
//             />
//           </IconButton>
//           {permissions.isVideoRecording ? (
//             <video
//               ref={videoRef}
//               style={{ display: "block", height: "75vh", width: "100%" }}
//             />
//           ) : (
//             <div>
//               <video
//                 ref={cameraRef}
//                 style={{ display: "block", height: "75vh", width: "100%" }}
//                 autoPlay
//               />
//               <canvas
//                 ref={canvasRef}
//                 style={{ display: "none", height: "75vh", width: "100%" }}
//               ></canvas>
//             </div>
//           )}
//           <div className="d-flex justify-content-evenly my-2">
//             {permissions.isVideoRecording ? (
//               <Tooltip title="Stop Video">
//                 <IconButton
//                   onClick={handleStopCameraRecording}
//                   sx={{
//                     backgroundColor: "#dc3545",
//                     padding: "20px",
//                     borderRadius: "50%",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     "&:hover": {
//                       backgroundColor: "#dc3545",
//                     },
//                   }}
//                 >
//                   <FaVideoSlash
//                     style={{
//                       color: "#ffff",
//                       fontSize: "40px",
//                     }}
//                   />
//                 </IconButton>
//               </Tooltip>
//             ) : (
//               <>
//                 <Tooltip title="Start Video">
//                   <IconButton
//                     onClick={handleStartCameraRecording}
//                     sx={{
//                       backgroundColor: "#548dff",
//                       padding: "20px",
//                       borderRadius: "50%",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       "&:hover": {
//                         backgroundColor: "#548dff",
//                       },
//                     }}
//                   >
//                     <FaVideo
//                       style={{
//                         color: "#ffff",
//                         fontSize: "40px",
//                       }}
//                     />
//                   </IconButton>
//                 </Tooltip>
//                 <Tooltip title="Capture Image">
//                   <IconButton
//                     onClick={captureImage}
//                     sx={{
//                       backgroundColor: "#548dff",
//                       padding: "20px",
//                       borderRadius: "50%",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       "&:hover": {
//                         backgroundColor: "#548dff",
//                       },
//                     }}
//                   >
//                     <MdCameraAlt
//                       style={{
//                         color: "#ffff",
//                         fontSize: "40px",
//                       }}
//                     />
//                   </IconButton>
//                 </Tooltip>
//                 {/* <button className="btn btn-success" onClick={captureImage}>
//               Capture Image
//             </button> */}
//               </>
//             )}
//           </div>
//         </div>
//       ) : (
//         <div className="d-flex pt-2 justify-content-center">
//           <Box
//             sx={{
//               position: "relative",
//               width: "95%",
//               height: "85vh",
//               overflowY: "auto",
//               backgroundColor: "#ffff",
//               borderRadius: "10px",
//               boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
//               paddingBottom: "10px",
//             }}
//           >
//             {isMediaDisplayOpen ? (
//               <div
//                 style={{ height: "70vh" }}
//                 className="d-flex pt-4 justify-content-center"
//               >
//                 <IconButton
//                   onClick={() => {
//                     setIsMediaDisplayOpen(false);
//                     setMedia(setAllMediaToNull);
//                     setMessageInput("");
//                     setPermissions(setAllPermissionsToFalse);
//                   }}
//                   sx={{
//                     backgroundColor: "transparent",
//                     padding: "2px",
//                     borderRadius: "20%",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     "&:hover": {
//                       backgroundColor: "transparent",
//                     },
//                   }}
//                   style={{
//                     position: "absolute",
//                     /* left: 0; */
//                     right: 40,
//                     zIndex: 99999,
//                   }}
//                 >
//                   <RxCross2
//                     style={{
//                       color: "#667781",
//                       fontSize: "40px",
//                     }}
//                   />
//                 </IconButton>
//                 {media.selectedImage && isMediaDisplayOpen && (
//                   <MediaDisplay file={media.selectedImage} type="image" />
//                 )}
//                 {media.selectedAudio && isMediaDisplayOpen && (
//                   <MediaDisplay file={media.selectedAudio} type="audio" />
//                 )}
//                 {media.selectedVideo && isMediaDisplayOpen && (
//                   <MediaDisplay file={media.selectedVideo} type="video" />
//                 )}
//                 {<ImageBlob />}
//                 {<AudioBlob />}
//                 {<VideoBlob />}
//               </div>
//             ) : (
//               <>
//                 <IconButton
//                   className="me-1"
//                   // color={"primary"}
//                   onClick={() =>
//                     flag === "booking" ? callBackHandler() : history.goBack()
//                   }
//                   sx={{
//                     position: "absolute",
//                     top: "1%",
//                     left: "0.5%",
//                     color: "#FFD705",
//                     border: "0.5px solid #FFD705",
//                     zIndex: 999999,
//                   }}
//                 >
//                   <ArrowBack />
//                 </IconButton>
//                 <Box
//                   ref={messagesBoxRef}
//                   sx={{
//                     height:
//                       media.selectedImage !== null &&
//                       media.selectedAudio !== null &&
//                       media.selectedVideo !== null &&
//                       media.recorderAudioBlob !== undefined &&
//                       media.recordedVideoBlob !== undefined &&
//                       media.capturedImageBlob !== undefined
//                         ? "68vh"
//                         : (media.recorderAudioBlob !== undefined ||
//                             media.selectedAudio !== null) &&
//                           media.selectedImage === null &&
//                           media.selectedVideo === null &&
//                           media.recordedVideoBlob === undefined &&
//                           media.capturedImageBlob === undefined
//                         ? "65vh"
//                         : "70vh",
//                     overflowY: "auto",
//                     //   marginBottom: 2,
//                     display: "flex",
//                     flexDirection: "column-reverse",
//                     scrollbarWidth: "thin",
//                     scrollbarColor: "transparent transparent",
//                     // position: "relative",
//                     "&::-webkit-scrollbar": {
//                       width: "1px",
//                     },
//                     "&::-webkit-scrollbar-thumb": {
//                       backgroundColor: "transparent",
//                     },
//                   }}
//                 >
//                   <List>
//                     {fetchedData ? (
//                       <NotesLoading />
//                     ) : (
//                       notes.map((item, index) => {
//                         const isSender =
//                           item.created_by === bookingData.providerId;
//                         const messageTimestamp = new Date(
//                           item.created_at
//                         ).toLocaleTimeString();
//                         const isReadMore = readMoreMap[item?.id];

//                         return (
//                           <div
//                             className={`ps-4 pe-4 d-flex ${
//                               isSender
//                                 ? "justify-content-end"
//                                 : "justify-content-start"
//                             }
//                 `}
//                           >
//                             <ListItem
//                               key={index}
//                               sx={{
//                                 display: "flex",
//                                 flexDirection:
//                                   item.created_by === bookingData.providerId
//                                     ? "row-reverse"
//                                     : "row",

//                                 // textAlign: item.created_by === bookingData.providerId ? "right": "start",
//                               }}
//                             >
//                               {isSender
//                                 ? renderDropdown(item, "me-2")
//                                 : renderDropdown(item, "ms-2")}

//                               <div
//                                 // className="d-flex align-items-center"
//                                 style={{
//                                   marginBottom: "8px",
//                                 }}
//                               >
//                                 {" "}
//                                 {/* {isSender !== bookingData.providerId && ( */}
//                                 <Avatar
//                                   // src={item.created_by_img_url}
//                                   sx={{
//                                     width: 40,
//                                     height: 40,
//                                     marginRight: "10px",
//                                     marginLeft: "10px",
//                                   }}
//                                 />
//                                 {/* )} */}
//                                 <div
//                                   style={{
//                                     fontStyle: "normal",
//                                     fontWeight: 500,
//                                     fontSize: "10px",
//                                     marginRight: "10px",
//                                     marginLeft: "10px",
//                                     color: "rgb(171 166 166)",
//                                   }}
//                                 >
//                                   {item.created_at === undefined
//                                     ? "Just Now"
//                                     : moment(
//                                         messageTimestamp,
//                                         "HH:mm:ss"
//                                       ).format("h:mm A")}
//                                 </div>
//                               </div>
//                               <Box
//                                 component="div"
//                                 sx={{
//                                   backgroundColor:
//                                     item.created_by === bookingData.providerId
//                                       ? "#ECF3FE"
//                                       : "#F6F6F6",
//                                   padding: "2px 10px 2px 10px",
//                                   borderRadius:
//                                     item.created_by === bookingData.providerId
//                                       ? "16px 0px 16px 16px"
//                                       : "0px 16px 16px 16px",
//                                   width: "fit-content",
//                                   color:
//                                     item.created_by === bookingData.providerId
//                                       ? "black"
//                                       : "black",
//                                 }}
//                               >
//                                 {item.notesContent.audio_url !== null ? (
//                                   <audio
//                                     style={{
//                                       padding: "5px",
//                                       // maxWidth: "200px",
//                                       // maxHeight: "200px",
//                                       borderRadius: "16px",
//                                     }}
//                                     controls
//                                     controlsList="nodownload"
//                                   >
//                                     <source
//                                       src={item.notesContent.audio_url}
//                                       type="audio/webm"
//                                     />
//                                   </audio>
//                                 ) : null}

//                                 {item.notesContent.video_url !== null ? (
//                                   <video
//                                     controls
//                                     style={{
//                                       width: "100%",
//                                       height: "auto",
//                                       maxWidth: "300px",
//                                       padding: "10px",
//                                       borderRadius: "16px",
//                                     }}
//                                   >
//                                     <source
//                                       src={item.notesContent.video_url}
//                                       type="video/mp4"
//                                     />
//                                   </video>
//                                 ) : null}
//                                 {item.notesContent.image_url !== null ? (
//                                   <img
//                                     src={item.notesContent.image_url}
//                                     alt="Uploaded"
//                                     style={{
//                                       padding: "10px",
//                                       maxWidth: "200px",
//                                       maxHeight: "200px",
//                                       borderRadius: "16px",
//                                     }}
//                                     onClick={() =>
//                                       handleImageClick(
//                                         item.notesContent.image_url
//                                       )
//                                     }
//                                   />
//                                 ) : null}

//                                 {item.notesContent.text_data !== null ? (
//                                   <div className="d-flex align-items-center">
//                                     {/* {item.created_by !== bookingData.providerId && (
//                             <Avatar
//                               src={item.created_by_img_url}
//                               sx={{
//                                 width: 30,
//                                 height: 30,
//                                 marginRight: "10px",
//                               }}
//                             />
//                           )} */}

//                                     <ListItemText>
//                                       <div
//                                         key={item?.id}
//                                         style={{
//                                           fontStyle: "normal",
//                                           fontWeight: 500,
//                                           fontSize: "10px",
//                                         }}
//                                       >
//                                         {item.created_by_name}
//                                       </div>
//                                       <Typography
//                                         sx={{
//                                           fontFamily: "Roboto",
//                                           fontStyle: "normal",
//                                           fontWeight: 400,
//                                           fontSize: "12px",
//                                           // display: "flex",
//                                           alignItems: "center",
//                                           // width: "90%",

//                                           display: "inline",
//                                           justifyContent: "space-between",
//                                           // whiteSpace: "pre-line",
//                                         }}
//                                       >
//                                         {/* {item.notesContent.text_data} */}
//                                         {/* {item.notesContent.text_data && (
//                                   <>
//                                     {isReadMore
//                                       ? item.notesContent.text_data.slice(
//                                           0,
//                                           60
//                                         )
//                                       : item.notesContent.text_data
//                                           .match(/.{1,60}/g) // Split the text into chunks of 60 characters
//                                           .map((chunk, index) => (
//                                             <React.Fragment key={index}>
//                                               {chunk}
//                                               <br />
//                                             </React.Fragment>
//                                           ))}
//                                     {item.notesContent.text_data.length >
//                                       60 && (
//                                       <span
//                                         onClick={() =>
//                                           toggleReadMore(item?.id)
//                                         }
//                                         className="read-or-hide"

//                                       >
//                                         {isReadMore
//                                           ? "...Read More"
//                                           : "Read Less"}
//                                       </span>
//                                     )}
//                                   </>
//                                 )} */}
//                                         {item.notesContent.text_data.length >
//                                         1000 ? (
//                                           <>
//                                             {isReadMore
//                                               ? item.notesContent.text_data
//                                                   .match(/.{1,60}/g) // Split the text into chunks of 60 characters
//                                                   .map((chunk, index) => (
//                                                     <React.Fragment key={index}>
//                                                       {chunk}
//                                                       <br />
//                                                     </React.Fragment>
//                                                   ))
//                                               : item.notesContent.text_data.slice(
//                                                   0,
//                                                   60
//                                                 )}
//                                             {item.notesContent.text_data
//                                               .length > 60 && (
//                                               <span
//                                                 onClick={() =>
//                                                   toggleReadMore(item.id)
//                                                 }
//                                                 className="read-or-hide"
//                                               >
//                                                 {isReadMore
//                                                   ? "Read Less"
//                                                   : "...Read More"}
//                                               </span>
//                                             )}
//                                           </>
//                                         ) : (
//                                           item.notesContent.text_data
//                                             .match(/.{1,60}/g) // Split the text into chunks of 60 characters
//                                             .map((chunk, index) => (
//                                               <React.Fragment key={index}>
//                                                 {chunk}
//                                                 <br />
//                                               </React.Fragment>
//                                             ))
//                                         )}
//                                       </Typography>
//                                     </ListItemText>
//                                     {/* {item.created_by === bookingData.providerId && (
//                             <Avatar
//                               src={item.created_by_img_url}
//                               sx={{
//                                 width: 30,
//                                 height: 30,
//                                 marginLeft: "10px",
//                               }}
//                             />
//                           )} */}
//                                   </div>
//                                 ) : null}
//                               </Box>
//                             </ListItem>
//                           </div>
//                         );
//                       })
//                     )}
//                   </List>
//                 </Box>
//               </>
//             )}

//             <hr
//               className="mt-3 ms-0 mb-3"
//               style={{
//                 color: "#c4c4c4",
//                 margin: "15.6px 0 21.4px",
//               }}
//             />

//             <form onSubmit={handleSendMessage}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   paddingLeft: "20px",
//                   paddingRight: "20px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 {permissions.isAudioRecording ? (
//                   <div
//                     style={{
//                       backgroundColor: "#FBFCFF",
//                       //  height: "1.4375em",
//                       borderRadius: "6px",
//                       border: `1px solid #DCDCDC`,
//                     }}
//                     className=" w-100 d-flex justify-content-between"
//                   >
//                     <div
//                       style={{ fontSize: "14px", color: "#535353" }}
//                       className="mt-2 ms-3"
//                     >
//                       {formatTime()}
//                     </div>
//                     <div>
//                       <IconButton onClick={handleStopRecording} color="inherit">
//                         <DeleteOutlineRoundedIcon color="error" />
//                       </IconButton>
//                     </div>
//                   </div>
//                 ) : (
//                   <OutlinedInput
//                     // label="Message"
//                     fullWidth
//                     placeholder="Type Here..."
//                     size="small"
//                     variant="standard"
//                     value={messageInput}
//                     onChange={(e) => setMessageInput(e.target.value)}
//                     onFocus={() => setIsFocused(true)}
//                     onBlur={() => setIsFocused(false)}
//                     sx={{
//                       marginRight: 1,
//                       backgroundColor: "#FBFCFF",
//                       borderRadius: "6px",
//                       border: `1px solid ${isFocused ? "#DCDCDC" : "#DCDCDC"}`,
//                       ".css-111djrg-MuiInputBase-root-MuiOutlinedInput-root": {
//                         border: "none",
//                       },
//                     }}
//                     id="outlined-adornment-weight"
//                     endAdornment={
//                       <InputAdornment position="end">
//                         <IconButton
//                           // color="primary"
//                           component="span"
//                           aria-label="Attach File"
//                           onClick={handleOpenMenu}
//                         >
//                           <AttachFileIcon />
//                         </IconButton>
//                         {!isMediaDisplayOpen && (
//                           <IconButton
//                             // color={"primary"}
//                             onClick={handleOpenCamera}
//                             className="me-0"
//                             aria-label="Start Camera"
//                             // sx={{
//                             //   border: "1px solid #548DFF",
//                             // }}
//                           >
//                             <CameraAltOutlinedIcon />
//                           </IconButton>
//                         )}
//                       </InputAdornment>
//                     }
//                     aria-describedby="outlined-weight-helper-text"
//                   />
//                 )}

//                 {!permissions.isAudioRecording ? (
//                   <IconButton
//                     className={`bg-light me-1${
//                       isMediaDisplayOpen ? "d-none" : ""
//                     }
//       `}
//                     // color={"primary"}
//                     onClick={handleStartRecording}
//                     aria-label={"Start Recording"}
//                     // sx={{
//                     //   border: "1px solid #548DFF",
//                     // }}
//                   >
//                     <MicIcon
//                       className={`${isMediaDisplayOpen ? "d-none" : ""}
//                 `}
//                       ref={recorderRef}
//                     />
//                   </IconButton>
//                 ) : (
//                   <div className=" me-1"></div>
//                 )}
//                 <Button
//                   sx={{
//                     backgroundColor: "#538dff",
//                     height: "35px",
//                     width: "20px",
//                     minWidth: "40px",
//                     borderRadius: "4px",
//                     textTransform: "capitalize",
//                     zIndex: "99999",
//                   }}
//                   variant="contained"
//                   type="submit"
//                   aria-label="Send Message"
//                   disabled={
//                     !messageInput &&
//                     !media.selectedVideo &&
//                     !media.recorderAudioBlob &&
//                     !media.selectedImage &&
//                     !media.selectedAudio &&
//                     !media.recordedVideoBlob &&
//                     !media.capturedImageBlob
//                   }
//                   onClick={handleSendMessage}
//                 >
//                   {loading ? (
//                     <CircularProgress
//                       size={20}
//                       sx={{
//                         color: "white",
//                       }}
//                     />
//                   ) : (
//                     <SendIcon
//                       sx={{
//                         fontSize: "14px",

//                         ".css-owiivq-MuiSvgIcon-root": {
//                           color: "white",
//                           height: "20px",
//                           width: "20px",
//                           fontSize: "10px",
//                         },
//                       }}
//                     />
//                   )}
//                 </Button>
//               </Box>
//             </form>
//           </Box>

//           <Box>
//             <Menu
//               sx={{
//                 ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
//                   borderRadius: "10px",
//                   position: "absolute",
//                   // top: "1%",
//                   // left: "0.5%",
//                   top: "1%",
//                   left: "0.5%",
//                   padding: "10px 0px 10px 0px",
//                 },
//               }}
//               anchorEl={showMenu}
//               open={Boolean(showMenu)}
//               onClose={handleCloseMenu}
//             >
//               <MenuItem
//               //  onClick={()=>{
//               //   handleCloseMenu()
//               // }}
//               >
//                 <label htmlFor="file-input">
//                   <input
//                     id="file-input"
//                     type="file"
//                     accept="video/*"
//                     onChange={(e) => handleVideoFile(e)}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   <div className="d-flex">
//                     <VideoCallIcon color="info" sx={{ fontSize: "20px" }} />
//                     <Typography sx={{ fontSize: "12px" }}>
//                       Select Video
//                     </Typography>
//                   </div>
//                 </label>
//               </MenuItem>
//               <MenuItem
//               //  onClick={()=>{
//               //   handleCloseMenu()
//               // }}
//               >
//                 <label htmlFor="image-input">
//                   <input
//                     id="image-input"
//                     type="file"
//                     accept="image/*,.gif"
//                     onChange={handleImageFile}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   {/* <IconButton
//                         color="primary"
//                         component="span"
//                         aria-label="Select Image"
//                       > */}
//                   <div className="d-flex">
//                     <ImageIcon color="primary" sx={{ fontSize: "20px" }} />
//                     <Typography sx={{ fontSize: "12px" }}>
//                       Select Image
//                     </Typography>
//                   </div>
//                   {/* </IconButton> */}
//                 </label>
//               </MenuItem>
//               {/* <MenuItem onClick={() => fileInputRef.current?.click()}>
//             <input
//               id="image-input"
//               type="file"
//               // accept="image/*"
//               onChange={(e) => handleImageFile(e)}
//               style={{ display: "none" }}
//               ref={fileInputRef}
//             />
//             <ImageIcon color="primary" sx={{ fontSize: "30px" }} />
//             <Typography>Select Image</Typography>
//           </MenuItem> */}

//               <MenuItem
//               // onClick={()=>{
//               //   handleCloseMenu()
//               // }}
//               >
//                 <label htmlFor="audio-input">
//                   <input
//                     id="audio-input"
//                     type="file"
//                     accept="audio/*"
//                     onChange={(e) => handleAudioFile(e)}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   <div className="d-flex">
//                     <AudiotrackIcon
//                       color="warning"
//                       size="12px"
//                       sx={{ fontSize: "20px" }}
//                     />
//                     <Typography sx={{ fontSize: "12px" }}>
//                       Select Audio
//                     </Typography>
//                   </div>
//                 </label>
//               </MenuItem>
//             </Menu>
//           </Box>
//         </div>
//       )}
//       {selectedImages && (
//         <ZoomableImageModal
//           imageUrl={selectedImages}
//           onClose={handleCloseModal}
//         />
//       )}
//     </>
//   );
//   if (flag === "booking") {
//     return <>{component}</>;
//   } else {
//     return (
//       <>
//         <SideNavone>{component}</SideNavone>
//       </>
//     );
//   }
// };
// export default Notes;
