import React, { useState } from "react";
import "./RoundLoader.css";
import {
  boxicon,
  layericon,
  calendericon,
  usericon,
  coinsicon,
  pie,
} from "../../Assets";
import { Link } from "react-router-dom";
import { handleCalendexDomain } from "utils";
import { useSelector } from "react-redux";
import { getCookiesData } from "utils/helper";

export const RoundLoaders = () => {
  const [isMouseEnter, setMouseEnter] = useState(true);
  var userDetail = useSelector((state) => state.Auth.userloginsuccessyasir);
  const auth= getCookiesData("authtication")

  const [data, setData] = useState([
    {
      name: "BuzzCom",
      icon: layericon,
      color: "blue",
      extra: isMouseEnter,
      url: "/",
    },
    {
      name: "Calendex",
      icon: calendericon,
      color: "red",
      url: "/",
    },
    {
      name: "iReach",
      icon: usericon,
      color: "green",
      url: "/hr-manager",
    },
    {
      name: "Ondel",
      icon: coinsicon,
      color: "yellow",
      url: "/finance",
    },
    {
      name: "Accounts",
      icon: boxicon,
      color: "blue2",
      url: "/inventory",
    },
    {
      name: "Salesplex",
      icon: pie,
      color: "pink",
      url: "/",
    },
  ]);
  return (
    <div className="loadersHeroContainer">
      {data.map((item, index) => (
        <Link to={item.url} style={{ textDecoration: "none" }}>
        {item.name === "Calendex" ? (
            <div
              key={index}
              onClick={() => handleCalendexDomain(auth, userDetail)}
              className="button2 spin2 circle2 spacingBox marginLoader"
            >
              <div
                className={
                 "loadersHero2"
                }
              >
                <img src={item.icon} alt={item.name} />
                <p>{item.name}</p>
              </div>
            </div>
          ) : (
          <div
            onMouseEnter={() => {
              setMouseEnter(false);
            }}
            onMouseLeave={() => {
              setMouseEnter(true);
            }}
            className={
              item.color === "blue" && isMouseEnter === true
                ? "buttonActive spinActive circleActive spacingBox marginLoader"
                : item.color === "blue"
                ? "button spin circle spacingBox marginLoader"
                : item.color === "red"
                ? "button2 spin2 circle2 spacingBox marginLoader"
                : item.color === "green"
                ? "button3 spin3 circle3 spacingBox marginLoader"
                : item.color === "yellow"
                ? "button4 spin4 circle4 spacingBox marginLoader"
                : item.color === "pink"
                ? "button5 spin5 circle5 spacingBox marginLoader"
                : item.color === "blue2"
                ? "button6 spin6 circle6 spacingBox marginLoader"
                : null
            }
          >
            <div
              key={index}
              className={
                item.color === "blue" && item.extra === true
                  ? "loadersHeroActive"
                  : item.color === "blue"
                  ? "loadersHero"
                  : item.color === "red"
                  ? "loadersHero2"
                  : item.color === "green"
                  ? "loadersHero3"
                  : item.color === "yellow"
                  ? "loadersHero4"
                  : item.color === "pink"
                  ? "loadersHero5"
                  : item.color === "blue2"
                  ? "loadersHero6"
                  : null
              }
            >
              <img src={item.icon} alt={item.name} />
              <p>{item.name}</p>
            </div>
          </div>
          )}
        </Link>
      ))}
    </div>
  );
};