import React from 'react'
import { useState,useEffect } from 'react';
import { getQuestions } from '../../service/APIService';

export const ModalQuestionList = ({refresh,setFormBuilderQuestions,formQuestionList,clickedId,
    showModal,setModal,
    sectionData,setSectionData,selectedSection,setSelectedSection
}) => {

    const [questionsList, setQuestionsList] = useState([]);
    const [nextPage, setNextPage] = useState('');
    const [isNextPage, setIsNextPage] = useState(false);
    const [loading, setloading] = useState(false);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        loadQuestions('')
    }, [refresh]);

    async function loadQuestions(url) {
        setloading(true)
        await getQuestions(url)
            .then(function (response) {
                setTotal(response.data.count)
                if (isNextPage) {
                    setQuestionsList([...questionsList, ...response.data.results])
                } else {
                    setQuestionsList(response.data.results)
                }

                if (response.data.next === null || response.data.next === 'null') {
                    setIsNextPage(false)
                } else {
                    setIsNextPage(true)
                    setNextPage(response.data.next)
                }

            })
        setloading(false)
            
    }
    //[9,1,8,12,2,31,3,6]
    function modifyObjectById(object, targetId, newValue) {
        if (object?.contentObject?.options) {
            object.contentObject.options.forEach((option) => {
            if (option.id.toString() === targetId) {
                if(option.childs){
                    option.childs = [...option.childs,newValue]
                    option.hasChild=true
                }else{
                    option.childs = [newValue]
                    option.hasChild=true
                    
                }
            }
          });
        }
          
      
        if (object.contentObject && object.contentObject.options) {
          object.contentObject.options.forEach((option) => {
            if (option.childs) {
              option.childs.forEach((child) => {
                modifyObjectById(child, targetId, newValue);
              });
            }
          });
        }
    }
      
    

  return (
    <div className=' my-2 text-center'>
            <h3>Question Bank</h3>
            <h6 className='text-start mt-2'>Total Question: {total}</h6>
            <div className='mb-2' style={{ 'height': '80vh', 'overflowY': 'scroll' }}>
                {
                    questionsList.map((e, index) => {
                        return (
                            <div className='text-start shadown border p-2 my-2 rounded-2 d-flex justify-content-between' key={index}>
                                <h5>{e.contentObject?.questionText}</h5>
                                <small>Type: {e.contentObject.questionType}</small><br/>
                                <button className='btn btn-danger'  onClick={()=>{
                                    var ids = clickedId.split('.');
                                                                   
                                        var temp_obj  = e;
                                        temp_obj.isRequired = false;
                                        temp_obj.id = `${clickedId}.${temp_obj.id}`;
                                        if (temp_obj.contentObject.options) {
                                            temp_obj.contentObject.options.forEach(element => {
                                            element.id = `${temp_obj.id}.${element.id}`;
                                        });
                                        }
                                        
                                        var parentObj = {}
                                        var index = 0;
                                        selectedSection.questionList.forEach((element,i) => {
                                            
                                            if(element.id.toString() === ids[0]){
                                                parentObj = element;
                                                index = i;
                                            }
                                        });
                                        modifyObjectById(parentObj,clickedId,temp_obj)
                                        // formQuestionList[index] = parentObj;
                                        selectedSection.questionList[index] = parentObj;
                                        // formQuestionList.filter(obj => obj.id.toString() === ids[0])
                                        setSelectedSection(selectedSection);
                                        sectionData.forEach(element=>{
                                            if(element.id === selectedSection.id){
                                                element = selectedSection
                                            }
                                        })
                                        setSectionData([...sectionData])
                                        
                                    
                                }} data-bs-dismiss="modal" aria-label="Close">Select</button>
                                
                            </div>

                        )
                    })
                }
                {
                    loading ?
                        <div className="spinner-border text-primary mt-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <></>
                }
            </div>

            {
                isNextPage ?
                    <button className='btn btn-danger' onClick={() => { loadQuestions(nextPage) }}>Load More</button> : <small>No more to Load</small>
            }
    </div>
  )
}
