import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useParams } from 'react-router-dom';
import { deleteRequest, postRequest, putRequest } from '../../../request';
import { FaRegEdit } from 'react-icons/fa';
import { base_url } from '../../../base_url';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceAttributes } from '../../../store/servicesActions';
import { disableMiniLoader, enableMiniLoader } from '../../../store/ServicesSlice';
import { BntLoader } from '../../../../../Componenets/loaders';

const AddAttributesMobile = () => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Type', width: '300px' },
        { id: 2, name: 'Value', width: '' },
    ]);
    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);
    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];
    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [defaultColumns, setDefaultColumns] = useState([
        { id: 1, width: '150px' },
        { id: 2, width: '120px' },
    ]);
    const [attribType, setAttribType] = useState('');
    const [selectedId, setSelectedId] = useState();
    const [newAttrib, setNewAttrib] = useState('');
    const [attribValList, setAttribValList] = useState([]);
    const [btnClicked, setBtnClicked] = useState(false);

    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);
    const { id } = useParams();

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };

    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };

    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);

        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });

        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteSelectedRows = async (delId) => {
        const res = await deleteRequest(`${base_url}/ServiceAttribute/RemoveServiceAttribute?Id=${delId}`);
        if (res?.message === "succeeded") {
            dispatch(getServiceAttributes(id))
            alert('Service attribute deleted successfully');
        }
        else {
            alert(res?.message)
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const postData = async () => {
        if (attribType && attribValList.length > 0) {
            dispatch(enableMiniLoader());
            if (selectedId) {
                const data = {
                    id: selectedId,
                    serviceId: parseInt(id),
                    type: attribType,
                    values: attribValList,
                    attributePrice: 0
                }
                const res = await putRequest(`${base_url}/ServiceAttribute/UpdateServiceAttribute`, data);
                if (res?.message === "succeeded") {
                    setAttribType('');
                    setAttribValList([]);
                    setSelectedId(null);
                    dispatch(disableMiniLoader());
                    dispatch(getServiceAttributes(id))
                    alert('Service attribute Updated successfully');
                }
                else {
                    alert(res?.message)
                }
            }

            else {
                const data = {
                    serviceId: parseInt(id),
                    type: attribType,
                    values: attribValList,
                    attributePrice: 0
                }
                const res = await postRequest(`${base_url}/ServiceAttribute/AddServiceAttribute`, data);
                if (res?.message === "succeeded") {
                    setAttribType('');
                    setAttribValList([]);
                    setSelectedId(null);
                    dispatch(disableMiniLoader());
                    dispatch(getServiceAttributes(id));
                    alert('Service attribute added successfully');
                }
                else {
                    alert(res?.message)
                }
            }
        }
        else {
            alert('Please provide missing values')
        }
    }

    const getEditData = (id) => {
        setSelectedId(id)
        const filtered = rows.filter(elem => elem?.id === id);
        if (filtered) {
            filtered[0]?.data?.map(v => {
                if (v?.attributeType) {
                    setAttribType(v?.attributeType);
                }
                else if (v?.values?.length > 0) {
                    setAttribValList(v?.values);
                }
            });
        }
    }

    useEffect(() => {
        if (store?.serviceAttributes) {
            setRows(() => store?.serviceAttributes.map((v, i) => {
                return (
                    {
                        id: v?.id, serviceId: v?.serviceId,
                        data: [{ id: 1, attributeType: v?.attributeType }, { id: 2, values: [...v?.values] }]
                    })
            }))
        }
    }, [store?.serviceAttributes]);

    useEffect(() => {
        dispatch(disableMiniLoader())
    }, [])

    return (
        <>
            <div className="container p-0 hide_scrollbar position-relative mobile_display mt-3" style={{ maxHeight: '100vh', overflow: 'scroll' }}>
                <table className="table attribute_form_container_mobile w-100"
                // style={{ overflowX: 'clip' }}
                >
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {columns.map((column, index) => {
                                if (index < 1) {
                                    return (
                                        <th
                                            id={`sticky_col${column?.id}`}
                                            key={column.id}
                                        >{column.name}</th>
                                    );
                                }

                                return (
                                    <th
                                        // key={column.id}
                                        draggable
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}

                                        onMouseOver={() => setColumnId(column.id)}
                                        onMouseOut={() => setColumnId('')}
                                        className={isDraggingRow ? 'dragging-row' : ''}

                                        style={{
                                            width:
                                                // column?.width
                                                column?.id == 1 && "150px" ||
                                                column?.id == 2 && "450px"
                                        }}
                                    >
                                         <div className='d-flex justify-content-center'>
                                                {column.name}
                                            </div>
                                            <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                                <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                                </button>
                                                <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                                </div>
                                            </div>
                                    </th>
                                );
                            })}
                            <th style={{ width: '70px' }}>
                                Action
                            </th>
                            <th className='addcol_btn_th'
                                style={{ width: '73px' }}
                            >
                                <div className="dropdown">
                                    <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                    </button>
                                    <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                        {dropdown_columns && dropdown_columns.map((val, ind) => {
                                            return (
                                                <div key={val?.id}>
                                                    <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {rows.length && rows.map((row, rowIndex) => (
                            <tr
                                key={row?.id}
                                draggable
                                onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                onDragOver={handleRowDragOver}
                                onDrop={(e) => handleRowDrop(e, rowIndex)}
                                handleDragEnd={handleDragEnd}
                                className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                            >
                                <td>
                                    <div className="form-check ps-4 text-center">
                                        <input type="checkbox" value="" id="flexCheckDefault"
                                            className="form-check-input checkbox_btn"
                                            checked={selectedRows.includes(row.id)}
                                            onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                        />
                                    </div>
                                </td>

                                {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                    return (
                                        <>
                                            {cellValue.id === 1 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    {cellValue?.attributeType}
                                                </td>
                                            }

                                            {cellValue.id === 2 &&
                                                <td key={columnIndex}
                                                    style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                >
                                                    {cellValue?.values.length > 0 && cellValue?.values.map((v, i) => {
                                                        return <span className='px-3 py-1 me-1' style={{ borderRadius: '15px', fontSize: '9px', color: 'gray', backgroundColor: '#e8eaed' }}>{v?.value}</span>
                                                    })}
                                                </td>
                                            }


                                            {cellValue.id === 7 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                >
                                                    <input type='text' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 8 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                >
                                                    <select>
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="Option 1">option A</option>
                                                        <option value="Option 2">option B</option>
                                                        <option value="Option 2">option C</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 9 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    <input type='number' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 10 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    <input type='file' placeholder='' value={''} />
                                                </td>
                                            }

                                        </>
                                    )
                                })}

                                <td className='text-center'
                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                >
                                    <div className="dropdown" style={{ zIndex: '1', positition: 'relative' }}>
                                        <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style={{ zIndex: '0' }}
                                        >
                                            <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                                        </button>
                                        <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                                        >
                                            <div className='py-1'
                                                onClick={() => getEditData(row?.id)}
                                            >
                                                <FaRegEdit className='text-warning' style={{ fontSize: '18px' }} />
                                                <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                >Edit Row</span>
                                            </div>
                                            <div className='py-1'
                                                onClick={() => { deleteSelectedRows(row?.id) }}>
                                                <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }}
                                                />
                                                <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                >Delete Row</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                >
                                </td>
                            </tr>
                        ))}

                        <tr id='table_form_row'>
                            <td>
                            </td>
                            {defaultColumns?.length > 0 && defaultColumns?.map((cellValue, columnIndex) => {
                                return (
                                    <>
                                        {cellValue.id === 1 &&
                                            <td key={columnIndex}
                                                id={`sticky_col${columnIndex}`}
                                            >
                                                <select
                                                    onChange={(e) => setAttribType(e.target.value)}
                                                    value={attribType}
                                                >
                                                    <option value="" className='w-100'>Select</option>
                                                    {store?.allAttributeTypes && store?.allAttributeTypes.map((v) => {
                                                        return (
                                                            <option key={v?.id} value={v?.name}>{v?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        }

                                        {cellValue.id === 2 &&
                                            <td key={columnIndex}
                                                className='position-relative'
                                            >
                                                <div className='d-flex'>
                                                    <input type='text' placeholder=''
                                                        className={`${!newAttrib && btnClicked ? 'enable_validation' : ''} w-50`}
                                                        style={{ backgroundColor: 'white' }}
                                                        onChange={(e) => {
                                                            setNewAttrib(e.target.value);
                                                            setBtnClicked(false);
                                                        }
                                                        }
                                                        value={newAttrib}
                                                    />
                                                    <button className='btn text-warning'
                                                        style={{ fontSize: '12px' }}
                                                        onClick={() => {
                                                            setBtnClicked(true);
                                                            if (newAttrib) {
                                                                setAttribValList([...attribValList, { value: newAttrib }]);
                                                                setBtnClicked(false);
                                                                setNewAttrib('');
                                                            }
                                                        }}
                                                    >
                                                        <ControlPointIcon sx={{ fontSize: '12px' }} /> Add New</button>
                                                </div>
                                            </td>
                                        }

                                        {cellValue.id === 7 &&
                                            <td key={columnIndex}
                                            >
                                                <input type='text' placeholder='' value={''} />
                                            </td>
                                        }

                                        {cellValue.id === 8 &&
                                            <td key={columnIndex}
                                            >
                                                <select
                                                >
                                                    <option value="" className='w-100'>Select</option>
                                                    <option value="Option 1">option A</option>
                                                    <option value="Option 2">option B</option>
                                                    <option value="Option 2">option C</option>
                                                </select>
                                            </td>
                                        }

                                        {cellValue.id === 9 &&
                                            <td key={columnIndex}
                                            >
                                                <input type='number' placeholder='' value={''} />
                                            </td>
                                        }

                                        {cellValue.id === 10 &&
                                            <td key={columnIndex}
                                            >
                                                <input type='file' placeholder='' value={''} />
                                            </td>
                                        }

                                    </>
                                )
                            })}
                            <td className='text-center'
                                style={{ position: 'relative' }}
                            >
                                {store?.miniLoading &&
                                    <BntLoader />
                                }
                                <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                    style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                    onClick={postData}
                                >Add</button>
                            </td>

                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {attribValList.length > 0 &&
                <div className='d-flex p-2' style={{ backgroundColor: 'rgb(240 242 244)', height: 'auto', width: '100%', position: '', top: '50px', borderRadius: '40px' }}>
                    {attribValList.length > 0 && attribValList.map((val, ind) => {

                        return (
                            <span key={ind} className='d-flex px-3 py-1 me-1' style={{ borderRadius: '15px', fontSize: '9px', color: 'gray', backgroundColor: '#e8eaed' }}>{val?.value}
                                &nbsp; &nbsp;<span className='text-danger' onClick={() => {
                                    const filtered = attribValList.filter(val => attribValList.indexOf(val) !== ind);
                                    setAttribValList(filtered);
                                }}>X</span>
                            </span>
                        );
                    })}
                </div>
            }
        </>
    );
};

export default AddAttributesMobile;
