import React, { useState } from "react";
import "./ProcessDetail.css";
import { icon1, icon2, icon3 } from "../../Assets";
export const ProcessDetail = () => {
  const [data, setData] = useState([
    {
      icon: icon1,
      title: "Easy monitoring",
      info:
        "Round the clock monitoring and management of everything with convenience",
    },
    {
      icon: icon2,
      title: "Complete visibility",
      info:
        "Get a 360-degree view of all your business operations to save valuable time",
    },
    {
      icon: icon3,
      title: "Anywhere Anytime access",
      info:
        "Add projects, make changes, assign tasks, communicate, and a lot more anywhere, anytime",
    },
  ]);
  return (
    <div>
      <p className="processDetailText">
        <span>Track every detail</span>
        <span className="light">Every stage of the process in one place </span>
      </p>
      <div className="processDetailCard">
        {data.map((item, index) => (
          <div className="processDetailCardAlign">
            <div className="processDetailImage">
              <img src={item.icon} />
            </div>
            <div className="countAlign">
              <div className="count">
                <p>{index + 1}</p>
              </div>
              <div className="countText">
                <p className="count_title">{item.title}</p>
                <p className="count_info">{item.info}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
