import React, { useState, useEffect } from "react";
import { Form } from "formik";
import InputTextField from "../TextField";

import Button from "@mui/material/Button";
import { CustomTextField, FormateDate, success } from "../../../utils";
import { MdAddCircleOutline } from "react-icons/md";
import { Autocomplete, InputAdornment, Switch } from "@mui/material";
import dayjs from "dayjs";
import InputDate from "../InputDate";
import FireNav from "../List";
import Modal from "@mui/material/Modal";
import { ClipLoader } from "react-spinners";
import axios from "axios";
const ModalData = ({ onClose, fetchProduct }) => {
  const [product, setProduct] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!product || !type) {
      setError("Product and Type fields are required");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: `
            mutation {
              createProduct(description: "this is for testing", name: "${product}", type: "${type}") {
                product {
                  createdAt
                  description
                  id
                  isDeleted
                  name
                  type
                  updatedAt
                }
              }
            }
          `,
        }
      );

      if (response.status === 200) {
        success("Product Created Successfully");
        onClose();
        fetchProduct();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="p-5 m-5">
        <div className="p-3 card">
          <h2 className="p-3">Add Product</h2>
          <div className="w-100  ps-4 pe-4 d-sm-flex !m-n2">
            <div className="p-2 w-100">
              <InputTextField
                id="product"
                label="Product Title"
                name="product"
                placeholder="Enter Title for Product"
                //size={20}
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                // touched={touched.subscription}
                // error={touched.subscription && errors.subscription}
                // onChange={handleChange}
                variant="filled"
                autoFocused
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="p-2 w-100">
              <InputTextField
                id="type"
                label="Type"
                name="Type"
                placeholder="Enter Type"
                //size={20}
                value={type}
                onChange={(e) => setType(e.target.value)}
                // value={values.subscription}
                // touched={touched.subscription}
                // error={touched.subscription && errors.subscription}
                // onChange={handleChange}
                variant="filled"
                autoFocused
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="d-flex justify-content-end pe-3 pe-5 pt-4">
            <Button
              type="submit"
              sx={{
                width: "120px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              disable={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
              variant="contained"
            >
              {loading ? "Creating..." : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalData;
