import React,{useState} from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import AddBooking from "../components/AddBooking.jsx";
import SideNavone from "../../../SideNavBar";

export default function CreateBooking() {
  
  return (
    <div>
      <SideNavone>
        <AddBooking />
      </SideNavone>
    </div>
  );
}
