// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-top-tc {
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 8%;
  padding-right: 8%;
}

.background_tc {
  background-color: white;
  border-radius: 10px;
}

.button-outline-tc {
  border: 1px solid gray;
}

.giff_width {
  width: 70%;
}

@media screen and (max-width: 480px) {
  .padding-top-tc {
    padding-top: 5%;
    padding-bottom: 10%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/PublicPages/Faq/Faq.scss"],"names":[],"mappings":"AACA;EAEE,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EAEI,uBAAA;EACA,mBAAA;AAFJ;;AAKA;EAEI,sBAAA;AAHJ;;AAMA;EAEI,UAAA;AAJJ;;AAOA;EACG;IAEE,eAAA;IACA,mBAAA;EALH;AACF","sourcesContent":["\n.padding-top-tc\n{\n  padding-top: 10%;\n  padding-bottom: 10%;\n  padding-left: 8%;\n  padding-right: 8%;\n}\n\n.background_tc\n{\n    background-color: white;\n    border-radius: 10px;\n}\n\n.button-outline-tc\n{\n    border: 1px solid gray;\n}\n\n.giff_width\n{\n    width: 70%;\n}\n\n@media screen and (max-width: 480px) {\n   .padding-top-tc\n   {\n     padding-top: 5%;\n     padding-bottom: 10%;\n   }\n \n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
