import React from "react";
import SideNavone from "../../../../SideNavBar";
import Mapsection from "../Mapsection/Mapsection";


function Addaddressfrommap() {
 

  return (
    <SideNavone>
      <div className="col-md-12 padding_left_right  mt-2  ">
        <Mapsection />
       
      </div>
    </SideNavone>
  );
}

export default Addaddressfrommap;
