import React, { useState, useRef } from "react";
import clsx from "clsx";
import { useEffect } from "react";
import StickyNotesDialog from "./stickyNotes";

export default function CustomEventWidget(props) {
  const { event } = props;
  const eventRef = useRef();
  const [Stikcy, setSticky] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setSticky(event?.event?.isstickyNotes);
  }, [event]);

  // useEffect(()=>{
  //   if(eventRef.current && event?.title === "Available Slots") {
  //     const rbcEvent = document.querySelector(".rbc-event-label");
  //   }
  // },[event]);
  const handleStickyNotes = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log("-------------------------------------------------------------------");
  // console.log(event, "ENEVTTTT");
  // console.log("-------------------------------------------------------------------");
  // function calculateEndTime(startTime) {
  //   const [startHours, startMinutes] = startTime.split(":").map(Number);
  //   const totalStartMinutes = startHours * 60 + startMinutes;
  //   const newTotalEndMinutes = totalStartMinutes + 15;
  //   const newEndHours = Math.floor(newTotalEndMinutes / 60);
  //   const newEndMinutes = newTotalEndMinutes % 60;
  //   const formattedEndTime = `${String(newEndHours).padStart(2, "0")}:${String(
  //     newEndMinutes
  //   ).padStart(2, "0")}`;
  //   return formattedEndTime;
  // }
  // console.log(event);
  return (
    <div
      ref={eventRef}
      className={clsx(
        `bg-[${event?.event?.colorCode}] w-100 rounded-lg px-2 ${
          event?.title === "Unavailable" ? "" : "mt-1"
        }`,
        event?.title === "Unavailable" ? "bg-repeat bg-center" : null
      )}
      style={{
        // backgroundImage:
        //   event?.title === "Unavailable"
        //     ? "url('/images/unavailable.jpg')"
        //     : null,
        // backgroundSize: event?.title === "Unavailable" ? "cover" : null,
        backgroundColor:
          event?.title === "Unavailable" && event.event.isBusinessHour === false
            ? "#e99595"
            : event?.title === "Unavailable" &&
              event.event.isBusinessHour === true &&
              event?.event?.type !== "break" &&
              event?.event?.type !== "timeBlock" &&
              event?.event?.type !== "event"
            ? "lightgrey"
            : event?.event?.type === "break"
            ? "#f3f3f3"
            : event?.event?.type === "timeBlock"
            ? "#34aadc"
            : event?.event?.type === "event"
            ? "#bb86fc !important"
            : event?.event?.colorCode === "#173371"
            ? "#548DFF !important"
            : "transparent",

        height: "100%",
        // display: event?.title === "Available Slots" ? "block" : null,
        // height: event?.title === "Available Slots" ? "2rem" : "100%",
        // backgroundColor:
        //   event?.title === "Available Slots" ? "transparent" : null,
        // border: event?.title === "Available Slots" ? "" : null,
        // borderStyle: event?.title === "Available Slots" ? "" : null,
        borderRadius: event?.title === "Available Slots" ? "1px" : null,
        // color: event?.title === "Available Slots" ? "#ffbf00 !important" : null,
      }}
    >
      <div>
        {/* <Image
          alt="testing"
          src={ImgData}
          className="rounded-full"
          width="50" height="50"
      /> */}
        <div className={clsx(event?.event?.isstickyNotes ? "d-block" : "")}>
          {event?.title === "Available Slots" ? (
            <p
              className={clsx("mt-1 text-right")}
              style={{
                fontSize: event?.title === "Available Slots" ? "10px" : "12px",
                margin: "0",
                fontWeight: "bold",
                color: "#ffbf00",
                textAlign: "center",
                // maxHeight: "2rem"
              }}
            >
              {event?.event?.timeFrom} - {event?.event?.timeTo}
            </p>
          ) : event?.event?.type === "break" ? (
            <div
              //  className="w-100 d-flex  justify-content-center align-items-center",
              // {
              //   "flex-column": event?.event?.duration !== 15,
              //   "flex-row": event?.event?.duration === 15,
              // }
              className={clsx(
                "w-100 d-flex  justify-content-center align-items-center",
                {
                  "flex-column": event?.event?.duration !== 15,
                  "flex-row": event?.event?.duration === 15,
                }
              )}
            >
              <p
                style={{
                  fontSize: event?.event?.duration === 15 ? "12px" : "12px",
                }}
                className={clsx("text-base leading-5 booking_customer_name", {
                  "pt-2": event?.event?.duration !== 15,
                  "pt-0": event?.event?.duration === 15,
                })}
                // style={{ fontSize: "14px", margin: " 0" }}
              >
                {event?.event?.title}
                {/* {event?.event?.timeFrom} - {event?.event?.timeTo} */}
              </p>
              {/* {event?.event?.description && (
                <p
                  className={clsx(
                    "mt-1  text-capitalize text-right  booking_customer_name"
                  )}
                  // style={{
                  //   fontSize: event?.title === "break" ? "12px" : "10px",
                  //   // color: event?.title === "break" ? "white" : "black",
                  //   margin: "0",
                  //   fontWeight: "",
                  // }}
                >
                  {event?.event?.description}
                </p>
              )} */}
              <p
                className={clsx(
                  "mt-1 d-flex  text-right booking_service_name ",
                  {
                    "flex-column": event?.event?.duration !== 15,
                    "flex-row": event?.event?.duration === 15,
                  }
                )}
                style={{
                  fontSize: event?.event?.duration === 15 ? "10px" : "12px",

                  // fontSize: event?.event?.type === "break" ? "12px" : "12px",
                  // color: event?.title === "break" ? "white" : "black",
                  // margin: "0",
                  // fontWeight: "bold",
                }}
              >
                {event?.event?.duration === 15 ? (
                  <>
                    &nbsp;&nbsp;({event?.event?.timeFrom} -{" "}
                    {event?.event?.timeTo})
                  </>
                ) : (
                  <>
                    {event?.event?.timeFrom} - {event?.event?.timeTo}
                  </>
                )}
              </p>
            </div>
          ) : event?.event?.type === "timeBlock" ? (
            <div
              //  className="w-100 d-flex  justify-content-center align-items-center",
              // {
              //   "flex-column": event?.event?.duration !== 15,
              //   "flex-row": event?.event?.duration === 15,
              // }
              className={clsx(
                "w-100 d-flex  justify-content-center align-items-center",
                {
                  "flex-column": event?.event?.duration !== 15,
                  "flex-row": event?.event?.duration === 15,
                }
              )}
            >
              <p
                style={{
                  fontSize: event?.event?.duration === 15 ? "12px" : "12px",
                }}
                className={clsx(
                  "text-base  leading-5 booking_customer_name",
                  {
                    "pt-2": event?.event?.duration !== 15,
                    "pt-0": event?.event?.duration === 15,
                  }
                )}
                // style={{ fontSize: "14px", margin: " 0" }}
              >
                {event?.event?.title}
                {/* {event?.event?.timeFrom} - {event?.event?.timeTo} */}
              </p>
              {/* {event?.event?.description && (
                <p
                  className={clsx(
                    "mt-1  text-capitalize text-right  booking_customer_name"
                  )}
                  // style={{
                  //   fontSize: event?.title === "break" ? "12px" : "10px",
                  //   // color: event?.title === "break" ? "white" : "black",
                  //   margin: "0",
                  //   fontWeight: "",
                  // }}
                >
                  {event?.event?.description}
                </p>
              )} */}
              <p
                className={clsx(
                  "mt-1 d-flex text-right booking_service_name ",
                  {
                    "flex-column": event?.event?.duration !== 15,
                    "flex-row": event?.event?.duration === 15,
                  }
                )}
                style={{
                  fontSize: event?.event?.duration === 15 ? "10px" : "12px",

                  // fontSize: event?.event?.type === "break" ? "12px" : "12px",
                  // color: event?.title === "break" ? "white" : "black",
                  // margin: "0",
                  // fontWeight: "bold",
                }}
              >
                {event?.event?.duration === 15 ? (
                  <>
                    &nbsp;&nbsp;({event?.event?.timeFrom} -{" "}
                    {event?.event?.timeTo})
                  </>
                ) : (
                  <>
                    {event?.event?.timeFrom} - {event?.event?.timeTo}
                  </>
                )}
              </p>
            </div>
          ) : event?.event?.type === "event" ? (
            <div
              className={clsx(
                "w-100 d-flex  justify-content-center align-items-center",
                {
                  "flex-column": event?.event?.duration !== 15,
                  "flex-row": event?.event?.duration === 15,
                }
              )}
            >
              <p
                style={{
                  fontSize: event?.event?.duration === 15 ? "10px" : "12px",
                }}
                className={clsx(
                  "text-base leading-5 booking_customer_name",
                  {
                    "pt-2": event?.event?.duration !== 15,
                    "pt-0": event?.event?.duration === 15,
                  }
                )}
                // style={{ fontSize: "14px", margin: " 0" }}
              >
                {event?.event?.title}
                {/* {event?.event?.timeFrom} - {event?.event?.timeTo} */}
              </p>
              {/* {event?.event?.description && (
                <p
                  className={clsx(
                    "mt-1  text-capitalize text-right  booking_customer_name"
                  )}
                  // style={{
                  //   fontSize: event?.title === "break" ? "12px" : "10px",
                  //   // color: event?.title === "break" ? "white" : "black",
                  //   margin: "0",
                  //   fontWeight: "",
                  // }}
                >
                  {event?.event?.description}
                </p>
              )} */}
              <p
                className={clsx(
                  "mt-1 d-flex  flex-column text-right booking_service_name "
                )}
                style={{
                  fontSize: event?.event?.duration === 15 ? "8px" : "12px",
                  // color: event?.title === "break" ? "white" : "black",
                  // margin: "0",
                  // fontWeight: "bold",
                }}
              >
                {event?.event?.duration === 15 ? (
                  <>
                    &nbsp;&nbsp;({event?.event?.timeFrom} -{" "}
                    {event?.event?.timeTo})
                  </>
                ) : (
                  <>
                    {event?.event?.timeFrom} - {event?.event?.timeTo}
                  </>
                )}{" "}
              </p>
            </div>
          ) : event?.title === "Unavailable" ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              {/* <p
                className={clsx("text-base leading-5 pt-3 text-black")}
                style={{ fontSize: "20px", fontWeight: "600", margin: " 0" }}
              >
                UnAvailable
              </p> */}
              {/* <p
                className={clsx(
                  "mt-1  text-right  leading-6 booking_customer_name"
                )}
                style={{
                  fontSize: event?.event?.type === "break" ? "12px" : "12px",
                  margin: "0",
                  // fontWeight: "bold",
                }}
              >
                {event?.event?.timeFrom} - {event?.event?.timeTo}
              </p> */}
            </div>
          ) : event.event.duration <= 15 ? (
            <div>
              <div className="d-flex justify-content-between my-0">
                <p
                  className={clsx(
                    " font-bold leading-6 booking_customer_name",
                    event?.title === "Unavailable" ? "text-black" : "text-white"
                  )}
                  // style={{ textTransform: "capitalize", fontSize: "12px", margin: "0" }}
                >
                  {event?.event?.customerFirstName +
                    " " +
                    event?.event?.customerLastName}
                </p>
              </div>
              {/* <div
                  className={clsx(
                    "text-right",
                    event?.title === "Unavailable" ||
                      event?.title === "Available Slots"
                      ? "#ffbf00"
                      : "text-white"
                  )}
                  style={{
                    fontSize:
                      event?.title === "Available Slots" ? "10px" : "10px",
                    margin: "0",
                    fontWeight: "normal",
                    color: "#ffbf00 !important",
                  }}
                >
                  {event?.event?.timeFrom} - {event?.event?.timeTo}
                </div> */}
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-between pt-1">
                <div
                  className={clsx(
                    `${
                      event?.title === "Unavailable"
                        ? "text-black"
                        : "text-white"
                    } booking_customer_name`
                  )}
                >
                  {event?.event?.customerFirstName +
                    " " +
                    event?.event?.customerLastName}
                </div>
              </div>
              <div
                className={clsx(
                  "text-base leading-5 booking_service_name",
                  event?.title === "Unavailable" ? "text-black" : "text-white"
                )}
              >
                {event?.event?.serviceDetails?.serviceName}
              </div>
              <div
                className={clsx(
                  "text-right booking_time",
                  event?.title === "Unavailable" ||
                    event?.title === "Available Slots"
                    ? "#ffbf00"
                    : "text-white"
                )}
              >
                {event?.event?.timeFrom} - {event?.event?.timeTo}
              </div>
            </div>
          )}
        </div>
        {/* {event?.title === "Available Slots" ? null : (
          <p className="text-base font-normal text-white" style={{margin:"0"}}>
            {event?.event?.serviceDetail}
          </p>
        )} */}
      </div>
      {Stikcy ? (
        <div
          className="g-yellow-400 p-1 w-28 h-fit position-absolute top-0 end-0 shadow-xl d-flex flex-column justify-content-center align-items-center z-50"
          onClick={(e) => handleStickyNotes(e)}
        >
          <div className="h-3 w-3 bg-slate-400 rounded-circle"></div>
          <p
            className={`text-xs text-[${event?.event?.colorCode}]`}
            style={{ margin: "0" }}
          >
            {event?.event?.stickyNotesDescription.substring(0, 10)}...
          </p>
        </div>
      ) : null}
      <StickyNotesDialog
        open={open}
        handleClose={handleClose}
        event={event?.event}
      />
    </div>
  );
}
