import React from 'react';
import ProviderTable from '../components/ProviderList/ProviderTable';

import SideNavone from '../../../SideNavBar';

const CalenderPage = () => {
  return (
    <>
      <SideNavone>
      <ProviderTable />
      </SideNavone>
    </>
  );
};

export default CalenderPage;