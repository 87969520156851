import { Col } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, Link } from "react-router-dom/cjs/react-router-dom";

const SideMenuServices = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Col className="p-0" md={2}>
      <ul className="service-menu custom-border">
        <li className={`${pathname === "/service-detail" && "active-menu"}`}>
          <Link to="/service-detail">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Service Detail</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li className={`${pathname === "/attributes" && "active-menu"}`}>
          <Link to="/attributes">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Attributes</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li className={`${pathname === "/pricing" && "active-menu"}`}>
          <Link to="/pricing">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Pricing</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li className={`${pathname === "/cost" && "active-menu"}`}>
          <Link to="/cost">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Cost</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li className={`${pathname === "/service-group" && "active-menu"}`}>
          <Link to="/service-group">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Service Group</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li className={`${pathname === "/service-settings" && "active-menu"}`}>
          <Link to="/service-settings">
            <div class="link-wrap">
              <div className="menu d-flex justify-content-between">
                <span className="menu-link">Service Settings</span>
                <ArrowForwardIosIcon className="caret" />
              </div>
            </div>
          </Link>
        </li>
        <li>
          <div class="link-wrap">
            <div className="menu d-flex justify-content-between">
              <span className="menu-link">Attachment</span>
              <ArrowForwardIosIcon className="caret" />
            </div>
          </div>
        </li>
      </ul>
    </Col>
  );
};
export default SideMenuServices;
