import React from "react";
import "./Joinus.css";
import { office } from "../../Assets";

export const Joinus = () => {
  return (
    <div className="joinusContainer">
      <div className="joinusChild1">
        <img src={office} alt="" />
      </div>
      <div className="joinusChild2">
        <p className="joinusText">
          <span className="joinusBold">Empower your business </span>
          <span>& bolster operational efficiency.</span>
        </p>
        <p className="joinusInfo">
          Having Plexaar ERP by your side lets you easily view every stage of
          your business process in a lucid and simple interface. By integrating
          this highly customizable ERP system into your business, you are
          improving your operational efficiency and, ultimately, your business
          performance.
        </p>
        <a href="/login">
          <button className="joinusBtn">Join us today</button>
        </a>
      </div>
    </div>
  );
};
