import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SideNavBar = ({ extractedPart }) => {
  return (
    <>
      {extractedPart === "calendar" ||extractedPart === "addScheduler"? (
        <div className=" pt-1 pb-2" style={{ width: "20px" }}>
          <div className="d-flex justify-content-between align-item-center">
            <div className="">
              <div className="">
                <div className="px-0 ">
                  <Link to="/dashboard">
                    <svg
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="33" height="33" rx="8" fill="#F6F6F6" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.3954 23.8074C19.8061 23.8074 18.3342 23.3043 17.1305 22.4486C17.5868 21.9616 17.9847 21.4192 18.3128 20.8325H25.4672C25.0813 21.7479 24.5673 22.5957 23.9479 23.3534C23.1526 23.647 22.2927 23.8074 21.3954 23.8074ZM25.4961 12.8748H18.3512C17.9175 12.0815 17.3572 11.3675 16.6973 10.7598C17.9727 9.70336 19.6099 9.06836 21.3954 9.06836C21.9324 9.06836 22.456 9.1258 22.9604 9.23486C24.0491 10.2414 24.9205 11.481 25.4961 12.8748Z"
                        fill="url(#paint0_linear_2045_730)"
                      />
                      <path
                        d="M21.2599 9.62658C21.8533 9.62658 22.4297 9.70028 22.9805 9.83909C20.6892 7.61017 17.3765 6.51689 14.0129 7.20356C12.8999 7.43079 11.8679 7.83524 10.9402 8.38163C11.1026 8.36255 11.2724 8.34807 11.4467 8.33768C12.2256 8.29125 13.1152 8.32541 13.8751 8.40826C14.5523 8.4821 15.5603 8.65178 16.4669 8.93642C16.92 9.07866 17.3559 9.25211 17.7147 9.46139C18.0482 9.6559 18.3384 9.89473 18.5004 10.1897C19.3483 9.82714 20.2808 9.62658 21.2599 9.62658Z"
                        fill="#FFE24D"
                      />
                      <path
                        d="M18.0757 10.3884C17.9687 10.2112 17.7688 10.0348 17.48 9.86641C17.1624 9.68117 16.7624 9.52008 16.3275 9.38352C15.4581 9.11057 14.4816 8.94564 13.8246 8.87401C13.0862 8.79349 12.223 8.76072 11.4744 8.80534C11.0999 8.82766 10.7595 8.86905 10.4803 8.93151C10.1946 8.99539 9.9987 9.0759 9.89119 9.16028L9.85875 9.11869C8.41283 10.2502 7.3052 11.7608 6.65509 13.4699C6.93844 13.39 7.27557 13.338 7.64709 13.3094C8.39252 13.252 9.30037 13.2867 10.2408 13.3945C11.1821 13.5023 12.1486 13.6889 12.9519 13.9485C13.3535 14.0783 13.7197 14.2281 14.0235 14.3986C14.194 14.4943 14.3499 14.5994 14.4825 14.715C15.0414 12.8248 16.3573 11.2635 18.0757 10.3884Z"
                        fill="#FFD705"
                      />
                      <path
                        d="M14.3466 15.2499C14.2397 15.104 14.0565 14.954 13.7955 14.8075C13.5271 14.6568 13.1917 14.5182 12.8087 14.3945C12.0429 14.1469 11.1082 13.9654 10.1878 13.8599C9.26641 13.7543 8.38982 13.7221 7.68285 13.7765C7.32919 13.8038 7.02396 13.8523 6.77944 13.9214C6.64991 13.9581 6.54292 13.999 6.45686 14.0423C5.96654 15.6093 5.85337 17.3193 6.2029 19.0427C6.26889 19.3681 6.34989 19.6866 6.44501 19.9976C6.46433 20.0049 6.48595 20.0127 6.50994 20.021C6.64964 20.0693 6.86954 20.1333 7.17915 20.1897C7.79827 20.3027 8.77743 20.386 10.1927 20.2539C11.6241 20.1203 12.6392 19.9629 13.3501 19.7522C14.0025 19.5588 14.3666 19.3297 14.5683 19.0639C14.3223 18.3396 14.1888 17.5626 14.1888 16.7543C14.1888 16.2382 14.2432 15.7349 14.3466 15.2499Z"
                        fill="#F5BF00"
                      />
                      <path
                        d="M14.7547 19.5529C14.4622 19.8281 14.0429 20.0353 13.4824 20.2015C12.7257 20.4258 11.6729 20.5863 10.2359 20.7204C8.7827 20.856 7.76062 20.772 7.09562 20.6507C6.91449 20.6176 6.75994 20.5818 6.63049 20.547C7.42285 22.6883 8.90004 24.4358 10.7515 25.5831C11.9806 25.7003 13.0065 25.3159 14.2504 24.726C15.5721 24.0992 16.5262 23.3787 16.9929 22.4384C16.0185 21.694 15.2414 20.7009 14.7547 19.5529Z"
                        fill="#CB9200"
                      />
                      <path
                        d="M17.3776 22.7126C16.8304 23.7612 15.7748 24.5213 14.4499 25.1495C13.4746 25.6121 12.5843 25.9742 11.6032 26.0554C13.5192 26.9995 15.7498 27.3384 18.0033 26.8784C20.3361 26.4021 22.3129 25.1474 23.7194 23.439C22.9534 23.7254 22.1247 23.882 21.2599 23.882C19.8261 23.882 18.492 23.4518 17.3776 22.7126Z"
                        fill="#9C5E00"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2045_730"
                          x1="21.0966"
                          y1="9.06836"
                          x2="21.0966"
                          y2="23.8074"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#916800" />
                          <stop offset="0.295918" stopColor="#FFE871" />
                          <stop offset="0.766766" stopColor="#FFD600" />
                          <stop offset="1" stopColor="#B37600" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SideNavBar;
