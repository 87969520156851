import dayjs from "dayjs";
import { FormateTime } from "../../schedulerModule/utils";
import * as yup from "yup";

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
function convertToDateTime(inputTime) {
  // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
  if (dayjs(inputTime, { strict: true }).isValid()) {
    return dayjs(inputTime);
  }

  // Parse input time as "HH:mm:ss"
  const currentTime = dayjs();
  const [hours, minutes, seconds] = inputTime.split(":");

  // Set the time components
  const formattedDateTime = currentTime
    .set("hour", parseInt(hours, 10))
    .set("minute", parseInt(minutes, 10))
    .set("second", parseInt(seconds, 10));

  return formattedDateTime;
}
export const initialValues = (businessId, action, actionState, editData) => {
  if (actionState === "edit" && editData && editData.length > 0) {
    const mappedData = editData.map((item) => ({
      id: item.id,
      businessId: item.businessId,
      day: item.day,
      businessStartTime: convertToDateTime(`${item.businessStartTime}:00`),
      businessEndTime: convertToDateTime(`${item.businessEndTime}:00`),
      isAvailable: item.isAvailable,
      hourFormat:
        item.businessStartTime === "00:00" && item.businessEndTime === "23:59"
          ? true
          : false,
    }));
    return mappedData;
  } else {
    // Set the mappedData as the initial values using setFieldValue

    const day = daysOfWeek.map((day) => ({
      day: day,
      id: businessId,
      businessStartTime: convertToDateTime("08:00:00"),
      businessEndTime: convertToDateTime("22:00:00"),
      isAvailable: true,
      hourFormat: false,
    }));
    if (actionState === "edit" && editData && editData.length > 0) {
    }
    return day;
  }
};

const daySchema = yup.object().shape({
  day: yup.string().required(),
  id: yup.string().required(),
  businessStartTime: yup.string().when(["hourFormat", "isAvailable"], {
    is: (hourFormat, isAvailable) => hourFormat && !isAvailable,
    then: yup.string().strip(),
    otherwise: yup
      .string()
      .nullable()
      .required("Business start time is required"),
  }),
  businessEndTime: yup.string().when(["hourFormat", "isAvailable"], {
    is: (hourFormat, isAvailable) => hourFormat && !isAvailable,
    then: yup.string().strip(),
    otherwise: yup
      .string()
      .nullable()
      .required("Business end time is required"),
  }),
  isAvailable: yup.boolean().required(),
  hourFormat: yup.boolean().required(),
});

export const combinedSchema = yup.object().shape({
  // Your existing validations...
  businessStartTime: daySchema.fields.businessStartTime,
  businessEndTime: daySchema.fields.businessEndTime,

  day: yup
    .array()
    .of(daySchema)
    .test(
      "atLeastOneAvailable",
      "At least one availability should be true",
      function (dayArray) {
        return dayArray.some((item) => item.isAvailable === true);
      }
    ),
});

export const formateData = (values, businessId) => {
  const data = values.map((data) => ({
    day: data.day,
    businessId: businessId,
    businessStartTime:
      // data.isAvailable === ?
         data.hourFormat === true
          ? "00:00"
          : FormateTime(data.businessStartTime)
        // : "",
        ,
    businessEndTime:
      // data.isAvailable === true
        // ? 
        data.hourFormat === true
          ? "23:59"
          : FormateTime(data.businessEndTime)
        // : "",
        ,
    isAvailable: data.isAvailable,
  }));

  return data;
};
export const formateDataUpdate = (values, businessId) => {
  const data = values.map((data) => ({
    day: data.day,
    businessId: businessId,
    businessStartTime:
      // data.isAvailable === true
      //   ?
         data.hourFormat === true
          ? "00:00"
          :FormateTime(`${data.businessStartTime}`)
        // : ""
        ,
    businessEndTime:
      // data.isAvailable === true
      //   ? 
        data.hourFormat === true
          ? "23:59"
          : FormateTime(`${data.businessEndTime}`)
        // : ""
        ,
    isAvailable: data.isAvailable,
  }));

  return data;
};
