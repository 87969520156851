import { Tooltip } from "@mui/material";
import { MdOutlineAddCircle } from "react-icons/md";
import * as Yup from "yup";

export const record = [
  {
    image: "../images/Custom Size29.jpg",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "12-06-2023",
    title: "ChatBot",
  },
  {
    image: "../images/Custom Size – 32.jpg",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "30-03-2023",
    title: "Plaxaar",
  },
  {
    image: "../images/NoPath - Copy (3).png",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "21-04-2023",
    title: "Instagram",
  },
  {
    image: "../images/Custom Size – 30.jpg",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "12-04-2023",
    title: "Facebook",
  },
  {
    image: "../images/NoPath - Copy (4).jpg",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "21-03-2023",
    title: "Meet Up",
  },
  {
    image: "../images/Custom Size29.jpg",
    type: "basic",
    total: " 4 / 16",
    startDate: "20-03-2023",
    endDate: "12-05-2023",
    title: "ChatBot",
  },
];
export const formateData = (values, id) => {
  return {
    startDate: values.activeFrom,
    endDate: values.activeTill,
  };
};
export const AddButton = ({
  title = "",
  onClick,
  className = "",
  iconClassName = "",
}) => {
  return (
    <Tooltip placement="right" title={title}>
      {/* <button
        onClick={() => onClick()}
        className={`bg-gradient rounded-full h-12 w-12 flex justify-center items-center ${className}`}
      > */}
      <MdOutlineAddCircle
        color="548DFF"
        size={100}
        onClick={() => onClick()}
        style={{cursor:"pointer"}}
        className={`col-11  ${iconClassName}`}
      />
      {/* </button> */}
    </Tooltip>
  );
};
export const subscribeInitialValue = (action, editData) => {
  const initialValues = {
    subscription: action === "new" ? editData.name : "",
    paymentMethod: action === "new" ? editData.departmentDescription : "",
    activeTill: "",
    activeFrom: "",
  };
  return initialValues;
};
export const validationSchema = Yup.object({
  subscription: Yup.string().required("Enter the  Name"),
  activeTill: Yup.string().required("Enter the  Name"),
  activeFrom: Yup.string().required("Enter the  Name"),
  paymentMethod: Yup.object().nullable().required("payment method Require"),
});
