import { Col, Row, Table, Spinner } from "react-bootstrap";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import OffCanvasContainer from "./offCanvas/OffCanvasContainer";

const ServiceCost = ({
  show,
  handleClose,
  handleShow,
  costList,
  isLoading,
  costTypeId,
  unitTypeId,
  costType,
  handleCostTypeChange,
}) => {
  return (
    <Row className="dynamic_formContainer service-add-detail service-pricing-wrap service-cost-wrap px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head select-service-type my-3">
            Add costing details of services
          </h6>
          <Row className="price-table-wrap att-table mx-0">
            <Col className="px-0" xs={12}>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className="table-cell"></th>
                    <th className="px-0 description-cell">Cost Type</th>
                    <th className="sku-cell ps-1">Title</th>
                    <th className="duration-cell ps-2">Unit Type</th>
                    <th className="price-cell">Price</th>
                  </tr>
                </thead>
                <tbody
                  className={`${
                    isLoading &&
                    "d-flex justify-content-center align-items-center py-5"
                  }
                  `}
                >
                  {isLoading ? (
                    <Spinner animation="border" variant="dark" size="sm" />
                  ) : (
                    costList?.map((el) => (
                      <tr className={`${show && "tr"}`} key={el.id}>
                        <td className="table-cell p-0 py-3">
                          <div className="border-right">
                            <MoreVertIcon className="pe-2 cursor-pointer" />
                          </div>
                        </td>
                        <td className="text-truncate px-2">
                          <Select
                            className="w-100 pricing-table-select border-right"
                            labelId="cost-type"
                            id={costTypeId}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  fontSize: "18px",
                                  color: "#aaaaaa",
                                  marginLeft: "-45px",
                                }}
                              />
                            )}
                            value={costType || el.costTypeTitle}
                            onChange={handleCostTypeChange}
                          >
                            {costList.map((costTypeEl) => (
                              <MenuItem
                                value={costTypeEl.costTypeTitle}
                                key={costTypeEl.id}
                              >
                                {costTypeEl.costTypeTitle}
                              </MenuItem>
                            ))}
                          </Select>
                        </td>
                        <td className="sku-cell px-1 text-wrap">
                          <div className="border-right">{el.title}</div>
                        </td>
                        <td className="duration-cell px-0 text-truncate">
                          <Select
                            className="w-100 pricing-table-select border-right"
                            labelId="unit-type"
                            id={unitTypeId}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  fontSize: "18px",
                                  color: "#aaaaaa",
                                  marginLeft: "-45px",
                                }}
                              />
                            )}
                            value={10}
                          >
                            {costList.map((unitType) => (
                              <MenuItem value={10} key={unitType.id}>
                                {unitType.costType}
                              </MenuItem>
                            ))}
                          </Select>
                        </td>
                        <td className="price-cell px-1">
                          <div className="d-flex justify-content-between pe-1">
                            <span className="ps-2">
                              {el.currencySymbol}
                              {el.cost}
                            </span>
                            <RemoveCircleOutlineIcon className="remove cursor-pointer" />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {!isLoading && (
                <div
                  className="table-add-new d-flex align-items-center"
                  onClick={handleShow}
                >
                  <>
                    <AddCircleOutlineIcon
                      className={`add-circle-table ${show && "invisible"}`}
                    />
                    <span className={show && "invisible"}>Add New</span>
                  </>
                </div>
              )}
            </Col>
          </Row>
          <div className="pt-3">
            <div className="d-flex justify-content-end gap-3 mt-5 pt-5">
              <button className="cancel-btn">Cancel</button>
              <button className="save-btn">Save</button>
            </div>
          </div>
        </div>
      </Col>
      <OffCanvasContainer
        placement="end"
        name="End"
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        costList={costList}
      />
    </Row>
  );
};
export default ServiceCost;
