import moment from "moment";

export function getTimeStops(start, end){
    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');
    
    if( endTime.isBefore(startTime) ){
      endTime.add(1, 'day');
    }
  
    var timeStops = [];
  
    while(startTime <= endTime){
      timeStops.push(new moment(startTime).format('HH:mm'));
      startTime.add(15, 'minutes');
    }
    return timeStops;
  }

export function dateAuthentication(date) {
    const today =  moment().format('LL')
    const update = moment(date).format('LL')
    if (update<today) {
      return true;
    }
    return false;
  };


export function getMinDiff(startTime, endTime) {
  const time1 = startTime.split(':')
  const time2 = endTime.split(':')
  const diff1= parseInt(time2[0])-parseInt(time1[0])
  const diff2= parseInt(time2[1])-parseInt(time1[1])
  if(diff1===0){
    return diff2
  }else{
    return((diff1*60)+diff2)
  }
  }