import React from "react";
import "./notes.css";
import { RightAngle } from "../../Calendar/scheduler/AppointmentDetails/Svgs/svgs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { saleOrderDetails } from "../../Calendar/scheduler/store/bookingSlice";
import { useDispatch } from "react-redux";

const LeftSection = (bookingData) => {
//   let bookingData = useSelector((state) => state.bookingReducer.bookingData);
console.log("booking data left section",bookingData);
console.log("servicenameleft", bookingData?.bookingData?.amountpayable);
  const history = useHistory()
  const dispatch = useDispatch()
  const data = {
    OrderNumber: "14658e4",
    Quantity: "2",
    Dates: [
      "Friday, 5th Mar, 2023",
      "Friday, 6th Mar, 2023",
      "Friday, 7th Mar, 2023",
      "Friday, 8th Mar, 2023",
    ],
    Appointment: "Friday, 7th Mar, 2023",
    TotalAmount: 87,
    currency: "$",
    PaymentStatus: "Paid",
  };
  return (
    <div className="left-section">
      <div className="order-details-section-container">
        <div style={{
          cursor:"pointer"
        }} className="Order-details_order-num">
          <label className="order-num_heading">Order Number</label>
          <div onClick={()=>{
            if(bookingData?.bookingData?.quantity>1){
            dispatch(saleOrderDetails(bookingData.bookingData?.plexaarSalesOrderId))
            history.push("/scheduler/order_details")
            }
            
          }} className="d-flex justify-content-between align-items-baseline">
            <span className="order-num_number m-0">
              {bookingData.bookingData?.plexaarBookingId}
            </span>
            <RightAngle />
          </div>
        </div>

        <hr className="left-section-container-lines" />
        <div className="Order-details_quantity">
          <label className="quantity_heading">Service Name</label>
          <span className="order-num_number text-capitalize m-0">{bookingData?.bookingData?.serviceName}</span>
        </div>
        <hr className="left-section-container-lines" />
        <div className="Order-details_quantity">
          <label className="quantity_heading">Customer Name</label>
          <p className="order-num_number text-capitalize m-0">{bookingData?.bookingData?.customerFirstName} <span>{bookingData?.bookingData?.customerLastName}</span></p>
        </div>
      </div>

      <div
        className={`payment-status-section-container  ${
          bookingData?.bookingData?.payment_status === "paid"
            ? "success-payment"
            : "danger-payment"
        } mt-4`}
      >
        <div className="Order-details_order-num">
          <label className="total-amount_heading">Total Amount</label>
          <div className="d-flex justify-content-between align-items-baseline">
            <span className="total-amount_number">
              {bookingData?.bookingData?.currency === "usd" ? "$" : "£"}
              {bookingData?.bookingData?.totalPrice}{" "}
            </span>
          </div>
        </div>

        <hr className="left-section-container-lines " />
        <div className="Order-details_quantity">
          <label className="quantity_heading">Payment Status</label>
          <div className="d-flex justify-content-between align-items-baseline">
            <span
              className={`order-num_number ${
                bookingData?.bookingData?.payment_status === "paid"
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {bookingData?.bookingData?.payment_status}
            </span>
            <RightAngle />
          </div>
        </div>
      </div>

      {/* <div className="all-appointments-section mt-1">
        <label className="section-heading m-1">Notes for</label>
        <div className="all-appointments-section-container ">
          {data.Dates.map((date, index) => (
            <div className="">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="all-appointments_dates">{date}</h6>
                <RightAngle />
              </div>
              {index < data.Dates.length - 1 ? (
                <hr className="left-section-container-lines" />
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default LeftSection;
