const API_BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;
const API_BASE_URL2 = `${process.env.REACT_APP_BASE_URL}/`;

const API_ENDPOINTS = {
  GET_DATE_SCHEDULE:"provideravailability_svc/pb/get/provider/schedule/",
  POST_PROVIDER_SCHEDULE:
    "provideravailability_svc/pb/update/provider/Schedule/date",
  POST_SCHEDULE: "provideravailability_svc/pb/post/schedule/",
  UPDATE_SCHEDULE: "provideravailability_svc/pb/update/provider/Schedule",
  GET_SCHEDULE_LIST:
    "provideravailability_svc/pb/get/provider/addedSchedule/list",
  GET_SCHEDULE: "provideravailability_svc/pb/get/provider/addedSchedule",
  DELETE_SCHEDULE: "provideravailability_svc/pb/delete/schedule/",
  POST_DATE_SCHEDULE: "provideravailability_svc/pb/post/date/schedule/",

  // service cost
  GET_ALL_SERVICES_COST:"serviceinventory_svc/pb/Cost/getAllServicesCost",
  //Notifictaion API 
  GET_ALL_NOTIFACTIONS:"notification_svc/pb/get/all?"
};
export { API_BASE_URL, API_ENDPOINTS };
