import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddStaffColumn = ({ data, updateData }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelecteData] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const location = useLocation();

  const handleMenuClick = (event, data) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row.id);
    setSelecteData(data);
  };
  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleHideClick = async () => {
    if (
      location.pathname === "/edit-service/column" ||
      location.pathname === "/edit-customer/column"
    ) {
      const updatedColumns = data.map((column) =>
        column.id === selectedData.id ? { ...column, isShown: false } : column
      );
      updateData(updatedColumns);
    }
    if (
      location.pathname === "/edit-business/column" ||
      location.pathname === "/edit-business-departments/column" ||
      location.pathname === "/edit-business-user/column" ||
      location.pathname === "/edit-business-team/column"
    ) {
      const updatedColumns = data.map((column) =>
        column.columnId === selectedData.columnId
          ? { ...column, isShown: false }
          : column
      );
      updateData(updatedColumns);
    }
    setOpen(false);
    // const updatedColumns = data.map((column) =>
    //   column.id === selectedData.id ? { ...column, isShown: false } : column
    // );

    // console.log("data", data);
    // console.log("selectedData#", selectedData);

    // updateData(updatedColumns);
    // setOpen(false);

    // const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
    //   const { serviceName, columnName, ...rest } = item;
    //   return rest;
    // });

    // const payload = {
    //   userId: 4,
    //   columns: dataWithoutServiceNameAndColumnName,
    //   createdBy: 0,
    // };
    //   const result = await updateColumnPreferrence(payload);
    //   const code = result.data.code;
    //   const message = result.data.message;
    //   if (code === 0) {
    //     updateData(updatedColumns);
    //   } else {
    //   }
  };
  const handleFreezeClick = () => {};
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedData = Array.from(data).map((column) => ({ ...column }));
    const [removed] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, removed);
    reorderedData.forEach((column, index) => {
      column.position = index + 1;
    });

    updateData(reorderedData);
  };

  return (
    <>
      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {" "}
        <Box sx={{ display: "flex", maxWidth: "982px" }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columnList" direction="horizontal">
              {(provided) => (
                <Grid
                  container
                  wrap="nowrap"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {data
                    // .filter((column) => column.isShown)
                    // .sort((a, b) => a.position - b.position)
                    .map((columnName, index) => (
                      <Draggable
                        key={columnName.columnName}
                        draggableId={columnName.columnName}
                        index={index}
                      >
                        {(provided) => (
                          <Grid
                            item
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {columnName.isShown && (
                              <Box
                                sx={{
                                  border: "1px solid #DCDCDC",
                                }}
                                component="div"
                                bgcolor="#FFFFFF"
                                height="41px"
                                display="flex"
                                justifyContent="space-between"
                                minWidth="222px"
                                alignItems="center"
                              >
                                <Typography pl={2}>
                                  {columnName.columnName}{" "}
                                </Typography>
                                <MoreVertIcon
                                  fontSize="small"
                                  onClick={(event) =>
                                    handleMenuClick(event, columnName)
                                  }
                                />
                              </Box>
                            )}
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        {/* <button onClick={handleSaveChanges}>Save Changes</button> */}
      </Box>
      {open && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleHideClick}>
            {/* <MenuItem onClick={() => handleHideClick(columnName.columnName)}> */}
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
              hide
            </Box>
          </MenuItem>
          {/* <MenuItem onClick={handleFreezeClick}>
           
            Freeze
          </MenuItem> */}
        </Menu>
      )}
    </>
  );
};

export default AddStaffColumn;
