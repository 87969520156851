import * as React from 'react';
import Plexar from '../../assets/scheduler/logo/plexaar final web-17 1.svg'
import Expert from '../../assets/scheduler/logo/expert logo-ARS-01 3.svg'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { SvgIcon } from '@mui/material';
import Sidebar from './Sidebar'

export default function Header() {

  return (
    <>
   <div style={{background: "#5599FF", padding:"8px 40px"}}>
    <div className='d-flex  justify-content-between align-items-center'>
    <img src={Plexar} alt="Plexaar" style={{background:"#ffffff", padding:"4px 8px", borderRadius:'5px'}}/>
    <div className='d-flex  justify-content-between align-items-center'>
    <SvgIcon className="m-0 text-white" style={{paddingRight:"3px"}} component={NotificationsNoneIcon} />
    <SvgIcon className="m-0 text-white" style={{paddingRight:"3px"}} component={SettingsOutlinedIcon} />
    <div style={{borderRight:"5px solid #ffffff", width:'5px'}}></div>
    <div className='d-flex  align-items-center'>
    <img src={Expert} alt="Plexaar" style={{ padding:"2px 8px", borderRadius:'5px'}}/>
    <div >
      <p className='p-0 m-0 text-white' style={{textAlign:'initial'}}>Admin</p>
      <p className='p-0 m-0 text-white'><b>Expert Medical Center</b></p>
    </div>
    </div>
    </div>
    </div>
   
   </div>
   {/* <Sidebar/> */}
   </>
  );
}