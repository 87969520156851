import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { IconButton, Pagination, Stack, Tooltip } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { FaRegEdit } from "react-icons/fa";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import { getSchedule } from "../../services/serviceProvider";
import { error } from "../../utils";

const TableList = ({ data, businessId, handleOpen }) => {
  const [columns, setColumns] = useState([
    { id: 1, name: "Staff Name" },
    { id: 2, name: "Qualification" },
    { id: 3, name: "Nationality" },
    { id: 4, name: "Designation" },
  ]);

  const dropdown_columns = [
    { id: 7, name: "Column 1", width: "100px" },
    { id: 8, name: "Column 2", width: "100px" },
    { id: 9, name: "Column 3", width: "100px" },
    { id: 10, name: "Column 4", width: "100px" },
  ];
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnId, setColumnId] = useState("");
  const [isDraggingRow, setIsDraggingRow] = useState(false);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [providerData, setProviderData] = useState();
  const [activeProvider, setActiveProvider] = React.useState("");
  const [schedules, setSchedules] = useState([]);
  const [datas, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [providerRecord, setProviderRecord] = useState("");
  const [rows, setRows] = useState([
    {
      id: 1,
      data: ["", "", "", "", ""],
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const history = useHistory();
  const addColumn = (val) => {
    const newColumn = {
      id: val?.id,
      name: val?.name,
    };
    setColumns([...columns, newColumn]);

    rows.forEach((row) => {
      row.data.push({
        id: val?.id,
        name: "",
      });
    });
    setRows([...rows]);
  };
  useEffect(() => {
    let isMounted = true;

    // Your asynchronous code here
    fetchData().then(
      (data) => {
        if (isMounted) {
          // Update state only if the component is still mounted
          setState(data);
        }
      },
      [schedules]
    );

    return () => {
      // Set the "isMounted" flag to false when the component unmounts
      isMounted = false;
    };
  }, []);

  const fetchData = async (providerDatas) => {
    try {
      setProviderRecord(providerDatas);

      // Fetch provider data
      setLoading(true); // Show loading indicator

      const providerResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/addedSchedule?providerId=${providerDatas.providerId}`
      );
      setProviderData(providerResponse.data.result);
      setData(providerResponse.data.result.startDate);
      if (providerResponse.code === 0) {
        return providerResponse;
      } else {
        appointmentData = {
          date: date,
          actionState: "new",
        };
        localStorage.setItem("providerData", JSON.stringify(providerRecord));

        history.push(
          `/timeSlot?busiessId=${businessId}&providerId=${providerRecord.providerId}`,
          { appointmentData }
        );
        error("Provider does'nt have Scheduler Please Add it");
      }
      // if (providerResponse.data.result) {
      //   // Fetch schedules
      //   const schedulesResponse = await getSchedules(
      //     providerResponse.data.result,
      //     providerDatas
      //   );
      //   setSchedules(schedulesResponse.result);
      // }

      setLoading(false); // Hide loading indicator
    } catch (err) {
      setLoading(false); // Hide loading indicator in case of an error
    }
  };
  useEffect(()=>{
    
  },[])

  const getSchedules = async (providerData, record) => {
    const now = new Date();

    const servicesData = {
      providerId: providerData ? [providerData.provider] : [],
      startDate: (providerData && providerData.startDate) || "",
      endDate: (providerData && providerData.endDate) || "",
    };
    setActiveProvider(servicesData);

    try {
      setLoading(true);
      const schedulesResponse = await getSchedule(servicesData);

      if (schedulesResponse.code === 0) {
        return schedulesResponse;
      } else {
        appointmentData = {
          date: date,
          actionState: "new",
        };
        localStorage.setItem("providerData", JSON.stringify(providerRecord));

        history.push(
          `/timeSlot?busiessId=${businessId}&providerId=${providerRecord.providerId}`,
          { appointmentData }
        );
        error("Provider does'nt have Scheduler Please Add it");
      }
    } catch (err) {
    }
  };

  const handleRowDragStart = (e, rowIndex) => {
    e.dataTransfer.setData("text/plain", rowIndex);
    setDraggedRowIndex(rowIndex);
  };

  const handleRowDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowDrop = (e, targetRowIndex) => {
    const sourceRowIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
    updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
    setRows(updatedRows);
    setIsDraggingRow(false);
    setDraggedRowIndex(null);
  };

  const handleColumnDragStart = (e, columnIndex) => {
    e.dataTransfer.setData("text/plain", columnIndex);
  };

  const handleColumnDragOver = (e) => {
    e.preventDefault();
  };

  const handleColumnDrop = (e, targetColumnIndex) => {
    const sourceColumnIndex = parseInt(e.dataTransfer.getData("text/plain"));

    const updatedColumns = [...columns];
    const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
    updatedColumns.splice(targetColumnIndex, 0, movedColumn);
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = [...row.data];
      const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
      updatedData.splice(targetColumnIndex, 0, movedData);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  const handleRowCheckboxChange = (e, rowIndex) => {
    const rowId = rows[rowIndex].id;
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const deleteColumn = (columnIndex) => {
    const updatedColumns = columns.filter(
      (col, index) => index !== columnIndex
    );
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = row.data.filter((_, index) => index !== columnIndex);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  // Calculate the index range of data to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);
  useEffect(() => {
    setData(schedules);
  }, [schedules]);

  useEffect(() => {
    if (listData) {
      setRows(() =>
        listData.map((v, i) => {
          return {
            id: v.id,
            serviceId: v?.serviceId,
            hasChild: v?.hasChild,
            allData: v,
            data: [
              { id: 1, fname: v?.firstName, lname: v?.lastName },
              { id: 2, qualification: v?.qualification },
              { id: 3, nationality: v?.nationality },
              { id: 4, designation: v?.designation },
            ],
          };
        })
      );
    }
  }, [listData]);
  const date = new Date();
  let appointmentData = {};
  // Define a function to handle the MoreVertIcon button click
  const handleMoreOptionsClick = async (row) => {
    try {
      const providerDatas = row.allData;

      // Fetch provider data
      setLoading(true); // Show loading indicator

      const providerResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/addedSchedule?providerId=${providerDatas.providerId}`
      );
      setProviderData(providerResponse.data.result);
      setData(providerResponse.data.result.startDate);

        // Fetch schedules
        
   
        // Navigate to the scheduler view
        if (providerResponse.data.result.scheduler.length>0) {

          appointmentData = {
            date: date,
            data: providerResponse.data.result,
            actionState: "edit",
          };
          localStorage.setItem("providerData", JSON.stringify(providerDatas));

          history.push(
            `/timeSlot?busiessId=${businessId}&providerId=${providerDatas.providerId}`,
            { appointmentData }
          );
        } else {
          // If provider data doesn't exist, navigate with the current date
          // history.push(
          //   `/timeSlot?busiessId=${businessId}&providerId=${providerDatas.providerId}`,
          //   { appointmentData }
          // );
          appointmentData = {
            date: date,
            actionState: "new",
          };
          localStorage.setItem("providerData", JSON.stringify(providerRecord));
  
          history.push(
            `/timeSlot?busiessId=${businessId}&providerId=${providerDatas.providerId}`,
            { appointmentData }
          );
          error("Provider does'nt have Scheduler Please Add it");
                }
      

      setLoading(false); // Hide loading indicator
    } catch (err) {
      setLoading(false); // Hide loading indicator in case of an error
    }
  };
  const handlePriovderData = (val) => {
    localStorage.setItem("providerData", JSON.stringify(val.allData));
    history.push(
      `/timeSlot??busiessId=${businessId}&providerId=${val.allData.providerId}`
    );
  };
  const handlePriovderViewData = (val) => {
    localStorage.setItem("providerView", JSON.stringify(val.allData));
    history.push(`/providerSchedulerViewer/${val.allData.providerId}`);
  };
  return (
    <>
      <div
        className="container p-0 hide_scrollbar position-relative pc_display"
        style={{ maxHeight: "80vh", overflow: "scroll" }}
      >
        <div className="row sticky_header">
          <div className="col-6 d-flex align-items-center">
            <h5 className="text-dark mt-3 mb-3 position-sticky">
              Providers List
            </h5>
            <Tooltip title="Refresh List">
              <IconButton aria-label="delete" className="ms-2">
                <CachedIcon
                  className=""
                  sx={{ fontSize: "30px", color: "#f5bf00" }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="row">
          <table
            className="table table_main_list w-100"
            style={{
              marginBottom: "150px",
              // overflowX: 'clip'
            }}
          >
            <thead>
              <tr>
                <th></th>

                {columns.map((column, index) => {
                  if (index < 1) {
                    return (
                      <th id={`sticky_col${column?.id}`} key={column.id}>
                        {column.name}
                      </th>
                    );
                  }

                  return (
                    <th
                      key={column.id}
                      draggable
                      onDragStart={(e) => handleColumnDragStart(e, index)}
                      onDragOver={handleColumnDragOver}
                      onDrop={(e) => handleColumnDrop(e, index)}
                      onMouseOver={() => setColumnId(column.id)}
                      onMouseOut={() => setColumnId("")}
                      className={isDraggingRow ? "dragging-row" : ""}
                      style={{
                        width:
                          (column?.id == 2 && "150px") ||
                          (column?.id == 3 && "80px") ||
                          (column?.id == 4 && "280px") ||
                          (column?.id == 5 && "90px") ||
                          (column?.id == 6 && "80px"),
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        {column.name}

                        <div
                          className="dropdown float-right"
                          style={{
                            visibility:
                              columnId === column.id && column.id !== 0
                                ? "visible"
                                : "hidden",
                            zIndex: "9999",
                            positition: "relative",
                          }}
                        >
                          <button
                            className="btn bg-transparent float-right p-0 m-0"
                            id="dropdownMenuButton"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <MoreVertIcon
                              className="float-right"
                              sx={{ fontSize: "18px" }}
                            />
                          </button>
                          <div
                            className="dropdown-menu px-2 mt-2"
                            aria-labelledby="dropdownMenuButton"
                            onClick={() => deleteColumn(index)}
                          >
                            <DeleteForeverIcon
                              className="text-danger"
                              sx={{ fontSize: "18px" }}
                            />
                            <span
                              className="text-secondary ps-3"
                              style={{ fontSize: "12px" }}
                            >
                              Delete Column
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                  );
                })}
                <th style={{ width: "150px" }}>Action</th>
                <th className="addcol_btn_th">
                  <div className="dropdown">
                    <button
                      className="btn bg-transparent p-0 m-0"
                      id="dropdownMenuButton"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <ControlPointIcon
                        sx={{ color: "gray", margin: "auto", fontSize: "20px" }}
                      />
                    </button>
                    <div
                      className="dropdown-menu px-2 mt-2"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {dropdown_columns &&
                        dropdown_columns.map((val, ind) => {
                          return (
                            <div key={val?.id}>
                              <ControlPointIcon
                                className="text-danger"
                                sx={{ fontSize: "18px" }}
                              />
                              <span
                                className="text-secondary ps-3"
                                style={{ fontSize: "12px" }}
                                onClick={() => addColumn(val)}
                              >
                                {val?.name}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedRows.length > 0 &&
                paginatedRows.map((row, rowIndex) => {
                  return (
                    <tr
                      key={row.serviceId}
                      draggable
                      onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                      onDragOver={handleRowDragOver}
                      onDrop={(e) => handleRowDrop(e, rowIndex)}
                      className={
                        rowIndex === draggedRowIndex ? "dragging-row" : ""
                      }
                    >
                      <td>
                        <div className="form-check ps-4 text-center">
                          <input
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            className="form-check-input checkbox_btn"
                            checked={selectedRows.includes(row.id)}
                            onChange={(e) =>
                              handleRowCheckboxChange(e, rowIndex)
                            }
                            style={{
                              background:
                                rowIndex === draggedRowIndex ? " #D3D3D3" : "",
                            }}
                          />
                        </div>
                      </td>

                      {row?.data.length > 0 &&
                        row?.data.map((cellValue, columnIndex) => {
                          return (
                            <>
                              {cellValue.id == 1 && (
                                <td
                                  key={columnIndex}
                                  id={`sticky_col${columnIndex}`}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  // onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
                                >
                                  <span>
                                    {cellValue?.fname + " " + cellValue?.lname}{" "}
                                  </span>
                                </td>
                              )}

                              {cellValue.id === 2 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    backgroundColor:
                                      rowIndex === draggedRowIndex
                                        ? "#D3D3D3"
                                        : "",
                                  }}
                                >
                                  <span>{cellValue?.qualification}</span>
                                </td>
                              )}

                              {cellValue.id === 3 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  className="text-center"
                                  data-toggle={cellValue?.serviceUrl}
                                  data-placement="top"
                                >
                                  {cellValue?.nationality}
                                </td>
                              )}

                              {cellValue.id === 4 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  {cellValue?.designation}
                                </td>
                              )}

                              {cellValue.id === 5 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  className="text-center"
                                >
                                  <div className="form-check form-switch pt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="flexSwitchCheckDefault"
                                      checked={cellValue?.isActive}
                                    />
                                  </div>
                                </td>
                              )}

                              {cellValue.id === null && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  className="text-center"
                                ></td>
                              )}

                              {cellValue.id === 7 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c7
                                </td>
                              )}

                              {cellValue.id === 8 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c8
                                </td>
                              )}

                              {cellValue.id === 9 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c9
                                </td>
                              )}

                              {cellValue.id === 10 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c10
                                </td>
                              )}
                            </>
                          );
                        })}

                      <td
                        className="text-center"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="dropdown"
                          style={{ positition: "relative" }}
                        >
                          <button
                            className="btn bt-info p-0 m-0"
                            id="dropdownMenuButton"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => {
                              fetchData(row.allData);
                            }}
                            style={{ position: "relative", zIndex: "0" }}
                          >
                            <MoreVertIcon
                              className="float"
                              sx={{ fontSize: "18px", zIndex: "99" }}
                            />
                          </button>
                          <div
                            className="dropdown-menu px-2 mt-2"
                            aria-labelledby="dropdownMenuButton"
                            style={{ position: "relative", zIndex: "4" }}
                          >
                            <div
                              className="py-1"
                              onClick={(e) => handleMoreOptionsClick(row)}
                            >
                              <AddBoxIcon
                                className="text-primary"
                                sx={{ fontSize: "18px" }}
                              />
                              <span
                                className="text-secondary ps-3"
                                style={{ fontSize: "12px" }}
                              >
                                Add Scheduler
                              </span>
                            </div>
                            {/* <div
                              className="py-1"
                              onClick={(e) => handlePriovderViewData(row)}
                            >
                              <AddBoxIcon
                                className="text-success"
                                sx={{ fontSize: "18px" }}
                              />
                              <span
                                className="text-secondary ps-3"
                                style={{ fontSize: "12px" }}
                              >
                                Edit Schedular
                              </span>
                            </div> */}
                            {/* <div
                              className="py-1"
                              onClick={(e) => handlePriovderViewData(row)}
                            >
                              <FaRegEdit
                                className="text-warning"
                                sx={{ fontSize: "18px" }}
                              />
                              <span
                                className="text-secondary ps-3"
                                style={{ fontSize: "12px" }}
                              >
                                View
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row pc_display mt-3">
        <div className="col-12 d-flex justify-content-center">
          <select
            className="items_per_page_select"
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option disabled>Items Per page</option>
            <option value={10} selected>
              10
            </option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={rows?.length}>All</option>
          </select>

          <Stack spacing={2} className="">
            <Pagination
              count={Math.ceil(rows.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default TableList;
