import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useParams } from 'react-router-dom';
import { deleteRequest, postRequest, putRequest } from '../../../request';
import { FaRegEdit } from 'react-icons/fa';
import { base_url } from '../../../base_url';
import { useDispatch, useSelector } from 'react-redux';
import { getServicePrices } from '../../../store/servicesActions';
import { disableMiniLoader, enableMiniLoader, miniLoadingAction } from '../../../store/ServicesSlice';
import { BntLoader } from '../../../../../Componenets/loaders';
import { Pagination, Stack } from '@mui/material';

const PriceListMobile = ({ renderVarbilesList }) => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Price Unit', width: '200px' },
        { id: 2, name: 'Currency', width: '120px' },
        { id: 3, name: 'Price', width: '120px' },
        { id: 4, name: 'Min Amount', width: '120px' },
        { id: 5, name: 'Coverage', width: '150px' },
        { id: 6, name: 'Variables', width: '150px' },
    ]);

    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');

    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);

    const [defaultColumns, setDefaultColumns] = useState([
        { id: 1, width: '150px' },
        { id: 2, width: '120px' },
        { id: 3, width: '280px' },
        { id: 4, width: '90px' },
        { id: 5, width: '80px' },
        { id: 6, name: '80px' },
    ]);
    const [priceUnit, setPriceUnit] = useState('');
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState('');
    const [minAmount, setMinAmount] = useState('0');
    const [coverageAmount, setCoverageAmount] = useState('0');
    const [selectedId, setSelectedId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const { id } = useParams();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };


    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };


    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);

        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteSelectedRows = async (delId) => {
        const res = await deleteRequest(`${base_url}/ServicePrice/RemoveServicePrice?Id=${delId}`)
        if (res?.message === "succeeded") {
            alert('Service Price deleted successfully');
            dispatch(getServicePrices(id));
        }
        else {
            alert(res?.message);
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const postData = async () => {
        if (priceUnit && currency && price && minAmount && coverageAmount) {
            if (parseInt(price) < (parseInt(minAmount) + parseInt(coverageAmount))) {
                alert("Total price cannot be less than the sum of minimum amount and coverage amount.")
            }
            else {
                dispatch(enableMiniLoader());
                if (selectedId) {
                    const data = {
                        id: selectedId,
                        serviceId: parseInt(id),
                        type: priceUnit,
                        currency: currency,
                        price: parseInt(price),
                        minimumAmount: parseInt(minAmount),
                        minAmountCoverage: coverageAmount,
                    }
                    const res = await putRequest(`${base_url}/ServicePrice/UpdateServicePrice`, data);
                    if (res?.message === "succeeded") {
                        dispatch(disableMiniLoader(id));
                        setPriceUnit('');
                        setPrice(0);
                        setCurrency('');
                        setMinAmount('0');
                        setCoverageAmount('0')
                        setSelectedId(null);
                        alert('Service Price Updated successfully');
                    }
                    else {
                        dispatch(disableMiniLoader());
                        alert(res?.message)
                    }
                }
                else {
                    const data = {
                        serviceId: parseInt(id),
                        type: priceUnit,
                        currency: currency,
                        price: parseInt(price),
                        minimumAmount: parseInt(minAmount),
                        minimumAmountCoverage: coverageAmount,
                    }
                    const res = await postRequest(`${base_url}/ServicePrice/AddServicePrice`, data);
                    if (res?.message === "succeeded") {
                        dispatch(getServicePrices(id));
                        dispatch(disableMiniLoader());
                        setPriceUnit('');
                        setPrice(0);
                        setCurrency('');
                        setMinAmount('0');
                        setCoverageAmount('0')
                        alert('Service Price added successfully');
                    }
                    else {
                        dispatch(disableMiniLoader());
                        alert(res?.message)
                    }
                }
            }
        }
        else {
            alert('Please provide missing value')
        }
    }

    const getEditData = (id) => {
        setSelectedId(id)
        const filtered = rows.filter(elem => elem?.id === id);
        if (filtered) {
            filtered[0]?.data?.map(v => {
                if (v?.priceType) {
                    setPriceUnit(v?.priceType);
                }
                else if (v?.servicePrice) {
                    setPrice(v?.servicePrice);
                }
                else if (v?.currency) {
                    setCurrency(v?.currency);
                }
                else if (v?.minimumAmount) {
                    setMinAmount(v?.minimumAmount);
                }
                else if (v?.minimumAmountCoverage) {
                    setCoverageAmount(v?.minimumAmountCoverage);
                }

            });
        }
    }

    useEffect(() => {
        if (store?.servicePrices) {
            setRows(() => store?.servicePrices.map((v, i) => {
                return (
                    {
                        id: v?.id, serviceId: v?.serviceId,
                        data: [{ id: 1, priceType: v?.priceType }, { id: 2, currency: v?.currency }, { id: 3, servicePrice: v?.servicePrice }, { id: 4, minimumAmount: v?.minimumAmount }, { id: 5, minimumAmountCoverage: v?.minimumAmountCoverage }, { id: 6, variablePrices: v?.variablePrices?.length }]
                    })
            }))
        }
    }, [store?.servicePrices]);

    useEffect(() => {
        dispatch(disableMiniLoader())
    }, []);


    // Calculate the index range of data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRows = rows.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    return (
        <>
            <div className="container p-0 hide_scrollbar position-relative mobile_display mt-3" style={{ maxHeight: '43vh', overflow: 'scroll' }}>
                <table className="table pricing_form_container_mobile w-100"
                    style={{
                        marginBottom: '150px',
                        // overflowX: 'clip' 
                    }}>
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {columns.map((column, index) => {
                                if (index < 1) {
                                    return (
                                        <th
                                            id={`sticky_col${column?.id}`}
                                            key={column.id}
                                        >{column.name}</th>
                                    );
                                }

                                return (
                                    <th
                                        // key={column.id}
                                        draggable
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}

                                        onMouseOver={() => setColumnId(column.id)}
                                        onMouseOut={() => setColumnId('')}
                                        className={isDraggingRow ? 'dragging-row' : ''}

                                        style={{
                                            width:
                                                // column?.width
                                                column?.id == 2 && '150px' ||
                                                column?.id == 3 && '120px' ||
                                                column?.id == 4 && '280px' ||
                                                column?.id == 5 && '90px' ||
                                                column?.id == 6 && '80px'
                                        }}
                                    >
                                        <div className='d-flex justify-content-center'>
                                            {column.name}
                                        </div>
                                        <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                            <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                            </div>
                                        </div>

                                    </th>
                                );
                            })}
                            <th style={{ width: '70px' }}>
                                Action
                            </th>
                            <th className='addcol_btn_th'
                                style={{ width: '73px' }}
                            >
                                <div className="dropdown">
                                    <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                    </button>
                                    <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                        {dropdown_columns && dropdown_columns.map((val, ind) => {
                                            return (
                                                <div key={val?.id}>
                                                    <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>


                    <tbody>
                        {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => (
                            <>
                                <tr
                                    key={row.id}
                                    draggable
                                    onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                    onDragOver={handleRowDragOver}
                                    onDrop={(e) => handleRowDrop(e, rowIndex)}
                                    handleDragEnd={handleDragEnd}
                                    className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                                >
                                    <td>
                                        <div className="form-check ps-4 text-center">
                                            <input type="checkbox" value="" id="flexCheckDefault"
                                                className="form-check-input checkbox_btn"
                                                checked={selectedRows.includes(row.id)}
                                                onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                                style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                            />
                                        </div>
                                    </td>

                                    {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                        return (
                                            <>
                                                {cellValue.id === 1 &&
                                                    <td key={columnIndex}
                                                        id={`sticky_col${columnIndex}`}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.priceType}
                                                    </td>
                                                }

                                                {cellValue.id === 2 &&
                                                    <td key={columnIndex}
                                                        style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.currency}
                                                    </td>
                                                }

                                                {cellValue.id === 3 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        {cellValue?.servicePrice}
                                                    </td>
                                                }

                                                {cellValue.id === 4 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        {cellValue?.minimumAmount}
                                                    </td>
                                                }

                                                {cellValue.id === 5 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        className='text-center'
                                                    >
                                                        {cellValue?.minimumAmountCoverage}
                                                    </td>
                                                }

                                                {cellValue.id === 6 &&
                                                    <td key={columnIndex}
                                                        className='text-center text-warning'
                                                        onClick={() => renderVarbilesList(row?.id, row?.data)}
                                                    >
                                                        <span className='text-warning'>{cellValue?.variablePrices} Variables</span>
                                                    </td>
                                                }

                                                {cellValue.id === null &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        className='text-center'
                                                    >

                                                    </td>
                                                }


                                                {cellValue.id === 7 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <input type='text' placeholder='' value={''} />
                                                    </td>
                                                }

                                                {cellValue.id === 8 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <select>
                                                            <option value="" className='w-100'>Select</option>
                                                            <option value="Option 1">option A</option>
                                                            <option value="Option 2">option B</option>
                                                            <option value="Option 2">option C</option>
                                                        </select>
                                                    </td>
                                                }

                                                {cellValue.id === 9 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='number' placeholder='' value={''} />
                                                    </td>
                                                }

                                                {cellValue.id === 10 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='file' placeholder='' value={''} />
                                                    </td>
                                                }

                                            </>
                                        )
                                    })}

                                    <td className='text-center'
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                        <div className="dropdown" style={{ positition: 'relative' }}>
                                            <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                style={{ zIndex: '0' }}
                                            >
                                                <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                                                style={{ zIndex: '4' }}
                                            >
                                                <div className='py-1'
                                                    onClick={() => getEditData(row?.id)}
                                                >
                                                    <FaRegEdit className='text-warning' style={{ fontSize: '18px' }} />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Edit Row</span>
                                                </div>
                                                <div className='py-1'
                                                    onClick={() => { deleteSelectedRows(row?.id) }}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }}
                                                    />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Delete Row</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                    </td>
                                </tr>
                                {paginatedRows.length - 1 === rowIndex &&
                                    <tr className='sticky_row'>
                                        <td>
                                            <div className="form-check ps-4 text-center">
                                            </div>
                                        </td>

                                        {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                            return (
                                                <>
                                                    {cellValue.id === 1 &&
                                                        <td key={columnIndex}
                                                            id={`sticky_col${columnIndex}`}
                                                        >
                                                            <select
                                                                onChange={(e) => setPriceUnit(e.target.value)}
                                                                value={priceUnit}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                {store?.allUnitTypes && store?.allUnitTypes.map((v) => {
                                                                    return (
                                                                        <option key={v?.id} value={v?.name}>{v?.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 2 &&
                                                        <td key={columnIndex}
                                                        >
                                                            <select
                                                                onChange={(e) => setCurrency(e.target.value)}
                                                                value={currency}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                {store?.allCurrencies && store?.allCurrencies.map((v) => {
                                                                    return (
                                                                        <option key={v?.id} value={v?.v?.currencyName}>{v?.currencyName}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 3 &&
                                                        <td key={columnIndex}>
                                                            <input type='text' placeholder=''
                                                                onChange={(e) => setPrice(e.target.value)}
                                                                value={price}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 4 &&
                                                        <td key={columnIndex}>
                                                            <input type='text' placeholder=''
                                                                value={minAmount}
                                                                onChange={(e) => setMinAmount(e.target.value)}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 5 &&
                                                        <td key={columnIndex}
                                                            className='text-center'
                                                        >
                                                            <input type='text' placeholder=''
                                                                value={coverageAmount}
                                                                onChange={(e) => setCoverageAmount(e.target.value)}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 6 &&
                                                        <td key={columnIndex}
                                                            className='text-center'
                                                        >
                                                            <select
                                                                disabled
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="Option 1">3-Varables</option>
                                                                <option value="Option 2">4-Varables</option>
                                                                <option value="Option 2">5-Varables</option>
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === null &&
                                                        <td key={columnIndex}
                                                            className='text-center'
                                                        >

                                                        </td>
                                                    }

                                                    {cellValue.id === 7 &&
                                                        <td key={columnIndex}

                                                        >
                                                            <input type='text' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                    {cellValue.id === 8 &&
                                                        <td key={columnIndex}>
                                                            <select>
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="Option 1">option A</option>
                                                                <option value="Option 2">option B</option>
                                                                <option value="Option 2">option C</option>
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 9 &&
                                                        <td key={columnIndex}
                                                        >
                                                            <input type='number' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                    {cellValue.id === 10 &&
                                                        <td key={columnIndex}
                                                        >
                                                            <input type='file' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                </>
                                            )
                                        })}

                                        <td className='text-center'
                                            style={{ position: 'relative' }}
                                        >
                                            {store?.miniLoading &&
                                                <BntLoader />
                                            }
                                            <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                                style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                                onClick={postData}
                                            >Add</button>
                                        </td>
                                        <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                        >
                                        </td>
                                    </tr>
                                }
                            </>
                        ))}

                        {paginatedRows.length == 0 &&
                            <tr id='table_form_row'>
                                <td>
                                </td>
                                {defaultColumns?.length > 0 && defaultColumns?.map((cellValue, columnIndex) => {
                                    return (
                                        <>
                                            {cellValue.id === 1 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                >
                                                    <select
                                                        onChange={(e) => setPriceUnit(e.target.value)}
                                                        value={priceUnit}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        {store?.allUnitTypes && store?.allUnitTypes.map((v) => {
                                                            return (
                                                                <option key={v?.id} value={v?.name}>{v?.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 2 &&
                                                <td key={columnIndex}
                                                >
                                                    <select
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                        value={currency}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        {store?.allCurrencies && store?.allCurrencies.map((v) => {
                                                            return (
                                                                <option key={v?.id} value={v?.v?.currencyName}>{v?.currencyName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 3 &&
                                                <td key={columnIndex}>
                                                    <input type='text' placeholder=''
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        value={price}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 4 &&
                                                <td key={columnIndex}>
                                                    <input type='text' placeholder=''
                                                        value={minAmount}
                                                        onChange={(e) => setMinAmount(e.target.value)}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 5 &&
                                                <td key={columnIndex}
                                                    className='text-center'
                                                >
                                                    <input type='text' placeholder=''
                                                        value={coverageAmount}
                                                        onChange={(e) => setCoverageAmount(e.target.value)}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 6 &&
                                                <td key={columnIndex}
                                                    className='text-center'
                                                >
                                                    <select
                                                        disabled
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="Option 1">3-Varables</option>
                                                        <option value="Option 2">4-Varables</option>
                                                        <option value="Option 2">5-Varables</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === null &&
                                                <td key={columnIndex}
                                                    className='text-center'
                                                >

                                                </td>
                                            }

                                            {cellValue.id === 7 &&
                                                <td key={columnIndex}

                                                >
                                                    <input type='text' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 8 &&
                                                <td key={columnIndex}>
                                                    <select>
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="Option 1">option A</option>
                                                        <option value="Option 2">option B</option>
                                                        <option value="Option 2">option C</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 9 &&
                                                <td key={columnIndex}
                                                >
                                                    <input type='number' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 10 &&
                                                <td key={columnIndex}
                                                >
                                                    <input type='file' placeholder='' value={''} />
                                                </td>
                                            }

                                        </>
                                    )
                                })}
                                <td className='text-center'
                                    style={{ position: 'relative' }}
                                >
                                    {store?.miniLoading &&
                                        <BntLoader />
                                    }
                                    <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                        style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                        onClick={postData}
                                    >Add</button>
                                </td>

                                <td></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className='row mobile_display mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <select
                        className='items_per_page_select'
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option disabled >Items Per page</option>
                        <option value={5} selected>5</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={rows?.length}>All</option>
                    </select>

                    <Stack spacing={2} className=''>
                        <Pagination
                            count={Math.ceil(rows.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                        />
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default PriceListMobile;
