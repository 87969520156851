import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRequest } from '../../request';
import GroupList from './group-list/GroupList';
import GroupAndPkgDetails from './group-details/GroupAndPkgDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups, getAllServices } from '../../store/servicesActions';
import ServiceLoader from '../ServiceLoader';
import { base_url } from '../../base_url';

const GroupsAndPkgs = () => {
  const [uniqueGroup, setUniqueGroup] = useState(null);
  const [services, setServices] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(state => state?.servicesReducer);

  const reFetchGroups = async () => {
    if (id !== null) {
      dispatch(getAllGroups())
    }
  }

  const fetchUniqueGroup = async (gid) => {
    if (gid !== null) {
      const res = await getRequest(`${base_url}/Package/GetGroupById?Id=${gid}`);
      const dataa = res?.result?.group;
      if (dataa) {
        setUniqueGroup(dataa);
      }
    }
  }

  const fetchUniquePackage = async (gid) => {
    if (gid !== null) {
      const res = await getRequest(`${base_url}/Package/GetPackageById?Id=${gid}`);
      const dataa = res;
      if (dataa) {
        setUniqueGroup(dataa);
      }
    }
  }

  useEffect(() => {
    if (id) {
      reFetchGroups();
      dispatch(getAllServices())
    }
  }, [id])

  return (
    <>
      {store?.isLoading && store?.allGroups ?
        <ServiceLoader />
        :
        <>
          {
            uniqueGroup ?
              <GroupAndPkgDetails reFetchGroups={reFetchGroups} data={uniqueGroup} fetchUniqueGroup={fetchUniqueGroup} fetchUniquePackage={fetchUniquePackage} />
              :
              <GroupList fetchUniqueGroup={fetchUniqueGroup} />
          }
        </>
      }
    </>
  )
}

export default GroupsAndPkgs;
