import React, { useState } from "react";
import SideNavone from "../../../SideNavBar";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scheduler/css/calendar.css";
import { useHistory } from "react-router-dom";
import { updateNumberOfProviders } from "../../../Store/CalendarSettings/slice";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CalendarSettings = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const numberOfProviders = useSelector((state)=>state.calendarSettingReducer.numberOfProviders);
    const [selectedOption, setSelectedOption] = useState(numberOfProviders);
    const options = [1,2,3,4,5,6,7,8,9,10];
    // const headerStyle = {
    //     width: "50%",
    //     color: "#4A4A4A",
    //     textAlign: "left",
    //     fontFamily: "Poppins",
    //     fontSize: "15px",
    //     fontStyle: "normal",
    //     fontWeight: 500,
    // };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    const saveChanges = () => {
        dispatch(updateNumberOfProviders(selectedOption));
        history.push("/calendar");
    }
    return <SideNavone>
        <div>
            <div>
                <div className="col-md-12 padding_fixed_left_right mt-4">
                    <div className="d-flex me-2 mob_flex">
                        <div className="pt-2" onClick={() => history.goBack()} role="button">
                            <i id="icon_left_color" className="fas fa-chevron-left"></i>
                        </div>
                        <div className="w-100 mob_table">
                            <div className="col-md-12 row bg-white px-3 pt-2 border_rrad_solid" style={{
                                height: "80vh"
                            }}>
                                <div className="col-md-4">Show Number Of Providers</div>
                                <div className="col-md-6">
                                    <div
                                        className="dropdown-container d-none d-lg-block"
                                        style={{
                                            position: "relative",
                                            display: "inline-block",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        <div
                                            className="dropdown-header"
                                            style={{
                                                borderRadius: "2px",
                                                border: "0.6px solid #D7D7D7",
                                                background: "#FFF",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "60px",
                                                padding: "0",
                                                textAlign: "center",
                                                cursor: "pointer", // Add cursor pointer
                                            }}
                                            onClick={toggleDropdown}
                                        >
                                            <span
                                                style={{
                                                    color: "#585858",
                                                    fontFamily: "Inter",
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    marginLeft: "10px",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {String(selectedOption)}
                                            </span>
                                            <div
                                                className={`dropdown-icon px-2 ${isOpen ? "open" : ""}`}
                                                style={{
                                                    borderRadius: "0px 2px 2px 0px",
                                                    background: "#EBEBEB",
                                                    height: "24px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    height="6"
                                                    viewBox="0 0 10 6"
                                                    fill="none"
                                                    style={{
                                                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
                                                    }}
                                                >
                                                    <path
                                                        d="M1 1L5.1569 5L9 1"
                                                        stroke="#707070"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        {isOpen && (
                                            <ul
                                                className="dropdown-options"
                                                style={{
                                                    listStyle: "none",
                                                    padding: "0",
                                                    margin: "0",
                                                    position: "absolute",
                                                    minWidth: "60px",
                                                    top: "100%",
                                                    left: "0",
                                                    zIndex: "1",
                                                    border: "0.6px solid #D7D7D7",
                                                    // borderRadius: "8px",
                                                }}
                                            >
                                                {options.map((val, key) => (
                                                    <li
                                                        key={key}
                                                        onClick={() => handleOptionClick(val)}
                                                        style={{
                                                            background: val === selectedOption ? "#ffd705" : "#FFF",
                                                            padding: "8px 10px",
                                                            borderBottom: "1px solid #D7D7D7",
                                                            cursor: "pointer",
                                                            userSelect: "none",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {val}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 d-flex justify-content-center pt-2">
                <button className="btn btn-primary col-md-4" style={{
                    backgroundColor: "#ffd705",
                    color: "#530",
                }} onClick={saveChanges}>Save Changes</button>
                </div>
            </div>
        </div>
    </SideNavone>;
}

export default CalendarSettings;