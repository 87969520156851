import React, { useState, useCallback, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { teamMange } from "./helper";
import TableHeader from "../TableHeader";
import TableRows from "./TableRow";
import DialogBox from "../DialogBox";
import { teamList } from "../../../utils/constant";

const TableData = (props) => {
  const { data, dialog, deleteTeam } = props;
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("CreatedAt");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [data]);
  //Use Effect To set RowData on searching
  useEffect(() => {
    setPage(0);
  }, [data]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [drawerToggle, setDrawerToggle] = useState(false);
  const toggleDrawerHandler = useCallback(() => {
    setDrawerToggle(!drawerToggle);
  }, [drawerToggle]);
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const newSelectedCheckboxes = data.map((item) => item.id);
      setSelectedCheckboxes(newSelectedCheckboxes);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleRowCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelectedCheckboxes) => [
        ...prevSelectedCheckboxes,
        id,
      ]);
    } else {
      setSelectedCheckboxes((prevSelectedCheckboxes) =>
        prevSelectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      );
    }
  };
  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <>
        <DialogBox
          {...dialog}
          header={teamList.DELETE_TEAM}
          message={teamList.WANT_TO_DELETE_TEAM}
        />
      </>
      <TableContainer className="rbc-time-content" sx={{ maxHeight: 500 }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHeader
            column={teamMange}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            selectedCheckboxes={selectedCheckboxes}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            rowCount={data.length}
          />
          <TableBody>
            <TableRows
              page={page}
              rowsPerPage={rowsPerPage}
              rowData={rowData}
              deleteTeam={deleteTeam}
              toggleDrawerHandler={toggleDrawerHandler}
              selectedCheckboxes={selectedCheckboxes}
              handleRowCheckboxChange={handleRowCheckboxChange}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default TableData;
