import React, { useEffect } from "react";
import TextQuestion from "../InputFields/TextQuestion";
import DropDown from "../InputFields/DropDown";
import CheckBox from "../InputFields/CheckBox";
import VideoQuestion from "../InputFields/VideoQuestion";
import AudioQuestion from "../InputFields/AudioQuestion";
import ImageQuestion from "../InputFields/ImageQuestion";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import { GrDocumentUpload } from "react-icons/gr";
import { IoIosMusicalNotes } from "react-icons/io";
import SignatureQuestion from "../InputFields/SignatureQuestion";

const QuestionTypes = ({
  typeText,
  inputType,
  options,
  onInputChange,
  id,
  formId,
  section,
  handleUploadMedia,
  uploadedData,
}) => {
  useEffect(() => {
    // options?.map((item) => {
    // });
  }, [options]);

  return (
    <>
      {inputType === "LongTextQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}
      {inputType === "SmallTextQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}
      {inputType === "EmailQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}
      {inputType === "NumberQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}

      {inputType === "DropdownQuestion" && (
        <div>
          <DropDown
            text={typeText}
            options={options}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
          />
        </div>
      )}

      {inputType === "MCQQuestion" && (
        <div>
          <CheckBox
            text={typeText}
            options={options}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
          />
        </div>
      )}

      {inputType === "DateQuestion" && (
        <div>
          <p>{typeText}</p>
          <input type="date" />
        </div>
      )}
      {inputType === "ImageQuestion" && (
        <div>
          <ImageQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
            uploadType="image/*"
            icon={<BiImageAdd color="#6c6c6c" size={"35px"} />}
            title="Image"
          />
        </div>
      )}
      {inputType === "DocumentQuestion" && (
        <div>
          <VideoQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
            uploadType="file"
            icon={<GrDocumentUpload color="#6c6c6c" size={"35px"} />}
            title="Document"
            type="uploadDocument"
          />
        </div>
      )}

      {inputType === "SignatureQuestion" && (
        <SignatureQuestion
          text={typeText}
          onInputChange={onInputChange}
          id={id}
          formId={formId}
          inputType={inputType}
          section={section}
          handleUploadMedia={handleUploadMedia}
          uploadedData={uploadedData}
          uploadType="file"
          icon={<AiOutlineVideoCameraAdd color="#6c6c6c" size={"35px"} />}
          title="Signature"
          type="uploadSignature"
        />
      )}

      {inputType === "VideoQuestion" && (
        <div>
          <VideoQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
            uploadType="video/*"
            icon={<AiOutlineVideoCameraAdd color="#6c6c6c" size={"35px"} />}
            title="Video"
            type="uploadVideo"
          />
        </div>
      )}

      {inputType === "AudioQuestion" && (
        <div>
          <VideoQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
            handleUploadMedia={handleUploadMedia}
            uploadedData={uploadedData}
            uploadType="audio/*"
            icon={<IoIosMusicalNotes color="#6c6c6c" size={"35px"} />}
            title="Audio"
            type="uploadAudio"
          />
        </div>
      )}

      {inputType === "LabelQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}

      {inputType === "StatementQuestion" && (
        <div>
          <TextQuestion
            text={typeText}
            onInputChange={onInputChange}
            id={id}
            formId={formId}
            inputType={inputType}
            section={section}
          />
        </div>
      )}
    </>
  );
};

export default QuestionTypes;
