import React from 'react'

const ServiceLoader = () => {
    return (
        <div className='vh-100 w-100 d-flex justify-content-center align-items-center'>
            <div className="spinner-border text-warning" role="status" >
                {/* <span className="sr-only">Loading...</span> */}
            </div>
        </div>
    )
}

export default ServiceLoader

export const MiniLoader = () => {
    return (
        <div className="spinner-border text-dark" role="status"
            style={{ height: '15px', width: '15px' }}
        >
            {/* <span className="sr-only">Loading...</span> */}
        </div>
    )
}