import React, { useCallback, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addsecondaryemail,

} from "../../../Store/Auth/actions";
import InputField from "../../../Shared/InputField";
import Headerauth from "../Headerauth/Headerauth";
import "../Login/Login.scss";
import { ClipLoader } from "react-spinners";
import InputTextField from "../../BusinessProfile/TextField";
import SideNavone from "../../../SideNavBar";
import Header from "../../BusinessProfile/Header";
import { Button } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const flag = true;

  // registered user id --------------------------------
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );

  /////for demo
  const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };
  const handleNumberChange = (event) => {
    const valuew = event.target.value;
    setEmail(valuew);
    setValid(validateEmail(valuew));
  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);
  const handleSubmit = async() => {
    await handleReCaptchaVerify();
  if(token){
    // Check if the token is available
    dispatch(
      addsecondaryemail({
        id: registersuccessId,
        email: email,
        modifiedBy: registersuccessId,
        // history: history,
        raptchaToken: token?token:"string",

        setIsLoading,
      })
    );
  }
    
    // history.push(`/password/${email}`);
  };
  return (
    <>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-md-4">
          <div>
            <div>
              <p className=" ps-3 sub_text_color_account_alltext_show_actual">
                Add Secondary Email
              </p>
            </div>
            <div className="p-2 w-100">
              <InputTextField
                id="secondary-email"
                label="Secondary Email"
                name="secondaryemail"
                placeholder="abc@xyz.com"
                //size={20}
                variant="filled"
                value={email}
                onChange={handleNumberChange}
                autoFocused
                sx={{
                  // maxWidth: "300px",
                  backgroundColor: "#fff",
                }}
              />
            </div>
            {isValid ? (
              <span className="text_valid pt-3">✔️ Email is Valid</span>
            ) : (
              email !== "" && <span className="text_invalid">❌ Email is invalid </span>
            )}
          </div>
          <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
            <div className="p-2">
              <Button
                sx={{
                  // width: "150px",
                  backgroundColor: "#538dff",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                onClick={handleSubmit}
                variant="contained"
                disabled={!isValid}
              >
                {isLoading ? (
                  <ClipLoader color="#fffff" size={30} />
                ) : (
                  "Save and Continue"
                )}{" "}
              </Button>
            </div>
          </div>
        </div>
      </SideNavone>
    </>
  
  );
}
