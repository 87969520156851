import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const BusinessDetail=({handleEditName,handleType,handleIndustry,handleWebsite})=> {
  const history=useHistory()
    const businesProfile = useSelector((state) => state.Business.businesProfile);
  const pushpage = () => {
    history.push("/edit-business-name");
  };
  const pushType = () => {
    history.push("/edit-business-type");
  };
  const pushWebsite = () => {
    history.push("/edit-business-website");
  };
  const pushIndustry= () => {
    history.push("/edit-industry-type");
  };
  return (
    <>
    <div className="pb-1">
    <div className="d-flex pt-3 pb-2">
      <img src="/images/person (1).svg" alt="" />{" "}
      <span className=" sub_text_color_account_alltext1 ps-1">Name</span>
    </div>
    <div className="card" onClick={pushpage}
>
      <div className="row me-0">
        <div className="col-10 ps-2 ">
          <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
            Business Name
          </p>
          {businesProfile?.name?(
          <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
            {businesProfile?.name}
          </p>
          ):(<p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
           {"No getting From Backend"}
          </p>)}
        </div>
        <div className="col-2 text-end m-auto">
        <img src="/images/Path 40226.svg" alt="" />{" "}
        </div>
      </div>
    </div>
  </div>  


  <div className="pb-1">
    <div className="d-flex pt-3 pb-2">
      <img src="/images/Mask Group 788.svg" alt="" />{" "}
      <span className=" sub_text_color_account_alltext1 ps-1">Organization Type</span>
    </div>
    <div className="card" onClick={pushType}
>
      <div className="row me-0">
        <div className="col-10 ps-2 ">
          <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
            Organization Type
          </p>
         
          {businesProfile?.businessType?.name?(
          <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
            {businesProfile?.businessType?.name}
          </p>
          ):(<p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
           {"No getting From Backend"}
          </p>)}
        </div>
        <div className="col-2 text-end m-auto">
        <img src="/images/Path 40226.svg" alt="" />{" "}
        </div>
      </div>
    </div>
  </div>  

    <div className="pb-1">
    <div className="d-flex pt-3 pb-2">
      <img src="/images/building (1) 1.svg" alt="" />{" "}
      <span className=" sub_text_color_account_alltext1 ps-1">Organization Type</span>
    </div>
    <div className="card" onClick={pushIndustry}
>
      <div className="row me-0">
        <div className="col-10 ps-2 ">
          <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
            Industry Type
          </p>
           {businesProfile?.industry?.name?(
          <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
            {businesProfile?.industry?.name}
          </p>
          ):(<p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
           {"No getting From Backend"}
          </p>)}
        </div>
        <div className="col-2 text-end m-auto">
        <img src="/images/Path 40226.svg" alt="" />{" "}
        </div>
      </div>
    </div>
  </div>  


    <div className="pb-1">
    <div className="d-flex pt-3 pb-2">
      <img src="/images/Clip path group.svg" alt="" />{" "}
      <span className=" sub_text_color_account_alltext1 ps-1">Website</span>
    </div>
    <div className="card" onClick={pushWebsite}
>
      <div className="row me-0">
        <div className="col-10 ps-2 ">
          <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
            Website
          </p>
           {businesProfile?.websiteUrl?(
          <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual ">
            {businesProfile?.websiteUrl}
          </p>
          ):(<p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
           {"No getting From Backend"}
          </p>)}
        </div>
        <div className="col-2 text-end m-auto">
        <img src="/images/Path 40226.svg" alt="" />{" "}
        </div>
      </div>
    </div>
  </div>  
  </>
  
  )
}

export default BusinessDetail