import { useEffect, useState } from "react";
import instance from "../utils/utils";
import { customSnackBar } from "utils";

const useFetch = (endpoint) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // State for storing error information

  const fetchData = async (controller) => {
    try {
      const signal = controller.signal;
      setIsLoading(true);
      const res = await instance.get(endpoint, { signal });
      console.log(res, "resssssss");
      const { code, result } = res.data;
      if (code === 0) {
        setData(result);
      } else {
        throw new Error("Failed to fetch data"); // Throw an error if response code is not 0
      }
    } catch (error) {




customSnackBar("Something went wrong,Please Try Again")

      console.log(error,"EORIOIEOIO")
      setError(error); // Set the error state if an error occurs during fetching
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);
    return () => controller.abort();
  }, [endpoint]);

  return { data, isLoading, error }; // Return the error state along with data and loading state
};

export default useFetch;
