// General Forms
export const GET_GENERAL_FORM_SUCCESS = "GET_GENERAL_FORM_SUCCESS";
export const GENERAL_FORM_SUBMITTED = "GENERAL_FORM_SUBMITTED";
export const GENERAL_FORM_UPDATED_SUCCESSFULLY =
  "GENERAL_FORM_UPDATED_SUCCESSFULLY";
export const APPLY_DEFAULT_VALUES_SUCCESSFULLY =
  "APPLY_DEFAULT_VALUES_SUCCESSFULLY";

// Steps Types
export const GET_STEPS = "GET_STEPS";

// Dynamic Forms Types
export const GET_DYNAMIC_FORM_SUCCESSFULLY = "GET_DYNAMIC_FORM_SUCCESSFULLY";
export const SUBMIT_DYNAMIC_FORM_SUCCESSFULLY =
  "SUBMIT_DYNAMIC_FORM_SUCCESSFULLY";
export const STORE_CONSENT_FORM_ID_SUCCESSFULLY =
  "STORE_CONSENT_FORM_ID_SUCCESSFULLY";

// Questions Types
export const GET_QUESTIONS_SUCCESSFULLY = "GET_QUESTIONS_SUCCESSFULLY";
export const SUBMIT_QUESTIONS_ANSWERS_SUCCESSFULLY =
  "SUBMIT_QUESTIONS_ANSWERS_SUCCESSFULLY";

// Common Types
export const IMAGE_UPLOADED = "IMAGE_UPLOADED";

// Loader Types
export const ACTIVATE_LOADER = "ACTIVATE_LOADER";
export const DEACTIVATE_LOADER = "DEACTIVATE_LOADER";
