import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import TimeBlock from "../components/AddTimeBlock.jsx";
import SideNavone from "../../../SideNavBar/index.js";

export default function BookingDetails() {
  return (
    <div>
      <SideNavone>
        <TimeBlock />
      </SideNavone>
    </div>
  );
}
