import React from "react";
import "./skeleton.css";
import CalendarHeader from "./CalenderHeader";

const CalendarSkeleton = () => {
    return <div className="h-100">
    {/* <div className="header skeleton"></div> */}
    <CalendarHeader />
    <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    {/* <div className="week-days">
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
        <div className="day skeleton"></div>
    </div>
    <div className="timeslots">
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
        <div className="time skeleton"></div>
    </div> */}
</div>;
}

export default CalendarSkeleton;