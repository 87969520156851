import React, { useEffect } from "react";
import InputTextField from "../../TextField";
import DescriptionField from "../../TextField/descriptionField";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ClipLoader } from "react-spinners";

const BusinessAddress = (props) => {
  const {
    address,
    values,
    errors,
    touched,
    handleChange,
    action,
    editData,
    setFieldValue,
    handleSubmit,
    loading,
    isMobile
  } = props;
const history=useHistory()
  return (
    <div>
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Address</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="addressName"
          label="addressName"
          name="addressName"
          value={values.addressName}
          onChange={handleChange}
          placeholder="Enter Address"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.addressName && (
        <span className="text-danger font_size_password">
          {errors.addressName}
        </span>
      )}

      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Address Name</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="line1"
          label="line1"
          name="line1"
          value={values.line1}
          onChange={handleChange}
          placeholder="Enter Home Address"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.line1 && (
        <span className="text-danger font_size_password">
          {errors.line1}
        </span>
      )}

<div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Flat & Building</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="flat"
          label="flat"
          name="flat"
          value={values.flat}
          onChange={handleChange}
          placeholder="Enter Flat & Building"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.flat && (
        <span className="text-danger font_size_password">
          {errors.flat}
        </span>
      )}
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Street Address</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="streetAddress"
          label="streetAddress"
          name="streetAddress"
          value={values.streetAddress}
          onChange={handleChange}
          placeholder="Enter Street Address"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.streetAddress && (
        <span className="text-danger font_size_password">
          {errors.streetAddress}
        </span>
      )}
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Post Code</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="code"
          label="code"
          name="code"
          value={values.code}
          onChange={handleChange}
          placeholder="Enter Postal Code"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.code && (
        <span className="text-danger font_size_password">
          {errors.code}
        </span>
      )}
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">City</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="city"
          label="city"
          name="city"
          value={values.city}
          onChange={handleChange}
          placeholder="Enter City"
          style={{
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0",
          }}
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {errors.city && (
        <span className="text-danger font_size_password">
          {errors.city}
        </span>
      )}

       <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Address Note</label>
          <textarea
            className="form-control1 input_style_fix"
            rows={3}
            type="text"
            id="note"
            label="note"
            name="note"
            onChange={handleChange}
            placeholder="Type note Here..."
            // //size={20}
            value={values.note}
            error={touched.note && errors.note}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            // onChange={(event) => setBusinessDes(event.target.value)}
          ></textarea>
        </div> 
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business"> Address Type</label>

          <select
            type="text"
            className="form-control1 changecolor m-0  p-0 "
            id="addressType"
            name="addressType"
            value={values.addressType}
            style={{
              border: "none",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
            onChange={(e) => setFieldValue("addressType",e.target.value)}
          >


            <option disabled style={{color:"#adada"}} value={""}>Select Address Type</option>
              <option value={"Home Address"}>Home Address</option>
              <option value={"Centre Address"}> Center Address </option>
              {/* <option value={"PHD"}>PHD</option> */}
          </select>
        </div>
        {errors.addressType && (
          <span className="text-danger font_size_password">
            {errors.addressType}
          </span>
        )}

<div className={`${!isMobile ? " w-100 d-flex mt-4 flex-wrap justify-content-end " : " w-100 mt-4 "}`}>
       
       <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#c0c0c0",
                  color: "#ffffff",
                },
              }}
              onClick={() => {
                history.push("/business/");
              }}
              variant="contained"
              className=" btn btn-secondary padding_next_secondary_button"
            >
              {"Cancel"}
            </Button>
          </div>
          <div>
            <Button
              sx={{
                width: isMobile?"100%":"150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={() => {
                handleSubmit();
              }}
              disabled={loading}

              variant="contained"
            >
               {loading ? (
                <ClipLoader color="#fffff" size={30} />
              
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </div>
    </div>
  );
};

export default BusinessAddress;
