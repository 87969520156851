/** @format */

import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/scheduler/33994-date-and-clock.json";

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
export default function DataNotFound(props) {
	const { val } = props;
	return (
		<div className=' flex flex-col justify-center items-center  w-full px-12 py-10'>
			<h1 className='text-5xl font-extrabold text-orange-500 text-center pb-4'>Date Error</h1>
			<Lottie options={defaultOptions} height={250} width={350} />
      <div>
			<p className='text-xl font-bold text-center'><span className='text-2xl font-bold text-orange-500 mr-2'>Note:</span>Sorry but you can not book slot in the past dates. Kindly Select date on present or futures dates thank you.</p>
	    </div>
  	</div>
	);
}
