import ServicesList from "./services/InventoryList";
import ServicesAttribute from "../InventoryServices/services/expertServiceAttributes";
import CreateService from "../InventoryServices/services/create";
import CreateNewService from "../InventoryServices/services/newService";
import ServiceDetail from "../InventoryServices/services/inventory";
import CreateChildService from "../InventoryServices/services/create";
import BusinessService from "../InventoryServices/services/BusinessService";
import AssignStaffService from "../InventoryServices/services/StaffService";
import ServicesColumnList from "./services/InventoryList/components/ColumnList";

export const servicesRoutes = [
  {
    path: "/all-services",
    component: ServicesList,
    ispublic: false,
    exact: true,
  },
  // {
  //   path: "/services-attribute",
  //   component: ServicesAttribute,
  //   ispublic: false,
  //   exact: true,
  // },
  {
    path: "/all-services/columns-list",
    component: ServicesColumnList,
    ispublic: false,
    exact: true,
  },
  {
    path: "/create-service",
    component: CreateService,
    ispublic: false,
    exact: true,
  },
  {
    path: "/create-new-service",
    component: CreateNewService,
    ispublic: false,
    exact: true,
  },
  {
    path: "/service-detail/:id",
    component: ServiceDetail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-business-service/:id",
    component: BusinessService,
    ispublic: false,
    exact: true,
  },
  {
    path: "/assign-staff-service/:id",
    component: AssignStaffService,
    ispublic: false,
    exact: true,
  },
  {
    path: "/assign-staff-dynamic-service/:id",
    component: AssignStaffService,
    ispublic: false,
    exact: true,
  },
  {
    path: "/create-child-service/:id",
    component: CreateChildService,
    ispublic: false,
    exact: true,
  },
];
