import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import EventsDetailPage from "../bookingDetails/EventDetails.jsx";
import SideNavone from "../../../SideNavBar/index.js";

export default function BookingDetails() {
  return (
    <div>
      <SideNavone>
        <EventsDetailPage />
      </SideNavone>
    </div>
  );
}
