import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import instance from "../../../../utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadis: "10px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ businessId, open, handleClose }) {
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [weekDay, setWeekDays] = React.useState([
    {
      day: "Monday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Tuseday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Wednesday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Thursday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Friday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Saturday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Sunday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
  ]);
  const formatTime = (time) => {
    if (!time) return ""; // Return empty string if no time selected

    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  const parseTime = (formattedTime) => {
    if (!formattedTime) return null; // Return null if the input is empty or invalid

    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; // Regular expression for valid time format (HH:mm)

    if (!timeRegex.test(formattedTime)) return null; // Return null if the time format is invalid

    const [hours, minutes] = formattedTime.split(":").map(Number);

    const dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    dateObj.setSeconds(0); // Optionally, set seconds to 0 if you want them to be 0 in the resulting Date object

    return dateObj;
  };
  const handleChangeAvailability = (index, checked) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].isAvailable = checked;
    setWeekDays(updatedWeekDays);
  };

  const handleChangeStartTime = (index, newValue) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].businessStartTime = formatTime(newValue);
    setWeekDays(updatedWeekDays);
  };

  const handleChangeEndTime = (index, newValue) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].businessEndTime = formatTime(newValue);
    setWeekDays(updatedWeekDays);
  };

  const AddSchedule = () => {
    const data = weekDay;
    instance
      .post(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/business/`,
        { data: weekDay }
      )
      .then((res) => {
        console.warning(res);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  React.useEffect(() => {}, [weekDay]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="py-4"
          >
            Set Business Schedule
          </Typography>
          {weekDay.map((val, index) => (
            <div
              className="w-100 d-flex justify-content-between align-items-center py-3 px-2"
              key={index}
            >
              <div className="checkbox">
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "25px",
                  }}
                >
                  <input
                    type="checkbox"
                    value={weekDay[index].isAvailable}
                    checked={weekDay[index].isAvailable}
                    onChange={(e) =>
                      handleChangeAvailability(index, e.target.checked)
                    }
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  {val.day}
                </label>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Opening time"
                  value={parseTime(weekDay[index].businessStartTime)}
                  onChange={(newValue) =>
                    handleChangeStartTime(index, newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Closing time"
                  value={parseTime(weekDay[index].businessEndTime)}
                  onChange={(newValue) => handleChangeEndTime(index, newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          ))}
          <div className="w-100 d-flex justify-content-end align-items-center py-3 px-2">
            <div className="w-25 d-flex justify-content-betweens align-items-center">
              <Button
                variant="text"
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={AddSchedule}>
                Add
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
