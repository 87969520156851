import React, { useState } from "react";
import Actions from "./Actions";
import { DataNotFound } from "../../../utils";
import TableCell from "@mui/material/TableCell";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import axios from "axios";
import ActionMenu from "./ActionMenu";
import instance from "../../../utils/utils";
const TableRows = (props) => {
  const {
    rowData,
    toggleDrawerHandler,
    selectedCheckboxes,
    handleRowCheckboxChange,
    rowsPerPage,
    deleteDepartment,
    page,
  } = props;
  const history = useHistory();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const tableData = rowData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [data, setData] = useState(rowData);
  const [switchStates, setSwitchStates] = useState([]);

  const handleClick = (row) => {
    localStorage.setItem("departmentRecord", JSON.stringify(row));
    const queryParams = new URLSearchParams({
      id: row.id,
      // Add other relevant properties from the selectedRow
    });
    history.push(`/team`);
  };
  
  React.useEffect(() => {
    setSwitchStates(rowData.map((row) => row.isActive || false));
  }, [rowData]);
  const handleSwitchChange = (event, index, row) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = event.target.checked;
    setSwitchStates(updatedSwitchStates);

    const isActive = event.target.checked;

    instance
      .patch(
        `/business_svc/pb/department/${row.id}/`,
        { isActive }
      )
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        // Handle the error if needed
      });

    // Here, we're just updating the local state directly
    const updatedRow = { ...row, isActive: event.target.checked };
    setData((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  return (
    <>
      {rowData.length > 0 ? (
        rowData.map((row, index) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                padding="checkbox"
              >
                 <ActionMenu
                handleClick={handleClick}
                row={row}
                index={index}
                toggleDrawerHandler={toggleDrawerHandler}
                />
                {/* <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  className="form-check-input2 checkbox_btn2"
                  checked={selectedCheckboxes.includes(row.name)}
                  onChange={(event) => handleRowCheckboxChange(event, row.name)}
                /> */}
              </TableCell>{" "}
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <Link
                  underline="none"
                  // onClick={() => {
                  //   handleClick(row);
                  // }}
                >
                  <span className="link-hover bolds">{row.id}</span>
                </Link>{" "}
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                {" "}
                <Link
                  underline="none"
                  // onClick={() => {
                  //   handleClick(row);
                  // }}
                >
                  <span className="link-hover text-capitalize bolds">{row.name}</span>
                </Link>{" "}
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <span className="bolds">{row.numTeams}</span>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="d-flex justify-content-center"
              >
                <div className="d-flex justify-content-center">
                  <Switch
                    {...label}
                    onChange={(event) => handleSwitchChange(event, index, row)}
                    checked={switchStates[index]}
                    color="primary"
                    className="d-flex justify-content-center"
                  />
                </div>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
                id={"action-" + index}
              >
                <Actions
                  row={row}
                  index={index}
                  deleteDepartment={deleteDepartment}
                  toggleDrawerHandler={toggleDrawerHandler}
                />
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <DataNotFound />{" "}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRows;
