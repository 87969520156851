/** @format */

import moment from "moment";
import Fab from "@mui/material/Fab";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { momentLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, createContext, useState, useRef } from "react";

import CalendarApp from "./calenderWidget";
import { openmodel } from "../store/modelSlice";
import { getCalendarData } from "../store/calendarSlice";
import { handleOpenAlert } from "../store/alertSlice";
import {
  SelectedEvent,
  resetAllData,
  selectedSlotData,
  singleBookingAppoitmentDetails,
  singleBookingDetails,
  slotsFlag,
} from "../store/bookingSlice";
import useCheckPermission, {
  calculateDurationInMinutes,
  customSnackBar,
} from "../../../utils";
import CalendarSkeleton from "./skeleton";
import instance from "../../../utils/utils";
import CryptoJS from "crypto-js";

const localizer = momentLocalizer(moment);
export const LabelContext = createContext();

export default function CalendarAppParent() {
  /* -------------------------------------------------------------------------- */
  /*                                  Variables                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarWrapperRef = useRef();
  const action = {
    action: "add-schdule",
  };
  // const [open, setOpen] = useState(false);
  const [, setModelTypeg] = useState("");
  const [permission, setPermissions] = useState(null);
  const [permissionView, setViewPermissions] = useState(null);
  const numberOfProviders = useSelector(
    (state) => state.calendarSettingReducer.numberOfProviders
  );
  const user = useSelector((state) => state.Auth.userloginsuccessyasir);

  const [, setNewslott] = useState({
    resourceId: "",
    start: "",
    end: "",
  });
  // const [eventsData, setEventDate] = useState([]);
  // const [currentData, setCurrentDate] = useState();
  // const [clinicId, setClinicId] = useState("9106");
  /* ---------------------------- Static Variables ---------------------------- */
  const views = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    // AGENDA: "agenda",
  };
  const allViews = Object.keys(views).map((k) => views[k]);
  /* ------------------------------- useSelector ------------------------------ */
  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  const {
    startTime,
    endTime,
    resources,
    events: allevent,
    calendarData,
    bgevents: allbgevent,
    isLoading,
  } = useSelector((state) => state.calendarReducer);
  const {
    responseStatus,
    updateStatus,
    deleteStatus,
    successMessage,
    updateMessage,
    deleteMessage,
    isLoadingPost,
    isLoadingUpdsate,
    isLoadingDelete,
  } = useSelector((state) => state.modelReducer);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  console.log(calendarData, "userrrrrrr");
  useCheckPermission("Booking", "canCreateBooking").then((res) => {
    setPermissions(res);
  });
  useCheckPermission("Booking", "canViewBookingDetails").then((res) => {
    setViewPermissions(res);
  });

  useEffect(() => {
    if (permission === false) {
      customSnackBar("You have not a Permssion To Add Booking");
    }
  }, [permission]);
  // let allevent = useSelector((state) => state.calendarReducer.events);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Use Effect ------------------------------- */
  useEffect(() => {
    const intervalId = setInterval(() => {
      var time = moment().format("LT");
      const existing = document.getElementsByClassName("my-div-time")[0];
      if (existing) {
        existing.innerHTML = `${time}`;
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      handleCurrentIndicator();
    }, 220);
  }, []);

  useEffect(() => {
    if (isLoadingPost === false) {
      if (responseStatus === "success") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
      if (responseStatus === "error") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
    }
  }, [isLoadingPost, responseStatus, dispatch, successMessage]);
  useEffect(() => {
    if (isLoadingUpdsate === false) {
      if (updateStatus === "success") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
      if (updateStatus === "error") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
    }
  }, [isLoadingUpdsate, updateStatus, updateMessage, dispatch]);
  useEffect(() => {
    if (isLoadingDelete === false) {
      if (deleteStatus === "success") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
      if (deleteStatus === "error") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
    }
  }, [isLoadingDelete, deleteMessage, deleteStatus, dispatch]);

  useEffect(() => {
    const functionCalling = async () => {
      if (selectedBusiness !== null && selectedBusiness?.id) {
        // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const data = {
        //   id: selectedBusiness?.id,
        //   userTimezone: userTimezone,
        // };
        dispatch(resetAllData());
        console.log(selectedBusiness, "selectedBusinessselectedBusiness");
        let record = {
          bookingDateStart: dateApi,
          bookingDateEnd: dateApi,
          timeZone: "Europe/London",
        };

        if(user?.isPlexaarStaff === true ){
          let providerIdd;
        console.log(user,"selectedBusinessselectedBusiness")

        //   try{
        //     const response = await instance.get(`/staff_svc/pv/staff/getStaffByEmail?email=${user.primaryEmail}`);
        //     if(response.data.code === 0){
        //       providerIdd = response.data.result.staff[0].providerId;
        //     }else {
        //       customSnackBar(response.data.message);
        // console.log(response,"selectedBusinessselectedBusiness")

        //       return;
        //     }
        //   }catch (e) {
        //     customSnackBar(e);
        //     return;
        //   }
          record = {
            ...record,
            providerIds: [user?.plexaarStaffId],
            is_business: false,
          };
        } else {
        console.log(selectedBusiness, "selectedBusinessselectedBusiness");

        record = {
          ...record,
          providerId: [],
          is_business: true,
          businessId: selectedBusiness?.id,
        };
        }
        dispatch(getCalendarData(record));
      }
    };
    functionCalling();
  }, [
    dateApi,
    selectedBusiness,
    dispatch,
    user.defaultAccountId,
    user.isBusinessAccount,
    user.primaryEmail,
  ]);
  // useEffect(() => {}, [calendarData]);
  /* -------------------------------- Function -------------------------------- */
  const handleOpen = () => history.push("/scheduler/create_booking");
  // const handleClose = () => setOpen(false);

  const handleCurrentIndicator = () => {
    var time = moment().format("LT");
    const existingDiv = document?.getElementsByClassName(
      "rbc-current-time-indicator"
    )[0];
    if (existingDiv) {
      const newDiv = document.createElement("div");
      newDiv.className =
        "my-div-time -mt-2 text-xs -ml-20 px-2 border-2 w-fit border-red-500 rounded-full";
      newDiv.textContent = time;
      existingDiv.appendChild(newDiv);
    }
  };

  function handleEvents(events) {
    if (permissionView === false) {
      customSnackBar("You haven't Permssion to view Booking Detail");
    } else {
      if (events.type === "booking") {
        dispatch(singleBookingDetails(events?.bookingId));
        dispatch(singleBookingAppoitmentDetails(events?.appointmentId));
        const toggle = {
          flag: false,
        };
        history.push(
          `/scheduler/booking_details/${selectedBusiness?.id}`,
          toggle
        );
      } else if (
        events.type === "event" ||
        events.type === "break" ||
        events.type === "timeBlock"
      ) {
        console.log(events,"eventseventsevents")
        // const eventType = events.type === "event" ? "event" : "break";
        const eventType =
          events.type === "event"
            ? "event"
            : events.type === "timeBlock"
            ? "timeblock"
            : "break";
// console.log( id: events[eventType + "id"],"eventTypeeventType")
        dispatch(
          SelectedEvent({ id: events[eventType + "Id"], type: eventType })
        );
        history.push(`/scheduler/events_details/${selectedBusiness?.id}`);
      }
    }
  }
  function encryptURL(currentURL) {
    return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP7Y8MP").toString();
  }

  const handleNavLinkClick = (action) => {
    const currentURL = window.location.href;
    const encryptedURL = encryptURL(currentURL);
    const encryptedUser = encryptURL(JSON.stringify(user));

    const redirectObject = {
      encryptedURL: encryptedURL,
      user: encryptedUser,
    };
    if (selectedBusiness === null) {
      const redirectURL = `https://accounts.plexaar.com?redirectInfo=${encodeURIComponent(
        JSON.stringify(redirectObject)
      )}`;
      // Perform redirection
      window.location.href = redirectURL;
    } else {
      const redirectURL = `https://accounts.plexaar.com/add-business-schedular/${
        selectedBusiness?.id
      }?redirectInfo=${encodeURIComponent(JSON.stringify(redirectObject))}`;
      // Perform redirection
      window.location.href = redirectURL;
    }
    // If you want to use history.push() for redirection uncomment the following line
    // history.push(redirectURL);
  };
  function handleSlots(slot) {
    const currentDate = new Date(); // Get the current date
    const datetime = new Date(slot.start);
    const datetime2 = new Date(slot.end);
    const timeFrom = `${datetime.getHours()}:${datetime.getMinutes()}`;
    const timeTo = `${datetime2.getHours()}:${datetime2.getMinutes()}`;
    const bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    const data = {
      resourceId: slot.resourceId,
      timeFrom: timeFrom,
      timeTo: timeTo,
      bookingDate: bookingDate,
      duration: calculateDurationInMinutes(timeFrom, timeTo),
      resourceObject: resources.find(
        (obj) => obj.resourceId === slot.resourceId
      ),
    };

    if (datetime < currentDate) {
      customSnackBar("Time is In past you cannot Select that");
      // dispatch(
      //   openToast({
      //     message: "Time is In past you cannot Select that",
      //     status: "warning",
      //   })
      // );
    } else {
      dispatch(selectedSlotData(data));
      dispatch(slotsFlag(true));
      if (permission || permission === null || permission === undefined) {
        handleOpen();
      } else {
        customSnackBar("You haven't permission to Add Booking");
      }
      setNewslott({
        resourceId: slot.resourceId,
        start: slot.start,
        end: slot.end,
      });
    }
    // setModelTypeg("slot");
    // dispatch(openmodel());
    // dispatch(selectedSlot(slot));
  }

  //Onclick Filter Model
  function handleFilter() {
    setModelTypeg("filter");
    dispatch(openmodel());
  }

  const handlingDragEvent = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot = false,
  }) => {
    const { allDay } = event;
    if (!allDay && droppedOnAllDaySlot) {
      event.allDay = true;
    }

    // setMyEvents((prev) => {
    //   const existing = prev.find((ev) => ev.id === event.id) ?? {}
    //   const filtered = prev.filter((ev) => ev.id !== event.id)
    //   return [...filtered, { ...existing, start, end, allDay }]
    // })
  };

  // const scrollToStart = () => {
  //   if (calendarWrapperRef.current) {
  //     // calendarWrapperRef.current.scrollIntoView({behavior: "smooth"});
  //     const calendarContainer =
  //       calendarWrapperRef.current.children[0].children[1].children[1];
  //     calendarContainer.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   } else {
  //     console.warning(
  //       "calendarWrapperRef is not correctly",
  //       calendarWrapperRef.current
  //     );
  //   }
  // };

  // console.log(calendarData,"calendarDatav")
  return (
    <div className="w-100 h-100 rounded-xl" style={{ paddingTop: "1.2rem" }}>
      {isLoading ? (
        <CalendarSkeleton />
      ) : selectedBusiness == null ? (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <h5 className="m-0">Business Not Selected</h5>
            <Button
              style={{ backgroundColor: "#5599FF" }}
              className="col-4 py-3 my-3"
              variant="contained"
              onClick={() => handleNavLinkClick()}
            >
              Add Business
            </Button>
          </div>
        </>
      ) : calendarData?.length === 0 ? (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <h5 className="m-0">Business or Provider Schedule is not added Or Business Not Active</h5>
            <Button
              style={{ backgroundColor: "#5599FF" }}
              className="col-4 py-3 my-3"
              variant="contained"
              // onClick={() => handleNavLinkClick()}

              onClick={() =>
                history.push(
                  `/add-business-schedular/${selectedBusiness?.id}`,
                  action
                )
              }
            >
              Add Schedule
            </Button>
          </div>
        </>
      ) : (
        <div
          className="w-100 rounded p-lg-0 p-sm-0 p-0"
          style={{ height: "95vh !important" }}
        >
          {permission || permission === null || permission === undefined ? (
            <div
              style={{
                position: "fixed",
                bottom: 50,
                right: 50,
                zIndex: 999999999,
              }}
            >
              <div
                onClick={handleOpen}
                style={{ marginTop: "5px", textDecoration: "none" }}
              >
                <Fab
                  className="buttondesign2 d-flex justify-content-center align-items-center"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    border: "3px solid #ffffff",
                  }}
                  aria-label="add"
                >
                  <img
                    className="img-fluid icon_size_newplus"
                    src="/images/newplus.png"
                    alt="add"
                  />
                </Fab>
              </div>
            </div>
          ) : null}
          {resources?.length === 0 && (
            <div
              style={{
                position: "fixed",
                top: "48%",
                bottom: "48%",
                right: "32%",
                left: "32%",
                zIndex: 999999999,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5 className="text-center">
                Please Add Provider or schedule of Provider first
              </h5>
              <button
                className="btn btn-primary"
                onClick={() => history.push("/staff-list")}
                // onClick={() => history.push("/staff-list",action="calender")}
              >
                Add Provider
              </button>
            </div>
          )}
          <div
            ref={calendarWrapperRef}
            className="col-md-12 overflow_calender height_for_calendar"
          >
            <CalendarApp
              localizer={localizer}
              allevent={allevent}
              allbgevent={allbgevent}
              resources={resources}
              views={allViews}
              startTime={startTime}
              endTime={endTime}
              // isLoading={isLoading}
              calenderEvents={handleEvents}
              handleSlots={handleSlots}
              handleFilter={handleFilter}
              draghandleEvents={handlingDragEvent}
              //  scrollToStart={scrollToStart}
            />
          </div>
        </div>
      )}
    </div>
  );
}
