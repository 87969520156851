/** @format */

import { UnavailableEventsCreation } from "./UnavailableEventsCreation";
import moment from "moment";

export function ExtractSlots(res, date) {
  let val = res?.slotsData;
  let temp = [];
  val?.map((val,parentIndex) => {
    val?.slots?.map((value,index) => {
      const originalTimeFrom = moment(value.from, "HH:mm");
      const newTime = originalTimeFrom.add(15, "minutes");
      const formattedTime = newTime.format("HH:mm");
      temp.push({
        id: `A.${parentIndex}.${index+1}`,
        title: "Available Slots",
        resourceId: val?.providerId,
        start: UnavailableEventsCreation(value?.from, date),
        end: UnavailableEventsCreation(formattedTime, date),
        // end: UnavailableEventsCreation(value.timeTo, date),
        colorEvento: "transparent",
        color: "#000000",
        allDay: false,
        background: false,
        serviceDetails: "Available Slots",
        customerName: "",
        colorCode: "#5599FF",
        timeFrom: value?.from,
        timeTo: value?.endTo,
        resource: val?.providerId,
      });
    });
  });
  return temp;
}
