import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkPathname } from "../utils";
import { useDispatch } from "react-redux";
import {
  searchText,
  searchTextCustomer,
  searchTextEmail,
  searchTextFirstName,
  searchTextLastName,
  searchTextnumber,
} from "../Pages/Dashboard/MainComponent/store/StaffSlice";
import debounce from "lodash/debounce";
import { getFormData } from "../Store/dynamicForm/slice";
import { getStaffFormData } from "../Store/dynamicForm/staffSlice";
import { getServiceFormData } from "../Store/dynamicForm/serviceSlice";
import {
  dispatchSearchActions,
  getPageTitle,
  handleResize,
  pathArray,
  saveToLocalStorage,
} from "../utils/helper";
import PageHeader from "./HeaderSearchBar";
const NavBarHeading = ({
  backtostafflising,
  selectedBusines,
  handleMoreOptionsClick,
  rowData,
  record,
  data,
  setRowData,
  buttonText,
  link,
  OnSearch,
  onClick,
  handleClick,
  permission,
  typeOfBooking
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const businessId = JSON.parse(localStorage.getItem("businessRecord"));
  const [openAction, setOpenAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const [searchValues, setSearchValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
  });
  const isMatchingPath = checkPathname(location.pathname, pathArray);
  const pageTitle = getPageTitle(
    location.pathname,
    businessId,
    selectedBusines,
    typeOfBooking,
  );

  const handleMenuActionClick = (event, data, rowIndex) => {
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setOpenAction(false);
    setAnchorEl(null);
  };
  const handleChangeSearch = useCallback(
    (inputValue) => {
      dispatch(searchText(inputValue));
      localStorage.setItem("filterText", inputValue);
    },
    [dispatch]
  );

  // Debounce the handleChangeSearch function
  const handleDebouncedSearch = useCallback(debounce(handleChangeSearch, 200), [
    handleChangeSearch,
  ]);

  //for manage the search value states
  useEffect(() => {
    const localStorageKeys = [
      "filterText",
      "filterCustomerText",
      "filterCustomerFirstName",
      "filterCustomerLastName",
      "filterCustomerEmail",
      "filterCustomerMobile",
    ];

    const storedValues = localStorageKeys.reduce((acc, key) => {
      acc[key] = localStorage.getItem(key) || "";
      return acc;
    }, {});

    const dispatchActions = {
      "/all-customer": [
        { action: searchText, key: "filterText" },
        { action: searchTextCustomer, key: "filterCustomerText" },
        { action: searchTextFirstName, key: "filterCustomerFirstName" },
        { action: searchTextLastName, key: "filterCustomerLastName" },
        { action: searchTextEmail, key: "filterCustomerEmail" },
        { action: searchTextnumber, key: "filterCustomerMobile" },
      ],
    };

    const path = location.pathname;
    const actionsToDispatch = dispatchActions[path] || [];

    actionsToDispatch.forEach(({ action, key }) => {
      dispatch(action(storedValues[key]));
    });

    const clearLocalStorage = () => {
      localStorageKeys.forEach((key) => localStorage.removeItem(key));
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      clearLocalStorage();
      dispatchActions["/all-customer"].forEach(({ action }) =>
        dispatch(action(""))
      );
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, [dispatch, location.pathname]);

  const providerDatas = JSON.parse(localStorage.getItem("StaffRecord"));

  const handleSearchIconClick = () => {
    setIsSearchVisible((prev) => !prev);
  };

  const handleBusinesSelection = () => {
    if (selectedBusines === null) {
      alert("Plese Select/Add the Business");
    } else {
      history.push(`/add-staff/${selectedBusines?.id}`);
    }
  };
  //for screen responsiveness
  useEffect(() => {
    return handleResize(setIsMobile, setIsTablet);
  }, []);

  const handleDebouncedCustomerSearch = useCallback(
    debounce((inputValue) => {
      // Your search logic here
    }, 200),
    []
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    handleDebouncedCustomerSearch(inputValue);
  };

  const handleSearchButtonClick = () => {
    dispatch(searchTextCustomer(searchValue));
    localStorage.setItem("filterCustomerText", searchValue);
  };

  const handleInputChanges = (fieldType, e) => {
    const inputValue = e.target.value;
    setSearchValues((prevValues) => ({
      ...prevValues,
      [fieldType]: inputValue,
    }));
    // handleDebouncedSearchs(fieldType, inputValue);
  };
  const handleSearchButtonClicks = () => {
    dispatchSearchActions(searchValues, dispatch);
    saveToLocalStorage(searchValues);
  };

  return (
    <>
      {isMatchingPath ? (
        <div
          className={`${isMobile ? "px-0 py-0 " : " px-2 py-1"} col-md-12 ${
            isMobile ? "bg-none" : "bg-white second_nav"
          } `}
        >
          <div className="d-flex justify-content-between">
            <PageHeader
            typeOfBooking={typeOfBooking}
              isMobile={isMobile}
              location={location}
              pageTitle={pageTitle}
              backtostafflising={backtostafflising}
              history={history}
              isSearchVisible={isSearchVisible}
              dispatch={dispatch}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              searchTextFirstName={searchTextFirstName}
              searchTextLastName={searchTextLastName}
              searchTextEmail={searchTextEmail}
              searchTextnumber={searchTextnumber}
              handleSearchIconClick={handleSearchIconClick}
              handleDebouncedSearch={handleDebouncedSearch}
              handleInputChanges={handleInputChanges}
              handleSearchButtonClicks={handleSearchButtonClicks}
              OnSearch={OnSearch}
              data={data}
              setIsSearchVisible={setIsSearchVisible}
              setRowData={setRowData}
              searchText={searchText}
              searchTextCustomer={searchTextCustomer}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              handleInputChange={handleInputChange}
              handleSearchButtonClick={handleSearchButtonClick}
              providerDatas={providerDatas}
              handleMoreOptionsClick={handleMoreOptionsClick}
              permission={permission}
              onClick={onClick}
              record={record}
              link={link}
              buttonText={buttonText}
              getStaffFormData={getStaffFormData}
              handleBusinesSelection={handleBusinesSelection}
              selectedBusines={selectedBusines}
              handleMenuActionClick={handleMenuActionClick}
              openAction={openAction}
              getServiceFormData={getServiceFormData}
              anchorEl={anchorEl}
              handleMenuClose={handleMenuClose}
              getFormData={getFormData}
              handleClick={handleClick}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavBarHeading;
