// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow_main_dashboard {
  overflow-x: auto !important;
  background-color: #F8FAFC;
}

.link-active {
  color: red;
  background-color: #FFD705;
}

.link-active:hover {
  color: white;
  background-color: #FFD705;
}

.link-white {
  color: #FFD705;
}

.link-white:hover {
  color: #FFD705;
}

.background_nav1_header {
  background-color: #548DFF;
}

.side_nav_1_right {
  width: 100%;
}

.margin_left_m3 {
  margin-left: -60px;
}

.col6section {
  color: white;
}

.background_sidenav_two {
  background-color: white;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 6px;
}

.background_sidenav_two_t {
  background-color: white;
  padding-top: 3%;
  padding-bottom: 3%;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/side-menu/dashboard.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,yBAAA;AACJ;;AAGA;EACI,UAAA;EACA,yBAAA;AAAJ;;AAIA;EACI,YAAA;EACA,yBAAA;AADJ;;AAKA;EACI,cAAA;AAFJ;;AAKA;EACI,cAAA;AAFJ;;AAUA;EACI,yBAAA;AAPJ;;AAUA;EACI,WAAA;AAPJ;;AAUA;EACI,kBAAA;AAPJ;;AAUA;EACI,YAAA;AAPJ;;AAgBA;EACI,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAbJ;;AAgBA;EACI,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAbJ","sourcesContent":[".overflow_main_dashboard {\n    overflow-x: auto !important;\n    background-color: #F8FAFC;\n}\n\n\n.link-active {\n    color: red;\n    background-color: #FFD705;\n\n}\n\n.link-active:hover {\n    color: white;\n    background-color: #FFD705;\n\n}\n\n.link-white {\n    color: #FFD705;\n}\n\n.link-white:hover {\n    color: #FFD705;\n\n}\n\n\n\n\n\n.background_nav1_header {\n    background-color: #548DFF;\n}\n\n.side_nav_1_right {\n    width: 100%;\n}\n\n.margin_left_m3 {\n    margin-left: -60px;\n}\n\n.col6section {\n    color: white;\n}\n\n// .background_sidenav_one {\n//     background-color: white;\n//     height: 100vh;\n//     overflow-y: hidden !important;\n// }\n\n.background_sidenav_two {\n    background-color: white;\n    padding-top: 5%;\n    padding-bottom: 5%;\n    border-radius: 6px;\n}\n\n.background_sidenav_two_t {\n    background-color: white;\n    padding-top: 3%;\n    padding-bottom: 3%;\n    border-radius: 6px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
