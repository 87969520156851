import React, { useState, useEffect, createContext, useMemo } from "react";
import "./bookingdetail.css";
import {
  eventsandbreakAxios,
  providertimeavailabilityAxios,
} from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  resetAllData,
  selectedSlotData,
  setEventDuration,
} from "../store/bookingSlice";
import { openToast } from "../store/alertSlice";
import CalendarApp from "../components/calenderWidget";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getCalendarData, getSlotsData } from "../store/calendarSlice";
import SideNavone from "../../../SideNavBar";
import checkPermission, { customSnackBar } from "../../../utils";
import { ClipLoader } from "react-spinners";
import CalendarSkeleton from "../components/skeleton";
import instance from "../../../utils/utils";

const localizer = momentLocalizer(moment);
export const LabelContext = createContext();

const EditEventAndBreak = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [eventData, setEventData] = useState(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  let combinedData = useSelector((state) => state.calendarReducer.combinedData);
  const { selectedEvent: event, eventsDuration: eventDuration } = useSelector(
    (state) => state.bookingReducer
  );
  let allbgevent = useSelector((state) => state.calendarReducer.bgevents);
  let resources = useSelector((state) => state.calendarReducer.resources);
  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  let startTime = useSelector((state) => state.calendarReducer.startTime);
  let endTime = useSelector((state) => state.calendarReducer.endTime);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const [isOpen, setIsOpen] = useState(false);
  const [permission, setPermissions] = useState(null);

  const memoizedDateApi = useMemo(() => dateApi, [dateApi]);
  const memoizedSelectedBusinessId = useMemo(
    () => selectedBusiness.id,
    [selectedBusiness.id]
  );
  const memoizedEventDuration = useMemo(() => eventDuration, [eventDuration]);
  const result = checkPermission("Events", "create").then((res) => {
    setPermissions(res);
  });
  useEffect(() => {
    if (permission === false) {
      customSnackBar("You have not a Permssion To Add Events");
    }
  }, [permission]);

  const views = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    AGENDA: "agenda",
  };
  const allViews = Object.keys(views).map((k) => views[k]);
  const extractedIds = [];
  resources.every((obj) => {
    const currentId = obj.resourceId;
    if (
      extractedIds.length === 0 ||
      currentId > extractedIds[extractedIds?.length - 1]
    ) {
      extractedIds.push(currentId);
      return true; // Continue iteration
    } else {
      return false; // Stop iteration
    }
  });
  function findProviderNameById(resources, id) {
    const foundResource = resources.find(
      (resource) => resource.resourceId === id
    );
    return foundResource ? foundResource.resourceTitle : "Unknown Provider";
  }
  useEffect(() => {
    if(isOpenCalendar){

    const data = {
      page: 1,
      providerId: [eventData?.providerId],
      date: dateApi,
      timeZone: "Europe/London",
      // pageSize: extractedIds.length,
      pageSize:100,
      breakDuration: eventDuration,
      hasBreak: true,
      businessId: selectedBusiness?.id,
      sku: "",
    };
    const record = {
      providerId: extractedIds,
      bookingDateStart: memoizedDateApi,
      is_business: true,
      bookingDateEnd: memoizedDateApi,
      timeZone: "Europe/London",
      businessId: memoizedSelectedBusinessId,
    };

    dispatch(getCalendarData(record));
    dispatch(getSlotsData({ data, setLoading }));
  }
  }, [
    memoizedDateApi,
    memoizedSelectedBusinessId,
    memoizedEventDuration,
    eventData,
    isOpenCalendar

  ]);

  useEffect(() => {
    if (event) {
      setLoader(true)
      instance
        .get(
          `/eventsandbreaks_svc/pb/eventsbyId/?Id=${event.id}&type=${event.type}&timeZone=Europe/London`
        )
        .then((response) => {
          if (event.type === "event") {
            const data = response?.data?.result?.events_data;
            setEventData(data);
            setTitle(data.title);
            setDescription(data.description);
            dispatch(setEventDuration(parseInt(data.bookingDuration)));
      setLoader(false)

          } else {
            const data = response?.data?.result?.breaks_data;
            setEventData(data);
            setTitle(data.title);
            setDescription(data.description);
            dispatch(setEventDuration(parseInt(data.bookingDuration)));
      setLoader(false)

          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [event]);

  function calculateEndTime(startTime, initialEndTime, durationMinutes) {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const totalStartMinutes = startHours * 60 + startMinutes;
    const newTotalEndMinutes = totalStartMinutes + durationMinutes;
    const newEndHours = Math.floor(newTotalEndMinutes / 60);
    const newEndMinutes = newTotalEndMinutes % 60;
    const formattedEndTime = `${String(newEndHours).padStart(2, "0")}:${String(
      newEndMinutes
    ).padStart(2, "0")}`;
    return formattedEndTime;
  }
  const CheckProviderAvailabilty = async (events) => {
    const data = {
      providerId: eventData?.providerId ? eventData?.providerId : "",
      date: eventData?.bookingDate ? eventData?.bookingDate : "",
      breakDuration: eventDuration ? eventDuration : "",
      from: eventData?.timeFrom ?`${ eventData?.timeFrom}:00` : "",
      timeZone: "Europe/London",
      sku:  "",
      hasBreak: true,
      businessId: selectedBusiness?.id ? selectedBusiness?.id : 0,
    };
    await providertimeavailabilityAxios
    .post("/verify", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res?.data?.result?.available === true) {

          // setIsOpenCalendar(true);
          updateEventHandlers(events);
          // CraeteEventsFunctions(selectedSlot)
        } else if (res?.data?.code === 1) {
          setIsOpenCalendar(true);
        }
      })
      .catch((err) => {
        setIsOpenCalendar(true);

        // customSnackBar(err.message);
        // dispatch(openToast({ message: err.message, status: "error" }));
      });
  };
  const handleDuration = (duration, event) => {
    event.preventDefault();
    dispatch(setEventDuration(duration));
  };

  function handleEvents(events) {
    if (!Object.isExtensible(events)) {
      events = { ...events };
    }
    const currentDate = new Date();
    const datetime = new Date(events.start);
    events.bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    if (datetime < currentDate) {
      customSnackBar("Slot is in past unable to select that");
      // dispatch(
      //   openToast({
      //     message: "Slot is in past unable to select that",
      //     status: "warning",
      //   })
      // );
    } else if (events.title === "Available Slots") {
      dispatch(selectedSlotData(events));
      updateEventHandler(events);
    } else {
      customSnackBar("Already Booked Slot");
      // dispatch(
      //   openToast({ message: "Already Booked Slot", status: "warning" })
      // );
    }
  }

  const updateEventHandler = async (events) => {
    const formattedEndDate = calculateEndTime(
      events.timeFrom,
      events.timeTo,
      eventDuration
    );
    const data = {
      businessId: event.businessId
        ? event.businessId
        : memoizedSelectedBusinessId,

      id: event.id,
      type: event.type,
      providerName: findProviderNameById(resources, events.resourceId),
      title: title ? title : "break",
      description: description ? description : " ",
      bookingDate: events.bookingDate,
      bookingType: event.type,
      bookingDuration: eventDuration,
      timeFrom: `${events.timeFrom}:00`,
      timeTo: `${formattedEndDate}:00`,
      isSlotChanged: true,
      timeZone: "Europe/London",
    };
    try {
      const response = await eventsandbreakAxios.patch(`pb/events/`, data);
      if (response.data.code === 0) {
        dispatch(resetAllData());
        setIsOpenCalendar(false);
        history.push("/calendar");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateEventHandlers = async (events) => {
    const formattedEndDate = calculateEndTime(
      events.timeFrom,
      events.timeTo,
      eventDuration
    );
    const data = {
      businessId: event.businessId
        ? event.businessId
        : memoizedSelectedBusinessId,

      id: event.id,
      type: event.type,
      providerName: findProviderNameById(resources, events.resourceId),
      title: title ? title : "break",
      description: description ? description : " ",
      bookingDate: events.bookingDate,
      bookingType: event.type,
      breakDuration: eventDuration,
      timeFrom: `${events.timeFrom}:00`,
      timeTo: `${formattedEndDate}:00`,
      isSlotChanged: true,
      timeZone: "Europe/London",
    };
    try {
      const response = await eventsandbreakAxios.patch(`pb/events/`, data);
      if (response.data.code === 0) {
        dispatch(resetAllData());
        setIsOpenCalendar(false);
        history.push("/calendar");
        // window.location.reload()
      }
    } catch (e) {
      console.error(e);
    }
  };

  const styleForDeActiveButton = {
    borderRadius: "8px",
    border: "0.6px solid #DCDCDC",
    backgroundColor: "#FFF",
    width: "18%",
    color: "#888",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
  };

  const styleForActiveButton = {
    borderRadius: "8px",
    border: "0.6px solid #FFD705",
    backgroundColor: "#FFD705",
    width: "18%",
    color: "#530",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
  };

  const options = [
    { hours: 1, minutes: 15 },
    { hours: 1, minutes: 30 },
    { hours: 1, minutes: 45 },
    { hours: 2, minutes: 0 },
    { hours: 2, minutes: 15 },
    { hours: 2, minutes: 30 },
    { hours: 2, minutes: 45 },
    { hours: 3, minutes: 0 },
    { hours: 3, minutes: 15 },
    { hours: 3, minutes: 30 },
    { hours: 3, minutes: 45 },
    { hours: 4, minutes: 0 },
    { hours: 4, minutes: 15 },
    { hours: 4, minutes: 30 },
    { hours: 4, minutes: 45 },
    { hours: 5, minutes: 0 },
    { hours: 5, minutes: 15 },
    { hours: 5, minutes: 30 },
    { hours: 5, minutes: 45 },
    { hours: 6, minutes: 0 },
    { hours: 6, minutes: 15 },
    { hours: 6, minutes: 30 },
    { hours: 6, minutes: 45 },
    { hours: 7, minutes: 0 },
    { hours: 7, minutes: 15 },
    { hours: 7, minutes: 30 },
    { hours: 7, minutes: 45 },
    { hours: 8, minutes: 0 },
  ];
  const handleOptionChange = (hours, minutes) => {
    const totalMinutes = hours * 60 + minutes;
    dispatch(setEventDuration(totalMinutes));
    setIsOpen(!isOpen);
  };
  const action = "edit";
  return (
    <SideNavone>
      <div>
        <div className=" py-1 px-4">
          {!isOpenCalendar ? (
            <div
              className="d-flex mt-2"
              style={{
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div
                className="pt-2"
                onClick={() => history.goBack()}
                role="button"
              >
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
              <div className="container ms-4">
                <div
                  className="ps-2"
                  style={{
                    borderRadius: "8px",
                    border: "0.6px solid #DCDCDC",
                    backgroundColor: "#FFF",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#8A8A8A",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    Title
                  </span>
                  <input
                    type="text"
                    placeholder="Lunch Break"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#616161",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                  />
                </div>
                <div className="pt-2 mb-2">
                  <span
                    style={{
                      color: "#484848",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    Update Duration
                  </span>

                  <div className="d-flex justify-content-between">
                    <div
                      className="py-1 d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => handleDuration(15, e)}
                      style={
                        eventDuration === 15
                          ? styleForActiveButton
                          : styleForDeActiveButton
                      }
                    >
                      {eventDuration === 15 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7207_6338)">
                            <path
                              d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7207_6338">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      15 min
                    </div>
                    <div
                      className="py-1 d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => handleDuration(30, e)}
                      style={
                        eventDuration === 30
                          ? styleForActiveButton
                          : styleForDeActiveButton
                      }
                    >
                      {eventDuration === 30 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7207_6338)">
                            <path
                              d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7207_6338">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      30 min
                    </div>
                    <div
                      className="py-1 d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => handleDuration(45, e)}
                      style={
                        eventDuration === 45
                          ? styleForActiveButton
                          : styleForDeActiveButton
                      }
                    >
                      {eventDuration === 45 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7207_6338)">
                            <path
                              d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              // disabled={permission === false}
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7207_6338">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      45 min
                    </div>
                    <div
                      className="py-1 d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => handleDuration(60, e)}
                      style={
                        eventDuration === 60
                          ? styleForActiveButton
                          : styleForDeActiveButton
                      }
                    >
                      {eventDuration === 60 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7207_6338)">
                            <path
                              d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                              stroke="#553300"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7207_6338">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      60 min
                    </div>
                    <div
                      className="dropdown-container"
                      style={{
                        borderRadius: "8px",
                        border:
                          eventDuration > 60
                            ? "0.6px solid #FFD705"
                            : "0.6px solid #DCDCDC",
                        backgroundColor:
                          eventDuration > 60 ? "#FFD705" : "#FFF",
                        width: "24%",
                        color: eventDuration > 60 ? "#530" : "#888",
                        // textAlign: "center",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: eventDuration > 60 ? 700 : 500,
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <div
                        className="py-1 dropdown-header"
                        style={{
                          textAlign: "center",
                        }}
                        role="button"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {eventDuration === 75
                          ? "1 hour - 15 Min"
                          : eventDuration === 90
                          ? "1 hour - 45 Min"
                          : eventDuration === 105
                          ? "1 hour - 45 Min"
                          : eventDuration === 120
                          ? "2 hour - 0 Min"
                          : eventDuration === 135
                          ? "2 hour - 15 Min"
                          : eventDuration === 150
                          ? "2 hour - 30 Min"
                          : eventDuration === 165
                          ? "2 hour - 45 Min"
                          : eventDuration === 180
                          ? "3 hour - 0 Min"
                          : eventDuration === 195
                          ? "3 hour - 15 Min"
                          : eventDuration === 210
                          ? "3 hour - 30 Min"
                          : eventDuration === 225
                          ? "3 hour - 45 Min"
                          : eventDuration === 240
                          ? "4 hour - 0 Min"
                          : eventDuration === 255
                          ? "4 hour - 15 Min"
                          : eventDuration === 270
                          ? "4 hour - 30 Min"
                          : eventDuration === 285
                          ? "4 hour - 45 Min"
                          : eventDuration === 300
                          ? "5 hour - 0 Min"
                          : eventDuration === 315
                          ? "6 hour - 15 Min"
                          : eventDuration === 330
                          ? "6 hour - 30 Min"
                          : eventDuration === 345
                          ? "6 hour - 45 Min"
                          : eventDuration === 360
                          ? "7 hour - 0 Min"
                          : eventDuration === 375
                          ? "7 hour - 15 Min"
                          : eventDuration === 390
                          ? "7 hour - 30 Min"
                          : eventDuration === 405
                          ? "7 hour - 45 Min"
                          : eventDuration === 420
                          ? "8 hour - 0 Min"
                          : "Custom"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="7"
                          viewBox="0 0 17 9"
                          fill="none"
                          className="ms-2"
                          style={{
                            transform: isOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)", // Rotate icon based on dropdown state
                          }}
                        >
                          <path
                            d="M1.56969 1.7959L7.27469 7.5009C7.94844 8.17465 9.05094 8.17465 9.72469 7.5009L15.4297 1.7959"
                            stroke="#7B7B7B"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      {isOpen && (
                        <ul
                          className="dropdown-options"
                          style={{
                            listStyle: "none",
                            padding: "0",
                            margin: "0",
                            position: "absolute",
                            minWidth: "100%",
                            top: "100%",
                            left: "0",
                            zIndex: "1",
                            border: "1px solid #D7D7D7",
                            marginTop: "5px",
                            borderRadius: "5px",
                            height: "50vh",
                            overflow: "auto",
                            backgroundColor: "white",
                            // borderRadius: "8px",
                          }}
                        >
                          {options.map((option, index) => (
                            <li
                              key={index}
                              onMouseDown={() =>
                                handleOptionChange(option.hours, option.minutes)
                              }
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",

                                backgroundColor:
                                  option.hours * 60 + option.minutes ===
                                  eventDuration
                                    ? "#FFD705"
                                    : "#FFF",
                                padding: "4px 9px",
                                cursor: "pointer",
                                userSelect: "none",
                              }}
                            >
                              {`${option.hours} hour - ${option.minutes} Min`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="px-2 py-1"
                  style={{
                    borderRadius: "8px",
                    border: "0.6px solid #DCDCDC",
                    backgroundColor: "#FFF",
                  }}
                >
                  <div className="mb-1 d-flex justify-content-between">
                    <span
                      style={{
                        color: "#8A8A8A",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      Description
                    </span>
                    <span
                      style={{
                        color: "#8A8A8A",
                        textAlign: "right",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {description?.length}/35
                    </span>
                  </div>
                  <textarea
                    style={{
                      border: "none",
                      width: "100%",
                      color: "#616161",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                    row="4"
                    placeholder="Write small description here.."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="pt-3 d-flex justify-content-end">
                  <button
                    className="px-5 py-2"
                    type="button"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#FFD705",
                      color: "#530",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                    // disabled={
                    //   title?.length === 0 ||
                    //   description?.length === 0 ||
                    //   event.title === title ||
                    //   event.description === description
                    //     ? true
                    //     : false
                    // }
                    onClick={() => {
                      // if(eventData?.length===0){
                      setIsOpenCalendar(true);
                      // }else{
                      // CheckProviderAvailabilty()
                      // }
                    }}
                  >
                    {loader? <ClipLoader color="#000" size={14}/> :"Next"}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center align-items-center">
              <div className="d-flex">
                <div
                  className="pt-2"
                  onClick={() => history.push("/scheduler/create_break")}
                  role="button"
                >
                  <i id="icon_left_color" className="fas fa-chevron-left"></i>
                </div>
                <p
                  className="w-100 text-center text-primary py-0"
                  style={{ fontSize: "20px" }}
                >
                  {eventData.type === "event"
                    ? "Select your Events Slot"
                    : "Select your Break Slot"}
                </p>
              </div>
              {loading ? (
                <CalendarSkeleton />
              ) : (
                <div
                  className="col-12 overflow_calender"
                  style={{
                    overflow: "auto",
                    height: "80vh",
                  }}
                >
                  <CalendarApp
                    action={action}
                    eventData={eventData}
                    localizer={localizer}
                    allevent={combinedData}
                    allbgevent={allbgevent}
                    resources={resources}
                    views={allViews}
                    startTime={startTime}
                    endTime={endTime}
                    calenderEvents={handleEvents}
                    handleSlots={() => {}}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SideNavone>
  );
};

export default EditEventAndBreak;
