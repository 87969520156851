import { createSlice } from '@reduxjs/toolkit';
import { getAllEventsList } from './meetspaceActions';

const initialState = {
    allEventsList: [],
    isLoading: false,
    miniLoading: false,
    errorMessage: '',
}

const CreateSlice = createSlice({
    name: 'meetspace',
    initialState,
    reducers: {
        enableMiniLoader: (state) => {
            state.miniLoading = true;
        },
        disableMiniLoader: (state) => {
            state.miniLoading = false;
        }
    },

    extraReducers(builder) {
        //Get all services
        builder.addCase(getAllEventsList.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getAllEventsList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allEventsList = [...action.payload];
        })
        builder.addCase(getAllEventsList.rejected, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload
        })
    }
})

export const { enableMiniLoader, disableMiniLoader } = CreateSlice.actions;
export const meetspaceReducer = CreateSlice.reducer;