import { MenuItem, Select } from "@mui/material";
import { Col } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";

const PublishStatusServices = () => {
  const [value, setValue] = useState(20);

  const handleChange = (e) => setValue(e.target.value);

  return (
    <Col className="publish-status-wrapper px-0" md={2}>
      <span className="publish-label text-light-grey">Status</span>
      <Select
        className={`w-100 publish-status ${
          value === 10 ? "green-border" : "red-border"
        }`}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        IconComponent={() => (
          <KeyboardArrowDownIcon
            sx={{ fontSize: "18px", color: "#aaaaaa", marginLeft: "-40px" }}
          />
        )}
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={10}>Published</MenuItem>
        <MenuItem value={20}>Unpublished</MenuItem>
      </Select>
    </Col>
  );
};
export default PublishStatusServices;
