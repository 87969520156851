// entitySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    entity:"" ,
    process:"",// Array to store steps with IDs and statuses
  };

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntity(state, action) {
        state.entity=action.payload
    },
    setProcess(state,action)
    {state.process=action.payload},
    clearEntity(state) {
      return null;
    },
  },
});

export const { setEntity, clearEntity,setProcess } = entitySlice.actions;
export const entityReducer= entitySlice.reducer;
