import "./App.css";
import Apidelete from "./Apidelete";

function Index() {
  return (
    <div className="App p-5">
      <h1>Delete Api apply</h1>
      <Apidelete />
    </div>
  );
}

export default Index;
