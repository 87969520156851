import { Col, Form, Row, Table } from "react-bootstrap";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import PublishStatusServices from "../../../../Componenets/publishStatusServices/PublishStatusServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Formik, FieldArray } from "formik";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import OffCanvas from "./offCanvas/OffCanvas";

const ServiceAttributes = ({ show, handleClose, handleShow }) => {
  const rows = [
    {
      id: 1,
      description: "Full Body",
      sku: "4361652169",
      time: "60",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
    {
      id: 2,
      description: "Hands",
      sku: "4361652169",
      time: "20",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
    {
      id: 3,
      description: "Feet",
      sku: "4361652169",
      time: "20",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
  ];

  return (
    <Row className="dynamic_formContainer service-add-detail px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head select-service-type my-3">
            Service type for your attributes
          </h6>
          <div className="mb-3">
            <Form.Check
              className="form-check"
              type="checkbox"
              id="checkbox"
              label="Business Centre"
            />
          </div>
          <Row className="price-table-wrap att-table mx-0">
            <Col className="px-0" xs={12}>
              <div className="area-wrap">
                <div className="area">Area</div>
              </div>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className="table-cell"></th>
                    <th className="px-0 description-cell">Description</th>
                    <th className="sku-cell ps-1">SKU</th>
                    <th className="duration-cell ps-1">Duration</th>
                    <th className="price-cell ps-1">Price</th>
                  </tr>
                </thead>
                <tbody className="overflow-x-hidden">
                  {rows.map((row) => (
                    <tr className={`${show && "tr"}`} key={row.id}>
                      <td className="table-cell p-0 py-3">
                        <div className="border-right">
                          <MoreVertIcon className="pe-2 cursor-pointer" />
                        </div>
                      </td>
                      <td className="text-truncate px-2 text-truncate description-cell">
                        <div className="border-right">{row.description}</div>
                      </td>
                      <td className="sku-cell px-1 text-truncate">
                        <div className="border-right">{row.sku}</div>
                      </td>
                      <td className="duration-cell px-1 text-truncate">
                        <div className="border-right">
                          {row.time}
                          <span className="text-light-grey mins">
                            {row.interval}
                          </span>
                        </div>
                      </td>
                      <td className="price-cell px-1 text-truncate">
                        <div>
                          {row.currency}
                          {row.price}
                          <span className="text-light-grey mins">
                            {row.priceTime}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div
                className="table-add-new d-flex align-items-center"
                onClick={handleShow}
              >
                <>
                  <AddCircleOutlineIcon
                    className={`add-circle-table ${show && "invisible"}`}
                  />
                  <span className={show && "invisible"}>Add New</span>
                </>
              </div>
            </Col>
          </Row>

          <Formik
            initialValues={{ attributes: [] }}
            render={({ values }) => (
              <Form>
                <FieldArray
                  name="attributes"
                  render={(arrayHelpers) => (
                    <div>
                      {values.attributes.map((el, index) => (
                        <Row className="price-table-wrap att-table mx-0 mt-3 position-relative">
                          <DeleteOutlinedIcon className="delete-field" />
                          <Col className="px-0" xs={12}>
                            <div className="area-wrap">
                              <div className="area">Area</div>
                            </div>
                            <Table className="mb-0">
                              <thead>
                                <tr>
                                  <th className="table-cell"></th>
                                  <th className="px-0 description-cell">
                                    Description
                                  </th>
                                  <th className="sku-cell ps-1">SKU</th>
                                  <th className="duration-cell ps-1">
                                    Duration
                                  </th>
                                  <th className="price-cell ps-1">Price</th>
                                </tr>
                              </thead>
                              <tbody className="overflow-x-hidden">
                                {rows.map((row) => (
                                  <tr key={row.id}>
                                    <td className="table-cell p-0 py-3">
                                      <div className="border-right">
                                        <MoreVertIcon className="pe-2" />
                                      </div>
                                    </td>
                                    <td className="text-truncate px-2 text-truncate description-cell">
                                      <div className="border-right">
                                        {row.description}
                                      </div>
                                    </td>
                                    <td className="sku-cell px-1 text-truncate">
                                      <div className="border-right">
                                        {row.sku}
                                      </div>
                                    </td>
                                    <td className="duration-cell px-1 text-truncate">
                                      <div className="border-right">
                                        {row.time}
                                        <span className="text-light-grey mins">
                                          {row.interval}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="price-cell px-1 text-truncate">
                                      <div>
                                        {row.currency}
                                        {row.price}
                                        <span className="text-light-grey mins">
                                          {row.priceTime}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <div
                              className="table-add-new d-flex align-items-center"
                              onClick={handleShow}
                            >
                              <>
                                <AddCircleOutlineIcon
                                  className={`add-circle-table ${
                                    show && "invisible"
                                  }`}
                                />
                                <span className={show && "invisible"}>
                                  Add New
                                </span>
                              </>
                            </div>
                          </Col>
                        </Row>
                      ))}

                      <div
                        className="mt-3"
                        onClick={() => arrayHelpers.push("")}
                      >
                        <div className="d-flex justify-content-center py-1 cursor-pointer add-column">
                          <div className="add-circle-icon">
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </Form>
            )}
          />

          <div className="pt-3">
            <div className="d-flex justify-content-end gap-3 mt-5 pt-5">
              <button className="cancel-btn">Cancel</button>

              <button className="save-btn">Save</button>
            </div>
          </div>
        </div>
      </Col>
      <PublishStatusServices />
      <OffCanvas
        placement="end"
        name="End"
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </Row>
  );
};
export default ServiceAttributes;
