/** @format */

import { UnavailableEventsCreation } from "./UnavailableEventsCreation";

function calculateTimeDuration(timeFrom, timeTo) {
  const timeFromParts = timeFrom.split(":");
  const timeToParts = timeTo.split(":");

  const fromMinutes =
    parseInt(timeFromParts[0], 10) * 60 + parseInt(timeFromParts[1], 10);
  const toMinutes =
    parseInt(timeToParts[0], 10) * 60 + parseInt(timeToParts[1], 10);

  const durationInMinutes = toMinutes - fromMinutes;
  return Math.abs(durationInMinutes);
}

export function ExtractAppointments(res, date) {
  let val = res?.providers;
  let temp = [];
  val?.map((val1,parentIndex) => {
    const multiTime = val1.multiTime.sort((a, b) => {
      const timeAFromList = a.timeFrom;
      const timeBFromList = b.timeFrom;
      return timeAFromList.localeCompare(timeBFromList, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });

    const multiTimeFrom = multiTime[0].timeFrom.split(":");
    const businessTimeFrom = res.timeFrom.split(":");
    const multiTimeTo = multiTime[multiTime.length - 1]?.timeTo.split(":");
    const businessTimeTo = res.timeTo.split(":");
   

    val1?.appointments?.map((value,index) => {
      temp.push({
        id: `${parentIndex}.0.${index+1}`,
        appointmentId:
          value?.plexaarAppointmentId !== null
            ? value?.plexaarAppointmentId
            : value?.expertAppointmentId,
          //   appointmentId:
          // value?.appointmentId,
          
        title: value?.serviceName,
        start: UnavailableEventsCreation(value?.timeFrom, date),
        end: UnavailableEventsCreation(value?.timeTo, date),
        resourceId: val1?.providerId,
        allDay: false,
        background: false,
        bookingDate: value?.bookingDate,
        bookingId: value?.plexaarBookingId,
        color: "white",
        colorCode: value?.colorCode,
        colorEvento: value?.colorCode,
        customerFirstName: value?.customerFirstName,
        customerLastName: value?.customerLastName,
        customerName: value?.customerName,
        duration: calculateTimeDuration(value?.timeFrom, value?.timeTo),
        providerName: val1?.providerName,
        resource: val1?.providerId,
        serviceDetails: value?.servicePayload,
        timeFrom: value?.timeFrom,
        timeTo: value?.timeTo,
        type: "booking",
        sourceResource: null,
      });
    });
    val1?.events?.map((valu,index) => {
      temp.push({
        id: `${parentIndex}.1.${index+1}`,
        title: valu?.title,
        resourceId: val1?.providerId,
        start: UnavailableEventsCreation(valu?.timeFrom, date),
        end: UnavailableEventsCreation(valu?.timeTo, date),
        duration: calculateTimeDuration(valu?.timeFrom, valu?.timeTo),
        colorEvento: valu?.colorCode,
        color: "white",
        allDay: false,
        background: false,
        resource: val1?.providerId,
        serviceDetail: valu?.serviceDetail,
        customerName: valu?.customerName,
        colorCode: valu?.colorCode,
        timeFrom: valu?.timeFrom,
        timeTo: valu?.timeTo,
        eventId: valu?.eventId,
        description: valu?.description,
        type: "event",
        bookingDate: valu?.bookingDate,
        providerName: val1?.providerName,
        sourceResource: null,
      });
    });
    val1?.timeblock?.map((valu,index) => {
      console.log(valu,"valuvaluvalu")
      temp.push({
        id: `${parentIndex}.1.${index+1}`,
        title: valu?.title,
        resourceId: val1?.providerId,
        start: UnavailableEventsCreation(valu?.timeFrom, date),
        end: UnavailableEventsCreation(valu?.timeTo, date),
        duration: calculateTimeDuration(valu?.timeFrom, valu?.timeTo),
        colorEvento: valu?.colorCode,
        color: "white",
        allDay: false,
        background: false,
        resource: val1?.providerId,
        serviceDetail: valu?.serviceDetail,
        customerName: valu?.customerName,
        colorCode: valu?.colorCode,
        timeFrom: valu?.timeFrom,
        timeTo: valu?.timeTo,
        timeblockId: valu?.timeblockId,
        description: valu?.description,
        type: "timeBlock",
        bookingDate: valu?.bookingDate,
        providerName: val1?.providerName,
        sourceResource: null,
      });
    });
    val1?.breaks?.map((vals,index) => {
      temp.push({
        id: `${parentIndex}.2.${index+1}`,
        title: vals?.title,
        resourceId: val1?.providerId,
        start: UnavailableEventsCreation(vals?.timeFrom, date),
        end: UnavailableEventsCreation(vals?.timeTo, date),
        duration: calculateTimeDuration(vals?.timeFrom, vals?.timeTo),
        colorEvento: vals?.colorCode,
        color: "black",
        allDay: false,
        background: false,
        timeFrom: vals?.timeFrom,
        timeTo: vals?.timeTo,
        resource: val1?.providerId,
        breakId: vals?.breakId,
        type: "break",
        description: vals?.description,
        serviceDetail: vals?.serviceDetail,
        sourceResource: null,
        providerName: val1?.providerName,
      });
    });
    
    if (
      multiTime[0].timeFrom !== res.timeFrom &&
      parseInt(multiTimeFrom[0]) >= parseInt(businessTimeFrom[0]) 
    ) {
      if(parseInt(multiTimeFrom[0]) === parseInt(businessTimeFrom[0]) && parseInt(multiTimeFrom[1])>parseInt(businessTimeFrom[1])){
        temp.push({
          id: `${parentIndex}.3.${1}`,
          title: "Unavailable",
          resourceId: val1?.providerId,
          start: UnavailableEventsCreation(res?.timeFrom, date),
          end: UnavailableEventsCreation(multiTime[0].timeFrom, date),
          duration: calculateTimeDuration(res?.timeFrom, multiTime[0].timeFrom),
          colorEvento: "white",
          color: "black",
          allDay: false,
          background: false,
          selectable: false,
          timeFrom: res?.timeFrom,
          timeTo: multiTime[0].timeFrom,
          resource: val1?.providerId,
          providerName: val1?.providerName,
          isBusinessHour: true,
        });
      }else {
        temp.push({
          id: `${parentIndex}.3.${1}`,
        title: "Unavailable",
        resourceId: val1?.providerId,
        start: UnavailableEventsCreation(res?.timeFrom, date),
        end: UnavailableEventsCreation(multiTime[0].timeFrom, date),
        duration: calculateTimeDuration(res?.timeFrom, multiTime[0].timeFrom),
        colorEvento: "white",
        color: "black",
        allDay: false,
        background: false,
        selectable: false,
        timeFrom: res?.timeFrom,
        timeTo: multiTime[0].timeFrom,
        resource: val1?.providerId,
        providerName: val1?.providerName,
        isBusinessHour: true,
      });
      }
    }

    for (let i = 0; i < multiTime.length - 1; i++) {
      if (multiTime[i].timeTo !== multiTime[i + 1].timeFrom) {
        temp.push({
          id: `${parentIndex}.3.${i+1}`,
          title: "Unavailable",
          resourceId: val1?.providerId,
          start: UnavailableEventsCreation(multiTime[i].timeTo, date),
          end: UnavailableEventsCreation(multiTime[i + 1].timeFrom, date),
          duration: calculateTimeDuration(
            multiTime[i].timeTo,
            multiTime[i + 1].timeFrom
          ),
          resource: val1?.providerId,
          colorEvento: "white",
          color: "black",
          allDay: false,
          background: false,
          timeFrom: multiTime[i].timeTo,
          timeTo: multiTime[i + 1].timeFrom,
          providerName: val1?.providerName,
        isBusinessHour: true,
        });
      }
    }

    if (
      multiTime[multiTime.length - 1]?.timeTo !== res.timeTo &&
      parseInt(multiTimeTo[0]) <= parseInt(businessTimeTo[0])
    ) {
      if(parseInt(multiTimeTo[0]) === parseInt(businessTimeTo[0]) && parseInt(multiTimeTo[1]) < parseInt(businessTimeTo[1])){
        temp.push({
          id: `${parentIndex}.3.${multiTime.length}`,
          title: "Unavailable",
          resourceId: val1?.providerId,
          start: UnavailableEventsCreation(
            multiTime[multiTime.length - 1]?.timeTo,
            date
          ),
          end: UnavailableEventsCreation(res?.timeTo, date),
          duration: calculateTimeDuration(
            multiTime[multiTime.length - 1]?.timeTo,
            res?.timeTo
          ),
          colorEvento: "white",
          resource: val1?.providerId,
          color: "black",
          allDay: false,
          background: false,
          timeFrom: multiTime[multiTime.length - 1]?.timeTo,
          timeTo: res?.timeTo,
          providerName: val1?.providerName,
          isBusinessHour: true,
        });
      }else {
        temp.push({
          id: `${parentIndex}.3.${multiTime.length}`,
          title: "Unavailable",
          resourceId: val1?.providerId,
          start: UnavailableEventsCreation(
            multiTime[multiTime.length - 1]?.timeTo,
            date
          ),
          end: UnavailableEventsCreation(res?.timeTo, date),
          duration: calculateTimeDuration(
            multiTime[multiTime.length - 1]?.timeTo,
            res?.timeTo
          ),
          colorEvento: "white",
          resource: val1?.providerId,
          color: "black",
          allDay: false,
          background: false,
          timeFrom: multiTime[multiTime.length - 1]?.timeTo,
          timeTo: res?.timeTo,
          providerName: val1?.providerName,
          isBusinessHour: true,
        });
      }
    }
  });
  return temp;
}

export function ExtractBgAppointments(res, date) {
  let val = res?.providers;
  let background = [];
  val?.map((val) => {
    if (val.isAvailable === false) {
      background.push({
        title: "Notavailable",
        start: UnavailableEventsCreation(res?.timeFrom, date),
        end: UnavailableEventsCreation(res?.timeTo, date),
        resourceId: val?.providerId,
        resource: val?.providerId,
        colorEvento: "gray",
        color: "white",
        allDay: false,
        background: false,
      });
    }
  });
  return background;
}
export function ExtractResources(res) {
  const tempResource = res?.providers?.map((val) => {
    return {
      resourceId: val?.providerId,
      resourceTitle: val?.providerName,
      img: val?.providerImageURL,
    };
  });
  return tempResource;
}

export function getNameOfResource(date, model) {
  const data = date.filter((val) => {
    return model.resourceId === val.resourceId;
  });
  return data;
}
