import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import './payment.scss';

const PaymentBox = () => {
    return (
        <div className="row mt-3">
            <div className="col-12 px-4 pt-2 payment_card_list">
                <div className="row">
                    <div className="col-8 d-flex flex-column justify-content-center">
                        <h4>Payment Method</h4>
                        <p>
                            Don't worry, your information is private and we will not share this info with anyone outside Expert!
                        </p>

                        <p className="p-0">
                            <h5 className="text-danger" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                Learn More <KeyboardDoubleArrowRightIcon sx={{ fontSize: '30px' }} />
                            </h5>
                        </p>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body collapse_card hide_scrollbar border-0 p-0">
                                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                            </div>
                        </div>

                    </div>

                    <div className="col-4 p-3 d-flex justify-content-end">
                        <img src="./images/pmethodimg.png" alt="no image" className="pmethod_img w-75" />
                    </div>
                </div>
            </div>
        </div>
        // <div className="border border-primary-subtle rounded py-2 px-4 d-flex justify-content-between align-items-center">
        //     <div>
        //         <p className="fs-5">Payment Method</p>
        //         <p className="fs-6 me-5">
        //             Don't worry, your information is private and we will not share this
        //             info with anyone outside Expert!
        //         </p>
        //         <div className="d-flex align-items-center text-danger justify-content-start">
        //             Learn More <AiOutlineRight />
        //         </div>
        //     </div>
        //     <div>
        //         <img
        //             src="/assets/images/people_group.png"
        //             alt="Information"
        //             width={190}
        //             height={173}
        //         />
        //     </div>
        // </div>
    );
};

export default PaymentBox;
