import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import SideNavBar from "../../../../SideNavBar";
// import "../AddBusiness/AddBusiness.scss";
import "../../../Dashboard/MainComponent/Stafflistview/staffview.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBusinessRequest } from "../../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import BannerProfile from "./bannerProfile";
import BusinessDetail from "./businessDetail";
import EditBusinessName from "./editbusinessName";
import EditBusinessType from "./editBusinessType";
import EditWebsite from "./editWebsite";
import CardLink from "./cardsLink";
import BusinessOwner from "./BusinessOwner";
const EditBusinessAddress = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const newid = parseInt(id);
  const businesProfile = useSelector((state) => state.Business.businesProfile);
const history=useHistory()
  return (
    <SideNavBar>
      <div
        className="ps-5 pe-5 hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <span className=" pt-3 pb-4 text-black ">Manage Address</span>

        <div className="card mt-2" onClick={()=>{ history.push("/edit-map")

        }}>
          <div className="row me-0">
            <div className="col-md-6 ps-2 ">
              <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                Address Name
              </p>
              {businesProfile?.businessAddress?.addressName ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.addressName}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
            </div>
            <div className="col-md-6 text-end m-auto">
              <img src="/images/Clip path group (2).svg" alt="" />{" "}
            </div>
            <div className="col-md-12">
              <hr className="my-3 background_color_line" />
            </div>

            <div className="col-md-6 ps-2 ">
              <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                Flat & Building
              </p>
              {businesProfile?.businessAddress?.flat ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.flat}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
            </div>
            <div className="col-md-12">
              <hr className="my-3 background_color_line" />
            </div>
            <div className="col-md-6 ps-2 ">
              <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                Street Address
              </p>
              {businesProfile?.businessAddress?.streetAddress ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.streetAddress}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
             
            </div>
            <div className="col-md-12">
              <hr className="my-3 background_color_line" />
            </div>
            <div className="col-md-6 ps-2 ">
              <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                Postal Code
              </p>
              {businesProfile?.businessAddress?.postalCode ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.postalCode}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
            </div>
          </div>
        </div>
      </div>
    </SideNavBar>
  );
};

export default EditBusinessAddress;
