import { Card, Col, Row } from "react-bootstrap";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import PublishStatusServices from "../../../../Componenets/publishStatusServices/PublishStatusServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";
import serviceImg from "../../../../Assets/svgs/service-img.svg";

const ServiceGroup = () => {
  return (
    <Row className="dynamic_formContainer service-add-detail service-group-wrap px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head my-3">
            Following services can be grouped together
          </h6>
          <div className="cards-wrap">
            {[0, 1, 2, 3].map((el) => (
              <Card className="custom-border group-card">
                <Card.Body className="p-1 d-flex align-items-center">
                  <div className="group-service-card">
                    <img
                      className="h-100 w-100 d-block"
                      src={serviceImg}
                      alt=""
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    <h1 className="head mb-1">Laser Hair Removal</h1>
                    <p className="text-light-grey card-detail mb-0">
                      ID 890383
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>

          <div className="add-new text-center">
            <div className="plus">
              <span>+</span>
            </div>
            &nbsp;&nbsp;
            <span>Add New</span>
          </div>
          <div className="d-flex justify-content-end gap-3 mt-5">
            <button className="cancel-btn">Cancel</button>
            <button className="save-btn">Save</button>
          </div>
        </div>
      </Col>
      <PublishStatusServices />
    </Row>
  );
};
export default ServiceGroup;
