import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useParams } from 'react-router-dom';
import { deleteRequest, postRequest, putRequest } from '../../../request';
import { FaRegEdit } from 'react-icons/fa';
import { base_url } from '../../../base_url';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceCosts } from '../../../store/servicesActions';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { BntLoader } from '../../../../../Componenets/loaders';
import { disableMiniLoader, enableMiniLoader } from '../../../store/ServicesSlice';

const CostFormMobile = () => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Cost Type', width: '200px' },
        { id: 2, name: 'Title', width: '200px' },
        { id: 3, name: 'Unit Type', width: '150px' },
        { id: 4, name: 'Quantity', width: '150px' },
        { id: 5, name: 'Cost', width: '200px' },
    ]);

    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [defaultColumns, setDefaultColumns] = useState([
        { id: 1, width: '150px' },
        { id: 2, width: '120px' },
        { id: 3, width: '280px' },
        { id: 4, width: '90px' },
        { id: 5, width: '80px' },
    ]);
    const [costType, setCostType] = useState();
    const [title, setTitle] = useState('');
    const [unitType, setUnitType] = useState('');
    const [quantity, setQuantity] = useState('');
    const [cost, setCost] = useState('');
    const [serviceSku, setServiceSku] = useState('');
    const [currencyId, setCurrencyId] = useState();
    const [selectedId, setSelectedId] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const { id } = useParams();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);
    let total_cost = 0;

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };

    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };

    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);

        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });

        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteSelectedRows = async (delId) => {
        const headers = {
            "Content-Type": "application/json",
            "charset": "utf-8"
        }
        const res = await deleteRequest(`${base_url}/Cost/removeServicesCost`, { id: delId }, headers);
        if (res?.message === "Success") {
            dispatch(getServiceCosts(id))
            alert('Service Cost deleted successfully');
        }
        else {
            alert(res?.message);
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const postData = async () => {
        if (costType && title && unitType && quantity && cost) {
            dispatch(enableMiniLoader());
            if (selectedId) {
                const data = {
                    id: selectedId,
                    serviceId: parseInt(id),
                    currencyId: parseInt(currencyId) ? parseInt(currencyId) : 1,
                    costTypeId: parseInt(costType),
                    title: title,
                    type: unitType,
                    quantity: quantity,
                    cost: parseInt(cost),
                    serviceSku: serviceSku
                }
                const res = await putRequest(`${base_url}/Cost/updateServicesCost`, data);
                if (res?.message === "Success") {
                    setCostType('');
                    setTitle('');
                    setUnitType('');
                    setQuantity('')
                    setCost('');
                    setSelectedId(null);
                    dispatch(disableMiniLoader());
                    dispatch(getServiceCosts(id))
                    alert('Service Price Updated successfully');
                }
                else {
                    alert(res?.message);
                }
            }
            else {
                const data = {
                    serviceId: parseInt(id),
                    currencyId: parseInt(currencyId) ? parseInt(currencyId) : 1,
                    costTypeId: parseInt(costType),
                    title: title,
                    type: unitType,
                    quantity: quantity,
                    cost: parseInt(cost),
                    serviceSku: serviceSku
                }
                const res = await postRequest(`${base_url}/Cost/addServiceCost`, data);
                if (res?.message === "Success") {
                    setCostType('');
                    setTitle('');
                    setUnitType('');
                    setQuantity('')
                    setCost('');
                    setSelectedId(null);
                    dispatch(disableMiniLoader());
                    dispatch(getServiceCosts(id))
                    alert('Service cost added successfully');
                }
                else {
                    alert(res?.message);
                }
            }
        }
        else {
            alert('Please provide misssing values')
        }
    }

    const getEditData = (id) => {
        setSelectedId(id)
        const filtered = rows.filter(elem => elem?.id === id);

        if (filtered) {
            filtered[0]?.data?.map(v => {

                if (v?.costTypeId) {
                    setCostType(v?.costTypeId);
                }
                if (v?.title) {
                    setTitle(v?.title);
                }
                if (v?.costType) {
                    setUnitType(v?.costType);
                }
                if (v?.quantity) {
                    setQuantity(v?.quantity);
                }
                if (v?.cost) {
                    setCost(v?.cost);
                }

            });
        }
    }

    useEffect(() => {
        if (store?.serviceCosts) {
            // Map the serviceCosts array to the rows state
            const mappedRows = store.serviceCosts.map((v) => ({
                id: v.id,
                serviceId: v.serviceId,
                data: [
                    { id: 1, costTypeTitle: v.costTypeTitle },
                    { id: 2, title: v.title },
                    { id: 3, costType: v.costType },
                    { id: 4, quantity: v.quantity },
                    { id: 5, cost: v.cost },
                    { costTypeId: v.costTypeId },
                ],
            }));
            setRows(mappedRows);
        }
    }, [store?.serviceCosts]);

    // Calculate the index range of data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRows = rows.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="container p-0 hide_scrollbar position-relative mobile_display mt-3" style={{ maxHeight: '100vh', overflow: 'scroll' }}>
                <table className="table cost_form_container_mobile w-100"
                // style={{ overflowX: 'clip' }}
                >
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {columns.map((column, index) => {
                                if (index < 1) {
                                    return (
                                        <th
                                            id={`sticky_col${column?.id}`}
                                            key={column.id}
                                        >{column.name}</th>
                                    );
                                }

                                return (
                                    <th
                                        // key={column.id}
                                        draggable
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}

                                        onMouseOver={() => setColumnId(column.id)}
                                        onMouseOut={() => setColumnId('')}
                                        className={isDraggingRow ? 'dragging-row' : ''}

                                        style={{
                                            width:
                                                // column?.width
                                                // column?.id == 1 && "200px" ||
                                                column?.id == 2 && "200p" ||
                                                column?.id == 3 && "150px" ||
                                                column?.id == 4 && "150px" ||
                                                column?.id == 5 && "200px"
                                        }}
                                    >
                                        <div className='d-flex justify-content-center'>
                                            {column.name}
                                        </div>
                                        <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                            <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                            </div>
                                        </div>

                                    </th>
                                );
                            })}
                            <th style={{ width: '70px' }}>
                                Action
                            </th>
                            <th className='addcol_btn_th'
                                style={{ width: '73px' }}
                            >
                                <div className="dropdown">
                                    <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                    </button>
                                    <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                        {dropdown_columns && dropdown_columns.map((val, ind) => {
                                            return (
                                                <div key={val?.id}>
                                                    <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => (
                            <>
                                <tr
                                    key={row.id}
                                    draggable
                                    onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                    onDragOver={handleRowDragOver}
                                    onDrop={(e) => handleRowDrop(e, rowIndex)}
                                    handleDragEnd={handleDragEnd}
                                    className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                                >
                                    <td>
                                        <div className="form-check ps-4 text-center">
                                            <input type="checkbox" value="" id="flexCheckDefault"
                                                className="form-check-input checkbox_btn"
                                                checked={selectedRows.includes(row.id)}
                                                onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                                style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                            />
                                        </div>
                                    </td>

                                    {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                        if (paginatedRows.length - 1 !== rowIndex) {
                                            total_cost = cellValue?.id === 5 ? total_cost + cellValue?.cost : total_cost
                                        }
                                        return (
                                            <>
                                                {cellValue.id === 1 &&
                                                    <td key={columnIndex}
                                                        id={`sticky_col${columnIndex}`}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.costTypeTitle}
                                                    </td>
                                                }

                                                {cellValue.id === 2 &&
                                                    <td key={columnIndex}
                                                        style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.title}
                                                    </td>
                                                }

                                                {cellValue.id === 3 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        {cellValue?.costType}
                                                    </td>
                                                }

                                                {cellValue.id === 4 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.quantity}
                                                    </td>
                                                }

                                                {cellValue.id === 5 &&
                                                    <>
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                            className='text-center'
                                                        >
                                                            {cellValue?.cost}
                                                        </td>
                                                    </>
                                                }


                                                {cellValue.id === 7 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <input type='text' placeholder='' value={''} />
                                                    </td>
                                                }

                                                {cellValue.id === 8 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <select>
                                                            <option value="" className='w-100'>Select</option>
                                                            <option value="Option 1">option A</option>
                                                            <option value="Option 2">option B</option>
                                                            <option value="Option 2">option C</option>
                                                        </select>
                                                    </td>
                                                }

                                                {cellValue.id === 9 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='number' placeholder='' value={''} />
                                                    </td>
                                                }

                                                {cellValue.id === 10 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='file' placeholder='' value={''} />
                                                    </td>
                                                }

                                            </>
                                        )
                                    })}

                                    <td className='text-center'
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                        <div className="dropdown" style={{ positition: 'relative' }}>
                                            <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                style={{ zIndex: '0' }}
                                            >
                                                <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                                                style={{ zIndex: '4' }}
                                            >
                                                <div className='py-1'
                                                    onClick={() => getEditData(row?.id)}
                                                >
                                                    <FaRegEdit className='text-warning' style={{ fontSize: '18px' }} />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Edit Row</span>
                                                </div>
                                                <div className='py-1'
                                                    onClick={() => { deleteSelectedRows(row?.id) }}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }}
                                                    />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Delete Row</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                    </td>
                                </tr>
                                {paginatedRows.length - 1 === rowIndex &&
                                    <tr className='sticky_row'>
                                        <td>
                                            <div className="form-check ps-4 text-center">
                                            </div>
                                        </td>

                                        {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                            if (paginatedRows.length - 1 === rowIndex) {
                                                total_cost = cellValue?.id === 5 ? total_cost + cellValue?.cost : total_cost
                                            }
                                            return (
                                                <>
                                                    {cellValue.id === 1 &&
                                                        <td key={columnIndex}
                                                            id={`sticky_col${columnIndex}`}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        >
                                                            <select
                                                                onChange={(e) => setCostType(e.target.value)}
                                                                value={costType}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                {store?.allCostTypes && store?.allCostTypes.map((v) => {
                                                                    return (
                                                                        <option key={v?.id} value={v?.id}>{v?.title}</option>
                                                                    )
                                                                })}
                                                            </select>

                                                        </td>
                                                    }

                                                    {cellValue.id === 2 &&
                                                        <td key={columnIndex}
                                                            style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                        >
                                                            <input type='text' placeholder=''
                                                                onChange={(e) => setTitle(e.target.value)}
                                                                value={title}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 3 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                        >
                                                            <select
                                                                onChange={(e) => setUnitType(e.target.value)}
                                                                value={unitType}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                {store?.allUnitTypes && store?.allUnitTypes.map((v) => {
                                                                    return (
                                                                        <option key={v?.id} value={v?.name}>{v?.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 4 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        >
                                                            <input type='number' placeholder=''
                                                                min="0"
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    if (e.target.value < 0) {
                                                                        e.preventDefault();
                                                                    }
                                                                    else {
                                                                        setQuantity(e.target.value);

                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 5 &&
                                                        <>
                                                            <td key={columnIndex}
                                                                style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                                className='text-center'
                                                            >
                                                                <input type='number' placeholder=''
                                                                    min="0"
                                                                    value={cost}
                                                                    onChange={(e) => {
                                                                        if (e.target.value < 0) {
                                                                            e.preventDefault()
                                                                        }
                                                                        else {
                                                                            setCost(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                        </>
                                                    }


                                                    {cellValue.id === 7 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                        >
                                                            <input type='text' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                    {cellValue.id === 8 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                        >
                                                            <select>
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="Option 1">option A</option>
                                                                <option value="Option 2">option B</option>
                                                                <option value="Option 2">option C</option>
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 9 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        >
                                                            <input type='number' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                    {cellValue.id === 10 &&
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        >
                                                            <input type='file' placeholder='' value={''} />
                                                        </td>
                                                    }

                                                </>
                                            )
                                        })}

                                        <td className='text-center'
                                            style={{ position: 'relative' }}
                                        >
                                            {store?.miniLoading &&
                                                <BntLoader />
                                            }
                                            <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                                style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                                onClick={postData}
                                            >Add</button>
                                        </td>
                                        <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                        >
                                        </td>
                                    </tr>
                                }
                            </>
                        ))}

                        {paginatedRows.length == 0 &&
                            <tr id='table_form_row'>
                                <td>
                                </td>
                                {defaultColumns?.length > 0 && defaultColumns?.map((cellValue, columnIndex) => {
                                    return (
                                        <>
                                            {cellValue.id === 1 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                >
                                                    <select
                                                        onChange={(e) => setCostType(e.target.value)}
                                                        value={costType}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        {store?.allCostTypes && store?.allCostTypes.map((v) => {
                                                            return (
                                                                <option key={v?.id} value={v?.id}>{v?.title}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 2 &&
                                                <td key={columnIndex}>
                                                    <input type='text' placeholder=''
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        value={title}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 3 &&
                                                <td key={columnIndex}
                                                >
                                                    <select
                                                        onChange={(e) => setUnitType(e.target.value)}
                                                        value={unitType}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        {store?.allUnitTypes && store?.allUnitTypes.map((v) => {
                                                            return (
                                                                <option key={v?.id} value={v?.name}>{v?.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 4 &&
                                                <td key={columnIndex}
                                                >
                                                    <input type='number' placeholder=''
                                                        min="0"
                                                        value={quantity}
                                                        onChange={(e) => {
                                                            if (e.target.value < 0) {
                                                                e.preventDefault();
                                                            }
                                                            else {
                                                                setQuantity(e.target.value);

                                                            }
                                                        }}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 5 &&
                                                <td key={columnIndex}
                                                    className='text-center'
                                                >
                                                    <input type='number' placeholder=''
                                                        min="0"
                                                        value={cost}
                                                        onChange={(e) => {
                                                            if (e.target.value < 0) {
                                                                e.preventDefault()
                                                            }
                                                            else {
                                                                setCost(e.target.value)
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            }


                                            {cellValue.id === 7 &&
                                                <td key={columnIndex}
                                                >
                                                    <input type='text' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 8 &&
                                                <td key={columnIndex}>
                                                    <select>
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="Option 1">option A</option>
                                                        <option value="Option 2">option B</option>
                                                        <option value="Option 2">option C</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 9 &&
                                                <td key={columnIndex}>
                                                    <input type='number' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 10 &&
                                                <td key={columnIndex}>
                                                    <input type='file' placeholder='' value={''} />
                                                </td>
                                            }

                                        </>
                                    )
                                })}
                                <td className='text-center'
                                    style={{ position: 'relative' }}
                                >
                                    {store?.miniLoading &&
                                        <BntLoader />
                                    }
                                    <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                        style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                        onClick={postData}
                                    >Add</button>
                                </td>

                                <td></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className='container mobile_display mt-2'>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-between p-3 shadow-sm' style={{ borderRadius: '9px', backgroundColor: 'white', border: '1px solid lightgray' }}>
                        <h5 className='p-0 m-0'>Total Cost</h5>
                        <h5 className='p-0 m-0'>{total_cost} £</h5>
                    </div>
                </div>
            </div>

            <div className='row mobile_display mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <select
                        className='items_per_page_select'
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option disabled >Items Per page</option>
                        <option value={5} selected>5</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={rows?.length}>All</option>
                    </select>

                    <Stack spacing={2} className=''>
                        <Pagination
                            count={Math.ceil(rows.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                        />
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default CostFormMobile;
