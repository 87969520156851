import React, { useEffect, useState } from "react";
import Features from "../Features/Features";
import ContactUs from "../ContactUs/ContactUs";
import SmartInventoryReporting from "../SmartInventoryReporting/SmartInventoryReporting";
import Hero from "../Hero/Hero";
import { HomePageContent, defaultData } from "../../static/Content";
import { useParams } from "react-router-dom";
import {
  AiBookingIcon,
  AiPowerAutomationIcon,
  AiScheduler,
} from "../../assets";
import NewsLatter from "../../../HRManagement/components/NewsLatter/NewsLatter";
import Footer from "../../../Calendex/components/Footer/Footer";
import { Header } from "../../../../Hero/Header";
import {
  InventroyWeb,
  InventoryMobile,
  invn,
  invn2,
} from "../../../../../../Assets/components/HomePage/Assets";

const Home = () => {
  const [pageData, setPageData] = useState([]);
  const { id } = useParams();
  const { readMorePages } = HomePageContent;

  // Find the page object that matches the provided id
  useEffect(() => {
    if (id) {
      const filtered = readMorePages.filter((val) => val?.id == id);
      setPageData(filtered);
    } else {
      setPageData(defaultData);
    }
  }, [id]);

  return (
    <div>
      {/* <Hero
        pageData={pageData}
        
      /> */}
      <Header
        title={"Stockpro"}
        heading={"Make Inventory Management Smarter with AI Technology"}
        text={
          "Ready to manage your inventory levels & tracking stock movements in real time. You can create purchase orders, manage supplier information, & generate reports on inventory levels, usage, & costs."
        }
        imgWeb={InventroyWeb}
        imgMob={InventoryMobile}
      />
      <Features
        pageData={pageData}
        // featuresHeadingSectionOne={featuresHeadingSectionOne}
        // featuresParaSectionOne={featuresParaSectionOne}
        // readMoreInventoryFeatures={readMoreInventoryFeatures}
        // featuresSectionTwo={featuresSectionTwo}
        // readMoreMannagementFeatures={readMoreMannagementFeatures}
        invn={invn}
        invn2={invn2}
      />
      <ContactUs
        pageData={pageData}
        // contactUsHeading={contactUsHeading}
        // contactUsPara={contactUsPara}
        // heroLearnContactUs={heroLearnContactUs}
      />
      <SmartInventoryReporting
        pageData={pageData}
        // InventoryReportingHeadingBlueLatter={
        //   InventoryReportingHeadingBlueLatter
        // }
        // InventoryReportingHeadingBlackLatter={
        //   InventoryReportingHeadingBlackLatter
        // }
        // InventoryReportingPara={InventoryReportingPara}
        // InventoryReportingItems={InventoryReportingItems}
        // learnMoreInventoryReporting={learnMoreInventoryReporting}
      />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home;
