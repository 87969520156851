import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import columns1 from "../../Assets/Images/columns1.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAddNewColumnPreferenceStaffMutation } from "../../Feature/api/StaffApi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { error } from "../../Pages/schedulerModule/utils";
import { DataNotFound, customSnackBar, warning } from "../../utils";
import { postRequest } from "../request";
import { useEffect } from "react";
import instance from "../../utils/utils";
import { getAllStaff } from "../../Pages/Dashboard/MainComponent/store/staffActions";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#9F9E9E",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "28px" ,
    textAlign: "left",
    paddingRight: "0px",
    backgroundColor: "#FBFBFB",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#434343",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "28.8px" /* 200% */,
    // textAlign: "center"
    textAlign: "-webkit-center",
  },
  // "&:not(:last-child)": {
  //   borderRight: "1px solid #DCDCDC", // Add right border to all cells except the last one
  //   borderLeft: "1px solid #DCDCDC", // Add left border to all cells except the first one
  // },

  // "&:first-of-type": {
  //   // borderTop: "1px solid #DCDCDC",
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    width: "100%",
  },
  // "&:not(:last-child) td": {
  //   borderBottom: "1px solid #ccc", // Add bottom border to all cells in the row except the last one
  //   borderRight: "1px solid #ccc", // Add right border to all cells in the row except the last one
  //   borderLeft: "1px solid #ccc", // Add left border to all cells in the row except the first one
  // },
  // "&:last-child td": {
  //   borderBottom: "1px solid #ccc", // Add bottom border to all cells in the last row
  //   borderRight: "1px solid #ccc", // Add right border to all cells in the last row
  //   borderLeft: "1px solid #ccc", // Add left border to all cells in the last row
  // },
}));
// const cellStyle = {
//   borderRight: '1px solid rgba(224, 224, 224, 1)',
// };

export default function StaffListTable(props) {
  const date = new Date();
  const history = useHistory();
  const business = useSelector((state) => state.Business?.selectedBusiness);
  const store = useSelector((state) => state?.staffReducer);
  var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const [loading, setLoading] = useState(false);
  const bussinessId = business?.id;
  const selectedBusines = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const [staffSortedData, setStaffSortedData] = useState([]);

  const { columns, staffData, staffSortedData, updateState, permission ,itemsPerPage,page,setIsLoading} =
    props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedData, setSelecteData] = useState();
  const [listData, setListData] = useState([]);
  const [frozenColumns, setFrozenColumns] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [actionIndex, setActionIndex] = useState();
  const [
    updateColumnPreferrence,
    { isLoading: isUpdateColumnPreferrenceLoading },
  ] = useAddNewColumnPreferenceStaffMutation();

  const handleMenuClick = (event, data) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row.id);
    setSelecteData(data);
  };

  const handleMenuActionClick = (event, data, rowIndex) => {
    setActionData(data);

    setActionIndex(rowIndex);
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(columnValue);
    // setSelecteData(data);
  };
  const handleMenuClose = () => {
    setOpen(false);
    setOpenAction(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAddClick = () => {
    history.push("/edit-faculty", { state: listData });
    // history.push(`staff-list/${bussinessId}/columns-list`);

    // navigate("/staff/edit-staff", { state: listData });
  };

  const stickyIndices = staffData.reduce((acc, column, index) => {
    if (column.isSticky) acc.push(index);
    return acc;
  }, []);

  const shownColumns = listData.filter((column) => column.isShown);

  const sortedData = shownColumns.sort((a, b) => a.position - b.position);
  const stickyObjects = sortedData.filter((object) => object.isSticky);
  const nonStickyObjects = sortedData.filter((object) => !object.isSticky);

  const sortedStickyObjects = stickyObjects.sort(
    (object1, object2) => object1.position - object2.position
  );
  const sortedNonStickyObjects = nonStickyObjects.sort(
    (object1, object2) => object1.position - object2.position
  );

  const newArray = sortedStickyObjects.concat(sortedNonStickyObjects);
  // const maxFirstNameLength = Math.max(
  //   ...newArray.map((row) =>
  //     row.columnValue.map(
  //       (column, rowIndex) => column.columnValue[rowIndex].values.length
  //     )
  //   )
  // );
  const handleHideClick = async (columnName) => {
    const updatedColumns = listData.map((column) =>
      column.id === selectedData.id ? { ...column, isShown: false } : column
    );

    updateState(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, ...rest } = item;
      return rest;
    });

    const classes = dataWithoutServiceNameAndColumnName
      .map((i) => {
        if (i.isShown === true) {
          return { columnId: i.id, isSticky: i.isSticky };
        }
        return null;
      })
      .filter((it) => it !== null);

    const payload = {
      entityId: userID,
      columns: classes,
      createdBy: userID,
    };
    const result = await postRequest(
      `/staff_svc/pv/ListingPreferences/addUserListingPreference`,
      payload
    );
    if (result?.code === 0) {
      setListData(updatedColumns);
      console.log(listData,updatedColumns,"updatedColumnsupdatedColumnsupdatedColumns")
      // dispatch(
      //   getAllStaff({
      //     id: business?.id,
      //     userid: parseInt(userID),
      //     page: page,
      //     pageSize: itemsPerPage,
      //     // setIsLoading,
      //   })
      // );
      // alert("Columns list updated successfully");
      // history.push(`/staff-list`);
      // updateColumnPreferrence(payload);
      // const code = result.data.code;
      // const message = result.data.message;
    }
  };
  console.log(store,"listDatalistDatalistDatalistData")
  useEffect(() => {
    if (store?.allStaff) {
      const sorted = [...store?.allStaff].sort(
        (a, b) => a?.position - b?.position
      );

      if (
        store?.filterText === "" &&
        store?.firstNameCustomer === "" &&
        store?.lastName === "" &&
        store?.email === "" &&
        store?.mobileNumber === ""
      ) {
        setListData(sorted);
      } else {
        setListData(store?.staffSearchList);
      }
    }
    // searchIndexForValue();
  }, [store?.allStaff, store?.staffSearchList]);

  const handleFreezeClick = async (columnName) => {
    if (
      permission.canUpdateStaffPrefernces ||
      permission.canUpdateStaffPrefernces === null ||
      permission.canUpdateStaffPrefernces === undefined
    ){
    const updatedColumns = listData.map((column) =>
      column.id === selectedData.id ? { ...column, isSticky: true } : column
    );

    setListData(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, ...rest } = item;
      return rest;
    });

    const classes = dataWithoutServiceNameAndColumnName
      .map((i) => {
        if (i.isShown === true) {
          return { columnId: i.id, isSticky: i.isSticky };
        }
        return null;
      })
      .filter((it) => it !== null);

    const payload = {
      entityId: userID,
      columns: classes,
      createdBy: userID,
    };
    const result =
      //  await updateColumnPreferrence(payload);
      await postRequest(
        `/staff_svc/pv/ListingPreferences/addUserListingPreference`,
        payload
      );
    if (result?.code === 0) {
      // const code = result.data.code;
      // const message = result.data.message;
      // if (code === 0) {

      setListData(updatedColumns);
      console.log(updatedColumns,"resultresultresultresult")

      // dispatch(
      //   getAllStaff({
      //     id: business?.id,
      //     userid: parseInt(userID),
      //     page: page,
      //     pageSize: itemsPerPage,
      //     // setIsLoading,
      //   })
      // );
    } 
  }else{
    customSnackBar("You haven't Permssion to Update Prefrence")
  }
  };
  const handleUnFreezeClick = async (columnName) => {
    const updatedColumns = listData.map((column) =>
      column.id === selectedData.id ? { ...column, isSticky: false } : column
    );

    setListData(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, ...rest } = item;
      return rest;
    });

    const classes = dataWithoutServiceNameAndColumnName
      .map((i) => {
        if (i.isShown === true) {
          return { columnId: i.id, isSticky: i.isSticky };
        }
        return null;
      })
      .filter((it) => it !== null);

    const payload = {
      entityId: userID,
      columns: classes,
      createdBy: userID,
    };
    const result = await postRequest(
      `/staff_svc/pv/ListingPreferences/addUserListingPreference`,
      payload
    );
    if (result?.code === 0) {
      // const code = result.data.code;
      // const message = result.data.message;
      // if (code === 0) {
        setListData(updatedColumns);
      console.log(updatedColumns,"resultresultresultresult")
      // dispatch(
      //   getAllStaff({
      //     id: business?.id,
      //     userid: parseInt(userID),
      //     page: page,
      //     pageSize: itemsPerPage,
      //     // setIsLoading,
      //   })
      // );
    } else {
    }
  };
  const handleProfileClick = () => {
    if (
      permission.canViewStaffProfile ||
      permission.canViewStaffProfile === null ||
      permission.canViewStaffProfile === undefined
    ) {
      let rowIndex = actionIndex;
      const dumy = actionData.filter(
        (column, index) => column.columnName === "id"
      );
      if (dumy.length === 0) {
        alert("Please Add ID in Prefrence");
      } else {
        const columnValue = dumy[0].columnValue[rowIndex] || {};
        const idValue = columnValue.values;

        if (idValue === undefined) {
          alert("Add ProviderID and ID to User Prefrence");
        } else {
          history.push(`/staff-profile-view/${columnValue?.values}`);
        }
      }
    } else {
      customSnackBar("You haven't Permission to view the Profile");
    }
  };
  const handleScheduleClick2 = () => {
    let rowIndex = actionIndex;
    const dumy = actionData.filter(
      (column, index) => column.columnName === "providerId"
    );
    const columnValue = dumy[0].columnValue[rowIndex] || {};
    const idValue = columnValue.values;
    history.push(`/staff-profile-view/${columnValue?.values}`);
  };
  let appointmentData = {};


const handleScheduleClick = async () => {
  if (
    permission.canSeeStaffSchedule ||
    permission.canSeeStaffSchedule === null ||
    permission.canSeeStaffSchedule === undefined
  ) {
    let rowIndex = actionIndex;

    const dumy = actionData.filter(
      (column, index) => column?.columnName === "providerId"
    );

    const columnValue = dumy[0]?.columnValue[rowIndex] || {};
    const rowId = columnValue.values;

    //profile id
    const dumy1 = actionData.filter(
      (column, index) => column.columnName === "id"
    );
    const columnValue1 = dumy1[0]?.columnValue[rowIndex] || {};

    if (
      columnValue1.values === undefined ||
      columnValue.values === undefined
    ) {
      alert("Add ProviderID and ID to User Preference");
    } else {
      if (+columnValue.values === 0) {
        history.push(`/add-provider/${columnValue1.values}+${selectedBusines?.id}`);
      } else {
        try {
          const response = await instance.get(
            `/staff_svc/pv/staff/getStaffsById?id=${columnValue1.values}`
          );
          if (response.status >= 200 && response.status < 300) {
            localStorage.setItem(
              "StaffRecord",
              JSON.stringify(response?.data?.result?.staff[0])
            );

            // Fetch provider data
            setLoading(true); // Show loading indicator

            const appointmentData = {
              date: date,
              actionState: "new",
            };

            // Redirect to addScheduler page
            history.push("/addScheduler");
          } else {
            // Handle unsuccessful response
            customSnackBar("Failed to fetch provider data");
          }
        } catch (error) {
          // Handle any errors
          console.error("Error fetching provider data:", error);
          customSnackBar("An error occurred while fetching provider data");
        } finally {
          setLoading(false); // Hide loading indicator
        }
      }
    }
  } else {
    customSnackBar("You don't have permission to view schedules");
  }
};

  return (
    <Box
    sx={{
      width: "100%",
      maxHeight: "350px",
      overflow: "auto",
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
      borderTopLeftRadius: "8px",
      margin: "0 auto",
    }}
    >
      <TableContainer component={Paper} h>
        <Table size="small" aria-label="a dense table" component="table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                sx={{
                  width: "40px",
                  position: "sticky",
                  left: 0,
                  zIndex: 10,
                  paddingX: "4px",
                  textAlign: "center",
                  // backgroundColor: "#fff",
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    // maxWidth: "40px",
                    borderRight: "0.7px solid rgba(220, 220, 220, 1)", // Add a right border
                    borderTop: "5px solid #F5BF00",
                  },
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    right: "0px", // Adjust this value as needed for the border width
                    height: "100%",
                    borderRight: "0.7px solid rgba(220, 220, 220, 1)", // Add a right border
                  },
                }}
              >
                <img src={columns1} alt="upload Logo" />
                {/* Ac */}
              </StyledTableCell>

              {newArray.map((columnName, index) => {
                // {
                // }
                return (
                  <StyledTableCell
                  key={columnName.columnName}
                  sx={{
                    width: columnName.isSticky === true ? "40px" : "40px",
                    margin: "0px",
                    paddingY: "2px",
                    position:
                      columnName.isSticky === true ? "sticky" : undefined,
                      left: !index? '25px' : index * 85 + 'px',
                    zIndex: columnName.isSticky === true ? 2 : -1,
                    backgroundColor:
                      columnName.isSticky === true ? "#fff" : "#fff",
                    borderRadius:
                      columnName.isSticky === true ? "5px" : undefined,
                   
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
               
                      borderTop:
                        columnName.isSticky === true
                          ? "5px solid #F5BF00"
                          : undefined,
                    },
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      top: "0",
                      right: "0px",
                      height: "100%",
                    },
                  }}
                  >
                    <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textTransform="capitalize"
                    minWidth="50px"
                    >
                      {columnName.columnName}
                      <Box component="span"/>
                      <MoreVertIcon
                        // sx={{ marginLeft: "30px" }}
                        fontSize="small"
                        onClick={(event) => handleMenuClick(event, columnName)}
                      />
                    </Box>
                  </StyledTableCell>
                );
              })}

              {/* <StyledTableCell
                sx={{ margin: "0px", paddingY: "2px" }}
                align="right"
              >
                <IconButton size="small" onClick={handleAddClick}>
                  <ControlPointIcon />
                </IconButton>
              </StyledTableCell> */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {newArray?.at(0)?.columnValue?.length !== 0 ? (
              newArray?.at(0)?.columnValue?.map((_, rowIndex) => {
                return (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell
                      sx={{
                        width: "40px",
                        position: "sticky",
                        left: 0,
                        zIndex: 10,
                        paddingX: "4px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          // maxWidth: "40px",
                          borderRight: "0.7px solid rgba(220, 220, 220, 1)",
                          // borderTop: "5px solid #F5BF00",
                        },
                        "&::after": {
                          content: "''",
                          position: "absolute",
                          top: "0",
                          right: "0px",
                          height: "100%",
                          borderRight: "0.7px solid rgba(220, 220, 220, 1)",
                        },
                      }}
                      align="right"
                    >
                      <MoreVertIcon
                        onClick={(event) =>
                          handleMenuActionClick(event, newArray, rowIndex)
                        }
                        fontSize="small"
                        sx={{ color: "#000" }}
                      />
                    </StyledTableCell>
                    {newArray?.map((column, colIndex) => {
                      return column?.status === "status" ? (
                        <StyledTableCell
                          key={column.columnName}
                          align="center"
                          sx={{
                            background:
                              column.isShown &&
                              column.columnValue[rowIndex].values
                                ? "#B8D693"
                                : "#E9B67A",
                            width: column.isSticky === true ? "50px" : "50px",
                            margin: "0px",
                            paddingY: "2px",
                            position:
                              column.isSticky === true ? "sticky" : undefined,
                            // left:
                            //   colIndex === 0
                            //     ? colIndex * 100 + 28 + "px"
                            //     : colIndex === 2
                            //     ? colIndex * 100 + 3 + "px"
                            //     : colIndex * 100 + 20 + "px",
                            zIndex: column.isSticky === true ? 2 : -1,
                            backgroundColor:
                              column.isSticky === true ? "#fff" : undefined,
                            borderRadius:
                              column.isSticky === true ? "5px" : undefined,
                            borderRight:
                              column.isSticky === true
                                ? undefined
                                : "0.7px solid rgba(220, 220, 220, 1)",
                            "&::before": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                            "&::after": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              right: "0px",
                              height: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                          }}
                        >
                          <Typography
                            component="p"
                            color="#FFF"
                            fontWeight="500"
                            fontFamily="Roboto"
                            fontSize="14px"
                          >
                            {column.isShown &&
                            column.columnValue[rowIndex].values
                              ? "Active"
                              : "InActive"}
                          </Typography>

                          {/* <SwitchOne
                          {...props}
                          size="small"
                          // defaultChecked={row.isActive ? true : false}
                          defaultChecked={
                            column.isShown && column.columnValue[rowIndex].values
                          }
                          // onClick={(event) => handleStatusClick(event, row)}
                        /> */}
                        </StyledTableCell>
                      ) : column.status === "image" ? (
                        <StyledTableCell
                          key={column.columnName}
                          // align="center"
                          sx={{
                            width: column.isSticky === true ? "50px" : "50px",
                            margin: "0px",
                            paddingY: "2px",
                            position:
                              column.isSticky === true ? "sticky" : undefined,
                            // left:
                            //   colIndex === 0
                            //     ? colIndex * 100 + 28 + "px"
                            //     : colIndex === 2
                            //     ? colIndex * 100 + 3 + "px"
                            //     : colIndex * 100 + 20 + "px",
                            zIndex: column.isSticky === true ? 2 : -1,
                            backgroundColor:
                              column.isSticky === true ? "#fff" : undefined,
                            borderRadius:
                              column.isSticky === true ? "5px" : undefined,
                            borderRight:
                              column.isSticky === true
                                ? undefined
                                : "0.7px solid rgba(220, 220, 220, 1)",
                            "&::before": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                            "&::after": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              right: "0px",
                              height: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                          }}
                        >
                          <Avatar
                            component="imgae"
                            alt="Remy Sharp"
                            src={
                              column.isShown
                                ? column.columnValue[rowIndex].values
                                : ""
                            }
                            sx={{
                              width: "24px",
                              height: "24px",
                              textAlign: "center",
                            }}
                          />
                        </StyledTableCell>
                      ) : column.status === "email" ? (
                        <StyledTableCell
                          key={column.columnName}
                          align="center"
                          sx={{
                            width: column.isSticky === true ? "50px" : "50px",
                            margin: "0px",
                            paddingY: "2px",
                            position:
                              column.isSticky === true ? "sticky" : undefined,
                            // left:
                            //   colIndex === 0
                            //     ? colIndex * 100 + 28 + "px"
                            //     : colIndex === 2
                            //     ? colIndex * 100 + 3 + "px"
                            //     : colIndex * 100 + 20 + "px",
                            zIndex: column.isSticky === true ? 2 : -1,
                            backgroundColor:
                              column.isSticky === true ? "#fff" : undefined,
                            borderRadius:
                              column.isSticky === true ? "5px" : undefined,
                            borderRight:
                              column.isSticky === true
                                ? undefined
                                : "0.7px solid rgba(220, 220, 220, 1)",
                            "&::before": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                            "&::after": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              right: "0px",
                              height: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                          }}
                        >
                          {column.isShown
                            ? column.columnValue[rowIndex].values
                            : ""}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          key={column.columnName}
                          align="center"
                          sx={{
                            width: column.isSticky === true ? "50px" : "50px",
                            margin: "0px",
                            paddingY: "2px",
                            position:
                            column.isSticky === true ? "sticky" : undefined,
                            left: !colIndex? '25px' : colIndex * 85 + 'px',
                            // left:
                            //   colIndex === 0
                            //     ? colIndex * 100 + 28 + "px"
                            //     : colIndex === 2
                            //     ? colIndex * 100 + 3 + "px"
                            //     : colIndex * 100 + 20 + "px",
                            zIndex: column.isSticky === true ? 2 : -1,
                            backgroundColor:
                              column.isSticky === true ? "#fff" : undefined,
                            borderRadius:
                              column.isSticky === true ? "5px" : undefined,
                            borderRight:
                              column.isSticky === true
                                ? undefined
                                : "0.7px solid rgba(220, 220, 220, 1)",
                            "&::before": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                            "&::after": {
                              content: "''",
                              position: "absolute",
                              top: "0",
                              right: "0px",
                              height: "100%",
                              borderRight:
                                column.isSticky === true
                                  ? "0.7px solid rgba(220, 220, 220, 1)"
                                  : "0.7px solid rgba(220, 220, 220, 1)",
                            },
                          }}
                        >
                          {column.isShown
                            ? typeof column.columnValue[rowIndex].values ===
                              "string"
                              ? column.columnValue[rowIndex].values
                              : Array.isArray(
                                  column.columnValue[rowIndex].values
                                )
                              ? column.columnValue[rowIndex].values.map(
                                  (el) => <div>{el}</div>
                                )
                              : JSON.stringify(
                                  column.columnValue[rowIndex].values
                                )
                            : ""}
                        </StyledTableCell>
                      );
                    })}

                    {/* <StyledTableCell
               
                  ></StyledTableCell> */}
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={newArray.length + 1} align="center">
                  <DataNotFound />{" "}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {open && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleHideClick}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
              Hide
            </Box>
          </MenuItem>
          <MenuItem>
            {selectedData.isSticky ? (
              <Typography onClick={handleUnFreezeClick}>UnFreeze</Typography>
            ) : (
              <Typography onClick={handleFreezeClick}>Freeze</Typography>
            )}
          </MenuItem>
        </Menu>
      )}
      {openAction && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleProfileClick}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
              Profile
            </Box>
          </MenuItem>
          <MenuItem onClick={handleScheduleClick}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* {selectedData.isSticky ? ( */}
              <Typography>Schedule</Typography>
              {/* ) : (
              <Typography >Make Provider</Typography>
            )}   */}
            </Box>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
}
