import React from "react";
import "./Banner.css";

export const Banner = () => {
  return (
    <div className="bannerContainer">
      <p className="bannerHeading">
        <span>Get Ahead with </span>
        <span className="bannerBoldHeading">Cutting-edge ERP Technology</span>
      </p>
      <p className="bannerInfo">See how Plexaar can uplift your business</p>
      <div>
        <a href="/login">
          <button>Get Started</button>
        </a>
      </div>
    </div>
  );
};
