// import React, { useState, useEffect, useCallback } from "react";
// import "./listpage.css";
// import { customerAxios } from "../../axios";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableRow from "@mui/material/TableRow";
// import "../../assets/scheduler/css/calendar.css";
// import { openToast } from "../store/alertSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import PhoneInput from "react-phone-input-2";
// import { customSnackBar } from "../../../utils";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import {
//   getAllCustomer,
//   searchCustomerList,
// } from "../../../Pages/Dashboard/MainComponent/store/staffActions";
// import debounce from "lodash/debounce";
// import { TableHead } from "@mui/material";
// import AddCustomerModal from "./AddCustomerModal";

// const Listplexaar = ({
//   handleSelectedCustomer,
//   handleSubmit,
//   isModalOpen,
//   closeModal,
//   openModal,
//   loading,
// }) => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.Auth?.alluserdataafterlogin);
//   const business = useSelector((state) => state.Business?.selectedBusiness);
//   const [filterFirstName, setFilterFirstName] = useState("");
//   const [filterLastName, setFilterLastName] = useState("");
//   const [filterEmail, setFilterEmail] = useState("");
//   const [listData, setListData] = useState([]);

//   const [filterContactNumber, setFilterContactNumber] = useState("");
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [customers, setCustomers] = useState([]);
//   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   const history = useHistory;
//   const userid = useSelector((state) => state.Auth.userloginsuccessyasir.id);
//   const [itemsPerPage, setItemsPerPages] = useState(10);
//   const [page, setPage] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const store = useSelector((state) => state?.staffReducer);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (
//           filterFirstName === "" &&
//           filterLastName === "" &&
//           filterEmail === "" &&
//           filterContactNumber === ""
//         ) {
//           setIsLoading(true);

//           // Dispatch the async thunk and wait for it to complete
//           await dispatch(
//             getAllCustomer({
//               id: business?.id,
//               userid: parseInt(userid),
//               page,
//               pageSize: itemsPerPage,
//               setIsLoading,
//             })
//           );
//           setIsLoading(false);
//         } else {
//           // Dispatch the async thunk and wait for it to complete
//           await dispatch(
//             searchCustomerList({
//               id: business?.id,
//               userid: parseInt(userid),
//               text: store?.filterTextCustomer,
//               firstName: filterFirstName ? filterFirstName : "",
//               lastName: filterLastName ? filterLastName : "",
//               email: filterEmail ? filterEmail : "",
//               number: filterContactNumber ? filterContactNumber : "",
//             })
//           );
//         }
//       } catch (error) {
//         // Handle errors if needed
//         console.error("Error:", error);
//       } finally {
//       }
//     };

//     fetchData();
//   }, [
//     business?.id,
//     userid,
//     dispatch,
//     page,
//     itemsPerPage,
//     filterFirstName,
//     filterLastName,
//     filterEmail,
//     filterContactNumber,
//   ]);

//   const isAddButtonDisabled =
//     filterFirstName?.length === 0 ||
//     filterLastName?.length === 0 ||
//     !emailPattern.test(filterEmail) ||
//     filterContactNumber?.length === 0 ||
//     filterContactNumber?.length < 9;

//   const handleSearchFirstName = useCallback(
//     (inputValue) => {
//       setFilterFirstName(inputValue);
//     },
//     [dispatch]
//   );
//   const handleDebouncedSearch = useCallback(
//     debounce(handleSearchFirstName, 0),
//     [handleSearchFirstName]
//   );
//   const handleSearchLastName = useCallback(
//     (inputValue) => {
//       setFilterLastName(inputValue);
//     },
//     [dispatch]
//   );
//   const handleDebouncedSearchLastName = useCallback(
//     debounce(handleSearchLastName, 0),
//     [handleSearchLastName]
//   );

//   const handleSearchEmail = useCallback(
//     (inputValue) => {
//       setFilterEmail(inputValue);
//     },
//     [dispatch]
//   );
//   const handleDebouncedSearchEmail = useCallback(
//     debounce(handleSearchEmail, 0),
//     [handleSearchEmail]
//   );

//   const handleSearchContactNumber = useCallback(
//     (inputValue) => {
//       setFilterContactNumber(inputValue);
//     },
//     [dispatch]
//   );
//   const handleDebouncedSearchContactNo = useCallback(
//     debounce(handleSearchContactNumber, 0),
//     [handleSearchContactNumber]
//   );

//   const handleToggle = (customerId) => {
//     setSelectedItems((prevSelectedItems) => {
//       if (prevSelectedItems.includes(customerId)) {
//         return prevSelectedItems?.filter((id) => id !== customerId);
//       } else {
//         return [...prevSelectedItems, customerId];
//       }
//     });
//   };
//   useEffect(() => {
//     if (store?.allCustomer) {
//       const sorted = [...store?.allCustomer].sort(
//         (a, b) => a?.position - b?.position
//       );
//       if (
//         filterFirstName === "" &&
//         filterLastName === "" &&
//         filterEmail === "" &&
//         filterContactNumber === ""
//       ) {
//         setListData([...sorted]);
//       } else {
//         setListData(store?.customerSearchList);
//       }
//       // Create an empty object to store unique column values
//     }

//     // searchIndexForValue();
//   }, [store?.allCustomer, store?.customerSearchList]);

//   const filteredItems = customers
//     ? customers?.filter((item) => {
//         const firstNameMatch = item.customerFirstName
//           ?.toLowerCase()
//           .includes(filterFirstName.toLowerCase());
//         const lastNameMatch = item.customerLastName
//           ?.toLowerCase()
//           .includes(filterLastName.toLowerCase());
//         const emailMatch = item.primaryEmail
//           ?.toLowerCase()
//           .includes(filterEmail.toLowerCase());
//         const contactNumberMatch = item.primaryMobile?.includes(
//           filterContactNumber ? filterContactNumber : ""
//         );
//         return (
//           firstNameMatch && lastNameMatch && emailMatch && contactNumberMatch
//         );
//       })
//     : [];

//   const labelStyle = {
//     color: "#8A8A8A",
//     fontFamily: "Poppins",
//     fontSize: "13px",
//     fontStyle: "normal",
//     fontWeight: 400,
//   };

//   const inputStyle = {
//     color: "#1B1B1B",
//     fontFamily: "Poppins",
//     fontSize: "14px",
//     fontStyle: "normal",
//     fontWeight: "400",
//   };

//   const listFontStyle = {
//     color: "#A4A4A4",
//     fontFamily: "Poppins",
//     fontSize: "15px",
//     fontStyle: "normal",
//     fontWeight: 400,
//   };

//   return (
//     <>
// {isModalOpen ? (
//   <AddCustomerModal
//     isModalOpen={isModalOpen}
//     closeModal={closeModal}
//     openModal={openModal}
//     handleSubmit={handleSubmit}
//     loading={loading}
//   />
// ) : null}
//       <div className="d-flex flex-column">
//         <div className="d-flex justify-content-between align-items-center mb-2">
//           <div
//             style={{
//               color: "#424242",
//               fontFamily: "Roboto",
//               fontSize: "15px",
//               fontStyle: "normal",
//               fontWeight: "500",
//             }}
//           >
//             Search Customer
//           </div>
//           <div>
//             <button
//               style={{
//                 borderRadius: "8px",
//                 backgroundColor: "#FFD705",
//                 padding: "4px 20px",
//                 color: "#530",
//                 textAlign: "center",
//                 fontFamily: "Poppins",
//                 fontSize: "14px",
//                 fontStyle: "normal",
//                 fontWeight: "400",
//               }}
//               onClick={openModal}
//               // disabled={isAddButtonDisabled || filteredItems.length > 0}
//             >
//               Add Customer
//             </button>
//           </div>
//         </div>
//         <div
//           className="w-100 d-flex mb-2 pb-1"
//           style={{
//             borderRadius: "8px",
//             border: "0.6px solid #DCDCDC",
//             backgroundColor: "white",
//           }}
//         >
//           <div className="form-group w-100">
//             <label htmlFor="input1" className="input1" style={labelStyle}>
//               First Name
//             </label>
//             <input
//               type="text"
//               className="form-control"
//               id="input1"
//               style={inputStyle}
//               value={filterFirstName}
//               onChange={(e) => {
//                 const inputValue = e.target.value;
//                 handleDebouncedSearch(inputValue);
//               }}
//               // onChange={handleDebouncedSearch}
//               // placeholder="John"
//             />
//           </div>
//           <div
//             style={{
//               backgroundColor: "#DCDCDC",
//               width: "0.6px",
//             }}
//             className="my-1"
//           />
//           <div className="form-group w-100">
//             <label htmlFor="input1" className="input1" style={labelStyle}>
//               Last Name
//             </label>
//             <input
//               type="text"
//               className="form-control"
//               id="input1"
//               value={filterLastName}
//               style={inputStyle}
//               onChange={(e) => {
//                 const inputValue = e.target.value;
//                 handleDebouncedSearchLastName(inputValue);
//               }}
//               // onChange={handleSearchLastName}
//               // placeholder="Wick"
//             />
//           </div>
//           <div
//             style={{
//               backgroundColor: "#DCDCDC",
//               width: "0.6px",
//             }}
//             className="my-1"
//           />
//           <div className="form-group w-100">
//             <label htmlFor="input1" className="input1" style={labelStyle}>
//               Email
//             </label>
//             <input
//               type="email"
//               className="form-control "
//               id="input1"
//               value={filterEmail}
//               style={inputStyle}
//               onChange={(e) => {
//                 const inputValue = e.target.value;
//                 handleDebouncedSearchEmail(inputValue);
//               }}
//               // onChange={handleSearchEmail}
//               // placeholder="john@xyz.abc"
//             />
//           </div>
//           <div
//             style={{
//               backgroundColor: "#DCDCDC",
//               width: "0.6px",
//             }}
//             className="my-1"
//           />
//           <div className="form-group w-100">
//             <label htmlFor="input1" className="input1" style={labelStyle}>
//               Contact
//             </label>
//             {/* <PhoneInput
//             defaultMask="........."
//             renderStringAsFlag={filterContactNumber === "" ? " ": ""}
//                   //  inputStyle={{color:'green'}}
//                   //  containerStyle={{backgroundColor:"#000"}}
//                   buttonStyle={{ borderRight: "1px solid #DCDCDC" }}
//                   specialLabel=""
//                   //  dropdownStyle={{height:'22px'}}
//                   inputStyle={{
//                     width: "95%",
//                     height: "22px",
//                     color: " #616161",
//                     border: "none",
//                     borderRadius: "8px",
//                     paddingLeft: "75px",
//                     ...inputStyle,
//                   }}
//                   // disableDropdown={true}
//                   showDropdown={false}
//                   country={filterContactNumber ? undefined : 'uk'}
//                   inputProps={{
//                     onFocus: (e) => {
//                       // e.target.style.backgroundColor = "white";
//                     },
//                     onBlur: (e) => {
//                       e.target.style.backgroundColor = "";
//                     },
//                   }}
//                   value={filterContactNumber}
//                   onChange={setFilterContactNumber}
//                   // placeholder="4423221321"
//                   // className="phone-input"
//                 /> */}
//             <input
//               type="number"
//               className="form-control"
//               id="input1"
//               value={filterContactNumber}
//               style={inputStyle}
//               onChange={(e) => {
//                 const inputValue = e.target.value;
//                 handleDebouncedSearchContactNo(inputValue);
//               }}
//               // onChange={handleSearchContactNumber}
//               // placeholder="821371237"
//             />
//           </div>
//         </div>
//         <div
//           className="w-100 ps-1"
//           style={{
//             boxShadow:
//               "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
//             borderRadius: "10px",
//             maxHeight: "280px",
//             backgroundColor: "#fff",
//             overflow: "auto",
//           }}
//         >
//           <TableContainer>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell className="text-black" style={listFontStyle}>
//                     First Name
//                   </TableCell>
//                   <TableCell className="text-black" style={listFontStyle}>
//                     Last Name
//                   </TableCell>
//                   <TableCell className="text-black" style={listFontStyle}>
//                     Email
//                   </TableCell>
//                   <TableCell className="text-black" style={listFontStyle}>
//                     Contact
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {listData[0]?.columnValue.length !== 0 ? (
//                   listData[0]?.columnValue.map((_, rowIndex) => (
//                     <TableRow
//                     onClick={() => {
//                       const customerData = listData.reduce((acc, column, colIndex) => {
//                         const cellValue = column.columnName;
//                         acc[cellValue] = column.columnValue[rowIndex]?.values || "";
//                         return acc;
//                       }, {});

//                       handleSelectedCustomer(customerData);
//                     }}
//                       key={rowIndex}
//                     >
//                       {listData.map((column, colIndex) => {
//                         const cellValue =
//                           column?.columnName === "customerFirstName"
//                             ? "First Name"
//                             : column?.columnName === "customerLastName"
//                             ? "Last Name"
//                             : column?.columnName === "primaryEmail"
//                             ? "Email"
//                             : column?.columnName === "primaryMobile"
//                             ? "Contact"
//                             : "";

//                         return (
//                           <TableCell
//                             // onClick={() =>
//                             //   handleSelectedCustomer(column?.columnValue[rowIndex])
//                             // }
//                             // onClick={() => handleSelectedCustomer(column?.columnValue[rowIndex]?.values)}
//                             key={colIndex}
//                             className="py-0"
//                           >
//                             <p
//                               style={listFontStyle}
//                               className=" m-0 p-0 margin_adjust_new"
//                             >
//                               {cellValue
//                                 ? column?.columnValue[rowIndex]?.values
//                                 : ""}
//                             </p>
//                           </TableCell>
//                         );
//                       })}
//                       <TableCell
//                         className={`${
//                           listData.length - 1 === rowIndex
//                             ? "p-0 text-center pt-1"
//                             : ""
//                         }`}
//                       ></TableCell>
//                     </TableRow>
//                   ))
//                 ) : (
//                   <TableRow>
//                     <TableCell
//                       colSpan={listData.length + 1}
//                       style={listFontStyle}
//                     >
//                       No Customer Found
//                     </TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* <TableContainer>
//             <Table>
//               <TableBody>
//                 {filteredItems.length ? (
//                   filteredItems.map((customer, index) => {
//                     return (
//                       <TableRow
//                         className="pointer"
//                         onClick={() => handleSelectedCustomer(customer)}
//                         key={index}
//                       >
//                         <TableCell
//                           style={{
//                             borderRight: "0.6px solid #ddd",
//                             lineHeight: "2px",
//                             width: "25%",
//                           }}
//                         >
//                           <img
//                             className="rounded-circle me-2"
//                             style={{ width: "25px", height: "25px" }}
//                             src="https://giovannicosmetics.com/wp-content/uploads/2020/04/For-Men.jpg"
//                             alt="Profile"
//                           />
//                           <span style={listFontStyle}>
//                             {customer.customerFirstName}
//                           </span>
//                         </TableCell>
//                         <TableCell
//                           style={{
//                             borderRight: "0.6px solid #ddd",
//                             width: "25%",
//                           }}
//                         >
//                           <div style={listFontStyle}>
//                             {customer.customerLastName}{" "}
//                           </div>
//                         </TableCell>
//                         <TableCell
//                           style={{
//                             borderRight: "1px solid #ddd",
//                             width: "25%",
//                           }}
//                         >
//                           <div style={listFontStyle}>
//                             {customer.primaryEmail}{" "}
//                           </div>
//                         </TableCell>
//                         <TableCell
//                           style={{
//                             borderRight: "1px solid #ddd",
//                             width: "25%",
//                           }}
//                         >
//                           <div style={listFontStyle}>
//                             {customer.primaryMobile}
//                           </div>
//                         </TableCell>
//                       </TableRow>
//                     );
//                   })
//                 ) : (
//                   <TableRow>
//                     <TableCell style={listFontStyle}>
//                       No Customer Found
//                     </TableCell>
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Listplexaar;

// /* ----------------- Buttons in AddCustomer page previously ----------------- */
// // : (
// //   // Show "Add New Customer" button when not cleared
// //   <div className="row justify-content-center align-items-center my-3">
// //     <Button
// //       style={{ backgroundColor: "#5599FF", textDecoration:"capitalize" }}
// //       className="col-10 py-3 my-0"
// //       variant="contained"
// //       onClick={addCustomer}
// //       disabled={filteredItems.length > 0 ? true : false}
// //     >
// //     {filteredItems.length === 0 ? <>Add Customer</>: <>Select Customer</>}
// //     </Button>
// //   </div>
// // )

import React, { useState, useEffect, useCallback } from "react";
import "./listpage.css";
import { customerAxios } from "../../axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Avatar } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import "../../assets/scheduler/css/calendar.css";
import { openToast } from "../store/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PhoneInput from "react-phone-input-2";
import { customSnackBar } from "../../../utils";
import AddCustomerModal from "./AddCustomerModal";
import { getFormData } from "../../../Store/dynamicForm/slice";
import { ClipLoader } from "react-spinners";
import instance from "../../../utils/utils";
import { customerImageURL } from "../../../utils/imageUrlsExtension";
import CustomPhoneInput from "../../../Pages/DynamicForm/dynamicFormView/phoneInput";

const Listplexaar = ({
  handleSelectedCustomer,
  setLoading,
  isModalOpen,
  closeModal,
  openModal,
  loading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(
    (state) => state.Auth?.userloginsuccessyasir.users
  );
  const business = useSelector((state) => state.Business?.selectedBusiness);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterContactNumber, setFilterContactNumber] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const fetchFilteredData = useCallback(async () => {
    try {
      // const response = await customerAxios.post(
      //   `/pv/customerBusiness/searchCustomerBusinessUpdated`,
      //   {
      //     firstName: filterFirstName,
      //     mobile: filterContactNumber ? filterContactNumber : "",
      //     businessId: business.id,
      //     lastName: filterLastName,
      //     email: filterEmail,
      //     // text: "",
      //   }
      // );
      const response = await instance.post(
        `/customer_svc/pv/customerBusiness/searchCustomerBusinessByBusinessId`,
        {
          firstName: filterFirstName,
          mobile: filterContactNumber ? filterContactNumber : "",
          businessId: business.id,
          lastName: filterLastName,
          email: filterEmail,
          // text: "",
        }
      );
      const data = response.data;
      setCustomers(data?.result?.customerBusiness);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  }, [
    filterFirstName,
    filterLastName,
    filterEmail,
    filterContactNumber,
    business.id,
  ]);

  const isAddButtonDisabled =
    filterFirstName?.length === 0 ||
    filterLastName?.length === 0 ||
    !emailPattern.test(filterEmail) ||
    filterContactNumber?.length === 0 ||
    filterContactNumber?.length < 9;

  useEffect(() => {
    const filterContactNum = filterContactNumber ? filterContactNumber : "";
    if (
      filterFirstName === "" &&
      filterLastName === "" &&
      filterEmail === "" &&
      filterContactNum === ""
    ) {
      setCustomers([]);
    } else {
      if (!isModalOpen) {
        fetchFilteredData();
      }
    }
  }, [
    filterFirstName,
    filterLastName,
    filterEmail,
    filterContactNumber,
    // fetchAllCustomerData,
    fetchFilteredData,
  ]);

  const handleSearchFirstName = (e) => {
    setFilterFirstName(e.target.value);
  };

  const handleSearchLastName = (e) => {
    setFilterLastName(e.target.value);
  };

  const handleSearchEmail = (e) => {
    setFilterEmail(e.target.value);
  };

  const handleSearchContactNumber = (e) => {
    setFilterContactNumber(e.target.value);
  };
  const handleToggle = (customerId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(customerId)) {
        return prevSelectedItems?.filter((id) => id !== customerId);
      } else {
        return [...prevSelectedItems, customerId];
      }
    });
  };

  const filteredItems = customers
    ? customers?.filter((item) => {
        const firstNameMatch = item.customerFirstName
          ?.toLowerCase()
          .includes(filterFirstName.toLowerCase());
        const lastNameMatch = item.customerLastName
          ?.toLowerCase()
          .includes(filterLastName.toLowerCase());
        const emailMatch = item.primaryEmail
          ?.toLowerCase()
          .includes(filterEmail.toLowerCase());
        const contactNumberMatch = item.primaryMobile?.includes(
          filterContactNumber ? filterContactNumber : ""
        );
        return (
          firstNameMatch && lastNameMatch && emailMatch && contactNumberMatch
        );
      })
    : [];
  const handleSubmit = async (values) => {
    setLoading(true);
    const data1 = {
      businessId: business?.id,
      createdby: userData?.id,
      firstName: values.firstname,
      lastName: values.lastname,
      primaryEmail: values.email,
      primaryMobile: JSON.stringify(values.mobile),
    };
    try {
      // const response = await customerAxios.post(
      //   "/pv/Customers/addNewCustomer",
      //   data1
      // );
      const response = await instance.post(
        "/customer_svc/pv/Customers/addNewCustomer",
        data1
      );
      const result = response.data;
      if (result?.code === 0) {
        closeModal();
        // setAddFlag(true);
        setLoading(false);
        // if (result?.code === 0) {
        if (filterFirstName === "") {
          setCustomers((prevCustomers) => [
            {
              customerId: result?.result?.id,
              externalCustomerId: 0,
              externalPlatform: "plexaar",
              businessId: business?.id,
              customerFirstName: values.firstname,
              customerLastName: values.lastname,
              primaryEmail: values.email,
              primaryMobile: JSON.stringify(values.mobile),
            },
            ...prevCustomers,
          ]);
        }
      } else {
        setLoading(false);

        customSnackBar(result?.message);
      }
    } catch (e) {
      setLoading(false);

      customSnackBar(e);
      // dispatch(
      //   openToast({
      //     message: e,
      //     status: "error",
      //   })
      // );
    }
  };
  const addCustomer = async () => {
    // setLoadings(true)
    const customerData = {
      filterContactNumber: filterContactNumber,
      filterFirstName: filterFirstName,
      filterLastName: filterLastName,
      filterEmail: filterEmail,
    };
    openModal(customerData);
    dispatch(getFormData(business?.id));
    // history.push("/view-page");
    // const data1 = {
    //   businessId: business?.id,
    //   firstName: filterFirstName,
    //   lastName: filterLastName,
    //   primaryEmail: filterEmail,
    //   primaryMobile: filterContactNumber,
    //   createdby: userData?.id,
    // };
    // try {
    //   const response = await customerAxios.post(
    //     "/pv/Customers/addNewCustomer",
    //     data1
    //   );
    //   const result = response.data;
    //   if (result?.code === 0) {
    //     // handleSelectedCustomer(data);
    //     setFilterFirstName("");
    //     setFilterLastName("");
    //     setFilterEmail("");
    //     setFilterContactNumber("");
    // setLoadings(false)

    //     customSnackBar(result?.message);
    //     // dispatch(
    //     //   openToast({
    //     //     message: result?.message,
    //     //     status: "success",
    //     //   })
    //     // );
    //   } else {
    //     customSnackBar(result?.message);
    // setLoadings(false)

    //   }
    // } catch (e) {
    //   customSnackBar(e);
    //   dispatch(
    //     openToast({
    //       message: e,
    //       status: "error",
    //     })
    //   );
    // setLoadings(false)

    // }
  };

  const labelStyle = {
    color: "#8A8A8A",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
  };

  const inputStyle = {
    color: "#1B1B1B",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  };

  const listFontStyle = {
    color: "#595858",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  };
  const listFontStylephone = {
    color: "#A4A4A4",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center mb-2 pt-3 pb-2">
          <div
            style={{
              color: "#424242",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            Search Customer
          </div>
          <div>
            <button
              style={{
                borderRadius: "8px",
                backgroundColor:
                  // isAddButtonDisabled || filteredItems?.length > 0
                  //   ? "#f5f5f5"
                  //   :
                  "#FFD705",
                padding: "8px 30px",
                color: "#FFFFFF",
                border: "none",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
              onClick={addCustomer}
              // disabled={isAddButtonDisabled || filteredItems.length > 0}
            >
              {loadings ? (
                <ClipLoader color="#0000" size={16} />
              ) : (
                "New Customer"
              )}
            </button>
          </div>
        </div>
        <div className="d-flex pb-2">
          <div className="col-md-6 pe-1">
            <div className=" border_rad_new px-2 mt-1">
              <label className="customer-label-text mb-2">First Name</label>
              <input
                className="form-control1 customer-input-style"
                name="businessName"
                type="text"
                id="input1"
                style={inputStyle}
                value={filterFirstName}
                onChange={handleSearchFirstName}
                placeholder="Search First Name"
              />
            </div>
          </div>
          &nbsp;
          <div className="col-md-6 ps-1">
            <div className=" border_rad_new px-2 mt-1">
              <label className="customer-label-text mb-2">Last Name</label>
              <input
                className="form-control1 customer-input-style"
                type="text"
                id="input1"
                value={filterLastName}
                style={inputStyle}
                onChange={handleSearchLastName}
                placeholder="Search Last Name"
              />
            </div>
          </div>
        </div>

        <div className="d-flex pb-2">
          <div className="col-md-6 pe-1">
            <div className=" border_rad_new px-2 mt-1">
              <label className="customer-label-text mb-2">Email</label>
              <input
                className="form-control1 customer-input-style"
                type="email"
                id="input1"
                value={filterEmail}
                style={inputStyle}
                onChange={handleSearchEmail}
                placeholder="Search by Email"
              />
            </div>
          </div>
          &nbsp;
          <div className="col-md-6 ps-1">
            <div className=" border_rad_new1 px-2 mt-1">
              <label className="customer-label-text">Contact </label>
              <CustomPhoneInput />
              {/* <input
              className="form-control1 customer-input-style"
              type="number"
              id="input1"
              value={filterContactNumber}
              style={inputStyle}
              onChange={handleSearchContactNumber}
              placeholder="Search By Number"
            /> */}
            </div>
          </div>
        </div>

        <div
          className="w-100 mt-2 "
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            borderRadius: "10px",
            maxHeight: "280px",
            backgroundColor: "#fff",
            overflow: "auto",
          }}
        >
          {filteredItems.length ? (
            filteredItems.map((customer, index) => {
              return (
                <>
                  <div
                    style={{ cursor: loading ? "progress" : "pointer" }}
                    className="row pointer p-3"
                    onClick={() => handleSelectedCustomer(customer)}
                    key={index}
                  >
                    <div
                      style={{
                        // borderRight: "0.6px solid #ddd",
                        // lineHeight: "2px",
                        // width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="col-md-5 m-auto"
                    >
                      {/* {customer.customerId ?  */}
                      <Avatar
                        alt={customer.customerFirstName}
                        src={customerImageURL(customer.id)}
                        style={{ width: 30, height: 30, marginRight: "15px", backgroundColor:"#F0F0F0", color:"#B5B5B5", textTransform:"capitalize" }}
                        onError={(e) => {
                          e.target.src =
                            "https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0=";
                        }}
                      />

                      {/* <img
                            className="rounded-circle me-2"
                            style={{ width: "25px", height: "25px" }}
                            src={customerImageURL(customer.id)}
                            alt="Profile"
                          />  */}

                      {/* : */}
                      {/* <img
                            className="rounded-circle me-2"
                            style={{ width: "25px", height: "25px" }}
                            src="https://giovannicosmetics.com/wp-content/uploads/2020/04/For-Men.jpg"
                            alt="Profile"
                          /> */}
                      {/* } */}
                      <span style={listFontStyle}>
                        {customer.customerFirstName} {customer.customerLastName}
                      </span>
                    </div>
                    {/* <TableCell
                          style={{
                            borderRight: "0.6px solid #ddd",
                            width: "25%",
                          }}
                        >
                          <div style={listFontStyle}>
                            {customer.customerLastName}{" "}
                          </div>
                        </TableCell> */}
                    <div
                      style={{
                        // borderRight: "1px solid #ddd",
                        // width: "25%",
                        // borderBottom: "0.5px solid #DCDCDC",
                      }}
                      className="col-md-4 m-auto"
                    >
                      <div style={listFontStylephone}>
                        {customer.primaryEmail}{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        // borderRight: "1px solid #ddd",
                        // borderBottom: "0.5px solid #DCDCDC",
                        // width: "25%",
                      }}
                      className="col-md-3 m-auto"
                    >
                      <div style={listFontStylephone}>
                        {customer.primaryMobile}
                      </div>
                    </div>
                  </div>

                  <hr className="mx-2 m-0" style={{border:"0.5px solid #DCDCDC"}}/>
                </>
              );
            })
          ) : (
            <div className="row p-3">
              <div style={listFontStyle} className="col-md-5">
                No Customer Found
              </div>
              {/* <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell> */}
            </div>
          )}
        </div>
      </div>
      {isModalOpen ? (
        <AddCustomerModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          openModal={openModal}
          handleSubmit={handleSubmit}
          loading={loading}
          filterFirstName={filterFirstName}
          filterLastName={filterLastName}
          filterEmail={filterEmail}
          filterContactNumber={filterContactNumber}
        />
      ) : null}
    </>
  );
};

export default Listplexaar;

/* ----------------- Buttons in AddCustomer page previously ----------------- */
// : (
//   // Show "Add New Customer" button when not cleared
//   <div className="row justify-content-center align-items-center my-3">
//     <Button
//       style={{ backgroundColor: "#5599FF", textDecoration:"capitalize" }}
//       className="col-10 py-3 my-0"
//       variant="contained"
//       onClick={addCustomer}
//       disabled={filteredItems.length > 0 ? true : false}
//     >
//     {filteredItems.length === 0 ? <>Add Customer</>: <>Select Customer</>}
//     </Button>
//   </div>
// )
