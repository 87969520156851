import React, { useState, useEffect } from "react";
// 
import Table from "./Table";
import { OnSearch } from "./helper";
import Header from "../Header";
import { departmentList } from "../../../utils/constant";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDepartmentRequest,
  fetchDepartmentRequest,
} from "../../../Store/Department/actions";
import SideBar from "../../../SideNavBar";

import MenuTabList from "../CustomTabs/MenuTabList";
import NewList from "./NewList";
import '../../../Assets/components/meetspace/events.scss'
import NewListMobile from "./NewListMobile";

const MangeDeaprtment = ({ setActiveTab }) => {
  const flag = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const busienssRecord = location;
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.Department?.data);
  const loading = useSelector((state) => state.Department?.loading);
  const removeBusiness = (id) => {
    dispatch(
      deleteDepartmentRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };
  //Delete busienss Confirmation Dialouge Box handler
  const handleBusinessDeleteConfirmation = (id) => {
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  };
  const business = JSON.parse(localStorage.getItem("businessRecord"))
  useEffect(() => {

    if (business?.id) {

      dispatch(fetchDepartmentRequest(business?.id));
    }
  }, []);
  useEffect(() => {
    setRowData(data || []);
  }, [data]);
  return (
    <>
      <SideBar
      data={data}
      OnSearch={OnSearch}
      rowData={rowData}
      setRowData={setRowData}
      flag={flag}
      record={busienssRecord?.state}
      heading={departmentList.DEPARTMENT}
      buttonText={departmentList.ADD_DEPARTMENT}
      link="/add-department"
      >
        <div>
          {/* <Header
            data={data}
            OnSearch={OnSearch}
            rowData={rowData}
            setRowData={setRowData}
            flag={flag}
            record={busienssRecord?.state}
            heading={departmentList.DEPARTMENT}
            buttonText={departmentList.ADD_DEPARTMENT}
            link="/add-department" */}
          {/* /> */}
        </div>
        {/* <MenuTabList type="departmentList" /> */}

        <div className=" p-md-4 hide_scrollbar min-h-[250px] table-container">
          {loading ? (
            <SkeletonTable />
          ) : (
            <Table
              loading={loading}
              setRecord={setRowData}
              data={rowData}
              dialog={dialog}
              deleteDepartment={handleBusinessDeleteConfirmation}
            />
            // <>
            //   <NewList
            //     loading={loading}
            //     data={data}
            //   />
            //   <NewListMobile
            //     loading={loading}
            //     data={data}
            //   />
            // </>
          )}
        </div>
      </SideBar>
    </>
  );
};

export default MangeDeaprtment;
