import { Card, CardActionArea, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const DetailsForm = () => {
  const [activeInput, setActiveInput] = useState();

  return (
    <div className='container-fluid candidate_main_container px-4'>
      <div className='candidate_details_container mt-3'>
        <div className='row p-4'>
          <div className='col-12 candidate_details_sec1 pb-4'>
            <div className='row'>
              <div className='col-md-6 col-12 d-flex align-items-center'>
                <img src='./meetspace/cl1.png' alt='' className='img rounded-circle' style={{ height: '80px', width: '80px' }} />
                <span className='d-flex flex-column  ms-3'>
                  <h5 className='p-0 m-0'>Muhammad Zeeshan</h5>
                  <p className='text-secondaryp-0 m-0 name_details' style={{ color: 'lightgray', fontSize: '14px' }}>920U98</p>
                </span>
              </div>
              <div className='col-md-6 col-12 d-flex justify-content-end align-items-center'>
                <span className='d-flex pe-4' style={{ borderRight: '1px solid lightgray' }}>
                  <AccessTimeIcon sx={{ fontSize: '24px' }} />
                  <p className='text-secondaryp-0 m-0 ms-3' style={{ color: 'gray', fontSize: '14px' }}><b>30 Mins</b></p>
                </span>
                <span className='d-flex ps-4'>
                  <AccessTimeIcon sx={{ fontSize: '24px' }} />
                  <p className='text-secondaryp-0 m-0 ms-3' style={{ color: 'gray', fontSize: '14px' }}><b>20 Mins</b></p>
                </span>
              </div>
            </div>
          </div>
        </div>

        <form className='_input_form'>
          <div className="row">
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 1 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="fname" className="form-label _label">First Name</label>
                <input type="text" placeholder="Enter First Name" name="fname"
                  className={`form-control`}
                  onFocus={() => setActiveInput(1)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 2 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="lname" className="form-label _label">Last Name</label>
                <input type="text" placeholder="Enter Last Name" name="lname"
                  className={`form-control`}
                  onFocus={() => setActiveInput(2)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 3 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="email" className="form-label _label">Email</label>
                <input type="email" placeholder="Enter Email" name="email"
                  className={`form-control`}
                  onFocus={() => setActiveInput(3)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 4 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="uname" className="form-label _label">Phone</label>
                <input type="number" placeholder="Enter Phone" name="phone"
                  className={`form-control`}
                  onFocus={() => setActiveInput(4)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 lg-mt-0 mt-3">
              <div className={`${activeInput === 5 ? 'active_input_wrapper' : 'default_input_wrapper'} d-flex justify-content-between align-items-center`}>
                <span className='w-100'>
                  <label for="mlink" className="form-label _label">Meeting Link</label>
                  <input type="mlink" placeholder="Enter Meeting Link" name="mlink"
                    className={`form-control`}
                    onFocus={() => setActiveInput(5)}
                    onBlur={() => setActiveInput()}
                  />
                </span>
                <span className='me-3'><RiFileCopyLine size='22px' style={{ color: '#78aeff' }} /></span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 6 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="etime" className="form-label _label">Starting Time</label>
                <input type="text" placeholder="Enter Start Time" name="stime"
                  className={`form-control`}
                  onFocus={() => setActiveInput(6)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
            <div className="col-md-6 col-12 mt-3">
              <div className={`${activeInput === 7 ? 'active_input_wrapper' : 'default_input_wrapper'}`}>
                <label for="etime" className="form-label _label">End Time</label>
                <input type="text" placeholder="Enter End Time" name="etime"
                  className={`form-control`}
                  onFocus={() => setActiveInput(7)}
                  onBlur={() => setActiveInput()}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12 mt-4">
              <h5>Other Files</h5>
              <Card sx={{ width: '200px', height: '150px', borderRadius: '15px', border: '1px solid lightgray' }}>
                <CardActionArea>
                  <div>
                    <CardMedia
                      component="img"
                      height="90"
                      image="/service-docs/filebg.png"
                      alt="no image"
                      className='px-2 py-1 edited_img'
                      sx={{ borderRadius: '30px' }}
                    />

                    <div className='d-flex justify-content-center ftype_icon1 mt-2'>
                      <IconButton aria-label="" className='p-2 bg-primary' color="text.primary" sx={{ position: 'absolute', left: '15%', top: '15%', height: '40px', width: '40px' }}>
                        <span style={{ fontSize: '20px', color: 'white' }}><b>W</b></span>
                      </IconButton>
                    </div>
                  </div>
                  <CardContent className='pt-0'>
                    <Typography gutterBottom variant="p" component="div">
                      <b>Muhammad Resume</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className='d-flex justify-content-between align-items-center'>
                      <span>5MB</span>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              {/* </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DetailsForm