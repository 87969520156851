import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import "../bookingDetails/bookingdetail.css";

import Venue from "../bookingDetails/Venue";

import { bookingAxios } from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { BookingDetails } from "../store/bookingSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Avatar, Tooltip, Menu, MenuItem, Box } from "@mui/material";
import { MoreOptionsVertSvg } from "../../../Assets/svgs";
import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
import useCheckPermission, {
  customSnackBar,
  isBusinessRolePermissionAllowed,
} from "../../../utils";
import LeftSection from "./Sections/LeftSection/LeftSection";
import RightSection from "./Sections/RightSection/RightSection";
import { AppointmentDetailsIcon, AttributesIcon } from "./Svgs/svgs";
import Portal from "./Portal";
import SideNavone from "../../../SideNavBar";
import instance from "../../../utils/utils";

const AppointmentDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const options = {
    weekday: "long", // Get the full day name (e.g., "Monday")
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };
  const [bookingData, setBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheckin, setIsLoadingCheckIn] = useState(false);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [isLoadingCreated, setIsLoadingCreated] = useState(false);
  const [isLoadingShow, setIsLoadingShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState({ visible: false });
  const date = new Date(bookingData?.appointments[0]?.bookingDate);

  const formattedDate = date.toLocaleString("en-US", options);

  const [address, setAddress] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [viewPermssion, setViewPermissions] = useState(null);

  let bookingId = useSelector((state) => state.bookingReducer.bookingId);
  let appointmentId = useSelector(
    (state) => state.bookingReducer.appointmentId
  );
  let permissions = useSelector((state) => state?.Auth?.permissionsModule);

  const action = bookingData?.createdPlatforms;
  const permissionsToCheck = [
    "canCancelBooking",
    "canEditBooking",
    "canCheckinJob",
    "canCompleteJob",
    "canStartJob",
  ];

  // useCheckPermission("Booking", "canCancelBooking").then((res) => {
  //   setViewPermissions(res);
  // })

  console.log(viewPermssion, "viewPermssion");

  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Booking",
          permission
        );
        console.log(`Permission ${permission} result: ${result}`);
        fetchedPermissions[permission] = result;
      }
      console.log("Fetched Permissions:", fetchedPermissions);
      setViewPermissions(fetchedPermissions);
      setIsLoading(false);
    };

    console.log("Permissions to check:", permissionsToCheck);
    fetchPermissions();
  }, []);

  const handleMenuActionClick = (event) => {
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(columnValue);
    // setSelecteData(data);
  };
  const handleProfileClick = () => {
    if (bookingData?.plexaarcustomerId === 0) {
      customSnackBar("Customer Id not exsit");
    } else {
      const customerId =
        bookingData?.createdPlatforms === "expert"
          ? bookingData?.customerId
          : bookingData?.plexaarcustomerId;
      history.push(
        `/customer-profile-view/${bookingData?.plexaarcustomerId}`,
        action
      );
    }
  };
  const handleMenuClose = () => {
    setOpenAction(false);
    setAnchorEl(null);
  };
  const fetchBookingData = useCallback(async () => {
    if (bookingId !== 0 && appointmentId !== 0) {
      setIsLoading(true);
      await instance
        .get(
          `/booking_svc/pb/getBookingId/?plexaarBookingId=${bookingId}&timeZone=Europe/London&appointmentId=${appointmentId}`
        )
        .then((response) => {
          setBookingData(response?.data?.result);
          setAddress(response?.data?.result?.address);
          dispatch(BookingDetails(response?.data?.result));
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false); // Stop the loader
    }
  }, [dispatch, bookingId, appointmentId]);
  useEffect(() => {
    fetchBookingData();
  }, [fetchBookingData]);

  const CheckedInData = async () => {
    setIsLoadingCheckIn(true);
    const data = {
      plexaarBookingId: bookingId,
      plexaarAppointmentId: appointmentId,
      appointmentStatus: "isCheckedIn",

      // isCheckedIn: true,
    };
    await instance
      .patch(`/booking_svc/pb/booking/`, data)
      .then((response) => {
        setDialog({ visible: false });
        fetchBookingData();

        setIsLoadingCheckIn(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoadingCheckIn(false));
  };
  const CreatedData = async () => {
    setIsLoadingCreated(true);
    const data = {
      plexaarBookingId: bookingId,
      plexaarAppointmentId: appointmentId,
      appointmentStatus: "created",

      // isCheckedIn: true,
    };
    await instance
      .patch(`/booking_svc/pb/booking/`, data)
      .then((response) => {
        setDialog({ visible: false });
        fetchBookingData();

        setIsLoadingCreated(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoadingCreated(false));
  };
  function formatTime(time) {
    const [hour, minute] = time.split(":");
    const formattedHours = String(parseInt(hour, 10)).padStart(2, "0");
    const formattedMinutes = String(parseInt(minute, 10)).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  }
  const NoShow = async () => {
    setIsLoadingShow(true);
    const data = {
      plexaarBookingId: bookingId,
      plexaarAppointmentId: appointmentId,
      appointmentStatus: "no_show",
      // is_job_started: true,
    };
    await instance
      .patch(`/booking_svc/pb/booking/`, data)
      // bookingAxios
      //   .put(`/api/update_booking_status/`, data)
      .then((response) => {
        setDialog({ visible: false });

        fetchBookingData();

        setIsLoadingShow(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoadingShow(false));
  };

  const handleNoShow = () => {
    setDialog({
      visible: true,
      header: "No Show",
      message: "Are you sure you want to mark this appointment as no-show?",
      key: Math.random().toString(36).substring(7),
      ok: () => NoShow(),
    });
  };
  const handleCheckIn = (id) => {
    if (
      viewPermssion.canCheckinJob ||
      viewPermssion.canCheckinJob === null ||
      viewPermssion.canCheckinJob === undefined
    ) {
      setDialog({
        visible: true,
        header: "Check In",
        message: "Are you sure you want to check in?",
        key: Math.random().toString(36).substring(7),
        ok: () => CheckedInData(),
      });
    } else {
      customSnackBar("Permission Denied");
    }
  };
  const CompletedJob = async () => {
    setIsLoadingJob(true);
    const data = {
      plexaarBookingId: bookingId,
      plexaarAppointmentId: appointmentId,
      appointmentStatus: "is_job_started",
      // is_job_started: true,
    };
    await instance
      .patch(`/booking_svc/pb/booking/`, data)
      // bookingAxios
      //   .put(`/api/update_booking_status/`, data)
      .then((response) => {
        setDialog({ visible: false });

        fetchBookingData();

        setIsLoadingJob(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleStartJob = () => {
    if (
      viewPermssion.canStartJob ||
      viewPermssion.canStartJob === null ||
      viewPermssion.canStartJob === undefined
    ) {
      setDialog({
        visible: true,
        header: "Start Job",
        message: "Are you sure you want to start the job?",
        key: Math.random().toString(36).substring(7),
        ok: () => CompletedJob(),
      });
    } else {
      customSnackBar("Permission Denied");
    }
  };
  const handleCreated = () =>{
    if (
      viewPermssion.canStartJob ||
      viewPermssion.canStartJob === null ||
      viewPermssion.canStartJob === undefined
    ) {
      setDialog({
        visible: true,
        header: "Revert Status",
        message: "Are you sure you want to revert its status?",
        key: Math.random().toString(36).substring(7),
        ok: () => CreatedData(),
      });
    } else {
      customSnackBar("Permission Denied");
    }
  };
  const CompltetdData = async () => {
    setIsLoading(true);
    const data = {
      plexaarBookingId: bookingId,
      plexaarAppointmentId: appointmentId,
      appointmentStatus: "isCompleted",

      // isCompleted: true,
    };
    await instance
      .patch(`/booking_svc/pb/booking/`, data)
      .then((response) => {
        history.push(`/calendar`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCompleteJob = () => {
    if (
      viewPermssion.canCompleteJob ||
      viewPermssion.canCompleteJob === null ||
      viewPermssion.canCompleteJob === undefined
    ) {
      setDialog({
        visible: true,
        header: "Complete Job",
        message: "Are you sure you want to complete the job?",
        key: Math.random().toString(36).substring(7),
        ok: () => CompltetdData(),
      });
    } else {
      customSnackBar("Permission Denied");
    }
  };
  const ComplteteBooking = () => {
    alert("Booking Already Completed");
  };
  if (isLoading || bookingData === null) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (appointmentId === 0 && bookingData === null) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div style={{ marginLeft: "10px" }}>Appointment ID does not exist.</div>
      </div>
    );
  } else {
    return (
      <>
        <DialogBox
          {...dialog}
          header={dialog.header}
          message={dialog.message}
        />
        <div class="appointment-detail-section container-fluid">
          <div class="row">
            <div class="col-sm py-3 px-4">
              <LeftSection bookingData={bookingData} />
            </div>
            <div class="col-12 col-md-7">
              <div className="col-md-12 px-2 pt-3 pb-5" id="style-2">
                <div className="d-flex padding-left-right ">
                  <div
                    className="pt-2"
                    onClick={() => {
                      if (!location?.state?.flag) {
                        history.push("/calendar");
                      } else {
                        history.goBack();
                      }
                    }}
                    role="button"
                  >
                    <i id="icon_left_color" className="fas fa-chevron-left"></i>
                  </div>

                  <Container className="m-auto mb-3">
                    <Grid item xl={1} lg={3} md={6} sm={12} className="">
                      <div className="card card-cont p-3 appointment-detail-container mb-3">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex ">
                            {bookingData?.serviceImage ? (
                              <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                <Avatar
                                  alt={bookingData?.serviceName}
                                  src={bookingData?.serviceImage}
                                  variant="rounded"
                                  style={{
                                    borderRadius: 50,
                                    width: "60px",
                                    height: "60px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                <Avatar
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: 50,
                                  }}
                                >
                                  S
                                </Avatar>
                              </div>
                            )}
                            <div className="appointment-service-name_flex">
                              <div className="appointment-service-name_holder">
                                <div className={`d-flex pt-1`}>
                                  <div className="d-flex flex-column">
                                    <span className="m-0 mx-2 spand ps-0">
                                      {bookingData.serviceName
                                        ? bookingData.serviceName
                                        : bookingData?.servicePayload
                                            ?.serviceName}
                                    </span>{" "}
                                    <div className="d-flex pt-0">
                                      <div className="d-flex flex-column">
                                        <span className="m-0 mx-2 spand ps-0">
                                          <Tooltip
                                            title={
                                              bookingData.attributes.length > 0
                                                ? bookingData.attributes[0]
                                                    .value
                                                : bookingData.serviceName
                                            }
                                          >
                                            <p className="m-0 mx-0 appointment-details_attributes">
                                              {bookingData.attributes.length > 0
                                                ? bookingData.attributes[0]
                                                    .value
                                                : bookingData.serviceName}
                                            </p>
                                          </Tooltip>
                                        </span>
                                      </div>
                                      <span className="pt-0 size-sml col-dark-gray non-capitalized">
                                        (
                                        {bookingData.attributes.length > 0
                                          ? bookingData?.attributes[0].duration
                                          : bookingData.servicePayload.duration}
                                        ) min -
                                        {bookingData?.currency === "usd"
                                          ? "$"
                                          : "£"}
                                        {
                                          bookingData.attributes.length > 0
                                            ? bookingData?.attributes[0]
                                            .attributePrice
                                            : bookingData.totalPrice
                                          
                                        }
                                        
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="mt-2 ms-0 mb-1 horizontal-line_appointment" />
                          <div className="align-self-center">
                            <span className="ms-1 appointment-time">
                              {bookingData?.currency === "usd" ? "$" : "£"}
                              {  bookingData.attributes.length > 0
                                            ? bookingData?.attributes[0]
                                            .attributePrice
                                            : bookingData.totalPrice}{" "}
                            </span>
                          </div>
                        </div>

                        {/* Attribute section */}
                        {/* {bookingData?.attributes.length > 0 ? (
                            <div>
                              {bookingData?.attributes &&
                                bookingData?.attributes.length > 0 && (
                                  <div className="pt-1">
                                    <div className="d-flex ps-0 flex-wrap justify-content">
                                      <span className="book size-sml p-1">
                                        Attributes
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center my-2">
                                      <div className="d-flex align-items-center">
                                        <AttributesIcon />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <hr className="mt-3 ms-0 mb-1 horizontal-line_appointment" />
                            </div>
                          ) : null} */}
                        {/* Customer Name */}
                        <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                        <div>
                          <div className="d-flex flex-wrap align-items-center my-1 justify-content-between pe-0">
                            <div className="d-flex  flex-wrap justify-content">
                              <h1 className="book pt-1 size-sml">
                                Customer Name
                              </h1>
                            </div>
                            <div
                              className="d-flex flex-wrap cursor-pointer"
                              onClick={(event) => handleMenuActionClick(event)}
                            >
                              <MoreOptionsVertSvg />
                            </div>
                          </div>
                          <div>
                            <div className="flex-wrap align-items-center d-flex justify-content-start">
                              <div className="provider-details">
                                {bookingData.providerImage ? (
                                  <Avatar
                                    alt={bookingData?.customerFirstName}
                                    src={bookingData.customerImage}
                                    className="rounded-circle"
                                    style={{ width: 30, height: 30 }}
                                  />
                                ) : (
                                  <Avatar
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "#5599FF",
                                      width: 30,
                                      height: 30,
                                      color: "white",
                                    }}
                                  >
                                    {bookingData?.customerFirstName?.charAt(0)}
                                  </Avatar>
                                )}
                              </div>
                              <div>
                                <div className="d-flex flex-wrap justify-content-between ">
                                  <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                    <span className="spand pt-1 ps-0 provider-name">
                                      {bookingData?.customerFirstName +
                                        " " +
                                        bookingData?.customerLastName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Appointment Details */}
                        <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                        <div className="mt-0">
                          <span className="book size-sml">
                            Appointment Details
                          </span>
                          <div className="d-flex align-items-center my-1">
                            <div className="d-flex align-items-center">
                              <AppointmentDetailsIcon />
                              <div className="d-flex px-2 appointment-details">
                                {formattedDate}
                              </div>
                            </div>
                            <div className="d-flex align-items-center me-1 appointment-details">
                              {bookingData?.appointments[0]?.timeFrom != null &&
                              bookingData?.appointments[0]?.timeTo != null ? (
                                <>
                                 {formatTime(
                                    bookingData?.appointments[0]?.timeFrom
                                  )} -

                                  {formatTime(
                                    bookingData?.appointments[0]?.timeTo
                                    
                                  )}
                                   
                                </>
                              ) : (
                                "Booking Time is Null"
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Provider Name*/}
                        <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                        <div>
                          <div className="d-flex flex-wrap justify-content-between pe-0">
                            <div className="d-flex flex-wrap justify-content">
                              <h1 className="book pt-1 size-sml">
                                Provider Name
                              </h1>
                            </div>
                          </div>
                          <div>
                            <div className="flex-wrap align-items-center d-flex justify-content-start">
                              <div className="provider-details">
                                {bookingData.providerImage ? (
                                  <Avatar
                                    alt={bookingData?.providerName}
                                    src={bookingData.providerImage}
                                    className="rounded-circle"
                                    style={{ width: 30, height: 30 }}
                                  />
                                ) : (
                                  <Avatar
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "#5599FF",
                                      width: 30,
                                      height: 30,
                                      color: "white",
                                    }}
                                  >
                                    {bookingData?.providerName?.charAt(0)}
                                  </Avatar>
                                )}
                              </div>
                              <div>
                                <div className="d-flex flex-wrap justify-content-between ">
                                  <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                    <span className="spand pt-1 ps-0 provider-name">
                                      {bookingData?.providerName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Venue*/}
                        <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                        <Venue VenueData={bookingData?.venue} />

                        {openAction && (
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={handleProfileClick}>
                              <Box
                                component="div"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                Customer Profile
                              </Box>
                            </MenuItem>
                          </Menu>
                        )}
                      </div>
                      <div className="pt-3 ">
                        <Portal bookingDatas={bookingData} />
                      </div>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
            <div class="col-sm py-3 px-4">
              <RightSection
                permission={viewPermssion}
                bookingData={bookingData}
                isLoadingShow={isLoadingShow}
                handleNoShow={handleNoShow}
                handleCheckIn={handleCheckIn}
                handleCompleteJob={handleCompleteJob}
                handleStartJob={handleStartJob}
                handleCreated={handleCreated}
                ComplteteBooking={ComplteteBooking}
                isLoadingCheckin={isLoadingCheckin}
                isLoadingCreated={isLoadingCreated}
                isLoadingJob={isLoadingJob}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AppointmentDetail;
