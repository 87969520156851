import React from "react";
import "../../appointmentDetails.css";
import { RightAngle } from "../../Svgs/svgs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { saleOrderDetails } from "../../../store/bookingSlice";
import { useDispatch } from "react-redux";

const LeftSection = ({ bookingData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = {
    OrderNumber: "14658e4",
    Quantity: "2",
    Dates: [
      "Friday, 5th Mar, 2023",
      "Friday, 6th Mar, 2023",
      "Friday, 7th Mar, 2023",
      "Friday, 8th Mar, 2023",
    ],
    Appointment: "Friday, 7th Mar, 2023",
    TotalAmount: 87,
    currency: "$",
    PaymentStatus: "Paid",
  };
  return (
    <div className="left-section">
      <div className="order-details-section-container">
        <div
          style={{
            cursor: "pointer",
          }}
          className="Order-details_order-num"
        >
          <label className="order-num_heading">Order Number</label>
          <div
            onClick={() => {
              // if (bookingData?.quantity > 1) {
                dispatch(saleOrderDetails(bookingData.plexaarSalesOrderId));
                history.push("/scheduler/order_details");
              // }
            }}
            className="d-flex justify-content-between align-items-baseline"
          >
            <span className="order-num_number">
              {bookingData.plexaarBookingId}
            </span>
            <RightAngle />
          </div>
        </div>

        <hr className="left-section-container-lines" />
        {/* {bookingData?.quantity>1&&( */}
        <div className="Order-details_quantity">
          <label className="quantity_heading">Quantity</label>
          <span className="quantity_number">{bookingData?.quantity}</span>
        </div>
        {/* )} */}
      </div>

      <div
        className={`payment-status-section-container  ${
          bookingData?.payment_status === "paid"
            ? "success-payment"
            : "danger-payment"
        } mt-4`}
      >
        <div className="Order-details_order-num">
          <label className="total-amount_heading">Total Amount</label>
          <div className="d-flex justify-content-between align-items-baseline">
            <span className="total-amount_number">
              {bookingData?.currency === "usd" ? "$" : "£"}
              {bookingData?.totalPrice}{" "}
            </span>
          </div>
        </div>

        <hr className="left-section-container-lines " />
        <div className="Order-details_quantity">
          <label className="quantity_heading">Payment Status</label>
          <div className="d-flex justify-content-between align-items-baseline">
            <span
              className={`quantity_number ${
                bookingData?.payment_status === "paid"
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {bookingData?.payment_status}
            </span>
            <RightAngle />
          </div>
        </div>
      </div>

      {/* <div className="all-appointments-section mt-4">
        <label className="section-heading">All Appointment</label>
        <div className="all-appointments-section-container ">
          {data.Dates.map((date, index) => (
            <div className="">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="all-appointments_dates">{date}</h6>
                <RightAngle />
              </div>
              {index < data.Dates.length - 1 ? (
                <hr className="left-section-container-lines" />
              ) : (
                ""
              )}
            </div>
          ))}

          {/* <div className="Order-details_quantity">
            <label className="quantity_heading">Quantity</label>
            <span className="quantity_number">{data.Quantity}</span>
          </div> */}
        {/* </div>
      </div> */} 
    </div>
  );
};

export default LeftSection;
