import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import {
  fetchUserSuccess,
  fetchUserFailure,
  deleteTeamFailure,
  deleteTeamSuccess,
  fetchProductListFailer,
  fetchProductListSuccess,
  deleteUserFailure,
  fetchRoleSuccess,
  fetchRoleFailure,
  fetchPermissionFailure,
  fetchPermissionSuccess,
  fetchProductRoleSuccess,
  fetchProductRoleFailure,
} from "./actions";
import {
  ADD_USER_FAILURE,
  UPDATE_USER_REQUEST,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_USER_REQUEST,
  FETCH_USER_REQUEST,
  FETCH_PRODUCT_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  FETCH_ROLE_REQUEST,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  FETCH_PERMISSION_REQUEST,
  ADD_PERMISSION_REQUEST,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
  ALLOW_PERMISSION_SUCCESS,
  ALLOW_PERMISSION_FAILURE,
  ALLOW_PERMISSION_REQUEST,
  FETCH_SERVICE_ROLE,
} from "./actionType";
import { push } from "connected-react-router";
import axiosConfig from "../../Routes/AxiosConfigg";
import { customSnackBar, error, success } from "../../utils";
import { NO_DATA_FOUND, teamList, userList } from "../../utils/constant";
import instance from "../../utils/utils";

function* fetchUser(action) {
  const { payload: id } = action;
  try {
    // const response = yield axiosConfig.get(
    //   `business_svc/pb/user?businessId=${id}`
    // );
    const response = yield call(
      instance.get,
      `business_svc/pb/user?businessId=${id}`
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        // Handle case where response has no records
        // error(NO_DATA_FOUND);
        yield put(fetchUserFailure(NO_DATA_FOUND));
      } else {
        // Handle case where response has records successfully
        // success(userList.USER_FETCH);
        yield put(fetchUserSuccess(response.data.result));
      }
    } else {
      yield put(fetchUserFailure(response.data.message));
    }
  } catch (errors) {
    error(errors.response.data.message);

    yield put(fetchUserFailure(error.message));
  }
}
function* fetchProductList(action) {
  const { payload: id } = action;
  try {
    // const response = yield axiosConfig.get(
    //   `business_svc/pb/get_business_services/${id}`
    // );
    const response = yield call(
      instance.get,
      `business_svc/pb/get_business_services/${id}`
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        // Handle case where response has no records
        // error(NO_DATA_FOUND);
        yield put(fetchProductListFailer(NO_DATA_FOUND));
      } else {
        yield put(fetchProductListSuccess(response.data.result));
      }
    } else {
      yield put(fetchProductListFailer(response.data.message));
    }
  } catch (errors) {
    // error(errors.response.data.message);
    yield put(fetchProductListFailer(error.message));
  }
}

function* fetchServiceRole(action) {
  // const {payload:businessId}=payload
  // const {payload:id}=payload
  const { payload: id } = action;
  try {
    // const response = yield axiosConfig.get(
    //   `business_svc/pb/role/?businessId=${id.businessId}&service=${id.id}     `
    // );
    const response = yield call(
      instance.get,
      `business_svc/pb/role/?businessId=${id.businessId}&service=${id.id}`
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        // Handle case where response has no records
        // error(NO_DATA_FOUND);
        yield put(fetchProductRoleFailure(NO_DATA_FOUND));
      } else {
        // Handle case where response has records successfully
        // success(userList.USER_FETCH);
        yield put(
          fetchProductRoleSuccess({
            serviceId: id.id,
            roles: response.data.result,
          })
        );
      }
    } else {
      yield put(fetchProductListFailer(response.data.message));
    }
  } catch (errors) {
    error(errors.response.data.message);
    yield put(fetchProductListFailer(error.message));
  }
}

function* fetchPermissionList({ payload }) {
  try {
    // const response = yield axiosConfig.get(
    //   `${process.env.REACT_APP_BASE_URL}/business_svc/pb/module/?business=${payload}`
    // );
    const response = yield call(
      instance.get,
     `/business_svc/pb/module/?business=${payload}`     
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        // Handle case where response has no records
        // error(NO_DATA_FOUND);
        yield put(fetchPermissionFailure(NO_DATA_FOUND));
      } else {
        // Handle case where response has records successfully
        // success(userList.USER_FETCH);
        yield put(fetchPermissionSuccess(response.data.result));
      }
    } else {
      yield put(fetchPermissionFailure(response.data.message));
    }
  } catch (errors) {
    error(errors.response.data.message);
    yield put(fetchPermissionFailure(error.message));
  }
}
function* addUserSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    const response = yield call(
      instance.post,
      "business_svc/pb/user/", data     
    );
    // const response = yield axiosConfig.post("business_svc/pb/user/", data);
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(userList.USER_CREATE);
      yield put(push(`/users?id=${payload.businessId}`));
      yield put({ type: ADD_USER_SUCCESS, payload: response.data.result });
      customSnackBar(response.data.message);
    } else {
      customSnackBar(response.data.message);
      yield put({ type: ADD_USER_FAILURE, payload: response.data.message });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);

    yield put({ type: ADD_USER_FAILURE, payload: "" });
  }
}
function* allowPermission({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.post("business_svc/pb/subPerms/", data);
    const response = yield call(
      instance.post,
      "business_svc/pb/subPerms/", data    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success("Permission Allowed Successfully");
      window.location = "/permissions";
      yield put({
        type: ALLOW_PERMISSION_SUCCESS,
        payload: response.data.result,
      });
    } else {
      error(response.data.message);
      yield put({
        type: ALLOW_PERMISSION_FAILURE,
        payload: response.data.message,
      });
    }
  } catch (errors) {
    payload.setLoading(false);
    error(errors.response.data.message);
    yield put({ type: ALLOW_PERMISSION_FAILURE, payload: "" });
  }
}
function* addPermission({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.post(
    //   "business_svc/pb/permission/",
    //   data
    // );
    const response = yield call(
      instance.post,
      "business_svc/pb/permission/",
       data    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      success("Permission Created Successfully");
      window.location = "/permissions";
      yield put({
        type: ADD_PERMISSION_SUCCESS,
        payload: response.data.result,
      });
    } else {
      error(response.data.message);
      yield put({
        type: ADD_PERMISSION_FAILURE,
        payload: response.data.message,
      });
    }
  } catch (errors) {
    payload.setLoading(false);
    error(errors.response.data.message);
    yield put({ type: ADD_PERMISSION_FAILURE, payload: "" });
  }
}
function* updateUserSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.patch(
    //   `business_svc/pb/user/${payload.id}/`,
    //   data
    // );
    const response = yield call(
      instance.patch,
      `business_svc/pb/user/${payload.id}/`,
       data    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(userList.USER_UPDATED);
      if (payload.action === "businessProfile") {
        yield put(push(`/business-profile/${payload.businessId}`));
        customSnackBar(response.data.message);
      } else {
        yield put(push(`/users`));
        customSnackBar(response.data.message);
      }

      // yield put({ type: UPDATE_TEAM_SUCCESS, payload:response.data.result });
    } else {
      error(response.data.message);
      customSnackBar(response.data.message);

      // yield put({ type: UPDATE_TEAM_FAILURE, payload: response.data.message });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);

    error(errors.response.data.message);
    yield put({ type: UPDATE_USER_FAILURE, payload: "" });
  }
}
function* deleteUserSaga({ payload }) {
  try {
    // Call the delete API function passing the ID
    // const response = yield axiosConfig.delete(
    //   `business_svc/pb/user/${payload.id}/`
    // );
    const response = yield call(
      instance.delete,
      `business_svc/pb/user/${payload.id}/`,
           );
    if (response.data.code === 0) {
      // success(userList.USER_DELETE);
      payload.setRowData(
        payload.rowData.filter((item) => item.id !== payload.id)
      );
      customSnackBar(response.data.message);

      // yield put(deleteTeamSuccess(payload.id));
    } else {
      customSnackBar(response.data.message);

      error(response.data.message);
      yield put(deleteUserFailure(response.message));
    }
  } catch (errors) {
    customSnackBar(errors.response.data.message);

    error(errors.response.data.message);

    yield put(deleteUserFailure("An error occurred while deleting the Team."));
  }
}
function* addRole({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    const response = yield call(
      instance.post,
      "business_svc/pb/role/", data
    );
    // const response = yield axiosConfig.post("business_svc/pb/role/", data);
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success("Role have been created");
      payload.onClose();
      yield put({ type: ADD_ROLE_SUCCESS, payload: response.data.result });
      customSnackBar(response.data.message);
    } else {
      customSnackBar("Role Already Exist");
      yield put({ type: ADD_ROLE_FAILURE, payload: response.data.message });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);

    error(errors.response.data.message);
    yield put({ type: ADD_ROLE_FAILURE, payload: "" });
  }
}
function* fetchRole(data) {
  const { payload: id } = data;
  try {
    // const response = yield axiosConfig.get(
    //   `business_svc/pb/role/?businessId=${id}`
    // );
    const response = yield call(
      instance.post,
      `business_svc/pb/role/?businessId=${id}`
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        // Handle case where response has no records
        // error(NO_DATA_FOUND);
        yield put(fetchRoleFailure(NO_DATA_FOUND));
      } else {
        // Handle case where response has records successfully
        // success(userList.USER_FETCH);
        yield put(fetchRoleSuccess(response.data.result));
      }
    } else {
      yield put(fetchProductListFailer(response.data.message));
    }
  } catch (errors) {
    error(errors.response.data.message);
    yield put(fetchProductListFailer(errors.response.data.message));
  }
}

export function* fetchUserBinder() {
  yield takeLatest(FETCH_USER_REQUEST, fetchUser);
}
export function* fetchProductBinder() {
  yield takeLatest(FETCH_PRODUCT_REQUEST, fetchProductList);
}
export function* fetchPermissionBinder() {
  yield takeLatest(FETCH_PERMISSION_REQUEST, fetchPermissionList);
}
export function* allowPermissionBinder() {
  yield takeLatest(ALLOW_PERMISSION_REQUEST, allowPermission);
}
export function* addPermissionBinder() {
  yield takeLatest(ADD_PERMISSION_REQUEST, addPermission);
}
export function* fetchRoleBinder() {
  yield takeLatest(FETCH_ROLE_REQUEST, fetchRole);
}
export function* addRoleBinder() {
  yield takeLatest(ADD_ROLE_REQUEST, addRole);
}

export function* addUserWatcher() {
  yield takeLatest(ADD_USER_REQUEST, addUserSaga);
}
export function* fetchSerivceRoleWatcher() {
  yield takeLatest(FETCH_SERVICE_ROLE, fetchServiceRole);
}
export function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserSaga);
}
export function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
}

export default function* userSaga() {
  yield all([
    fork(fetchUserBinder),
    fork(fetchProductBinder),
    fork(addUserWatcher),
    fork(updateUserWatcher),
    fork(deleteUserWatcher),
    fork(fetchSerivceRoleWatcher),
    fork(addRoleBinder),
    fork(fetchPermissionBinder),
    fork(addPermissionBinder),
    fork(allowPermissionBinder),
    fork(fetchRoleBinder),
  ]);
}
