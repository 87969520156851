import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getCookiesData,
  getStorageData,
  setCookiesData
} from '../utils/helper';
import CryptoJs from 'crypto-js';
import {
  addSelectedBusinessId,
  addUserId,
  fetchBusinessRequest
} from '../Store/Business/actions';
import {
  getuserdataafterlogin,
  getuserdataafterloginsuccess,
  userpayloadsuccess
} from '../Store/Auth/actions';

export function PrivateRoute({ Component, props }) {
  // const auth = getStorageData("auth");
    const auth = getCookiesData("auth");
    const authitcation = getCookiesData("authtication");
  const tokenExists = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);

  //   console.log(authitcation, auth, 'authitcationauthitcationauthitcation');
  const decryptUrl = (encryptedUrl) => {
    return CryptoJs.AES.decrypt(encryptedUrl, '9GxbhX0FNMP').toString(
      CryptoJs.enc.Utf8
    );
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const redirectInfoParam = params.get('encryptedData');

    const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
    // if (decodedRedirectInfo) {
      try {
        const redirectObject = JSON.parse(decodedRedirectInfo);
        const token = decryptUrl(redirectObject.token);
        const userId = decryptUrl(redirectObject.userId);
        const user = decryptUrl(redirectObject.user);
        const refreshToken = decryptUrl(redirectObject.jwtRefreshToken);
        // setRedirectUrl(decryptedURL);

        // Extract other data as needed

        // Save token and userId into state

        setCookiesData(
          'authtication',
          JSON.stringify({
            token: token,
            userId: userId,
            user: user,
            refreshToken:refreshToken
          })
        );
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);

        // setTimeout(() => {
          setToken(token);
          setUserId(userId);
          setUser(user);
          setLoading(false);
        // }, 900);
        localStorage.setItem("user", JSON.stringify(user));

        if (token||refreshToken) {
          dispatch(fetchBusinessRequest(parseInt(userId)));
          dispatch(getuserdataafterlogin({ id: parseInt(userId) }));

          dispatch(userpayloadsuccess(JSON.parse(user)));
        }
        
      } catch (e) {
        console.error('Error parsing redirectInfo:', e);
      }
    // }
  }, []);

  useEffect(() => {
   
    setLoading(false);
  }, []);

  if (loading && !tokenExists) {
    return <div>Loading....{loading}</div>
    ;
  // } else if (token || tokenExists) {
  //   return <Component {...props} />;
  } else {
    return <Redirect to="/" />;

  }
  
}