import React, { useState, useEffect } from "react";
import SubscribePlan from "./plans";
import ProductDetail from "./productDetail";
import { error, success } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GET_PLAN } from "../../../utils/GraphQLQueries";
import { Button } from "@mui/material";
import { MdAddCircleOutline } from "react-icons/md";
import PlanEntity from "./planEntity";
import SideNavone from "../../../SideNavBar";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const SubsciptionPlan = () => {
  const history = useHistory();
  const location = useLocation();
  const itemsToShow = 3;
  const [loading, setLoading] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
  };

  const [data, setData] = useState([]);
  const id =
    location &&
    location?.state &&
    location?.state?.state &&
    location?.state?.state?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const query = `
        query  PlanById {
            productEntityById(id: "${id}") {
              id
              createdAt
              updatedAt
              isDeleted
              name
              description
              type
              planSet {
                id
                createdAt
                updatedAt
                isDeleted
                name
                price
                currency
                isActive
                planEntity
              }
            }
          }
        `;

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
          {
            query: GET_PLAN,
          },
          {
            referrerPolicy: "no-referrer-when-downgrade",
          }
        );

        if (response.data.data && response.data.data.productEntityById) {
          // success("Subscription Fetch Successfully");
          setData(response.data.data.productEntityById);
        } else {
          // error("No Record Found");
          setData([]); // Set empty data if no record found
        }
      } catch (error) {
        console.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setData([]); // Reset data if businessId is empty
    }
  }, [id]);
  return (
    <SideNavone>
      <div className=" min-h-[250px] form-container scroll hide_scrollbar pt-4">
        <ProductDetail location={location} />
        <div className="col-md-12 col-12 d-flex justify-content-center mt-4 pt-2 me-0 mb-0 pb-0 ">
          <b className="bold-text mt-4 ">Plans for teams of all sizes</b>
        </div>
        <div className="col-md-12 col-12 d-flex justify-content-center mt-0 pt-0">
          <span className="bold-text1"> Per month, billed annually in USD</span>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            sx={{
              backgroundColor: "#5599FF",
              height: "30px",
              borderRadius: "6px",
              textTransform: "capitalize",
            }}
            variant="contained"
            onClick={() => {
              history.push("/add-subscription");
            }}
          >
            <MdAddCircleOutline color="#fff" /> &nbsp;{" Add"}
          </Button>
        </div>
        <div className="row mt-4 pt-3 pb-5 gap-3">
          <PlanEntity />

          {/* <div className="d-flex gap-3"> */}
          <SubscribePlan data={data} location={location} />
          {/* </div> */}
        </div>
      </div>
    </SideNavone>
  );
};

export default SubsciptionPlan;
