// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coreControlContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -4.5vw;
}
.coreControlChild1 {
  width: 35%;
  margin-right: 4.39vw;
}
.coreControlChild1 img {
  width: 100%;
}
.coreControlChild2 {
  margin-top: 13.17vw;
  width: 40%;
}
.controlChild2Heading {
  margin-bottom: 1.75vw;
}
.coreControlChildHeading {
  display: flex;
  flex-direction: column;
}
.coreControlBlueTxt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75vw;
  line-height: 153.19%;
  /* or 37px */

  color: #548dff;
}
.coreControlgreyText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.31vw;
  line-height: 153.19%;
  /* or 37px */

  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/WebHomePage/CoreControl/CoreControl.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;;EAEZ,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;;EAEZ,cAAc;AAChB","sourcesContent":[".coreControlContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-top: -4.5vw;\n}\n.coreControlChild1 {\n  width: 35%;\n  margin-right: 4.39vw;\n}\n.coreControlChild1 img {\n  width: 100%;\n}\n.coreControlChild2 {\n  margin-top: 13.17vw;\n  width: 40%;\n}\n.controlChild2Heading {\n  margin-bottom: 1.75vw;\n}\n.coreControlChildHeading {\n  display: flex;\n  flex-direction: column;\n}\n.coreControlBlueTxt {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 1.75vw;\n  line-height: 153.19%;\n  /* or 37px */\n\n  color: #548dff;\n}\n.coreControlgreyText {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 1.31vw;\n  line-height: 153.19%;\n  /* or 37px */\n\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
