import React, { useCallback, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { GrPowerReset } from "react-icons/gr";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FaRegEdit } from "react-icons/fa";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import axios from "axios";
import instance from "../../../utils/utils";

const Actions = (props) => {
  const history = useHistory();

  const { index, row, actionLoading, deleteUser,business } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);
  const handleEditClick = async(row) => {
    // const data = {
    //   data: row,
    //   action: "edit",
    // };
    // history.push("/add-user", { data });
    const response = await instance.get(
      // `${process.env.REACT_APP_BASE_URL}/business_svc/pb/user/?businessId=443`
      // `${process.env.REACT_APP_BASE_URL}/business_svc/pb/user/${business.id}`
      `/business_svc/pb/user/${row.id}/`

      // `${process.env.REACT_APP_BASE_URL}/business_svc/pb/user?business_id=${business.id}`
    );
    if(response.data.code===0){
     const data = {
      id:business.id,
      data: response?.data?.result,
      action: "edit",
    };

    history.push("/add-user", { data });
  }
  };
  const handleRoleClick = (row) => {
    const data = {
      data: row,
      action: "edit",
    };
    history.push("/add-user", { data });
  };
  return (
    <>
      <Button
        disabled={actionLoading}
        id={"action-button-" + index}
        size={"small"}
        onClick={handleClick}
        className="d-30 btn-pill p-0 btn-icon"
      >
        <BsThreeDotsVertical
          style={{ color: "#9a9999", fontSize: 20, marginLeft: "10px" }}
        />
      </Button>

      <Menu
        id={"action-menu-" + index}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!row.verified && (
          <>
            {/* <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3 text-success"
              onClick={() => {
                // resentVerificationEmail(row.id);
                handleClose();
              }}
            >
              {"Resend Invitation"}

              <div className="">
                <GrPowerReset
                  style={{ color: "#b2b2b2", fontSize: 20, marginLeft: "10px" }}
                />
              </div>
            </MenuItem> */}

            <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleEditClick(row);
              }}
            >
              <FaRegEdit className="text-primary" sx={{ fontSize: "18px" }} />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Edit
              </span>

              {/* <div className="">
              <GrPowerReset
                style={{ color: "#b2b2b2", fontSize: 20, marginLeft: "10px" }}
              />
            </div> */}
            </MenuItem>
            {/* <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3"
              // onClose={handleClose}
              onClick={() => {
                handleRoleClick(row);
                handleClose();
              }}
            >
              <SyncOutlinedIcon
                className="text-success"
                sx={{ fontSize: "18px" }}
              />{" "}
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Assign Product Role
              </span>
              {/* <div className="">
              <GrPowerReset
                style={{ color: "#b2b2b2", fontSize: 20, marginLeft: "10px" }}
              />
            </div> */}
            {/* </MenuItem> */} 
            <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3 text-danger"
              onClick={() => {
                deleteUser(row.id);
                handleClose();
              }}
            >
              <DeleteForeverIcon
                className="text-danger"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Delete User
              </span>
              {/* <div className="">
            <GrPowerReset
              style={{ color: "#b2b2b2", fontSize: 20, marginLeft: "10px" }}
            />
          </div> */}
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
export default Actions;
