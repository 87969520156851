// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAutocomplete-hasPopupIcon.css-10kwk2h-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-10kwk2h-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px !important;
}

.backgroud_main_section {
  background-color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 2%;
  border: 1px solid rgb(223, 222, 222);
  border-radius: 10px;
}

.labe_from {
  color: #A3A3A3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/schedulerModule/components/TimeSlot/Timeslot.scss"],"names":[],"mappings":"AAKA;;EAEE,uBAAA;AAJF;;AAOA;EACE,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,oCAAA;EACA,mBAAA;AAJF;;AAOA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAJF;;AAOA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,kBAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;EACA,uCAAA;EACA,aAAA;AAJF;;AAOA;EACE;IACE,uBAAA;EAJF;EAOA;IACE,yBAAA;EALF;AACF","sourcesContent":["// .css-10kwk2h-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input\n//                 {\n//                   padding: 0.5px 4px 0px 5px !important\n//                 }\n\n.MuiAutocomplete-hasPopupIcon.css-10kwk2h-MuiAutocomplete-root .MuiOutlinedInput-root,\n.MuiAutocomplete-hasClearIcon.css-10kwk2h-MuiAutocomplete-root .MuiOutlinedInput-root {\n  padding: 6px !important\n}\n\n.backgroud_main_section {\n  background-color: white;\n  padding-top: 2%;\n  padding-bottom: 2%;\n  padding-left: 2%;\n  padding-right: 2%;\n  margin-top: 2%;\n  border: 1px solid rgb(223, 222, 222);\n  border-radius: 10px;\n}\n\n.labe_from {\n  color: #A3A3A3;\n  font-family: Roboto;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  border: 4px solid rgba(0, 0, 0, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #3498db;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  z-index: 9999;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
