import React, { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SideBarItem from "./SideBarItem";
import SideBarHeader from "./SideBarHeader";
import NavBarHeading from "./NavBarHeading";
import SideNavBar from "./SideNavBar";
import "./SideNav.scss";

import axios from "axios";
import { error } from "../Pages/schedulerModule/utils";
// import Setting from "../../public/images/settings.svg";

const SideNavone = ({
  children,
  data,
  rowData,
  record,
  setRowData,
  buttonText,
  link,
  OnSearch,
  businessPermission,
  onClick,
  handleClick,
  handleServiceClick,
  permission,
  typeOfBooking,
}) => {
  // Get the current location using react-router-dom's useLocation hook
  const location = useLocation();
  const date = new Date();
  console.log(permission, "PERMSISISO");
  // Split the pathname by slashes to extract parts
  const firstSlashIndex = location.pathname;
  const parts = firstSlashIndex.split("/");

  // Retrieve user data from Redux state
  var alluserdata = useSelector((state) => state.Auth.userloginsuccessyasir);
  const selectedBusines = useSelector(
    (state) => state.Business?.selectedBusiness
  );

  // State for managing active link and sidebar expansion
  const [isActiveLink, setIsActiveLink] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providerData, setProviderData] = useState();
  const [providerRecord, setProviderRecord] = useState("");

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsExpanded(false);
  };
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 1000); // Set a 1-second delay

    return () => clearTimeout(timeout);
  }, []);

  // Get the history object from react-router-dom
  const history = useHistory();

  // Extracted part from the pathname
  const extractedPart = parts[1];

  // Function to navigate back to the staff listing page
  const backtostafflising = () => {
    history.push("/staff-list");
  };

  let appointmentData = {};

  const handleMoreOptionsClick = async () => {
    const providerDatas = JSON.parse(localStorage.getItem("StaffRecord"));
    appointmentData = {
      date: date,
      actionState: "new",
    };
    if (providerDatas?.providerId !== 0) {
      history.push(
        `/timeSlot?busiessId=${selectedBusines.id}&providerId=${providerDatas.providerId}`,
        { appointmentData }
      );
    } else {
      alert("Staff is not a Providr");
    }
  };

  return (
    <div className="bod_overflow">
      {/* Render the SideBarHeader component */}
      <SideBarHeader
        selectedBusines={selectedBusines}
        alluserdata={alluserdata}
      />

      {/* Render the NavBarHeading component */}
      <NavBarHeading
        typeOfBooking={typeOfBooking}
        permission={permission}
        backtostafflising={backtostafflising}
        selectedBusines={selectedBusines}
        handleMoreOptionsClick={handleMoreOptionsClick}
        OnSearch={OnSearch}
        rowData={rowData}
        data={data}
        record={record}
        setRowData={setRowData}
        buttonText={buttonText}
        link={link}
        businessPermission={businessPermission}
        onClick={onClick}
        handleClick={handleClick}
        handleServiceClick={handleServiceClick}
      />

      <div className="col-md-12 px-0 overflow_main_dashboard">
        {/* Render the SideNavBar component */}
        <SideNavBar
          selectedBusines={selectedBusines}
          extractedPart={extractedPart}
        />

        {/* Render the SideBarItem component */}
        <SideBarItem
          permission={permission}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          isActiveLink={isActiveLink}
          isExpanded={isExpanded}
          location={location}
          setIsExpanded={setIsExpanded}
          setIsActiveLink={setIsActiveLink}
          children={children}
          isVisible={isVisible}
          extractedPart={extractedPart}
        />
      </div>
    </div>
  );
};

export default SideNavone;
