import React from "react";
import Styles from "./CoreControl.module.css";
import { lady } from "../../Assets";
import { CoreControlReason } from "../../WebHomePage/Reusable/CoreControlReason";

export const CoreControl = () => {
  return (
    <div className={Styles.coreControlContainer}>
      <div className={Styles.coreControlSection1}>
        <p className={Styles.mobileCoreText}>
          <span>Automated &</span> Centralized Business Management
        </p>
        <div className={Styles.coreControlImageContainer}>
          <img src={lady} alt="A young women" />
        </div>
      </div>
      <div className={Styles.coreControlSection2}>
        <CoreControlReason />
      </div>
    </div>
  );
};
