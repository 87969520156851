import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import { GoBell } from "react-icons/go";
import BusinessListFromHeader from "./BusinessListFromHeader";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { API_ENDPOINTS } from "Pages/schedulerModule/configs/config";

const SideBarHeader = ({ alluserdata, selectedBusines }) => {
  const history = useHistory();
  const location = useLocation();
  console.log(alluserdata, "alluserdataalluserdataalluserdata");
  const [anchorEl, setAnchorEl] = useState(null); // State to manage Menu anchor element

  const { data, isLoading } = useFetch(
    API_ENDPOINTS.GET_ALL_NOTIFACTIONS +
      `limit=${50}&user=${alluserdata?.id}&sort=-createdAt`
  );
  const { costs: result } = data;
  console.log(result, data, "resultresultresult");
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const datat = [
    {
      title: "Cleaning Service",
      heading: "Expert Professional is 2 mins away for Plumbing Service.",
      time: "5 min ago",
    },
    {
      title: "Cleaning Service",
      heading: "Expert Professional is 2 mins away for Plumbing Service.",
      time: "5 min ago",
    },
    {
      title: "Cleaning Service",
      heading: "Expert Professional is 2 mins away for Plumbing Service.",
      time: "5 min ago",
    },
    {
      title: "Cleaning Service",
      heading: "Expert Professional is 2 mins away for Plumbing Service.",
      time: "5 min ago",
    },
    {
      title: "Cleaning Service",
      heading: "Expert Professional is 2 mins away for Plumbing Service.",
    },
  ];
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className=" pt-1 pb-2 background_nav1_header">
        <div className="d-flex justify-content-between align-item-center">
          <div className="">
            <div className="">
              <div className="mx-2 ">
                <Link to="/dashboard">
                  <img
                    className="img-fluid"
                    src={Logo}
                    alt="kdsnda"
                    style={{
                      cursor: "pointer",
                      height: "1.8rem",
                      width: "1.8rem",
                    }} // Change cursor on hover
                  />
                </Link>
              </div>
            </div>
          </div>
          {location.pathname.includes("/terms") ||
          location.pathname.includes("/privacy") ? null : (
            <div className="d-flex">
              <div className="d-flex">
                <div onClick={handleMenuOpen} className="pt-1 cursor-pointer ">
                  <GoBell
                    fontSize={18}
                    color="#fff"
                    alt="np-data"
                    src="/images/bell.svg"
                  />
                </div>
                {/* Menu */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{
                    maxHeight: "500px", // Adjust the maximum height as needed
                    overflowY: "scroll",
                  }}
                >
                  {data.length === 0 ? (
                    <MenuItem>
                      <div className="text-center text-black-50">
                        No Notifications
                      </div>
                    </MenuItem>
                  ) : (
                    data?.map((item, index) => (
                      <MenuItem key={index}>
                        <div>
                          <div className="d-flex justify-content-between">
                            <strong className="font_size_heading">
                              {item.title}
                            </strong>
                            <small className="text-black-50 font_size_error">
                              {item.time}
                            </small>
                          </div>
                          <p className="font_size_password text-black-50">
                            {item.heading}
                          </p>
                        </div>
                      </MenuItem>
                    ))
                  )}
                </Menu>
                &nbsp; &nbsp;
                <div
                  className="pt-1 pe-4 hide"
                  role="button"
                  onClick={() => history.push("/settings")}
                >
                  {/* <TfiSettings
               fontSize={19}
               color="#fff" */}
                  <img alt="np-data" src="/images/setting.svg" />
                </div>
                <div className="vl mt-md-0 me-md-3 pb-1" />
              </div>

              {/*-------------------------Selecting Business From Header HERE --------------------------*/}
              {location.pathname.includes("/scheduler/create_booking") ||
              location.pathname.includes("/scheduler/create_break") ||
              location.pathname.includes("/scheduler/create_timeblock") ||
              location.pathname.includes("/view-page") ||
              location.pathname.includes(`/add-staff/${selectedBusines?.id}`) ||
              location.pathname.includes("/create-new-service") ||
              location.pathname.includes("/add-new-business") ||
              location.pathname.includes("/select-address") ||
              location.pathname.includes("/add-business-address") ||
              location.pathname.includes(
                `/add-business-schedular/${selectedBusines?.id}`
              ) ||
              location.pathname.includes("/dynamic-form") ||
              location.pathname.includes("/customer-dynamic-fields") ||
              location.pathname.includes("/scheduler/create_event") ? null : (
                <BusinessListFromHeader />
              )}

              <div className=" pe-3 pt-md-0 section">
                <NavLink to="/Profile">
                  {alluserdata?.imageURL ? (
                    <img
                      className=" width_profile_header"
                      src={alluserdata?.imageURL}
                      alt="B"
                      width="20px"
                      height="20px"
                    />
                  ) : (
                    <Avatar
                      sx={{ width: "2rem", height: "2rem" }}
                      //  src=
                      //  {user.imageURL}
                    />
                  )}
                  {/* <button className="btn btn-warning">Profile</button> */}
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBarHeader;
