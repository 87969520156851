export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const LOGOUT = "logout";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = "reset_password";
export const CHANGE_PASSWORD = "change_password";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
///////////////////////////////////////////////////////////////////////////////////////////
export const REGISTERAPI = "register_api";
export const REGISTERAPI_SUCCESS = "register_api_success";
export const SEND_OTP_EMAIL = "send_otp";
export const SEND_OTP_SUCCESS_EMAIL = "send_otp_success";
export const SET_USER_PASSWORD = "set_user_password";
export const SET_USER_PASSWORD_SUCCESS = "set_user_password_success";
export const ADD_USER_PROFILE = "ADD_USER_PROFILE";
export const ADD_USER_MOBILE = "ADD_USER_MOBILE";
export const ADD_USER_MOBILE_SUCCESS = "ADD_USER_MOBILE_SUCCESS";
export const MOBILE_OTP_VERIFY = "MOBILE_OTP_VERIFY";
export const MOBILE_OTP_VERIFY_SUCCESS = "MOBILE_OTP_VERIFY_SUCCESS";
export const GET_USER_DATA_AFTER_LOGIN = "GET_USER_DATA_AFTER_LOGIN";
export const GET_USER_DATA_AFTER_LOGIN_SUCCESS =
  "GET_USER_DATA_AFTER_LOGIN_SUCCESS";
export const ADD_SECONDARY_EMAIL = "ADD_SECONDARY_EMAIL";
export const ADD_SECONDARY_EMAIL_SUCCESS = "ADD_SECONDARY_EMAIL_SUCCESS";
export const VERIFY_SECONDARY_EMAIL = "VERIFY_SECONDARY_EMAIL";
export const VERIFY_SECONDARY_EMAIL_SUCCESS = "VERIFY_SECONDARY_EMAIL_SUCCESS";
export const SEND_OTP_SECONDRY_EMAIL = "SEND_OTP_SECONDRY_EMAIL";
export const ADD_SECONDARY_PHONE_NUMBER  = "ADD_SECONDARY_PHONE_NUMBER";
export const ADD_SECONDARY_PHONE_NUMBER_SUCCESS  = "ADD_SECONDARY_PHONE_NUMBER_SUCCESS";
export const VERIFY_SECONDARY_MOBILE  = "VERIFY_SECONDARY_MOBILE";
export const VERIFY_SECONDARY_MOBILE_SUCCESS  = "VERIFY_SECONDARY_MOBILE_SUCCESS";
export const RESEND_OTP_MOBILE  = "RESEND_OTP_MOBILE";
export const GET_ALL_GENDERS  = "GET_ALL_GENDERS";
export const GET_ALL_GENDERS_SUCCESS  = "GET_ALL_GENDERS_SUCCESS";
export const SEND_GENDER  = "SEND_GENDER";
export const SEND_GENDER_SUCCESS  = "SEND_GENDER_SUCCESS";
export const ADD_DOB  = "ADD_DOB";
export const ADD_DOB_SUCCESS  = "ADD_DOB_SUCCESS";
export const SIGNIN_USER  = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS  = "SIGNIN_USER_SUCCESS";
export const RESEND_MOBILE_PRIMARY_OTP  = "RESEND_MOBILE_PRIMARY_OTP";
export const RESEND_OTP_NEW_PRIMARY_EMAIL  = "RESEND_OTP_NEW_PRIMARY_EMAIL";
export const RESEND_OTP_SECONDARY_EMAIL  = "RESEND_OTP_SECONDARY_EMAIL";
export const NEW_OTP_RESEND_PRIMARY_MOBILE  = "NEW_OTP_RESEND_PRIMARY_MOBILE";
export const UPDATE_USER_NAME  = "UPDATE_USER_NAME";
export const UPLOAD_PROFILE_IMG  = "UPLOAD_PROFILE_IMG";
export const UPLOAD_PROFILE_IMG_SUCCESS  = "UPLOAD_PROFILE_IMG_SUCCESS";
export const SUCCESS_LOGIN_USER  = "SUCCESS_LOGIN_USER";
export const PERMISSION_API  = "PERMISSION_API";
export const PERMISSION_API_SUCCESS  = "PERMISSION_API_SUCCESS";
export const FORGET_PASSWORD  = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS  = "FORGET_PASSWORD_SUCCESS";
export const CHANGE_USERS_PASSWORD = "CHANGE_USERS_PASSWORD";

