import React from 'react'
import uuid from 'react-uuid';
export const ChildDropMCQQuestion = ({refresh,setRefresh, e, index, setFormBuilderQuestions, setClickOption,
   formBuilderQuestions,sectionData, setSectionData, selectedSection, setSelectedSection }) => {

    // function modifyObjectById(object, targetId) {
        
    //     if (object.id.toString() === targetId) {
            
    //         object = null
    //     }
          
    //   if (object) {
    //     if (object.contentObject && object.contentObject.options) {
    //       object.contentObject.options.forEach((option) => {
    //         if (option.childs) {
    //           option.childs.forEach((child) => {
    //             modifyObjectById(child, targetId);
    //           });
    //         }
    //       });
    //     }
    //   }
        
    // }

    function modifyObjectById(object, targetId) {
        if (object.id.toString() === targetId) {
          return null;
        }
      
        if (object.contentObject && object.contentObject.options) {
          object.contentObject.options.forEach((option) => {
            if (option.childs) {
              option.childs.forEach((child, index) => {
                const modifiedChild = modifyObjectById(child, targetId);
                if (modifiedChild === null) {
                  option.childs.splice(index, 1);
                }
              });
            }
          });
        }
      
        return object;
      }
      
    


    let id = uuid()
    return (


        <div className='rounded shadow p-2 bg-white my-2'>
        
            <div className='d-flex justify-content-between align-items-center' >
                <div style={{'cursor':'pointer'}} className="align-items-center" data-bs-toggle="collapse" data-bs-target={`#collapseExample${index}${id}`} aria-expanded="false" aria-controls={`#collapseExample${index}${id}`} >

                 <h5>- {e.contentObject?.questionText}</h5>
                 
                </div>
                <h5 className='btn  text-danger' onClick={() => {
                    let ids = e.id.split('.');
                    var parentObj = {}
                    var index = 0
                    selectedSection?.questionList?.forEach((k,i)=>{
                        if(k.id.toString() === ids[0]){
                            parentObj = Object.create(k)
                            index = i
                        }
                    })
                    
                   var obj = modifyObjectById(parentObj,e.id)
                    selectedSection.questionList[index] = obj
                    setSelectedSection(selectedSection)
                    sectionData.forEach(element => {
                      if(element.id === selectedSection.id){
                        element = selectedSection
                      }
                    });
                    setSectionData([...sectionData])
                    // setFormBuilderQuestions([...formBuilderQuestions])

                    setRefresh(!refresh)


                }}><i className="fas fa-minus-square"></i></h5>
            </div>
            <div className=' collapse px-2' id={`collapseExample${index}${id}`} >
                <p className='m-0 p-0 small'>Type: <b>{e.contentObject.questionType}</b> </p>
                
                {e.contentObject.questionType === "MCQQuestion" ? <p className='small'>Multiple Choose: <b>{e.contentObject.multiple_choice ? "Yes" : "No"}</b> </p> : e.contentObject.questionType === 'DateQuestion'? <div><h6>Start Date: {e.contentObject.start_date}</h6><h6>End Date: {e.contentObject.end_date}</h6></div>:<></>}
                { e.contentObject.options ? <p className='small'>Options:</p>:<></>}
                
                {

                    e.contentObject.options ? e.contentObject.options.map((op, indexOp) => {

                        return <div key={uuid()} className=' my-1 rounded bg-light p-1 align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>

                                <h6 >{op.choiceText}</h6>
                                <button className='btn btn-primary p-1' data-bs-toggle="modal" data-bs-target="#addChildModal" onClick={() => {
                                    // setChildAddClickObject({ parent: e, option: op })
                                    setClickOption(op.id)
                                }}><small className='px-2'>+</small>
                                </button>
                            </div>
                            {op.childs ?
                                op.childs.map((e) => <ChildDropMCQQuestion key={uuid()} e={e} setFormBuilderQuestions={setFormBuilderQuestions} setClickOption={setClickOption} formBuilderQuestions={formBuilderQuestions}
                                sectionData={sectionData}
                                    setSectionData={setSectionData}

                                    selectedSection={selectedSection}
                                    setSelectedSection={setSelectedSection} />)
                                : null}
                        </div>
                    }) : null
                }
            </div>
        </div>
    )
}
