/** @format */

import React, { useEffect, useMemo, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import {
	Avatar,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Select,
	Switch,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useDispatch, useSelector } from "react-redux";
import {
	closeupdatemodel,
	DeleteEventsData,
	UpdateEventsData,
} from "../store/modelSlice";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import countryList from "react-select-country-list";
import {
	dateAuthentication,
	getMinDiff,
	getTimeStops,
} from "../../utils/TimeSlotsAvailable";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import DataNotFound from "./dataNotFound";
import { getNameOfResource } from "../../utils/ExtractAppointments";

let colorScheme = [
	{
		id: 1,
		colorCode: "#D50000",
		label: "Tomato",
	},
	{
		id: 2,
		colorCode: "#E67C73",
		label: "Flamingo",
	},
	{
		id: 3,
		colorCode: "#F4511E",
		label: "Tangerine",
	},
	{
		id: 4,
		colorCode: "#F6BF26",
		label: "Banana",
	},
	{
		id: 5,
		colorCode: "#33B679",
		label: "Sage",
	},
	{
		id: 6,
		colorCode: "#0B8043",
		label: "Basil",
	},
	{
		id: 7,
		colorCode: "#039BE5",
		label: "Peacock",
	},
	{
		id: 8,
		colorCode: "#3F51B5",
		label: "Blueberry",
	},
	{
		id: 9,
		colorCode: "#7986CB",
		label: "Lavender",
	},
	{
		id: 10,
		colorCode: "#8E24AA",
		label: "Grape",
	},
	{
		id: 11,
		colorCode: "#616161",
		label: "Graphite",
	},
];
let alertScheme = [
	{
		id: 1,
		time: "30 mins before",
		duration:"30"
	},
	{
		id: 2,
		time: "20 mins before",
		duration:"20"
	},
	{
		id: 3,
		time: "10 mins before",
		duration:"10"
	},
	{
		id: 4,
		time: "5 mins before",
		duration:"5"
	},
	{
		id: 5,
		time: "2 mins before",
		duration:"2"
	},
	{
		id: 6,
		time: "1 mins before",
		duration:"1"
	},
];
// dateAuthentication
function EventUpdateDialog(props) {
	const dispatch = useDispatch();
	const [tabvalue, setTabValue] = useState("Event");
	const open = useSelector((state) => state.modelReducer.openupdate);
	const eventUpdate = useSelector((state) => state.modelReducer.eventSelected);
	const startTime = useSelector((state) => state.calendarReducer.startTime);
	const endTime = useSelector((state) => state.calendarReducer.endTime);
	const date = useSelector((state) => state.calendarReducer.resources);
	const event_Date = useSelector((state) => state.counterReducer.date);
	const post_Date = useSelector((state) => state.counterReducer.dateApi);
	const slotStart = eventUpdate.timeFrom;
	const slotEnd =  eventUpdate.timeTo;
	const [startTimeSlot, setStartTime] = useState();
	const [endTimeSlot, setEndTime] = useState();
	const [alertTime, setAlertTime] = useState("");
	const [locationData, setLocationData] = useState("");
	const [dateAuth, setDateAuth] = useState(false);
	const [colorCode, setColorCode] = useState("");
	const [providerId, setProviderId] = useState("");
	const [servicesName, setServicesName] = useState("");
	const [serviceDetail, setServiceDetail] = useState("");
	const [bookingDuration, setBookingDuration] = useState("");
	const [stickyNotes, setStickyNotes] = useState(false);
	const [stickyNotesDescription, setStickyNotesDescription] = useState("");
	const [eventDate, setEventDate] = useState(
		moment(event_Date).format("MMM Do YY"),
	);
	//Get Customer Name
	var labelData = getNameOfResource(date, eventUpdate);
	const options = useMemo(() => countryList().getData(), []);

	//Get Time Slot Array
	var timeStops = getTimeStops(
		`${startTime.hour}:${startTime.min}`,
		`${endTime.hour}:${endTime.min}`,
	);

	//Set Provider ID as Slot Change
	useEffect(() => {
		setTabValue(eventUpdate.type);
		setProviderId(eventUpdate.resourceId);
		setServicesName(eventUpdate.title);
		setServiceDetail(eventUpdate.serviceDetail);
		setColorCode(eventUpdate.colorEvento);
		setLocationData(eventUpdate.location);
		setAlertTime(eventUpdate.notify);
		setStickyNotes(eventUpdate.isstickyNotes);
		setStickyNotesDescription(eventUpdate.stickyNotesDescription)
	}, [eventUpdate]);

	//Set Date and Get Filter List
	useEffect(() => {
		setEventDate(moment(event_Date).format("MMM Do YY"));
	}, [event_Date]);

	//Change Tabs of Event and Break
	const handleChangetab = (event, newValue) => {
		setTabValue(newValue);
	};


	//Booking Durations
	useEffect(() => {
		setDateAuth(dateAuthentication(event_Date));
		if(startTimeSlot && endTimeSlot){
			setBookingDuration(getMinDiff(startTimeSlot, endTimeSlot))
		}
	}, [startTimeSlot, endTimeSlot,event_Date]);
	useEffect(() => {
		setStartTime(slotStart);
		setEndTime(slotEnd);
	}, [slotStart, slotEnd]);
	//Form Validations
	const getIsFormValid = () => {
		return (
			providerId &&
			tabvalue &&
			post_Date &&
			startTimeSlot &&
			endTimeSlot &&
			servicesName &&
			serviceDetail &&
			Math.sign(bookingDuration) === 1
		);
	};

	//Api Call For Form Validations
	const UpdateFunctionEvent = () => {
		var data;
		if (tabvalue === "Event") {
			data = {
				drag: "no",
				UniqueId: eventUpdate.UniqueId,
				type: tabvalue,
				providerId: providerId,
				date: post_Date,
				timeFrom: startTimeSlot,
				timeTo: endTimeSlot,
				bookingDuration: bookingDuration,
				serviceName: servicesName,
				serviceDetail: serviceDetail,
				colorCode: colorCode,
				location: locationData,
				notify: alertTime,
				customerName: labelData[0]?.title,
				isstickyNotes:stickyNotes,
				stickyNotesDescription:stickyNotesDescription
			};
		} else {
			data = {
				drag: "no",
				UniqueId: eventUpdate.UniqueId,
				type: tabvalue,
				providerId: providerId,
				date: post_Date,
				timeFrom: startTimeSlot,
				timeTo: endTimeSlot,
				bookingDuration: bookingDuration,
				serviceName: servicesName,
				serviceDetail: serviceDetail,
				customerName: labelData[0]?.title,
			};
		}

		dispatch(UpdateEventsData(data));
	};

	const handleStickyNotes = () => {
		setStickyNotes(!stickyNotes);
	};
	const handleDelete = (id)=>{
		dispatch(DeleteEventsData(id))
	}
	return (
		<Dialog
			onClose={() => {
				dispatch(closeupdatemodel());
			}}
			open={open}
			style={{ width: "100%" }}>
			{/* If Slot Selected */}
			{dateAuth ? (
				<div>
					<DataNotFound val='Date is In Past' />{" "}
				</div>
			) : (
				<div className='w-full px-4 pb-8'>
					{/* Header Component */}
					<DialogTitle className='w-full flex justify-center items-center'>
						<div
							className='bg-[#fafafa] my-4 py-4 px-4 rounded-md shadow-md w-full justify-center items-center'
							style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
							<div className='flex flex-row justify-start items-center'>
								{labelData[0]?.img ? (
									<Avatar
										alt={labelData[0]?.title}
										src={labelData[0]?.img}
										sx={{ width: 56, height: 56 }}
									/>
								) : (
									<Avatar
										sx={{
											bgcolor: "#5599FF",
											width: 56,
											height: 56,
											color: "white",
										}}>
										{labelData[0]?.title.charAt(0)}
									</Avatar>
								)}
								<div className='flex w-full flex-row justify-between py-4'>
									<p className='text-gray-900 font-bold ml-8'>
										{labelData[0]?.title}
									</p>
								</div>
							</div>
							<p className='font-bold text-2xl text-[#5599FF] py-4'>
								{eventDate}
							</p>
							<div className='flex flex-row justify-between items-center px-4 '>
								<div className='flex flex-row justify-center items-center'>
									<div className='flex flex-row justify-center items-center'>
										<AccessTimeFilledIcon style={{ fontSize: "30px" }} />
										<p className='text-base font-bold mx-2 text-black'>
											Start Time
										</p>
									</div>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										variant='standard'
										className='font-semibold'
										value={startTimeSlot}
										onChange={(e) => {
											setStartTime(e.target.value);
										}}>
										{timeStops.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</div>
								<div className='flex flex-row justify-center items-center'>
									<div className='flex flex-row justify-center items-center'>
										<AccessTimeFilledIcon style={{ fontSize: "30px" }} />
										<p className='text-base font-bold mx-2 text-black'>
											End Time
										</p>
									</div>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										variant='standard'
										className='font-semibold'
										value={endTimeSlot}
										onChange={(e) => {
											setEndTime(e.target.value);
										}}>
										{timeStops.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
							{Math.sign(bookingDuration) === -1 ? (
								<p className='text-red-600 text-xs bg-white px-4 rounded-md'>
									Can Not Select slot thats end time is before than start time
								</p>
							) : null}
							<div className='px-4 py-2'>
								<TextField
									required
									id='outlined-basic'
									variant='filled'
									className='w-full'
									value={servicesName}
									onChange={(e) => {
										setServicesName(e.target.value);
									}}
								/>
							</div>
						</div>
					</DialogTitle>

					{/* Form Data */}
					<div
						className=' px-6 bg-[#fafafa] rounded-lg shadow-md'
						style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
						<div>
							<TabContext value={tabvalue}>
								<TabList
									onChange={handleChangetab}
									aria-label='lab API tabs example'>
									<Tab
										label='Events'
										value='Event'
										className='text-black font-bold'
									/>
									<Tab
										label='Breaks'
										value='Break'
										className='text-black font-bold'
									/>
								</TabList>
								{/*Appointment Form Data */}
								<TabPanel value='Event'>
									<div className='py-2'>
										<div className='flex flex-row justify-between  items-center py-2'>
											<div className='flex flex-row justify-start items-center'>
												<IconButton>
													<AddAlertIcon
														style={{ color: "#5599FF", fontSize: "40px" }}
													/>
												</IconButton>
												<Select
													labelId='demo-simple-select-label'
													id='demo-simple-select'
													variant='standard'
													sx={{ width: 200 }}
													className='font-semibold flex flex-row items-center'
													value={alertTime}
													onChange={(e) => {
														setAlertTime(e.target.value);
													}}>
													{alertScheme.map((option) => (
														<MenuItem
															key={option.id}
															value={option.duration}
															className='flex'>
															<p className='ml-2'>{option.time}</p>
														</MenuItem>
													))}
												</Select>
											</div>
											<div className='flex flex-row justify-center items-center'>
												<IconButton>
													<ColorLensIcon
														style={{ color: "orange", fontSize: "40px" }}
													/>
												</IconButton>
												<Select
													labelId='demo-simple-select-label'
													id='demo-simple-select'
													variant='standard'
													sx={{ width: 200 }}
													className='font-semibold flex flex-row items-center'
													value={colorCode}
													onChange={(e) => {
														setColorCode(e.target.value);
													}}>
													{colorScheme.map((option) => (
														<MenuItem
															key={option.id}
															value={option.colorCode}
															className='flex'>
															<div
																className={clsx(` h-3 w-3 rounded-full`)}
																style={{
																	backgroundColor: `${option.colorCode}`,
																}}></div>
															<p className='ml-2'>{option.label}</p>
														</MenuItem>
													))}
												</Select>
											</div>
										</div>{" "}
										<div className='flex flex-row justify-center items-center'>
											<IconButton className='pt-2'>
												<LocationOnIcon
													style={{ color: "orange", fontSize: "35px" }}
												/>
											</IconButton>

											<Autocomplete
												id='disable-close-on-select'
												className='w-full'
												value={locationData}
												options={options}
												onInputChange={(event, newInputValue) => {
													setLocationData(newInputValue);
												}}
												renderInput={(params) => (
													<TextField
														variant='standard'
														className='font-semibold'
														{...params}
													/>
												)}
											/>
										</div>
										<TextField
											required
											id='outlined-multiline-flexible'
											variant='filled'
											className='w-full py-2'
											multiline
											rows={2}
											value={serviceDetail}
											onChange={(e) => {
												setServiceDetail(e.target.value);
											}}
										/>
											<div className='flex justify-between items-center'>
												<label className='font-semibold'>
													Add Sticky Notes
												</label>
												<Switch
													checked={stickyNotes}
													onChange={handleStickyNotes}
													inputProps={{ "aria-label": "controlled" }}
												/>
											</div>
											{stickyNotes?
											<TextField
												id='outlined-basic'
												label='Sticky Notes Details'
												variant='filled'
												className='w-full'
												value={stickyNotesDescription}
												onChange={(e) => {
													setStickyNotesDescription(e.target.value);
												}}
											/> :
											null
										}
									</div>
								</TabPanel>
								{/*Breaks Form Data */}
								<TabPanel value='Break'>
									<div className='py-2'>
										<TextField
											required
											id='outlined-multiline-flexible'
											variant='filled'
											className='w-full py-2'
											multiline
											rows={2}
											value={serviceDetail}
											onChange={(e) => {
												setServiceDetail(e.target.value);
											}}
										/>
									</div>
								</TabPanel>
							</TabContext>
						</div>
						{/*Submit Button */}
						<div className='py-2 flex justify-between'>
							<Button
								variant='contained'
								className='bg-[#5599FF]'
								disabled={!getIsFormValid()}
								onClick={() => {
									UpdateFunctionEvent();
									dispatch(closeupdatemodel());
								}}>
								Update
							</Button>
							<Button
								variant='contained'
								className='bg-red-600'
								disabled={!getIsFormValid()}
								onClick={() => {
									handleDelete({id:eventUpdate.UniqueId,type:tabvalue});
									dispatch(closeupdatemodel());
								}}>
								delete
							</Button>
						</div>
					</div>
				</div>
			)}
		</Dialog>
	);
}

export default React.memo(EventUpdateDialog);
