import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import './payment.scss';
import SideMenu from "../side-menu";
import PaymentBox from "./PaymentBox";

const CreditCard = ({
    defaultBtn,
    cardProvider,
    lastFourDigits,
    expireMonth,
    expireYear,
    onDeleteClickHandler,
    paymentMethodId,
    setAsDefaultHandler,
}) => {
    const [showPopUp, setShowPopUp] = useState(false);

    return (
        <div className="container-fluid p-5">
            <div className="row">
                {/* <div className="col-4"></div> */}
                <div className="col-12 ">
                    <PaymentBox />
                    <div className="row mt-5">
                        <div className="col-12 p-3 payment_card_list">

                            <div className="row card_action_row">
                                <div className="col-12 d-flex justify-content-end mb-3">
                                    <div className="input-group search_group me-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-transparent" id="inputGroup-sizing-default"><SearchIcon sx={{ fontSize: '30px' }} /></span>
                                        </div>
                                        <input type="text" placeholder="Search" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <button type="button" className="btn btn-danger px-3">Add Payment Method</button>
                                </div>
                            </div>

                            <div
                                className={`border ${!defaultBtn ? "border-danger-subtle" : "border-primary-subtle"} rounded px-4 py-2 me-4 mb-4`}
                                style={{
                                    width: "47%",
                                    fontSize: "80%",
                                }}
                            >
                                <div className="d-flex justify-content-between align-items-center pt-2">
                                    {cardProvider === "visa" ? (
                                        <img
                                            src="/assets/images/visa.png"
                                            alt="Visa card Details"
                                            height={80}
                                            width={80}
                                        />
                                    ) : cardProvider === "mastercard" ? (
                                        <img
                                            src="/assets/images/mastercard.png"
                                            alt="Master card Details"
                                            height={80}
                                            width={80}
                                        />
                                    ) : cardProvider === "amex" ? (
                                        <img
                                            src="https://logowik.com/content/uploads/images/amex-card1708.jpg"
                                            alt="amex"
                                            height={80}
                                            width={80}
                                        />
                                    ) : (
                                        <p>No company</p>
                                    )}
                                    {defaultBtn ? null : (
                                        <div>
                                            <span
                                                style={{
                                                    fontSize: "",
                                                    cursor: "pointer",
                                                    marginTop: "16px",
                                                    marginLeft: "-5px",
                                                    marginRight: "22px",
                                                }}
                                                onClick={() => setShowPopUp(!showPopUp)}
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <BsThreeDotsVertical />
                                            </span>
                                            <div className={`dropdown ${showPopUp ? "show" : ""}`}>
                                                <div
                                                    className={`dropdown-menu ${showPopUp ? "show" : ""}`}
                                                    aria-labelledby="dropdownMenuButton"
                                                >
                                                    <div className="px-2 py-2">
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={(e) => {
                                                                setShowPopUp(false);
                                                                setAsDefaultHandler(paymentMethodId);
                                                            }}
                                                        >
                                                            <i className="fas fa-edit me-2"></i>
                                                            <span>set as Default</span>
                                                        </div>
                                                        <div
                                                            onClick={(e) => {
                                                                setShowPopUp(false);
                                                                onDeleteClickHandler(paymentMethodId);
                                                            }}
                                                            className="cursor-pointer"
                                                        >
                                                            <i className="fas fa-trash-alt me-2"></i>
                                                            <span>Delete</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className="fs-5">**** **** **** {` ${lastFourDigits}`}</p>
                                <div className="d-flex justify-content-between pb-2">
                                    <p style={{ fontSize: "90%" }}>
                                        Expires {expireMonth}-{expireYear}
                                    </p>
                                    {/* {defaultBtn && ( */}
                                    <div className="px-3 py-2 rounded bg-danger bg-opacity-25 text-danger fs-6">
                                        Saved as default
                                    </div>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditCard;
