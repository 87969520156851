import React from "react";
import "./Hero.css";
import { RoundLoaders } from "../Reusable/RoundLoaders";
import { heroimage } from "../../Assets";
import { useHistory } from "react-router-dom"; // Import useHistory hook
import CryptoJS from "crypto-js";

export const Hero = () => {
  const history = useHistory(); // Initialize useHistory hook

  function encryptURL(currentURL) {
    return CryptoJS.AES.encrypt(currentURL, "secret_key").toString();
  }
  const handleGetStarted = () => {
    const currentURL = window.location.href;
    const encryptedURL = encryptURL(currentURL);
    const redirectObject = {
      encryptedURL: encryptedURL,
      targetURL: "https://login.plexaar.com",
    };

    const redirectURL = `https://login.plexaar.com?redirectInfo=${encodeURIComponent(
      JSON.stringify(redirectObject)
    )}`;
    // Perform redirection
    window.location.href = redirectURL;
    // If you want to use history.push() for redirection uncomment the following line
    // history.push(redirectURL);
  };

  return (
    <>
      <div className="homepageHeroContainer">
        <div className="homepageHeroChild1">
          <p className="homepageHeroTitle">
            The #1 ERP System for Business Excellence
          </p>
          <p className="homepageHeroDescription">
            All-In-One Business Management Suite trusted by 10k+ happy customers
            worldwide. A ground-breaking ERP system to thrive businesses.
          </p>
          <RoundLoaders />

          <button onClick={handleGetStarted} className="homepagehero_button">Get Started</button>
        </div>
        <div className="homepageHeroChild2">
          <img src={heroimage} alt="Welcome to plexaar" />
        </div>
      </div>
    </>
  );
};
