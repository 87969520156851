import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mainAxios, slotAxios } from "../../axios";
import {
  ExtractAppointments,
  ExtractBgAppointments,
  ExtractResources,
} from "../../utils/ExtractAppointments";
import { FilterResources } from "../../utils/filterResources";
import { StringToHourMin } from "../../utils/StringToHourMin";
import { ExtractSlots } from "../../utils/ExtractSlots";
import instance from "../../../utils/utils";
// import axios from "axios";

// export const getCalendarData = createAsyncThunk(
//   "clinics/getCalendarData",
//   async (clinicId, { getState }) => {
//     var dateApi = getState().counterReducer.dateApi;
//     var businessID = getState().Auth.alluserdata.users.id;

//     const response = await mainAxios.get(
//       `eventsService/pb/events?businessId=1&bookingDate=${dateApi}`
//     );
//     return { data: response.data?.result, date: dateApi };
//   }
// );

export const getCalendarData = createAsyncThunk(
  "clinics/getCalendarData",
  async (data, { getState }) => {
    var dateApi = getState().counterReducer.dateApi;
    // const response = await mainAxios.get(
    //   `/pb/events?businessId=${data.id}&is_business=true&bookingDate=${dateApi}&timeZone=Europe/London`
    // );
    const response = await instance.post(`/events_svc/pb/events`, data);
    console.log(response,"responseresponseresponse")
    // const response = await axios.get(
    //   `${process.env.REACT_APP_BASE_URL}/events_svc/pb/events?businessId=4&bookingDate=2023-12-01&timeZone=Europe/London`
    // );
    // const response = await axios.get(
    //   "https://plexaargateway.findanexpert.net/events_svc/pb/events?businessId=4&bookingDate=2023-12-13&timeZone=Europe/London"
    // );
    return { data: response.data?.result, date: dateApi };
  }
);

//
export const getSlotsData = createAsyncThunk(
  "clinics/getSlotData",
  async ({ data }, { getState }) => {
    // console.log(data,"businessIdbusinessIdbusinessId")

    var dateApi = getState().counterReducer.dateApi;
    var extractedIds = getState().calendarReducer.resources.map(
      (obj) => obj.resourceId
    );
    var record = {
      ...data,
      providerId: extractedIds,
    };
    // setLoading(true)
    const response = await instance.post(
      `/provideravailability_svc/pb/post/slots`,
      record
    );
    // setLoading(false)
    return { data: response.data?.result, date: dateApi };
  }
);
export const getOpenBooking = createAsyncThunk(
  "clinics/getOpenBooking",
  async ({ id, sku ,setLoader}, { getState }) => {

    var dateApi = getState().counterReducer.dateApi;
    var extractedIds = getState().calendarReducer.resources.map(
      (obj) => obj.resourceId
    );

    setLoader(true)
    const response = await instance.get(`/booking_svc/pb/openBooking/?plexaarcustomerId=${id}&sku=${sku}&page_size=100`);
    // const response =
    //   await instance.get(`/booking_svc/pb/openBooking/?plexaarcustomerId=33&sku=LH-31425827&timeZone=Europe%2FLondon`);
      setLoader(false)
    return { data: response.data?.result };
  }
);
const initialState = {
  calendarData: [],
  events: [],
  bgevents: [],
  resources: [],
  slots: [],
  combinedData: [],
  filterResource: [],
  startTime: { hour: 0, min: 0 },
  endTime: { hour: 0, min: 0 },
  isLoading: false,
  errorMessageCalendar: [],
  errorMessage: [],
  openBooking: [],
};

const CreateSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    filterResources: (state, action) => {
      state.resources = FilterResources(
        {
          providerFilter: action.payload.providerFilter,
          resources: action.payload.resource,
        },
        state.filterResource
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(getCalendarData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCalendarData.fulfilled, (state, action) => {
      if (action?.payload?.data?.timeFrom && action?.payload?.data?.timeTo) {
        state.calendarData = action.payload;
        state.startTime = {
          hour: StringToHourMin(action.payload.data.timeFrom, "hour"),
          min: StringToHourMin(action.payload.data.timeFrom, "mins"),
        };
        state.endTime = {
          hour: StringToHourMin(action.payload.data.timeTo, "hour"),
          min: StringToHourMin(action.payload.data.timeTo, "mins"),
        };
        state.resources = ExtractResources(action.payload.data);
        state.filterResource = ExtractResources(action.payload.data);
        state.events = ExtractAppointments(
          action.payload.data,
          action.payload.date
        );
        state.bgevents = ExtractBgAppointments(
          action.payload.data,
          action.payload.date
        );
      } else {
        state.calendarData = [];
        state.events = [];
        state.bgevents = [];
        state.resources = [];
        state.slots = [];
        state.combinedData = [];
        state.filterResource = [];
      }
      state.isLoading = false;
    });
    builder.addCase(getCalendarData.rejected, (state, action) => {
      state.errorMessageCalendar = action.payload;
      state.calendarData = [];
      state.events = [];
      state.bgevents = [];
      state.resources = [];
      state.slots = [];
      state.combinedData = [];
      state.filterResource = [];
      state.isLoading = false;
    });

    builder.addCase(getSlotsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSlotsData.fulfilled, (state, action) => {
      state.slots = ExtractSlots(action?.payload?.data, action?.payload?.date);
      state.combinedData = state.events.concat(state.slots);
      state.isLoading = false;
    });
    builder.addCase(getSlotsData.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(getOpenBooking.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOpenBooking.fulfilled, (state, action) => {
      state.openBooking = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getOpenBooking.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { filterResources } = CreateSlice.actions;
export const calendarReducer = CreateSlice.reducer;
