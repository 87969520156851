import React, { useState, useEffect } from "react";
import Img from "../../assets/scheduler/assests/Group 515408.png";

const Venue = ({ VenueData }) => {
  return (
    <div>
      {/* {address.pickupAddress.map((pickup, index) => ( */}
      <>
        {/* <div >
                  <p>Line 1: </p>
                  <p>Line 2: {pickup.line2}</p>
                  <p>Town/City: {pickup.townCity}</p>
                  <p>Postal Code: {pickup.postalcode}</p>
                </div> */}

        <div className="d-flex flex-wrap justify-content-between pe-0">
          <div className="d-flex flex-wrap justify-content">
            <h1 style={{ fontSize: "12px" }} className="book  pt-1">
              {" "}
              Venue
              {/* {VenueData?.addressName} */}
            </h1>
            
            {/* <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3327 0.666687L11.4993 17.3334L8.16602 9.83335L0.666016 6.50002L17.3327 0.666687Z"
                stroke="#11C85A"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
          </div>

          {/* <div className="d-flex flex-wrap me-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 4" fill="none">
              <path d="M4 2C4 0.895431 3.10457 0 2 0C0.89543 0 0 0.895431 0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2Z" fill="#404145" />
              <path d="M19 2C19 0.895431 18.1046 0 17 0C15.8954 0 15 0.895431 15 2C15 3.10457 15.8954 4 17 4C18.1046 4 19 3.10457 19 2Z" fill="#404145" />
              <path d="M11 2C11 0.895431 10.1046 0 9 0C7.89543 0 7 0.895431 7 2C7 3.10457 7.89543 4 9 4C10.1046 4 11 3.10457 11 2Z" fill="#404145" />
            </svg>
          </div> */}
        </div>
        <div className=" align-items-center d-flex justify-content-start">
          {/* <div className='col-md-2 text-start'> */}
          <div className=" p-0 m-0">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="30" height="30" rx="15" fill="#F0F0F0" />
              <path
                d="M14.1495 22C14.0751 21.9728 14.007 21.9306 13.949 21.8759C13.8911 21.8212 13.8445 21.7551 13.8122 21.6816L9.84032 15.1738C9.19047 14.1519 8.90393 12.9338 9.02847 11.7226C9.12868 10.6394 9.56639 9.6173 10.2775 8.80611C10.8533 8.13617 11.5855 7.62511 12.4064 7.32014C13.2272 7.01518 14.1103 6.92617 14.9738 7.06138C15.8155 7.20076 16.6107 7.5493 17.2891 8.07615C17.9676 8.60299 18.5084 9.292 18.8638 10.0824C19.2193 10.8727 19.3786 11.7401 19.3275 12.6081C19.2764 13.476 19.0166 14.3179 18.571 15.0592L14.6865 21.6561C14.614 21.8021 14.4951 21.9188 14.3493 21.9873L14.1495 22Z"
                stroke="#B5B5B5"
                stroke-width="0.75"
                stroke-miterlimit="10"
              />
              <path
                d="M14.169 14.011C13.8281 14.011 13.4948 13.908 13.2114 13.7149C12.9279 13.5218 12.707 13.2473 12.5765 12.9262C12.4461 12.605 12.4119 12.2516 12.4784 11.9107C12.5449 11.5698 12.7091 11.2566 12.9502 11.0108C13.1912 10.765 13.4983 10.5977 13.8327 10.5299C14.1671 10.4621 14.5136 10.4968 14.8286 10.6298C15.1435 10.7629 15.4127 10.9882 15.6021 11.2772C15.7915 11.5662 15.8926 11.906 15.8926 12.2535C15.8926 12.7197 15.711 13.1667 15.3878 13.4963C15.0645 13.8259 14.6261 14.011 14.169 14.011Z"
                stroke="#B5B5B5"
                stroke-width="0.75"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          {/* <img className='col-md-1 p-0 m-0' style={{ width: "50px", height: '50px' }} src={Img} /> */}

          <div className="text-end ms-2">
            <div className="d-flex flex-wrap justify-content-between ">
              <div className="d-flex flex-wrap text-start ms-0 m-2 justify-content">
                {/* <SlUser style={{ color: "#538dff", fontSize: 25 }} /> */}
                <span
                  style={{ fontSize: "14px", color: "#443F3F" }}
                  className="spand  pt-0 ps-0 "
                >
                  {VenueData && VenueData.addressName
                    ? VenueData.addressName
                    : VenueData?.centreAddress}
                  {/* {VenueData?.centreAddress?.line2} {VenueData?.centreAddress?.postalcode}   {VenueData?.centreAddress?.state} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* ))} */}
    </div>
  );
};

export default Venue;
