import React, { useCallback, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getuserdataafterlogin,
  loginUser,
  resendnewsecondaryemailotp,
  sendotopemail,
  verifysecondaryemail,
} from "../../../Store/Auth/actions";
import Button from "../../../Shared/Button/Button";
import InputField from "../../../Shared/InputField";
import Headerauth from "../Headerauth/Headerauth";
import "../Login/Login.scss";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import SideNavone from "../../../SideNavBar";
import Header from "../../BusinessProfile/Header";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Secondaryemailotpverify(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [isLoadingresend, setIsLoadingresend] = useState(false);
  const [time, setTime] = useState(110); // Initial time in seconds
  const secondaryEmail = useSelector((state)=>state.Auth.secondaryEmail) 
  const dispatch = useDispatch();
  const flag = true;
  const toggle = () => {
    setVisible(!isVisible);
  };
  const userid = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const getdataofregistereduser = useSelector(
    (state) => state.Auth?.alluserdataafterlogin?.secondaryEmail
  );
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  //////////////////////

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setIsActive(false);
            return 110; // Reset the time to 1 minute 50 seconds
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    dispatch(
      getuserdataafterlogin({
        id: registersuccessId,
      })
    );
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);
  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  /////////////////////
  const handleSubmit = () => {
    dispatch(
      verifysecondaryemail({
        otpCode: otp,
        email: getdataofregistereduser,
        userId: userid,
        setIsLoading,
      })
    );
  };
  const resendotpsecondaryemailoncall = async() => {
    await handleReCaptchaVerify();
    if(token){

    dispatch(
      resendnewsecondaryemailotp({
        id: userid,
        type: 2,
        // history: history,
        // setIsLoading,
        raptchaToken: token?token:"string",

        setIsActive,
        setIsLoadingresend,
      })
    );
    }
  };

  const validateOTP = (otp) => {
    const otpRegex = /^[0-9]*$/;
    return otpRegex.test(otp);
  };
  const handleToken = useCallback((token) => {
    setAuthToken(token);
    return token;
  }, []);
  return (
    <>
     {!authToken && <GoogleReCaptcha onVerify={handleToken} />}
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-4">
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4 pt-4">
                <div className=" ">
                  <div className="text-center ">
                    <div className="alignment_vertically">
                    <div className="pb-3">
                    <img
                      className="img-fluid img_plexar_width"
                      src="../images/plexaar final 2 round-15 1.png"
                      alt="no-data"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div>
                      <img
                        className="img-fluid img_plexar_width"
                        src="../images/plexaar text-22 1.png"
                        alt="no-data"
                        style={{ width: "111px", height: "46" }}
                      />
                    </div>
                  </div>
                     
                    <p className="sub_heading mt-4 mb-3">
                    <span style={{ display: "inline" }}>
                      We sent verification code to  <b className="text-black">{secondaryEmail}</b>.
                    </span>
                    <br />
                    Please check your inbox and enter the code below.
                  </p>
                      <div className="col-md-12">
                        <div className="pt-2">
                          <div
                            className="flex-wrap"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={otp}
                              onChange={(otp) => setOtp(otp)}
                              // className="input_style"
                              numInputs={6}
                              renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  style={{
                                    width: "3.5em",
                                    height: "47px",
                                    textAlign: "center",
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="text-left">
                      { validateOTP(otp) ? null :<h6 className="warning_text">Only enter numbers will not accept alphabets and !@#$%^</h6>}
                    </div>
                          <div className="col-md-12 px-0">
                            <div className="col-md-12 text-center">
                              <p className="mb-1 pt-4 having_problrm">
                                Having problem?
                              </p>
                              {isActive && authToken ? (
                                <p className="mb-0 resend_button_disabled">
                                  Resend Code
                                </p>
                              ) : (
                                <p
                                  className="mb-0 resend_button"
                                  onClick={resendotpsecondaryemailoncall}
                                >
                                  Resend Code
                                </p>
                              )}
                              {isLoadingresend ? (
                                <div
                                  className="spinner-border spinner-sm text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              <p className=" having_problrm">
                                {" "}
                                {isActive ? (
                                  <div>{formatTime()}</div>
                                ) : null}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12 px-0 ps-4 pe-4">
                            {otp.length === 6 && validateOTP(otp) ? (
                              <button
                                className="btn btn-primary button_width btn-lg"
                                // onClick={() => history.push(`/create-password`)}
                                onClick={handleSubmit}
                              >
                                {isLoading ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <>Verify Now</>
                                )}
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary button_width_disabled button_width btn-lg"
                                // onClick={() => history.push(`/create-password`)}
                                disabled={!authToken?false:true}
                              >
                                Verify Now
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </SideNavone>
    </>
    // <div className="col-sm-12 px-0 height_page">
    //   <div className="col-sm-12 padding_top_bottom_equal">
    //     <Headerauth />
    //   </div>
    //   <div className="col-md-12 ">
    //     <div className="col-md-12 px-5">
    //       <div className="row">
    //         <div className="col-md-3 background_plexar text-center  d-flex align-items-center  justify-content-center">
    //           <img
    //             className="img-fluid img_plexar_width"
    //             src="../images/plexar/plexar.png"
    //           />
    //         </div>
    //         <div className="col-md-9 border_welcome_to_plexar text-center ">
    //           <div className="col-md-12 alignment_vertically">
    //             <h2>
    //               <b>
    //                 Secondary Email{" "}
    //                 <span className="plexar_font">Verification</span>
    //               </b>
    //             </h2>
    //             <p className="sub_heading mb-0">
    //               Entered the code that was sent to
    //             </p>
    //             <p className="sub_heading mb-0">
    //             <b>{getdataofregistereduser}</b>
    //             </p>

    //             <p className="sub_heading1">{email}</p>
    //             <div className="col-md-12">
    //               <div className="row">
    //                 <div className="col-md-4"></div>
    //                 <div className="col-md-4 pt-3">
    //                   <OtpInput
    //                     value={otp}
    //                     onChange={setOtp}
    //                     className="input_style"
    //                     numInputs={6}
    //                     renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
    //                     renderInput={(props) => <input {...props} />}
    //                   />
    //                    <div className="text-left">
    //                   { validateOTP(otp) ? null :<h6 className="warning_text">Only enter numbers will not accept alphabets and !@#$%^</h6>}
    //                   </div>
    //                   <div className="col-md-12 text-center">
    //                     <p className="mb-1 pt-3 having_problrm">
    //                       Having problem?
    //                     </p>
    //                     {isActive?  <p className="mb-0 resend_button_disabled"  >Resend Code</p> :  <p className="mb-0 resend_button" onClick={resendotpsecondaryemailoncall} >Resend Code</p> }
    //                     {isLoadingresend ? (
    //                           <div
    //                             className="spinner-border spinner-sm text-primary"
    //                             role="status"
    //                           >
    //                             <span className="sr-only">Loading...</span>
    //                           </div>
    //                         ) : (
    //                          null
    //                         )}

    //                    <p className=" having_problrm"> {isActive ? <div>{formatTime()}</div> : null } </p>
    //                   </div>
    //                   <div className="col-md-12 px-0 ">
    //                     {otp.length === 6 && validateOTP(otp)  ? (
    //                       <button
    //                         className="btn btn-primary button_width btn-lg"
    //                         // onClick={() => history.push(`/create-password`)}
    //                         onClick={handleSubmit}
    //                       >
    //                         {isLoading ? (
    //                           <div
    //                             className="spinner-border text-light"
    //                             role="status"
    //                           >
    //                             <span className="sr-only">Loading...</span>
    //                           </div>
    //                         ) : (
    //                           <>Verify Now</>
    //                         )}
    //                       </button>
    //                     ) : (
    //                       <button
    //                         className="btn btn-primary button_width_disabled button_width btn-lg"
    //                         // onClick={() => history.push(`/create-password`)}
    //                         disabled
    //                       >
    //                         Verify Now
    //                       </button>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4"></div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-md-12 bottom_attach ">
    //             <hr />
    //             <p className="sub_heading">
    //               Terms & Conditions. Privacy Policy. Copyright. Cookies Policy.
    //               Help
    //             </p>
    //             <p className="sub_heading">&copy; Selteq Ltd.</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
