import SideNavone from "../../../../SideNavBar";
// import CreateFormMobile from "Pages/InventoryServices/services/create/components/CreateFormMobile";
import ServiceSettings from "./ServiceSettings";
import { useEffect, useRef, useState } from "react";

const ServiceSettingsContainer = () => {
  const [circleColor, setCircleColor] = useState({
    completeBooking: "#7cba4b",
    checkIn: "#F1DC24",
    edit: "#4B8BBA",
    createBooking: "#7cba4b",
  });
  const [visibleColorPicker, setVisibleColorPicker] = useState({
    completeBooking: false,
    checkIn: false,
    edit: false,
    createBooking: false,
  });
  const colorPickerRefs = useRef({});

  const handleColorChange = (color, state) => {
    const updatedCircleColor = { ...circleColor, [state]: color.hex };
    setCircleColor(updatedCircleColor);
  };

  const handleColorPicker = (state, event) => {
    event.stopPropagation();
    const updatedVisibleColorPicker = { ...visibleColorPicker };
    updatedVisibleColorPicker[state] = true;
    setVisibleColorPicker(updatedVisibleColorPicker);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      for (const ref of Object.values(colorPickerRefs.current)) {
        if (ref && ref.contains(e.target)) {
          return;
        }
      }
      setVisibleColorPicker({
        completeBooking: false,
        checkIn: false,
        edit: false,
        createBooking: false,
      });
    });
  }, []);

  const splitWords = (arg) => {
    const words = arg.split(/(?=[A-Z])/);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      for (const ref of Object.values(colorPickerRefs.current)) {
        if (ref && ref.contains(e.target)) {
          return;
        }
      }
      setVisibleColorPicker({
        completeBooking: false,
        checkIn: false,
        edit: false,
        createBooking: false,
      });
    });
  }, []);

  return (
    <>
      <SideNavone>
        <ServiceSettings
          circleColor={circleColor}
          visibleColorPicker={visibleColorPicker}
          colorPickerRefs={colorPickerRefs}
          handleColorChange={handleColorChange}
          handleColorPicker={handleColorPicker}
          splitWords={splitWords}
        />
        {/* <CreateFormMobile /> */}
      </SideNavone>
    </>
  );
};
export default ServiceSettingsContainer;
