import React from "react";
import { Hero } from "../Hero/Hero";
import { CoreControl } from "../CoreControl/CoreControl";
import { NextGen } from "./../NextGen/NextGen";
import { ProcessDetail } from "../ProcessDetail/ProcessDetail";
import { Farewell } from "../Farewell/Farewell";
import { Joinus } from "./../Joinus/Joinus";
import { Banner } from "../Banner/Banner";
import { Footer} from "../../Footer/Footer";

export const Layout = () => {
  return (
    <>
      <Hero />
      <CoreControl />
      <NextGen />
      <ProcessDetail />
      <Farewell />
      <Joinus />
      <Banner />
      <Footer />
    </>
  );
};
