import { Box, Fade, Modal } from "@mui/material";
// import { Form, Formik } from "formik";
import React from "react";
// import ViewPage from "../../../Pages/DynamicForm/viewPage";
// import {
// initialValuesofCustomer,
// validationSchemaofCustomer,
// } from "../../utils/helper";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchCountryRequest } from "../../../Store/Business/actions";
// import { ClipLoader } from "react-spinners";
import AddCustomerForm from "../../../Pages/DynamicForm/addCustomerForm";
const AddCustomerModal = ({
  loading,
  isModalOpen,
  closeModal,
  handleSubmit,
  filterFirstName,
  filterLastName,
  filterEmail,
  filterContactNumber,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "#fbfcff",
    borderRadius: "6px",
    boxShadow: 24,
    pt: 0,
    px: 1,
    pb: 0,
    mt: 0,
    height: 200,
  };
  // const dispatch = useDispatch();
  // const qualification = [
  //   {
  //     id: 1,
  //     value: "PHD",
  //   },
  //   {
  //     id: 2,
  //     value: "Master",
  //   },
  //   {
  //     id: 3,
  //     value: "Bacholar",
  //   },
  // ];
  // var countryList = useSelector((state) => state?.Business?.countryList);

  // useEffect(() => {
  //   dispatch(fetchCountryRequest());
  // }, []);

  const handleFormSubmission = () => {
    closeModal();
  };

  return (
    <>
      {/* <CustomerSearch handleSelectedCustomer={handleSelectedCustomer} /> */}

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={{ ...style, width: 700, height: 550 }}>
            <AddCustomerForm
              isModal={true}
              handleFormSubmission={handleFormSubmission}
              filterFirstName={filterFirstName}
              filterLastName={filterLastName}
              filterEmail={filterEmail}
              filterContactNumber={filterContactNumber}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddCustomerModal;

//<Formik
//              initialValues={initialValuesofCustomer}
//              validationSchema={validationSchemaofCustomer}
//              onSubmit={(values) => {
//                handleSubmit(values);
//              }}
//            >
//              {(options) => {
//                const {
//                  touched,
//                  values,
//                  errors,
//                  setFieldValue,
//                  handleChange,
//                  handleSubmit,
//                  prevScheduleItems,
//                  validateForm,
//                } = options;
//                return (
//                  <Form onSubmit={handleSubmit}>
//                    <div className="pt-2" onClick={closeModal} role="button">
//                      <i
//                        id="icon_left_color"
//                        className=" pe-2 fas fa-chevron-left"
//                      ></i>
//                      <span className=" pt-2 text_heading_customer">
//                        Add New Customer
//                      </span>
//                    </div>
//                    <div className="">
//                      {/* <CustomerSearch handleSelectedCustomer={handleSelectedCustomer} /> */}
//                      <div
//                        className="ps-3 pe-3 hide_scrollbar  mt-2 "
//                        style={{
//                          height: "100vh",
//                          paddingBottom: "100px",
//                          overflow: "auto",
//                        }}
//                      >
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 ">
//                          <label className="label_text_business">
//                            First Name
//                          </label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="text"
//                            id="first-name"
//                            label="First Name"
//                            name="firstname"
//                            value={values.firstname}
//                            onChange={handleChange}
//                            placeholder="Enter First Name"
//                            error={touched.firstname && errors.firstname}
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // placeholder="Enter Organization Name"
//                            // onChange={(event) => {
//                            //     setBusinessName(event.target.value);
//                            //     clearError('businessName'); // Clear the error
//                            //   }}
//                          />
//                        </div>
//                        {errors?.firstname && (
//                          <span className="text-danger font_size_error">
//                            {errors.firstname}
//                          </span>
//                        )}
//
//                        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//          <label className="label_text_business">Organization Type</label>
//          <input
//            className="form-control1 input_style_fix"
//            type="text"
//            id="business-type"
//            label="Organization Type"
//            name="businessType"
//            placeholder="Enter Organization Type"
//            onChange={handleChange}
//            value={values.businessType}
//            error={touched.businessType && errors.businessType}
//            // placeholder="Enter Organization Type"
//            // onChange={(event) => {
//            //     setBusinessType(event.target.value)
//            //         clearError('businessType'); // Clear the error
//            //   }}
//          />
//        </div> */}
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-2 ">
//                          <label className="label_text_business">
//                            Last Name
//                          </label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="text"
//                            id="last-name"
//                            label="Last Name"
//                            name="lastname"
//                            value={values.lastname}
//                            onChange={handleChange}
//                            placeholder="Enter Last Name"
//                            // error={touched.businessName && errors.businessName}
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // placeholder="Enter Organization Name"
//                            // onChange={(event) => {
//                            //     setBusinessName(event.target.value);
//                            //     clearError('businessName'); // Clear the error
//                            //   }}
//                          />
//                        </div>
//                        {errors?.lastname && (
//                          <span className="text-danger m-0 font_size_error">
//                            {errors.lastname}
//                          </span>
//                        )}
//                        <div className="col-md-12  border_rad_new px-2 pb-2 mt-2 mb-3 ">
//                          <label className="label_text_business">Email</label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="email"
//                            id="email"
//                            label="Email "
//                            name="email"
//                            value={values.email}
//                            onChange={handleChange}
//                            placeholder="Enter Email "
//                            // error={touched.businessName && errors.businessName}
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // placeholder="Enter Organization Name"
//                            // onChange={(event) => {
//                            //     setBusinessName(event.target.value);
//                            //     clearError('businessName'); // Clear the error
//                            //   }}
//                          />
//                        </div>
//                        {errors?.email && (
//                          <span className="text-danger m-0 font_size_error">
//                            {errors.email}
//                          </span>
//                        )}
//
//                        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//          <label className="label_text_business">Industry</label>
//          <input
//            className="form-control1 input_style_fix"
//            type="text"
//            placeholder="Industry Type"
//            id="industry"
//            label="Industry"
//            name="industry"
//            onChange={handleChange}
//            value={values.industry}
//            // //size={20}
//            variant="filled"
//            error={touched.industry && errors.industry}
//          />
//        </div>
//        {errors.industry && (
//          <span className="text-danger font_size_error">{errors.industry}</span>
//        )} */}
//                        <span className=" pt-2 pb-2 text_heading_customer">
//                          Address
//                        </span>
//                        {/* {errors.industry && (
//          <span className="text-danger font_size_error">
//            {errors.industry}
//          </span>
//        )} */}
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//                          <label className="label_text_business">Mobile</label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="number"
//                            id="mobile"
//                            label="Mobile"
//                            name="mobile"
//                            value={values.mobile}
//                            onChange={handleChange}
//                            placeholder="Enter Mobile Number"
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // //size={20}
//                            // error={touched.website && errors.website}
//                            // onChange={(event) => setWebsiteurl(event.target.value)}
//                          />
//                        </div>
//                        {errors.mobile && (
//                          <span className="text-danger font_size_error">
//                            {errors.mobile}
//                          </span>
//                        )}
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//                          <label className="label_text_business">
//                            Address Line 1
//                          </label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="text"
//                            id="address1"
//                            label="Address Line 1"
//                            name="address1"
//                            value={values.address1}
//                            onChange={handleChange}
//                            placeholder="Enter Address Line 1"
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // //size={20}
//                            // error={touched.website && errors.website}
//                            // onChange={(event) => setWebsiteurl(event.target.value)}
//                          />
//                        </div>
//                        {/* {errors.address1 && (
//                                  <span className="text-danger font_size_error">
//                                    {errors.address1}
//                                  </span>
//                                )} */}
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-2 mb-3">
//                          <label className="label_text_business">
//                            Address Line 2
//                          </label>
//                          <input
//                            className="form-control1 input_style_fix"
//                            type="text"
//                            id="address2"
//                            label="Address Line 2"
//                            name="address2"
//                            value={values.address2}
//                            onChange={handleChange}
//                            placeholder="Enter Address Line 2"
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            // //size={20}
//                            // error={touched.website && errors.website}
//                            // onChange={(event) => setWebsiteurl(event.target.value)}
//                          />
//                        </div>
//
//                        <span className=" pt-2 pb-2 text_heading_customer">
//                          Additional info
//                        </span>
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//                          <label className="label_text_business">
//                            Nationality
//                          </label>
//
//                          <select
//                            type="text"
//                            name="nationality"
//                            className="form-control1 changecolor m-0  p-0 "
//                            id="nationality"
//                            value={values.nationality}
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            onChange={(e) =>
//                              setFieldValue("nationality", e.target.value)
//                            }
//                          >
//                            <option value={""}>Select Nationality</option>
//                            {countryList?.countries &&
//                              countryList?.countries?.map((v, i) => {
//                                return (
//                                  <option key={i} value={v?.id}>
//                                    {v?.name}
//                                  </option>
//                                );
//                              })}
//                          </select>
//                        </div>
//                        {/* {errors.nationality && (
//                                  <span className="text-danger font_size_error">
//                                    {errors.nationality}
//                                  </span>
//                                )} */}
//                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-2 ">
//                          <label className="label_text_business">
//                            Higest Qualification
//                          </label>
//
//                          <select
//                            type="text"
//                            name="qualification"
//                            className="form-control1 changecolor m-0  p-0 "
//                            id="qualification"
//                            value={values.qualification}
//                            style={{
//                              border: "none",
//                              outline: "none",
//                              padding: "0",
//                              margin: "0",
//                            }}
//                            onChange={(e) =>
//                              setFieldValue("qualification", e.target.value)
//                            }
//                          >
//                            <option value={""}>Highest Qualification</option>
//                            {qualification &&
//                              qualification?.map((v, i) => {
//                                return (
//                                  <option key={i} value={v?.id}>
//                                    {v?.value}
//                                  </option>
//                                );
//                              })}
//                          </select>
//                        </div>
//
//                        {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
//          <label className="label_text_business">Organization Description</label>
//          <textarea
//            className="form-control1 input_style_fix"
//            rows={3}
//            type="text"
//            id="description"
//            label="Description"
//            name="description"
//            onChange={handleChange}
//            placeholder="Type Description Here..."
//            // //size={20}
//            value={values.description}
//            error={touched.description && errors.description}
//            // onChange={(event) => setBusinessDes(event.target.value)}
//          ></textarea>
//        </div> */}
//
//                        <div className="mt-3">
//                          <Button
//                            type="submit"
//                            sx={{
//                              // width: isMobile ? "100%" : "150px",
//                              backgroundColor: "#ffbf00",
//                              height: "40px",
//                              paddingLeft: "15px",
//                              paddingRight: "15px",
//
//                              borderRadius: "10px",
//                              width: "100%",
//                              "&:hover": {
//                                backgroundColor: "#ffbf00",
//                                color: "#ffffff",
//                              },
//                              textTransform: "capitalize",
//                            }}
//                            className=""
//                            onClick={() => {
//                              // handleSubmit();
//                            }}
//                            variant="contained"
//                            // disabled={loading}
//                          >
//
//                            {loading ? <ClipLoader color="#fffff" size={30} /> : "Continue"}
//                          </Button>
//                        </div>
//                      </div>{" "}
//                    </div>
//                  </Form>
//                );
//              }}
//            </Formik>
