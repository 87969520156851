import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, TextField } from "@mui/material";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadis: "10px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  businessId,
  open,
  handleClose,
  activeProvider,
}) {
  const [providerData, setProviderData] = React.useState();

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/detail?provider=${activeProvider.id}`
      )
      .then((res) => {
        setProviderData(res.data.result);
        AddSchedule();
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleYearMonthDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const AddSchedule = () => {
    const now = new Date();
    const seventhDate = new Date(now);
    seventhDate.setDate(now.getDate() + 6);

    const startDate = handleYearMonthDate(now);
    const endDate = handleYearMonthDate(seventhDate);
    // const end = formatTime(endTime);
    // const data = {
    //   businessId: businessId,
    //   businessStartTime: `${start}:00`,
    //   businessEndTime: `${end}:00`,
    // };
    const data = {
      providerId: [activeProvider.id],
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/schedule/`,
        data
      )
      .then((res) => {
        console.warning(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="py-1"
          >
            {activeProvider.firstName} Schedule
          </Typography>
          <div className="w-100 d-flex justify-content-between align-items-center py-3 px-2">
            <p>
              Schedule Type : <b>{providerData?.repeatChoice}</b>
            </p>
            <p>
              Repeat Type : <b>{providerData?.repeatPattern}</b>
            </p>
            <p>
              {" "}
              Start Date : <b>{providerData?.startDate}</b>
            </p>
            <p>
              {" "}
              End Date : <b>{providerData?.endDate}</b>
            </p>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center py-3 px-2">
            <div className="w-25 d-flex justify-content-betweens align-items-center">
              <Button variant="text">Cancel</Button>
              <Button variant="contained">Add</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
