import {
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  DELETE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
} from './actionType';
import produce from "immer";


const initialState = {
  rowData: [],
  data: [],
  record:null,
  loading: false,
  result:"",
  updatedRecord:"",
  error: null,
};

const Team = produce((state, action) => {
  switch (action.type) {
    case FETCH_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        rowData: action.payload,
      };
    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case ADD_TEAM_REQUEST:
          state.record=action.payload;
        break;

      case ADD_TEAM_SUCCESS:
          state.result=action.payload;
          break;
      case UPDATE_TEAM_REQUEST:
            state.updatedRecord=action.payload;
            break;
     case DELETE_TEAM_SUCCESS: {
      const { payload: id } = action;
      return {
        ...state,
        rowData: state.rowData.filter((team) => team.id !== id),
        error: null,
      };
    }
    case DELETE_TEAM_FAILURE: {
      const { payload: error } = action;
      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
}, initialState);
export default Team;
