import axios from "axios";
import instance from "../../../utils/utils";
const FormData = require("form-data");

export const getQuestions = async (section) => {
  let responseData;
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `/genericform_svc/pb/get_form?formId=3&section=${section}`,
    // headers: {},
    data: data,
  };

  try {
    const response = await instance.request(config);
    responseData = response.data;
  } catch (error) {
    console.error(error);
  }

  return responseData;
};

export const uploadMedia = async (id, media) => {
  let responseData;
  let data = new FormData();
  data.append(id, media);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url:
      `${process.env.REACT_APP_BASE_URL}/genericform_svc/pb/upload/media/?formId=23&questionType=video&userId=1`,
    headers: {},
    data: data,
  };

  await axios
    .request(config)
    .then((response) => {
      console.warning(JSON.stringify(response.data));
      responseData = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  return responseData;
};

export const uploadAnswers = async (answers) => {
  let responseData;
  let data = answers;

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/genericform_svc/pb/add/answers/bulk/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  await axios
    .request(config)
    .then((response) => {
      console.warning(JSON.stringify(response.data));
      responseData = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  return responseData;
};

export const uploadSignature = async (media, userId, formId) => {
  let data = new FormData();
  data.append("image", media);
  data.append("formId", formId);
  data.append("userId", userId);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://genericformsapi-preprod.findanexpert.net/upload/signature/",
    headers: {},
    data: data,
  };

  await axios
    .request(config)
    .then((response) => {
      console.warning(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.error(error);
    });
};
