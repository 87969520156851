// import * as React from "react";
// import { TimePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import TextField from "../TextField";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { styled } from "@mui/material/styles"; // Import the styled function from MUI styles

// // Create a new styled component with the desired styles
// const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
//   width: 150,
//   backgroundColor: "black",
//   // Add more custom styles here if needed
// }));

// const InputTime = ({
//   label = "Time",
//   format = "hh:mm",
//   views = ["hours", "minutes"],
//   name,
//   value = "",
//   minTime,
//   maxTime,
//   disabled = false,
//   onChange = () => {},
//   helperText = null,
//   inputFormat = "HH:mm a",
//   defaultValue,
//   sx,
// }) => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <TimePicker
//         inputFormat={inputFormat}
//         minTime={minTime}
//         maxTime={maxTime}
//         disabled={disabled}
//         value={value}
//         onChange={onChange}
//         views={views}
//         sx={sx}
//         format={format}
//         defaultValue={defaultValue}
//         slotProps={{ textField: { size: "small", error: false } }}
//         ampm={false}
//         skipDisabled={true}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             name={name}
//             size={"small"}
//             variant={"outlined"}
//             helperText={helperText}
//             error={false}
//           />
//         )}
//       />
//     </LocalizationProvider>
//   );
// };

// export default InputTime;
import * as React from "react";
import TextField from "../TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { defaultFormat } from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import dayjs from "dayjs"
const InputTime = ({
  label = "Date",
  name,
  value = "",
  minTime,
  maxTime,
  disabled = false,
  onChange = () => {},
  helperText = null,
  format , // Set the desired format ,
  defaultValue = new Date(),
  defaultCalendarMonth,
  sx,
  textColor
}) => {
  const formattedValue = dayjs(value).format(format);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        className="custom-text-field custom-date-picker"
        // format={format}
       
        disabled={disabled}
        views={['hours', 'minutes']}
        defaultCalendarMonth={defaultCalendarMonth}
        // label={label}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        sx={sx}
        slotProps={{
          textField: {
            size: "small",
            error: false,
            InputProps: {
              sx: {
                color: textColor, // Set the text color here
              },
            },
          },
        }}
        // classes={{
        //   root: "!bg-primary !text-secondary custom-date-picker-popup",
        // }}
        textField={(params) => (
          <TextField
            {...params}
            name={name}
            size={"small"}
            variant={"standard"}
            helperText={helperText}

          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputTime;
