import React, { useState, useRef, useEffect, useCallback } from "react";
import SideNavone from "../../SideNavBar";
import ChatNotes from "../../Componenets/ChatNotes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { customSnackBar } from "../../utils";
import { socket } from "../../socket"; // Adjust the path accordingly

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import NotesLoading from "../../Componenets/NotesLoading";
import { ArrowBack } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StopIcon from "@mui/icons-material/Stop";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ZoomableImageModal from "./ImageModal";
import moment from "moment";
const Notes = () => {
  const history = useHistory();
  const [media, setMedia] = useState({
    recordedVideoBlob: undefined,
    recorderAudioBlob: undefined,
    capturedImageBlob: undefined,
    selectedVideo: null,
    selectedImage: null,
    selectedAudio: null,
  });
  const [permissions, setPermissions] = useState({
    isAudioRecording: false,
    isVideoRecording: false,
    isCapturingImage: false,
  });
  const [timer, setTimer] = useState(0);
  const [isOpenedCamera, setIsOpenedCamera] = useState(false);
  const canvasRef = useRef(null);
  const bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const [messageInput, setMessageInput] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [notes, setNotes] = useState([]);
  const [showFileDialog, setShowFileDialog] = useState(false);
  const [moreOptions, setMoreOptions] = useState(null);
  const [loading, SetLoading] = useState(false);
  const recorderRef = useRef(null);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedImages, setSelectedImage] = useState(null);
  const [recieveMessages, setRecieveMessages] = useState("");
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
  const id = bookingData?.providerId + "." + bookingData?.customerId;

  const [shouldFetchData, setShouldFetchData] = useState(true);
  const storedNotes = localStorage.getItem("notesData");
  const [storedData, setStoredData] = useState(
    storedNotes ? JSON.parse(storedNotes) : []
  );

  // ... (your existing code)

  const fetchNotesData = useCallback(async () => {
    try {
      setFetchedData(true);
      const originalUrl = process.env.REACT_APP_SOCKET_URL;

      const parts = originalUrl.split("/");
      parts.pop();
      const newUrl = parts.join("/");

      const response = await axios.get(
        `${newUrl}/api/message/get/${id}?page=1`
      );
      const data = await response.data;
      setNotes(data?.data);
      setFetchedData(false);

      // Update local storage with the latest data
      localStorage.setItem("notesData", JSON.stringify(data?.data));

      // Set shouldFetchData to false after successful data fetch
      setShouldFetchData(false);
    } catch (e) {
      // Handle error
    } finally {
      setFetchedData(false);
    }
  }, [id]);

  useEffect(() => {
    // Listen for incoming messages
    socket.connect();
    socket.on("ios_prv_message", (message) => {
      // Update the UI with the message
      setNotes((prevNotes) => [...prevNotes, message]);
      // Update local storage with the new message
      localStorage.setItem(
        "notesData",
        JSON.stringify([...storedData, message])
      );
    });

    // Clean up when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [storedData]);

  const handleSendMessage = () => {
    const newMessage = {
      conversationId: id,
      text: messageInput,
      from: bookingData?.providerId,
      to: bookingData?.customerId,
      createdAt: new Date().toISOString(),
    };

    // Update the state to include the new message
    setNotes((prevNotes) => [...prevNotes, newMessage]);

    // Emit the message through the socket
    socket.emit("ios_prv_message", newMessage);

    // Clear the message input
    setMessageInput("");
  };
  useEffect(() => {
    if (shouldFetchData) {
      fetchNotesData();
    }
  }, [shouldFetchData, fetchNotesData]);

  return (
    <SideNavone>
      <Box
        sx={{
          position: "relative",
          paddingLeft: "20px",
          paddingRight: "20px",
          bgcolor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundColor: "#fff",
            height:
              media.selectedImage === null &&
              media.selectedAudio === null &&
              media.selectedVideo === null &&
              media.recorderAudioBlob === undefined &&
              media.recordedVideoBlob === undefined &&
              media.capturedImageBlob === undefined
                ? "84vh"
                : (media.recorderAudioBlob !== undefined ||
                    media.selectedAudio !== null) &&
                  media.selectedImage === null &&
                  media.selectedVideo === null &&
                  media.recordedVideoBlob === undefined &&
                  media.capturedImageBlob === undefined
                ? "76vh"
                : "68vh",
            overflowY: "auto",
            //   marginBottom: 2,
            display: "flex",
            flexDirection: "column-reverse",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            // position: "relative",
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
          }}
        >
          <IconButton
            className="me-1 mt-3"
            color={"primary"}
            onClick={() => history.goBack()}
            aria-label={"Start Recording"}
            sx={{
              position: "absolute",
              top: "1%",
              left: "0.5%",
              zIndex: "999999",

              color: "#FFD705",
              // border: "0.5px solid #FFD705",
            }}
          >
            <ArrowBack />
          </IconButton>
          <div
            style={{
              position: "absolute",
              top: "1%",
              left: "3%",
              zIndex: "999999",
              color: "#000",
            }}
            className="d-flex chat-card "
          >
            <div className="d-flex justify-content-evenly">
              <Avatar
                className="img-fluid img_profile_shape"
                alt="nodata"
                style={{ width: 50, height: 50 }}
              />

              <div className="">
                <h6 className="mt-3 ms-1 ">
                  <b>{bookingData?.providerName} </b>
                </h6>
              </div>
            </div>
          </div>

          <List>
            {fetchedData ? (
              <NotesLoading />
            ) : (
              notes?.map((item, index) => {
                const isSender = item.from != bookingData?.providerId;
                const messageTimestamp = new Date(
                  item.createdAt
                ).toLocaleTimeString();

                return (
                  <div
                    className={`ps-4 pe-4 d-flex ${
                      isSender ? "justify-content-end" : "justify-content-start"
                    } 
                  `}
                  >
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: isSender ? "row" : "row-reverse",
                        // justifyContent: isSender ? "flex-end" : "flex-start" ,
                      }}
                    >
                      <div>
                        {/* {isSender !== bookingData.providerId && ( */}
                        <Avatar
                          // src={item.created_by_img_url}
                          sx={{
                            width: 40,
                            height: 40,
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        />
                        {/* )} */}
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "10px",
                            marginRight: "10px",
                            marginLeft: "10px",
                            color: "rgb(171 166 166)",
                          }}
                        >
                          {item.createdAt === undefined
                            ? "Just Now"
                            : moment(messageTimestamp, "HH:mm:ss").format(
                                "h:mm A"
                              )}
                          {/* {  moment(messageTimestamp).format("h:mm a")} */}
                          {/* {messageTimestamp} */}
                          {/* {item.created_by_name} */}
                        </div>
                      </div>

                      <Box
                        component="div"
                        sx={{
                          backgroundColor: isSender ? "#cacaca" : "#FFD705",
                          padding: "6px 15px 6px 15px",
                          borderRadius: isSender
                            ? "0px 20px 20px 20px"
                            : "20px 0px 20px 20px",
                          width: "fit-content",
                          color: isSender ? "black" : "white",
                        }}
                      >
                        {item.text && (
                          <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                              <ListItemText
                                sx={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                <span
                                  className="text-capitalize"
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: "14px",
                                    // lineHeight: "16px",
                                    display: "flex",
                                    alignItems: "center",

                                    justifyContent: !isSender?"end":"start",
                                  }}
                                >
                                  {!isSender
                                    ? bookingData?.providerName
                                    : `${bookingData?.customerFirstName} ${bookingData?.customerLastName}`}
                                </span>
                              </ListItemText>
                              <ListItemText
                                sx={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                <Typography
                                  className={`text-capitalize `}
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    whiteSpace: "pre-line", // Allow newlines to be rendered
                                  }}
                                >
                                  {item?.text &&
                                    item.text
                                      .match(/.{1,60}/g) // Split the text into chunks of 60 characters
                                      .map((chunk, index) => (
                                        <React.Fragment key={index}>
                                          {chunk}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                </Typography>
                              </ListItemText>

                              {/* {item.created_by === bookingData.providerId && (
                              <Avatar
                                src={item.created_by_img_url}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginLeft: "10px",
                                }}
                              />
                            )} */}
                            </div>
                          </div>
                        )}
                      </Box>
                    </ListItem>
                  </div>
                );
              })
            )}
          </List>
        </Box>

        <form onSubmit={{}}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Message"
              fullWidth
              size="small"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              sx={{
                marginRight: 1,
                backgroundColor: "white",
                borderRadius: "6px",
                border: "1px solid #548DFF",
              }}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton
              //         color="primary"
              //         component="span"
              //         aria-label="Attach File"
              //         onClick={handleOpenFileDialog}
              //         sx={{
              //           border: "1px solid #548DFF",
              //         }}
              //       >
              //         <AttachFileIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />

            <Button
              sx={{
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "4px",
                textTransform: "capitalize",
                zIndex: "99999",
              }}
              variant="contained"
              type="submit"
              aria-label="Send Message"
              disabled={
                !messageInput &&
                !media.selectedVideo &&
                !media.recorderAudioBlob &&
                !media.selectedImage &&
                !media.selectedAudio &&
                !media.recordedVideoBlob &&
                !media.capturedImageBlob
              }
              onClick={handleSendMessage}
            >
              {loading ? (
                <CircularProgress
                  size={30}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                "Send"
              )}
            </Button>
            {/* )} */}
          </Box>
        </form>
      </Box>
    </SideNavone>
  );
};
export default Notes;
