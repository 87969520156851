import React, { useState, useEffect } from "react";
import Styles from "./InputField.module.css";
import QuestionSection from "../QuestionSection/QuestionSection";

const DropDown = ({
  text,
  options,
  onInputChange,
  id,
  formId,
  inputType,
  section,
  handleUploadMedia,
  uploadedData,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [answerValue, setAnswerValue] = useState();
  const [dropdownId, setDropDownId] = useState();

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const answer = option.choiceText;
    setAnswerValue(answer);
  };

  const handleInputChange = (answer) => {
    onInputChange(id, answer, formId, text, inputType, section, 1);
  };

  const handleClick = (option) => {
    setDropDownId(option.id);
  };

  useEffect(() => {
    handleInputChange(answerValue);
  }, [answerValue]);

  return (
    <>
      <div className={Styles.textQuestionContainer}>
        <p>{text}</p>
        <div className={Styles.radioButtonAlign}>
          {options.map((option, index) => (
            <label key={option.id} className={Styles.labelRadio}>
              <input
                className={Styles.radioBtn}
                type="radio"
                name="option"
                value={option.id}
                checked={selectedOption && selectedOption.id === option.id}
                onChange={() => handleOptionSelect(option)}
                onClick={() => handleClick(option)}
                onBlur={() => {
                  setAnswerValue(option?.choiceText);
                }}
              />
              <p>{option.choiceText}</p>
            </label>
          ))}
        </div>
      </div>

      {options?.map((item3) => {
        if (item3.id === dropdownId) {
          return (
            <QuestionSection
              key={item3.id}
              questionsData={item3.childs}
              onInputChange={onInputChange}
              formId={formId}
              section={section}
              handleUploadMedia={handleUploadMedia}
              uploadedData={uploadedData}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default DropDown;
