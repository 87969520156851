import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { GoHome } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { tabConfigs } from "../../../utils";

// const MenuTabList = ({ type, value }) => {
//   const tabs = tabsList[type] ?? [];

//   return (
//     <div className="d-flex">
//       {/* <Box
//         sx={{
//           width: "15%",
//           bgcolor: "background.paper",
//           border: "1px solid #ededed",
//           padding: "4px",
//         }}
//       >
//         <div className="ms-3">
//           <div className="d-flex fle ">
//             <h6 style={{ margin: 0, fontSize: "16px" }}>Selteq IT Solution</h6>
//           </div>
//           <div className="d-flex fle ">
//             <span style={{ color: "#a1a1a1", margin: 0, fontSize: "12px" }}>
//               Business
//             </span>
//           </div>
//         </div>
//       </Box> */}
      
//       {/* <Box
//         sx={{
//           flex: 1,
//           border: "1px solid #ededed",
//           bgcolor: "background.paper",
//         }}
//       >
//         <Tabs value={type} className="tabs-container">
//           {tabs.map((tab, i) => (
//             <div className={`tab pt-3 ps-3 px-2  "active"`}>
//               <NavLink exact to={tab.url} className="link" key={tab.id + i}>
//                 {tab.name === "Business List" ? (
//                   <>
//                     <GoHome className="homeIcon me-2 mb-1" />
//                     {tab.name}
//                   </>
//                 ) : (
//                   tab.name
//                 )}
//               </NavLink>
//               <div className="active-indicator" />
//             </div>
//           ))}
//         </Tabs>
//       </Box> */}
//     </div>
//   );
// };
const MenuTabList = () => {
  return (
    <div className="d-flex">
      <Box
        sx={{
          flex: 1,
          border: "1px solid #ededed",
          bgcolor: "background.paper",
        }}
      >
        <Tabs sx={{
          ".css-jpln7h-MuiTabs-scroller":{
            position:"unset",
            borderBottom:"0px"
          }
        }}value={0} className="tabs-container">
          {tabConfigs.map((tab,i) => (
            <div
              className={`tab pt-3 ps-3 px-2  "active"`}
              key={tab.id}
            >
              <NavLink exact to={tab.url} className="link" key={tab.id + i}>
                {tab.name === "Business List" ? (
                  <>
                    <GoHome className="homeIcon me-2 mb-1" />
                    {tab.name}
                  </>
                ) : (
                  tab.name
                )}
              </NavLink>
              <div className="active-indicator" />
            </div>
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default MenuTabList;

