// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoreControl_coreControlSection1__Q2mUy {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin: 0 14px;
}
.CoreControl_mobileCoreText__HUFOm {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
  width: 190px;
  line-height: 1.2;
  margin-bottom: 15vw;
}
.CoreControl_mobileCoreText__HUFOm span {
  color: #548dff;
}
.CoreControl_coreControlImageContainer__1dnTv {
  width: 55%;
}
.CoreControl_coreControlImageContainer__1dnTv img {
  width: 100%;
}
.CoreControl_coreControlContainer__DAqtj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CoreControl_coreControlSection2__uyQh5 {
  background: #f5faff;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/MobileHomePage/CoreControl/CoreControl.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".coreControlSection1 {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: flex-end;\n  margin: 0 14px;\n}\n.mobileCoreText {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 22px;\n  color: #000000;\n  width: 190px;\n  line-height: 1.2;\n  margin-bottom: 15vw;\n}\n.mobileCoreText span {\n  color: #548dff;\n}\n.coreControlImageContainer {\n  width: 55%;\n}\n.coreControlImageContainer img {\n  width: 100%;\n}\n.coreControlContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.coreControlSection2 {\n  background: #f5faff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coreControlSection1": `CoreControl_coreControlSection1__Q2mUy`,
	"mobileCoreText": `CoreControl_mobileCoreText__HUFOm`,
	"coreControlImageContainer": `CoreControl_coreControlImageContainer__1dnTv`,
	"coreControlContainer": `CoreControl_coreControlContainer__DAqtj`,
	"coreControlSection2": `CoreControl_coreControlSection2__uyQh5`
};
export default ___CSS_LOADER_EXPORT___;
