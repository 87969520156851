import React from 'react'
import DetailsForm from './components/DetailsForm'
import Topbar from './components/Topbar';
import SideMenu from '../../side-menu';
import "../../../../Assets/components/meetspace/events.scss";
import "../../../../Assets/Scss/___global.scss";

const CandidateDetails = () => {
    return (
        <>
            <SideMenu topbar={<Topbar/>}>
                <DetailsForm />
            </SideMenu>
        </>
    )
}

export default CandidateDetails;