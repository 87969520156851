import {
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,

} from "./actionType";

// Action creators
export const fetchTeamRequest = (id) => ({
  type: FETCH_TEAM_REQUEST,
  payload:id,
});

export const fetchTeamSuccess = (data) => {
  return {
    type: FETCH_TEAM_SUCCESS,
    payload: data,
  };
};

export const fetchTeamFailure = (error) => ({
  type: FETCH_TEAM_FAILURE,
  payload: error,
});

export const addTeamRequest = (data) => {
  return{
  type: ADD_TEAM_REQUEST,
  payload:data
  };
};
export const addTeamSuccess = (data) => {
  return{
  type: ADD_TEAM_SUCCESS,
  payload:data
  };
};

export const addTeamFailure = (error) => ({
  type: ADD_TEAM_FAILURE,
  payload: error,
});

export const updateTeamRequest = (data) => {
  return{
  type: UPDATE_TEAM_REQUEST,
  payload:data
  };
};

export const updateTeamSuccess = (data) => {
  return{
  type: UPDATE_TEAM_SUCCESS,
  payload: data,
}
}


export const updateTeamFailure = (error) => ({
  type: UPDATE_TEAM_FAILURE,
  payload: error,
});
export const deleteTeamRequest = (id) => {
  return{
  type: DELETE_TEAM_REQUEST,
  payload: id,
  }
};

export const deleteTeamSuccess = (id) => {
  return{
  type: DELETE_TEAM_SUCCESS,
  payload: id,
  }
}

export const deleteTeamFailure = (error) => ({
  type: DELETE_TEAM_FAILURE,
  payload: error,
});
