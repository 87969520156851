import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Button, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceAttributesExpert,
  getServiceToUpdateExpert,
} from "../../../Pages/InventoryServices/store/servicesActions";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { BsThreeDotsVertical } from "react-icons/bs";
import "../../assets/scheduler/css/calendar.css";
import { DataNotFound, customSnackBar } from "../../../utils";
import { ClipLoader } from "react-spinners";
import CustomizedSnackbars from "./AlertToast";
import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
import debounce from "lodash.debounce";
import { serviceImageURL } from "../../../utils/imageUrlsExtension";
const ServiceAttribute = ({
  providerAvailabilty,
  selectedService,
  setSelectedFlag,
  action,
  selectedSlot,
  setSelectSlotFlag,
  setSelectedService,
  loaders,
  dialog,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const service_store_expert = useSelector(
    (state) => state?.servicesReducer?.expertServiceToUpdate[0]
  );
  const service_store_expert_attributes = useSelector(
    (state) => state?.servicesReducer?.serviceAttributesExpert
  );
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const [loading, setIsLoading] = useState(false);
  const [loader, setIsLoader] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDurations, setTotalDuration] = useState(0);
  const [selectedAttributeDetails, setSelectedAttributeDetails] = useState([]);
  const [selectedAttributeDetail, setSelectedAttributeDetail] = useState({});

  // const handleAttributeClick = (
  //   id,
  //   attributeType,
  //   attributePrice,
  //   duration,
  //   sku
  // ) => {
  //   // Toggle the selection state of the clicked attribute
  //   // setSelectedAttributes((prevSelected) =>
  //   //   prevSelected.includes(attributeType)
  //   //     ? prevSelected.filter((id) => id !== attributeType)
  //   //     : [...prevSelected, attributeType]
  //   // );
  //   const isAttributeSelected = selectedAttributes.includes(id);

  //   // Update states only if the attribute is not already selected
  //   if (!isAttributeSelected) {
  //     // Toggle the selection state of the clicked attribute
  //     setSelectedAttributes([id]);
  //     setSelectedAttributeDetail({
  //       id: id,
  //       serviceSKU: sku,
  //       duration: duration,
  //       name: attributeType,
  //     });
  //   }

  //   // Check if the attributeType is already selected
  //   const updatedAttributeDetails = isAttributeSelected
  //     ? selectedAttributeDetails.filter((attr) => attr.value !== attributeType)
  //     : [
  //         ...selectedAttributeDetails,
  //         {
  //           value: attributeType,
  //           attributePrice,
  //           duration,
  //           attributeSku: sku,
  //           isChecked: true,
  //           // Add other attributes as needed
  //         },
  //       ];
  //   // Calculate the total price and duration
  //   const newTotalPrice = isAttributeSelected
  //     ? totalPrice - attributePrice
  //     : totalPrice + attributePrice;

  //   const newTotalDurations = isAttributeSelected
  //     ? totalDurations - duration
  //     : totalDurations + duration;

  //   // Update states
  //   setSelectedService([
  //     {
  //       ...selectedService,
  //       bookedSeats: 1,
  //       duration: newTotalDurations,
  //       actualPrice: newTotalPrice,
  //       attributes: updatedAttributeDetails,
  //     },
  //   ]);
  //   // setSelectedAttributeDetails(updatedAttributeDetails);

  //   setTotalPrice(newTotalPrice);
  //   setTotalDuration(newTotalDurations);
  // };

  const debouncedHandleAttributeClick = useCallback(
    debounce((id, attributeType, attributePrice, duration, sku) => {
      // setSelectedAttributes((prevSelected) =>
      //   prevSelected.includes(attributeType)
      //     ? prevSelected.filter((id) => id !== attributeType)
      //     : [...prevSelected, attributeType]
      // );
      const isAttributeSelected = selectedAttributes.includes(id);

      // Update states only if the attribute is not already selected
      if (!isAttributeSelected) {
        // Toggle the selection state of the clicked attribute
        setSelectedAttributes([id]);
        setSelectedAttributeDetail({
          id: id,
          serviceSKU: sku,
          duration: duration,
          name: attributeType,
        });
      }

      // Check if the attributeType is already selected
      const updatedAttributeDetails = isAttributeSelected
        ? selectedAttributeDetails.filter(
            (attr) => attr.value !== attributeType
          )
        : [
            ...selectedAttributeDetails,
            {
              value: attributeType,
              attributePrice,
              duration,
              attributeSku: sku,
              isChecked: true,
              // Add other attributes as needed
            },
          ];
      // Calculate the total price and duration
      const newTotalPrice = isAttributeSelected
        ? totalPrice - attributePrice
        : totalPrice + attributePrice;

      const newTotalDurations = isAttributeSelected
        ? totalDurations - duration
        : totalDurations + duration;

      // Update states
      setSelectedService([
        {
          ...selectedService,
          bookedSeats: 1,
          duration: newTotalDurations,
          actualPrice: newTotalPrice,
          attributes: updatedAttributeDetails,
        },
      ]);
      // setSelectedAttributeDetails(updatedAttributeDetails);

      setTotalPrice(newTotalPrice);
      setTotalDuration(newTotalDurations);
    }, 300), // Adjust the debounce delay (in milliseconds) as needed
    [] // Ensure this callback doesn't change between renders
  );

  // Handle attribute click events
  const handleAttributeClick = (
    id,
    attributeType,
    attributePrice,
    duration,
    sku
  ) => {
    // Call the debounced function with the provided arguments
    debouncedHandleAttributeClick(
      id,
      attributeType,
      attributePrice,
      duration,
      sku
    );
  };

  //   const handleAttributeClick = (attributeType, attributePrice, duration) => {
  //   console.log(attributeType,attributePrice,duration,"newTotalPricenewTotalPricenewTotalPrice")

  //     // Toggle the selection state of the clicked attribute
  //     setSelectedAttributes((prevSelected) =>
  //       prevSelected.includes(attributeType)
  //         ? prevSelected.filter((id) => id !== attributeType)
  //         : [...prevSelected, attributeType]
  //     );

  //     // const updatedAttributes = [
  //     //     ...(selectedService.selectedAttributes || []),
  //     //     {
  //     //       attributeType,
  //     //       attributePrice,
  //     //       duration,
  //     //       isChecked: !selectedService.attributes?.some((attr) => attr.attributeType === attributeType),
  //     //       value: attributeType,
  //     //       // Add other attributes as needed
  //     //     },
  //     //   ];
  //     const isAttributeSelected = selectedService.attributes?.some(
  //         (attr) => attr.attributeType === attributeType
  //       );

  //       // Update the selected attributes array
  //       const updatedAttributes = selectedService.attributes
  //         ? selectedService.attributes.map((attr) =>
  //             attr.attributeType === attributeType
  //               ? {
  //                   ...attr,
  //                   isChecked: !isAttributeSelected,
  //                   // You can add other attributes as needed
  //                 }
  //               : attr
  //           )
  //         : [];

  //       // If the attributeType is not already present, add it to the array
  //       if (!isAttributeSelected) {
  //         updatedAttributes.push({
  //           attributeType,
  //           attributePrice,
  //           duration,
  //           isChecked: true,
  //           // Add other attributes as needed
  //         });
  //       }
  //     // Calculate the total price
  //     const newTotalPrice = selectedAttributes.includes(attributeType)
  //       ? totalPrice - attributePrice
  //       : totalPrice + attributePrice;

  //       const newTotalDurations = selectedAttributes.includes(attributeType)
  //       ? totalDurations - duration
  //       : totalDurations + duration;
  //   console.log(newTotalDurations,"newTotalPricenewTotalPricenewTotalPrice")
  //     // Update states
  //     setSelectedService([{
  //       ...selectedService,
  //       bookedSeats:1,
  //       duration:newTotalDurations,
  //       actualPrice: newTotalPrice, // Update the actual price in selectedService
  //       attributes:updatedAttributes
  //     //   selectedAttributes: updatedAttributes,
  //     }]);
  //     setTotalPrice(newTotalPrice);
  //     setTotalDuration(newTotalDurations);
  //   };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedService) {
          setIsLoading(true);
          await dispatch(
            getServiceAttributesExpert({
              //   id: "LH-31425827",
              id: selectedService?.serviceSKU,
              setIsLoading,
            })
          );
          setIsLoading(false);
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [selectedService, dispatch]);
  return (
    <>
      {/* <DialogBox
    {...dialog}
    action="new-alert"
    header={"Alert"}
    message={"Slots is Unavailable Please Select Anohter Slot?"}
  /> */}
      <div
        className="d-flex mt-4 service_booking_flex"
        style={{ height: "100vh", paddingBottom: "120px", overflow: "auto" }}
      >
        <div
          className="pt-3 back_btn_div"
          onClick={() => setSelectSlotFlag("service")}
          role="button"
        >
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>
        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ClipLoader color="#548DFF" />
          </div>
        ) : (
          <>
            <div
              className="container py-2 sm:px-4 service_details"
              // style={{
              //   borderRadius: "8px",
              //   border: "0.6px solid #DCDCDC",
              //   background: "#FFF",
              // }}
            >
              {" "}
              {/* <div className="pt-2" 
        // onClick={()=>history.push("/all-services")}
         role="button">
          <i id="" style={{color:"#5599ff"}}className="fas fa-chevron-left"></i>
        </div> */}
              <div
                className="col-md-12 border_rad_new
             pb-1 pt-1 mt-1 mb-2"
                style={{ paddingLeft: "10px", paddingRight: "12px" }}
              >
                <span className="servicedetails-heading">Service Details</span>
                <div className="d-flex justify-content-between py-2">
                  <div className="d-flex ">
                    {selectedService ? (
                      <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple my-auto">
                        <Avatar
                        alt={selectedService?.serviceName}
                        src={serviceImageURL(selectedService?.serviceId)}
                        variant="rounded"
                        style={{
                          borderRadius: 50,
                          width: "30px",
                          height: "30px",
                          // marginRight: 6,
                          backgroundColor: "#F0F0F0",
                          color: "#B5B5B5"
                        }}
                      />
                        {/* <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="30" height="30" rx="15" fill="#F0F0F0" />
                          <path
                            d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                            stroke="#B5B5B5"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                            stroke="#B5B5B5"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> */}
                      </div>
                    ) : (
                      <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 my-auto">
                        {/* <Avatar
                          sx={{
                            width: "42px",
                            height: "42px",
                            marginRight: 10,
                            borderRadius: 50,
                          }}
                        >
                          S
                        </Avatar> */}
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="30" height="30" rx="15" fill="#F0F0F0" />
                          <path
                            d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                            stroke="#B5B5B5"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                            stroke="#B5B5B5"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="d-flex my-auto ps-2 justify-content-between ">
                      <div
                        style={{ textAlign: "left" }}
                        className=" justify-content-start"
                      >
                        <span
                          className=" selected_servicename"
                          style={{ textTransform: "capitalize" }}
                        >
                          {selectedService?.serviceName}
                        </span>
                        &nbsp;{" "}
                        <span className="selected_serviceduration">
                          ({selectedService?.duration} mins)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="my-auto">
                    <div className="service-price ">{`${
                      // selectedService.currency === null
                      //   ? ""
                      //   : 
                        selectedService.currency === "USD"
                        ? "$"
                        : "£"
                    }${
                      selectedService.actualPrice === null
                        ? "0"
                        : selectedService.actualPrice
                    }`}</div>{" "}
                  </div>
                </div>
              </div>
              <p className=" mt-4 mb-3 service-text-heading">
                Please select one option
              </p>
              <div
                style={{
                  height: "30vh",
                  paddingBottom: "100px",
                  overflow: "auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="col-md-12 border_rad_new  mt-1 mb-2 px-0"
              >
                {service_store_expert_attributes &&
                service_store_expert_attributes.length > 0 ? (
                  service_store_expert_attributes.map((attribute, index) => (
                    <div key={attribute.id}>
                      {attribute.values.map((value) => (
                        <>
                          <div
                            key={value.id}
                            className={`d-flex justify-content-between p-1 ${
                              selectedAttributes.includes(value.id)
                                ? "selected-attribute"
                                : ""
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleAttributeClick(
                                value?.id,
                                value.value,
                                value.attributePrice,
                                value.duration,
                                value?.sku
                              )
                            }
                          >
                            <div className="d-flex">
                              <div className="d-flex justify-content-between">
                                <div
                                  style={{
                                    textAlign: "left",
                                    // textTransform: "capitalize",
                                  }}
                                  className="d-flex flex-column justify-content-start"
                                >
                                  <p className=" ms-2 mt-1 mb-1 attribute-value">
                                    {value?.value}
                                    <span className="attribute-duration ps-2">
                                      ({value?.duration} mins)
                                    </span>
                                  </p>
                                  <span className=" ps-2 attribute-description">
                                    This Service includes only one session
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="pt-1">
                              <div className="d-flex justify-content-between">
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "capitalize",
                                  }}
                                  className="d-flex flex-column justify-content-start"
                                >
                                  <span className="ms-3 me-2 text-capitalize attribute-price">
                                    {`${
                                      attribute.currency === null
                                        ? "£"
                                        : attribute.currency === "USD"
                                        ? "$"
                                        : "£"
                                    }${value?.attributePrice}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Divider
                            style={{ marginTop: "5px", width: "100%" }}
                          />
                        </>
                      ))}
                    </div>
                  ))
                ) : (
                  <div>
                    {/* Insert your message or component for data not found */}
                    <DataNotFound />
                  </div>
                )}
              </div>
              <div className="py-5 w-100">
                <div className="w-100 row justify-content-center align-items-center my-2">
                  <div className="col-7 text-center">
                  <button
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#FFD705",
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      fontStyle: "normal",
                      borderRadius: "8px",
                      fontWeight: 500,
                      border: "none",
                      padding: "3% 45%"
                    }}
                    
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      if (service_store_expert_attributes.length === 0) {
                        setSelectedFlag(true);
                      } else if (
                        service_store_expert_attributes.length > 0 &&
                        selectedAttributes.length === 0
                      ) {
                        customSnackBar("Please Select One Attribute");
                      } else if (selectedSlot?.length !== 0) {
                        const action = "available";
                        providerAvailabilty(selectedAttributeDetail, action);
                        // setSelectedFlag(true);
                      } else {
                        setSelectedFlag(true);
                      }
                    }}
                  >
                    {loader || loaders ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      // "Save & Continue"
                      "Next"
                    )}
                  </button>
                  </div>
                  
                </div>
              </div>
              {/* <div className="mt-3 d-flex justify-content-center">
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={() => {
                setSelectedFlag(true)
                // handleSubmit();
              }}
              variant="contained"
              disabled={loader}
            >
              {loader ? <ClipLoader color="#fffff" size={30} /> : "Next"}
            </Button>
          </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ServiceAttribute;
