import React, { useEffect, useState } from "react";
import Table from "./Table";
import {
  fetchBusinessFailure,
  fetchBusinessRequest,
} from "../../../../Store/Business/actions";
import { useDispatch, useSelector } from "react-redux";
import Model from "./Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import TableList from "./TableList";
import TableListMobile from "./TableListMobile";
import NewSchedularModal from "./NewSchedularModal";
import { Button } from "@mui/material";
import instance from "../../../../utils/utils";

export default function BusinessTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [businessId, setBusinessId] = useState();
  const [open, setOpen] = useState(false);

  const { data, error, loading } = useSelector((state) => state.Business);
  var userID = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );

  useEffect(() => {
    // setLoading(true)
    dispatch(fetchBusinessRequest(userID));
    if (error) {
      dispatch(fetchBusinessFailure(error));
    }
  }, [userID]);

  const handleOpen = async (id) => {
    setBusinessId(id);
    await instance
      .get(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/business?businessId=${id}`
      )
      .then((res) => {
        if (res.data.result.length > 0) {
          // history.push(`/providerScheduler/${id}`);
        } else {
          // history.push(`/providerScheduler/${id}`)
          setOpen(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  if (data?.length === 0) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center vh-100">
        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <h5 className="m-0">Please Create Business first</h5>
            <Button
              style={{ backgroundColor: "#5599FF" }}
              className="col-4 py-3 my-3"
              variant="contained"
              onClick={() => history.push("/add-new-business")}
            >
              Add Business
            </Button>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="form-container hide_scrollbar p-3">
        <TableList data={data} handleOpen={handleOpen} />
        <TableListMobile data={data} handleOpen={handleOpen} />
        {open && (
          <NewSchedularModal
            businessId={businessId}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        )}
      </div>
    );
  }
}
