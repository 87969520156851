import React, { useEffect } from "react";
import BusinessServiceForm from "./BusinessServiceForm";
import SideNavone from "../../../../SideNavBar";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getServiceToUpdate,
  getServiceToUpdateExpert,
} from "../../store/servicesActions";
import { useDispatch } from "react-redux";
const BusinessService = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state === 2) {
      dispatch(getServiceToUpdateExpert(id));
    } else {
      dispatch(getServiceToUpdate(id));
    }
  }, []);
  return (
    <SideNavone>
      <BusinessServiceForm
      sources={location.state} />
    </SideNavone>
  );
};

export default BusinessService;
