import ServiceDetail from "./ServiceDetail";

const ServiceDetailContainer = () => {
  return (
    <>
      <ServiceDetail />
    </>
  );
};
export default ServiceDetailContainer;
