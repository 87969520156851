import axios from "axios";
import instance from "../utils/utils";

export const postRequest = async (url, data, headers) => {
  try {
    const response = await instance.post(url, data, { headers: headers ?? "" });
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export const putRequest = async (url, data, headers) => {
  try {
    const response = await instance.put(url, data, { headers: headers ?? "" });
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export const patchRequest = async (url, data, headers) => {
  try {
    const response = await instance.patch(url, data, {
      headers: headers ?? "",
    });
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export const getRequest = async (url, headers) => {
  try {
    const response = await instance.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export const deleteRequest = async (url, data, headers) => {
  try {
    const response = await instance.delete(
      url,
      { data: data },
      { headers: headers ?? "" }
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

// export const deleteRequest = async (url, data, headers) => {

//     try {
//         const response = await axios.delete(url, data, { headers: headers ?? '' });
//         return response.data;
//     } catch (error) {
//         console.error('An error occurred:', error);
//         return null;
//     }
// }
