import React, { useEffect, useState } from "react";
import Header from "../Header";
import { businessList } from "../../../utils/constant";
import { Formik, Form } from "formik";
import {
  businessInitialValue,
  businessInitialValues,
  formateData,
  formateRecord,
  formateUpdateData,
  validationSchema,
} from "../Business/helper";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addBusinessRequest,
  addBusinessSuccess,
  updateBusinessSuccess,
} from "../../../Store/Business/actions";
import SideNavone from "../../../SideNavBar";

import AddBusinessInfo from "./AddBusiness/AddBusiness";
import Stepper from "./stepperButton";
import OwnerDetail from "./OwnerDetail/OwnerDetail";
import Addaddressfrommap from "./Addaddressfrommap/Addaddressfrommap";
import BusinessAddress from "./BusinessAddress/BusinessAddress";
import { getuserdataafterlogin } from "../../../Store/Auth/actions";
const AddBusiness = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const action =
    location &&
    location?.state &&
    location?.state?.data &&
    location?.state?.data?.action;
  const editData =
    location &&
    location?.state &&
    location?.state?.data &&
    location?.state?.data?.data;
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [result, setResult] = useState([]);
  const [actionState, setActionState] = useState("new");
  const [loading, setLoading] = useState(false);
 const [loader,setLoader]=useState(false)
  var userID = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir?.id
  );
  var userEmail = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir?.primaryEmail
  );
  var userInfo = useSelector((state) => state?.Auth?.userloginsuccessyasir);
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
    const fetchUser = () => {
    dispatch(
      getuserdataafterlogin({
        id: registersuccessId,
        setLoader,
      })
    );
  };
  useEffect(() => {
    if (registersuccessId) {
      fetchUser();
    }
  }, [registersuccessId, dispatch]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const postBusiness = (values, handles) => {
    // setLoading(true);
    values["userId"] = userID;
    values["userEmail"] = userEmail;
    const data = formateRecord(values,userInfo);
    dispatch(
      addBusinessRequest({
        data,
        setLoading,
      })
    ); // Dispatch the addBusinessRequest action
  };

  const updateBusiness = (values, handle) => {
    values["userId"] = userID;
    values["userEmail"] = userEmail;
    let id;
    if (
      actionState === "edit" ||
      (action && action === "edit" && editData && editData.id)
    ) {
      id = editData ? editData.id : result.id;
    }
    const data = formateUpdateData(values);
    const jsonData = JSON.stringify(data);
    dispatch(updateBusinessSuccess({ data, setLoading, id, history }));
  };
  const handleSubmit = (values, handles, result) => {
    values["userId"] = userID;
    values["userEmail"] = userEmail;

    if (
      actionState === "edit" ||
      (action && action === "edit" && editData && editData.id)
    ) {
      updateBusiness(values, handles, result);
    } else {
      postBusiness(values, handles);
    }
  };
  return (
    <>
      <div className="">
        <Formik
          initialValues={businessInitialValues(editData, action, userInfo)}
          validationSchema={validationSchema}
          onSubmit={(values, handles) => {
            handleSubmit(values, handles);
          }}
        >
          {(options) => {
            return (
              <Form onSubmit={handleSubmit}>
                
                <div>
                  <AddBusinessInfo
                  loader={loader}
                  isMobile={isMobile}
                    loading={loading}
                    onSubmit={handleSubmit}
                    action={action}
                    {...options}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* </div> */}
    </>
  );
};

export default AddBusiness;
