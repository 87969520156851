import React, { useState } from "react";
import { Button, Divider, TextField, Typography } from "@mui/material";
// import "./style.scss";
import InputTime from "../InputTime";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MapPin from "../../../../Assets/scheduler/map-pin.svg";
import { DropdownArrowSvg } from "../../../../Assets/svgs";
import moment from "moment";
import Switch from "./Switch";
import CustomTimePickerComponent from "./timePicker";

const Times = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const {
    scheduleIndex,
    setFieldValue,
    startTimeValues,
    startTimeTouched,
    startTimeErrors,
    endTimeValues,
    endTimeTouched,
    endTimeErrors,
    slot,
    locationValues,
    locationTouched,
    locationErrors,
    businessLocations,
  } = props;

  if (slot.days === 0) {
    slot.days = 7;
  }

  // const handleAddTimeSlot = () => {
  //   setFieldValue(
  //     `scheduleItems.${scheduleIndex}.startTime`,
  //     startTimeValues ? [...startTimeValues, ""] : []
  //   );
  //   setFieldValue(
  //     `scheduleItems.${scheduleIndex}.endTime`,
  //     endTimeValues ? [...endTimeValues, ""] : []
  //   );
  //   setFieldValue(
  //     `scheduleItems.${scheduleIndex}.businessLocation`,
  //     locationValues ? [...locationValues, ""] : []
  //   );
  // };
  const handleAddTimeSlot = () => {
    // Calculate the start time of the next slot
    const previousEndTime = endTimeValues[endTimeValues.length - 1];
    const [hours, minutes] = previousEndTime.split(":").map(Number);
    const nextStartTimeMinutes = (hours * 60 + minutes + 15) % (24 * 60);
    const nextStartTimeHours = Math.floor(nextStartTimeMinutes / 60);
    const nextStartTimeFormatted = `${String(nextStartTimeHours).padStart(2, "0")}:${String(nextStartTimeMinutes % 60).padStart(2, "0")}`;
  
    // Add the new slot with the calculated start time
    setFieldValue(`scheduleItems.${scheduleIndex}.startTime`, [...startTimeValues, nextStartTimeFormatted]);
    setFieldValue(`scheduleItems.${scheduleIndex}.endTime`, [...endTimeValues, ""]);
    setFieldValue(`scheduleItems.${scheduleIndex}.businessLocation`, [...locationValues, ""]);
  };
  

  

  const handleRemoveTimeSlot = (currIIndex) => {
    const currStartTime =
      (startTimeValues &&
        startTimeValues.filter((time, index) => index !== currIIndex)) ||
      [];

    const currEndTime =
      (endTimeValues &&
        endTimeValues.filter((time, index) => index !== currIIndex)) ||
      [];

    const currBussinessLoc =
      (locationValues &&
        locationValues.filter((loc, index) => index !== currIIndex)) ||
      [];

    setFieldValue(`scheduleItems.${scheduleIndex}.startTime`, currStartTime);
    setFieldValue(`scheduleItems.${scheduleIndex}.endTime`, currEndTime);
    setFieldValue(
      `scheduleItems.${scheduleIndex}.businessLocation`,
      currBussinessLoc
    );
  };
  const fiveAM = dayjs().set("hour", 5).startOf("hour");
  const nineAM = dayjs().set("hour", 9).startOf("hour");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const settingValues = (newValue,id) => {
    setFieldValue(
      id,
      newValue
    );
  }

  return (
    <>
      <div>
        {startTimeValues &&
          startTimeValues.length > 0 &&
          startTimeValues.map((time, index) => {
            return (
              <div
                className={` ${
                  startTimeValues && startTimeValues.length > 1 ? "" : ""
                }`}
                key={time + index}
              >
                <div className="row">
                  <div className="col-md-3 mt-2 pt-2">
                    <FormControl
                      sx={{ maxWidth: "180px" }}
                      size="small"
                      disabled={!slot.active}
                    >
                      <Select
                        id={`scheduleItems.${scheduleIndex}.businessLocation.${index}`}
                        name={`scheduleItems.${scheduleIndex}.businessLocation.${index}`}
                        // defaultValue={
                        //   businessLocations && businessLocations[0]?.id
                        // }
                        startAdornment={
                          <img
                            src={MapPin}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          color: "#5599FF",
                          height: "37px",
                          "& fieldset": {
                            border: "none",
                          },
                          ".MuiSvgIcon-root ": {
                            color: "#5599FF",
                          },
                        }}
                        value={locationValues && locationValues[index]}
                        label=""
                        onChange={(e) => {
                          setFieldValue(
                            `scheduleItems.${scheduleIndex}.businessLocation.${index}`,
                            e.target.value
                          );
                        }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <em style={{ color: "gray" }}>Select Business</em>
                            );
                          }
                          const val = businessLocations.find(
                            (data) => data.id == selected
                          )?.name;

                          return val;
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em>Select Business</em>
                        </MenuItem>
                        {businessLocations?.map((loc) => (
                          <MenuItem key={loc.id} value={loc.id}>
                            {loc.name}
                          </MenuItem>
                        ))}
                        {/* <MenuItem value={18}>Yasir Business</MenuItem> */}
                      </Select>
                    </FormControl>
                    <p className="form-error" style={{ marginLeft: "16px" }}>
                      {locationTouched &&
                      locationTouched[index] &&
                      locationErrors &&
                      locationErrors[index]
                        ? locationErrors[index]
                        : null}
                    </p>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-md-3">
                      <Typography
                        sx={{
                          color: "#A3A3A3",
                          fontSize: "12px",
                          fontWeight: 400,
                          mb: 0.5,
                        }}
                      >
                        From
                      </Typography>
                      <CustomTimePickerComponent 
                        onMouseDown={settingValues}
                        values={startTimeValues && startTimeValues[index]}
                        id={`scheduleItems.${scheduleIndex}.startTime.${index}`}
                        setFieldValue={setFieldValue}

                      />
                      <p className="form-error">
                        {startTimeTouched &&
                        startTimeTouched[index] &&
                        startTimeErrors &&
                        startTimeErrors[index]
                          ? startTimeErrors[index]
                          : null}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Typography
                        sx={{
                          color: "#A3A3A3",
                          fontSize: "12px",
                          fontWeight: 400,
                          mb: 0.5,
                        }}
                      >
                        To
                      </Typography>
                      <CustomTimePickerComponent 
                        onMouseDown={settingValues}
                        values={endTimeValues && endTimeValues[index]}
                        id={`scheduleItems.${scheduleIndex}.endTime.${index}`}
                      />
                      <p className="form-error">
                        {startTimeTouched &&
                        startTimeTouched[index] &&
                        startTimeErrors &&
                        startTimeErrors[index]
                          ? startTimeErrors[index]
                          : null}
                      </p>
                    </div>
                  </LocalizationProvider>
                  <div className="col-md-3 pt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="mt-1"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <div
                            // variant="outlined"
                            disabled={
                              slot.day === 0 || startTimeValues.length === 10
                            }
                            onClick={handleAddTimeSlot}
                          >
                            <img alt="no data" src="../images/Layer_1.svg" />
                            {/* <span style={{ size: "20px" }}>+</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {startTimeValues && startTimeValues.length > 1 && (
                          <div
                            className="mt-1"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "end",
                            }}
                          >
                            <div
                              // variant="outlined"
                              // color="error"
                              onClick={() => {
                                handleRemoveTimeSlot(index);
                              }}
                            >
                              <img
                                alt="no data"
                                src="../images/crossButton.svg"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {startTimeValues.length > 1 &&
                  startTimeValues.length != index + 1 && (
                    <Divider sx={{ color: "#F1F1F1", mb: "10px" }} />
                  )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Times;