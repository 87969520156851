import React, { useState, useEffect } from "react";
import axios from "axios";
import BusinessLocation from "../BusinessMap/Location";
import SearchBar from "../BusinessMap/MapSearchBar";
import BusinessName from "../BusinessMap/BusinessRecord";
import { defaultLocation } from "../../Business/helper";
import { useDispatch } from "react-redux";
import { addedBusinessAddress } from "../../../../Store/Business/actions";
import { Button, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Mapsection = ({ result, action, editData }) => {
  const APIKEY = "c8fbac6467694ab0861b5e3973bfa7ee";
  const history =useHistory()
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(defaultLocation);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [address, setAddress] = useState(
    (editData &&
      editData.businessAddress &&
      editData.businessAddress.addressName) ||
      ""
  );
  const [isSearchFieldValid, setIsSearchFieldValid] = useState(false);
  const [message, setMessage] = useState(""); // Define the message state

const dispatch=useDispatch()
  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setAddress(value); // Update address directly with the value
    if (value.trim() === "") {
      setIsSearchFieldValid(true);
      setMessage("Please Search the Address"); // Clear the message when the search field is valid
    } else {
      setIsSearchFieldValid(false);
      setMessage(""); // Clear the message when the search field is valid

    }

    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          value
        )}&key=${APIKEY}`
      );
      setSearchResults(response.data.results);
    } catch (error) {
      console.error("Search error: ", error);
    }
  };

  useEffect(() => {
    if (editData && editData.id) {
      setSearchTerm(editData.businessAddress.addressName);
      setIsSearchFieldValid(true);

    }
  }, [action, editData]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResultClick = (result) => {
    setSelectedLocation(result);
    setSearchTerm(result.formatted);
    setAddress(result.formatted);
    setSearchResults([]);
    setIsSearchFieldValid(true);
    dispatch(addedBusinessAddress(result));

  };

  const selectedLocationHandlers = async (location) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          location.lat
        )}+${encodeURIComponent(location.lng)}&key=${APIKEY}`
      );
      setSearchTerm(response.data.results[0].formatted);
      setAddress(response.data.results[0].formatted);
      setSelectedLocation(response.data.results[0]);
      setIsSearchFieldValid(true);
      dispatch(addedBusinessAddress(response.data.results[0]));

    } catch (error) {}
  };


  return (
    <div className="p-1 min-h-[250px] map-container">
       <div className="row">
          {isMobile ? (
            <div className={`${!isMobile ? "d-none " : " col-4"}`}>
              <IconButton
                onClick={() => {
                  history.push("/business/");
                }}
                size="small"
                sx={{ mr: 5 }}
                // className="mb-3"
              >
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 24, color: "#AAAAAA" }}
                />
              </IconButton>
            </div>
          ) : null}
         
        </div>
      <div id="map" className="position-relative pb-3">
      <BusinessName action={action} result={result} editData={editData} />

        <SearchBar
          address={address}
          setAddress={setAddress}
          location={selectedLocation}
          searchTerm={searchTerm}
          editData={editData}
          handleInputChange={handleInputChange}
          searchResults={searchResults}
          handleResultClick={handleResultClick}
          isSearchFieldValid={isSearchFieldValid}
          message={message}
        />
        <div className="col-md-12 px-2 ">
          <BusinessLocation
            action={action}
            center={[
              selectedLocation.geometry?.lat || defaultLocation.geometry.lat,
              selectedLocation.geometry?.lng || defaultLocation.geometry.lng,
            ]}
            defaultGeometry={{
              lat: 33.6938882232111,
              lng: 73.0651304125786,
            }}
            zoom={17}
            address={address}
            selectedLocationHandler={selectedLocationHandlers}
            isSearchFieldValid={isSearchFieldValid}
          />
        </div>
      </div>
      <div className={`${!isMobile ? " w-100 d-flex mt-4 flex-wrap justify-content-end " : " w-100 mt-4 "}`}>
       
       <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#c0c0c0",
                  color: "#ffffff",
                },
              }}
              onClick={() => {
                history.push("/business/");
              }}
              variant="contained"
              className=" btn btn-secondary padding_next_secondary_button"
            >
              {"Cancel"}
            </Button>
          </div>
          <div>
            <Button
              sx={{
                width: isMobile?"100%":"150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={() => {
                history.push("/add-business-address");

                // handleSubmit();
              }}
              disabled={!isSearchFieldValid}
              variant="contained"
            >
              {/* {loading ? (
                <ClipLoader color="#fffff" size={30} />
              
              ) : ( */}
              Next
              {/* )} */}
            </Button>
          </div>
        </div>
    </div>
  );
};

export default Mapsection;
