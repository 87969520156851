import React from "react";
import { Hero } from "../Hero/Hero";
import { CoreControl } from "../CoreControl/CoreControl";
import { NextGen } from "../NextGen/NextGen";
import { ProcessDetail } from "../../WebHomePage/ProcessDetail/ProcessDetail";
import { Farewell } from "../../WebHomePage/Farewell/Farewell";
import { Joinus } from "../Joinus/Joinus";
import { Banner } from "../Banner/Banner";

export const MobileLayout = () => {
  return (
    <div style={{ widows: "100%", overflow: "hidden" }}>
      <Hero />
      <CoreControl />
      <NextGen />
      <ProcessDetail />
      <Farewell btn={true} />
      <Joinus />
      <Banner />
    </div>
  );
};
