import React, { useState } from "react";
// import axios from "axios";
// import './addstaff.css';
import axios from "axios";
import SideNavone from "../../../../SideNavBar";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ClipLoader } from "react-spinners";
import { getAllStaff } from "../store/staffActions";
import instance from "../../../../utils/utils";

const Addstaff = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch =useDispatch()
  const [isExpert, setIsExpert] = useState(false);
  const [loading1, setIsLoading] = useState(true);

  const userData = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir.id
  );
  const [loading, setLoading] = useState(false);

  const handleAddStaff = (e) => {
    e.preventDefault();
    var splitArray = id.split("+");
    setLoading(true);
    const data = {
      id: parseInt(splitArray[0]),
      modifiedBy: userData,
      businessId: parseInt(splitArray[1]),
      // isExpert: isExpert,
      latitude: 20.0,
      longitude: 25.0,
      radius: 25.0,
    };
    instance
      .post(
        `/staff_svc/pv/staff/makeServiceProvider`,
        data
      )
      .then((response) => {
        setLoading(false);
        history.push("/staff-list");
        // window.location.reload()
        // dispatch(
        //   getAllStaff({
        //     id: parseInt(splitArray[1]),
        //     userid: parseInt(userData),
        //     page:1,
        //     pageSize: 10,
        //     setIsLoading,
        //   })
        // );
      })
      .catch((error) => {
        // handle any errors
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <SideNavone>
      <div
        className="pt-3 staff_form_main hide_scrollbar"
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="row mb-3 listpage mx-4 pt-0 gap-2">
          <div className="row justify-content-between ">
            <div className="col-4 mt-2 mb-4 ">
              <p style={{ fontSize: "20px" }}>Add Staff as a Provider</p>
            </div>
            <div className="col-4 mt-2 mb-4 ">
              <div className="card h-full">
                <div className=" cardtext-start d-flex flex-rows align-items-center">
                  <label htmlFor="input1">IsExpert</label>
                  <input
                    type="checkbox"
                    style={{ marginLeft: "20px" }}
                    checked={isExpert}
                    onChange={(e) => {
                      setIsExpert(!isExpert);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=" card form-group col-md-4 ps-0 col-4 text-start mb-5">
              <div className="bottom-0 end-0 pe-4">
                <button
                  type="button"
                  className="btn btn-primary me-4  addstaff ms-4"
                  onClick={(e) => {
                    history.push("/staff-list");
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
            <div className="card form-group  col-md-4  ps-0 col-4 text-start">
              <div className="bottom-0 end-0 pe-4">
                <button
                  type="button"
                  className="btn btn-primary me-4  addstaff ms-4"
                  onClick={handleAddStaff}
                  disabled={loading}
                >
                  {loading ? <ClipLoader size={14} /> : "Add Provider"}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideNavone>
  );
};

export default Addstaff;
