import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
// import {
//     addDefaultPaymentMethod,
//     deletePaymentMethod,
//     getAllListOfPayments,
// } from "@/helper";
// import { setPaymentInLocalStorage } from "../helper";
import { useSelector } from "react-redux";
import CreditCard from "./CreditCard";
import SideMenu from "../side-menu";

const PaymentList = ({ onClickHandler }) => {
    /* -------------------------------------------------------------------------- */
    /*                                  Variables                                 */
    /* -------------------------------------------------------------------------- */
    const { profile } = useSelector((state) => state);
    const [listOfPaymentMethod, setListOfPaymentMethod] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /* -------------------------------------------------------------------------- */
    /*                                  Functions                                 */
    /* -------------------------------------------------------------------------- */
    // useEffect(() => {
    //     setIsLoading(true);
    //     if (profile?.userId !== undefined) {
    //         getAllListOfPayments(profile?.userId)
    //             .then((res) => {
    //                 setListOfPaymentMethod(res?.paymentList?.data ?? []);
    //             })
    //             .catch((e) => alert(e))
    //             .finally(() => setIsLoading(false));
    //     }
    // }, [profile?.userId]);

    // const deletePaymentMethodHandler = (selectedPaymentMethodId) => {
    //     setIsLoading(true);
    //     deletePaymentMethod(profile?.userId, selectedPaymentMethodId)
    //         .then((res) => {
    //             setListOfPaymentMethod((prev) =>
    //                 prev.filter((item) => item.id !== selectedPaymentMethodId)
    //             );
    //         })
    //         .catch((e) => alert(e))
    //         .finally(() => setIsLoading(false));
    // };

    // const setAsDefaultHandler = (selectedPaymentMethodId) => {
    //     setIsLoading(true);
    //     addDefaultPaymentMethod(profile?.userId, selectedPaymentMethodId)
    //         .then((res) => {
    //             setPaymentInLocalStorage(selectedPaymentMethodId);
    //             setListOfPaymentMethod((prevList) => {
    //                 const updatedList = prevList.map((paymentMethod) => {
    //                     if (paymentMethod.id === selectedPaymentMethodId) {
    //                         return { ...paymentMethod, defaultPaymentMethod: true };
    //                     } else {
    //                         return { ...paymentMethod, defaultPaymentMethod: false };
    //                     }
    //                 });
    //                 return updatedList;
    //             });
    //         })
    //         .catch((e) => alert(e))
    //         .finally(() => setIsLoading(false));
    // };

    return (
        <>
            <SideMenu>
                <CreditCard />
            </SideMenu>
            {/* <div className="d-flex justify-content-end align-items-center mb-5">
                <div
                    className="d-flex align-items-center rounded-pill border border-primary-subtle"
                    style={{
                        padding: "0.5rem 1rem",
                        margin: "0 0.6rem",
                    }}
                >
                    <BiSearch />
                    <input
                        type="text"
                        className="border border-0"
                        style={{
                            marginLeft: "0.5rem",
                            outline: "none",
                        }}
                        disabled={isLoading}
                        placeholder="Search"
                    />
                </div>
                {isLoading ? (
                    <Loader style={{}} status={isLoading} />
                ) : (
                    <button className="btn btn-danger" onClick={onClickHandler}>
                        Add Payment Method
                    </button>
                )}
            </div>
            <div className="d-flex flex-wrap">
                <CreditCard />
                {listOfPaymentMethod?.length === 0 ? (
                    <div>No Payment Method</div>
                ) : (
                    listOfPaymentMethod.map((item, index) => (
                        <CreditCard
                            key={index}
                            defaultBtn={item.defaultPaymentMethod}
                            cardProvider={item.card.brand}
                            lastFourDigits={item.card.last4}
                            expireMonth={item.card.exp_month}
                            expireYear={item.card.exp_year}
                            onDeleteClickHandler={deletePaymentMethodHandler}
                            setAsDefaultHandler={setAsDefaultHandler}
                            paymentMethodId={item.id}
                        />
                    ))
                )}
            </div> */}
        </>
    );
};

export default PaymentList;
