import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import Paper from "@mui/material/Paper";
import classNames from "classnames";
import { FiPlusCircle } from "react-icons/fi";
import moreVBlack from "Assets/svgs/more-v-black.svg";
import moreVGrey from "Assets/svgs/more-v-grey.svg";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { LuMoreVertical } from "react-icons/lu";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAddNewColumnPreferenceStaffMutation } from "../../Feature/api/StaffApi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { error } from "../../Pages/schedulerModule/utils";
import { DataNotFound, customSnackBar, warning } from "../../utils";
import { postRequest } from "../request";
import { useEffect } from "react";
import { getAllCustomer } from "../../Pages/Dashboard/MainComponent/store/staffActions";
import { column } from "stylis";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#9F9E9E",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "28px" ,
    textAlign: "left",
    textTransform: "lowercase",
    paddingRight: "0px",
    backgroundColor: "#FBFBFB",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#434343",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "28.8px" /* 200% */,
    // textAlign: "center"
    textAlign: "-webkit-center",
  },
  // "&:not(:last-child)": {
  //   borderRight: "1px solid #DCDCDC", // Add right border to all cells except the last one
  //   borderLeft: "1px solid #DCDCDC", // Add left border to all cells except the first one
  // },

  // "&:first-of-type": {
  //   // borderTop: "1px solid #DCDCDC",
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    width: "100%",
  },
  // "&:not(:last-child) td": {
  //   borderBottom: "1px solid #ccc", // Add bottom border to all cells in the row except the last one
  //   borderRight: "1px solid #ccc", // Add right border to all cells in the row except the last one
  //   borderLeft: "1px solid #ccc", // Add left border to all cells in the row except the first one
  // },
  // "&:last-child td": {
  //   borderBottom: "1px solid #ccc", // Add bottom border to all cells in the last row
  //   borderRight: "1px solid #ccc", // Add right border to all cells in the last row
  //   borderLeft: "1px solid #ccc", // Add left border to all cells in the last row
  // },
}));
// const cellStyle = {
//   borderRight: '1px solid rgba(224, 224, 224, 1)',
// };

export default function StaffListTable(props) {
  const date = new Date();
  const history = useHistory();
  const business = useSelector((state) => state.Business?.selectedBusiness);
  const store = useSelector((state) => state?.staffReducer);
  var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const bussinessId = business?.id;
  const location = useLocation();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const selectedBusines = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const [staffSortedData, setStaffSortedData] = useState([]);

  const {
    // columns,
    staffData,
    staffSortedData,
    updateState,
    permission,
    // setAllCustomer1Loader,
  } = props;
  // console.log(
  //   staffSortedData,
  //   "staffSortedDatastaffSortedDatastaffSortedDataxs"
  // );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedData, setSelecteData] = useState();
  const [listData, setListData] = useState([]);
  const [frozenColumns, setFrozenColumns] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [actionIndex, setActionIndex] = useState();
  const [
    updateColumnPreferrence,
    { isLoading: isUpdateColumnPreferrenceLoading },
  ] = useAddNewColumnPreferenceStaffMutation();

  const businessId = useSelector(
    (state) => state.Business?.selectedBusiness?.id
  );

  const handleMenuClick = (event, data) => {
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row.id);
    setSelecteData(data);
  };

  const handleMenuActionClick = (event, data, rowIndex) => {
    setActionData(data);
    setActionIndex(rowIndex);
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row.id);
    // setSelecteData(data);
    console.log("EVENT", event);
    console.log("data", data);
    console.log("rowIndex", rowIndex);
  };
  const handleMenuClose = () => {
    setOpen(false);
    setOpenAction(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  console.log(store, "STOREEEE");

  const handleAddClick = () => {
    history.push("/edit-faculty", { state: listData });
    // history.push(`staff-list/${bussinessId}/columns-list`);

    // navigate("/staff/edit-staff", { state: listData });
  };

  // const stickyIndices = staffData.reduce((acc, column, index) => {
  //   if (column.isSticky) acc.push(index);
  //   return acc;
  // }, []);

  const shownColumns = listData.filter((column) => column.isShown);

  const sortedData = shownColumns.sort((a, b) => a.position - b.position);
  const stickyObjects = sortedData.filter((object) => object.isSticky);
  const nonStickyObjects = sortedData.filter((object) => !object.isSticky);

  const sortedStickyObjects = stickyObjects.sort(
    (object1, object2) => object1.position - object2.position
  );
  const sortedNonStickyObjects = nonStickyObjects.sort(
    (object1, object2) => object1.position - object2.position
  );

  const sortedObjects = sortedStickyObjects.concat(sortedNonStickyObjects);
  console.log("log", sortedObjects);
  const newArray = sortedObjects.map((el) => {
    let width =
      {
        id: 70,
        name: 190,
        industry: 190,
        user: 190,
        description: 280,
        parentBusiness: 190,
        businessType: 190,
        websiteUrl: 280,
        imageUrl: 280,
        imageName: 280,
        businessAddress: 280,
        designation: 190,
        status: 110,
        default: 110,
        country_id: 140,
        has_working_hours: 190,
        has_address: 190,
        has_staff: 190,
        has_service: 190,
        subScribeService: 190,
        firstname: 130,
        gender: 110,
        contactNo: 160,
        address: 280,
        userId: 140,
        role: 190,
        business: 190,
        numTeams: 110,
        depType: 190,
        departmentDescription: 280,
        department: 190,
        isActive: 110,
        teamType: 190,
        teamDescription: 280,
        onlineSKU: 140,
        expertServiceId: 190,
        isOnline: 110,
        inHouseSKU: 140,
        isInHouse: 140,
        inClinicSKU: 140,
        serviceId: 140,
        noOfVisits: 140,
        serviceName: 190,
        serviceSKU: 140,
        shortDesc: 280,
        longDesc: 280,
        serviceUrl: 280,
        serviceImage: 140,
        actualPrice: 190,
        priceType: 190,
        duration: 190,
        availableSeats: 190,
        countryId: 140,
        currency: 140,
        isInClinic: 140,
        createdBy: 140,
        businessId: 140,
        isExpert: 140,
        title: 190,
        customerFirstName: 190,
        businessId: 140,
        primaryEmail: 190,
        secondaryEmail: 190,
        customerLastName: 190,
        primaryMobile: 160,
        dob: 110,
        image: 110,
        addressLine1: 280,
        addressLine2: 280,
        city: 190,
        postalCode: 190,
        country: 190,
        externalCustomerId: 190,
        externalPlatform: 190,
        createdOn: 190,
        modifiedBy: 140,
        modifiedOn: 190,
        isDeleted: 110,
        secondaryMobile: 160,
      }[el.columnName] || 190;

    return {
      ...el,
      width: width,
    };
  });

  const handleHideClick = async (columnName) => {
    const updatedColumns = listData.map((column) => {
      if (
        location.pathname === "/businesses" ||
        location.pathname === "/business-departments" ||
        location.pathname === "/business-users" ||
        location.pathname === "/business-teams"
      ) {
        return column.columnId === selectedData.ColumnId
          ? { ...column, isShown: false }
          : column;
      } else {
        return column.id === selectedData.id
          ? { ...column, isShown: false }
          : column;
      }
    });

    updateState(updatedColumns);

    setOpen(false);
    // const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
    //   const { serviceName, columnName, ...rest } = item;
    //   return rest;
    // });
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, customerId, ...rest } = item;

      // Rename the 'customerId' property to 'columnId'
      const updatedItem = { ...rest, columnId: item.id };
      return updatedItem;
    });
    // const dataWithoutServiceNameAndColumnName = staffSortedData.reduce(
    //   (uniqueData, item) => {
    //     const existingItem = uniqueData.find(
    //       (uniqueItem) => uniqueItem.position === item.position
    //     );

    //     // Rename the 'id' property to 'columnId'
    //     const updatedItem = { ...item, columnId: item.id };
    //     delete updatedItem.id;

    //     if (!existingItem) {
    //       uniqueData.push(updatedItem);
    //     }

    //     return uniqueData;
    //   },
    //   []
    // );
    const columnsss = dataWithoutServiceNameAndColumnName
      .map((itemsss) => {
        if (itemsss.isShown === true) {
          return {
            columnId: itemsss.columnId,
            isSticky: itemsss.isSticky,
          };
        }
        return null;
      })
      .filter((it) => it !== null);
    const payload1 = {
      entityId: userID,
      columns: columnsss,
      createdBy: userID,
    };

    const payload2 = {
      moduleName: "Departments",
      userId: userID,
      configuration: columnsss,
    };

    const payload3 = {
      moduleName: "Business",
      userId: userID,
      configuration: columnsss,
    };

    const payload4 = {
      moduleName: "Users",
      userId: userID,
      configuration: columnsss,
    };

    const payload5 = {
      moduleName: "Team",
      userId: userID,
      configuration: columnsss,
    };

    if (location.pathname === "all-customer") {
      const result = await postRequest(
        `/customer_svc/pv/ListingPreferences/addUserListingPreference`,
        payload1
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }
    if (location.pathname === "/dynamic-services") {
      const result = await postRequest(
        `/serviceinventory_svc/pv/ListingPreferences/addUserListingPreference`,
        payload1
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }

    console.log("pay ", payload2);
    if (location.pathname === "/business-departments") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload2
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }
    if (location.pathname === "/businesses") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload3
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }
    if (location.pathname === "/business-users") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload4
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }
    if (location.pathname === "/business-teams") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload5
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (store?.allCustomer) {
      const sorted = [...store?.allCustomer].sort(
        (a, b) => a?.position - b?.position
      );

      if (
        store?.filterTextCustomer === "" &&
        store?.firstNameCustomer === "" &&
        store?.lastName === "" &&
        store?.email === "" &&
        store?.mobileNumber === ""
      ) {
        setListData(sorted);
      } else {
        setListData(store?.customerSearchList);
      }
    }
  }, [store?.allCustomer, store?.customerSearchList]);

  const handleFreezeClick = async (columnName) => {
    const updatedColumns = listData.map((column) =>
      column.id === selectedData.id ? { ...column, isSticky: true } : column
    );

    setListData(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, customerId, ...rest } = item;

      const updatedItem = { ...rest, columnId: item.id };
      return updatedItem;
    });

    const columnsss = dataWithoutServiceNameAndColumnName
      .map((itemsss) => {
        if (itemsss.isShown === true) {
          return {
            columnId: itemsss.columnId,
            isSticky: itemsss.isSticky,
          };
        }
        return null;
      })
      .filter((it) => it !== null);
    const payload1 = {
      entityId: userID,
      columns: columnsss,
      createdBy: userID,
    };

    const payload2 = {
      moduleName: "Departments",
      userId: userID,
      configuration: columnsss,
    };
    const payload3 = {
      moduleName: "Business",
      userId: userID,
      configuration: columnsss,
    };
    const payload4 = {
      moduleName: "Users",
      userId: userID,
      configuration: columnsss,
    };
    const payload5 = {
      moduleName: "Team",
      userId: userID,
      configuration: columnsss,
    };

    if (location.pathname === "/all-customer") {
      if (
        permission.canUpdateCustomerPreferences ||
        permission.canUpdateCustomerPreferences === null ||
        permission.canUpdateCustomerPreferences === undefined
      ) {
        try {
          const result = await postRequest(
            `/customer_svc/pv/ListingPreferences/addUserListingPreference`,
            payload1
          );
          if (result?.code === 0) {
            setListData(updatedColumns);
            dispatch(
              getAllCustomer({
                id: business?.id,
                userid: parseInt(userID),
                page: 1,
                pageSize: 10,
              })
            );
          } else {
            // Handle error if needed
          }
        } catch (error) {
          // Handle error if needed
          console.error("Error:", error);
        }
      } else {
        customSnackBar("You don't have permission to Update Preference");
      }
    }

    if (location.pathname === "/dynamic-services") {
      const result = await postRequest(
        `/serviceinventory_svc/pv/ListingPreferences/addUserListingPreference`,
        payload1
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-departments") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload2
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/businesses") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload3
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-users") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload4
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-teams") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload5
      );
      if (result?.code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
  };
  const handleUnFreezeClick = async (columnName) => {
    const updatedColumns = listData.map((column) =>
      column.id === selectedData.id ? { ...column, isSticky: false } : column
    );

    setListData(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, customerId, ...rest } = item;

      // Rename the 'customerId' property to 'columnId'
      const updatedItem = { ...rest, columnId: item.id };
      return updatedItem;
    });
    // const dataWithoutServiceNameAndColumnName = staffSortedData.reduce(
    //   (uniqueData, item) => {
    //     const existingItem = uniqueData.find(
    //       (uniqueItem) => uniqueItem.position === item.position
    //     );

    //     // Rename the 'id' property to 'columnId'
    //     const updatedItem = { ...item, columnId: item.id };
    //     delete updatedItem.id;

    //     if (!existingItem) {
    //       uniqueData.push(updatedItem);
    //     }

    //     return uniqueData;
    //   },
    //   []
    // );
    // const payload = {
    //   userId: userID,
    //   columns: dataWithoutServiceNameAndColumnName,
    //   createdBy: userID,
    // };
    const columnsss = dataWithoutServiceNameAndColumnName
      .map((itemsss) => {
        if (itemsss.isShown === true) {
          return {
            columnId: itemsss.columnId,
            isSticky: itemsss.isSticky,
          };
        }
        return null;
      })
      .filter((it) => it !== null);
    const payload1 = {
      entityId: userID,
      columns: columnsss,
      createdBy: userID,
    };
    const payload2 = {
      moduleName: "Departments",
      userId: userID,
      configuration: columnsss,
    };
    const payload3 = {
      moduleName: "Business",
      userId: userID,
      configuration: columnsss,
    };
    const payload4 = {
      moduleName: "Users",
      userId: userID,
      configuration: columnsss,
    };
    const payload5 = {
      moduleName: "Team",
      userId: userID,
      configuration: columnsss,
    };

    if (location.pathname === "/all-customer") {
      const result = await postRequest(
        `/customer_svc/pv/ListingPreferences/addUserListingPreference`,
        payload1
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/dynamic-services") {
      const result = await postRequest(
        `/serviceinventory_svc/pv/ListingPreferences/addUserListingPreference`,
        payload1
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-departments") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload2
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/businesses") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload3
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-users") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload4
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
    if (location.pathname === "/business-teams") {
      const result = await postRequest(
        `business_svc/pb/update_list_configuration/`,
        payload5
      );
      if (result?.code === 0) {
        // const code = result.data.code;
        // const message = result.data.message;
        // if (code === 0) {
        setListData(updatedColumns);
        dispatch(
          getAllCustomer({
            id: business?.id,
            userid: parseInt(userID),
            page: 1,
            pageSize: 10,
          })
        );
      } else {
      }
    }
  };
  const handleProfileClick = () => {
    if (
      permission.canViewCustomerPrfile ||
      permission.canViewCustomerPrfile === null ||
      permission.canViewCustomerPrfile === undefined
    ) {
      const rowIndex = actionIndex;
      const dumy = actionData.filter(
        (column, index) => column.columnName === "id"
      );
      if (dumy.length === 0) {
        alert("Please Add ID in Prefrence");
      } else {
        const columnValue = dumy[0].columnValue[rowIndex] || {};
        const idValue = columnValue.values;
        if (
          columnValue.values === undefined ||
          columnValue.values === undefined
        ) {
          alert("Add ProviderID And ID to User Prefrence");
        } else {
          const externalPlatformValue = actionData.find(
            (column) => column?.columnName === "externalPlatform"
          )?.columnValue[rowIndex].values;
          const customerId =
            externalPlatformValue === "expert"
              ? actionData.find(
                  (column) => column?.columnName === "externalCustomerId"
                )?.columnValue[rowIndex].values
              : idValue;
          const action = externalPlatformValue;
          // const action="null"
          history.push(`/customer-profile-view/${idValue}`, action);
        }
      }
    } else {
      customSnackBar("You haven't Permission");
    }
  };

  let appointmentData = {};

  const handleScheduleClick = async () => {
    let rowIndex = actionIndex;

    const dumy = actionData.filter(
      (column, index) => column?.columnName === "id"
    );

    const columnValue = dumy[0]?.columnValue[rowIndex] || {};
    const rowId = columnValue.values;

    // Check the value of EXTERNALPLATFORM
    // const externalPlatformValue = actionData.find(
    //   (column) => column?.columnName === "externalPlatform"
    // )?.columnValue[rowIndex];
    // console.log(externalPlatformValue, dumy, "rowIdrowIdrowId");

    // // Assign rowId or Id based on the condition
    // const customerId =
    //   externalPlatformValue.values === 'expert'
    //     ? actionData.find(
    //         (column) => column?.columnName === "externalCustomerId"
    //       )?.columnValue[rowIndex].values
    //     : rowId;
    //     console.log(customerId, dumy, "rowIdrowIdrowId");

    //profile id
    if (rowId === undefined) {
      alert("Add ID and to User Prefrence");
    } else {
      history.push(`/customer-bookings/${rowId}`);
      localStorage.setItem("CustomerDetaill", JSON.stringify(actionData));
    }
  };

  // const maxRows = rows?.reduce(
  //   (max, service) => Math.max(max, services?.columnValue?.length),
  //   0
  // );

  console.log("new array is", newArray);

  const handleServiceSettingsRoute = () => {
    const rowIndex = actionIndex;
    const serviceIdExist = actionData.find(
      (item, index) => item.columnName === "serviceId"
    );
    const serivceNameExist = actionData.find(
      (item, index) => item.columnName === "serviceName"
    );
    const serviceSkuExist = actionData.find(
      (item, index) => item.columnName === "serviceSKU"
    );
    // const dumy = actionData.filter(
    //   (column, index) => column?.columnName === "id"
    // );
    console.log(
      serivceNameExist,
      serviceSkuExist,
      serviceIdExist,
      actionData,
      "serviceIdExistserviceIdExist"
    );
    if (serviceIdExist && serivceNameExist && serviceSkuExist) {
      let data = {};
      for (let i = 0; i < actionData.length; i++) {
        data = {
          ...data,
          [actionData[i].columnName]:
            actionData[i].columnValue[rowIndex].values,
        };
      }
      const queryParams = new URLSearchParams(data).toString();
      history.push(`/service-settings?${queryParams}`);
    } else {
      customSnackBar("Please select name, sku and id in preferences");
    }
  };

  const businessRoutesList = {
    profile: `/business-profile/${businessId}`,
    users: "/business-users",
    departments: "/business-departments",
    teams: "/business-teams",
  };

  const handleBusinessRoutes = (arg) => {
    const route = businessRoutesList[arg] || null;
    if (route) history.push(route);
  };

  const departmentRoutesList = {
    teams: "/business-teams",
  };

  const handleDepartmentRoutes = (arg) => {
    const route = departmentRoutesList[arg] || null;
    if (route) history.push(route);
  };

  const handleAssignStaff = async () => {
    let rowIndex = actionIndex;
    console.log("ACTIONID", actionData);
    const data = {
      businessId: businessId,
      source: 1,
    };
    const dumy = actionData.filter(
      (column, index) => column?.columnName === "serviceId"
    );

    const columnValue = dumy[0]?.columnValue[rowIndex] || {};
    const rowId = columnValue.values;

    if (rowId === undefined) {
      alert("Add ID and to User Prefrence");
    } else {
      history.push(`/assign-staff-dynamic-service/${rowId}`, data);
    }
  };

  //   return (
  //     <Box
  //       sx={{
  //         width: "100%",
  //         maxHeight: "350px",
  //         overflow: "auto",
  //         scrollbarWidth: "none",
  //         "-ms-overflow-style": "none",
  //         "::-webkit-scrollbar": {
  //           display: "none",
  //         },
  //         borderTopLeftRadius: "8px",
  //         margin: "0 auto",
  //       }}
  //     >
  //       <TableContainer component={Paper} h>
  //       <Table size="small" aria-label="a dense table" component="table">
  //           <TableHead>
  //             <StyledTableRow>
  //               <StyledTableCell
  //                sx={{
  //                 width: "40px",
  //                 position: "sticky",
  //                 left: 0,
  //                 zIndex: 10,
  //                 paddingX: "4px",
  //                 textAlign: "center",
  //                 // backgroundColor: "#fff",
  //                 "&::before": {
  //                   content: "''",
  //                   position: "absolute",
  //                   top: "0",
  //                   left: "0",
  //                   width: "100%",
  //                   // maxWidth: "40px",
  //                   borderRight: "0.7px solid rgba(220, 220, 220, 1)", // Add a right border
  //                   borderTop: "5px solid #F5BF00",
  //                 },
  //                 "&::after": {
  //                   content: "''",
  //                   position: "absolute",
  //                   top: "0",
  //                   right: "0px", // Adjust this value as needed for the border width
  //                   height: "100%",
  //                   borderRight: "0.7px solid rgba(220, 220, 220, 1)", // Add a right border
  //                 },
  //               }}
  //               >
  //                 <img src={columns1} alt="upload Logo" />
  //                 {/* Ac */}
  //               </StyledTableCell>

  //               {newArray.map((columnName, index) => {
  //                 return (
  //                   <StyledTableCell
  //                     key={columnName.columnName}
  //                     sx={{
  //                       width: columnName.isSticky === true ? "40px" : "40px",
  //                       margin: "0px",
  //                       paddingY: "2px",
  //                       position:
  //                         columnName.isSticky === true ? "sticky" : undefined,
  //                         left: !index? '45px' : index * 85 + 'px',
  //                       zIndex: columnName.isSticky === true ? 2 : -1,
  //                       backgroundColor:
  //                         columnName.isSticky === true ? "#fff" : "#fff",
  //                       borderRadius:
  //                         columnName.isSticky === true ? "5px" : undefined,

  //                       "&::before": {
  //                         content: "''",
  //                         position: "absolute",
  //                         top: "0",
  //                         left: "0",
  //                         width: "100%",

  //                         borderTop:
  //                           columnName.isSticky === true
  //                             ? "5px solid #F5BF00"
  //                             : undefined,
  //                       },
  //                       "&::after": {
  //                         content: "''",
  //                         position: "absolute",
  //                         top: "0",
  //                         right: "0px",
  //                         height: "100%",
  //                       },
  //                     }}
  //                   >
  //                     <Box
  //                       component="div"
  //                       display="flex"
  //                       justifyContent="space-between"
  //                       alignItems="center"
  //                       textTransform="capitalize"
  //                       minWidth="50px"
  //                     //   ref={(element) => {
  //                     //     if (element && columnName.isSticky) {
  //                     //         const rect = element.getBoundingClientRect();
  //                     //         console.log(`Width of cell ${columnName.columnName}: ${rect.width}px`);
  //                     //         totalWidth += rect.width;
  //                     //     }
  //                     // }}
  //                     >
  //                       {columnName.columnName}
  //                       <Box component="span"/>
  //                       <MoreVertIcon
  //                         // sx={{ marginLeft: "45px" }}
  //                         fontSize="small"
  //                         onClick={(event) => handleMenuClick(event, columnName)}
  //                       />
  //                     </Box>
  //                   </StyledTableCell>
  //                 );
  //               })}
  //             </StyledTableRow>
  //           </TableHead>
  //           <TableBody>
  //             {newArray?.at(0)?.columnValue?.length !== 0 ? (
  //               newArray?.at(0)?.columnValue?.map((_, rowIndex) => {
  //                 return (
  //                   <StyledTableRow key={rowIndex}>
  //                     <StyledTableCell
  //                       sx={{
  //                         width: "40px",
  //                         position: "sticky",
  //                         left: 0,
  //                         zIndex: 10,
  //                         paddingX: "4px",
  //                         textAlign: "center",
  //                         backgroundColor: "#fff",
  //                         "&::before": {
  //                           content: "''",
  //                           position: "absolute",
  //                           top: "0",
  //                           left: "0",
  //                           width: "100%",
  //                           // maxWidth: "40px",
  //                           borderRight: "0.7px solid rgba(220, 220, 220, 1)",
  //                           // borderTop: "5px solid #F5BF00",
  //                         },
  //                         "&::after": {
  //                           content: "''",
  //                           position: "absolute",
  //                           top: "0",
  //                           right: "0px",
  //                           height: "100%",
  //                           borderRight: "0.7px solid rgba(220, 220, 220, 1)",
  //                         },
  //                       }}
  //                       align="right"
  //                     >
  //                       <MoreVertIcon
  //                         onClick={(event) =>
  //                           handleMenuActionClick(event, newArray, rowIndex)
  //                         }
  //                         fontSize="small"
  //                         sx={{ color: "#000" }}
  //                       />
  //                     </StyledTableCell>
  //                     {newArray?.map((column, colIndex) => {
  //                       return column?.status === "status" ? (
  //                         <StyledTableCell
  //                           key={column.columnName}
  //                           align="center"
  //                           sx={{
  //                             background:
  //                               column.isShown &&
  //                               column.columnValue[rowIndex].values
  //                                 ? "#B8D693"
  //                                 : "#E9B67A",
  //                             width: column.isSticky === true ? "50px" : "50px",
  //                             margin: "0px",
  //                             paddingY: "2px",
  //                             position:
  //                               column.isSticky === true ? "sticky" : undefined,
  //                             // left:
  //                             //   colIndex === 0
  //                             //     ? colIndex * 100 + 28 + "px"
  //                             //     : colIndex === 2
  //                             //     ? colIndex * 100 + 3 + "px"
  //                             //     : colIndex * 100 + 20 + "px",
  //                             zIndex: column.isSticky === true ? 2 : -1,
  //                             backgroundColor:
  //                               column.isSticky === true ? "#fff" : undefined,
  //                             borderRadius:
  //                               column.isSticky === true ? "5px" : undefined,
  //                             borderRight:
  //                               column.isSticky === true
  //                                 ? undefined
  //                                 : "0.7px solid rgba(220, 220, 220, 1)",
  //                             "&::before": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               left: "0",
  //                               width: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                             "&::after": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               right: "0px",
  //                               height: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                           }}
  //                         >
  //                           <Typography
  //                             component="p"
  //                             color="#FFF"
  //                             fontWeight="500"
  //                             fontFamily="Roboto"
  //                             fontSize="14px"
  //                           >
  //                             {column.isShown &&
  //                             column.columnValue[rowIndex].values
  //                               ? "Active"
  //                               : "InActive"}
  //                           </Typography>

  //                           {/* <SwitchOne
  //                           {...props}
  //                           size="small"
  //                           // defaultChecked={row.isActive ? true : false}
  //                           defaultChecked={
  //                             column.isShown && column.columnValue[rowIndex].values
  //                           }
  //                           // onClick={(event) => handleStatusClick(event, row)}
  //                         /> */}
  //                         </StyledTableCell>
  //                       ) : column.status === "image" ? (
  //                         <StyledTableCell
  //                           key={column.columnName}
  //                           // align="center"
  //                           sx={{
  //                             width: column.isSticky === true ? "50px" : "50px",
  //                             margin: "0px",
  //                             paddingY: "2px",
  //                             position:
  //                               column.isSticky === true ? "sticky" : undefined,
  //                             // left:
  //                             //   colIndex === 0
  //                             //     ? colIndex * 100 + 28 + "px"
  //                             //     : colIndex === 2
  //                             //     ? colIndex * 100 + 3 + "px"
  //                             //     : colIndex * 100 + 20 + "px",
  //                             zIndex: column.isSticky === true ? 2 : -1,
  //                             backgroundColor:
  //                               column.isSticky === true ? "#fff" : undefined,
  //                             borderRadius:
  //                               column.isSticky === true ? "5px" : undefined,
  //                             borderRight:
  //                               column.isSticky === true
  //                                 ? undefined
  //                                 : "0.7px solid rgba(220, 220, 220, 1)",
  //                             "&::before": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               left: "0",
  //                               width: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                             "&::after": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               right: "0px",
  //                               height: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                           }}
  //                         >
  //                           <Avatar
  //                             component="imgae"
  //                             alt="Remy Sharp"
  //                             src={
  //                               column.isShown
  //                                 ? column.columnValue[rowIndex].values
  //                                 : ""
  //                             }
  //                             sx={{
  //                               width: "24px",
  //                               height: "24px",
  //                               textAlign: "center",
  //                             }}
  //                           />
  //                         </StyledTableCell>
  //                       ) : column.status === "email" ? (
  //                         <StyledTableCell
  //                           key={column.columnName}
  //                           align="center"
  //                           sx={{
  //                             width: column.isSticky === true ? "50px" : "50px",
  //                             margin: "0px",
  //                             paddingY: "2px",
  //                             position:
  //                               column.isSticky === true ? "sticky" : undefined,
  //                             // left:
  //                             //   colIndex === 0
  //                             //     ? colIndex * 100 + 28 + "px"
  //                             //     : colIndex === 2
  //                             //     ? colIndex * 100 + 3 + "px"
  //                             //     : colIndex * 100 + 20 + "px",
  //                             zIndex: column.isSticky === true ? 2 : -1,
  //                             backgroundColor:
  //                               column.isSticky === true ? "#fff" : undefined,
  //                             borderRadius:
  //                               column.isSticky === true ? "5px" : undefined,
  //                             borderRight:
  //                               column.isSticky === true
  //                                 ? undefined
  //                                 : "0.7px solid rgba(220, 220, 220, 1)",
  //                             "&::before": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               left: "0",
  //                               width: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                             "&::after": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               right: "0px",
  //                               height: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                           }}
  //                         >
  //                           {column.isShown
  //                             ? column.columnValue[rowIndex].values
  //                             : ""}
  //                         </StyledTableCell>
  //                       ) : (
  //                         <StyledTableCell
  //                           key={column.columnName}
  //                           align="center"
  //                           sx={{
  //                             width: column.isSticky === true ? "50px" : "50px",
  //                             margin: "0px",
  //                             paddingY: "2px",
  //                             position:
  //                             column.isSticky === true ? "sticky" : undefined,
  //                             left: !colIndex? '45px' : colIndex * 85 + 'px',
  //                             // left:
  //                             //   colIndex === 0
  //                             //     ? colIndex * 100 + 28 + "px"
  //                             //     : colIndex === 2
  //                             //     ? colIndex * 100 + 3 + "px"
  //                             //     : colIndex * 100 + 20 + "px",
  //                             zIndex: column.isSticky === true ? 2 : -1,
  //                             backgroundColor:
  //                               column.isSticky === true ? "#fff" : undefined,
  //                             borderRadius:
  //                               column.isSticky === true ? "5px" : undefined,
  //                             borderRight:
  //                               column.isSticky === true
  //                                 ? undefined
  //                                 : "0.7px solid rgba(220, 220, 220, 1)",
  //                             "&::before": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               left: "0",
  //                               width: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                             "&::after": {
  //                               content: "''",
  //                               position: "absolute",
  //                               top: "0",
  //                               right: "0px",
  //                               height: "100%",
  //                               borderRight:
  //                                 column.isSticky === true
  //                                   ? "0.7px solid rgba(220, 220, 220, 1)"
  //                                   : "0.7px solid rgba(220, 220, 220, 1)",
  //                             },
  //                           }}
  //                         >
  //                           {column.isShown
  //                             ? typeof column.columnValue[rowIndex].values ===
  //                               "string"
  //                               ? column.columnValue[rowIndex].values
  //                               : Array.isArray(
  //                                   column.columnValue[rowIndex].values
  //                                 )
  //                               ? column.columnValue[rowIndex].values.map(
  //                                   (el) => <div>{el}</div>
  //                                 )
  //                               : JSON.stringify(
  //                                   column.columnValue[rowIndex].values
  //                                 )
  //                             : ""}
  //                         </StyledTableCell>
  //                       );
  //                     })}

  //                     {/* <StyledTableCell

  //                   ></StyledTableCell> */}
  //                   </StyledTableRow>
  //                 );
  //               })
  //             ) : (
  //               <StyledTableRow>
  //                 <StyledTableCell colSpan={newArray.length + 1} align="center">
  //                   <DataNotFound />{" "}
  //                 </StyledTableCell>
  //               </StyledTableRow>
  //             )}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>

  //       {open && (
  //         <Menu
  //           anchorEl={anchorEl}
  //           //   open={open}
  //           open={Boolean(anchorEl)}
  //           onClose={handleMenuClose}
  //           anchorOrigin={{
  //             vertical: "top",
  //             horizontal: "left",
  //           }}
  //         >
  //           <MenuItem onClick={handleHideClick}>
  //             <Box
  //               component="div"
  //               display="flex"
  //               justifyContent="space-between"
  //               alignItems="center"
  //             >
  //               {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
  //               Hide
  //             </Box>
  //           </MenuItem>
  //           <MenuItem>
  //             {selectedData.isSticky ? (
  //               <Typography onClick={handleUnFreezeClick}>UnFreeze</Typography>
  //             ) : (
  //               <Typography onClick={handleFreezeClick}>Freeze</Typography>
  //             )}
  //           </MenuItem>
  //         </Menu>
  //       )}
  //       {openAction && (
  //         <Menu
  //           anchorEl={anchorEl}
  //           //   open={open}
  //           open={Boolean(anchorEl)}
  //           onClose={handleMenuClose}
  //           anchorOrigin={{
  //             vertical: "top",
  //             horizontal: "left",
  //           }}
  //         >
  //           {location.pathname === "/businesses" ||
  //           location.pathname === "/business-departments" ||
  //           location.pathname === "/business-users" ||
  //           location.pathname === "/business-teams" ||
  //           location.pathname === "/dynamic-services" ? (
  //             <>
  //               {location.pathname === "/dynamic-services" && (
  //                 <>
  //                   {" "}
  //                   <MenuItem onClick={handleServiceSettingsRoute}>
  //                     <Box
  //                       component="div"
  //                       display="flex"
  //                       justifyContent="space-between"
  //                       alignItems="center"
  //                     >
  //                       {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
  //                       Service Settings
  //                     </Box>
  //                   </MenuItem>
  //                   <MenuItem onClick={(e) => handleAssignStaff(e)}>
  //                     <Box
  //                       component="div"
  //                       display="flex"
  //                       justifyContent="space-between"
  //                       alignItems="center"
  //                     >
  //                       Assign Staff
  //                     </Box>
  //                   </MenuItem>
  //                 </>
  //               )}
  //               {location.pathname === "/businesses" && (
  //                 <>
  //                   {Object.keys(businessRoutesList).map((el) => (
  //                     <MenuItem onClick={() => handleBusinessRoutes(el)}>
  //                       <Box
  //                         component="div"
  //                         display="flex"
  //                         justifyContent="space-between"
  //                         alignItems="center"
  //                         textTransform="capitalize"
  //                       >
  //                         {el}
  //                       </Box>
  //                     </MenuItem>
  //                   ))}
  //                 </>
  //               )}
  //               {location.pathname === "/business-departments" && (
  //                 <>
  //                   {Object.keys(departmentRoutesList).map((el) => (
  //                     <MenuItem onClick={() => handleDepartmentRoutes(el)}>
  //                       <Box
  //                         component="div"
  //                         display="flex"
  //                         justifyContent="space-between"
  //                         alignItems="center"
  //                         textTransform="capitalize"
  //                       >
  //                         {el}
  //                       </Box>
  //                     </MenuItem>
  //                   ))}
  //                 </>
  //               )}
  //             </>
  //           ) : (
  //             <>
  //               {location.pathname !== "/dynamic-services" && (
  //                 <MenuItem onClick={handleScheduleClick}>
  //                   <Box
  //                     component="div"
  //                     display="flex"
  //                     justifyContent="space-between"
  //                     alignItems="center"
  //                   >
  //                     View Appointments
  //                   </Box>
  //                 </MenuItem>
  //               )}

  //               {location.pathname !== "/dynamic-services" && (
  //                 <MenuItem onClick={handleProfileClick}>
  //                   <Box
  //                     component="div"
  //                     display="flex"
  //                     justifyContent="space-between"
  //                     alignItems="center"
  //                   >
  //                     {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
  //                     Profile
  //                   </Box>
  //                 </MenuItem>
  //               )}
  //               {location.pathname === "/dynamic-services" && (
  //                 <MenuItem onClick={handleServiceSettingsRoute}>
  //                   <Box
  //                     component="div"
  //                     display="flex"
  //                     justifyContent="space-between"
  //                     alignItems="center"
  //                   >
  //                     {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
  //                     Service Settings
  //                   </Box>
  //                 </MenuItem>
  //               )}
  //             </>
  //           )}
  //         </Menu>
  //       )}
  //     </Box>
  //   );
  // }

  // const [columns, setColumns] = useState([]);

  // useEffect(() => {
  //   // const handleCellWidth = (cell) => {
  //   //   if (cell === "id") {
  //   //     return "70px";
  //   //   } else if (cell === "name") {
  //   //     return "190px";
  //   //   } else if (cell === "industry") {
  //   //     return "190px";
  //   //   } else if (cell === "user") {
  //   //     return "190px";
  //   //   } else {
  //   //     return "200px";
  //   //   }
  //   // };
  //   setColumns([...newArray]);
  // }, [newArray.length]);

  // const onPinColumn = (columnName, isPinned) => {
  //   const newCols = columns.map((col) => {
  //     if (col.columnName === columnName) {
  //       return {
  //         ...col,
  //         isPinned,
  //       };
  //     }
  //     return col;
  //   });

  //   newCols.sort((a, b) => {
  //     const aPinned = a.isPinned ? 1 : 0;
  //     const bPinned = b.isPinned ? 1 : 0;
  //     return bPinned - aPinned;
  //   });
  //   return setColumns([...newCols]);
  // };

  const getLeftStickyPos = (index) => {
    if (!index) return 0;

    const prevColumnsTotalWidth = newArray
      .slice(0, index)
      .reduce((curr, column) => {
        return curr + column.width;
      }, 45);
    return prevColumnsTotalWidth;
  };
  // const getLeftStickyPos = (index) => {
  //   if (!index) return 0;

  //   const prevColumnsTotalWidth = columns
  //     .slice(0, index)
  //     .reduce((curr, column) => {
  //       return curr + column.width;
  //     }, 45);
  //   return prevColumnsTotalWidth;
  // };

  return (
    <>
      <div className="overflow-auto dynamic-table-wrapper">
        <table className="dynamic-table">
          <thead>
            <tr>
              <th className="table-th-bg col1-th-sticky">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="col1-rec" />
                  <span className="col1-plus">
                    <FiPlusCircle />
                  </span>
                </div>
              </th>
              {newArray.map((el, i) => (
                <th
                  key={el.columnName}
                  className={classNames({
                    "ps-3 pr-1 text-left text-nowrap": true,
                    "table-th-bg": true,
                    "position-sticky th-sticky": el.isSticky,
                  })}
                  style={{
                    left: !i ? "45px" : getLeftStickyPos(i),
                    width: `${el.width}px`,
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center me-2">
                    <span>{el.columnName}</span>
                    <div
                      className="cursor-pointer"
                      onClick={(event) => handleMenuClick(event, el)}
                    >
                      <img src={moreVGrey} alt="" />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newArray?.at(0)?.columnValue?.length !== 0 ? (
              newArray?.at(0)?.columnValue?.map((item, rowIndex) => {
                return (
                  <tr key={rowIndex} className="text-left">
                    <td className="col1-td">
                      <div
                        className="cursor-pointer"
                        onClick={(event) =>
                          handleMenuActionClick(event, newArray, rowIndex)
                        }
                      >
                        <img src={moreVBlack} alt="" />
                      </div>
                    </td>
                    {newArray.map((col, i) => {
                      return (
                        <td
                          className={classNames({
                            "px-3 td-dynamic": true,
                            "position-sticky": col.isSticky,
                          })}
                          style={{
                            left: !i ? "45px" : getLeftStickyPos(i),
                          }}
                          key={col.columnName}
                        >
                          {col.isShown
                            ? typeof col.columnValue[rowIndex].values ===
                              "string"
                              ? col.columnValue[rowIndex].values
                              : Array.isArray(col.columnValue[rowIndex].values)
                              ? col.columnValue[rowIndex].values.length > 0
                                ? col.columnValue[rowIndex].values[0] // Display only the first element
                                : ""
                              : JSON.stringify(col.columnValue[rowIndex].values)
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={newArray.length + 1} align="center">
                  <DataNotFound />{" "}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </tbody>
        </table>
      </div>
      {open && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleHideClick}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Hide
            </Box>
          </MenuItem>
          <MenuItem>
            {selectedData.isSticky ? (
              <Typography onClick={handleUnFreezeClick}>UnFreeze</Typography>
            ) : (
              <Typography onClick={handleFreezeClick}>Freeze</Typography>
            )}
          </MenuItem>
        </Menu>
      )}
      {openAction && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {location.pathname === "/businesses" ||
          location.pathname === "/business-departments" ||
          location.pathname === "/business-users" ||
          location.pathname === "/business-teams" ||
          location.pathname === "/dynamic-services" ? (
            <>
              {location.pathname === "/dynamic-services" && (
                <>
                  {" "}
                  <MenuItem onClick={handleServiceSettingsRoute}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
                      Service Settings
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={(e) => handleAssignStaff(e)}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Assign Staff
                    </Box>
                  </MenuItem>
                </>
              )}
              {location.pathname === "/businesses" && (
                <>
                  {Object.keys(businessRoutesList).map((el) => (
                    <MenuItem onClick={() => handleBusinessRoutes(el)}>
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        {el}
                      </Box>
                    </MenuItem>
                  ))}
                </>
              )}
              {location.pathname === "/business-departments" && (
                <>
                  {Object.keys(departmentRoutesList).map((el) => (
                    <MenuItem onClick={() => handleDepartmentRoutes(el)}>
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        {el}
                      </Box>
                    </MenuItem>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {location.pathname !== "/dynamic-services" && (
                <MenuItem onClick={handleScheduleClick}>
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    View Appointments
                  </Box>
                </MenuItem>
              )}

              {location.pathname !== "/dynamic-services" && (
                <MenuItem onClick={handleProfileClick}>
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
                    Profile
                  </Box>
                </MenuItem>
              )}
              {location.pathname === "/dynamic-services" && (
                <MenuItem onClick={handleServiceSettingsRoute}>
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
                    Service Settings
                  </Box>
                </MenuItem>
              )}
            </>
          )}
        </Menu>
      )}
    </>
  );
}

// export default StaffListTable;
