import React from "react";
import Actions from "./Actions";
import Avatar from "@mui/material/Avatar";
import { DataNotFound } from "../../../utils";
import TableCell from "@mui/material/TableCell";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import axios from "axios";
import ActionMenu from "./ActionMenu"
const TableRows = (props) => {
  const {
    rowData,
    toggleDrawerHandler,
    selectedCheckboxes,
    handleRowCheckboxChange,
    rowsPerPage,
    deleteTeam,
    page,
  } = props;
  const history = useHistory();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  let tableData = rowData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [data, setData] = React.useState(rowData);
  const [switchStates, setSwitchStates] = React.useState([]);
  React.useEffect(() => {
    setSwitchStates(rowData.map((row) => row.isActive || false));
  }, [rowData]);
  const handleClick = (row) => {
    localStorage.setItem("teamRecord", JSON.stringify(row));
    history.push("/staff-list");
  };
  const handleSwitchChange = (event, index, row) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = event.target.checked;
    setSwitchStates(updatedSwitchStates);
    const isActive = event.target.checked;

    // Example of making an API call
    // Make sure to replace this code with your actual API call
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/business_svc/b1/team/${row.id}/`,
        { isActive }
      )
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        // Handle the error if needed
      });

    // Here, we're just updating the local state directly
    const updatedRow = { ...row, active: isActive };
    setData(updatedRow);
    // You may need to update the rowData state with the updated row
    // or update the backend data accordingly
  };
  return (
    <>
      {rowData.length > 0 ? (
        rowData.map((row, index) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              <TableCell
                style={{
                  border: "1px solid #ededed",
                  position: "sticky",
                  left: "auto",
                }}
                padding="checkbox"
              >
                <ActionMenu
                handleClick={handleClick}
                row={row}
                index={index}
                toggleDrawerHandler={toggleDrawerHandler}
                />
                {/* <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  className="form-check-input2 checkbox_btn2"
                  checked={selectedCheckboxes.includes(row.name)}
                  onChange={(event) => handleRowCheckboxChange(event, row.name)}
                /> */}
              </TableCell>{" "}
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <Link
                  underline="none"
                  onClick={() => {
                    handleClick(row);
                  }}
                >
                  <span className="link-hover bolds">{row.id}</span>
                </Link>{" "}
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <Link
                  underline="none"
                  onClick={() => {
                    handleClick(row);
                  }}
                >
                  <span className=" link-hover bolds">{row.name}</span>
                </Link>{" "}
              </TableCell>
              <TableCell style={{ border: "1px solid #ededed" }}>
                {row.numUsers}
                {/* <div className="d-flex align-items-center justify-content-center">
                  <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                    {row.userNo.slice(0, 5).map((img) => (
                      <Avatar
                        src={img.img}
                        alt={"img"}
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: 10,
                          backgroundColor: "red",
                        }}
                      />
                    ))}
                    {row.userNo.length > 5 && (
                      <Avatar
                        style={{
                          width: "25px",
                          height: "25px",
                          fontSize: "10px",
                          color: "#0d6efd",
                          fontWeight: 700,
                          backgroundColor: "rgb(37 150 190 / 30%)",
                        }}
                      >
                        <span className="size"> +{row.userNo.length - 5}</span>
                      </Avatar>
                    )}
                  </div>
                </div> */}
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="d-flex justify-content-center"
              >
                <div className="d-flex justify-content-center">
                  <Switch
                    {...label}
                    onChange={(event) => handleSwitchChange(event, index, row)}
                    // checked={row.isActive}
                    checked={switchStates[index]}
                    color="primary"
                    className="d-flex justify-content-center"
                  />
                </div>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
                id={"action-" + index}
              >
                <Actions
                  row={row}
                  index={index}
                  deleteTeam={deleteTeam}
                  toggleDrawerHandler={toggleDrawerHandler}
                />
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <DataNotFound />{" "}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
export default TableRows;
