import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageInput from "./dynamicFormView/imageInput";
import "./viewPage.css";
import TextField from "./dynamicFormView/textFieldInput";
import DropDown from "./dynamicFormView/dropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../Store/dynamicForm/slice";
import { customSnackBar } from "../../utils";
import CustomPhoneInput from "./dynamicFormView/phoneInput";
import instance from "../../utils/utils";

const AddCustomerForm = ({
  isModal = false,
  handleFormSubmission,
  handleSelectedCustomer,
  isModalOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.DynamicForm.form);
  const isLoading = useSelector((state) => state.DynamicForm.isLoading);
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const selectedBusinessId = useSelector(
    (state) => state.DynamicForm.selectedBusiness
  );
  const [formData, setFormData] = useState({
    customerFirstName: "",
    customerLastName: "",
    primaryEmail: "",
    primaryMobile: "",
    secondaryEmail: "",
    secondaryMobile: "",
    dob: "",
    image: "",
    title: "",
    gender: 0,
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    country: 0,
  });

  const handleFieldChange = (columnName, columnValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [columnName]: columnValue,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // const formattedData = { ...formData };
    // formState.sections.forEach((section) => {
    //   section.containers.forEach((container) => {
    //     container.fields.forEach((field) => {
    //       if (field.fieldType === "dropdown") {
    //         if (field.columnName !== "country" && field.columnName !== "gender") {
    //           formattedData[field.columnName] = formattedData[field.columnName].toString()
    //         }
    //       }
    //     });
    //   });
    // });
    const requiredFieldsEmpty = formState.sections.some((section) =>
      section.containers.some((container) =>
        container.fields.some(
          (field) => field.isRequired && !formData[field.columnName]
        )
      )
    );
    if (requiredFieldsEmpty) {
      customSnackBar("Please fill in all required fields.");
      return;
    }
    try {
      dispatch(setIsLoadingTrue());
      const response = await instance.post(
        `/customer_svc/pv/Customers/addCustomerDynamically`,
        { ...formData, businessId: selectedBusinessId, createdBy: userID }
      );
      if (response.data.code === 0) {
        if (isModal) {
          handleSelectedCustomer(response.data.result.customer);
          handleFormSubmission();
        } else {
          history.goBack();
        }
        customSnackBar("Added Customer Successfully");
      } else {
        const message = response?.data?.message;
        customSnackBar(`Error Occured: ${message}`);
      }
    } catch (e) {
      customSnackBar(e);
    } finally {
      dispatch(setIsLoadingFalse());
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <form
          className={isModal ? "dynamic_formModal" : "dynamic_formContainer"}
          onSubmit={handleFormSubmit}
        >
          {isModalOpen ? null : (
            <div className="d-flex justify-content-start align-items-center">
              <div
                className="pt-2"
                onClick={() => history.goBack()}
                role="button"
              >
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
            </div>
          )}
          {formState?.sections?.map((item, index) => (
            <div
              key={index}
              className={`${index !== 0 ? "dynamicform_section" : "mt-1"}`}
            >
              <span className="dynamicform_sectionTitle">
                {item.sectionTitle}
                <span
                  className={index === 0 ? "dynamic_formCompulsory" : "d-none"}
                >
                  {" (Compulsory to fill)"}
                </span>
              </span>
              {item.containers?.map((cont, i) => (
                <div
                  key={i}
                  className="d-flex align-items-center justify-content-center dynamicform_container"
                >
                  {cont.fields?.map((field, ind) => {
                    if (field.fieldType === "image") {
                      return (
                        <ImageInput
                          key={ind}
                          onImageChange={(selectedFile) =>
                            handleFieldChange(field.columnName, "selectedFile")
                          }
                        />
                      );
                    } else if (field.hasOption === true) {
                      // const findIndexOfOptions =  formData.findIndex(
                      //   (item) => item.columnName === field.columnName
                      // );
                      return (
                        <DropDown
                          key={ind}
                          isRequired={field.isRequired}
                          id={field.columnName}
                          options={field.options}
                          placeholder={`Select ${field.fieldLabel}`}
                          value={
                            field?.options[
                              field?.options?.findIndex(
                                (item) =>
                                  parseInt(formData[field.columnName]) ===
                                  item?.optionId
                              )
                            ]?.optionName
                          }
                          onChange={(value) => {
                            const id = field.options.findIndex(
                              (item) => item.optionName === value
                            );
                            const val =
                              field.fieldType === "int"
                                ? field.options[id].optionId
                                : field.options[id].optionId.toString();
                            handleFieldChange(field.columnName, val);
                          }}
                          labelKey={field.fieldLabel}
                          className={
                            ind === 1 && cont.fields.length === 2
                              ? "ms-2"
                              : (ind === 1 && cont.fields.length === 3) ||
                                (ind === 1 && cont.fields.length === 4)
                              ? "mx-2"
                              : ind === 3
                              ? "ms-2"
                              : ""
                          }
                        />
                      );
                    } else if (
                      field.columnName === "primaryMobile" ||
                      field.columnName === "secondaryMobile"
                    ) {
                      return (
                        <CustomPhoneInput
                          title={field.fieldLabel}
                          isRequired={field.isRequired}
                          classNameParent={`
                            ${
                              ind === 1 && cont.fields.length === 2
                                ? "ms-2"
                                : (ind === 1 && cont.fields.length === 3) ||
                                  (ind === 1 && cont.fields.length === 4)
                                ? "mx-2"
                                : ind === 3
                                ? "ms-2"
                                : ""
                            }`}
                          value={
                            formData[field.columnName] || ""
                            // formData.find(
                            //   (item) => item.columnName === field.columnName
                            // )?.columnValue || ""
                          }
                          onChange={(value) =>
                            handleFieldChange(field.columnName, value)
                          }
                        />
                      );
                    } else {
                      return (
                        <TextField
                          key={ind}
                          label={field.fieldLabel}
                          isRequired={field.isRequired}
                          placeholder={`Enter ${field.fieldLabel}`}
                          type={
                            field.fieldType === "string"
                              ? "text"
                              : field.fieldType === "int" ||
                                field.fieldType === "float"
                              ? "number"
                              : field.fieldType === "date" ||
                                field.fieldType === "dob"
                              ? "date"
                              : "text"
                          }
                          value={
                            formData[field.columnName] || ""
                            // formData.find(
                            //   (item) => item.columnName === field.columnName
                            // )?.columnValue || ""
                          }
                          classNameParent={
                            ind === 1 && cont.fields.length === 2
                              ? "ms-2"
                              : (ind === 1 && cont.fields.length === 3) ||
                                (ind === 1 && cont.fields.length === 4)
                              ? "mx-2"
                              : ind === 3
                              ? "ms-2"
                              : ""
                          }
                          onHandleChange={(e) =>
                            handleFieldChange(field.columnName, e.target.value)
                          }
                        />
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          ))}
          <div className="d-flex justify-content-center ">
            <button type="submit" className="dynamic_AddNewCustomer">
              Add New Customer
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AddCustomerForm;

// filterFirstName,
//   filterLastName,
//   filterEmail,
//   filterContactNumber,

// useEffect(() => {
//   if (formState?.sections) {
//     const initialData = formState.sections.reduce((acc, section) => {
//       if (section.containers) {
//         section.containers.forEach((container) => {
//           if (container.fields) {
//             container.fields.forEach((field) => {
//               let columnValue;
//               switch (field.columnName) {
//                 case "customerFirstName":
//                   columnValue = filterFirstName || "";
//                   break;
//                 case "customerLastName":
//                   columnValue = filterLastName || "";
//                   break;
//                 case "primaryEmail":
//                   columnValue = filterEmail || "";
//                   break;
//                 case "primaryMobile":
//                   columnValue = filterContactNumber || "";
//                   break;
//                 case "image":
//                   columnValue = "null";
//                   break;
//                 case "dropdown":
//                   columnValue = 0;
//                   break;
//                 default:
//                   columnValue = "";
//                   break;
//               }
//               acc[field.columnName] = columnValue;
//             });
//           }
//         });
//       }
//       return acc;
//     }, []);
//     console.log(initialData);
//     setFormData((prevData)=> ({
//       ...prevData,
//       initialData,
//     }));
//   }
// }, [
//   formState,
//   filterContactNumber,
//   filterFirstName,
//   filterLastName,
//   filterEmail,
// ]);

// !formData.find((item) => item.columnName === field.columnName)
//   ?.columnValue

// console.log(columnName,columnValue);
// Find the index of the column in formData
// const columnIndex = formData.findIndex(
//   (data) => data.columnName === columnName
// );

// // If the column is already in formData, update the value; otherwise, add a new entry
// if (columnIndex !== -1) {
//   setFormData((prevData) => {
//     const newData = [...prevData];
//     newData[columnIndex].columnValue = columnValue;
//     return newData;
//   });
// } else {
//   setFormData((prevData) => [
//     ...prevData,
//     {
//       columnName,
//       columnValue,
//     },
//   ]);
// }