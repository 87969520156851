import React, { useEffect } from 'react';
import CostForm from './components/CostForm';
import CostFormMobile from './components/CostFormMobile';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCostTypes, getServiceCosts } from '../../store/servicesActions';
import ServiceLoader from '../ServiceLoader';
import TestForm from './components/TestForm';

const Cost = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(state => state?.servicesReducer);

  useEffect(() => {
    if (id) {
      dispatch(getServiceCosts(id))
      dispatch(getAllCostTypes())
    }
  }, [])

  return (
    <>
      {store?.isLoading && store?.serviceCosts.length === 0 ?
        <ServiceLoader />
        : <>
          <CostForm />
          <CostFormMobile />
        </>
      }
    </>
  )
}

export default Cost
