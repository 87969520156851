import React, { useEffect, useState } from "react";
import Features from "../Features/Features";
import ContactUs from "../ContactUs/ContactUs";
import Hero from "../Hero/Hero";
import { HomePageContent, defaultData } from "../../static/Content";
import { useParams } from "react-router-dom";
import AutomateProcess from "../AutomateProcess/AutomateProcess";
import NewsLatter from "../../../HRManagement/components/NewsLatter/NewsLatter";
import Footer from "../../../Calendex/components/Footer/Footer";
import { Header } from "./../../../../Hero/Header";
import {
  FinanceWeb,
  FinanceMobile,
  finance1,
  finance2,
} from "../../../../../../Assets/components/HomePage/Assets";

const Home = () => {
  const [pageData, setPageData] = useState([]);
  const { id } = useParams();
  const { readMorePages } = HomePageContent;

  // Find the page object that matches the provided id
  useEffect(() => {
    if (id) {
      const filtered = readMorePages.filter((val) => val?.id === id);
      setPageData(filtered);
    } else {
      setPageData(defaultData);
    }
  }, [id]);

  return (
    <div>
      {/* <Hero pageData={pageData} /> */}
      <Header
        title={"Ondel"}
        heading={"No.1 AI-Powered Accounting Software for Every Business"}
        text={
          "A comprehensive financial management system to track expenses, manage invoices, & process payments. Generate financial reports & gain insights into your business's financial health."
        }
        imgWeb={FinanceWeb}
        imgMob={FinanceMobile}
      />
      <AutomateProcess pageData={pageData} />
      <ContactUs pageData={pageData} />
      <Features pageData={pageData} fn1={finance1} fn2={finance2} />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home;
