// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar {
  display: inline-block;
  position: fixed;
  background: #323232;
  color: #f1f1f1;
  min-height: 50px;
  min-width: 290px;
  padding: 16px 24px 12px;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  bottom: 1rem;
  left: 1rem;
  transform: translateX(-50%);
  text-align: center;
  font-size: 90%;
  transition: visibility 0.3s, transform 0.3s;
  transform: translateY(100px);
  visibility: hidden;
  will-change: transform;
  z-index: 1000000000000000000;
}

.snackbar--visible {
  visibility: visible;
  transform: none;
}`, "",{"version":3,"sources":["webpack://./src/snackbar/snackbar.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,sBAAA;EACA,2CAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,2BAAA;EACA,kBAAA;EACA,cAAA;EACA,2CAAA;EACA,4BAAA;EACA,kBAAA;EACA,sBAAA;EACA,4BAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF","sourcesContent":[".snackbar {\n  display: inline-block;\n  position: fixed;\n  background: #323232;\n  color: #f1f1f1;\n  min-height: 50px;\n  min-width: 290px;\n  padding: 16px 24px 12px;\n  box-sizing: border-box;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);\n  border-radius: 2px;\n  bottom: 1rem;\n  left: 1rem;\n  transform: translateX(-50%);\n  text-align: center;\n  font-size: 90%;\n  transition: visibility 0.3s, transform 0.3s;\n  transform: translateY(100px);\n  visibility: hidden;\n  will-change: transform;\n  z-index: 999999999999999999;\n}\n\n.snackbar--visible {\n  visibility: visible;\n  transform: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
