import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { Plans, list, lists } from "./helper";
import axios from "axios";
import { GET_PLAN, GET_PLAN_ENTITIES } from "../../../utils/GraphQLQueries";
import { success } from "../../../utils";
const PlanEntity = ({ list }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
          {
            query: GET_PLAN_ENTITIES,
          },
          {
            referrerPolicy: "no-referrer-when-downgrade",
          }
        );
        if (response.data.data) {
          success("PlanEntites Fetch Successfully");
          setData(response.data.data.planEntities);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="card1 col-md-3  col-12 ms-0 ">
        <div className="col-md-12 col-12">
          <img
            className="image4 col-md-2"
            alt="no-data"
            src="../images/771cc70a-0e69-4581-b366-4d0c4f3dfcde.png"
          />
        </div>
        <div className="col-md-10 ms-3 col-10  mt-4 mt-1 pt-3 pb-5 mb-3">
          <div className="dropdown row">
            <div className="col-md-10 ps-0">
              <button
                className="btns"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="dropdow"> Essentials</span>
              </button>
            </div>
            <div
              className="col-md-2 btns  dropdown-toggle"
              style={{ color: "#3C89FF" }}
            ></div>
          </div>
        </div>
        <div>
          {data.map((list) => {
            return (
              <>
                <div className="">
                  <div className="d-flex">
                    <div className="col-md-2 ms-0  ps-0 col-2">
                      <TiTick
                        style={{
                          width: "35px",
                          height: "25px",
                          color: "#72D5C9",
                        }}
                      />
                    </div>
                    <div className="mt-1 ps-0"> {list.name}</div>
                  </div>
                  <div>
                    {" "}
                    <hr className="hr ms-2 pt-0  " />
                  </div>
                </div>
              </>
            );
          })}{" "}
        </div>
      </div>
    </>
  );
};
export default PlanEntity;
