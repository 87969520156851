import SideNavone from "../../../../SideNavBar";
// import CreateFormMobile from "Pages/InventoryServices/services/create/components/CreateFormMobile";
import ServicePricing from "./ServicePricing";
import { useState } from "react";

const ServicePricingContainer = () => {
  const [priceTypeVal, setPriceTypeVal] = useState(20);

  const handlePriceTypeVal = (e) => setPriceTypeVal(e.target.value);

  return (
    <>
      <SideNavone>
        <ServicePricing
          priceTypeVal={priceTypeVal}
          handlePriceTypeVal={handlePriceTypeVal}
        />
        {/* <CreateFormMobile /> */}
      </SideNavone>
    </>
  );
};
export default ServicePricingContainer;
