import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Fade,
  FormControlLabel,
  Modal,
  SvgIcon,
  styled,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TuneIcon from "@mui/icons-material/Tune";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerData, selectedServiceData } from "../store/bookingSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomerSearch from "./Listplexaar";
import "../../assets/scheduler/css/calendar.css";
import {
  CalendarSvg,
  DecreasePackageQuantitySvg,
  IncreasePackageQuantitySvg,
  MoreOptionsVertSvg,
  PaymentSvg,
  SandTimerSvg,
  TimerSvg,
  ArrowDownYellowSvg,
  ArrowUpYellowSvg,
} from "../../../Assets/svgs";
import { customSnackBar, formatTime } from "../../../utils";
import { fetchCountryRequest } from "../../../Store/Business/actions";
import { Form, Formik } from "formik";
import {
  initialValuesofCustomer,
  validationSchemaofCustomer,
} from "../../utils/helper";
import { getAllCustomer } from "../../../Pages/Dashboard/MainComponent/store/staffActions";
import AddCustomerForm from "../../../Pages/DynamicForm/addCustomerForm";
import { getOpenBooking } from "../store/calendarSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BntLoader } from "../../../Componenets/loaders";
import {
  providerImageURL,
  serviceImageURL,
} from "../../../utils/imageUrlsExtension";

export default function AddCustomer({
  setSelectSlotFlag,
  callBackHandler,
  selectSlotFlag,
}) {
  let selectedService = useSelector(
    (state) => state.bookingReducer.servicesData
  );
  const history = useHistory();
  const business = useSelector((state) => state.Business?.selectedBusiness);

  const userData = useSelector(
    (state) => state.Auth?.userloginsuccessyasir.users
  );
  let slotData = useSelector((state) => state.bookingReducer.slotsData);
  let openBooking = useSelector((state) => state.calendarReducer.openBooking);
  const { customerData } = useSelector((state) => state.bookingReducer);
  const [addFlag, setAddFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [initialDataToShow, setInitialDataToShow] = useState();
  // const [loading,setLoading]=use
  const store = useSelector((state) => state?.staffReducer);

  const openModal = (data) => {
    setInitialDataToShow(data);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const increaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (service.serviceName === serviceName.serviceName) {
        return { ...service, bookedSeats: service?.bookedSeats + 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };

  const decreaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (
        service.serviceName === serviceName.serviceName &&
        service.bookedSeats > 1
      ) {
        return { ...service, bookedSeats: service.bookedSeats - 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };
  const date = new Date(slotData.bookingDate);

  const options = {
    weekday: "long", // Get the full day name (e.g., "Monday")
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };

  const formattedDate = date.toLocaleString("en-US", options);
  const handleSelectedCustomer = async (data) => {
    let serviceSku = "";
    if (
      selectedService[0]?.attributes &&
      selectedService[0]?.attributes.length > 0
    ) {
      serviceSku = selectedService[0].attributes[0].attributeSku;
    } else if (selectedService[0].serviceSKU === "") {
      serviceSku = selectedService[0].isOnlineSKU;
    } else {
      serviceSku = selectedService[0].serviceSKU;
    }
    dispatch(addCustomerData({ ...data }));

    dispatch(
      getOpenBooking({ id: data.customerId, sku: serviceSku, setLoader })
    );
    const actionResult = await dispatch(
      getOpenBooking({ id: data.customerId, sku: serviceSku, setLoader })
    );
    // Check if the openBooking action was successful
    if (actionResult.payload && actionResult.payload.data) {
      if (actionResult.payload.data.length === 0) {
        setSelectSlotFlag("payment");
      } else {
        setSelectSlotFlag("openBooking");
      }
    }
    // if (!loader&&openBooking.length === 0) {
    //   setSelectSlotFlag("payment");
    // } else {
    //   setSelectSlotFlag("openBooking");
    // }
  };

  return (
    <>
      {loader && <BntLoader />}

      <div
        className="d-flex mt-2 customer_flex"
        style={{
          paddingLeft: "18%",
          paddingRight: "18%",
        }}
      >
        <div
          className="pt-2"
          onClick={() => (isModalOpen ? closeModal() : callBackHandler())}
          role="button"
        >
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>

        {isModalOpen ? (
          <AddCustomerForm
            isModal={true}
            loading={loading}
            isModalOpen={isModalOpen}
            handleFormSubmission={() => closeModal()}
            filterContactNumber={initialDataToShow.filterContactNumber}
            filterFirstName={initialDataToShow.filterFirstName}
            filterLastName={initialDataToShow.filterLastName}
            filterEmail={initialDataToShow.filterEmail}
            handleSelectedCustomer={handleSelectedCustomer}
          />
        ) : (
          <>
            <div
              style={{
                height: "100vh",
                overflow: "auto",
                paddingBottom: "80px",
              }}
              className="container details_card"
            >
              <div
                className="container pb-2 px-2 pt-1"
                style={{
                  borderRadius: "8px",
                  border: "0.6px solid #DCDCDC",
                  background: "#FFF",
                  position: "relative",
                }}
              >
                <div className="d-flex mt-2 justify-content-between align-items-center">
                  <span className="add-customer-heading">Service Details</span>
                  <div className="d-flex">
                    <span className="add-customer-quantity pt-1">Quantity</span>{" "}
                    <div
                      className="ms-3"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F6F6F6",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0px 10px",
                      }}
                    >
                      <div
                        onClick={(e) => {
                          decreaseQuantity(selectedService[0]);
                        }}
                        role="button"
                      >
                        <DecreasePackageQuantitySvg />
                      </div>
                      <div
                        className="mx-2"
                        style={{
                          color: "#4D4D4D",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 500,
                        }}
                      >
                        {selectedService[0]?.bookedSeats
                          ? selectedService[0]?.bookedSeats
                          : 1}
                        {/* {quantity} */}
                      </div>
                      <div
                        onClick={(e) => {
                          increaseQuantity(selectedService[0]);
                        }}
                        role="button"
                      >
                        <IncreasePackageQuantitySvg />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div>
                    {selectedService.map((vl, index) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between">
                            <div key={index} className="d-flex ">
                              {selectedService ? (
                                <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    alt={vl?.serviceName}
                                    src={serviceImageURL(vl?.serviceId)}
                                    variant="rounded"
                                    style={{
                                      borderRadius: 50,
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: "#F0F0F0",
                                      // marginRight: 10,
                                      color: "#B5B5B5",
                                      fontWeight:"100"
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    sx={{
                                      width: "30px",
                                      height: "30px",
                                      // marginRight: 10,
                                      borderRadius: 50,
                                      backgroundColor: "#F0F0F0",
                                      color: "#B5B5B5",
                                      fontWeight:"300"
                                    }}
                                  >
                                    S
                                  </Avatar>
                                </div>
                              )}
                              <div className="d-flex mt-2 justify-content-between ">
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "capitalize",
                                  }}
                                  className=" justify-content-start"
                                >
                                  <div
                                    className={`d-flex ${
                                      vl &&
                                      vl.attributes &&
                                      vl.attributes.length === 0
                                        ? "pt-0"
                                        : "pt-0"
                                    }`}
                                  >
                                    {/* Your content here */}

                                    <div className="d-flex flex-column">
                                      <p className="m-0 mx-2 selected_servicename">
                                        {vl?.serviceName}{" "}
                                        <span className="selected_serviceduration ps-2">
                                          ({vl?.duration} Mins)
                                        </span>
                                      </p>
                                      {vl.attributes &&
                                        vl.attributes[0] &&
                                        vl.attributes.length > 0 && (
                                          <span
                                            style={{
                                              fontSize: "8.5px",
                                              lineHeight: "10px",
                                            }}
                                            className="m-0 mx-2 selected_servicevalue"
                                          >
                                            {vl.attributes[0].value}
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr
                              className="mt-3 ms-0 mb-1"
                              style={{
                                color: "#c4c4c4",
                                margin: "15.6px 0 21.4px",
                              }}
                            />
                            <div className="d-flex justify-content-end align-items-center">
                              {/* <PaymentSvg /> */}
                              {selectedService[0]?.bookedSeats > 0 && (
                                <span className="ms-1 serviceprice">
                                  {vl?.currency === null
                                    ? "£"
                                    : vl.currency === "USD"
                                    ? "$"
                                    : "£"}
                                  {vl.actualPrice === null ||
                                  vl.actualPrice === 0
                                    ? `${1 * selectedService[0]?.bookedSeats}`
                                    : vl.actualPrice *
                                      selectedService[0]?.bookedSeats}
                                </span>
                              )}
                            </div>
                          </div>

                          {isExpanded && (
                            <div className="pt-0">
                              <hr
                                className="mt-3 ms-0 mb-1"
                                style={{
                                  color: "#c4c4c4",
                                  margin: "15.6px 0 21.4px",
                                }}
                              />
                              {/* {selectedService[0].attributes && (
                                <div>
                                  <span
                                    style={{ fontSize: "12px" }}
                                    className="businessId"
                                  >
                                    Attributes
                                  </span>
                                  <div
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    role="button"
                                    className="d-flex justify-content-end align-items-center"
                                  ></div>
                                  {selectedService[0].attributes &&
                                    selectedService.map((vl, index) =>
                                      vl.attributes.map((value) => (
                                        <div
                                          className="d-flex justify-content-between align-items-center my-2"
                                          key={index}
                                        >
                                          <div className="d-flex align-items-center">
                                            <Avatar
                                              alt={value.value}
                                              src="/images/tag 1.svg"
                                              // src={value?.value}
                                              style={{ width: 20, height: 20 }}
                                              onError={(e) => {
                                                e.target.src =
                                                  "https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg";
                                              }}
                                            />
                                            <Tooltip title={value.value}>
                                              <p className="m-0 mx-2 ellipsis selected_servicename">
                                                {value.value}
                                              </p>
                                            </Tooltip>
                                          </div>
                                          <div className="d-flex justify-content-around align-items-center">
                                            <div className="d-flex justify-content-end align-items-center">
                                              <SandTimerSvg />
                                              <span
                                                className="ms-1"
                                                style={{
                                                  color: "#4D4D4D",
                                                  textAlign: "center",
                                                  fontFamily: "Poppins",
                                                  fontSize: "11px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                {value.duration + " Min "}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-center">
                                            <PaymentSvg />
                                            <span
                                              className="ms-1"
                                              style={{
                                                color: "#4D4D4D",
                                                textAlign: "center",
                                                fontFamily: "Poppins",
                                                fontSize: "11px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                              }}
                                            >
                                              £{value.attributePrice}
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-end align-items-center">
                                            <MoreOptionsVertSvg />
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  <hr
                                    className="mt-3 ms-0 mb-1"
                                    style={{
                                      color: "#c4c4c4",
                                      margin: "15.6px 0 21.4px",
                                    }}
                                  />
                                </div>
                              )} */}
                              <div className="">
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="servicedetails-heading"
                                >
                                  Appointment Details
                                </span>
                                <div className="d-flex align-items-center my-1">
                                  <div className="d-flex align-items-center">
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="30"
                                        height="30"
                                        rx="15"
                                        fill="#F0F0F0"
                                      />
                                      <path
                                        d="M20.625 12.1875V11.25C20.625 10.9185 20.4933 10.6005 20.2589 10.3661C20.0245 10.1317 19.7065 10 19.375 10H10.625C10.2935 10 9.97554 10.1317 9.74112 10.3661C9.5067 10.6005 9.375 10.9185 9.375 11.25V20C9.375 20.3315 9.5067 20.6495 9.74112 20.8839C9.97554 21.1183 10.2935 21.25 10.625 21.25H12.8125"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17.5 8.75V11.25"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12.5 8.75V11.25"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.375 13.75H12.5"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M18.4375 18.4375L17.5 17.6562V16.25"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M21.25 17.5C21.25 18.4946 20.8549 19.4484 20.1517 20.1517C19.4484 20.8549 18.4946 21.25 17.5 21.25C16.5054 21.25 15.5516 20.8549 14.8483 20.1517C14.1451 19.4484 13.75 18.4946 13.75 17.5C13.75 16.5054 14.1451 15.5516 14.8483 14.8483C15.5516 14.1451 16.5054 13.75 17.5 13.75C18.4946 13.75 19.4484 14.1451 20.1517 14.8483C20.8549 15.5516 21.25 16.5054 21.25 17.5Z"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    <div className="d-flex px-2 add-customer-date">
                                      {/* <div className="d-flex align-items-center me-1">
                                  <CalendarSvg />
                                </div> */}
                                      {formattedDate}
                                    </div>
                                  </div>

                                  <div
                                    className="d-flex px-4 align-items-center"
                                    style={{
                                      color: "#443F3F",
                                      fontFamily: "Roboto",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <div className="d-flex align-items-center me-1">
                                      {/* <TimerSvg /> */}
                                    </div>
                                    {formatTime(slotData.timeFrom)}{" "}-{" "}
                                    {formatTime(slotData.timeTo)}
                                  </div>
                                </div>
                              </div>
                              <hr
                                className="mt-3 ms-0 mb-1"
                                style={{
                                  color: "#c4c4c4",
                                  margin: "15.6px 0 21.4px",
                                }}
                              />
                              <div className="mt-0">
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="servicedetails-heading"
                                >
                                  Provider Name
                                </span>
                                <div className="d-flex justify-content-between align-items-center my-1">
                                  <div className="d-flex align-items-center">
                                    {/* <Avatar
                                      alt={
                                        slotData?.resourceObject
                                          ?.resourceTitle ??
                                        slotData?.provider?.title
                                      }
                                      src={providerImageURL(slotData.resourceId)}
                                      style={{ width: 30, height: 30, backgroundColor:"#F0F0F0", color:"#B5B5B5"}}
                                      onError={(e) => {
                                        e.target.src =
                                          "https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0=";
                                      }}
                                    /> */}
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="30"
                                        height="30"
                                        rx="15"
                                        fill="#F0F0F0"
                                      />
                                      <path
                                        d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                        stroke="#B5B5B5"
                                        stroke-width="0.75"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    <div
                                      className="ms-2"
                                      style={{
                                        color: "#443F3F",
                                        fontFamily: "Roboto",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {slotData?.resourceObject
                                        ?.resourceTitle ??
                                        slotData?.provider?.resourceTitle}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
                {/* {selectedService[0].attributes && ( */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "-18px",
                    left: "50%",
                    zIndex: "9999",
                  }}
                  className="d-flex justify-content-center pb-1"
                >
                  <div
                    // style={{
                    //   background: "white",
                    //   cursor: "pointer",
                    //   border: " 0.6px solid rgb(220, 220, 220)",
                    //   borderRadius: "50px",
                    //   height: "35px",
                    //   width: "35px",
                    //   alignItems: "center"
                    // }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <div className="">
                      {!isExpanded ? (
                        <Tooltip title="Expand">
                          <div style={{ height: "100%", width: "100%" }}>
                            <ArrowDownYellowSvg />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Collap">
                          <ArrowUpYellowSvg />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                {/* // )} */}
              </div>
              <div className="py-2 w-100">
                {/* {addFlag ? ( */}
                <CustomerSearch
                  setLoading={setLoading}
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  loading={loading}
                  openModal={openModal}
                  handleSelectedCustomer={handleSelectedCustomer}
                />
                {/* ) : (
              <div className="w-100 row justify-content-center align-items-center my-3">
                <Button
                  style={{
                    backgroundColor: "#FFD705",
                    color: "#530",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontStyle: "normal",
                    borderRadius: "8px",
                    fontWeight: 400,
                    textTransform: "none",
                  }}
                  className="col-6 py-2 addCustomer_btn"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    // openModal();
                    setAddFlag(true);
                  }}
                >
                  Add Customer
                </Button>
              </div>
            )} */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
