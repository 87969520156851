import React, { useEffect, useState } from "react";
import { BsNewspaper } from "react-icons/bs";
import InputTextField from "../TextField";
import { MdAdd } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { TbFileSettings } from "react-icons/tb";
import { DataNotFound } from "../../../utils";

const UserInfo = ({
  handleChange,
  values,
  touched,
  errors,
  handleAddNewRole,
  role,
  action,
  userRoleID,
  setUserRoleID,
  handleRoles,
  selectedRolese,
  selectedRole,
  setSelectedRole,
}) => {

  const [showPermissions, setShowPermissions] = useState(true);

  return (
    <>
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 ">
        <label className="label_text_business">First Name</label>
        <input
          className="form-control1 input_style_fix"
          id="firstName"
          label="First Name"
          name="firstName"
          placeholder="Enter First Name"
          //size={20}
          variant="filled"
          value={values.firstName}
          touched={touched.firstName}
          error={touched.firstName && errors.firstName}
          onChange={handleChange}
        />
      </div>
      {errors.firstName && (
        <span className="text-danger font_size_password">
          {errors.firstName}
        </span>
      )}

      <div className="col-md-12 pt-2 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Last Name</label>
        <input
          className="form-control1 input_style_fix"
          label="Last Name"
          name="lastName"
          placeholder="Enter Last Name"
          //size={20}
          variant="filled"
          value={values.lastName}
          touched={touched.lastName}
          error={touched.lastName && errors.lastName}
          onChange={handleChange}
        />
      </div>
      {errors.lastName && (
        <span className="text-danger font_size_password">
          {errors.lastName}
        </span>
      )}

      <div className="col-md-12 mb-3 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">User Email </label>
        <input
          className="form-control1 input_style_fix"
          id="email"
          label="User Email "
          name="email"
          placeholder="Enter User Email"
          //size={20}
          disabled={action === "edit"}
          value={values.email}
          touched={touched.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          variant="filled"
        />
      </div>
      {errors.email && (
        <span className="text-danger font_size_password">{errors.email}</span>
      )}

      <span className=" pt-3 text_heading_owner">Busines Roles</span>
      <div>
        <span className="span m-auto">Select Role for business</span>
      </div>

      <div className="w-100 pb-2 d-sm-flex flex-column  !m-n2 justify-content-between">
        <div className="d-flex justify-content-between pt-3 pb-0">
          <div className="d-flex pb-0">
            <AiOutlineUser style={{ color: "#b2b2b2", fontSize: 25 }} />
            <span className="span ps-1 pt-1">Role</span>
          </div>
          <div className="d-flex  pb-0">
            <MdAdd
              style={{ color: "#538dff", fontSize: 20 }}
              onClick={handleAddNewRole}
            />
            <span
              className="span ps-1"
              style={{
                color: "#538dff",
                fontSize: "12px",
                fontWeight: 500,
                cursor: "pointer",
              }}
              onClick={handleAddNewRole}
            >
              Add New Role
            </span>
          </div>
        </div>

        <div>
          <div
            className="d-flex flex-wrap px-2 align-items-center"
            style={{ backgroundColor: "#FBFCFF" }}
          >
            {role && role.length > 0 ? (
              role.map((option, index) => (
                <div
                  className="d-flex flex-column flex-wrap pe-4"
                  key={option.value}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <input
                      type="checkbox"
                      value={option.id}
                      // checked={userRoleID.some(
                      //   (role) =>
                      //     role.id === option.id && role.index === index
                      // )}
                      // onChange={(event) =>
                      //   handleRoles(event, option, index)
                      // }
                      checked={selectedRolese.includes(option.id)}
                      // checked={
                      //   selectedRole === option
                      //   // selectedOptions.id === option?.service?.id
                      // }
                      // onChange={(event) => handleRoles(event, option, index)}
                      onChange={() =>
                        handleRoles(
                          option.id,
                          option?.business,
                          setSelectedRole
                        )
                      }
                      className="lager"
                      style={{
                        marginBottom: "20px",
                        marginRight: "10px",
                        marginTop: "3px",
                      }}
                    />
                    {option.name}
                  </label>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col-md-12 text-center">
                  
                    <p className="text-black-50 ">Roles not Found</p>
                  
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedRole !== null&& (
          <div
            style={{ backgroundColor: "#FBFCFF" }}
            className="d-flex flex-column flex-wrap px-2"
          >
            <div className="d-flex">
              <span
                className={` ${
                  !showPermissions ? "permission2" : "permission"
                }`}
                onClick={() => setShowPermissions((prev) => !prev)}
              >
                {`${showPermissions ? "See" : "Hide"} Permissions`}
              </span>
            </div>
            <div className="d-flex flex-column">
              {!showPermissions &&
                role
                  .filter((role) => role.id === selectedRole)
                  .map((selectedRole) =>
                    selectedRole.allowPermission.map((data) => {
                      const permissions = data.permissions;
                      const truePermissions = Object.keys(permissions).filter(
                        (key) => permissions[key] === true
                      );
                      return (
                        <>
                          <ul
                            className="d-flex flex-column orderlist"
                            key={data.module.id}
                          >
                            <div>
                              <TbFileSettings style={{ color: "#b2b2b2" }} />{" "}
                              <span className="span pt-3">
                                {data.module.name}
                              </span>
                              <div className="ps-3">
                                {truePermissions.map((permission, index) => (
                                  <li className="text-capitalize" key={index}>
                                    {permission}
                                  </li>
                                ))}
                              </div>
                            </div>
                          </ul>
                        </>
                      );
                    })
                  )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default UserInfo;
