// import React, { Component, useState } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// import "./test.css";
// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import CalendarHeader from "./scheduler/components/CalenderHeader";
// import ProviderWidget from "./scheduler/components/providerWidget";
// import CustomEventWidget from "./scheduler/components/customEventWidget";

// const localizer = momentLocalizer(moment);
// const DnDCalendar = withDragAndDrop(Calendar);

// const App = (props) => {
//     const [events, setEvents]  = useState([
//         {
//             "title": "PRP Hair Regrowth",
//             "start": "2023-12-16T10:00:00.000Z",
//             "end": "2023-12-16T10:45:00.000Z",
//             "resourceId": 381,
//             "allDay": false,
//             "background": false,
//             "bookingDate": "2023-12-16",
//             "bookingId": 3,
//             "color": "white",
//             "colorCode": "#548DFF",
//             "colorEvento": "#548DFF",
//             "customerFirstName": "hameed",
//             "customerLastName": "r",
//             "customerName": "hameed r",
//             "duration": 45,
//             "providerName": "Wednesday Two",
//             "resource": 381,
//             "serviceDetails": {
//                 "industryId": 4,
//                 "serviceId": 16,
//                 "duration": 45,
//                 "serviceSKU": "PR-42915222",
//                 "serviceName": "PRP Hair Regrowth",
//                 "serviceImage": null,
//                 "shortDesc": null,
//                 "longDesc": null,
//                 "serviceUrl": "prp-hair-regrowth",
//                 "consentForms": null,
//                 "serviceParentId": null,
//                 "bookedSeats": 1,
//                 "availableSeats": null,
//                 "countryId": [
//                     1
//                 ],
//                 "maxSesssions": null,
//                 "isInHouse": false,
//                 "isInClinic": true,
//                 "isOnline": false,
//                 "hasPrice": false,
//                 "isAddOn": false,
//                 "hasSessions": false,
//                 "hasAttributes": false,
//                 "hasProductCompulsory": false,
//                 "isRequiredDOB": false,
//                 "isRequiredGenderPreference": false,
//                 "hasPackage": false,
//                 "hasProductOptional": false,
//                 "hasChild": false,
//                 "hasVariablePrices": true,
//                 "actualPrice": 250,
//                 "priceId": 14,
//                 "priceType": "Per person",
//                 "currency": "£",
//                 "isActive": true,
//                 "isInHouseSKU": null,
//                 "isInClinicSKU": "PR-50947582",
//                 "isOnlineSKU": null,
//                 "outPutServiceAttachments": null,
//                 "outPutServiceAttributes": null,
//                 "outPutServicePrices": null,
//                 "outPutServiceCosts": null,
//                 "outPutServiceBFlow": null
//             },
//             "timeFrom": "15:00",
//             "timeTo": "15:45",
//             "type": "booking",
//             "sourceResource": null
//         },
//         {
//             "title": "PRP Hair Regrowth",
//             "start": "2023-12-16T06:00:00.000Z",
//             "end": "2023-12-16T06:45:00.000Z",
//             "resourceId": 382,
//             "allDay": false,
//             "background": false,
//             "bookingDate": "2023-12-16",
//             "bookingId": 5,
//             "color": "white",
//             "colorCode": "#548DFF",
//             "colorEvento": "#548DFF",
//             "customerFirstName": "hameed",
//             "customerLastName": "r",
//             "customerName": "hameed r",
//             "duration": 45,
//             "providerName": "New Provider",
//             "resource": 382,
//             "serviceDetails": {
//                 "industryId": 4,
//                 "serviceId": 16,
//                 "duration": 45,
//                 "serviceSKU": "PR-42915222",
//                 "serviceName": "PRP Hair Regrowth",
//                 "serviceImage": null,
//                 "shortDesc": null,
//                 "longDesc": null,
//                 "serviceUrl": "prp-hair-regrowth",
//                 "consentForms": null,
//                 "serviceParentId": null,
//                 "bookedSeats": 1,
//                 "availableSeats": null,
//                 "countryId": [
//                     1
//                 ],
//                 "maxSesssions": null,
//                 "isInHouse": false,
//                 "isInClinic": true,
//                 "isOnline": false,
//                 "hasPrice": false,
//                 "isAddOn": false,
//                 "hasSessions": false,
//                 "hasAttributes": false,
//                 "hasProductCompulsory": false,
//                 "isRequiredDOB": false,
//                 "isRequiredGenderPreference": false,
//                 "hasPackage": false,
//                 "hasProductOptional": false,
//                 "hasChild": false,
//                 "hasVariablePrices": true,
//                 "actualPrice": 250,
//                 "priceId": 14,
//                 "priceType": "Per person",
//                 "currency": "£",
//                 "isActive": true,
//                 "isInHouseSKU": null,
//                 "isInClinicSKU": "PR-50947582",
//                 "isOnlineSKU": null,
//                 "outPutServiceAttachments": null,
//                 "outPutServiceAttributes": null,
//                 "outPutServicePrices": null,
//                 "outPutServiceCosts": null,
//                 "outPutServiceBFlow": null
//             },
//             "timeFrom": "11:00",
//             "timeTo": "11:45",
//             "type": "booking",
//             "sourceResource": null
//         }
//     ]);
//     const [resourceMap, setResourceMap] = useState([
//         {
//             "resourceId": 381,
//             "resourceTitle": "Wednesday Two"
//         },
//         {
//             "resourceId": 382,
//             "resourceTitle": "New Provider"
//         }
//     ]);
//     const defaultDate = new Date();
//     const calendarRef = React.createRef();

//   const onEventResize = (data) => {
//     const { start, end } = data;

//     setEvents((state) => {
//       state[0].start = start;
//       state[0].end = end;
//       return [...state];
//     });
//   };

//   const onEventDrop = (data) => {
//     console.log(data);
//   };


//     return (
//       <div className="App">
//         <DnDCalendar
//           ref={calendarRef}
//           defaultDate={defaultDate}
//           defaultView="day"
//           events={events}
//           localizer={localizer}
//           resourceIdAccessor="resourceId"
//           resourceTitleAccessor="resourceTitle"
//           onEventDrop={onEventDrop}
//           onEventResize={onEventResize}
//           resources={resourceMap}
//           resizable
//           dayLayoutAlgorithm={"overlap"}
//           style={{
//             backgroundColor: "white",
//             // height: "100vh",
//           }}
//           culture="en"
//           onSelectEvent={(event) => console.log(event)}
//           draggableAccessor={(event) => true}
//           onSelectSlot={(slotInfo) => console.log(slotInfo)}
//           onDragOver={(event) => console.log(event)}
//           onDragStart={(data) => {
//             console.log(data);
//           }}
//           className="flex flex-1 h-100 w-100 padding_of_cal"
//           startAccessor={(event) => {
//             return new Date(event.start);
//           }}
//           endAccessor={(event) => {
//             return new Date(event.end);
//           }}
//           min={new Date(0, 0, 0, 1, 0, 0)}
//           max={new Date(0, 0, 0, 23, 30, 0)}
//           components={{
//             resourceHeader: (props) => {
//               return <ProviderWidget val={props.resource} />;
//             },
//             // toolbar: (toolbar) => {
//             // 	return (
//             // 		<CalendarHeader handleFilter={()=>{}} toolbar={toolbar} />
//             // 	);
//             // },
//             event: (event) => {
//             	return (
//             		<CustomEventWidget event={event} />
//             	)
//             },
//           }}
//           eventPropGetter={(myEventsList) => {
//             const backgroundColor =
//               myEventsList.colorCode === "#173371"
//                 ? "#548DFF"
//                 : myEventsList.colorEvento;
//             const color = myEventsList.color ? myEventsList.color : "blue";
//             const border = "none";
//             const cursor = "pointer";
//             const display = "flex";
//             const width = "100%";
//             return {
//               style: { backgroundColor, color, border, cursor, display, width },
//             };
//           }}
//           slotPropGetter={(event) => {
//             // if (event)
//             return {
//               style: {},
//               className: "special-day",
//             };
//             // else return {};
//           }}
//           onNavigate={(date, view, action) => {
//             if (view === "day" && action === "NEXT") {
//               // dispatch(nextdate());
//             } else if (view === "day" && action === "PREV") {
//               // dispatch(prevdate());
//             } else if (view === "day" && action === "TODAY") {
//               // dispatch(resetdate());
//             }
//           }}
//           step={15}
//         showMultiDayTimes={true}
//         // date={date}
//         popup
//         selectable
//         backgroundEvents={[]}
//         // onLoading={
//         //   <div>
//         //     <CircularProgress />
//         //   </div>
//         // }
//         />
//       </div>
//     );
// }

// export default App;



//       // {
//       //   // id: 0,
//       //   "title": "Board meeting",
//       //   "start": new Date(2023, 11, 19, 1, 0, 0),
//       //   "end": new Date(2023, 11, 19, 5, 0, 0),
//       //   "resourceId": 1,
//       // },
//       // {
//       //   // id: 1,
//       //   "title": "MS training",
//       //   "start": new Date(2023, 11, 19, 6, 0, 0),
//       //   "end": new Date(2023, 11, 19, 11, 30, 0),
//       //   "resourceId": 2,
//       // },
//       // {
//       //   // id: 2,
//       //   "title": "Team lead meeting",
//       //   "start": new Date(2023, 11, 19, 13, 30, 0),
//       //   "end": new Date(2023, 11, 19, 18, 30, 0),
//       //   "resourceId": 3,
//       // },
//       // {
//       //   // id: 10,
//       //   "title": "Board meeting",
//       //   "start": new Date(2023, 11, 20, 1, 0, 0),
//       //   "end": new Date(2023, 11, 20, 6, 0, 0),
//       //   "resourceId": 1,
//       // },
//       // {
//       //   // id: 11,
//       //   "title": "Birthday Party",
//       //   "start": new Date(2023, 11, 20, 7, 0, 0),
//       //   "end": new Date(2023, 11, 20, 10, 30, 0),
//       //   "resourceId": 4,
//       // },
//       // {
//       //   // id: 12,
//       //   "title": "Board meeting",
//       //   "start": new Date(2023, 11, 20, 11, 0, 0),
//       //   "end": new Date(2023, 11, 20, 20, 0, 0),
//       //   "resourceId": 1,
//       // },
//       // {
//       //   // id: 13,
//       //   "title": "Board meeting",
//       //   "start": new Date(2023, 11, 21, 2, 45, 0),
//       //   "end": new Date(2023, 11, 21, 9, 0, 0),
//       //   "resourceId": 2,
//       // },
//       // {
//       //   // id: 14,
//       //   "title": "Board meeting",
//       //   "start": new Date(2023, 11, 21, 10, 15, 0),
//       //   "end": new Date(2023, 11, 21, 20, 0, 0),
//       //   "resourceId": 4,
//       // },

      
// // { resourceId: 10, resourceTitle: "Board room" },
//       // { resourceId: 349, resourceTitle: "Training room" },
//       // { resourceId: 500, resourceTitle: "Meeting room 1" },
//       // { resourceId: 20, resourceTitle: "Meeting room 2" },