// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listpage{
    font-family: "Roboto", sans-serif;
    margin-top: 40px;
}
.input{
    font-family: "Roboto", sans-serif;
    background-color:white;
    border: 2px;
    padding:20px;
}
.form-group .form-control{
    font-family: "Roboto", sans-serif;
	border-right:0; 
	box-shadow:0 0 0; 
	border:none;
    appearance: auto;
    margin-left: 2px;
    width: 97%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input1{
    font-size: 14px;
    font-weight: 500;
    color: #818181;
    font-family:  'Roboto', sans-serif;
    margin-left: 14px;
}
.card{
    font-family: "Roboto", sans-serif;
    max-width: 100%;
}
.form-control::placeholder{
    color:#c9c9c9;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Calendar/scheduler/components/listpage.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;AACpB;AACA;IACI,iCAAiC;IACjC,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;AACA;IACI,iCAAiC;CACpC,cAAc;CACd,gBAAgB;CAChB,WAAW;IACR,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;AACd;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kCAAkC;IAClC,iBAAiB;AACrB;AACA;IACI,iCAAiC;IACjC,eAAe;AACnB;AACA;IACI,aAAa;IACb,UAAU;AACd","sourcesContent":[".listpage{\n    font-family: \"Roboto\", sans-serif;\n    margin-top: 40px;\n}\n.input{\n    font-family: \"Roboto\", sans-serif;\n    background-color:white;\n    border: 2px;\n    padding:20px;\n}\n.form-group .form-control{\n    font-family: \"Roboto\", sans-serif;\n\tborder-right:0; \n\tbox-shadow:0 0 0; \n\tborder:none;\n    appearance: auto;\n    margin-left: 2px;\n    width: 97%;\n}\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.input1{\n    font-size: 14px;\n    font-weight: 500;\n    color: #818181;\n    font-family:  'Roboto', sans-serif;\n    margin-left: 14px;\n}\n.card{\n    font-family: \"Roboto\", sans-serif;\n    max-width: 100%;\n}\n.form-control::placeholder{\n    color:#c9c9c9;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
