import {
  GET_GENERAL_FORM_SUCCESS,
  GENERAL_FORM_SUBMITTED,
  GET_DYNAMIC_FORM_SUCCESSFULLY,
  SUBMIT_DYNAMIC_FORM_SUCCESSFULLY,
  GENERAL_FORM_UPDATED_SUCCESSFULLY,
  APPLY_DEFAULT_VALUES_SUCCESSFULLY,
  STORE_CONSENT_FORM_ID_SUCCESSFULLY,
} from "../../ActionType";

// returns action types for dispatching

// Type to get dynamic form
export const getDynamicFormSuccessfully = (res) => {
  return {
    type: GET_DYNAMIC_FORM_SUCCESSFULLY,
    payload: res,
  };
};

// Type to submit dynamic form
export const submitDynamicFormsSuccessfully = (res) => {
  return {
    type: SUBMIT_DYNAMIC_FORM_SUCCESSFULLY,
    payload: res,
  };
};

export const storeConsentFormIdSuccessfully = (res) => {
  return {
    type: STORE_CONSENT_FORM_ID_SUCCESSFULLY,
    payload: res,
  };
};
