import React from 'react';
import Calender from '../components/Calender/index';

import SideNavone from '../../../SideNavBar';

const CalenderPage = () => {
  return (
    <>
      <SideNavone>
      <Calender />
      </SideNavone>
    </>
  );
};

export default CalenderPage;