import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Autocomplete from "@mui/material/Autocomplete";
import PopupIndicator from "@mui/material/Popover";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Times from "./Times";
import dayjs from "dayjs";
import { weekOptions } from "./helper";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { error } from "../../utils";
import Form from "react-bootstrap/Form";
import "./Timeslot.scss";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

dayjs.extend(customParseFormat);

// Set Monday as the first day of the week in the locale
dayjs.locale({
  week: {
    dow: 1, // Monday
  },
});
const RepeatedDay = (props) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    activeWeekOptions,
    setActiveWeekOptions,
    setError,
    handleUpdateTimeSlots,
    editData,
    action,
    editRecord,
    handleTimeSlots,
    businessLocations,
  } = props;
  const [activeWeek, setActiveWeek] = useState(false);
  const [disable, setDisable] = useState("");
  const [pattern, setPattern] = useState("every_week");
  let startIndex = 0;

  function splitArrayIntoChunks(array, chunkSize) {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }

  const arrayOfObjects = values.scheduleItems;
  const chunkSize = 7;
  const splitArray = splitArrayIntoChunks(arrayOfObjects, chunkSize);
  // useEffect(() => {
  //   if (action === "edit") {
  //     const startDate = dayjs(values.startDate);
  //     const endDate = dayjs(values.endDate);
  //     handleTimeSlots(startDate, endDate, values, setFieldValue);
  //   }
  // }, []);
  const currentDay = dayjs().day();

  useEffect(() => {
    if (action === "edit") {
      let startDate = dayjs(values.startDate);
      startDate = startDate.startOf("week").add(1, "day");
      const endDate = startDate.add(7, "day");
      handleTimeSlots(startDate, endDate, values, setFieldValue);
    } else {
      let startDate = dayjs(values.startDate);
      startDate = startDate.startOf("week").add(1, "day");
      const endDate = startDate.add(7, "day");

      // Filter scheduleItems to disable days before the current day
      const updatedScheduleItems = values.scheduleItems.map((item) => {
        // Check if the day of the item is before the current day
        const isBeforeCurrentDay = item.day < currentDay;

        // If it's before the current day, set 'active' to false
        return {
          ...item,
          disable: isBeforeCurrentDay,
        };
      });
      setFieldValue("scheduleItems", updatedScheduleItems);
      setDisable(updatedScheduleItems);
      // Call your function to handle time slots
      handleUpdateTimeSlots(startDate, endDate, values, setFieldValue);
    }
  }, []);

  const handleChangeActiveWeek = (event, value, setFieldValue) => {
    let startDate = dayjs(values.startDate);
    startDate = startDate.startOf("week").add(1, "day");
    const endDate = startDate.add(value ? parseInt(value.days) : 1, "day");
    handleUpdateTimeSlots(startDate, endDate, values, setFieldValue, value);
    setActiveWeek((value && value.id) || null);
  };

  const handleSelectUnSelectSlot = (index, slot, setFieldValue) => {
    const selectedWeek = activeWeek || slot.week;
    const currentSlot = values.scheduleItems.find(
      (slot) => slot.week === selectedWeek || slot.active === true
    );
    setFieldValue(`scheduleItems.${index}`, {
      ...slot,
      startTime: slot.active === true ? [""] : currentSlot.startTime,
      endTime: slot.active === true ? [""] : currentSlot.endTime,
      businessLocation: slot.active === true ? [] : currentSlot.businessLocation,
      active: slot.active === true ? slot.active === false : !slot.active,
    });
  };

  // const handleOpenDayAccordion = (overallIndex, setFieldValue, slot) => {
  //   setFieldValue(`scheduleItems[${overallIndex}].expanded`, !slot.expanded)
  // }

  return (
    <div>
      <div className="row">
        {/* <div className="col-md-6"></div> */}
        <div className="col-md-12 autocomplete position-relative">
          <Typography
            variant="body2"
            className="position-absolute"
            sx={{ top: 4, fontSize: "12px", color: "#AAAAAA", pl: 1.5, zIndex: 5 }}
          >
            Repeat
          </Typography>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              sx={{
                borderRadius: "8px !important",
                padding: "20px 0 10px 3px !important",
                height: "50px !important",
                bgcolor: "#fff",
              }}
              value={values.repeatPattern && values.repeatPattern.value}
              label=""
              onChange={(event) => {
                setPattern(event.target.value)
                setFieldValue("repeatPattern", weekOptions.find((d) => d.value === event.target.value)); // Set the value in Formik
                handleChangeActiveWeek(event, weekOptions.find((d) => d.value === event.target.value), setFieldValue);
              }}
            >
              {weekOptions.map((option, index) =>
                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
              )}
            </Select>
          </FormControl>
          {/* <Autocomplete
            disablePortal
            options={weekOptions}
            getOptionLabel={(option) => option.label}
            defaultValue={weekOptions[0]}
            value={values.repeatPattern}
            components={{
              PopupIndicator: ArrowDropDownIcon,
            }}
            popupIcon={<KeyboardArrowDownIcon />}
            disableClearable
            onChange={(event, value) => {
              debugger
              setFieldValue("repeatPattern", value); // Set the value in Formik
              handleChangeActiveWeek(event, value, setFieldValue);
            }}
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: '8px',
              "& .MuiAutocomplete-input": {
                fontSize: "14px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "32px",
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                cursor: "pointer !important"
              },
              "& .MuiAutocomplete-input": {
                cursor: "pointer !important"
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    color: "#000",
                    height: "50px",
                    fontSize: "12px",
                    paddingTop: "28px !important",
                    cursor: "pointer !important"
                  },
                }}
              />
            )}
          /> */}
        </div>
      </div>

      {splitArray.map((weekDays, weekIndex) => (
        <div className="col-md-12 " key={weekIndex}>
          <div className="row ">
            <div className="col-md-6 mb-2">
              <span className="schedule_text2">Week {weekIndex + 1}</span>
            </div>
            {/* <div className="col-md-6 m-auto">
              <div className="row">
                <div className="col-md-4">
                  <div className="labe_from">From</div>
                </div>
                <div className="col-md-4">
                  <div className="labe_from">To</div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div> */}
          </div>
          {/* <div className="col-md-12">
            <hr className="mt-2 mb-0 p-0" />
          </div> */}

          {weekDays.map((slot, index) => {
            const overallIndex = startIndex++; // Calculate the overall index
            if (slot.day === 0) {
              slot.day = 7;
            }
            const isBeforeCurrentDay = slot.day < currentDay;
            return (
              <div key={overallIndex}>
                <div>
                  <div className="col-md-12">
                    <div className="col mb-2">
                      <Accordion
                        elevation={0}
                        expanded={slot.active}
                        disableGutters
                        sx={{
                          py: 0,
                          my: 0,
                          border: "1px solid #DCDCDC",
                          borderRadius: "8px !important",
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <IconButton
                            // onClick={() => { handleOpenDayAccordion(overallIndex, setFieldValue, slot) }}
                            >
                              <ExpandMoreIcon
                                sx={{ fontSize: 22, color: "#A9A9A9" }}
                              />
                            </IconButton>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{ cursor: "auto !important" }}
                        >
                          <div className="col-md-12">
                            <div className="col-md-12 px-2">
                              <label
                                className=""
                                style={{
                                  // fontSize: "12px",
                                  // fontWeight: "bold",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "130px",
                                  cursor: "pointer"
                                  // marginTop: "10px",
                                  // marginLeft: "10px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value={slot.active}
                                  checked={slot.active}
                                  // disabled={isBeforeCurrentDay&&slot.week===1}
                                  onChange={() =>
                                    handleSelectUnSelectSlot(
                                      overallIndex, // Pass the overall index
                                      slot,
                                      setFieldValue
                                    )
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    // marginBottom: "10px",
                                    marginRight: "10px",
                                    // marginTop: "3px",
                                    borderColor: "#E0E1E4",
                                    backgroundColor: "#F8F9FF",
                                  }}
                                />
                                <span
                                  style={{ color: slot.active ? "#000" : "#A9A9A9", fontWeight: 500 }}
                                >
                                  {dayjs().day(slot.day).format("dddd")}
                                </span>
                                {/* {(slot.day)} */}
                                {/* {dayjs(slot.date).format("dddd")} */}
                              </label>
                            </div>
                          </div>
                        </AccordionSummary>
                        {/* <p className="form-error">
                       {errors &&
                          errors.scheduleItems &&
                          errors.scheduleItems[overallIndex] }
                        {/* {alert(errors &&
              errors.scheduleItems 
              )} */}
                        {/* </p>  */}
                        <AccordionDetails>
                          <div className="col-md-12">
                            <Times
                              values={values}
                              slot={slot}
                              scheduleIndex={overallIndex} // Pass the overall index
                              setError={setError}
                              setFieldValue={setFieldValue}
                              locationValues={
                                values.scheduleItems[overallIndex]
                                  .businessLocation
                              }
                              locationTouched={
                                touched &&
                                touched.scheduleItems &&
                                touched.scheduleItems[overallIndex] &&
                                touched.scheduleItems[overallIndex].businessLocation
                              }
                              locationErrors={
                                errors &&
                                errors.scheduleItems &&
                                errors.scheduleItems[overallIndex] &&
                                errors.scheduleItems[overallIndex].businessLocation
                              }
                              startTimeValues={
                                values.scheduleItems[overallIndex].startTime
                              } // Use the overall index
                              startTimeTouched={
                                touched &&
                                touched.scheduleItems &&
                                touched.scheduleItems[overallIndex] &&
                                touched.scheduleItems[overallIndex].startTime
                              }
                              startTimeErrors={
                                errors &&
                                errors.scheduleItems &&
                                errors.scheduleItems[overallIndex] &&
                                errors.scheduleItems[overallIndex].startTime
                              }
                              endTimeValues={
                                values.scheduleItems[overallIndex].endTime
                              } // Use the overall index
                              endTimeTouched={
                                touched &&
                                touched.scheduleItems &&
                                touched.scheduleItems[overallIndex] &&
                                touched.scheduleItems[overallIndex].endTime
                              }
                              endTimeErrors={
                                errors &&
                                errors.scheduleItems &&
                                errors.scheduleItems[overallIndex] &&
                                errors.scheduleItems[overallIndex].endTime
                              }
                              businessLocations={businessLocations}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default RepeatedDay;
