import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React from "react";
import { useLocation } from "react-router-dom";
import SideNavone from "../../SideNavBar";
import { styled } from "@mui/material/styles";

import { useState } from "react";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  useAddNewColumnPreferenceStaffMutation,
  useGetColumnNamesByServiceNameStaffQuery,
} from "../../Feature/api/StaffApi";
import AddStaffColumn from "../../Componenets/Customer/AddCustomerColumn";
import InsertStaffColumn from "../../Componenets/Customer/InsertCustomerColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRequest, postRequest } from "../../Componenets/request";
import useCheckPermission, { customSnackBar } from "../../utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#6B6B6B",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#A4A4A4",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px" /* 200% */,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    width: "100%",
    padding: "2px 6px",
  },
}));
const EditCustomer = () => {
  const serviceName = "expert customer";
  const id = 1;
  var userId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const location = useLocation();
  const [permission, setPermissions] = useState(null);
  useCheckPermission("customer", "canUpdateCustomerPreferences").then((res) => {
    setPermissions(res);
  });  
  // const data = location.state;
  const [success, setSuccess] = useState(false);
  const [filterService, setFilterService] = useState([]);
  const [staffSortedData, setStaffSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { isLoading, isSuccess, data: GetStaff } = useGetStaffsQuery();
  const history = useHistory();
  const store = useSelector((state) => state?.staffReducer);
  const [columnNames, setColumnNames] = useState([]);
  const userid = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir?.id
  );
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const {
  //   isLoading,
  //   isSuccess,
  //   data: GetStaff,
  // } = useGetColumnNamesByServiceNameStaffQuery(userId);
  const [
    updateColumnPreferrence,
    { isLoading: isUpdateColumnPreferrenceLoading },
  ] = useAddNewColumnPreferenceStaffMutation();
  const getColumnNames = async () => {
    const res = await getRequest(
      `/customer_svc/pv/ListingPreferences/getAllListingPreferenceByUserId?id=${userid}&businessId=${selectedBusiness.id}`
    );
    setColumnNames(res?.result?.listingPreference);
  };
  useEffect(() => {
    getColumnNames();
  }, [userid]);
  useEffect(() => {
    if (columnNames) {
      const data = columnNames
        .slice()
        .sort((a, b) => a.position - b.position);
      setStaffSortedData(data);
      setLoading(false);
    }
  }, [columnNames]);

  const hanldeSaveChanges = async () => {
    if (
      permission ||
      permission === null ||
      permission === undefined
    ) {
    // const dataWithoutServiceNameAndColumnName = staffSortedData.map((item) => {
    //   const { serviceName, columnName, ...rest } = item;
    //   console.log(GetStaff?.result?.columnNames,"restrestrestrest")
    //   return rest;
    // });
    const dataWithoutServiceNameAndColumnName = staffSortedData.reduce(
      (uniqueData, item) => {
        const existingItem = uniqueData.find(
          (uniqueItem) => uniqueItem.position === item.position
        );
    
        // Rename the 'id' property to 'columnId'
        const updatedItem = { ...item, columnId: item.id };
        delete updatedItem.id;
    
        if (!existingItem) {
          uniqueData.push(updatedItem);
        }
    
        return uniqueData;
      },
      []
    );
    
    const classes = dataWithoutServiceNameAndColumnName.map((i)=>{
      if(i.isShown === true){
        return {columnId: i.columnId,isSticky: i.isSticky};
      }
    return null;
    }).filter((it)=>it!==null);
    const payload = {
      entityId: userid,
      columns: classes,
      createdBy: userid,
    };
    const result = await postRequest(
      `/customer_svc/pv/ListingPreferences/addUserListingPreference`,
      payload
    );
    if (result?.code ===0) {
      alert("Columns list updated successfully");
      history.push(`/all-customer`);
    } else {
      alert(result?.message);
    }
    // const code = result.data.code;
    // const message = result.data.message;
    // if (code === 0) {
    //   dispatch(
    //     setNotifications({
    //       type: "success",
    //       message: message,
    //       isActive: false,
    //     })
    //   );
    //   setTimeout(() => {
    //     navigate("/staff");
    //   });
    // } else {
    //   dispatch(
    //     setNotifications({
    //       type: "error",
    //       message: message,
    //       isActive: true,
    //     })
    //   );
    // }
  }else{
    customSnackBar("You haven't Permission")
  }
  };
  return (
    <>
      <SideNavone>
      <div 
          style={{ height: "100vh", overflow: "auto", paddingBottom: "220px" }}
          >
        <Grid className=""
      container rowSpacing={1.5} bgcolor="#FBFCFF">
          <Grid
            item
            xs={12}
            bgcolor="#FFF"
            border="#DCDCDC"
            px={2}
            py={1.4}
            mt={2}
          >
            <Box component="div" display="flex" justifyContent="space-between">
              <Typography>Edit Column</Typography>
              <div className="col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3">
                <button
                  // type="submit"
                  className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
                  onClick={hanldeSaveChanges}
                >
                  {/* {isUpdateColumnPreferrenceLoading ? (
                    <CircularProgress size={25} color="white" />
                  ) : ( */}
                    <>
                      <AddBoxIcon className="addbox_icon me-1" />
                      Save Changes
                    </>
                  {/* )} */}
                </button>
              </div>
              {/* <Button variant="contained"               className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
 onClick={() => hanldeSaveChanges()}>
                {isUpdateColumnPreferrenceLoading ? (
                  <CircularProgress size={25} color="white" />
                ) : (
                  "Save Changes"
                )}
              </Button> */}
            </Box>
          </Grid>
          <Grid item xs={12} mx={1.5}>
            <Box component="div">
              <Typography sx={{ color: "#000" }} mb={1.5}>
                Added Columns
              </Typography>
              <AddStaffColumn
                data={staffSortedData}
                updateData={setStaffSortedData}
              />
            </Box>
          </Grid>
          <Grid item xs={12} mx={1.5}>
            <Box component="div">
              <Typography sx={{ color: "#000" }} mb={1.5}>
                Insert Columns
              </Typography>
              <InsertStaffColumn
                data={staffSortedData}
                updateData={setStaffSortedData}
              />
            </Box>
          </Grid>
        </Grid>
        </div>
      </SideNavone>

      {/* {state?.isActive && <Toaster state={state} />} */}
    </>
  );
};

export default EditCustomer;
