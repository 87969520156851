// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_screen {
  width: 20%;
}

.padding_top {
  padding-top: 15%;
}

@media screen and (max-width: 480px) {
  .padding_top {
    padding-top: 85%;
  }
  .loading_screen {
    width: 40%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Shared/HelperMethods/Loading.scss"],"names":[],"mappings":"AAAA;EAEI,UAAA;AAAJ;;AAGA;EAEI,gBAAA;AADJ;;AAIA;EACI;IAEI,gBAAA;EAFN;EAIE;IAEA,UAAA;EAHF;AACF","sourcesContent":[".loading_screen\n{\n    width: 20%;\n}\n\n.padding_top\n{\n    padding-top: 15%;\n}\n\n@media screen and (max-width: 480px) {\n    .padding_top\n    {\n        padding-top: 85%;\n    }\n    .loading_screen\n{\n    width: 40%;\n}\n    \n \n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
