import { Col, Row, Table, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FieldArray, Formik } from "formik";

const OffCanvas = ({ name, show, handleClose, handleShow, ...props }) => {
  const rows = [
    {
      id: 1,
      description: "Full Body",
      sku: "4361652169",
      time: "60",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
    {
      id: 2,
      description: "Hands",
      sku: "4361652169",
      time: "20",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
    {
      id: 3,
      description: "Feet",
      sku: "4361652169",
      time: "20",
      interval: "mins",
      currency: "£",
      price: "67",
      priceTime: "Per Hour",
    },
  ];

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        {/* <Offcanvas.Header closeButton> */}
        <Offcanvas.Header className="mt-4">
          <Offcanvas.Title className="offcanvas-title">
            Add new Attributes
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="price-table-wrap att-table mx-0 mt-2">
            <Col className="px-0" xs={12}>
              <div className="area-wrap">
                <div className="area">Area</div>
              </div>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className="table-cell"></th>
                    <th className="px-0 description-cell">Description</th>
                    <th className="sku-cell ps-1">SKU</th>
                    <th className="duration-cell ps-1">Duration</th>
                    <th className="price-cell ps-1">Price</th>
                  </tr>
                </thead>
                <tbody className="overflow-x-hidden">
                  {rows.map((row) => (
                    <tr className={`${show && "tr"}`} key={row.id}>
                      <td className="table-cell p-0 py-3">
                        <div className="border-right">
                          <MoreVertIcon className="pe-2 cursor-pointer" />
                        </div>
                      </td>
                      <td className="text-truncate px-2 text-truncate description-cell">
                        <div className="border-right">{row.description}</div>
                      </td>
                      <td className="sku-cell px-1 text-truncate">
                        <div className="border-right">{row.sku}</div>
                      </td>
                      <td className="duration-cell px-1 text-truncate">
                        <div className="border-right">
                          {row.time}
                          <span className="text-light-grey mins">
                            {row.interval}
                          </span>
                        </div>
                      </td>
                      <td className="price-cell px-1 text-truncate">
                        <div>
                          {row.currency}
                          {row.price}
                          <span className="text-light-grey mins">
                            {row.priceTime}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Formik
                initialValues={{ attributes: [] }}
                render={({ values }) => (
                  <Form>
                    <FieldArray
                      name="attributes"
                      render={(arrayHelpers) => (
                        <div>
                          {values.attributes.map((el, index) => (
                            <Table className="mb-0">
                              <tbody className="overflow-x-hidden">
                                <tr>
                                  <td className="table-cell p-0 py-3">
                                    <div className="border-right">
                                      <MoreVertIcon className="pe-2 cursor-pointer" />
                                    </div>
                                  </td>
                                  <td className="text-truncate px-2 text-truncate description-cell">
                                    <div className="border-right">
                                      Body Area
                                    </div>
                                  </td>
                                  <td className="sku-cell px-1 text-truncate">
                                    <div className="border-right">
                                      4361652169
                                    </div>
                                  </td>
                                  <td className="duration-cell px-1 text-truncate">
                                    <div className="border-right">
                                      40
                                      <span className="text-light-grey mins">
                                        mins
                                      </span>
                                    </div>
                                  </td>
                                  <td className="price-cell px-1 text-truncate">
                                    <div>
                                      £70
                                      <span className="text-light-grey mins">
                                        Per Hour
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ))}

                          <div
                            className="table-add-new d-flex align-items-center"
                            onClick={() => arrayHelpers.push("")}
                          >
                            <AddCircleOutlineIcon className="add-circle-table" />
                            <span>Add New</span>
                          </div>
                        </div>
                      )}
                    />
                  </Form>
                )}
              />
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default OffCanvas;
