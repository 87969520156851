import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../../utils/requests";
import { DOCUMENT_TO_URL_STAGING } from "../../../../Constants/DynamicFormConstants";

const ImageUploadField = ({ handleChange, id, label, formData }) => {
  const [selectedDocument, setSelectedDocument] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const documentUploadRef = useRef(null);

  const handleDocumentChange = (e) => {
    const documentFile = e.target.files[0];
    setSelectedDocument(documentFile);
    handleUpload(documentFile);
  };

  const handleUpload = async (documentFile) => {
    if (!documentFile) {
      console.error("No document file selected.");
      return;
    }

    const formData = new FormData();
    var randomId = Math.floor(Math.random() * 1000000);

    formData.append("Id", randomId);
    formData.append("serviceName", "onBoarding");
    formData.append("source", "Plexaar");
    formData.append("Category", "main");
    formData.append("FileType", "i"); // Set the file type accordingly
    formData.append("Files", documentFile);
    formData.append("CreatedBy", randomId);

    await post(DOCUMENT_TO_URL_STAGING, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure you use multipart/form-data for form data
      },
    })
      .then((response) => {
        setDocumentUrl(response.data.paths[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleUploadClick = () => {
    documentUploadRef.current.click();
  };

  useEffect(() => {
    if (documentUrl) handleChange(id, documentUrl);
    // eslint-disable-next-line
  }, [documentUrl]);

  return (
    <>
      <div className="float-label">
        <input
          type="file"
          accept="image/jpeg"
          id={id}
          ref={documentUploadRef}
          className="document-field"
          onChange={handleDocumentChange}
        />
        <div className="document-uploader">
          {selectedDocument && (
            <p className="float-left">{selectedDocument.name}</p>
          )}
          <button type="button" onClick={() => handleUploadClick()}>
            {selectedDocument ? "Re-Upload" : "Upload"}
          </button>
        </div>
        <label htmlFor={id}>{label}</label>
      </div>
    </>
  );
};

export default ImageUploadField;
