import React, { useState, useEffect } from "react";

import Table from "./Table";
import { OnSearch, team } from "./helper";
import { teamList } from "../../../utils/constant";
import Header from "../Header";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTeamRequest, fetchTeamRequest } from "../../../Store/Team/actions";
import SideNavone from "../../../SideNavBar"
import MenuTabList from "../CustomTabs/MenuTabList";
import { fetchPermissionRequest } from "../../../Store/User/actions";

const ManagePermission = () => {
  const flag = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const departmentRecord = location && location.state;
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.User?.permissionList);
  const loading = useSelector((state) => state.User?.loading);
 
  const removeBusiness = (id) => {
    dispatch(
      deleteTeamRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };

  //Delete busienss Confirmation Dialouge Box handler
  const handleTeamDeleteConfirmation = (id) => {
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  };
  useEffect(() => {    
      dispatch(fetchPermissionRequest());
  }, []);
  useEffect(() => {
    setRowData(data || []);
  }, [data]);
  return (
    <>
      <SideNavone>
      <div>
        <Header
          data={data}
          OnSearch={OnSearch}
          rowData={rowData}
          flag={flag}
          record={departmentRecord}
          setRowData={setRowData}
          heading={"Role & Permission"}
          buttonText={"Add Permission"}
          link="/add-permission"
        />
      </div>
      <MenuTabList type="permissionList" />

      <div className="p-2 pt-5 min-h-[250px] table-container">
        {loading ? (
          <SkeletonTable />
        ) : (
          <Table
            loading={loading}
            setRecord={setRowData}
            data={rowData}
            dialog={dialog}
            deleteTeam={handleTeamDeleteConfirmation}
          />
        )}{" "}
      </div>
      </SideNavone>
    </>
  );
};
export default ManagePermission;
