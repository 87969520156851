import React, { useEffect, useState } from "react";
import { postRequest } from "../../request";
import { useParams } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BntLoader } from "../../../../Componenets/loaders";
import { disableMiniLoader, enableMiniLoader } from "../../store/ServicesSlice";
import { getBusinessStaff } from "../../store/servicesActions";

const StaffServiceForm = ({ sources, businessId }) => {
  const [staffId, setStaffId] = useState("");
  const [source, setSource] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const service_store = useSelector(
    (state) => state?.servicesReducer?.serviceToUpdate[0]
  );
  const store = useSelector((state) => state?.servicesReducer);

  const service_store_expert = useSelector(
    (state) => state?.servicesReducer?.expertServiceToUpdate[0]
  );
  const all_staff = useSelector(
    (state) => state?.servicesReducer?.businessStaff
  );
  const userData = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir.id
  );
  console.log(all_staff, "service_store_expert");
  const postData = async () => {
    dispatch(enableMiniLoader());

    const data = {
      businessId: parseInt(businessId),
      providerId: parseInt(staffId),
      createdBy: userData,
      // sourceId: parseInt(source),
      service: [
        {
          id:
            sources === 2
              ? parseInt(service_store_expert?.serviceId)
              : parseInt(service_store?.serviceId),
          name:
            sources === 2
              ? service_store_expert?.serviceName
              : service_store?.serviceName || "",
          sku:
            sources === 2
              ? service_store_expert?.serviceSKU
              : service_store?.serviceSKU || "",
          noOfSeats:
            sources === 2
              ? service_store_expert?.availableSeats ??
                service_store?.availableSeats ??
                1
              : 1,
          durations:
            sources === 2
              ? service_store_expert?.duration
              : service_store?.duration || 0,
          isExpert: parseInt(source) === 1 ? false : true,
          hasAttribute:
            sources === 2
              ? service_store_expert?.hasAttributes
              : service_store?.hasAttributes || true,
          // attriubutes: [
          //   {
          //     attributeId: 0,
          //     attributeSKU: "string",
          //     attributeName: "string",
          //     attributeType: "string",
          //     attributeValue: "string",
          //     attributePrice: 0,
          //     hasCombination: true,
          //     attributeDuration: 0
          //   }
          // ]
        },
      ],
    };
    const res = await postRequest(
      `/serviceprovider_svc/pv/servicesproviders/addNewProviderServices`,
      data
    );
    if (res?.message === "success") {
      alert("Staff assigned successfully");
      dispatch(disableMiniLoader());
      if (window.location.pathname.includes("/assign-staff-dynamic-service")) {
        history.push("/dynamic-services");
      } else {
        history.push("/all-services");
      }
    } else {
      if (res?.message === "same data already exists.") {
        dispatch(disableMiniLoader());

        alert(res?.message);
        if (
          window.location.pathname.includes("/assign-staff-dynamic-service")
        ) {
          history.push("/dynamic-services");
        } else {
          history.push("/all-services");
        }
      } else {
        dispatch(disableMiniLoader());

        alert(res?.message);
      }
    }
  };

  useEffect(() => {
    dispatch(getBusinessStaff(businessId));
  }, []);
  return (
    <div className="container">
      <h3>Assign Staff</h3>
      <div className="row mt-5">
        <select
          className="form-control"
          onChange={(e) => setStaffId(e.target.value)}
        >
          <option>Select Staff</option>
          {all_staff &&
            all_staff.map((v, i) => {
              return (
                <option value={v?.providerId}>
                  {v?.firstName} {v?.lastName}
                </option>
              );
            })}
        </select>
      </div>
      <div className="row mt-3">
        <select
          className="form-control"
          onChange={(e) => setSource(e.target.value)}
        >
          <option>Select Source</option>

          <option value={1}>Plexaar</option>
          <option value={2}>Expert</option>
        </select>
      </div>
      <div className="row">
        <button
          className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1 mt-3"
          onClick={postData}
        >
          <AddBoxIcon className="addbox_icon me-1" />
          Create New
        </button>
        {store?.miniLoading && <BntLoader />}
      </div>
    </div>
  );
};

export default StaffServiceForm;
