import React from "react";
import "./CoreControl.css";
import { lady } from "../../Assets";
import { CoreControlReason } from "../Reusable/CoreControlReason";

export const CoreControl = () => {
  return (
    <div className="coreControlContainer">
      <div className="coreControlChild1">
        <img src={lady} alt="A business lady" />
      </div>
      <div className="coreControlChild2">
        <div className="controlChild2Heading">
          <p className="coreControlChildHeading">
            <span className="coreControlBlueTxt">Automated &</span>
            <span className="coreControlgreyText">
              Centralized Business Management
            </span>
          </p>
        </div>
        <div>
          <CoreControlReason />
        </div>
      </div>
    </div>
  );
};
