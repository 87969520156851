import React from "react";
import { ReusableBtn } from "../Reusable/ReusableBtn";
import Styles from "./Banner.module.css";

export const Banner = () => {
  return (
    <>
      <div className={Styles.bannerContainer}>
        <p className={Styles.bannerText}>
          <span>Use Intelligent CRM Made for</span> Your Business
        </p>
        <p className={Styles.bannerText1}>
          See how Plexaar can uplift your business
        </p>
        <ReusableBtn color="#8BB2FF" text="GET STARTED" />
      </div>
      <div className={Styles.footerBg}>
        <div className={Styles.terms}>
          <p>Terms & Condition</p>
          <p>Privacy</p>
        </div>
        <p className={Styles.cop}>© 2021 Plexaar. All Rights Reserved.</p>
      </div>
    </>
  );
};
