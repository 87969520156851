import React, { useEffect } from "react";

const NumberField = ({ handleChange, id, label, answer, formData }) => {
  useEffect(() => {
    handleChange(id, answer);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="float-label">
        <input
          id={id}
          type="number"
          placeholder={`Enter ${label}`}
          onChange={(e) => handleChange(id, e.target.value)}
          value={formData[id]}
        />

        <label forhtml={id}>{label}</label>
      </div>
    </>
  );
};

export default NumberField;
