import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllEventsList } from '../../../../Store/MeetSpace/meetspaceActions';
import SideMenu from '../../side-menu';
import "../../../../Assets/components/meetspace/events.scss";
import Topbar from './components/Topbar';
import EventsGView from './components/EventsGView';
import EventsLView from './components/EventsLView';

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

const BookedEventDetail = () => {
  const [selectedTab, setSelectedTab] = useState('1');
  const [view, setView] = useState(1);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const store = useSelector(state => state?.meetspaceReducer);
  let tab_ids = ['1', '2', '3'];

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(getAllEventsList());
  }, []);

  useEffect(() => {
    if (store?.allEventsList) {
      setData([...store?.allEventsList]);
    }
  }, [])
  return (
    <SideMenu topbar={<Topbar />}>
      <div className='events_list'>
        <div className='container d-flex justify-content-center align-items-center event_details_container1 mt-3 p-0'>
          <div className='row detail_row1 h-100 w-100 align-items-center g-0'>
            <div className='col-lg-6 col-12 h-100 pe-lg-3 pe-0'>
              <div className='h-100 col1_contents d-flex flex-column justify-content-center p-3'>
                <div className='d-flex'>
                  <img className='rounded-circle' src='./meetspace/cl1.png' style={{ height: '70px', width: '70px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h4 className='p-0 m-0 _cont1'>Muhammad Zeeshan</h4>
                    <p className='p-0 m-0 text-secondary _cont3'>9ur484</p>
                  </span>
                </div>
                <span className='d-flex align-items-center mt-3'>
                  <VideocamOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  &nbsp;&nbsp;
                  <p className='text-secondary _cont3 p-0 m-0'>26 Events</p>
                </span>
                <span className='d-flex align-items-center mt-2'>
                  <DateRangeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  &nbsp;&nbsp;
                  <p className='text-secondary _cont3 p-0 m-0'>Today</p>
                </span>
              </div>
            </div>
            <div className='col-lg-3 col-5 mt-lg-0 mt-3 pe-3 h-100'>
              <div className='h-100 col2_contents d-flex flex-column justify-content-center p-3'>
                <Button
                  variant={`text`}
                  className='p-2'
                >
                  <VideocamOutlinedIcon sx={{ fontSize: '25px' }} />
                </Button>
                <h3 className='mt-3 p-0 _cont3'>18</h3>
                <h6 className='text-secondary p-0 m-0 _cont3'>Remaining Events</h6>
                <span className='d-flex align-items-center mt-2'>
                  <DateRangeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  &nbsp;&nbsp;
                  <p className='text-secondary p-0 m-0 _cont3'>Today</p>
                </span>
              </div>
            </div>

            <div className='col-lg-3 col-7 mt-lg-0 mt-3 h-100'>
              <div className='h-100 col3_contents d-flex flex-column justify-content-center p-3'>
                <h4 className='_cont2'>Events Overview</h4>
                <div className='overview_grid'>
                  <span className='d-flex align-items-center overview_sec1'>
                    <div className='bg-primary' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                    <span className='ms-2 _cont3'>Completed</span>
                  </span>
                  <span className='overview_sec2 _cont3'>12</span>
                  <span className='overview_sec3 _cont3'>60%</span>
                </div>

                <div className='overview_grid mt-3'>
                  <span className='d-flex align-items-center overview_sec1'>
                    <div className='bg-warning' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                    <span className='ms-2 _cont3'>Booked</span>
                  </span>
                  <span className='overview_sec2 _cont3'>04</span>
                  <span className='overview_sec3 _cont3'>30%</span>
                </div>

                <div className='overview_grid mt-3'>
                  <span className='d-flex align-items-center overview_sec1'>
                    <div className='bg-danger' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                    <span className='ms-2 _cont3'>Cancelled</span>
                  </span>
                  <span className='overview_sec2 _cont3'>02</span>
                  <span className='overview_sec3 _cont3'>10%</span>
                </div>

                <div className='d-flex mt-2'>
                  <span className='bg-primary' style={{ height: '5px', width: '60%', borderRadius: '12px' }}></span>
                  <span className='bg-warning ms-1' style={{ height: '5px', width: '30%', borderRadius: '12px' }}></span>
                  <span className='bg-danger ms-1' style={{ height: '5px', width: '10%', borderRadius: '12px' }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container d-lg-none d-flex justify-content-end mt-2'>
          <div className='row '>
            <div className='col-12'>
              <div className='d-flex'>
                <span className='d-flex align-items-center pe-2'>
                  <DateRangeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                  &nbsp;&nbsp;
                  <p className='small text-secondary _cont3 p-0 m-0'>Today</p>
                </span>
                <span
                  className='ps-2'
                  style={{ borderLeft: '1px solid lightgray' }}
                >
                  <Button
                    variant={view === 2 ? `text` : ''}
                    className='p-1'
                    onClick={() => {
                      setView(2);
                    }}
                  >
                    <WindowOutlinedIcon sx={{ fontSize: '20px' }} />
                  </Button>
                </span>
                <span>
                  <Button
                    variant={view === 1 ? `text` : ''}
                    className='ms-3 p-1 '
                    onClick={() => {
                      setView(1);
                    }}
                  >
                    <FormatListBulletedOutlinedIcon sx={{ fontSize: '20px' }} />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='container event_tab_container g-0 pt-2 mt-3'>
          <Box sx={{ width: 'auto' }} className='d-flex justify-content-between align-items-center'>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab value="1" label="Booked" />
              <Tab value="2" label="Completed" />
              <Tab value="3" label="Cancelled" />
            </Tabs>
            <div className='d-lg-flex d-none'>
              <span className='d-flex align-items-center pe-2'>
                <DateRangeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                &nbsp;&nbsp;
                <p className='small text-secondary _cont3 p-0 m-0'>Today</p>
              </span>
              <span
                className='ps-2'
                style={{ borderLeft: '1px solid lightgray' }}
              >
                <Button
                  variant={view === 2 ? `text` : ''}
                  className='p-1'
                  onClick={() => {
                    setView(2);
                  }}
                >
                  <WindowOutlinedIcon sx={{ fontSize: '20px' }} />
                </Button>
              </span>
              <span>
                <Button
                  variant={view === 1 ? `text` : ''}
                  className='ms-3 me-4 p-1 '
                  onClick={() => {
                    setView(1);
                  }}
                >
                  <FormatListBulletedOutlinedIcon sx={{ fontSize: '20px' }} />
                </Button>
              </span>
            </div>
          </Box>
        </div>
        {view === 1 && tab_ids.includes(selectedTab) && <EventsLView data={data} />}
        {view === 2 && tab_ids.includes(selectedTab) && <EventsGView data={data} />}
      </div>
    </SideMenu>
  )
}

export default BookedEventDetail;