import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { staff_base_url } from "../../../../Componenets/base_url";
import { useSelector } from "react-redux";
import { customSnackBar } from "../../../../utils";
import instance from "../../../../utils/utils";
import { getCookiesData } from "../../../../utils/helper";

// export const getAllStaff = createAsyncThunk('all-staff', async (id) => {
//     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/staff_svc/pv/staff/getStaffsByBussinessId?id=${id}&projectId=0`);
//     return response?.data?.result?.staff;
// })

export const getAllStaff = createAsyncThunk(
  "all-staff-list",
  async ({ id, userid, page, pageSize, setIsLoading }) => {
    try {
      const authitcation = getCookiesData("authtication");
      setIsLoading(true);
      // const response = await instance.get(handlePath);
      const response = await instance.get(
        `/staff_svc/pv/staff/getStaffByBusinessDynamically?userId=${userid}&businessId=${id}&pageSize=${pageSize}&pageNo=${page}`
      );
      console.log(response,"pageSizepageSizepageSize")

      const { result, totalcount } = response?.data;
      setIsLoading(false);

      // Return an object with both total count and result
      return { staff: response?.data?.result?.staff, totalcount };
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching staff data:", error);
      throw error;
    }
  }
);

export const searchStaffList = createAsyncThunk(
  "all-list-search-staff",
  async ({ id, userid, text, firstName, lastName, email, number }) => {
    try {
      const response = await instance.post(
        `/staff_svc/pv/staff/searchStaffBusinessUpdated`,
        {
          userId: userid,
          businessId: id,
          firstName: firstName ? firstName : null,
          lastName: lastName ? lastName : null,
          email: email ? email : null,
          mobile: number ? number : null,
          text: text ? text : null,
          // Add the search text to the request body
        }
      );
      if (response.data.code === 0) {
        return { staffList: response?.data?.result?.staff };
      } else {
        customSnackBar("No Staff Against it");
      }

      // Return an object with both total count and result
    } catch (error) {
      // Handle errors if necessary
      console.error("Error fetching staff data:", error);
      throw error;
    }
  }
);

export const getAllCustomer = createAsyncThunk(
  "all-customer-list",
  async ({ id, userid, page, pageSize, setIsLoading }) => {
    if (window.location.pathname === "/all-customer") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `/customer_svc/pv/Customers/getCustomerByBusinessDynamicallyNew?userId=${userid}&businessId=${id}&pageSize=${pageSize}&pageNo=${page}`
        );
      console.log(response,"pageSizepageSizepageSize")


        const { result, totalcount } = response?.data;
        setIsLoading(false);
        // Return an object with both total count and result
        return { customer: response?.data?.result?.customer, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
    if (window.location.pathname === "/dynamic-services") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `/serviceinventory_svc/pb/Service/getServicesDynamically?userId=${userid}&countryId=188&businessId=${id}&pageSize=${pageSize}&pageNo=${page}`
        );
        const { result, totalcount } = response?.data;
        setIsLoading(false);
        // Return an object with both total count and result
        return { customer: response?.data?.result?.services, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
    if (window.location.pathname === "/businesses") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `business_svc/pb/business/?businessId=${id}&userId=${userid}&page=${page}&page_size=${pageSize}&dynamicListing=true`
        );
        const totalcount = response?.data?.totalCount;
        setIsLoading(false);
        return { customer: response?.data?.result, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
    if (window.location.pathname === "/business-departments") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `business_svc/pb/department/?businessId=${id}&userId=${userid}&page=${page}&page_size=${pageSize}&dynamicListing=true`
        );
        const totalcount = response?.data?.totalCount;
        setIsLoading(false);
        return { customer: response?.data?.result, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
    if (window.location.pathname === "/business-users") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `business_svc/pb/user/?businessId=${id}&userId=${userid}&page=${page}&page_size=${pageSize}&dynamicListing=true`
        );
        const totalcount = response?.data?.totalCount;
        setIsLoading(false);
        return { customer: response?.data?.result, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
    if (window.location.pathname === "/business-teams") {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `business_svc/pb/team/?userId=${userid}&page=${page}&page_size=${pageSize}&dynamicListing=true`
        );
        const totalcount = response?.data?.totalCount;
        setIsLoading(false);
        return { customer: response?.data?.result, totalcount };
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching staff data:", error);
        throw error;
      }
    }
  }
);

export const getAllCustomerDynamicForm = createAsyncThunk(
  "customer-form",
  async ({ id, setIsLoading }) => {
    try {
      setIsLoading(true);
      const response = await instance.get(
        `/customer_svc/pv/FormsPreference/getFormByBusinessId?id=${id}`
      );

      const customerForm = JSON.parse(response?.data).result;

      setIsLoading(false);
      return { form: customerForm };
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching staff data:", error);
      throw error;
    }
  }
);
export const searchCustomerList = createAsyncThunk(
  "all-list-search-customer",
  async ({ id, userid, text, firstName, lastName, email, number }) => {
    try {
      const response = await instance.post(
        `/customer_svc/pv/customerBusiness/searchCustomerBusinessUpdated`,
        {
          userId: userid,
          businessId: id,
          firstName: firstName ? firstName : "",
          lastName: lastName ? lastName : "",
          email: email ? email : "",
          mobile: number ? number : "",
          text: text ? text : "", // Add the search text to the request body
        }
      );
      if (response.data.code === 0) {
        return { customerList: response?.data?.result?.customer };
      } else {
        customSnackBar("No Customer Against it");
      }

      // Return an object with both total count and result
    } catch (error) {
      // Handle errors if necessary
      console.error("Error fetching staff data:", error);
      throw error;
    }
  }
);
