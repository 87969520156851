import React from "react";
import InputTextField from "../Pages/BusinessProfile/TextField";
import { InputAdornment } from "@mui/material";
import { RxCrossCircled } from "react-icons/rx";
import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import HeaderButton from "./HeaderButton";

const PageHeader = ({
  isMobile,
  location,
  pageTitle,
  backtostafflising,
  history,
  isSearchVisible,
  dispatch,
  setSearchValues,
  searchValues,
  searchTextFirstName,
  searchTextLastName,
  searchTextEmail,
  searchTextnumber,
  handleSearchIconClick,
  handleDebouncedSearch,
  handleInputChanges,
  handleSearchButtonClicks,
  OnSearch,
  data,
  setIsSearchVisible,
  setRowData,
  searchText,
  searchTextCustomer,
  setSearchValue,
  searchValue,
  handleInputChange,
  handleSearchButtonClick,
  providerDatas,
  handleMoreOptionsClick,
  permission,
  onClick,
  record,
  link,
  buttonText,
  getStaffFormData,
  handleBusinesSelection,
  selectedBusines,
  handleMenuActionClick,
  openAction,
  getServiceFormData,
  anchorEl,
  handleMenuClose,
  getFormData,
  handleClick,
  typeOfBooking
}) => (
  <>
    <div className={`${!isMobile ? "" : " ps-0 pe-0"}`}>
      <div className={`${!isMobile ? "flex_in_calendex_bar" : ""}`}>
        <div>
          {location.pathname.includes("/staff-profile-view") ? (
            //  ||
            // location.pathname.includes("/add-new-business")
            <span onClick={backtostafflising} className="px-2">
              <i className="fas fa-arrow-left"></i>
            </span>
          ) : isMobile || location.pathname.includes("/calendar") ? null : (
            <img
              className="img-fluid img_size_cal"
              src="/images/cal.png"
              alt="no-data"
            />
          )}
        </div>
        <div>
          {location.pathname.includes("/customer-profile-view/") ? (
            //  ||
            // location.pathname.includes("/add-new-business")
            <span onClick={() => history.goBack()} className="px-2">
              <i className="fas fa-arrow-left"></i>
            </span>
          ) : null}
        </div>
        {!isMobile ? (
          <div className="px-2">
            {location.pathname.includes("/staff-list") ||
            location.pathname.includes("/our-faculty") ? (
              <div className="d-flex my-auto">
                <p className="my-auto mx-2 p-0">
                  <strong> Staff </strong>
                </p>
                <div className="w-100 d-flex justify-content-between align-items-center py-1">
                  <div className="ms-3 w-100">
                    {isSearchVisible && (
                      <InputTextField
                        id="input-with-icon-textfield-search"
                        placeholder="Search Here.."
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          handleDebouncedSearch(inputValue);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        sx={{
                          width: "100%",
                          maxWidth: "500px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 1,
                            backgroundColor: "#fff",
                            height: "30px",
                            border: "1px solid #E0E3E7",
                            transition:
                              "border-color, background-color, box-shadow",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          },
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <FiSearch
                              style={{
                                color: "#b2b2b2",
                                fontSize: "20px",
                              }}
                            />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <Tooltip placement="bottom" title="Filter">
                              <img
                                src="/images/Vector (2).svg"
                                alt="no-data"
                                onClick={() => {
                                  handleSearchIconClick();
                                  dispatch(searchText(""));
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </InputAdornment>
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p className="m-0 p-0">{pageTitle}</p>
            )}
          </div>
        ) : null}

        {location.pathname.includes("/all-customer") ||
        location.pathname.includes("/dynamic-services") ||
        location.pathname.includes("/businesses") ||
        location.pathname.includes("/business-departments") ||
        location.pathname.includes("/business-users") ||
        location.pathname.includes("/business-teams") ||
        location.pathname.includes("/staff-list") ||
        location.pathname.includes("/our-faculty")
          ? !isSearchVisible && (
              <>
                <div className="pt-1">
                  {" "}
                  <div className="d-flex align-items-center me-2  ms-1 mt-1 mb-2">
                    <InputTextField
                      id="input-with-icon-textfield-search"
                      placeholder="First Name.."
                      // onChange={(e) => {
                      //   const inputValue = e.target.value;
                      //   handleDebouncedFirstNameSearch(inputValue);
                      // }}
                      value={searchValues.firstName}
                      onChange={(e) => handleInputChanges("firstName", e)}
                      // ref={fieldType === "firstName" ? inputRef : null}
                      className="pe-1"
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 1,
                          backgroundColor: "#fff",
                          height: "30px",
                          border: "1px solid #E0E3E7",
                          transition:
                            "border-color, background-color, box-shadow",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="start">
                          {searchValues.firstName !== "" && (
                            <Tooltip placement="bottom" title="Clear">
                              <RxCrossCircled
                                onClick={() => {
                                  // handleSearchIconClick();
                                  dispatch(searchTextFirstName(""));
                                  setSearchValues({
                                    ...searchValues,
                                    firstName: "",
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        </InputAdornment>
                      }
                    />

                    <InputTextField
                      id="input-with-icon-textfield-search"
                      placeholder="Last Name.."
                      // onChange={(e) => {
                      //   const inputValue = e.target.value;
                      //   handleDebouncedLastNameSearch(inputValue);
                      // }}
                      value={searchValues.lastName}
                      onChange={(e) => handleInputChanges("lastName", e)}
                      // ref={fieldType === "lastName" ? inputRef : null}
                      className="pe-1"
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 1,
                          backgroundColor: "#fff",
                          height: "30px",
                          border: "1px solid #E0E3E7",
                          transition:
                            "border-color, background-color, box-shadow",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="start">
                          {searchValues.lastName !== "" && (
                            <Tooltip placement="bottom" title="Clear">
                              <RxCrossCircled
                                onClick={() => {
                                  // handleSearchIconClick();
                                  dispatch(searchTextLastName(""));
                                  setSearchValues({
                                    ...searchValues,
                                    lastName: "",
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        </InputAdornment>
                      }
                    />
                    <InputTextField
                      id="input-with-icon-textfield-search"
                      placeholder="Email .."
                      // onChange={(e) => {
                      //   const inputValue = e.target.value;
                      //   handleDebouncedEmailSearch(inputValue);
                      // }}
                      value={searchValues.email}
                      onChange={(e) => handleInputChanges("email", e)}
                      // ref={fieldType === "email" ? inputRef : null}
                      className="pe-1"
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 1,
                          backgroundColor: "#fff",
                          height: "30px",
                          border: "1px solid #E0E3E7",
                          transition:
                            "border-color, background-color, box-shadow",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="start">
                          {searchValues.email !== "" && (
                            <Tooltip placement="bottom" title="Clear">
                              <RxCrossCircled
                                onClick={() => {
                                  // handleSearchIconClick();
                                  dispatch(searchTextEmail(""));
                                  setSearchValues({
                                    ...searchValues,
                                    email: "",
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        </InputAdornment>
                      }
                    />

                    <InputTextField
                      id="input-with-icon-textfield-search"
                      placeholder="Contact .."
                      // onChange={(e) => {
                      //   const inputValue = e.target.value;
                      //   handleDebouncedNumberSearch(inputValue);
                      // }}
                      value={searchValues.number}
                      onChange={(e) => handleInputChanges("number", e)}
                      // ref={fieldType === "contact" ? inputRef : null}
                      className="pe-1"
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 1,
                          backgroundColor: "#fff",
                          height: "30px",
                          border: "1px solid #E0E3E7",
                          transition:
                            "border-color, background-color, box-shadow",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      endAdornment={
                        <InputAdornment
                          sx={{ paddingRight: "0px" }}
                          position="start"
                        >
                          {searchValues.number !== "" && (
                            <Tooltip placement="bottom" title="Clear">
                              <RxCrossCircled
                                onClick={() => {
                                  // handleSearchIconClick();
                                  dispatch(searchTextnumber(""));
                                  setSearchValues({
                                    ...searchValues,
                                    number: "",
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        </InputAdornment>
                      }
                    />
                    {/* {(searchValues.firstName.length ||
                    searchValues.number.length ||
                    searchValues.lastName.length ||
                    searchValues.email.length) > 0 && ( */}
                    <Button
                      sx={{
                        backgroundColor: "#538dff",
                        height: "26px",
                        width: "50px",
                        minWidth: "60px",
                        color: "white",
                        borderRadius: "4px",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#538dff",
                          height: "26px",
                        },
                      }}
                      onClick={handleSearchButtonClicks}
                      className="me-1"
                      id="dbox-cancel"
                    >
                      <span>Filter</span>
                    </Button>
                    {/* // )} */}
                    <Button
                      sx={{
                        backgroundColor: "#d9534f",
                        height: "26px",
                        width: "50px",
                        minWidth: "60px",
                        color: "white",
                        borderRadius: "4px",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#d9534f",
                          height: "26px",
                        },
                      }}
                      onClick={() => {
                        setIsSearchVisible(true);
                        setSearchValues({
                          firstName: "",
                          lastName: "",
                          email: "",
                          number: "",
                        });
                        dispatch(searchTextnumber(""));
                        dispatch(searchTextFirstName(""));
                        dispatch(searchTextLastName(""));
                        dispatch(searchTextEmail(""));
                      }}
                      className="me-3"
                      id="dbox-cancel"
                    >
                      <span>Reset</span>
                    </Button>
                  </div>
                </div>
              </>
            )
          : null}
        {location.pathname.includes("/business/") ||
        location.pathname.includes("/users") ||
        location.pathname.includes("/department") ||
        location.pathname.includes("/team") ? (
          <div className="pt-1">
            {" "}
            <div className="d-flex align-items-center me-2  ms-3 mt-1 mb-2">
              <InputTextField
                id="input-with-icon-textfield-search"
                placeholder="Search Here.."
                onChange={(e) => OnSearch(e.target.value, data, setRowData)}
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 1,
                    backgroundColor: "#fff",
                    height: "30px",
                    border: "1px solid #E0E3E7",
                    transition: "border-color, background-color, box-shadow",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <FiSearch style={{ color: "#b2b2b2", fontSize: "20px" }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    <img
                      src="/images/Vector (2).svg"
                      alt="no-data"
                      // onClick={handleSearchIconClick}
                    />
                  </InputAdornment>
                }
              />
            </div>
          </div>
        ) : null}

        {(location.pathname.includes("/all-customer") || location.pathname.includes('/dynamic-services') ||
        location.pathname.includes('/businesses') || location.pathname.includes('/business-departments') || location.pathname.includes('/business-users') || location.pathname.includes('/business-teams')) ? (
      
          // ||
          // location.pathname.includes("/dynamic-services") ||
          // location.pathname.includes("/businesses") ||
          // location.pathname.includes("/business-departments") ||
          // location.pathname.includes("/business-users") ||
          // location.pathname.includes("/business-teams")
          <div className="pt-1">
            <div className="d-flex align-items-center me-2  ms-3 mt-1 mb-2">
              {isSearchVisible && (
                <InputTextField
                  id="input-with-icon-textfield-search"
                  placeholder="Search Here.."
                  value={searchValue}
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    maxWidth: "700px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 1,
                      width: "100%",
                      minWidth: "487px",
                      backgroundColor: "#fff",
                      height: "30px",
                      border: "1px solid #E0E3E7",
                      transition: "border-color, background-color, box-shadow",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FiSearch
                        style={{ color: "#b2b2b2", fontSize: "20px" }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {searchValue.length > 0 ? (
                        <Tooltip placement="bottom" title="Clear">
                          <RxCrossCircled
                            onClick={() => {
                              // handleSearchIconClick();
                              dispatch(searchTextCustomer(""));
                              setSearchValue("");
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="bottom" title="Filter">
                          <img
                            src="/images/Vector (2).svg"
                            alt="no-data"
                            onClick={() => {
                              handleSearchIconClick();
                              dispatch(searchTextCustomer(""));
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  }
                />
              )}
              &nbsp;
              {searchValue.length > 0 && (
                <Button
                  sx={{
                    backgroundColor: "#538dff",
                    height: "26px",
                    width: "50px",
                    minWidth: "60px",
                    color: "white",
                    borderRadius: "4px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#538dff",
                      height: "26px",
                    },
                  }}
                  onClick={handleSearchButtonClick}
                  className="me-3"
                  id="dbox-cancel"
                >
                  <span>Search</span>
                </Button>
              )}
            </div>
            {/* <div className="pe-1">
            <FilterButtonInTextField />
          </div> */}
          </div>
        ) : null}
      </div>
    </div>
    <HeaderButton
    typeOfBooking={typeOfBooking}
    location={location}
    isMobile={isMobile}
    history={history}
    dispatch={dispatch}
      providerDatas={providerDatas}
      handleMoreOptionsClick={handleMoreOptionsClick}
      permission={permission}
      onClick={onClick}
      record={record}
      link={link}
      buttonText={buttonText}
      getStaffFormData={getStaffFormData}
      handleBusinesSelection={handleBusinesSelection}
      selectedBusines={selectedBusines}
      handleMenuActionClick={handleMenuActionClick}
      openAction={openAction}
      getServiceFormData={getServiceFormData}
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      getFormData={getFormData}
      handleClick={handleClick}
    />
  </>
);

export default PageHeader;