import React, { useEffect, useState } from "react";
import SideNavone from "../../SideNavBar";
import { Avatar, Button, Divider } from "@mui/material";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { data } from "./helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";

const Dashboard = () => {
  const history = useHistory();
  const users = useSelector((state) => state.Auth?.userloginsuccessyasir);

  function encryptURL(currentURL) {
    return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP7Y8MP").toString();
  }

  const handleNavLinkClick = () => {
    const currentURL = window.location.href;
    const encryptedURL = encryptURL(currentURL);
    const encryptedUser = encryptURL(JSON.stringify(users));

    const redirectObject = {
      encryptedURL: encryptedURL,
      user: encryptedUser,
    };

    const redirectURL = `https://accounts.plexaar.com?redirectInfo=${encodeURIComponent(
      JSON.stringify(redirectObject)
    )}`;
    // Perform redirection
    window.location.href = redirectURL;
    // If you want to use history.push() for redirection uncomment the following line
    // history.push(redirectURL);
  };

  return (
    <SideNavone>
      <div
        className="hide_scrollbar"
        style={{ height: "100vh", overflow: "auto", paddingBottom: "100px" }}
      >
        <div className="ps-5 pe-5 pt-3 ">
          <div className="card-black p-5">
            <div className="d-flex flex-column p-3">
              <h6>
                <b>Create Business</b>
              </h6>
              <p
                style={{
                  color: "#74767E",
                  fontSize: "14px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Lorem Ipsum text is calculated based, <br />
                on your activity{" "}
              </p>

              <button
                type="button"
                className="btn btn-primary"
                style={{
                  width: "90px",
                  borderRadius: "50px",
                  backgroundColor: "#5599FF",
                }}
                onClick={() => {
                  handleNavLinkClick()
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>

        <div className="pt-3 ps-5 pe-5">
          <div className="d-flex justify-content-between">
            <span
              className="span ps-1"
              style={{
                color: "#74767E",
                fontSize: "14px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Apps
            </span>
            <span
              className="span ps-1"
              style={{
                color: "#74767E",
                fontSize: "14px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              {"See More"}
            </span>
          </div>
          <div className="d-flex mb-3 flex-wrap">
            <div className="row">
              {data.map((row, index) => (
                <div className="col-md-4" key={index}>
                  <div className="mt-3 mb-1 pt-0">
                    <div className="card">
                      <div className="d-flex mt-2">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="me-1 ms-2">
                            <img
                              style={{ width: "41px", height: "41px" }}
                              src="../images/Custom Size29.jpg"
                              alt="no-data"
                            />
                          </div>
                          <div>
                            <div className="ms-2 mb-2">
                              <p className="mt-1 mb-1 me-5 product-name">
                                {row.name.charAt(0).toUpperCase() +
                                  row.name.slice(1)}
                              </p>
                              <div className="">
                                <img
                                  className="vector"
                                  src="../images/mobile.svg"
                                  alt="no-data"
                                />
                                &nbsp;
                                <img
                                  className="vector1"
                                  src="../images/desktop (2).svg"
                                  alt="no data"
                                />{" "}
                                {/* <img
                                  className="vector1"
                                  src="../images/Vector (2).jpg"
                                  alt="no data"
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="position ps-2 text-end">
                            <div
                              className="ms-md-2 "
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "20px",
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#538dff",
                                  height: "30px",
                                  borderRadius: "6px",
                                  textTransform: "capitalize",
                                }}
                                variant="contained"
                              >
                                {"Open"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mt-2">
                        <div
                          className="subtype2 me-2 ms-2"
                          style={{ wordWrap: "break-word", maxWidth: "37ch" }}
                        >
                          {row.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="d-flex pt-2">
              <span
                className="span ps-1 pb-3"
                style={{
                  color: "#74767E",
                  fontSize: "14px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Available in your Plans
              </span>
            </div>
            <div>
              <div className="card min-h-[250px] card-cont">
                {data.map((row, index) => (
                  <div key={index} className="">
                    <div className="d-flex justify-content-between flex-wrap ps-md-2 pe-md-2">
                      <div className="">
                        <div className="d-flex pt-3 align-items-center justify-content-between">
                          <div className="d-flex">
                            <div>
                              <Avatar
                                src={row?.image}
                                alt="not-found"
                                variant="rounded"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: 10,
                                }}
                              />
                            </div>
                            <div className="font-size-sm pt-2 d-flex flex-column">
                              <span className="bolds text-capitalize text-black small">
                                {row.name}
                              </span>
                              <span className="version small">
                                version: {row.version}
                              </span>
                            </div>
                          </div>
                          <div className=""></div>
                        </div>{" "}
                      </div>
                      <div className="">
                        <div className="font-size-sm pt-2 d-flex flex-column">
                          <span className="bolds text-capitalize text-black small">
                            {row.price}
                          </span>
                          <span className="version small">{row.duration}</span>
                        </div>
                      </div>
                      <div className="">
                        <div className=" ps-2 text-end">
                          <div className="ms-md-2 pt-md-3 d-flex align-items-center">
                            <Button
                              className="pe-md-3"
                              sx={{
                                backgroundColor: "#538dff",
                                height: "30px",
                                borderRadius: "6px",
                                textTransform: "capitalize",
                                marginRight: "10px", // Add margin to the right
                              }}
                              variant="contained"
                            >
                              Buy Subscription
                            </Button>
                            <BsThreeDots />
                          </div>
                        </div>
                      </div>
                      <Divider
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideNavone>
  );
};

export default Dashboard;
