import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SideNavone from "../../../SideNavBar";
import { ClipLoader } from "react-spinners";
import { Avatar } from "@mui/material";
import "../../../Pages/Dashboard/MainComponent/new-customer-list/components/Dtable.scss";
import { useDispatch, useSelector } from "react-redux";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import dayjs from "dayjs";
import {
  singleBookingAppoitmentDetails,
  singleBookingDetails,
} from "../../../Calendar/scheduler/store/bookingSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { customSnackBar } from "../../../utils";
import "./bookNextVisit.css";

const BookNextVisit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const id=1
  const [data, setData] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(true);
  const strAscending = [...data].sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );
  const selectedBusiness = useSelector(
    (state) => state.Business.selectedBusiness
  );
  const appointments = [
    {
      plexaarAppointmentId: 1675,
      expertAppointmentId: null,
      expertSalesOrderId: null,
      purhcaseorderid: null,
      expertBookingId: 0,
      plexaarSalesOrderId: 1270,
      plexaarBookingId: 1660,
      isCheckedIn: false,
      isCompleted: true,
      is_job_started: false,
      colorCode: "#548DFF",
      bookingDate: "2024-03-07",
      timeFrom: "14:00:00",
      timeTo: "14:15:00",
      createdBy: 0,
      updatedBy: 0,
      created_at: "2024-03-07T10:03:19.227013Z",
      updated_at: "2024-03-07T10:03:19.562515Z",
      booking: 1660,
    },
    {
      plexaarAppointmentId: 1676,
      expertAppointmentId: null,
      expertSalesOrderId: null,
      purhcaseorderid: null,
      expertBookingId: 0,
      plexaarSalesOrderId: 1270,
      plexaarBookingId: 1660,
      isCheckedIn: false,
      isCompleted: false,
      is_job_started: false,
      colorCode: "#548DFF",
      bookingDate: "2024-03-07",
      timeFrom: null,
      timeTo: null,
      createdBy: 0,
      updatedBy: 0,
      created_at: "2024-03-07T10:03:19.786842Z",
      updated_at: "2024-03-07T10:03:20.123521Z",
      booking: 1660,
    },
    {
      plexaarAppointmentId: 1677,
      expertAppointmentId: null,
      expertSalesOrderId: null,
      purhcaseorderid: null,
      expertBookingId: 0,
      plexaarSalesOrderId: 1270,
      plexaarBookingId: 1660,
      isCheckedIn: false,
      isCompleted: false,
      is_job_started: false,
      colorCode: "#548DFF",
      bookingDate: "2024-03-07",
      timeFrom: null,
      timeTo: null,
      createdBy: 0,
      updatedBy: 0,
      created_at: "2024-03-07T10:03:20.351535Z",
      updated_at: "2024-03-07T10:03:20.687049Z",
      booking: 1660,
    },
    {
      plexaarAppointmentId: 1678,
      expertAppointmentId: null,
      expertSalesOrderId: null,
      purhcaseorderid: null,
      expertBookingId: 0,
      plexaarSalesOrderId: 1270,
      plexaarBookingId: 1660,
      isCheckedIn: false,
      isCompleted: false,
      is_job_started: false,
      colorCode: "#548DFF",
      bookingDate: null,
      timeFrom: null,
      timeTo: null,
      createdBy: 0,
      updatedBy: 0,
      created_at: "2024-03-07T10:03:20.911624Z",
      updated_at: "2024-03-07T10:03:21.247555Z",
      booking: 1660,
    },
  ];
  let bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const updateStatusBasedOnIsComplete = (stepsArray) => {
    return stepsArray.map((step) => {
      if (step.is_complete) {
        return { ...step, status: "Complete" };
      }
      return step;
    });
  };
  const updatedProcess = updateStatusBasedOnIsComplete(data);

  const isStepComplete = (stepId) => {
    const matchedStep = updatedProcess.find(
      (step) => step.current_step === stepId
    );
    if (matchedStep?.is_complete) {
      return matchedStep?.status === "Complete";
    }
  };

  const toggleCard = (data) => {
    if (data.appointments[0].bookingDate !== null) {
      dispatch(singleBookingDetails(data?.plexaarBookingId));
      dispatch(
        singleBookingAppoitmentDetails(
          data?.appointments[0]?.plexaarAppointmentId
        )
      );

      const toggle = {
        flag: true,
      };
      history.push(`/scheduler/booking_details/${selectedBusiness.id}`, toggle);
    } else {
      customSnackBar("Booking is Cancelled");
    }
  };
  return (
    <SideNavone>
      <div
        className="hide_scrollbar padding_left_right pt-1 d-flex"
        style={{
          height: "100vh",
          overflow: "auto",
          paddingBottom: "140px",
          position: "relative",
        }}
      >
        <div
          className="me-2 pt-3 col-md-1"
          onClick={() => history.goBack()}
          role="button"
        >
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>
        <div className="d-flex col-md-11 flex-column">
          <div key={bookingData.plexaarBookingId}>
            <div className="pt-1">
              <div
                className="background_color_booking_list col-md-12 col-12"
                // onClick={() => toggleCard(bookingData)}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    {bookingData?.serviceImage ? (
                      <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                        <Avatar
                          alt={bookingData?.serviceName}
                          src={bookingData?.serviceImage}
                          variant="rounded"
                          style={{
                            borderRadius: 50,
                            width: "60px",
                            height: "60px",
                          }}
                        />
                      </div>
                    ) : (
                      <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                        <Avatar
                          sx={{
                            width: "60px",
                            height: "60px",
                            borderRadius: 50,
                          }}
                        >
                          S
                        </Avatar>
                      </div>
                    )}
                    <div className="d-flex mt-2 justify-content-between ">
                      <div
                        style={{
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                        className=" justify-content-start"
                      >
                        <div className={`d-flex`}>
                          <div className="d-flex flex-column">
                            <div>
                              <span className="m-0 mx-2 next-visit-selected_servicename">
                                {bookingData?.serviceName}
                              </span>{" "}
                              <span className="businessId next-visit_booking-duration">
                                ({bookingData?.bookingDuration} mins)
                              </span>
                            </div>
                            <span className="m-0 mx-2 next-visit_sessions-remaining">
                              3 Session remaining
                            </span>
                            <span className="m-0 mx-2 next-visit_order">
                              Order: {bookingData.plexaarBookingId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr
                    className="mt-3 ms-0 mb-1"
                    style={{ color: "#c4c4c4", margin: "15.6px 0 21.4px" }}
                  />
                  <div className="d-flex justify-content-end pt-2">
                    <span className="ms-1 next-visit_total-price">
                      {bookingData?.currency === null
                        ? "£"
                        : bookingData.currencySymbol}
                      {bookingData?.totalPrice}{" "}
                    </span>
                    <MoreVertOutlinedIcon sx={{ paddingBottom: "5px" }} />
                  </div>
                </div>
                <hr
                  className="mt-3 ms-0 mb-1"
                  style={{ color: "#c4c4c4", margin: "15.6px 0 21.4px" }}
                />
                <div className="d-flex gap-2">
                  {" "}
                  {appointments.map((appointment) =>
                    appointment.isCompleted ? (
                      <div className="pt-2 appointment-completed">
                        <>
                          <span className="completed-date">
                            {dayjs(
                              bookingData.appointments[0].bookingDate,
                              "YYYY-MM-DD"
                            ).format("D MMM")}
                          </span>
                          <span
                            style={{ fontSize: "8.5px" }}
                            className="m-0 mx-2 selected_servicename d-flex justify-content-center completed-status"
                          >
                            Completed
                          </span>
                        </>
                      </div>
                    ) : appointment.isCompleted === false &&
                      appointment.bookingDate ? (
                      <div className="pt-2 appointment-upcoming">
                        <span className="upcoming-date">
                          {dayjs(
                            bookingData.appointments[0].bookingDate,
                            "YYYY-MM-DD"
                          ).format("D MMM")}
                        </span>
                        <span
                          style={{}}
                          className="m-0 mx-2 selected_servicename d-flex justify-content-center upcoming-text"
                        >
                          Upcoming
                        </span>
                      </div>
                    ) : appointment.isCompleted === false &&
                      appointment.bookingDate === null ? (
                      <>
                        <div
                          className="pt-2 book-new-session"
                          onClick={() => history.push(`/session-booking`)}
                        >
                          <span className="upcoming-date">Book</span>
                          <span
                            style={{ fontSize: "8.5px" }}
                            className="m-0 mx-2 selected_servicename d-flex justify-content-center"
                          >
                            Next Session
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideNavone>
  );
};

export default BookNextVisit;
