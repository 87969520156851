import React, { useState, useEffect } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import InputTextField from "../TextField";

import Button from "@mui/material/Button";
import Header from "../Header";
import { userList } from "../../../utils/constant";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoleRequest,
  fetchPermissionRequest,
} from "../../../Store/User/actions";
import { useHistory } from "react-router-dom";
import SideNavone from "../../../SideNavBar";
import { Switch } from "@mui/material";
import { permissionData, permissions } from "./helper";
// import "../NewBusiness/AddBusiness/AddBusiness.scss";

const BusinessRole = ({
  onClose,
  selectedOptions,
  service,
  action,
  businessData,
  onRoleCreated,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const permission = useSelector((state) => state.User?.permissionList);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!role) {
      setError("Role field is required");
      return;
    }

    setLoading(true);
    const permissionObjectsByModule = {};
    selectedPermissions.forEach((permission) => {
      const { permissionKey, module_id } = permission;
      if (!permissionObjectsByModule[module_id]) {
        permissionObjectsByModule[module_id] = {};
      }
      permissionObjectsByModule[module_id][permissionKey] = true;
    });

    // Convert the object into an array
    const selectedPermissionObjects = Object.keys(
      permissionObjectsByModule
    ).map((module_id) => ({
      module: parseInt(module_id),
      permissions: permissionObjectsByModule[module_id],
    }));

    const data = {
      name: role,
      roleType: "Backend",
      service: selectedOptions ? selectedOptions.id : null,
      business: action === "edit" ? businessData.business : businessData,
      permission: selectedPermissionObjects,
    };

    dispatch(
      addRoleRequest({
        data,
        setLoading,
        onClose,
        onRoleCreated,
      })
    ); // Dispatch the userRequest action
  };
  useEffect(() => {
    // setLoading(true)
    dispatch(fetchPermissionRequest(businessData));
  }, [businessData.id]);
  const handlePermissionChange = (permissionKey, checked, module_id, index) => {
    if (checked) {
      setSelectedPermissions((prevSelectedPermissions) => [
        ...prevSelectedPermissions,
        { permissionKey, module_id },
      ]);
    } else {
      setSelectedPermissions((prevSelectedPermissions) =>
        prevSelectedPermissions.filter(
          (permission) =>
            permission.permissionKey !== permissionKey ||
            permission.module_id !== module_id
        )
      );
    }
  };
  return (
    <>
      {/* Header and other components */}
      <SideNavone>
        <Header flag={true} heading={userList.ADD_ROLE} />
        <div
          className="padding_left_right pt-4 hide_scrollbar "
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "180px",
          }}
        >
          {" "}
          <div className="w-100">
            <form onSubmit={handleSubmit}>
            
              {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 ">
          <label className="label_text_business">Role Name</label>
          <input
             id="role"
             label="New Role"
             name="role"
             placeholder="Enter New Role"
             //size={20}
             variant="filled"
             value={role}
             onChange={(e) => setRole(e.target.value)}
             autoFocus
             sx={{
               width: "100%",
               backgroundColor: "#fff",
             }}
          />
        </div> */}

              <div className="w-100 p-2 pt-3">
                <InputTextField
                  id="role"
                  label="New Role"
                  name="role"
                  placeholder="Enter New Role"
                  //size={20}
                  variant="filled"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  autoFocus
                  sx={{
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                />
              </div>

              <div className="d-flex p-2 pt-1">
                <AiOutlineQuestionCircle
                  style={{ color: "#b2b2b2", fontSize: 18 }}
                />
                <span
                  style={{ fontWeight: 400, fontSize: "14px" }}
                  className="span ps-1 pb-1"
                >
                  Permissions
                </span>
              </div>

              {permission&&permission.map((data, index) => (
                <div
                  className="card px-3 py-2 ms-2 me-2 min-h-[250px] card-cont"
                  style={{
                    marginBottom:
                      index !== permission.length - 1 ? "20px" : "0",
                  }}
                  key={index}
                >
                  <>
                    <div className="text2 text-capitalize" key={data.id}>
                      <span className="span fw-normal text-d-flex">
                        {data.name}
                      </span>
                    </div>
                    <div>
                      <div className="d-flex flex-wrap px-2 align-items-center">
                        <div className="d-flex flex-wrap">
                          {permissionData.map((row, index) => (
                            <div key={index}>
                              <label
                                className=" pe-5"
                                style={{
                                  fontSize: "13px",
                                  display: "flex",
                                  fontWeight: 500,
                                  flexDirection: "row",
                                  marginTop: "10px",
                                  marginRight: "50px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedPermissions.some(
                                    (permission) =>
                                      permission.permissionKey === row.key &&
                                      permission.module_id === data.id
                                  )}
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      row.key,
                                      e.target.checked,
                                      data.id,
                                      index
                                    )
                                  }
                                  className="lager pe-5"
                                  style={{
                                    marginBottom: "10px",
                                    marginRight: "10px",
                                    marginTop: "3px",
                                  }}
                                />
                                {row.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              ))}

              <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                <div className="p-2">
                  <Button
                    sx={{
                      width: "150px",
                      backgroundColor: "#edf0f5",
                      height: "40px",
                      color: "#b6bec8",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#c0c0c0",
                        color: "#ffffff",
                      },
                    }}
                    onClick={onClose}
                    variant="contained"
                  >
                    Close
                  </Button>
                </div>

                <div className="p-2">
                  <Button
                    type="submit"
                    sx={{
                      width: "150px",
                      backgroundColor: "#538dff",
                      height: "40px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? "Creating..." : "Create Role"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SideNavone>
    </>
  );
};

export default BusinessRole;
