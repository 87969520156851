import React, { useEffect, useState } from 'react'
import EventsLView from './components/EventsLView';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEventsList } from '../../../../Store/MeetSpace/meetspaceActions';
import SideMenu from '../../side-menu';
import "../../../../Assets/components/meetspace/events.scss";
import EventsGView from './components/EventsGView';
import Topbar from './components/Topbar';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import EventsLViewMobile from './components/EventsLViewMobile';

const AllEvents = () => {
    const [selectedTab, setSelectedTab] = useState('1');
    const [view, setView] = useState(1);
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const store = useSelector(state => state?.meetspaceReducer);
    let tab_ids = ['1', '2', '3'];

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        dispatch(getAllEventsList());
    }, []);

    useEffect(() => {
        if (store?.allEventsList) {
            setData([...store?.allEventsList]);
        }
    }, [])

    return (
        <SideMenu topbar={<Topbar />}>
            <div className='events_list'>
                <div className='container d-flex justify-content-center align-items-center event_info_container mt-3'>
                    <div className='row h-100 w-100 p-3 align-items-center'>
                        <div className='col-lg-3 col-4 apply_border'>
                            <Button
                                variant={`text`}
                                className='p-2'
                            >
                                <TuneOutlinedIcon sx={{ fontSize: '25px' }} />
                            </Button>
                            <h6 className='text-muted p-0 m-0 mt-3 _cont3'>Total Number Of Events</h6>
                            <h3 className='mt-1'>18</h3>
                        </div>
                        <div className='col-lg-3 col-4 apply_border'>
                            <Button
                                variant={`text`}
                                className='p-2'
                            >
                                <TuneOutlinedIcon sx={{ fontSize: '25px' }} />
                            </Button>
                            <h6 className='text-muted _cont3 p-0 m-0 mt-3'>Group Meetings</h6>
                            <h3 className='mt-1'>08</h3>
                        </div>
                        <div className='col-lg-6 col-4 d-flex justify-content-between'>
                            <span className=''>
                                <Button
                                    variant={`text`}
                                    className='p-2'
                                >
                                    <TuneOutlinedIcon sx={{ fontSize: '25px' }} />
                                </Button>
                                <h6 className='text-muted p-0 m-0 mt-3 _cont3'>One On One Meetings</h6>
                                <h3 className='mt-1'>10</h3>
                            </span>
                            <span className='d-lg-block d-none'>
                                <input type='date' className='form-control' />
                            </span>
                        </div>
                    </div>
                </div>

                <div className='container d-lg-none d-flex justify-content-end mt-3'>
                    <div className='row '>
                        <div className='col-12'>
                            <div className='d-flex'>
                                <span className='d-flex align-items-center pe-2'>
                                    <DateRangeOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                    &nbsp;&nbsp;
                                    <p className='small text-secondary _cont3 p-0 m-0'>Today</p>
                                </span>
                                <span
                                    className='ps-2'
                                    style={{ borderLeft: '1px solid lightgray' }}
                                >
                                    <Button
                                        variant={view === 2 ? `text` : ''}
                                        className='p-1'
                                        onClick={() => {
                                            setView(2);
                                        }}
                                    >
                                        <WindowOutlinedIcon sx={{ fontSize: '20px' }} />
                                    </Button>
                                </span>
                                <span>
                                    <Button
                                        variant={view === 1 ? `text` : ''}
                                        className='ms-3 p-1 '
                                        onClick={() => {
                                            setView(1);
                                        }}
                                    >
                                        <FormatListBulletedOutlinedIcon sx={{ fontSize: '20px' }} />
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container event_tab_container g-0 pt-2 mt-3'>
                    <Box sx={{ width: 'auto', background: '' }} className='d-flex justify-content-between align-items-center'>
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="1" label="All" />
                            <Tab value="2" label="Group" />
                            <Tab value="3" label="One on One" />
                        </Tabs>
                        <div className='d-lg-flex d-none'>
                            <Button
                                variant={view === 2 ? `text` : ''}
                                className='p-1'
                                onClick={() => {
                                    setView(2);
                                }}
                            >
                                <WindowOutlinedIcon sx={{ fontSize: '20px' }} />
                            </Button>
                            <Button
                                variant={view === 1 ? `text` : ''}
                                className='ms-3 me-4 p-1 '
                                onClick={() => {
                                    setView(1);
                                }}
                            >
                                <FormatListBulletedOutlinedIcon sx={{ fontSize: '20px' }} />
                            </Button>
                        </div>
                    </Box>
                </div>
                {view === 1 && tab_ids.includes(selectedTab) && <><EventsLView data={data} /><EventsLViewMobile data={data} /></>}
                {view === 2 && tab_ids.includes(selectedTab) && <EventsGView data={data} />}
            </div>
        </SideMenu>
    )
}

export default AllEvents;