import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useParams } from 'react-router-dom';
import { deleteRequest } from '../../../request';
import { base_url } from '../../../base_url';
import { getServiceAttributes } from '../../../store/servicesActions';
import { useDispatch, useSelector } from 'react-redux';

const AttribCombinationMobile = () => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Combination', width: '300px' },
        { id: 2, name: 'SKU', width: '' },
    ]);

    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);

    const { id } = useParams();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };


    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };


    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);

        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });

        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteSelectedRows = async (id) => {
        const res = await deleteRequest(`${base_url}/ServiceAttribute/RemoveServiceAttribute?ServiceId=${id}`, { id });
        if (res?.message === "succeeded") {
            dispatch(getServiceAttributes(id))
            alert('Combination attribute deleted successfully');
        }
        else {
            alert(res?.message)
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    useEffect(() => {
        if (store?.serviceAttributes) {
            setRows(() => store?.serviceAttributes.map((v, i) => {
                return (
                    {
                        id: v?.id, serviceId: v?.serviceId,
                        data: [{ id: 1, values: [...v?.values] }, { id: 2, sku: v?.sku }]
                    })
            }))
        }
    }, [store?.serviceAttributes]);

    return (
        <>
            <div className="container p-0 hide_scrollbar position-relative mobile_display mt-3" style={{ maxHeight: '100vh', overflow: 'scroll' }}>
                <table className="table attribute_form_container_mobile w-100"
                // style={{ overflowX: 'clip' }}
                >
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {columns.map((column, index) => {
                                if (index < 1) {
                                    return (
                                        <th
                                            id={`sticky_col${column?.id}`}
                                            key={column.id}
                                        >{column.name}</th>
                                    );
                                }

                                return (
                                    <th
                                        // key={column.id}
                                        draggable
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}

                                        onMouseOver={() => setColumnId(column.id)}
                                        onMouseOut={() => setColumnId('')}
                                        className={isDraggingRow ? 'dragging-row' : ''}

                                        style={{
                                            width:
                                                // column?.width
                                                column?.id == 1 && "250px" ||
                                                column?.id == 2 && "300px"
                                        }}
                                    >
                                         <div className='d-flex justify-content-center'>
                                                {column.name}
                                            </div>
                                            <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                                <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                                </button>
                                                <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                                </div>
                                            </div>
                                    </th>
                                );
                            })}

                            <th className='addcol_btn_th'
                                style={{ width: '73px' }}
                            >
                                <div className="dropdown">
                                    <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                    </button>
                                    <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                        {dropdown_columns && dropdown_columns.map((val, ind) => {
                                            return (
                                                <div key={val?.id}>
                                                    <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {rows.length && rows.map((row, rowIndex) => (
                            <tr
                                key={row.id}
                                draggable
                                onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                onDragOver={handleRowDragOver}
                                onDrop={(e) => handleRowDrop(e, rowIndex)}
                                handleDragEnd={handleDragEnd}
                                className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                            >
                                <td>
                                    <div className="form-check ps-4 text-center">
                                        <input type="checkbox" value="" id="flexCheckDefault"
                                            className="form-check-input checkbox_btn"
                                            checked={selectedRows.includes(row.id)}
                                            onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                        />
                                    </div>
                                </td>

                                {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                    return (
                                        <>
                                            {cellValue.id === 1 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    {cellValue?.values?.length > 0 && cellValue?.values.map((v, i) => {
                                                        return <span className='px-3 py-1 me-1' style={{ borderRadius: '15px', fontSize: '9px', color: 'gray', backgroundColor: '#e8eaed' }}>{v?.value}</span>
                                                    })}
                                                </td>
                                            }

                                            {cellValue.id === 2 &&
                                                <td key={columnIndex}
                                                    style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                >

                                                    {cellValue?.sku}
                                                </td>
                                            }


                                            {cellValue.id === 7 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                >
                                                    <input type='text' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 8 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                >
                                                    <select
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="Option 1">option A</option>
                                                        <option value="Option 2">option B</option>
                                                        <option value="Option 2">option C</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 9 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    <input type='number' placeholder='' value={''} />
                                                </td>
                                            }

                                            {cellValue.id === 10 &&
                                                <td key={columnIndex}
                                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                >
                                                    <input type='file' placeholder='' value={''} />
                                                </td>
                                            }

                                        </>
                                    )
                                })}

                                <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                >
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AttribCombinationMobile;
