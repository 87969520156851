import Cookies from "js-cookie";
import { searchTextEmail, searchTextFirstName, searchTextLastName, searchTextnumber } from "../Pages/Dashboard/MainComponent/store/StaffSlice";

var CryptoJS = require("crypto-js");

export const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email); 
  };

  export const setStorageData = (key, value) => {
    return localStorage.setItem(key,JSON.stringify(value));
  }

  export const pathArray=[ "/all-listing",
  "/staff-list",
  "/all-services",
  "/dynamic-services",
  "/service-detail",
  "/attributes",
  "/pricing",
  "/cost",
  "/service-group",
  "/service-settings",
  "/businesses",
  "/business-departments",
  "/business-users",
  "/business-teams",
  "/subscribe",
  "/scheduler/create_booking",
  "/scheduler/create_event",
  "/scheduler/create_break",
  "/all-customer",
  "/customer-form",
  "/Profile",
  "/our-faculty",
  "/edit-map",
  "/add-new-business",
  "/create-new-service",
  `/business-profile/:id`,
  "/edit-business-name",
  "/add-owner-details",
  "/select-address",
  "/add-business-schedular/:id",
  "/note",
  "/team",
  "/business/",
  "/edit-owner-detail",
  "/department",
  "/customer-profile-view/",
  "/users",
  "/customer-bookings",
  `providerScheduler/:id`,
  "/edit-business-address",
  "/change-user-password",
  "/edit-industry-type",
  "/add-business-address",
  "/edit-business-type",
  "/edit-business-website",
  "/staff-profile-view"
]

// resizeUtils.js
export const handleResize = (setIsMobile, setIsTablet) => {
  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 600);
    setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
  };

  // Initial call to update dimensions
  updateDimensions();

  // Event listener for window resize
  const resizeHandler = () => {
    updateDimensions();
  };

  window.addEventListener("resize", resizeHandler);

  // Return cleanup function to remove event listener
  return () => {
    window.removeEventListener("resize", resizeHandler);
  };
};

  export const getStorageData = (key) => {
    return JSON.parse(localStorage.getItem(key));
  }

  export const setCookiesData = (key, value) => {
    try {
      if (typeof value !== 'string') {
        throw new Error('Invalid data format');
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 2);
      Cookies.set(key, encryptedData,{expires: expirationDate});
      return encryptedData;
    } catch (error) {
      console.error('Error in setCookiesData:', error);
      // Handle the error gracefully, e.g., by returning an error message.
      return 'Error: Unable to set data in cookies';
    } // You might want to return the encrypted data for further debugging
  }

  export const setCookiesDataWithTime = (key, value,rememberMe) => {
    try {
      if (typeof value !== 'string') {
        throw new Error('Invalid data format');
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      if(rememberMe){
        expirationDate.setDate(expirationDate.getDate() + 7);
      }else {
        expirationDate.setDate(expirationDate.getDate() + 1);
      }
      Cookies.set(key, encryptedData,{expires: expirationDate});
      return encryptedData;
    } catch (error) {
      console.error('Error in setCookiesData:', error);
      // Handle the error gracefully, e.g., by returning an error message.
      return 'Error: Unable to set data in cookies';
    } // You might want to return the encrypted data for further debugging
  }

  export const getCookiesData = (key) => {
    const encryptedData = Cookies.get(key);

  try {
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(CryptoJS.enc.Utf8);
     
      return JSON.parse(decryptedData);
    } else {
      return null; // Return null or handle the case where the cookie data is missing
    }
  } catch (error) {
    console.error('Error in getCookiesData:', error);
    // Handle decryption or parsing errors gracefully
    return null; // Return null or handle the error as appropriate
  }
  }
  export const getCookiesDataInSubDomain = (keyy) => {
    const encryptedData = Cookies.get(keyy);
    try {
      if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in getCookiesData:", error);
      return null;
    }
  };
 export const getPageTitle = (pathname, businessId, selectedBusines,typeOfBooking) => {
    if (pathname.includes("/staff-list") || pathname.includes("/our-faculty")) {
      return <strong>Staff</strong>;
    } else if (pathname.includes("/staff-profile-view")) {
      return "Staff Profile";
    } else if (pathname.includes("/create-new-service") || pathname.includes("/all-listing")) {
      return "Add Service";
    } else if (pathname.includes("/note")) {
      return "Notes";
    } else if (pathname.includes("/customer-profile-view/")) {
      return "Customer Profile";
    } else if (pathname.includes("/customer-form")) {
      return "Customer Dynamic Form";
    } else if (pathname.includes("/customer-bookings")) {
      return "Customer Bookings";
    } else if (pathname.includes("/business/")) {
      return "Manage Businesses";
    } else if (pathname.includes("/team")) {
      return "Manage Team";
    } else if (pathname.includes("/department")) {
      return "Manage Departments";
    } else if (pathname.includes("/users")) {
      return "Manage Users";
    } else if (pathname.includes("/change-user-password")) {
      return "Change User Password";
    } else if (pathname.includes("/all-customer")) {
      return "Customer";
    } else if (pathname.includes("/dynamic-services")) {
      return "Dynamic Services";
    } else if (pathname.includes("/service-detail")) {
      return "Service Detail";
    } else if (pathname.includes("/attributes")) {
      return "Attributes";
    } else if (pathname.includes("/pricing")) {
      return "Pricing";
    } else if (pathname.includes("/cost")) {
      return "Cost";
    } else if (pathname.includes("/service-group")) {
      return "Service Group";
    } else if (pathname.includes("/service-settings")) {
      return "Service Settings";
    } else if (pathname.includes("/businesses")) {
      return "Businesses";
    } else if (pathname.includes("/business-departments")) {
      return "Business Departments";
    } else if (pathname.includes("/business-users")) {
      return "Business User";
    } else if (pathname.includes("/business-teams")) {
      return "Business Team";
    } else if (pathname.includes("/add-business-schedular/:id")) {
      return "Business Opening Hour";
    } else if (pathname.includes("/all-services")) {
      return "Services";
    } else if (pathname.includes("/add-new-business")) {
      return "Add Business";
    } else if (pathname.includes("/scheduler/create_event")) {
      return "Add Event";
    } else if (pathname.includes("/select-address") || pathname.includes("/add-business-address")) {
      return "Business Location";
    } else if (pathname.includes("/add-owner-details")) {
      return "Add Business Detail";
    } else if (pathname.includes("/scheduler/create_booking")) {
      return <strong>Add Booking</strong>;
    } else if (pathname.includes("/scheduler/create_break_event_timeblock")) {
      if(typeOfBooking === "Break"){
        return <strong>Add Break</strong>;
      } else if(typeOfBooking === "Event"){
        return <strong>Add Event</strong>;
      } else if(typeOfBooking === "TimeBlock") {
        return <strong>Add TimeBlock</strong>;
      }
    } else if (pathname.includes("/subscribe")) {
      return "Products";
    } else if (
      pathname.includes("/edit-business-name") ||
      pathname.includes("/edit-business-address") ||
      pathname.includes("/edit-industry-type") ||
      pathname.includes("/edit-owner-detail") ||
      pathname.includes("/edit-business-type") ||
      pathname.includes("/edit-map") ||
      pathname.includes("/edit-business-website")
    ) {
      return "Manage Business Profile";
    } else if (pathname.includes(`/business-profile/${businessId?.id}`)) {
      return "Business Profile";
    } else if (pathname.includes("/Profile")) {
      return "Manage Profile";
    } else if (pathname.includes("/notes")) {
      return "Notes";
    } else if (pathname.includes(`providerScheduler/${selectedBusines?.id}`)) {
      return "Scheduler";
    } else {
      return "Name For This Page Not Added Yet";
    }
  };

  export const formatTime=(time) =>{
    const [hours, minutes] = time.split(':');
    
    const hoursInt = parseInt(hours);
    const minutesInt = parseInt(minutes);
    
    const formattedHours = (hoursInt < 10) ? `0${hoursInt}` : hoursInt;
    const formattedMinutes = (minutesInt < 10) ? `0${minutesInt}` : minutesInt;
    
    return `${formattedHours}:${formattedMinutes}`;
}
 export const dispatchSearchActions = (searchValues,dispatch) => {
    const { firstName, lastName, email, number } = searchValues;
    console.log(firstName, lastName, email, number,dispatch,"firstName, lastName, email, numberfirstName, lastName, email, number")
    dispatch(searchTextFirstName(firstName));
    dispatch(searchTextLastName(lastName));
    dispatch(searchTextEmail(email));
    dispatch(searchTextnumber(number));
  };
  
  export const saveToLocalStorage = (searchValues) => {
    const { firstName, lastName, email, number } = searchValues;
    localStorage.setItem("filterCustomerFirstName", firstName);
    localStorage.setItem("filterCustomerLastName", lastName);
    localStorage.setItem("filterCustomerEmail", email);
    localStorage.setItem("filterCustomerMobile", number);
  };
  export const removeAllCookies = () => {
    const cookies = Cookies.get();
    for (const cookieName in cookies) {
      Cookies.remove(cookieName); // Remove each cookie by name
    }
  }
  


  export const removeAllLocalStorage = () => {
    return localStorage.clear();
  }
  export const ClearAllSession=()=>{
    const cookies = Cookies.get();
    for (const cookieName in cookies) {
      Cookies.remove(cookieName); // Remove each cookie by name
      localStorage.clear()
    }
    // return localStorage.clear();

  }


  export const Logout =  () => {
      ClearAllSession()
    window.location = "/";
};



  // const formatDate = (dateString) => {
  //   if (!dateString) return ""; // Return empty string if dateString is not provided
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };
  