import React, { useState } from "react";
import uuid from "react-uuid";
import OffCanvas from "./OffCanvas";

const OffCanvasContainer = ({
  name,
  placement,
  show,
  handleClose,
  handleShow,
  costList,
}) => {
  const [costType, setCostType] = useState("");

  const costTypeId = uuid();
  const unitTypeId = uuid();

  const handleCostTypeChange = (e) => setCostType(e.target.value);

  return (
    <>
      <OffCanvas
        name={name}
        placement={placement}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        costList={costList}
        costTypeId={costTypeId}
        unitTypeId={unitTypeId}
        costType={costType}
        handleCostTypeChange={handleCostTypeChange}
      />
    </>
  );
};

export default OffCanvasContainer;
