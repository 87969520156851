import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import {
// Avatar,
// Button,
// Checkbox,
// Chip,
// FormControl,
// FormControlLabel,
// InputLabel,
// Select,
// SvgIcon,
// styled,
// } from "@mui/material";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import TuneIcon from "@mui/icons-material/Tune";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
// import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
// import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// import AddIcon from "@mui/icons-material/Add";
import { eventsandbreakAxios, providertimeavailabilityAxios } from "../../axios";
import CalendarApp from "./calenderWidgetSlot";
// import EventDialog from "./EventDialog";
// import EventUpdateDialog from "./EventUpdateDialog";
import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { openToast } from "../store/alertSlice";
import { getCalendarData, getSlotsData } from "../store/calendarSlice";
// import {
//   openmodel,
//   selectedEvent,
//   selectedSlot,
//   openupdatemodel,
// } from "../store/modelSlice";
// import { getNameOfResource } from "../../utils/ExtractAppointments";
import {
  resetAllData,
  // selectedServiceData,
  // selectedSlotData,
  setEventDuration,
} from "../store/bookingSlice";
// import { useParams } from "react-router-dom";
// import { MenuItem } from "react-pro-sidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import checkPermission, { customSnackBar, formatTime } from "../../../utils";
import CalendarSkeleton from "./skeleton";
const localizer = momentLocalizer(moment);
export const LabelContext = createContext();

export default function AddEvents({ setSelectSlotFlag }) {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { id } = useParams();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  // const [loading, setLoading] = useState(false);

  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  let startTime = useSelector((state) => state.calendarReducer.startTime);
  let isLoading = useSelector((state)=>state.calendarReducer.isLoading);
  let endTime = useSelector((state) => state.calendarReducer.endTime);
  let resources = useSelector((state) => state.calendarReducer.resources);
  // let allevent = useSelector((state) => state.calendarReducer.events);
  // let slots = useSelector((state) => state.calendarReducer.slots);
  let selectedSlot = useSelector((state) => state.bookingReducer.slotsData);
  let combinedData = useSelector((state) => state.calendarReducer.combinedData);
  let eventDuration = useSelector(
    (state) => state.bookingReducer.eventsDuration
  );
  // let allevent = useSelector((state) => state.calendarReducer.events);
  // const [eventsData, setEventDate] = useState([]);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState([]);
  const [descripion, setDescription] = useState([]);
  const [permission, setPermissions] = useState(null);
  // const [currentData, setCurrentDate] = useState();
  let allbgevent = useSelector((state) => state.calendarReducer.bgevents);
  // let responseStatus = useSelector(
  //   (state) => state.modelReducer.responseStatus
  // );
  // const [modelType, setModelTypeg] = useState("");
  // const [newSlot, setNewslott] = useState({
  //   resourceId: "",
  //   start: "",
  //   end: "",
  // });
  // const [clinicId, setClinicId] = useState("9106");

  const views = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    AGENDA: "agenda",
  };
  const memoizedDateApi = useMemo(() => dateApi, [dateApi]);
  const memoizedSelectedBusinessId = useMemo(
    () => selectedBusiness.id,
    [selectedBusiness.id]
  );
  const memoizedEventDuration = useMemo(() => eventDuration, [eventDuration]);
  const allViews = Object.keys(views).map((k) => views[k]);
  // const extractedIds = [];
  // const providerName = "";
  const extractResourceIds = useCallback(() => {
    const extractedIds = [];
    resources.forEach((obj) => {
      const currentId = obj.resourceId;
      extractedIds.push(currentId);
    });
    return extractedIds;
  }, [resources]);
  // resources.every((obj) => {
  //   const currentId = obj.id;
  //   if (
  //     extractedIds.length === 0 ||
  //     currentId > extractedIds[extractedIds.length - 1]
  //   ) {
  //     extractedIds.push(currentId);
  //     return true; // Continue iteration
  //   } else {
  //     return false; // Stop iteration
  //   }
  // });
  const CheckProviderAvailabilty = async (events) => {
    const formattedTimeFrom = formatTime(selectedSlot?.timeFrom);

    const data = {
      providerId: selectedSlot?.resourceId?selectedSlot?.resourceId:"",
      date: selectedSlot?.bookingDate?selectedSlot?.bookingDate:"",
      breakDuration: eventDuration ? eventDuration : "",
      // timeFrom: selectedSlot?.timeFrom?selectedSlot?.timeFrom:"",
      from: `${selectedSlot?.timeFrom}:00`
      ? `${formattedTimeFrom}:00`
      : "00:00:00",
      timeZone: "Europe/London",
      sku:  "",
      hasBreak: true,
      businessId: selectedBusiness?.id ? selectedBusiness?.id : 0,
    };
    await providertimeavailabilityAxios
    .post("/verify", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res?.data?.result?.available === true) {
          // setIsOpenCalendar(true);
          CraeteEventsFunction(selectedSlot)
        } else {

          customSnackBar(res?.data?.message);
        }
      })
      .catch((err) => {
        customSnackBar(err.message);
        // dispatch(openToast({ message: err.message, status: "error" }));
      });
  };
  function findProviderNameById(resources, id) {
    const foundResource = resources.find(
      (resource) => resource.resourceId === id
    );
    return foundResource ? foundResource.resourceTitle : "Unknown Provider";
  }
  const result = checkPermission("Events", "create").then((res) => {
    setPermissions(res);
  });
  useEffect(() => {
    if (permission === false) {
      customSnackBar("You have not a Permssion To Add Events");
    }
  }, [permission]);
  useEffect(() => {
    // Set default duration to 15 minutes when the component mounts
    dispatch(setEventDuration(15));

    // ... (rest of your code in the useEffect)
  }, []); 


  const getCalendarEventsData = useCallback(async () => {
    const record = {
      providerId: [],
      bookingDateStart: memoizedDateApi,
      is_business: true,
      bookingDateEnd: memoizedDateApi,
      timeZone: "Europe/London",
      businessId: memoizedSelectedBusinessId,
    };
    await dispatch(getCalendarData(record));
  },[dispatch, memoizedDateApi,memoizedSelectedBusinessId]);

  const getSlotsEventData = useCallback(async () => {
        const data = {
          providerId: [],
          date: memoizedDateApi,
          hasBreak: true,
          breakDuration: eventDuration,
          page: 1,
          // pageSize:  extractedIds.length,
          timeZone: "Europe/London",
  
          pageSize: 100,
          sku: "",
          businessId: memoizedSelectedBusinessId,
        };
    await dispatch(getSlotsData({data}));
  },[dispatch,memoizedDateApi,memoizedSelectedBusinessId,eventDuration]);

  useEffect(() => {
    const openCalendar = async () => {
      if (isOpenCalendar) {
        await getCalendarEventsData();
        await getSlotsEventData();
      }
    }
    openCalendar();
  }, [getCalendarEventsData,getSlotsEventData,isOpenCalendar]);

  //Onclick Event Function Call
  // function calenderEvents(events) {
  //   setEventDate(events);
  // }
  //Onclick Event Function Call
  function handleEvents(events) {
    if (!Object.isExtensible(events)) {
      events = { ...events };
    }
    const currentDate = new Date();
    const datetime = new Date(events.start);
    events.bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    if (datetime < currentDate) {
      customSnackBar("Slot is in past unable to select that");
      // dispatch(
      //   openToast({
      //     message: "Slot is in past unable to select that",
      //     status: "warning",
      //   })
      // );
    } else if (events.title === "Available Slots") {
      // dispatch(selectedSlotData(events));
      CraeteEventsFunction(events);
    } else {
      customSnackBar("Already Booked Slot");
      // dispatch(
      //   openToast({ message: "Already Booked Slot", status: "warning" })
      // );
    }
  }

  //Onclick Slot Function Call
  // function handleSlots(slot) {
  // setNewslott({
  // 	resourceId: slot.resourceId,
  // 	start: slot.start,
  // 	end: slot.end,
  // });
  // setModelTypeg("slot");
  // dispatch(openmodel());
  // dispatch(selectedSlot(slot));
  // }

  //Onclick Filter Model
  // function handleFilter() {
  //   setModelTypeg("filter");
  //   dispatch(openmodel());
  // }

  //Clinic Change Functions
  // const handleChangeClinic = (id) => {
  //   setClinicId(id);
  // };
  useEffect(() => {
    const intervalId = setInterval(() => {
      var time = moment().format("LT");
      const existing = document.getElementsByClassName("my-div-time")[0];
      if (existing) {
        existing.innerHTML = `${time}`;
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      handleCurrentIndicator();
    }, 220);
  }, []);
  const handleCurrentIndicator = () => {
    var time = moment().format("LT");
    const existingDiv = document?.getElementsByClassName(
      "rbc-current-time-indicator"
    )[0];
    if (existingDiv) {
      const newDiv = document.createElement("div");
      newDiv.className =
        "my-div-time -mt-2 text-xs -ml-20 px-2 border-2 w-fit border-red-500 rounded-full";
      newDiv.textContent = `${time}`;
      existingDiv.appendChild(newDiv);
    }
  };
  // const [age, setAge] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  useEffect(() => {
    if (selectedSlot.resourceId) {
    } else {
    }
  }, [selectedSlot]);

  function calculateEndTime(startTime, initialEndTime, durationMinutes) {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const totalStartMinutes = startHours * 60 + startMinutes;
    const newTotalEndMinutes = totalStartMinutes + durationMinutes;
    const newEndHours = Math.floor(newTotalEndMinutes / 60);
    const newEndMinutes = newTotalEndMinutes % 60;
    const formattedEndTime = `${String(newEndHours).padStart(2, "0")}:${String(
      newEndMinutes
    ).padStart(2, "0")}`;
    return formattedEndTime;
  }

  const CraeteEventsFunction = async (event) => {
    const formattedEndDate = calculateEndTime(
      event.timeFrom,
      event.timeTo,
      eventDuration
    );
    const data = {
      businessId: memoizedSelectedBusinessId,

      providerId: event.resourceId,
      title: title ? title : "Events",
      description: descripion,
      colorCode: "#ffc200",
      bookingDate: event.bookingDate,
      bookingType: "event",
      bookingDuration: eventDuration,
      timeFrom: `${event.timeFrom}:00`,
      timeTo: `${formattedEndDate}:00`,
      type: "event",
      providerName: findProviderNameById(resources, event.resourceId),
      timeZone: "Europe/London",
    };
    await eventsandbreakAxios
      .post("pb/events/", data)
      .then((res) => {
        customSnackBar("Event Created Successfully");
        // dispatch(
        //   openToast({
        //     message: "Event Created Successfully",
        //     status: "success",
        //   })
        // );
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        dispatch(resetAllData());
        setIsOpenCalendar(false);
        history.push(`/calendar`);
        // window.location.reload();
      })
      .catch((err) => {
        customSnackBar(err.message);
        // dispatch(openToast({ message: err.message, status: "error" }));
      });
  };

  const styleForDeActiveButton = {
    borderRadius: "8px",
    border: "0.6px solid #DCDCDC",
    backgroundColor: "#FFF",
    width: "18%",
    color: "#888",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  };

  const styleForActiveButton = {
    borderRadius: "8px",
    border: "0.6px solid #FFD705",
    backgroundColor: "#FFD705",
    width: "18%",
    color: "#530",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  };

  const handleDuration = (duration) => {
    dispatch(setEventDuration(duration));
  };

  const options = [
    { hours: 1, minutes: 15 },
    { hours: 1, minutes: 30 },
    { hours: 1, minutes: 45 },
    { hours: 2, minutes: 0 },
    { hours: 2, minutes: 15 },
    { hours: 2, minutes: 30 },
    { hours: 2, minutes: 45 },
    { hours: 3, minutes: 0 },
    { hours: 3, minutes: 15 },
    { hours: 3, minutes: 30 },
    { hours: 3, minutes: 45 },
    { hours: 4, minutes: 0 },
    { hours: 4, minutes: 15 },
    { hours: 4, minutes: 30 },
    { hours: 4, minutes: 45 },
    { hours: 5, minutes: 0 },
    { hours: 5, minutes: 15 },
    { hours: 5, minutes: 30 },
    { hours: 5, minutes: 45 },
    { hours: 6, minutes: 0 },
    { hours: 6, minutes: 15 },
    { hours: 6, minutes: 30 },
    { hours: 6, minutes: 45 },
    { hours: 7, minutes: 0 },
   
  ];
  const handleOptionChange = (hours, minutes) => {
    const totalMinutes = hours * 60 + minutes;
    dispatch(setEventDuration(totalMinutes));
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className=" py-1 px-4">
        {!isOpenCalendar ? (
          <div
            className="d-flex mt-2"
            style={{
              paddingLeft: "20%",
              paddingRight: "20%",
            }}
          >
            <div
              className="pt-2"
              onClick={() => history.push("/scheduler/create_booking")}
              role="button"
            >
              <i id="icon_left_color" className="fas fa-chevron-left"></i>
            </div>
            <div className="container ms-4">
              <div
                className="ps-2"
                style={{
                  borderRadius: "8px",
                  border: "0.6px solid #DCDCDC",
                  backgroundColor: "#FFF",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#8A8A8A",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  Event Title
                </span>
                <input
                  type="text"
                  placeholder="Event Here"
                  value={title}
                  disabled={permission === false}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "#616161",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 300,
                  }}
                />
              </div>
              <div className="pt-2 mb-2">
                <span
                  style={{
                    color: "#484848",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Event Duration
                </span>

                <div className="d-flex justify-content-between">
                  <div
                    className="py-1 d-flex align-items-center justify-content-center"
                    role="button"
                    disabled={permission === false}
                    onClick={() => handleDuration(15)}
                    style={
                      eventDuration === 15
                        ? styleForActiveButton
                        : styleForDeActiveButton
                    }
                  >
                    {eventDuration === 15 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        disabled={permission === false}
                        fill="none"
                      >
                        <g clipPath="url(#clip0_7207_6338)">
                          <path
                            d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            disabled={permission === false}
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                            stroke="#553300"
                            strokeWidth="1.5"
                            disabled={permission === false}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7207_6338">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    15 min
                  </div>
                  <div
                    className="py-1 d-flex align-items-center justify-content-center"
                    role="button"
                    onClick={() => handleDuration(30)}
                    style={
                      eventDuration === 30
                        ? styleForActiveButton
                        : styleForDeActiveButton
                    }
                  >
                    {eventDuration === 30 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_7207_6338)">
                          <path
                            d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7207_6338">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    30 min
                  </div>
                  <div
                    className="py-1 d-flex align-items-center justify-content-center"
                    role="button"
                    onClick={(e) => handleDuration(45, e)}
                    style={
                      eventDuration === 45
                        ? styleForActiveButton
                        : styleForDeActiveButton
                    }
                  >
                    {eventDuration === 45 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_7207_6338)">
                          <path
                            d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            disabled={permission === false}
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7207_6338">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    45 min
                  </div>
                  <div
                    className="py-1 d-flex align-items-center justify-content-center"
                    role="button"
                    disabled={permission === false}
                    onClick={() => handleDuration(60)}
                    style={
                      eventDuration === 60
                        ? styleForActiveButton
                        : styleForDeActiveButton
                    }
                  >
                    {eventDuration === 60 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_7207_6338)">
                          <path
                            d="M10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99996C18.3346 5.41663 14.5846 1.66663 10.0013 1.66663C5.41797 1.66663 1.66797 5.41663 1.66797 9.99996C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333Z"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.45703 10.0001L8.81536 12.3584L13.5404 7.64172"
                            stroke="#553300"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7207_6338">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    60 min
                  </div>
                  <div
                    className="dropdown-container"
                    style={{
                      borderRadius: "8px",
                      border:
                        eventDuration > 60
                          ? "0.6px solid #FFD705"
                          : "0.6px solid #DCDCDC",
                      backgroundColor: eventDuration > 60 ? "#FFD705" : "#FFF",
                      width: "24%",
                      color: eventDuration > 60 ? "#530" : "#888",
                      // textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: eventDuration > 60 ? 700 : 500,
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <div
                      className="py-1 dropdown-header"
                      style={{
                        textAlign: "center",
                      }}
                      role="button"
                      disabled={permission === false}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {eventDuration === 75
                        ? "1 hour - 15 Min"
                        : eventDuration === 90
                        ? "1 hour - 45 Min"
                        : eventDuration === 105
                        ? "1 hour - 45 Min"
                        : eventDuration === 120
                        ? "2 hour - 0 Min"
                        : eventDuration === 135
                        ? "2 hour - 15 Min"
                        : eventDuration === 150
                        ? "2 hour - 30 Min"
                        : eventDuration === 165
                        ? "2 hour - 45 Min"
                        : eventDuration === 180
                        ? "3 hour - 0 Min"
                        : eventDuration === 195
                        ? "3 hour - 15 Min"
                        : eventDuration === 210
                        ? "3 hour - 30 Min"
                        : eventDuration === 225
                        ? "3 hour - 45 Min"
                        : eventDuration === 240
                        ? "4 hour - 0 Min"
                        : eventDuration === 255
                        ? "4 hour - 15 Min"
                        : eventDuration === 270
                        ? "4 hour - 30 Min"
                        : eventDuration === 285
                        ? "4 hour - 45 Min"
                        : eventDuration === 300
                        ? "5 hour - 0 Min"
                        : eventDuration === 315
                        ? "5 hour - 15 Min"
                        : eventDuration === 330
                        ? "5 hour - 30 Min"
                        : eventDuration === 345
                        ? "5 hour - 45 Min"
                        : eventDuration === 360
                        ? "6 hour - 0 Min"
                        : eventDuration === 375
                        ? "6 hour - 15 Min"
                        : eventDuration === 390
                        ? "6 hour - 30 Min"
                        : eventDuration === 405
                        ? "6 hour - 45 Min"
                        : eventDuration === 420
                        ? "7 hour - 0 Min"
                        : "Custom"}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="7"
                        viewBox="0 0 17 9"
                        fill="none"
                        className="ms-2"
                        style={{
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
                        }}
                      >
                        <path
                          d="M1.56969 1.7959L7.27469 7.5009C7.94844 8.17465 9.05094 8.17465 9.72469 7.5009L15.4297 1.7959"
                          stroke="#7B7B7B"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    {isOpen && (
                      <ul
                        className="dropdown-options"
                        style={{
                          listStyle: "none",
                          padding: "0",
                          margin: "0",
                          position: "absolute",
                          minWidth: "100%",
                          top: "100%",
                          left: "0",
                          zIndex: "1",
                          border: "1px solid #D7D7D7",
                          marginTop: "5px",
                          height: "50vh",
                          overflow: "auto",
                          backgroundColor: "white",
                          // borderRadius: "8px",
                        }}
                      >
                        {options.map((option, index) => (
                          <li
                            key={index}
                            onMouseDown={() =>
                              handleOptionChange(option.hours, option.minutes)
                            }
                            style={{
                              marginTop: "5px",
                              marginBottom: "5px",
                              backgroundColor:
                                option * 60 === eventDuration
                                  ? "#FFD705"
                                  : "#FFF",
                              padding: "4px 9px",
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                          >
                            {`${option.hours} hour - ${option.minutes} Min`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="px-2 py-1"
                style={{
                  borderRadius: "8px",
                  border: "0.6px solid #DCDCDC",
                  backgroundColor: "#FFF",
                }}
              >
                <div className="mb-1 d-flex justify-content-between">
                  <span
                    style={{
                      color: "#8A8A8A",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    Description
                  </span>
                  <span
                    style={{
                      color: "#8A8A8A",
                      textAlign: "right",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    {descripion?.length}/35
                  </span>
                </div>
                <textarea
                  style={{
                    border: "none",
                    width: "100%",
                    color: "#616161",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 300,
                  }}
                  row="4"
                  disabled={permission === false}
                  placeholder="Write small description here.."
                  value={descripion}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="pt-3 d-flex justify-content-end">
                <button
                  className="px-5 py-2"
                  type="button"
                  style={{
                    borderRadius: "8px",
                    backgroundColor:
                    title.length === 0 || descripion.length === 0 
                      ? "#f5f5f5"
                      : "#FFD705",                    color: "#530",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                  disabled={
                    title.length === 0 || descripion.length === 0 ? true : false
                  }
                  onClick={(e) =>{
                    if(selectedSlot?.length===0){
                      setIsOpenCalendar(true)
                    }else{
                      CheckProviderAvailabilty()
                    }
                    }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M8 12.6667H17.3333M12.6667 17.3333V8M12.6667 24.3333C19.0833 24.3333 24.3333 19.0833 24.3333 12.6667C24.3333 6.25 19.0833 1 12.6667 1C6.25 1 1 6.25 1 12.6667C1 19.0833 6.25 24.3333 12.6667 24.3333Z"
                      stroke="#553300"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  Add Event
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center align-items-center">
            <div className="d-flex">
              <div
                className="pt-2"
                onClick={() => history.push("/scheduler/create_break")}
                role="button"
              >
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
              <p
                className="w-100 text-center text-primary py-0"
                style={{ fontSize: "20px" }}
              >
                Select your Events Slot
              </p>
            </div>
            {/* <Button className="col-4 py-3 " color="primary" variant="contained" onClick={e=>{e.preventDefault(); handleNextStep()}}> Pick Booking Slot</Button> */}
            {isLoading ? (
              <CalendarSkeleton />
            ) : (
              <div
                className="col-12 overflow_calender"
                style={{ height: "80vh", overflow: "auto" }}
              >
                <CalendarApp
                  localizer={localizer}
                  allevent={combinedData}
                  allbgevent={allbgevent}
                  resources={resources}
                  views={allViews}
                  startTime={startTime}
                  endTime={endTime}
                  //   draghandleEvents={draghandleEvents}
                  calenderEvents={handleEvents}
                  handleSlots={() => {}}
                  //   handleFilter={handleFilter}
                />

                {/* <EventDialog
                modelType={modelType}
                clinicId={clinicId}
                handleChangeClinic={handleChangeClinic}
              />
              <EventUpdateDialog /> */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
