import React from "react";
import uuid from "react-uuid";
import { ChildDropMCQQuestion } from "./ChildMCQQuestion";
export const DropMCQQuestion = ({
  setRefresh,
  refresh,
  e,
  index,
  setFormBuilderQuestions,
  setClickOption,
  showModal,
  setModal,
  formBuilderQuestions,
  sectionData,
  setSectionData,
  selectedSection,
  setSelectedSection,
}) => {

  return (
    <div key={uuid()} className="rounded shadow p-3 bg-white my-2">
      <div className="d-flex justify-content-between">
        <h5>{e?.contentObject?.questionText}</h5>
        <h5
          className="btn  text-danger"
          onClick={() => {
            selectedSection.questionList = selectedSection.questionList.filter(
              (item, i) => item.id !== e.id
            );
            setSelectedSection(selectedSection);
            sectionData.forEach((element) => {
              if (element.id === selectedSection.id) {
                element = selectedSection;
              }
            });
            setSectionData([...sectionData]);
            setRefresh(!refresh);
          }}
        >
          <i className="fas fa-minus-square"></i>
        </h5>
      </div>
      {/* <div className="d-flex align-items-center mb-3">
        <input
          type="checkbox"
          id="check_mcq"
          className="me-3"
          value={"isMultiple"}
            onClick={() => {
              setIsMultiple(!isMultiple);
            }}
        />
        <label htmlFor="check_mcq">Required </label>
      </div> */}
      
      <p className="m-0 p-0 small">
        Type: <b>{e?.contentObject?.questionType}</b>{" "}
      </p>
      {e?.contentObject?.questionType === "MCQQuestion" ? (
        <p className="small">
          Multiple Choose:{" "}
          <b>{e?.contentObject?.multiple_choice ? "Yes" : "No"}</b>{" "}
        </p>
      ) : (
        <></>
      )}
      <p className="small">Options:</p>

      {e?.contentObject?.options?.map((op, indexOp) => {
        return (
          <div key={uuid()} className=" my-1 rounded bg-light p-1 ">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>{op.choiceText}</h6>
              </div>
              <button
                className="btn btn-danger p-1"
                data-bs-toggle="modal"
                data-bs-target="#addChildModal"
                onClick={() => {
                  // setChildAddClickObject({ parent: e, option: op })
                  setClickOption(op.id);
                  setModal(true);
                }}
              >
                <small className="px-2">+</small>
              </button>
            </div>
            <div>
              {op.childs
                ? op.childs.map((e) => (
                    <ChildDropMCQQuestion
                      refresh={refresh}
                      setRefresh={setRefresh}
                      key={uuid()}
                      e={e}
                      setFormBuilderQuestions={setFormBuilderQuestions}
                      setClickOption={setClickOption}
                      formBuilderQuestions={formBuilderQuestions}
                      sectionData={sectionData}
                      setSectionData={setSectionData}
                      selectedSection={selectedSection}
                      setSelectedSection={setSelectedSection}
                    />
                  ))
                : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
