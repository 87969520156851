import {
  FETCH_STAFF_FAILURE,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
} from "./actionType";
import produce from "immer";
const initialState = {
  code: null,
  dataStaff: [],
  message: "",
  count: "",
  loading: false,
  error: null,
};
const Staff = produce((state, action) => {
  switch (action.type) {
    case FETCH_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STAFF_SUCCESS:
      state.dataStaff = action.payload.data.result.staff;
      state.message = action.payload.data.message;
      state.count = action.payload.data.totalcount;
      state.code = action.payload.data.code;
      break;
    case FETCH_STAFF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}, initialState);
export default Staff;
