import React from 'react'
import { useEffect,useState } from 'react';
import { getForms } from '../../service/APIService';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Paper } from '@mui/material';
import moment from 'moment';



export const FormList = () => {
    const [loading, setLoading] = useState(false);
    const [formList, setFormList] = useState([]);

    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {
        setLoading(true)
        await getForms()
            .then(function (response) {
                setFormList(response.data.results)
            })
        setLoading(false)
    }
    return (
        <div className=''>
            {   loading ? 
                <div className="text-center" style={{marginTop: "8rem", fontSize: "20px", color: "#db0406"}}>
                <div className="spinner-border" role="status" style={{width: "3.5rem", height: "3.5rem"}}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
                :
                
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className='theme_color_2' >
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="">ID</TableCell>
                      <TableCell align="">Form Name</TableCell>
                      <TableCell align="">Created</TableCell>
                      <TableCell align="">Version</TableCell>
                      <TableCell align="">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formList.reverse().map((row, i) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell  >{row.id}</TableCell>
                        <TableCell  >{row.title}</TableCell>
                        {/* <TableCell  >{row.createdDate}</TableCell> */}
                        <TableCell  >{moment(row.createdDate).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                        <TableCell  >{row.version}</TableCell>
                        <TableCell  >{row.published ? "published" : "Draft"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
      


        </div>
    )
}
