import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, CircularProgress } from "@mui/material";
import SideNavone from "../../../SideNavBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import Products from "./Products";

const Subscribe = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const query = `
        query {
          subscriptionBusinessById(id: "${selectedBusiness?.id}") {
            id
      createdAt
      updatedAt
      isDeleted
      BusinessId
      startDate
      endDate
      status
      planId {
        id
        createdAt
        updatedAt
        isDeleted
        name
        price
        currency
        isActive
        planEntity
        productId {
          id
          createdAt
          updatedAt
          isDeleted
          name
          description
          type
      }
    }
    
          }
        }
      `;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: query,
        },
        {
          referrerPolicy: "no-referrer-when-downgrade",
        }
      );

      if (response.data.data && response.data.data.subscriptionBusinessById) {
        // success("Subscription Fetch Successfully");
        setData(response.data.data.subscriptionBusinessById);
      } else {
        // error("No Record Found");
        setData([]); // Set empty data if no record found
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [selectedBusiness.id]);

  useEffect(() => {
    if (selectedBusiness.id) {
      fetchData();
    } else {
      setData([]); // Reset data if businessId is empty
    }
  }, [selectedBusiness.id, fetchData]);

  function getNumberOfDays(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    // Convert the date strings to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in days
    const diffDays = Math.round(Math.abs((end - start) / oneDay));

    return diffDays;
  }

  return (
    <SideNavone>
      <div
        className="rbc-time-content"
        style={{ height: "100vh", scroll: "auto" }}
      >
        <div
          className="mx-4 m-1 row"
          //  style={{height:"100vh", scroll:"auto"}}
        >
          <div className="row mt-5 ps-5 ">
            {/* <select
              className="form-control w-25"
              onChange={(e) => setBusinessId(e.target.value)}
            >
              <option>Select Business</option>
              {all_business &&
                all_business.map((v, i) => {
                  return <option value={v?.id}>{v?.name}</option>;
                })}
            </select> */}
            {loading && (
              <div className="mt-2 d-flex justify-content-center align-items-center">
                <CircularProgress size={20} />
              </div>
            )}
          </div>
          <div className="row mx-4 pt-4 subs ">Subscribed</div>
          <div className="row">
            {data.length > 0 ? (
              data.map((row) => {
                const numberOfDays = getNumberOfDays(
                  row.startDate,
                  row.endDate
                );
                const showUpgradeButton = numberOfDays < 30;
                return (
                  <div className="col-md-3">
                    <div className="row mt-4 ms-4   mb-3 pt-0 ">
                      <div className="card ">
                        <div className="d-flex mt-3">
                          <div className="d-flex flex-wrap justify-content-between">
                            <div className="me-2">
                              {" "}
                              <img
                                style={{ width: "41px", height: "41px" }}
                                src="../images/Custom Size29.jpg"
                                alt="no-data"
                              />
                            </div>
                            <div className="ms-2">
                              <h2 className="mt-1 me-5 chat ">
                                {row.planId.productId.name
                                  .charAt(0)
                                  .toUpperCase() +
                                  row.planId.productId.name.slice(1)}
                              </h2>
                              {/* <div className="d-flex mt-0">
                                <div className="subtype me-2">Businesses</div>
                                <div
                                  className=" ps-0 four"
                                  style={{ color: "#FF4E4E" }}
                                >
                                  {" "}
                                  {row.total}
                                </div>
                              </div> */}
                            </div>
                            <div className="position text-end">
                              {" "}
                              <BsThreeDotsVertical />{" "}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-3 justify-content-between flex-wrap mt-3">
                          <div className="d-flex  flex-column">
                            <div className=" subtype">Subscription Type</div>
                            <div className=" pro text-start">
                              {row.planId.name}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="text-end subtype">Expires in</div>
                            {showUpgradeButton ? (
                              <div
                                className=" text-end twinty"
                                style={{ color: "#FF4E4E" }}
                              >
                                ({getNumberOfDays(row.startDate, row.endDate)})
                              </div>
                            ) : (
                              <div
                                className=" text-end twinty"
                                style={{ color: "#000" }}
                              >
                                ({getNumberOfDays(row.startDate, row.endDate)})
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          {" "}
                          <hr
                            style={{
                              background: "#DCDCDC",
                              height: "1px",
                              width: "100%",
                              left: "20px",
                              top: "320px",
                              border: "0.7px",
                            }}
                          />
                        </div>
                        <div className="d-flex flex-wrap mb-3 justify-content-between">
                          <div className=" ">
                            <img
                              className="vector "
                              src="../images/Group 29441.png"
                              alt="no-data"
                            />
                            &nbsp; &nbsp;
                            <img
                              className="vector1 "
                              src="../images/Vector (2).jpg"
                              alt="no data"
                            />
                          </div>

                          <div className="pe-0 ps-4 text-end">
                            <div className="d-flex flex-wrap pe-0 me-0">
                              <div className="mb-2 mb-md-0">
                                {showUpgradeButton && (
                                  <Button
                                    sx={{
                                      backgroundColor: "#fff",
                                      height: "30px",
                                      borderRadius: "6px",
                                      color: "#A9A9A9",
                                      border: "1px solid #A9A9A9",
                                      textTransform: "capitalize",
                                      "&:hover": {
                                        backgroundColor: "#c0c0c0",
                                        color: "#ffffff",
                                      },
                                    }}
                                    variant="contained"
                                  >
                                    {"Upgrade"}
                                  </Button>
                                )}
                              </div>
                              <div className="ms-md-2">
                                <Button
                                  sx={{
                                    backgroundColor: "#538dff",
                                    height: "30px",
                                    borderRadius: "6px",
                                    textTransform: "capitalize",
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    history.push("/business/calender", {
                                      state: row,
                                    });
                                  }}
                                >
                                  {"Explore More"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : loading ? (
              // Show loading text if data is not available but still loading
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "200px" }}
              >
                <CircularProgress size={40} />
              </div>
            ) : (
              // Show a message when there's no data available
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "100px" }}
              >
                Business does not have any subscribe product{" "}
              </div>
            )}
          </div>
          <Products businessId={selectedBusiness?.id} />
        </div>
      </div>
    </SideNavone>
  );
};

export default Subscribe;
