import React, { useEffect, useState, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import { momentLocalizer } from "react-big-calendar";
import { useHistory } from "react-router-dom";
import { Calendar } from "react-big-calendar";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import dayjs from "dayjs";

import CalenderHeader from "../../../../Calendar/scheduler/components/CalenderHeader";
import { resetdate } from "../../../../scheduler/store/dateSlice";
import { FormateTime, customSnackBar } from "../../../../utils";
import MapPin from "../../../../Assets/scheduler/map-pin.svg";
import instance from "../../../../utils/utils";
import {
  getScheduleList,
  postProviderSceduleForStaff,
} from "../../services/serviceProvider";
import {
  nextmonth,
  prevmonth,
} from "../../../../Calendar/scheduler/store/dateSlice";
import MultiScheduleTimes from "./multiScheduleTimes";

const resourcesMap = [
  {
    resourceId: 396,
    resourceTitle: "Pat Commins",
    img: "https://1864597015.rsc.cdn77.org/Plexaar/Attachments/Serviceprovider/396.jpeg",
  },
];
const DnDCalendar = withDragAndDrop(Calendar);

const ProviderCalender = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarRef = useRef();
  const inputBusinessRef = useRef();

  const views = {
    MONTH: "month",
    // AGENDA: "agenda",
  };
  const [open, setOpen] = useState(false);

  let currentDate = new Date();

  const userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [slotInfo, setSlotInfo] = useState("");
  const [loading, setLoading] = useState("");
  const [startTime, setStartTime] = useState("");
  const [date, setDate] = useState(new Date()); // Use this for displaying date
  const [isEdit, setIsEdit] = useState(false); // Store current date separately
  const [endTime, setEndTime] = useState("");
  const [fetchAgain, setFetchAgain] = useState(false);
  const [scheduleItems, setScheduleItems] = useState([
    {
      week: "",
      startTime: [],
      endTime: [],
      businessId: "",
      businessName: "",
    },
  ]);

  const allViews = Object.keys(views).map((k) => views[k]);
  const localizer = momentLocalizer(moment);

  // Set the slots in calendar
  function handleSlots(slot) {
    console.log("handleSlots called with slot", slot);
    const currentDate = new Date(); // Get the current date
    const datetime = new Date(slot.start);
    const datetime2 = new Date(slot.end);
    const timeFrom = `${datetime.getHours()}:${datetime.getMinutes()}`;
    const timeTo = `${datetime2.getHours()}:${datetime2.getMinutes()}`;
    const bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)

      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    const data = {
      resourceId: slot.resourceId,
      timeFrom: timeFrom,
      timeTo: timeTo,
      bookingDate: bookingDate,
      startDate: datetime,
      endDate: datetime2,
      // duration: calculateDurationInMinutes(timeFrom, timeTo),
      // resourceObject: resources.find(
      //   (obj) => obj.resourceId === slot.resourceId
      // ),
    };
    setSlotInfo(data);

    if (datetime < currentDate) {
      customSnackBar("Time is In past you cannot Select that");
      // dispatch(
      //   openToast({
      //     message: "Time is In past you cannot Select that",
      //     status: "warning",
      //   })
      // );
    } else {
      handleOpen();

      // setNewslott({
      //   resourceId: slot.resourceId,
      //   start: slot.start,
      //   end: slot.end,
      // });
    }
    // setModelTypeg("slot");
    // dispatch(openmodel());
    // dispatch(selectedSlot(slot));
  }
  // Open the Popup by setting open state to true
  const handleOpen = () => {
    // customSnackBar("Time is In past you cannot Select that");
    setOpen(true); // Open the modal by setting open state to true
  };
  // Close the popup by setting open state to false and all the selected states to default
  const handleClose = () => {
    setOpen(false);
    clearStates();
    // Close the modal by setting open state to false and all the selected states to default
  };

  const [customEvents, setCustomEvents] = useState([]);

  // This UseEffect contains an Api call to get schedules in the calendar
  useEffect(() => {
    const providerId = JSON.parse(localStorage.getItem("StaffRecord"));
    const getMonthStartEndDates = () => {
      const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
      return { startDate, endDate };
    };
    getBusinessLocations();
    if (providerId && providerId.providerId) {
      getScheduleList(providerId.providerId).then((res) => {
        if (res?.result?.length > 0) {
          setLoading(true);
          const { startDate, endDate } = getMonthStartEndDates();

          const data = {
            endDate: endDate,
            startDate: startDate,
            providerId: [providerId.providerId],
            timeZone: "Europe/London",
          };

          instance
            .post("/provideravailability_svc/pb/get/provider/schedule/", data)
            .then((res) => {
              console.log("RESPONSE", res);

              const formattedEvents = res.data.result.map((event) => {
                let arr = [];

                event.timeSlots.map((slot) => {
                  arr.push({
                    startTime: moment(event.date + " " + slot.startTime).format(
                      "HH:mm"
                    ),
                    endTime: moment(event.date + " " + slot.endTime).format(
                      "HH:mm"
                    ),
                  });
                });

                // const startTime = moment(
                //   event.date + " " + event.timeSlots[0].startTime
                // ).format("HH:mm");
                // const endTime = moment(
                //   event.date + " " + event.timeSlots[0].endTime
                // ).format("HH:mm");
                const timeRange = `${startTime}-${endTime}`;

                return {
                  title: (
                    <div onClick={() => editSchedule(event)}>
                      <div>Time</div>
                      {arr.map((ar) => (
                        <div>
                          <>{`${ar.startTime}-${ar.endTime}`}</>
                        </div>
                      ))}
                    </div>
                  ),
                  start: moment(
                    event.date + " " + event.timeSlots[0].startTime
                  ).toDate(),
                  end: moment(
                    event.date + " " + event.timeSlots[0].endTime
                  ).toDate(),
                };
              });
              // Set formatted events to state
              setCustomEvents(formattedEvents);

              // createCustomEvents(res?.result);
              // setStartDate(res?.result.startDate);
              setLoading(false);
            });
        } else {
          setLoading(false);

          customSnackBar(
            "Provider Schedule does not added Please Add it first"
          );
        }
      });
    }
  }, [date, fetchAgain]);

  // Upon clicking a saved schedule the states are filled with schedule data through this function
  const editSchedule = (event) => {
    setScheduleItems([...event.timeSlots]);
    // setStartTime(setScheduleItems[0].startTime);
    // setEndTime(event.timeSlots[0].endTime);
    // setBusiness({ name: event.timeSlots[0].businessName });
    setSlotInfo({ startDate: event.date });
    setIsEdit(true);

    const datetime = new Date(event.date);
    const datetime2 = new Date(event.date);
    const timeFrom = convertToDateTime(event.timeSlots[0].startTime);
    const timeTo = convertToDateTime(event.timeSlots[0].endTime);
    const bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)

      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    const data = {
      timeFrom: timeFrom,
      timeTo: timeTo,
      bookingDate: bookingDate,
      startDate: datetime,
      endDate: datetime2,
    };
    setSlotInfo(data);
    handleOpen();
  };
  // Convert the time into MUI time picker format
  function convertToDateTime(inputTime) {
    // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
    if (dayjs(inputTime, { strict: true }).isValid()) {
      return dayjs(inputTime);
    }

    // Parse input time as "HH:mm:ss"
    const currentTime = dayjs();
    const [hours, minutes, seconds] = inputTime.split(":");

    // Set the time components
    const formattedDateTime = currentTime
      .set("hour", parseInt(hours, 10))
      .set("minute", parseInt(minutes, 10))
      .set("second", parseInt(seconds, 10));

    return formattedDateTime;
  }

  const appointmentData = {
    date: date,
    actionState: "new",
  };

  const getBusinessLocations = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    await instance
      .get(
        `${process.env.REACT_APP_BASE_URL}/business_svc/pb/get_business_list/?userId=${userInfo?.id}`
      )
      .then((res) => {
        setBusinessLocations(res?.data?.result);
      });
  };

  // Function to get the number of week from selected date
  function getWeekOfMonth(date) {
    console.log("DATE", date);
    let adjustedDate = date.getDate() + date.getDay();
    let prefixes = ["0", "1", "2", "3", "4", "5"];
    return parseInt(prefixes[0 | (adjustedDate / 7)]);
  }
  const providerId = JSON.parse(localStorage.getItem("StaffRecord"));
  //Function to clear all the states
  const clearStates = () => {
    setStartTime("");
    setEndTime("");

    setIsEdit(false);
    setScheduleItems([
      {
        week: "",
        startTime: [],
        endTime: [],
        businessId: "",
        businessName: "",
      },
    ]);
  };

  const customEventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = "#548DFF"; // Blue color for event background
    const textColor = "#FFFFFF"; // White color for event text
    const height = "14vh"; // Full height for event
    const fontWeight = "100";
    const style = {
      backgroundColor,
      color: textColor,
      height,
      fontWeight: fontWeight,
      border: "none",
      cursor: "pointer",
    };

    return {
      style: style,
    };
  };

  // Function to call for adding/removing any schedule
  const AddProviderScedule = (bool) => {
    if (
      scheduleItems[0].startTime &&
      scheduleItems[0].endTime &&
      scheduleItems[0].businessName
    ) {
      let value = scheduleItems.map((item) => {
        let obj = {
          ...item,
          week: getWeekOfMonth(slotInfo.startDate),
        };
        return obj;
      });
      const providerId = JSON.parse(localStorage.getItem("StaffRecord"));
      let Data = {
        date: moment(slotInfo.startDate).format("YYYY-MM-DD"),
        timeZone: "Europe/London",
        isOff: bool,
        scheduleItems: [...value],
        providerId: providerId.providerId,
      };
      postProviderSceduleForStaff(Data).then((res) => {
        if (res.error) {
          customSnackBar(res.message);
        } else {
          customSnackBar("Schedule Added");
        }
        clearStates();
      });
      handleClose();
      clearStates();
      setFetchAgain(!fetchAgain);
    } else {
      customSnackBar("Add Business and Select Time");
    }
  };

  const options = {
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };
  const dayOption = {
    weekday: "long",
  };
  const handleNavigate = (date, view) => {
    // Handle navigation (e.g., next month, previous month)
    if (view === "month") {
      setDate(date); // Update displayed date
      if (date.getMonth() < currentDate.getMonth()) {
        dispatch(prevmonth()); // Previous month
      } else if (date.getMonth() > currentDate.getMonth()) {
        dispatch(nextmonth()); // Next month
      } else {
        dispatch(resetdate()); // Current month
      }
    }
  };

  return (
    <>
      <div className="w-100 h-100 rounded-xl" style={{ paddingTop: "1.2rem" }}>
        {/* Your calendar component and other content */}

        <div
          className={`card-grey-provider slide-in active
          `}
          style={{
            right: open ? "36%" : "-50%",
          }}
        >
          <div>
            <div className="col-md-12 px-3 pb-2 mt-4 col-black ">
              <h3>{slotInfo?.startDate?.toLocaleString("en-US", options)}</h3>
            </div>

            <div className="col-md-12 border_rad_new px-2 pb-2 mt-2 center-cols">
              <label className="label_text_business">Schedule Date</label>
              <input
                className="form-control1 input_style_fix provider-popup-dataview-field"
                type="text"
                id="date"
                label="date"
                name="date"
                value={slotInfo?.startDate?.toLocaleString("en-US", options)}
                disabled
              />
            </div>
            <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 center-cols">
              <h5 className="text-black pt-3 mb-0">
                {slotInfo?.startDate?.toLocaleString("en-US", dayOption)}
              </h5>
              <MultiScheduleTimes
                slot={slotInfo}
                setScheduleItems={setScheduleItems}
                scheduleItems={scheduleItems}
                businessLocations={businessLocations}
              />
              {/* <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 mt-2 pt-4 selected-business">
                    <FormControl
                      sx={{ maxWidth: "180px" }}
                      size="small"
                      // disabled={!slot.active}
                    >
                      {isEdit ? (
                        <p className="mt-3">{business.name}</p>
                      ) : (
                        <Select
                          defaultValue={
                            businessLocations && businessLocations[0]?.id
                          }
                          startAdornment={
                            <img
                              src={MapPin}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                              }}
                            />
                          }
                          sx={{
                            color: "#5599FF",
                            height: "37px",
                            "& fieldset": {
                              border: "none",
                            },
                            ".MuiSvgIcon-root ": {
                              color: "#5599FF",
                            },
                          }}
                          value={business}
                          onChange={(e) => {
                            setBusiness(e.target.value);
                          }}
                          label=""
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem disabled value="">
                            <em>Select Business</em>
                          </MenuItem>
                          {businessLocations?.map((loc) => (
                            <MenuItem
                              selected={
                                business.name === loc.name ? true : false
                              }
                              key={loc.id}
                              value={loc}
                            >
                              {loc.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                    <p className="form-error" style={{ marginLeft: "16px" }}>
                      {/* {locationTouched &&
                locationTouched[index] &&
                locationErrors &&
                locationErrors[index]
                  ? locationErrors[index]
                  : null} */}
              {/* </p> */}
              {/* </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="col-md-3  mt-2 pt-2">
                      <Typography
                        sx={{
                          color: "#A3A3A3",
                          fontSize: "12px",
                          fontWeight: 400,
                          mb: 0.5,
                        }}
                      >
                        From
                      </Typography>
                      <div className="d-flex">
                        <TimePicker
                          id={`businessStartTime`}
                          name={`businessStartTime`}
                          format="HH:mm:ss"
                          value={slotInfo?.timeFrom}
                          onChange={(newValue) => {
                            setStartTime(newValue);
                          }}
                          className="format"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mt-2 pt-2">
                      <Typography
                        sx={{
                          color: "#A3A3A3",
                          fontSize: "12px",
                          fontWeight: 400,
                          mb: 0.5,
                        }}
                      >
                        To
                      </Typography>
                      <div className="d-flex">
                        <TimePicker
                          disableIgnoringDatePartForTimeValidation
                          id={`businessEndTime`}
                          name={`businessEndTime`}
                          format="HH:mm:ss"
                          value={slotInfo?.timeTo}
                          onChange={(newValue) => {
                            setEndTime(newValue);
                          }}
                          className="format"
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  <div className="col-md-3 mt-2 pt-2">
                   
                  </div> */}
              {/* </div> */}
              {/* </div>  */}
            </div>

            <div className="col-md-12 d-flex justify-content-end align-items-center py-3 mt-5 center-cols">
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <button
                  className="cancel-creating-schedule"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                {isEdit ? (
                  <button
                    className="edit-creating-schedule"
                    onClick={() => AddProviderScedule(true)}
                  >
                    Remove
                  </button>
                ) : (
                  <button
                    className="save-creating-schedule "
                    onClick={() => AddProviderScedule(false)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ClipLoader color="#548DFF" />
          </div>
        ) : (
          <div
            className="w-100 rounded p-lg-0 p-sm-0 p-0"
            style={{ height: "95vh !important" }}
          >
            <div
              // ref={calendarWrapperRef}
              className="col-md-12 overflow_calender height_for_calendar"
            >
              <DnDCalendar
                ref={calendarRef}
                // defaultDate={startDate}
                defaultView={"month"}
                events={customEvents}
                localizer={localizer}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                // onEventDrop={moveEvent}
                // onEventResize={this.resizeEvent}
                resources={resourcesMap}
                resizable={false}
                dayLayoutAlgorithm="overlap"
                style={{
                  backgroundColor: "white",
                }}
                culture="en"
                // onSelectEvent={(event) => calenderEvents(event)}
                draggableAccessor={(event) =>
                  event.title === "Unavailable" ? false : true
                }
                onSelectSlot={(slotInfo) => handleSlots(slotInfo)}
                onDragOver={(event) => console.table(event)}
                className="flex flex-1 w-100 padding_of_calender"
                startAccessor={(event) => new Date(event.start)}
                endAccessor={(event) => new Date(event.end)}
                // min={new Date(0, 0, 0, startTime?.hour, startTime?.min, 0)}
                // max={new Date(0, 0, 0, endTime?.hour, endTime?.min, 0)}
                components={{
                  // resourceHeader: (props) => {
                  //   return (
                  //     props?.resource?.length !== 0 && (
                  //       <ProviderWidget val={props.resource} />
                  //     )
                  //   );
                  // },
                  toolbar: (toolbar) => {
                    return (
                      <CalenderHeader
                        // handleFilter={handleFilter}
                        toolbar={toolbar}
                        providerId={providerId.providerId}
                        action={"providerCaleder"}
                        eventData={""}
                        navigate={history}
                        date={date}
                        appointmentData={appointmentData}
                        setDate={setDate}
                        setLoading={setLoading}
                      />
                    );
                  },
                  // event: (event) => {
                  //   return (
                  //     resourcesMap.length !== 0 && (
                  //       <CustomEventWidget event={event} />
                  //     )
                  //   );
                  // },
                }}
                // eventPropGetter={(myEventsList) => {
                //   const backgroundColor =
                //     myEventsList.colorCode === "#173371"
                //       ? "#548DFF"
                //       : myEventsList.colorEvento;
                //   const color = myEventsList.color
                //     ? myEventsList.color
                //     : "blue";
                //   const border = "none";
                //   const cursor = "pointer";
                //   const display = "flex";
                //   const width = "100%";
                //   const height = "99.70%";
                //   return {
                //     style: {
                //       backgroundColor,
                //       color,
                //       border,
                //       cursor,
                //       display,
                //       width,
                //       height,
                //     },
                //   };
                // }}
                eventPropGetter={customEventStyleGetter}
                slotPropGetter={(event) => {
                  if (event) {
                    return {
                      className: "special-day",
                    };
                  } else return {};
                }}
                // onNavigate={handleNavigate}
                // onNavigate={(date, view, action) => {
                //   if (view === "month" && action === "NEXT") {
                //     dispatch(nextdate());
                //   } else if (view === "month" && action === "PREV") {
                //     dispatch(prevdate());
                //   } else if (view === "month" && action === "TODAY") {
                //     dispatch(resetdate());
                //   }
                // }}
                selectable
                // backgroundEvents={allbgevent}
                views={allViews}
                step={15}
                showMultiDayTimes={true}
                date={date}
                popup
              />
            </div>
          </div>
        )}
        {/* )} */}
      </div>
    </>
  );
};

export default ProviderCalender;
