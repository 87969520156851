import * as Yup from "yup";

const departmentManger = [
  {
    id: "departmentId",
    label: " DepartmentID",
    sort: true,
    minWidth: 100,
    color: "#7b7d84",
    align: "center",
    border: "1px solid #ededed",
    borderTop: "4px soild #548dff",
  },
  {
    id: "deparmentName",
    label: "Department Name",
    // class: "text-left  upper-case table-header-item font-size-xs",
    minWidth: 200,
    align: "center",
    color: "#7b7d84",
    border: "1px solid #ededed",
  },
  {
    id: "teamNo",
    label: "Number of Teams",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    border: "1px solid #ededed",
    minWidth: 170,
  },
  {
    id: "active",
    label: "Active",
    // class: "text-left  upper-case table-header-item font-size-xs",
    border: "1px solid #ededed",
    color: "#7b7d84",
    align: "center",

    minWidth: 170,
  },
  {
    id: "actions",
    label: "Actions",
    // class: "text-center  upper-case table-header-item font-size-xs",
    border: "1px solid #ededed",
    color: "#7b7d84",
    align: "center",

    minWidth: 170,
  },
];

export const OnSearch = (searchedVal, data, setRowData) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      if (!match) {
        match = row.name
          ? row.name.toLowerCase().includes(searchedVal.toLowerCase())
          : "";
      }
      // if (!match) {
      //   match = row.numTeams
      //     ? row.numTeams.toLowerCase().includes(searchedVal.toLowerCase())
      //     : "";
      // }

      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData(data);
  }
};

export const departmentInitialValue = (action, editData) => {
  const initialValues = {
    name: action === "edit" ? editData.name : "",
    description: action === "edit" ? editData.departmentDescription : "",
  };
  return initialValues;
};
export const validationSchema = Yup.object({
  name: Yup.string().required("Enter the Department Name"),
  description: Yup.string().required("Description is required"),
});
export const formateData = (values, id,userID) => {
  return {
    name: values.name,
    user: userID,
    departmentDescription: values.description,
    // depType: null,
    numTeams: 5,
    business: id,
  };
};

export { departmentManger };
