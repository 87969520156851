import React, { useState } from "react";
import {BsThreeDots} from "react-icons/bs";
import "./style.css";
import Img from '../../assets/scheduler/assests/Mask Group 950@2x.png'
import Img1 from '../../assets/scheduler/assests/Mask Group 949@2x.png'
import Img2 from '../../assets/scheduler/assests/Mask Group 948@2x.png'
import Img3 from '../../assets/scheduler/assests/Mask Group 948@2x.png'
import Img4 from '../../assets/scheduler/assests/Mask Group 950@2x.png'
import Img5 from '../../assets/scheduler/assests/Mask Group 950@2x.png'
import Img6 from '../../assets/scheduler/assests/Mask Group 950@2x.png'
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { openToast } from "../store/alertSlice";
import NotesModel from './NotesModel';



const FooterSection = () => {

  return (
    // <Container className="mb-5">
    //   <Grid item xl={3} lg={3} md={6} sm={12} className="p-2 min-h-[250px]">
    <>
    <div className="d-flex flex-wrap justify-content-between pe-0">
    <div className="d-flex ps-1 flex-wrap justify-content">
        <h1 className="book  ms-2  pt-1"> Action </h1>
      </div>
      <div className="d-flex flex-wrap me-2">
      <BsThreeDots/>
      </div>
    </div>
    <div className="d-flex justify-content-around ">
      <div className="d-flex flex-wrap ps-5 pe-3">
        <div className="pe-3">
          <div
            className="card px-3 py-2  min-h-[250px] hover pt-2  border-1 p-3"
            style={{
              width: "104px",
              height: "70px",
              borderRadius: "10px",
              padding: "30px",
              background:"#fbfcff"
            }}
          > 
            <div className="d-flex flex-wrap justify-content-center flex-column" 
            // onClick={e=>{bookingData?.data?.isCompleted===true ? handleUpdateAlert() :handleUpdateBooking()}}
            >
            <div className=" ms-3  pt-1 pe-2" >
              <img   src={Img3} style={{ color: "#538dff",height:"30px", width:"30px" }} />
              </div>
              <span className="spanF ms-2  pt-1 pe-3">Modify</span>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    </>
    // </Container>
  );
};

export default FooterSection;
