import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url, services_base_url } from "../base_url";
import instance from "../../../utils/utils";

export const getAllServices = createAsyncThunk(
  "all-services",
  async (setLoading) => {
    setLoading(true);
    const response = await instance.get(`${base_url}/Service/GetAllServices`);
    setLoading(false);

    return response?.data?.result?.services;
  }
);

export const getServiceToUpdate = createAsyncThunk(
  "service-to-update",
  async (id) => {
    const response = await instance.get(
      `${base_url}/Service/GetServiceById?id=${id}`
    );
    return response?.data?.result?.service;
  }
);

export const getServiceAttributesExpert = createAsyncThunk(
  "service-attribute-expert",
  async ({ id, setIsLoading }) => {
    setIsLoading(true);
    const response = await instance.get(
      `${services_base_url}/ServiceAttribute/GetServiceAttributeByServiceSku?serviceSku=${id}&countryId=188`
    );
    setIsLoading(false);

    return response?.data?.result?.serviceAttribute;
  }
);
export const getServiceToUpdateExpert = createAsyncThunk(
  "service-to-update-expert",
  async (id) => {
    const response = await instance.get(
      `${services_base_url}/Service/GetServiceById?id=${id}`
    );
    return response?.data?.result?.service;
  }
);

export const getServicePrices = createAsyncThunk(
  "get-servics-prices",
  async (id) => {
    const response = await instance.get(
      `${base_url}/ServicePrice/GetServicePriceByServiceId?Id=${id}`
    );
    return response?.data?.result?.servicePrice;
  }
);

export const getAllUnitTypes = createAsyncThunk("get-unit-types", async () => {
  const response = await instance.get(`${base_url}/UnitType/GetUnitTypes`);
  return response?.data?.result?.unitTypes;
});

export const getAllCurrencies = createAsyncThunk(
  "get-all-currencies",
  async () => {
    const response = await instance.get(`${base_url}/Currencies/getAllCurrencies`);
    return response?.data?.result?.currencies;
  }
);

export const getPriceVariables = createAsyncThunk(
  "get-price-variables",
  async (priceId) => {
    const response = await instance.get(
      `${base_url}/ServicePrice/GetServiceVarPriceByPriceId?Id=${priceId}`
    );
    return response?.data?.result?.servicevariablePrices;
  }
);

export const getServiceCosts = createAsyncThunk("service-costs", async (id) => {
  const response = await instance.get(
    `${base_url}/Cost/getCostByServicesId?id=${id}`
  );
  return response?.data?.result?.serviceCost;
});

export const getAllCostTypes = createAsyncThunk("all-cost-types", async () => {
  const response = await instance.get(`${base_url}/CostType/getAllCostTypes`);
  return response?.data?.result?.costTypes;
});

export const getServiceAttributes = createAsyncThunk(
  "service-attributes",
  async (id) => {
    const response = await instance.get(
      `${base_url}/ServiceAttribute/GetServiceAttributeByServiceId?Id=${id}`
    );
    return response?.data?.result?.serviceAttribute;
  }
);

export const getAllAttributeTypes = createAsyncThunk(
  "all-attributes-types",
  async () => {
    const response = await instance.get(
      `${base_url}/ServiceAttribute/GetAllAttributeType`
    );
    return response?.data?.result?.attributeTypes;
  }
);

export const getServiceGroups = createAsyncThunk(
  "service-groups",
  async (id) => {
    const response = await instance.get(
      `${base_url}/Package/GetGroupByServiceId?Id=${id}`
    );
    return response?.data?.result;
  }
);

export const getAllGroups = createAsyncThunk("all-groups", async () => {
  const response = await instance.get(`${base_url}/Package/GetAllGroups`);
  return response?.data?.result?.groups;
});

export const getGroupDetails = createAsyncThunk(
  "groups-details",
  async (id) => {
    const response = await instance.get(
      `${base_url}/Package/GetGroupById?Id=${id}`
    );
    return response?.data?.result?.group;
  }
);

export const getPackageDetails = createAsyncThunk(
  "package-details",
  async (id) => {
    const response = await instance.get(
      `${base_url}/Package/GetPackageById?Id=${id}`
    );
    return response?.data?.result;
  }
);

export const getBusinessServices = createAsyncThunk(
  "get-business-services",
  async ({ businessId, source, setLoaderBusiness }) => {
    setLoaderBusiness(true);

    const baseUrl =
      source === 1
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_BASE_URL_EXPERT;

    const url = `${baseUrl}/serviceinventory_svc/pb/Service/GetAllServicesByBusinessId?businessId=${businessId}`;
    const response = await instance.get(url);
    setLoaderBusiness(false);

    return response?.data?.result?.services;
  }
);

export const getGetServices = createAsyncThunk(
  "get-platform-services",
  async ({ source, setLoader }) => {
    var url = "";
    if (source === 1) {
      url = `${base_url}/Service/GetAllServices`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/Service/GetAllBookableServices`;
    }
    setLoader(true);
    const response = await instance.get(url);
    setLoader(false);
    return response?.data?.result?.services;
  }
);

export const getBusinessStaff = createAsyncThunk(
  "get-business-staff",
  async (id) => {
    const response = await instance.get(
      `/serviceprovider_svc/pv/providersbusiness/getProviderBusinessByBussinessId?id=${id}`
    );
    return response?.data?.result?.providerBusiness;
  }
);
