import { lazy } from "react";
import fakeDelay from "../../Shared/HelperMethods/FakeDelay";
import '../../Assets/components/documents/docDashboard.scss';

const delayTime = 2000;

export const documentRoutes = [
  // {
  //   path: "/document-dashboard",
  //   component: lazy(() =>
  //     fakeDelay(delayTime)(
  //       import("./doc-dashboard")
  //     )
  //   ),
  //   ispublic: false,
  //   exact: true,
  // },

  // {
  //   path: "/:fname/:fid",
  //   component: lazy(() =>
  //     fakeDelay(delayTime)(
  //       import("./all-documents")
  //     )
  //   ),
  //   ispublic: false,
  //   exact: true,
  // },

  // {
  //   path: "/share-documents",
  //   component: lazy(() =>
  //     fakeDelay(delayTime)(
  //       import("./share-document")
  //     )
  //   ),
  //   ispublic: false,
  //   exact: true,
  // },
]


