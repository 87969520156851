import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Step, StepLabel, Stepper } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { connect, useSelector, useDispatch } from "react-redux";

import {
  DROPDOWN_QUESTION,
  MCQ_QUESTION,
} from "../../Constants/DynamicFormConstants";
import { renderQuestion } from "./RenderQuestions";
import {
  getDynamicForms,
  submitDynamicForms,
} from "../../Store/DynamicForms/redux/actions/dynamicForms.actions";
import { getStorage } from "../../utils/storage";
import "../../Calendar/assets/scheduler/css/main.css";
import SideNavone from "../../SideNavBar";
import { ClipLoader } from "react-spinners";

const DynamicFormView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formId = 55 } = useParams();
  const [formData, setFormData] = useState({});
  const [renderForm, setRenderForm] = useState(false);
  const [sections, setSections] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  let form = useSelector((state) => state.DynamicFormReducer.form);
  let consentFormId = useSelector(
    (state) => state.DynamicFormReducer.consentFormId
  );
  let sectionHeader = useSelector(
    (state) => state.DynamicFormReducer.sectionHeader
  );

  let totalSections = useSelector(
    (state) => state.DynamicFormReducer.totalSections
  );
  let loading = useSelector((state) => state.DynamicFormReducer.loading);
  let bookingData = useSelector((state) => state.bookingReducer.bookingData);
  const selectedBusiness = useSelector(
    (state) => state.Business.selectedBusiness
  );
  const handleNext = () => {
    setActiveStepIndex((prevStep) => prevStep + 1);
  };

  const handleChange = (questionId, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [questionId]: value,
    }));
  };

  useEffect(() => {
    setRenderForm(true);
    setSections(totalSections); // Storing all the sections in a state
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (activeStepIndex === 0) {
      props.getDynamicForms("", bookingData.customerId, bookingData.serviceSKU);
    } else if (activeStepIndex === totalSections.length) {
      history.push(`/scheduler/booking_details/${selectedBusiness.id}`);
    } else {
      const data = {
        section: sections[activeStepIndex]?.position,
      };

      props.getDynamicForms(
        data,
        bookingData.customerId,
        bookingData.serviceSKU
      );
    }
    // eslint-disable-next-line
  }, [getDynamicForms, activeStepIndex]);

  const renderChildQuestions = (childQuestions) => {
    return childQuestions.map((childQuestion) =>
      renderQuestion(
        childQuestion,
        handleChange,
        renderChildQuestions,
        formData
      )
    );
  };

  const handleSubmit = (
    e,
    currentQuestions,
    answers = [],
    plexarBookingId,
    formId,
    customerId,
    plexaarSalesOrderId,
    sectionId = ""
  ) => {
    e.preventDefault();

    // Prepare JSON structure for submission
    currentQuestions?.forEach((field) => {
      if (field.question) {
        var { question } = field;
      } else {
        // eslint-disable-next-line
        var question = field;
      }

      const answer = {
        questionId: question?.id,
        questionType: question?.contentObject?.questionType, // Extract questionType from contentObject
        answer: {
          name: formData[question?.id],
        },
      };
      answers.push(answer);

      if (
        question?.contentObject?.questionType === MCQ_QUESTION ||
        (question?.contentObject?.questionType === DROPDOWN_QUESTION &&
          formData[question.id] &&
          question.contentObject.options.find((opt) => opt.haschild))
      ) {
        const childQuestions = question.contentObject.options
          .find((opt) => opt.choiceText === formData[question.id])
          ?.childs?.map((child) => child.question);

        handleSubmit(
          e,
          childQuestions,
          answers,
          plexarBookingId,
          consentFormId,
          customerId,
          plexaarSalesOrderId,
          sectionId
        );
      }
    });

    return {
      answers,
      formId,
      customerId,
      sectionId,
      plexaarSalesOrderId,
      plexarBookingId,
    };
  };

  const submitSection = async (e) => {
    let plexarBookingId = bookingData.plexaarBookingId;
    let plexaarSalesOrderId = bookingData.plexaarSalesOrderId;
    let customerId = bookingData.customerId;
    props.submitDynamicForms(
      handleSubmit(
        e,
        form?.questions,
        [],
        plexarBookingId,
        consentFormId,
        customerId,
        plexaarSalesOrderId,
        sections[activeStepIndex]?.id.toString()
      )
    );

    // Once the operation is complete, move to the next step
    setTimeout(() => handleNext(), 5000);
  };

  return (
    <>
      <SideNavone>
        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ClipLoader color="#548DFF" />
          </div>
        ) : (
          <section className="dynamic-forms">
            <div className="dynamic-forms_stepper">
              <Stepper
                activeStep={activeStepIndex}
                alternativeLabel
                complete={false}
              >
                {sections?.map((section, index) => (
                  <Step
                    key={section.position}
                    completed={false}
                    className="dynamic-forms_step"
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: yellow.A100, // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: yellow.A100, // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: yellow.A700, // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "common.white", // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel>
                      <button
                        //onClick={() => setActiveStepIndex(section.position - 1)}
                        className="dynamic-forms_stepper-button"
                      >
                        {section.sectionTitle}
                      </button>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="dynamic-forms_container">
              <header>
                <p>{sectionHeader}</p>
              </header>
              <form onSubmit={(e) => submitSection(e)}>
                {renderForm && (
                  <>
                    {form?.questions?.map((question) =>
                      renderQuestion(
                        question,
                        handleChange,
                        renderChildQuestions,
                        formData
                      )
                    )}

                    <button className="submit-btn" type="submit">
                      Continue
                    </button>
                  </>
                )}
              </form>
            </div>
          </section>
        )}
      </SideNavone>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   form: state.dynamicForm.form,
//   sectionHeader: state.dynamicForm.sectionHeader,
//   dynamicFormSubmitted: state.dynamicForm.dynamicFormSubmitted,
//   totalSections: state.dynamicForm.totalSections,
//   loading: state.dynamicForm.loading,
// });

const mapDispatchToProps = (dispatch) => {
  return {
    getDynamicForms: (abc, ab, serviceSKU) => {
      dispatch(getDynamicForms(abc, ab, serviceSKU));
    },

    submitDynamicForms: (data) => {
      dispatch(submitDynamicForms(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(DynamicFormView);
