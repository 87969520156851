import dayjs from "dayjs";
import { customSnackBar } from "../../../utils";

const DATE = "YYYY-MM-DD";
const TIME = "HH:mm";
const TIMES = "HH:mm:ss";
export const FormateDate = (str) => {
  return str ? dayjs(str).format(DATE) : "";
};
export const FormateTimes = (str) => {
  return str ? dayjs(str).format(TIMES) : "";
};

export const FormateTime = (str) => {
  return str ? dayjs(str).format(TIME) : "";
};

export const success = (value, option = {}) => customSnackBar(value);
export const error = (value, option = {}) => customSnackBar(value);
