import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideNavone from "../../../../../SideNavBar";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getRequest, postRequest } from "../../../../../Componenets/request";
import { useHistory, useParams } from "react-router-dom";
import { getAllStaff } from "../../store/staffActions";

const ColumnList = () => {
  const [showColumnList, setShowColumnList] = useState([]);
  const [draggedItems, setDraggedItems] = useState([]);
  const [columnNames, setColumnNames] = useState([]);

  const store = useSelector((state) => state?.staffReducer);
  const userid = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir?.id
  );
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const getColumnNames = async () => {
    const res = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/customer_svc/pv/ListingPreferences/getAllListingPreferenceByUserId?id=${userid}&businessId=${selectedBusiness.id}`
    );
    setColumnNames(res?.result?.columnNames);
  };

  useEffect(() => {
    if (draggedItems.length === 0) {
      getColumnNames();
    }
  }, [draggedItems]);

  useEffect(() => {
    if (store?.allCustomer.length > 0 && draggedItems.length === 0) {
      const filtered = store?.allCustomer.filter((elem) => elem?.isShown === true);
      const sorted = filtered.sort((a, b) => a?.position - b?.position);
      const new_modal = [];
      const show_col_list = [];
      for (let i = 0; i < sorted.length; i++) {
        const objj = {
          id: `${i}`,
          columnId: sorted[i]?.id,
          position: parseInt(i + 1),
          columnName: sorted[i]?.columnName,
          serviceName: "plexaar customer",
          isSticky: sorted[i]?.isSticky,
          isShown: true,
        };
        new_modal.push(objj);
        setDraggedItems([...new_modal]);

        if (showColumnList.includes(sorted[i]?.id)) {
          const filtered1 = showColumnList?.filter(
            (el) => el !== sorted[i]?.id
          );
          setShowColumnList([...filtered1]);
        } else {
          setShowColumnList([...showColumnList]);
        }

        show_col_list.push(sorted[i]?.id);
        setShowColumnList([...show_col_list, sorted[i]?.id]);
      }
    }
  }, [store?.allCustomer]);

  const handleColumnButtonClick = (i) => {
    if (showColumnList.includes(i)) {
      const updatedList1 = showColumnList.filter((item) => item !== i);
      setShowColumnList(updatedList1);
    } else {
      setShowColumnList([...showColumnList, i]);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = Array.from(draggedItems);
    const [movedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, movedItem);
    setDraggedItems(updatedItems);
  };

  const saveChanges = async () => {
    const modal = [];
    draggedItems.map((item, index) => {
      modal.push({
        columnId: item?.columnId,
        isShown: item?.isShown,
        isSticky: item?.isSticky,
      });
    });

    const classes = draggedItems.map((i)=>{
      if(i.isShown === true){
        return {
          columnId: i.columnId,isSticky: i.isSticky,
        };
      }
      return null;
    }).filter((it)=>it !== null);

    const payload = {
      userId: userid,
      columns: classes,
      createdBy: userid,
    };


    const res = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/customer_svc/pv/ListingPreferences/addUserListingPreference`,
      payload
    );
    if (res?.message === "success") {
      alert("Columns list updated successfully");
      history.push(`/all-customer`);
    } else {
      alert("Fail");
    }
    // setDraggedItems(updatedDraggedItems);
  };
  const uniqueDiv = document.getElementById("uniqueDiv");

  return (
    <SideNavone>
      <div className="conainer list_col_container">
        <h3 className="ps-4 mt-5">Added Columns</h3>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div className="px-4 d-flex position-relative scrollable_div">
                <button
                  className="btn btn-warning h-100"
                  style={{ position: "absolute", left: "24px" }}
                  onClick={() => {
                    // Replace 'uniqueDiv' with the actual ID or reference to your scrollable div
                    const uniqueDiv = document.getElementById("uniqueDiv");

                    uniqueDiv.scrollTo({
                      left: uniqueDiv.scrollLeft - 100, // Subtracting 100 pixels to scroll left
                      behavior: "smooth",
                    });
                  }}
                >
                  <ArrowBackIosNewIcon sx={{ color: "white" }} />
                </button>

                <ul
                  id="uniqueDiv"
                  className="d-flex m-0 scrollable_col_list px-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {draggedItems &&
                    draggedItems.map((item, index) => (
                      <Draggable
                        key={item?.id}
                        draggableId={item?.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="bg-secondary text-light rounded py-2 px-4 me-1"
                            style={{
                              height: "50px",
                              width: "120px",
                              backgroundColor: "green",
                              margin: "0 10px", // Adjust the spacing between items
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              borderRadius: "5px",
                            }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {item.columnName}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
                <button
                  className="btn btn-warning h-100"
                  style={{ position: "absolute", right: "24px" }}
                  onClick={() => {
                    uniqueDiv.scrollTo({
                      left: uniqueDiv.scrollLeft + 100, // Adding 100 pixels to scroll right
                      behavior: "smooth",
                    });
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </button>

                <hr className="my-3" />
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="row mt-4 px-4">
          <div className="col-sm-6 col-12 d-flex justify-content-sm-start justify-content-center g-0">
            <h3 className="">Columns List</h3>
          </div>
          <div className="col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3">
            <button
              type="submit"
              className="add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1"
              onClick={saveChanges}
            >
              <AddBoxIcon className="addbox_icon me-1" />
              Save Changess
            </button>
          </div>
          <hr className="my-3" />
        </div>

        <div className="w-100 d-flex flex-wrap justify-content-center">
          {columnNames?.length > 0 &&
            columnNames?.map((v, i) => {
              const bgColor = showColumnList.includes(v?.id) ? "#ffc720" : "#fff";  

              return (
                <div key={v?.id} className="col_btn_div pe-3 mt-3">
                  <button
                    className={`btn h-100 w-100 pe-3 ${
                      showColumnList.includes(v?.id) ? "selectedColumn" : ""
                    }`}
                    style={{backgroundColor:`${bgColor}`}}

                    onClick={() => {
                      handleColumnButtonClick(v?.id);
                      if (draggedItems.find((val) => val?.columnId == v?.id)) {
                        const filtered = draggedItems.filter(
                          (el) => el?.columnId != v?.id
                        );
                        setDraggedItems(filtered);
                      } else {
                        setDraggedItems([
                          ...draggedItems,
                          {
                            id: `${i}`,
                            columnId: v?.id,
                            position: parseInt(i + 1),
                            columnName: v?.columnName,
                            serviceName: "plexaar staff",
                            isSticky: v?.isSticky,
                            isShown: true,
                          },
                        ]);
                      }
                    }}
                  >
                    {v?.columnName}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </SideNavone>
  );
};

export default ColumnList;

// import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import SideNavone from '../../../../../SideNavBar';

// const ColumnList = () => {
//     const [columns, setColumns] = useState([]);
//     const [showColumnList, setShowColumnList] = useState([]);
//     const [draggedItems, setDraggedItems] = useState([]);

// const data = useSelector((state) => state.Business?.data);
// const store = useSelector(state => state?.staffReducer);
// const userid = useSelector(state => state?.Auth?.userloginsuccessyasir?.users?.id);

//     // useEffect(() => {
//     //     if (data) {
//     //         const columns = Object.keys(data[0])
//     //         setColumns(columns);
//     //     }
//     // }, [data]);

//     useEffect(() => {
//         if (store?.allCustomer.length > 0) {
//             const heading_row = [];
//             store?.allCustomer.map((h, i) => {
//                 heading_row.push(store?.allCustomer[i]?.columnName);
//             });
//             setColumns([...heading_row]);
//         }
//     }, [store?.allCustomer]);

//     const handleColumnButtonClick = (v) => {
//         if (showColumnList.includes(v?.columnName)) {
//             const updatedList = showColumnList.filter(item => item !== v?.columnName);
//             setShowColumnList(updatedList);
//         } else {
//             setShowColumnList([...showColumnList, v?.columnName]);
//         }
//     };

//     const handleDragEnd = (result) => {
//         if (!result.destination) {
//             return;
//         }
//         const updatedItems = Array.from(draggedItems);
//         const [movedItem] = updatedItems.splice(result.source.index, 1);
//         updatedItems.splice(result.destination.index, 0, movedItem);
//         setDraggedItems(updatedItems);
//     };

//     const saveChanges = () => {
//         const updatedDraggedItems = draggedItems.map((item, index) => ({
//             ...item,
//             position: (index + 1).toString(),
//         }));
//         'dataset', updatedDraggedItems);
//         setDraggedItems(updatedDraggedItems);
//     }

//     const uniqueDiv = document.getElementById('uniqueDiv');

//     return (
//         <SideNavone>
//             <div className='conainer list_col_container'>
//                 <h3 className='ps-4 mt-5'>Added Columns</h3>
//                 <DragDropContext onDragEnd={handleDragEnd}>
//                     <Droppable droppableId="droppable" direction="horizontal">
//                         {(provided) => (
//                             <div className='px-4 d-flex position-relative scrollable_div'>
//                                 <button
//                                     className='btn btn-warning'
//                                     style={{ position: 'absolute', left: '24px' }}
//                                     onClick={() => {
//                                         // Replace 'uniqueDiv' with the actual ID or reference to your scrollable div
//                                         const uniqueDiv = document.getElementById('uniqueDiv');

//                                         uniqueDiv.scrollTo({
//                                             left: uniqueDiv.scrollLeft - 100, // Subtracting 100 pixels to scroll left
//                                             behavior: 'smooth',
//                                         });
//                                     }}
//                                 >
//                                     <ArrowBackIosNewIcon sx={{ color: 'white' }} />
//                                 </button>

//                                 <ul
//                                     id='uniqueDiv'
//                                     className='d-flex m-0 scrollable_col_list px-4'
//                                     {...provided.droppableProps}
//                                     ref={provided.innerRef}
//                                 >
//                                     {draggedItems.map((item, index) => (
//                                         <Draggable key={item?.id} draggableId={item?.id} index={index}
//                                         >
//                                             {(provided) => (
//                                                 <div
//                                                     className='bg-secondary text-light rounded py-2 px-4 me-1'
//                                                     style={{
//                                                         height: '50px',
//                                                         width: '120px',
//                                                         backgroundColor: 'green',
//                                                         margin: '0 10px', // Adjust the spacing between items
//                                                         display: 'flex',
//                                                         alignItems: 'center',
//                                                         justifyContent: 'center',
//                                                         color: 'white',
//                                                         borderRadius: '5px'
//                                                     }}
//                                                     {...provided.draggableProps}
//                                                     {...provided.dragHandleProps}
//                                                     ref={provided.innerRef}
//                                                 >
//                                                     {item.content}
//                                                 </div>
//                                             )}
//                                         </Draggable>
//                                     ))}
//                                     {provided.placeholder}
//                                 </ul>
//                                 <button
//                                     className='btn btn-warning'
//                                     style={{ position: 'absolute', right: '24px' }}
//                                     onClick={() => {
//                                         uniqueDiv.scrollTo({
//                                             left: uniqueDiv.scrollLeft + 100, // Adding 100 pixels to scroll right
//                                             behavior: 'smooth',
//                                         });
//                                     }}
//                                 >
//                                     <ArrowForwardIosIcon sx={{ color: 'white' }} />
//                                 </button>

//                                 <hr className='my-3' />
//                             </div>
//                         )}
//                     </Droppable>
//                 </DragDropContext>

//                 <div className='row mt-4 px-4'>
//                     <div className='col-sm-6 col-12 d-flex justify-content-sm-start justify-content-center g-0'>
//                         <h3 className=''>Columns List</h3>
//                     </div>
//                     <div className='col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3'>
//                         <button type='submit' className='add_service_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1'
//                             onClick={saveChanges}
//                         ><AddBoxIcon className='addbox_icon me-1'
//                             />Save Changes</button>
//                     </div>
//                     <hr className='my-3' />
//                 </div>

//                 <div className="w-100 d-flex flex-wrap justify-content-center">
//                     {store?.allCustomer.length > 0 && store?.allCustomer.map((v, i) => {
//                         return (
//                             <div key={v?.columnName} className='col_btn_div pe-3 mt-3'>
//                                 <button
//                                     className={`btn h-100 w-100 pe-3 ${showColumnList.includes(v?.columnName) ? 'selectedColumn' : ''}`}
//                                     onClick={() => {
//                                         handleColumnButtonClick(v);
//                                         if (draggedItems.find(val => val?.id == `${i}`)) {
//                                             const filtered = draggedItems.filter(el => el?.id != `${i}`);
//                                             setDraggedItems(filtered);
//                                         }
//                                         else {
//                                             setDraggedItems([...draggedItems, { userId: userid, columnId: `${i}`, serviceName: 'plexaar staff', isSticky: v?.isSticky, isShown: v?.isShown, position: i + 1, createdBy: v?.createdBy, columnName: v?.columnName }]);
//                                         }
//                                     }}
//                                 >
//                                     {v?.columnName}
//                                 </button>
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//         </SideNavone>
//     )
// }

// export default ColumnList
