// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hero_mobileHeroContainerBox__QM8lw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Hero_heroContainer__OU67C {
  width: 100%;
  background: linear-gradient(258.67deg, #f3f3f3 24.47%, #ffffff 84.71%);
  padding: 30px 0;
}
.Hero_heroTitle__aN7Aq {
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #313131;
  font-family: Roboto;
  margin: 0;
}

.Hero_heroSlogan__98Ftw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 55px;
}

.Hero_heroSlogan__98Ftw p {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #8d8d8d;
  font-family: Roboto;
}
.Hero_roundDivider__jLP4Y {
  width: 7px;
  height: 7px;
  background: #d9d9d9;
  border-radius: 50%;
  margin: 0 15px;
  margin-top: -12px;
}
.Hero_loaderCenter__ClHLo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: -35px;
}

.Hero_mobileHeroImage__mfE5K {
  width: 90%;
  margin-top: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/MobileHomePage/Hero/Hero.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,sEAAsE;EACtE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".mobileHeroContainerBox {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.heroContainer {\n  width: 100%;\n  background: linear-gradient(258.67deg, #f3f3f3 24.47%, #ffffff 84.71%);\n  padding: 30px 0;\n}\n.heroTitle {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 1.2rem;\n  text-align: center;\n  color: #313131;\n  font-family: Roboto;\n  margin: 0;\n}\n\n.heroSlogan {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 7px;\n  margin-bottom: 55px;\n}\n\n.heroSlogan p {\n  /* font-family: \"Roboto\"; */\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  color: #8d8d8d;\n  font-family: Roboto;\n}\n.roundDivider {\n  width: 7px;\n  height: 7px;\n  background: #d9d9d9;\n  border-radius: 50%;\n  margin: 0 15px;\n  margin-top: -12px;\n}\n.loaderCenter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 30px;\n  margin-top: -35px;\n}\n\n.mobileHeroImage {\n  width: 90%;\n  margin-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileHeroContainerBox": `Hero_mobileHeroContainerBox__QM8lw`,
	"heroContainer": `Hero_heroContainer__OU67C`,
	"heroTitle": `Hero_heroTitle__aN7Aq`,
	"heroSlogan": `Hero_heroSlogan__98Ftw`,
	"roundDivider": `Hero_roundDivider__jLP4Y`,
	"loaderCenter": `Hero_loaderCenter__ClHLo`,
	"mobileHeroImage": `Hero_mobileHeroImage__mfE5K`
};
export default ___CSS_LOADER_EXPORT___;
