import React from "react";
import "./NextGen.css";
import { laptop } from "../../Assets";
import { NextGenFeatures } from "./../Reusable/NextGenFeatures";

export const NextGen = () => {
  return (
    <div className="nextgenContainer">
      <p className="nextgenHeadingText">
        Experience the <span>Next Gen of ERP </span>
      </p>
      <div className="nextgenLaptop">
        <img src={laptop} alt="Plexaar desktop" />
      </div>
      <div>
        <NextGenFeatures />
      </div>
      <div className="alignNextGenBtn">
        <a href="/login">
          <button className="nextGenGetStartedBtn">Get Started</button>
        </a>
      </div>
    </div>
  );
};
