import React from 'react'
import { useEffect, useState } from 'react';
import { getQuestions } from '../../service/APIService';
import { customSnackBar } from '../../../../utils';
export const QuestionList = ({isRefresh,setRefresh,isFormBuilder,formBuilderQuestions,setFormBuilderQuestions,
sectionData,setSectionData,selectedSection,setSelectedSection
}) => {

    const [questionsList, setQuestionsList] = useState([]);
    const [nextPage, setNextPage] = useState('');
    const [isNextPage, setIsNextPage] = useState(false);
    const [loading, setloading] = useState(false);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const [total, setTotal] = useState(0);
    useEffect(() => {
        loadQuestions('')
    }, [isRefresh]);

    async function loadQuestions(url) {
        setloading(true)
        await getQuestions(url)
            .then(function (response) {
                setTotal(response.data.count)
                if (isNextPage && !isRefresh) {
                    setQuestionsList([...questionsList, ...response.data.results])
                } else {
                    setQuestionsList(response.data.results)
                }

                if (response.data.next === null || response.data.next === 'null') {
                    setIsNextPage(false)
                } else {
                    setIsNextPage(true)
                    setNextPage(response.data.next)
                }

            })
        setloading(false)
           
    }

    return (
        <div className=' my-2 text-center' style={{'height':'100vh','overflowY':'scroll'}} >
            <h4>Question Bank</h4>
            <h6 className='text-start mt-2'>Total Question: {total}</h6>
            <div className='mb-2' style={{ 'height': '80vh', 'overflowY': 'scroll' }}>
                {
                    questionsList.map((e, index) => {
                        const c_date = new Date(e.created_date);
                        const date = new Date(e.updated_date);

                        return (
                            <div className='text-start shadown border p-2 my-2 rounded-2' key={index}>
                                <h5>{e.contentObject?.questionText}</h5>
                                <small>Type: {e.contentObject.questionType}</small><br/>
                                {isFormBuilder? <button className='btn btn-primary' onClick={()=>{
                                    if(Object.keys(selectedSection).length < 1){
                                        customSnackBar('Please Add or Select Section');
                                        return;
                                    }
                                    let flag = true;
                                    selectedSection?.questionList?.forEach(element => {
                                        if(element.id === e.id){
                                            customSnackBar("This question is already in Form, Cannot add same question again")
                                            flag = false;
                                        }
                                    })
                                    if(flag){
                                        if("options" in e.content_object){
                                            var temp_obj = e;
                                            temp_obj.isRequired = false
                                        temp_obj.content_object.options.forEach(element => {
                                            element.id = `${temp_obj.id}.${element.id}`;
                                        });
                                        const temp_selected = selectedSection
                                        temp_selected.questionList = [...selectedSection.questionList,temp_obj]
                                        
                                        sectionData.forEach(element=>{
                                            if(element.id === temp_selected.id){
                                                element = temp_selected
                                            }
                                        })
                                        setSectionData([...sectionData])
                                        setSelectedSection(temp_selected)
                                        // setFormBuilderQuestions([...formBuilderQuestions,temp_obj])
                                    }else{
                                        var obj = e
                                        obj.isRequired = false;
                                        const temp_selected = selectedSection
                                        temp_selected.questionList = [...selectedSection.questionList,obj]
                                        
                                        sectionData.forEach(element=>{
                                            if(element.id === temp_selected.id){
                                                element = temp_selected
                                            }
                                        })
                                        setSectionData([...sectionData])
                                        setSelectedSection(temp_selected)
                                            // setFormBuilderQuestions([...formBuilderQuestions,obj])
                                        }
                                        
                                    }
                                }} >Select</button>:<></>}
                                
                            </div>

                        )
                    })
                }
                {
                    loading ?
                        <div className="spinner-border text-primary mt-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : <></>
                }
            </div>

            {
                isNextPage ?
                    <button className='btn btn-primary' onClick={() => { loadQuestions(nextPage) }}>Load More</button> : <small>No more to Load</small>
            }
        </div>
    )
}
