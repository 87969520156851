import {
  ACTIVATE_LOADER,
  DEACTIVATE_LOADER,
  IMAGE_UPLOADED,
} from "../../ActionType";

// returns action types
export const activateLoader = () => {
  return {
    type: ACTIVATE_LOADER,
    payload: true,
  };
};

export const deactivateLoader = () => {
  return {
    type: DEACTIVATE_LOADER,
    payload: false,
  };
};

export const imageUploaded = (res) => {
  return {
    type: IMAGE_UPLOADED,
    payload: res,
  };
};
