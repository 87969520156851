import axios from "axios";
import React, { useState } from "react";

function Apidelete() {
  const [inputValue, setInputValue] = useState("");
  const [responseData, setResponseData] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    // Define your API URL and headers
    const apiUrl =
      "Apply your base url here i have setted up the payload for you just set url and hit it also pass token blow in header";
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer YOUR_ACCESS_TOKEN", // Add your authentication token if needed
    };

    // Create the data you want to send to the API
    const data = {
      primaryEmail: inputValue,
      ModifiedBy: "user_id",
    };
    // Make the POST request
    axios
      .post(apiUrl, data, { headers })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div>
      <h6>Enter Email to delete account</h6>
      <input
        className="form-control"
        type="mail"
        placeholder="enter email"
        value={inputValue}
        onChange={handleInputChange}
      />
      <button className="btn btn-primary mt-3" onClick={handleButtonClick}>
        Delete
      </button>
    </div>
  );
}

export default Apidelete;
