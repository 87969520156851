import React, { useState, useEffect } from "react";
import axios from "axios";
import SideNavone from "../../SideNavBar";
import { DropdownArrowSvg } from "../../Assets/svgs";
import { useHistory, useLocation } from "react-router-dom";
import {
  calculateDurationInMinutes,
  convertDurationToHoursAndMinutesSeparate,
  customSnackBar,
} from "../../utils";
import styles from "./break.module.css";
import TextField from "../DynamicForm/dynamicFormView/textFieldInput";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAllData,
  setTypeOfBooking,
  updateDescription,
  updateDetailsDuration,
  updateEndTime,
  updateStartTime,
  updateTitle,
} from "../../Calendar/scheduler/store/bookingSlice";
import { getCalendarData } from "../../Calendar/scheduler/store/calendarSlice";
import { handlechange } from "../../Calendar/scheduler/store/dateSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { eventsandbreakAxios } from "../../Calendar/axios";

const CreateBreak = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const slotsData = useSelector((state) => state.bookingReducer.slotsData);
  const selectedBreak = useSelector(
    (state) => state.bookingReducer.selectedEvent
  );
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  const [, setSelectedDuration] = useState();
  const [hour, setHour] = useState(-1);
  const [mins, setMins] = useState(-1);
  const [selectedBookingType, setSelectedBookingType] = useState("Break");
  const [selectedProvider, setSelectedProvider] = useState();
  const options = ["Booking", "Event", "Break", "TimeBlock"];
  const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  const resources = useSelector((state) => state.calendarReducer.resources);

  const callBackHandler = () => {
    history.goBack();
  };

  const titleHandler = (e) => {
    closeAllDropDown();
    dispatch(updateTitle(e.target.value));
    // setBreakTitle(e.target.value);
  };
console.log(slotsData,"slotsDataslotsDataslotsData")
  useEffect(() => {
    if (slotsData.type === "event") {
      setSelectedBookingType("Event");
    } else if (slotsData.type === "break") {
      setSelectedBookingType("Break");
    } else if (slotsData.type === "timeBlock") {
      setSelectedBookingType("TimeBlock");
    }
  }, [slotsData.type]);

  const descriptionHandler = (e) => {
    closeAllDropDown();
    dispatch(updateDescription(e.target.value));
    // setBreakDescription(e.target.value);
  };

  const dateChangeHandler = (e) => {
    closeAllDropDown();
    // console.log(e.target.value);
    dispatch(handlechange(e.target.value));
  };

  useEffect(() => {
    if (slotsData.resourceId !== 0) {
      setIsLoading(true);
      const resourceIdExist = resources.find(
        (item, index) => item.resourceId === slotsData.resourceId
      );
      if (resourceIdExist) {
        setSelectedProvider(resourceIdExist);
      } else {
        setSelectedProvider(resources[0]);
      }
      setIsLoading(false);
    }
  }, [resources, slotsData.resourceId]);

  useEffect(() => {
    if (selectedBusiness !== null && selectedBusiness?.id) {
      setIsLoading(true);
      const record = {
        providerId: [],
        bookingDateStart: dateApi,
        is_business: true,
        bookingDateEnd: dateApi,
        timeZone: "Europe/London",
        businessId: selectedBusiness?.id,
      };
      dispatch(getCalendarData(record));
      setIsLoading(false);
    }
  }, [dateApi, dispatch, selectedBusiness]);

  useEffect(() => {
    if (slotsData) {
      setIsLoading(true);
      const durationInMinutes = calculateDurationInMinutes(
        slotsData.timeFrom,
        slotsData.timeTo
      );
      setSelectedDuration(durationInMinutes);
      if (durationInMinutes > 45) {
        const obj = convertDurationToHoursAndMinutesSeparate(durationInMinutes);
        setHour(obj.hours);
        setMins(obj.minutes);
      } else {
        setHour(-1);
        setMins(-1);
      }
      setIsLoading(false);
    } else {
      setSelectedDuration(0);
      setHour(-1);
      setMins(-1);
    }
  }, [slotsData, selectedBreak, slotsData.timeFrom, slotsData.timeTo]);
  console.log(slotsData, "slotsDataslotsData");
  const durationHandler = (duration) => {
    closeAllDropDown();
    setSelectedDuration(duration);
    dispatch(updateDetailsDuration({ duration }));
    setHour(-1);
    setMins(-1);
  };

  const customDurationHandler = () => {
    if (hour <= 0 && mins <= 0) {
      customSnackBar("duration cannot be zero");
    } else {
      let hourr;
      let minn;
      if (hour <= 0 || mins <= 0) {
        hourr = hour <= 0 ? 0 : hour;
        minn = mins <= 0 ? 0 : mins;
      } else {
        hourr = hour;
        minn = mins;
      }
      const dur = hourr * 60 + minn;
      setSelectedDuration(dur);
      dispatch(updateDetailsDuration({ duration: dur }));
      setHour(hourr); // Update the state with current hour value
      setMins(minn); // Update the state with current mins value
      closeAllDropDown();
    }
  };

  const startTimeHandler = (time) => {
    const date = new Date(time);
    const formattedTime = dayjs(date).format("HH:mm");
    dispatch(updateStartTime({ startTime: formattedTime }));
  };

  const endTimeHandler = (time) => {
    const date = new Date(time);
    const formattedTime = dayjs(date).format("HH:mm");
    dispatch(updateEndTime({ endTime: formattedTime }));
  };

  const handleOptionChange = (option) => {
    closeAllDropDown();
    if (option === "Booking") {
      history.push("/scheduler/create_booking");
    } else if (option === "Event") {
      dispatch(setTypeOfBooking("event"));
    } else if (option === "TimeBlock") {
      dispatch(setTypeOfBooking("timeBlock"));
    } else {
      dispatch(setTypeOfBooking("break"));
    }
  };

  const verifySlots = async () => {
    if (slotsData.isUpdate) {
      setIsLoading(true);
      const dataForVerify = {
        providerId: selectedProvider.resourceId,
        date: dateApi,
        breakDuration: slotsData.duration,
        from: `${slotsData.timeFrom}:00`,
        editing: {
          date: dateApi,
          timeFrom: `${slotsData.actualTimeFrom}:00`,
          timeTo: `${slotsData.actualTimeTo}:00`,
        },
        timeZone: "Europe/London",
        sku: "",
        hasBreak: true,
        businessId: selectedBusiness.id,
      };
      console.log(selectedProvider,"selectedProviderselectedProvider")
      const data = {
        businessId: selectedBusiness?.id,
        id: selectedBreak.id,
        type: selectedBreak.type,
        providerId:selectedProvider.resourceId,
        providerName: selectedProvider.resourceTitle,
        title: slotsData.title,
        description: slotsData.description,
        bookingDate: dateApi,
        bookingType: selectedBreak.type,
        bookingDuration: slotsData.duration,
        timeFrom: `${slotsData.timeFrom}:00`,
        timeTo: `${slotsData.timeTo}:00`,
        isSlotChanged: true,
        timeZone: "Europe/London",
      };
      try {
        const responseVerify = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/slot/verify`,
          dataForVerify
        );
        if (responseVerify.data.statusCode !== 0) {
          customSnackBar(responseVerify.data.message);
          setIsLoading(false);
          return;
        }
        const response = await eventsandbreakAxios.patch(`pb/events/`, data);
        if (response.data.code === 0) {
          dispatch(resetAllData());
          history.replace("/calendar");
        }
      } catch (e) {
        customSnackBar(e);
      }
      setIsLoading(false);
    } else {
      closeAllDropDown();
      setIsLoading(true);
      if (slotsData.duration === 0) {
        customSnackBar("Duration is Zero");
        setIsLoading(false);
        return;
      }
      if (slotsData.title === "" || slotsData.description === "") {
        customSnackBar("title or description cannot be empty");
        setIsLoading(false);
        return;
      }
      if (resources.length === 0) {
        customSnackBar("Please Select Provider");
        setIsLoading(false);
        return;
      }
      if (slotsData.timeFrom === "" || slotsData.timeTo === "") {
        customSnackBar("time not selected");
        setIsLoading(false);
        return;
      }
      try {
        const data = {
          providerId: selectedProvider.resourceId,
          date: dateApi,
          breakDuration: slotsData.duration,
          from: `${slotsData.timeFrom}:00`,
          timeZone: "Europe/London",
          sku: "",
          hasBreak: true,
          businessId: selectedBusiness.id,
        };
        const data1 = {
          businessId: data.businessId,
          providerId: selectedProvider.resourceId,
          title: slotsData.title,
          description: slotsData.description,
          colorCode:
            slotsData.type === "break"
              ? "#f3f3f3"
              : slotsData.type === "event"
              ? "#bb86fc"
              : "#34aadc",
          bookingDate: dateApi,
          bookingType: slotsData.type.toLowerCase(),
          bookingDuration: data.breakDuration,
          timeFrom: `${slotsData.timeFrom}:00`,
          timeTo: `${slotsData.timeTo}:00`,
          type: slotsData.type.toLowerCase(),
          providerName: selectedProvider.resourceTitle,
          timeZone: "Europe/London",
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/slot/verify`,
          data
        );
        if (response.data.statusCode !== 0) {
          customSnackBar(response.data.message);
          setIsLoading(false);
          return;
        }
        const responseBreak = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/eventsandbreaks_svc/pb/events/`,
          data1
        );
        if (responseBreak.data.code !== 0) {
          customSnackBar(responseBreak.data.message);
          return;
        }
        history.replace("/calendar");
      } catch (e) {
        customSnackBar(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".bookingnode_dropdown") &&
        !event.target.closest(".customnode_dropdown") &&
        !event.target.closest(".providernode_dropdown")
      ) {
        closeAllDropDown();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCustomOpen, isProviderOpen, isOpen]);

  const dropdownOpenHandler = ({
    isBreak = false,
    isCustom = false,
    isProvider = false,
  }) => {
    closeAllDropDown();
    if (isBreak && !isCustom && !isProvider) {
      setIsOpen(!isOpen);
    } else if (!isBreak && isCustom && !isProvider) {
      setIsCustomOpen(!isCustomOpen);
    } else if (!isBreak && !isCustom && isProvider) {
      setIsProviderOpen(!isProviderOpen);
    }
  };

  const closeAllDropDown = () => {
    setIsOpen(false);
    setIsCustomOpen(false);
    setIsProviderOpen(false);
  };

  const handleResourceChange = (res) => {
    setSelectedProvider(res);
    closeAllDropDown();
  };

  return (
    <SideNavone buttonText={selectedBookingType}>
      <div>
        <div className="col-md-12 padding_fixed_left_right mt-4">
          <div className="d-flex me-2 mob_flex">
            <div className="pt-2" role="button" onClick={callBackHandler}>
              <i id="icon_left_color" className="fas fa-chevron-left"></i>
            </div>
            <div className="w-100 mob_table">
              <div className="px-0 mb-2">
                <div className="dropdown-container position-relative bookingnode_dropdown">
                  <div
                    className={`${styles.dropdown_break} dropdown-header d-flex align-items-center justify-content-between ${styles.dropdown_container}`}
                    onClick={() =>
                      slotsData.isUpdate
                        ? null
                        : dropdownOpenHandler({ isBreak: true })
                    }
                  >
                    <div className="text-center w-100">
                      {selectedBookingType}
                    </div>
                    <div
                      className={`dropdown-icon d-flex align-items-center justify-content-center 
                          ${isOpen ? "open" : ""}  ${styles.dropdown_icon}
                          `}
                      style={{
                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
                        borderRadius: isOpen
                          ? "6px 0px 0px 6px"
                          : "0px 6px 6px 0px",
                      }}
                    >
                      <DropdownArrowSvg />
                    </div>
                  </div>
                  {isOpen && (
                    <ul
                      className={`dropdown-options list-unstyled p-0 m-0 position-absolute ${styles.dropdown_list}`}
                    >
                      {options.map((option, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleOptionChange(option)}
                          className={`${
                            option.toLowerCase() ===
                              slotsData.type.toLowerCase() &&
                            `${styles.dropdown_activelist_item}`
                          }`}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <TextField
                label={
                  slotsData.type === "event"
                    ? "Event Title"
                    : slotsData.type === "timeBlock"
                    ? "Time Block Title"
                    : "Break Title"
                }
                placeholder={
                  slotsData.type === "event"
                    ? "Event"
                    : slotsData.type === "timeBlock"
                    ? "Time Block"
                    : "Break"
                }
                value={slotsData.title}
                onHandleChange={titleHandler}
                disabled={isLoading}
              />
              <div
                className={`my-2 d-flex align-items-center w-100 ${styles.timeselector}`}
              >
                <div
                  className={`h-100 ${
                    slotsData.duration === 15 && styles.selectedtimer_div
                  }`}
                  role="button"
                  onClick={() => durationHandler(15)}
                  disabled={isLoading}
                >
                  15 mins
                </div>
                <div
                  className={`mx-2 h-100 ${
                    slotsData.duration === 30 && styles.selectedtimer_div
                  }`}
                  role="button"
                  onClick={() => durationHandler(30)}
                  disabled={isLoading}
                >
                  30 mins
                </div>
                <div
                  className={`h-100 ${
                    slotsData.duration === 45 && styles.selectedtimer_div
                  }`}
                  role="button"
                  onClick={() => durationHandler(45)}
                  disabled={isLoading}
                >
                  45 mins
                </div>
                <div className="dropdown-container position-relative ms-2 h-100 customnode_dropdown ">
                  <div
                    onClick={() => dropdownOpenHandler({ isCustom: true })}
                    disabled={isLoading}
                    className={`${
                      styles.dropdown_break
                    } dropdown-header h-100 d-flex align-items-center justify-content-between ${
                      styles.dropdown_container
                    } px-3 justify-content-between ${
                      (hour > 0 || mins > 0) && styles.selectedtimer_div
                    }`}
                  >
                    <span>
                      {hour <= 0 && mins <= 0
                        ? "Custom Duration"
                        : `${hour} hour ${mins} mins`}
                      {/* convertDurationToHoursAndMinutes(selectedDuration) */}
                    </span>
                    <DropdownArrowSvg />
                  </div>
                  {isCustomOpen && (
                    <div
                      className={`dropdown-options d-block p-2 m-0 position-absolute ${styles.dropdown_custom}`}
                    >
                      <div className="d-block border border-0">
                        <div className="border border-0 text-start">
                          Select hours and mins
                        </div>
                        <div className="d-flex w-100 border border-0">
                          <ul
                            className={`list-unstyled w-100 pe-2 ${styles.dropdown_listitems_both} ${styles.dropdown_timeStyle}`}
                          >
                            {hours.map((item, index) => (
                              <li
                                key={index}
                                role="button"
                                className={`ps-1 my-1 ${
                                  item === hour &&
                                  styles.dropdown_selectedTime_style
                                }`}
                                onClick={() => setHour(item)}
                              >
                                {`${item} hours`}
                              </li>
                            ))}
                          </ul>
                          <ul
                            className={`list-unstyled w-100 ps-1 pe-2 ${styles.dropdown_listitems_both} ${styles.dropdown_timeStyle}`}
                          >
                            {minutes.map((item, index) => (
                              <li
                                key={index}
                                role="button"
                                className={`ps-1 my-1 ${
                                  item === mins &&
                                  styles.dropdown_selectedTime_style
                                }`}
                                onClick={() => setMins(item)}
                              >
                                {`${item} mins`}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="border border-0">
                          <button
                            className={`w-100 py-1 ${styles.dropdown_button}`}
                            onClick={customDurationHandler}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  height: "50px",
                }}
              >
                <TextField
                  label="Date"
                  type="date"
                  value={dateApi}
                  onHandleChange={dateChangeHandler}
                  disabled={isLoading}
                />
                <div className="dropdown-container position-relative providernode_dropdown ms-2 h-100 w-100">
                  <div
                    onClick={() => dropdownOpenHandler({ isProvider: true })}
                    disabled={isLoading}
                    className={`${styles.dropdown_break} dropdown-header h-100 d-flex align-items-center justify-content-between ${styles.dropdown_container} px-3 justify-content-between`}
                  >
                    <div className="d-flex flex-column">
                      <span className={styles.providerName_style}>
                        Provider Name
                      </span>
                      <span>
                        {resources.length === 0 && slotsData.isUpdate
                          ? "Provider Not Available Today"
                          : resources.length === 0 &&
                            slotsData.isUpdate === false
                          ? "No Provider Found"
                          : `${selectedProvider?.resourceTitle}`}
                      </span>
                    </div>
                    <DropdownArrowSvg />
                  </div>
                  {isProviderOpen && (
                    <ul
                      className={`dropdown-options list-unstyled p-0 m-0 position-absolute ${styles.dropdown_list}`}
                    >
                      {resources?.map((option, index) => (
                        <li
                          key={index}
                          onMouseDown={
                            slotsData.isUpdate
                              ? () => {}
                              : () => handleResourceChange(option)
                          }
                          className={`${
                            option?.resourceId ===
                              selectedProvider?.resourceId &&
                            `${styles.dropdown_activelist_item}`
                          }`}
                        >
                          {option?.resourceTitle}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="d-flex my-2">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  sx={{
                    width: "100%",
                  }}
                >
                  <DemoContainer
                    components={["TimePicker"]}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TimePicker
                      label="Start Time"
                      className={`${styles.startTime_Input} w-100`}
                      views={["hours", "minutes"]}
                      value={dayjs(`${dateApi}T${slotsData.timeFrom}`).toDate()}
                      onChange={startTimeHandler}
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField {...params} label="Start Time" />
                      )}
                    />
                    <TimePicker
                      label="End Time"
                      className={`${styles.startTime_Input} w-100`}
                      views={["hours", "minutes"]}
                      minTime={dayjs(
                        `${dateApi}T${slotsData.timeFrom}`
                      ).toDate()}
                      value={dayjs(`${dateApi}T${slotsData.timeTo}`).toDate()}
                      onChange={endTimeHandler}
                      disabled={isLoading || !slotsData.timeFrom}
                      renderInput={(params) => (
                        <TextField {...params} label="End Time" />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <TextField
                label={
                  slotsData.type === "event"
                    ? "Event Description"
                    : slotsData.type === "timeBlock"
                    ? "Time Block Description"
                    : "Break Description"
                }
                placeholder="Enter Description"
                value={slotsData.description}
                onHandleChange={descriptionHandler}
                disabled={isLoading}
                lengthOnInput={200}
              />
              <div className="d-flex justify-content-center my-5">
                {isLoading ? (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={verifySlots}
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "center",
                      color: "#553300",
                      backgroundColor: "#FFD705",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px 200px",
                    }}
                  >
                    {slotsData.isUpdate
                      ? slotsData.type === "event"
                        ? "Update Event"
                        : slotsData.type === "timeBlock"
                        ? "Update Time Block"
                        : "Update Break"
                      : slotsData.type === "event"
                      ? "Add Event"
                      : slotsData.type === "timeBlock"
                      ? "Add Time Block"
                      : "Add Break"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideNavone>
  );
};

export default CreateBreak;
