import React, { useEffect, useState } from "react";
import InputTextField from "../../TextField";
import DescriptionField from "../../TextField/descriptionField";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { businessAddressInitialValue, formateUpdateBusinessAddress, formateUpdateData, validationSchemaOfBusinessAddress } from "../../Business/helper";
// import "../AddBusiness/AddBusiness.scss";
import { addBusinessAddressesRequest, updateBusinessRequest } from "../../../../Store/Business/actions";
import EditBusinessAddressForm from "./editBusinessAddressFrom";

const BusinessAddressForm = ({handleNextClose}) => {
const dispatch=useDispatch()
  var data = useSelector((state) => state.Business.addedAddress);
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  var businessResult = useSelector((state) => state?.Business?.result);

  const [loading, setLoading] = useState(false);
 
  const updateBusiness = (values, handle) => {
    const businessResults = JSON.parse(localStorage.getItem("businessRecord"));

   let id=businessResults?businessResults.id:"";

    const data = formateUpdateBusinessAddress(values);
    const action='edit'
    dispatch(addBusinessAddressesRequest({ data, setLoading, id,action }));
  };
  const handleSubmit = (values, handles, result) => {
   

   
      updateBusiness(values, handles, result);
    
  };
  return (
    <>
      <div className="ps-5 pe-5 hide_scrollbar  mt-2 "
      style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >

        <Formik
          initialValues={businessAddressInitialValue(data,userInfo)}
          validationSchema={validationSchemaOfBusinessAddress}
          onSubmit={(values, handles) => {
            handleSubmit(values, handles);
          }}
        >
          {(options) => {
            return (
              <Form onSubmit={handleSubmit}>
               <EditBusinessAddressForm
               handleNextClose={handleNextClose}
               loading={loading}
               {...options}/>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default BusinessAddressForm;
