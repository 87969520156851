import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  SvgIcon,
  Tooltip,
  styled,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TuneIcon from "@mui/icons-material/Tune";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddIcon from "@mui/icons-material/Add";
import {
  expertServiceAxios,
  plexaarServiceAxios,
  providertimeavailabilityAxios,
  serviceAxios,
} from "../../axios";
import { getSlotsData } from "../store/calendarSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addBookingTypeAll,
  addBookingTypeExpert,
  addBookingTypePrivate,
  selectedServiceData,
  setTypeOfBooking,
} from "../store/bookingSlice";
import { useParams } from "react-router-dom";
import "../../assets/scheduler/css/calendar.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customSnackBar, formatTime } from "../../../utils";
import {
  DecreasePackageQuantitySvg,
  DropdownArrowSvg,
  FilterButtonInTextField,
  IncreasePackageQuantitySvg,
  MoreOptionsVertSvg,
  PaymentSvg,
  SandTimerSvg,
  ArrowDownYellowSvg,
  ArrowUpYellowSvg,
} from "../../../Assets/svgs";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import AddServiceAttribute from "../components/AddServiceAttribute";
import instance from "../../../utils/utils";
import { serviceImageURL } from "../../../utils/imageUrlsExtension";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomChip = styled(Chip)(({ theme }) => ({
  "& .MuiChip-icon": {
    color: "red", // Change this color to your desired color
  },
}));

export default function SelectService({
  setServiceData,
  setSelectSlotFlag,
  callBackHandler,
  selectedSlot,
  selectSlotFlag,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const [filterText, setFilterText] = useState("");
  const [selectedService, setSelectedService] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState(false);
  const [selectedFlagAtrribute, setSelectedFlagAttribute] = useState("");
  const [subService, setSubService] = useState(false);
  const [inputKey, setInpuKey] = useState(false);
  const [copyofCheckBox, setCopyofCheckBox] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(" Add Booking");
  const [quantity, setQuantity] = useState(1);
  const [variablePrice, setVairablePrice] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loader, setLoader] = useState(false);
  const slotData = useSelector((state) => state.bookingReducer.slotsData);
  let slotFlag = useSelector((state) => state.bookingReducer.slotFlag);
  const strAscending = [...checkboxList].sort((a, b) =>
    a.serviceName > b.serviceName ? 1 : -1
  );

  const { customerData, isExpert, isPrivate } = useSelector(
    (state) => state.bookingReducer
  );
  const numAscending = [...checkboxList].sort(
    (a, b) => a.serviceId - b.serviceId
  );
  const getServicesData = useCallback(async () => {
    setIsLoading(true);
    await instance
      .get(
        `/serviceinventory_svc/pb/Service/GetAllServicesByBusinessId?businessId=${selectedBusiness.id}`
      )
      .then((res) => {
        setCheckboxList(res.data.result.services);
      })
      .catch((err) => {
        setCheckboxList([]);
      })
      .finally(() => setIsLoading(false));
  }, [selectedBusiness.id]);

  const getVariableSerivcePrice = useCallback(async () => {
    setIsLoading(true);
    await instance
      .get(
        `/serviceinventory_svc/pb/ServicePrice/GetServiceVarPriceByServiceIdd?Id=${selectedService[0]?.serviceId}&quantity=${quantity}`
        // `/serviceinventory_svc/pb/ServicePrice/GetServiceVarPriceByServiceIdd?Id=1&quantity=${quantity}`
      )
      .then((res) => {
        setVairablePrice(res?.data?.result?.servicePrice);
        // setCheckboxList(res.data.result.services);
      })
      .catch((err) => {
        setVairablePrice([]);
      })
      .finally(() => setIsLoading(false));
  }, [selectedService, quantity]);
  useEffect(() => {
    if (selectedService.length > 0) {
      console.log(selectedService, "openBookingDetailopenBookingDetail");

      getVariableSerivcePrice();
    }
  }, [selectedService, quantity, getVariableSerivcePrice]);

  const getServicesChildData = async (id) => {
    setIsLoading(true);
    if (selectedOption === "Expert") {
      await instance
        .get(`/serviceinventory_svc/pb/Service/GetAllChildServices?id=${id}`)
        .then((res) => {
          setCheckboxList(res.data.result.services);
        })
        .catch((err) => {
          setCheckboxList([]);
        })
        .finally(() => setIsLoading(false));
    } else if (selectedOption === "Private") {
      await instance
        .get(`/serviceinventory_svc/pb/Service/GetAllChildServices?id=${id}`)
        .then((res) => {
          setCheckboxList(res.data.result.services);
        })
        .catch((err) => {
          setCheckboxList([]);
        })
        .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {
    setQuantity(1);
  }, [selectedFlag]);
  const handleOptionChange = (option) => {
    if (option === "Booking") {
      history.push("/scheduler/create_booking");
    } else if (option === "Event") {
      dispatch(setTypeOfBooking("event"));
      history.push("/scheduler/create_break_event_timeblock");
    } else if (option === "Break") {
      dispatch(setTypeOfBooking("break"));
      history.push("/scheduler/create_break_event_timeblock");
    } else if (option === "TimeBlock") {
      dispatch(setTypeOfBooking("timeBlock"));
      history.push("/scheduler/create_break_event_timeblock");
    }
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown after selection
  };

  console.log(formatTime(selectedSlot?.timeFrom), "selectedSlotselectedSlot");
  const CheckProviderAvailabilty = async (updatedData, action) => {
    const formattedTimeFrom = formatTime(selectedSlot?.timeFrom);

    const data = {
      providerId: selectedSlot?.resourceId ? selectedSlot?.resourceId : "",
      date: selectedSlot?.bookingDate ? selectedSlot?.bookingDate : "",
      breakDuration: updatedData?.duration ? updatedData?.duration : "",
      from: `${selectedSlot?.timeFrom}:00`
        ? `${formattedTimeFrom}:00`
        : "00:00:00",
      timeZone: "Europe/London",
      sku: updatedData?.serviceSKU ? updatedData?.serviceSKU : "",
      hasBreak: false,
      businessId: selectedBusiness?.id ? selectedBusiness?.id : 0,
    };
    setLoader(true);
    await instance
      .post("/provideravailability_svc/pb/slot/verify", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.statusCode === 1) {
          if (action === "available") {
            customSnackBar(res?.data?.message);
            setLoader(false);
          } else {
            // history.push("/calendar")
            customSnackBar(res?.data?.message);
            setLoader(false);
          }
        } else {
          if (res?.data?.result?.available === true) {
            setLoader(false);

            setSelectedFlag(true);
          } else {
            if (action === "available") {
              customSnackBar(
                "Slots is not available Please Select another slot"
              );
              setLoader(false);
            } else {
              history.push("/calendar");
              customSnackBar(
                "Slots is not available Please Select another slot"
              );
              setLoader(false);
            }

            // customSnackBar(res?.data?.message);
          }
        }
      })
      .catch((err) => {
        customSnackBar(err.message);
        setLoader(false);
        // dispatch(openToast({ message: err.message, status: "error" }));
      });
  };

  const handleSelectedService = async (data) => {
    setSelectedFlagAttribute(data);
    const temp = selectedService;

    // history.push(
    //   `/services-attribute`,data?.serviceId
    // )
    const subServices = handlegetSubServices(data);
    if (subServices !== true) {
      const foundService = temp.find(
        (item) => item.serviceName === data.serviceName
      );
      // if(slotData){
      //   const data1 = {
      //     provider_id: slotData.resourceId,
      //     bookingDate: slotData.bookingDate,
      //     time_from: slotData.timeFrom,
      //     duration: data.duration,
      //     timeZone: "Europe/London",
      //   };
      //   await axios.put(`${process.env.REACT_APP_BASE_URL}/events_svc/check_availablity`,data1);
      // }
      if (foundService) {
        if (foundService.bookedSeats > 1) {
          // Decrease quantity and split services
          setQuantity(foundService.bookedSeats - 1);
          setSelectedService((prevItems) => [
            ...prevItems,
            {
              ...foundService,
              bookedSeats: 1,
            },
          ]);
          if (selectedService?.hasAttributes === true) {
            setSelectSlotFlag("attribute");
          } else if (
            selectedSlot?.length !== 0 &&
            selectedService[0].hasAttributes === false
          ) {
            CheckProviderAvailabilty(selectedService[0]);
          } else {
            setSelectedFlag(true);
          }
        } else {
          setSelectedService((prevItem) =>
            prevItem.filter((item) => item.serviceName !== data.serviceName)
          );
        }
        if (selectedService[0]?.hasAttributes === true) {
          setSelectSlotFlag("attribute");
        } else if (
          selectedSlot?.length !== 0 &&
          selectedService[0].hasAttributes === false
        ) {
          CheckProviderAvailabilty(selectedService[0]);
        } else {
          setSelectedFlag(true);
        }
        setSelectSlotFlag("attribute");
      } else {
        // if(data?.actualPrice === null){
        //   customSnackBar("Price ");
        // }
        const updatedData = { ...data };
        updatedData.bookedSeats = 1;
        setSelectedService([updatedData]);
        if (updatedData.hasAttributes === true) {
          setSelectSlotFlag("attribute");
        } else if (
          selectedSlot?.length !== 0 &&
          updatedData.hasAttributes === false
        ) {
          CheckProviderAvailabilty(updatedData);
        } else {
          setSelectedFlag(true);
        }
        // if (selectedSlot?.length !== 0) {
        // CheckProviderAvailabilty(updatedData);
        // } else {
        setSelectSlotFlag("attribute");
        // setSelectedFlag(true);
        // }
      }
    }
  };
  const handlegetSubServices = (data) => {
    if (data.hasChild === true) {
      setFilterText("");
      getServicesChildData(data.serviceId);
      setSubService(true);
      return true;
    }
  };
  const handleAddMore = () => {
    setSelectedFlag(false);
    setFilterText("");
  };

  const durationAndCostStyle = {
    color: "#433C3C",
    textAlign: "start",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "500",
    paddingTop: "5px",
  };
  const CostStyle = {
    color: "#838383",
    textAlign: "start",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  };

  const increaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (service.serviceName === serviceName.serviceName) {
        return { ...service, bookedSeats: service.bookedSeats + 1 };
      }
      setQuantity(serviceName.bookedSeats);
      return service;
    });
    setSelectedService(updatedArray);
  };
  const decreaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (
        service.serviceName === serviceName.serviceName &&
        service.bookedSeats > 1
      ) {
        return { ...service, bookedSeats: service.bookedSeats - 1 };
      }
      setQuantity(serviceName.bookedSeats);
      return service;
    });

    setSelectedService(updatedArray);
  };

  // const increaseQuantity = () => {
  //   setQuantity((prevQuantity) => prevQuantity + 1);
  // };

  // const decreaseQuantity = (serviceName) => {
  //   console.log(serviceName, "serviceNameserviceName");
  //   if (quantity > 1) {
  //     setQuantity((prevQuantity) => prevQuantity - 1);
  //   }
  // };
  const handleBackServices = () => {
    setSubService(false);
    getServicesData();
  };
  const styleForDeActiveButton = {
    borderRadius: "12px",
    border: "0.6px solid #DCDCDC",
    backgroundColor: "#FFF",
    width: "20%",
    // color: "#888",
    // textAlign: "center",
    // fontFamily: "Roboto",
    // fontSize: "14px",
    // fontStyle: "normal",
    // fontWeight: 500,
  };

  const styleForActiveButton = {
    borderRadius: "12px",
    border: "0.6px solid #FFD705",
    backgroundColor: "#FFD705",
    width: "20%",
    // color: "#530",
    // textAlign: "center",
    // fontFamily: "Roboto",
    // fontSize: "14px",
    // fontStyle: "normal",
    // fontWeight: 700,
  };

  const handleNextStep = () => {
    dispatch(selectedServiceData(selectedService));
    setSelectSlotFlag("question");
  };

  // for drop down ----------------------------------------------
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getExpertServices = async () => {
    setIsLoading(true);
    await instance
      .get(
        `/serviceinventory_svc/pb/Service/GetAllServicesByBusinessId?businessId=${selectedBusiness.id}`
      )
      .then((res) => {
        setCheckboxList(res.data.result.services);
      })
      .catch((err) => {
        setCheckboxList([]);
      })
      .finally(() => setIsLoading(false));
  };

  const getPlexaarServices = async () => {
    setIsLoading(true);
    await instance
      .get(
        `/serviceinventory_svc/pb/Service/GetAllServicesByBusinessId?businessId=${selectedBusiness.id}`
      )
      .then((res) => {
        setCheckboxList(res.data.result.services);
      })
      .catch((err) => {
        setCheckboxList([]);
      })
      .finally(() => setIsLoading(false));
  };

  const getAllServices = async () => {
    setIsLoading(true);
    await instance
      .get(
        `/serviceinventory_svc/pb/Service/GetAllServicesByBusinessId?businessId=${selectedBusiness.id}`
      )
      .then((res) => {
        setCheckboxList(res.data.result.services);
      })
      .catch((err) => {
        setCheckboxList([]);
      })
      .finally(() => setIsLoading(false));
  };

  // const handleOptionChange = (option) => {
  //   if (option === "Expert") {
  //     getExpertServices();
  //     dispatch(addBookingTypeExpert());
  //   } else if (option === "Private") {
  //     getPlexaarServices();
  //     dispatch(addBookingTypePrivate());
  //   } else if (option === "All") {
  //     getAllServices();
  //     dispatch(addBookingTypeAll());
  //   }
  //   setSelectedOption(option);
  //   setIsOpen(false); // Close the dropdown after selection
  // };

  useEffect(() => {
    dispatch(addBookingTypeAll());
    getServicesData();
  }, [getServicesData]);

  const serviceCard = () => {
    return (
      <>
        <div className="d-flex mt-4 service_booking_flex">
          <div
            className="pt-2 back_btn_div"
            onClick={() => {
              setSelectedFlag(false);
              setSelectSlotFlag("service");
            }}
            role="button"
          >
            <i id="icon_left_color" className="fas fa-chevron-left"></i>
          </div>
          <div
            className="container pt-1 pb-2 sm:px-4 service_details"
            style={{
              borderRadius: "8px",
              border: "0.6px solid #DCDCDC",
              background: "#FFF",
              position: "relative",
            }}
          >
            <div className="d-flex mt-2 justify-content-between align-items-center">
              <span style={{ fontSize: "12px" }} className="businessId">
                Service Details
              </span>
              <div className="d-flex">
                <span style={{ fontSize: "12px" }} className="businessId pt-1">
                  Quantity
                </span>{" "}
                <div
                  className="ms-3"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                  }}
                >
                  <div
                    onClick={(e) => {
                      decreaseQuantity(selectedService[0]);
                    }}
                    role="button"
                  >
                    <DecreasePackageQuantitySvg />
                  </div>
                  <div
                    className="mx-2"
                    style={{
                      color: "#4D4D4D",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {selectedService[0]?.bookedSeats
                      ? selectedService[0]?.bookedSeats
                      : 1}
                    {/* {quantity} */}
                  </div>
                  <div
                    onClick={(e) => {
                      increaseQuantity(selectedService[0]);
                    }}
                    role="button"
                  >
                    <IncreasePackageQuantitySvg />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div>
                {selectedService.map((vl, index) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between">
                        <div key={index} className="d-flex ">
                          {selectedService ? (
                            <div className=" text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                              <Avatar
                                alt={vl?.serviceName}
                                src={serviceImageURL(vl?.serviceId)}
                                variant="rounded"
                                style={{
                                  borderRadius: 50,
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#F0F0F0",
                                  color: "#B5B5B5",
                                }}
                              />
                              {/* <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="30"
                                  height="30"
                                  rx="15"
                                  fill="#F0F0F0"
                                />
                                <path
                                  d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                  stroke="#B5B5B5"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                  stroke="#B5B5B5"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg> */}
                            </div>
                          ) : (
                            <div className=" text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                              <Avatar
                                alt={vl?.serviceName}
                                src={serviceImageURL(vl?.serviceId)}
                                variant="rounded"
                                style={{
                                  borderRadius: 50,
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#F0F0F0",
                                  color: "#B5B5B5",
                                }}
                              />
                              {/* <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="30"
                                  height="30"
                                  rx="15"
                                  fill="#F0F0F0"
                                />
                                <path
                                  d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                  stroke="#B5B5B5"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                  stroke="#B5B5B5"
                                  stroke-width="0.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg> */}
                            </div>
                          )}
                          <div className="d-flex justify-content-between ">
                            <div
                              style={{
                                textAlign: "left",
                              }}
                              className=" justify-content-start"
                            >
                              <div
                                className={`d-flex ${
                                  vl &&
                                  vl.attributes &&
                                  vl.attributes.length === 0
                                    ? "pt-0"
                                    : "pt-0"
                                }`}
                              >
                                {/* Your content here */}

                                <div className="d-flex flex-column">
                                  <p className="m-0 mx-2 mt-2 mb-0 selected_servicename">
                                    {vl?.serviceName}{" "}
                                    {vl.attributes &&
                                      vl.attributes[0] &&
                                      vl.attributes.length > 0 && (
                                        <span className="selected_serviceduration ms-2">
                                          ({vl?.duration} mins)
                                        </span>
                                      )}
                                  </p>{" "}
                                  {vl.attributes &&
                                    vl.attributes[0] &&
                                    vl.attributes.length > 0 && (
                                      <span
                                        style={{
                                          fontSize: "8.5px",
                                          lineHeight: "10px",
                                        }}
                                        className="m-0 mx-2 mb-2 mt-0 selected_servicevalue"
                                      >
                                        {vl.attributes[0].value}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mb-1">
                          {/* <PaymentSvg /> */}
                          {selectedService[0]?.bookedSeats > 0 && (
                            <span className="ms-1 serviceprice">
                              {variablePrice === null ||
                              variablePrice?.length === 0
                                ? (vl.currency === null
                                    ? "£"
                                    : vl.currency === "USD"
                                    ? "$"
                                    : "£") +
                                  (vl.actualPrice === null ||
                                  vl.actualPrice === 0
                                    ? 1 * selectedService[0]?.bookedSeats
                                    : vl.actualPrice *
                                      selectedService[0]?.bookedSeats)
                                : (variablePrice?.currency === "USD"
                                    ? "$"
                                    : "£") +
                                  variablePrice?.servicePrice *
                                    selectedService[0]?.bookedSeats}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* <hr
                        className="mt-3 ms-0 mb-1"
                        style={{
                          color: "#c4c4c4",
                          margin: "15.6px 0 21.4px",
                        }}
                      />
                      {selectedService[0].attributes && isExpanded && (
                        <div className="pt-0">
                          <span
                            style={{ fontSize: "12px" }}
                            className="businessId"
                          >
                            Attributes
                          </span>

                          {selectedService[0].attributes &&
                            selectedService.map((vl, index) =>
                              vl.attributes.map((value) => (
                                <div
                                  className="d-flex justify-content-between align-items-center my-2"
                                  key={index}
                                >
                                  <div className="d-flex align-items-center">
                                    <Avatar
                                      alt={value.value}
                                      src="/images/tag 1.svg"
                                      
                                      style={{ width: 20, height: 20 }}
                                      onError={(e) => {
                                        e.target.src =
                                          "https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg";
                                      }}
                                    />
                                    <Tooltip title={value.value}>
                                      <p className="m-0 mx-2 ellipsis selected_servicename">
                                        {value.value}
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <div className="d-flex justify-content-around align-items-center">
                                    <div className="d-flex justify-content-end align-items-center">
                                      <SandTimerSvg />
                                      <span
                                        className="ms-1"
                                        style={{
                                          color: "#4D4D4D",
                                          textAlign: "center",
                                          fontFamily: "Poppins",
                                          fontSize: "11px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {value.duration + " Min "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <PaymentSvg />
                                    <span
                                      className="ms-1"
                                      style={{
                                        color: "#4D4D4D",
                                        textAlign: "center",
                                        fontFamily: "Poppins",
                                        fontSize: "11px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                      }}
                                    >
                                      £{value.attributePrice}
                                      
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-center">
                                    <MoreOptionsVertSvg />
                                  </div>
                                </div>
                              ))
                            )}
                        </div>
                      )} */}
                    </>
                  );
                })}
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "-18px",
                left: "50%",
                zIndex: "9999",
              }}
              className="d-flex justify-content-center"
            >
              {/* <div
                style={{
                  background: "white",
                  cursor: "pointer",
                  border: " 0.6px solid rgb(220, 220, 220)",
                  borderRadius: "50px",
                  height: "35px",
                  width: "35px",
                }}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <div className="pt-1 px-2">
                  {!isExpanded ? (
                    <Tooltip title="Expand">
                      <ArrowDownYellowSvg />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Collap">
                      <ArrowUpYellowSvg />
                    </Tooltip>
                  )}
                </div>
              </div> */}
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="col-md-12 text-center mt-4 pt-4">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 px-0">
              <button
                className=" service_btn"
                style={{
                  backgroundColor: "#FFD705",
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  textTransform: "capitalize",
                  borderRadius: "7px",
                  border: "none",
                  width: "100%",
                  padding: "3%"
                }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleNextStep();
                }}
              >
                {" "}
                {slotFlag === true ? "Continue" : "Pick Booking Slot"}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const headerStyle = {
    width: "50%",
    color: "#4A4A4A",
    textAlign: "left",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
  };

  // const options = ["All", "Expert", "Private"];
  const options = ["Booking", "Event", "Break", "TimeBlock"];

  return (
    <>
      {selectSlotFlag === "attribute" &&
        selectedService[0]?.hasAttributes === true &&
        selectedFlag === false && (
          <AddServiceAttribute
            loaders={loader}
            providerAvailabilty={CheckProviderAvailabilty}
            setSelectedFlag={setSelectedFlag}
            selectedSlot={selectedSlot}
            setSelectSlotFlag={setSelectSlotFlag}
            callBackHandler={callBackHandler}
            selectedService={selectedFlagAtrribute}
            setSelectedService={setSelectedService}
          />
        )}

      {/* <button className="btn btn-primary" type="button" onClick={callBackHandler}>Go Back2</button> */}
      {!(
        selectSlotFlag === "attribute" &&
        selectedService[0]?.hasAttributes === true &&
        selectedFlag === false
      ) &&
        (selectedFlag ? (
          <>{serviceCard()}</>
        ) : (
          <div>
            <div className="col-md-12 padding_fixed_left_right mt-4">
              <div className="d-flex me-2 mob_flex">
                <div className="pt-2" onClick={callBackHandler} role="button">
                  <i id="icon_left_color" className="fas fa-chevron-left"></i>
                </div>

                <div className="w-100 mob_table">
                  <div className="px-0 mb-2">
                    <div
                      className="dropdown-container"
                      style={{
                        position: "relative",
                        // display: "inline-block",
                      }}
                    >
                      <div
                        className="dropdown-header"
                        style={{
                          borderRadius: "6px",
                          border: "1px solid #DCDCDC",
                          borderRight: "0.6px solid #D7D7D7",
                          background: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "0",
                          cursor: "pointer", // Add cursor pointer
                        }}
                        onClick={toggleDropdown}
                      >
                        <div
                          style={{
                            color: "#585858",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {selectedOption}
                        </div>
                        <div
                          className={`dropdown-icon px-3 ${
                            isOpen ? "open" : ""
                          }`}
                          style={{
                            borderRadius: "0px 7px 7px 0px",
                            background: "#EBEBEB",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                          }}
                        >
                          <DropdownArrowSvg isOpen={isOpen} />
                        </div>
                      </div>
                      {isOpen && (
                        <ul
                          className="dropdown-options"
                          style={{
                            listStyle: "none",
                            padding: "0",
                            margin: "0",
                            position: "absolute",
                            minWidth: "100%",
                            top: "100%",
                            left: "0",
                            zIndex: "1",
                            border: "0.5px solid #DCDCDC",
                            marginTop: "5px",
                            borderRadius: "5px",
                            overflow: "hidden",
                            backgroundColor: "white",
                            // borderRadius: "8px",
                          }}
                        >
                          {options.map((option, index) => (
                            <li
                              key={index}
                              onMouseDown={() => handleOptionChange(option)}
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                                background:
                                  option === selectedOption
                                    ? "#FFD705"
                                    : "#FFF",
                                padding: "4px 9px",
                                cursor: "pointer",
                                userSelect: "none",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  {strAscending.length === 0 && isLoading === false ? (
                    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                      <h5 className="m-0">
                        Services is Not available kindly add
                      </h5>
                      <Button
                        style={{ backgroundColor: "#5599FF" }}
                        className="col-4 py-3 my-3"
                        variant="contained"
                        onClick={(e) => {
                          history.push("/all-services");
                        }}
                      >
                        Add Services
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "70vh",
                        paddingBottom: "100px",
                        // overflow: "auto",
                        border: "1px solid #DCDCDC",
                        borderRadius: "8px",
                      }}
                      className="col-md-12 mt-1 mb-2 "
                    >
                      <div className="d-flex backgound_white_select_options pt-2 pb-2">
                        <div className="w-100 py-1 px-2 d-flex justify-content-between align-items-center">
                          <svg
                            className="ms-2"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.05084 12.2885C8.08766 12.2875 9.10089 11.9791 9.96252 11.4023C10.8241 10.8255 11.4955 10.0063 11.8917 9.0481C12.288 8.0899 12.3913 7.03575 12.1888 6.01882C11.9862 5.0019 11.4867 4.06784 10.7536 3.33468C10.0204 2.60151 9.08636 2.10214 8.0695 1.89965C7.05264 1.69717 5.99859 1.80067 5.04052 2.19706C4.08246 2.59346 3.26337 3.26496 2.68677 4.12673C2.11018 4.98846 1.80193 6.00176 1.80094 7.03862M7.05084 12.2885L7.05074 12.1885L7.05114 12.2885C7.05104 12.2885 7.05094 12.2885 7.05084 12.2885ZM7.05084 12.2885C6.36077 12.2912 5.67698 12.1573 5.03889 11.8945C4.40072 11.6316 3.82089 11.245 3.33283 10.7569C2.84477 10.2689 2.45814 9.68904 2.19522 9.05084C1.93232 8.41269 1.79832 7.72881 1.80094 7.03862M1.80094 7.03862C1.80094 7.03857 1.80094 7.03853 1.80094 7.03848L1.90094 7.03886L1.80094 7.03877C1.80094 7.03872 1.80094 7.03867 1.80094 7.03862ZM12.5779 11.2555L12.5254 11.325L12.5869 11.3865C12.8199 11.6195 13.0522 11.8519 13.2841 12.0839C14.0531 12.8533 14.8173 13.6179 15.5831 14.3807L15.5832 14.3807C15.8375 14.634 15.9576 14.9066 15.8731 15.2469L15.8727 15.2486C15.8403 15.3896 15.7721 15.5198 15.6745 15.6266C15.577 15.7334 15.4536 15.8132 15.3162 15.8583C15.1788 15.9033 15.032 15.9121 14.8902 15.8837C14.7484 15.8553 14.6163 15.7908 14.5068 15.6963L14.5056 15.6953C14.4099 15.6155 14.3236 15.5273 14.2334 15.4351C14.1997 15.4006 14.1653 15.3655 14.1298 15.3301L11.4265 12.629L11.4269 12.6285L11.4184 12.6217C11.3994 12.6065 11.3797 12.5923 11.3592 12.5792L11.3022 12.5428L11.2472 12.5821C9.36074 13.931 7.29366 14.3436 5.05124 13.6954C3.42545 13.2238 2.14846 12.2512 1.22548 10.8338L1.22546 10.8337C-0.64377 7.96585 -0.137862 4.15166 2.39561 1.88655L2.39592 1.88627C3.63297 0.770901 5.22975 0.137498 6.89492 0.101613C8.56009 0.0657275 10.1827 0.629755 11.4666 1.69079L11.4669 1.69104C14.2155 3.94422 14.9216 8.15425 12.5779 11.2555Z"
                              fill="#A5A5A5"
                              stroke="white"
                              stroke-width="0.2"
                            />
                          </svg>

                          <div
                            className="ms-2 mt-1 mb-1 w-100"
                            // style={{
                            //   position: "relative",
                            // }}
                          >
                            <input
                              onChange={(e) => {
                                e.preventDefault();
                                setFilterText(e.target.value);
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              type="text"
                              placeholder="Search the service you want to book"
                              className="w-100 p-0 m-0 select-service-input"
                              style={{
                                color: "#1C1B1B",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                            />
                          </div>
                          <div className="pe-2">
                            <svg
                              onClick={() => {
                                customSnackBar("Working on it");
                              }}
                              style={{ cursor: "pointer" }}
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="28"
                                height="28"
                                rx="5"
                                fill="#FFD705"
                              />
                              <path
                                d="M13.9993 16.917C15.841 16.917 17.3327 15.4253 17.3327 13.5837V9.00033C17.3327 7.15866 15.841 5.66699 13.9993 5.66699C12.1577 5.66699 10.666 7.15866 10.666 9.00033V13.5837C10.666 15.4253 12.1577 16.917 13.9993 16.917Z"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.625 12.0419V13.4586C7.625 16.9753 10.4833 19.8336 14 19.8336M14 19.8336C17.5167 19.8336 20.375 16.9753 20.375 13.4586V12.0419M14 19.8336V22.3336M12.8417 9.35859C13.5917 9.08359 14.4083 9.08359 15.1583 9.35859M13.3333 11.1253C13.775 11.0086 14.2333 11.0086 14.675 11.1253"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>

                          {/* <div className="pe-1">
                      <FilterButtonInTextField />
                    </div> */}
                        </div>
                      </div>
                      <div style={{ overflow: "auto", height: "62vh" }}>
                        <div className=" bg-white ">
                          <ul className="list-group" style={{ border: "none" }}>
                            <hr
                              className="mx-2 m-0"
                              style={{
                                // color:"#EDEDED",
                                // heigh:"1px",
                                // borderStyle:"solid",
                                border: "1px solid #DCDCDC",
                              }}
                            />
                            {isLoading ? (
                              // Show loader when isLoading is true
                              <li
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                                className="d-flex "
                              >
                                {/* Add your loader component here */}
                                <ClipLoader color="#548DFF" />
                              </li>
                            ) : (
                              // Render the list items when not loading
                              strAscending
                                .filter((value) =>
                                  value.serviceName
                                    ?.toLowerCase()
                                    .includes(filterText.toLowerCase())
                                )
                                .map((value, i) => (
                                  <>
                                    <li
                                      key={i}
                                      className="list-group-item justify-content-between d-flex"
                                      style={{
                                        color: "black",
                                        fontFamily: "Roboto",
                                        fontSize: "13px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        cursor: loader ? "progress" : "pointer",
                                        border: "none",
                                        padding: "12px 12px 12px 12px",
                                      }}
                                      onClick={(e) => {
                                        if (loader) {
                                          e.preventDefault();
                                        } else {
                                          handleSelectedService(value);
                                        }
                                      }}
                                    >
                                      <div className="d-flex align-items-center me-3">
                                        {value.serviceImage ? (
                                          // <svg
                                          //   width="30"
                                          //   height="30"
                                          //   viewBox="0 0 30 30"
                                          //   fill="none"
                                          //   xmlns="http://www.w3.org/2000/svg"
                                          // >
                                          //   <rect
                                          //     width="30"
                                          //     height="30"
                                          //     rx="15"
                                          //     fill="#F0F0F0"
                                          //   />
                                          //   <path
                                          //     d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                          //     stroke="#B5B5B5"
                                          //     stroke-width="0.75"
                                          //     stroke-linecap="round"
                                          //     stroke-linejoin="round"
                                          //   />
                                          //   <path
                                          //     d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                          //     stroke="#B5B5B5"
                                          //     stroke-width="0.75"
                                          //     stroke-linecap="round"
                                          //     stroke-linejoin="round"
                                          //   />
                                          // </svg>
                                          <Avatar
                                            alt={value?.serviceName}
                                            src={
                                              serviceImageURL(value.serviceId)
                                              // value.isExpert
                                              //   ? `${process.env.REACT_APP_IMAGE_URL}/ServiceInventory/${value.serviceId}.webp`
                                              //   : value?.serviceImage
                                            }
                                            className="rounded-circle m-auto"
                                            style={{
                                              width: 30,
                                              height: 30,
                                              backgroundColor: "#F0F0F0",
                                              color: "#B5B5B5",
                                            }}
                                          />
                                        ) : (
                                          <Avatar
                                            alt={value?.serviceName}
                                            src={
                                              serviceImageURL(value.serviceId)
                                              // value.isExpert
                                              //   ? `${process.env.REACT_APP_IMAGE_URL}/ServiceInventory/${value.serviceId}.webp`
                                              //   : value?.serviceImage
                                            }
                                            className="rounded-circle m-auto"
                                            style={{
                                              width: 30,
                                              height: 30,
                                              backgroundColor: "#F0F0F0",
                                              color: "#B5B5B5",
                                            }}
                                          />
                                          // <svg
                                          //   width="30"
                                          //   height="30"
                                          //   viewBox="0 0 30 30"
                                          //   fill="none"
                                          //   xmlns="http://www.w3.org/2000/svg"
                                          // >
                                          //   <rect
                                          //     width="30"
                                          //     height="30"
                                          //     rx="15"
                                          //     fill="#F0F0F0"
                                          //   />
                                          //   <path
                                          //     d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                          //     stroke="#B5B5B5"
                                          //     stroke-width="0.75"
                                          //     stroke-linecap="round"
                                          //     stroke-linejoin="round"
                                          //   />
                                          //   <path
                                          //     d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                          //     stroke="#B5B5B5"
                                          //     stroke-width="0.75"
                                          //     stroke-linecap="round"
                                          //     stroke-linejoin="round"
                                          //   />
                                          // </svg>

                                          // <Avatar
                                          //   className="rounded-circle"
                                          //   style={{
                                          //     backgroundColor: "#616161",
                                          //     width: "25px",
                                          //     height: "25px",
                                          //     color: "white",
                                          //   }}
                                          //   src={
                                          //     serviceImageURL(value.serviceId)
                                          //     value.isExpert
                                          //       ? `${process.env.REACT_APP_IMAGE_URL}/ServiceInventory/${value.serviceId}.webp`
                                          //       : "https://play-lh.googleusercontent.com/J9w6iG3fnxSuACWl_QZbAcQ1CGuEM0n03exftRyNtaYiy7ldnAr4ZNH85vvl18uXwfU=w240-h480-rw"
                                          //   }

                                          //   src="https://play-lh.googleusercontent.com/J9w6iG3fnxSuACWl_QZbAcQ1CGuEM0n03exftRyNtaYiy7ldnAr4ZNH85vvl18uXwfU=w240-h480-rw"
                                          // />
                                        )}
                                        {value?.isExpert ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "25px",
                                              left: "33px",
                                            }}
                                            className="m-auto"
                                          >
                                            <div className="upload-btn-wrapper">
                                              {/* <div className="btnn"> */}
                                              <img
                                                src="/images/expert.png"
                                                alt="nodata"
                                                style={{
                                                  borderRadius: 50,
                                                  border: "0.5px solid white",
                                                  width: "14px",
                                                  height: "14px",
                                                }}
                                              />
                                              {/* </div> */}
                                            </div>
                                          </div>
                                        ) : null}
                                        &nbsp; &nbsp;
                                        <span
                                          style={{
                                            color: "#616161",
                                            fontSize: "14px",
                                            textTransform: "capitalize",
                                          }}
                                          className="m-auto"
                                        >
                                          {value.serviceName}
                                        </span>
                                        &nbsp; &nbsp;
                                        {value.hasAttributes ? null : (
                                          <div
                                            style={CostStyle}
                                            className="m-auto"
                                          >
                                            ({value.duration} mins)
                                          </div>
                                        )}
                                      </div>
                                      {value.hasAttributes ? (
                                        <div className="my-auto">
                                          <svg
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 9L5 4.8431L1 1"
                                              stroke="#AFABAB"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      ) : (
                                        <div className="my-auto" style={durationAndCostStyle}>{`${
                                          value.currency === null
                                            ? ""
                                            : value.currency === "USD"
                                            ? "$"
                                            : "£"
                                        }${
                                          value.actualPrice === null
                                            ? "0"
                                            : value.actualPrice
                                        }`}</div>
                                      )}
                                    </li>
                                    <hr
                                      className="mx-2 m-0"
                                      style={{
                                        // color:"#F8F8F8",
                                        // height:"1px",
                                        // borderStyle:"solid",
                                        border: "1px solid #EDEDED",
                                      }}
                                    />
                                  </>
                                ))
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
