import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SideNavone from "../../SideNavBar";
import Header from "../BusinessProfile/Header";
import { onBoardingAxios } from "../../Calendar/axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityContext } from "../../context";
import { setEntity } from "./store/entitySlice";
import { error } from "../../utils";

const OnBoarding = () => {
  const history = useHistory();
  const dispatch=useDispatch()
  const flag = true;
  const [data, setData] = useState([]);
  // const [entity,setEntity]=useContext(EntityContext)

  var user = useSelector(
    (state) => state.Auth.userloginsuccessyasir
  ); 
  const entity = useSelector((state) => state?.entityReducer?.entity);


  useEffect(() => {
    onBoardingAxios
      .get("pb/onboarding-types/")
      .then((response) => {
        setData(response?.data?.results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const addEntity=(name)=>{
    const data={
      type:name,
      userid:user?.id.toString(),
      details:{
        "name": user?.firstName ,
        "position": "Software Developer",
        "address": "John Doe Address, City, Country",
        "phone": "123-456-7890"
      }
    }
    onBoardingAxios
    .post(`/pb/entities/`,data)
    .then((response) => {
      if(response?.status===400){
        error(response?.data?.userid[0])
      }
      dispatch(setEntity(response?.data)); // Dispatch the action to update the entity state

      // setEntity(response?.data);
    })
    .catch((error) => {
      console.error(error);
    });
  }
  return (
    <>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Onboarding Type"} />{" "}
        </div>
        <div
          className="hide_scrollbar"
          style={{ height: "100vh", overflow: "auto", paddingBottom: "140px" }}
        >
          <div className="ps-5 pe-5 pt-4">
            <span className="grey-letter">Please Select Onboarding Type</span>
            {data.map((row, index) => (
              <div className="pt-4">
                <div className="card">
                  <div className="d-flex justify-content-between  ">
                    <div>
                      <div
                        className="d-flex"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addEntity(row.name.toLowerCase());
                          if (row.name === "Business") {
                            history.push(`/on-board/${row.id}`,entity);
                          } else if (row.name === "Property") {
                            history.push(`/on-board/${row.id}`);
                          }
                          // Add more cases for other names if needed
                        }}
                        //   onClick={() => history.push("/add-new-business")}
                      >
                        <img
                          src={
                            row.name === "Business"
                              ? "../images/Rectangle 12327.png"
                              : row.name === "Property"
                              ? "../images/Rectangle 12329.png"
                              : "../images/Rectangle 12328.png"
                          } //   src="../images/Rectangle 12327.png"
                          alt="no-data"
                        />{" "}
                        <div className="d-flex flex-column">
                          <span className=" pt-3 ps-3 text-capitalize sub_text_color_account_alltext_show_actual">
                            {row.name}
                          </span>
                          <span className="grey-letter mt-1 ps-3 ">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2 pe-2">
                      <div className="pt-3">
                        <i id="color_icon_right" className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SideNavone>
    </>
  );
};
export default OnBoarding;
