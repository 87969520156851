import React, { useState, useCallback, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { departmentManger } from "./helper";
import TableHeader from "../TableHeader";
import TableRows from "./TableRow";
import { departmentList } from "../../../utils/constant";
import DialogBox from "../DialogBox";

const TableData = (props) => {
  const { data, dialog, deleteDepartment } = props;
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("CreatedAt");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    setRowData(data);
    // searchBinding(setSearchBar, setRowData, blogs);
  }, [data]);
  //Use Effect To set RowData on searching
  useEffect(() => {
    setPage(0);
  }, [data]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [drawerToggle, setDrawerToggle] = useState(false);
  const toggleDrawerHandler = useCallback(() => {
    setDrawerToggle(!drawerToggle);
  }, [drawerToggle]);

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const newSelectedCheckboxes = data.map((item) => item.id);
      setSelectedCheckboxes(newSelectedCheckboxes);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleRowCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelectedCheckboxes) => [
        ...prevSelectedCheckboxes,
        id,
      ]);
    } else {
      setSelectedCheckboxes((prevSelectedCheckboxes) =>
        prevSelectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      );
    }
  };

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  return (
    // <Paper sx={{ width: "100%", overflow: "hidden" }}>
    <>
      <DialogBox
        {...dialog}
        header={departmentList.DELETE_DEPPARTMENT}
        message={departmentList.WANT_TO_DELETE_DEPARTMENT}
      />
      <TableContainer
        className="hide_scrollbar"
        sx={{
          maxHeight: 1000,
          borderRadius: "8px",
          border: "1px solid #DCDCDC",
          backgroundColor: "#fff",
          // boxShadow: "3px 5px 0px #c6c5c5",
        }}
      >
        {" "}
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHeader
            column={departmentManger}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            rowCount={data.length}
            selectedCheckboxes={selectedCheckboxes}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          />

          <TableBody>
            <TableRows
              page={page}
              rowsPerPage={rowsPerPage}
              rowData={rowData}
              deleteDepartment={deleteDepartment}
              toggleDrawerHandler={toggleDrawerHandler}
              selectedCheckboxes={selectedCheckboxes}
              handleRowCheckboxChange={handleRowCheckboxChange}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
    </>
  );
};
export default TableData;
