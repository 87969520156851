import React from 'react'
import GroupDetails from './GroupDetails'
import PackageDetails from './PackageDetails'

const GroupAndPkgDetails = ({ data, reFetchGroups, fetchUniqueGroup, fetchUniquePackage }) => {

    const reFetchGroupsFunc = () => {
        reFetchGroups()
    }

    const fetchUniqueGroupFunc = (uid) => {
        fetchUniqueGroup(uid)
    }

    const fetchUniquePackageFunc = (uid) => {
        fetchUniquePackage(uid)
    }

    return (
        <>
            <GroupDetails reFetchGroups={reFetchGroupsFunc} data={data} fetchUniqueGroup={fetchUniqueGroupFunc} />
            <PackageDetails reFetchGroups={reFetchGroups} data={data} fetchUniquePackage={fetchUniquePackageFunc} />
        </>
    )
}

export default GroupAndPkgDetails