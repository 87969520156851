import React, { useEffect } from "react";
// import "../AddBusiness/AddBusiness.scss";
import SideNavone from "../../../../SideNavBar";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Field } from "formik";

const OwnerDetail = ({
  values,
  errors,
  onSubmit,
  touched,
  setFieldValue,
  handleChange,
  action,
  isMobile
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [checkboxChecked1, setCheckboxChecked1] = useState(true);

  const history = useHistory();
  var userInfo = useSelector((state) => state?.Auth?.alluserdataafterlogin);
  useEffect(() => {
    if (checkboxChecked) {
      setFieldValue("Owner.firstname", userInfo?.firstName);
      setFieldValue("Owner.lastname", userInfo?.lastName);
      setFieldValue("Owner.userEmail", userInfo?.primaryEmail);
      setFieldValue("Owner.gender", userInfo?.genderName);
      setFieldValue("Owner.contactNo", userInfo?.primaryMobile);
      setFieldValue("checkboxChecked", checkboxChecked);
    }
  }, [checkboxChecked, setFieldValue, userInfo]);

  useEffect(() => {
    if (checkboxChecked1) {
      setFieldValue("contactPerson.firstname", userInfo?.firstName);
      setFieldValue("contactPerson.lastname", userInfo?.lastName);
      setFieldValue("contactPerson.userEmail", userInfo?.primaryEmail);
      setFieldValue("contactPerson.gender", userInfo?.genderName);
      setFieldValue("contactPerson.contactNo", userInfo?.primaryMobile);
      setFieldValue("checkboxChecked1", checkboxChecked1);
    }
  }, [checkboxChecked1, setFieldValue, userInfo]);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleCheckboxChange1 = (event) => {
    setCheckboxChecked1(event.target.checked);
  };
 
  return (
    <div className="pt-5">
      <span className="pt-5 text_heading_owner">Organization Owner Details</span>
      <div className="col-md-12 border_rad_new px-2 mt-1 ">
        <div className="  py-3">
          <div className="row">
          <div className={`${!isMobile ? "col-md-11 " : "col-10"}`}>

              <span
                className={`ps-1 text-size ${
                  checkboxChecked ? "text-black" : "owner-text"
                }`}
              >
                {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
              </span>
            </div>
          <div className={`${!isMobile ? "col-md-1 text-center " : "col-1 ms-4"}`}>

          
              <input
                className="form-check-input text-end"
                type="checkbox"
                defaultValue=""
                id="defaultCheck1"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </div>
      {!checkboxChecked ? (
        <div>
            
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">First Name</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="Owner.firstname"
              name="Owner.firstname"
              value={values?.Owner?.firstname}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              onChange={handleChange}
              placeholder="Enter First Name"
            />
          </div>
          {errors?.Owner?.firstname && (
        <p className="text-danger font_size_password">{errors.Owner.firstname}</p>
      )}

          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Last Name</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="Owner.lastname"
              name="Owner.lastname"
              value={values?.Owner?.lastname}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>
          {errors?.Owner?.lastname && (
        <p className="text-danger font_size_password">{errors?.Owner?.lastname}</p>
      )}

          {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Gender</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="Owner.gender"
              name="Owner.gender"
              value={values.Owner.gender}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              onChange={handleChange}
              placeholder="Enter Gender"
            />
          </div> */}
          {/* {errors.Owner.firstname && (
        <p className="text-danger font_size_password">{errors.Owner.firstname}</p>
      )} */}
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Contact No</label>
            <Field
              className="form-control1 input_style_fix"
              type="number"
              id="Owner.contactNo"
              name="Owner.contactNo"
              value={values?.Owner?.contactNo}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              onChange={handleChange}
              placeholder="Enter Contact Number"
            />
          </div>
          {errors?.Owner?.contactNo && (
        <p className="text-danger font_size_password">{errors?.Owner?.contactNo}</p>
      )}
          <div className="pb-5">
            <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
              <label className="label_text_business">Email</label>
              <Field
                className="form-control1 input_style_fix"
                type="email"
                id="Owner.userEmail"
                name="Owner.userEmail"
                value={values.Owner.userEmail}
                // onChange={(newValue) => {
                //   setFieldValue("businessName", newValue);
                // }}
                // onChange={handleChange}
                placeholder="Enter Email"
              />
            </div>
          </div>
          {errors?.Owner?.userEmail && (
        <p className="text-danger font_size_password">{errors?.Owner?.userEmail}</p>
      )}
        </div>
      ) : (
        <div className="pt-2 pb-5">
          <Button
            sx={{
              border: "1px solid #538dff",
              backgroundColor: "#fff",
              height: "30px",
              borderRadius: "8px",
              color: "#538dff",
              textTransform: "capitalize",
              fontSize:"12px",
              fontWeight:"200",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#538dff",
              },
            }}
            variant="contained"
            onClick={() => {
              setCheckboxChecked(false);
            }}
          >
            + &nbsp;{"Add New Owner"}
          </Button>
        </div>
      )}
      {/* Second Section    -----------------------------------------------------------------------------------------         */}

      <span className="text_heading_owner mb-0 mt-3">Organization Manager</span>
      <div className="col-md-12 border_rad_new px-2  mt-1 ">
        <div className="  py-3">
          <div className="row">
          <div className={`${!isMobile ? "col-md-11 " : "col-10"}`}>
              <span
                className={`ps-1 text-size ${
                  checkboxChecked1 ? "text-black" : "owner-text"
                }`}
              >
                {userInfo && userInfo?.firstName} {userInfo && userInfo?.lastName}
              </span>
            </div>
            <div className={`${!isMobile ? "col-md-1 text-center " : "col-1 ms-4"}`}>
              <input
                className="form-check-input text-end"
                type="checkbox"
                defaultValue=""
                id="defaultCheck1"
                checked={checkboxChecked1}
                onChange={handleCheckboxChange1}
              />
            </div>
          </div>
        </div>
      </div>
      {!checkboxChecked1 ? (
        <div>
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">First Name</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="contactPerson.firstname"
              name="contactPerson.firstname"
              value={values?.contactPerson?.firstname}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              onChange={handleChange}
              placeholder="Enter First Name"
           
            />
          </div>
          {errors?.contactPerson?.firstname && (
        <p className="text-danger font_size_password">{errors?.contactPerson?.firstname}</p>
      )}
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Last Name</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="contactPerson.lastname"
              name="contactPerson.lastname"
              placeholder="Enter Last Name"
              value={values?.contactPerson?.lastname}
              onChange={handleChange}
           
            />
          </div>
          {errors?.contactPerson?.lastname && (
        <p className="text-danger font_size_password">{errors?.contactPerson?.lastname}</p>
      )}
          {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Gender</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              placeholder="Enter Gender"
              id="contactPerson.gender"
              name="contactPerson.gender"
              value={values.contactPerson.gender}
              onChange={handleChange}
           
            />
          </div> */}
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Contact</label>
            <Field
              className="form-control1 input_style_fix"
              type="text"
              id="contactPerson.contactNo"
              name="contactPerson.contactNo"
              placeholder="Enter Contact"
              value={values?.contactPerson?.contactNo}
              onChange={handleChange}
           
            />
          </div>
          {errors?.contactPerson?.contactNo && (
        <p className="text-danger font_size_password">{errors?.contactPerson?.contactNo}</p>
      )}
          <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
            <label className="label_text_business">Email</label>
            <Field
              className="form-control1 input_style_fix"
              type="email"
              id="contactPerson.userEmail"
              name="contactPerson.userEmail"
              placeholder="Enter Email"
              value={values?.contactPerson?.userEmail}
              onChange={handleChange}
           
            />
          </div>
          {errors?.contactPerson?.userEmail && (
        <p className="text-danger font_size_password">{errors.contactPerson.userEmail}</p>
      )}
        </div>
      ) : (
        <div className="pt-2">
          <Button
            sx={{
              border: "1px solid #538dff",
              backgroundColor: "#fff",
              height: "30px",
              borderRadius: "8px",
              color: "#538dff",
              textTransform: "capitalize",
              fontSize:"12px",
              fontWeight:"200",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#538dff",
              },
            }}
            variant="contained"
            onClick={() => {
              setCheckboxChecked1(false);
            }}
          >
            + &nbsp;{"Add New Manager"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default OwnerDetail;
