import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CardLink=({isMobile})=> {
  const businesProfile = useSelector((state) => state.Business.businesProfile);

    const history=useHistory()
    const handleAddUser = () => {
    
        history.push(`/users`, businesProfile);
        // handleDialogClose();
      };
      const handleAddDepartment = () => {
        localStorage.setItem("businessRecord", JSON.stringify(businesProfile));
    
        history.push(`/department`, businesProfile);
        // handleDialogClose();
      };
      const handleStaffAdd = () => {
        localStorage.setItem("businessRecord", JSON.stringify(businesProfile));
    
        history.push(`/edit-business-address`, );
        // handleDialogClose();
      };
      const handleSchulaer = () => {
        localStorage.setItem("businessRecord", JSON.stringify(businesProfile));
    let action="businessProfile"
        history.push(`/add-business-schedular/${businesProfile.id}`, action);
        // handleDialogClose();
      };
  return (
    <div
    className={`${!isMobile ? "d-flex mb-3 justify-content-center flex-wrap":"d-flex mb-3 justify-content-center "}`}>
    {" "}
    <div className={`${!isMobile ? "mt-3 mb-1 pt-0 pe-4 pt-md-2":"pe-2 mt-3"}`}>

      <div className="card-link ">
        <div  style={{cursor:"pointer"}} className="p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleAddDepartment}>
          <img
          className={`${!isMobile ? "ms-1":"ms-1 mt-2"}`}
            src="../images/git-fork 2.svg"
            alt="nodata"
          />{" "}
          <p style={{fontSize:isMobile?"12px":""}} className={`${isMobile?"pt-2":"pt-0"}" sub_text_color_account_alltext_show_actual"`}>
            Department
          </p>
        </div>
      </div>
    </div>
    <div className={`${!isMobile ? "mt-3 mb-1 pt-0  pe-4 pt-md-2":"pe-2 mt-3"}`}>

        <div className="card-link ">
          <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleSchulaer}>
            <img
              className="ms-1"
              src="../images/alarm-check 1 (1).svg"
              alt="nodata"
            />{" "}
            <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
              Timing
            </p>
          </div>
        </div>
      </div>
      <div className={`${!isMobile ? "mt-3 mb-1 pt-0  pe-4 pt-md-2":"pe-2 mt-3"}`}>
        <div className="card-link ">
          <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleStaffAdd}>
            <img
              className="ms-1"
              src="../images/map-pin 1.svg"
              alt="nodata"
            />{" "}
            <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
              Address
            </p>
          </div>
        </div>
      </div>
      <div className={`${!isMobile ? "mt-3 mb-1 pt-0  pe-4 pt-md-2":"pe-2 mt-3"}`}>
        <div className="card-link ">
          <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleAddUser}>
            <img
              className="ms-1"
              src="../images/tv-2 1.svg"
              alt="nodata"
            />{" "}
            <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
              Portal
            </p>
          </div>
        </div>
    </div>
  </div>
  )
}

export default CardLink