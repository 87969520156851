import axios from "axios";

axios.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + process.env.NEXT_PUBLIC_APP_AUTH_TOKKEN,
};
const testAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_TEST_AXIOS,
});

const mainAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/events_svc`,
});
const expertServiceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_EXPERT}/`,
});
const plexaarServiceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
// const serviceAxios = axios.create({
//   baseURL: `${process.env.REACT_APP_BASE_URL_EXPERT}/`,
// });
const slotAxios = axios.create({
  baseURL:
    `${process.env.REACT_APP_BASE_URL}/provideravailability_svc`,
});
const customerAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/customer_svc`,
});
const salesOrderAxios = axios.create({
  // baseURL: "http://192.168.0.105:8002/salesorder_svc"
  baseURL: `${process.env.REACT_APP_BASE_URL}/salesorder_svc`,
});
const bookingAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/booking_svc`,
});
const onBoardingAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/onboarding_svc/`,
});
const attendanceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/attendance_svc`,
});
const eventsandbreakAxios = axios.create({
  baseURL:
    `${process.env.REACT_APP_BASE_URL}/eventsandbreaks_svc`,
});
const clinicAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_CLINIC_AXIOS,
});
const providertimeavailabilityAxios = axios.create({
  baseURL:
    `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/slot`,
});
export {
  mainAxios,
  attendanceAxios,
  // serviceAxios,
  expertServiceAxios,
  plexaarServiceAxios,
  providertimeavailabilityAxios,
  slotAxios,
  customerAxios,
  salesOrderAxios,
  bookingAxios,
  clinicAxios,
  testAxios,
  eventsandbreakAxios,
  onBoardingAxios,
};
