// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  height: 50vh;
  width: 60%;
  font-size: 5em;
  border: 1px solid var(--border-color);
  background: white;
  border-radius: 8px;
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/Calendar/assets/scheduler/css/view/thankYouView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,cAAc;EACd,qCAAqC;EACrC,iBAAiB;EACjB,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".thank-you {\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  align-items: center;\n  height: 50vh;\n  width: 60%;\n  font-size: 5em;\n  border: 1px solid var(--border-color);\n  background: white;\n  border-radius: 8px;\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
