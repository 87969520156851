import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, IconButton, Tooltip } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const EventsLView = (props) => {

    return (
        <div className='container events_grid_container'>
            <div className='row'
            >
                {props?.data.length > 0 && props?.data.map(v => {
                    return (
                        <div className='col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center scrollable_list_card_wrapper'
                        >
                            <Card className='scrollable_card' sx={{ width: '100%', borderRadius: '15px', border: '1px solid lightgray' }}
                            >
                                <CardActionArea className='px-3 py-2'
                                >
                                    <CardContent className='px-0'
                                    // sx={{ minWidth: '700px', overflow: 'scroll' }}
                                    >
                                        <Typography variant="body2" color="text.secondary"
                                        >
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <div className='d-flex'>
                                                        <DateRangeOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                                                        <span className='ms-2'><b>12 Aug 2022</b></span>
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        <AccessTimeOutlinedIcon sx={{ fontSize: '18px' }} />
                                                        <span className='ms-2'><b>09:00 AM-06:00 PM</b></span>
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        <span className='ms-2 text-warning'>Pending</span>
                                                    </div>
                                                </div>

                                                <div className='col-4 d-flex flex-column align-items-center'>
                                                    <div className='d-flex'>
                                                        <GroupOutlinedIcon className='text-secondary' sx={{ fontSize: '24px' }} />
                                                        <span className='ms-2'><b>adnan@gmail.com</b></span>
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        <AccessAlarmOutlinedIcon className='text-warning' sx={{ fontSize: '18px' }} />
                                                        <span className='ms-2 text-warning'>-00-14-59</span>
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        <ContentCopyOutlinedIcon className='text-primary' sx={{ fontSize: '18px' }} />
                                                    </div>
                                                </div>

                                                <div className='col-4 d-flex flex-column align-items-end'>
                                                    <div className='d-flex'>
                                                        <span className='text-secondary py-1 px-3' style={{ borderRadius: '40px', background: '#f7f7f9' }}>Development</span>
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        <AccessAlarmOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                                                        <span className='ms-2'><b>30 mins</b></span>
                                                    </div>
                                                    <div className='d-flex justify-content-end align-items-center mt-3 w-100'>
                                                        <button className='btn btn-danger' style={{ width: '120px', fontSize: '12px', borderRadius: '10px' }}>Cancel</button>
                                                        <button className='btn btn-primary ms-4' style={{ width: '120px', fontSize: '12px', borderRadius: '10px' }}>Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EventsLView