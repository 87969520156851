import React, { useState, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { FaRegEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { deleteRequest, postRequest, putRequest } from '../../../request';
import { IconButton, Pagination, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { base_url } from '../../../base_url';
import { getPriceVariables } from '../../../store/servicesActions';
import { useDispatch, useSelector } from 'react-redux';
import { disableMiniLoader, enableMiniLoader } from '../../../store/ServicesSlice';
import { BntLoader } from '../../../../../Componenets/loaders';

const VariableList = ({ renderVarbilesList, priceId, priceData }) => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Type', width: '200px' },
        { id: 2, name: 'Currency', width: '200px' },
        { id: 3, name: 'From', width: '200px' },
        { id: 4, name: 'To', width: '200px' },
        { id: 5, name: 'Price', width: '200px' },
    ]);

    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '']
        },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Text', width: '100px' },
        { id: 8, name: 'Dropdown', width: '100px' },
        { id: 9, name: 'Number', width: '100px' },
        { id: 10, name: 'File', width: '100px' },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [defaultColumns, setDefaultColumns] = useState([
        { id: 1, width: '200px' },
        { id: 2, width: '200px' },
        { id: 3, width: '200px' },
        { id: 4, width: '200px' },
        { id: 5, width: '200px' },
    ]);
    const [varType, setVarType] = useState('');
    const [currency, setCurrency] = useState(priceData[1]?.currency);
    const [varFrom, setVarFrom] = useState('');
    const [varTo, setVarTo] = useState('');
    const [varPrice, setVarPrice] = useState(0);
    const [selectedId, setSelectedId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const { id } = useParams();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);


    const addRow = () => {
        const newRow = {
            id: rows.length + 1,
            data: columns.map(() => ''),
        };
        setRows([...rows, newRow]);
    };

    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };

    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };

    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);

        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });

        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteSelectedRows = async (delId) => {
        const res = await deleteRequest(`${base_url}/ServicePrice/RemoveServiceVarPrice?Id=${delId}`);
        if (res?.message === "succeeded") {
            dispatch(getPriceVariables(priceId))
            alert('Service Price deleted successfully');
        }
        else {
            alert(res?.message)
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const postData = async () => {
        if (varFrom && varTo && varType && varPrice && currency) {
            dispatch(enableMiniLoader());
            if (!selectedId) {
                const data = {
                    serviceId: parseInt(id),
                    priceId: parseInt(priceId),
                    name: varFrom + '-' + varTo,
                    type: varType,
                    price: parseInt(varPrice),
                    currency: currency
                }
                const res = await postRequest(`${base_url}/ServicePrice/AddVarServicePrice`, data);
                if (res?.message === "succeeded") {
                    setVarType('');
                    setVarFrom('');
                    setVarTo('');
                    setVarPrice(0);
                    dispatch(disableMiniLoader());
                    dispatch(getPriceVariables(priceId))
                    alert('Variable Price added successfully');
                }
                else {
                    alert(res?.message)
                }
            }
            else {
                const data = {
                    id: parseInt(selectedId),
                    serviceId: parseInt(id),
                    priceId: parseInt(priceId),
                    name: varFrom + '-' + varTo,
                    type: varType,
                    price: parseInt(varPrice),
                    currency: currency,
                }
                const res = await putRequest(`${base_url}/ServicePrice/UpdateServiceVarPrice`, data);
                if (res?.message === "succeeded") {
                    // setSelectedId(null);
                    setVarType('');
                    setVarFrom('');
                    setVarTo('');
                    setVarPrice(0);
                    dispatch(disableMiniLoader());
                    dispatch(getPriceVariables(priceId))
                    alert('Service Price updated successfully');
                }
                else {
                    alert(res?.message)
                }
            }
        }
        else {
            alert("please Provice missing values")
        }
    }

    const getEditData = (id) => {
        setSelectedId(id)
        const filtered = rows.filter(elem => elem?.id === id);
        if (filtered) {
            filtered[0]?.data?.map(v => {
                if (v?.priceType) {
                    setVarType(v?.priceType);
                }
                else if (v?.currency) {
                    setCurrency(v?.currency);
                }
                else if (v?.variablePriceName) {
                    setVarFrom(v?.variablePriceName.split("-")[0]);
                    setVarTo(v?.variablePriceName.split("-")[1]);
                }
                else if (v?.servicePrice) {
                    setVarPrice(v?.servicePrice);
                }

            });
        }
    }

    // Calculate the index range of data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRows = rows.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (store?.priceVariables) {
            setRows(() => store?.priceVariables.map((v, i) => {
                return (
                    {
                        id: v?.id, serviceId: v?.serviceId, servicePriceId: v?.servicePriceId,
                        data: [{ id: 1, priceType: v?.priceType }, { id: 2, currency: v?.currency }, { id: 3, variablePriceName: v?.variablePriceName }, { id: 4, variablePriceName: v?.variablePriceName }, { id: 5, servicePrice: v?.servicePrice }]
                    })
            }))
        }
    }, [store?.priceVariables]);

    useEffect(() => {
        dispatch(disableMiniLoader())
    }, [])

    return (
        <>
            <div className='container pc_display mt-3 mb-3'>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-between px-3 py-2 shadow-sm' style={{ borderRadius: '9px', backgroundColor: 'white', border: '1px solid lightgray' }}>
                        <span>
                            <h5 className='p-0 m-0'>{priceData[0]?.priceType}</h5>
                            <span style={{ color: 'gray', fontSize: '14px' }}>Price: {priceData[2]?.servicePrice}</span>
                        </span>

                        <span>
                            <h5 className='p-0 m-0 text-end'>{priceData[1]?.currency}</h5>
                            <span style={{ color: 'gray', fontSize: '14px' }}>Currency</span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="container p-0 hide_scrollbar position-relative pc_display mt-3" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
                <table className="table pricing_list_container w-100">
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {columns.map((column, index) => {
                                if (index < 1) {
                                    return (
                                        <th
                                            id={`sticky_col${column?.id}`}
                                            key={column.id}
                                        >{column.name}</th>
                                    );
                                }

                                return (
                                    <th
                                        // key={column.id}
                                        draggable
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}

                                        onMouseOver={() => setColumnId(column.id)}
                                        onMouseOut={() => setColumnId('')}
                                        className={isDraggingRow ? 'dragging-row' : ''}

                                        style={{
                                            width:
                                                // column?.width
                                                column?.id == 1 && '150px' ||
                                                column?.id == 2 && '120px' ||
                                                column?.id == 3 && '280px' ||
                                                column?.id == 4 && '90px' ||
                                                column?.id == 6 && '80px'
                                        }}
                                    >
                                        <div className='d-flex justify-content-center'>
                                            {column.name}
                                        </div>
                                        <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                            <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                            </div>
                                        </div>

                                    </th>
                                );
                            })}
                            <th style={{ width: '70px' }}>
                                Action
                            </th>
                            <th className='addcol_btn_th'
                                style={{ width: '73px' }}
                            >
                                <div className="dropdown">
                                    <button className="btn bg-transparent p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                    </button>
                                    <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton">
                                        {dropdown_columns && dropdown_columns.map((val, ind) => {
                                            return (
                                                <div key={val?.id}>
                                                    <ControlPointIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }} onClick={() => addColumn(val)}>{val?.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => (
                            <>
                                <tr
                                    key={row.id}
                                    draggable
                                    onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                    onDragOver={handleRowDragOver}
                                    onDrop={(e) => handleRowDrop(e, rowIndex)}
                                    handleDragEnd={handleDragEnd}
                                    className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                                >
                                    <td>
                                        <div className="form-check ps-4 text-center">
                                            <input type="checkbox" value="" id="flexCheckDefault"
                                                className="form-check-input checkbox_btn"
                                                checked={selectedRows.includes(row.id)}
                                                onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                                style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                            />
                                        </div>
                                    </td>

                                    {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                        const var_name = cellValue?.id == 3 ? cellValue?.variablePriceName : '';
                                        let name_arr = var_name.split("-");
                                        // setVarFrom(name_arr[0]);
                                        // setVarTo(name_arr[1]);

                                        return (
                                            <>
                                                {cellValue.id === 1 &&
                                                    <td key={columnIndex}
                                                        id={`sticky_col${columnIndex}`}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        {cellValue?.priceType}
                                                    </td>
                                                }

                                                {cellValue.id === 2 &&
                                                    <td key={columnIndex}
                                                        style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                    >
                                                        {priceData[1]?.currency}
                                                    </td>
                                                }

                                                {cellValue.id === 3 &&
                                                    <>
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                        >
                                                            {cellValue?.id == 3 && cellValue?.variablePriceName?.split("-")[0]}

                                                        </td>
                                                        <td key={columnIndex}
                                                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                        >
                                                            {cellValue?.id == 3 && cellValue?.variablePriceName?.split("-")[1]}

                                                        </td>
                                                    </>
                                                }


                                                {cellValue.id === 4 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        {cellValue?.servicePrice}
                                                    </td>
                                                }


                                                {cellValue.id === null &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        className='text-center'
                                                    >

                                                    </td>
                                                }


                                                {cellValue.id === 7 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <input type='text' placeholder='' value='' />
                                                    </td>
                                                }

                                                {cellValue.id === 8 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        <select >
                                                            <option value="" className='w-100'>Select</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </td>
                                                }

                                                {cellValue.id === 9 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='number' placeholder='' value={''} />
                                                    </td>
                                                }

                                                {cellValue.id === 10 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <input type='file' placeholder='' value={''} />
                                                    </td>
                                                }

                                            </>
                                        )
                                    })}
                                    <td className='text-center'
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                        <div className="dropdown" style={{ positition: 'relative' }}>
                                            <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                style={{ zIndex: '0' }}
                                            >
                                                <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                                            </button>
                                            <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                                                style={{ zIndex: '4' }}
                                            >
                                                <div className='py-1'
                                                    onClick={() => getEditData(row?.id)}
                                                >
                                                    <FaRegEdit className='text-warning' style={{ fontSize: '18px' }}

                                                    />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Edit Row</span>
                                                </div>
                                                <div className='py-1'
                                                    onClick={() => { deleteSelectedRows(row?.id) }}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }}
                                                    />
                                                    <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                    >Delete Row</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                    >
                                    </td>
                                </tr>

                                {paginatedRows.length - 1 === rowIndex &&
                                    <tr className='sticky_row'>
                                        <td>
                                        </td>
                                        {row?.data?.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                            return (
                                                <>
                                                    {cellValue.id === 1 &&
                                                        <td key={columnIndex}
                                                            id={`sticky_col${columnIndex}`}
                                                        >
                                                            <select
                                                                onChange={(e) => setVarType(e.target.value)}
                                                                value={varType}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="size">Size</option>
                                                                <option value="color">Color</option>
                                                                <option value="shape">Shape</option>                                                </select>
                                                        </td>
                                                    }


                                                    {cellValue.id === 2 &&
                                                        <td key={columnIndex}
                                                            id={`sticky_col${columnIndex}`}
                                                        >
                                                            <input type='text' placeholder=''
                                                                value={priceData[1]?.currency}
                                                            />
                                                        </td>
                                                    }

                                                    {cellValue.id === 3 &&
                                                        <td key={columnIndex}
                                                        >
                                                            <select
                                                                onChange={(e) => setVarFrom(e.target.value)}
                                                                value={varFrom}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </td>
                                                    }

                                                    

                                                    {cellValue.id === 4 &&
                                                        <td key={columnIndex}
                                                        >
                                                            <select
                                                                onChange={(e) => setVarTo(e.target.value)}
                                                                value={varTo}
                                                            >
                                                                <option value="" className='w-100'>Select</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </td>
                                                    }

                                                    {cellValue.id === 5 &&
                                                        <td key={columnIndex}>
                                                            <input type='text' placeholder=''
                                                                value={varPrice}
                                                                onChange={(e) => setVarPrice(e.target.value)}
                                                            />
                                                        </td>
                                                    }

                                                </>
                                            )
                                        })}
                                        <td className='text-center'
                                            style={{ position: 'relative' }}
                                        >
                                            {store?.miniLoading &&
                                                <BntLoader />
                                            }
                                            <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                                style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                                onClick={postData}
                                            >Add</button>
                                        </td>

                                        <td>
                                        </td>
                                    </tr>
                                }
                            </>
                        ))}

                        {paginatedRows.length == 0 &&
                            <tr id='table_form_row'>
                                <td>
                                </td>
                                {defaultColumns?.length > 0 && defaultColumns?.map((cellValue, columnIndex) => {
                                    return (
                                        <>
                                            {cellValue.id === 1 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                >
                                                    <select
                                                        onChange={(e) => setVarType(e.target.value)}
                                                        value={varType}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="size">Size</option>
                                                        <option value="color">Color</option>
                                                        <option value="shape">Shape</option>                                                </select>
                                                </td>
                                            }


                                            {cellValue.id === 2 &&
                                                <td key={columnIndex}
                                                    id={`sticky_col${columnIndex}`}
                                                >
                                                    <input type='text' placeholder=''
                                                        value={priceData[1]?.currency}
                                                    />
                                                </td>
                                            }

                                            {cellValue.id === 3 &&
                                                <td key={columnIndex}
                                                >
                                                    <select
                                                        onChange={(e) => setVarFrom(e.target.value)}
                                                        value={varFrom}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 4 &&
                                                <td key={columnIndex}
                                                >
                                                    <select
                                                        onChange={(e) => setVarTo(e.target.value)}
                                                        value={varTo}
                                                    >
                                                        <option value="" className='w-100'>Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </td>
                                            }

                                            {cellValue.id === 5 &&
                                                <td key={columnIndex}>
                                                    <input type='text' placeholder=''
                                                        value={varPrice}
                                                        onChange={(e) => setVarPrice(e.target.value)}
                                                    />
                                                </td>
                                            }

                                        </>
                                    )
                                })}
                                <td className='text-center'
                                    style={{ position: 'relative' }}
                                >
                                    {store?.miniLoading &&
                                        <BntLoader />
                                    }
                                    <button className='border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light'
                                        style={{ opacity: store?.miniLoading ? '.4' : '1' }}
                                        onClick={postData}
                                    >Add</button>
                                </td>

                                <td>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className='row pc_display mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <select
                        className='items_per_page_select'
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option disabled >Items Per page</option>
                        <option value={5} selected>5</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={rows?.length}>All</option>
                    </select>

                    <Stack spacing={2} className=''>
                        <Pagination
                            count={Math.ceil(rows.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                        />
                    </Stack>
                </div>
            </div>
            <hr className='mt-2' />

            <div className='pc_display'>
                <IconButton aria-label="Back"
                    onClick={() => renderVarbilesList()}
                    className=''
                    sx={{ backgroundColor: 'lightgray', boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.2)' }}
                >
                    <ArrowBack />
                </IconButton>
            </div>
        </>
    );
};

export default VariableList;
