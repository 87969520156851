import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { registerapi } from "../../../Store/Auth/actions";
import Headerauth from "../Headerauth/Headerauth";
import "./Login.scss";
import { DEVICE_ID } from "../../../Constants/ImgConstants";
import InputTextField from "../../BusinessProfile/TextField";
import { validateEmail } from "../../../utils/helper";
import { useHistory } from "react-router-dom";
import {customSnackBar} from "../../../utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Login = (props) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailInputRef = useRef(null);
  const history = useHistory();
  // const [data, setData] = useState([]);
  // let history = useHistory();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValid(validateEmail(e.target.value));
  };

  const handleSubmit = async () => {
    // Verify ReCaptcha token before submitting the form
    await handleReCaptchaVerify();
    if(token){
  
    // Check if the token is available
      dispatch(
        registerapi({
          email: email,
          countryId: 188,
          deviceId: DEVICE_ID,
          raptchaToken: token?token:"string",
          setIsLoading,
        })
      );
      }
  };
  
  const handleEnterKey = (e) => {
    if (e.key === "Enter" && isValid) {
      e.preventDefault(); // Prevent form submission
      handleSubmit(); // Automatically trigger the button click
    }
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   console.log(token,"sucessssssucesssss")
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);
useEffect(()=>{
  emailInputRef.current.focus() 
},[]);


const handleForgetPassword = () => {
 if(!isValid) {
  customSnackBar("Please Enter Email");
 } else {
  
 }
}

  return (
    <div className="login_height" >
      <div className="container mb-5 pb-5">
          <div className="container  paddings ">
            <div className="text-center alignment_vertically">
              <img
                className="img-fluid img_plexar_width"
                src="../images/plexaar final 2 round-15 1.png"
                alt="no-data"
                style={{ width: "100px", height: "100px" }}
              />
              <div>
                <img
                  className="img-fluid img_plexar_width pb-3"
                  src="../images/plexaar text-22 1.png"
                  alt="no-data"
                  style={{ width: "111px", height: "46" }}
                />
              </div>
              <h3>
                <b>
                  Welcome to <span className="plexar_font">Plexaar</span>
                </b>
              </h3>
              <p className="sub_heading pt-2 text-wrap">
                To keep connected with us please continue with your
                personal information by email and password.
              </p>
              <div className="col-md-12">
                <div className="p-2 pt-4">
                  {/* <InputTextField
                    id="email"
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    size={"small"}
                    variant="filled"
                    value={email}
                    onChange={handleEmailChange}
                    type="email"
                    sx={{
                      // maxWidth: "300px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                  /> */}
                  <InputTextField
                    id="email"
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    variant="filled"
                    size="small"
                    type="email"
                    value={email}
                    autoFocus
                     onKeyDown={handleEnterKey}
                    inputRef={emailInputRef}
                    onChange={handleEmailChange}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    // endAdornment={
                    //   <InputAdornment position="end">
                    //     <IconButton
                    //       onClick={toggle}
                    //       edge="end"
                    //       className="pe-3"

                    //       aria-label="toggle password visibility"
                    //     >
                    //       {isVisible ? (
                    //         <img
                    //           className="img-fluid img_plexar_width"
                    //           src="../images/eye_open.svg"
                    //           alt="no-data"
                    //           style={{ width: "20px", height: "20px" }}
                    //         />
                    //       ) : (
                    //         <img
                    //           className="img-fluid img_plexar_width"
                    //           src="../images/eye_open.svg"
                    //           alt="no-data"
                    //           style={{ width: "20px", height: "20px" }}
                    //         />
                    //       )}
                    //     </IconButton>
                    //   </InputAdornment>
                    // }
                  />
                </div>
              
                {
                  <button
                    className={`btn btn-primary pb-3 ${
                      !isValid ? "button_width_disabled" : "button_width"
                    } btn-lg`}
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>Next</>
                    )}
                  </button>
                }
              </div>
            </div>
          </div>
      </div>
      <div className="bottom_attach px-3 pt-2 center_copyright">
        <div className="show_privacy_policy">
          <p className="sub_heading2" role="button" onClick={()=>history.push("/privacy")}>Privacy Policy</p> &nbsp; &nbsp; &nbsp;
          <p className="sub_heading2" role="button" onClick={()=>history.push("/terms")}>Terms & Conditions</p>
        </div>
       <p className="sub_heading2">
          Copyright © 2023 Plexaar. All rights reserved.
        </p>
      </div>
    </div>
  );
};
export default Login;
