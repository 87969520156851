import React from "react";
import Features from "../Features/Features";
import ContactUs from "../ContactUs/ContactUs";
import AiPoweredSystem from "../AiPoweredSystem/AiPoweredSystem";
import Hero from "../Hero/Hero";
import NewsLatter from "./../../../HRManagement/components/NewsLatter/NewsLatter";
import Footer from "../Footer/Footer";
import { Header } from "../../../../Hero/Header";
import {
  CalendexWeb,
  CalendexMobile,
  inventorySection1,
  inventorySection2,
} from "../../../../../../Assets/components/HomePage/Assets";

const Home = () => {
  return (
    <div>
      <Header
        title={"Calendex"}
        heading={"AI-Powered Booking System for All Businesses"}
        text={
          "Empower your business with a feature-rich booking system that automates scheduling, manages clients efficiently, intelligently tracks clients, handles staff management & enables effective marketing strategies."
        }
        imgWeb={CalendexWeb}
        imgMob={CalendexMobile}
      />
      <Features inv1={inventorySection1} inv2={inventorySection2} />
      <ContactUs />
      <AiPoweredSystem />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home;
