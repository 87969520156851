import ManageTeam from "../Pages/BusinessProfile/Team/ManageTeam";
import TeamColumnList from "../Pages/BusinessProfile/Team/ColumnList";
import AddTeam from "../Pages/BusinessProfile/Team/AddTeam";
import ManageDepartments from "../Pages/BusinessProfile/Department/MangeDepartments";
import DepartmentColumnList from "../Pages/BusinessProfile/Department/ColumnList";
import AddDepartment from "../Pages/BusinessProfile/Department/AddDepartment";

export const TeamAndDepartmentRoutes = [
  {
    path: "/team",
    component: ManageTeam,
    ispublic: false,
    exact: true,
  },
  {
    path: "/team/columns-list",
    component: TeamColumnList,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-team",
    component: AddTeam,
    ispublic: false,
    exact: true,
  },
  {
    path: "/department",
    component: ManageDepartments,
    ispublic: false,
    exact: true,
  },
  {
    path: "/department/columns-list",
    component: DepartmentColumnList,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-department",
    component: AddDepartment,
    ispublic: false,
    exact: true,
  },
];
