import React, { useState } from "react";
import "../../appointmentDetails.css";
import { LowerAngle, RightAngle } from "../../Svgs/svgs";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { customSnackBar } from "../../../../../utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { slotAxios } from "../../../../axios";
import { useSelector } from "react-redux";
import DialogBox from "../../../../../Pages/BusinessProfile/DialogBox";
import { ClipLoader } from "react-spinners";
import instance from "../../../../../utils/utils";

const RightSection = ({
  bookingData,
  handleCheckIn,
  handleCreated,
  handleCompleteJob,
  handleStartJob,
  ComplteteBooking,
  isLoadingCheckin,
  isLoadingCreated,
  isLoadingJob,
  isLoadingShow,
  handleNoShow,
  permission,
}) => {
  const initialOptions = [
    { value: "created", label: "Created", selected: true, disabled: true },
    {
      value: "isCheckedIn",
      label: "Is Checked In",
      selected: false,
      disabled: false,
    },
    {
      value: "is_job_started",
      label: "Is Job Started",
      selected: false,
      disabled: true,
    },
    {
      value: "isCompleted",
      label: "Is Completed",
      selected: false,
      disabled: true,
    },
    { value: "no_show", label: "No Show", selected: false, disabled: false },
  ];
  const checkInOptions = [
    
    {
      value: "isCheckedIn",
      label: "Is Checked In",
      selected: true,
      disabled: true,
    },
    {
      value: "created",
      label: "Created",
      selected: false,
      disabled: false,
    },
    {
      value: "is_job_started",
      label: "Is Job Started",
      selected: false,
      disabled: false,
    },
    {
      value: "isCompleted",
      label: "Is Completed",
      selected: false,
      disabled: true,
    },
    { value: "no_show", label: "No Show", selected: false, disabled: false },

  ];
  const jobStartedOptions = [
    {
      value: "is_job_started",
      label: "Is Job Started",
      selected: true,
      disabled: true,
    },
    {
      value: "isCompleted",
      label: "Is Completed",
      selected: false,
      disabled: false,
    },
  ];
  const jobCompletedOptions = [
    {
      value: "isCompleted",
      label: "Is Completed",
      selected: true,
      disabled: true,
    },
  ];
  const NoShowOptions = [
    {
      value: "no_show",
      label: "No Show",
      selected: true,
      disabled: true,
    },
    {
      value: "created",
      label: "Created",
      selected: false,
      disabled: false,
    },
  ];
  const history = useHistory();
  const { id } = useParams();
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
  const [dialog, setDialog] = useState({ visible: false });

  const handleUpdateBooking = () => {
    if (
      permission.canEditBooking ||
      permission.canEditBooking === null ||
      permission.canEditBooking === undefined
    ) {
      const date = bookingData?.data?.bookingDate;
      const currentDate = new Date();
      const datetime = new Date(date);
      if (datetime < currentDate) {
        customSnackBar("Booking is completed");
      } else {
        if (
          bookingData?.appointments[0]?.status === "is_job_started" ||
          bookingData?.appointments[0]?.status === "isCompleted"
        ) {
          customSnackBar("You cannot Modify the Booking");
        } else {
          history.push(`/scheduler/booking_update/${id}`, bookingData);
        }
      }
    } else {
      customSnackBar("You haven't Permission to Modify the Booking");
    }
  };

  const handleUpdateAlert = () => {
    if (bookingData?.appointments[0]?.status === "isCheckedIn") {
      customSnackBar("You cannot Make change in  the Booking");
    } else {
      alert("Already Completed");
    }
  };

  const cancelBooking = async () => {
    try {
      const data = {
        businessId: bookingData?.businessId,
        bookingId: bookingData?.plexaarBookingId,
        plexaarAppointmentId:
          bookingData?.appointments[0]?.plexaarAppointmentId || 0,
        expertAppointmentId:
          bookingData?.appointments[0]?.expertAppointmentId || 0,
        sku:
          bookingData?.attributes && bookingData?.attributes.length > 0
            ? bookingData?.attributes[0].attributeSku
            : bookingData?.serviceSKU,
        oldDate: {
          date: bookingData?.appointments[0]?.bookingDate,
          timeFrom: `${bookingData.appointments[0]?.timeFrom}`,
          timeTo: `${bookingData.appointments[0]?.timeTo}`,
        },
        newDate: {
          date: bookingData?.appointments[0]?.bookingDate,
          timeFrom: `${bookingData.appointments[0]?.timeFrom}`,
          timeTo: `${bookingData.appointments[0]?.timeTo}`,
        },
        isCancel: true,
        providerIdNew: bookingData?.providerId,

        salesOrderId: bookingData?.plexaarSalesOrderId,
        isExpert: bookingData?.isExpert,
        providerId: bookingData?.providerId,
        modifiedBy: `${userInfo?.firstName} ${userInfo?.lastName}`,
        customerId: bookingData.isExpert
        ? bookingData?.customerId
        : bookingData?.plexaarcustomerId,         timeZone: "Europe/London",
      };

      // Make the asynchronous request
      const response = await instance.post(
        `/provideravailability_svc/pb/edit/booking-slot`,
        data
      );

      // Check if the request was successful
      if (response.data.result.response.code === 0) {
        customSnackBar(response.data.result.response.message);
        // Optionally, dispatch additional actions or perform other actions on success
        window.window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push(`/calendar`);
      } else {
        // Handle unexpected response status
        console.error("Unexpected response status:", response.status);
        customSnackBar(
          "An unexpected error occurred while updating the booking."
        );
      }
    } catch (error) {
      // Handle any exceptions that occurred during the execution
      console.error("Error updating booking slot:", error);
      customSnackBar(
        error.message || "An error occurred while updating the booking."
      );
    }
  };

  const handleBookingCancelConfirmation = (id) => {
    if (
      permission.canCancelBooking ||
      permission.canCancelBooking === null ||
      permission.canCancelBooking === undefined
    ) {
      const date = bookingData?.data?.bookingDate;
      const currentDate = new Date();
      const datetime = new Date(date);
      if (datetime < currentDate) {
        customSnackBar("Booking is completed");
        // dispatch(
        //   openToast({ message: "Booking is completed", status: "warning" })
        // );
      } else {
        if (
          bookingData?.appointments[0]?.status === "is_job_started" ||
          bookingData?.appointments[0]?.status === "isCompleted"
        ) {
          customSnackBar("You cannot Cancel the Booking");
        } else {
          setDialog({
            visible: true,
            key: Math.random().toString(36).substring(7),
            ok: () => cancelBooking(),
          });
        }
      }
    } else {
      customSnackBar("You haven't Permission to cancel the Booking");
    }
  };

  const data = {
    OrderNumber: "14658e4",
    AppointmentNumber: "14658e4",
    BookingNumber: "2",

    AppointmentStatus: "Confirmed",
  };

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = checkInOptions.find(opt => opt.value === selectedValue);
    console.log(selectedOption,"selectedOptionselectedOption")
    if (bookingData?.appointments[0]?.status === "created") {
       if(selectedOption.value === "isCheckedIn"){
        handleCheckIn()
      }
      else if(selectedOption.value === "no_show"){
        handleNoShow();
      }
    } else if (bookingData?.appointments[0]?.status === "is_job_started") {
      handleCompleteJob();
    } else if (bookingData?.appointments[0]?.status === "isCheckedIn") {
      if (selectedOption.value === "created") {
        handleCreated();
      }
      else if(selectedOption.value === "is_job_started"){
        handleStartJob();
      }
      
      
    } else if (bookingData?.appointments[0]?.status === "no_show") {
      if (selectedOption.value === "created") {
        handleCreated();
      }

      customSnackBar("The Provider is not Available");
    } else {
      ComplteteBooking();
    }
  };
  return (
    <>
      <DialogBox
        {...dialog}
        header={"Cancel Booking"}
        message={"Are you Sure you want to Cancel the Booking?"}
      />

      <div className="left-section">
        <div className="d-flex justify-content-center">
          
          <Button
            disabled={bookingData?.appointments[0]?.status === "no_show"}
            sx={{
              textTransform: "capitalize",
              width: "100%",
              backgroundColor:
                bookingData?.appointments[0]?.status === "created"
                  ? "#F5BF00"
                  : bookingData?.appointments[0]?.status === "isCheckedIn"
                  ? "#FFCA2C"
                  : bookingData?.appointments[0]?.status === "isCompleted"
                  ? "#00aa74"
                  : "#F5BF00", // Default color, update it as needed
              height: "40px",
              borderRadius: "5px",
              marginTop: "20px",
            }}
            onClick={() => {
              if (bookingData?.appointments[0]?.status === "created") {
                handleCheckIn();
              } else if (
                bookingData?.appointments[0]?.status === "is_job_started"
              ) {
                handleCompleteJob();
              } else if (
                bookingData?.appointments[0]?.status === "isCheckedIn"
              ) {
                handleStartJob();
              } else if (bookingData?.appointments[0]?.status === "no_show") {
                customSnackBar("The Provider is not Available");
              } else {
                ComplteteBooking();
              }
            }}
            variant="contained"
          >
            {bookingData?.appointments[0]?.status === "created" ? (
              isLoadingCheckin ? (
                <ClipLoader color="white" />
              ) : (
                "Check In"
              )
            ) : bookingData?.appointments[0]?.status === "isCheckedIn" ? (
              isLoadingJob ? (
                <ClipLoader color="white" />
              ) : (
                "Start Job"
              )
            ) : bookingData?.appointments[0]?.status === "is_job_started" ? (
              "Complete Booking"
            ) : bookingData?.appointments[0]?.status === "isCompleted" ? (
              "Job Completed"
            ) : bookingData?.appointments[0]?.status === "no_show" ? (
              "No Show"
            ) : null}
          </Button>
          
          
        </div>

        <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="Order-details_quantity">
              <label className="quantity_heading">Appointment Number</label>
              <span className="quantity_number">
                {bookingData.plexaarBookingId}
              </span>
            </div>
          </div>
        </div>

        <div className="order-details-section-container mt-3">
          <div className="Order-details_order-num">
            <div className="d-flex justify-content-between align-items-baseline">
              <span
                onClick={(e) => {
                  bookingData?.appointments[0]?.status === "isCompleted"
                    ? handleUpdateAlert()
                    : handleUpdateBooking();
                }}
                className="appointment-action-btns py-1"
              >
                Modify Appointment
              </span>
              <RightAngle />
            </div>
          </div>
          <hr className="left-section-container-lines " />
          <div className="Order-details_order-num">
            <div className="d-flex justify-content-between align-items-baseline">
              <span
                onClick={(e) => {
                  bookingData?.appointments[0]?.status === "isCompleted"
                    ? handleUpdateAlert()
                    : handleBookingCancelConfirmation();
                }}
                className="appointment-action-btns py-1"
              >
                Cancel Appointment
              </span>
            </div>
          </div>
          <hr className="left-section-container-lines" />
          {bookingData.visits > 1 ? (
            <div
              className="Order-details_order-num"
              onClick={() => history.push("/book-next-visit")}
            >
              <div className="d-flex justify-content-between align-items-baseline">
                <span className="appointment-action-btns py-1">
                  Book Next Visit
                </span>
                <RightAngle />
              </div>
            </div>
          ) : null}
        </div>

        <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="Order-details_quantity">
              <label className="quantity_heading">Appointment Status</label>
              <div className="d-flex justify-content-between align-items-baseline">
                <select
                  className="w-100 appointment-status-dropdown"
                  onChange={handleStatusChange}
                >
                  {bookingData?.appointments[0]?.status === "created"
                    ? initialOptions.map((opt) => (
                        <option
                          selected={opt.selected}
                          disabled={opt.disabled}
                          value={opt.value}
                        >
                          {opt.label}
                        </option>
                      ))
                    : bookingData?.appointments[0]?.status === "isCheckedIn"
                    ? checkInOptions.map((opt) => (
                        <option
                          selected={opt.selected}
                          disabled={opt.disabled}
                          value={opt.value}
                        >
                          {opt.label}
                        </option>
                      ))
                    : bookingData?.appointments[0]?.status === "is_job_started"
                    ? jobStartedOptions.map((opt) => (
                        <option
                          selected={opt.selected}
                          disabled={opt.disabled}
                          value={opt.value}
                        >
                          {opt.label}
                        </option>
                      ))
                    : bookingData?.appointments[0]?.status === "isCompleted"
                    ? jobCompletedOptions.map((opt) => (
                        <option
                          selected={opt.selected}
                          disabled={opt.disabled}
                          value={opt.value}
                        >
                          {opt.label}
                        </option>
                      ))
                    : NoShowOptions.map((opt) => (
                      <option
                        selected={opt.selected}
                        disabled={opt.disabled}
                        value={opt.value}
                      >
                        {opt.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="Order-details_quantity">
              <label className="quantity_heading">Booking Number</label>
              <span className="quantity_number">{data.BookingNumber}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSection;
