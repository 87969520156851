import React, { useState, useEffect } from "react";

import Table from "./Table";
import { OnSearch, team } from "./helper";
import { teamList } from "../../../utils/constant";
import Header from "../Header";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTeamRequest, fetchTeamRequest } from "../../../Store/Team/actions";
import SideNavone from "../../../SideNavBar"
import MenuTabList from "../CustomTabs/MenuTabList";
import NewList from "./NewList";
import '../../../Assets/components/meetspace/events.scss'
import NewListMobile from "./NewListMobile";

const ManageTeam = () => {
  const flag = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const departmentRecord = location && location.state;
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.Team?.data);
  // const data = useSelector((state) => state.Team?.data);
  const loading = useSelector((state) => state.Team?.loading);

  const removeBusiness = (id) => {
    dispatch(
      deleteTeamRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };

  //Delete busienss Confirmation Dialouge Box handler
  const handleTeamDeleteConfirmation = (id) => {
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  };
  const department = JSON.parse(localStorage.getItem("departmentRecord"))
  useEffect(() => {
    if (department) {
      dispatch(fetchTeamRequest(department.id));
    }
  }, []);
  useEffect(() => {
    setRowData(data || []);
  }, [data]);
  return (
    <>
      <SideNavone data={data}
            OnSearch={OnSearch}
            rowData={rowData}
            flag={flag}
            record={departmentRecord}
            setRowData={setRowData}
            heading={teamList.TEAM}
            buttonText={teamList.ADD_TEAM}
            link="/add-team">
        <div>
          {/* <Header
            data={data}
            OnSearch={OnSearch}
            rowData={rowData}
            flag={flag}
            record={departmentRecord}
            setRowData={setRowData}
            heading={teamList.TEAM}
            buttonText={teamList.ADD_TEAM}
            link="/add-team"
          /> */}
        </div>
        {/* <MenuTabList type="teamList" /> */}

        <div className=" p-md-4 hide_scrollbar min-h-[250px] table-container">
          {loading ? (
            <SkeletonTable />
          ) : (
            <Table
              loading={loading}
              setRecord={setRowData}
              data={rowData}
              dialog={dialog}
              deleteTeam={handleTeamDeleteConfirmation}
            />
            // <>
            //   <NewList
            //     loading={loading}
            //     data={data}
            //   />
            //   <NewListMobile
            //     loading={loading}
            //     data={data}
            //   />
            // </>
          )}
        </div>
      </SideNavone>
    </>
  );
};
export default ManageTeam;
