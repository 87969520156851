// Dynamic field types
export const SMALL_TEXT_QUESTION = "SmallTextQuestion";
export const LONG_TEXT_QUESTION = "LongTextQuestion";
export const DATE_QUESTION = "DateQuestion";
export const EMAIL_QUESTION = "EmailQuestion";
export const NUMBER_QUESTION = "NumberQuestion";
export const DROPDOWN_QUESTION = "DropdownQuestion";
export const MCQ_QUESTION = "MCQQuestion";
export const STATEMENT_QUESTION = "StatementQuestion";
export const LABEL_QUESTION = "LabelQuestion";
export const SIGNATURE_QUESTION = "SignatureQuestion";
export const VIDEO_DOCUMENT_QUESTION = "VideoDocumentQuestion";
export const AUDIO_DOCUMENT_QUESTION = "AudioDocumentQuestion";
export const DOCUMENT_QUESTION = "DocumentQuestion";
export const IMAGE_QUESTION = "ImageQuestion";

// Constant API for getting URL's against documents
export const DOCUMENT_TO_URL_STAGING =
  "https://expertgateway-staging.findanexpert.net/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment";

// Constants
export const DISABLED = "disabled";
export const COMPLETED = "completed";
export const IN_PROGRESS = "in progress";
export const CUSTOMER_ID = "customerId";
export const CURRENT_STEP_ID = "currentStepId";
export const NEXT_STEP_ID = "nextStepId";
export const NUM_ZERO = 0;
export const NUM_ONE = 1;
