import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowBack, Close } from "@mui/icons-material";
import { postRequest } from "../../../request";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { IconButton } from "@mui/material";
import { getAllGroups } from "../../../store/servicesActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BntLoader } from "../../../../../Componenets/loaders";
import { base_url } from "../../../base_url";
import {
  disableMiniLoader,
  enableMiniLoader,
} from "../../../store/ServicesSlice";

function GroupForm({ reFetchGroups, addGroupFunc }) {
  const [serviceSelect, setServiceSelect] = useState(null);
  const [multipleSelected, setMultipleSelected] = useState([]);
  const [selectedServicesList, setSelectedServicesList] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([{ label: "", id: "" }]);
  const [searchValue, setSearchValue] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    type: "",
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.servicesReducer);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleServiceSelect = (event, selectedOption) => {
    if (selectedOption === null) {
      setMultipleSelected([]);
      setServiceSelect(null);
    } else {
      setMultipleSelected([
        ...multipleSelected,
        { serviceId: selectedOption.id },
      ]);
      setServiceSelect({
        label:
          selectedOption.label.props.children.props.children.props.children[0]
            .props.children[1].props.children,
        id: selectedOption.id,
      });
    }
  };

  const postData = async () => {
    if (formData?.name && formData?.location && formData?.type) {
      dispatch(enableMiniLoader());
      const data = {
        ...formData,
        services: multipleSelected,
      };
      const res = await postRequest(`${base_url}/Package/AddGroup`, data);
      if (res?.message === "succeeded") {
        dispatch(disableMiniLoader());
        dispatch(getAllGroups());
        addGroupFunc();
        alert("New Group added successfully");
      } else {
        alert("Something went wrong");
      }
    }
    // const res = await postRequest(`${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pb/Package/AddGroup`, data);
    // if (res?.message === "succeeded") {
    //     dispatch(getAllGroups())
    //     addGroupFunc()
    //     alert('New Group added successfully');
    // }
  };

  useEffect(() => {
    if (searchValue !== "") {
      const filteredServices = store?.allServices.filter((service) => {
        return service.serviceName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      const newOptions =
        filteredServices &&
        filteredServices.map((service) => ({
          id: service.serviceId,
          url: service.serviceUrl,
          label: (
            <>
              <div className="container" key={"9"}>
                <div className="row">
                  <div className="col-6">
                    <img
                      src="/logo192.png"
                      style={{ height: "30px", width: "30px" }}
                    />
                    <span className="ms-2">{service.serviceName}</span>
                  </div>
                  <div className="col-3">
                    <span className="">{service.duration} Min</span>
                  </div>
                  <div className="col-3">
                    <span className="">£{service.actualPrice}</span>
                  </div>
                </div>
              </div>
            </>
          ),
        }));

      setServiceOptions([...newOptions]);
    } else if (!searchValue && store?.allServices.length > 0) {
      const newOptions = store?.allServices.map((service) => ({
        id: service.serviceId,
        url: service.serviceUrl,
        label: (
          <>
            <div className="container" key={"9"}>
              <div className="row">
                <div className="col-6">
                  <img
                    src="/logo192.png"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <span className="ms-2">{service.serviceName}</span>
                </div>
                <div className="col-3">
                  <span className="">{service.duration} Min</span>
                </div>
                <div className="col-3">
                  <span className="">£{service.actualPrice}</span>
                </div>
              </div>
            </div>
          </>
        ),
      }));
      setServiceOptions((prevOptions) => [...prevOptions, ...newOptions]);
    }
  }, [store?.allServices, searchValue]);

  useEffect(() => {
    if (multipleSelected.length > 0) {
      const filteredServices = store?.allServices.filter((obj1) => {
        return multipleSelected.some(
          (obj2) => obj2.serviceId == obj1?.serviceId
        );
      });
      setSelectedServicesList(filteredServices);
    }
  }, [multipleSelected]);

  useEffect(() => {
    dispatch(disableMiniLoader());
  }, []);

  return (
    <div className="container mt-3" style={{ minHeight: "100vh" }}>
      <IconButton
        aria-label="Back"
        onClick={() => addGroupFunc()}
        className=""
        sx={{
          position: "absolute",
          bottom: "-35px",
          left: "5px",
          backgroundColor: "lightgray",
          boxShadow: "0px 0px 5px 5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <ArrowBack />
      </IconButton>

      <div className="row">
        <div className="col-sm-6 col-12">
          <TextField
            className="w-100"
            id="outlined-basic"
            label="Group Name"
            variant="outlined"
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
          />
        </div>
        <div className="col-sm-6 col-12 mt-sm-0 mt-2">
          <TextField
            className="w-100"
            id="outlined-basic"
            label="Type"
            variant="outlined"
            onChange={(e) => {
              setFormData({ ...formData, type: e.target.value });
            }}
          />
        </div>
      </div>

      <div className="row mt-sm-3 mt-2">
        <div className="col-sm-6 col-12">
          <TextField
            className="w-100"
            id="outlined-basic"
            label="Location"
            variant="outlined"
            onChange={(e) => {
              setFormData({ ...formData, location: e.target.value });
            }}
          />
        </div>

        <div className="col-sm-6 col-12 mt-sm-0 mt-2">
          <Autocomplete
            className=""
            disablePortal
            id="combo-box-demo"
            options={serviceOptions}
            value={serviceSelect}
            filterOptions={(serviceOptions, state) => serviceOptions}
            PaperProps={{
              style: {
                color: "red", // Replace 'red' with your desired label color
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{ color: "yellow" }}
                {...params}
                label="Services"
                onChange={handleSearchChange}
              />
            )}
            onChange={handleServiceSelect}
          />
        </div>
      </div>

      {selectedServicesList.length > 0 && (
        <div className="mt-4 selected_service_list pb-5">
          {selectedServicesList.map((v, i) => {
            return (
              <div
                className="row selected_row g-0 px-2 py-1"
                key={v?.serviceId}
              >
                <div className="col-6">
                  <img
                    src="/logo192.png"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <span className="ms-2">{v.serviceName}</span>
                </div>
                <div className="col-3">
                  <span className="">{v.duration} Min</span>
                </div>
                <div className="col-2">
                  <span className="">£ {v.actualPrice}</span>
                </div>
                <div className="col-1 text-end text-danger">
                  <button
                    className="btn"
                    onClick={() => {
                      let filtered = selectedServicesList.filter((elem) => {
                        return elem?.serviceId != v?.serviceId;
                      });
                      setSelectedServicesList(filtered);
                      let filterInd = multipleSelected.filter(
                        (val) => val?.serviceId != v?.serviceId
                      );
                      setMultipleSelected(filterInd);
                    }}
                  >
                    <Close />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <hr className="mt-4" />
      <div
        className="position-relative"
        style={{ position: "relative", width: "95px" }}
      >
        <button
          className="add_service_btn btn-sm my-0 mx-0 py-1 pe-4 px-1"
          // style={{ display: multipleSelected.length > 0 ? 'block' : 'none' }}
          onClick={postData}
        >
          <AddBoxIcon className="addbox_icon me-1" />
          Save
        </button>
        {store?.miniLoading && <BntLoader />}
      </div>
    </div>
  );
}

export default GroupForm;
