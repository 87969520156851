import axios from "axios";
import { logout } from "../Store/Auth/actions";
import { useDispatch } from "react-redux";
import { Logout, getCookiesData, removeAllCookies, removeAllLocalStorage, setCookiesData } from "./helper";
import { toast } from "react-toastify";
import { customSnackBar } from "utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    try {
      // Check if the URL contains "pv" and attach authorization token accordingly
      if (config.url.includes("/pv/")) {
        const authentication = getCookiesData("authtication");

        if (authentication?.token) {
          config.headers.authorization = `Bearer ${authentication?.token}`;
        }
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Initialize AbortController
const controller = new AbortController();

// Add a response interceptor
instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Check if the request URL contains "getnewRefreshToken"
      if (originalRequest.url.includes("getnewRefreshToken")) {
        // Reload the window
        window.location.reload();
        return Promise.reject(error);
      }

      try {
        const authentication = getCookiesData("authtication");

        const base64EncodedToken = btoa(authentication.refreshToken);

        const resp = await instance.get(
          `signup_svc/pb/users/getnewRefreshToken?tokenModel=${base64EncodedToken}`,
          { signal: controller.signal } // Pass the AbortController's signal to the request
        );

        if (
          resp.data.errorCode === 0 &&
          resp.data.result &&
          resp.data.result.jwtRefreshToken &&
          resp.data.result.jwtToken
        ) {
          const accessToken = resp?.data?.result.jwtToken;
          localStorage.setItem("token", accessToken);
          localStorage.setItem(
            "refreshToken",
            resp?.data?.result.jwtRefreshToken
          );
          setCookiesData(
            "authtication",
            JSON.stringify({
              token: accessToken,
              refreshToken: resp?.data?.result.jwtRefreshToken,
            })
          );
          instance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          // Retry the original request
          return instance(originalRequest);
        } else if (resp.data.errorCode === 403) {
          Logout();
          alert("Your Session has been Expired ");
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
      // Display toast for network errors
      // console.log(error,"errorerrorerror")
      // originalRequest(controller);

      // customSnackBar("Something went Wrong,Please try Again")
    }

    return Promise.reject(error);
  }
);

export default instance;

// Function to cancel the request using the AbortController
export const cancelRequest = () => {
  controller.abort();
};
