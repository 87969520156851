import React from "react";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
// import "../AddBusiness/AddBusiness.scss";
import { useSelector } from "react-redux";

const BusinessName = ({ action, editData }) => {
  var result = useSelector((state) => state?.Business?.result);
  const businessImage =
    action === "edit" ? editData?.imageUrl : result?.imageUrl;

  return (
    <>
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-1 pt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex ">
            {businessImage ? (
              <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                <Avatar
                  src={`${process.env.REACT_APP_IMAGE_URL}/Business/${result.id}_0.jpeg`}
                  alt={result?.name}
                  // src={businessImage}
                  // alt={"not-found"}
                  variant="rounded"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: 10,
                  }}
                />
              </div>
            ) : (
              <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                <Avatar
                  sx={{
                    bgcolor: deepPurple[500],
                    width: "30px",
                    height: "30px",
                    marginRight: 2,
                  }}
                >
                  B
                </Avatar>
              </div>
            )}

            <div className="d-flex justify-content-between ">
              <div
                style={{ textAlign: "left", textTransform: "capitalize" }}
                className="d-flex flex-column justify-content-start"
              >
                <span className="businessId">
                  {action === "edit" ? editData?.name : result?.name}
                </span>
                <span className="businessId">
                  ID: {action === "edit" ? editData?.id : result?.id}
                </span>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <span className=" businessId">
              {action === "edit" ? editData?.id : result?.businessType?.name}
            </span>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessName;
