import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAllEventsList } from '../../../../Store/MeetSpace/meetspaceActions';
import SideMenu from '../../side-menu';
import "../../../../Assets/components/meetspace/events.scss";
import Topbar from './components/Topbar';
import BookedEventsLView from './components/BookedEventsLView';
import BookedEventsGView from './components/BookedEventsGView';

const BookedEventsList = () => {
    const [renderView, setRenderView] = useState(1);
    const dispatch = useDispatch();

    const renderViewFunc = (viewId) => {
        setRenderView(viewId);
    }

    useEffect(() => {
        dispatch(getAllEventsList());
    }, []);
    return (
        <SideMenu topbar={<Topbar renderViewFunc={renderViewFunc} />}>
            {renderView === 1 ?
                <BookedEventsLView /> :
                <BookedEventsGView />
            }
        </SideMenu>
    )
}

export default BookedEventsList;