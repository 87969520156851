import React, { useEffect, useState } from "react";
import SideNavone from "../../../SideNavBar";
import axios from "axios";
import SkeletonTable from "../../BusinessProfile/SkeltonTableSimple/SkeltonTable";
import NewListForEvents from "../../../Componenets/list_for_events";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./event_listing.module.css";
import instance from "../../../utils/utils";

const EventsListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const options = { weekday: "long" };
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  useEffect(() => {
    const fetchEvents = () => {
      setIsLoading(true);
      const formattedDate = `${selectedDate.getFullYear()}-${String(
        selectedDate.getMonth() + 1
      ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(2, "0")}`;
      const record={
        providerId: [],
        bookingDateStart: formattedDate,
        is_business: true,
        bookingDateEnd:formattedDate,
        timeZone: "Europe/London",
        businessId:selectedBusiness?.id 
      }
      // axios
      //   .get(
      //     `${process.env.REACT_APP_BASE_URL}/events_svc/pb/events?businessId=${selectedBusiness?.id}&is_business=true&bookingDate=${formattedDate}&timeZone=Europe/London`
      //   )
        instance
        .post(
          `${process.env.REACT_APP_BASE_URL}/events_svc/pb/events`,record
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.result !== null) {
              setEvents(res.data.result);
            } else {
              alert(res.data.message);
            }
          } else {
            console.error("RESPONSE STATUS CODE:", res.status);
          }
        })
        .catch((e) => alert(e))
        .finally(() => setIsLoading(false));
    };
    fetchEvents();
  }, [selectedBusiness, selectedDate]);

  return (
    <SideNavone>
      <div className="px-5 py-3">
        <div className="mb-3 ps-2">
          <span className={styles.custom_date_picker}>
            {selectedDate.toLocaleDateString("en-US", options)}
          </span>
          <DatePicker
            closeOnScroll={true}
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd MMMM yyyy"
            disabledKeyboardNavigation
            className={`${styles.custom_date_picker} ms-3`}
          />
        </div>
        {/* {events && (
          <h3>
            Tuesday, 12 September 2023
          </h3>
        )} */}
        {isLoading && <SkeletonTable />}
        {isLoading || events?.providers?.length === 0 ? (
          <h3 className="text-center text-danger">No Provider Found</h3>
        ) : (
          <NewListForEvents data={events?.providers} />
        )}
      </div>
    </SideNavone>
  );
};
export default EventsListing;
