import React, { useState, useEffect } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import InputTextField from '../TextField';

import Button from '@mui/material/Button';
import Header from '../Header';
import { userList } from '../../../utils/constant';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRoleRequest,
  allowPermissionRequest
} from '../../../Store/User/actions';
import { useHistory } from 'react-router-dom';
import SideNavone from '../../../SideNavBar';
import { Autocomplete, Switch } from '@mui/material';
import { CustomTextField } from '../../../utils';

const Role = ({
  onClose,
  selectedOptions,
  service,
  businessData,
  onRoleCreated
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const permission = useSelector((state) => state.User?.permissionList);
  const roles = useSelector((state) => state.User?.roles);
  // const [checkedPermissions, setCheckedPermissions] = useState({});
  const [rolePermission, setRolePermission] = useState({
    permission: '',
    hasPer: false
  });
  const [business, setBusiness] = useState('');
  useEffect(() => {
    const business = localStorage.getItem('businessRecord');
    if (business) {
      setBusiness(JSON.parse(business));
    }
  }, []);
  // Function to handle checkbox click
  const handlePermissionClick = (pid) => {
    setRolePermission({
      permission: pid,
      hasPerm: Boolean(pid)
    });
  
  };
  const handleBusinessChange = (event, selectedOption) => {
    setRole(selectedOption ? selectedOption : null);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!role) {
      setError('Role field is required');
      return;
    }

    setLoading(true);
   
    const data = {
      role: role.id,
      subService: null,
  
      permission: rolePermission.permission,
      hasPerm: rolePermission.hasPerm
    };
    dispatch(
      allowPermissionRequest({
        data,
        setLoading,
        onClose,
        onRoleCreated
      })
    ); // Dispatch the userRequest action
  };

  return (
    <>
      {/* Header and other components */}
      <SideNavone>
        <Header flag={true} heading={'Add Permission For Role'} />
        <div className="p-2  pt-4 hide_scrollbar min-h-[250px] form-container-business">
          <div className="w-100">
            <form onSubmit={handleSubmit}>
              <div className="card px-3 py-2 ms-2 me-2  min-h-[250px] card-cont">
                <div className="d-flex align-items-center">
                  <div>
                    <Avatar
                      src={'images/download.jpg'}
                      alt={'not-found'}
                      variant="rounded"
                      className=""
                      style={{
                        width: '40px',
                        height: '40px',
                        marginRight: 10
                      }}
                    />
                  </div>
                  <div className="font-size-sm text-capitalize">
                    <span className="bolds text-black">
                      {business && business.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2 w-100">
                <Autocomplete
                  disablePortal
                  options={
                    roles &&
                    roles.map((role) => ({
                      id: role.id,
                      name: role.name
                    }))
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={handleBusinessChange}
                  value={role || null}
                  sx={{
                    backgroundColor: '#fff',
                    border: '0px solid #fff'
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      variant="filled"
                      sx={{
                        border: '0px solid #fff',
                        borderRadius: '5px'
                      }}
                      {...params}
                      label="Role"
                      placeholder="Select Role"
                    />
                  )}
                />
              </div>

              <div className="d-flex p-2 pt-1">
                <AiOutlineQuestionCircle
                  style={{ color: '#b2b2b2', fontSize: 18 }}
                />
                <span
                  style={{ fontWeight: 400, fontSize: '14px' }}
                  className="span ps-1 pb-1">
                  Permissions
                </span>
              </div>

              {permission.map((data, index) => (
                <div
                  className="card px-3 py-2 ms-2 me-2 min-h-[250px] card-cont"
                  style={{
                    marginBottom: index !== permission.length - 1 ? '20px' : '0'
                  }}
                  key={data.id}>
                  <>
                    <div className="text2" key={data.id}>
                      <span className="span fw-normal d-flex">
                        {data.description}
                      </span>
                    </div>
                    <div>
                      <div className="d-flex flex-wrap px-2 align-items-center">
                        <div className="d-flex flex-wrap">
                          <div key={data.name}>
                            <label
                              className=" pe-5"
                              style={{
                                fontSize: '13px',
                                display: 'flex',
                                fontWeight: 500,
                                flexDirection: 'row',
                                marginTop: '10px',
                                marginRight: '50px'
                              }}>
                              <input
                                type="checkbox"
                                checked={Boolean(
                                  rolePermission.permission === data.id
                                )}
                                className="lager pe-5"
                                style={{
                                  marginBottom: '10px',
                                  marginRight: '10px',
                                  marginTop: '3px'
                                }}
                                onChange={() =>
                                  handlePermissionClick(
                                    rolePermission.permission !== data.id
                                      ? data.id
                                      : ''
                                  )
                                }
                              />
                              {data.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              ))}

              <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                <div className="p-2">
                  <Button
                    sx={{
                      width: '150px',
                      backgroundColor: '#edf0f5',
                      height: '40px',
                      color: '#b6bec8',
                      borderRadius: '10px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#c0c0c0',
                        color: '#ffffff'
                      }
                    }}
                    onClick={history.push("/permissions")}
                    variant="contained">
                    Close
                  </Button>
                </div>

                <div className="p-2">
                  <Button
                    type="submit"
                    sx={{
                      width: '150px',
                      backgroundColor: '#538dff',
                      height: '40px',
                      borderRadius: '10px',
                      textTransform: 'capitalize'
                    }}
                    variant="contained"
                    disabled={loading}>
                    {loading ? 'Creating...' : 'Allow Permission'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SideNavone>
    </>
  );
};

export default Role;