import React, { useEffect, useState } from "react";
import SelectService from "./SelectService";
import SlotPickingWidget from "./SlotPickingWidget";
import AddCustomer from "./AddCustomer";
import AddPayments from "./AddPayments";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OpenBooking from "./OpenBooking";
import Notes from "../../../Pages/Notes";

export default function AddBooking() {
  const history = useHistory();
  const [serviceData, setServiceData] = useState([]);
  const [bookingCreated, setBookingCreated] = useState(false);
  const [selectSlotFlag, setSelectSlotFlag] = useState("service");
  const {
    slotsData: selectedSlot,
    servicesData: selectedServiceData,
    slotFlag,
  } = useSelector((state) => state.bookingReducer);

  useEffect(() => {
    setServiceData(selectedServiceData);
  }, [selectedServiceData]);
  const handleBackButtonFunction = () => {
    console.log("SLOT FLAG HERE", selectSlotFlag);
    if (selectSlotFlag === "payment" && slotFlag === true) {
      setSelectSlotFlag("question");
    } else if (selectSlotFlag === "question" && slotFlag === true) {
      setSelectSlotFlag("service");
    } else if (selectSlotFlag === "payment" && slotFlag === false) {
      setSelectSlotFlag("addCustomer");
    } else if (selectSlotFlag === "addCustomer" && slotFlag === false) {
      setSelectSlotFlag("question");
    } else if (selectSlotFlag === "question" && slotFlag === false) {
      setSelectSlotFlag("service");
    } else if (selectSlotFlag === "openBooking" && slotFlag === false) {
      setSelectSlotFlag("addCustomer");
    } else if (selectSlotFlag === "notes" && slotFlag === false) {
      setSelectSlotFlag("payment");
    } else {
      history.push("/calendar");
    }
  };
  return (
    <div>
      {slotFlag === true ? (
        <div>
          {" "}
          {selectSlotFlag === "question" ? (
            <AddCustomer
              selectSlotFlag={selectSlotFlag}
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          ) : selectSlotFlag === "payment" ? (
            <AddPayments
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
              setBookingCreated={setBookingCreated}
              bookingCreated={bookingCreated}
            />
          ) : selectSlotFlag === "openBooking" ? (
            <OpenBooking
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          ) : (
            <SelectService
              selectSlotFlag={selectSlotFlag}
              selectedSlot={selectedSlot}
              setServiceData={setServiceData}
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          )}
        </div>
      ) : (
        <div>
          {" "}
          {selectSlotFlag === "question" ? (
            <SlotPickingWidget
              setSelectSlotFlag={setSelectSlotFlag}
              selectedService={serviceData}
              callBackHandler={handleBackButtonFunction}
            />
          ) : selectSlotFlag === "addCustomer" ? (
            <AddCustomer
              selectSlotFlag={selectSlotFlag}
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          ) : selectSlotFlag === "payment" ? (
            <AddPayments
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
              setBookingCreated={setBookingCreated}
              bookingCreated={bookingCreated}
            />
          ) : selectSlotFlag === "notes" ? (
            <Notes
              flag="booking"
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          ) : selectSlotFlag === "openBooking" ? (
            <OpenBooking
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          ) : (
            <SelectService
              selectSlotFlag={selectSlotFlag}
              selectedSlot={selectedSlot}
              setServiceData={setServiceData}
              setSelectSlotFlag={setSelectSlotFlag}
              callBackHandler={handleBackButtonFunction}
            />
          )}
        </div>
      )}
    </div>
  );
}
