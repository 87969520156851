import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import {
  fetchStaffFailure,
  fetchStaffRequest,
  fetchStaffSuccess,
} from "./action";
import {
  FETCH_STAFF_FAILURE,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
} from "./actionType";

import { push } from "connected-react-router";
import axiosConfig from "../../Routes/AxiosConfigg";
import { error, success } from "../../utils";
import { NO_DATA_FOUND, teamList } from "../../utils/constant";
import instance from "../../utils/utils";

function* fetchStaff(action) {
  //   const { payload: userID } = action;
  try {
    const response = yield instance.get(
      `/staff_svc/pv/staff/getStaffs?userId=${action.payload.userID}&businessId=${action.payload.bussinessId}&pageSize=12&pageNo=${action.payload.page}`
      //   `${process.env.REACT_APP_BASE_URL}/business_svc/pb/team?departmentId=${id}`
    );
    if (response.data.code === 0) {
      success("Staff fetch");
      yield put(fetchStaffSuccess(response));
    } else {
      yield put(fetchStaffFailure(response.data.message));
    }
  } catch (error) {
    error(error.response.data.message);

    yield put(fetchStaffFailure(error.message));
  }
}
export function* fetchStaffBinder() {
  yield takeLatest(FETCH_STAFF_REQUEST, fetchStaff);
}
export default function* staffSaga() {
  yield all([fork(fetchStaffBinder)]);
}
