import React from "react";
import SideNavone from "../../../../SideNavBar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import "./customer.scss";
import { customSnackBar } from "../../../../utils";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getCookiesData } from "../../../../utils/helper";
import PhoneInput from "react-phone-input-2";
import CustomPhoneInput from "../../../DynamicForm/dynamicFormView/phoneInput";
import instance from "../../../../utils/utils";

function CustomerView() {
  const { id } = useParams();
  const loaction = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [profilevalues, setProfilevalues] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [file, setFile] = useState(null);
  const action = loaction.state;
  const newid = parseInt(id);
  const [updateimage, setUpdateimage] = useState(null);

  const record = localStorage.getItem("CustomerRecord");
  const getdataofregistereduser = useSelector(
    (state) => state.Auth?.alluserdataafterlogin
  );
  const auth = getCookiesData("auth");

  // api fetch for data
  const fetchData = async () => {
    try {
      const baseUrl =
        action === "plexaar" || action === "null"
          ? `${process.env.REACT_APP_BASE_URL}/customer_svc/pv/Customers/getCustomerById?id=${newid}`
          : `${process.env.REACT_APP_BASE_URL_EXPERT}/customer_svc/pb/Customers/getCustomerById?id=${newid}`;
      const response = await instance.get(
        // baseUrl,
        `/customer_svc/pv/Customers/getCustomerById?id=${newid}`
        //   {
        //     headers: {
        //       Authorization: `Bearer ${auth?.token }`,
        //         "Access-Control-Allow-Origin": "*",
        //         // "Content-Type": "application/json",
        //         // "X-Requested-With": "XMLHttpRequest",
        //     },
        // }
      );

      // Assuming a successful response has a status code in the 200 range
      if (response.status >= 200 && response.status < 300) {
        setData(response.data);
        setProfilevalues(response?.data?.result?.customer[0]);
        localStorage.setItem(
          "CustomerRecord",
          JSON.stringify(response?.data?.result?.customer[0])
        );
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [newid]);
  

  // useEffect(() => {
  //   if (updateimage) {
  //     const postImageData = async () => {
  //       // setIsloadingImage(true);
  //       console.log(profilevalues, record, updateimage, "PJDHKAHKAHKAHKJHA");
  //       try {
  //         let data = {
  //           id: newid,
  //           centerAddressId: profilevalues.centerAddressId
  //             ? profilevalues.centerAddressId
  //             : 0,
  //           workingAddressId: profilevalues.workingAddressId
  //             ? profilevalues.workingAddressId
  //             : 0,
  //           imageUrl: updateimage,
  //           title: profilevalues.title ? profilevalues.title : 0,
  //           firstName: profilevalues?.firstName
  //             ? profilevalues?.firstName
  //             : "string",
  //           lastName: profilevalues?.lastName
  //             ? profilevalues?.lastName
  //             : "string",
  //           email: profilevalues?.email ? profilevalues?.email : "string",
  //           designation: profilevalues?.designation
  //             ? profilevalues?.designation
  //             : "string",
  //           qualification: profilevalues?.qualification
  //             ? profilevalues?.qualification
  //             : "string",

  //           nationality: profilevalues?.nationality
  //             ? profilevalues?.nationality
  //             : "string",
  //           countryId: profilevalues?.countryId ? profilevalues?.countryId : 0,
  //           nationalTaxNumber: profilevalues?.countryId
  //             ? profilevalues?.countryId
  //             : 0,
  //           mobile: profilevalues?.mobile ? profilevalues?.mobile : "string",
  //           kinFirstName: profilevalues?.kinFirstName
  //             ? profilevalues?.kinFirstName
  //             : "string",
  //           kinLastName: profilevalues?.kinLastName
  //             ? profilevalues?.kinLastName
  //             : "string",
  //           kinMobile: profilevalues?.kinMobile
  //             ? profilevalues?.kinMobile
  //             : "string",
  //           kinEmail: profilevalues?.kinEmail
  //             ? profilevalues?.kinEmail
  //             : "string",
  //           kinAddress: profilevalues?.kinAddress
  //             ? profilevalues?.kinAddress
  //             : "string",

  //           modifiedby: getdataofregistereduser
  //             ? getdataofregistereduser?.id
  //             : 0,
  //         };
  //         const response = await axios.put(
  //           `${process.env.REACT_APP_BASE_URL}/staff_svc/pv/staff/updateStaff`,
  //           data
  //         );
  //         customSnackBar(response.data.message);
  //         fetchData();
  //         window.location.reload();
  //       } catch (error) {
  //         customSnackBar(error);
  //       } finally {
  //         setIsLoadingImage(false);
  //       }
  //     };
  //     postImageData();
  //   }
  // }, [updateimage, newid]);
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Create form data
      const formData = new FormData();
      formData.append("Id", id);
      formData.append("ServiceName", "staff");
      formData.append("Source", "Plexaar");
      formData.append("Files", selectedFile);
      formData.append("Category", "main");
      formData.append("FileType", "i");
      formData.append("CreatedBy", 10);
      setIsLoadingImage(true);
      setFile(event.target.files[0]);
      // Create form data

      try {
        // Send the image data to the API endpoint
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.code === 0) {
          // dispatch(uploadprofileimagesuccess(response.data.paths[0]));
          customSnackBar(response.data.message);
          setUpdateimage(response.data.paths[0]);
          setIsLoadingImage(false);
        } else {
          setIsLoadingImage(false);
          customSnackBar(response.data.message);
        }
      } catch (error) {
        customSnackBar("Error uploading image:", error);
      }
    } else {
      customSnackBar("Invalid file type. Please select a jpg or jpeg file.");
    }
    // finally {
    //   setIsLoadingImage(false);
    // }
  };
  return (
    <SideNavone>
      {profilevalues ? (
        <div
          className="container mt-5 hide_scrollbar"
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "180px",
          }}
        >
          {/* {profilevalues.length > 0 ? ( */}
          <div>
            <div className="card card-content">
              <div className="d-flex  justify-content-between">
                <div className="d-flex justify-content-evenly">
                  {isLoadingImage ? (
                    <div
                      style={{
                        width: 120,
                        height: 120,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ marginTop: "200px" }}
                        className="spinner-border text-primary "
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : profilevalues?.imageUrl === null ||
                    profilevalues?.imageUrl === "" ? (
                    <Avatar
                      className="img-fluid img_profile_shape"
                      alt="nodata"
                      style={{ width: 100, height: 100 }}
                    />
                  ) : (
                    // <img
                    //   className="img_profile_shape"
                    //   // src={
                    //   //   profilevalues
                    //   //     ? `${process.env.REACT_APP_IMAGE_URL}/staff/${profilevalues?.staffId}_0.jpeg`
                    //   //     : "/images/profile.png"
                    //   // }
                    //   src={
                    //     profilevalues
                    //       ?profilevalues?.imageURL
                    //       : "/images/profile.png"
                    //   }
                    //   alt="no-data"
                    //   // width={100}
                    //   // height={100}
                    // />
                    <Avatar
                      className=" img_profile_shape"
                      style={{ width: 100, height: 100 }}
                      src={
                        profilevalues
                          ? profilevalues?.imageUrl
                          : "/images/profile.png"
                      }
                      alt="no-data"
                      // width={100}
                      // height={100}
                    />
                  )}
                  <div className="margin_neg_button">
                    <div className="upload-btn-wrapper">
                      {/* <div className="btnn"> */}
                      <img src="../images/camera.svg" alt="nodata" />
                      {/* </div> */}
                      <input
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="p-4">
                    <h6 className="mb-1 text-capitalize">
                      <b>
                        {profilevalues?.firstName} {profilevalues?.lastName}
                      </b>
                    </h6>
                    <p className="mb-0 text_color_account">Personal Account</p>
                    <p className="mb-0 sub_text_color_account">
                      ID {profilevalues?.id}
                    </p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
            {/* Name ---------------------------------------------------------------------------------------------- */}
            <p className="mb-2 mt-5 main_heading p-0">
              <img
                alt="img"
                className="img-fluid icons_size "
                src="../images/pp.png"
              />
              &nbsp;Name
            </p>
            <div className="col-md-12 px-4 py-2 border_syle_staff ">
              <div className="row">
                <div className="col-md-8 pt-2">
                  <p className="label_first m-0 pt-0">First Name</p>
                  <p className="text_of_label m-0 p-0">
                    {profilevalues?.firstName}
                  </p>
                </div>
                <div className="col-md-4 text-end pt-2 m-auto">
                  <img
                    alt="img"
                    className="img-fluid  "
                    src="../images/gr.png"
                  />
                </div>
              </div>
              <hr className="my-2 p-0 background_of_line" />
              <div className="row">
                <div className="col-md-8 pt-2">
                  <p className="label_first m-0 pt-0">Last Name</p>
                  <p className="text_of_label m-0 p-0">
                    {profilevalues?.lastName}
                  </p>
                </div>
                <div className="col-md-4 text-end pt-2 m-auto">
                  <img
                    alt="img"
                    className="img-fluid  "
                    src="../images/gr.png"
                  />
                </div>
              </div>
            </div>
            {/* Email ---------------------------------------------------------------------------------------------- */}
            <p className="mb-2 mt-5 main_heading p-0">
              <img
                alt="img"
                className="img-fluid icons_size "
                src="../images/mail.png"
              />
              &nbsp;Email
            </p>
            <div className="col-md-12 px-4 py-2 border_syle_staff ">
              <div className="row">
                <div className="col-md-8 pt-2">
                  <p className="label_first m-0 pt-0">Primary Email</p>
                  <p className="text_of_label m-0 p-0">
                    {profilevalues?.primaryEmail}
                  </p>
                </div>
                <div className="col-md-4 text-end pt-2 m-auto">
                  <img
                    alt="img"
                    className="img-fluid  "
                    src="../images/gr.png"
                  />
                </div>
              </div>
              {/* <hr className="my-2 p-0 background_of_line" />
            <div className="row">
              <div className="col-md-8 pt-2">
                <p className="label_first m-0 pt-0">Secondary Email</p>
                <p className="text_of_label_if_notadded m-0 p-0">
                  No Key From Backend
                </p>
              </div>
              <div className="col-md-4 text-end pt-2 m-auto">
                <img alt="img" className="img-fluid  " src="../images/gr.png" />
              </div>
            </div> */}
            </div>
            {/* Mobile ---------------------------------------------------------------------------------------------- */}
            <p className="mb-2 mt-5 main_heading p-0">
              <img
                alt="img"
                className="img-fluid icons_size1 "
                src="../images/mob.png"
              />
              &nbsp;Mobile
            </p>
            {/* <div className="col-md-12 px-4 py-2 border_syle_staff ">
                <div className="row">
                  <div className="col-md-8 pt-2">
                    <p className="label_first m-0 pt-0">Primary Number</p>
                    <p className="text_of_label m-0 p-0">
                      <img
                        alt="img"
                        className="img-fluid"
                        src="/images/uk.png"
                      />{" "}
                      &nbsp;
                      <strong> +44 </strong>
                      <span className="font_color_line">
                        &nbsp; | &nbsp;
                      </span>{" "}
                      {profilevalues?.primaryMobile}
                    </p>
                  </div>
                  <div className="col-md-4 text-end pt-2 m-auto">
                    <img
                      alt="img"
                      className="img-fluid  "
                      src="../images/gr.png"
                    />
                  </div>
                </div>
                {/* <hr className="my-2 p-0 background_of_line" />
            <div className="row">
              <div className="col-md-8 pt-2">
                <p className="label_first m-0 pt-0">Secondary Number</p>
                <p className="text_of_label_if_notadded m-0 p-0">
                  Add Secondary Email
                </p>
              </div>
              <div className="col-md-4 text-end pt-2 m-auto">
                <img alt="img" className="img-fluid  " src="../images/gr.png" />
              </div>
            </div> */}
            {/* </div> */}
            <CustomPhoneInput
              title="Primary Number"
              action="customer"
              disabled
              style={{ height: 0 }}
              placeholder="Phone number"
className="mt-3"
              value={profilevalues?.primaryMobile}
            />
            {/* Addinitional info ---------------------------------------------------------------------------------------------- */}
            <p className="mb-2 mt-5 main_heading p-0">
              <img
                alt="img"
                className="img-fluid icons_size "
                src="../images/info.png"
              />
              &nbsp;Additional Info
            </p>
            <div className="col-md-12 px-4 py-2 border_syle_staff ">
              <div className="row">
                <div className="col-md-8 pt-2">
                  <p className="label_first m-0 pt-0">Platform</p>
                  <p className="text_of_label_if_notadded m-0 p-0">
                    <p className="text_of_label text-capitalize m-0 p-0">
                      {profilevalues?.externalPlatFormName}
                    </p>
                  </p>
                </div>
                <div className="col-md-4 text-end pt-2 m-auto">
                  <img
                    alt="img"
                    className="img-fluid  "
                    src="../images/gr.png"
                  />
                </div>
              </div>
              <hr className="my-2 p-0 background_of_line" />
              <div className="row">
                <div className="col-md-8 pt-2">
                  <p className="label_first m-0 pt-0">Date of Birth</p>
                  <p className="text_of_label_if_notadded m-0 p-0">N/A</p>
                </div>
                <div className="col-md-4 text-end pt-2 m-auto">
                  <img
                    alt="img"
                    className="img-fluid  "
                    src="../images/gr.png"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* // ) : (
          //   <div
          //     style={{ marginTop: "200px" }}
          //     className="col-md-12 text-center x pt-5"
          //   >
          //     No customer exists.
          //   </div>
          // )} */}
        </div>
      ) : (
        <div
          style={{ marginTop: "200px" }}
          className="col-md-12 text-center  pt-5"
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </SideNavone>
  );
}

export default CustomerView;
