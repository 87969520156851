import React, { useCallback, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { BookmarkAdd } from "@mui/icons-material";
const Actions = (props) => {
  const history = useHistory();
  const { index, row, actionLoading, handleClick,handleAddUser, action } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClicks = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );
  const handleEditClick = (row) => {
    const data = {
      data: row,
      action: action,
    };
    history.push(`/add-new-business`, { data })
    
    // history.push(`/edit-business-profile/${row.id}`, { data });
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  return (
    <>
     
       
      <div className="">
                  <BsThreeDotsVertical
                  key={row.id}
                    id={"action-button-" + index}
                    style={{ color: "#9a9999", fontSize: 16 }}
                    onClick={handleClicks}
                  />
                  <Menu
                  key={row.id}

                    id={"action-menu-" + index}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ "&:hover": { backgroundColor: "text-primary" } }}

                  >
                    {!row.verified && (
                      <>
                        {/* <MenuItem
                          id={"email-verification-" + index}
                          className="pr-5 px-3 text-success"
                          // onClose={handleClose}
                          onClick={() => {
                            handleClose();
                            // handleEditClick(row);
                          }}
                        >
                          <img
                            src="/images/user-follow-line 1.png"
                            alt=""
                            className="text-primary"
                            // sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                            sx={{ "&:hover": { color: "white" } }} // Change text color on hover

                          >
                            Profile
                          </span>
                        </MenuItem> */}

                        <MenuItem
                          id={"email-verification-" + index}
                          className="pr-5 px-3 text-success"
                          // onClose={handleClose}
                          onClick={() => {
                            handleClose();
                            handleClick(row);
                          }}
                        >
                          <img
                            src="/images/Group 515743.svg"
                            alt=""
                            className="text-primary"
                            // sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                          >
                            Team
                          </span>
                        </MenuItem>
                        {/* <MenuItem
                          id={"email-verification-" + index}
                          className="pr-5 px-3"
                          // onClose={handleClose}
                          onClick={() => {
                            handleClose();
                            handleAddUser(row);
                            // handleEditClick(row);
                          }}
                        >
                          <img
                            src="/images/user-follow-line 1.png"
                            alt=""
                            className="text-primary"
                            sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                          >
                            User
                          </span>
                        </MenuItem> */}
                      </>
                    )}
                  </Menu>
                </div>
    </>
  );
};
export default Actions;
