import React, { useEffect } from "react";
import { IconButton, SvgIcon, TextField, Tooltip } from "@mui/material";
import DatePicker from "react-datepicker";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  nextdate,
  prevdate,
  handlechange,
  nextweekdate,
  prevweekdate,
  prevmonth,
  nextmonth,
} from "../store/dateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { filterResources } from "../store/calendarSlice";
import "../../assets/scheduler/css/calendar.css";
import { useHistory } from "react-router-dom";

function CalendarHeader(props) {
  // const theme = useTheme();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [labelProvider, setLabelProvider] = useState(
    moment(props.date).format(" MMMM YYYY")
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleFilter, toolbar, navigate, appointmentData, providerId } =
    props;
  const resources = useSelector(
    (state) => state.calendarReducer.filterResource
  );
  const selectedBusines = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const date = useSelector((state) => state.counterReducer.date);
  console.log(date, props.date, "datedate");
  const resource = useSelector((state) => state.calendarReducer.resources);
  const label = moment(date).format("dddd,DD MMM YYYY");
  // let labelProvider = moment(props.date).format(" MMMM YYYY");
  const labelArray = label.split(",");
  const [providerFilter, setProviderFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProviderOption, setSelectedProviderOption] = useState(
    props?.action === "edit" ? props?.eventData?.providerName : "All Staff"
  );
  const [isStaffOpen, setIsStaffOpen] = useState(false);
  // const [isAllStaff, setIsAllStaff] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.action === "addScheduler" ? "month" : "day"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedResources, setCheckedResources] = useState({});
  const { isLoading } = useSelector((state) => state.calendarReducer);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredResources = resources.filter((name) =>
    name?.resourceTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (props.action === "addScheduler" && option !== "month") {
      return;
    }
    toolbar.onView(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleChangeData = (data) => {
    if (data === "reset") {
      setSelectedProviderOption("All Staff");
      setProviderFilter([]);
      setIsStaffOpen(!isStaffOpen);
    } else {
      setProviderFilter((prevSelected) => {
        if (prevSelected.includes(data)) {
          return prevSelected.filter((item) => item !== data);
        } else {
          return [...prevSelected, data];
        }
      });
      setIsStaffOpen(!isStaffOpen);
    }
    // if (props.action === "edit") {
    //   const providerName =
    //     data === "reset" ? "All Staff" : props.eventData.providerName;
    //   // console.log(providerName, "providerNameproviderName");
    //   setSelectedProviderOption(providerName);
    // }
  };

  useEffect(() => {
    const currProviderFilter = providerFilter; // Use the current state
    dispatch(
      filterResources({
        providerFilter: currProviderFilter,
        resource: resources,
      })
    );
  }, [providerFilter, dispatch, resources]);
  const handleDateLabelClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDatePickerChange = (date) => {
    if (props.action === "providerCaleder") {
      setLabelProvider(moment(date).format("MMMM YYYY"));
      props.setDate(moment(date).format("MMMM YYYY"));
      setShowDatePicker(false);
    } else {
      dispatch(handlechange(date));
      setShowDatePicker(false);
    }
    // You can dispatch an action with the selected date here
  };

  useEffect(() => {
    if (
      props.action &&
      props.eventData &&
      props.eventData.providerName &&
      props.action === "edit"
    ) {
      setSelectedProviderOption(props.eventData.providerName);
      setProviderFilter([props.eventData.providerId]);
    } else {
      setSelectedProviderOption("All Staff");
      setProviderFilter([]);
    }
  }, [
    props?.action,
    props.eventData,
    props?.eventData?.providerName,
    // providerFilter,
    // selectedProviderOption,
  ]);
  useEffect(() => {
    setSelectedProviderOption(
      props?.action === "edit" ? props?.eventData?.providerName : "All Staff"
    );
    setProviderFilter(
      props?.action === "edit" ? [props?.eventData?.providerId] : []
    );
  }, [props?.action, props?.eventData]);

  // Move to the next month
  const addNextMonth = () => {
    props.setLoading(true);

    dispatch(nextmonth());
    let date = moment(labelProvider).add(1, "month").format("MMMM YYYY");
    setLabelProvider(date);
    props.setDate(date);
  };

  // Move to the previous month
  const addPrevMonth = () => {
    props.setLoading(true);
    dispatch(prevmonth());
    setLabelProvider(
      moment(labelProvider).subtract(1, "month").format("MMMM YYYY")
    );
    props.setDate(
      moment(labelProvider).subtract(1, "month").format("MMMM YYYY")
    );
  };

  useEffect(() => {
    console.log("ABCABC", labelProvider);
  }, [labelProvider]);

  return (
    <div
      className="w-100 px-4 border-bottom d-flex flex-wrap justify-content-between align-items-center"
      style={{ height: "2.5rem" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        {props.action === "providerCaleder" ? (
          <IconButton
            onClick={() => {
              history.goBack();
            }}
            size="small"
            sx={{ mr: 3 }}
            className="mb-1"
          >
            <KeyboardBackspaceIcon sx={{ fontSize: 24, color: "#AAAAAA" }} />
          </IconButton>
        ) : (
          ""
        )}

        <div
          style={{
            color: "#424242",
            fontFamily: "Roboto",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {props.action === "providerCaleder" ? "Schedule" : "Calendar"}
        </div>
        <div
          className="dropdown-container d-none d-lg-block"
          style={{
            position: "relative",
            display: "inline-block",
            marginLeft: "30px",
          }}
        >
          <div
            className="dropdown-header"
            style={{
              borderRadius: "8px",
              border: "1px solid #D7D7D7",
              background: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "141px",
              padding: "0",
              cursor: "pointer", // Add cursor pointer
            }}
            onClick={toggleDropdown}
          >
            <span
              style={{
                color: "#585858",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                marginLeft: "10px",
                fontWeight: 600,
              }}
            >
              {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
            </span>
            <div
              className={`dropdown-icon px-2 ${isOpen ? "open" : ""}`}
              style={{
                borderRadius: "0px 7px 7px 0px",
                background: "#EBEBEB",
                height: "24px",
                display: "flex",
                alignItems: "center",
                transition: "transform 0.3s ease", // Add transition for smooth icon rotation
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
                }}
              >
                <path
                  d="M1 1L5.1569 5L9 1"
                  stroke="#707070"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          {isOpen && (
            <ul
              className="dropdown-options"
              style={{
                listStyle: "none",
                padding: "0",
                margin: "0",
                position: "absolute",
                minWidth: "140px",
                top: "100%",
                left: "0",
                zIndex: "1",
                border: "1px solid #D7D7D7",
              }}
            >
              {props.action === "addScheduler" ? (
                <li
                  onClick={() => handleOptionClick("month")}
                  style={{
                    background: "#FFF",
                    padding: "8px 10px",
                    borderBottom: "1px solid #D7D7D7",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  Month
                </li>
              ) : (
                toolbar?.views?.map((val, key) => (
                  <li
                    key={key}
                    onClick={() => handleOptionClick(val)}
                    style={{
                      background: "#FFF",
                      padding: "8px 10px",
                      borderBottom: "1px solid #D7D7D7",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </li>
                ))
              )}
            </ul>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex rounded-md d-none d-md-flex">
          <Tooltip
            title={`${
              props.action === "providerCaleder"
                ? "Previous Month"
                : "Previous Week"
            }`}
          >
            <div
              className="d-flex justify-content-center align-items-center color_week rounded-start px-2"
              onClick={() => {
                props.action === "providerCaleder"
                  ? addPrevMonth()
                  : dispatch(prevweekdate());
              }}
              role="button"
            >
              <div
                className="secondary_fonts m-0"
                style={{ paddingRight: "0.125rem" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M4 2L1 5.11768L4 8"
                    stroke="#7B7B7B"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M4 2L1 5.11768L4 8"
                    stroke="#7B7B7B"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Tooltip>
          {props.action === "providerCaleder" ? null : (
            <Tooltip title="Previous Day">
              <div
                className="d-flex justify-content-center align-items-center rounded-0 px-2 border_up_and_down"
                onClick={() => {
                  dispatch(prevdate());
                }}
                role="button"
              >
                <SvgIcon
                  className="primary_fonts m-0"
                  sx={{
                    paddingRight: "0.125rem",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                  component={KeyboardArrowLeftIcon}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <h6
          className="px-4 d-block border_h6 rounded-0 date_title text-lg"
          onClick={handleDateLabelClick}
          role="button"
        >
          {props.action === "providerCaleder" ? (
            <span style={{ color: "#0f0f0f" }}>
              <b>{labelProvider}</b>
            </span>
          ) : (
            <>
              {labelArray[0]}{" "}
              <span style={{ color: "#0f0f0f" }}>
                <b>{labelArray[1]}</b>
              </span>
            </>
          )}
        </h6>

        {showDatePicker && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "-210px",
                zIndex: "1000000000000",
              }}
            >
              <DatePicker
                closeOnScroll={true}
                selected={
                  props.action === "providerCaleder"
                    ? new Date(labelProvider)
                    : new Date(labelArray[1])
                }
                onChange={handleDatePickerChange}
                dateFormat={
                  props.action === "addScheduler" ? "MMMM yyyy" : "dd MMMM yyyy"
                }
                disabledKeyboardNavigation
                inline
              />
            </div>
          </div>
        )}
        <div className="d-none d-md-flex">
          {props.action === "providerCaleder" ? null : (
            <Tooltip title="Next Day">
              <div
                className="d-flex justify-content-evenly align-items-center padding_set_day2 rounded-0 px-2 border_up_and_down"
                onClick={() => {
                  dispatch(nextdate());
                }}
                role="button"
              >
                <SvgIcon
                  className="primary_fonts m-0"
                  sx={{
                    paddingLeft: "0.125rem",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                  component={KeyboardArrowRightIcon}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            title={`${
              props.action === "providerCaleder" ? "Next Month" : "Next Week"
            }`}
          >
            <div
              className="d-flex justify-content-center align-items-center color_week rounded-end px-2"
              onClick={() => {
                props.action === "providerCaleder"
                  ? addNextMonth()
                  : dispatch(nextweekdate());
              }}
              role="button"
            >
              <div className="secondary_fonts m-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M2 8L5 4.88232L2 2"
                    stroke="#7B7B7B"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M2 8L5 4.88232L2 2"
                    stroke="#7B7B7B"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="d-none d-md-flex align-items-center">
        {props.action === "providerCaleder" ? (
          <div className="d-none d-lg-block">
            <button
              className="buttondesign"
              onClick={() =>
                // handleSchedular()
                navigate.push(
                  `/timeSlot?busiessId=${selectedBusiness.id}&providerId=${providerId}`,
                  { appointmentData }
                )
              }
            >
              Schedule
            </button>
          </div>
        ) : (
          !isLoading && (
            <div className="d-none d-lg-block">
              <button
                className="buttondesign"
                onClick={() => toolbar.onNavigate("TODAY")}
              >
                Today
              </button>
            </div>
          )
        )}

        {props.action === "providerCaleder" ? null : (
          <div
            className="dropdown-container mx-2 d-none d-md-block"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <div
              className="dropdown-header"
              style={{
                borderRadius: "8px",
                border: "1px solid #D7D7D7",
                background: "#FFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "141px",
                padding: "0",
                cursor: "pointer", // Add cursor pointer
              }}
              onClick={() => setIsStaffOpen(!isStaffOpen)}
            >
              <span
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  marginLeft: "10px",
                  fontWeight: 600,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {resource?.length > 1
                  ? "All Staff"
                  : resource[0]?.resourceTitle}
              </span>
              <div
                className={`dropdown-icon px-2 ${isStaffOpen ? "open" : ""}`}
                style={{
                  borderRadius: "0px 7px 7px 0px",
                  background: "#EBEBEB",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  style={{
                    transform: isStaffOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
                  }}
                >
                  <path
                    d="M1 1L5.1569 5L9 1"
                    stroke="#707070"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            {isStaffOpen && (
              <div
                className="dropdown-options px-2 py-2"
                style={{
                  listStyle: "none",
                  padding: "0",
                  margin: "0",
                  position: "absolute",
                  backgroundColor: "white",
                  minWidth: "140px",
                  top: "100%",
                  left: "0",
                  zIndex: "1",
                  border: "1px solid #D7D7D7",
                }}
                role="button"
              >
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    // setSelectedProviderOption(props.eventData.providerName);
                    handleChangeData("reset");
                  }}
                  style={{
                    color: "#585858",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    marginLeft: "10px",
                    fontWeight: 600,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {"All Staff"}
                </span>
                {props.action === "edit" ? (
                  <label
                    className="dropdown-item pb-1"
                    style={{ fontSize: "0.875rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedProviderOption(props.eventData.providerName);
                      handleChangeData(props.eventData.providerId);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input mx-1"
                      checked={providerFilter.includes(
                        props.eventData.providerId
                      )}
                    />
                    {props.eventData.providerName}
                  </label>
                ) : (
                  filteredResources.map((name, key) => (
                    <label
                      className="dropdown-item pb-1"
                      style={{ fontSize: "0.875rem" }}
                      key={key}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedProviderOption((prev) => name.resourceTitle);
                        handleChangeData(name.resourceId);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input mx-1"
                        checked={providerFilter.includes(name.resourceId)}
                      />
                      {name.resourceTitle}
                    </label>
                  ))
                )}
              </div>
            )}
          </div>
        )}
        {props.action === "providerCaleder" ? null : (
          <div className=" bg_light px-2 me-2 d-none d-lg-flex justify-content-center align-items-center ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
              >
                <path
                  d="M14.3333 3.33333H10.3333M3.66667 3.33333H1M14.3333 10.6667H11.6667M5 10.6667H1M6.33333 5.66667C6.63975 5.66667 6.94317 5.60631 7.22626 5.48905C7.50935 5.37179 7.76658 5.19992 7.98325 4.98325C8.19992 4.76658 8.37179 4.50935 8.48905 4.22626C8.60631 3.94317 8.66667 3.63975 8.66667 3.33333C8.66667 3.02692 8.60631 2.7235 8.48905 2.44041C8.37179 2.15731 8.19992 1.90009 7.98325 1.68342C7.76658 1.46675 7.50935 1.29488 7.22626 1.17761C6.94317 1.06035 6.63975 1 6.33333 1C5.7145 1 5.121 1.24583 4.68342 1.68342C4.24583 2.121 4 2.7145 4 3.33333C4 3.95217 4.24583 4.54566 4.68342 4.98325C5.121 5.42083 5.7145 5.66667 6.33333 5.66667ZM9 13C9.61884 13 10.2123 12.7542 10.6499 12.3166C11.0875 11.879 11.3333 11.2855 11.3333 10.6667C11.3333 10.0478 11.0875 9.45434 10.6499 9.01675C10.2123 8.57917 9.61884 8.33333 9 8.33333C8.38116 8.33333 7.78767 8.57917 7.35008 9.01675C6.9125 9.45434 6.66667 10.0478 6.66667 10.6667C6.66667 11.2855 6.9125 11.879 7.35008 12.3166C7.78767 12.7542 8.38116 13 9 13Z"
                  stroke="#797979"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className="ms-2"
              style={{
                color: "#797979",
                fontSize: "12px",
                fontStyle: " normal",
                fontWeight: 500,
              }}
            >
              Filter
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarHeader;

// import { setOptions } from "react-chartjs-2/dist/utils";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

//<div className="buttongroup">
//          {toolbar?.views?.map((val, key) => (
//            <Button
//              key={key}
//              onClick={() => {
//                toolbar.onView(val);
//              }}
//              // startIcon={val === "day" ? <SvgIcon component={DarkModeOutlinedIcon} /> : val === "week" ? <SvgIcon component={CalendarMonthIcon} />: val==="month"?<SvgIcon component={CalendarViewDayIcon}/> : <SvgIcon component={SubjectIcon} />}
//              className={clsx(
//                "cursor-pointer rounded-md text-xs",
//                val === toolbar.view
//                  ? "buttondesign"
//                  : "bg-transparent buttontext"
//              )}
//              style={{
//                cursor: "pointer",
//                fontWeight: val === toolbar.view ? "600" : "500",
//              }}
//              variant={val === toolbar.view ? "contained" : "text"}
//            >
//              <span
//                  className={clsx(
//                    val === toolbar.view
//                      ? "text-white font-medium"
//                      : "text-dark font-medium"
//                  )}
//                  style={{
//                    cursor: "pointer",
//                    display: "flex",
//                    fontWeight: val === toolbar.view ? "600" : "500",
//                  }}
//                >
//              {val === "agenda" ? "list" : val}
//               </span>
//            </Button>
//          ))}
//        </div>

//<FormControl sx={{ m: 1, width: 120, mt: 1, borderRadius:2 }} >
//<select className="form-select form-select-sm" aria-label=".form-select-sm example">
//  <option selected>All Selected</option>
//  <option value="1"><div><input type='checkbox' checked={false}/> One</div> </option>
//</select>

// <Select
//      className='dropDown'
//      multiple
//      displayEmpty
//      value={providerFilter}
//      onChange={handleChangeData}
//      input={<OutlinedInput />}
//      renderValue={(selected) => {
//        if (selected.length === 0) {
//          return <>All Staff</>;
//        }
//        return selected.join(', ');
//      }}
//      inputProps={{ 'aria-label': 'Without label' }}
//    >
//      <MenuItem disabled value="">
//        <>All Staff</>
//      </MenuItem>
//      {resources.map((name,key) => (
//        <MenuItem
//          key={name?.id}
//          value={name?.title}
//          style={getStyles(name?.title, providerFilter, theme)}
//        >
//          <Checkbox   checked={providerFilter.find(staff => staff.id === name.id)?true:false} />
//          {name?.title}
//        </MenuItem>
//      ))}
//    </Select>
//        </FormControl>

//<FormControl className="w-100">
//            <InputLabel id="demo-simple-select-helper-label" className="w-100 position-relative">All Staff</InputLabel>
//            <Select
//              labelId="demo-multiple-checkbox-label"
//              placeholder="All Staff"
//              id="demo-multiple-checkbox"
//              multiple
//             style={{width:"130px"}}
//              value={providerFilter}
//              onChange={handleChange}
//              input={<OutlinedInput label="Tag" />}
//              renderValue={(selected) => selected?.join(',')}
//              MenuProps={MenuProps}
//            >
//              {resources?.map((name) => (
//                <MenuItem key={name?.id} value={name?.title}>
//                  <Checkbox checked={providerFilter?.indexOf(name?.title) > -1} />
//                  <ListItemText primary={name?.title} />
//                </MenuItem>
//              ))}
//            </Select>
//          </FormControl>
