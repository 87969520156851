import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { weekdays } from "moment";
import instance from "../../../../utils/utils";

const style = {     
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 680,
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadis: "10px",
  boxShadow: 24,
  p: 4,
};

export default function NewSchedularModal({ businessId, open, handleClose }) {
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [weekDay, setWeekDays] = React.useState([
    {
      day: "Monday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Tuseday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Wednesday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Thursday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Friday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Saturday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
    {
      day: "Sunday",
      businessId: businessId,
      businessStartTime: "08:00",
      businessEndTime: "20:00",
      isAvailable: false,
    },
  ]);
  const formatTime = (time) => {
    if (!time) return ""; // Return empty string if no time selected

    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const parseTime = (formattedTime) => {
    if (!formattedTime) return null; // Return null if the input is empty or invalid

    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; // Regular expression for valid time format (HH:mm)

    if (!timeRegex.test(formattedTime)) return null; // Return null if the time format is invalid

    const [hours, minutes] = formattedTime.split(":").map(Number);

    const dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    dateObj.setSeconds(0); // Optionally, set seconds to 0 if you want them to be 0 in the resulting Date object

    return dateObj;
  };

  const handleChangeAvailability = (index, checked) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].isAvailable = checked;
    setWeekDays(updatedWeekDays);
  }; 

  const handleChangeStartTime = (index, newValue) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].businessStartTime = formatTime(newValue);
    setWeekDays(updatedWeekDays);
  };

  const handleChangeEndTime = (index, newValue) => {
    const updatedWeekDays = [...weekDay];
    updatedWeekDays[index].businessEndTime = formatTime(newValue);
    setWeekDays(updatedWeekDays);
  };

  const AddSchedule = async () => {
    const data = weekDay;
    await instance
      .post(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/business/`,
        { data: weekDay, timeZone: "Europe/London" }
      )
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        handleClose();
        console.error(err);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="py-2 px-4"
      >
        <Box sx={style} className="schedular_modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="pb-2"
          >
            Set Business Schedule
          </Typography>
          {weekDay.map((val, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-4 pe-4 day_name_col">
                <input
                  type="text"
                  className="form-control h-100"
                  placeholder=""
                  value={val?.day}
                  readOnly
                />
              </div>

              <div className="col-4 pe-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start time"
                    value={parseTime(weekDay[index].businessStartTime)}
                    onChange={(newValue) =>
                      handleChangeStartTime(index, newValue)
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className="col-4 pe-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="End Time"
                    value={parseTime(weekDay[index].businessEndTime)}
                    onChange={(newValue) =>
                      handleChangeEndTime(index, newValue)
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          ))}
          <div className="w-100 d-flex justify-content-end align-items-center py-3 px-2">
            <div className="w-25 d-flex justify-content-betweens align-items-center">
              <Button
                className="cancel_schedule_theme me-2"
                variant="text"
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className="add_schedule_theme"
                variant="contained"
                onClick={AddSchedule}
              >
                Add
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
