import { API_ENDPOINTS } from "../configs/config";
import makeRequest from "./requestCall";

async function postSchedule(data) {
  return makeRequest(API_ENDPOINTS.POST_SCHEDULE, "post", data);
}
async function getScheduleList(data) {
  return makeRequest(
    `${API_ENDPOINTS.GET_SCHEDULE_LIST}?providerId=${data}&timeZone=Europe/London`,
    "get",
    data
  );
}
async function getSchedule(data) {
  return makeRequest(
    `${API_ENDPOINTS.GET_SCHEDULE}?id=${data}&timeZone=Europe/London`,
    "get",
    data
  );
}
async function postdateSchedule(data) {
  return makeRequest(API_ENDPOINTS.POST_DATE_SCHEDULE, "post", data);
}
async function updateSchedule(data) {
  return makeRequest(API_ENDPOINTS.UPDATE_SCHEDULE, "post", data);
}

async function deleteSchedule(data) {
  return makeRequest(API_ENDPOINTS.DELETE_SCHEDULE, "post", data);
}
async function postProviderSceduleForStaff(data) {
  return makeRequest(API_ENDPOINTS.POST_PROVIDER_SCHEDULE, "post", data);
}
async function getProviderScedule(data) {
  return makeRequest(API_ENDPOINTS.GET_DATE_SCHEDULE, "post", data);
}

export {
  postSchedule,
  getScheduleList,
  getSchedule,
  deleteSchedule,
  postdateSchedule,
  updateSchedule,
  postProviderSceduleForStaff,
  getProviderScedule
};
