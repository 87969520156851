import React, { useEffect } from "react";
import CalendarAppParent from "../components/CalendarApp.jsx";
import SideNavone from "../../../SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { getuserdataafterlogin } from "../../../Store/Auth/actions.js";
import CryptoJs from 'crypto-js';

export default function Scheduler() {
  const dispatch = useDispatch()
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const encryptedData = params.get("encryptedData");
    if (encryptedData) {
      try {
        // Decrypt the encrypted data
        const bytes = CryptoJs.AES.decrypt(encryptedData, "9GxbhX0FNMP");
        const decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
  
        // Extract token, userId, and other data from the decrypted data
        const token = decryptedData.token;
        const userId = decryptedData.userId;
        // Extract other data as needed
        
        // Log or set state with the extracted data
       
        // Set other state variables if needed
  
      } catch (error) {
        console.error("Error decrypting data:", error);
      }
    }
  }, []);
  useEffect(() => {
    dispatch(
      getuserdataafterlogin({
        id: registersuccessId,
      })
    );
  }, [registersuccessId,dispatch]);
  return (
    <div>
      <SideNavone>
        <CalendarAppParent />
      </SideNavone>
    </div>
  );
}
