import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteRequest } from '../../../../../Componenets/request';
import { FaRegEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEventsList } from '../../../../../Store/MeetSpace/meetspaceActions';
import { base_url } from '../../../../../Componenets/base_url';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const BookedEventsLView = () => {
  const [columns, setColumns] = useState([
    { id: 1, name: 'Name' },
    { id: 2, name: 'Plexaar ID' },
    { id: 3, name: 'Events' },
    { id: 4, name: 'Pending' },
    { id: 5, name: 'Cancelled' },
    { id: 6, name: 'Completed' },
  ]);

  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnId, setColumnId] = useState('');
  const [isDraggingRow, setIsDraggingRow] = useState(false);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1, data: ['', '', '', '', '']
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(state => state?.meetspaceReducer);

  const addColumn = (val) => {
    const newColumn = {
      id: val?.id,
      name: val?.name,
    };
    setColumns([...columns, newColumn]);

    rows.forEach((row) => {
      row.data.push({
        id: val?.id,
        name: '',
      });
    });
    setRows([...rows]);
  };

  const handleRowDragStart = (e, rowIndex) => {
    e.dataTransfer.setData('text/plain', rowIndex);
    setDraggedRowIndex(rowIndex);
  };

  const handleRowDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowDrop = (e, targetRowIndex) => {
    const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
    const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
    updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
    setRows(updatedRows);
    setIsDraggingRow(false);
    setDraggedRowIndex(null);
  };

  const handleColumnDragStart = (e, columnIndex) => {
    e.dataTransfer.setData('text/plain', columnIndex);
  };

  const handleColumnDragOver = (e) => {
    e.preventDefault();
  };

  const handleColumnDrop = (e, targetColumnIndex) => {
    const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

    const updatedColumns = [...columns];
    const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
    updatedColumns.splice(targetColumnIndex, 0, movedColumn);
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = [...row.data];
      const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
      updatedData.splice(targetColumnIndex, 0, movedData);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  const handleRowCheckboxChange = (e, rowIndex) => {
    const rowId = rows[rowIndex].id;
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const deleteService = async (id) => {
    const res = await deleteRequest(`${base_url}/Service/RemoveService`, { servcieId: parseInt(id) });
    if (res?.message === "succeeded") {
      alert('Service Price deleted successfully');
      dispatch(getAllEventsList());
    }
  };

  const deleteColumn = (columnIndex) => {
    const updatedColumns = columns.filter((col, index) => index !== columnIndex);
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = row.data.filter((_, index) => index !== columnIndex);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  useEffect(() => {
    if (store?.allEventsList) {
      setListData(store?.allEventsList);
    }
  }, [store?.allEventsList]);

  useEffect(() => {
    if (listData) {
      setRows(() => listData.map((v, i) => {
        return (
          {
            id: i + 1, serviceId: v?.serviceId, hasChild: v?.hasChild,
            data: [{ id: 1, serviceName: v?.serviceName }, { id: 2, serviceId: v?.serviceId }, { id: 3, serviceUrl: v?.serviceUrl }, { id: 4, isActive: v?.isActive }, { id: 5 }, { id: 6 }]
          })
      }))
    }
  }, [listData]);

  // Calculate the index range of data to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className='container-fluid px-5'>
        <div className="container-fluid p-0 hide_scrollbar position-relative pc_display" style={{ height: '80vh', overflow: 'scroll' }}>
          <table className="table table_main_list w-100 mt-4" style={{
            marginBottom: '150px',
            // overflowX: 'clip' 
          }}>
            <thead>
              <tr>
                <th>
                </th>

                {columns.map((column, index) => {
                  if (index < 1) {
                    return (
                      <th
                        id={`sticky_col${column?.id}`}
                        key={column.id}
                      >{column.name}</th>
                    );
                  }

                  return (
                    <th
                      key={column.id}
                      draggable
                      onDragStart={(e) => handleColumnDragStart(e, index)}
                      onDragOver={handleColumnDragOver}
                      onDrop={(e) => handleColumnDrop(e, index)}

                      onMouseOver={() => setColumnId(column.id)}
                      onMouseOut={() => setColumnId('')}
                      className={isDraggingRow ? 'dragging-row' : ''}

                      style={{
                        width:
                          column?.id == 2 && '100px' ||
                          column?.id == 3 && '150px' ||
                          column?.id == 4 && '150px'
                      }}
                    >
                      <div className='d-flex justify-content-center'>
                        {column.name}
                      </div>
                      <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                        <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                        </button>
                        <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                          <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                        </div>
                      </div>
                    </th>
                  );
                })}
                <th style={{ width: '70px' }}>
                  Action
                </th>
                {/* <th className='addcol_btn_th'>
                <div className="dropdown">
                  <button className="btn bg-transparent p-0 m-0"
                  >
                    <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                  </button>
                </div>
              </th> */}
              </tr>
            </thead>

            <tbody>
              {paginatedRows.length && paginatedRows.map((row, rowIndex) => {
                return (<tr
                  key={row.serviceId}
                  draggable
                  onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                  onDragOver={handleRowDragOver}
                  onDrop={(e) => handleRowDrop(e, rowIndex)}
                  className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                >
                  <td>
                    <div className="form-check ps-4 text-center">
                      <input type="checkbox" value="" id="flexCheckDefault"
                        className="form-check-input checkbox_btn"
                        checked={selectedRows.includes(row.id)}
                        onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                      />
                    </div>
                  </td>

                  {row?.data.length > 0 && row?.data.map((cellValue, columnIndex) => {
                    return (
                      <>
                        {cellValue.id == 1 &&
                          <Tooltip title={`Click to see "${cellValue?.serviceName}" details`}>
                            <td key={columnIndex}
                              id={`sticky_col${columnIndex}`}
                              style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                              onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
                            >
                              <div className='d-flex align-items-center'>
                                <img src='./images/plexar/profile.png' alt='' className='rounded-circle list_img me-3' />
                                <span>{cellValue?.serviceName}</span>
                              </div>
                            </td>
                          </Tooltip>
                        }

                        {cellValue.id === 2 &&
                          <td key={columnIndex}
                            style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                          >
                            <span>{cellValue?.serviceId}</span>
                          </td>
                        }

                        {cellValue.id === 3 &&
                          <td key={columnIndex}
                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                            className='text-center'
                            data-toggle={cellValue?.serviceUrl} data-placement="top"
                          >
                            {rowIndex + 7}
                          </td>
                        }

                        {cellValue.id === 4 &&
                          <td key={columnIndex}
                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                            className='text-center'

                          >
                            3
                          </td>
                        }

                        {cellValue.id === 5 &&
                          <td key={columnIndex}
                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                          >
                            9
                          </td>
                        }

                        {cellValue.id === 6 &&
                          <td key={columnIndex}
                            style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                          >
                            12
                          </td>
                        }
                      </>
                    )
                  })}

                  <td className='text-center'
                    style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                  >
                    <div className="dropdown" style={{ positition: 'relative' }}>
                      <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        style={{ position: 'relative', zIndex: '0' }}
                      >
                        <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                      </button>
                      <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                        style={{ position: 'relative', zIndex: '4' }}
                      >
                        <div className='py-1'
                          onClick={() => history.push(`/add-business-service/${row?.serviceId}`)}
                        >
                          <SyncOutlinedIcon className='text-primary' sx={{ fontSize: '18px' }} />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Assign Business</span>
                        </div>
                        {!row?.hasChild &&
                          <div className='py-1'
                            onClick={() => history.push(`/create-child-service/${row?.serviceId}`)}
                          >
                            <AddBoxIcon className='text-success' sx={{ fontSize: '18px' }} />
                            <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                            >Add Child</span>
                          </div>
                        }
                        <div className='py-1'
                          onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
                        >
                          <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Edit Service</span>
                        </div>
                        <div className='py-1'
                          onClick={() => {
                            deleteService(row?.serviceId)
                          }}
                        >
                          <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }}
                          />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Delete Service</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* <td className={`${rows.length - 1 === rowIndex ? 'p-0 text-center pt-1' : ''}`}
                  style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                >
                </td> */}
                </tr>)
              })}
            </tbody>
          </table>
        </div>

        <div className='row mt-3 pc_display'>
          <div className='col-12 d-flex justify-content-center'>
            <select
              className='items_per_page_select'
              onChange={(e) => setItemsPerPage(e.target.value)}
            >
              <option disabled>Items Per page</option>
              <option value={15} selected>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={rows?.length}>All</option>
            </select>

            <Stack spacing={2} className=''>
              <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedEventsLView;
