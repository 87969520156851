import React from "react";
import "./Farewell.css";
import { laptopphone } from "../../Assets";
import { ReusableBtn } from "./../../MobileHomePage/Reusable/ReusableBtn";
export const Farewell = ({ btn }) => {
  return (
    <div className="farewellContainer">
      <div className="farewellBgColour"></div>

      <div className="alignFarewell">
        <p className="farewellTitle">
          <span>Say Farewell</span> to Manual Overload with Our ERP
        </p>
        <p className="farewellTitle_mobile">
          <span>Say goodbye to</span> sluggish Manual work
        </p>
        <p className="farewellInfo">
          Plexaar lets you automate all your processes like booking, tracking,
          HR, inventory, finance & a lot more.
        </p>
        <img src={laptopphone} alt="A calander" />
      </div>
      {btn && <ReusableBtn color="#548dff" text="JOIN US TODAY" />}
    </div>
  );
};
