import React from "react";
import Styles from "./NextGen.module.css";
import { icon5, ladder, laptop, icon } from "../../Assets";
import { ReusableBtn } from "../Reusable/ReusableBtn";

export const NextGen = () => {
  return (
    <>
      <p className={Styles.nextGenHeading}>
        <span>
          Centralize all your appointments <br /> & customer data
        </span>{" "}
        in one place
      </p>
      <div className={Styles.nextGenImageBox}>
        <img
          src={laptop}
          alt="Plexaar calender"
          className={Styles.nextGenImage}
        />
      </div>

      {/*  */}
      <div className={Styles.nextGenKeypointContainerBox}>
        <div className={Styles.nextGenKeyPointsContainer}>
          <div className={Styles.nextGenKeypoint}>
            <img src={ladder} alt="" />
          </div>
          <p className={Styles.nextGenKeypointsHeading}>
            Measure Your Business KPIs
          </p>
          <p className={Styles.nextGenKeyPointTitle}>
            Generate tailored reports for key areas of your business, including
            sales, lead reports, job handling, how your business is performing
            against KPIs, and so on.
          </p>
        </div>

        <div className={Styles.nextGenKeyPointsContainer}>
          <div className={Styles.nextGenKeypoint}>
            <img src={icon5} alt="" />
          </div>
          <p className={Styles.nextGenKeypointsHeading}>
            A 360° View of Your Leads
          </p>
          <p className={Styles.nextGenKeyPointTitle}>
            Increase customer interactions, reduce costs and scale your business
            with the world's leading CRM. A comprehensive resource that allows
            you to collect, process, and analyze the leads & sales data.
          </p>
        </div>

        <div className={Styles.nextGenKeyPointsContainer}>
          <div className={Styles.nextGenKeypoint}>
            <img src={icon} alt="" />
          </div>
          <p className={Styles.nextGenKeypointsHeading}>
            Intelligent Scheduling & Time Management{" "}
          </p>
          <p className={Styles.nextGenKeyPointTitle}>
            Use Calendar view to schedule all of your appointments, add
            reminders and share details with other users. Take advantage of
            location-based grouping tools to view upcoming appointments by
            location.
          </p>
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <ReusableBtn color="#548dff" text="Get started" />
        </div>
      </div>
    </>
  );
};
