import { useState, useEffect } from "react";
import { number, expirationDate, cvv } from "card-validator";
import creditCardType from "credit-card-type";
import { useSelector } from "react-redux";
import { postRequest } from "../../Componenets/request";
import { base_url } from "../InventoryServices/base_url";
import SideMenu from "../side-menu";

const AddPayment = ({ onClickHandler }) => {
  // State variables
  const [cardNumbers, setCardNumbers] = useState(["", "", "", ""]);
  const [cardHolderName, setCardHolderName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [cardLabel, setCardLabel] = useState("Visa");
  const [isCardValid, setIsCardValid] = useState(true);
  const [address, setAddress] = useState({
    flatBuildingNumber: "",
    streetAddress: "",
    country: "",
    city: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useSelector((state) => state);

  const userData = useSelector((state) => state?.Auth?.alluserdata?.users);


  // Functions
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const paymentObject = {
      email: userData?.primaryEmail,
      userId: userData?.id,
      cardOwnerName: cardHolderName,
      cardNumber: cardNumbers?.join(""),
      expirationYear: selectedYear,
      expirationMonth: selectedMonth,
      cvv: cvc,
      billingAddress: {
        city: address?.city,
        country: address?.country,
        line1: address?.flatBuildingNumber,
        line2: address?.streetAddress,
        state: "tes",
        postalCode: "47000",
      },
    };

    // const res = await postRequest(`${base_url}/Cost/addServiceCost`, paymentObject);
    // if (res?.message === "Success") {

    // }

    // try {
    //   // Assuming the addNewPaymentMethod function returns a Promise
    //   // await addNewPaymentMethod(paymentObject);
    //   onClickHandler();
    // } catch (error) {
    //   alert(error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const checkValidation = () => {
    const countryValidation = /^[A-Z]{2}$/.test(address.country);
    const isFormValid =
      countryValidation &&
      isCardValid &&
      address.flatBuildingNumber !== "" &&
      address.streetAddress !== "" &&
      address.city !== "";
    return !isFormValid;
  };

  useEffect(() => {
    const isCardNumberValid = number(cardNumbers.join("")).isValid;
    const isExpirationDateValid = expirationDate({
      month: selectedMonth,
      year: selectedYear,
    }).isValid;
    const isCvvValid = cvv(cvc).isValid;
    const dataValid = isCardNumberValid && isExpirationDateValid && isCvvValid;
    const cardTypeResult = creditCardType(cardNumbers.join(""));
    const cardTypeLabel =
      cardTypeResult && cardTypeResult[0] ? cardTypeResult[0].niceType : "Unknown";
    setCardLabel(cardTypeLabel);
    setIsCardValid(dataValid);
  }, [cardNumbers, selectedMonth, selectedYear, cvc]);

  const today = new Date();
  const currentYear = today.getFullYear();
  const monthOptions = Array.from({ length: 12 }, (_, index) => {
    const monthValue = (index + 1).toString().padStart(2, "0");
    return (
      <option key={monthValue} value={monthValue}>
        {monthValue}
      </option>
    );
  });

  const yearOptions = Array.from({ length: 10 }, (_, index) => {
    const yearValue = (currentYear + index).toString();
    return (
      <option key={yearValue} value={yearValue}>
        {yearValue}
      </option>
    );
  });

  const handleCardNumberChange = (index, value) => {
    const newCardNumbers = [...cardNumbers];
    newCardNumbers[index] = value;
    setCardNumbers(newCardNumbers);
  };

  const handleCvcValue = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, "");
    const trimmedValue = sanitizedValue.slice(0, 3);
    if (trimmedValue.length >= 4) {
      // Do something if needed
    } else {
      setCvc(trimmedValue);
    }
  };

  const handleCardHolderNameChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^A-Za-z\s]/g, ""); // Remove non-alphabetic and non-space characters
    setCardHolderName(sanitizedValue);
  };

  return (
    <SideMenu>
      <div className="p-5">
        <div
          className={`bg-light rounded border ${isCardValid ? "" : "border-danger"
            } p-3`}
        >
          <div className="d-flex flex-column">
            <span className="fs-6 text-secondary">Card Number</span>
            <div className="d-flex">
              <div className="d-flex card-number-input">
                {cardNumbers.map((number, index) => (
                  <input
                    key={index}
                    type="tel"
                    maxLength={4}
                    name="cardNumber"
                    placeholder="0000"
                    className="form-control border border-0 border-bottom me-2"
                    style={{
                      // width: "5%",
                    }}
                    onKeyPress={(e) => {
                      if (e.which < 48 || e.which > 57) e.preventDefault(); // Prevent entering non-digit characters
                    }}
                    value={number}
                    onChange={(e) =>
                      handleCardNumberChange(index, e.target.value)
                    }
                    required
                  />
                ))}
              </div>
              <div className="w-50 d-flex justify-content-center">
                <img
                  alt="card label"
                  src={
                    cardLabel === "Mastercard"
                      ? "/assets/images/mastercard.png"
                      : "/images/visa.png"
                  }
                  height={50}
                  width={50}
                />
              </div>
            </div>
          </div>
          <label htmlFor="Name" className="mt-2 fs-6 text-secondary">
            Card Holder Name
          </label>
          <div>
            <input
              type="text"
              className="form-control w-50"
              id="Name"
              value={cardHolderName}
              placeholder="Card Holder Name"
              onChange={handleCardHolderNameChange}
            />
          </div>
          <div className="d-flex mt-2">
            <div>
              <label htmlFor="expiry" className="fs-6 text-secondary">
                Expire Date
              </label>
              <div className="d-flex">
                <select
                  id="expiry"
                  className="form-control me-2"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {monthOptions}
                </select>
                <select
                  id="expiry-year"
                  className="form-control me-2"
                  style={{ width: '64px' }}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {yearOptions}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="cvc" className="fs-6 text-secondary">
                CVC
              </label>
              <div>
                <input
                  type="number"
                  id="cvc"
                  value={cvc}
                  onChange={handleCvcValue}
                  maxLength={3}
                  min="0"
                  max="999"
                  pattern="[0-9]*" // Allow only digits on mobile devices
                  inputMode="numeric" // Show numeric keyboard on mobile devices
                  placeholder="000"
                  className="form-control me-2 w-100 border border-0 border-bottom"
                  onKeyPress={(e) => {
                    if (e.which < 48 || e.which > 57) e.preventDefault(); // Prevent entering non-digit characters
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {isCardValid ? null : (
          <div className="text-danger">This Card is not valid</div>
        )}
        <div className="d-flex my-4">
          <input
            type="text"
            placeholder="Flat & Building Number"
            className="rounded border border-primary-subtle px-3 py-2 me-4"
            name="flatBuildingNumber"
            value={address.flatBuildingNumber}
            disabled={isLoading}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="Street Address"
            className="rounded border border-primary-subtle px-3 py-2"
            name="streetAddress"
            value={address.streetAddress}
            disabled={isLoading}
            onChange={handleInputChange}
          />
        </div>
        <input
          type="text"
          placeholder="UK"
          className="rounded border border-primary-subtle px-3 py-2 me-4"
          name="country"
          value={address.country}
          disabled={isLoading}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="City"
          className="rounded border border-primary-subtle px-3 py-2"
          name="city"
          value={address.city}
          disabled={isLoading}
          onChange={handleInputChange}
        />
        <div className="d-flex justify-content-end">
          <button
            className="bg-danger bg-opacity-25 border-0 rounded text-danger me-4 px-4 py-2"
            disabled={isLoading}
            onClick={onClickHandler}
          >
            Cancel
          </button>
          {isLoading ? (
            // <Loader status={isLoading} style={{}} />
            'Loading'
          ) : (
            <button
              type="submit"
              className={`btn btn-danger px-5 py-2`}
              //   className={`btn btn-danger px-5 py-2 ${checkValidation() ? "disabled" : ""
              // }`}
              // disabled={checkValidation()}
              onClick={handleSubmit}
            >
              Add Now
            </button>
          )}
        </div>
      </div>
    </SideMenu>
  );
};

export default AddPayment;
