import React, { useState } from 'react';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

const Topbar = ({ renderViewFunc }) => {
    const [view, setView] = useState(1);

    return (
        <div className='d-flex justify-content-between align-items-center topbar_main px-2'>
            <div className='sec1 d-flex'>
                <img src='./meetspace/moon1.png' className='topbar_logo' />
                <span className='d-flex align-items-center ms-5'>
                    <h4 className='p-0 m-0'>Booked Events!</h4>
                    <img src='./meetspace/comp1.png' alt='' className='ms-2 h-50' />
                </span>
            </div>
        </div>
    )
}

export default Topbar