import {
  ACTIVATE_LOADER,
  DEACTIVATE_LOADER,
  GET_DYNAMIC_FORM_SUCCESSFULLY,
  SUBMIT_DYNAMIC_FORM_SUCCESSFULLY,
  STORE_CONSENT_FORM_ID_SUCCESSFULLY,
} from "../../ActionType";

const initialState = {
  form: {},
  sectionHeader: "",
  dynamicFormSubmitted: false,
  totalSections: [],
  loading: false,
  consentFormId: "",
};

const dynamicFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case DEACTIVATE_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_DYNAMIC_FORM_SUCCESSFULLY: {
      return {
        ...state,
        form: action.payload.data.result.currentSection,
        sectionHeader: action.payload.data.result.currentSection.sectionTitle,
        totalSections: action.payload.data.result.totalSections,
        dynamicFormSubmitted: false,
      };
    }

    case SUBMIT_DYNAMIC_FORM_SUCCESSFULLY: {
      return {
        ...state,
        dynamicFormSubmitted: true,
      };
    }
    case STORE_CONSENT_FORM_ID_SUCCESSFULLY: {
      console.log("THIS IS PAYLOAD", action.payload);
      return {
        ...state,
        consentFormId: action.payload.data.result.consentForm.formIds[0],
      };
    }

    default:
      return state;
  }
};

export default dynamicFormReducer;
