import HRManagerHomePage from "../Pages/Homepage/MudasirScreens/HRManagement/components/Home/Home";
import LearnMoreHRFeatures from "../Pages/Homepage/MudasirScreens/HRManagement/components/ReadMoreButtonsPages/ReadMoreButtonsPages";
import InventoryHomePage from "../Pages/Homepage/MudasirScreens/Inventory/components/Home/Home";
import LearnMoreStockHomePage from "../Pages/Homepage/MudasirScreens/Inventory/components/ReadMoreButtonsPages/ReadMoreButtonsPages";
import FinanceHomePage from "../Pages/Homepage/MudasirScreens/Finance/components/Home/Home";
import LearnMoreFinancePage from "../Pages/Homepage/MudasirScreens/Finance/components/ReadMoreButtonsPages/ReadMoreButtonsPages";
import CalendaxHomePage from "../Pages/Homepage/MudasirScreens/Calendex/components/Home/Home";
import LandingPage from "../Pages/PublicPages/Homepage/LandingPage/LandingPage";

export const HomePageRoutes = [
  {
    path: "/",
    component: LandingPage,
    ispublic: true,
    exact: true,
  },
  {
    path: "/calendex",
    component: CalendaxHomePage,
    ispublic: false,
    exact: true,
  },
  {
    path: "/hr-manager",
    component: HRManagerHomePage,
    ispublic: true,
    exact: true,
  },
  {
    path: "/learn-more-hr-features/:id",
    component: LearnMoreHRFeatures,
    ispublic: true,
    exact: true,
  },
  {
    path: "/inventory",
    component: InventoryHomePage,
    ispublic: true,
    exact: true,
  },
  {
    path: "/learn-more-stock/:id",
    component: LearnMoreStockHomePage,
    ispublic: true,
    exact: true,
  },
  {
    path: "/finance",
    component: FinanceHomePage,
    ispublic: true,
    exact: true,
  },
  {
    path: "/learn-more-finance-mannage/:id",
    component: LearnMoreFinancePage,
    ispublic: true,
    exact: true,
  },
];
