import React from 'react';
import TimeSlot from '../components/TimeSlot';
import TimeSlotMobileView from '../components/TimeSlotMobileView';


import SideNavone from '../../../SideNavBar';

const TimeSlotPage = () => {
  return (
    <>
     <SideNavone>
      <TimeSlot />
      <TimeSlotMobileView/>
      </SideNavone>
    </>
  );
};

export default TimeSlotPage;