import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { FiXCircle } from "react-icons/fi";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";

const ZoomableImageModal = ({ imageUrl, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const imageRef = useRef(null);

  const handleZoomIn = () => {
    // Implement zoom-in logic
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    // Implement zoom-out logic
    setZoomLevel(zoomLevel - 0.1);
  };

  const handleDoubleClick = () => {
    // Toggle zoom on double click
    if (zoomLevel === 1) {
      setZoomLevel(2.5);
    } else {
      setZoomLevel(1);
    }
  };
  return (
    <div>
      <div
        style={{
          position: "fixed",
          overflow: "auto",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgb(0 0 0 / 89%)",
          zIndex: 99999999,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: "transparent",
            padding: "2px",
            borderRadius: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          style={{
            position: "absolute",
            /* left: 0; */
            right: 0,
            zIndex: 99999,
          }}
        >
          <FiXCircle
            style={{
              color: "#fff",
              fontSize: "40px",
            }}
          />
        </IconButton>
        <div
          className="d-flex"
          style={{
            position: "absolute",
            top: "10%",
            left: "65%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
          }}
        >
          <IconButton
            onClick={handleZoomOut}
            className="pe-2"
            sx={{
              backgroundColor: "transparent",
              padding: "2px",
              width: "100px",
              borderRadius: "20%",
              display: "flex",
              alignItems: "center",
              paddingRight: 10,
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#b2b2b2",
                padding: "2px",
                width: "100px",
                borderRadius: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            style={{}}
          >
            <BsZoomOut
              style={{
                color: "#fff",
                fontSize: "30px",
              }}
            />
          </IconButton>
          <IconButton
            onClick={handleZoomIn}
            sx={{
              backgroundColor: "transparent",
              padding: "2px",
              borderRadius: "20%",
              display: "flex",
              width: "100px",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#b2b2b2",
                padding: "2px",
                borderRadius: "20%",
                display: "flex",
                width: "100px",

                alignItems: "center",
                justifyContent: "center",
              },
            }}
            style={{}}
          >
            <BsZoomIn
              style={{
                color: "#fff",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            ref={imageRef}
            src={imageUrl}
            alt="Zoomable Image"
            style={{
              width: "100%",
              height: "auto",
              transform: `scale(${zoomLevel})`,
              transition: "transform 0.3s ease-in-out",
              cursor: zoomLevel == 1 ? "zoom-in" : "zoom-out",
            }}
            onDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ZoomableImageModal;
