import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import BookingData from "../updateBooking/Updatebooking.jsx";
import SideNavone from "../../../SideNavBar";

export default function updateBooking() {
  return (
    <div>
      <SideNavone>
        <BookingData />
      </SideNavone>
    </div>
  );
}
