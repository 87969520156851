// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.providerWidgetSkeleton_tableContainer__kyZ7z {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
  
  .providerWidgetSkeleton_customTable__gm8bd {
    border-collapse: collapse;
    width: 100%;
    max-width: 700px;
    border: 0.06rem solid #5599ff;
  }
  
  .providerWidgetSkeleton_customTableCell__Uu3g3 {
    padding: 0.8rem;
    border: 0.06rem solid #5599ff;
  }
  
  .providerWidgetSkeleton_skeleton__xsSAT {
    width: 100%;
    height: 1rem; /* Adjust as needed */
    background: linear-gradient(90deg, #5599ff 25%, #99c2ff 50%, #d6e7ff 75%);
    background-size: 200% 100%;
    animation: providerWidgetSkeleton_skeleton-loading__ZUrJ5 1.5s infinite ease-in-out forwards alternate;
  }
  
  @keyframes providerWidgetSkeleton_skeleton-loading__ZUrJ5 {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Calendar/scheduler/components/skeleton/providerWidgetSkeleton/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,WAAW;IACX,YAAY,EAAE,qBAAqB;IACnC,yEAAyE;IACzE,0BAA0B;IAC1B,sGAAwE;EAC1E;;EAEA;IACE;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".tableContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 90vh;\n  }\n  \n  .customTable {\n    border-collapse: collapse;\n    width: 100%;\n    max-width: 700px;\n    border: 0.06rem solid #5599ff;\n  }\n  \n  .customTableCell {\n    padding: 0.8rem;\n    border: 0.06rem solid #5599ff;\n  }\n  \n  .skeleton {\n    width: 100%;\n    height: 1rem; /* Adjust as needed */\n    background: linear-gradient(90deg, #5599ff 25%, #99c2ff 50%, #d6e7ff 75%);\n    background-size: 200% 100%;\n    animation: skeleton-loading 1.5s infinite ease-in-out forwards alternate;\n  }\n  \n  @keyframes skeleton-loading {\n    0% {\n      background-position: -200% 0;\n    }\n    100% {\n      background-position: 200% 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `providerWidgetSkeleton_tableContainer__kyZ7z`,
	"customTable": `providerWidgetSkeleton_customTable__gm8bd`,
	"customTableCell": `providerWidgetSkeleton_customTableCell__Uu3g3`,
	"skeleton": `providerWidgetSkeleton_skeleton__xsSAT`,
	"skeleton-loading": `providerWidgetSkeleton_skeleton-loading__ZUrJ5`
};
export default ___CSS_LOADER_EXPORT___;
