import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getServicePrices, getAllUnitTypes, getAllCurrencies, getPriceVariables } from '../../store/servicesActions';
import { useDispatch, useSelector } from 'react-redux';
import ServiceLoader from '../ServiceLoader';
import PriceList from './components/PriceList';
import PriceListMobile from './components/PriceListMobile';
import VariableList from './components/VariableList';
import VariableListMobile from './components/VariableListMobile';

const Pricing = () => {
  const [renderVar, setRenderVar] = useState(false);
  const [priceId, setPriceId] = useState();
  const [priceData, setPriceData] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(state => state?.servicesReducer);

  const renderVarbilesList = (vid, price) => {
    setPriceId(vid)
    setPriceData(price)
    setRenderVar(!renderVar);
  }

  // const getData = () => {
  //   if (id !== null) {
  //     dispatch(getServicePrices(id))
  //   }
  // }

  const getVariablesData = (pid) => {
    if (id !== null) {
      dispatch(getPriceVariables(pid))
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(getServicePrices(id))
      dispatch(getAllUnitTypes())
      dispatch(getAllCurrencies())
    }
    if (priceId && id) {
      getVariablesData(priceId);
    }
  }, [id, priceId, renderVar])

  return (
    <>
      {store?.isLoading && store?.servicePrices.length === 0 ?
        <ServiceLoader />
        : <>
          {!renderVar ?
            <>
              <PriceList renderVarbilesList={renderVarbilesList} />
              <PriceListMobile renderVarbilesList={renderVarbilesList} />
            </> : <>
              <VariableList reFetchData={getVariablesData} renderVarbilesList={renderVarbilesList} priceId={priceId} priceData={priceData} />
              <VariableListMobile reFetchData={getVariablesData} renderVarbilesList={renderVarbilesList} priceId={priceId} priceData={priceData} />
            </>
          }
        </>}
    </>
  )
}

export default Pricing