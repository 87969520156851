import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllEventsList } from '../../../../Store/MeetSpace/meetspaceActions';
import SideMenu from '../../side-menu';
import "../../../../Assets/components/meetspace/events.scss";
import Topbar from './components/Topbar';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, FormControlLabel, Switch } from '@mui/material';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import One2OneMeetingList from './components/One2OneMeetingList';
import GroupMeetingList from './components/GroupMeetingList';
import { useParams } from 'react-router-dom';
import GroupMeetingListMobile from './components/GroupMeetingListMobile';
import One2OneMeetingListMobile from './components/One2OneMeetingListMobile';

const EventDetails = () => {
    const [selectedTab, setSelectedTab] = useState('1');
    const [view, setView] = useState(1);
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const store = useSelector(state => state?.meetspaceReducer);
    const { eid } = useParams();

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        dispatch(getAllEventsList());
    }, []);

    useEffect(() => {
        if (store?.allEventsList) {
            setData([...store?.allEventsList]);
        }
    }, [])

    return (
        <SideMenu topbar={<Topbar />}>
            <div className='events_list'>
                <div className='container d-flex justify-content-center align-items-center event_details_container1 mt-3 p-0'>
                    <div className='row detail_row1 h-100 w-100 align-items-center'>
                        <div className='col-lg-6 col-12 h-100'>
                            <div className='h-100 col1_contents d-flex flex-column justify-content-center p-3'>
                                <h4 className='_cont1'>London Laser</h4>
                                <h6 className='text-secondary p-0 m-0 mt-0'>{eid == '2' ? 'Group Meeting' : 'One On One Meeting'}</h6>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex flex-column mt-3'>
                                        <label>Active</label>
                                        <div className="form-check form-switch pt-0">
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                defaultChecked='true'
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <AccessTimeOutlinedIcon sx={{ fontSize: '18px' }} />
                                        <span className='ms-2'>{eid == '2' ? 'Aug 25 2022 - Aug 24 2023' : 'Aug 21 2022 - Aug 28 2023'}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3 col-5 h-100 mt-3'>
                            <div className='h-100 col2_contents d-flex flex-column justify-content-center p-3'>
                                <Button
                                    variant={`text`}
                                    className='p-2'
                                >
                                    <TuneOutlinedIcon sx={{ fontSize: '25px' }} />
                                </Button>
                                <h3 className='mt-3 p-0'>{eid == '2' ? '142' : '09'}</h3>
                                <h6 className='text-secondary p-0 m-0'>Total Number Of Events</h6>
                            </div>
                        </div>

                        <div className='col-lg-3 col-7 h-100 mt-3'>
                            <div className='h-100 col3_contents d-flex flex-column justify-content-center p-3'>
                                <h4>Meetings Overview</h4>
                                <div className='overview_grid'>
                                    <span className='d-flex align-items-center overview_sec1'>
                                        <div className='bg-primary' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                                        <span className='ms-2'>Booked</span>
                                    </span>
                                    <span className='overview_sec2'>80</span>
                                    <span className='overview_sec3'>100%</span>
                                </div>

                                <div className='overview_grid mt-1'>
                                    <span className='d-flex align-items-center overview_sec1'>
                                        <div className='bg-warning' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                                        <span className='ms-2'>Pending</span>
                                    </span>
                                    <span className='overview_sec2'>75</span>
                                    <span className='overview_sec3'>90%</span>
                                </div>

                                <div className='overview_grid mt-1'>
                                    <span className='d-flex align-items-center overview_sec1'>
                                        <div className='bg-danger' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                                        <span className='ms-2'>Cancel</span>
                                    </span>
                                    <span className='overview_sec2'>5</span>
                                    <span className='overview_sec3'>10%</span>
                                </div>

                                <div className='d-flex mt-2'>
                                    <span className='bg-primary' style={{ height: '5px', width: '60%', borderRadius: '12px' }}></span>
                                    <span className='bg-warning ms-1' style={{ height: '5px', width: '30%', borderRadius: '12px' }}></span>
                                    <span className='bg-danger ms-1' style={{ height: '5px', width: '10%', borderRadius: '12px' }}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {eid && eid == '2' ?
                    <>
                        <GroupMeetingList />
                        <GroupMeetingListMobile />
                    </> :
                    <>
                        <One2OneMeetingList />
                        <One2OneMeetingListMobile />
                    </>
                }
            </div>
        </SideMenu>
    )
}

export default EventDetails;