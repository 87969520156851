import React from "react";
import Styles from "./Joinus.module.css";
import { office } from "../../Assets";

export const Joinus = () => {
  return (
    <>
      <div className={Styles.joinUsContainer}>
        <img src={office} alt="" />
        <div className={Styles.joinUsTextContainer}>
          <p className={Styles.textBold}>Empower your business and bolster</p>
          <p className={Styles.textLight}>your sales experience.</p>
        </div>
      </div>
      <p className={Styles.infoText}>
        Having Plexaar by your side, lets you easily view every stage of your
        sales funnel in a lucid and simple interface. By integrating this highly
        customizable CRM into your business, not only are you improving your
        sales but also enhancing your customer support and, ultimately, your
        business.
      </p>
    </>
  );
};
