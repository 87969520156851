import React, { useEffect, useState } from "react";
import Table from "./Table";
import Model from "./Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import TableList from "./TableList";
import TableListMobile from "./TableListMobile";
import { useSelector } from "react-redux";
import instance from "../../../../utils/utils";

export default function BusinessTable() {
  const history = useHistory();
  const { id } = useParams();
  const [businessId, setBusinessId] = useState();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeProvider, setActiveProvider] = useState();
  const [loading, setLoading] = useState(false); // Initially, set loading to true
  const business = useSelector((state) => state.Business?.selectedBusiness);

  useEffect(() => {
    setLoading(true);

    instance
      .get(
        `/serviceprovider_svc/pv/providersbusiness/getProviderBusinessByBussinessId?id=${business?.id}`
      )
      .then((res) => {
        setData(res.data.result.providerBusiness);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((err) => {
        console.error(err);
      });
  }, [business?.id]);

  const handleClose = () => setOpen(false);

  const handleOpenModal = (val) => {
    setActiveProvider(val);
    setOpen(true);
  };

  return (
    <div className="form-container hide_scrollbar p-3">
      {loading ? (
        <div className="loader-container">
          <CircularProgress size={40} />
        </div>
      ) : data.length > 0 ? (
        <>
          <TableList
            businessId={business?.id}
            data={data}
            handleOpen={handleOpenModal}
          />
          <TableListMobile
            businessId={business?.id}
            data={data}
            handleOpen={handleOpenModal}
          />
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <h5 className="m-0">Provider is not Registered. Kindly Add That</h5>
          <Button
            style={{ backgroundColor: "#5599FF" }}
            className="col-4 py-3 my-3"
            variant="contained"
            onClick={(e) => {
              history.push(`/staff-list/${id}`);
            }}
          >
            Add Staff
          </Button>
        </div>
      )}
      {open ? (
        <Model
          businessId={business?.id}
          open={open}
          handleClose={handleClose}
          activeProvider={activeProvider}
        />
      ) : null}
    </div>
  );
}
