import React, { useContext, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoArrowBackOutline } from "react-icons/io5";
import Day from "./day";
import Button from "@mui/material/Button";
import Week from "./RepeatedDay";
import { useHistory, useLocation } from "react-router-dom";
import {
  getScheduleList,
  getSchedule,
  postSchedule,
  postdateSchedule,
  updateSchedule,
} from "../../services/serviceProvider";
import InputDate from "../InputDate";
import { FormateDate, success, error } from "../../utils";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import dayjs from "dayjs";
import { SelectedDateContext } from "../../../../context";
import {
  defaultItem,
  editFormatdata,
  formateData,
  formateRepeatData,
  initialValuesofSlot,
  // timeSlotSchema,
  defaultItemDates,
  weekOptions,
  convertToDateTime,
} from "./helper";
import * as Yup from "yup";
import RepeatDate from "./RepeatedDate";
import RepeatedDay from "./RepeatedDay";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Header from "../../../BusinessProfile/Header";
import ExpiryInputDate from "../InputDate/EpiryInputField";
import useCheckPermission, { customSnackBar } from "../../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxIcon from "@mui/icons-material/AddBox";
import axios from "axios";
import CustomizedSnackbars from "../../../../Calendar/scheduler/components/AlertToast";
import { useSelector } from "react-redux";
import { convertTo12HourFormat, convertTo24HourFormat } from "../../utils/helper";
import instance from "../../../../utils/utils";

const TimeSlot = ({ route }) => {
  const location = useLocation();
  const history = useHistory();
  const flag = true;
const userInfo = useSelector((state) => state.Auth.userloginsuccessyasir)
  const [states, setState] = useContext(SelectedDateContext);
  const [activeWeekOptions, setActiveWeekOptions] = useState([]);
  const [activeMonthOptions, setActiveMonthOptions] = useState([]);
  const [permissionView, setViewPermissions] = useState(null);

  const [loading, setLoading] = useState(false);
  const [businessId, setBusinessId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [isOpen, setIsOpen] = useState({
    startDate: false,
    endDate: false,
  });
  const [scheduleData, setScheduleData] = useState({});
  const [businessLocations, setBusinessLocations] = useState([]);
  const [expandList, setExpandList] = useState(false);
  const [permission, setPermissions] = useState(null);

  const formikRef = useRef();
console.log(formikRef,"formikRefformikRefformikRef")
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setBusinessId(urlParams.get("busiessId"));
    setProviderId(urlParams.get("providerId"));

    getSchedules(parseInt(urlParams.get("providerId")));

    getBusinessLocations();
  }, []);
  useCheckPermission("Staff", "canCreateStaffSchedule").then((res) => {
    setPermissions(res);
  });
  useCheckPermission("Staff", "canUpdateStaffSchedule").then((res) => {
    setViewPermissions(res);
  });
  const date = scheduleData.date || "";

  const editData = scheduleData.date || "";

  const editRecord = scheduleData.data || "";

  const day = moment(date || new Date()).day();
  const week = Math.ceil(moment(date).date() / 7);
  const month = Math.ceil(moment(date).month() + 1);
  const action = scheduleData.actionState || "new";

  //handle the state of repeatedDay
  const handleChangeRepeatedDays = (repeatedDays, setFieldValue, values) => {
    if (values.repeatedDates === true) {
      setFieldValue("repeatedDates", !values.repeatedDates);
    }
    if (repeatedDays === true) {
      const item = values.scheduleItems.find((slot) => slot.active);
      setFieldValue("scheduleItems", [item]);
      setFieldValue("startDate", item.date);
      setFieldValue("endDate", item.date);
      setFieldValue("repeatedDays", !repeatedDays);
    } else if (repeatedDays === false) {
      setFieldValue("repeatedDays", !repeatedDays);
    } else {
      setFieldValue("repeatedDays", !repeatedDays);
    }
  };

  //handle the state of repeatedDates
  const handleChangeRepeatedDates = (repeatedDates, setFieldValue, values) => {
    if (values.repeatedDays === true) {
      setFieldValue("repeatedDays", !values.repeatedDays);
    }
    if (repeatedDates === true) {
      const item = values.scheduleItems.find((slot) => slot.active);
      setFieldValue("scheduleItems", [item]);
      setFieldValue("startDate", item.date);
      setFieldValue("endDate", item.date);
      setFieldValue("repeatedDates", !repeatedDates);
    } else if (repeatedDates === false) {
      setFieldValue("repeatedDates", !repeatedDates);
    } else {
      setFieldValue("repeatedDates", !repeatedDates);
    }
  };

  //add timeSlot function
  const handleAddSlot = (setFieldValue, scheduleItems) => {
    const lastSlot = scheduleItems[scheduleItems.length - 1];
    const newSlotDate = new Date(lastSlot.date);
    newSlotDate.setDate(newSlotDate.getDate() + 1);

    const newSlot = {
      date: newSlotDate,
      day: moment(newSlotDate).day() === 0 ? 7 : moment(newSlotDate).day(),
      startTime: "",
      endTime: "",
    };

    setFieldValue("scheduleItems", [...scheduleItems, newSlot]);
  };

  //delete timeSlot function
  const handleDeleteSlot = (setFieldValue, scheduleItems, index) => {
    setFieldValue(
      "scheduleItems",
      scheduleItems.filter((slot, i) => i !== index)
    );
  };

  //get schedule list
  const getSchedules = (providerId) => {
    setLoading(true);
    getScheduleList(providerId).then((res) => {

      setSchedules(res?.result);
      if (res?.result?.length > 0 && formikRef.current) {
        setSelectedSchedule(res?.result[0]);
    console.log(res?.result[0],"providerIdproviderId")

        getSingleSchedule(res?.result[0].id, formikRef.current.setFieldValue);
      }
      setLoading(false);
    });
  };

  const getSingleSchedule = (scheduleId, setFieldValue) => {
    console.log(scheduleId,"providerIdproviderId")
    setExpandList(false);
    setLoading(true);
    getSchedule(scheduleId).then((res) => {
    console.log(res,"providerIdproviderId")

      const weeksVal = weekOptions.find(
        (data) => data?.value == res?.result?.repeatPattern
      );

      const scheduleDataItems = [];

      for (let i = 1; i <= weeksVal.id; i++) {
        for (let j = 1; j <= 7; j++) {
          let obj = {
            expanded: false,
            active: false,
            date: new Date(),
            day: j,
            dayName:
              j == 1
                ? "Monday"
                : j == 2
                ? "Tuesday"
                : j == 3
                ? "Wednesday"
                : j == 4
                ? "Thursday"
                : j == 5
                ? "Friday"
                : j == 6
                ? "Saturday"
                : "Sunday",
            week: i,
            month: 11,
            startTime: [""],
            endTime: [""],
            businessLocation: [],
          };
          scheduleDataItems.push(obj);
        }
      }

      const data = {
        date: new Date(),
        data: res.result,
        actionState: "edit",
      };

      setScheduleData(data);

      function localObjWithTimeSlotsData(data) {
        for (let i = 0; i < data.data.scheduler.length; i++) {
          for (
            let localObjIndex = 0;
            localObjIndex < scheduleDataItems.length;
            localObjIndex++
          ) {
            if (
              data.data.scheduler[i].dayOfWeek ===
                scheduleDataItems[localObjIndex].dayName
                  .toLowerCase()
                  .substr(0, 3) &&
              data.data.scheduler[i].week ===
                scheduleDataItems[localObjIndex].week
            ) {
              let startTimes = [];
              let endTimes = [];
              let businessLocation = [];

              for (let j = 0; j < data.data.scheduler[i].slots.length; j++) {
                // startTimes.push(
                //   convertTo12HourFormat(data.data.scheduler[i].slots[j].startTime)
                // );
                // endTimes.push(
                //   convertTo12HourFormat(data.data.scheduler[i].slots[j].endTime)
                // ); 
                startTimes.push(
                 ( data.data.scheduler[i].slots[j].startTime).slice(0, 5)
                );
                endTimes.push(
                 (data.data.scheduler[i].slots[j].endTime).slice(0, 5)
                );
                businessLocation.push(
                  data.data.scheduler[i].slots[j].businessId
                );
              }
              scheduleDataItems[localObjIndex].startTime = startTimes;
              scheduleDataItems[localObjIndex].endTime = endTimes;
              scheduleDataItems[localObjIndex].businessLocation =
                businessLocation;
              scheduleDataItems[localObjIndex].active = true;
              scheduleDataItems[localObjIndex].expanded = true;
            }
          }
        }
      }

      localObjWithTimeSlotsData(data);

      setFieldValue("action", "edit");
      setFieldValue("startDate", res.result.startDate);
      setFieldValue("endDate", res.result.endDate);
      setFieldValue("repeatPattern", weeksVal);
      setFieldValue("providerId", res.result.providerId);
      setFieldValue("scheduleItems", scheduleDataItems);

      setLoading(false);
    });
  };

  //timeslot creation
  const createSlot = (values, handles) => {
    setLoading(true);

    const isDayActiveWeek1 = values.scheduleItems
      .filter((data) => data.week === 1)
      ?.find((d) => d.active === true);
    const isDayActiveWeek2 = values.scheduleItems
      .filter((data) => data.week === 2)
      ?.find((d) => d.active === true);
    const isDayActiveWeek3 = values.scheduleItems
      .filter((data) => data.week === 3)
      ?.find((d) => d.active === true);
    const isDayActiveWeek4 = values.scheduleItems
      .filter((data) => data.week === 4)
      ?.find((d) => d.active === true);

    if (values.repeatPattern.id === 1 && !isDayActiveWeek1) {
      customSnackBar("There should be atleast 1 day active in each week.");
    setLoading(false);

      return;
    } else if (
      values.repeatPattern.id === 2 &&
      (!isDayActiveWeek1 || !isDayActiveWeek2)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
    setLoading(false);

      return;
    } else if (
      values.repeatPattern.id === 3 &&
      (!isDayActiveWeek1 || !isDayActiveWeek2 || !isDayActiveWeek3)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
    setLoading(false);

      return;
    } else if (
      values.repeatPattern.id === 4 &&
      (!isDayActiveWeek1 ||
        !isDayActiveWeek2 ||
        !isDayActiveWeek3 ||
        !isDayActiveWeek4)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
    setLoading(false);

      return;
    }
    const providerData = JSON.parse(localStorage.getItem("StaffRecord"));

    const scheduledItemsData = [];

    const items = values.scheduleItems.filter((item) => item.active === true);

            // dayjs.isDayjs(items[i].startTime[j]) === false
            //   ? items[i].startTime[j]
            //   : dayjs(items[i].startTime[j], "h:mma").format("HH:mm:ss"),
            
            // dayjs.isDayjs(items[i].endTime[j]) === false
            //   ? items[i].endTime[j]
            //   : dayjs(items[i].endTime[j], "h:mma").format("HH:mm:ss"),
    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < items[i].startTime.length; j++) {
        const data = {
          week: items[i].week,
          day: items[i].day,
          startTime: [`${items[i].startTime[j]}:00`], // Add seconds ":00" to the time
          endTime: [`${items[i].endTime[j]}:00`],   
          // startTime: 
          //   [convertTo24HourFormat(items[i].startTime[j]),],
          
          // endTime: [
          //   convertTo24HourFormat(items[i].endTime[j]),
          // ],
          businessId: items[i].businessLocation[j],
          businessName: businessLocations.find(
            (d) => d.id === items[i].businessLocation[j]
          ).name,
        };
        scheduledItemsData.push(data);
      }
    }

    const data = {
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
      timeZone: "Europe/London",
      scheduleType: values.repeatPattern.id,
      scheduleItems: scheduledItemsData,
      // providerType: 1,
      // providerId: 3,
      // providerName: "New User",
      // providerType: providerData ? providerData.projectId : null,
      providerType: 2,

      providerId: providerData ? providerData.providerId : parseInt(providerId),
      providerName: providerData
        ? providerData.firstName + " " + providerData.lastName
        : "",
      providerImageURL: `${process.env.REACT_APP_IMAGE_URL}/Serviceprovider/${providerData.providerId}.jpeg`,
    };

    // debugger;
    const updatedData = {
      ...data,
      timeZone: "Europe/London",
      // businessData: { businessId: parseInt(businessId) }, // Replace the "businessId" with the new value if needed
      // providerType: providerData ? providerData.projectId : null, // Replace the "businessId" with the new value if needed
      // providerId: providerData ? providerData.providerId : parseInt(providerId), // Replace the "providerId" with the new value if needed
      // providerName: providerData
      //   ? providerData.firstName + " " + providerData.lastName
      //   : "",
      scheduleItems: data.scheduleItems.map((item) => {
        if (item.day === 0) {
          return { ...item, day: 7 };
        }
        return item;
      }),
    };
    const jsonData = JSON.stringify(updatedData);
    postSchedule(jsonData).then((res) => {
      if (res.statusCode === 0) {
        // success(res.message);
        setState([...states, values]);
        setLoading(false);

        history.push("/calendar");
        // history.push(`/providerScheduler/${parseInt(businessId)}`);
        handles.resetForm();
      } else {
        setLoading(false);

        customSnackBar(res.message);
        // handles.setValues(initialValuesofSlot());
      }
    });
  };
  //create repeatDate
  const createRepeatDate = (values, handles) => {
    const data = formateRepeatData(values);
    const providerData = JSON.parse(localStorage.getItem("StaffRecord"));
    const updatedData = {
      ...data,
      businessData: { businessId: parseInt(businessId) }, // Replace the "businessId" with the new value if needed
      providerType: providerData.projectId, // Replace the "businessId" with the new value if needed
      providerId: parseInt(providerId), // Replace the "providerId" with the new value if needed
      providerName: providerData.firstName + " " + providerData.lastName,
    };
    const jsonData = JSON.stringify(updatedData);
    postdateSchedule(jsonData).then((res) => {
      setLoading(false);
      if (res.code === 0) {
        success(res.message);
        setState([...states, values]);
        history.push(`/providerScheduler/${parseInt(businessId)}`);
        handles.resetForm();
      } else {
        error(res.message);
        customSnackBar(res.message);

        // handles.setValues(initialValuesofSlot());
      }
    });
  };

  //timeslot updation
  const updateSlot = (values, handles) => {
    const isDayActiveWeek1 = values.scheduleItems
      .filter((data) => data.week === 1)
      ?.find((d) => d.active === true);
    const isDayActiveWeek2 = values.scheduleItems
      .filter((data) => data.week === 2)
      ?.find((d) => d.active === true);
    const isDayActiveWeek3 = values.scheduleItems
      .filter((data) => data.week === 3)
      ?.find((d) => d.active === true);
    const isDayActiveWeek4 = values.scheduleItems
      .filter((data) => data.week === 4)
      ?.find((d) => d.active === true);

    if (values.repeatPattern.id === 1 && !isDayActiveWeek1) {
      customSnackBar("There should be atleast 1 day active in each week.");
      return;
    } else if (
      values.repeatPattern.id === 2 &&
      (!isDayActiveWeek1 || !isDayActiveWeek2)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
      return;
    } else if (
      values.repeatPattern.id === 3 &&
      (!isDayActiveWeek1 || !isDayActiveWeek2 || !isDayActiveWeek3)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
      return;
    } else if (
      values.repeatPattern.id === 4 &&
      (!isDayActiveWeek1 ||
        !isDayActiveWeek2 ||
        !isDayActiveWeek3 ||
        !isDayActiveWeek4)
    ) {
      customSnackBar("There should be atleast 1 day active in each week.");
      return;
    }

    setLoading(true);

    const data = editFormatdata(values, businessLocations);
    const providerData = JSON.parse(localStorage.getItem("StaffRecord"));

    const updatedData = {
      ...data,
      id: parseInt(scheduleData.data.schedule),
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
      timeZone: "Europe/London",
      // providerType: 1,
      // providerId: 3,
      // providerName: "New User",
      providerType:2,
      // businessData: { businessId: parseInt(businessId) }, // Replace the "businessId" with the new value if needed
      // providerType: providerData.projectId, // Replace the "businessId" with the new value if needed
      providerId: parseInt(providerId), // Replace the "providerId" with the new value if needed
      providerName: providerData.firstName + " " + providerData.lastName,
      providerImageURL: `${process.env.REACT_APP_IMAGE_URL}/Serviceprovider/${providerData.providerId}.jpeg`,
    };
    const jsonData = JSON.stringify(updatedData);
    updateSchedule(jsonData).then((res) => {
      setLoading(false);
      if (res.statusCode === 0) {
        // success(res.message);
        setState(
          states.map((slots) =>
            slots && slots.providerId === values.providerId ? values : slots
          )
        );
        if (action === "edit-schedular") {
          history.push(`/providerSchedulerViewer/${values.providerId}`);
        } else {
          history.push("/staff-list");
          // history.push(`/providerScheduler/${parseInt(businessId)}`);
        }
        handles.resetForm();
      } else {
        customSnackBar(res.message);
        // handles.resetForm();
      }
    });
  };

  //function handle the submission form
  const handleSubmit = (values, handles) => {
   

    // debugger;
    if (action === "edit" || action === "edit-schedular") {
      if ( permissionView ||
        permissionView === null ||
        permissionView === undefined) {
          updateSlot(values, handles);
        }else{
          customSnackBar("You have't permission to Update Schuldule")
        }
    } else if (values.repeatedDays === true) {
      if ( permission ||
        permission === null ||
        permission === undefined) {
      createSlot(values, handles);
        }else{
          customSnackBar("You have't permission to Create Schuldule")
        }
    } else {
      createRepeatDate(values, handles);
    }
  };

  //repeateday functionailty
  const handleUpdateTimeSlots = (
    startDate,
    endDate,
    values,
    setFieldValue,
    errors,
    value
  ) => {
    const items = [];
    const prevItems = [];
    const days = [];
    const weeks = [];
    let maxWeek = 1;

    let startingDate = dayjs(startDate);
    startingDate = startingDate.startOf("week").add(1, "day");
    values.scheduleItems &&
      values.scheduleItems.length > 0 &&
      values.scheduleItems.map((item) => {
        if (item && item.active && item.day && !days.includes(item.day)) {
          prevItems.push(item);
          days.push(item.day);
          maxWeek = maxWeek <= item.week ? item.week : maxWeek;

          weeks.push(item.week);
        }
        return item;
      });
    const endingDate = startDate.add(maxWeek * 7, "day");
    for (
      let currDate = startDate;
      currDate.isBefore(endDate);
      currDate = currDate.add(1, "day")
    ) {
      let currDay = dayjs(currDate).day();
      let loopIndex = items.length; // Assuming 'items' is the array where you store the loop items
      let currWeek = Math.floor(loopIndex / 7) + 1;

      let currMonth = dayjs(currDate).month() + 1;
      if (days.includes(currDay) && weeks.includes(currWeek)) {
        let currItem = prevItems.find((item) => item.day === currDay);
        items.push({
          ...currItem,
          date: currDate,
          week: currWeek,
          month: currMonth,
          active: true,
        });
      } else {
        const item = defaultItem(currDay, currWeek, currDate, currMonth);

        items.push({
          ...item,
          date: currDate,
          week: currWeek,
          day: currDay,
          month: currMonth,
          active: false,
        });
      }
    }
    setFieldValue("scheduleItems", items);
  };

  //repeateday functionailty
  const handleTimeSlots = (startDate, endDate, values, setFieldValue) => {
    const items = [];
    const prevItems = [];
    const days = [];
    const weeks = [];

    let startingDate = dayjs(startDate);
    startingDate = startingDate.startOf("week").add(1, "day");
    let maxWeek = 0;

    values.scheduleItems &&
      values.scheduleItems.length > 0 &&
      values.scheduleItems.map((item) => {
        if (item && item.active && item.day && item.week) {
          prevItems.push(item);
          maxWeek = maxWeek < item.week ? item.week : maxWeek;
          days.push(item.day);
          if (!weeks.includes(item.week)) weeks.push(item.week);
        }
        return item;
      });

    const endingDate = startDate.add(maxWeek * 7, "day");

    let count = 0;

    for (
      let currDate = startDate;
      currDate.isBefore(endingDate);
      currDate = currDate.add(1, "day")
    ) {
      let currDay = dayjs(currDate).day();
      let currWeek = Math.floor(count / 7) + 1;
      // const currItem = prevItems.find((i) => i.day === currDate && i.week === currWeek);
      let currItem = prevItems.find(
        (item) => item.day === currDay && item.week === currWeek
      );

      // let currMonth = dayjs(currDate).month() + 1;
      if (currItem) {
        items.push(currItem);
      } else {
        const item = defaultItem(currDay, currWeek, currDate);

        items.push({
          ...item,
          date: currDate,
          week: currWeek,
          day: currDay,
          // month: currMonth,
          active: false,
        });
      }
      count++;
    }
    setFieldValue("scheduleItems", items);

    return items;
  };
  //repeatedates functionailty
  const handleUpdateDatesTimeSlots = (
    startDate,
    endDate,
    values,
    setFieldValue,
    errors
  ) => {
    const items = [];
    const prevItems =
      values.scheduleItems && values.scheduleItems.length > 0
        ? values.scheduleItems
        : [];

    for (
      let currDate = startDate;
      currDate.isBefore(endDate);
      currDate = currDate.add(1, "day")
    ) {
      let currDayOfMonth = dayjs(currDate).date();
      // let currMonth = dayjs(currDate).month() + 1;
      let loopIndex = items.length; // Assuming 'items' is the array where you store the loop items
      let currMonth = Math.floor(loopIndex / 30) + 1;
      let currItem = prevItems.find(
        (item) => currDayOfMonth === dayjs(item.date).date()
      );
      if (currItem) {
        items.push({
          ...currItem,
          date: currDate,
          month: currMonth,
        });
      } else {
        const item = defaultItemDates(currDate, currMonth);
        items.push({
          ...item,
          date: currDate,
          month: currMonth,
          active: false,
        });
      }
    }
    setFieldValue("scheduleItems", items);
  };

  const openDatePicker = () => {
    setIsOpen((prevState) => ({ ...prevState, startDate: true }));
  };

  const closeDatePicker = (name) => {
    setIsOpen((prevState) => ({ ...prevState, [name]: false }));
  };

  const getBusinessLocations = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    

    await instance
      .get(
        `/business_svc/pb/get_business_list/?userId=${userInfo?.id}`
      )
      .then((res) => {
        setBusinessLocations(res?.data?.result);
      });
  };

  return (
    <>
      {loading && <div className="loader"></div>}

      <div className=" pc_display">
        <div>
          <Header flag={flag} heading={"Schedule 📅 "} />
        </div>
        <div
          className="pt-4 hide_scrollbar pc_display"
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "180px",
            paddingLeft: "220px",
            paddingRight: "220px",
          }}
        >
          <Formik
            innerRef={formikRef}
            // enableReinitialize
            initialValues={initialValuesofSlot(
              action,
              editData,
              location,
              date,
              day,
              week,
              month,
              providerId,
              businessId,
              editRecord,
              handleTimeSlots
            )}
            onSubmit={(values, handles) => {
              handleSubmit(values, handles);
            }}
            // validationSchema={timeSlotSchema}
          >
            {(options) => {
              const {
                touched,
                values,
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                prevScheduleItems,
                // validateForm,
                resetForm,
              } = options;
             
              return (
                <form onSubmit={handleSubmit} className=" ">
                  <div className="mb-5">
                    <Accordion
                      expanded={expandList}
                      disableGutters
                      sx={{
                        py: 0,
                        px: 1,
                        my: 0,
                        bgcolor: "#5599FF",
                        borderRadius: "8px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                        },
                      }}
                    >
                      <AccordionSummary
                        onClick={() => setExpandList(!expandList)}
                        sx={{ p: 0 }}
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{ fontSize: 22, color: "#fff" }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="col">
                          <Typography sx={{ color: "#fff", fontSize: 10 }}>
                            Schedule
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: 14,
                              mt: 0.5,
                            }}
                          >
                            {selectedSchedule.startDate
                              ? moment(selectedSchedule.startDate).format(
                                  "D MMM, YYYY"
                                )
                              : ""}{" "}
                            -{" "}
                            {selectedSchedule.endDate
                              ? moment(selectedSchedule.endDate).format(
                                  "D MMM, YYYY"
                                )
                              : ""}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails sx={{ px: 0 }}>
                        <div className="col">
                          {schedules.map((schedule, index) => (
                            <Typography
                              key={index}
                              onClick={() => {
                                setSelectedSchedule(schedule);
                                getSingleSchedule(schedule?.id, setFieldValue);
                              }}
                              sx={{
                                mb: 2,
                                color: "#fff",
                                fontSize: 14,
                                cursor: "pointer",
                                width: "200px",
                              }}
                            >
                              {moment(schedule.startDate).format("D MMM, YYYY")}{" "}
                              - {moment(schedule.endDate).format("D MMM, YYYY")}
                            </Typography>
                          ))}
                        </div>

                        <div className="w-100 d-flex justify-content-center">
                          <Button
                            type="reset"
                            onClick={() => {
                              setFieldValue("startDate", "");
                              setFieldValue("endDate", "");
                              // openDatePicker();
                              setScheduleData({});
                              setSelectedSchedule({});
                              resetForm();
                              setExpandList(false);
                            }}
                            sx={{
                              width: "150px",
                              height: "40px",
                              borderRadius: "8px",
                              textTransform: "capitalize",
                              color: "#fff",
                              border: "1px solid white !important",
                            }}
                            variant="outlined"
                            startIcon={
                              <AddBoxIcon
                                sx={{
                                  bgColor: "white",
                                }}
                              />
                            }
                          >
                            Add New
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div
                    className="row g-0 px-2 bg-white"
                    style={{
                      border: "1px solid #DCDCDC",
                      borderRadius: "8px",
                      height: "50px",
                      marginBottom: "30px",
                    }}
                  >
                    <div className="col-md-6 mt-2 mx-auto">
                      <span className="schedule_text">
                        {(action && action === "edit") ||
                        action === "edit-schedular"
                          ? "Update Schedule "
                          : "Create Schedule "}
                      </span>
                    </div>

                    <div className="col-md-6 mx-auto">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="position-relative"
                            style={{ top: "18px" }}
                          >
                            <div
                              className="time position-absolute"
                              style={{ top: "-20px" }}
                            >
                              From
                            </div>
                            <InputDate
                              id="start-date"
                              open={isOpen.startDate}
                              onClick={() => {
                                setIsOpen((prevState) => ({
                                  ...prevState,
                                  startDate: true,
                                }));
                              }}
                              onClose={() => {
                                closeDatePicker("startDate");
                                setIsOpen((prevState) => ({
                                  ...prevState,
                                  endDate: true,
                                }));
                              }}
                              iconRemove={{ openPickerButton: () => null }}
                              // label="Start date"
                              format="D MMM YYYY"
                              name="startDate"
                              minDate={dayjs(date)}
                              disableOpenPicker
                              // maxDate={action==='edit'dayjs(values.endDate)}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: 0,
                                  fontSize: "14px",
                                  cursor: "pointer",
                                },
                                // "& .MuiSvgIcon-root": {
                                //   display: "none",
                                // },
                                // backgroundColor: "#fff",
                                // ".css-i4bv87-MuiSvgIcon-root":{
                                //   display:"none"
                                // },
                              }}
                              // defaultValue={new Date()}
                              value={dayjs(values.startDate)}
                              helperText={touched.startDate && errors.startDate}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "startDate",
                                  FormateDate(newValue)
                                );
                              }}
                            />
                            {<p className="form-error">{errors.startDate}</p>}
                          </div>
                        </div>
                        {/* <div className="time">From</div> */}

                        <div className="col-md-6">
                          <div
                            className="position-relative"
                            style={{ top: "18px" }}
                          >
                            <div
                              className="time position-absolute"
                              style={{ top: "-20px" }}
                            >
                              To
                            </div>
                            <InputDate
                              id="end-date"
                              open={isOpen.endDate}
                              onClick={() => {
                                setIsOpen((prevState) => ({
                                  ...prevState,
                                  endDate: true,
                                }));
                              }}
                              onClose={() => {
                                closeDatePicker("endDate");
                              }}
                              iconRemove={{ openPickerButton: () => null }}
                              // label="No Expiry "
                              name="endDate"
                              format="D MMM YYYY"
                              minDate={dayjs(values.startDate)}
                              // maxDate={
                              //   !values.repeatedDates
                              //     ? dayjs(values.startDate).add(30, "day")
                              //     : dayjs(values.startDate).add(365, "day")
                              // }
                              value={dayjs(values.endDate)}
                              helperText={touched.endDate && errors.endDate}
                              sx={{
                                width: "100%",
                                border: "none",
                                // backgroundColor: "#fff",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: 0,
                                  fontSize: "14px",
                                  cursor: "pointer",
                                },
                                // "& .MuiSvgIcon-root": {
                                //   display: "none",
                                // },
                              }}
                              // disableOpenPicker
                              onChange={(newValue) => {
                                setFieldValue("endDate", FormateDate(newValue));
                                // setSchedules((prevState) => [
                                //   ...prevState,
                                //   {
                                //     startDate: FormateDate(values.startDate),
                                //     endDate: FormateDate(newValue),
                                //   },
                                // ]);
                                // setSelectedSchedule({
                                //   startDate: FormateDate(values.startDate),
                                //   endDate: FormateDate(newValue),
                                // });
                              }}
                            />
                            {<p className="form-error">{errors.startDate}</p>}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {!values.repeatedDays && !values.repeatedDates ? (
                    <Day
                      validateForm={validateForm}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      date={date}
                      editData={editData}
                      errors={errors}
                      onSubmit={handleSubmit}
                      handleAddSlot={handleAddSlot}
                      handleDeleteSlot={handleDeleteSlot}
                    /> */}
                  {/* // ) : values.repeatedDays && !values.repeatedDates ? ( */}
                    <RepeatedDay
                      action={action}
                      editData={editData}
                      activeWeekOptions={activeWeekOptions}
                      setActiveWeekOptions={setActiveWeekOptions}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      editRecord={editRecord}
                      touched={touched}
                      errors={errors}
                      handleUpdateTimeSlots={handleUpdateTimeSlots}
                      onSubmit={handleSubmit}
                      handleTimeSlots={handleTimeSlots}
                      businessLocations={businessLocations}
                    />
                  {/* // ) : !values.repeatedDays && values.repeatedDates ? (
                  //   <RepeatDate
                  //     action={action}
                  //     prevScheduleItems={prevScheduleItems}
                  //     activeMonthOptions={activeMonthOptions}
                  //     setActiveMonthOptions={setActiveMonthOptions}
                  //     handleChange={handleChange}
                  //     setFieldValue={setFieldValue}
                  //     handleUpdateDatesTimeSlots={handleUpdateDatesTimeSlots}
                  //     values={values}
                  //     touched={touched}
                  //     errors={errors}
                  //     onSubmit={handleSubmit}
                  //   />
                  // ) : null} */}

                  <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                    <div className="p-2">
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          history.goBack();
                        }}
                        variant="contained"
                      >
                        Back
                      </Button>
                    </div>
                    <div className="p-2">
                      <Button
                        type="submit"
                        sx={{
                          width: "150px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {loading ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : (action && action === "edit") ||
                          action === "edit-schedular" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default TimeSlot;
