/** @format */

import React, { useEffect, useRef } from "react";
// import moment from "moment";
import { Calendar } from "react-big-calendar";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ProviderWidget from "./providerWidget.jsx";
import CalenderHeader from "./CalenderHeader.jsx";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { nextdate, prevdate, resetdate } from "../store/dateSlice.js";
// import CircularProgress from "@mui/material/CircularProgress";
import CustomEventWidget from "./customEventWidget.jsx";

// const DnDCalendar = withDragAndDrop(Calendar);

export default function CalendarApp(props) {
  const calendarRef = useRef();
  const dispatch = useDispatch();
  const numberOfProviders = useSelector(
    (state) => state.calendarSettingReducer.numberOfProviders
  );
  const {
    localizer,
    allevent,
    allbgevent,
    resources,
    views,
    startTime,
    endTime,
    calenderEvents,
    handleSlots,
    handleFilter,
  } = props;
  let date = useSelector((state) => state.counterReducer.dateApi);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarContainer = document.querySelector(".rbc-calendar");
      const childrens = calendarContainer.children[1].children[1].children;
      for (let i = 1; i < childrens.length; i++) {
        childrens[i].style.minWidth =
          numberOfProviders === 1
            ? "91vw"
            : numberOfProviders === 2
            ? "46vw"
            : numberOfProviders === 3
            ? "30.5vw"
            : numberOfProviders === 4
            ? "23vw"
            : numberOfProviders === 5
            ? "18.2vw"
            : numberOfProviders === 6
            ? "15.2vw"
            : numberOfProviders === 7
            ? "13vw"
            : numberOfProviders === 8
            ? "11.4vw"
            : numberOfProviders === 9
            ? "10.15vw"
            : "9.1vw";
      }
    }
  }, [numberOfProviders]);
  return (
    <Calendar
      className="flex flex-1 w-100"
      localizer={localizer}
      ref={calendarRef}
      events={allevent}
      backgroundEvents={allbgevent}
      resources={resources}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      // resizable={false}
      startAccessor={(event) => new Date(event.start)}
      endAccessor={(event) => new Date(event.end)}
      defaultView="day"
      culture="en"
      defaultDate={new Date()}
      views={views}
      step={15}
      min={new Date(0, 0, 0, startTime?.hour, startTime?.min, 0)}
      max={new Date(0, 0, 0, endTime?.hour, endTime?.min, 0)}
      // selectable="ignoreEvents"
      selectable
      showMultiDayTimes
      // onSelectEvent={(event) => calenderEvents(event)}
      onSelectEvent={(event) => calenderEvents(event)}
      onSelectSlot={(slotInfo) => handleSlots(slotInfo)}
      dayLayoutAlgorithm="overlap"
      components={{
        resourceHeader: (props) => {
          return  props?.resource?.length !== 0 && (
            <ProviderWidget val={props.resource} />
          );
        },
        toolbar: (toolbar) => {
          return (
            <CalenderHeader
              handleFilter={handleFilter}
              toolbar={toolbar}
              action={props.action ? props.action : ""}
              eventData={props.eventData ? props.eventData : ""}
            />
          );
        },
        event: (event) => {
          return props?.resource?.length !== 0 && <CustomEventWidget event={event} />;
        },
      }}
      style={{
        backgroundColor: "white",
      }}
      eventPropGetter={(myEventsList) => {
        const backgroundColor = myEventsList.colorEvento
          ? myEventsList.colorEvento
          : "blue";
        const color = myEventsList.color ? myEventsList.color : "blue";
        const border = "none";
        const cursor = "pointer";
        const display = "flex";
        const width = "100%";

        return {
          style: { backgroundColor, color, border, cursor, display, width },
        };
      }}
      slotPropGetter={(event) => {
        if (event)
          return {
            className: "special-day",
          };
        else return {};
      }}
      date={date}
      onNavigate={(date, view, action) => {
        if (view === "day" && action === "NEXT") {
          dispatch(nextdate());
        } else if (view === "day" && action === "PREV") {
          dispatch(prevdate());
        } else if (view === "day" && action === "TODAY") {
          dispatch(resetdate());
        }
      }}
    />
  );
}
