/** @format */

import React, { useEffect, useMemo, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { SvgIcon } from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from "react-redux";
import { closeupdatemodel } from "../store/modelSlice";



function StickyNotesDialog(props) {
    const dispatch= useDispatch();
	const {open, handleClose, event} = props;
    const handleBothModel=()=>{
        dispatch(closeupdatemodel());
        handleClose()
    }
	return (
		<Dialog
			open={open}
          onClick={(e)=>e.stopPropagation()}
			style={{ width: "100%", height:"100%"}}>
			{/* If Slot Selected */}
				<div className='w-full h-full  px-4 pb-8 bg-yellow-400 overflow-hidden shadow-2xl shadow-slate-800'>
					{/* Header Component */}
					<DialogTitle className='w-full h-screen  overflow-hidden'>
                        <SvgIcon className="absolute right-5 top-3 text-3xl " onClick={handleBothModel} component={CancelOutlinedIcon} />
                    <div className="flex justify-center items-center py-6 pt-10">
                    <div className='h-7 w-7 bg-slate-400 rounded-full'></div>
                    </div>
						<p className="text-xl font-bold text-center">Sticky Notes</p>
                    <div className="w-full flex py-4">
                        <p style={{fontFamily:"Ruda, sans-serif"}} className="text-2xl">{event.stickyNotesDescription}</p>
                    </div>
					</DialogTitle>
				</div>
		</Dialog>
	);
}

export default React.memo(StickyNotesDialog);
