import React, { useEffect, useState } from "react";
import Times from "./Times";
import convertToDateTime from "../helper";
import dayjs from "dayjs";

const Info = (props) => {
  const {
    values,
    handleChange,
    options,
    index,
    active,
    setFieldValue,
    isMobile,
    scheduleData,
    actionState
  } = props;
  const [slotStates, setSlotStates] = useState({});
  const [activeState, setActiveStates] = useState({});
  
  const handleClickActive = (index) => {
    // Create a copy of the slot states
    const newSlotStates = { ...slotStates };
    // Toggle the active state
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setSlotStates(newSlotStates);
    setFieldValue(`${index}.hourFormat`, newSlotStates[index]);
  };
  function convertToDateTime(inputTime) {
    // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
    if (dayjs(inputTime, { strict: true }).isValid()) {
      return dayjs(inputTime);
    }
  
    // Parse input time as "HH:mm:ss"
    const currentTime = dayjs();
    const [hours, minutes, seconds] = inputTime.split(":");
  
    // Set the time components
    const formattedDateTime = currentTime
      .set("hour", parseInt(hours, 10))
      .set("minute", parseInt(minutes, 10))
      .set("second", parseInt(seconds, 10));
  
    return formattedDateTime;
  }
  const handleChanges = (index) => {
    const newSlotStates = { ...activeState };
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setActiveStates(newSlotStates);
    setFieldValue(`${index}.isAvailable`, newSlotStates[index]);
  };
  useEffect(() => {
    if (scheduleData && scheduleData.length>0 && actionState === 'edit') {
      // Map the scheduleData to match the initialValues structure
      const mappedData = scheduleData.map((item) => ({
        id: item.id,
        businessId: item.businessId,
        day: item.day,
        businessStartTime: convertToDateTime(`${item.businessStartTime}:00`),
        businessEndTime: convertToDateTime(`${item.businessEndTime}:00`),
        isAvailable: item.isAvailable,
        hourFormat:item.businessStartTime==="00:00"&&item.businessEndTime==="23:59"?true:false,
      }));

      // Set the mappedData as the initial values using setFieldValue
      setFieldValue('day', mappedData);
      localStorage.setItem('businessScularUpdatedRecord',mappedData)
    }
  }, [scheduleData, actionState, setFieldValue]);
  return (
    <div
      className={`${!isMobile ? "col-md-12 backgroud_main_section" : ""}`}
      key={{}}
    >
      <div className="row">
        <div className={`${!isMobile ? "col-md-6" : "col-5"}`}>
          {/* <span className="schedule_text">Week {weekIndex + 1}</span> */}
        </div>
        <div className={`${!isMobile ? "col-md-6 m-auto" : "col-7 m-auto"}`}>
          <div className="row">
            <div className={`${!isMobile ? "col-md-4" : "col-5"}`}>
              <div className="labe_from">From</div>
            </div>
            <div className={`${!isMobile ? "col-md-4" : "col-5 ps-0"}`}>
              <div className="labe_from">To</div>
            </div>
            <div className={`${!isMobile ? "col-md-4" : "col-2"}`}></div>
          </div>
        </div>
      </div>
      {!isMobile ? (
        <div className="col-md-12">
          <hr className="mt-2 mb-0 p-0" />
        </div>
      ) : null}
      {/* {actionState === "edit"
        ? values?.day.map((dayData, index) => {
            return (
              <div key={index}>
                <div className="p-2">
                  <div className={`${!isMobile ? "col-md-12" : "col-12"}`}>
                    <div className="row">
                      <div
                        className={`${
                          !isMobile ? "col-md-4 mt-3" : "col-4 pe-0 ps-0"
                        }`}
                      >
                        <div
                          className={`${
                            !isMobile ? "col-md-12 px-2" : "col-12"
                          }`}
                        >
                          <label className="new_fontt pb-3">
                            <div
                              className={`${
                                !isMobile ? "col-md-12 px-2" : "d-flex"
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="special-checkbox"
                                value={dayData.isAvailable}
                                checked={dayData.isAvailable}
                                onChange={() => {
                                  handleChanges(index);
                                }}
                                style={{
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              />
                              {isMobile ? (
                                <div
                                  style={{ fontSize: "14px" }}
                                  className="card-mobile"
                                >
                                  {dayData.day}
                                </div>
                              ) : (
                                `${dayData.day}`
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${
                          !isMobile ? "col-md-2 mt-3" : "col-1"
                        }`}
                      >
                        <div className="d-flex">
                          <div
                            onClick={() => {
                              handleClickActive(index);
                            }}
                          >
                            {slotStates[index] ? (
                              <div>
                                <img
                                  className={`${
                                    !isMobile ? "me-3" : "me-3 pt-2"
                                  }`}
                                  src="/images/Large_Header (1).svg"
                                  alt="no-image"
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  className={`${
                                    !isMobile ? "me-3" : "me-3 pt-2"
                                  }`}
                                  src="/images/Large_Header (2).svg"
                                  alt="no-image"
                                />
                              </div>
                            )}
                          </div>
                          {!isMobile ? (
                            <div
                              onClick={() => {
                                handleClickActive(index);
                              }}
                            >
                              {!slotStates[index] ? (
                                <div>
                                  <img
                                    className="me-3"
                                    src="/images/Layer_1 (12).svg"
                                    alt="no-image"
                                  />
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="me-3"
                                    src="/images/Layer_1 (2) 2.svg"
                                    alt="no-image"
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={`${
                          !isMobile ? "col-md-6 mt-2 d-flex" : "col-6 mt-1"
                        }`}
                      >
                        <Times
                          options={dayData}
                          index={index}
                          isMobile={isMobile}
                          records={options}
                          active={slotStates[index]}
                          setFieldValue={setFieldValue}
                          {...options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : */}
        { values.map((dayData, index) => {
            return (
              <div key={index}>
                <div className="p-2">
                  <div className={`${!isMobile ? "col-md-12" : "col-12"}`}>
                    <div className="row">
                      <div
                        className={`${
                          !isMobile ? "col-md-4 mt-3" : "col-4 pe-0 ps-0"
                        }`}
                      >
                        <div
                          className={`${
                            !isMobile ? "col-md-12 px-2" : "col-12"
                          }`}
                        >
                          <label className="new_fontt pb-3">
                            <div
                              className={`${
                                !isMobile ? "col-md-12 px-2" : "d-flex"
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="special-checkbox"
                                value={dayData.isAvailable}
                                checked={dayData.isAvailable}
                                onChange={() => {
                                  handleChanges(index);
                                }}
                                style={{
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              />
                              {isMobile ? (
                                <div
                                  style={{ fontSize: "14px" }}
                                  className="card-mobile"
                                >
                                  {dayData.day}
                                </div>
                              ) : (
                                `${dayData.day}`
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${
                          !isMobile ? "col-md-2 mt-3" : "col-1"
                        }`}
                      >
                        <div className="d-flex">
                          <div
                            onClick={() => {
                              handleClickActive(index);
                            }}
                          >
                            {slotStates[index] ? (
                              <div>
                                <img
                                  className={`${
                                    !isMobile ? "me-3" : "me-3 pt-2"
                                  }`}
                                  src="/images/Large_Header (1).svg"
                                  alt="no-image"
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  className={`${
                                    !isMobile ? "me-3" : "me-3 pt-2"
                                  }`}
                                  src="/images/Large_Header (2).svg"
                                  alt="no-image"
                                />
                              </div>
                            )}
                          </div>
                          {!isMobile ? (
                            <div
                              onClick={() => {
                                handleClickActive(index);
                              }}
                            >
                              {!slotStates[index] ? (
                                <div>
                                  <img
                                    className="me-3"
                                    src="/images/Layer_1 (12).svg"
                                    alt="no-image"
                                  />
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="me-3"
                                    src="/images/Layer_1 (2) 2.svg"
                                    alt="no-image"
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={`${
                          !isMobile ? "col-md-6 mt-2 d-flex" : "col-6 mt-1"
                        }`}
                      >
                        <Times
                          options={dayData}
                          index={index}
                          isMobile={isMobile}
                          records={options}
                          active={slotStates[index]}
                          setFieldValue={setFieldValue}
                          {...options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
  
};

export default Info;
