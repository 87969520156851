import { Col, Row, Table, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FieldArray, Formik } from "formik";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const OffCanvas = ({
  name,
  show,
  handleClose,
  handleShow,
  costList,
  costTypeId,
  unitTypeId,
  costType,
  handleCostTypeChange,
  ...props
}) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        {/* <Offcanvas.Header closeButton> */}
        <Offcanvas.Header className="mt-4">
          <Offcanvas.Title className="offcanvas-title">Costing</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="price-table-wrap att-table mx-0 mt-2 service-cost-wrap">
            <Col className="px-0" xs={12}>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className="table-cell"></th>
                    <th className="px-0 description-cell">Cost Type</th>
                    <th className="sku-cell ps-1">Title</th>
                    <th className="duration-cell ps-2">Unit Type</th>
                    <th className="price-cell">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {costList?.map((el) => (
                    <tr className={`${show && "tr"}`} key={el.id}>
                      <td className="table-cell p-0 py-3">
                        <div className="border-right">
                          <MoreVertIcon className="pe-2 cursor-pointer" />
                        </div>
                      </td>
                      <td className="text-truncate px-2">
                        <Select
                          className="w-100 pricing-table-select border-right"
                          labelId="cost-type"
                          id={costTypeId}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                fontSize: "18px",
                                color: "#aaaaaa",
                                marginLeft: "-45px",
                              }}
                            />
                          )}
                          value={costType || el.costTypeTitle}
                          onChange={handleCostTypeChange}
                        >
                          {costList.map((costTypeEl) => (
                            <MenuItem
                              value={costTypeEl.costTypeTitle}
                              key={costTypeEl.id}
                            >
                              {costTypeEl.costTypeTitle}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td className="sku-cell px-1 text-wrap">
                        <div className="border-right">{el.title}</div>
                      </td>
                      <td className="duration-cell px-0 text-truncate">
                        <Select
                          className="w-100 pricing-table-select border-right"
                          labelId="unit-type"
                          id={unitTypeId}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                fontSize: "18px",
                                color: "#aaaaaa",
                                marginLeft: "-45px",
                              }}
                            />
                          )}
                          value={10}
                        >
                          {costList.map((unitType) => (
                            <MenuItem value={10} key={unitType.id}>
                              {unitType.costType}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td className="price-cell px-1">
                        <div className="d-flex justify-content-between pe-1">
                          <span className="ps-2">
                            {el.currencySymbol}
                            {el.cost}
                          </span>
                          <RemoveCircleOutlineIcon className="remove cursor-pointer" />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Formik
                initialValues={{ attributes: [] }}
                render={({ values }) => (
                  <Form>
                    <FieldArray
                      name="attributes"
                      render={(arrayHelpers) => (
                        <div>
                          {values.attributes.map((el, index) => (
                            <Table className="mb-0 cost-offcanvas">
                              <tbody>
                                <tr>
                                  <td className="table-cell p-0 py-3">
                                    <div className="border-right">
                                      <MoreVertIcon className="pe-2 cursor-pointer" />
                                    </div>
                                  </td>
                                  <td className="text-truncate px-2">
                                    <Select
                                      className="w-100 pricing-table-select border-right"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      IconComponent={() => (
                                        <KeyboardArrowDownIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#aaaaaa",
                                            marginLeft: "-45px",
                                          }}
                                        />
                                      )}
                                      value={10}
                                    >
                                      <MenuItem value={10}>
                                        Product Cost
                                      </MenuItem>
                                      <MenuItem value={20}>Human Cost</MenuItem>
                                    </Select>
                                  </td>
                                  <td className="sku-cell px-1 text-truncate">
                                    <div className="border-right">
                                      Water Bottle
                                    </div>
                                  </td>
                                  <td className="duration-cell px-0 text-truncate">
                                    <Select
                                      className="w-100 pricing-table-select border-right"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      IconComponent={() => (
                                        <KeyboardArrowDownIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#aaaaaa",
                                            marginLeft: "-45px",
                                          }}
                                        />
                                      )}
                                      value={10}
                                    >
                                      <MenuItem value={10}>Per liter</MenuItem>
                                      <MenuItem value={20}>Per Hour</MenuItem>
                                    </Select>
                                  </td>

                                  <td className="price-cell px-1">
                                    <div className="d-flex justify-content-between pe-1">
                                      <span className="ps-2">£67</span>
                                      <RemoveCircleOutlineIcon className="remove cursor-pointer" />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ))}

                          <div
                            className="table-add-new d-flex align-items-center"
                            onClick={() => arrayHelpers.push("")}
                          >
                            <AddCircleOutlineIcon className="add-circle-table" />
                            <span>Add New</span>
                          </div>
                        </div>
                      )}
                    />
                  </Form>
                )}
              />
              {/* <div
                className="table-add-new d-flex align-items-center"
                onClick={() => arrayHelpers.push("")}
              >
                <AddCircleOutlineIcon className="add-circle-table" />
                <span>Add New</span>
              </div> */}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default OffCanvas;
