import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Import the dayjs library

const MobileInputDate = ({
  label,
  format,
  value = "",
  sx,
}) => {
  const formattedValue = value ? dayjs(value).format("d MMM YYYY") : "No Expiry Date";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="custom-input">
        {formattedValue}
      </div>
    </LocalizationProvider>
  );
};

export default MobileInputDate;