import { useState } from "react";
import SideNavone from "../../../../SideNavBar";
import CreateFormMobile from "../newService/components/CreateFormMobile";
import ServiceAttributes from "./ServiceAttributes";

const ServiceAttributesContainer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <SideNavone>
        <ServiceAttributes
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
        <CreateFormMobile />
      </SideNavone>
    </>
  );
};
export default ServiceAttributesContainer;
