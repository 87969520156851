import axios from "../../Routes/AxiosConfig";
import {
  fork,
  put,
  all,
  select,
  takeLatest,
  call,
  take,
} from "redux-saga/effects";
import axiosConfig from "../../Routes/AxiosConfigg";

// Login Redux States
import {
  LOGIN,
  REGISTER_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  REGISTERAPI,
  SEND_OTP_EMAIL,
  SET_USER_PASSWORD,
  ADD_USER_PROFILE,
  ADD_USER_MOBILE,
  MOBILE_OTP_VERIFY,
  GET_USER_DATA_AFTER_LOGIN,
  ADD_SECONDARY_EMAIL,
  VERIFY_SECONDARY_EMAIL,
  SEND_OTP_SECONDRY_EMAIL,
  ADD_SECONDARY_PHONE_NUMBER,
  VERIFY_SECONDARY_MOBILE,
  RESEND_OTP_MOBILE,
  GET_ALL_GENDERS,
  SEND_GENDER,
  ADD_DOB,
  SIGNIN_USER,
  RESEND_MOBILE_PRIMARY_OTP,
  RESEND_OTP_NEW_PRIMARY_EMAIL,
  RESEND_OTP_SECONDARY_EMAIL,
  NEW_OTP_RESEND_PRIMARY_MOBILE,
  UPDATE_USER_NAME,
  UPLOAD_PROFILE_IMG,
  PERMISSION_API,
  FORGET_PASSWORD,
  CHANGE_USER_PASSWORD,
  CHANGE_USERS_PASSWORD,
} from "./actionTypes";
import {
  addsecondaryemailsuccess,
  addsecondaryphonenumbersuccess,
  addusermobilesuccess,
  forgetapisuccess,
  getallgenderssuccess,
  getuserdataafterlogin,
  getuserdataafterloginsuccess,
  loginSuccess,
  permissionModuleSucess,
  registerUserSuccessful,
  registerapisuccess,
  sendgendersuccess,
  sendotopemailsuccess,
  setuserpasswordsuccess,
  uploadprofileimagesuccess,
  userpayloadsuccess,
  verfysecondarymobilesuccess,
  verifymobileotpsuccess,
  verifysecondaryemailsuccess,
} from "./actions";
import { push } from "connected-react-router";
import { sagaErrorHandler } from "../../Shared/HelperMethods/SagaErrorHandler";
import { makeSelectAuthToken } from "../selector";
import { DEVICE_ID } from "../../Constants/ImgConstants";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedBusiness, fetchBusinessRequest } from "../Business/actions";
import { resetdate } from "../../Calendar/scheduler/store/dateSlice";
import {
  getCookiesData,
  setCookiesData,
  setCookiesDataWithTime,
  setStorageData,
} from "../../utils/helper";
import { customSnackBar } from "../../utils";
import { FETCH_BUSINESS_SUCCESS } from "../Business/actionType";
import instance from "../../utils/utils";

//If user is login then dispatch redux action's are directly from here.
// function* loginUser({ payload }) {
//   try {
//     payload.setIsLoading(true);
//     let data = {
//       email: payload.email,
//       password: payload.password,
//     };
//     const response = yield axios.post("admin/login", data);
//     if (response.data.data.role === "Admin") {
//       payload.history.push("/Dashboard-Admin");
//     } else {
//       payload.history.push("/dashboard");
//     }
//     yield put(loginSuccess(response.data.data));
//     payload.setIsLoading(false);
//   } catch (error) {
//     yield sagaErrorHandler(error.response.data);
//     payload.setIsLoading(false);
//   }
// }
// const loading = useSelector((state) => state.Business?.loading);
function* registerUser({ payload }) {
  try {
    let data = {
      name: payload.name,
      email: payload.email,
      password: payload.password,
    };
    const response = yield axios.post("auth/register", data);
    yield put(registerUserSuccessful(response.data));
    yield put(push("/on-boarding"));
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
  }
}

function* forgotPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      email: payload.email,
    };
    yield axios.post("admin/forgetPassword", data);
    payload.history.push("/login");
    // toast.success("Please check you email");

    payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* resetPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      token: payload.token,
      newPassword: payload.password,
    };
    yield axios.put("admin/resetPassword", data);
    // toast.success("Password reset successfully");
    payload.history.push("/login");
    payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* changePasswordRequest({ payload }) {
  const token = yield select(makeSelectAuthToken());
  try {
    let data = {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
    };
    const response = yield axios.put("admin/changePassword", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    payload.history.push("/Dashboard-Admin");
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
  }
}

///////////////////////////////////////functions for plexar

function* registeruser({ payload }) {
  payload.setIsLoading(true);
  try {
    // let data = {
    //   name: payload.name,
    //   email: payload.email,
    //   password: payload.password,
    //      deviceId: payload.deviceId,
    // countryId: payload.countryId,
    // };
    let data = {
      email: payload.email,
      deviceId: payload.deviceId,
      countryId: payload.countryId,

      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };
    const response = yield axios.post("signup_svc/pb/users/register", data);
    // yield put(push(`/Email-verification/${payload.email}`));
    if (response.data.code === 0) {
      yield put(push(`/Email-verification/${payload.email}`));
    } else if (response.data.code === 6) {
      yield put(push(`/Email-verification/${payload.email}`));
      // } else if (response.data.code === 4) {
      //   yield put(push(`/Profile-information`));
    } else if (response.data.code === 3 || response.data.code === 4) {
      yield put(push(`/password/${payload.email}`));
    } else {
      customSnackBar(response.data.message);
    }
    yield put(registerapisuccess(response.data));
  } catch (error) {
    error(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* forgetPassword({ payload }) {
  payload.setIsLoading(true);
  try {
    let data = {
      email: payload.email,
      // deviceId: payload.deviceId,
      // countryId: payload.countryId,
    };
    const response = yield axios.post(
      "/signup_svc/pb/users/forgetPassword",
      data
    );
    // yield put(push(`/Email-verification/${payload.email}`));
    if (response.data.code === 0) {
      yield put(push(`/Email-verification/${payload.email}`));
    } else if (response.data.code === 6) {
      yield put(push(`/Email-verification/${payload.email}`));
      // } else if (response.data.code === 4) {
      //   yield put(push(`/Profile-information`));
    } else if (response.data.code === 3 || response.data.code === 4) {
      yield put(push(`/password/${payload.email}`));
    } else {
      customSnackBar(response.data.message);
    }
    yield put(forgetapisuccess(response.data));
  } catch (error) {
    error(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* sendotpemail({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      email: payload.email,
      userId: payload.userId,
      deviceId: DEVICE_ID,
    };
    yield put(sendotopemailsuccess(payload));
    const response = yield axiosConfig.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.statusCode === 0) {
      if (payload.accountNumber) {
        yield put(push(`/reset-user-password`));
      } else {
        yield put(push(`/create-password`));
      }
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setOtp("");
    payload.setIsLoading(false);
  }
}
function* setuserrpasswordfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      password: payload.password,
      otp: payload.otp,
      deviceId: DEVICE_ID,
    };

    const response = yield axios.post(
      "signup_svc/pb/users/addUserPassword",
      data
    );
    yield put(setuserpasswordsuccess(response.data.result));
    setCookiesData(
      "auth",
      JSON.stringify({
        token: response?.data?.result?.jwtToken,
        userId: response?.data?.result?.users?.id,
      })
    );
    localStorage.setItem("token", response?.data?.result?.jwtToken);
    localStorage.setItem("user", JSON.stringify(response?.data));
    yield put(push(`/Profile-information`));
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}
function* adduserprofilefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    const authentication = getCookiesData("authtication");

    let data = {
      id: payload.id,
      firstName: payload.firstName,
      lastname: payload.lastname,
      imageUrl: payload.imageUrl,
      modifiedBy: payload.modifiedBy,
    };

    // yield put(sendotopemailsuccess(payload));
    // const response = await instance.post(
    //   "signup_svc/pv/users/addUserProfile",
    //   data,
    //   authentication?.token
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUserProfile",
      data
    );
    yield put(push(`/add-number`));
    // yield put(push(`/Profile`));

    // toast.success(response.data.message);
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* addusermobilefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      mobileNumber: payload.mobileNumber,
      type: payload.type,
      modifiedBy: payload.modifiedBy,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };

    const response = yield axios.post(
      "signup_svc/pv/users/addUserMobiles",
      data
    );
    yield put(addusermobilesuccess(payload));
    // alert("verified");
    if (response.data.code === 0) {
      yield put(push(`/verify-number/${payload.mobileNumber}`));
      // toast.success(response.data.message);
    } else if (response.data.code === 1) {
      customSnackBar(response.data.message);
    } else {
      customSnackBar(response.data.message);
    }
    payload.setIsLoading(false);
  } catch (error) {
    payload.setIsLoading(false);
  }
}
function* mobileotpvrtifyy({ payload }) {
  try {
    let data = {
      otpCode: payload.otpCode,
      phone: payload.phone,
      userId: payload.userId,
    };
    let mobileprimaryverify = true;
    payload.setIsLoading(true);
    const response = yield axiosConfig.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.statusCode === 0) {
      yield put(push(`/dashboard`));
      yield put(verifymobileotpsuccess(mobileprimaryverify));
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setOtp("");
    payload.setIsLoading(false);
  }
}
function* getuserdetaillogin({ payload }) {
  // const token = yield select(makeSelectAuthToken());

  try {
    const useridlogin = payload.id;
    if (payload.setLoader) {
      payload.setLoader(true);
    }
    // const response = yield axios.get(
    // `signup_svc/pv/users/getUserById?id=${useridlogin}`
    // );
    const authentication = getCookiesData("authtication");

    const response = yield call(
      instance.get,
      `signup_svc/pv/users/getUserById?id=${useridlogin}`
    );
    yield put(getuserdataafterloginsuccess(response.data.result.user));
  } catch (error) {
    yield sagaErrorHandler(error);
  } finally {
    if (payload.setLoader) {
      payload.setLoader(false);
    }
  }
}
function* addsecondaryemailfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      email: payload.email,
      modifiedBy: payload.modifiedBy,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,

      // isMobile: false,
      // isCrossPlatForm: false,
      // raptchaToken: "string",
      // isAndroidRequest: false,
      // mobileCaptchaData: {
      //   projectId: "string",
      //   recaptchaAction: "string",
      //   recaptchaSiteKey: "string",
      // },
    };

    // yield put(sendotopemailsuccess(payload));
    // const response = yield axios.post(
    //   "signup_svc/pv/users/addSecondaryEmail",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addSecondaryEmail",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/add-secondary-email-otp-verify`));
      yield put(addsecondaryemailsuccess(payload.email));
    } else {
      customSnackBar(response.data.message);
      yield put(push(`/add-secondary-email`));
    }
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* verifysecondaryemailfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      email: payload.email,
      userId: payload.userId,
      deviceId: DEVICE_ID,
    };
    let emailsecondryverify = true;
    yield put(sendotopemailsuccess(payload));
    const response = yield axiosConfig.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      yield put(verifysecondaryemailsuccess(emailsecondryverify));
      yield put(push(`/Profile`));
    } else {
      yield put(push(`/add-secondary-email-otp-verify`));
      customSnackBar(response.data.message);
    }
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* sendotpsecondaryfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
    };
    payload.setIsLoading(true);

    // yield put(sendotopemailsuccess(payload));
    // const response = yield axios.post(
    //   "signup_svc/pv/users/resendEmailOtp",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pb/users/resendEmailOtp",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/add-secondary-email-otp-verify`));
      // toast.success(response.data.message);
      payload.setIsLoading(false);
    } else {
      yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoading(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* addsecondaryphonefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      mobileNumber: payload.mobileNumber,
      type: payload.type,
      modifiedBy: payload.modifiedBy,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };
    yield put(sendotopemailsuccess(payload));

    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUserMobiles",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/verify-secondary-mobile-number`));
      yield put(addsecondaryphonenumbersuccess(payload.mobileNumber));
      payload.setIsLoading(false);
    } else {
      customSnackBar(response.data.message);
      yield put(push(`/add-secondary-mobile-number`));
      payload.setIsLoading(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* verifysecondarymobilefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      phone: payload.phone,
      userId: payload.userId,
      deviceId: DEVICE_ID,
    };
    let mobilesecondryverify = true;
    // yield put(sendotopemailsuccess(payload));
    const response = yield axiosConfig.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/Profile`));
      yield put(verfysecondarymobilesuccess(mobilesecondryverify));
    } else {
      yield put(push(`/verify-secondary-mobile-number`));
      customSnackBar(response.data.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* resetUserPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      password: payload.password,
      otp: payload.otp,
    };

    const response = yield axios.post(
      "/signup_svc/pb/users/resetUserPassword",
      data
    );
    // yield put(setuserpasswordsuccess(response.data.result));
    setCookiesData(
      "auth",
      JSON.stringify({
        token: response?.data?.result?.jwtToken,
        userId: response?.data?.result?.users?.id,
      })
    );
    localStorage.setItem("token", response?.data?.result?.jwtToken);
    localStorage.setItem("user", JSON.stringify(response?.data));
    yield put(push(`/login`));
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* changeUserPasswordRequests({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      userId: payload.id,
      currentpassword: payload.currentpassword,
      newpassword: payload.newpassword,
    };
    const response = yield call(
      instance.put,
      "/signup_svc/pv/users/changePassword"
      ,     data
    );
   
    // yield put(setuserpasswordsuccess(response.data.result));
    if (response?.data?.code === 0) {
      yield put(push(`/Profile`));
    } else {
      customSnackBar(response?.data?.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* sendotpsecondarymobilefunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
    };
    // yield put(sendotopemailsuccess(payload));
    payload.setIsLoading1(true);
    // const response = yield axios.post(
    //   "signup_svc/pb/users/resendMobileOtp",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/verify-secondary-mobile-number`));
      // toast.success(response.data.message);
      payload.setIsLoading1(false);
    } else {
      // yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoading1(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading1(false);
  }
}

function* getallgendersfunc({ payload }) {
  // const token = yield select(makeSelectAuthToken());

  try {
    // payload.setIsLoading(true);
    // Reload the window
    // const useridlogin = payload.id;
    // const response = yield axios.get(`signup_svc/pv/genders/getAllGender`);
    // {
    //   headers: {
    //     Authorization: `Token  ${token}`,
    //   },
    // });
    const response = yield call(
      instance.get,
      "signup_svc/pv/genders/getAllGender"
    );
    yield put(getallgenderssuccess(response.data.result.genders));
    // payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* sendgenderfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      genderId: payload.genderId,
      modifiedBy: payload.modifiedBy,
    };

    // yield put(sendotopemailsuccess(payload));
    // const response = yield axios.post(
    //   "signup_svc/pv/users/addUsergender",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUsergender",
      data
    );
    yield put(push(`/Profile`));
    // yield put(sendotopemailsuccess(sendgendersuccess));
    // if(response.data.code === 0){
    //   yield put(push(`/verify-secondary-mobile-number`));
    //   alert("code delivered")
    // }else{
    //   // yield put(push(`/allemailsuser`));
    //   alert("api failed")
    // }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* addsobfunctionfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      dob: payload.dob,
      modifiedBy: payload.modifiedBy,
    };

    // yield put(sendotopemailsuccess(payload));
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUserDob" ,     data
    );
    // const response = yield axios.post("signup_svc/pv/users/addUserDob", data);
    yield put(push(`/Profile`));
    payload.setIsLoading(false);

  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* getBusienss(id) {
  try {
    const response = yield axiosConfig.get(
      `business_svc/pb/business?userId=${id}`
    );
    return response.data; // Return the fetched business data
  } catch (error) {
    // Handle error
    console.error(error);
    return null;
  }
}

function* signinuserfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      email: payload.email,
      password: payload.password,
    };
    const response = yield axios.post("signup_svc/pb/users/signIn", data);
    if (response.data.code === 1) {
      payload.setpassword("");
      customSnackBar(response.data.message);
    } else if (response.data.code === 0 || response.data.code === 4) {
      const result = response?.data?.result;
      yield put(userpayloadsuccess(result));
      setCookiesDataWithTime(
        "auth",
        JSON.stringify({
          token: result?.jwtToken,
          userId: result?.users?.id,
        }),
        payload.rememberMe
      );
      localStorage.setItem("token", result?.jwtToken);
      localStorage.setItem("user", JSON.stringify(response?.data));
      yield put(fetchBusinessRequest(result?.users?.id));
      const businessData = yield take(FETCH_BUSINESS_SUCCESS);
      if (businessData?.payload?.length === 0) {
        yield put(push("/dashboard"));
      } else {
        if (
          result?.users?.defaultAccountId !== 0 &&
          result?.users?.isBusinessAccount === true
        ) {
          yield put(resetdate());
          const businessId = result?.users?.defaultAccountId;
          const selectedBusiness = businessData.payload.find(
            (business) => business.id === businessId
          );
          if (selectedBusiness) {
            yield put(addSelectedBusiness(selectedBusiness));
            yield put(push("/calendar"));
          } else {
            yield put(permissionModule(selectedBusiness));
            yield put(push(`/dashboard`));
          }
        } else {
          yield put(push(`/dashboard`));
        }
      }
    } else {
      yield put(push(`/dashboard`));
      customSnackBar(response.data.message);
    }
  } catch (error) {
    console.error(error);
    customSnackBar(error?.data?.message);
    payload.setpassword("");
  } finally {
    payload.setIsLoading(false);
  }
}

// function* resendprimarymobilefunc({ payload }) {
//   console.log(payload,"payloadpayloadpayload")
//   try {
//     // payload.setIsActive(true);
//     let data = {
//       id: payload.id,
//       type: payload.type,
//     };
//     // payload.setIsLoading2(true);
//     payload.setIsLoadingresend(true);
//     const response = yield axios.post(
//       "signup_svc/pb/users/resendMobileOtp",
//       data
//     );
//     if (response.data.code === 0) {
//       yield put(push(`/verify-number/${payload.usernumber}`));
//       // toast.success(response.data.message);
//       payload.setIsActive(true);
//       payload.setIsLoadingresend(false);
//       // payload.setIsLoading2(true);
//     } else {
//       customSnackBar(response.data.message);
//       payload.setIsLoadingresend(false);
//       // payload.setIsLoading2(true);
//     }
//   } catch (error) {
//     payload.setIsLoading2(true);
//   }
// }
function* resendprimarymobilefunc({ payload }) {
  try {
    let data = {
      id: payload.id,
      type: payload.type,
    };
    payload.setIsLoadingresend(true);
    const response = yield axios.post(
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // toast.success(response.data.message);
      payload.setIsLoadingresend(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoadingresend(false);
  }
}
function* resendprimaryemailnewotpfunc({ payload }) {
  try {
    let data = {
      id: payload.id,
      type: payload.type,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };
    payload.setIsLoadingresend(true);
    const response = yield axios.post(
      "signup_svc/pb/users/resendEmailOtp",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // toast.success(response.data.message);
      payload.setIsLoadingresend(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoadingresend(false);
  }
}

function* resendotpsecondaryfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };
    payload.setIsLoadingresend(true);
    yield put(sendotopemailsuccess(payload));
    // const response = yield axios.post(
    //   "signup_svc/pb/users/resendEmailOtp",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pb/users/resendEmailOtp",
      data
    );
    if (response.data.code === 0) {
      // yield put(push(`/add-secondary-email-otp-verify`));
      const isActive = true;
      payload.setIsActive(isActive);
      payload.setIsLoadingresend(false);
      // yield put(push(`/allemailsuser`));

      // toast.success(response.data.message);
    } else {
      yield put(push(`/allemailsuser`));
      payload.setIsLoadingresend(false);
      customSnackBar(response.data.message);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    payload.setIsLoadingresend(false);

    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* resendprimarymobilenewfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
      isMobile: false,
      isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      isAndroidRequest: false,
    };
    payload.setIsLoadingresend(true);
    // yield put(sendotopemailsuccess(payload));
    const response = yield axios.post(
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoadingresend(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    // payload.setIsLoading(false);
    payload.setIsLoadingresend(false);
  }
}

function* updatenameprofile({ payload }) {
  try {
    const authentication = getCookiesData("authtication");

    let data = {
      id: payload.id,
      firstName: payload.firstName,
      lastname: payload.lastName,
      modifiedBy: payload.modifiedBy,
    };
    payload.setLoading(true);
    const response = yield call(
      instance.put,
      "signup_svc/pv/users/updateBasicProfile",
      data
    );
    // const response = yield axios.put(
    //   "signup_svc/pv/users/updateBasicProfile",
    //   data
    // );
    if (response.data.code === 0) {
      yield put(push(`/Profile`));
      payload.setLoading(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setLoading(false);
  }
}

function* uploadimageuser({ payload }) {
  try {
    const formData = new FormData();
    formData.append("uploadImage", payload.uploadimage);
    const response = yield call(
      instance.post,
      `signup_svc/pv/users/addUserImage?userId=${payload.id}&environment=dev`,
      formData,
      {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    );
    // const response = yield axios.post(
    //   `signup_svc/pv/users/addUserImage?userId=${payload.id}&environment=dev`,
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      yield put(uploadprofileimagesuccess(response.data.result));
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    // payload.setIsLoading(false);
  }
}

function* getUserPermission({ payload }) {
  // const token = yield select(makeSelectAuthToken());
  try {
    // payload.setIsLoading(true);
    // Reload the window
    const useridlogin = payload.userID;
    const defaultBusiness = payload.businessID;
    // const response = yield axiosConfig.get(
    //   `/business_svc/pb/get_userroles/?userId=${useridlogin}&businessId=${defaultBusiness}`
    //   // `/business_svc/pb/get_userroles/?userId=19&businessId=1`
    // );
    const response = yield call(
      instance.get,
      `/business_svc/pb/get_userroles/?userId=${useridlogin}&businessId=${defaultBusiness}`
    );
    // {
    //   headers: {
    //     Authorization: `Token  ${token}`,
    //   },
    // });
    yield put(permissionModuleSucess(response?.data?.result));
    // payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

//////////////////////////////////////

// export function* watchLogin() {
//   yield takeLatest(LOGIN, loginUser);
// }
export function* watchRegister() {
  yield takeLatest(REGISTER_USER, registerUser);
}
export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordRequest);
}
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordRequest);
}
export function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordRequest);
}

///////////////////////////////////////////////////binders for plexar
export function* registerapibinder() {
  yield takeLatest(REGISTERAPI, registeruser);
}

export function* forgetPasswordBinder() {
  yield takeLatest(FORGET_PASSWORD, forgetPassword);
}
export function* sendotpbinder() {
  yield takeLatest(SEND_OTP_EMAIL, sendotpemail);
}
export function* setpasswordbinder() {
  yield takeLatest(SET_USER_PASSWORD, setuserrpasswordfunc);
}
export function* adduserprofilebinder() {
  yield takeLatest(ADD_USER_PROFILE, adduserprofilefunc);
}
export function* addusermobilebinder() {
  yield takeLatest(ADD_USER_MOBILE, addusermobilefunc);
}
export function* mobileotpverifyybinder() {
  yield takeLatest(MOBILE_OTP_VERIFY, mobileotpvrtifyy);
}
export function* getuserbyloginbinder() {
  yield takeLatest(GET_USER_DATA_AFTER_LOGIN, getuserdetaillogin);
}
export function* addsecondaryemailbinder() {
  yield takeLatest(ADD_SECONDARY_EMAIL, addsecondaryemailfunc);
}
export function* verifysecondarymailbinder() {
  yield takeLatest(VERIFY_SECONDARY_EMAIL, verifysecondaryemailfunc);
}
export function* sendotpsecondarybinder() {
  yield takeLatest(SEND_OTP_SECONDRY_EMAIL, sendotpsecondaryfunc);
}

export function* addsecondaryphonebinder() {
  yield takeLatest(ADD_SECONDARY_PHONE_NUMBER, addsecondaryphonefunc);
}

export function* verifysecondarymobilebinder() {
  yield takeLatest(VERIFY_SECONDARY_MOBILE, verifysecondarymobilefunc);
}
export function* resendsecondarymobilebinder() {
  yield takeLatest(RESEND_OTP_MOBILE, sendotpsecondarymobilefunc);
}

export function* getallusersgenderbinder() {
  yield takeLatest(GET_ALL_GENDERS, getallgendersfunc);
}

export function* sendgenderbinder() {
  yield takeLatest(SEND_GENDER, sendgenderfunc);
}

export function* adddobbinder() {
  yield takeLatest(ADD_DOB, addsobfunctionfunc);
}

export function* signinuserfuncbinder() {
  yield takeLatest(SIGNIN_USER, signinuserfunc);
}

export function* otpmobileprimaryfuncbinder() {
  yield takeLatest(RESEND_MOBILE_PRIMARY_OTP, resendprimarymobilefunc);
}

export function* resendprimaryemailnewotpfuncbinder() {
  yield takeLatest(RESEND_OTP_NEW_PRIMARY_EMAIL, resendprimaryemailnewotpfunc);
}

export function* resendotpsecondaryfuncbinder() {
  yield takeLatest(RESEND_OTP_SECONDARY_EMAIL, resendotpsecondaryfunc);
}

export function* resendotpsecondarymobilefuncbinder() {
  yield takeLatest(NEW_OTP_RESEND_PRIMARY_MOBILE, resendprimarymobilenewfunc);
}

export function* updateuserprofilebinder() {
  yield takeLatest(UPDATE_USER_NAME, updatenameprofile);
}

export function* uploadimageuserbinder() {
  yield takeLatest(UPLOAD_PROFILE_IMG, uploadimageuser);
}

export function* permissionModule() {
  yield takeLatest(PERMISSION_API, getUserPermission);
}

export function* watchchangeUserPasswords() {
  yield takeLatest(CHANGE_USERS_PASSWORD, changeUserPasswordRequests);
}
export function* watchRestsPassword() {
  yield takeLatest(CHANGE_USER_PASSWORD, resetUserPasswordRequest);
}

///////////////////////////////

export default function* authSaga() {
  yield all([
    // fork(watchLogin),
    fork(watchRegister),
    fork(permissionModule),
    fork(watchchangeUserPasswords),
    fork(watchRestsPassword),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchChangePassword),
    fork(registerapibinder),
    fork(sendotpbinder),
    fork(setpasswordbinder),
    fork(adduserprofilebinder),
    fork(addusermobilebinder),
    fork(mobileotpverifyybinder),
    fork(getuserbyloginbinder),
    fork(addsecondaryemailbinder),
    fork(verifysecondarymailbinder),
    fork(sendotpsecondarybinder),
    fork(addsecondaryphonebinder),
    fork(verifysecondarymobilebinder),
    fork(resendsecondarymobilebinder),
    fork(getallusersgenderbinder),
    fork(sendgenderbinder),
    fork(adddobbinder),
    fork(forgetPasswordBinder),
    fork(signinuserfuncbinder),
    fork(otpmobileprimaryfuncbinder),
    fork(resendprimaryemailnewotpfuncbinder),
    fork(resendotpsecondaryfuncbinder),
    fork(resendotpsecondarymobilefuncbinder),
    fork(updateuserprofilebinder),
    fork(uploadimageuserbinder),
  ]);
}
