import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import OrderDetailPage from "../OrderDetail/orderDetail.jsx"
import SideNavone from "../../../SideNavBar";

export default function OrderDetail() {
  return (
    <div>
      <SideNavone>
        <OrderDetailPage />
      </SideNavone>
    </div>
  );
}
