export const data = [
  {
    id: 1,
    name: "Chatbot",
    description: "Text definition, the main body of matter in a manuscript, book",
    image: "../images/Custom Size29.jpg",
    price:"$36",
    duration:"Per Month",
    version:"24.0"
  },
  {
    id: 2,
    name: "MetData",
    description: "Text definition, the main body of matter in a manuscript, book",
    image: "../images/Custom Size29.jpg",
    price:"$36",
    duration:"Per Month",
    version:"24.0"
  },
  {
    id: 3,
    name: "Slack",
    description: "Text definition, the main body of matter in a manuscript, book",
    image: "../images/Custom Size29.jpg",
    price:"$36",
    duration:"Per Month",
    version:"24.0"
  },
  {
      id: 4,
      name: "Plaxaar",
      description: "Text definition, the main body of matter in a manuscript, book",
      image: "../images/Custom Size29.jpg",
      price:"$36",
      duration:"Per Month",
      version:"24.0"
  },
  {
      id: 5,
      name: "Expert",
      description: "Text definition, the main body of matter in a manuscript, book",
      image: "../images/Custom Size29.jpg",
      price:"$36",
      duration:"Per Month",
      version:"24.0"
  },
];


export const record = [
  {
    id: 1,
    name: "Document",
    image: "../images/document-text (6).svg",
    
  },
  {
    id: 2,
    name: "Apply",
    image: "../images/apply.svg",
    
  },
  {
    id: 3,
    name: "Staff",
    image: "../images/staff.svg",
    
  }, {
    id: 4,
    name: "Onboarding Meeting",
    image: "../images/meeting.svg",
    
  }, {
    id: 5,
    name: "Training",
    image: "../images/presentation-line.svg",
    
  }, {
    id: 6,
    name: "Business Approval",
    image: "../images/timeer.svg",
    
  },
];
  