import * as React from "react";
import TextField from "../TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import the dayjs library
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const InputDate = ({
  open,
  onClose,
  onClick,
  iconRemove,
  label,
  format,
  name,
  value = "",
  minDate,
  maxDate,
  disabled = false,
  onChange = () => { },
  helperText = null,
  // inputFormat = "dd MMM, yyyy",
  defaultValue = new Date(),
  defaultCalendarMonth,
  sx,
}) => {
  const formattedValue = value ? value : "No Expiry Date";
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onClose={onClose}
        label={label}
        // inputFormat={inputFormat}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        // defaultCalendarMonth={defaultCalendarMonth}
        value={value}
        onChange={onChange}
        // defaultValue={defaultValue}
        sx={sx}
        slotProps={{
          textField: {
            size: "small",
            error: false,
            onClick: onClick
          },
        }}
        slots={iconRemove}
        textField={(params) => {
          return (
            <TextField
              {...params}
              name={name}
              size={"small"}
              // variant={"filled"}
              helperText={helperText}
            // value={formattedValue}

            // Remove the default placeholder
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
