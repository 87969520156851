import React, { useEffect } from 'react'
import SideNavone from '../../../../SideNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { getAllStaff } from '../store/staffActions';
import { useParams } from 'react-router-dom';
import AddStaffForm from './components/AddStaffForm';
// import StaffTableListMobile from './components/StaffTableListMobile';

const AddStaff = () => {

    // const dispatch = useDispatch();
    // const { id } = useParams();

    // useEffect(() => {
    //     dispatch(getAllStaff(id))
    // }, []);

    return (
        <>
            <SideNavone>
                {/* {store?.isLoading ?
                    <ServiceLoader />
                    : <> */}
                <AddStaffForm />
                {/* <StaffTableListMobile /> */}
                {/* </>
                } */}
            </SideNavone>
        </>
    )
}

export default AddStaff