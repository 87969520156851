import React, { useState, useEffect } from "react";
import { postQuestion } from "../../service/APIService";
import { QuestionList } from "../question_list/QuestionList";
import { getQuestions } from "./../../service/APIService";
import {
  Autocomplete,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { customSnackBar } from "../../../../utils";

export const QuestionBuilder = ({
  setTab
}) => {
  const [questionType, setQuestionType] = useState("");
  const [smallQuestion, setSmallQuestion] = useState("");
  const [longQuestion, setLongQuestion] = useState("");
  const [emailQuestion, setEmailQuestion] = useState("");
  const [numberQuestion, setNumberQuestion] = useState(0);
  const [spinnerQuestion, setSpinnerQuestion] = useState("");
  const [mcqQuestion, setMcqQuestion] = useState("");
  const [dateQuestion, setDateQuestion] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limitDate, setLimitDate] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [imageQuestion, setImageQuestion] = useState("");
  const [documentQuestion, setDocumentQuestion] = useState("");
  const [Options, setOptions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
  ]);
  const [McqOptions, setMCQOptions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
  ]);

  const [signatureQuestion, setSignatureQuestion] = useState("");
  const [labelQuestion, setLabelQuestion] = useState("");
  const [statementQuestion, setStatementQuestion] = useState("");
  const [statement, setStatement] = useState("");

  const [videoQuestion, setVideoQuestion] = useState("");
  const [audioQuestion, setAudioQuestion] = useState("");

  const [questionStatement, setQuestionStatement] = useState('')
  const [refreshQuestions, setRefreshQuestions] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [questionsFilterList, setQuestionsFilterList] = useState([]);

  useEffect(() => {
    getQuestions("").then(function (response) {
      setQuestionsList(response.data.results);
    });
  }, []);
  function updateSpinnerOptions(id, value) {
    const index = Options.findIndex((e) => e.id === id);
    var optionsList = [...Options]; // important to create a copy, otherwise you'll modify state outside of setState call
    optionsList[index] = { id: id, value: value };
    setOptions(optionsList);
  }

  function addOptionSpinner() {
    setOptions([
      ...Options,
      { id: Options[Options.length - 1].id + 1, value: "" },
    ]);
  }
  function deleteOptionSpinner(index) {
    setOptions([
      ...Options.slice(0, index),
      ...Options.slice(index + 1, Options.length),
    ]);
  }
  function updateMCQOptions(id, value) {
    const index = McqOptions.findIndex((e) => e.id === id);
    var optionsList = [...McqOptions]; // important to create a copy, otherwise you'll modify state outside of setState call
    optionsList[index] = { id: id, value: value };
    setMCQOptions(optionsList);
  }

  function addMCQSpinner() {
    setMCQOptions([
      ...McqOptions,
      { id: McqOptions[McqOptions.length - 1].id + 1, value: "" },
    ]);
  }
  function deleteMCQSpinner(index) {
    setMCQOptions([
      ...McqOptions.slice(0, index),
      ...McqOptions.slice(index + 1, McqOptions.length),
    ]);
  }

  function submitQuestion() {
    if (questionType === "SmallTextQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "SmallTextQuestion",
          question: questionStatement,
          regex: "^.{0,100}$",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "LongTextQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "LongTextQuestion",
          question: questionStatement,
          regex: "^.*$",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "EmailQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "EmailQuestion",
          question: emailQuestion,
          regex: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "NumberQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "NumberQuestion",
          question: numberQuestion,
          regex: "^\\d+$",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (
      questionType === "DropdownQuestion" &&
      Options[0].value !== "" &&
      Options[1].value !== ""
    ) {
      if (questionStatement !== "") {
        let data = {
          type: "DropdownQuestion",
          question: questionStatement,
          regex: "",
          options: Options,
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (
      questionType === "MCQQuestion" &&
      McqOptions[0].value !== "" &&
      McqOptions[1].value !== ""
    ) {
      if (questionStatement !== "") {
        let data = {
          type: "MCQQuestion",
          question: questionStatement,
          regex: "",
          options: McqOptions,
          multiple: isMultiple,
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "DateQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "DateQuestion",
          question: questionStatement,
          regex: "",
          startDate: startDate,
          endDate: endDate,
          limit: limitDate,
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "ImageQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "ImageQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "DocumentQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "DocumentQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "SignatureQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "SignatureQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "LabelQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "LabelQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "StatementQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "StatementQuestion",
          question: statementQuestion,
          statement: statement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "VideoQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "VideoQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else if (questionType === "AudioQuestion") {
      if (questionStatement !== "") {
        let data = {
          type: "AudioQuestion",
          question: questionStatement,
          regex: "",
        };
        sendData(data);
      } else {
        console.warning("null string");
      }
    } else {
      customSnackBar("All fields required");
    }
  }
  function cleanFields() {
    setQuestionType("");
    setSmallQuestion("");
    setLongQuestion("");
    setNumberQuestion("");
    setEmailQuestion("");
    setSpinnerQuestion("");
    setMCQOptions("");
    setMCQOptions([
      { id: 1, value: "" },
      { id: 2, value: "" },
    ]);
    setOptions([
      { id: 1, value: "" },
      { id: 2, value: "" },
    ]);
    setIsMultiple(false);
    setLimitDate(false);
    setDateQuestion("");
    setStartDate("");
    setEndDate("");
  }

  async function sendData(data) {
    await postQuestion(data)
      .then(function (response) {
        if (!response.data.error) {
          customSnackBar(response.data.message);
          cleanFields();
          setRefreshQuestions(true);
          setTab(4)

        } else {
          customSnackBar(response.data.message);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function addQuestionInSelectedSection(e, val) {

  }

  function handleStatement(e) {
    let questionsListTemp = questionsList;
    let questionsFilterListTemp = questionsListTemp.filter((question, i) => (
      (question.contentObject?.questionText).toLowerCase().includes(e.target.value.toLowerCase())
    ))
    setQuestionsFilterList(questionsFilterListTemp);
    setQuestionStatement(e.target.value)
  }

  return (
    <div className="pt-3">
      <div
        className="row justify-content-center pb-5 rounded"
        style={{ background: "#f0f4f5" }}
      >
        <div className="text-end my-3"></div>
        <div className="col-lg-10 col-md-10">
          <header className="theme_color_2 p-2">
            <div className="d-flex">
              <i
                className="ri-file-list-2-line me-2"
                style={{ color: "#db0406", fontSize: "22px" }}
              ></i>
              <h4 className="mt-2">Question and Answer Builder</h4>
            </div>
          </header>
          <div className="bg-white p-4 mb-3 rounded">
            <div className="d-flex justify-content-between">
              <div className="col-lg-8 col-md-8">
              <div className="me-3">
                       <input
                        type="text"
                        onChange={(e) => handleStatement(e)}
                        value={questionStatement}
                        className="form-control py-3 input_field_height"
                        placeholder="What..."
                      />
                    </div>
                    <div>{questionsFilterList.map((questtion, i) => (
                      <div className=" border p-2 mt-0 me-3">
                      <div className="d-flex justify-content-between">
                      <div>{questtion?.contentObject?.questionText}</div>
                      <div>Type: {questtion?.contentObject.questionType}</div>
                      </div>
                        {questtion?.contentObject.questionType === "MCQQuestion" ? 
                        <div className="d-flex">
                          {questtion?.contentObject?.options?.map((option,i) => (
                            <p className="pe-4 pt-2"><i className="ri-checkbox-blank-circle-fill" style={{color: "lightGray"}}></i> {option.choiceText}</p>
                          ))}</div>
                          :
                          <></>
                        }
                      </div>
                    ))}</div>
               
              </div>
              <div className="form-floating col-lg-4 col-md-4 ">
                <select
                  className="form-select pt-2 input_field_height"
                  value={questionType}
                  onChange={(e) => {
                    setQuestionType(e.target.value);
                  }}
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option defaultValue>select question type</option>

                  {/* <option defaultValue>Click to select option</option> */}
                  <option value="SmallTextQuestion">Small Text</option>
                  <option value="LongTextQuestion">Long Text</option>
                  <option value="EmailQuestion">Email</option>
                  <option value="NumberQuestion">Number</option>
                  <option value="DropdownQuestion">Dropdown/Spinner</option>
                  <option value="MCQQuestion">MCQ(Multiple Choice)</option>
                  <option value="DateQuestion">Date</option>
                  <option value="ImageQuestion">Image</option>
                  <option value="DocumentQuestion">Document</option>
                  <option value="SignatureQuestion">Signature</option>
                  <option value="LabelQuestion">Label</option>
                  <option value="StatementQuestion">Statement</option>
                  <option value="VideoQuestion">Video</option>
                  <option value="AudioQuestion">Audio</option>
                </select>
                {/* <label htmlFor="floatingSelect">select question type</label> */}
              </div>
            </div>
            {(() => {
              if (questionType === "SmallTextQuestion" ||
               questionType === "LongTextQuestion" || 
               questionType === "NumberQuestion" ||
               questionType === "EmailQuestion" ||
               questionType === "ImageQuestion" ||
               questionType === "DocumentQuestion" ||
               questionType === "SignatureQuestion" ||
               questionType === "LabelQuestion" ||
               questionType === "VideoQuestion" ||
               questionType === "VideoQuestion" 
               ) {
                return (
                  <div className="text-end">
                    {/* <div>
                      <h4>Short Question Statement</h4>
                      <input
                        type="text"
                        onChange={(e) => setSmallQuestion(e.target.value)}
                        value={smallQuestion}
                        className="form-control "
                      />
                    </div> */}
                    {questionStatement !== "" && questionsFilterList.length === 0 ? (
                      <button
                        className="btn btn-danger mt-3"
                        onClick={() => submitQuestion()}
                      >
                        Create New
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
              //  else if (questionType === "LongTextQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Long Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setLongQuestion(e.target.value)}
              //           value={longQuestion}
              //         />
              //       </div>
              //       {longQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "NumberQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Number Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setNumberQuestion(e.target.value)}
              //           value={numberQuestion}
              //         />
              //       </div>
              //       {numberQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "EmailQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Email Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setEmailQuestion(e.target.value)}
              //           value={emailQuestion}
              //         />
              //       </div>
              //       {emailQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "ImageQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Image Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setImageQuestion(e.target.value)}
              //           value={imageQuestion}
              //         />
              //       </div>
              //       {imageQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "DocumentQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Document Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setDocumentQuestion(e.target.value)}
              //           value={documentQuestion}
              //         />
              //       </div>
              //       {documentQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "SignatureQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Signature Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setSignatureQuestion(e.target.value)}
              //           value={signatureQuestion}
              //         />
              //       </div>
              //       {signatureQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Create New
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "LabelQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Label Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setLabelQuestion(e.target.value)}
              //           value={labelQuestion}
              //         />
              //       </div>
              //       {labelQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "VideoQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Video Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setVideoQuestion(e.target.value)}
              //           value={videoQuestion}
              //         />
              //       </div>
              //       {videoQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }
              //  else if (questionType === "AudioQuestion") {
              //   return (
              //     <div className="rounded shadow p-3 bg-light my-2">
              //       <div>
              //         <h4>Audio Question Statement</h4>
              //         <input
              //           type="text"
              //           className="form-control "
              //           onChange={(e) => setAudioQuestion(e.target.value)}
              //           value={audioQuestion}
              //         />
              //       </div>
              //       {audioQuestion !== "" ? (
              //         <button
              //           className="btn btn-danger mt-3"
              //           onClick={() => submitQuestion()}
              //         >
              //           Save
              //         </button>
              //       ) : (
              //         <></>
              //       )}
              //     </div>
              //   );
              // }

              else if (questionType === "StatementQuestion") {
                return (
                  <div className="rounded shadow p-3 bg-light my-2">
                    <div>
                      {/* <h4>Statement Question Statement</h4>
                      <input
                        type="text"
                        className="form-control "
                        onChange={(e) => setStatementQuestion(e.target.value)}
                        value={statementQuestion}
                      /> */}
                      <h4>Statement</h4>
                      <textarea
                        type="text"
                        className="form-control "
                        onChange={(e) => setStatement(e.target.value)}
                        value={statement}
                      />
                    </div>
                    {statementQuestion !== "" ? (
                      <div className="text-end">
                      <button
                        className="btn btn-danger mt-3"
                        onClick={() => submitQuestion()}
                      >
                        Save
                      </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
              else if (questionType === "DropdownQuestion") {
                return (
                  <div className="rounded shadow p-3 bg-light my-2">
                    <div>
                      {/* <h4>Spinner Question Statement</h4>
                      <input
                        type="text"
                        className="form-control "
                        onChange={(e) => setSpinnerQuestion(e.target.value)}
                        value={spinnerQuestion}
                      /> */}
                    </div>
                    <div className="mt-3">
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="me-2">Options</h5>
                        <button
                          className="btn btn-danger"
                          onClick={() => addOptionSpinner()}
                        >
                          +
                        </button>
                      </div>

                      <div className="">
                        {Options.map((e, index) => {
                          return (
                            <div className="mb-2 d-flex" key={e.id}>
                              <input
                                type="text"
                                value={e.value}
                                key={e.id}
                                onChange={(v) =>
                                  updateSpinnerOptions(e.id, v.target.value)
                                }
                                className="form-control me-2"
                                placeholder="enter text"
                              />
                              {index > 1 ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteOptionSpinner(index)}
                                >
                                  -
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {questionStatement !== "" && questionsFilterList.length === 0 ? (
                      <button
                        className="btn btn-danger mt-3"
                        onClick={() => submitQuestion()}
                      >
                        Create New
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
               else if (questionType === "MCQQuestion") {
                return (
                  <div className="rounded shadow p-3 bg-light my-2">
                    <div>
                      {/* <h4>MCQ Question Statement</h4>
                      <input
                        type="text"
                        className="form-control "
                        onChange={(e) => setMcqQuestion(e.target.value)}
                        value={mcqQuestion}
                      /> */}
                      <div className="d-flex align-items-center mt-2">
                        <input
                          type="checkbox"
                          id="check_mcq"
                          className="me-3"
                          value={isMultiple}
                          onClick={() => {
                            setIsMultiple(!isMultiple);
                          }}
                        />
                        <label htmlFor="check_mcq">Multiple Selection </label>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="me-2">Options</h5>
                        <button
                          className="btn btn-danger"
                          onClick={() => addMCQSpinner()}
                        >
                          +
                        </button>
                      </div>

                      <div className="">
                        {McqOptions.map((e, index) => {
                          return (
                            <div className="mb-2 d-flex" key={e.id}>
                              <input
                                type="text"
                                value={e.value}
                                key={e.id}
                                onChange={(v) =>
                                  updateMCQOptions(e.id, v.target.value)
                                }
                                className="form-control me-2"
                                placeholder="enter text"
                              />
                              {index > 1 ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteMCQSpinner(index)}
                                >
                                  -
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {questionStatement !== "" && questionsFilterList.length === 0 ? (
                      <div className="text-end">
                      <button
                        className="btn btn-danger mt-3"
                        onClick={() => submitQuestion()}
                      >
                        Create New
                      </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
               else if (questionType === "DateQuestion") {
                return (
                  <div className="rounded shadow p-3 bg-light my-2">
                    <div>
                      {/* <h4>Date Question Statement</h4>
                      <input
                        type="email"
                        className="form-control "
                        onChange={(e) => setDateQuestion(e.target.value)}
                        value={dateQuestion}
                      /> */}
                      <div className="d-flex align-items-center mt-2">
                        <input
                          id="limit_date"
                          type="checkbox"
                          className="me-3"
                          value={limitDate}
                          onClick={() => {
                            setLimitDate(!limitDate);
                          }}
                        />
                        <label htmlFor="limit_date">
                          Limit Date (Start/End){" "}
                        </label>
                      </div>
                      {limitDate ? (
                        <div className="d-flex mt-3">
                          <div>
                            <label>Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className="ms-5">
                            <label>End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {questionStatement !== "" && questionsFilterList.length === 0 ? (
                      <div className="text-end">
                      <button
                        className="btn btn-danger mt-3"
                        onClick={() => submitQuestion()}
                      >
                        Create New
                      </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        </div>
        {/* <div className='col-lg-4 col-md-4'>
                    <div className='rounded shadow p-3 bg-light'>
                    <QuestionList isRefresh={refreshQuestions} setRefresh={setRefreshQuestions}/>
                    </div>
                </div> */}
      </div>
    </div>
  );
};
