import React from 'react'

const SelectInvite = (props) => {
    return (
        <div className='container invite_container'>
            <div className='row invite_row'>
                <div className='col-md-6 col-12 pe-md-4 p-0'>
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 w-100 one2one_section'>
                        <img src='./meetspace/8.png' alt='' />
                        <h3 className='text-muted mt-4'>One-on-One</h3>
                        <p className='text-secondary'>Let an invitee pick a time to meet with you.</p>
                        <button className='btn btn-lg px-5'
                        onClick={()=> {
                            props.handleNext('1');
                        }}
                        >Create</button>
                    </div>
                </div>

                <div className='col-md-6 col-12 ps-md-4 p-0 mt-md-0 mt-4'>
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 w-100 one2one_section'>
                        <img src='./meetspace/16.png' alt='' />
                        <h3 className='text-muted mt-4'>Group</h3>
                        <p className='text-secondary'>Let an invitee pick a time to meet with you.</p>
                        <button className='btn btn-lg px-5'
                         onClick={()=> {
                            props.handleNext('2');
                        }}
                        >Create</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectInvite