import React, { useEffect } from "react";
import "./dashboardpages.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getuserdataafterlogin,
  logout,
  uploadprofileimagesuccess,
} from "../../Store/Auth/actions";
import { useState } from "react";
import SideNavone from "../../SideNavBar/index";
import axios from "axios";
import Header from "../BusinessProfile/Header";
import { BsInfoCircle, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { CiMail, CiMobile2 } from "react-icons/ci";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { addSelectedBusiness } from "../../Store/Business/actions";
import { Avatar } from "@mui/material";
import { customSnackBar } from "../../utils";
import instance from "../../utils/utils";

function Profilepage() {
  const dispatch = useDispatch();
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  let history = useHistory();
  const flag = true;

  const [alluserdata, setAlluserdata] = useState("");
  const [file, setFile] = useState(null);
  const [updateimage, setUpdateimage] = useState(null);
  const [loader, setLoader] = useState(false);

  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const getdataofregistereduser = useSelector(
    (state) => state.Auth?.alluserdataafterlogin
  );
  const fetchUser = () => {
    dispatch(
      getuserdataafterlogin({
        id: registersuccessId,
        setLoader,
      })
    );
  };
  useEffect(() => {
    if (registersuccessId) {
      fetchUser();
      
    }
  }, [registersuccessId, dispatch]);
  const handleInputChange = (event) => {
    setFile(event.target.files[0]);
  };
  // useEffect(()=>{
  //   const resp =  instance.get(
  //     `signup_svc/pb/users/getnewRefreshToken?tokenModel=ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxlSEFpT2pFM01UQXpNekV3T1Rrc0ltbHpjeUk2SWtsVFUxVkZVbDlRVEVWWVFVRlNJaXdpWVhWa0lqb2lVRXhGV0VGQlVpSXNJbFZ6WlhJaU9pSmxlVW94WXpKV2VWTlhVV2xQYVVsM1NXbDNhVnBYTVdoaFYzZHBUMmxKYVV4RFNubGlNbmhzU1dwdmFVbHBkMmxrV0U1c1kydFNhR1JIUldsUGFVcFJWRVZXV1ZGVlJsTkpiakE5SW4wLmstSVZkNzRZakNlS1ZqSEhDOF9ubmNJeVViaTlyOEpyN1E0Uy1LXy1wQmZ5R3VKa0EwMG1ObzFlVnZXWW4wWjc1azRaWkFCY1JYY1N5QWgtTFEyRjhfbms5V0E1cHU0dzhYaXNvUmF4N09HX1BJM0J2RWl4RlE3S1daTmVxTjFlOUFRTDZ3bVdHLTZmN2RfMDM2Sm5CVURORllPYUNnS2lXSXg0bHRrVlNxbw==`
  //   );
  //   console.log(resp,"Reso")
  // })
  const Logout = async () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      // await localStorage.clear();
      dispatch(logout());
      dispatch(addSelectedBusiness(null));
      history.push("/");
    } else {
      return;
    }
  };
  // useEffect(
  //   () => console.log(getdataofregistereduser, "getdataofregistereduser"),
  //   [getdataofregistereduser]
  // );

  // apiii ...........................
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    const allowedFileTypes = ["image/jpeg", "image/jpg","image/png"];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Create form data
      const formData = new FormData();
      formData.append("Id", registersuccessId);
      formData.append("ServiceName", "signup");
      formData.append("Source", "Plexaar");
      formData.append("Files", selectedFile);
      formData.append("Category", "main");
      formData.append("FileType", "i");
      formData.append("CreatedBy", 10);
      setIsLoadingImage(true);
      setFile(event.target.files[0]);
      // Create form data

      try {
        // Send the image data to the API endpoint
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.code === 0) {
          dispatch(uploadprofileimagesuccess(response.data.paths[0]));
          customSnackBar(response.data.message);
          setUpdateimage(response.data.paths[0]);
        } else {
          customSnackBar(response.data.message);
        }
      } catch (error) {
        customSnackBar("Error uploading image:", error);
      }
    } else {
      customSnackBar("Invalid file type. Please select a jpg or jpeg file.");
    }
    // finally {
    //   setIsLoadingImage(false);
    // }
  };

  useEffect(() => {
    if (updateimage) {
      const postImageData = async () => {
        // setIsloadingImage(true);
        try {
          let data = {
            id: registersuccessId,
            imageUrl: updateimage,
            modifiedBy: registersuccessId,
          };
          const response = await instance.put(
            `/signup_svc/pv/users/changeUserImage`,
            data
          );
          customSnackBar(response.data.message);
          fetchUser();
        // window.location.reload()
      } catch (error) {
          customSnackBar(error);
        } finally {
          setIsLoadingImage(false);
        }
      };
      postImageData();
    }
  }, [updateimage, registersuccessId]);

  const pushpage = () => {
    history.push("/show-all-numbers-of-users");
  };
  const editnames = () => {
    history.push(`/edit-user-name`);
  };
  const genderbutton = () => {
    history.push("/addgenderuser");
  };
  const adddobbutton = () => {
    history.push("/add-dob-user");
  };
  return (
    <>
      <SideNavone>
        {loader ? (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {/* <div>
              <Header flag={flag} heading={"Manage Profile"} />
            </div> */}
            <div
              className="ms-md-2 me-md-3 ps-md-5 pe-md-5 pt-4 hide_scrollbar "
              style={{
                height: "88vh",
                overflow: "auto",
                paddingBottom: "40px",
              }}
            >
              <div className="card card-content">
                <div className="d-flex  justify-content-between">
                  <div className="d-flex justify-content-evenly">
                    {isLoadingImage ? (
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="spinner-border text-primary mt-3"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : getdataofregistereduser?.imageURL === null ||
                      getdataofregistereduser?.imageURL === "" ? (
                      <Avatar
                        className="img-fluid img_profile_shape"
                        alt="nodata"
                        style={{ width: 100, height: 100 }}
                      />
                    ) : (
                      <img
                        className=" img_profile_shape"
                        src={
                          getdataofregistereduser
                            ? getdataofregistereduser?.imageURL
                            : "/images/profile.png"
                        }
                        alt="no-data"
                        // width={100}
                        // height={100}
                      />
                    )}
                    <div className="margin_neg_button">
                      <div className="upload-btn-wrapper">
                        {/* <div className="btnn"> */}
                        <img src="../images/camera.svg" alt="nodata" />
                        {/* </div> */}
                        <input
                          type="file"
                          name="image"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="p-4">
                      <h6 className="mb-1 ">
                        <b>
                          {getdataofregistereduser?.firstName}{" "}
                          {getdataofregistereduser?.lastName}
                        </b>
                      </h6>
                      <p className="mb-0 text_color_account">
                        Personal Account
                      </p>
                      <p className="mb-0 sub_text_color_account">
                        ID {getdataofregistereduser?.accountNumber}
                      </p>
                    </div>
                  </div>
                  <div className="mt-md-4">
                    <BsThreeDotsVertical fontSize={25} color="#BEC3C7" />
                  </div>
                </div>
              </div>
              {/* Profile show section ------------------------------------------------------ */}

              <div className="pb-3">
                <div className="d-flex pt-3 pb-0">
                  <AiOutlineUser style={{ color: "#AAA", fontSize: 20 }} />
                  <p className=" sub_text_color_account_alltext ps-1">Name</p>
                </div>
                <div className="background_color_showboxes" onClick={editnames}>
                  <div className="row me-3">
                    <div className="col-md-6 ps-4 ">
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        First Name
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.firstName}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                    <div className="col-md-12">
                      <hr className="my-3 background_color_line" />
                    </div>
                    <div className="col-md-6 ps-4">
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        Last Name
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.lastName}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* Email show section ------------------------------------------------------ */}
              <div className="pb-2">
                <div className="d-flex pt-3 pb-0">
                  <CiMail style={{ color: "#AAA", fontSize: 20 }} />
                  <p className=" sub_text_color_account_alltext ps-1">Email</p>
                </div>
                <div
                  className="background_color_showboxes "
                  // onClick={() => history.push("/allemailsuser")}
                >
                  <div className="row me-3">
                    <div className="col-md-6 ps-4">
                      <p className="mb-0  ms-2 sub_text_color_account_alltext_show">
                        Primary Email
                      </p>
                      <p className="mb-0  ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.primaryEmail}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      {getdataofregistereduser?.primaryEmailVerify === true ? (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/verified.gif"
                          alt="no-data"
                        />
                      ) : (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/unverified.gif"
                          alt="no-data"
                        />
                      )}
                    </div>
                    <div className="col-md-12">
                      <hr className="my-3 background_color_line" />
                    </div>
                    <div
                      className="col-md-6 ps-4"
                      onClick={() => {
                        if (!getdataofregistereduser?.secondaryEmailVerify) {
                          history.push("/add-secondary-email");
                        }
                      }}
                    >
                      <p className="mb-0  ms-2 sub_text_color_account_alltext_show">
                        Secondary Email
                      </p>
                      <p className="mb-0  ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.secondaryEmail === "" ? (
                          <>Add Secondary Email</>
                        ) : getdataofregistereduser?.secondaryEmail ? (
                          <>{getdataofregistereduser?.secondaryEmail}</>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      {getdataofregistereduser?.secondaryEmailVerify ===
                      true ? (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/verified.gif"
                          alt="no-data"
                        />
                      ) : (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/unverified.gif"
                          alt="no-data"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Mobile show section ------------------------------------------------------ */}

              <div className="pb-2">
                <div className="d-flex pt-3 pb-0">
                  <CiMobile2 style={{ color: "#AAA", fontSize: 20 }} />
                  <p className=" sub_text_color_account_alltext ps-1">Mobile</p>
                </div>
                <div className="background_color_showboxes">
                  <div className="row me-3">
                    <div
                      className="col-md-6 ps-4"
                      onClick={() => {
                        if (!getdataofregistereduser?.primaryMobileVerify) {
                          history.push("/add-number");
                        }
                      }}
                    >
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        Primary Phone Number
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.primaryMobile === "" ? (
                          <>Add Primary Phone Number</>
                        ) : getdataofregistereduser?.primaryMobile ? (
                          <>
                            {getdataofregistereduser?.primaryMobileVerify ===
                            true ? (
                              <>
                                {getdataofregistereduser?.primaryMobile.includes(
                                  "+"
                                )
                                  ? ""
                                  : "+"}
                                {getdataofregistereduser?.primaryMobile}
                              </>
                            ) : (
                              <span className="applyonclick here">
                                {getdataofregistereduser?.primaryMobile.includes(
                                  "+"
                                )
                                  ? ""
                                  : "+"}
                                {getdataofregistereduser?.primaryMobile}
                              </span>
                            )}
                          </>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-md-6 ps-4 text-end m-auto">
                      {getdataofregistereduser?.primaryMobileVerify === true ? (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/verified.gif"
                          alt="no-data"
                        />
                      ) : (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/unverified.gif"
                          alt="no-data"
                        />
                      )}
                      {/* <i id="color_icon_right" className="fas fa-angle-right"></i> */}
                    </div>
                    <div className="col-md-12">
                      <hr className="my-3 background_color_line" />
                    </div>
                    <div
                      className="col-md-6 ps-4"
                      onClick={() => {
                        if (!getdataofregistereduser?.secondaryMobileVerify) {
                          history.push("/add-secondary-mobile-number");
                        }
                      }}
                    >
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        Secondary Phone Number
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.secondaryMobile === "" ? (
                          <>Add Secondary Phone Number</>
                        ) : getdataofregistereduser?.secondaryMobile ? (
                          <>
                            {getdataofregistereduser?.secondaryMobile.includes(
                              "+"
                            )
                              ? ""
                              : "+"}
                            {getdataofregistereduser?.secondaryMobile}
                          </>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      {getdataofregistereduser?.secondaryMobileVerify ===
                      true ? (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/verified.gif"
                          alt="no-data"
                        />
                      ) : (
                        <img
                          className="img-fluid img_verified_size"
                          src="../images/plexar/unverified.gif"
                          alt="no-data"
                        />
                      )}
                      {/* <i id="color_icon_right" className="fas fa-angle-right"></i> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Addtional Info show section ------------------------------------------------------ */}

              <div className="pb-2">
                <div className="d-flex pt-3 pb-0">
                  <BsInfoCircle style={{ color: "#AAA", fontSize: 20 }} />
                  <p className=" sub_text_color_account_alltext ps-1">
                    Additional Info
                  </p>
                </div>
                <div className="background_color_showboxes">
                  <div className="row me-3">
                    <div className="col-md-6 ps-4">
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        Gender
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.genderName === "" ? (
                          <span onClick={genderbutton}>Add Your Gender</span>
                        ) : (
                          <span onClick={genderbutton}>
                            {getdataofregistereduser?.genderName}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                    <div className="col-md-12">
                      <hr className="my-3 background_color_line" />
                    </div>
                    <div className="col-md-6 ps-4" onClick={adddobbutton}>
                      <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                        Date of Birth
                      </p>
                      <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                        {getdataofregistereduser?.dob === null ? (
                          <>Add Date of Birth</>
                        ) : getdataofregistereduser?.dob ? (
                          <>{getdataofregistereduser?.dob.substring(0, 10)}</>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* cards......... */}

              <div className="d-flex mb-3 flex-wrap">
                {" "}
                <div className="mt-3 mb-1 pt-0 pe-4 pt-md-2 ">
                  <div className="card card-content">
                    <div className="p-5 ms-4 me-4 ms-md-4 me-md-4">
                      <img
                        className="ms-1"
                        src="../images/location.svg"
                        alt="nodata"
                      />{" "}
                      <p className="pt-2 sub_text_color_account_alltext_show_actual">
                        Address
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="mt-3 mb-1 pt-0  pe-4 pt-md-2 ">
                    <div className="card card-content">
                      <div className="p-5 ms-4 me-4 ms-md-4 me-md-4">
                        <img
                          className="ms-1"
                          src="../images/cart.svg"
                          alt="nodata"
                        />{" "}
                        <p className="pt-2 sub_text_color_account_alltext_show_actual">
                          Order
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 mb-1 pt-0  pe-4 pt-md-2 ">
                    <div className="card card-content">
                      <div className="p-5 ms-4 me-4 ms-md-4 me-md-4">
                        <img
                          className="ms-1"
                          src="../images/booking.svg"
                          alt="nodata"
                        />{" "}
                        <p className="pt-2 sub_text_color_account_alltext_show_actual">
                          Booking
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 mb-1 pt-0  pe-4 pt-md-2 ">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push("/change-user-password");
                      }}
                      className="card card-content"
                    >
                      <div className="p-5 ms-4 me-4 ms-md-4 me-md-4">
                        <img
                          className="ms-1"
                          src="../images/settings.svg"
                          alt="nodata"
                        />{" "}
                        <p className="pt-2 sub_text_color_account_alltext_show_actual">
                          Settings
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="background_color_showboxes">
                  <div className="row me-3">
                    <div className="col-md-6 ps-4 ">
                      <div className="d-flex">
                        <img
                          src="../images/logout.svg"
                          alt="no-data"
                          className="mt-2"
                          height={20}
                          width={20}
                        />
                        <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                          Login and Security{" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-3">
                <div className="background_color_showboxes">
                  <div className="row me-3">
                    <div
                      className="col-md-6 ps-4 "
                      onClick={() => {
                        Logout();
                      }}
                    >
                      <div className="d-flex">
                        <img
                          src="../images/security.svg"
                          alt="no-data"
                          className="mt-2"
                          height={20}
                          width={20}
                        />
                        <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                          Sign Out{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div className="pt-3">
                <div className="background_color_showboxes">
                  <div className="row me-3">
                    <div
                      className="col-md-6 ps-4 "
                      onClick={() => {
                        history.push("/delete_account");
                      }}
                    >
                      <div className="d-flex">
                        <img
                          src="../images/security.svg"
                          alt="no-data"
                          className="mt-2"
                          height={20}
                          width={20}
                        />
                        <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual">
                          Delete Account
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-end m-auto">
                      <i
                        id="color_icon_right"
                        className="fas fa-angle-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SideNavone>
    </>
  );
}

export default Profilepage;
