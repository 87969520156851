import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import "./bookingdetail.css";
import Slider from "./Slider";
import FooterSection from "./footerEventSection";
import { BsThreeDots } from "react-icons/bs";
import Venue from "./Venue";
import Img from "../../assets/scheduler/assests/cosmetologist-woman-performs-procedure-2022-06-18-00-38-57-utc@2x.png";
import Img2 from "../../assets/scheduler/assests/Ellipse 783@2x.png";
import Img3 from "../../assets/scheduler/assests/Ellipse 783@2x.png";
import { eventsandbreakAxios } from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Avatar, SvgIcon } from "@mui/material";
import {
  calculateDurationInMinutes,
  customSnackBar,
  formatTime,
} from "../../../utils";
import { ClipLoader } from "react-spinners";
import { selectedSlotData } from "../store/bookingSlice";
import { handlechange } from "../../../scheduler/store/dateSlice";
import instance from "../../../utils/utils";
import DialogBox from "Pages/BusinessProfile/DialogBox";

const Eventdetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });

  let event = useSelector((state) => state.bookingReducer.selectedEvent);
  console.log(event, "eventeventevent");
  useEffect(() => {
    if (event) {
      setLoading(true);
      let eventType = event.type;
      if (event.type === "timeblock") {
        eventType ="TimeBlock";
      }
      
      instance
        .get(
          `/eventsandbreaks_svc/pb/eventsbyId/?Id=${event.id}&type=${eventType}&timeZone=Europe/London`
        )
      
        .then((response) => {
          if (event.type === "event") {
            setEventData(response?.data?.result.events_data);
            setLoading(false);
          } else if (event.type === "break") {
            setEventData(response?.data?.result.breaks_data);
            setLoading(false);
          } else {
            setEventData(response?.data?.result.TimeBlock_data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [event]);

  const handleDeleteEvents = (event) => {
    setDialog({
      visible: true,
      header: `Delete ${event?.type}`,
      message: `Are you sure you want to delete this ${event?.type}?`,
      key: Math.random().toString(36).substring(7),
      ok: () => deleteEventHandler(),
    });
  };
  const deleteEventHandler = async () => {
    if (event.type === "break") {
      try {
        const response = await instance.delete(
          `/eventsandbreaks_svc/pb/events/?type=${event.type}&id=${event.id}`
        );
        if (response.data.code === 0) {
          history.push("/calendar");
        }
      } catch (e) {
        customSnackBar(e);
      }
    } else if (event.type === "event") {
      try {
        const response = await instance.delete(
          `/eventsandbreaks_svc/pb/events/?type=${event.type}&id=${event.id}`
        );
        if (response.data.code === 0) {
          history.push("/calendar");
        }
      } catch (e) {
        customSnackBar(e);
      }
    } else {
      try {
        const response = await instance.delete(
          `/eventsandbreaks_svc/pb/events/?type=${event.type}&id=${event.id}`
        );
        if (response.data.code === 0) {
          history.push("/calendar");
        }
      } catch (e) {
        customSnackBar(e);
      }
    }
  };

  const updateEventHandler = () => {
    if (event.type === "break") {
      const data = {
        resourceId: eventData.providerId,
        timeFrom: formatTime(eventData.timeFrom),
        actualTimeFrom: formatTime(eventData.timeFrom),
        actualTimeTo: formatTime(eventData.timeTo),
        timeTo: formatTime(eventData.timeTo),
        bookingDate: eventData.bookingDate,
        duration: calculateDurationInMinutes(
          formatTime(eventData.timeFrom),
          formatTime(eventData.timeTo)
        ),
        resourceObject: {
          resourceId: eventData.providerId,
          resourceTitle: eventData.providerName,
        },
        isUpdate: true,
        title: eventData.title,
        description: eventData.description,
        type: "break",
      };
      dispatch(handlechange(eventData.bookingDate));
      dispatch(selectedSlotData(data));
      history.push("/scheduler/create_break_event_timeblock");
    } else if(event.type === "event") {
      const data = {
        resourceId: eventData.providerId,
        timeFrom: formatTime(eventData.timeFrom),
        actualTimeFrom: formatTime(eventData.timeFrom),
        actualTimeTo: formatTime(eventData.timeTo),
        timeTo: formatTime(eventData.timeTo),
        bookingDate: eventData.bookingDate,
        duration: calculateDurationInMinutes(
          formatTime(eventData.timeFrom),
          formatTime(eventData.timeTo)
        ),
        resourceObject: {
          resourceId: eventData.providerId,
          resourceTitle: eventData.providerName,
        },
        isUpdate: true,
        title: eventData.title,
        description: eventData.description,
        type: "event",
      };
      dispatch(handlechange(eventData.bookingDate));
      dispatch(selectedSlotData(data));
      history.push("/scheduler/create_break_event_timeblock");
    }
    else {
      const data = {
        resourceId: eventData.providerId,
        timeFrom: formatTime(eventData.timeFrom),
        actualTimeFrom: formatTime(eventData.timeFrom),
        actualTimeTo: formatTime(eventData.timeTo),
        timeTo: formatTime(eventData.timeTo),
        bookingDate: eventData.bookingDate,
        duration: calculateDurationInMinutes(
          formatTime(eventData.timeFrom),
          formatTime(eventData.timeTo)
        ),
        resourceObject: {
          resourceId: eventData.providerId,
          resourceTitle: eventData.providerName,
        },
        isUpdate: true,
        title: eventData.title,
        description: eventData.description,
        type: "timeBlock",
      };
      dispatch(handlechange(eventData.bookingDate));
      dispatch(selectedSlotData(data));
      history.push("/scheduler/create_break_event_timeblock");
    }
  };

  const selectedService = {
    color: "#616161",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  };
  return (
    <>
     <DialogBox
          {...dialog}
          header={dialog.header}
          message={dialog.message}
        />
      <div
        className="d-flex mt-3"
        style={{
          marginRight: "20%",
          marginLeft: "20%",
        }}
      >
        <div
          className="pt-2"
          onClick={() => history.push("/calendar")}
          role="button"
        >
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>
        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ClipLoader color="#000" />
          </div>
        ) : (
          <div
            className="container ms-4 py-2 px-4"
            style={{
              borderRadius: "8px",
              border: "1px solid #DCDCDC",
              background: "#FFF",
            }}
          >
            <div>
              <span
                style={{
                  color: "#1B1B1B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Type
              </span>
              <div className="d-flex justify-content-between align-items-center my-1">
                <div className="d-flex align-items-center">
                  <p
                    className="m-0 text-captilize mx-2"
                    style={selectedService}
                  >
                    {eventData?.type}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#1B1B1B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Title
              </span>
              <div className="d-flex justify-content-between align-items-center my-1">
                <div className="d-flex align-items-center">
                  <p className="m-0 mx-2" style={selectedService}>
                    {eventData?.title}
                  </p>
                </div>
                <div
                  style={{
                    color: "#1B1B1B",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  <b>Duration: </b>
                  {eventData?.bookingDuration}
                </div>
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#1B1B1B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Description
              </span>
              <div className="d-flex justify-content-between align-items-center my-1">
                <div className="d-flex align-items-center">
                  <p className="m-0 mx-2" style={selectedService}>
                    {eventData?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-evenly align-items-center my-1">
              <div className="d-flex align-items-center">
                <p className="m-0 mx-2" style={selectedService}>
                  <b>Date:</b> {eventData?.bookingDate}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="m-0 mx-2" style={selectedService}>
                  <b>Time:</b> {eventData?.timeFrom} - {eventData?.timeTo}
                </p>
              </div>
            </div>
            <div className="mt-2">
              <span
                style={{
                  color: "#1B1B1B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Service Provider
              </span>
              <div className="d-flex justify-content-between align-items-center my-1">
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    style={{ width: "30px", height: "30px" }}
                    src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9LCJ0b0Zvcm1hdCI6ImF2aWYifX0="
                  />
                  <div
                    className="ms-2"
                    style={{
                      color: "#616161",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    {eventData?.providerName}
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <SvgIcon
                    component={MoreVertOutlinedIcon}
                    style={{
                      color: "#bfbfbf",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-evenly mb-2">
              <button
                type="button"
                onClick={()=>handleDeleteEvents(eventData)}
                className="btn btn-danger px-5 py-2"
              >
                Delete
              </button>
              <button
                type="button"
                onClick={updateEventHandler}
                className="btn btn-warning px-5 py-2"
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Eventdetail;

/* <div className="col-md-12 px-5 height_fixed_cal " id="style-2">
    <Container className="m-auto">
      <Grid item xl={3} lg={3} md={6} sm={12} className="p-2 min-h-[250px]">
        <div
          className="card px-3 py-2  mt-4  min-h-[250px] card-cont shadow-lg border-0 p-3"
          style={{
            // height: "100vh",
            // overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "5px 6px 5px",
            // padding: 15px 15px 23px 15px;
            //   border-radius: 10px;
            boxshadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
            //   backgroundcolor: ;
          }}
        >

          <div className="ps-2 pe-2">
            <div>
              <div className=" row gap-4  ps-0">
                <div className='col-md-2 text-start'>
                <div
                  className="col-md-3 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Title</h1>
            </div>
                 <h5>{eventData?.title}</h5>
                </div>
                <div
                  className="col-md-4 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Provider</h1>
            </div>
                 <h5> {eventData?.providerName}</h5>
                </div>
                <div
                  className="col-md-4 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Duration</h1>
            </div>
                 <h5> {eventData?.bookingDuration} Min</h5>
                </div>
              </div>
              <hr
                className="m-2 ms-0"
                style={{
                  background: "#edf0f5",
                  width: "970px",
                  height: "1px",
                  margin: "15.6px 0 21.4px",
                }}
              />
            </div>
          </div>
          <div className=" ps-2 pe-2">
            <div>
              <div className="d-flex flex-wrap justify-content-between pe-0">
                <div className="d-flex ps-1 flex-wrap justify-content">
                  <h1 className="book pt-1"> Description</h1>
                </div>
              </div>
              <div>
                <div className="row gap-4 ps-0">
                <p>{eventData?.description}</p>
                 
                </div>
                <hr
                  className="mt-4 ms-0"
                  style={{
                    background: "#edf0f5",
                    width: "970px",
                    height: "1px",
                    margin: "15.6px 0 21.4px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ps-2 pe-2">
            <div>
              <div className=" row gap-4  ps-0">
                <div className='col-md-2 text-start'>
                <div
                  className="col-md-3 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Booking Date</h1>
            </div>
                 <h5>{eventData?.bookingDate}</h5>
                </div>
                <div
                  className="col-md-4 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Time From</h1>
            </div>
                 <h5> {eventData?.timeFrom}</h5>
                </div>
                <div
                  className="col-md-4 ps-3"
                  
                >
                   <div className="d-flex ps-1 flex-wrap justify-content">
              <h1 className="book  pt-1"> Time To</h1>
            </div>
                 <h5> {eventData?.timeTo}</h5>
                </div>
              </div>
              <hr
                className="m-2 ms-0"
                style={{
                  background: "#edf0f5",
                  width: "970px",
                  height: "1px",
                  margin: "15.6px 0 21.4px",
                }}
              />
            </div>
          </div>

          <div className="  ps-2 pe-2">
            <FooterSection  eventData={eventData}/>
          </div>
          <div className="pt-2 pb-4 d-flex justify-content-center">
          <Button
              sx={{
                width: "200px",
                backgroundColor:  "#f3f3f3" ,
                height: "40px",
                borderRadius: "10px",
                margin:"0 20px"
               
              }}
              variant="outlined"
              onClick={e=>{history.goBack()}}
            >
             Back
            </Button>
            <Button
              sx={{
                width: "200px",
                height: "40px",
                borderRadius: "10px",
               
              }}
              variant="contained"
            >
             Edit
            </Button>
          </div>
        </div>
      </Grid>
    </Container>
    </div> */
