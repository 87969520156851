import React, {  useRef, useEffect } from "react";
import { Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ProviderWidget from "./providerWidget.jsx";
import CalenderHeader from "./CalenderHeader.jsx";
import { useDispatch, useSelector } from "react-redux";
import { nextdate, prevdate, resetdate } from "../store/dateSlice";
// import CircularProgress from "@mui/material/CircularProgress";
import CustomEventWidget from "./customEventWidget.jsx";

// const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const customEvents = [
  {
    appointmentId: 28971,
    title: "Cricket",
    start: "2023-12-29T07:00:00.000Z",
    end: "2023-12-29T07:30:00.000Z",
    resourceId: 396,
    allDay: false,
    background: false,
    bookingDate: "2023-12-29",
    bookingId: 28971,
    color: "white",
    colorCode: "#548DFF",
    colorEvento: "#548DFF",
    customerFirstName: "hameed",
    customerLastName: "r",
    customerName: "",
    duration: 30,
    providerName: "Pat Commins",
    resource: 396,
    timeFrom: "12:00",
    timeTo: "12:30",
    type: "booking",
    sourceResource: null,
  },
  {
    title: "Break",
    resourceId: 396,
    start: "2023-12-29T07:30:00.000Z",
    end: "2023-12-29T08:30:00.000Z",
    duration: 60,
    colorEvento: "#f3f3f3",
    color: "black",
    allDay: false,
    background: false,
    timeFrom: "12:30",
    timeTo: "13:30",
    resource: 396,
    breakId: 6,
    type: "break",
    description: "",
    sourceResource: null,
    providerName: "Pat Commins",
  },
  {
    title: "EVENTS",
    resourceId: 396,
    start: "2023-12-29T08:30:00.000Z",
    end: "2023-12-29T09:30:00.000Z",
    duration: 60,
    colorEvento: "#FFFF00",
    color: "white",
    allDay: false,
    background: false,
    resource: 396,
    serviceDetail: {},
    customerName: "Hsmrrf",
    colorCode: "#FFFF00",
    timeFrom: "13:30",
    timeTo: "14:30",
    eventId: 10,
    description: "Nothing",
    type: "event",
    bookingDate: "",
    providerName: "Pat Commins",
    sourceResource: null,
  },
  {
    appointmentId: 28971,
    title: "Cricket",
    start: "2023-12-30T07:00:00.000Z",
    end: "2023-12-30T07:30:00.000Z",
    resourceId: 396,
    allDay: false,
    background: false,
    bookingDate: "2023-12-29",
    bookingId: 28971,
    color: "white",
    colorCode: "#548DFF",
    colorEvento: "#548DFF",
    customerFirstName: "hameed",
    customerLastName: "r",
    customerName: "",
    duration: 30,
    providerName: "Pat Commins",
    resource: 396,
    timeFrom: "12:00",
    timeTo: "12:30",
    type: "booking",
    sourceResource: null,
  },
  {
    title: "Break",
    resourceId: 396,
    start: "2023-12-30T07:30:00.000Z",
    end: "2023-12-30T08:30:00.000Z",
    duration: 60,
    colorEvento: "#f3f3f3",
    color: "black",
    allDay: false,
    background: false,
    timeFrom: "12:30",
    timeTo: "13:30",
    resource: 396,
    breakId: 6,
    type: "break",
    description: "",
    sourceResource: null,
    providerName: "Pat Commins",
  },
  {
    title: "YESSSSSSSSS",
    resourceId: 396,
    start: "2023-12-30T08:30:00.000Z",
    end: "2023-12-30T09:30:00.000Z",
    duration: 60,
    colorEvento: "#FFFF00",
    color: "white",
    allDay: false,
    background: false,
    resource: 396,
    serviceDetail: {},
    customerName: "Hsmrrf",
    colorCode: "#FFFF00",
    timeFrom: "13:30",
    timeTo: "14:30",
    eventId: 9,
    description: "Nothing",
    type: "event",
    bookingDate: "",
    providerName: "Pat Commins",
    sourceResource: null,
  },
  {
    title: "Unavailable",
    resourceId: 396,
    start: "2023-12-26T03:00:00.000Z",
    end: "2023-12-26T17:00:00.000Z",
    duration: 900,
    resource: 396,
    colorEvento: "white",
    color: "black",
    isBusinessHour: false,
    allDay: false,
    background: false,
    timeFrom: "08:00",
    timeTo: "22:00",
    providerName: "Pat Commins",
  },
  {
    title: "Unavailable",
    resourceId: 396,
    start: "2023-12-28T03:00:00.000Z",
    end: "2023-12-28T05:30:00.000Z",
    duration: 60,
    resource: 396,
    colorEvento: "white",
    isBusinessHour: true,
    color: "black",
    allDay: false,
    background: false,
    timeFrom: "08:30",
    timeTo: "10:30",
    providerName: "Pat Commins",
  },
  {
    title: "Unavailable",
    resourceId: 396,
    start: "2023-12-28T08:30:00.000Z",
    end: "2023-12-28T09:30:00.000Z",
    duration: 60,
    resource: 396,
    colorEvento: "white",
    isBusinessHour: true,
    color: "black",
    allDay: false,
    background: false,
    timeFrom: "13:30",
    timeTo: "14:30",
    providerName: "Pat Commins",
  },
];
const resourcesMap = [
  {
    resourceId: 396,
    resourceTitle: "Pat Commins",
    img: "https://1864597015.rsc.cdn77.org/Plexaar/Attachments/Serviceprovider/396.jpeg",
  },
];

const CalendarApp = (props) => {
  // console.log(props, "prospos");
  const calendarRef = useRef();
  const dispatch = useDispatch();
  const numberOfProviders = useSelector(
    (state) => state.calendarSettingReducer.numberOfProviders
  );
  const {
    localizer,
    allevent,
    allbgevent,
    resources,
    views,
    startTime,
    endTime,
    // draghandleEvents,
    calenderEvents,
    handleSlots,
    handleFilter,
  } = props;

  let date = useSelector((state) => state.counterReducer.dateApi);
  // const loading = useSelector((state) => state.calendarReducer.isLoading);
  const viewOfCalendar = useSelector(
    (state) => state.calendarSettingReducer.viewOfCalendar
  );
  useEffect(() => {
    if (calendarRef.current) {
      const calendarContainer = document.querySelector(".rbc-calendar");
      const childrens = calendarContainer.children[1].children[1].children;
      // console.log("CALENDAR WIDGET", calendarContainer);
      for (let i = 1; i < childrens.length; i++) {
        childrens[i].style.minWidth =
          numberOfProviders === 1
            ? "91vw"
            : numberOfProviders === 2
            ? "46vw"
            : numberOfProviders === 3
            ? "30.5vw"
            : numberOfProviders === 4
            ? "23vw"
            : numberOfProviders === 5
            ? "18.2vw"
            : numberOfProviders === 6
            ? "15.2vw"
            : numberOfProviders === 7
            ? "13vw"
            : numberOfProviders === 8
            ? "11.4vw"
            : numberOfProviders === 9
            ? "10.15vw"
            : "9.1vw";
      }
    }
  }, [numberOfProviders]);
  const moveEvent = (data) => {
  };

  return (
    <DnDCalendar
      ref={calendarRef}
      defaultDate={new Date()}
      defaultView={viewOfCalendar}
      events={viewOfCalendar === "week" ? customEvents : allevent}
      localizer={localizer}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      onEventDrop={moveEvent}
      // onEventResize={this.resizeEvent}
      resources={viewOfCalendar === "week" ? resourcesMap : resources}
      resizable={false}
      dayLayoutAlgorithm="overlap"
      style={{
        backgroundColor: "white",
      }}
      culture="en"
      onSelectEvent={(event) => calenderEvents(event)}
      draggableAccessor={(event) =>
        event.title === "Unavailable" ? false : true
      }
      onSelectSlot={(slotInfo) => handleSlots(slotInfo)}
      onDragOver={(event) => console.table(event)}
      onDragStart={(data) => {
        console.table(data);
      }}
      className="flex flex-1 w-100 padding_of_cal"
      startAccessor={(event) => new Date(event.start)}
      endAccessor={(event) => new Date(event.end)}
      min={new Date(0, 0, 0, startTime?.hour, startTime?.min, 0)}
      max={new Date(0, 0, 0, endTime?.hour, endTime?.min, 0)}
      components={{
        resourceHeader: (props) => {
          return (
            props?.resource?.length !== 0 && (
              <ProviderWidget val={props.resource} />
            )
          );
        },
        toolbar: (toolbar) => {
          return (
            <CalenderHeader
              handleFilter={handleFilter}
              toolbar={toolbar}
              action={props.action ? props.action : ""}
              eventData={props.eventData ? props.eventData : ""}
            />
          );
        },
        event: (event) => {
          return (
            props?.resource?.length !== 0 && <CustomEventWidget event={event} />
          );
        },
      }}
      eventPropGetter={(myEventsList) => {
        const backgroundColor =
          myEventsList.colorCode === "#173371"
            ? "#548DFF"
            : myEventsList.colorEvento;
        const color = myEventsList.color ? myEventsList.color : "blue";
        const border = "none";
        const cursor = "pointer";
        const display = "flex";
        const width = "100%";
        const height = "99.70%";
        return {
          style: {
            backgroundColor,
            color,
            border,
            cursor,
            display,
            width,
            height,
          },
        };
      }}
      slotPropGetter={(event) => {
        if (event) {
          return {
            className: "special-day",
          };
        } else return {};
      }}
      onNavigate={(date, view, action) => {
        if (view === "day" && action === "NEXT") {
          dispatch(nextdate());
        } else if (view === "day" && action === "PREV") {
          dispatch(prevdate());
        } else if (view === "day" && action === "TODAY") {
          dispatch(resetdate());
        }
      }}
      selectable
      backgroundEvents={allbgevent}
      views={views}
      step={15}
      showMultiDayTimes={true}
      date={date}
      popup
    />
  );
};
export default CalendarApp;

// class CalendarApp extends Component {
//   constructor(props) {
//     super(props);
//     this.calendarRef = React.createRef();
//     this.customEvents = [
//       {
//         appointmentId: 28971,
//         title: "Cricket",
//         start: "2023-12-29T07:00:00.000Z",
//         end: "2023-12-29T07:30:00.000Z",
//         resourceId: 396,
//         allDay: false,
//         background: false,
//         bookingDate: "2023-12-29",
//         bookingId: 28971,
//         color: "white",
//         colorCode: "#548DFF",
//         colorEvento: "#548DFF",
//         customerFirstName: "hameed",
//         customerLastName: "r",
//         customerName: "",
//         duration: 30,
//         providerName: "Pat Commins",
//         resource: 396,
//         timeFrom: "12:00",
//         timeTo: "12:30",
//         type: "booking",
//         sourceResource: null,
//       },
//       {
//         title: "Break",
//         resourceId: 396,
//         start: "2023-12-29T07:30:00.000Z",
//         end: "2023-12-29T08:30:00.000Z",
//         duration: 60,
//         colorEvento: "#f3f3f3",
//         color: "black",
//         allDay: false,
//         background: false,
//         timeFrom: "12:30",
//         timeTo: "13:30",
//         resource: 396,
//         breakId: 6,
//         type: "break",
//         description: "",
//         sourceResource: null,
//         providerName: "Pat Commins",
//       },
//       {
//         title: "EVENTS",
//         resourceId: 396,
//         start: "2023-12-29T08:30:00.000Z",
//         end: "2023-12-29T09:30:00.000Z",
//         duration: 60,
//         colorEvento: "#FFFF00",
//         color: "white",
//         allDay: false,
//         background: false,
//         resource: 396,
//         serviceDetail: {},
//         customerName: "Hsmrrf",
//         colorCode: "#FFFF00",
//         timeFrom: "13:30",
//         timeTo: "14:30",
//         eventId: 10,
//         description: "Nothing",
//         type: "event",
//         bookingDate: "",
//         providerName: "Pat Commins",
//         sourceResource: null,
//       },
//       {
//         appointmentId: 28971,
//         title: "Cricket",
//         start: "2023-12-30T07:00:00.000Z",
//         end: "2023-12-30T07:30:00.000Z",
//         resourceId: 396,
//         allDay: false,
//         background: false,
//         bookingDate: "2023-12-29",
//         bookingId: 28971,
//         color: "white",
//         colorCode: "#548DFF",
//         colorEvento: "#548DFF",
//         customerFirstName: "hameed",
//         customerLastName: "r",
//         customerName: "",
//         duration: 30,
//         providerName: "Pat Commins",
//         resource: 396,
//         timeFrom: "12:00",
//         timeTo: "12:30",
//         type: "booking",
//         sourceResource: null,
//       },
//       {
//         title: "Break",
//         resourceId: 396,
//         start: "2023-12-30T07:30:00.000Z",
//         end: "2023-12-30T08:30:00.000Z",
//         duration: 60,
//         colorEvento: "#f3f3f3",
//         color: "black",
//         allDay: false,
//         background: false,
//         timeFrom: "12:30",
//         timeTo: "13:30",
//         resource: 396,
//         breakId: 6,
//         type: "break",
//         description: "",
//         sourceResource: null,
//         providerName: "Pat Commins",
//       },
//       {
//         title: "YESSSSSSSSS",
//         resourceId: 396,
//         start: "2023-12-30T08:30:00.000Z",
//         end: "2023-12-30T09:30:00.000Z",
//         duration: 60,
//         colorEvento: "#FFFF00",
//         color: "white",
//         allDay: false,
//         background: false,
//         resource: 396,
//         serviceDetail: {},
//         customerName: "Hsmrrf",
//         colorCode: "#FFFF00",
//         timeFrom: "13:30",
//         timeTo: "14:30",
//         eventId: 9,
//         description: "Nothing",
//         type: "event",
//         bookingDate: "",
//         providerName: "Pat Commins",
//         sourceResource: null,
//       },
//       {
//         title: "Unavailable",
//         resourceId: 396,
//         start: "2023-12-26T03:00:00.000Z",
//         end: "2023-12-26T17:00:00.000Z",
//         duration: 900,
//         resource: 396,
//         colorEvento: "white",
//         color: "black",
//         isBusinessHour: false,
//         allDay: false,
//         background: false,
//         timeFrom: "08:00",
//         timeTo: "22:00",
//         providerName: "Pat Commins",
//       },
//       {
//         title: "Unavailable",
//         resourceId: 396,
//         start: "2023-12-28T03:00:00.000Z",
//         end: "2023-12-28T05:30:00.000Z",
//         duration: 60,
//         resource: 396,
//         colorEvento: "white",
//         isBusinessHour: true,
//         color: "black",
//         allDay: false,
//         background: false,
//         timeFrom: "08:30",
//         timeTo: "10:30",
//         providerName: "Pat Commins",
//       },
//       {
//         title: "Unavailable",
//         resourceId: 396,
//         start: "2023-12-28T08:30:00.000Z",
//         end: "2023-12-28T09:30:00.000Z",
//         duration: 60,
//         resource: 396,
//         colorEvento: "white",
//         isBusinessHour: true,
//         color: "black",
//         allDay: false,
//         background: false,
//         timeFrom: "13:30",
//         timeTo: "14:30",
//         providerName: "Pat Commins",
//       },
//     ];
//     this.resourcesMap = [
//       {
//         resourceId: 396,
//         resourceTitle: "Pat Commins",
//         img: "https://1864597015.rsc.cdn77.org/Plexaar/Attachments/Serviceprovider/396.jpeg",
//       },
//     ];
//   }

//   componentDidMount() {
//     // Add any componentDidMount logic here
//   }

//   componentDidUpdate(prevProps) {
//     // Add any componentDidUpdate logic here
//     if (this.props.numberOfProviders !== prevProps.numberOfProviders) {
//       this.adjustCalendarLayout();
//     }
//   }

//   adjustCalendarLayout = () => {
//     if (this.calendarRef.current) {
//       const calendarContainer = document.querySelector(".rbc-calendar");
//       const childrens = calendarContainer.children[1].children[1].children;
//       for (let i = 1; i < childrens.length; i++) {
//         childrens[i].style.minWidth =
//           this.props.numberOfProviders === 1
//             ? "91vw"
//             : this.props.numberOfProviders === 2
//             ? "46vw"
//             : this.props.numberOfProviders === 3
//             ? "30.5vw"
//             : this.props.numberOfProviders === 4
//             ? "23vw"
//             : this.props.numberOfProviders === 5
//             ? "18.2vw"
//             : this.props.numberOfProviders === 6
//             ? "15.2vw"
//             : this.props.numberOfProviders === 7
//             ? "13vw"
//             : this.props.numberOfProviders === 8
//             ? "11.4vw"
//             : this.props.numberOfProviders === 9
//             ? "10.15vw"
//             : "9.1vw";
//       }
//     }
//   };

//   moveEvent = (data) => {
//     console.log(data);
//   };

// //   resizeEvent = (data) => {
// //     console.log(data);
// //   };

//   render() {
//     const {
//       localizer,
//       allevent,
//       allbgevent,
//       resources,
//       views,
//       startTime,
//       endTime,
//       calenderEvents,
//       handleSlots,
//       handleFilter,
//       date,
//       dispatch,
//     } = this.props;

//     return (
//       <DnDCalendar
//         ref={this.calendarRef}
//         defaultDate={new Date()}
//         defaultView={this.props.viewOfCalendar}
//         events={this.props.viewOfCalendar === "week" ? this.customEvents : allevent}
//         localizer={localizer}
//         resourceIdAccessor="resourceId"
//         resourceTitleAccessor="resourceTitle"
//         onEventDrop={this.moveEvent}
//         // onEventResize={this.resizeEvent}
//         // resources={[
//         //   { resourceId: 1, resourceTitle: 'Board room' },
//         //   { resourceId: 2, resourceTitle: 'Training room' },
//         //   { resourceId: 3, resourceTitle: 'Meeting room 1' },
//         //   { resourceId: 4, resourceTitle: 'Meeting room 2' },
//         // ]}
//         resources={this.props.viewOfCalendar === "week" ? this.resourcesMap : resources}
//         resizable={false}
//         dayLayoutAlgorithm="overlap"
//         style={{
//           backgroundColor: "white",
//         }}
//         culture="en"
//         onSelectEvent={(event) => calenderEvents(event)}
//         draggableAccessor={(event) => event.title === "Unavailable"? false : true}
//         onSelectSlot={(slotInfo) => handleSlots(slotInfo)}
//         onDragOver={(event) => console.table(event)}
//         onDragStart={(data) => {
//           console.table(data);
//         }}
//         className="flex flex-1 h-100 w-100 padding_of_cal"
//         startAccessor={(event) => new Date(event.start)}
//         endAccessor={(event) => new Date(event.end)}
//         min={new Date(0, 0, 0, startTime?.hour, startTime?.min, 0)}
//         max={new Date(0, 0, 0, endTime?.hour, endTime?.min, 0)}
//         components={{
//           resourceHeader: (props) => {
//             return (
//               props?.resource?.length !== 0 && (
//                 <ProviderWidget val={props.resource} />
//               )
//             );
//           },
//           toolbar: (toolbar) => {
//             console.log(toolbar);
//             return (
//               <CalenderHeader handleFilter={handleFilter} toolbar={toolbar} />
//             );
//           },
//           event: (event) => {
//             return (
//               this.props?.resource?.length !== 0 && (
//                 <CustomEventWidget event={event} />
//               )
//             );
//           },
//         }}
//         eventPropGetter={(myEventsList) => {
//           const backgroundColor =
//             myEventsList.colorCode === "#173371"
//               ? "#548DFF"
//               : myEventsList.colorEvento;
//           const color = myEventsList.color ? myEventsList.color : "blue";
//           const border = "none";
//           const cursor = "pointer";
//           const display = "flex";
//           const width = "100%";
//           return {
//             style: { backgroundColor, color, border, cursor, display, width },
//           };
//         }}
//         slotPropGetter={(event) => {
//           if (event) {
//             return {
//               className: "special-day",
//             };
//           } else return {};
//         }}
//         onNavigate={(date, view, action) => {
//           if (view === "day" && action === "NEXT") {
//             dispatch(nextdate());
//           } else if (view === "day" && action === "PREV") {
//             dispatch(prevdate());
//           } else if (view === "day" && action === "TODAY") {
//             dispatch(resetdate());
//           }
//         }}
//         selectable
//         backgroundEvents={allbgevent}
//         views={views}
//         step={15}
//         showMultiDayTimes={true}
//         date={date}
//         popup
//       />
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   numberOfProviders: state.calendarSettingReducer.numberOfProviders,
//   date: state.counterReducer.dateApi,
//   viewOfCalendar: state.calendarSettingReducer.viewOfCalendar
// });

// export default connect(mapStateToProps)(CalendarApp);
