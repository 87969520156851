import React from "react";


const SearchBar = ({
  searchTerm,
  handleInputChange,
  searchResults,
  handleResultClick,
  message
}) => {
  // Define a validation function to check if the search term is empty
 
  return (
    <>
      <div className="position-relative" style={{ zIndex: "1000", width: "100%" }}>
        <div className="d-flex align-items-center w-100">
          <div className="w-100">
            <div className="col-md-12 border_rad_new px-2 pb-2 mt-3">
              <label className="label_text_business">Search Address</label>
              <input
                className="form-control input_style_fix"
                id="Address"
                label="Address"
                name="address"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search to find business address"
                style={{
                  border: "none",
                  outline: "none",
                  padding: "0",
                  margin: "0",
                }}
              />
            </div>
          </div>
        
        </div>
        {message && (
        <span className="text-danger font_size_password">
          {message}
        </span>
      )}
        {searchResults.length > 0 && (
          <ul
            style={{
              backgroundColor: "white",
              color: "black",
              width: "100%",
              textAlign: "left",
              maxHeight: "25vh",
              overflowY: "auto",
              fontSize: "12px",
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
              position: "absolute",
            }}
          >
            {searchResults.map((result, index) => (
              <React.Fragment key={index}>
                <li onClick={() => handleResultClick(result)}>
                  {result.formatted}
                </li>
                <hr />
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;
