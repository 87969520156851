// import React, { useState, useRef, useEffect, useCallback } from "react";
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   TextField,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   CircularProgress,
//   IconButton,
//   Avatar,
// } from "@mui/material";
// import MicIcon from "@mui/icons-material/Mic";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import SendIcon from "@mui/icons-material/Send";
// import ImageIcon from "@mui/icons-material/Image";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// import StopIcon from "@mui/icons-material/Stop";
// import VideoCallIcon from "@mui/icons-material/VideoCall";
// import AudiotrackIcon from "@mui/icons-material/Audiotrack";
// import InputAdornment from "@mui/material/InputAdornment";
// import axios from "axios";
// import NotesLoading from "./NotesLoading";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { customSnackBar } from "../utils";
// import instance from "../utils/utils";
// // import { useSelector } from "react-redux";

// const ChatNotes = ({ flag, bookingData }) => {
//   const history = useHistory();
//   const [media, setMedia] = useState({
//     recordedVideoBlob: undefined,
//     recorderAudioBlob: undefined,
//     capturedImageBlob: undefined,
//     selectedVideo: null,
//     selectedImage: null,
//     selectedAudio: null,
//   });
//   const [permissions, setPermissions] = useState({
//     isAudioRecording: false,
//     isVideoRecording: false,
//     isCapturingImage: false,
//   });
//   const canvasRef = useRef(null);
//   const [messages, setMessages] = useState([]);
//   const [messageInput, setMessageInput] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   // const [showVideo, setShowVideo] = useState(false);
//   const [recordedBlob, setRecordedBlob] = useState(undefined);
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const [recordedCameraBlob, setRecordedCameraBlob] = useState(undefined);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [selectedAudio, setSelectedAudio] = useState(null);
//   const [selectedType, setSelectedType] = useState(null);
//   const [isRecordingCamera, setIsRecordingCamera] = useState(false);
//   const [isImageCapturing, setIsImageCapturing] = useState(false);
//   const [fetchedData, setFetchedData] = useState(false);
//   const [mediaStream, setMediaStream] = useState(null);
//   const [notes, setNotes] = useState([]);
//   const [showFileDialog, setShowFileDialog] = useState(false);
//   const [moreOptions, setMoreOptions] = useState(null);
//   const [loading, SetLoading] = useState(false);
//   const [salesOrderId, setSalesOrderId] = useState(0);
//   const [providerId, setProviderId] = useState(0);
//   // const currentUser = useSelector((state) => state.Auth.alluserdataafterlogin);
//   const recorderRef = useRef(null);
//   const videoRef = useRef(null);
//   const cameraRef = useRef(null);
//   const fileInputRef = useRef(null);
//   // const [senderAvatar] = useState("S");
//   // const [receiverAvatar] = useState("R");
//   // const messagesContainerRef = useRef(null);
//   useEffect(() => {
//     if (flag === "modal") {
//       setSalesOrderId(bookingData?.bookingId);
//       setProviderId(bookingData?.providerId);
//     } else {
//       const urlParams = new URLSearchParams(window.location?.search);
//       setSalesOrderId(urlParams.get("salesOrderId"));
//       setProviderId(urlParams.get("providerId"));
//     }
//   }, [bookingData, flag]);

//   const MediaDisplay = ({ file, type }) => {
//     if (type === "image") {
//       return (
//         <img
//           src={URL.createObjectURL(file)}
//           alt="Selected"
//           width="100"
//           height="100"
//         />
//       );
//     } else if (type === "video") {
//       return (
//         <video width={100} height={100} controls>
//           <source src={URL.createObjectURL(file)} type="video/mp4" />
//         </video>
//       );
//     } else if (type === "audio") {
//       return (
//         <audio controls>
//           <source src={URL.createObjectURL(file)} type="audio/mpeg" />
//         </audio>
//       );
//     } else {
//       return null;
//     }
//   };

//   const MediaBlobDisplay = ({ blob, type }) => {
//     var url = URL.createObjectURL(blob);
//     if (type === "image") {
//       return <img src={url} alt="Selected" width="100" height="100" />;
//     } else if (type === "audio") {
//       return (
//         <audio controls>
//           <source src={url} type="audio/mpeg" />
//         </audio>
//       );
//     } else if (type === "video") {
//       return (
//         <video width={100} height={100} controls>
//           <source src={url} type="video/mp4" />
//         </video>
//       );
//     } else {
//       return null;
//     }
//   };

//   const fetchNotesData = useCallback(async () => {
//     let url = `/notes_svc/pv/notes/?id=${
//       bookingData.bookingId
//     }&purchase_order_id=${
//       bookingData.purchaseOrderId === null ? 0 : bookingData.purchaseOrderId
//     }&type=booking&getting_by_type=provider&getting_by=${
//       bookingData.providerId
//     }&timezone=Europe/London`;
//     try {
//       setFetchedData(true);
//       const response = await instance.get(url);
//       const data = await response.data;
//       setNotes(data.result);
//     } catch (e) {
//     } finally {
//       setFetchedData(false);
//     }
//   }, [providerId]);

//   useEffect(() => {
//     fetchNotesData();
//   }, [fetchNotesData]);
//   const setAllPermissionsToFalse = {
//     isAudioRecording: false,
//     isCapturingImage: false,
//     isVideoRecording: false,
//   };

//   const setAllMediaToNull = {
//     recordedVideoBlob: undefined,
//     recorderAudioBlob: undefined,
//     capturedImageBlob: undefined,
//     selectedVideo: null,
//     selectedImage: null,
//     selectedAudio: null,
//   };

//   const handleSendMessage = async (e) => {
//     // const allowedTypes = ["image/png", "image/jpeg"];
//     e.preventDefault();
//     if (
//       !messageInput.trim() &&
//       !media.selectedVideo &&
//       !media.recorderAudioBlob &&
//       !media.selectedImage &&
//       !media.selectedAudio &&
//       !media.recordedVideoBlob &&
//       !media.capturedImageBlob
//     )
//       return;
//     // let newSenderMessage = {
//     //   sender: "user",
//     //   content: messageInput,
//     //   timestamp: Date.now(),
//     // };
//     SetLoading(true);
//     let fd = new FormData();
//     fd.append("created_by", parseInt(providerId));
//     fd.append("created_for", bookingData?.bookingId);
//     fd.append("created_by_type", 1);
//     fd.append("created_for_type", 4);
//     fd.append("is_shown", false);
//     fd.append("created_by_name", bookingData?.providerName);
//     fd.append("created_by_img_url", bookingData?.providerImage);
//     fd.append("is_expert0_plexaar1", true);
//     if (media.selectedImage !== null || media.capturedImageBlob !== undefined) {
//       fd.append("note_type", 2);
//       fd.append("text", "");
//       fd.append(
//         "image",
//         media.selectedImage !== null
//           ? media.selectedImage
//           : media.capturedImageBlob
//       );
//     } else if (
//       media.selectedAudio !== null ||
//       media.recorderAudioBlob !== undefined
//     ) {
//       fd.append("note_type", 3);
//       fd.append("text", "");
//       fd.append(
//         "audio",
//         media.selectedAudio !== null
//           ? media.selectedAudio
//           : media.recorderAudioBlob
//       );
//     } else if (
//       media.selectedVideo !== null ||
//       media.recordedVideoBlob !== undefined
//     ) {
//       fd.append("note_type", 4);
//       fd.append("text", "");
//       fd.append(
//         "video",
//         media.selectedVideo !== null
//           ? media.selectedVideo
//           : media.recordedVideoBlob
//       );
//     } else {
//       fd.append("note_type", 1);
//       fd.append("text", messageInput);
//     }
//     try {
//       await instance.post(
//         `/notes_svc/pv/notes/`,
//         fd,
//         media.selectedImage === null &&
//           media.selectedAudio === null &&
//           media.selectedVideo === null &&
//           media.capturedImageBlob === undefined &&
//           media.recordedVideoBlob === undefined &&
//           media.recorderAudioBlob === undefined
//           ? null
//           : {
//               headers: {
//                 "Content-Type": "multipart/form-data",
//               },
//             }
//       );
//       setMedia(setAllMediaToNull);
//       setMessageInput("");
//       setPermissions(setAllPermissionsToFalse);
//       fetchNotesData();
//     } catch (error) {
//       customSnackBar(error);
//     }
//     // setMessages((prevMessages) => [
//     //   ...prevMessages,
//     //   newSenderMessage,
//     // ]);
//     setSelectedType(null);
//     SetLoading(false);
//   };

//   const handleImageFile = (e) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedImage: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const handleAudioFile = (e) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedAudio: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const handleVideoFile = (e) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         selectedVideo: file,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const handleStartRecording = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const recorder = new MediaRecorder(stream);
//       const chunks = [];
//       recorder.ondataavailable = (e) => {
//         chunks.push(e.data);
//       };
//       recorder.onstop = () => {
//         const recordedBlob = new Blob(chunks, { type: "audio/webm" });
//         setRecordedBlob(recordedBlob);
//       };
//       recorder.start();
//       setPermissions((prevState) => ({
//         ...setAllPermissionsToFalse,
//         isAudioRecording: true,
//       }));
//       recorderRef.current = recorder;
//       setSelectedType("voice");
//       setMediaStream(stream); // Store the media stream in state
//     } catch (error) {
//       console.error("Failed to access microphone:", error);
//     }
//   };

//   const handleStopRecording = () => {
//     if (!recorderRef.current || !permissions.isAudioRecording) {
//       return;
//     }
//     recorderRef.current.stop();
//     const chunks = [];
//     recorderRef.current.ondataavailable = (e) => {
//       chunks.push(e.data);
//     };
//     setPermissions(setAllPermissionsToFalse);
//     recorderRef.current.onstop = () => {
//       const recordedBlob = new Blob(chunks, { type: "audio/webm" });
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         recorderAudioBlob: recordedBlob,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//       if (mediaStream) {
//         mediaStream.getTracks().forEach((track) => track.stop()); // Stop the media stream
//       }
//       setMediaStream(null);
//     };
//   };

//   const handleStartCameraForCapture = async () => {
//     try {
//       setPermissions((prevState) => ({
//         ...setAllPermissionsToFalse,
//         isCapturingImage: true,
//       }));
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//       });
//       if (cameraRef.current) {
//         cameraRef.current.srcObject = stream;
//       }
//     } catch (err) {
//       customSnackBar("Error Accessing Camers:", err);
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const captureImage = async () => {
//     if (canvasRef.current) {
//       const context = canvasRef.current.getContext("2d");
//       context.drawImage(cameraRef.current, 0, 0, 640, 480);
//       canvasRef.current.toBlob((blob) => {
//         if (blob) {
//           setMedia((prevMedia) => ({
//             ...setAllMediaToNull,
//             capturedImageBlob: blob,
//           }));
//         } else {
//           customSnackBar("Failed to create a blob from the canvas image.");
//         }
//       }, "image/jpeg");
//       setPermissions(setAllPermissionsToFalse);
//       if (cameraRef.current) {
//         const stream = cameraRef.current.srcObject;
//         if (stream) {
//           const tracks = stream.getTracks();
//           tracks.forEach((track) => track.stop());
//         }
//         cameraRef.current.srcObject = null;
//       }
//     } else {
//       customSnackBar("Canvas reference is null or undefined");
//     }
//   };

//   const handleStartCameraRecording = async () => {
//     try {
//       setPermissions((prevPermissions) => ({
//         ...setAllPermissionsToFalse,
//         isVideoRecording: true,
//       }));
//       const stream = await navigator.mediaDevices.getUserMedia({
//         audio: true,
//         video: true,
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         if (!videoRef.current.paused) {
//           videoRef.current.pause();
//         }
//         videoRef.current.play();

//         const recorder = new MediaRecorder(stream);
//         const chunks = [];
//         recorder.ondataavailable = (e) => {
//           chunks.push(e.data);
//         };
//         recorder.start();
//         recorderRef.current = recorder;
//         setSelectedType("camera");
//       } else {
//         customSnackBar("videoRef.current is null or undefined");
//         setPermissions(setAllPermissionsToFalse);
//       }
//     } catch (error) {
//       customSnackBar("Failed to access camera:", error);
//       setPermissions(setAllPermissionsToFalse);
//     }
//   };

//   const handleStopCameraRecording = () => {
//     if (!recorderRef.current || !permissions.isVideoRecording) return;

//     const chunks = [];
//     if (recorderRef.current.ondataavailable) {
//       recorderRef.current.ondataavailable = (e) => {
//         chunks.push(e.data);
//       };
//     }

//     recorderRef.current.onstop = () => {
//       const recordedBlob = new Blob(chunks, { type: "video/webm" });
//       setMedia((prevMedia) => ({
//         ...setAllMediaToNull,
//         recordedVideoBlob: recordedBlob,
//       }));
//       setPermissions(setAllPermissionsToFalse);
//       setShowFileDialog(false);
//     };
//     if (videoRef.current && videoRef.current.srcObject) {
//       const stream = videoRef.current.srcObject;
//       const tracks = stream.getTracks();
//       tracks.forEach((track) => track.stop());
//       videoRef.current.srcObject = null;
//     }
//     recorderRef.current.stop();
//   };

//   const handleOpenFileDialog = () => {
//     setShowFileDialog(true);
//   };

//   const handleCloseFileDialog = () => {
//     setShowFileDialog(false);
//   };

//   const handleEditNote = () => {
//     customSnackBar("We Are Working On It");
//   };

//   const handleDeleteNote = async (id) => {
//     try {
//       setFetchedData(true);
//       const response = await instance.delete(`/notes_svc/pv/notes/?id=${id}`);
//       // const data = await response.data;
//       // setNotes(data.result);
//       if (response.status === 200) {
//         fetchNotesData();
//       }
//     } catch (error) {
//       customSnackBar(error);
//     } finally {
//       setFetchedData(false);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         height:
//           media.selectedImage === null &&
//           media.selectedAudio === null &&
//           media.selectedVideo === null &&
//           media.recorderAudioBlob === undefined &&
//           media.recordedVideoBlob === undefined &&
//           media.capturedImageBlob === undefined
//             ? "77vh"
//             : "50vh",
//         overflowY: "auto",
//         marginBottom: 2,
//         display: "flex",
//         flexDirection: "column-reverse",
//         scrollbarWidth: "thin",
//         scrollbarColor: "transparent transparent",
//         "&::-webkit-scrollbar": {
//           width: "1px",
//         },
//         "&::-webkit-scrollbar-thumb": {
//           backgroundColor: "#fff",
//         },
//       }}
//     >
//       <Box
//         sx={{
//           height:
//             media.selectedImage === null &&
//             media.selectedAudio === null &&
//             media.selectedVideo === null &&
//             media.recorderAudioBlob === undefined &&
//             media.recordedVideoBlob === undefined &&
//             media.capturedImageBlob === undefined
//               ? "77vh"
//               : "50vh",
//           overflowY: "auto",
//           marginBottom: 2,
//           display: "flex",
//           flexDirection: "column-reverse",
//           scrollbarWidth: "thin",
//           scrollbarColor: "transparent transparent",
//           "&::-webkit-scrollbar": {
//             width: "1px",
//           },
//           "&::-webkit-scrollbar-thumb": {
//             backgroundColor: "#fff",
//           },
//         }}
//       >
//         <div className="my-2 d-flex align-items-center">
//           {media.selectedImage && (
//             <MediaDisplay file={media.selectedImage} type="image" />
//           )}
//           {media.selectedAudio && (
//             <MediaDisplay file={media.selectedAudio} type="audio" />
//           )}
//           {media.selectedVideo && (
//             <MediaDisplay file={media.selectedVideo} type="video" />
//           )}
//           {/* {
//           media.capturedImageBlob && <MediaBlobDisplay file={media.capturedImageBlob} type="image" />
//         }
//         {
//           media.recorderAudioBlob && <MediaBlobDisplay file={media.recorderAudioBlob} type="audio" />
//         }
//         {
//           media.recordedVideoBlob && <MediaBlobDisplay file={media.recordedVideoBlob} type="video" />
//         } */}
//         </div>
//         {permissions.isVideoRecording ? (
//           <video ref={videoRef} style={{ display: "block" }} height="350" />
//         ) : permissions.isCapturingImage ? (
//           <div>
//             <video
//               ref={cameraRef}
//               style={{ display: "block" }}
//               height="350"
//               autoPlay
//             />
//             <canvas
//               ref={canvasRef}
//               height="350"
//               style={{ display: "none" }}
//             ></canvas>
//           </div>
//         ) : (
//           <List>
//             {fetchedData ? (
//               <NotesLoading />
//             ) : notes.length === 0 ? (
//               <>No Notes Found</>
//             ) : (
//               notes.map((item, index) => {
//                 return (
//                   <ListItem
//                     key={index}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row-reverse",
//                       justifyContent:
//                         item.created_by === providerId
//                           ? "flex-start"
//                           : "flex-end",
//                       // textAlign: item.created_by === providerId ? "right": "start",
//                     }}
//                   >
//                     <Box>
//                       <Box
//                         component="div"
//                         sx={{
//                           backgroundColor:
//                             item.created_by === providerId ? "#FFD705" : "red",
//                           padding: "8px",
//                           borderRadius: "6px",
//                           width: "fit-content",
//                           color:
//                             item.created_by === providerId ? "black" : "white",
//                         }}
//                       >
//                         {item.notesContent.audio_url && (
//                           <audio controls controlsList="nodownload">
//                             <source
//                               src={item.notesContent.audio_url}
//                               type="audio/webm"
//                             />
//                           </audio>
//                         )}

//                         {item.notesContent.video_url && (
//                           <video
//                             controls
//                             style={{
//                               width: "100%",
//                               height: "auto",
//                               maxWidth: "300px",
//                             }}
//                           >
//                             <source
//                               src={item.notesContent.video_url}
//                               type="video/mp4"
//                             />
//                           </video>
//                         )}
//                         {item.notesContent.image_url && (
//                           <img
//                             src={item.notesContent.image_url}
//                             alt="Uploaded"
//                             style={{
//                               maxWidth: "300px",
//                               maxHeight: "200px",
//                             }}
//                           />
//                         )}

//                         {item.notesContent.text_data && (
//                           <div className="d-flex align-items-center">
//                             {item.created_by !== providerId && (
//                               <Avatar
//                                 src={item.created_by_img_url}
//                                 sx={{
//                                   width: 30,
//                                   height: 30,
//                                   marginRight: "10px",
//                                 }}
//                               />
//                             )}
//                             {item.created_by === providerId && (
//                               <div
//                                 className="me-2 dropown"
//                                 style={{
//                                   position: "relative",
//                                 }}
//                                 role="button"
//                                 onClick={() =>
//                                   setMoreOptions(moreOptions ? null : item.id)
//                                 }
//                               >
//                                 <svg
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   height="16"
//                                   viewBox="0 -960 960 960"
//                                   width="16"
//                                 >
//                                   <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
//                                 </svg>
//                                 {moreOptions === item.id && (
//                                   <div
//                                     className=" d-flex flex-column px-2 py-1"
//                                     style={{
//                                       position: "absolute",
//                                       top: "-100%",
//                                       right:
//                                         item.created_by !== providerId
//                                           ? ""
//                                           : "36px",
//                                       left:
//                                         item.created_by !== providerId
//                                           ? "36px"
//                                           : "",
//                                       borderRadius: "10px",
//                                       border: "0.5px solid gray",
//                                       backgroundColor: "white",
//                                       zIndex: "1000000000",
//                                     }}
//                                   >
//                                     <button
//                                       className="text-primary px-2 py-1 "
//                                       style={{
//                                         backgroundColor: "transparent",
//                                       }}
//                                       onClick={handleEditNote}
//                                     >
//                                       Edit
//                                     </button>
//                                     <button
//                                       className="text-danger px-2 py-1"
//                                       style={{
//                                         backgroundColor: "transparent",
//                                       }}
//                                       onClick={() => handleDeleteNote(item.id)}
//                                     >
//                                       Delete
//                                     </button>
//                                   </div>
//                                 )}
//                               </div>
//                             )}
//                             <ListItemText>
//                               <div
//                                 style={{
//                                   fontStyle: "normal",
//                                   fontWeight: 500,
//                                   fontSize: "10px",
//                                 }}
//                               >
//                                 {item.created_by_name}
//                               </div>
//                               <Typography
//                                 sx={{
//                                   fontFamily: "Roboto",
//                                   fontStyle: "normal",
//                                   fontWeight: 500,
//                                   fontSize: "12px",
//                                   lineHeight: "16px",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "space-between",
//                                 }}
//                               >
//                                 {item.notesContent.text_data}
//                                 {item.created_by !== providerId && (
//                                   <div
//                                     className="ms-2 dropown"
//                                     style={{
//                                       position: "relative",
//                                     }}
//                                     role="button"
//                                     onClick={() =>
//                                       setMoreOptions(
//                                         moreOptions ? null : item.id
//                                       )
//                                     }
//                                   >
//                                     <svg
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       height="16"
//                                       viewBox="0 -960 960 960"
//                                       width="16"
//                                     >
//                                       <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
//                                     </svg>
//                                     {moreOptions === item.id && (
//                                       <div
//                                         className=" d-flex flex-column px-2 py-1"
//                                         style={{
//                                           position: "absolute",
//                                           top: "-100%",
//                                           right:
//                                             item.created_by !== providerId
//                                               ? ""
//                                               : "36px",
//                                           left:
//                                             item.created_by !== providerId
//                                               ? "36px"
//                                               : "",
//                                           borderRadius: "10px",
//                                           border: "0.5px solid gray",
//                                           backgroundColor: "white",
//                                           zIndex: "1000000000",
//                                         }}
//                                       >
//                                         <button
//                                           className="text-primary px-2 py-1 "
//                                           style={{
//                                             backgroundColor: "transparent",
//                                           }}
//                                           onClick={handleEditNote}
//                                         >
//                                           Edit
//                                         </button>
//                                         <button
//                                           className="text-danger px-2 py-1"
//                                           style={{
//                                             backgroundColor: "transparent",
//                                           }}
//                                           onClick={() =>
//                                             handleDeleteNote(item.id)
//                                           }
//                                         >
//                                           Delete
//                                         </button>
//                                       </div>
//                                     )}
//                                   </div>
//                                 )}
//                               </Typography>
//                             </ListItemText>
//                             {item.created_by === providerId && (
//                               <Avatar
//                                 src={item.created_by_img_url}
//                                 sx={{
//                                   width: 30,
//                                   height: 30,
//                                   marginLeft: "10px",
//                                 }}
//                               />
//                             )}
//                           </div>
//                         )}
//                       </Box>
//                     </Box>
//                   </ListItem>
//                 );
//               })
//             )}
//           </List>
//         )}
//       </Box>
//       <form onSubmit={handleSendMessage}>
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <TextField
//             label="Message"
//             fullWidth
//             size="small"
//             value={messageInput}
//             onChange={(e) => setMessageInput(e.target.value)}
//             sx={{
//               marginRight: 1,
//               backgroundColor: "white",
//               borderRadius: "6px",
//               border: "1px solid #548DFF",
//             }}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton
//                     color="primary"
//                     component="span"
//                     aria-label="Attach File"
//                     onClick={handleOpenFileDialog}
//                     sx={{
//                       border: "1px solid #548DFF",
//                     }}
//                   >
//                     <AttachFileIcon />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//           <Dialog open={showFileDialog} onClose={handleCloseFileDialog}>
//             <DialogTitle>Select Media</DialogTitle>
//             <DialogContent>
//               <Box sx={{ display: "flex", justifyContent: "center" }}>
//                 <label htmlFor="file-input">
//                   <input
//                     id="file-input"
//                     type="file"
//                     accept="video/*"
//                     onChange={handleVideoFile}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   <IconButton
//                     color="primary"
//                     component="span"
//                     aria-label="Select Video"
//                   >
//                     <VideoCallIcon
//                       sx={{
//                         fontSize: "40px",
//                       }}
//                     />
//                     <Typography>Select Video</Typography>
//                   </IconButton>
//                 </label>
//                 <label htmlFor="image-input">
//                   <input
//                     id="image-input"
//                     type="file"
//                     accept="image/*,.gif"
//                     onChange={handleImageFile}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   <IconButton
//                     color="primary"
//                     component="span"
//                     aria-label="Select Image"
//                   >
//                     <ImageIcon
//                       sx={{
//                         fontSize: "40px",
//                       }}
//                     />
//                     <Typography>Select Image</Typography>
//                   </IconButton>
//                 </label>
//                 <label htmlFor="audio-input">
//                   <input
//                     id="audio-input"
//                     type="file"
//                     accept="audio/*"
//                     onChange={handleAudioFile}
//                     style={{ display: "none" }}
//                     ref={fileInputRef}
//                   />
//                   <IconButton
//                     color="primary"
//                     component="span"
//                     aria-label="Select Audio"
//                   >
//                     <AudiotrackIcon
//                       sx={{
//                         fontSize: "40px",
//                       }}
//                     />
//                     <Typography>Select Audio</Typography>
//                   </IconButton>
//                 </label>
//               </Box>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={handleCloseFileDialog}>Cancel</Button>
//             </DialogActions>
//           </Dialog>

//           <IconButton
//             className="bg-light me-1"
//             color={permissions.isAudioRecording ? "secondary" : "primary"}
//             onClick={
//               permissions.isAudioRecording
//                 ? handleStopRecording
//                 : handleStartRecording
//             }
//             aria-label={
//               permissions.isAudioRecording
//                 ? "Stop Recording"
//                 : "Start Recording"
//             }
//             sx={{
//               border: "1px solid #548DFF",
//             }}
//           >
//             <MicIcon />
//           </IconButton>
//           <IconButton
//             color={permissions.isVideoRecording ? "secondary" : "primary"}
//             onClick={
//               permissions.isVideoRecording
//                 ? handleStopCameraRecording
//                 : handleStartCameraRecording
//             }
//             aria-label={
//               permissions.isVideoRecording ? "Stop Camera" : "Start Camera"
//             }
//             sx={{
//               border: "1px solid #548DFF",
//             }}
//           >
//             {permissions.isVideoRecording ? <StopIcon /> : <VideoCallIcon />}
//           </IconButton>
//           <IconButton
//             className="mx-1"
//             color={permissions.isCapturingImage ? "secondary" : "primary"}
//             onClick={
//               permissions.isCapturingImage
//                 ? captureImage
//                 : handleStartCameraForCapture
//             }
//             aria-label={
//               permissions.isCapturingImage ? "Stop Camera" : "Start Camera"
//             }
//             sx={{
//               border: "1px solid #548DFF",
//             }}
//           >
//             {permissions.isCapturingImage ? <ImageIcon /> : <PhotoCameraIcon />}
//           </IconButton>
//           {loading ? (
//             <CircularProgress
//               size={30}
//               sx={{
//                 color: "white",
//               }}
//             />
//           ) : (
//             <IconButton
//               type="submit"
//               className="bg-light"
//               color="primary"
//               aria-label="Send Message"
//               disabled={
//                 !messageInput &&
//                 !media.selectedVideo &&
//                 !media.recorderAudioBlob &&
//                 !media.selectedImage &&
//                 !media.selectedAudio &&
//                 !media.recordedVideoBlob &&
//                 !media.capturedImageBlob
//               }
//               sx={{
//                 border: "1px solid #548DFF",
//               }}
//             >
//               <SendIcon />
//             </IconButton>
//           )}
//         </Box>
//       </form>
//       {flag === "modal" ? null : (
//         <Button
//           sx={{ margin: "10px" }}
//           variant="contained"
//           onClick={(e) => {
//             history.push("/business_calendar");
//           }}
//         >
//           {" "}
//           Continue
//         </Button>
//       )}
//     </Box>
//   );
// };

// export default ChatNotes;
import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  Avatar,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StopIcon from "@mui/icons-material/Stop";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import NotesLoading from "./NotesLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customSnackBar } from "../utils";
import instance from "../utils/utils";
// import { useSelector } from "react-redux";

const ChatNotes = ({ flag, bookingData }) => {
  const history = useHistory();
  const [media, setMedia] = useState({
    recordedVideoBlob: undefined,
    recorderAudioBlob: undefined,
    capturedImageBlob: undefined,
    selectedVideo: null,
    selectedImage: null,
    selectedAudio: null,
  });
  const [permissions, setPermissions] = useState({
    isAudioRecording: false,
    isVideoRecording: false,
    isCapturingImage: false,
  });
  const canvasRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  // const [showVideo, setShowVideo] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(undefined);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [recordedCameraBlob, setRecordedCameraBlob] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isRecordingCamera, setIsRecordingCamera] = useState(false);
  const [isImageCapturing, setIsImageCapturing] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [notes, setNotes] = useState([]);
  const [showFileDialog, setShowFileDialog] = useState(false);
  const [moreOptions, setMoreOptions] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  // const currentUser = useSelector((state) => state.Auth.alluserdataafterlogin);
  const recorderRef = useRef(null);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);
  const fileInputRef = useRef(null);
  // const [senderAvatar] = useState("S");
  // const [receiverAvatar] = useState("R");
  // const messagesContainerRef = useRef(null);
  useEffect(() => {
    if (flag === "modal") {
      setSalesOrderId(bookingData?.bookingId);
      setProviderId(bookingData?.providerId);
    } else {
      const urlParams = new URLSearchParams(window.location?.search);
      setSalesOrderId(urlParams.get("salesOrderId"));
      setProviderId(urlParams.get("providerId"));
    }
  }, [bookingData, flag]);

  const MediaDisplay = ({ file, type }) => {
    if (type === "image") {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Selected"
          width="100"
          height="100"
        />
      );
    } else if (type === "video") {
      return (
        <video width={100} height={100} controls>
          <source src={URL.createObjectURL(file)} type="video/mp4" />
        </video>
      );
    } else if (type === "audio") {
      return (
        <audio controls>
          <source src={URL.createObjectURL(file)} type="audio/mpeg" />
        </audio>
      );
    } else {
      return null;
    }
  };

  const MediaBlobDisplay = ({ blob, type }) => {
    var url = URL.createObjectURL(blob);
    if (type === "image") {
      return <img src={url} alt="Selected" width="100" height="100" />;
    } else if (type === "audio") {
      return (
        <audio controls>
          <source src={url} type="audio/mpeg" />
        </audio>
      );
    } else if (type === "video") {
      return (
        <video width={100} height={100} controls>
          <source src={url} type="video/mp4" />
        </video>
      );
    } else {
      return null;
    }
  };

  const fetchNotesData = useCallback(async () => {
    let url = `/notes_svc/pv/notes/?id=${
      bookingData.bookingId
    }&purchase_order_id=${
      bookingData.purchaseOrderId === null ? 0 : bookingData.purchaseOrderId
    }&type=booking&getting_by_type=provider&getting_by=${
      bookingData.providerId
    }&timezone=Europe/London`;
    try {
      setFetchedData(true);
      const response = await instance.get(url);
      const data = await response.data;
      setNotes(data.result);
    } catch (e) {
    } finally {
      setFetchedData(false);
    }
  }, [providerId]);

  useEffect(() => {
    fetchNotesData();
  }, [fetchNotesData]);
  const setAllPermissionsToFalse = {
    isAudioRecording: false,
    isCapturingImage: false,
    isVideoRecording: false,
  };

  const setAllMediaToNull = {
    recordedVideoBlob: undefined,
    recorderAudioBlob: undefined,
    capturedImageBlob: undefined,
    selectedVideo: null,
    selectedImage: null,
    selectedAudio: null,
  };

  const handleSendMessage = async (e) => {
    // const allowedTypes = ["image/png", "image/jpeg"];
    e.preventDefault();
    if (
      !messageInput.trim() &&
      !media.selectedVideo &&
      !media.recorderAudioBlob &&
      !media.selectedImage &&
      !media.selectedAudio &&
      !media.recordedVideoBlob &&
      !media.capturedImageBlob
    )
      return;
    // let newSenderMessage = {
    //   sender: "user",
    //   content: messageInput,
    //   timestamp: Date.now(),
    // };
    SetLoading(true);
    let fd = new FormData();
    fd.append("created_by", parseInt(providerId));
    fd.append("created_for", bookingData?.bookingId);
    fd.append("created_by_type", 1);
    fd.append("created_for_type", 4);
    fd.append("is_shown", false);
    fd.append("created_by_name", bookingData?.providerName);
    fd.append("created_by_img_url", bookingData?.providerImage);
    fd.append("is_expert0_plexaar1", true);
    if (media.selectedImage !== null || media.capturedImageBlob !== undefined) {
      fd.append("note_type", 2);
      fd.append("text", "");
      fd.append(
        "image",
        media.selectedImage !== null
          ? media.selectedImage
          : media.capturedImageBlob
      );
    } else if (
      media.selectedAudio !== null ||
      media.recorderAudioBlob !== undefined
    ) {
      fd.append("note_type", 3);
      fd.append("text", "");
      fd.append(
        "audio",
        media.selectedAudio !== null
          ? media.selectedAudio
          : media.recorderAudioBlob
      );
    } else if (
      media.selectedVideo !== null ||
      media.recordedVideoBlob !== undefined
    ) {
      fd.append("note_type", 4);
      fd.append("text", "");
      fd.append(
        "video",
        media.selectedVideo !== null
          ? media.selectedVideo
          : media.recordedVideoBlob
      );
    } else {
      fd.append("note_type", 1);
      fd.append("text", messageInput);
    }
    try {
      await instance.post(
        `/notes_svc/pv/notes/`,
        fd,
        media.selectedImage === null &&
          media.selectedAudio === null &&
          media.selectedVideo === null &&
          media.capturedImageBlob === undefined &&
          media.recordedVideoBlob === undefined &&
          media.recorderAudioBlob === undefined
          ? null
          : {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
      );
      setMedia(setAllMediaToNull);
      setMessageInput("");
      setPermissions(setAllPermissionsToFalse);
      fetchNotesData();
    } catch (error) {
      customSnackBar(error);
    }
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   newSenderMessage,
    // ]);
    setSelectedType(null);
    SetLoading(false);
  };

  const handleImageFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedImage: file,
      }));
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const handleAudioFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedAudio: file,
      }));
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const handleVideoFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        selectedVideo: file,
      }));
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = [];
      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };
      recorder.onstop = () => {
        const recordedBlob = new Blob(chunks, { type: "audio/webm" });
        setRecordedBlob(recordedBlob);
      };
      recorder.start();
      setPermissions((prevState) => ({
        ...setAllPermissionsToFalse,
        isAudioRecording: true,
      }));
      recorderRef.current = recorder;
      setSelectedType("voice");
      setMediaStream(stream); // Store the media stream in state
    } catch (error) {
      console.error("Failed to access microphone:", error);
    }
  };

  const handleStopRecording = () => {
    if (!recorderRef.current || !permissions.isAudioRecording) {
      return;
    }
    recorderRef.current.stop();
    const chunks = [];
    recorderRef.current.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    setPermissions(setAllPermissionsToFalse);
    recorderRef.current.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: "audio/webm" });
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        recorderAudioBlob: recordedBlob,
      }));
      setPermissions(setAllPermissionsToFalse);
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop()); // Stop the media stream
      }
      setMediaStream(null);
    };
  };

  const handleStartCameraForCapture = async () => {
    try {
      setPermissions((prevState) => ({
        ...setAllPermissionsToFalse,
        isCapturingImage: true,
      }));
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (cameraRef.current) {
        cameraRef.current.srcObject = stream;
      }
    } catch (err) {
      customSnackBar("Error Accessing Camers:", err);
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const captureImage = async () => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(cameraRef.current, 0, 0, 640, 480);
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          setMedia((prevMedia) => ({
            ...setAllMediaToNull,
            capturedImageBlob: blob,
          }));
        } else {
          customSnackBar("Failed to create a blob from the canvas image.");
        }
      }, "image/jpeg");
      setPermissions(setAllPermissionsToFalse);
      if (cameraRef.current) {
        const stream = cameraRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        cameraRef.current.srcObject = null;
      }
    } else {
      customSnackBar("Canvas reference is null or undefined");
    }
  };

  const handleStartCameraRecording = async () => {
    try {
      setPermissions((prevPermissions) => ({
        ...setAllPermissionsToFalse,
        isVideoRecording: true,
      }));
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        if (!videoRef.current.paused) {
          videoRef.current.pause();
        }
        videoRef.current.play();

        const recorder = new MediaRecorder(stream);
        const chunks = [];
        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };
        recorder.start();
        recorderRef.current = recorder;
        setSelectedType("camera");
      } else {
        customSnackBar("videoRef.current is null or undefined");
        setPermissions(setAllPermissionsToFalse);
      }
    } catch (error) {
      customSnackBar("Failed to access camera:", error);
      setPermissions(setAllPermissionsToFalse);
    }
  };

  const handleStopCameraRecording = () => {
    if (!recorderRef.current || !permissions.isVideoRecording) return;

    const chunks = [];
    if (recorderRef.current.ondataavailable) {
      recorderRef.current.ondataavailable = (e) => {
        chunks.push(e.data);
      };
    }

    recorderRef.current.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: "video/webm" });
      setMedia((prevMedia) => ({
        ...setAllMediaToNull,
        recordedVideoBlob: recordedBlob,
      }));
      setPermissions(setAllPermissionsToFalse);
      setShowFileDialog(false);
    };
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    recorderRef.current.stop();
  };

  const handleOpenFileDialog = () => {
    setShowFileDialog(true);
  };

  const handleCloseFileDialog = () => {
    setShowFileDialog(false);
  };

  const handleEditNote = () => {
    customSnackBar("We Are Working On It");
  };

  const handleDeleteNote = async (id) => {
    try {
      setFetchedData(true);
      const response = await instance.delete(`/notes_svc/pv/notes/?id=${id}`);
      // const data = await response.data;
      // setNotes(data.result);
      if (response.status === 200) {
        fetchNotesData();
      }
    } catch (error) {
      customSnackBar(error);
    } finally {
      setFetchedData(false);
    }
  };

  return (
    <Box
      sx={{
        height:
          media.selectedImage === null &&
          media.selectedAudio === null &&
          media.selectedVideo === null &&
          media.recorderAudioBlob === undefined &&
          media.recordedVideoBlob === undefined &&
          media.capturedImageBlob === undefined
            ? "77vh"
            : "50vh",
        overflowY: "auto",
        marginBottom: 2,
        display: "flex",
        flexDirection: "column-reverse",
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        "&::-webkit-scrollbar": {
          width: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#fff",
        },
      }}
    >
      <Box
        sx={{
          height:
            media.selectedImage === null &&
            media.selectedAudio === null &&
            media.selectedVideo === null &&
            media.recorderAudioBlob === undefined &&
            media.recordedVideoBlob === undefined &&
            media.capturedImageBlob === undefined
              ? "77vh"
              : "50vh",
          overflowY: "auto",
          marginBottom: 2,
          display: "flex",
          flexDirection: "column-reverse",
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#fff",
          },
        }}
      >
        <div className="my-2 d-flex align-items-center">
          {media.selectedImage && (
            <MediaDisplay file={media.selectedImage} type="image" />
          )}
          {media.selectedAudio && (
            <MediaDisplay file={media.selectedAudio} type="audio" />
          )}
          {media.selectedVideo && (
            <MediaDisplay file={media.selectedVideo} type="video" />
          )}
          {/* {
          media.capturedImageBlob && <MediaBlobDisplay file={media.capturedImageBlob} type="image" />
        }
        {
          media.recorderAudioBlob && <MediaBlobDisplay file={media.recorderAudioBlob} type="audio" />
        }
        {
          media.recordedVideoBlob && <MediaBlobDisplay file={media.recordedVideoBlob} type="video" />
        } */}
        </div>
        {permissions.isVideoRecording ? (
          <video ref={videoRef} style={{ display: "block" }} height="350" />
        ) : permissions.isCapturingImage ? (
          <div>
            <video
              ref={cameraRef}
              style={{ display: "block" }}
              height="350"
              autoPlay
            />
            <canvas
              ref={canvasRef}
              height="350"
              style={{ display: "none" }}
            ></canvas>
          </div>
        ) : (
          <List>
            {fetchedData ? (
              <NotesLoading />
            ) : notes.length === 0 ? (
              <>No Notes Found</>
            ) : (
              notes.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent:
                        item.created_by === providerId
                          ? "flex-start"
                          : "flex-end",
                      // textAlign: item.created_by === providerId ? "right": "start",
                    }}
                  >
                    <Box>
                      <Box
                        component="div"
                        sx={{
                          backgroundColor:
                            item.created_by === providerId ? "#FFD705" : "red",
                          padding: "8px",
                          borderRadius: "6px",
                          width: "fit-content",
                          color:
                            item.created_by === providerId ? "black" : "white",
                        }}
                      >
                        {item.notesContent.audio_url && (
                          <audio controls controlsList="nodownload">
                            <source
                              src={item.notesContent.audio_url}
                              type="audio/webm"
                            />
                          </audio>
                        )}

                        {item.notesContent.video_url && (
                          <video
                            controls
                            style={{
                              width: "100%",
                              height: "auto",
                              maxWidth: "300px",
                            }}
                          >
                            <source
                              src={item.notesContent.video_url}
                              type="video/mp4"
                            />
                          </video>
                        )}
                        {item.notesContent.image_url && (
                          <img
                            src={item.notesContent.image_url}
                            alt="Uploaded"
                            style={{
                              maxWidth: "300px",
                              maxHeight: "200px",
                            }}
                          />
                        )}

                        {item.notesContent.text_data && (
                          <div className="d-flex align-items-center">
                            {item.created_by !== providerId && (
                              <Avatar
                                src={item.created_by_img_url}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            {item.created_by === providerId && (
                              <div
                                className="me-2 dropown"
                                style={{
                                  position: "relative",
                                }}
                                role="button"
                                onClick={() =>
                                  setMoreOptions(moreOptions ? null : item.id)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="16"
                                  viewBox="0 -960 960 960"
                                  width="16"
                                >
                                  <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                                </svg>
                                {moreOptions === item.id && (
                                  <div
                                    className=" d-flex flex-column px-2 py-1"
                                    style={{
                                      position: "absolute",
                                      top: "-100%",
                                      right:
                                        item.created_by !== providerId
                                          ? ""
                                          : "36px",
                                      left:
                                        item.created_by !== providerId
                                          ? "36px"
                                          : "",
                                      borderRadius: "10px",
                                      border: "0.5px solid gray",
                                      backgroundColor: "white",
                                      zIndex: "1000000000",
                                    }}
                                  >
                                    <button
                                      className="text-primary px-2 py-1 "
                                      style={{
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={handleEditNote}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="text-danger px-2 py-1"
                                      style={{
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => handleDeleteNote(item.id)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            <ListItemText>
                              <div
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                }}
                              >
                                {item.created_by_name}
                              </div>
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item.notesContent.text_data}
                                {item.created_by !== providerId && (
                                  <div
                                    className="ms-2 dropown"
                                    style={{
                                      position: "relative",
                                    }}
                                    role="button"
                                    onClick={() =>
                                      setMoreOptions(
                                        moreOptions ? null : item.id
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="16"
                                      viewBox="0 -960 960 960"
                                      width="16"
                                    >
                                      <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                                    </svg>
                                    {moreOptions === item.id && (
                                      <div
                                        className=" d-flex flex-column px-2 py-1"
                                        style={{
                                          position: "absolute",
                                          top: "-100%",
                                          right:
                                            item.created_by !== providerId
                                              ? ""
                                              : "36px",
                                          left:
                                            item.created_by !== providerId
                                              ? "36px"
                                              : "",
                                          borderRadius: "10px",
                                          border: "0.5px solid gray",
                                          backgroundColor: "white",
                                          zIndex: "1000000000",
                                        }}
                                      >
                                        <button
                                          className="text-primary px-2 py-1 "
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={handleEditNote}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="text-danger px-2 py-1"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() =>
                                            handleDeleteNote(item.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Typography>
                            </ListItemText>
                            {item.created_by === providerId && (
                              <Avatar
                                src={item.created_by_img_url}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginLeft: "10px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                );
              })
            )}
          </List>
        )}
      </Box>
      <form onSubmit={handleSendMessage}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Message"
            fullWidth
            size="small"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            sx={{
              marginRight: 1,
              backgroundColor: "white",
              borderRadius: "6px",
              border: "1px solid #548DFF",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label="Attach File"
                    onClick={handleOpenFileDialog}
                    sx={{
                      border: "1px solid #548DFF",
                    }}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Dialog open={showFileDialog} onClose={handleCloseFileDialog}>
            <DialogTitle>Select Media</DialogTitle>
            <DialogContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <label htmlFor="file-input">
                  <input
                    id="file-input"
                    type="file"
                    accept="video/*"
                    onChange={handleVideoFile}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label="Select Video"
                  >
                    <VideoCallIcon
                      sx={{
                        fontSize: "40px",
                      }}
                    />
                    <Typography>Select Video</Typography>
                  </IconButton>
                </label>
                <label htmlFor="image-input">
                  <input
                    id="image-input"
                    type="file"
                    accept="image/*,.gif"
                    onChange={handleImageFile}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label="Select Image"
                  >
                    <ImageIcon
                      sx={{
                        fontSize: "40px",
                      }}
                    />
                    <Typography>Select Image</Typography>
                  </IconButton>
                </label>
                <label htmlFor="audio-input">
                  <input
                    id="audio-input"
                    type="file"
                    accept="audio/*"
                    onChange={handleAudioFile}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label="Select Audio"
                  >
                    <AudiotrackIcon
                      sx={{
                        fontSize: "40px",
                      }}
                    />
                    <Typography>Select Audio</Typography>
                  </IconButton>
                </label>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFileDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <IconButton
            className="bg-light me-1"
            color={permissions.isAudioRecording ? "secondary" : "primary"}
            onClick={
              permissions.isAudioRecording
                ? handleStopRecording
                : handleStartRecording
            }
            aria-label={
              permissions.isAudioRecording
                ? "Stop Recording"
                : "Start Recording"
            }
            sx={{
              border: "1px solid #548DFF",
            }}
          >
            <MicIcon />
          </IconButton>
          <IconButton
            color={permissions.isVideoRecording ? "secondary" : "primary"}
            onClick={
              permissions.isVideoRecording
                ? handleStopCameraRecording
                : handleStartCameraRecording
            }
            aria-label={
              permissions.isVideoRecording ? "Stop Camera" : "Start Camera"
            }
            sx={{
              border: "1px solid #548DFF",
            }}
          >
            {permissions.isVideoRecording ? <StopIcon /> : <VideoCallIcon />}
          </IconButton>
          <IconButton
            className="mx-1"
            color={permissions.isCapturingImage ? "secondary" : "primary"}
            onClick={
              permissions.isCapturingImage
                ? captureImage
                : handleStartCameraForCapture
            }
            aria-label={
              permissions.isCapturingImage ? "Stop Camera" : "Start Camera"
            }
            sx={{
              border: "1px solid #548DFF",
            }}
          >
            {permissions.isCapturingImage ? <ImageIcon /> : <PhotoCameraIcon />}
          </IconButton>
          {loading ? (
            <CircularProgress
              size={30}
              sx={{
                color: "white",
              }}
            />
          ) : (
            <IconButton
              type="submit"
              className="bg-light"
              color="primary"
              aria-label="Send Message"
              disabled={
                !messageInput &&
                !media.selectedVideo &&
                !media.recorderAudioBlob &&
                !media.selectedImage &&
                !media.selectedAudio &&
                !media.recordedVideoBlob &&
                !media.capturedImageBlob
              }
              sx={{
                border: "1px solid #548DFF",
              }}
            >
              <SendIcon />
            </IconButton>
          )}
        </Box>
      </form>
      {flag === "modal" ? null : (
        <Button
          sx={{ margin: "10px" }}
          variant="contained"
          onClick={(e) => {
            history.push("/business_calendar");
          }}
        >
          {" "}
          Continue
        </Button>
      )}
    </Box>
  );
};

export default ChatNotes;
