import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { BsLink45Deg } from "react-icons/bs";
import { deleteRequest, putRequest } from "../../../request";
import { FaRegEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getBusinessServices,
} from "../../../store/servicesActions";
import { base_url } from "../../../base_url";
import { MiniLoader } from "../../ServiceLoader";
import { miniLoadingAction } from "../../../store/ServicesSlice";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { MenuItem, Select } from "@mui/material";
import { fetchBusinessRequest } from "../../../../../Store/Business/actions";

const BusinessServicesTableList = ({ businessId, source }) => {
  const [columns, setColumns] = useState([
    { id: 1, name: "Service Name" },
    { id: 2, name: "ID" },
    { id: 3, name: "Service URL" },
    { id: 4, name: "Service Type" },
    { id: 5, name: "Active" },
  ]);

  const dropdown_columns = [
    { id: 7, name: "Column 1", width: "100px" },
    { id: 8, name: "Column 2", width: "100px" },
    { id: 9, name: "Column 3", width: "100px" },
    { id: 10, name: "Column 4", width: "100px" },
  ];
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnId, setColumnId] = useState("");
  const [isDraggingRow, setIsDraggingRow] = useState(false);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);

  const [statusId, setStatusId] = useState([]);

  const [rows, setRows] = useState([
    {
      id: 1,
      data: ["", "", "", "", ""],
    },
  ]);

  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.servicesReducer);
  const all_business = useSelector((state) => state.Business?.data);

  const addColumn = (val) => {
    const newColumn = {
      id: val?.id,
      name: val?.name,
    };
    setColumns([...columns, newColumn]);

    rows.forEach((row) => {
      row.data.push({
        id: val?.id,
        name: "",
      });
    });
    setRows([...rows]);
  };

  const handleRowDragStart = (e, rowIndex) => {
    e.dataTransfer.setData("text/plain", rowIndex);
    setDraggedRowIndex(rowIndex);
  };

  const handleRowDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowDrop = (e, targetRowIndex) => {
    const sourceRowIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
    updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
    setRows(updatedRows);
    setIsDraggingRow(false);
    setDraggedRowIndex(null);
  };

  const handleColumnDragStart = (e, columnIndex) => {
    e.dataTransfer.setData("text/plain", columnIndex);
  };

  const handleColumnDragOver = (e) => {
    e.preventDefault();
  };

  const handleColumnDrop = (e, targetColumnIndex) => {
    const sourceColumnIndex = parseInt(e.dataTransfer.getData("text/plain"));

    const updatedColumns = [...columns];
    const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
    updatedColumns.splice(targetColumnIndex, 0, movedColumn);

    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = [...row.data];
      const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
      updatedData.splice(targetColumnIndex, 0, movedData);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  const handleRowCheckboxChange = (e, rowIndex) => {
    const rowId = rows[rowIndex].id;
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const deleteService = async (id) => {
    const res = await deleteRequest(`${base_url}/Service/RemoveService`, {
      servcieId: parseInt(id),
    });
    if (res?.message === "succeeded") {
      dispatch(getAllServices());
      alert("Service Price deleted successfully");
    }
  };

  const deleteColumn = (columnIndex) => {
    const updatedColumns = columns.filter(
      (col, index) => index !== columnIndex
    );
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = row.data.filter((_, index) => index !== columnIndex);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  const changeStatus = async (id, status) => {
    dispatch(miniLoadingAction());
    setStatusId((old) => [...old, id]);
    const data = {
      id: parseInt(id),
      status: status,
    };
    const res = await putRequest(
      `${base_url}/Service/UpdateServiceStatus`,
      data
    );
    if (res?.message === "succeeded") {
      dispatch(miniLoadingAction());
      dispatch(getAllServices());
      alert("Service Status Updated successfully");
    } else {
      dispatch(miniLoadingAction());
      alert("Something went wrong");
    }
  };
  const data = {
    businessId: businessId,
    source: source,
  };

  useEffect(() => {
    if (store?.businessServices) {
      setRows(() =>
        store?.businessServices.map((v, i) => {
          return {
            id: i + 1,
            serviceId: v?.serviceId,
            hasChild: v?.hasChild,
            data: [
              { id: 1, serviceName: v?.serviceName },
              { id: 2, serviceId: v?.serviceId },
              { id: 3, serviceUrl: v?.serviceUrl },
              {
                id: 4,
                isInHouseSKU: v?.isInHouseSKU,
                isInClinicSKU: v?.isInClinicSKU,
                isOnlineSKU: v?.isOnlineSKU,
              },
              { id: 5, isActive: v?.isActive },
            ],
          };
        })
      );
    }
  }, [store?.businessServices]);

  return (
    <>
      <table
        className="table inv_list_container w-100"
        style={{
          marginBottom: "150px",
          // overflowX: 'clip'
        }}
      >
        <thead>
          <tr>
            <th></th>

            {columns.map((column, index) => {
              if (index < 1) {
                return (
                  <th id={`sticky_col${column?.id}`} key={column.id}>
                    {column.name}
                  </th>
                );
              }

              return (
                <th
                  key={column.id}
                  draggable
                  onDragStart={(e) => handleColumnDragStart(e, index)}
                  onDragOver={handleColumnDragOver}
                  onDrop={(e) => handleColumnDrop(e, index)}
                  onMouseOver={() => setColumnId(column.id)}
                  onMouseOut={() => setColumnId("")}
                  className={isDraggingRow ? "dragging-row" : ""}
                  style={{
                    width:
                      (column?.id == 2 && "150px") ||
                      (column?.id == 3 && "120px") ||
                      (column?.id == 4 && "280px") ||
                      (column?.id == 5 && "90px") ||
                      (column?.id == 6 && "80px"),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    {column.name}
                  </div>
                  <div
                    className="dropdown float-right"
                    style={{
                      visibility:
                        columnId === column.id && column.id !== 0
                          ? "visible"
                          : "hidden",
                      zIndex: "9999",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                    }}
                  >
                    <button
                      className="btn bg-transparent float-right p-0 m-0"
                      id="dropdownMenuButton"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <MoreVertIcon
                        className="float-right"
                        sx={{ fontSize: "18px" }}
                      />
                    </button>
                    <div
                      className="dropdown-menu px-2 mt-2"
                      aria-labelledby="dropdownMenuButton"
                      onClick={() => deleteColumn(index)}
                    >
                      <DeleteForeverIcon
                        className="text-danger"
                        sx={{ fontSize: "18px" }}
                      />
                      <span
                        className="text-secondary ps-3"
                        style={{ fontSize: "12px" }}
                      >
                        Delete Column
                      </span>
                    </div>
                  </div>
                </th>
              );
            })}
            <th style={{ width: "70px" }}>Action</th>
            <th className="addcol_btn_th">
              <div className="dropdown">
                <button
                  className="btn bg-transparent p-0 m-0"
                  id="dropdownMenuButton"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <ControlPointIcon
                    sx={{ color: "gray", margin: "auto", fontSize: "20px" }}
                  />
                </button>
                <div
                  className="dropdown-menu px-2 mt-2"
                  aria-labelledby="dropdownMenuButton"
                >
                  {dropdown_columns &&
                    dropdown_columns.map((val, ind) => {
                      return (
                        <div key={val?.id}>
                          <ControlPointIcon
                            className="text-danger"
                            sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                            onClick={() => addColumn(val)}
                          >
                            {val?.name}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {rows.length &&
            rows.map((row, rowIndex) => {
              return (
                <tr
                  key={row.serviceId}
                  draggable
                  onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                  onDragOver={handleRowDragOver}
                  onDrop={(e) => handleRowDrop(e, rowIndex)}
                  className={rowIndex === draggedRowIndex ? "dragging-row" : ""}
                >
                  <td>
                    <div className="form-check ps-4 text-center">
                      <input
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        className="form-check-input checkbox_btn"
                        checked={selectedRows.includes(row.id)}
                        onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                        style={{
                          background:
                            rowIndex === draggedRowIndex ? " #D3D3D3" : "",
                        }}
                      />
                    </div>
                  </td>

                  {row?.data.length > 0 &&
                    row?.data.map((cellValue, columnIndex) => {
                      return (
                        <>
                          {cellValue.id == 1 && (
                            <td
                              key={columnIndex}
                              id={`sticky_col${columnIndex}`}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                              onClick={() =>
                                history.push(
                                  `/service-detail/${row?.serviceId}`
                                )
                              }
                            >
                              <span>{cellValue?.serviceName} </span>
                            </td>
                          )}

                          {cellValue.id === 2 && (
                            <td
                              key={columnIndex}
                              style={{
                                backgroundColor:
                                  rowIndex === draggedRowIndex ? "#D3D3D3" : "",
                              }}
                            >
                              <span>{cellValue?.serviceId}</span>
                            </td>
                          )}

                          {cellValue.id === 3 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                              className=""
                              data-toggle={cellValue?.serviceUrl}
                              data-placement="top"
                              title={cellValue?.serviceUrl}
                            >
                              <BsLink45Deg
                                style={{
                                  fontSize: "24px",
                                  color: "1px solid lightgray",
                                }}
                              />{" "}
                              Link
                            </td>
                          )}

                          {cellValue.id === 4 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                            >
                              <span
                                className="badge px-3 me-2"
                                style={{
                                  backgroundColor: "#e8fcf6",
                                  color: "#42e5b3",
                                  borderRadius: "15px",
                                }}
                              >
                                {cellValue?.isInHouseSKU}
                              </span>
                              <span
                                className="badge px-3 me-2"
                                style={{
                                  backgroundColor: "#e8f9fc",
                                  color: "#4fd4e8",
                                  borderRadius: "15px",
                                }}
                              >
                                {cellValue?.isOnlineSKU}
                              </span>
                              <span
                                className="badge px-3 me-2"
                                style={{
                                  backgroundColor: "#fff7ee",
                                  color: "#fdb767",
                                  borderRadius: "15px",
                                }}
                              >
                                {cellValue?.isInClinicSKU}
                              </span>
                            </td>
                          )}

                          {cellValue.id === 5 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                              className="text-center"
                            >
                              {statusId.length > 0 &&
                              statusId.includes(row?.serviceId) &&
                              store?.miniLoading ? (
                                <MiniLoader />
                              ) : (
                                <div className="form-check form-switch pt-1">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) => {
                                      changeStatus(
                                        row?.serviceId,
                                        e.target.checked
                                      );
                                    }}
                                    checked={cellValue?.isActive}
                                  />
                                </div>
                              )}
                            </td>
                          )}

                          {cellValue.id === null && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                              className="text-center"
                            ></td>
                          )}

                          {cellValue.id === 7 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                            >
                              c7
                            </td>
                          )}

                          {cellValue.id === 8 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                            >
                              c8
                            </td>
                          )}

                          {cellValue.id === 9 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                            >
                              c9
                            </td>
                          )}

                          {cellValue.id === 10 && (
                            <td
                              key={columnIndex}
                              style={{
                                background:
                                  rowIndex === draggedRowIndex
                                    ? " #D3D3D3"
                                    : "",
                              }}
                            >
                              c10
                            </td>
                          )}
                        </>
                      );
                    })}
                  <td
                    className="text-center"
                    style={{
                      background:
                        rowIndex === draggedRowIndex ? " #D3D3D3" : "",
                    }}
                  >
                    <div
                      className="dropdown"
                      style={{ positition: "relative" }}
                    >
                      <button
                        className="btn bt-info p-0 m-0"
                        id="dropdownMenuButton"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ position: "relative", zIndex: "0" }}
                      >
                        <MoreVertIcon
                          className="float"
                          sx={{ fontSize: "18px", zIndex: "99" }}
                        />
                      </button>
                      <div
                        className="dropdown-menu px-2 mt-2"
                        aria-labelledby="dropdownMenuButton"
                        style={{ position: "relative", zIndex: "4" }}
                      >
                        <div
                          className="py-1"
                          onClick={() => {
                            if (source === "") {
                              alert("Please Select Service Category");
                            } else {
                              history.push(
                                `/assign-staff-service/${row?.serviceId}`,
                                data
                              );
                            }
                          }}
                        >
                          <SyncOutlinedIcon
                            className="text-primary"
                            sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                          >
                            Assign Staff
                          </span>
                        </div>
                        {!row?.hasChild && (
                          <div
                            className="py-1"
                            onClick={() =>
                              history.push(
                                `/create-child-service/${row?.serviceId}`
                              )
                            }
                          >
                            <AddBoxIcon
                              className="text-success"
                              sx={{ fontSize: "18px" }}
                            />
                            <span
                              className="text-secondary ps-3"
                              style={{ fontSize: "12px" }}
                            >
                              Add Child
                            </span>
                          </div>
                        )}
                        <div
                          className="py-1"
                          onClick={() =>
                            history.push(`/service-detail/${row?.serviceId}`)
                          }
                        >
                          <FaRegEdit
                            className="text-warning"
                            sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Service
                          </span>
                        </div>
                        <div
                          className="py-1"
                          onClick={() => {
                            deleteService(row?.serviceId);
                          }}
                        >
                          <DeleteForeverIcon
                            className="text-danger"
                            sx={{ fontSize: "18px" }}
                          />
                          <span
                            className="text-secondary ps-3"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Service
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className={`${
                      rows.length - 1 === rowIndex ? "p-0 text-center pt-1" : ""
                    }`}
                    style={{
                      background:
                        rowIndex === draggedRowIndex ? " #D3D3D3" : "",
                    }}
                  ></td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default BusinessServicesTableList;
