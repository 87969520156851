

export const StringToHourMin = (val, type)=>{
    const array= val?.split(":")

    if (type === "hour"){
        return parseInt(array[0])
    } else{
        return parseInt(array[1])
    }
}