import axios from "axios"
import { BASE_URL } from "../utils/Constants"
export const postQuestion = async (data)=> {
  return await axios({
    method: 'post',
    url: BASE_URL+'/genericform_svc/pb/question/create',
    data:JSON.stringify(data),
    headers: {'Content-Type': 'application/json' }
   })
}
export const saveForm = async (data)=> {
  return await axios({
    method: 'post',
    url: BASE_URL+'/genericform_svc/pb/form/create',
    data:JSON.stringify(data),
    headers: {'Content-Type': 'application/json' }
   })
}

export const getQuestions = async (url) =>{
  return await axios({
    method: 'get',
    url: url ==='' ? BASE_URL + '/genericform_svc/pb/questions': url,
  })
}
export const searchQuestions = async (url) =>{
  return await axios({
    method: 'get',
    url: url ==='' ? BASE_URL + '/genericform_svc/pb/questions?s=' + {}: url,
  })
}

export const getForms = async () =>{
  return await axios({
    method: 'get',
    url:  BASE_URL + '/genericform_svc/pb/forms',
  })
}
