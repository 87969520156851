import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsCircleHalf } from 'react-icons/bs';

const InventoryTabMenuLinks = () => {
    return (
        <>
            <div className='row g-0 bg-transparent mt-3 mb-3 d-sm-flex d-none'>
                <div className='col-12 d-flex align-items-center justify-content-end bg-transparent'>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <AiFillCheckCircle style={{ color: '#1ae075' }} />
                        <span className='d-flex ms-1'>Service Details</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <AiFillCheckCircle style={{ color: '#1ae075' }} />
                        <span className='d-flex ms-1'>Pricing</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <BsCircleHalf className='text-warning' />
                        <span className='d-flex ms-1'>Cost</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <BsCircleHalf className='text-warning' />
                        <span className='d-flex ms-1'>Attributes</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <AiFillCheckCircle style={{ color: '#1ae075' }} />
                        <span className='d-flex ms-1'>Groups</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-end media_menu_listing ms-3">
                        <AiFillCheckCircle style={{ color: '#1ae075' }} />
                        <span className='d-flex ms-1'>Templates</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventoryTabMenuLinks