import React from 'react'
import MultiStepper from './components/MultiStepper';
import '../../../Assets/components/meetspace/createslot.scss';
import "../../../Assets/Scss/___global.scss";
import SideMenu from '../side-menu';
import Topbar from './components/Topbar';

const CreateSlot = () => {
  return (
    <SideMenu topbar={<Topbar />}>
      <MultiStepper />
    </SideMenu>
  )
}

export default CreateSlot;