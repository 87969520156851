// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stepper_stepCountContainer__eeU6A {
  width: 600px;
}
.Stepper_stepSeprator__Z01h7 {
  margin-top: -20px;
}
.Stepper_textBox__\\+n1b4 {
  margin-top: 5px;
}
.Stepper_stepTitle__mKEwQ {
  /* font-size: 18=px; */
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #6c6c6c;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/QuestionAnswer/components/Stepper/Stepper.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".stepCountContainer {\n  width: 600px;\n}\n.stepSeprator {\n  margin-top: -20px;\n}\n.textBox {\n  margin-top: 5px;\n}\n.stepTitle {\n  /* font-size: 18=px; */\n  font-weight: 500;\n  font-style: normal;\n  text-align: left;\n  color: #6c6c6c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepCountContainer": `Stepper_stepCountContainer__eeU6A`,
	"stepSeprator": `Stepper_stepSeprator__Z01h7`,
	"textBox": `Stepper_textBox__+n1b4`,
	"stepTitle": `Stepper_stepTitle__mKEwQ`
};
export default ___CSS_LOADER_EXPORT___;
