import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import BusinessList from "../components/BusinessList.jsx"
import SideNavone from "../../../SideNavBar/index.js";

export default function BookingDetails() {
  return (
    <div>
      <SideNavone>
        <BusinessList />
      </SideNavone>
    </div>
  );
}