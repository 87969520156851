import React from "react";
import styles from "./index.module.css";

const SkeletonTable = () => {
  return (
    <div className={styles.tableContainer}>
      {[1, 2].map((val) => (
        <div>
          <div
            className="rbc-time-header rbc-overflowing"
            style={{ marginRight: "9px" }}
          >
            <div
              className="rbc-label rbc-time-header-gutter"
              style={{
                width: "54.2969px",
                minWidth: "54.2969px",
                maxWidth: "54.2969px",
              }}
            ></div>
            <div className="rbc-time-header-content">
              <div className="rbc-row rbc-row-resource">
                <div className="rbc-header">
                  <div
                    className="provider_parent mb-1 mx-2 "
                    style={{
                      width: "180px",
                      borderRadius: "30px",
                      height: "2.5rem",
                    }}
                  >
                    <div
                      className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault rounded-circle css-154ogbs"
                      style={{ width: "40px", height: "40px" }}
                    >
                      A
                    </div>
                    <p className="provider_title">Abdul Rehman</p>
                  </div>
                </div>
              </div>
              <div className="rbc-row rbc-time-header-cell rbc-time-header-cell-single-day">
                <div className="rbc-header">
                  <button type="button" className="rbc-button-link">
                    <span role="columnheader" aria-sort="none">
                      28 Tue
                    </span>
                  </button>
                </div>
              </div>
              <div className="rbc-allday-cell" role="rowgroup">
                <div className="rbc-row-bg">
                  <div className="rbc-day-bg"></div>
                </div>
                <div className="rbc-row-content" role="row">
                  <div className="rbc-row"></div>
                  <div className="rbc-row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* <table className={styles.customTable} aria-label="customized table">
        <thead>
          <tr>
            <th className={styles.customTableCell}>
              <div className={styles.skeleton}></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((data, i) => (
            <tr key={i}>
              <td className={styles.customTableCell}>
                <div className={styles.skeleton}></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default SkeletonTable;
