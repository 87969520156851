import SideNavone from "../../../../SideNavBar";
import ServiceDetail from "./ServiceDetail";
import CreateFormMobile from "../newService/components/CreateFormMobile";

const ServiceDetailContainer = () => {
  return (
    <>
      <SideNavone>
        <ServiceDetail />
        <CreateFormMobile />
      </SideNavone>
    </>
  );
};
export default ServiceDetailContainer;
