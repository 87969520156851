import React from "react";
import Styles from "./Hero.module.css";
import { ReusableBtn } from "../Reusable/ReusableBtn";
import { RoundLoaders } from "../../WebHomePage/Reusable/RoundLoaders";
import { heroimage } from "../../Assets";
export const Hero = () => {
  return (
    <div className={Styles.mobileHeroContainerBox}>
      <div className={Styles.heroContainer}>
        <p className={Styles.heroTitle}>AI Powered Cloud ERP System</p>
        <div className={Styles.heroSlogan}>
          <p>Plan</p>
          <div className={Styles.roundDivider}></div>
          <p>Manage</p>
          <div className={Styles.roundDivider}></div>
          <p>Grow</p>
        </div>
        <div className={Styles.loaderCenter}>
          <RoundLoaders />
        </div>
        <ReusableBtn color="#548dff" text="Get started" />
      </div>
      <img
        src={heroimage}
        alt="Plexaar Calender"
        className={Styles.mobileHeroImage}
      />
    </div>
  );
};
