import React from "react";
import { BiSearch } from "react-icons/bi";
import InputTextField from "../../TextField";

const SearchBar = ({
  searchTerm,
  handleInputChange,
  searchResults,
  handleResultClick,
  message
}) => {
  return (
    <>
      <div
        className="position-relative"
        style={{
          zIndex: "1000",
          width: "100%",
        }}
      >
        <div
          className="d-flex align-items-center "
          // style={{
          //   backgroundColor: "white",
          //   border: "1px solid lightgrey",
          //   padding: "0.5rem 1rem",
          //   width: "100%",
          //   flex: 1,
          //   justifyContent: "center",
          //   borderRadius: "5px",
          // }}
        >
          <div className="p-2 w-100">
            <InputTextField
              id="Address"
              label="Address"
              name="address"
              // value={values.businessName}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              className="w-100"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search to find  business address"
              // error={touched.businessName && errors.businessName}
              //size={20}
              variant="filled"
              autoFocused
              sx={{
                backgroundColor: "#fff",
              }}
            />
          </div>
          {/* <input
            type="text"
            className="border border-0"
            style={{
              marginLeft: "0.5rem",
              outline: "none",
              width: "100%",
              height:"45px"
            }}
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search to find  business address"
          /> */}
          {/* <BiSearch onClick={() => {}} /> */}
        </div>
        {message && (
        <span className="text-danger font_size_password">
          {message}
        </span>
      )}
        {searchResults.length > 0 && (
          <ul
            style={{
              listStyleType: "none",
              backgroundColor: "white",
              color: "black",
              width: "100%",
              // marginLeft: "1.4rem",
              textAlign: "left",
              maxHeight: "25vh",
              overflowY: "auto",
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
              position: "absolute",
              top: "3rem",
              // left: "1rem",
            }}
          >
            {searchResults.map((result, index) => (
              <React.Fragment key={index}>
                <li onClick={() => handleResultClick(result)}>
                  {result.formatted}
                </li>
                <hr />
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;
