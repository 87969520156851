import React from "react";
import styles from "./Header.module.css";
import woman from "./../Assets/woman.png";
import { NavBar } from "../Header/NavBar";
import { HRWeb, HRMobile } from "../../../Assets/components/HomePage/Assets";

export const Header = ({ title, heading, text, imgWeb, imgMob }) => {
  return (
    <>
      <NavBar />
      <div className={styles.main_div}>
        <div className={styles.background_Image}>
          <img src={imgWeb} alt="" />
        </div>
        <div className={styles.background_mobile_image}>
          <img src={imgMob} alt="" />
        </div>

        <div className={styles.first_section}>
          <div className={styles.first_sub_section}>
            <p className={styles.heading1}>{title} </p>
            <p className={styles.heading2} style={{ marginBottom: "12px" }}>
              {heading}
            </p>
            <p className={styles.description}>{text}</p>
            <div className={styles.buttons}>
              <button className={styles.letstalk}>Let's talk</button>
              {/* <button className={styles.learnmore}>Learn more</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
