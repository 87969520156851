import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { FormateDate, FormateTime, FormateTimes } from "../../utils";
import dayjs from "dayjs";
import moment from "moment";
import { convertTo24HourFormat } from "../../utils/helper";

//generate random id

//Schema

// export const timeSlotSchema = Yup.object().shape({
//   scheduleItems: Yup.array()
//     .of(
//       Yup.object().shape({
//         active: Yup.boolean(),
//         businessLocation: Yup.array().when("active", {
//           is: true,
//           then: Yup.array()
//             .of(Yup.number().nullable().required("Business Id is required"))
//             .min(1, "At least one Business Id is required"),
//           otherwise: Yup.array().notRequired(),
//         }),
//         startTime: Yup.array().when("active", {
//           is: true,
//           then: Yup.array()
//             .of(Yup.string().nullable().required("Start time is required"))
//             .min(1, "At least one start time is required"),
//           otherwise: Yup.array().notRequired(),
//         }),
//         endTime: Yup.array().when(["active", "startTime"], {
//           is: (active, startTime) => active && startTime, // Check if both active and startTime are truthy
//           then: Yup.array()
//             .of(
//               Yup.string()
//                 .nullable()
//                 .required("End time is required")
//                 .test(
//                   "is-greater",
//                   "End time must be greater than start time",
//                   function (endTime) {
//                     const { path, createError } = this;
//                     const { startTime } = this.parent;

//                     // Compare the times (assuming HH:mm format)
//                     if (startTime && endTime) {
//                       const start = new Date(`2000-01-01T${startTime}`);
//                       const end = new Date(`2000-01-01T${endTime}`);
//                       if (start >= end) {
//                         return createError({
//                           path,
//                           message: "End time must be greater than start time",
//                         });
//                       }
//                     }

//                     return true;
//                   }
//                 )
//             )
//             .min(1, "At least one end time is required"),
//           otherwise: Yup.array().notRequired(),
//         }),
//       })
//     )
//     .test(
//       "atLeastOneActive",
//       "Select at least one item as active",
//       function (scheduleItems) {
//         // Check if at least one item in the scheduleItems array has active set to true
//         return scheduleItems.some((item) => item.active === true);
//       }
//     ),
//   startDate: Yup.string().required("Start Date is Required"),
//   endDate: Yup.string().required("End Date is Required"),
// });
export const weekOptions = [
  { label: "Every 1st Week", value: "every_week", id: 1, days: 7 },
  { label: "Every 2nd Week", value: "every_two_week", id: 2, days: 14 },
  { label: "Every 3rd week", value: "every_three_week", id: 3, days: 21 },
  { label: "Every 4th week", value: "every_four_week", id: 4, days: 28 },
  // { label: "5th week", value: "week5", id: 5 },
];

export const monthOptions = [
  { label: "Every 1st Month", value: "month1", id: 1, days: 30 },
  { label: "Every 2nd Month", value: "month2", id: 2, days: 60 },
  { label: "Every 3rd Month", value: "month3", id: 3, days: 90 },
  { label: "Every 4th Month", value: "month4", id: 4, days: 120 },
  { label: "Every 5th Month", value: "month5", id: 5, days: 150 },
  { label: "Every 6th Month", value: "month6", id: 6, days: 180 },
];

export const defaultItem = (day, week, date, month) => {
  const defaultItem = {
    day: day,
    week: week,
    month: month,
    startTime: [""],
    endTime: [""],
    date: date,
    active: false,
  };
  return defaultItem;
};

export const defaultItemDates = (date, month) => {
  const defaultItem = {
    month: month,
    startTime: [""],
    endTime: [""],
    date: date,
    active: false,
  };
  return defaultItem;
};
export function convertToDateTime(inputTime) {
  // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
  if (dayjs(inputTime, { strict: true }).isValid()) {
    return dayjs(inputTime);
  }

  // Parse input time as "HH:mm:ss"
  const currentTime = dayjs();
  const [hours, minutes, seconds] = inputTime.split(":");

  // Set the time components
  const formattedDateTime = currentTime
    .set("hour", parseInt(hours, 10))
    .set("minute", parseInt(minutes, 10))
    .set("second", parseInt(seconds, 10));

  return formattedDateTime;
}
export const initialValuesofSlot = (
  action,
  editData,
  location,
  date,
  day,
  week,
  month,
  providerIds,
  businessId,
  editRecord,
  handleTimeSlots
) => {
  let currentDate = dayjs(); // Current date
  // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const currentDayOfWeek = currentDate.day();

  // Calculate the start of the week (Monday)
  const startOfWeek = currentDate.subtract(currentDayOfWeek - 1, "day");

  // Calculate the end of the week (Sunday)
  const endOfWeek = currentDate.add(7 - currentDayOfWeek, "day");
  // Create an array to store the dates of the week
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    weekDates.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
  }

  const providerData = JSON.parse(localStorage.getItem("StaffRecord"));
  let initialRepeatPattern;

  if (action === "edit") {
    switch (editRecord.repeatPattern) {
      case "every_week":
        initialRepeatPattern = weekOptions[0];
        break;
      case "every_two_week":
        initialRepeatPattern = weekOptions[1];
        break;
      case "every_three_week":
        initialRepeatPattern = weekOptions[2];
        break;
      case "every_four_week":
        initialRepeatPattern = weekOptions[3];
        break;
      default:
        initialRepeatPattern = weekOptions[0]; // Default value if none of the conditions match
        break;
    }
  } else {
    initialRepeatPattern = weekOptions[0]; // Set a default value if not in edit mode
  }

  const result =
    action === "edit"
      ? (editRecord.scheduler || []).map((item, index) => {
        // const dateKey = Object.keys(item).find((key) => key.includes("-"));
        // const times = item[dateKey];
        const startTime = item.slots.startTime;
        const endTime = item.slots.endTime;
        const businessLocation = item.slots.map((data) => data.businessId);

        const date = weekDates[index]; // Use weekDates to set the date field

        return {
          expanded: true,
          active: true,
          day:
            moment().day(item.dayOfWeek).day() === 0
              ? 7
              : moment().day(item.dayOfWeek).day(),
          week: item.week,
          month: 1, // month from the date
          // date: date,
          startTime: startTime,
          endTime: endTime,
          businessLocation: businessLocation,
        };
      })
      : [];


  const scheduleDataItems = [];

  for (let i = 1; i <= initialRepeatPattern.id; i++) {
    for (let j = 1; j <= 7; j++) {
      let obj = {
        expanded: false,
        active: false,
        date: new Date(),
        day: j,
        dayName:
          j == 1
            ? "Monday"
            : j == 2
              ? "Tuesday"
              : j == 3
                ? "Wednesday"
                : j == 4
                  ? "Thursday"
                  : j == 5
                    ? "Friday"
                    : j == 6
                      ? "Saturday"
                      : "Sunday",
        week: i,
        month: 11,
        startTime: [""],
        endTime: [""],
        businessLocation: [],
      };
      scheduleDataItems.push(obj);
    }
  }

  const initialValues = {
    repeatPattern: initialRepeatPattern,
    repeatedDates: false,
    repeatedDays: true,
    providerId: action === "edit" ? providerData.providerId : providerIds,
    startDate:
      action === "edit"
        ? editRecord
          ? FormateDate(editRecord.startDate)
          : FormateDate(location.state.appointmentData.date)
        : FormateDate(location.state.appointmentData.date),
    endDate:
      action === "edit"
        ? editRecord
          ? FormateDate(editRecord.endDate)
          : (dayjs().add(7, "day"))
        : (dayjs().add(7, "day")),
    day: action === "edit" ? editData.day : date ? date : "",
    scheduleItems: action === "edit" ? result : scheduleDataItems,

    // [
    //     {
    //       day: day,
    //       month: month,
    //       week: week,
    //       startTime: [""],
    //       endTime: [""],
    //       businessLocation: [],
    //       date: date,
    //       active: true,
    //     },
    //   ],
  };
  return initialValues;
};

export const editFormatdata = (values, businessLocations) => {
  const pattern = values.repeatPattern.value;

  const scheduledItemsData = [];

  const scheduleItems = values.scheduleItems.filter(
    (item) => item.active === true
  );
  // const scheduleItems = values.scheduleItems.filter((slot) => slot.active);

  for (let i = 0; i < scheduleItems.length; i++) {
    // debugger;

    if (scheduleItems[i].startTime.length > 0) {
      for (let j = 0; j < scheduleItems[i].startTime.length; j++) {
        const data = {
          week: scheduleItems[i].week,
          day: scheduleItems[i].day,
          startTime: [
            // convertTo24HourFormat(scheduleItems[i].startTime[j])
           `${scheduleItems[i].startTime[j]}:00`
            // dayjs.isDayjs(scheduleItems[i].startTime[j]) === false
            //   ? scheduleItems[i].startTime[j]
            //   : dayjs(scheduleItems[i].startTime[j], "h:mma").format(
            //     "HH:mm:ss"
            //   ),
          ],
          endTime: [
            // convertTo24HourFormat(scheduleItems[i].endTime[j]),
           `${scheduleItems[i].endTime[j]}:00`

            // dayjs.isDayjs(scheduleItems[i].endTime[j]) === false
            //   ? scheduleItems[i].endTime[j]
            //   : dayjs(scheduleItems[i].endTime[j], "h:mma").format("HH:mm:ss"),
          ],
          businessId: scheduleItems[i].businessLocation[j],
          businessName: businessLocations.find((d) => d.id == scheduleItems[i].businessLocation[j]).name
        };

        scheduledItemsData.push(data);
      }
    }
  }

  // debugger;

  // .map((slot) => {
  //   return {
  //     week: slot.week,
  //     day: slot.day,
  //     // date:FormateDate(slot.date),
  //     startTime: slot.startTime.map((time) => FormateTime(time)),
  //     endTime: slot.endTime.map((time) => FormateTime(time)),
  //     businessLocation: slot.businessLocation.map((city) => city),
  //   };
  // });
  return {
    providerId: values.providerId,
    scheduleType: values.repeatPattern.id,
    startDate: values.startDate,
    endDate: values.endDate,
    scheduleItems: scheduledItemsData,
  };
};

export const formateData = (values) => {
  const pattern = values.repeatPattern.value;
  const scheduleItems = values.scheduleItems
    .filter((slot) => slot.active)
    .map((slot) => {
      return {
        week: slot.week,
        day: slot.day,
        // date:FormateDate(slot.date),
        startTime: slot.startTime.map((time) => FormateTime(time)),
        endTime: slot.endTime.map((time) => FormateTime(time)),
        businessLocation: slot.businessLocation.map((city) => city),
      };
    });
  return {
    // providerId: values.providerId,
    startDate: values.startDate,
    endDate: values.endDate,
    scheduleType:
      pattern === "week1"
        ? 1
        : pattern === "week2"
          ? 2
          : pattern === "week3"
            ? 3
            : 4,
    // repeatPattern:pattern==='week1'?'every_week':pattern==='week2'?"every_two_week":pattern==="week3"?"every_three_week":"every_four_week",
    // scheduleType: values.repeatedDays===true?1:2,
    scheduleItems: scheduleItems,
  };
};

export const formateRepeatData = (values) => {
  const scheduleItems = values.scheduleItems
    .filter((slot) => slot.active)
    .map((slot) => {
      return {
        month: slot.month,
        date: FormateDate(slot.date),
        startTime: slot.startTime.map((time) => FormateTime(time)),
        endTime: slot.endTime.map((time) => FormateTime(time)),
        businessLocation: slot.businessLocation.map((city) => city),
      };
    });
  return {
    businessData: {
      businessId: 1,
      businessStartTime: "06:00:00",
      businessEndTime: "16:00:00",
    },
    providerId: values.providerId,
    startDate: values.startDate,
    endDate: values.endDate,
    scheduleType: values.scheduleType,
    scheduleItems: scheduleItems,
  };
};
