import React, { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { TbFileSettings } from "react-icons/tb";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductRoleRequest } from "../../../Store/User/actions";
import { DataNotFound } from "../../../utils";

const Permissions = ({
  showNewRole,
  handleAddNewRole,
  selectedOptions,
  productIndex,
  role,
  serviceId,
  data,
  businessId,
  handleRoles,
  selectedRole,
  setSelectedRole,
  selectedSwitch
}) => {

  const dispatch = useDispatch();
  const serviceRole = useSelector((state) => state.User?.serviceRole);
  const roles = serviceRole[serviceId];
  const [showPermissions, setShowPermissions] = useState(true);
  const selectedRolese = useSelector((state) => state.roleReducer.selectedRole);

  useEffect(() => {
    const id = selectedSwitch[productIndex];
    dispatch(fetchProductRoleRequest({ id, businessId }));
  }, selectedSwitch[productIndex]);

  const action = "edit";

  return (
    <>
      <div
        className="w-100 pt-4 d-sm-flex !m-n2 justify-content-between"
        style={{ backgroundColor: "#FBFCFF" }}
      >
        <div className="d-flex p-2 pb-0">
          <AiOutlineUser style={{ color: "#b2b2b2", fontSize: 20 }} />
          <span className="span ps-1">Role</span>
        </div>
        <div
          className="d-flex p-2 pb-0"
          onClick={() => handleAddNewRole(data, action)}
        >
          <MdAdd style={{ color: "#538dff", fontSize: 20 }} />
          <span
            className="span ps-1"
            style={{
              color: "#538dff",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            Add New Role
          </span>
        </div>
      </div>
      <div
        className="d-flex flex-wrap px-2 align-items-center"
        style={{ backgroundColor: "#FBFCFF" }}
      >
        {roles?.length > 0 ? (
          roles?.map((option) => (
            <div key={option.value} className="d-flex flex-column">
              <div
                key={option.value}
                className="d-flex flex-column flex-wrap pe-4"
              >
                <label
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <input
                    type="checkbox"
                    value={option.id}
                    // checked={
                    //   selectedRole === option.id &&
                    //   selectedOptions.id === option?.service?.id
                    // }
                    checked={selectedRolese.includes(option.id)}
                    // checked={selectedRole === option.id&&selectedOptions.id}
                    onChange={() =>
                      handleRoles(
                        option.id,
                        option?.service?.id,
                        setSelectedRole
                      )
                    }
                    className="lager"
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  {option.name}
                </label>
              </div>
            </div>
          ))
        ) : (
          <div>
            {/* Insert your message or component for data not found */}
            <DataNotFound />
          </div>
        )}
      </div>
      {selectedRole !== null && (
        <div
          style={{ backgroundColor: "#FBFCFF" }}
          className="d-flex flex-column flex-wrap px-2"
        >
          <div className="d-flex">
            <span
              className={` ${!showPermissions ? "permission2" : "permission"}`}
              onClick={() => setShowPermissions((prev) => !prev)}
            >
              {`${showPermissions ? "See" : "Hide"} Permissions`}
            </span>
          </div>
          <div className="d-flex flex-column">
            {!showPermissions &&
              roles
                .filter((role) => role.id === selectedRole)
                .map((selectedRole) =>
                  selectedRole.allowPermission.map((data) => {
                    const permissions = data.permissions;
                    const truePermissions = Object.keys(permissions).filter(
                      (key) => permissions[key] === true
                    );
                    return (
                      <>
                        <ul
                          className="d-flex flex-column orderlist"
                          key={data.module.id}
                        >
                          <div>
                            <TbFileSettings style={{ color: "#b2b2b2" }} />{" "}
                            <span className="span pt-3">
                              {data.module.name}
                            </span>
                            <div className="ps-3">
                              {truePermissions.map((permission, index) => (
                                <li className="text-capitalize" key={index}>
                                  {permission}
                                </li>
                              ))}
                            </div>
                          </div>
                        </ul>
                      </>
                    );
                  })
                )}
          </div>
        </div>
      )}
    </>
  );
};

export default Permissions;
