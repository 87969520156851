export const selectRole = (roleId) => {
    return {
      type: 'SELECT_ROLE',
      payload: roleId,
    };
  };
  
  export const deselectRole = () => {
    return {
      type: 'DESELECT_ROLE',
    };
  };
  export const selectSwitch = (roleId) => {
    return {
      type: 'SELECT_SWITCH',
      payload: roleId,
    };
  };
  
  export const delectSwitch = () => {
    return {
      type: 'DESELECT_SWITCH',
    };
  };