import React, { useState, useEffect } from "react";
import Table from "./Table";
import { OnSearch } from "./helper";
import Header from "../Header";
import { userList } from "../../../utils/constant";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserRequest, fetchUserRequest } from "../../../Store/User/actions";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import SideNavone from "../../../SideNavBar";
import MenuTabList from "../CustomTabs/MenuTabList";
const MangeUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const teamRecord = location;
  const flag = false;
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.User?.data);
  const loading = useSelector((state) => state.User?.loading);
  const business= JSON.parse(localStorage.getItem("businessRecord"))
  useEffect(() => {
    
      dispatch(fetchUserRequest(business.id));
    
  }, []);
  useEffect(() => {
    setRowData(data || []);
  }, [data]);
  const removeBusiness = (id) => {
    dispatch(
      deleteUserRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };
  //Delete busienss Confirmation Dialouge Box handler
  const handleBusinessDeleteConfirmation = (id) => {
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  };
  return (
    <>
      <SideNavone
       data={data}
       OnSearch={OnSearch}
       rowData={rowData}
       flag={flag}
       record={business.id}
       setRowData={setRowData}
       heading={userList.USER}
       buttonText={userList.ADD_USER}
       link="/add-user">

      <div>
        {/* <Header
          data={data}
          OnSearch={OnSearch}
          rowData={rowData}
          flag={flag}
          record={business.id}
          setRowData={setRowData}
          heading={userList.USER}
          buttonText={userList.ADD_USER}
          link="/add-user"
        /> */}
      </div>
      {/* <MenuTabList type="userList" /> */}

      <div className=" p-md-4 hide_scrollbar min-h-[250px] table-container">
        {loading ? (
          <SkeletonTable />
        ) : (
          <Table
            loading={loading}
            setRecord={setRowData}
            data={rowData}
            business={business}
            dialog={dialog}
            deleteUser={handleBusinessDeleteConfirmation}
          />
        )}
      </div>
      </SideNavone>
    </>
  );
};

export default MangeUser;
