// import React, { useState, useEffect, useCallback } from "react";
// import Grid from "@mui/material/Grid";
// import Container from "@mui/material/Container";

// import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import {
//   useLocation,
//   useParams,
// } from "react-router-dom/cjs/react-router-dom.min";
// import { Avatar, Tooltip, Menu, MenuItem, Box } from "@mui/material";
// import instance from "../../../utils/utils";
// import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
// import {
//   AppointmentDetailsIcon,
//   RightAngle,
// } from "../AppointmentDetails/Svgs/svgs";
// import dayjs from "dayjs";

// import { MoreOptionsVertSvg } from "../../../Assets/svgs";
// import Venue from "../bookingDetails/Venue";
// import { appoitmentsDetails, singleBookingAppoitmentDetails } from "../store/bookingSlice";
// import { ClipLoader } from "react-spinners";
// const OrderDetail = () => {
//   const history = useHistory();
//   const location = useLocation();
//   const { id } = useParams();
//   const dispatch = useDispatch();
//   const options = {
//     weekday: "long", // Get the full day name (e.g., "Monday")
//     day: "numeric", // Get the numeric day (e.g., 14)
//     month: "long", // Get the full month name (e.g., "June")
//     year: "numeric", // Get the full year (e.g., 2023)
//   };
//   const [bookingData, setBookingData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingCheckin, setIsLoadingCheckIn] = useState(false);
//   const [isLoadingJob, setIsLoadingJob] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [dialog, setDialog] = useState({ visible: false });
//   const [pagination, setPagination] = useState(null);

//   const [address, setAddress] = useState(null);
//   const [openAction, setOpenAction] = useState(false);

//   let bookingId = useSelector((state) => state.bookingReducer.bookingId);
//   let plexaarSalesOrderId = useSelector(
//     (state) => state.bookingReducer.plexarSaleOrderId
//   );
  
//   let appointmentId = useSelector(
//     (state) => state.bookingReducer.appointmentId
//   );
//   const action = bookingData?.createdPlatforms;
//   const handleMenuActionClick = (event) => {
//     setOpenAction(true);
//     setAnchorEl(event.currentTarget);
//     // setSelectedRow(columnValue);
//     // setSelecteData(data);
//   };
//   const handleProfileClick = () => {
//     if (bookingData?.plexaarcustomerId === 0) {
//       //   customSnackBar("Customer Id not exsit");
//     } else {
//       const customerId =
//         bookingData?.createdPlatforms === "expert"
//           ? bookingData?.customerId
//           : bookingData?.plexaarcustomerId;
//       history.push(
//         `/customer-profile-view/${bookingData?.plexaarcustomerId}`,
//         action
//       );
//     }
//   };
//   console.log(bookingData,"responseresponseresponseresponse")

//   const handleMenuClose = () => {
//     setOpenAction(false);
//     setAnchorEl(null);
//   };
//   const fetchBookingData = useCallback(async () => {
//     if (plexaarSalesOrderId !== 0) {
//       setIsLoading(true);
//       await instance
//         .get(
//           `/booking_svc/pb/salesOrder_appointments/?plexaarSalesOrderId=${plexaarSalesOrderId}&timeZone=Europe%2FLondon`
//         )
//         .then((response) => {
//           console.log(response.data,"responseresponseresponseresponse")
//           setBookingData(response?.data?.result);
//           setPagination(response?.data?.pagination);

//           setAddress(response?.data?.result?.address);
//           //   dispatch(BookingDetails(response?.data?.result));
//         })
//         .catch((error) => {
//           console.error(error);
//         })
//         .finally(() => setIsLoading(false));
//     } else {
//       setIsLoading(false); // Stop the loader
//     }
//   }, [dispatch, plexaarSalesOrderId]);
//   useEffect(() => {
//     fetchBookingData();
//   }, [fetchBookingData]);


//   const fetchMoreData = async () => {
//     if (pagination.next) {
//       console.log(pagination,"pagination")
//       setIsLoading(true);
//       try {
//         const response = await fetch(pagination.next);
//         const newData = await response.json();
//         console.log(newData,"newDatanewData")

//         setBookingData((prevData) => [...prevData, ...newData.data]);
//         setPagination(newData.pagination);
//       } catch (error) {
//         console.error("Error fetching more data: ", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const handleScroll = () => {
//     const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
//     if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
//       fetchMoreData();
//     }
//   };
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isLoading]);
//   if (isLoading || bookingData?.length === 0) {
//     return (
//       <div className="w-100 h-100 d-flex align-items-center justify-content-center">
//         <div className="spinner-border text-primary" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   } else if (bookingData.length === 0) {
//     return (
//       <div className="w-100 h-100 d-flex align-items-center justify-content-center">
//         <div style={{ marginLeft: "10px" }}>Appointment ID does not exist.</div>
//       </div>
//     );
//   } else {
//     return (
//       <>
//         <DialogBox
//           {...dialog}
//           header={dialog.header}
//           message={dialog.message}
//         />
//         <div class="appointment-detail-section container-fluid">
//           <div class="row">
//             <div class="col-sm py-3 px-4">
//               <div className="left-section">
//                 <div className="order-details-section-container">
//                   <div className="Order-details_order-num">
//                     <label className="order-num_heading">Order Number</label>
//                     <div className="d-flex justify-content-between align-items-baseline">
//                       <span className="order-num_number">
//                         {bookingData[0].plexaarBookingId}
//                       </span>
//                       <RightAngle />
//                     </div>
//                   </div>

//                   <hr className="left-section-container-lines" />
//                   <div className="Order-details_quantity">
//                     <label className="quantity_heading">Quantity</label>
//                     <span className="quantity_number">
//                       {bookingData[0]?.quantity}
//                     </span>
//                   </div>
//                 </div>

//                 <div
//                   className={`payment-status-section-container  ${
//                     bookingData[0]?.payment_status === "paid"
//                       ? "success-payment"
//                       : "danger-payment"
//                   } mt-4`}
//                 >
//                   <div className="Order-details_order-num">
//                     <label className="total-amount_heading">Total Amount</label>
//                     <div className="d-flex justify-content-between align-items-baseline">
//                       <span className="total-amount_number">
//                         {bookingData[0]?.currency === "usd" ? "$" : "£"}
//                         {bookingData[0]?.totalPrice}{" "}
//                       </span>
//                     </div>
//                   </div>

//                   <hr className="left-section-container-lines " />
//                   <div className="Order-details_quantity">
//                     <label className="quantity_heading">Payment Status</label>
//                     <div className="d-flex justify-content-between align-items-baseline">
//                       <span
//                         className={`quantity_number ${
//                           bookingData[0]?.payment_status === "paid"
//                             ? "text-success"
//                             : "text-danger"
//                         }`}
//                       >
//                         {bookingData[0]?.payment_status}
//                       </span>
//                       <RightAngle />
//                     </div>
//                   </div>
//                 </div>
//               </div>{" "}
//             </div>

//             <div class="col-12 col-md-7">
//               <div className="col-md-12 px-2 pt-3 pb-5" id="style-2">
//                 <div className="d-flex padding-left-right ">
//                   <div
//                     className="pt-2"
//                     onClick={() => {
//                       if (!location?.state?.flag) {
//                         history.push("/calendar");
//                       } else {
//                         history.goBack();
//                       }
//                     }}
//                     role="button"
//                   >
//                     <i id="icon_left_color" className="fas fa-chevron-left"></i>
//                   </div>
//                   {isLoading && <ClipLoader />}

//                   <Container className="m-auto mb-3">
//                     <Grid item xl={1} lg={3} md={6} sm={12} className="">

//                       {bookingData.map((bookingData) => (
//                         <div className="card card-cont p-3 appointment-detail-container mb-3">
//                           <div className="d-flex justify-content-between">
//                             <div className="d-flex ">
//                               {bookingData?.serviceImage ? (
//                                 <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
//                                   <Avatar
//                                     alt={bookingData?.serviceName}
//                                     src={bookingData?.serviceImage}
//                                     variant="rounded"
//                                     style={{
//                                       borderRadius: 50,
//                                       width: "60px",
//                                       height: "60px",
//                                     }}
//                                   />
//                                 </div>
//                               ) : (
//                                 <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
//                                   <Avatar
//                                     sx={{
//                                       width: "60px",
//                                       height: "60px",
//                                       borderRadius: 50,
//                                     }}
//                                   >
//                                     S
//                                   </Avatar>
//                                 </div>
//                               )}
//                               <div className="appointment-service-name_flex">
//                                 <div className="appointment-service-name_holder">
//                                   <div className={`d-flex pt-1`}>
//                                     <div className="d-flex flex-column">
//                                       <span className="m-0 mx-2 spand ps-0">
//                                         {bookingData.serviceName
//                                           ? bookingData.serviceName
//                                           : bookingData?.servicePayload
//                                               ?.serviceName}
//                                       </span>{" "}
//                                       {bookingData?.attributes?.length > 0 && ( // Added safe navigation operator
//                                         <div className="d-flex pt-0">
//                                           <div className="d-flex flex-column">
//                                             <span className="m-0 mx-2 spand ps-0">
//                                               <Tooltip
//                                                 title={
//                                                   bookingData.attributes[0]
//                                                     .value
//                                                 }
//                                               >
//                                                 <p className="m-0 mx-0 appointment-details_attributes">
//                                                   {
//                                                     bookingData.attributes[0]
//                                                       .value
//                                                   }
//                                                 </p>
//                                               </Tooltip>
//                                             </span>
//                                           </div>
//                                           <span className="pt-0 size-sml col-dark-gray non-capitalized">
//                                             (
//                                             {
//                                               bookingData?.attributes[0]
//                                                 .duration
//                                             }{" "}
//                                             min -
//                                             {bookingData?.currency === "usd"
//                                               ? "$"
//                                               : "£"}
//                                             {
//                                               bookingData?.attributes[0]
//                                                 .attributePrice
//                                             }
//                                             )
//                                           </span>
//                                         </div>
//                                       )}
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                             <hr className="mt-2 ms-0 mb-1 horizontal-line_appointment" />
//                             <div className="align-self-center">
//                               <span className="ms-1 appointment-time">
//                                 {bookingData?.currency === "usd" ? "$" : "£"}
//                                 {bookingData?.attributes?.length > 0
//                                   ? bookingData.attributes[0].attributePrice +
//                                     " "
//                                   : bookingData?.totalAmount}
//                               </span>
//                             </div>
//                           </div>

//                           {/* Attribute section */}
//                           {/* {bookingData?.attributes.length > 0 ? (
//                             <div>
//                               {bookingData?.attributes &&
//                                 bookingData?.attributes.length > 0 && (
//                                   <div className="pt-1">
//                                     <div className="d-flex ps-0 flex-wrap justify-content">
//                                       <span className="book size-sml p-1">
//                                         Attributes
//                                       </span>
//                                     </div>

//                                     <div className="d-flex justify-content-between align-items-center my-2">
//                                       <div className="d-flex align-items-center">
//                                         <AttributesIcon />
//                                       </div>
//                                     </div>
//                                   </div>
//                                 )}
//                               <hr className="mt-3 ms-0 mb-1 horizontal-line_appointment" />
//                             </div>
//                           ) : null} */}
//                           {/* Customer Name */}
//                           <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
//                           <div>
//                             <div className="d-flex flex-wrap align-items-center my-1 justify-content-between pe-0">
//                               <div className="d-flex  flex-wrap justify-content">
//                                 <h1 className="book pt-1 size-sml">
//                                   Customer Name
//                                 </h1>
//                               </div>
//                               <div
//                                 className="d-flex flex-wrap cursor-pointer"
//                                 onClick={(event) =>
//                                   handleMenuActionClick(event)
//                                 }
//                               >
//                                 <MoreOptionsVertSvg />
//                               </div>
//                             </div>
//                             <div>
//                               <div className="flex-wrap align-items-center d-flex justify-content-start">
//                                 <div className="provider-details">
//                                   {bookingData.providerImage ? (
//                                     <Avatar
//                                       alt={bookingData?.customerFirstName}
//                                       src={bookingData.customerImage}
//                                       className="rounded-circle"
//                                       style={{ width: 30, height: 30 }}
//                                     />
//                                   ) : (
//                                     <Avatar
//                                       className="rounded-circle"
//                                       style={{
//                                         backgroundColor: "#5599FF",
//                                         width: 30,
//                                         height: 30,
//                                         color: "white",
//                                       }}
//                                     >
//                                       {bookingData?.customerFirstName?.charAt(
//                                         0
//                                       )}
//                                     </Avatar>
//                                   )}
//                                 </div>
//                                 <div>
//                                   <div className="d-flex flex-wrap justify-content-between ">
//                                     <div className="d-flex flex-wrap text-start ms-0 justify-content">
//                                       <span className="spand pt-1 ps-0 provider-name">
//                                         {bookingData?.customerFirstName +
//                                           " " +
//                                           bookingData?.customerLastName}
//                                       </span>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           {/* Appointment Details */}
//                           <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
//                           <div className="mt-0">
//                             <span className="book size-sml">
//                               Appointment Details
//                             </span>
//                             <div className="d-flex gap-2">
//                               {bookingData.appointments.map((appointment) =>
//                                 appointment.isCompleted ? (
//                                   <div
//                                     className="pt-2 appointment-completed"
//                                     key={appointment.id}
//                                   >
//                                     <>
//                                       <span className="completed-date">
//                                         {dayjs(
//                                           appointment.bookingDate,
//                                           "YYYY-MM-DD"
//                                         ).format("D MMM")}
//                                       </span>
//                                       <span
//                                         style={{ fontSize: "8.5px" }}
//                                         className="m-0 mx-2 selected_servicename d-flex justify-content-center completed-status"
//                                       >
//                                         Completed
//                                       </span>
//                                     </>
//                                   </div>
//                                 ) : appointment.isCompleted === false &&
//                                   appointment.bookingDate ? (
//                                   <div
//                                     className="pt-2 appointment-upcoming"
//                                     key={appointment.id}
//                                   >
//                                     <span className="upcoming-date">
//                                       {dayjs(
//                                         appointment.bookingDate,
//                                         "YYYY-MM-DD"
//                                       ).format("D MMM")}
//                                     </span>
//                                     <span
//                                       style={{}}
//                                       className="m-0 mx-2 selected_servicename d-flex justify-content-center upcoming-text"
//                                     >
//                                       Upcoming
//                                     </span>
//                                   </div>
//                                 ) : (
//                                   <div
//                                   className="pt-2 book-new-session"
//                                   onClick={() => {
//                                       dispatch(
//                                           appoitmentsDetails(bookingData)
//                                         );
//                                     history.push(`/session-booking`);
                                    
//                                   }}
//                                 >
//                                   <span className="upcoming-next">Book</span>
//                                   <span
//                                     style={{ fontSize: "8.5px", color: "#fff" }}
//                                     className="m-0 mx-2 selected_servicename d-flex justify-content-center"
//                                   >
//                                     Next Session
//                                   </span>
//                                 </div>                                )
//                               )}
                             
//                             </div>
//                           </div>

//                           {/* Provider Name*/}
//                           <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
//                           <div>
//                             <div className="d-flex flex-wrap justify-content-between pe-0">
//                               <div className="d-flex flex-wrap justify-content">
//                                 <h1 className="book pt-1 size-sml">
//                                   Provider Name
//                                 </h1>
//                               </div>
//                             </div>
//                             <div>
//                               <div className="flex-wrap align-items-center d-flex justify-content-start">
//                                 <div className="provider-details">
//                                   {bookingData.providerImage ? (
//                                     <Avatar
//                                       alt={bookingData?.providerName}
//                                       src={bookingData.providerImage}
//                                       className="rounded-circle"
//                                       style={{ width: 30, height: 30 }}
//                                     />
//                                   ) : (
//                                     <Avatar
//                                       className="rounded-circle"
//                                       style={{
//                                         backgroundColor: "#5599FF",
//                                         width: 30,
//                                         height: 30,
//                                         color: "white",
//                                       }}
//                                     >
//                                       {bookingData?.providerName?.charAt(0)}
//                                     </Avatar>
//                                   )}
//                                 </div>
//                                 <div>
//                                   <div className="d-flex flex-wrap justify-content-between ">
//                                     <div className="d-flex flex-wrap text-start ms-0 justify-content">
//                                       <span className="spand pt-1 ps-0 provider-name">
//                                         {bookingData?.providerName}
//                                       </span>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           {/* Venue*/}
//                           <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
//                           {bookingData?.venue !== "" && (
//                             <Venue VenueData={bookingData?.venue} />
//                           )}

//                           {openAction && (
//                             <Menu
//                               anchorEl={anchorEl}
//                               open={Boolean(anchorEl)}
//                               onClose={handleMenuClose}
//                               anchorOrigin={{
//                                 vertical: "top",
//                                 horizontal: "left",
//                               }}
//                             >
//                               <MenuItem onClick={handleProfileClick}>
//                                 <Box
//                                   component="div"
//                                   display="flex"
//                                   justifyContent="space-between"
//                                   alignItems="center"
//                                 >
//                                   Customer Profile
//                                 </Box>
//                               </MenuItem>
//                             </Menu>
//                           )}
//                         </div>
//                       ))}
//                     </Grid>
//                   </Container>
//                 </div>
//               </div>
//             </div>

//             {!isLoading && !pagination.next && <p>No more data to load</p>}

//             <div class="col-sm py-3 px-4">
//               <div className="order-details-section-container mt-3">
//                 <div className="Order-details_order-num">
//                   <div className="d-flex justify-content-between align-items-baseline">
//                     <span className="appointment-action-btns py-1">
//                       View Invoice
//                     </span>
//                     <RightAngle />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// };

// export default OrderDetail;
import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Avatar, Tooltip, Menu, MenuItem, Box } from "@mui/material";
import instance from "../../../utils/utils";
import DialogBox from "../../../Pages/BusinessProfile/DialogBox";
import {
  AppointmentDetailsIcon,
  RightAngle,
} from "../AppointmentDetails/Svgs/svgs";
import dayjs from "dayjs";

import { MoreOptionsVertSvg } from "../../../Assets/svgs";
import Venue from "../bookingDetails/Venue";
import { appoitmentsDetails, singleBookingAppoitmentDetails } from "../store/bookingSlice";
const OrderDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const options = {
    weekday: "long", // Get the full day name (e.g., "Monday")
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheckin, setIsLoadingCheckIn] = useState(false);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState({ visible: false });

  const [address, setAddress] = useState(null);
  const [openAction, setOpenAction] = useState(false);

  let bookingId = useSelector((state) => state.bookingReducer.bookingId);
  let plexaarSalesOrderId = useSelector(
    (state) => state.bookingReducer.plexarSaleOrderId
  );
  
  let appointmentId = useSelector(
    (state) => state.bookingReducer.appointmentId
  );
  const action = bookingData?.createdPlatforms;
  const handleMenuActionClick = (event) => {
    setOpenAction(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(columnValue);
    // setSelecteData(data);
  };
  const handleProfileClick = () => {
    if (bookingData?.plexaarcustomerId === 0) {
      //   customSnackBar("Customer Id not exsit");
    } else {
      const customerId =
        bookingData?.createdPlatforms === "expert"
          ? bookingData?.customerId
          : bookingData?.plexaarcustomerId;
      history.push(
        `/customer-profile-view/${bookingData?.plexaarcustomerId}`,
        action
      );
    }
  };
  const handleMenuClose = () => {
    setOpenAction(false);
    setAnchorEl(null);
  };
  const fetchBookingData = useCallback(async () => {
    if (plexaarSalesOrderId !== 0) {
      setIsLoading(true);
      await instance
        .get(
          `/booking_svc/pb/salesOrder_appointments/?plexaarSalesOrderId=${plexaarSalesOrderId}&timeZone=Europe%2FLondon`
        )
        .then((response) => {
          setBookingData(response?.data?.result);
          setAddress(response?.data?.result?.address);
          //   dispatch(BookingDetails(response?.data?.result));
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false); // Stop the loader
    }
  }, [dispatch, plexaarSalesOrderId]);
  useEffect(() => {
    fetchBookingData();
  }, [fetchBookingData]);


  if (isLoading || bookingData.length === 0) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (bookingData.length === 0) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div style={{ marginLeft: "10px" }}>Appointment ID does not exist.</div>
      </div>
    );
  } else {
    return (
      <>
        <DialogBox
          {...dialog}
          header={dialog.header}
          message={dialog.message}
        />
        <div class="appointment-detail-section container-fluid">
          <div class="row">
            <div class="col-sm py-3 px-4">
              <div className="left-section">
                <div className="order-details-section-container">
                  <div className="Order-details_order-num">
                    <label className="order-num_heading">Order Number</label>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <span className="order-num_number">
                        {bookingData[0].plexaarBookingId}
                      </span>
                      <RightAngle />
                    </div>
                  </div>

                  <hr className="left-section-container-lines" />
                  <div className="Order-details_quantity">
                    <label className="quantity_heading">Quantity</label>
                    <span className="quantity_number">
                      {bookingData[0]?.quantity}
                    </span>
                  </div>
                </div>

                <div
                  className={`payment-status-section-container  ${
                    bookingData[0]?.payment_status === "paid"
                      ? "success-payment"
                      : "danger-payment"
                  } mt-4`}
                >
                  <div className="Order-details_order-num">
                    <label className="total-amount_heading">Total Amount</label>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <span className="total-amount_number">
                        {bookingData[0]?.currency === "usd" ? "$" : "£"}
                        {bookingData[0]?.totalPrice}{" "}
                      </span>
                    </div>
                  </div>

                  <hr className="left-section-container-lines " />
                  <div className="Order-details_quantity">
                    <label className="quantity_heading">Payment Status</label>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <span
                        className={`quantity_number ${
                          bookingData[0]?.payment_status === "paid"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {bookingData[0]?.payment_status}
                      </span>
                      <RightAngle />
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div class="col-12 col-md-7">
              <div className="col-md-12 px-2 pt-3 pb-5" id="style-2">
                <div className="d-flex padding-left-right ">
                  <div
                    className="pt-2"
                    onClick={() => {
                      if (location?.state?.flag) {
                        history.push("/calendar");
                      } else {
                        history.goBack();
                      }
                    }}
                    role="button"
                  >
                    <i id="icon_left_color" className="fas fa-chevron-left"></i>
                  </div>

                  <Container className="m-auto mb-3">
                    <Grid item xl={1} lg={3} md={6} sm={12} className="">
                      {bookingData.map((bookingData) => (
                        <div className="card card-cont p-3 appointment-detail-container mb-3">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex ">
                              {bookingData?.serviceImage ? (
                                <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    alt={bookingData?.serviceName}
                                    src={bookingData?.serviceImage}
                                    variant="rounded"
                                    style={{
                                      borderRadius: 50,
                                      width: "60px",
                                      height: "60px",
                                      backgroundColor:"#F0F0F0",
                                      color:"#B5B5B5"
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                                  <Avatar
                                    sx={{
                                      width: "60px",
                                      height: "60px",
                                      borderRadius: 50,
                                    }}
                                  >
                                    S
                                  </Avatar>
                                </div>
                              )}
                              <div className="appointment-service-name_flex">
                                <div className="appointment-service-name_holder">
                                  <div className={`d-flex pt-1`}>
                                    <div className="d-flex flex-column">
                                      <span className="m-0 mx-2 spand ps-0">
                                        {bookingData.serviceName
                                          ? bookingData.serviceName
                                          : bookingData?.servicePayload
                                              ?.serviceName}
                                      </span>{" "}
                                      {bookingData?.attributes?.length > 0 && ( // Added safe navigation operator
                                        <div className="d-flex pt-0">
                                          <div className="d-flex flex-column">
                                            <span className="m-0 mx-2 spand ps-0">
                                              <Tooltip
                                                title={
                                                  bookingData.attributes[0]
                                                    .value
                                                }
                                              >
                                                <p className="m-0 mx-0 appointment-details_attributes">
                                                  {
                                                    bookingData.attributes[0]
                                                      .value
                                                  }
                                                </p>
                                              </Tooltip>
                                            </span>
                                          </div>
                                          <span className="pt-0 size-sml col-dark-gray non-capitalized">
                                            (
                                            {
                                              bookingData?.attributes[0]
                                                .duration
                                            }{" "}
                                            min -
                                            {bookingData?.currency === "usd"
                                              ? "$"
                                              : "£"}
                                            {
                                              bookingData?.attributes[0]
                                                .attributePrice
                                            }
                                            )
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="mt-2 ms-0 mb-1 horizontal-line_appointment" />
                            <div className="align-self-center">
                              <span className="ms-1 serviceprice">
                                {bookingData?.currency === "usd" ? "$" : "£"}
                                {bookingData?.attributes?.length > 0
                                  ? bookingData.attributes[0].attributePrice +
                                    " "
                                  : bookingData?.totalAmount}
                              </span>
                            </div>
                          </div>

                          {/* Attribute section */}
                          {/* {bookingData?.attributes.length > 0 ? (
                            <div>
                              {bookingData?.attributes &&
                                bookingData?.attributes.length > 0 && (
                                  <div className="pt-1">
                                    <div className="d-flex ps-0 flex-wrap justify-content">
                                      <span className="book size-sml p-1">
                                        Attributes
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center my-2">
                                      <div className="d-flex align-items-center">
                                        <AttributesIcon />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <hr className="mt-3 ms-0 mb-1 horizontal-line_appointment" />
                            </div>
                          ) : null} */}
                          {/* Customer Name */}
                          <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                          <div>
                            <div className="d-flex flex-wrap align-items-center my-1 justify-content-between pe-0">
                              <div className="d-flex  flex-wrap justify-content">
                                <h1 className="book pt-1 size-sml">
                                  Customer Name
                                </h1>
                              </div>
                              <div
                                className="d-flex flex-wrap cursor-pointer"
                                onClick={(event) =>
                                  handleMenuActionClick(event)
                                }
                              >
                                <MoreOptionsVertSvg />
                              </div>
                            </div>
                            <div>
                              <div className="flex-wrap align-items-center d-flex justify-content-start">
                                <div className="provider-details">
                                  {bookingData.providerImage ? (
                                    <Avatar
                                      alt={bookingData?.customerFirstName}
                                      src={bookingData.customerImage}
                                      className="rounded-circle"
                                      style={{ width: 30, height: 30 }}
                                    />
                                  ) : (
                                    <Avatar
                                      className="rounded-circle"
                                      style={{
                                        backgroundColor: "#F0F0F0",
                                        width: 30,
                                        height: 30,
                                        color: "B5B5B5",
                                      }}
                                    >
                                      {bookingData?.customerFirstName?.charAt(
                                        0
                                      )}
                                    </Avatar>
                                  )}
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap justify-content-between ">
                                    <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                      <span className="spand pt-1 ps-0 provider-name">
                                        {bookingData?.customerFirstName +
                                          " " +
                                          bookingData?.customerLastName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Appointment Details */}
                          <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                          <div className="mt-0">
                            <span className="book size-sml">
                              Appointment Details
                            </span>
                            <div className="d-flex gap-2">
                              {bookingData.appointments.map((appointment) =>
                                appointment.isCompleted ? (
                                  <div
                                    className="pt-2 appointment-completed"
                                    key={appointment.id}
                                  >
                                    <>
                                      <span className="completed-date">
                                        {dayjs(
                                          appointment.bookingDate,
                                          "YYYY-MM-DD"
                                        ).format("D MMM")}
                                      </span>
                                      <span
                                        style={{ fontSize: "8.5px" }}
                                        className="m-0 mx-2 selected_servicename d-flex justify-content-center completed-status"
                                      >
                                        Completed
                                      </span>
                                    </>
                                  </div>
                                ) : appointment.isCompleted === false &&
                                  appointment.bookingDate ? (
                                  <div
                                    className=" appointment-upcoming"
                                    key={appointment.id}
                                  >
                                    <span className="upcoming-date">
                                      {dayjs(
                                        appointment.bookingDate,
                                        "YYYY-MM-DD"
                                      ).format("D MMM")}
                                    </span>
                                    <span
                                      style={{}}
                                      className="m-0 selected_servicename d-flex justify-content-center upcoming-text"
                                    >
                                      Upcoming
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                  className="pt-2 book-new-session"
                                  onClick={() => {
                                      dispatch(
                                          appoitmentsDetails(bookingData)
                                        );
                                    history.push(`/session-booking`);
                                    
                                  }}
                                >
                                  <span className="upcoming-next">Book</span>
                                  <span
                                    style={{ fontSize: "8.5px", color: "#fff" }}
                                    className="m-0 mx-2 selected_servicename d-flex justify-content-center"
                                  >
                                    Next Session
                                  </span>
                                </div>
                                )
                              )}
                             
                            </div>
                          </div>

                          {/* Provider Name*/}
                          <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                          <div>
                            <div className="d-flex flex-wrap justify-content-between pe-0">
                              <div className="d-flex flex-wrap justify-content">
                                <h1 className="book pt-1 size-sml">
                                  Provider Name
                                </h1>
                              </div>
                            </div>
                            <div>
                              <div className="flex-wrap align-items-center d-flex justify-content-start">
                                <div className="provider-details">
                                  {bookingData.providerImage ? (
                                    <Avatar
                                      alt={bookingData?.providerName}
                                      src={bookingData.providerImage}
                                      className="rounded-circle"
                                      style={{ width: 30, height: 30 }}
                                    />
                                  ) : (
                                    <Avatar
                                      className="rounded-circle"
                                      style={{
                                        backgroundColor: "#F0F0F0",
                                        width: 30,
                                        height: 30,
                                        color: "#B5B5B5",
                                      }}
                                    >
                                      {bookingData?.providerName?.charAt(0)}
                                    </Avatar>
                                  )}
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap justify-content-between ">
                                    <div className="d-flex flex-wrap text-start ms-0 justify-content">
                                      <span className="spand pt-1 ps-0 provider-name">
                                        {bookingData?.providerName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Venue*/}
                          <hr className="mt-2 ms-0 mb-2 horizontal-line_appointment" />
                          {bookingData?.venue !== "" && (
                            <Venue VenueData={bookingData?.venue} />
                          )}

                          {openAction && (
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <MenuItem onClick={handleProfileClick}>
                                <Box
                                  component="div"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  Customer Profile
                                </Box>
                              </MenuItem>
                            </Menu>
                          )}
                        </div>
                      ))}
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
            <div class="col-sm py-3 px-4">
              <div className="order-details-section-container mt-3">
                <div className="Order-details_order-num">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <span className="appointment-action-btns py-1">
                      View Invoice
                    </span>
                    <RightAngle />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default OrderDetail;
