import React, { useEffect } from "react";

const LongTextField = ({
  handleChange,
  id,
  label,
  statement,
  answer,
  formData,
}) => {
  useEffect(() => {
    handleChange(id, answer);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="float-label">
        <textarea
          id={id}
          type="text"
          rows="5"
          placeholder={`${label}`}
          onChange={(e) => handleChange(id, e.target.value)}
          value={formData[id]}
        />

        <label forhtml={id}>{label}</label>
      </div>
      {statement ? <p>{statement}</p> : ""}
    </>
  );
};

export default LongTextField;
