import { createSlice } from "@reduxjs/toolkit";
import {
  getAllServices,
  getServiceToUpdate,
  getGetServices,
  getServicePrices,
  getAllUnitTypes,
  getAllCurrencies,
  getPriceVariables,
  getServiceCosts,
  getAllCostTypes,
  getServiceAttributes,
  getAllAttributeTypes,
  getServiceGroups,
  getAllGroups,
  getGroupDetails,
  getPackageDetails,
  getBusinessServices,
  getBusinessStaff,
  getServiceToUpdateExpert,
  getServiceAttributesExpert,
} from "./servicesActions";

const initialState = {
  allServices: [],
  serviceToUpdate: [],
  serviceAttributesExpert: [],
  expertServiceToUpdate: [],
  servicePrices: [],
  allUnitTypes: [],
  allCurrencies: [],
  priceVariables: [],
  serviceCosts: [],
  allCostTypes: [],
  serviceAttributes: [],
  allAttributeTypes: [],
  businessServices: [],
  businessStaff: [],
  serviceGroups: null,
  allGroups: null,
  groupDetails: null,
  packageDetails: null,
  isLoading: false,
  miniLoading: false,
  errorMessage: "",
};

const CreateSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    nullUpdateService: (state) => {
      state.serviceToUpdate = [];
      state.expertServiceToUpdate = [];
      state.servicePrices = [];
      state.priceVariables = [];
      state.serviceCosts = [];
      state.serviceAttributes = [];
      state.serviceGroups = null;
      state.groupDetails = null;
      state.packageDetails = null;
    },
    loadingAction: (state) => {
      state.isLoading = !state?.isLoading;
    },
    miniLoadingAction: (state) => {
      state.miniLoading = !state?.miniLoading;
    },
    enableMiniLoader: (state) => {
      state.miniLoading = true;
    },
    disableMiniLoader: (state) => {
      state.miniLoading = false;
    },
  },
  extraReducers(builder) {
    //Get all services
    builder.addCase(getAllServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allServices = action.payload === null ? [] : [...action.payload];
    });
    builder.addCase(getAllServices.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get service to update
    builder.addCase(getServiceToUpdate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceToUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceToUpdate =
        action.payload !== null ? [...action.payload] : [];
    });
    builder.addCase(getServiceToUpdate.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    //get expert service
    builder.addCase(getServiceToUpdateExpert.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceToUpdateExpert.fulfilled, (state, action) => {
      state.isLoading = false;
      state.expertServiceToUpdate =
        action.payload !== null ? [...action.payload] : [];
    });
    builder.addCase(getServiceToUpdateExpert.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get service prices
    builder.addCase(getServicePrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServicePrices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.servicePrices = [...action.payload];
    });
    builder.addCase(getServicePrices.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get unit Types
    builder.addCase(getAllUnitTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUnitTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allUnitTypes = [...action.payload];
    });
    builder.addCase(getAllUnitTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get currencies
    builder.addCase(getAllCurrencies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCurrencies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allCurrencies = [...action.payload];
    });
    builder.addCase(getAllCurrencies.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get Price variables
    builder.addCase(getPriceVariables.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPriceVariables.fulfilled, (state, action) => {
      state.isLoading = false;
      state.priceVariables = [...action.payload];
    });
    builder.addCase(getPriceVariables.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get Service costs
    builder.addCase(getServiceCosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceCosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceCosts = [...action.payload];
    });
    builder.addCase(getServiceCosts.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get all cost types
    builder.addCase(getAllCostTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCostTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allCostTypes = [...action.payload];
    });
    builder.addCase(getAllCostTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
    // get service attribute of expert
    builder.addCase(getServiceAttributesExpert.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceAttributesExpert.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceAttributesExpert =
        action.payload !== null ? [...action.payload] : [];
    });
    builder.addCase(getServiceAttributesExpert.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get service attributes
    builder.addCase(getServiceAttributes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceAttributes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceAttributes = [...action.payload];
    });
    builder.addCase(getServiceAttributes.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get all attributes types
    builder.addCase(getAllAttributeTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllAttributeTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allAttributeTypes = [...action.payload];
    });
    builder.addCase(getAllAttributeTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get service groups
    builder.addCase(getServiceGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceGroups = action.payload;
    });
    builder.addCase(getServiceGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get all groups
    builder.addCase(getAllGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allGroups = action.payload;
    });
    builder.addCase(getAllGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get group details
    builder.addCase(getGroupDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGroupDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groupDetails = action.payload;
    });
    builder.addCase(getGroupDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get package details
    builder.addCase(getPackageDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPackageDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.packageDetails = action.payload;
    });
    builder.addCase(getPackageDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get Business Services
    builder.addCase(getBusinessServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBusinessServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.businessServices = action.payload;
    });
    builder.addCase(getBusinessServices.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });

    // get Platform Services
    builder.addCase(getGetServices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGetServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allServices = action.payload;
    });
    builder.addCase(getGetServices.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.allServices = [];
    });

    // get Business Staff
    builder.addCase(getBusinessStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBusinessStaff.fulfilled, (state, action) => {
      state.isLoading = false;
      state.businessStaff = action.payload;
    });
    builder.addCase(getBusinessStaff.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const {
  nullUpdateService,
  loadingAction,
  miniLoadingAction,
  enableMiniLoader,
  disableMiniLoader,
} = CreateSlice.actions;
export const servicesReducer = CreateSlice.reducer;
