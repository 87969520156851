import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ImageInput from "../../../../../Pages/DynamicForm/dynamicFormView/imageInput";
import "../../../../../Pages/DynamicForm/viewPage.css";
import TextField from "../../../../../Pages/DynamicForm/dynamicFormView/textFieldInput";
import DropDown from "../../../../../Pages/DynamicForm/dynamicFormView/dropdown";
import {
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../../../Store/dynamicForm/serviceSlice";
import { customSnackBar } from "../../../../../utils";
import CustomPhoneInput from "../../../../../Pages/DynamicForm/dynamicFormView/phoneInput";
import instance from "../../../../../utils/utils";
import ServiceDetailContainer from "../service-detail/ServiceDetailContainer";
import { postRequest } from "../../../request";
import { base_url } from "../../../base_url";

const CreateForm = ({
  isModal = false,
  handleFormSubmission,
  handleSelectedCustomer,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.ServiceDynamicForm.form);
  const isLoading = useSelector((state) => state.ServiceDynamicForm.isLoading);
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const selectedBusinessId = useSelector(
    (state) => state.ServiceDynamicForm.selectedBusiness
  );
  const [formData, setFormData] = useState({
    serviceName: "",
    serviceSKU: "",
    shortDesc: "",
    longDesc: "",
    serviceUrl: "",
    serviceImage: "",
    actualPrice: 0,
    priceType: "",
    currency: "",
    duration: 0,
    availableSeats: 1,
    countryId: 188,
    noOfVisits: 1,
    isOnline: true,
    isInClinic: true,
    isInHouse: true,
    onlineSKU: "",
    inClinicSKU: "",
    inHouseSKU: "",
    BusinessId: selectedBusinessId,
    createdBy: userID,
  });

  const [isServiceDetail, setIsServiceDetail] = useState(true);

  const handleFieldChange = (columnName, columnValue) => {
    // console.log(columnName,columnValue);
    // Find the index of the column in formData
    setFormData((prevData) => ({
      ...prevData,
      [columnName]: columnValue,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requiredFieldsEmpty = formState.sections.some((section) =>
      section.containers.some((container) =>
        container.fields.some(
          (field) => field.isRequired && !formData[field.columnName]
          // !formData.find((item) => item.columnName === field.columnName)
          //   ?.columnValue
        )
      )
    );

    if (requiredFieldsEmpty) {
      customSnackBar("Please fill in all required fields.");
      return;
    }
    try {
      dispatch(setIsLoadingTrue());
      const response = await instance.post(
        `/serviceinventory_svc/pb/Service/addServiceDynamically`,
        { ...formData, BusinessId: selectedBusinessId, createdBy: userID }
      );
      console.log();
      if (response.data.code === 0) {
        customSnackBar("Service Has been Ceated Successfully");
      } else {
        const message = response?.data?.message;
        customSnackBar(`Error Occured: ${message}`);
      }
    } catch (e) {
      customSnackBar(e);
    } finally {
      dispatch(setIsLoadingFalse());
      history.push("/dynamic-services");
    }
  };

  const assignBusiness = async (service) => {
    const data = {
      services: [
        {
          serviceId: service.serviceId || "",
          sku: service.serviceSKU || "",
          name: service.serviceName || "",

          inHouseSku: service?.isInHouseSKU || "",
          inClinicSku: service?.inClinicSku || "",
          isOnlineSku: service?.isOnlineSku || "",
          image: "string",
          duration: service?.duration || 0,
          availableSeats: service?.availableSeats || 1,
          price: service?.actualPrice || 0,
          currencyId: 0,
          isOnline: service?.isOnline || true,
          isInClinic: service?.isInClinic || true,
          isInHouse: service?.isInHouse || true,
          shortDesc: service?.shortDesc || "string",
          hasAttribute: service?.hasAttributes || true,
        },
      ],
      countryId: service?.countryId !== null ? [service?.countryId] : [1],
      businessId: selectedBusinessId,
      createdBy: userID,
      isExpert: false,
    };
    dispatch(setIsLoadingTrue());

    const res = await postRequest(
      `${base_url}/Service/AddBusinessServiceNew`,
      data
    );
    if (res?.message === "Success") {
      customSnackBar("Service Has been Ceated Successfully");

      history.push("/dynamic-services");
      dispatch(setIsLoadingFalse());

      // activeBusiness()
    } else {
      customSnackBar(res?.message);

      dispatch(setIsLoadingFalse());

      history.push("/dynamic-services");
    }
  };
  return (
    <>
      {/* {!isServiceDetail ? ( */}
      <div>
        {isLoading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form
            className={isModal ? "dynamic_formModal" : "dynamic_formContainer"}
            onSubmit={handleFormSubmit}
          >
            <div className="d-flex justify-content-start align-items-center">
              <div
                className="pt-2"
                onClick={() => history.goBack()}
                role="button"
              >
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
              {/* <p className="dynamic_heading">You are Previewing this page</p>{" "} */}
            </div>
            {formState?.sections?.map((item, index) => (
              <div
                key={index}
                className={`${index !== 0 ? "dynamicform_section" : "mt-1"}`}
              >
                <span className="dynamicform_sectionTitle">
                  {item.sectionTitle}
                  <span
                    className={
                      index === 0 ? "dynamic_formCompulsory" : "d-none"
                    }
                  >
                    {" (Compulsory to fill)"}
                  </span>
                </span>
                {item.containers?.map((cont, i) => (
                  <div
                    key={i}
                    className="d-flex align-items-center justify-content-center dynamicform_container"
                  >
                    {cont.fields?.map((field, ind) => {
                      if (field.fieldType === "image") {
                        return (
                          <ImageInput
                            key={ind}
                            onImageChange={(selectedFile) =>
                              handleFieldChange(
                                field.columnName,
                                "selectedFile"
                              )
                            }
                          />
                        );
                      } else if (field.hasOption === true) {
                        // const findIndexOfOptions =  formData.findIndex(
                        //   (item) => item.columnName === field.columnName
                        // );
                        return (
                          <DropDown
                            key={ind}
                            isRequired={field.isRequired}
                            id={field.columnName}
                            options={field.options}
                            placeholder={`Select ${field.title}`}
                            value={
                              field?.options[
                                field?.options?.findIndex(
                                  (item) =>
                                    parseInt(formData[field.columnName]) ===
                                    item?.optionId
                                )
                              ]?.optionName
                            }
                            onChange={(value) => {
                              const id = field.options.findIndex(
                                (item) => item.optionName === value
                              );
                              const val =
                                field.fieldType === "int"
                                  ? field.options[id].optionId
                                  : field.options[id].optionId.toString();
                              handleFieldChange(field.columnName, val);
                            }}
                            labelKey={field.title}
                            className={
                              ind === 1 && cont.fields.length === 2
                                ? "ms-2"
                                : (ind === 1 && cont.fields.length === 3) ||
                                  (ind === 1 && cont.fields.length === 4)
                                ? "mx-2"
                                : ind === 3
                                ? "ms-2"
                                : ""
                            }
                          />
                        );
                      } else if (
                        field.columnName === "primaryMobile" ||
                        field.columnName === "secondaryMobile"
                      ) {
                        return (
                          <CustomPhoneInput
                            title={field.title}
                            isRequired={field.isRequired}
                            classNameParent={`
                            ${
                              ind === 1 && cont.fields.length === 2
                                ? "ms-2"
                                : (ind === 1 && cont.fields.length === 3) ||
                                  (ind === 1 && cont.fields.length === 4)
                                ? "mx-2"
                                : ind === 3
                                ? "ms-2"
                                : ""
                            }`}
                            value={
                              formData[field.columnName] || ""
                              // formData.find(
                              //   (item) => item.columnName === field.columnName
                              // )?.columnValue || ""
                            }
                            onChange={(value) =>
                              handleFieldChange(field.columnName, value)
                            }
                          />
                        );
                      } else {
                        return (
                          <TextField
                            key={ind}
                            label={field.title}
                            isRequired={field.isRequired}
                            placeholder={`Enter ${field.title}`}
                            type={
                              field.fieldType === "string"
                                ? "text"
                                : field.fieldType === "int" ||
                                  field.fieldType === "float"
                                ? "number"
                                : field.fieldType === "date" ||
                                  field.fieldType === "dob"
                                ? "date"
                                : "text"
                            }
                            value={
                              formData[field.columnName] || ""
                              // formData.find(
                              //   (item) => item.columnName === field.columnName
                              // )?.columnValue || ""
                            }
                            classNameParent={
                              ind === 1 && cont.fields.length === 2
                                ? "ms-2"
                                : (ind === 1 && cont.fields.length === 3) ||
                                  (ind === 1 && cont.fields.length === 4)
                                ? "mx-2"
                                : ind === 3
                                ? "ms-2"
                                : ""
                            }
                            onHandleChange={(e) =>
                              handleFieldChange(
                                field.columnName,
                                e.target.value
                              )
                            }
                          />
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
            ))}
            <div className="d-flex justify-content-center ">
              <button type="submit" className="dynamic_AddNewCustomer">
                Add New Service
              </button>
            </div>
          </form>
        )}
      </div>
      {/* <ServiceDetailContainer /> */}
    </>
  );
};

export default CreateForm;
