import React from 'react';
import BusinessTable from '../components/BusinessList/BusinessTable';

import SideNavone from '../../../SideNavBar';
import '../../../Assets/components/serviceInventory/services.scss';
import '../../../Assets/Scss/___global.scss';

const CalenderPage = () => {
  return (
    <>
      <SideNavone>
      <BusinessTable />
      </SideNavone>
    </>
  );
};

export default CalenderPage;