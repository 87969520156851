// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionPage_questionPageSectionCount__zsS1g {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px !important;
  width: 50%;
  margin: 0 auto;
  height: 50px;
  background-color: lightgray;
}
.QuestionPage_sectionChildElement__06z-G {
  margin: 0 30px;
  cursor: pointer;
  /* background-color: aliceblue; */
  padding: 10px 20px;
}
.QuestionPage_sectionChildElementActive__jDLZR {
  margin: 0 30px;
  cursor: pointer;
  background-color: aliceblue;
  padding: 10px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/QuestionAnswer/components/QuestionPage/QuestionPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,UAAU;EACV,cAAc;EACd,YAAY;EACZ,2BAA2B;AAC7B;AACA;EACE,cAAc;EACd,eAAe;EACf,iCAAiC;EACjC,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,2BAA2B;EAC3B,kBAAkB;AACpB","sourcesContent":[".questionPageSectionCount {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px !important;\n  width: 50%;\n  margin: 0 auto;\n  height: 50px;\n  background-color: lightgray;\n}\n.sectionChildElement {\n  margin: 0 30px;\n  cursor: pointer;\n  /* background-color: aliceblue; */\n  padding: 10px 20px;\n}\n.sectionChildElementActive {\n  margin: 0 30px;\n  cursor: pointer;\n  background-color: aliceblue;\n  padding: 10px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionPageSectionCount": `QuestionPage_questionPageSectionCount__zsS1g`,
	"sectionChildElement": `QuestionPage_sectionChildElement__06z-G`,
	"sectionChildElementActive": `QuestionPage_sectionChildElementActive__jDLZR`
};
export default ___CSS_LOADER_EXPORT___;
