import Subscribe from "../Pages/BusinessProfile/Subscribed/Subscribe";
import AddSubscription from "../Pages/BusinessProfile/Subscribed/AddSubscription";
import SubscriptionPlan from "../Pages/BusinessProfile/Calendar/SubscriptionPlan";

export const SubscriptionRoute = [
  {
    path: "/subscribe",
    component: Subscribe,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-subscription",
    component: AddSubscription,
    ispublic: false,
    exact: true,
  },
  {
    path: "/subscription-plan",
    component: SubscriptionPlan,
    ispublic: false,
    exact: true,
  },
];
