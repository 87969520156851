import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_ROLE_FAILURE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_REQUEST,
  FETCH_ROLE_FAILURE,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_PERMISSION_REQUEST,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAILURE,
  ADD_PERMISSION_REQUEST,
  ADD_PERMISSION_SUCCESS,
  FETCH_SERVICE_ROLE,
  FETCH_SERVICE_ROLE_SUCCESS,
  FETCH_SERVICE_ROLE_FAILURE,
} from "./actionType";
import produce from "immer";

const initialState = {
  rowData: [],
  data: [],
  record: null,
  loading: false,
  result: "",
  updatedRecord: "",
  role: "",
  roles: [],
  error: null,
  productList: [],
  permissionList: [],
  permissionRowData: [],
  serviceRole: [],
  services: [],
};

const User = produce((state, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        rowData: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        rowData: [],
        data: [],
        error: action.payload,
      };
    case FETCH_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissionList: action.payload,
        permissionRowData: action.payload,
      };
    case FETCH_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        rowData: [],
        data: [],
        error: action.payload,
      };
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.payload,
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case FETCH_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_USER_REQUEST:
      state.record = action.payload;
      break;

    case ADD_USER_SUCCESS:
      state.result = action.payload;
      break;
    case ADD_ROLE_REQUEST:
      state.role = action.payload;
      break;

    case FETCH_SERVICE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SERVICE_ROLE:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case FETCH_SERVICE_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          serviceRole: {
            ...state.serviceRole,
            [action.payload.serviceId]: action.payload.roles
          },
          services:  action.payload.roles,
        };
    case ADD_ROLE_SUCCESS:
      state.role = action.payload;
      state.roles = [...state.roles, action.payload];
      break;
    case ADD_PERMISSION_REQUEST:
      state.record = action.payload;
      break;

    case ADD_PERMISSION_SUCCESS:
      state.result = action.payload;
      break;
    case UPDATE_USER_REQUEST:
      state.updatedRecord = action.payload;
      break;
    case DELETE_USER_SUCCESS: {
      const { payload: id } = action;
      return {
        ...state,
        rowData: state.rowData.filter((team) => team.id !== id),
        error: null,
      };
    }
    case DELETE_USER_FAILURE: {
      const { payload: error } = action;
      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
}, initialState);
export default User;
