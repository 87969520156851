import React, { useState } from "react";
import "./CoreControlReason.css";
import { t60, setting, horse } from "../../Assets";

export const CoreControlReason = () => {
  const [data, setData] = useState([
    {
      icon: t60,
      heading: "Off-the-Shelf Brilliance",
      title:
        "Synchronize automation for diverse workflows. Expand your business with the unparalleled power of the world's foremost ERP solution while ensuring compliance & minimizing overhead costs and beyond.",
    },
    {
      icon: horse,
      heading: "Productive Scheduling",
      title:
        "Use the Calendar view to schedule appointments, add reminders, and share details with other users. Take advantage of location-based grouping tools to view upcoming appointments by location.",
    },
    {
      icon: setting,
      heading: "Measure Your Business KPIs",
      title:
        "Generate tailored reports for key areas of your business, including sales, lead reports, job handling, how your business is performing against KPIs, and so on.",
    },
  ]);
  return (
    <div className="coreControlReasonContainer">
      {data.map((item, index) => (
        <div className="alignCoreControl">
          <div className="coreControlReasonIcon">
            <img src={item.icon} alt="" />
          </div>
          <div className="coreControlText">
            <p className="coreControlHeading">{item.heading}</p>
            <p className="coreControlTitle">{item.title}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
