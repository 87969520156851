export function limitText(text, limit) {
  if (text.length <= limit) {
    return text; // Return the original text if it's already within the limit
  }

  const truncatedText = text.slice(0, limit); // Get the first 'limit' characters
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    return truncatedText.slice(0, lastSpaceIndex); // Trim text to the last space
  }

  return truncatedText; // Return the truncated text if there are no spaces
}
