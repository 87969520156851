import React from "react";
import {Header} from "../../../Homepage/Header/Header";
import { Layout } from "../../../Homepage/WebHomePage/Layout/Layout";
import { MobileLayout } from "./../../../Homepage/MobileHomePage/Layout/Layout";

import "./LandingPage.scss";

function LandingPage() {
  return (
    <div className="col-sm-12 p-0">
      <Header />
      <div className="webView">
        <Layout />
      </div>
      <div className="mobileView">
        <MobileLayout />
      </div>
    </div>
  );
}

export default LandingPage;
