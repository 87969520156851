import React from 'react';
// import TextField from '@mui/material/TextField';
import TextField from '../TextField/index';
import { Autocomplete } from '@mui/material';
// import { _STANDARD } from '../../../utils/Constants';
import { alpha, styled } from "@mui/material/styles";


// options, handleSortByChain;

const InputAutoComplete = ({
  fullWidth = true,
  id = '',
  title = '',
  placeholder = '',
  options = [],
  name = '',
  multiple = false,
  renderKey = 'name',
  getOptionLabel,
  loading = false,
  value,
  disableClearable = false,
  touch,
  error,
  size = 'small',
  helperText = '',
  isRefresh = false,
  onChange = () => {},
  disabled = false,
  className = 'custom-text-field',
  onClickRefresh = () => {},
  handleSortByChain,
  endAdornment = '',
  popupIcon
}) => {
  return (
    <Autocomplete
      // open={true}
      fullWidth={fullWidth}
      className={className + ' !capitalize'}
      classes={{ option: ' !capitalize' }}
      // classes={{
      //   paper: '!bg-primary',
      //   noOptions: '!text-secondary text-sm',
      //   option: 'text-secondary text-sm',
      //   tag: '!m-y-x-3 !text-fontSecondary',
      //   inputRoot: '!pb-[1px]'
      // }}
      id={id || name + '-auto-complete'}
      options={options || []}
      loading={loading}
      disableClearable={disableClearable}
      value={value || null}
      filterSelectedOptions={true}
      disabled={disabled}
      multiple={multiple}
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option) => (option ? option[renderKey] : '')
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={
        handleSortByChain
          ? (event, value) => {
              handleSortByChain(value && value.id ? value.id : '', id);
            }
          : onChange
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option && option.id ? option.id : ''}>
            {option ? option[renderKey] : ''}
          </li>
        );
      }}
      popupIcon={popupIcon || undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={(touch && error) || helperText}
          fullWidth
          size={size}
          // className="custom-text-field"
          label={title}
          placeholder={placeholder}
          name={name}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            // endAdornment: (
            //   <>
            //     {loading ? (
            //     ) : isRefresh ? (
            //       <Tooltip title="Refresh">
            //         <button disabled={disabled} onClick={onClickRefresh}>
            //           <RefreshIcon
            //             className={`text-2xl text-fontSecondary cursor-pointer ${
            //               disabled && 'refresh-icon'
            //             }`}
            //             disabled={disabled}
            //           />
            //         </button>
            //       </Tooltip>
            //     ) : null}
            //     {endAdornment}
            //     {params.InputProps.endAdornment}
            //   </>
            // )
          }}
        />
      )}
    />
  );
};

// const StyledAutocomplete = styled(InputAutoComplete)(({ theme }) => ({
//   "& .MuiAutocomplete-root": {
//     width: "100%", // Adjust the width as needed
//   },
//   "& .MuiFilledInput-root": {
//     borderRadius: 8,
//     height: "47px",
//     backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1A2027",
//     border: "1px solid",
//     borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
//     transition: theme.transitions.create([
//       "border-color",
//       "background-color",
//       "box-shadow",
//     ]),
//     "&:hover": {
//       backgroundColor: "transparent",
//     },
//     "&.Mui-focused": {
//       backgroundColor: "transparent",
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }));
export default InputAutoComplete;