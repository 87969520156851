import React, { useEffect } from 'react'
import InventoryTableList from './components/InventoryTableList'
import InventoryTableListMobile from './components/InventoryTableListMobile'
import SideNavone from '../../../../SideNavBar'
import { useDispatch, useSelector } from 'react-redux';
import { getAllServices } from '../../store/servicesActions';
import { nullUpdateService } from '../../store/ServicesSlice';
import ServiceLoader from '../ServiceLoader';
import { fetchBusinessRequest } from '../../../../Store/Business/actions';

const InventoryList = () => {

    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);
    var userID=useSelector((state) => state.Auth.userloginsuccessyasir.id)
    useEffect(() => {
        dispatch(getAllServices());
        dispatch(fetchBusinessRequest(userID));
        dispatch(nullUpdateService())
    }, []);

    return (
        <>
            <SideNavone>
                {/* {store?.isLoading ?
                    <ServiceLoader />
                    : <> */}
                <InventoryTableList />
                <InventoryTableListMobile />
                {/* </>
                } */}
            </SideNavone>
        </>
    )
}

export default InventoryList