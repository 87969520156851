import React, { useEffect } from "react";
import "./dashboardpages.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getuserdataafterlogin,
  sendotpsecondary,
} from "../../Store/Auth/actions";
import SideNavone from "../../SideNavBar/index";
import Header from "../BusinessProfile/Header";
import InputTextField from "../BusinessProfile/TextField";
import { Button } from "@mui/material";

function Editprofilemail() {
  const dispatch = useDispatch();
  const flag = true;
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const [alluserdata, setAlluserdata] = useState("");
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const getdataofregistereduser = useSelector(
    (state) => state.Auth?.userloginsuccessyasir?.users
  );
  useEffect(() => {
    dispatch(
      getuserdataafterlogin({
        id: registersuccessId,
      })
    );
  }, []);

  const resendotponcall = () => {
    dispatch(
      sendotpsecondary({
        id: registersuccessId,
        type: 2,
        // history: history,
        setIsLoading,
      })
    );
  };
  return (
    <>
     
          <SideNavone>
            <div>
              <Header flag={flag} heading={"Manage Profile"} />
            </div>

            <div className="p-md-4">
              <div>
                <div>
                  <p className=" ps-3 sub_text_color_account_alltext_show_actual">
                    Edit User Name
                  </p>
                </div>
                <div className="p-2 w-100">
                  <InputTextField
                    id="primary-email"
                    label="Primary Email"
                    name="primaryemail"
                    placeholder="Edir Primary Email"
                    //size={20}
                    variant="filled"
                    value={getdataofregistereduser.primaryEmail}
                    // onChange={handleFirstNameChange}
                    autoFocused
                    sx={{
                      // maxWidth: "300px",
                      backgroundColor: "#fff",
                    }}
                  />
                 
                </div>
            
              </div>
              <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                <div className="p-2">
                  <Button
                    type="submit"
                    sx={{
                      width: "150px",
                      backgroundColor: "#538dff",
                      height: "40px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={()=>{

                    }}
                    // disabled={firstNameError || lastNameError}
                    variant="contained"
                  >
                    {"Save & Continue"}
                  </Button>
                </div>
              </div>
            </div>
          </SideNavone>
       
    </>
  );
}

export default Editprofilemail;
