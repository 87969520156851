import React, { useState, useEffect } from "react";
import { Form } from "formik";
import InputTextField from "../TextField";

import Button from "@mui/material/Button";
import { CustomTextField, FormateDate, error, success } from "../../../utils";
import { MdAddCircleOutline } from "react-icons/md";
import { Autocomplete, InputAdornment, Switch } from "@mui/material";
import dayjs from "dayjs";
import InputDate from "../InputDate";
import FireNav from "../List";
import Modal from "@mui/material/Modal";
import { ClipLoader } from "react-spinners";
import ModalData from "./modal";
import axios from "axios";
import {
  GET_PLAN,
  GET_PLAN_ENTITIES,
  GET_PRODUCTS,
} from "../../../utils/GraphQLQueries";
import { useSelector } from "react-redux";

const FormData = ({
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  list,
  setFieldValue,
  loading,
  all_business,
  setBusinessId,
  businessId,
  planOptions,
  setPlanOptions,
  fetchPlan,
  selectedPlan,
  setSelectedPlan,
  setSelectedFeatures,
  selectedFeatures,
  features,
  selectedFeatureIds,
  setFeatures,
  fetchData,
}) => {
  const [newFeature, setNewFeature] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loadings, setLoading] = useState(false);
  const [plan, setPlan] = useState("");
  const [price, setPrice] = useState("");
  const [product, setProduct] = useState("");
  const [planEntity, setPlanEntity] = useState();
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const data = localStorage.getItem("selectedProduct");
    const entity = localStorage.getItem("planEntity");
    if (data) {
      setProduct(JSON.parse(data));
      setPlanEntity(JSON.parse(entity));
    }
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [toggleSwitch, setToggleSwitch] = useState(false);
  const handleProductChange = (event, value) => {
    setSelectedProduct(value ? value : null);

    // Save the selected product in local storage
  };
  const handlePlanChange = (event, value) => {
    setSelectedPlan(value ? value : null);
    localStorage.setItem("selectedPlan", JSON.stringify(value.id));
  };
  const handleAddFeature = async () => {
    setLoader(true);
    if (newFeature.trim()) {
      setFeatures([...features, newFeature]);
      setNewFeature("");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: `
            mutation {
              createPlanEntity(name: "${newFeature}") {
                planEntity {
                  createdAt
                  id
                  isDeleted
                  name
                  updatedAt
                }
              }
            }
          `,
        }
      );

      if (response.status === 200) {
        success("Plan Entity Added Successfully");
        localStorage.setItem(
          "planEntity",
          JSON.stringify(response.data.data.createPlanEntity.planEntity)
        );
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setFieldValue("paymentMethod", values.paymentMethod);
  }, [values.paymentMethod, setFieldValue]);

  const submitPlan = async (e) => {
    setLoading(true);
    const planEntityString = JSON.stringify(selectedFeatureIds).replace(
      /"/g,
      '\\"'
    );

    if (!selectedProduct || !planEntity) {
      error("Please Select or ADD Product by Clicking on Add button");
      setLoading(false);
      return;
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
          {
            query: `
              mutation {
                createPlan(
                  currency: "USD"
                  isActive: false
                  name: "${plan}"
                  planEntity: "${planEntityString}"
                  price: "${price}"
                  productId: "${selectedProduct.id}"
                ) {
                  plan {
                    createdAt
                    currency
                    id
                    isActive
                    isDeleted
                    name
                    planEntity
                    price
                    updatedAt
                  }
                }
              }
            `,
          }
        );

        if (response.status === 200) {
          success("Plan Created Successfully");
          setToggleSwitch(false);
          setPrice("");
          setPlan("");

          // Update planOptions with the newly created plan
          setPlanOptions((prevOptions) => [
            ...prevOptions,
            response.data.data.createPlan.plan,
          ]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: GET_PRODUCTS,
        },
        {
          referrerPolicy: "no-referrer-when-downgrade",
        }
      );
      if (response.data.data) {
        const newProductOptions = [
          ...productOptions, // Merge existing productOptions with new data
          ...response.data.data.products,
        ];
        setProductOptions(newProductOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProduct();
    fetchPlan();
  }, []);
  const handleBusinessChange = (event, selectedOption) => {
    setBusinessId(selectedOption ? selectedOption : null);
  };
  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <div>
          <ModalData fetchProduct={fetchProduct} onClose={handleModalClose} />
        </div>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <div className="w-100 d-sm-flex !m-n2">
          <div className="p-2 w-100">
            <InputTextField
              id="subscription"
              label="Subscription Title"
              name="subscription"
              placeholder="Enter Title for Subscription"
              //size={20}
              value={values.subscription}
              touched={touched.subscription}
              error={touched.subscription && errors.subscription}
              onChange={handleChange}
              variant="filled"
              autoFocused
              sx={{
                backgroundColor: "#fff",
              }}
            />
          </div>
          <div className="p-2 w-100">
            <Autocomplete
              disablePortal
              options={[
                { id: 1, value: "Annual" },
                { id: 2, value: "Monthly" },
              ]}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.value}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(e, values) => {
                setFieldValue("paymentMethod", values);
              }}
              value={values.paymentMethod || null}
              sx={{
                backgroundColor: "#fff",
                border: "0px solid #fff",
              }}
              renderInput={(params) => (
                <CustomTextField
                  variant="filled"
                  sx={{
                    border: "0px solid #fff",
                    borderRadius: "5px",
                  }}
                  {...params}
                  label="Payment Method"
                  placeholder="Select Payment method"
                  onChange={handleChange}
                />
              )}
            />
          </div>
        </div>
        <div className="w-100 d-sm-flex !m-n2">
          <div className="p-2 w-100">
            <InputDate
              id="start-date"
              label=" Active From"
              name="activeFrom"
              variant="filled"
              minDate={dayjs(values.startDate)}
              maxDate={
                !values.repeatedDates
                  ? dayjs(values.activeFrom).add(30, "day")
                  : dayjs(values.activeFrom).add(365, "day")
              }
              value={dayjs(values.activeFrom)}
              helperText={touched.activeFrom && errors.activeFrom}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                ".css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
                  height: "2.5em",
                },
              }}
              onChange={(newValue) => {
                setFieldValue("activeFrom", FormateDate(newValue));
              }}
            />
          </div>
          <div className="p-2 w-100">
            <InputDate
              id="end-date"
              label="Active Till"
              name="activeTill"
              variant="filled"
              format="MMMM Do YYYY"
              minDate={dayjs(values.activeFrom)}
              value={dayjs(values.activeTill)}
              touched={touched.activeTill}
              error={touched.activeTill && errors.activeTill}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                ".css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
                  height: "2.5em",
                },
              }}
              onChange={(newValue) => {
                setFieldValue("activeTill", FormateDate(newValue));
              }}
            />
          </div>
        </div>
        <div className="w-100 d-sm-flex !m-n2">
          <div className="p-2 w-100">
            <Autocomplete
              disablePortal
              options={planOptions.map((plan) => ({
                id: plan.id,
                name: plan.name,
              }))}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              onChange={handlePlanChange}
              value={selectedPlan || null}
              sx={{
                backgroundColor: "#fff",
                border: "0px solid #fff",
              }}
              renderInput={(params) => (
                <CustomTextField
                  variant="filled"
                  sx={{
                    border: "0px solid #fff",
                    borderRadius: "5px",
                  }}
                  {...params}
                  label="Select Plan"
                  placeholder="Select Plan"
                  onChange={handleChange}
                />
              )}
            />
          </div>
          <div className="p-2 w-100">
            <Autocomplete
              disablePortal
              options={productOptions.map((product) => ({
                id: product.id,
                name: product.name,
              }))}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              onChange={handleProductChange}
              value={selectedProduct || null}
              sx={{
                backgroundColor: "#fff",
                border: "0px solid #fff",
              }}
              renderInput={(params) => (
                <CustomTextField
                  variant="filled"
                  sx={{
                    border: "0px solid #fff",
                    borderRadius: "5px",
                  }}
                  {...params}
                  label="Select Product for Plan"
                  placeholder="Select Product"
                  onChange={handleChange}
                />
              )}
            />
          </div>
          <div className="p-2 w-100">
            <Autocomplete
              disablePortal
              options={
                all_business &&
                all_business.map((business) => ({
                  id: business.id,
                  name: business.name,
                }))
              }
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              onChange={handleBusinessChange}
              value={businessId || null}
              sx={{
                backgroundColor: "#fff",
                border: "0px solid #fff",
              }}
              renderInput={(params) => (
                <CustomTextField
                  variant="filled"
                  sx={{
                    border: "0px solid #fff",
                    borderRadius: "5px",
                  }}
                  {...params}
                  label="Business"
                  placeholder="Select Business"
                  onChange={handleChange}
                />
              )}
            />
          </div>
        </div>

        <div className="d-flex ms-2 mt-2 flex-wrap  flex-column">
          <div className="d-flex flex-wrap justify-content-between">
            {toggleSwitch ? (
              <span className="d-flex subscribe">Add Plan</span>
            ) : (
              <span className="d-flex subscribe">Subscription Features</span>
            )}
            <div className="">
              <span>Add plan</span>
              <Switch
                id="toggle-switch"
                type="checkbox"
                checked={toggleSwitch}
                onChange={() => setToggleSwitch(!toggleSwitch)}
              />
            </div>
          </div>
          {toggleSwitch && (
            <div className="card mt-3">
              <div className="w-100 d-sm-flex !m-n2">
                <div className="p-2 w-100">
                  <InputTextField
                    id="plan"
                    label="Plan Title"
                    name="planName"
                    placeholder="Enter Title for Plan"
                    //size={20}
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    variant="filled"
                    autoFocused
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
                <div className="p-2 w-100">
                  <InputTextField
                    id="price"
                    label="Price"
                    name="price"
                    placeholder="Enter Price"
                    //size={20}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    variant="filled"
                    autoFocused
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
                <div className="p-2 mt-3 d-flex justify-content-end">
                  <MdAddCircleOutline
                    color="#538dff"
                    size={30}
                    onClick={handleModalOpen}
                  />
                </div>
              </div>
              <div className="p-2 pe-5 d-flex justify-content-end">
                <Button
                  type="submit"
                  sx={{
                    width: "100px",
                    backgroundColor: "#538dff",
                    height: "30px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                  }}
                  onClick={(e) => submitPlan(e)}
                  variant="contained"
                >
                  {loadings ? <ClipLoader color="#fffff" size={30} /> : "Save"}{" "}
                </Button>
              </div>
            </div>
          )}
          <div className="card mt-3">
            <div className="w-100 d-sm-flex !m-n2">
              <div className="p-2 w-100">
                <InputTextField
                  id="input-with-icon-textfield-search"
                  placeholder="Add New Feature"
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  sx={{
                    backgroundColor: "#fff",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        type="button"
                        sx={{
                          width: "80px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleAddFeature}
                        variant="contained"
                      >
                        {loader ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : (
                          <>
                            <MdAddCircleOutline color="#fff" /> &nbsp; Add
                          </>
                        )}
                      </Button>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="p-2 w-100 ">
                <InputTextField
                  disabled
                  id="input-with-icon-textfield-search"
                  placeholder="Add New Feature"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        disabled
                        type="submit"
                        sx={{
                          width: "80px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        <MdAddCircleOutline color="#fff" /> &nbsp; Add
                      </Button>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className="d-flex ">
              <div className="d-flex">
                <div className="ps-3">
                  <FireNav
                    features={features}
                    setSelectedFeatures={setSelectedFeatures}
                    selectedFeatures={selectedFeatures}
                  />
                </div>
                <div className="d-flex flex-column ">
                  <span className="select pt-2 ms-3">
                    Please Select Sample Text
                  </span>

                  <div>
                    <div className="p-2 ms-5 w-100">
                      <InputTextField
                        id="input-with-icon-textfield-search"
                        label="User"
                        name="subscription"
                        variant="filled"
                        placeholder="Enter Number of User"
                        sx={{
                          width: "100%",
                          maxWidth: "505px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row ms-3 mt-4  ">
                    <div className="col-md-12 d-flex justify-content-center ps-0 use">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckCheckedDisabled"
                          checked
                          disabled
                        />
                        <label
                          className="form-check-label record ms-2"
                          htmlFor="flexCheckCheckedDisabled"
                        >
                          Automatic Scheduling
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mr-4">{/* Other form fields */}</div>
        <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
          <div className="p-2">
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#edf0f5",
                },
              }}
              variant="contained"
              onClick={() => {
                window.location.href = "/subscription";
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="p-2">
            <Button
              type="submit"
              sx={{
                width: "150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              {loading ? <ClipLoader color="#fffff" size={30} /> : "Save"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default FormData;
