import { createSlice } from '@reduxjs/toolkit';
import  moment from 'moment'
const initialState ={
  date:new Date(),
  dateApi:moment().format('YYYY-MM-DD'),
}

const CreateSlice =createSlice({
    name:'date',
    initialState,
    reducers:{
        nextdate:(state)=>{
          state.date=moment(state.date).add(1,'days').format('llll')
         state.dateApi=moment(state.dateApi).add(1,'days').format('YYYY-MM-DD')
        },
        prevdate:(state)=>{
          state.date=moment(state.date).subtract(1, 'days').format('llll')
          state.dateApi=moment(state.dateApi).subtract(1, 'days').format('YYYY-MM-DD')
          },
        handlechange:(state, action)=>{
          state.date=action.payload;
          state.dateApi=moment(action.payload).format('YYYY-MM-DD')
          },
          resetdate:(state)=>{
            state.date=new Date()
            state.dateApi=moment().format('YYYY-MM-DD')
            },
    }
})
export const {nextdate,prevdate,handlechange, resetdate} = CreateSlice.actions;
export const selectData = state => state.CreateSlice.dateApi;
export const counterReducer= CreateSlice.reducer;