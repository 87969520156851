import React, { useState } from "react";
import "./notes.css";
import {
  LowerAngle,
  RightAngle,
} from "../../Calendar/scheduler/AppointmentDetails/Svgs/svgs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const RightSection = ({ bookingData }) => {
  const history = useHistory();
  const data = {
    Services: [
      "Laser Hair Removal",
      "Laser Hair Removal",
      "Laser Hair Removal",
      "Laser Hair Removal",
    ],
  };

  return (
    <>
      {bookingData?.appointments && bookingData?.appointments.length > 0 && (
        <div className="all-appointments-section">
          <label className="section-heading m-1">Status of Booking</label>
          <div className="all-appointments-section-container ">
            {/* {data.Services.map((service, index) => ( */}
            <div className="">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="all-appointments_dates">
                  {bookingData?.appointments[0]?.status}
                </h6>
                <RightAngle />
              </div>
              {/* {index < data.Services.length - 1 ? (
                <hr className="left-section-container-lines" />
              ) : (
                ""
              )} */}
            </div>
            {/* ))} */}
          </div>
        </div>
      )}
    </>
  );
};

export default RightSection;
