import { Avatar, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customSnackBar } from "../../../../utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessRequest,
  updateBusinessSuccess,
} from "../../../../Store/Business/actions";
import { getFormData } from "../../../../Store/dynamicForm/slice";
import instance from "../../../../utils/utils";

const BannerProfile = ({ businesProfile, isMobile }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = businesProfile.id;
  const userID = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateimage, setUpdateimage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const profileImageURL = `${process.env.REACT_APP_IMAGE_URL}/Business/${businesProfile?.id}_0.jpeg`;
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );
  

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);
  const handleAddUser = (row) => {
    localStorage.setItem("businessRecord", JSON.stringify(row));

    history.push(`/users`, row);
    // handleDialogClose();
  };

  const handleFileChange = async (event) => {
    setLoading(true);

    const selectedFile = event.target.files[0];

    // Validate file type (allow only jpg and jpeg)
    const allowedFileTypes = ["image/jpeg", "image/jpg","image/png"];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Create form data
      const formData = new FormData();
      formData.append("Id", businesProfile?.id);
      formData.append("ServiceName", "Business");
      formData.append("Source", "Plexaar");
      formData.append("Files", selectedFile);
      formData.append("Category", "main");
      formData.append("FileType", "i");
      formData.append("CreatedBy", 10);

      try {
        // Send the image data to the API endpoint
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL_EXPERT}/serviceinventory_svc/pb/ServiceAttachment/UploadAttachment`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setUpdateimage(response?.data?.paths[0]);
        // window.location.reload()
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error uploading image:", error);
      }
    } else {
      // Display an alert for invalid file type
      customSnackBar("Invalid file type. Please select a jpg or jpeg file.");
    }
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (
        businesProfile?.imageURL === null ||
        businesProfile?.imageURL === ""
      ) {
        setProfileImage(
          <Avatar className=" img_profile_shape1" alt="nodata" />
        );
      } else {
        const imageURL = `${process.env.REACT_APP_IMAGE_URL}/Business/${businesProfile?.id}_0.jpeg`;
        if (profileImageURL) {
          setProfileImage(
            <img
              className=" img_profile_shape1"
              src={profileImageURL}
              alt="no-data"
            />
          );
        } else {
          setProfileImage(
            <Avatar className=" img_profile_shape1" alt="nodata" />
          );
        }
      }
    };

    fetchProfileImage();
  }, [profileImageURL]);

  useEffect(() => {
    if (updateimage) {
      const postImageData = async () => {
        // setIsloadingImage(true);
        try {
          let data = {
            // id: businesProfile?.id,
            imageUrl: updateimage,
            // modifiedBy: registersuccessId,
          };
          // const response = await axios.put(
          //   `${process.env.REACT_APP_BASE_URL}/signup_svc/pv/users/changeUserImage`,
          //   data
          // );
          // customSnackBar(response.data.message);
          // // fetchUser();
          dispatch(updateBusinessSuccess({ data, setLoading, id, userID }));

          // dispatch(getBusinessRequest({ id, setLoading }));

          // window.location.reload()
        } catch (error) {
          customSnackBar(error);
        } finally {
          setLoading(false);
        }
      };
      postImageData();
    }
  }, [updateimage, businesProfile?.id]);

  const handleAddDepartment = (row) => {
    localStorage.setItem("businessRecord", JSON.stringify(row));

    history.push(`/department`, row);
    // handleDialogClose();
  };
  const handleStaffAdd = (row) => {
    localStorage.setItem("businessRecord", JSON.stringify(row));

    history.push(`/staff-list`, row);
    // handleDialogClose();
  };
  const handleSchulaer = (row) => {
    localStorage.setItem("businessRecord", JSON.stringify(row));
    let action = "businessProfile";
    history.push(`/add-business-schedular/${businesProfile?.id}`, action);
    // handleDialogClose();
  };
  return (
    <div className={`${!isMobile ? "card card-content " : ""}`}>
      <div className="d-flex  justify-content-between">
        {isMobile ? (
          <div className={`${!isMobile ? "d-none " : ""}`}>
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              size="small"
              // sx={{ mr: 5 }}
              // className="mb-3"
            >
              <KeyboardBackspaceIcon sx={{ fontSize: 24, color: "#AAAAAA" }} />
            </IconButton>
          </div>
        ) : null}
        <div
          className={`${
            !isMobile
              ? "ps-2 d-flex justify-content-evenly"
              : "d-flex flex-column justify-content-evenly"
          }`}
        >
          {loading ? (
            <div
              style={{
                width: 100,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="spinner-border text-primary mt-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : businesProfile?.imageURL === null ||
            businesProfile?.imageURL === "" ? (
            <Avatar className="img_profile_shape1" alt="nodata" />
          ) : (
            <img
              className=" img_profile_shape1"
              src={
                businesProfile
                  ? businesProfile?.imageUrl
                  : "/images/profile.png"
              }
              alt=""
            />
          )}

          <div className="margin_neg_button1">
            <div className="upload-btn-wrapper">
              {/* <div className="btnn"> */}
              <img
                className="pt-2 ps-1"
                src="../images/camera.svg"
                alt="nodata"
              />
              {/* </div> */}
              <input type="file" name="image" onChange={handleFileChange} />
            </div>
          </div>
          <div className={`${!isMobile ? "p-4 " : "text-center"}`}>
            <h6 className="mb-1 text-capitalize">{businesProfile?.name} </h6>
            <p className="mb-0 text_color_account1 text-capitalize">
              Business Account
            </p>
            <p className="mb-0 id_text_color">ID {businesProfile?.id}</p>
          </div>
        </div>
        <div className="mt-md-4">
          <BsThreeDotsVertical
            fontSize={25}
            color="#BEC3C7"
            onClick={handleClick}
          />
        </div>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ "&:hover": { backgroundColor: "text-primary" } }}
        >
          <>
            <MenuItem
              className="pr-5 px-3 text-success"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleAddDepartment(businesProfile);
              }}
            >
              <img
                src="/images/Group 515743.svg"
                alt=""
                className="text-primary"
                // sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
                sx={{ "&:hover": { color: "white" } }} // Change text color on hover
              >
                Department
              </span>
            </MenuItem>

            <MenuItem
              className="pr-5 px-3 text-success"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleAddUser(businesProfile);
              }}
            >
              <img
                src="/images/users 1.svg"
                alt=""
                className="text-primary"
                // sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                User
              </span>
            </MenuItem>
            <MenuItem
              className="pr-5 px-3"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleStaffAdd(businesProfile);
                // handleEditClick(row);
              }}
            >
              <img
                src="/images/file-clock (1) 1.svg"
                alt=""
                className="text-primary"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Staff
              </span>
            </MenuItem>

            <MenuItem
              className="pr-5 px-3"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleSchulaer(businesProfile);
                // handleEditClick(row);
              }}
            >
              <img
                src="/images/alarm-check 1.svg"
                alt=""
                className="text-primary"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Busiess Hour
              </span>
            </MenuItem>
             <MenuItem
              className="pr-5 px-3"
              onClick={() => {
                dispatch(getFormData(businesProfile?.id));
                history.push("/preview-page");
              }}
            >
              <img
                src="/images/file-clock (1) 1.svg"
                alt=""
                className="text-primary"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Create Customer Form
              </span>
            </MenuItem>
            {/*<MenuItem
            className="pr-5 px-3"
            onClick={()=>{
              dispatch(getFormData(businesProfile?.id));
              history.push("/view-page");
            }}
            >
              <img
                src="/images/file-clock (1) 1.svg"
                alt=""
                className="text-primary"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                View Customer Form
              </span>
            </MenuItem> */}
          </>
        </Menu>
      </div>
    </div>
  );
};
export default BannerProfile;
