import React from "react";
import "./Footer.css";

export const Footer = () => {
    return(
        <div className="col-md-12 footer_background ">
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-8 text-center">
                                    <p className="footer_text">Terms & Conditions</p>
                                </div>
                                <div className="col-md-4">
                                    <p className="footer_text">Privacy</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
                <div className="col-md-6 text-end">
                    <p className="footer_text">© 2021 Plexaar. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    );
};