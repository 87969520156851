// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event_listing_custom_date_picker__AZZs7 {
    border: none; 
    background-color: transparent; /* Set the background color to transparent */
    font-size: 20px; 
    font-weight: 600; 
    color: black;
  } `, "",{"version":3,"sources":["webpack://./src/Pages/listing/event_listing/event_listing.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B,EAAE,4CAA4C;IAC3E,eAAe;IACf,gBAAgB;IAChB,YAAY;EACd","sourcesContent":[".custom_date_picker {\n    border: none; \n    background-color: transparent; /* Set the background color to transparent */\n    font-size: 20px; \n    font-weight: 600; \n    color: black;\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_date_picker": `event_listing_custom_date_picker__AZZs7`
};
export default ___CSS_LOADER_EXPORT___;
