// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_card_list {
  border: 1px solid lightgray;
  border-radius: 8px;
  color: rgb(48, 40, 68);
}

.payment_card_list .search_group {
  width: auto !important;
  border-radius: 40px;
  color: rgb(87, 85, 85) !important;
  border: 1px solid lightgray;
  padding: 4px;
  padding-right: 15px;
}

.payment_card_list button, .payment_card_list input, .payment_card_list span {
  border: none;
}

.payment_card_list input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.pmethod_img {
  height: 250px;
}

.collapse_card {
  height: 150px;
  overflow: scroll;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Payment-react/payment.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,kBAAA;EACA,sBAAA;AACJ;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,iCAAA;EACA,2BAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACI,YAAA;AACJ;;AAEE;EACE,2BAAA;EACA,wBAAA;AACJ;;AAEE;EACE,aAAA;AACJ;;AAEE;EACE,aAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;AACJ","sourcesContent":[".payment_card_list{\n    border: 1px solid lightgray;\n    border-radius: 8px;\n    color: rgb(48, 40, 68);\n}\n\n.payment_card_list .search_group{\n  width: auto !important;\n  border-radius: 40px;\n  color: rgb(87, 85, 85) !important;\n  border: 1px solid lightgray;\n  padding: 4px;\n  padding-right: 15px;\n}\n\n.payment_card_list button, .payment_card_list input, .payment_card_list span{\n    border: none;\n  }\n\n  .payment_card_list input:focus{\n    box-shadow: none !important;\n    outline: none !important;\n  }\n\n  .pmethod_img{\n    height: 250px;\n  }\n\n  .collapse_card{\n    height: 150px;\n    overflow: scroll;\n  }\n\n  .hide_scrollbar::-webkit-scrollbar{\n    display: none;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
