export const base_url =
  `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pb`;
export const staff_base_url =
  `${process.env.REACT_APP_BASE_URL}/staff_svc/pv`;
export const docs_base_url =
  `${process.env.REACT_APP_BASE_URL}/document_svc/pb`;
export const meetspace_base_url = "http://77.68.73.28:8003/schedule_svc";

export const services_base_url = "https://";

