import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  adduserprofile,
  loginUser,
  uploadprofileimage,
} from "../../../Store/Auth/actions";
import Button from "../../../Shared/Button/Button";
import InputField from "../../../Shared/InputField";
import Headerauth from "../Headerauth/Headerauth";
import "../Login/Login.scss";
import axios from "axios";
import InputTextField from "../../BusinessProfile/TextField";
import {customSnackBar} from "../../../utils";
import instance from "../../../utils/utils";

export default function Profileinformation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const userid = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  let history = useHistory();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [file, setFile] = useState(null);
  const [updatedImg, setUpdateimage] = useState(null);
  const uploadimg = updatedImg;
  const token = localStorage.getItem("token");
  const firstRef=useRef()
  const lastRef=useRef()
  const handleFileChange = async (event) => {
    // Create form data
    const formData = new FormData();
    formData.append("uploadImage", file);

    try {
      // Send the image data to the API endpoint
      const response = await instance.post(
        `/signup_svc/pv/users/addUserImage?userId=${userid}&environment=dev`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUpdateimage(response.data.result);
      customSnackBar(response.data.message);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    firstRef.current.focus(); // Focus on the password input field

    if (file) {
      handleFileChange();
      // dispatch(
      //   uploadprofileimage({
      //     id: userid,
      //     uploadimage: file,
      //     setIsLoading,
      //   })
      // );
    }
  }, [file]);

  const handleInputChange = (event) => {
    setFile(event.target.files[0]);
  };

  const firstnamefunc = (event) => {
    const valuew = event.target.value;
    setFirstname(valuew);
    setFirstNameError(validateName(valuew));
  };
  const lastnamefunc = (event) => {
    const valuew = event.target.value;
    setLastname(valuew);
    setLastNameError(validateName(valuew));
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }
    return "";
  };

  
  const handleFnamedEnterPress = () => {
    lastRef.current.focus(); // Focus on the password input field
  };

  const handleLastName = () => {
    submitprofile(); // Submit the form
  };
  const submitprofile = () => {
    dispatch(
      adduserprofile({
        id: userid,
        firstName: firstname,
        lastname: lastname,
        imageUrl: uploadimg?.imageUrl ? uploadimg?.imageUrl : "",
        modifiedBy: userid,
        setIsLoading,
      })
    );
  };

  return (
    <div className="login_height">
      <div className="container mb-5 pb-5">
          <div className="container paddings text-center alignment_vertically">
                  <div className="pb-3">
                    <img
                      className="img-fluid img_plexar_width"
                      src="../images/plexaar final 2 round-15 1.png"
                      alt="no-data"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div>
                      <img
                        className="img-fluid img_plexar_width"
                        src="../images/plexaar text-22 1.png"
                        alt="no-data"
                        style={{ width: "111px", height: "46" }}
                      />
                    </div>
                  </div>
                  <h3>
                    <b>
                      Profile <span className="plexar_font">Information</span>
                    </b>
                  </h3>
                  <p className="sub_heading text-wrap">
                    Dear user please add your personal information to complete Registration
                  </p>
                    <div className="p-2 w-100">
                      <InputTextField
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        placeholder="Enter First Name"
                        //size={20}
                        variant="filled"
                        onChange={firstnamefunc}
                        value={firstname}
                        autoFocused
                  inputRef={firstRef} // Set the ref for the password input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleFnamedEnterPress();
                    }
                  }}
                        sx={{
                          // maxWidth: "300px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="p-2 w-100">
                      <InputTextField
                        id="lastname"
                        label="Last Name"
                        name="firstName"
                        placeholder="Enter Last Name"
                        //size={20}
                        variant="filled"
                        onChange={lastnamefunc}
                        value={lastname}
                  inputRef={lastRef} // Set the ref for the password input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleLastName();
                    }
                  }}
                        autoFocused
                        sx={{
                          // maxWidth: "300px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="d-flex pt-3 ps-3">
                      {/* <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input pt-1"
                          id=""
                          onChange={(e) => setIsChecked(e.target.checked)}
                          checked={isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp; &nbsp;
                          <span className="font_label">
                            I agree to the Expert Terms of Services and Privacy
                            Policy
                          </span>
                        </label>
                      </div> */}

                      {/* <div className="form-group">
                            <input
                              type="checkbox"
                              className=""
                              id="customCheck1"
                              onChange={(e) => setIsChecked(e.target.checked)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1 "
                            >
                              <span className="font_label">
                                I agree to the Expert Terms of Services and
                                Privacy Policy mmm
                              </span>
                            </label>
                          </div> */}
                    </div>
                    {firstname === "" ||
                    lastname === "" 
                    // firstNameError ||
                    // lastNameError ||
                    // file === null||
                    // isChecked === false 
                    ? (
                      <button
                        className="btn btn-primary button_width_disabled btn-lg"
                        disabled
                        // onClick={() => history.push(`/add-number`)}
                      >
                        {" "}
                        Confirm{" "}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary button_width btn-lg"
                        onClick={submitprofile}
                        // onClick={() => history.push(`/add-number`)}
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>Confirm</>
                        )}
                      </button>
                    )}
          </div>
      </div>
      <div className="bottom_attach px-3 pt-2 center_copyright">
          <div className="show_privacy_policy">
            <p className="sub_heading2" role="button" onClick={()=>history.push("/privacy")}>Privacy Policy</p> &nbsp; &nbsp; &nbsp;
            <p className="sub_heading2" role="button" onClick={()=>history.push("/terms")}>Terms & Conditions</p>
          </div>
          <p className="sub_heading2">
            Copyright © 2023 Plexaar. All rights reserved.
          </p>
      </div>
    </div>
  );
}
