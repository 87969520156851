import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { putRequest } from '../../../request';
import { base_url } from '../../../base_url';
import { useDispatch, useSelector } from 'react-redux';
import { MiniLoader } from '../../ServiceLoader';
import { miniLoadingAction } from '../../../store/ServicesSlice';

const DetailForm = () => {
    const [serviceName, setServiceName] = useState('');
    const [serviceSKU, setServiceSKU] = useState('');
    const [serviceURL, setServiceURL] = useState('');
    const [serviceDuration, setServiceDuration] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [longtDesc, setLongtDesc] = useState('');
    const [consentForm, setConsentForm] = useState('');
    const [availableSeats, setAvailableSeats] = useState();
    const [maxSessions, setMaxSessions] = useState();
    const [isInHouse, setIsInHouse] = useState(false);
    const [isClinical, setIsClinical] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    const [hasPrice, setHasPrice] = useState(false);
    const [isAddOn, setIsAddOn] = useState(false);
    const [hasSession, setHasSession] = useState(false);
    const [hasAttribute, setHasAttribute] = useState(false);
    const [hasProdCompulsory, setHasProdCompulsory] = useState(false);
    const [isRequiredDOB, setIsRequiredDOB] = useState(false);
    const [isGenderPreference, setIsGenderPreference] = useState(false);
    const [hasPackage, setHasPackage] = useState(false);
    const [hasProductOptional, setHasProductOptional] = useState(false);
    const [hasChild, setHasChild] = useState(false);

    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);

    const updateData = async () => {
        dispatch(miniLoadingAction())
        const data = {
            id: id,
            parentId: 0,
            bookedSeats: 0,
            isActive: store?.serviceToUpdate[0]?.isActive,
            sku: serviceSKU,
            name: serviceName,
            short_Desc: shortDesc ?? '',
            long_Desc: longtDesc ?? '',
            url: serviceURL,
            consentForm: consentForm ?? '',
            parentId: 0,
            duration: parseInt(serviceDuration),
            availableSeats: parseInt(availableSeats) ?? 0,
            countryId: 188,
            maxSesssion: parseInt(maxSessions) ?? 0,
            isInHouseSKU: 'inhouse',
            isInHouse: isInHouse,
            isInClinicSKU: 'inclinic',
            isInClinic: isClinical,
            isOnlineSKU: 'online',
            isOnline: isOnline,
            hasPrice: hasPrice ?? false,
            isAddOn: isAddOn ?? false,
            hasSession: hasSession ?? false,
            hasAttribute: hasAttribute ?? false,
            hasProductCompulsory: hasProdCompulsory ?? false,
            isRequiredDOB: isRequiredDOB ?? false,
            isRequiredGenderPreference: isGenderPreference ?? false,
            hasPackage: hasPackage ?? false,
            hasProductOptional: hasProductOptional ?? false,
            hasChild: hasChild,
        }

        const res = await putRequest(`${base_url}/Service/UpdateService`, data);
        if (res?.message === "succeeded" || res?.message === "success") {
            dispatch(miniLoadingAction())
            alert('Service Updated Successfully');
            history.push('/all-services');
        }
        else {
            dispatch(miniLoadingAction())
            alert(res?.message);
        }
    }


    useEffect(() => {
        if (store?.serviceToUpdate && store?.serviceToUpdate.length > 0) {
            setServiceName(store?.serviceToUpdate[0]?.serviceName);
            setServiceSKU(store?.serviceToUpdate[0]?.serviceSKU);
            setServiceURL(store?.serviceToUpdate[0]?.serviceUrl);
            setServiceDuration(store?.serviceToUpdate[0]?.duration);
            setIsInHouse(store?.serviceToUpdate[0]?.isInHouse);
            setIsClinical(store?.serviceToUpdate[0]?.isInClinic);
            setIsOnline(store?.serviceToUpdate[0]?.isOnline);
            setAvailableSeats(store?.serviceToUpdate[0]?.availableSeats);
            setMaxSessions(store?.serviceToUpdate[0]?.maxSesssions);
            setShortDesc(store?.serviceToUpdate[0]?.shortDesc);
            setLongtDesc(store?.serviceToUpdate[0]?.longDesc);
            setConsentForm(store?.serviceToUpdate[0]?.consentForms);
            setHasPrice(store?.serviceToUpdate[0]?.hasPrice);
            setIsAddOn(store?.serviceToUpdate[0]?.isAddOn);
            setHasSession(store?.serviceToUpdate[0]?.hasSessions);
            setHasAttribute(store?.serviceToUpdate[0]?.hasAttributes);
            setHasProdCompulsory(store?.serviceToUpdate[0]?.hasProductCompulsory);
            setIsRequiredDOB(store?.serviceToUpdate[0]?.isRequiredDOB);
            setIsGenderPreference(store?.serviceToUpdate[0]?.isRequiredGenderPreference);
            setHasPackage(store?.serviceToUpdate[0]?.hasPackage);
            setHasProductOptional(store?.serviceToUpdate[0]?.hasProductOptional);
            setHasChild(store?.serviceToUpdate[0]?.hasChild);
        }
    }, [store?.serviceToUpdate.length > 0])

    return (
        <>
            <div className='container detail_form hide_scrollbar g-0 pc_display'>
                {/* <h6 className='text-dark'>Create New Service</h6> */}
                <form className='create_form'>
                    <div className="row create_form_row mt-3">
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service Name</label>
                                <input type="text" className="form-control" placeholder="Enter Service Name" name="service-name"
                                    value={serviceName}
                                    onChange={(e) => setServiceName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service SKU</label>
                                <input type="text" className="form-control" placeholder="Enter service SKU" name="sku"
                                    value={serviceSKU}
                                    onChange={(e) => setServiceSKU(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row mt-3">
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service URL</label>
                                <div className="input-group">
                                    <span className="input-group-text span_input_url pe-3">{'http:www.chelsford/services/'}</span>
                                    <input type="text" className="form-control" placeholder="service url..."
                                        value={serviceURL}
                                        onChange={(e) => setServiceURL(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="uname" className="form-label">Service Duration</label>
                                <div className="input-group">
                                    <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                        value={serviceDuration}
                                        onChange={(e) => setServiceDuration(e.target.value)}
                                    >
                                        <option value="15">15 Minute</option>
                                        <option value="30">30 Minutes</option>
                                        <option value="60">60 Minutes</option>
                                        <option value="120">2 Hours</option>
                                        <option value="180">3 Hours</option>
                                        <option value="240">4 Hours</option>
                                        <option value="300">5 Hours</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row mt-3">
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="seats" className="form-label">Available Seats</label>
                                <input type="number" className="form-control" placeholder="Enter available seats" name="seats"
                                    value={availableSeats}
                                    onChange={(e) => setAvailableSeats(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="sessions" className="form-label">Maximum Sessions</label>
                                <input type="number" className="form-control" placeholder="Enter Maximum sessions" name="sessions"
                                    value={maxSessions}
                                    onChange={(e) => setMaxSessions(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row mt-3">
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="sdesc" className="form-label">Short Description</label>
                                <textarea rows="1" type="text" className="form-control" placeholder="Short description" name="sdesc"
                                    value={shortDesc}
                                    onChange={(e) => setShortDesc(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="ldesc" className="form-label">Long Description</label>
                                <textarea rows="1" type="text" className="form-control" placeholder="Long description" name="ldesc"
                                    value={longtDesc}
                                    onChange={(e) => setLongtDesc(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row mt-3">
                        <div className="col">
                            <div className='input_wrapper'>
                                <label for="consentform" className="form-label">Consent Form</label>
                                <textarea rows="1" type="text" className="form-control" placeholder="Consent Form" name="consentform"
                                    value={consentForm}
                                    onChange={(e) => setConsentForm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row create_form_row mt-3 input_wrapper pt-1 pb-2 g-0 px-2">
                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"
                                    checked={isInHouse}
                                    onChange={(e) => setIsInHouse(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault1">
                                    Is Inhouse
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                                    checked={isOnline}
                                    onChange={(e) => setIsOnline(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault2">
                                    Is Online
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3"
                                    checked={isClinical}
                                    onChange={(e) => setIsClinical(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault3">
                                    Is Clinincal
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault4"
                                    checked={hasPrice}
                                    onChange={(e) => setHasPrice(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault4">
                                    Has Price
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault5"
                                    checked={isAddOn}
                                    onChange={(e) => setIsAddOn(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault5">
                                    Is Add-On
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault6"
                                    checked={hasSession}
                                    onChange={(e) => setHasSession(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault6">
                                    Has Session
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault7"
                                    checked={hasAttribute}
                                    onChange={(e) => setHasAttribute(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault7">
                                    Has Attribute
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault8"
                                    checked={hasProdCompulsory}
                                    onChange={(e) => setHasProdCompulsory(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault8">
                                    Has Product Compulsory
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault9"
                                    checked={isRequiredDOB}
                                    onChange={(e) => setIsRequiredDOB(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault9">
                                    Is required DOB
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault10"
                                    checked={isGenderPreference}
                                    onChange={(e) => setIsGenderPreference(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault10">
                                    Is required Gender Prefrence
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault11"
                                    checked={hasPackage}
                                    onChange={(e) => setHasPackage(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault11">
                                    Has Package
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12"
                                    checked={hasProductOptional}
                                    onChange={(e) => setHasProductOptional(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault12">
                                    Has Product Optional
                                </label>
                            </div>
                        </div>

                        <div className='sm-12 col-4'>
                            <div className="form-check ps-4">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12"
                                    checked={hasChild}
                                    onChange={(e) => setHasChild(e.target.checked)}
                                />
                                <label className="form-check-label checkbox_lbl" for="flexCheckDefault12">
                                    Has Child
                                </label>
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row mt-5'>
                    <div className='col-12 d-flex justify-content-end'>
                        <button type="button" className="btn text-dark cancel_btn px-5 me-3"
                            onClick={() => history.push('/all-services')}
                        >Cancel</button>
                        <button type="button" className="btn theme_btn_color px-5"
                            onClick={updateData}
                        >
                            {/* {store?.miniLoading ? <MiniLoader /> : */}
                            Update
                            {/* } */}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailForm