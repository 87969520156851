import React, { useEffect } from "react";
import SideNavone from "../../../../SideNavBar";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { format, parse } from "date-fns";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function ViewSchedulerProvider() {
  const history = useHistory();
  const [providerData, setProviderData] = React.useState();
  const [activeProvider, setActiveProvider] = React.useState();
  const [slotProvider, setSlotProvider] = React.useState();
  const [slotProviderKeys, setSlotProviderKeys] = React.useState();
  const [editModule, setEditModule] = React.useState("");
  const [updateType, setUpdateType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const providerDatas = JSON.parse(localStorage.getItem("providerView"));
    setActiveProvider(providerDatas);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/detail?provider=${providerDatas.providerId}`
      )
      .then((res) => {
        setProviderData(res.data.result);
        AddSchedule(providerDatas, res.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleYearMonthDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  function getFirstAndLastDateOfMonth(monthNumber) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based

    // Get the first day of the month
    const firstDate = new Date(year, month, 1);
    const formattedFirstDate = `${year}-${String(month + 1).padStart(
      2,
      "0"
    )}-${String(firstDate.getDate()).padStart(2, "0")}`;

    let targetMonth;

    if (monthNumber === 1) {
      targetMonth = currentMonth;
    } else if (monthNumber === 2) {
      targetMonth = currentMonth + 1;
    } else if (monthNumber === 3) {
      targetMonth = currentMonth + 3;
    } else if (monthNumber === 4) {
      targetMonth = currentMonth + 1;
    } else {
      return null; // Invalid offset
    }

    const lastDate = new Date(year, targetMonth, 0);
    const formattedLastDate = `${year}-${String(targetMonth).padStart(
      2,
      "0"
    )}-${String(lastDate.getDate()).padStart(2, "0")}`;

    return { formattedFirstDate, formattedLastDate };
  }

  function compareDates(startDate, newMonthDate) {
    const dateObject1 = new Date(startDate);
    const dateObject2 = new Date(newMonthDate);

    if (dateObject1 <= dateObject2) {
      return true; // date1 is equal to or greater than date2
    } else {
      return false; // date1 is smaller than date2
    }
  }

  const AddSchedule = (providerDatas, scheduleType) => {
    const now = new Date();
    const seventhDate = new Date(now);
    var startDate = "";
    var endDate = "";
    if (scheduleType.repeatPattern === "every_week") {
      seventhDate.setDate(now.getDate() + 6);
      startDate = handleYearMonthDate(now);

      endDate = handleYearMonthDate(seventhDate);
    } else if (scheduleType.repeatPattern === "every_two_week") {
      seventhDate.setDate(now.getDate() + 13);
      startDate = handleYearMonthDate(now);
      endDate = handleYearMonthDate(seventhDate);
    } else if (scheduleType.repeatPattern === "every_three_week") {
      seventhDate.setDate(now.getDate() + 20);
      startDate = handleYearMonthDate(now);
      endDate = handleYearMonthDate(seventhDate);
    } else if (scheduleType.repeatPattern === "every_four_week") {
      seventhDate.setDate(now.getDate() + 27);
      startDate = handleYearMonthDate(now);
      endDate = handleYearMonthDate(seventhDate);
    } else if (scheduleType.repeatPattern === "every_month") {
      const {
        formattedFirstDate,
        formattedLastDate,
      } = getFirstAndLastDateOfMonth(1);
      const slotType = compareDates(scheduleType.startDate, formattedFirstDate);
      startDate = slotType ? formattedFirstDate : scheduleType.startDate;
      endDate = formattedLastDate;
    } else if (scheduleType.repeatPattern === "every_two_month") {
      const {
        formattedFirstDate,
        formattedLastDate,
      } = getFirstAndLastDateOfMonth(2);
      const slotType = compareDates(scheduleType.startDate, formattedFirstDate);
      startDate = slotType ? formattedFirstDate : scheduleType.startDate;
      endDate = formattedLastDate;
    } else if (scheduleType.repeatPattern === "every_three_month") {
      const {
        formattedFirstDate,
        formattedLastDate,
      } = getFirstAndLastDateOfMonth(3);
      const slotType = compareDates(scheduleType.startDate, formattedFirstDate);
      startDate = slotType ? formattedFirstDate : scheduleType.startDate;
      endDate = formattedLastDate;
    } else if (scheduleType.repeatPattern === "every_four_month") {
      const {
        formattedFirstDate,
        formattedLastDate,
      } = getFirstAndLastDateOfMonth(4);
      const slotType = compareDates(scheduleType.startDate, formattedFirstDate);
      startDate = slotType ? formattedFirstDate : scheduleType.startDate;
      endDate = formattedLastDate;
    }

    const data = {
      providerId: [providerDatas?.providerId],
      startDate: startDate,
      endDate: endDate,
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/get/provider/schedule/`,
        data
      )
      .then((res) => {
        setSlotProvider(res.data.result);
        setLoading(false);
        const allKeys = [];
        res.data?.result?.map((val, key) => {
          const keys = Object.keys(val);
          allKeys.push({ id: key, keys: keys });
        });

        setSlotProviderKeys(allKeys);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  useEffect(() => {
    if (slotProviderKeys?.length > 0) {
      console.warning(slotProviderKeys[0]?.keys[3]);
    }
  }, [slotProviderKeys]);
  const handleStartTimeChange = (index, newValue) => {
    const date = new Date(newValue);
    const formattedTime = format(date, "HH:mm:ss");
    const updatedSlots = [...editModule.slots];
    updatedSlots[index].startTime = formattedTime;
    // Update the state with the new slots array
    setEditModule({ ...editModule, slots: updatedSlots });
  };

  const handleEndTimeChange = (index, newValue) => {
    const date = new Date(newValue);
    const formattedTime = format(date, "HH:mm:ss");
    const updatedSlots = [...editModule.slots];
    updatedSlots[index].endTime = formattedTime;
    // Update the state with the new slots array
    setEditModule({ ...editModule, slots: updatedSlots });
  };
  const handleUpdateRecord = () => {
    const appointmentData = {
      date: slotProvider,
      data: providerData,
      actionState: "edit-schedular",
    };
    history.push(
      `/timeSlot?busiessId=${activeProvider?.businessId}&providerId=${activeProvider?.providerId}`,
      { appointmentData }
    );
  };

  const handleUpdate = () => {
    const convertedSlots = {
      startTime: [],
      endTime: [],
    };
    editModule.slots.forEach((slot) => {
      convertedSlots.startTime.push(slot.startTime);
      convertedSlots.endTime.push(slot.endTime);
    });
    const data = {
      provider: editModule.provider,
      updateType: updateType ? 1 : 0,
      scheduleItems: [
        {
          startTime: convertedSlots.startTime,
          endTime: convertedSlots.endTime,
          date: editModule.date,
        },
      ],
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/provideravailability_svc/pb/update/schedule/`,
        data
      )
      .then((res) => {
        setEditModule("");
        AddSchedule();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <SideNavone>
      <div
        className="container p-0 hide_scrollbar position-relative pc_display"
        style={{ maxHeight: "80vh", overflow: "scroll" }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="py-1"
        >
          {activeProvider?.firstName} Schedule
        </Typography>
        <div className="w-100 d-flex justify-content-between align-items-center py-3 px-2">
          <p>
            Schedule Type : <b>{providerData?.repeatChoice}</b>
          </p>
          <p>
            Repeat Type : <b>{providerData?.repeatPattern}</b>
          </p>
          <p>
            {" "}
            Start Date : <b>{providerData?.startDate}</b>
          </p>
          <p>
            {" "}
            End Date : <b>{providerData?.endDate}</b>
          </p>
        </div>
        {loading ? (
          <div className="loader-container">
            <CircularProgress size={40} />
          </div>
        ) : slotProvider === undefined ? (
          <>
            <p className="loader-container">Record Not Exist</p>
          </>
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <button onClick={handleUpdateRecord} className="schedular-button">
                Edit Complete Schedular
              </button>
            </div>
            <div className="w-100 d-flex justify-content-end align-items-center py-3 px-2">
              {editModule.provider ? (
                <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Grid item xs={2}>
                      <p>{editModule.day}</p>
                      <p>{editModule.date}</p>
                    </Grid>
                    <Grid item xs={7}>
                      {editModule.slots.map((val, index) => (
                        <div key={index}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              label="Opening time"
                              value={parse(
                                val.startTime,
                                "HH:mm:ss",
                                new Date()
                              )}
                              onChange={(newValue) =>
                                handleStartTimeChange(index, newValue)
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              label="Closing time"
                              value={parse(val.endTime, "HH:mm:ss", new Date())}
                              onChange={(newValue) =>
                                handleEndTimeChange(index, newValue)
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      ))}
                    </Grid>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={
                            <Checkbox
                              checked={updateType}
                              onChange={(e) => {
                                setUpdateType(e.target.checked);
                              }}
                            />
                          }
                          label={`Update All ${
                            providerData?.repeatChoice === "weekly"
                              ? editModule.day
                              : editModule.date
                          }`}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      {editModule.provider ? (
                        <div className="w-25 d-flex justify-content-between align-items-center">
                          <Button
                            variant="text"
                            onClick={(e) => {
                              setEditModule("");
                            }}
                          >
                            Cancel
                          </Button>
                          <Button variant="contained" onClick={handleUpdate}>
                            Update
                          </Button>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid container spacing={2}>
                  {slotProvider?.length > 0 && slotProviderKeys?.length > 0
                    ? slotProvider?.map((val, key) => (
                        <Grid item xs={3} key={key}>
                          <Card sx={{ width: "100%" }}>
                            <CardContent>
                              <div>
                                <p className="capitalize">
                                  Day:{" "}
                                  <b>
                                    {val?.day === "thu"
                                      ? "Thursday"
                                      : val?.day === "fri"
                                      ? "Friday"
                                      : val?.day === "sat"
                                      ? "Saturday"
                                      : val?.day === "sun"
                                      ? "Sunday"
                                      : val?.day === "mon"
                                      ? "Monday"
                                      : val?.day === "tue"
                                      ? "Tuesday"
                                      : "Wednesday"}
                                  </b>
                                </p>
                                {val[slotProviderKeys[key]?.keys[3]]?.map(
                                  (va, index) => (
                                    <React.Fragment key={index}>
                                      <p>
                                        Date:{" "}
                                        <b>{slotProviderKeys[key]?.keys[3]}</b>
                                      </p>
                                      <p>
                                        Start Time: <b>{va?.startTime}</b>
                                      </p>
                                      <p>
                                        End Time: <b>{va?.endTime}</b>
                                      </p>
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </CardContent>
                            <CardActions>
                              <Button
                                size="small"
                                onClick={(e) => {
                                  setEditModule({
                                    provider: val.provider,
                                    date: slotProviderKeys[key]?.keys[3],
                                    slots: val[slotProviderKeys[key]?.keys[3]],
                                    day:
                                      val?.day === "thu"
                                        ? "Thursday"
                                        : val?.day === "fri"
                                        ? "Friday"
                                        : val?.day === "sat"
                                        ? "Saturday"
                                        : val?.day === "sun"
                                        ? "Sunday"
                                        : val?.day === "mon"
                                        ? "Monday"
                                        : val?.day === "tue"
                                        ? "Tuesday"
                                        : "Wednesday",
                                  });
                                }}
                              >
                                Update {val?.day}
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))
                    : null}
                </Grid>
              )}
              <div></div>
            </div>
          </div>
        )}
      </div>
    </SideNavone>
  );
}
