import React, { useState } from "react";
import "./CardList.css";
import { BsThreeDots } from "react-icons/bs";
import Img from "../../assets/scheduler/assests/lessthan.png";
import Img2 from "../../assets/scheduler/assests/greater.png";
import { formatTime } from "../../../utils";
import {
  CalendarSvg,
  DecreasePackageQuantitySvg,
  IncreasePackageQuantitySvg,
  MoreOptionsVertSvg,
  PaymentSvg,
  SandTimerSvg,
  TimerSvg,
} from "../../../Assets/svgs";

const Box = ({
  id,
  isSelected,
  onClick,
  data,
  timeTo,
  width,
  color,
  isPreviousCompleted,
  isNextScheduled,
  isSheduledtime,
  time,
}) => {
  const boxClassName = isSelected ? "box selected" : "box";
  const boxStyle = {
    width: "130px",
    backgroundColor: "#F5BF00",
    height: "40px",
  };
  return (
    <div className={boxClassName} style={boxStyle} onClick={() => onClick(id)}>
      <div className="box-data">
        <p className="booking_date_title">{data}</p>
        <div>
          <p style={{ fontSize: "11px", margin: "0px 0" }}>
            {" "}
            {time} - {timeTo}
          </p>
        </div>
      </div>
    </div>
  );
};

const Slider = ({ bookingData }) => {
  const boxData = [
    {
      id: 0,
      data: bookingData?.appointments[0]?.bookingDate,
      completed: bookingData?.appointments[0]?.isCompleted,
      Shedule: true,
      time: bookingData?.appointments[0]?.timeFrom,
      to: bookingData?.appointments[0]?.timeTo,
    },
  ];

  const [selectedBox, setSelectedBox] = useState(0);

  const handleBoxClick = (boxId) => {
    setSelectedBox(boxId);
  };

  const handleGreater = () => {
    setSelectedBox((prevBox) => (prevBox < 6 ? prevBox + 1 : 0));
  };

  const handleLess = () => {
    setSelectedBox((prevBox) => (prevBox > 0 ? prevBox - 1 : 6));
  };

  const calculateWidth = (index) => {
    const selectedWidth = 130; // Width of the selected box
    const otherWidth = 100;
    const nextnextwidth = 90;
    const prevWidth = 90;
    // Width of other boxes

    if (index === selectedBox) {
      return selectedWidth;
    } else if (index === (selectedBox + 1 + boxData.length) % boxData.length) {
      return prevWidth - 10;
      // Decrease width for the previous box
    } else if (index === (selectedBox + 2 + boxData.length) % boxData.length) {
      return nextnextwidth - 30;
      // Decrease width for the previous box
    } else {
      return otherWidth;
    }
  };

  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );

    return formattedDate;
  }
  function checkAMorPM(timeString) {
    const timeFormatted = formatTime(timeString);
    const [hour, minute] = timeFormatted?.split(":").map(Number);
    if (hour >= 0 && hour < 12) {
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} am`;
    } else {
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} pm`;
    }
  }
  return bookingData?.appointments?.length !== 0 ? (
    <>
      <div className="d-flex flex-wrap justify-content-between pe-0">
        <div className="d-flex ps-1 flex-wrap justify-content">
          <h1 className="book mt-0 pt-0"> Order ID: 7869 </h1>
        </div>
        <div className="d-flex flex-wrap mt-2 me-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 4"
            fill="none"
          >
            <path
              d="M4 2C4 0.895431 3.10457 0 2 0C0.89543 0 0 0.895431 0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2Z"
              fill="#404145"
            />
            <path
              d="M19 2C19 0.895431 18.1046 0 17 0C15.8954 0 15 0.895431 15 2C15 3.10457 15.8954 4 17 4C18.1046 4 19 3.10457 19 2Z"
              fill="#404145"
            />
            <path
              d="M11 2C11 0.895431 10.1046 0 9 0C7.89543 0 7 0.895431 7 2C7 3.10457 7.89543 4 9 4C10.1046 4 11 3.10457 11 2Z"
              fill="#404145"
            />
          </svg>
        </div>
      </div>
      <div className="d-flex justify-content-between col-md-12">
        <div
          className="d-flex ms-0 ps-0 w-full justify-content-center align-items-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "-10px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            <div className="d-flex align-items-center me-1">
              <CalendarSvg />
            </div>
            {bookingData?.appointments[0]?.bookingDate != null ? (
              <>{formatDate(bookingData?.appointments[0]?.bookingDate)}</>
            ) : (
              "Booking Date is Null"
            )}

            {/* {formattedDate} */}
          </div>
          <div
            className="ms-5 d-flex align-items-center"
            style={{
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            <div className="d-flex align-items-center me-1">
              <TimerSvg />
            </div>
            {bookingData?.appointments[0]?.timeFrom != null &&
            bookingData?.appointments[0]?.timeTo != null ? (
              <>
                {checkAMorPM(bookingData?.appointments[0]?.timeFrom)} -{" "}
                {checkAMorPM(bookingData?.appointments[0]?.timeTo)}
              </>
            ) : (
              "Booking Time is Null"
            )}

            {/* {formatTime(slotData.timeFrom)} */}
          </div>
          {/* {boxData.map((box, index) => {
            return <Box
              className="card1"
              key={box.id}
              id={box.id}
              data={formatDate(box.data)}
              isPreviousCompleted={index === (selectedBox + (boxData.length - 1)) % boxData.length && boxData[index].completed}
              isNextScheduled={index === (selectedBox + 1) % boxData.length && !boxData[index].Sheduled}
              isSheduledtime={index === (selectedBox)}
              isSelected={selectedBox === box.id}
              onClick={handleBoxClick}
              width={calculateWidth(index)}
              time={checkAMorPM(box?.time)}
              timeTo={checkAMorPM(box?.to)}
              color={index < selectedBox ? "#f0f4f5" : "#afeeee"}
            />
          })} */}
        </div>
      </div>
      <hr
        className="mt-3 ms-0"
        style={{
          color: "#c4c4c4",
        }}
      />
    </>
  ) : (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div style={{ marginLeft: "10px", fontSize: "12px" }}>
        Appointment does not exist.
      </div>
    </div>
  );
};

export default Slider;
