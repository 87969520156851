import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    numberOfProviders: 7,
    viewOfCalendar: "day",
}

const calendarSettingsSlice = createSlice({
    name: 'calendarSettings',
    initialState,
    reducers: {
        updateNumberOfProviders: (state,action) => {
            state.numberOfProviders = action.payload;
        },
        updateViewOfCalendar: (state,action)=>{
            state.viewOfCalendar = action.payload;
            state.numberOfProviders = 7;
        }
    },
    // extraReducers(builder) {
        //Get all services
        // builder.addCase(getAllFolders.pending, (state) => {
        //     state.isLoading = true;
        // })
        // builder.addCase(getAllFolders.fulfilled, (state, action) => {
        //     state.isLoading = false;
        //     state.allFolders = [...action.payload];
        // })
        // builder.addCase(getAllFolders.rejected, (state, action) => {
        //     state.isLoading = false;
        //     state.errorMessage = action.payload
        // });

        // //Get all services
        // builder.addCase(getFilesAndFoldersInFolder.pending, (state) => {
        //     state.isLoading = true;
        // })
        // builder.addCase(getFilesAndFoldersInFolder.fulfilled, (state, action) => {
        //     state.isLoading = false;
        //     state.folderData = [...action.payload];
        // })
        // builder.addCase(getFilesAndFoldersInFolder.rejected, (state, action) => {
        //     state.isLoading = false;
        //     state.errorMessage = action.payload
        // })
    // }
})

export const { updateNumberOfProviders, updateViewOfCalendar } = calendarSettingsSlice.actions;
export const calendarSettingsReducer = calendarSettingsSlice.reducer;