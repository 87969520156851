import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SideNavone from '../../../SideNavBar';

const ColumnList = () => {
    const [columns, setColumns] = useState([]);
    const [showColumnList, setShowColumnList] = useState([]);
    const [draggedItems, setDraggedItems] = useState([]);

    // const data = useSelector((state) => state.Department?.data);
    const data = useSelector((state) => state.Team?.data);


    useEffect(() => {
        if (data?.length > 0) {
            const columns = Object.keys(data[0]);
            setColumns(columns);
        }
    }, [data]);

    const handleColumnButtonClick = (i) => {
        if (showColumnList.includes(i)) {
            const updatedList = showColumnList.filter(item => item !== i);
            setShowColumnList(updatedList);
        } else {
            setShowColumnList([...showColumnList, i]);
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedItems = Array.from(draggedItems);
        const [movedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, movedItem);
        setDraggedItems(updatedItems);
    };

    const saveChanges = () => {
        const updatedDraggedItems = draggedItems.map((item, index) => ({
            ...item,
            position: (index + 1).toString(),
        }));
        setDraggedItems(updatedDraggedItems);
    }


    const uniqueDiv = document.getElementById('uniqueDiv');

    return (
        <SideNavone>
            <div className='conainer list_col_container'>
                <h3 className='ps-4 mt-5'>Added Columns</h3>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <div className='px-4 d-flex position-relative scrollable_div'>
                                <button
                                    className='btn btn-primary'
                                    style={{ position: 'absolute', left: '24px' }}
                                    onClick={() => {
                                        // Replace 'uniqueDiv' with the actual ID or reference to your scrollable div
                                        const uniqueDiv = document.getElementById('uniqueDiv');

                                        uniqueDiv.scrollTo({
                                            left: uniqueDiv.scrollLeft - 100, // Subtracting 100 pixels to scroll left
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <ArrowBackIosNewIcon sx={{ color: 'white' }} />
                                </button>

                                <ul
                                    id='uniqueDiv'
                                    className='d-flex m-0 scrollable_col_list px-4'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {draggedItems.map((item, index) => (
                                        <Draggable key={item?.id} draggableId={item?.id} index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    className='bg-secondary text-light rounded py-2 px-4 me-1'
                                                    style={{
                                                        height: '50px',
                                                        width: '120px',
                                                        backgroundColor: 'green',
                                                        margin: '0 10px', // Adjust the spacing between items
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'white',
                                                        borderRadius: '5px'
                                                    }}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {item.content}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                                <button
                                    className='btn btn-primary'
                                    style={{ position: 'absolute', right: '24px' }}
                                    onClick={() => {
                                        uniqueDiv.scrollTo({
                                            left: uniqueDiv.scrollLeft + 100, // Adding 100 pixels to scroll right
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <ArrowForwardIosIcon sx={{ color: 'white' }} />
                                </button>

                                <hr className='my-3' />
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <div className='row mt-4 px-4'>
                    <div className='col-sm-6 col-12 d-flex justify-content-sm-start justify-content-center g-0'>
                        <h3 className=''>Columns List</h3>
                    </div>
                    <div className='col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center mt-sm-0 mt-3'>
                        <button type='submit' className='add_blue_btn btn-sm btn-warning my-0 mx-0 py-1 pe-4 px-1'
                            onClick={saveChanges}
                        ><AddBoxIcon className='addbox_blue_icon me-1'
                            />Save Changes</button>
                    </div>
                    <hr className='my-3' />
                </div>

                <div className="w-100 d-flex flex-wrap justify-content-center">
                    {columns.length > 0 && columns.map((v, i) => {
                        return (
                            <div key={v} className='col_blue_btn_div pe-3 mt-3'>
                                <button
                                    className={`btn h-100 w-100 pe-3 ${showColumnList.includes(i) ? 'selectedColumnBlue' : ''}`}
                                    onClick={() => {
                                        handleColumnButtonClick(i);
                                        if (draggedItems.find(val => val?.id == `${i}`)) {
                                            const filtered = draggedItems.filter(el => el?.id != `${i}`);
                                            setDraggedItems(filtered);
                                        }
                                        else {
                                            setDraggedItems([...draggedItems, { id: `${i}`, position: i + 1, content: v }]);
                                        }
                                    }}
                                >
                                    {v}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </SideNavone>
    )
}

export default ColumnList



