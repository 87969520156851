import React, { useState } from "react";
import BusinessProfile from "..";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const BusinessOwner = ( {businesProfile}) => {
  // const businesProfile = useSelector((state) => state?.Business?.businesProfile);
const history=useHistory()
  const [checkboxChecked1, setCheckboxChecked1] = useState(true);
  const handleEditOwner = (row) => {
    // localStorage.setItem("businessRecord", JSON.stringify(row));
    history.push(`/edit-owner-detail`);
    // handleDialogClose();
  };

  return (
    <>
      <span className=" pt-3 pb-1 text_heading_owner">
        Organization Owner Details
      </span>
      <div className="d-flex pt-3 pb-2">
        <img src="/images/person (1).svg" alt="" />{" "}
        <span className=" sub_text_color_account_alltext1 ps-1">Name</span>
      </div>
      <div className="card" onClick={handleEditOwner}>
        <div className="row me-0">
          <div className="col-10 ps-2 ">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              First Name
            </p>
            {businesProfile && businesProfile.Owner && businesProfile?.Owner[0]?.firstname ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual ">
                {businesProfile?.Owner[0]?.firstname}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"No getting From Backend"}
              </p>
            )}
          </div>
          <div className="col-2 text-end m-auto">
            <img src="/images/Path 40226.svg" alt="" />{" "}
          </div>
          <div className="col-md-12">
            <hr className="my-3 background_color_line" />
          </div>

          <div className="col-10 ps-2 ">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Last Name
            </p>
            {businesProfile && businesProfile.Owner && businesProfile?.Owner[0]?.lastname ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual ">
                {businesProfile?.Owner[0]?.lastname}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"No getting From Backend"}
              </p>
            )}
          </div>
          <div className="col-2 text-end m-auto">
            <img src="/images/Path 40226.svg" alt="" />{" "}
          </div>
        </div>
      </div>

      <div className="d-flex pt-3 pb-2">
        <img src="/images/Mask Group 802.svg" alt="" />{" "}
        <span className=" sub_text_color_account_alltext1 ps-1">
          Mobile Number
        </span>
      </div>
      <div className="card" >
        <div className="row me-0">
          <div className="col-10 ps-2 ">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Number
            </p>
            {businesProfile && businesProfile.Owner && businesProfile?.Owner[0]?.contactNo ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.Owner[0]?.contactNo}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"No getting From Backend"}
              </p>
            )}
          </div>
          <div className="col-2 text-end m-auto">
            <img src="/images/Path 40226.svg" alt="" />{" "}
          </div>
        </div>
      </div>
      <div className="d-flex pt-3 pb-2">
        <img src="/images/mail 1.svg" alt="" />{" "}
        <span className=" sub_text_color_account_alltext1 ps-1">Email</span>
      </div>
      <div className="card" >
        <div className="row me-0">
          <div className="col-10 ps-2 ">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Email
            </p>
            { businesProfile && businesProfile.Owner && businesProfile?.Owner[0]?.email ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-black-50 ">
                {businesProfile?.Owner[0]?.email}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"No getting From Backend"}
              </p>
            )}
          </div>
          <div className="col-2 text-end m-auto">
            <img src="/images/Path 40226.svg" alt="" />{" "}
          </div>
        </div>
      </div>
      <div className="pt-3">
        <span className="text_heading_owner mb-0 mt-3 pt-3 pb-2">
          Organization Manager
        </span>
        <div className="col-md-12 border_rad_new px-2  mt-1 ">
          <div className="  py-3">
            <div className="row">
              <div className="col-10 ">
                <span
                  className={`ps-1 text-size ${
                    checkboxChecked1 ? "text-black" : "owner-text"
                  }`}
                >
                 {businesProfile && businesProfile.Owner && businesProfile?.ContactPerson[0]?.firstname ? (
              <span className=" ">
               {businesProfile && businesProfile.Owner && businesProfile?.ContactPerson[0]?.firstname}{" "}
               {businesProfile && businesProfile.Owner && businesProfile?.ContactPerson[0]?.lastname}{" "} 
              </span>
            ) : (
              <span className="  text-capitalize text-black-50">
                {"No getting From Backend"}
              </span>
            )}
                  
                </span>
              </div>
              <div className="col-2 text-center ">
                <input
                  className="form-check-input text-end"
                  type="checkbox"
                  defaultValue=""
                  id="defaultCheck1"
                  checked={checkboxChecked1}
                  // onChange={handleCheckboxChange1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessOwner;
