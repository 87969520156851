import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";

import axios from "axios";

export default function BasicList({ features,selectedFeatures ,setSelectedFeatures}) {
  const handleFeatureSelect = (feature) => {
    if (selectedFeatures.includes(feature)) {
      setSelectedFeatures(selectedFeatures.filter((f) => f !== feature));
    } else {
      setSelectedFeatures([...selectedFeatures, feature]);
    }
  };

  useEffect(() => {
    // Save selected features to local storage whenever the selection changes
    localStorage.setItem("selectedFeatures", JSON.stringify(selectedFeatures));
  }, [selectedFeatures]);

  return (
    <Box
    className="hide_scrollbar"
      sx={{
        width: "300px",
        maxWidth: 460,
        height: "90%",
        borderRadius: "10px",
        bgcolor: "#FBFCFF",
        border: "1px solid #DCDCDC",
        overflow:"auto"
      }}
    >
      <nav aria-label="secondary mailbox folders">
        <List>
          {features.length > 0 ? (
            features.map((feature, index) => (
              <ListItem disablePadding key={index}>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedFeatures.includes(feature)}
                      onChange={() => handleFeatureSelect(feature)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="type"
                    primary={
                      typeof feature === "string"
                        ? feature.charAt(0).toUpperCase() + feature.slice(1)
                        : feature?.name.charAt(0).toUpperCase() +
                          feature?.name.slice(1)
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No features found" />
            </ListItem>
          )}
        </List>
      </nav>
    </Box>
  );
}
