import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { clinicAxios } from '../../axios';
import axios from 'axios';




export const getFilterData = createAsyncThunk('clinics/getFilterList', async () => {
    const response = await clinicAxios.get('Clinics/GetSubBusiness?BusinsessId=9106')
    return response.data?.subBusinesses;
})

export const postEventsData = createAsyncThunk('events/postEvents', async (data) => {
  const response = await axios.post('http://http://15.206.187.29/api/events/',data)
  return response;
})

export const UpdateEventsData = createAsyncThunk('events/updateEvents', async (data) => {
  const response = await axios.patch('http://http://15.206.187.29/api/events/',data)
  return response;
})

export const DeleteEventsData = createAsyncThunk('events/deleteEvents', async (data) => {
  const response = await axios.delete(`http://http://15.206.187.29/api/events/?id=${data.id}&type=${data.type}`)
  return response;
})

const initialState ={
  open:false,
  openupdate:false,
  filterData:[],
  slotSelected:{},
  eventSelected:{},
  isLoading:false,
  isLoadingPost:false,
  isLoadingUpdsate:false,
  isLoadingDelete:false,
  errorMessage: '',
  successMessage:'',
  updateMessage:'',
  deleteMessage:'',
  responseStatus:'',
  updateStatus:'',
  deleteStatus:'',
}

const CreateSlice =createSlice({
    name:'model',
    initialState,
    reducers:{
        openmodel:(state)=>{
          state.open=true
        },
        closemodel:(state)=>{
          state.open=false
        },
        openupdatemodel:(state)=>{
          state.openupdate=true
        },
        closeupdatemodel:(state)=>{
          state.openupdate=false
        },
        selectedSlot:(state, action)=>{
            state.slotSelected=action.payload
        },
        selectedEvent:(state, action)=>{
          state.eventSelected=action.payload
      },
    },
    extraReducers(builder){
      //Get filter data
      builder.addCase(getFilterData.pending, (state) => {
        state.isLoading = true;
      })
      builder.addCase(getFilterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filterData = action.payload;
      })
      builder.addCase(getFilterData.rejected, (state,action) => {
        state.isLoading = false;
        state.errorMessage = action.payload
      })
      // Create Events data
      builder.addCase(postEventsData.pending, (state) => {
        state.isLoadingPost = true;
      })
      builder.addCase(postEventsData.fulfilled, (state, action) => {
        state.isLoadingPost = false;
        state.successMessage = action.payload.data.message;
        state.responseStatus="success"
      })
      builder.addCase(postEventsData.rejected, (state) => {
        state.isLoadingPost = false;
        state.successMessage = "Failed to create event"
        state.responseStatus="error"
      })
      // Update Events data
      builder.addCase(UpdateEventsData.pending, (state) => {
        state.isLoadingUpdsate = true;
      })
      builder.addCase(UpdateEventsData.fulfilled, (state, action) => {
        state.isLoadingUpdsate = false;
        state.updateMessage = action.payload.data.message;
        state.updateStatus="success"
      })
      builder.addCase(UpdateEventsData.rejected, (state) => {
        state.isLoadingUpdsate = false;
        state.updateMessage = "Failed to create event"
        state.updateStatus="error"
      })
      // Delete Events data
      builder.addCase(DeleteEventsData.pending, (state) => {
        state.isLoadingDelete = true;
      })
      builder.addCase(DeleteEventsData.fulfilled, (state, action) => {
        state.isLoadingDelete = false;
        state.deleteMessage = action.payload.data.message;
        state.deleteStatus="success"
      })
      builder.addCase(DeleteEventsData.rejected, (state) => {
        state.isLoadingDelete = false;
        state.deleteMessage = "Failed to create event"
        state.deleteStatus="error"
      })
    }
})

export const {openmodel,closemodel,openupdatemodel,closeupdatemodel,selectedSlot,selectedEvent} = CreateSlice.actions;
export const modelReducer= CreateSlice.reducer;