import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import InputTextField from "../TextField";

import Button from "@mui/material/Button";
import Header from "../Header";
import { CustomTextField, FormateDate, error, success } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAddCircleOutline } from "react-icons/md";
import { Autocomplete, InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import InputDate from "../InputDate";
import { FiSearch } from "react-icons/fi";
import FireNav from "../List";
import { ClipLoader } from "react-spinners";

import {
  InitialValue,
  formateData,
  subscribeInitialValue,
  validationSchema,
} from "./helper";
import axios from "axios";
import FormData from "./form";
import SideNavone from "../../../SideNavBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { GET_PLAN, GET_PLAN_ENTITIES } from "../../../utils/GraphQLQueries";

const list = [
  { id: 1, value: "Annual" },
  { id: 1, value: "Monthly" },
];

const AddSubscription = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [plan, setPlan] = useState("");
  const [features, setFeatures] = useState([]);
  const [selectedPlanEntity, setSelectedPlanEntity] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const all_business = useSelector((state) => state.Business?.data);
  const flag = true;
  const selectedFeatureIds = selectedFeatures.map((f) => f.id); // Extract the ids from selectedFeatures
  const fetchPlan = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: GET_PLAN,
        },
        {
          referrerPolicy: "no-referrer-when-downgrade",
        }
      );
      if (response.data.data) {
        const newPlanOptions = [...planOptions, ...response.data.data.plans];
        setPlanOptions(newPlanOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: GET_PLAN_ENTITIES,
        },
        {
          referrerPolicy: "no-referrer-when-downgrade",
        }
      );
      if (response.data.data) {
        setFeatures(response.data.data.planEntities);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const creatSubscription = async (values) => {
    setLoading(true);
    const businessIds = businessId.id;
    const planId = selectedPlan.id;
    const data = formateData(values);
    if (!planId) {
      error("Please Select a plan first");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
        {
          query: `
            mutation {
              createSubscription(
                businessId: "${businessIds}",
                startDate: "${data.startDate}",
                endDate: "${data.endDate}",
                planId: "${planId}"
              ) {
                subscription {
                  BusinessId
                  createdAt
                  endDate
                  id
                  isDeleted
                  startDate
                  status
                  updatedAt
                }
              }
            }
          `,
        }
      );

      if (response.status === 200) {
        success("Subscription Created Successfully");
        history.push("/subscribe");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = (values) => {
    creatSubscription(values);
  };

  return (
    <>
      <SideNavone>
        <Header flag={flag} heading={"Add Subscription"} />
        <div className="p-2 rbc-time-content box-height pt-4 min-h-[250px] form-container-business">
          <div className="w-100">
            <Formik
              initialValues={subscribeInitialValue()}
              onSubmit={(values) => {
                submitForm(values);
              }}
              validationSchema={validationSchema}
            >
              {(options) => {
                return (
                  <FormData
                    all_business={all_business}
                    setBusinessId={setBusinessId}
                    features={features}
                    setFeatures={setFeatures}
                    fetchData={fetchData}
                    businessId={businessId}
                    loading={loading}
                    list={list}
                    selectedFeatures={selectedFeatures}
                    selectedFeatureIds={selectedFeatureIds}
                    setSelectedFeatures={setSelectedFeatures}
                    planOptions={planOptions}
                    setPlanOptions={setPlanOptions}
                    fetchPlan={fetchPlan}
                    setSelectedPlan={setSelectedPlan}
                    selectedPlan={selectedPlan}
                    {...options}
                  />
                );
              }}
            </Formik>
          </div>
        </div>
      </SideNavone>
    </>
  );
};

export default AddSubscription;
