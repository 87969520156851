import {
  FETCH_STAFF_FAILURE,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
} from "./actionType";

// Action creators

export const fetchStaffRequest = (data) => {
  return {
    type: FETCH_STAFF_REQUEST,
    payload: data,
  };
};
export const fetchStaffSuccess = (data) => {
  return {
    type: FETCH_STAFF_SUCCESS,
    payload: data,
  };
};

export const fetchStaffFailure = (error) => ({
  type: FETCH_STAFF_FAILURE,
  payload: error,
});
