// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_text{
    color: #FFFFFF;
    font-weight: 300;
    font-size: 15px;
    font-family: Roboto;
}
.footer_background{
    background-color: #000F23;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 3%;
    padding-bottom: 2%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".footer_text{\n    color: #FFFFFF;\n    font-weight: 300;\n    font-size: 15px;\n    font-family: Roboto;\n}\n.footer_background{\n    background-color: #000F23;\n    padding-left: 8%;\n    padding-right: 8%;\n    padding-top: 3%;\n    padding-bottom: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
