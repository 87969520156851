import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "@mui/material";
import { error, success } from "../../../utils";
import { AddButton, record } from "./helper";
import { useNavigate } from "react-router-dom";
import { GET_PRODUCTS } from "../../../utils/GraphQLQueries";
import SideNavone from "../../../SideNavBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Subscribe = ({ businessId }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/subscription_svc/pb/`,
          {
            query: GET_PRODUCTS,
          },
          {
            referrerPolicy: "no-referrer-when-downgrade",
          }
        );
        if (response.data.data) {
          success("Product Fetch Successfully");
          setData(response.data.data.products);
        }
        // else{
        //   error("No Record Found")
        // }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  function getNumberOfDays(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    // Convert the date strings to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in days
    const diffDays = Math.round(Math.abs((end - start) / oneDay));

    return diffDays;
  }
  return (
    <>
      {" "}
      <div className="row mx-4 pt-4 subs ">More to Add</div>
      <div className="row">
        {data.length > 0
          ? data.map((row) => {
              return (
                <>
                  <div className="col-md-3">
                    <div className="row mt-4 ms-4   mb-3 pt-0 ">
                      <div className="card ">
                        <div className="d-flex mt-3">
                          <div className="d-flex flex-wrap justify-content-between">
                            <div className="me-2">
                              {" "}
                              <img
                                style={{ width: "41px", height: "41px" }}
                                src="../images/Custom Size29.jpg"
                                alt="no-data"
                              />
                            </div>
                            <div className="ms-2">
                              <h2 className="mt-1 me-5 chat ">
                                {row.name.charAt(0).toUpperCase() +
                                  row.name.slice(1)}
                              </h2>
                              <div className="d-flex mt-0">
                                <div className="subtype me-2">
                                  {row.description}
                                </div>
                                <div
                                  className=" ps-0 four"
                                  style={{ color: "#FF4E4E" }}
                                >
                                  {" "}
                                </div>
                              </div>
                            </div>
                            <div className="position text-end">
                              {" "}
                              <BsThreeDotsVertical />{" "}
                            </div>
                          </div>
                        </div>

                        <div>
                          {" "}
                          <hr
                            style={{
                              background: "#DCDCDC",
                              height: "1px",
                              width: "100%",
                              left: "20px",
                              top: "320px",
                              border: "0.7px",
                            }}
                          />
                        </div>
                        <div className="d-flex flex-wrap mb-3 justify-content-between">
                          <div className=" ">
                            <img
                              className="vector "
                              src="../images/Group 29441.png"
                              alt="no-data"
                            />
                            &nbsp; &nbsp;
                            <img
                              className="vector1 "
                              src="../images/Vector (2).jpg"
                              alt="no data"
                            />
                          </div>

                          <div className="pe-0 ps-4 text-end">
                            <div className="d-flex flex-wrap pe-0 me-0">
                              <div className="ms-md-2">
                                <Button
                                  sx={{
                                    backgroundColor: "#538dff",
                                    height: "30px",
                                    borderRadius: "6px",
                                    textTransform: "capitalize",
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    history.push("/business/calender", {
                                      state: row,
                                      id: businessId,
                                    });
                                  }}
                                >
                                  {"Subscribe"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : null}
      </div>
    </>
  );
};

export default Subscribe;
