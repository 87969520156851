import React, { useEffect } from 'react'
import AttribCombination from './components/AttribCombination'
import AddAttributes from './components/AddAttributes'
import { useParams } from 'react-router-dom';
import AddAttributesMobile from './components/AddAttributesMobile';
import AttribCombinationMobile from './components/AttribCombinationMobile';
import { getAllAttributeTypes, getServiceAttributes } from '../../store/servicesActions';
import { useDispatch, useSelector } from 'react-redux';
import ServiceLoader from '../ServiceLoader';

const Attributes = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(state => state?.servicesReducer);

  useEffect(() => {
    if (id) {
      dispatch(getServiceAttributes(id))
      dispatch(getAllAttributeTypes())
    }
  }, [id])

  return (
    <>
      {store?.isLoading && store?.serviceAttributes ?
        <ServiceLoader />
        :
        <>
          <div className='pc_display'>
            <AddAttributes />
            <AttribCombination />
          </div>
          <div className='mobile_display'>
            <AddAttributesMobile />
            <AttribCombinationMobile />
          </div>
        </>
      }
    </>
  )
}

export default Attributes