import React, { useEffect, useState,useRef } from 'react'
import Avatar from '@mui/material/Avatar';
import '../../assets/scheduler/css/calendar.css';
import { useSelector } from 'react-redux';
import { providerImageURL } from '../../../utils/imageUrlsExtension';

export default function ProviderWidget(props) {
  const providerWidgetRef = useRef();
  const numberOfProviders = useSelector((state)=>state.calendarSettingReducer.numberOfProviders);
    const {val} = props;
    console.log(val);
    useEffect(()=>{
      if (providerWidgetRef.current) {
        const parentDiv = providerWidgetRef.current.parentElement;
        if(parentDiv){
          parentDiv.style.width =numberOfProviders === 1 ? "91vw" : numberOfProviders === 2 ? "46vw" : numberOfProviders === 3 ? "30.5vw" : numberOfProviders === 4 ? "23vw" : numberOfProviders === 5 ? "18.2vw" : numberOfProviders === 6 ? "15.2vw" : numberOfProviders === 7 ? "13vw" : numberOfProviders === 8 ? "11.4vw" : numberOfProviders === 9 ? "10.15vw" : "9.1vw";
        }
        }
    },[]);
  return (
    <div className={`provider_parent mb-1 mx-2 provider_box ${numberOfProviders <= 4 ? "less_than_four" : numberOfProviders <= 8 && numberOfProviders > 4 ? "less_than_eight": ""}`} ref={providerWidgetRef} >
    {val.img ? (
      <Avatar
        alt={val?.resourceTitle}
        src={providerImageURL(val.resourceId)}
        className="rounded-circle provider_circle"
        // style={{ width: 40, height: 40 }}
      />
    ) : (
      <Avatar
        className="rounded-circle provider_circle"
        style={{ backgroundColor: "#5599FF", color: "white" }}
      >
        {val?.resourceTitle?.charAt(0)}
      </Avatar>
    )}
    <p className="provider_title">{val?.resourceTitle}</p>
  </div>
  
  )
}
