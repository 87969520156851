import React, { useState } from "react";
import { Button, Divider, TextField, Typography } from "@mui/material";
// import "./style.scss";
import InputTime from "../InputTime";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MapPin from "../../../../Assets/scheduler/map-pin.svg";
import { DropdownArrowSvg } from "../../../../Assets/svgs";
import moment from "moment";
import CustomTimePickerComponent from "../TimeSlot/timePicker";
import { FormateTime } from "../../../../utils";

// import Switch from "./Switch";

const MultiScheduleTimes = (props) => {
  const {
    scheduleIndex,
    setFieldValue,
    startTimeValues,
    startTimeTouched,
    startTimeErrors,
    endTimeValues,
    endTimeTouched,
    endTimeErrors,
    slot,
    locationValues,
    locationTouched,
    locationErrors,

    slotInfo,
    setScheduleItems,
    scheduleItems,
    businessLocations,
  } = props;

  if (slot.days === 0) {
    slot.days = 7;
  }

  const handleAddTimeSlot = () => {
    setScheduleItems([
      ...scheduleItems,
      {
        week: "",
        startTime: [],
        endTime: [],
        businessId: "",
        businessName: "",
      },
    ]);
  };

  const handleRemoveTimeSlot = (currIndex) => {
    // Filter out the item at the specified index
    const newScheduleItems = scheduleItems.filter(
      (_, index) => index !== currIndex
    );
    // Update the state with the new array
    setScheduleItems(newScheduleItems);
  };

  const settingValues = (newValue, id) => {
    setFieldValue(id, newValue);
  };

  const handleBusinessChange = (value, id, type) => {
    // Create a copy of the state array
    const newData = [...scheduleItems];

    if (type === "business")
      // Update the businessId and businessName of the object at the specified index
      newData[id] = {
        ...newData[id],
        businessId: value,
        businessName: businessLocations.find((data) => data.id == value)?.name,
      };
    else if (type === "startTime") {
      console.log("START TIME", value);
      newData[id] = {
        ...newData[id],
        startTime: [`${value}:00`],
      };
    } else if (type === "endTime") {
      console.log("END TIME", value);
      newData[id] = {
        ...newData[id],
        endTime: [`${value}:00`],
      };
    }
    console.log(newData);
    // Update the state with the new array
    setScheduleItems(newData);
  };

  return (
    <>
      <div>
        {scheduleItems.map((time, index) => {
          console.log(index);
          console.log(scheduleItems.length);
          return (
            <div
              className={` ${
                startTimeValues && startTimeValues?.length > 1 ? "" : ""
              }`}
              key={time + index}
            >
              <div className="row">
                <div className="col-md-3 mt-2 pt-2">
                  <FormControl
                    sx={{ maxWidth: "180px" }}
                    size="small"
                    // disabled={!slot.active}
                  >
                    <Select
                      id={`scheduleItems.${scheduleIndex}.businessLocation.${index}`}
                      // defaultValue={
                      //   businessLocations && businessLocations[0]?.id
                      // }
                      startAdornment={
                        <img
                          src={MapPin}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />
                      }
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        color: "#5599FF",
                        height: "37px",
                        "& fieldset": {
                          border: "none",
                        },
                        ".MuiSvgIcon-root ": {
                          color: "#5599FF",
                        },
                      }}
                      value={scheduleItems && scheduleItems[index].businessId}
                      onChange={(e) => {
                        handleBusinessChange(e.target.value, index, "business");
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <em style={{ color: "gray" }}>Select Business</em>
                          );
                        }
                        const val = businessLocations.find(
                          (data) => data.id == selected
                        )?.name;

                        return val;
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Business</em>
                      </MenuItem>
                      {businessLocations?.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id} name={loc.name}>
                          {loc.name}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={18}>Yasir Business</MenuItem> */}
                    </Select>
                  </FormControl>
                  {/* <p className="form-error" style={{ marginLeft: "16px" }}>
                    {locationTouched &&
                    locationTouched[index] &&
                    locationErrors &&
                    locationErrors[index]
                      ? locationErrors[index]
                      : null}
                  </p> */}
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="col-md-3">
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontSize: "12px",
                        fontWeight: 400,
                        mb: 0.5,
                      }}
                    >
                      From
                    </Typography>
                    <CustomTimePickerComponent
                      onMouseDown={(newValue) =>
                        handleBusinessChange(newValue, index, "startTime")
                      }
                      values={scheduleItems && scheduleItems[index]?.startTime}
                      id={index}
                      setFieldValue={setFieldValue}
                    />
                    <p className="form-error">
                      {startTimeTouched &&
                      startTimeTouched[index] &&
                      startTimeErrors &&
                      startTimeErrors[index]
                        ? startTimeErrors[index]
                        : null}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <Typography
                      sx={{
                        color: "#A3A3A3",
                        fontSize: "12px",
                        fontWeight: 400,
                        mb: 0.5,
                      }}
                    >
                      To
                    </Typography>
                    <CustomTimePickerComponent
                      onMouseDown={(newValue) =>
                        handleBusinessChange(newValue, index, "endTime")
                      }
                      values={scheduleItems && scheduleItems[index]?.endTime}
                      id={index}
                    />
                    <p className="form-error">
                      {startTimeTouched &&
                      startTimeTouched[index] &&
                      startTimeErrors &&
                      startTimeErrors[index]
                        ? startTimeErrors[index]
                        : null}
                    </p>
                  </div>
                </LocalizationProvider>
                <div className="col-md-3 pt-4">
                  <div className="row">
                    {index < scheduleItems.length - 1 ? (
                      <div className="col-md-6">
                        <div
                          className="mt-1"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleRemoveTimeSlot(index);
                            }}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.511597"
                                width="19"
                                height="19.2312"
                                rx="3.5"
                                fill="white"
                                stroke="#5599FF"
                              />
                              <path
                                d="M14.6654 9.55176H10.5435H9.43017H5.33203V10.678H9.43017H10.5435H14.6654V9.55176Z"
                                fill="#5599FF"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <div
                          className="mt-1"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <div
                            // variant="outlined"
                            disabled={
                              slot.day === 0 || startTimeValues?.length === 10
                            }
                            onClick={handleAddTimeSlot}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.713867"
                                width="19"
                                height="19.2312"
                                rx="3.5"
                                fill="white"
                                stroke="#5599FF"
                              />
                              <path
                                d="M14.6654 9.75404H10.5435V5.60852H9.43017V9.75404H5.33203V10.8803H9.43017V15.0498H10.5435V10.8803H14.6654V9.75404Z"
                                fill="#5599FF"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {startTimeValues?.length > 1 &&
                startTimeValues?.length != index + 1 && (
                  <Divider sx={{ color: "#F1F1F1", mb: "10px" }} />
                )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MultiScheduleTimes;
