export const RightAngle = () => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00781 8L4.75781 4.25L1.00781 0.5"
        stroke="#AAAAAA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LowerAngle = () => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4.75 4.75L8.5 1"
        stroke="#AAAAAA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AttributesIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#F0F0F0" />
      <g clipPath="url(#clip0_14106_10490)">
        <path
          d="M15.5 9.25H9.25V15.5L15.0562 21.3063C15.6437 21.8938 16.6063 21.8938 17.1938 21.3063L21.3063 17.1938C21.8938 16.6063 21.8938 15.6437 21.3063 15.0562L15.5 9.25Z"
          stroke="#B5B5B5"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.375 12.375H12.3813"
          stroke="#B5B5B5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14106_10490">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AppointmentDetailsIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#F0F0F0" />
      <path
        d="M20.625 12.1875V11.25C20.625 10.9185 20.4933 10.6005 20.2589 10.3661C20.0245 10.1317 19.7065 10 19.375 10H10.625C10.2935 10 9.97554 10.1317 9.74112 10.3661C9.5067 10.6005 9.375 10.9185 9.375 11.25V20C9.375 20.3315 9.5067 20.6495 9.74112 20.8839C9.97554 21.1183 10.2935 21.25 10.625 21.25H12.8125"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 8.75V11.25"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 8.75V11.25"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.375 13.75H12.5"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4375 18.4375L17.5 17.6562V16.25"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.25 17.5C21.25 18.4946 20.8549 19.4484 20.1517 20.1517C19.4484 20.8549 18.4946 21.25 17.5 21.25C16.5054 21.25 15.5516 20.8549 14.8483 20.1517C14.1451 19.4484 13.75 18.4946 13.75 17.5C13.75 16.5054 14.1451 15.5516 14.8483 14.8483C15.5516 14.1451 16.5054 13.75 17.5 13.75C18.4946 13.75 19.4484 14.1451 20.1517 14.8483C20.8549 15.5516 21.25 16.5054 21.25 17.5Z"
        stroke="#B5B5B5"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PrinterIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6.75V1.5H13.5V6.75"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 13.5H3C2.60218 13.5 2.22064 13.342 1.93934 13.0607C1.65804 12.7794 1.5 12.3978 1.5 12V8.25C1.5 7.85218 1.65804 7.47064 1.93934 7.18934C2.22064 6.90804 2.60218 6.75 3 6.75H15C15.3978 6.75 15.7794 6.90804 16.0607 7.18934C16.342 7.47064 16.5 7.85218 16.5 8.25V12C16.5 12.3978 16.342 12.7794 16.0607 13.0607C15.7794 13.342 15.3978 13.5 15 13.5H13.5"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 10.5H4.5V16.5H13.5V10.5Z"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const ShareIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 6C14.7426 6 15.75 4.99264 15.75 3.75C15.75 2.50736 14.7426 1.5 13.5 1.5C12.2574 1.5 11.25 2.50736 11.25 3.75C11.25 4.99264 12.2574 6 13.5 6Z"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 11.25C5.74264 11.25 6.75 10.2426 6.75 9C6.75 7.75736 5.74264 6.75 4.5 6.75C3.25736 6.75 2.25 7.75736 2.25 9C2.25 10.2426 3.25736 11.25 4.5 11.25Z"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 17.5C14.7426 17.5 15.75 16.4926 15.75 15.25C15.75 14.0074 14.7426 13 13.5 13C12.2574 13 11.25 14.0074 11.25 15.25C11.25 16.4926 12.2574 17.5 13.5 17.5Z"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.44141 10.1328L11.5639 13.1178"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5564 4.88281L6.44141 7.86781"
        stroke="#909090"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const NotesIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33203 2.33203V6.9987"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 2.33203V6.9987"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.668 2.33203V6.9987"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0013 4.66797H7.0013C5.71264 4.66797 4.66797 5.71264 4.66797 7.0013V23.3346C4.66797 24.6233 5.71264 25.668 7.0013 25.668H21.0013C22.29 25.668 23.3346 24.6233 23.3346 23.3346V7.0013C23.3346 5.71264 22.29 4.66797 21.0013 4.66797Z"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33203 11.668H16.332"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33203 16.332H18.6654"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33203 21H15.1654"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DocumentsIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5013 2.33203H7.0013C6.38246 2.33203 5.78897 2.57786 5.35139 3.01545C4.9138 3.45303 4.66797 4.04653 4.66797 4.66536V23.332C4.66797 23.9509 4.9138 24.5444 5.35139 24.9819C5.78897 25.4195 6.38246 25.6654 7.0013 25.6654H21.0013C21.6201 25.6654 22.2136 25.4195 22.6512 24.9819C23.0888 24.5444 23.3346 23.9509 23.3346 23.332V8.16536L17.5013 2.33203Z"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.332 2.33203V6.9987C16.332 7.61754 16.5779 8.21103 17.0154 8.64861C17.453 9.0862 18.0465 9.33203 18.6654 9.33203H23.332"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6654 10.5H9.33203"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6654 15.168H9.33203"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6654 19.832H9.33203"
        stroke="#FFD705"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ChatIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14106_10421)">
        <path
          d="M0 24.2324V23.839L1.53302 19.0199C1.57091 18.9471 1.59069 18.8661 1.59069 18.7839C1.59069 18.7018 1.57091 18.6208 1.53302 18.5479C0.86017 17.3236 0.401509 15.9931 0.17688 14.614L0 13.3551V11.8406C0.00925563 11.7688 0.00925563 11.6961 0 11.6243C0.106662 9.90132 0.589716 8.22274 1.4151 6.70691C2.2963 5.02097 3.55474 3.56147 5.09229 2.44237C6.62984 1.32328 8.40491 0.574779 10.2791 0.255241L11.7138 0.0585938H13.1879H13.3648C14.4369 0.136107 15.4942 0.354256 16.5094 0.707603C19.1737 1.6245 21.4504 3.41532 22.9702 5.78958C24.4899 8.16385 25.1637 10.9824 24.8821 13.7879C24.7077 15.792 24.0318 17.7199 22.9167 19.3937C21.8328 21.0845 20.3495 22.4821 18.5979 23.4632C16.8463 24.4444 14.8803 24.9788 12.8734 25.0193C10.6429 25.114 8.43041 24.5825 6.48585 23.485C6.41555 23.4503 6.33821 23.4323 6.25982 23.4323C6.18144 23.4323 6.10409 23.4503 6.03379 23.485L1.37578 24.9799C1.20136 25.0594 1.00719 25.085 0.818121 25.0535C0.62905 25.0219 0.453689 24.9347 0.314473 24.8028C0.187018 24.6474 0.0812239 24.4754 0 24.2915M2.49605 22.5801C3.53771 22.2458 4.46146 21.9508 5.54244 21.5771C5.84271 21.4549 6.16852 21.4088 6.49086 21.4431C6.8132 21.4774 7.12207 21.591 7.38993 21.7737C9.58278 23.0404 12.1697 23.4403 14.6423 22.8949C17.2144 22.3707 19.491 20.887 21.0102 18.7447C22.6493 16.4936 23.3279 13.6831 22.8966 10.9314C22.4654 8.17962 20.9597 5.71195 18.7107 4.07114C17.5403 3.17393 16.1873 2.54502 14.7473 2.22892C13.3073 1.91282 11.8155 1.91727 10.3774 2.24188C7.7182 2.79028 5.37249 4.34317 3.82783 6.57773C2.28317 8.8123 1.65837 11.5566 2.08333 14.2403C2.29929 15.5839 2.77313 16.8731 3.47875 18.0365C3.58371 18.1941 3.64923 18.3747 3.66976 18.563C3.69028 18.7513 3.66523 18.9418 3.5967 19.1184C3.43947 19.5905 3.30187 20.0625 3.14464 20.5345C2.9874 21.0066 2.71224 21.8721 2.49605 22.5015"
          fill="#FFD705"
        />
        <path
          d="M8.82381 12.5086C8.82381 12.832 8.69542 13.1422 8.4669 13.3709C8.23838 13.5996 7.92844 13.7281 7.60526 13.7281C7.28208 13.7281 6.97215 13.5996 6.74363 13.3709C6.5151 13.1422 6.38672 12.832 6.38672 12.5086C6.38672 12.1851 6.5151 11.875 6.74363 11.6463C6.97215 11.4176 7.28208 11.2891 7.60526 11.2891C7.76437 11.289 7.92191 11.3207 8.06866 11.3822C8.21542 11.4437 8.34844 11.5339 8.46003 11.6474C8.57162 11.7609 8.65952 11.8955 8.7186 12.0434C8.77768 12.1912 8.80677 12.3494 8.80416 12.5086"
          fill="#FFD705"
        />
        <path
          d="M12.4609 13.7281C12.1377 13.7281 11.8278 13.5996 11.5993 13.3709C11.3707 13.1422 11.2423 12.832 11.2423 12.5086C11.2397 12.3494 11.2688 12.1912 11.3279 12.0434C11.387 11.8955 11.4749 11.7609 11.5865 11.6474C11.698 11.5339 11.8311 11.4437 11.9778 11.3822C12.1246 11.3207 12.2821 11.289 12.4412 11.2891C12.7644 11.2891 13.0744 11.4176 13.3029 11.6463C13.5314 11.875 13.6598 12.1851 13.6598 12.5086C13.6598 12.832 13.5314 13.1422 13.3029 13.3709C13.0744 13.5996 12.7644 13.7281 12.4412 13.7281"
          fill="#FFD705"
        />
        <path
          d="M17.3748 11.2891C17.6158 11.2891 17.8514 11.3606 18.0518 11.4946C18.2522 11.6286 18.4084 11.8191 18.5006 12.0419C18.5928 12.2648 18.617 12.5099 18.5699 12.7465C18.5229 12.9831 18.4069 13.2004 18.2365 13.3709C18.066 13.5415 17.8489 13.6576 17.6125 13.7047C17.3761 13.7517 17.1311 13.7276 16.9085 13.6353C16.6858 13.543 16.4955 13.3867 16.3616 13.1861C16.2277 12.9856 16.1562 12.7498 16.1562 12.5086C16.1562 12.1851 16.2846 11.875 16.5131 11.6463C16.7417 11.4176 17.0516 11.2891 17.3748 11.2891Z"
          fill="#FFD705"
        />
      </g>
      <defs>
        <clipPath id="clip0_14106_10421">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BeforeAndAfterIcon = () => {
  return (
    <svg
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14291_2841)">
        <path
          d="M1.0865 30.4214H0C0 27.7758 2.48808 26.6621 4.88925 25.5865C7.37733 24.5 9.72417 23.4135 9.72417 20.8711H10.8107C10.8107 24.1306 7.90428 25.4344 5.33471 26.5806C3.0585 27.5857 1.0865 28.4657 1.0865 30.4214Z"
          fill="#FFD705"
          stroke="#FFD705"
          stroke-width="0.25"
        />
        <path
          d="M25.3083 30.4221H24.2218C24.2218 28.4827 22.2498 27.6026 19.9681 26.5813C17.3823 25.4188 14.4922 24.1204 14.4922 20.8555H15.5787C15.5787 23.4142 17.9255 24.4626 20.4136 25.5709C22.9017 26.6791 25.3083 27.7602 25.3083 30.4221Z"
          fill="#FFD705"
          stroke="#FFD705"
          stroke-width="0.25"
        />
        <path
          d="M18.5518 29.6509H6.7579C6.22961 29.6507 5.71288 29.4963 5.27096 29.2069C4.82904 28.9174 4.48112 28.5054 4.26981 28.0212L3.72656 26.7065L4.72614 26.2773L5.26939 27.592C5.39507 27.8829 5.6028 28.1308 5.86716 28.3054C6.13153 28.48 6.44106 28.5738 6.7579 28.5753H18.5518C18.8687 28.5738 19.1782 28.48 19.4426 28.3054C19.7069 28.1308 19.9147 27.8829 20.0403 27.592L20.5836 26.2773L21.5832 26.7065L21.0399 28.0212C20.8286 28.5054 20.4807 28.9174 20.0388 29.2069C19.5969 29.4963 19.0801 29.6507 18.5518 29.6509Z"
          fill="#FFD705"
          stroke="#FFD705"
          stroke-width="0.25"
        />
        <path
          d="M6.46996 22.8176C6.10109 22.7751 5.75696 22.6107 5.49211 22.3504C3.8026 20.9815 0.314941 16.0759 1.2113 10.5402C2.05334 5.36303 6.80134 -0.0911958 12.4946 0.00115666C15.5368 0.0609141 18.4106 1.69066 20.5836 4.58075C23.1043 7.93803 24.1201 12.3872 23.1695 15.9238C22.2948 19.1833 19.9697 21.2911 18.1661 22.508L17.5577 21.6062C19.1874 20.4925 21.3604 18.5531 22.121 15.6304C22.9847 12.409 22.0449 8.32374 19.7144 5.22179C17.7696 2.61419 15.2108 1.14198 12.4946 1.08766C7.35546 0.995303 3.05292 5.9769 2.28694 10.714C1.48836 15.6304 4.42191 19.9058 5.88868 21.2965L5.82349 21.0684C5.3563 19.4386 4.48167 16.3584 5.79633 13.7019C6.75788 11.7462 8.20836 10.5728 9.48499 9.53519C11.0387 8.27485 12.2664 7.2807 12.4131 5.28698L13.4996 5.36846C13.3203 7.82938 11.7938 9.06799 10.1749 10.3827C8.92545 11.3931 7.63251 12.4416 6.77418 14.1854C5.64422 16.4725 6.41563 19.167 6.87196 20.775C7.17618 21.8615 7.37719 22.5406 6.73072 22.7959C6.64598 22.8197 6.55747 22.8271 6.46996 22.8176Z"
          fill="#FFD705"
          stroke="#FFD705"
          stroke-width="0.25"
        />
        <path
          d="M12.6586 21.9379C9.28505 21.9379 6.30261 19.0532 5.40625 14.93L6.49275 14.7018C7.2533 18.3198 9.77941 20.8514 12.6586 20.8514C15.6193 20.8514 18.2541 18.0917 18.9277 14.2944L19.0201 13.7511L19.6503 13.8652C20.1283 13.8652 20.5575 13.1698 20.5575 12.3767C20.5575 11.5835 20.1283 10.8882 19.6394 10.8882L19.4493 10.9262L19.3406 10.8991C18.1259 10.6326 17.0189 10.0082 16.1626 9.10633C15.4948 8.39911 14.9985 7.54782 14.7121 6.61825L15.7552 6.30859C16.0157 7.16776 16.4961 7.944 17.1487 8.56047C17.8014 9.17695 18.6038 9.6123 19.4764 9.82342H19.6394C20.7639 9.82342 21.644 10.9534 21.644 12.3984C21.644 13.724 20.8997 14.7887 19.9056 14.9517C19.0147 19.0804 16.0811 21.9379 12.6586 21.9379Z"
          fill="#FFD705"
          stroke="#FFD705"
          stroke-width="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_14291_2841">
          <rect width="25.31" height="30.4057" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ReminderBellIcon = () => {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2549 1.01557C10.3587 0.35651 10.6849 0.00651181 11.1814 8.98205e-05C11.6779 -0.00633217 12.0056 0.331623 12.1393 1.01155C13.4967 1.17611 14.7724 1.62485 15.9151 2.42439C18.4069 4.16796 19.8445 6.59306 20.0637 9.75349C20.1325 10.7497 20.1027 11.7539 20.1103 12.755C20.1202 14.0626 20.5763 15.1744 21.454 16.0896C22.2629 16.9325 22.5669 17.9239 22.2377 19.075C21.9085 20.2261 21.1423 20.9213 20.0026 21.126C19.8709 21.1468 19.7378 21.1559 19.6046 21.1533C18.4038 21.1533 17.203 21.1533 16.0022 21.1533H15.6799C15.3842 22.4241 14.7441 23.4347 13.7259 24.166C12.9297 24.7523 11.9708 25.0443 10.9996 24.9961C9.10294 24.9102 7.23604 23.4909 6.70209 21.1541H6.11391C5.00859 21.1541 3.89869 21.1646 2.79719 21.1541C1.60402 21.1365 0.73321 20.5513 0.262666 19.4025C-0.207879 18.2538 -0.0543394 17.1492 0.788974 16.2654C1.8645 15.1359 2.32741 13.8106 2.29532 12.234C2.2747 11.1952 2.25713 10.1428 2.38546 9.11691C2.7674 6.04479 4.33104 3.76578 6.848 2.19641C7.89297 1.54939 9.03343 1.1713 10.2549 1.01557ZM11.1952 19.2315C13.9782 19.2315 16.761 19.2315 19.5435 19.2315C19.9736 19.2315 20.286 19.0493 20.4487 18.6391C20.6114 18.2289 20.561 17.858 20.2348 17.5442C20.1034 17.4131 19.9796 17.2737 19.8643 17.1267C18.7613 15.7789 18.248 14.216 18.2602 12.4387C18.2679 11.3694 18.2984 10.2873 18.164 9.2325C17.724 5.78068 14.8373 3.04572 11.5618 2.89079C8.20615 2.73024 5.11706 5.14972 4.32264 8.55819C4.01709 9.86105 4.14848 11.1864 4.11639 12.5037C4.11183 12.9631 4.07609 13.4216 4.00945 13.8756C3.77265 15.3205 3.11038 16.5367 2.13491 17.5787C1.99249 17.7354 1.89941 17.9344 1.86832 18.1486C1.79805 18.7667 2.21817 19.2291 2.81705 19.2291C5.61027 19.2323 8.40298 19.2331 11.1952 19.2315ZM13.7328 21.1774H8.63774C9.01968 22.343 10.1548 23.1305 11.3357 23.0727C12.4067 23.0213 13.5204 22.1391 13.7328 21.1774Z"
        fill="#FFD705"
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};
