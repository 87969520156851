import React from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
// import "./style.scss";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Clock icon

const Times = (props) => {
  const {
    scheduleIndex,
    setFieldValue,
    startTimeValues,
    startTimeTouched,
    startTimeErrors,
    endTimeValues,
    endTimeTouched,
    endTimeErrors,
    slot,
    records,
    handleChange,
    options,
    index,
    active,
    isMobile,
  } = props;
  const handleAddTimeSlot = () => {

    setFieldValue(
      `businessStartTime`,
      options.businessStartTime ? options.businessStartTime : []
    );
    setFieldValue(
      `businessEndTime`,
      options.businessEndTime ? options.businessEndTime : []
    );
  };

  const handleRemoveTimeSlot = (currIIndex) => {
    // const currStartTime =
    //   (startTimeValues &&
    //     startTimeValues.filter((time, index) => index !== currIIndex)) ||
    //   [];
    // const currEndTime =
    //   (endTimeValues &&
    //     endTimeValues.filter((time, index) => index !== currIIndex)) ||
    //   [];
    // setFieldValue(`scheduleItems.${scheduleIndex}.startTime`, currStartTime);
    // setFieldValue(`scheduleItems.${scheduleIndex}.endTime`, currEndTime);
  };

  return (
    <>
      <div>
        <div
          // className={` ${
          //   startTimeValues && startTimeValues.length > 1 ? "" : ""
          // }`}
          key={index}
        >
          <div className="row">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${!isMobile ? "col-md-4  " : "col-5"}`}>
                <TimePicker
                  id={`businessStartTime`}
                  name={`businessStartTime`}
                  format="HH:mm:ss"
                  // value={options.businessStartTime}
                  disabled={!options.isAvailable || active}
                  value={active ? "" : options.businessStartTime}
                  sx={{
                    width:isMobile?"100%": "100%",
                    borderRadius:isMobile?"10px": "0px",
                    minWidth:isMobile?"70px":"",

                    backgroundColor: isMobile?!active ? "#E0EBFF" : "#DCDCDC":!active ? "#F8F8F8" : "#ffff",
                    color: !active ? "#fff" : "#f4f7fc",
                    ".Mui-focused": {
                      borderColor: "red",
                    },
                    "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root":{
                      borderRadius:isMobile?"10px":"4px"
                    }
                  }}
                  // onChange={handleChange}
                  onChange={(newValue) => {
                    setFieldValue(`${index}.businessStartTime`, newValue);
                  }}
                  // disabled={active}
                  className="format"
                  // format="HH:mm a"
                  // disabled={day === 0}
                  helperText={
                    startTimeTouched &&
                    startTimeTouched[index] &&
                    startTimeErrors &&
                    startTimeErrors[index]
                  }
                  textColor={!active ? "#000" : "#fff"}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                      // InputProps: {
                      //   sx: {
                      //     color: textColor, // Set the text color here
                      //   },
                      // },
                    },
                  }}
                  textField={(params) => (
                    <TextField
                      {...params}
                      // name={name}
                      size={"small"}
                      variant={"standard"}
                      helperText={null}
                    />
                  )}
                />
                <p className="form-error">
                  {startTimeTouched &&
                  startTimeTouched[index] &&
                  startTimeErrors &&
                  startTimeErrors[index]
                    ? startTimeErrors[index]
                    : null}
                </p>
              </div>
              <div className={`${!isMobile ? "col-md-4  " : "col-5 "}`}>
                <TimePicker
                  disableIgnoringDatePartForTimeValidation
                  id={`businessEndTime`}
                  name={`businessEndTime`}
                  format="HH:mm:ss"
                  value={active ? "" : options.businessEndTime}
                  sx={{
                    width:isMobile?"100%": "100%",
                    minWidth:isMobile?"70px":"",
                    borderRadius:isMobile?"10px": "0px",
                    backgroundColor: isMobile?!active ? "#E0EBFF" : "#DCDCDC":!active ? "#F8F8F8" : "#ffff",
                    "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root":{
                      borderRadius:isMobile?"10px":"4px"
                    }
                  }}
                  disabled={!options.isAvailable || active}
                  // disabled={active} // Disable the field if active is false
                  // disabled={day === 0}
                  minTime={dayjs(startTimeValues && startTimeValues[index])}
                  // maxTime={dayjs(
                  //   startTimeValues && startTimeValues[index]
                  // )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccessTimeIcon style={{ visibility: 'hidden' }} />
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    endTimeTouched &&
                    endTimeTouched[index] &&
                    endTimeErrors &&
                    endTimeErrors[index]
                  }
                  onChange={(newValue) => {
                    setFieldValue(`${index}.businessEndTime`, newValue);
                  }}
                  textColor={!active ? "#000" : "#fff"}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                      // InputProps: {
                      //   sx: {
                      //     color: textColor, // Set the text color here
                      //   },
                      // },
                    },
                  }}
                  textField={(params) => (
                    <TextField
                      {...params}
                      // name={name}
                      size={"small"}
                      variant={"standard"}
                      helperText={null}
                      sx={{
                        borderRadius:"10px"
                      }}
                    />
                  )}
                />

                <p className="form-error">
                  {startTimeTouched &&
                  startTimeTouched[index] &&
                  startTimeErrors &&
                  startTimeErrors[index]
                    ? startTimeErrors[index]
                    : null}
                </p>
              </div>
            </LocalizationProvider>
            <div className={`${!isMobile ? "col-md-4  " : "col-2"}`}>
              <div className="row">
              <div className={`${!isMobile ? "col-md-6  " : "col-6"}`}>
                  <div className="button_schedular">
                    <div
                      variant="outlined"
                      disabled={active}
                      style={{ cursor: "pointer" }}
                      onClick={handleAddTimeSlot}
                    >
                      {active ? (
                        <img alt="no data" src="../images/Layer_1 2.svg" />
                      ) : (
                        <img alt="no data" src="../images/Layer_1.svg" />
                      )}
                      {/* <span style={{ size: "20px" }}>+</span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {startTimeValues && startTimeValues.length > 1 && (
                    <div className="button_schedular ">
                      <div
                        // variant="outlined"
                        // color="error"
                        onClick={() => {
                          handleRemoveTimeSlot(index);
                        }}
                      >
                        <img alt="no data" src="../images/crossButton.svg" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Times;
