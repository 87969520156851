import React, { useEffect, useState } from "react";
import Styles from "./InputField.module.css";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
// import { uploadMedia, uploadSignature } from "../axios/axios";
import { limitText } from "./HelperFunctions";
import { uploadMedia, uploadSignature } from "../../axios/axios";

const SignatureQuestion = ({
  text,
  onInputChange,
  id,
  formId,
  inputType,
  section,
  handleUploadMedia,
  uploadedData,
  uploadType,
  icon,
  title,
  type,
}) => {
  const [media, setMedia] = useState();
  const [data, setData] = useState();
  const [file, setFile] = useState();

  const handleUploadMediaa = async (id, media) => {
    let response = await uploadMedia(id, media);
    if (response?.error === false) {
      setData(response);
    }
  };

  const handleUploadSignature = async (media) => {
    let response = await uploadSignature(media, 1, 24);
  };

  const handleVideoUpload = async (event) => {
    const selectedVideo = event.target.files && event.target.files[0];

    setFile(limitText(selectedVideo?.name));

    handleUploadSignature(selectedVideo);
  };

  useEffect(() => {
  }, [data]);

  return (
    <div className={Styles.uploadContainer}>
      <p>{text}</p>
      <label htmlFor={id} className={Styles.uploadImage}>
        <div>{icon}</div>
        <p>{file}</p>
      </label>
      <input
        type="file"
        id={id}
        hidden
        multiple
        accept={uploadType}
        onChange={handleVideoUpload}
      />
    </div>
  );
};

export default SignatureQuestion;
