import React from 'react';

const Topbar = () => {
    return (
        <div className='d-flex justify-content-between align-items-center topbar_main px-2'>
            <div className='sec1 d-flex'>
                <img src='./meetspace/moon1.png' className='topbar_logo' />
                <span className='d-flex align-items-center ms-5'>
                    <h4 className='p-0 m-0'>Create One On One Meeting!</h4>
                    <img src='./meetspace/calc1.png' alt='' className='ms-2 h-50' />
                </span>
            </div>
        </div>
    )
}

export default Topbar