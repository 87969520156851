import React, { useState, useEffect } from "react";
import InputTextField from "../TextField";

import { Formik, Form, Field } from "formik";
import Button from "@mui/material/Button";
import { teamList } from "../../../utils/constant";
import { useLocation, useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Header from "../Header";
import { validationSchema } from "../Department/helper";
import { formateData, teamInitialValue } from "./helper";
import { useDispatch } from "react-redux";
import { addTeamRequest, updateTeamRequest } from "../../../Store/Team/actions";
import SideNavone from "../../../SideNavBar"
import MenuTabList from "../CustomTabs/MenuTabList";
import { addPermissionRequest } from "../../../Store/User/actions";

const AddTeam = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const action = location && location.state&& location.state.data&&location.state.data.action;
  const editData = location &&location.state&& location.state.data&& location.state.data.data;
  const flag = true;
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState("");
  useEffect(() => {
    // Retrieve the data from local storage when the component mounts
    const data = localStorage.getItem("departmentRecord");
    if (data) {
      setDepartment(JSON.parse(data));
    }
  }, []);
  const createPermission = (values) => {
    // setLoading(true);
    const data = formateData(values);
    // const jsonData = JSON.stringify(data);
    // addTeam(jsonData).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(teamList.ADD_TEAM);
    //     window.location = "/team";
    //   } else {
    //     error(res.message);
    //   }
    // });
    dispatch(
      addPermissionRequest({
        data,
        setLoading,
        history,
      })
    );
  };

  const updateTeam = (values) => {
    let id = editData.id;
    // setLoading(true);
    const data = formateData(values);
    // const jsonData = JSON.stringify(data);
    // editTeam(jsonData, id).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(teamList.DEPARTMENT_UPDATED);
    //     window.location = "/team";
    //   } else {
    //     error(res.message);
    //   }
    // });
    dispatch(
      updateTeamRequest({
        id,
        data,
        setLoading,
        history,
      })
    );
  };
  const submitForm = (values) => {
    // if (action === "edit") {
    //   updateTeam(values);
    // } else {
      createPermission(values);
    // }
  };

  return (
    <>
      <SideNavone>
      <Header
        flag={flag}
        heading={action === "edit" ? "Update Permission" : "Add Permission"}
      />
            <MenuTabList type="addPermission" />

      <div className="p-2 pt-4 min-h-[250px] form-container">
        <div className="w-100">
          <Formik
            initialValues={teamInitialValue(action, editData)}
            onSubmit={(values) => {
              submitForm(values);
            }}
            validationSchema={validationSchema}
          >
            {(options) => {
              const { handleSubmit, values, touched, errors, handleChange } =
                options;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="w-100 d-sm-flex !m-n2">
                    <div className="p-2 w-100">
                      <InputTextField
                        id="team-name"
                        label="Permission"
                        name="name"
                        placeholder="Permission"
                        //size={20}
                        value={values.name}
                        touched={touched.name}
                        error={touched.name && errors.name}
                        onChange={handleChange}
                        variant="filled"
                        autoFocused
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-2 w-100">
                    <InputTextField
                      id="description"
                      label="Description"
                      name="description"
                      placeholder="Type Description Here..."
                      //size={20}
                      variant="filled"
                      value={values.description}
                      onChange={handleChange}
                      autoFocused
                      touched={touched.description}
                      error={touched.description && errors.description}
                      multiline={2}
                      rows={5}
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                  <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                    <div className="p-2">
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          window.location = "/permissions";
                        }}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="p-2">
                      <Button
                        type="submit"
                        sx={{
                          width: "150px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {loading ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : action === "edit" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      </SideNavone>
    </>
  );
};

export default AddTeam;
