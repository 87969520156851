import React, { createContext, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Tooltip,
  //   Button,
  //   Checkbox,
  //   Chip,
  //   FormControlLabel,
  //   styled,
} from "@mui/material";
// import {
// 	CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
// 	CheckBox as CheckBoxIcon,
// 	AddOutlined as AddOutlinedIcon,
// 	RemoveOutlined as RemoveOutlinedIcon,
// 	HourglassEmptyOutlined as HourglassEmptyOutlinedIcon,
// 	PaymentOutlined as PaymentOutlinedIcon,
// 	MoreVertOutlined as MoreVertOutlinedIcon,
//   } from "@mui/icons-material";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
// import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
// import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CalendarApp from "./calenderWidgetSlot";
import EventDialog from "./EventDialog";
import EventUpdateDialog from "./EventUpdateDialog";
import moment from "moment";
import { selectedServiceData, selectedSlotData } from "../store/bookingSlice";
// import { useParams } from "react-router-dom/cjs/react-router-dom";
import { momentLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenAlert } from "../store/alertSlice";
import { getCalendarData, getSlotsData } from "../store/calendarSlice";
import {
  openmodel,
  //   selectedEvent,
  //   selectedSlot,
  //   openupdatemodel,
} from "../store/modelSlice";
import { customSnackBar } from "../../../utils";
import {
  ArrowDownYellowSvg,
  MoreOptionsVertSvg,
  ArrowUpYellowSvg,
  DecreasePackageQuantitySvg,
  IncreasePackageQuantitySvg,
  PaymentSvg,
  SandTimerSvg,
} from "../../../Assets/svgs";
// import { ClipLoader } from "react-spinners";
import CalendarSkeleton from "./skeleton";
import { serviceImageURL } from "../../../utils/imageUrlsExtension";
// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import TuneIcon from "@mui/icons-material/Tune";
// import AddIcon from "@mui/icons-material/Add";
// import { serviceAxios, slotAxios } from "../../axios";
// import { getNameOfResource } from "../../utils/ExtractAppointments";
const localizer = momentLocalizer(moment);
export const LabelContext = createContext();

export default function SlotPickingWidget({
  setSelectSlotFlag,
  callBackHandler,
}) {
  /* -------------------------------------------------------------------------- */
  /*                                  VARIABLES                                 */
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  // const { id } = useParams();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  function handleFilter() {
    setModelTypeg("filter");
    dispatch(openmodel());
  }

  //   const [currentData, setCurrentDate] = useState();
  const [eventsData, setEventDate] = useState([]);
  const [modelType, setModelTypeg] = useState("");
  const [clinicId, setClinicId] = useState("9106");
  // const [loading, setLoading] = useState(false);
  //   const [newSlot, setNewslott] = useState({
  //     resourceId: "",
  //     start: "",
  //     end: "",
  //   });
  const views = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    AGENDA: "agenda",
  };
  const allViews = Object.keys(views).map((k) => views[k]);

  /* ------------------------------ USE SELECTORS ----------------------------- */
  const {
    startTime,
    endTime,
    resources,
    // events: allevent,
    // slots,
    combinedData,
    isLoading,
    bgevents: allbgevent,
  } = useSelector((state) => state.calendarReducer);
  let dateApi = useSelector((state) => state.counterReducer.dateApi);
  const {
    servicesData: selectedService,
    // slotsData: { resourceId: providerId },
  } = useSelector((state) => state.bookingReducer);
  const {
    responseStatus,
    updateStatus,
    deleteStatus,
    successMessage,
    updateMessage,
    deleteMessage,
    isLoadingPost,
    isLoadingUpdsate,
    isLoadingDelete,
  } = useSelector((state) => state.modelReducer);
  // let allevent = useSelector((state) => state.calendarReducer.events);

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {}, [startTime, endTime]);
  //First Api Call on Start After Each time any dependency Change Api Call
  useEffect(() => {
    if (isLoadingPost === false) {
      if (responseStatus === "success") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
      if (responseStatus === "error") {
        dispatch(
          handleOpenAlert({ message: successMessage, status: responseStatus })
        );
      }
    }
  }, [isLoadingPost]);
  useEffect(() => {
    if (isLoadingUpdsate === false) {
      if (updateStatus === "success") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
      if (updateStatus === "error") {
        dispatch(
          handleOpenAlert({ message: updateMessage, status: updateStatus })
        );
      }
    }
  }, [isLoadingUpdsate]);
  useEffect(() => {
    if (isLoadingDelete === false) {
      if (deleteStatus === "success") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
      if (deleteStatus === "error") {
        dispatch(
          handleOpenAlert({ message: deleteMessage, status: deleteStatus })
        );
      }
    }
  }, [isLoadingDelete]);
  // const extractedIds = [];
  // const extractedIds = resources.map(item=>item.id);
  const providerName = "";

  const extractResourceIds = useCallback(() => {
    const extractedIds = [];
    resources.forEach((obj) => {
      const currentId = obj.resourceId;
      extractedIds.push(currentId);
    });
    return extractedIds;
  }, [resources]);

  // resources.forEach((obj) => {
  //   const currentId = obj.id;
  //   extractedIds.push(currentId);
  // if (
  //   extractedIds.length === 0 ||
  //   currentId > extractedIds[extractedIds.length - 1]
  // ) {
  //   extractedIds.push(currentId);
  //   return true; // Continue iteration
  // } else {
  //   return false; // Stop iteration
  // }
  // });

  const getCalendarEventsData = useCallback(async () => {
    const record = {
      providerId: [],
      bookingDateStart: dateApi,
      is_business: true,
      bookingDateEnd: dateApi,
      timeZone: "Europe/London",
      businessId: selectedBusiness?.id,
    };
    await dispatch(getCalendarData(record));
  }, [dispatch, dateApi, selectedBusiness]);

  const getSlotsEventData = useCallback(async () => {
    if (selectedService[0]?.serviceSKU === null) {
      customSnackBar("Slot not exists");
    } else {
      const extractedIds = extractResourceIds();

      const data = {
        // providerId: extractedIds,
        // date: dateApi,
        // sku:selectedService[0]?.serviceSKU,
        // slotDuration: parseInt(selectedService[0].duration),
        // page: 1,
        // pageSize: extractedIds.length,
        // timeZone: "Europe/London",
        page: 1,
        providerId: extractedIds,
        date: dateApi,
        timeZone: "Europe/London",
        pageSize: 50,
        sku: selectedService[0]?.attributes
          ? selectedService[0]?.attributes[0].attributeSku
          : selectedService[0]?.serviceSKU,
        hasBreak: false,
        businessId: selectedBusiness.id,
        // slotDuration: parseInt(selectedService[0].duration),
      };

      await dispatch(getSlotsData({ data }));
    }
  }, [dispatch, dateApi, selectedBusiness, selectedService]);

  useEffect(() => {
    const openCalendar = async () => {
      if (dateApi && selectedBusiness.id) {
        await getCalendarEventsData();
        await getSlotsEventData();
      }
    };
    openCalendar();
  }, [getCalendarEventsData, getSlotsEventData, dateApi, selectedBusiness]);

  // console.log(loading, "LOADINDDHHDHDH.....");
  //Onclick Event Function Call
  function calenderEvents(events) {
    setEventDate(events);
  }
  //Onclick Event Function Call
  function handleEvents(events) {
    if (!Object.isExtensible(events)) {
      events = { ...events };
    }
    const data = resources.filter(
      (val) => val.resourceId === events.resourceId
    );
    console.warn(events, data);
    events.provider = data[0];
    const currentDate = new Date();
    const datetime = new Date(events.start);
    events.bookingDate = `${datetime.getFullYear()}-${(datetime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${datetime.getDate().toString().padStart(2, "0")}`;
    if (datetime < currentDate) {
      customSnackBar("Slot is in past unable to select that");
      // dispatch(
      //   openToast({
      //     message: "Slot is in past unable to select that",
      //     status: "warning",
      //   })
      // );
    } else if (events.title === "Available Slots") {
      dispatch(selectedSlotData(events));
      dispatch(selectedServiceData(selectedService));
      setSelectSlotFlag("addCustomer");
    } else {
      customSnackBar("Already Booked Slot");
      // dispatch(
      //   openToast({ message: "Already Booked Slot", status: "warning" })
      // );
    }
  }

  //Onclick Slot Function Call
  function handleSlots(slot) {
    // setNewslott({
    // 	resourceId: slot.resourceId,
    // 	start: slot.start,
    // 	end: slot.end,
    // });
    // setModelTypeg("slot");
    // dispatch(openmodel());
    // dispatch(selectedSlot(slot));
  }

  //Onclick Filter Model
  function handleFilter() {
    setModelTypeg("filter");
    dispatch(openmodel());
  }

  //Clinic Change Functions
  const handleChangeClinic = (id) => {
    setClinicId(id);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      var time = moment().format("LT");
      const existing = document.getElementsByClassName("my-div-time")[0];
      if (existing) {
        existing.innerHTML = `${time}`;
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      handleCurrentIndicator();
    }, 220);
  }, []);
  const handleCurrentIndicator = () => {
    var time = moment().format("LT");
    const existingDiv = document?.getElementsByClassName(
      "rbc-current-time-indicator"
    )[0];
    if (existingDiv) {
      const newDiv = document.createElement("div");
      newDiv.className =
        "my-div-time -mt-2 text-xs -ml-20 px-2 border-2 w-fit border-red-500 rounded-full";
      newDiv.textContent = `${time}`;
      existingDiv.appendChild(newDiv);
    }
  };
  const increaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (service.serviceName === serviceName.serviceName) {
        return { ...service, bookedSeats: service?.bookedSeats + 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };

  const decreaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (
        service.serviceName === serviceName.serviceName &&
        service.bookedSeats > 1
      ) {
        return { ...service, bookedSeats: service.bookedSeats - 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };

  return (
    <div className="mx-1 mt-2">
      <div
        className=" pt-1 ps-3 mb-3"
        style={{
          borderRadius: "8px",
          border: "0.6px solid #DCDCDC",
          background: "#FFF",
          position: "relative",
          paddingRight:"11px",
          paddingBottom: "10px",
        }}
      >
        <div className="d-flex mt-2 justify-content-between align-items-center">
          <div className="d-flex">
            <Tooltip title="Back">
              <div className="pe-2" onClick={callBackHandler} role="button">
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
            </Tooltip>
            <span className="widget-service pt-1">Service Details</span>
          </div>
          <div className="d-flex">
            <span className="widget-quantity pt-1">Quantity</span>{" "}
            <div
              className="ms-3"
              style={{
                borderRadius: "8px",
                backgroundColor: "#F6F6F6",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 10px",
              }}
            >
              <div
                onClick={(e) => {
                  decreaseQuantity(selectedService[0]);
                }}
                role="button"
              >
                <DecreasePackageQuantitySvg />
              </div>
              <div
                className="mx-2"
                style={{
                  color: "#4D4D4D",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                {selectedService[0]?.bookedSeats
                  ? selectedService[0]?.bookedSeats
                  : 1}
                {/* {quantity} */}
              </div>
              <div
                onClick={(e) => {
                  increaseQuantity(selectedService[0]);
                }}
                role="button"
              >
                <IncreasePackageQuantitySvg />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div>
            {selectedService.map((vl, index) => {
              return (
                <>
                  <div className="d-flex justify-content-between" style={{paddingLeft:"11px"}}>
                    <div key={index} className="d-flex ">
                      {selectedService ? (
                        <div className="d-flex align-items-center justify-content-center mr-3 " style={{height:"40px", width:"40px"}}>
                          <Avatar
                            alt={vl?.serviceName}
                            src={serviceImageURL(vl?.serviceId)}
                            variant="rounded"
                            style={{
                              borderRadius: 50,
                              width: "30px",
                              height: "30px",
                              backgroundColor:"#F0F0F0",
                              color:"#B5B5B5"
                            }}
                          />
                          {/* <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="user"
                            style={{height:"100%", width:"100%"}}
                          >
                            <rect
                              width="30"
                              height="30"
                              rx="15"
                              fill="#F0F0F0"
                            />
                            <path
                              d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                              stroke="#B5B5B5"
                              stroke-width="0.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                              stroke="#B5B5B5"
                              stroke-width="0.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                        </div>
                      ) : (
                        <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                          <Avatar
                            alt={vl?.serviceName}
                            src={serviceImageURL(vl?.serviceId)}
                            variant="rounded"
                            style={{
                              borderRadius: 50,
                              width: "30px",
                              height: "30px",
                              backgroundColor:"#F0F0F0",
                              color:"#B5B5B5"
                            }}
                          />
                          {/* <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="30"
                              height="30"
                              rx="15"
                              fill="#F0F0F0"
                            />
                            <path
                              d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                              stroke="#B5B5B5"
                              stroke-width="0.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                              stroke="#B5B5B5"
                              stroke-width="0.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                        </div>
                      )}
                      <div className="d-flex justify-content-between">
                        <div
                          style={{
                            textAlign: "left",
                          }}
                          className=" justify-content-start"
                        >
                          <div
                            className={`d-flex ${
                              vl && vl.attributes && vl.attributes.length === 0
                                ? "pt-1"
                                : "pt-1"
                            }`}
                          >
                            {/* Your content here */}

                            <div className="d-flex flex-column">
                              <p className="m-0 mx-1 widget_servicename">
                                {vl?.serviceName}{" "}
                                <span className="widget_serviceduration">
                                  ({vl?.duration} mins)
                                </span>
                              </p>{" "}
                              {vl.attributes &&
                                vl.attributes[0] &&
                                vl.attributes.length > 0 && (
                                  <span className="m-0 mx-1 widget_description">
                                    {vl.attributes[0].value}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      {/* <PaymentSvg /> */}
                      <span className="ms-1 widget_price">
                        {vl.currency === null
                          ? "£"
                          : vl.currency === "USD"
                          ? "$"
                          : "£"}
                        {vl.actualPrice === null || vl.actualPrice === 0
                          ? `${1 * selectedService[0]?.bookedSeats}`
                          : vl.actualPrice * selectedService[0]?.bookedSeats}
                      </span>
                    </div>
                  </div>
                  {/* <hr
                    className="mt-3 ms-0 mb-1"
                    style={{
                      color: "#c4c4c4",
                      margin: "15.6px 0 21.4px",
                    }}
                  />
                      {isExpanded&&(

                  <div className="pt-0">
                    {selectedService[0].attributes && (
                      <div>
                        <span
                          style={{ fontSize: "12px" }}
                          className="businessId"
                        >
                          Attributes
                        </span>
                        <div
                          onClick={() => setIsExpanded(!isExpanded)}
                          role="button"
                          className="d-flex justify-content-end align-items-center"
                        ></div>
                        {selectedService[0].attributes &&
                          selectedService.map((vl, index) =>
                            vl.attributes.map((value) => (
                              <div
                                className="d-flex justify-content-between align-items-center my-2"
                                key={index}
                              >
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    alt={value.value}
                                    src="/images/tag 1.svg"
                                    // src={value?.value}
                                    style={{ width: 20, height: 20 }}
                                    onError={(e) => {
                                      e.target.src =
                                        "https://people.com/thmb/gbTutE0DaHlAxmbAHXa1OU24qGM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/selena-gomez-rare-beauty-tout-475f8973ffa34df0990416daad701564.jpg";
                                    }}
                                  />
                                  <Tooltip title={value.value}>
                                    <p className="m-0 mx-2 ellipsis selected_servicename">
                                      {value.value}
                                    </p>
                                  </Tooltip>
                                </div>
                                <div className="d-flex justify-content-around align-items-center">
                                  <div className="d-flex justify-content-end align-items-center">
                                    <SandTimerSvg />
                                    <span
                                      className="ms-1"
                                      style={{
                                        color: "#4D4D4D",
                                        textAlign: "center",
                                        fontFamily: "Poppins",
                                        fontSize: "11px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {value.duration + " Min "}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <PaymentSvg />
                                  <span
                                    className="ms-1"
                                    style={{
                                      color: "#4D4D4D",
                                      textAlign: "center",
                                      fontFamily: "Poppins",
                                      fontSize: "11px",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                    }}
                                  >
                                    £{value.attributePrice}
                                    
                                  </span>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                  <MoreOptionsVertSvg />
                                </div>
                              </div>
                            ))
                          )}
                      </div>
                    )}

                  </div>
                      )} */}
                </>
              );
            })}
          </div>
        </div>
        {/* {selectedService[0].attributes && ( */}
        <div
          style={{
            position: "absolute",
            bottom: "-18px",
            left: "50%",
            zIndex: "9999",
          }}
          className="d-flex justify-content-center"
        >
          {/* <div
            style={{
              background: "white",
              cursor: "pointer",
              border: " 0.6px solid rgb(220, 220, 220)",
              borderRadius: "50px",
              height: "35px",
              width: "35px",
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="pt-1 px-2">
              {!isExpanded ? (
                <Tooltip title="Expand">
                  <ArrowDownYellowSvg />
                </Tooltip>
              ) : (
                <Tooltip title="Collap">
                  <ArrowUpYellowSvg />
                </Tooltip>
              )}
            </div>
          </div> */}
        </div>
        {/* // )} */}
      </div>
      {isLoading ? (
        <CalendarSkeleton />
      ) : (
        <div
          className="col-12 overflow_calender mt-4 height_for_booking"
          style={{ width: "100%" }}
        >
          <CalendarApp
            // action={action}
            // eventData={eventData}
            localizer={localizer}
            allevent={combinedData}
            allbgevent={allbgevent}
            resources={resources}
            views={allViews}
            startTime={startTime}
            endTime={endTime}
            calenderEvents={handleEvents}
            handleSlots={() => {}}
          />
          {/* <EventDialog
            modelType={modelType}
            clinicId={clinicId}
            handleChangeClinic={handleChangeClinic}
          />
          <EventUpdateDialog /> */}
        </div>
      )}
    </div>
  );
}
