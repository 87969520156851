import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import {
  fetchDepartmentSuccess,
  fetchDepartmentFailure,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
} from "./actions";
import {
  ADD_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_SUCCESS,
} from "./actionType";
import { push } from "connected-react-router";
import axiosConfig from "../../Routes/AxiosConfigg";
import { customSnackBar, success } from "../../utils";
import { NO_DATA_FOUND, departmentList } from "../../utils/constant";
import instance from "../../utils/utils";

function* fetchDepartment(action) {
  const { payload: id } = action;
  try {
    const response = yield call(
      instance.get,
      `/business_svc/pb/department?businessId=${id}`
    
    );
    // const response = yield axiosConfig.get(`/business_svc/pb/department?businessId=${id}`);
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        customSnackBar(NO_DATA_FOUND);
        yield put(fetchDepartmentFailure(NO_DATA_FOUND));
      } else {
        // success(departmentList.DEPARTMENT_FETCH);

        yield put(fetchDepartmentSuccess(response.data.result));
      }
    } else {
      yield put(fetchDepartmentFailure(response.data.message));
    }
  } catch (customSnackBar) {
    customSnackBar(customSnackBar.response.data.message);
    yield put(fetchDepartmentFailure(customSnackBar.message));
  }
}
function* addDepartmentSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.post("business_svc/pb/department/", data);
    const response = yield call(
      instance.post,
      `business_svc/pb/department/`,
      data
    
    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(departmentList.DEPARTMENT_CREATE);
      // payload.history.push("./department");
      yield put(push("/department"))
      // window.location=`/department?id=${payload.busienssId}`
      yield put({
        type: ADD_DEPARTMENT_SUCCESS,
        payload: response.data.result,
      });
    } else {
      customSnackBar(response.data.message);
      yield put({
        type: ADD_DEPARTMENT_FAILURE,
        payload: response.data.message,
      });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);
    yield put({ type: ADD_DEPARTMENT_FAILURE, payload: "" });
  }
}

function* updateDepartmentSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.patch(
    //   `business_svc/pb/department/${payload.id}/`,
    //   data
    // );
    const response = yield call(
      instance.patch,
      `business_svc/pb/department/${payload.id}/`,
      data
    
    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(departmentList.DEPARTMENT_UPDATED);
      yield put(push("/department"))
      // yield put({ type: UPDATE_DEPARTMENT_SUCCESS, payload:response.data.result });
    } else {
      customSnackBar(response.data.message);
      // yield put({ type: UPDATE_DEPARTMENT_FAILURE, payload: response.data.message });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);
    yield put({ type: UPDATE_DEPARTMENT_FAILURE, payload: "" });
  }
}
function* deleteDepartmentSaga({ payload }) {
  try {
    // Call the delete API function passing the ID
    // const response = yield axiosConfig.delete(`business_svc/pb/department/${payload.id}/`);
    const response = yield call(
      instance.delete,
      `business_svc/pb/department/${payload.id}/`,
      
    
    );
    if (response.data.code === 0) {
      // success(departmentList.DEPARTMENT_DELETE);
      payload.setRowData(
        payload.rowData.filter((item) => item.id !== payload.id)
      );

      // yield put(deleteDepartmentSuccess(payload.id));
    } else {
      customSnackBar(response.data.message);
      yield put(deleteDepartmentFailure(response.message));
    }
  } catch (customSnackBar) {
    customSnackBar(customSnackBar.response.data.message);
    yield put(deleteDepartmentFailure(customSnackBar.response.data.message));
  }
}
export function* fetchDepartmentBinder() {
  yield takeLatest(FETCH_DEPARTMENT_REQUEST, fetchDepartment);
}

export function* addDepartmentWatcher() {
  yield takeLatest(ADD_DEPARTMENT_REQUEST, addDepartmentSaga);
}
export function* updateDepartmentWatcher() {
  yield takeLatest(UPDATE_DEPARTMENT_REQUEST, updateDepartmentSaga);
}
export function* deleteDepartmentWatcher() {
  yield takeLatest(DELETE_DEPARTMENT_REQUEST, deleteDepartmentSaga);
}

export default function* departmentSaga() {
  yield all([
    fork(fetchDepartmentBinder),
    fork(addDepartmentWatcher),
    fork(updateDepartmentWatcher),
    fork(deleteDepartmentWatcher),
  ]);
}
