import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AddSerivceColumn = ({ data, updateData }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelecteData] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const dataa = [
    {
      id: 1,
      serviceName: "expert staff",
      columnName: "id",
      position: 1,
      isShown: true,
      isSticky: false,
    },
    {
      id: 5,
      serviceName: "expert staff",
      columnName: "designation",
      position: 2,
      isShown: true,
      isSticky: false,
    },
    {
      id: 2,
      serviceName: "expert staff",
      columnName: "title",
      position: 3,
      isShown: true,
      isSticky: false,
    },
    {
      id: 3,
      serviceName: "expert staff",
      columnName: "firstName",
      position: 4,
      isShown: true,
      isSticky: false,
    },
    {
      id: 4,
      serviceName: "expert staff",
      columnName: "lastName",
      position: 5,
      isShown: true,
      isSticky: false,
    },
    {
      id: 6,
      serviceName: "expert staff",
      columnName: "qualification",
      position: 6,
      isShown: false,
      isSticky: false,
    },
    {
      id: 7,
      serviceName: "expert staff",
      columnName: "nationality",
      position: 7,
      isShown: false,
      isSticky: false,
    },
    {
      id: 8,
      serviceName: "expert staff",
      columnName: "countryId",
      position: 8,
      isShown: false,
      isSticky: false,
    },
    {
      id: 9,
      serviceName: "expert staff",
      columnName: "nationalTaxNumber",
      position: 9,
      isShown: false,
      isSticky: false,
    },
    {
      id: 10,
      serviceName: "expert staff",
      columnName: "mobile",
      position: 10,
      isShown: false,
      isSticky: false,
    },
    {
      id: 11,
      serviceName: "expert staff",
      columnName: "email",
      position: 11,
      isShown: false,
      isSticky: false,
    },
    {
      id: 12,
      serviceName: "expert staff",
      columnName: "kinFirstName",
      position: 12,
      isShown: false,
      isSticky: false,
    },
    {
      id: 13,
      serviceName: "expert staff",
      columnName: "kinLastName",
      position: 13,
      isShown: false,
      isSticky: false,
    },
    {
      id: 14,
      serviceName: "expert staff",
      columnName: "kinMobile",
      position: 14,
      isShown: false,
      isSticky: false,
    },
    {
      id: 15,
      serviceName: "expert staff",
      columnName: "kinAddress",
      position: 15,
      isShown: false,
      isSticky: false,
    },
    {
      id: 16,
      serviceName: "expert staff",
      columnName: "kinEmail",
      position: 16,
      isShown: false,
      isSticky: false,
    },
    {
      id: 17,
      serviceName: "expert staff",
      columnName: "hasServiceProvider",
      position: 17,
      isShown: false,
      isSticky: false,
    },
    {
      id: 18,
      serviceName: "expert staff",
      columnName: "projectId",
      position: 18,
      isShown: false,
      isSticky: false,
    },
    {
      id: 19,
      serviceName: "expert staff",
      columnName: "isActive",
      position: 19,
      isShown: false,
      isSticky: false,
    },
    {
      id: 20,
      serviceName: "expert staff",
      columnName: "isDeleted",
      position: 20,
      isShown: false,
      isSticky: false,
    },
    {
      id: 21,
      serviceName: "expert staff",
      columnName: "createdby",
      position: 21,
      isShown: false,
      isSticky: false,
    },
    {
      id: 22,
      serviceName: "expert staff",
      columnName: "createdOn",
      position: 22,
      isShown: false,
      isSticky: false,
    },
    {
      id: 23,
      serviceName: "expert staff",
      columnName: "modifiedOn",
      position: 23,
      isShown: false,
      isSticky: false,
    },
    {
      id: 24,
      serviceName: "expert staff",
      columnName: "modifiedby",
      position: 24,
      isShown: false,
      isSticky: false,
    },
  ];

  const handleMenuClick = (event, data) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row.id);
    setSelecteData(data);
  };
  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleHideClick = async (columnName) => {
    const updatedColumns = data.map((column) =>
      column.id === selectedData.id ? { ...column, isShown: false } : column
    );

    updateData(updatedColumns);
    setOpen(false);
    const dataWithoutServiceNameAndColumnName = updatedColumns.map((item) => {
      const { serviceName, columnName, ...rest } = item;
      return rest;
    });

    const payload = {
      userId: 4,
      columns: dataWithoutServiceNameAndColumnName,
      createdBy: 0,
    };
    //   const result = await updateColumnPreferrence(payload);
    //   const code = result.data.code;
    //   const message = result.data.message;
    //   if (code === 0) {
    //     updateData(updatedColumns);
    //   } else {
    //   }
  };
  const handleFreezeClick = () => {};
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedData = Array.from(data).map((column) => ({ ...column }));

    const [removed] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, removed);

    reorderedData.forEach((column, index) => {
      column.position = index + 1;
    });

    updateData(reorderedData);
  };

  return (
    <>
      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {" "}
        <Box sx={{ display: "flex", maxWidth: "982px" }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columnList" direction="horizontal">
              {(provided) => (
                <Grid
                  container
                  wrap="nowrap"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {data
                    .filter((column) => column.isShown)
                    // .sort((a, b) => a.position - b.position)
                    .map((columnName, index) => (
                      <Draggable
                        key={columnName.columnName}
                        draggableId={columnName.columnName}
                        index={index}
                      >
                        {(provided) => (
                          <Grid
                            item
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              sx={{
                                border: "1px solid #DCDCDC",
                              }}
                              component="div"
                              bgcolor="#FFFFFF"
                              height="41px"
                              display="flex"
                              justifyContent="space-between"
                              minWidth="222px"
                              alignItems="center"
                            >
                              <Typography pl={2}>
                                {columnName.columnName}{" "}
                              </Typography>
                              <MoreVertIcon
                                fontSize="small"
                                onClick={(event) =>
                                  handleMenuClick(event, columnName)
                                }
                              />
                            </Box>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        {/* <button onClick={handleSaveChanges}>Save Changes</button> */}
      </Box>
      {open && (
        <Menu
          anchorEl={anchorEl}
          //   open={open}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleHideClick}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
              hide
            </Box>
          </MenuItem>
          {/* <MenuItem onClick={handleFreezeClick}>
           
            Freeze
          </MenuItem> */}
        </Menu>
      )}
    </>
  );
};

export default AddSerivceColumn;
