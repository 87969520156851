import boxicon from "./box.png";
import calendericon from "./calender.png";
import coinsicon from "./coins.png";
import heroimage from "./heroimage.png";
import layericon from "./layer.png";
import plexaarlogo from "./plexaarlogo.png";
import usericon from "./user.png";
import lady from "./lady.png";
import horse from "./horse.png";
import t60 from "./360.png";
import setting from "./setting.png";
import laptop from "./laptop.png";
import feature1 from "./feature1.png";
import feature2 from "./feature2.png";
import feature3 from "./feature3.png";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import icon3 from "./icon3.png";
import laptopphone from "./laptopphone.png";
import office from "./business-people-modern-office 1.png";
import pie from "./pie.png";
import ladder from "./ladder.png";
import icon5 from "./1 1.png";
import icon from "./2 1.png";
import signUp from "./signup.png";
import headerLogo from "./plexaarLogo2.png";

export {
  horse,
  t60,
  setting,
  boxicon,
  calendericon,
  coinsicon,
  heroimage,
  layericon,
  plexaarlogo,
  lady,
  usericon,
  laptop,
  feature2,
  feature1,
  feature3,
  icon1,
  icon2,
  icon3,
  laptopphone,
  office,
  pie,
  ladder,
  icon,
  icon5,
  signUp,
  headerLogo,
};
