import React from "react";
import QuestionTypes from "../QuestionTypes/QuestionTypes";

const QuestionSection = ({
  questionsData,
  onInputChange,
  formId,
  section,
  handleUploadMedia,
  uploadedData,
}) => {
  return (
    <div>
      <div>
        {questionsData?.map((item, index) => (
          <div key={index}>
            {item?.contentObject?.questionType === "SmallTextQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="SmallTextQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                formId={formId}
                section={section}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}
            {item?.contentObject?.questionType === "LongTextQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="LongTextQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                formId={formId}
                section={section}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "DropdownQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="DropdownQuestion"
                options={item?.contentObject?.options}
                onInputChange={onInputChange}
                section={section}
                formId={formId}
                id={item?.id}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "EmailQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="EmailQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "NumberQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="NumberQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "MCQQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="MCQQuestion"
                options={item?.contentObject?.options}
                onInputChange={onInputChange}
                section={section}
                formId={formId}
                id={item?.id}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "DateQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="DateQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "ImageQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                handleUploadMedia={handleUploadMedia}
                inputType="ImageQuestion"
                id={item?.id}
                section={section}
                formId={formId}
                onInputChange={onInputChange}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "DocumentQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="DocumentQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "SignatureQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="SignatureQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "VideoQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="VideoQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "AudioQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="AudioQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "LabelQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="LabelQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}

            {item?.contentObject?.questionType === "StatementQuestion" && (
              <QuestionTypes
                typeText={item?.contentObject?.questionText}
                inputType="StatementQuestion"
                onInputChange={onInputChange}
                id={item?.id}
                section={section}
                formId={formId}
                handleUploadMedia={handleUploadMedia}
                uploadedData={uploadedData}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionSection;
