import React, { useEffect } from "react";
import "./dashboardpages.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Header from "../BusinessProfile/Header";
import { Button } from "@mui/material";
import { adddob } from "../../Store/Auth/actions";
import SideNavone from "../../SideNavBar/index";

function Adddob() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [alluserdata, setAlluserdata] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  // const [dateofbirth, setDateofbirth] = useState("");
  const {  alluserdataafterlogin } = useSelector(
    (state) => state.Auth
  );
  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const getdataofregistereduser = useSelector(
    (state) => state.Auth?.alluserdataafterlogin
  );
  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
  };
  const [dateofbirth, setDateOfBirth] = useState("");
  const [error, setError] = useState("");
  const flag = true;
  useEffect(() => {
    const userDOB = alluserdataafterlogin?.dob?.split("T")[0] ?? "";
    setDateOfBirth(userDOB);
  }, [alluserdataafterlogin]);
  const handleNumberChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - selectedDate.getFullYear();

    if (age < 18) {
      setError("You must be 18 years or older.");
    } else if (age >= 100) {
      setError("Your age must not exceed century");
    } else {
      setDateOfBirth(event.target.value);
      setError("");
    }
  };

  function calculateMaxDate() {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0];
  }
  const adddobdunc = () => {
    dispatch(
      adddob({
        id: registersuccessId,
        dob: dateofbirth,
        modifiedBy: registersuccessId,
        // history: history,
        setIsLoading,
      })
    );
  };
  return (
    <>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-md-4">
          <div>
            <p className=" ps-3 sub_text_color_account_alltext_show_actual">
              Edit Date of Birth
            </p>
          </div>
          <div
            className="ps-2 pe-2 w-100 input-container"
            style={{
              border: "0.8px solid #DCDCDC",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <span
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              Date Of Birth
            </span>

            <input
              type="date"
              variant="filled"
              value={dateofbirth}
              onChange={handleNumberChange}
              autoFocus={true}
              style={{
                backgroundColor: "#fff",
                border: "none",
                outline: "none",
                width: "100%",
                fontWeight: "normal",
                color: "black",
              }}
              max={calculateMaxDate()}
            />
          </div>
          {error && <p className="ms-2 text-danger">{error}</p>}
          <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
            <div className="p-2">
              <Button
                type="submit"
                sx={{
                  width: "150px",
                  backgroundColor: "#538dff",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                onClick={() => adddobdunc()}
                disabled={error || dateofbirth === ""}
                variant="contained"
              >
                 {isLoading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <>
                {"Save & Continue"}
                              </>
                              )}
              </Button>
            </div>
          </div>
        </div>
      </SideNavone>
    </>
  );
}

export default Adddob;

/* <div className="">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
            sx={{backgroundColor:"#fff"}}
             />
          </LocalizationProvider>
          </div> 
          <SideNavone>
        <div className="p-4">
          <div className="col-md-12  card p-4" id="style-2">
            <h2>Date of birth</h2>

            <div className="col-md-12 px-0 pb-2 pt-3">
              <p className="mb-0 sub_text_color_account_alltext">
                Date of Birth
              </p>
            </div>
            <input
              className="form-control form-control mt-2 background_color_gray"
              placeholder="Email Address"
              value={dateofbirth}
              onChange={handleNumberChange}
              type="date"
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            {error || dateofbirth === "" ? (
              <button className="btn btn-primary disabled_button mt-4 px-5">
                Save
              </button>
            ) : (
              <button
                className="btn btn-primary mt-4 px-5"
                onClick={adddobdunc}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </SideNavone>
      <InputTextField
              id="primary-email"
              label="Date oof Birth"
              name="primaryemail"
              placeholder="Enter Date of Birth"
              //size={20}
              variant="filled"
              value={dateofbirth}
              onChange={handleNumberChange}
              type="date"
              autoFocused
              sx={{
                // maxWidth: "300px",
                backgroundColor: "#fff",
              }}
            /> */
