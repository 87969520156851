import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../Assets/components/meetspace/sidemenu.scss";

const SideMenu = ({ children, topbar }) => {
  const [isActiveLink, setIsActiveLink] = useState(0);

  return (
    <div className="bod_overflow">
      <div className="col-md-12 pt-1 pb-2 background_nav1_header">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3">
                <img
                  className="img-fluid"
                  src="images/logoheader.png"
                  alt="not-found"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 text-right col6section m-auto">Welcome</div>
        </div>
      </div>
      {topbar ? topbar :''}
      
      <div className="col-md-12 px-0 overflow_main_dashboard p-1">
        <div className="d-flex">
          <div className="side_nav_main_1">
            <div className="col-md-12 pr-0 pl-1">
              <div className="col-md-12 height_fiz_menu">
                <NavLink
                  to="/create-new-service"
                  isActive={(match) => {
                    if (match?.isExact) {
                      setIsActiveLink(1);
                    }
                  }}
                >
                  <div className={`py-2 ms-1 ${isActiveLink === 1 ? 'inv_active_menu' : ''}`}
                  >
                    <i className="fas fa-book-open d-flex justify-content-center"></i>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-12 height_fiz_menu">
                <NavLink
                  to="/all-services"
                  isActive={(match) => {
                    if (match?.isExact) {
                      setIsActiveLink(2);
                    }
                  }}
                >
                  <div className={`py-2 ms-1 ${isActiveLink === 2 ? 'inv_active_menu' : ''}`}
                  >
                    <i className="far fa-clipboard d-flex justify-content-center"></i>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-12 height_fiz_menu">
                <NavLink
                  to="/business/"
                  isActive={(match) => {
                    if (match?.isExact) {
                      setIsActiveLink(3);
                    }
                  }}
                >
                  <div className={`py-2 ms-1 ${isActiveLink === 3 ? 'inv_active_menu' : ''}`}
                  >
                    <i className="fas fa-tools d-flex justify-content-center"></i>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-12 height_fiz_menu">
                <NavLink
                  to=""
                  isActive={(match) => {
                    if (match?.isExact) {
                      setIsActiveLink(4);
                    }
                  }}
                >
                  <div className={`py-2 ms-1 ${isActiveLink === 4 ? 'inv_active_menu' : ''}`}
                  >
                    <i className="fas fa-chart-line d-flex justify-content-center"></i>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-12 height_fiz_menu">
                <NavLink
                  to=""
                  isActive={(match) => {
                    if (match?.isExact) {
                      setIsActiveLink(5);
                    }
                  }}
                >
                  <div className={`py-2 ms-1 ${isActiveLink === 5 ? 'inv_active_menu' : ''}`}
                  >
                    <i className="far fa-user-circle d-flex justify-content-center"></i>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="side_nav_1_right">
            <div className="col-md-12 background_sidenav_one">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
