import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import {
  BookingDetails,
  addCustomerData,
  resetAllData,
  selectedServiceData,
} from "../store/bookingSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomerSearch from "./Listplexaar";
import { Avatar, Button, SvgIcon } from "@mui/material";
import NotesData from "../../assets/scheduler/payment/notes.svg";
import ChatData from "../../assets/scheduler/payment/chat.svg";
import AttributeData from "../../assets/scheduler/payment/attributes.svg";
import DocumentData from "../../assets/scheduler/payment/documents.svg";
import DirectionData from "../../assets/scheduler/payment/directions.svg";
import PayCash from "../../assets/scheduler/payment/paybycash.svg";
import PayLink from "../../assets/scheduler/payment/paylink.svg";
import PayTerminal from "../../assets/scheduler/payment/payterminal.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { salesOrderAxios } from "../../axios";
import { useHistory } from "react-router-dom";
import CustomizedSnackbars from "./AlertToast";
import { openToast } from "../store/alertSlice";
import "../../assets/scheduler/css/calendar.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NotesModel from "../bookingDetails/NotesModel";
import { customSnackBar } from "../../../utils";
import {
  CalendarSvg,
  DecreasePackageQuantitySvg,
  IncreasePackageQuantitySvg,
  MoreOptionsVertSvg,
  PayByCashActiveSvg,
  PayByCashDeActiveSvg,
  PayByLinkActiveSvg,
  PayByLinkDeActiveSvg,
  PayByTerminalDeActiveSvg,
  PaymentSvg,
  SandTimerSvg,
  ArrowDownYellowSvg,
  ArrowUpYellowSvg,
  TimerSvg,
  PayByTerminalActiveSvg,
} from "../../../Assets/svgs";
import { getCalendarData } from "../store/calendarSlice";
import instance from "../../../utils/utils";
import {
  providerImageURL,
  serviceImageURL,
} from "../../../utils/imageUrlsExtension";
import CustomPhoneInput from "Pages/DynamicForm/dynamicFormView/phoneInput";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PortalData = [
  {
    title: "Notes",
    img: (
      <svg
        width="22"
        height="26"
        viewBox="0 0 22 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.3335 1.3335V6.00016"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 1.3335V6.00016"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6665 1.3335V6.00016"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 3.6665H3.99984C2.71117 3.6665 1.6665 4.71117 1.6665 5.99984V22.3332C1.6665 23.6218 2.71117 24.6665 3.99984 24.6665H17.9998C19.2885 24.6665 20.3332 23.6218 20.3332 22.3332V5.99984C20.3332 4.71117 19.2885 3.6665 17.9998 3.6665Z"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.3335 10.6665H13.3335"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.3335 15.3335H15.6668"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.3335 20H12.1668"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    selectedSlotFlag: "notes",
  },
  {
    title: "Documents",
    img: (
      <svg
        width="22"
        height="26"
        viewBox="0 0 22 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4998 1.3335H3.99984C3.381 1.3335 2.78751 1.57933 2.34992 2.01691C1.91234 2.4545 1.6665 3.04799 1.6665 3.66683V22.3335C1.6665 22.9523 1.91234 23.5458 2.34992 23.9834C2.78751 24.421 3.381 24.6668 3.99984 24.6668H17.9998C18.6187 24.6668 19.2122 24.421 19.6498 23.9834C20.0873 23.5458 20.3332 22.9523 20.3332 22.3335V7.16683L14.4998 1.3335Z"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3335 1.3335V6.00016C13.3335 6.619 13.5793 7.21249 14.0169 7.65008C14.4545 8.08766 15.048 8.3335 15.6668 8.3335H20.3335"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.66683 9.5H6.3335"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6668 14.1665H6.3335"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6668 18.8335H6.3335"
          stroke="#FFD705"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    url: "#",
  },
];
export default function AddPayments({
  setSelectSlotFlag,
  callBackHandler,
  setBookingCreated,
  bookingCreated,
}) {
  const history = useHistory();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const { id } = useParams();
  let selectedService = useSelector(
    (state) => state.bookingReducer.servicesData
  );
  let slotData = useSelector((state) => state.bookingReducer.slotsData);
  const { customerData, isExpert, isPrivate } = useSelector(
    (state) => state.bookingReducer
  );
  const [payByCash, setPayByCash] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [addFlag, setAddFlag] = useState(false);
  const [payNow, setPayNow] = useState(true);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);

  const [openSelectTerminal, setOpenSelectTerminal] = useState(false);
  const [openValidType, setOpenValidType] = useState(false);
  const [openValidTill, setOpenValidTill] = useState(false);
  const [bookingRecord, setBookingRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [submittedOrder, setSubmittedOrder] = useState({});
  const [isFocused, setIsFocused] = useState(false);

  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
  var bookingDetails = useSelector((state) => state.bookingReducer.bookingData);

  const valueToPay = `${
    selectedService[0]?.currency === null
      ? "£"
      : selectedService[0].currency === "USD"
      ? "$"
      : "£"
  }${
    selectedService[0]?.actualPrice === null ||
    selectedService[0]?.actualPrice === 0
      ? `${1 * selectedService[0]?.bookedSeats}`
      : selectedService[0]?.actualPrice * selectedService[0]?.bookedSeats
  }`;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handelClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const increaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (service.serviceName === serviceName.serviceName) {
        return { ...service, bookedSeats: service.bookedSeats + 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };

  const decreaseQuantity = (serviceName) => {
    const updatedArray = selectedService.map((service) => {
      if (
        service.serviceName === serviceName.serviceName &&
        service.bookedSeats > 1
      ) {
        return { ...service, bookedSeats: service.bookedSeats - 1 };
      }
      return service;
    });
    dispatch(selectedServiceData(updatedArray));
  };
  const date = new Date(slotData.bookingDate);

  const options = {
    weekday: "long", // Get the full day name (e.g., "Monday")
    day: "numeric", // Get the numeric day (e.g., 14)
    month: "long", // Get the full month name (e.g., "June")
    year: "numeric", // Get the full year (e.g., 2023)
  };
  const handlerFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
  };
  const formattedDate = date.toLocaleString("en-US", options);
  function subtractOneHour(timeString) {
    const [hours, minutes] = timeString?.split(":");
    const paddedMinutes = minutes.padStart(2, "0");
    const paddedHours = hours.padStart(2, "0");
    const initialTime = new Date(
      `2023-09-05T${paddedHours}:${paddedMinutes}:00`
    );
    const newTime = new Date(initialTime.getTime()); //  - 3600 * 1000// 3600 seconds * 1000 milliseconds
    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  }

  function calculateEndTime(startTime, initialEndTime, durationMinutes) {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [initialEndHours, initialEndMinutes] = initialEndTime
      .split(":")
      .map(Number);
    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalInitialEndMinutes = initialEndHours * 60 + initialEndMinutes;
    const newTotalEndMinutes = totalStartMinutes + durationMinutes;
    const newEndHours = Math.floor(newTotalEndMinutes / 60);
    const newEndMinutes = newTotalEndMinutes % 60;
    const formattedEndTime = `${String(newEndHours).padStart(2, "0")}:${String(
      newEndMinutes
    ).padStart(2, "0")}`;
    return formattedEndTime;
  }

  function formatTime(time) {
    const [hour, minute] = time.split(":");
    const formattedHours = String(parseInt(hour, 10)).padStart(2, "0");
    const formattedMinutes = String(parseInt(minute, 10)).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  }
  const handleSubmitOrder = async () => {
    setIsLoading(true);
    const formattedEndDate = calculateEndTime(
      slotData.timeFrom,
      slotData.timeTo,
      selectedService[0].duration
    );
    const data2 = {
      bookingType: "Inclinic",
      bookingId: 0,
      isExpert: selectedService[0]?.isExpert
        ? selectedService[0]?.isExpert
        : isExpert,
      oldbBookingId: 1,
      noOfVisits: 1,
      timeZone: "Europe/London",

      countryId: selectedService[0].countryId[0],
      plexaarcustomerId: customerData?.customerId
        ? customerData?.customerId
        : 188,
      customerId: customerData?.externalCustomerId
        ? customerData?.externalCustomerId
        : 0,

      voucherDetail: {},

      customerName:
        customerData?.customerFirstName + " " + customerData?.customerLastName,
      customerFirstName: customerData?.customerFirstName,
      customerLastName: customerData?.customerLastName,
      customerEmail: `${customerData.primaryEmail}`,
      // isPrivate: isPrivate === true ? isPrivate : false,
      customerNumber: customerData?.primaryMobile,
      createdPlatforms: customerData?.externalPlatform
        ? customerData?.externalPlatform
        : "plexaar",
      customerGender: customerData?.gender ? customerData?.gender : "",
      customerImage: `https://1864597015.rsc.cdn77.org/Expert/Attachments/Customer/${customerData.userId}.jpeg`,
      servicePayload: selectedService[0],
      serviceName: selectedService[0].serviceName,
      serviceSKU: selectedService[0].serviceSKU,
      serviceId: selectedService[0].serviceId
        ? selectedService[0].serviceId
        : 0,

      serviceType: selectedService[0].isInClinicSKU ?? "nothing in the service",
      // servicePayload: selectedService[0],
      serviceImage: `https://1864597015.rsc.cdn77.org/Expert/Attachments/ServiceInventory/${selectedService[0].serviceId}.webp`,
      // venue: {
      //   centreAddress:
      //     selectedBusiness?.businessAddress?.addressName === "" ||
      //     selectedBusiness?.businessAddress?.addressName === null
      //       ? selectedBusiness?.businessAddress?.line1
      //       : selectedBusiness?.businessAddress?.addressName,
      // },
      venue: {
        id: userInfo.id ?? 0,
        userId: userInfo.id ?? 0,

        addressName:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        line1:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        line2:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        townCity: "qw",
        postalCode: "44010",
        state: "Lahore Capital Territory",
        countryId: 188,
        latitude: 33.73323440551758,
        longitude: 73.08861541748047,
        addressnotes: "qwqw",
        isResidentialAddress: true,
        radius: 20,
        addressTypeValue: 1,

        createdBy: userInfo.id ?? 0,
        createdOn: "2023-11-03T09:14:33.703",
        modifiedBy: userInfo.firstName + " " + userInfo?.lastName,
        modifiedOn: userInfo.firstName + " " + userInfo?.lastName,
      },
      bookingLocation:
        selectedBusiness?.businessAddress?.addressName === "" ||
        selectedBusiness?.businessAddress?.addressName === null
          ? selectedBusiness?.businessAddress?.line1
          : selectedBusiness?.businessAddress?.addressName,
      businessId: selectedBusiness.id,
      businessName: selectedBusiness.name ? selectedBusiness.name : "",
      businessImage: `${process.env.REACT_APP_IMAGE_URL}/Business/${id}_0.jpeg`,
      providerId: slotData.resourceId,
      providerName:
        slotData?.resourceObject?.resourceTitle ??
        slotData?.provider?.resourceTitle,
      providerImage: `${process.env.REACT_APP_IMAGE_URL}/Serviceprovider/${slotData.resourceId}.jpeg`,
      bookingDate: slotData.bookingDate,
      bookingDuration: selectedService[0]?.duration,
      timeFrom: `${formatTime(slotData.timeFrom)}:00`,
      timeTo: `${formattedEndDate}:00`,
      genderPreferences: "both",
      quantity: selectedService[0].bookedSeats,
      amountpayable:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      totalPrice:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,
      totalAmount:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,
      currency: "gbp",
      units: 1,
      perUnitPrice:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      totalDiscount:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      discountPercentage: 0,
      discountType: "",
      createdDateTimeUTC: slotData.bookingDate,
      updatedDateTimeUTC: slotData.bookingDate,
      taxType: "simple",
      taxPercent: 0,
      totalTax: 0,
      isNotes: true,
      formId: [0],
      fcmTokken: "",
      vouchercode: {
        id: 318,
        title: "jjihoi",
        flat_off: 40,
        percent_off: 0,
        voucherCode: "BEFDGDGABE",
        type: "e",
        countryId: 188,
        minPrice: 30,
        expiryDate: "2023-12-03T00:00:00",
        isExpired: false,
        isActive: true,
        isUsed: true,
        userId: 0,
        serviceId: selectedService[0].serviceId
          ? selectedService[0].serviceId
          : 0,
        mainServiceId: 0,
        parentID: 0,
        createdFor: 9,
        assigneeId: 9,
        services: "",
      },
      createdBy: userInfo?.id ? userInfo?.id : 0,

      // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
      // updatedBy: userInfo?.firstName + " " + userInfo?.lastName,
      updatedBy: userInfo?.id ? userInfo?.id : 0,

      repeatType: "",
      repeatNumber: "",
      repeatDays: "",
      daySlots: "",
      repeatDates: "",
      repeatEndDate: "",
      paymentMethodId: "",
      cartId: "",
      chargeId: "",
      promoCode: "",
      walletPayment: false,
      walletPaymentAmount: 0,
      transectionId: "",
      // submitPurchaseOrder: true,
      // submitPurchaseOrder: true,
      threedsStatus: "",
      paymentStatus: false,
      currencySymbol: "£",
      returnUrl: "https://newexpert-preprod.findanexpert.net/",
      providersList: [
        {
          providerId: slotData.resourceId,
        },
      ],
      slotCaptured: false,
      totalStep: "5",
      dateJson: [
        {
          bookingDate: slotData.bookingDate,
          timeFrom: `${formatTime(slotData.timeFrom)}:00`,
          timeTo: `${formattedEndDate}:00`,
          slotCaptured: false,
        },
      ],
      address: {
        refAddressId: 141,
        purchaseOrderId: 141,
        billingAddress: {
          id: 4,
          userId: userInfo?.id ? userInfo?.id : 0,
          createdBy: userInfo?.id ? userInfo?.id : 0,

          // createdBy: userInfo?.firstName + " " + userInfo?.lastName,

          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
        },
        customerAddress: {},
        pickupAddress: [
          {
            id: 4,
            userId: userInfo?.id ? userInfo?.id : 0,

            createdBy: userInfo?.id ? userInfo?.id : 0,

            // createdBy: userInfo?.firstName + " " + userInfo?.lastName,

            addressName:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line1:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line2: "qqqw",
            townCity: "qw",
            postalCode: "44010",
            state: "Islamabad Capital Territory",
            countryId: 188,
            latitude: 33.73323440551758,
            longitude: 73.08861541748047,
            addressnotes: "qwqw",
            isResidentialAddress: true,
            radius: 20,
            addressTypeValue: 1,
            createdOn: "2023-11-03T09:14:33.703",
            modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
            modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
          },
        ],
        dropOffAddress: {
          id: 4,
          userId: 30,
          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdBy: userInfo?.id ? userInfo?.id : 0,

          // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
        },
        deliveryAddress: {
          id: 4,
          userId: userInfo?.id ? userInfo?.id : 0,
          // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
          createdBy: userInfo?.id ? userInfo?.id : 0,

          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
        },
        centerAddress: [
          {
            id: 4,
            userId: 30,
            addressName:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line1:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line2: "qqqw",
            townCity: "qw",
            postalCode: "44010",
            state: "Islamabad Capital Territory",
            countryId: 188,
            latitude: 33.73323440551758,
            longitude: 73.08861541748047,
            addressnotes: "qwqw",
            isResidentialAddress: true,
            radius: 20,
            addressTypeValue: 1,
            createdBy: userInfo?.id ? userInfo?.id : 0,

            // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
            createdOn: "2023-11-03T09:14:33.703",
            modifiedBy:
              userInfo.firstName || userInfo.lastName
                ? userInfo.firstName + " " + userInfo.lastName
                : "",
            modifiedOn:
              userInfo.firstName || userInfo.lastName
                ? userInfo.firstName + " " + userInfo.lastName
                : "",
          },
        ],
        createdAt: "",
      },
      startTime: `${formatTime(slotData.timeFrom)}:00`,
      endTime: `${formattedEndDate}:00`,
    };

    await instance
      .post("/salesorder_svc/pb/create/", data2)
      .then((res) => {
        setBookingCreated(true);
        setSubmittedOrder(res?.data?.result);
        dispatch(BookingDetails(res?.data?.result));
        // customSnackBar("Booking Created Successfully");
        // // dispatch(
        // //   openToast({
        // //     message: "Booking Created Successfully",
        // //     status: "success",
        // //   })
        // // );
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        // history.push(`/calendar`);
        // dispatch(resetAllData());
      })
      .catch((err) => {
        customSnackBar(err.message);
        // dispatch(openToast({ message: err.message, status: "error" }));
      })
      .finally(() => {
        customSnackBar("Booking is Created");
        setIsLoading(false);
      });
  };
  const handleUpdateOrder = async (record) => {
    console.log(bookingDetails);
    const formattedEndDate = calculateEndTime(
      slotData.timeFrom,
      slotData.timeTo,
      selectedService[0].duration
    );
    const data2 = {
      plexaarSalesOrderId: bookingDetails?.plexaarSalesOrderId,
      bookingType: "Inclinic",
      bookingId: 0,
      isExpert: selectedService[0]?.isExpert
        ? selectedService[0]?.isExpert
        : isExpert,
      oldbBookingId: 1,
      noOfVisits: 1,
      timeZone: "Europe/London",

      hasattribute: selectedService[0]?.attributes ? "true" : "false",
      attributes: selectedService[0]?.attributes
        ? selectedService[0]?.attributes
        : [],
      countryId: selectedService[0].countryId[0],
      plexaarcustomerId: customerData?.customerId
        ? customerData?.customerId
        : 188,
      customerId: customerData?.externalCustomerId
        ? customerData?.externalCustomerId
        : 0,
      customerName:
        customerData?.customerFirstName + " " + customerData?.customerLastName,
      customerFirstName: customerData?.customerFirstName,
      customerLastName: customerData?.customerLastName,
      customerEmail: `${customerData.primaryEmail}`,
      // isPrivate: isPrivate,
      submitSalesOrder: true,
      // submitPurchaseOrder: true,
      createdPlatforms: customerData?.externalPlatform
        ? customerData?.externalPlatform
        : "plexaar",

      customerNumber: customerData?.primaryMobile,
      customerGender: customerData?.gender ? customerData?.gender : "",
      customerImage: `https://1864597015.rsc.cdn77.org/Expert/Attachments/Customer/${customerData.userId}.jpeg`,
      servicePayload: selectedService[0],
      serviceName: selectedService[0].serviceName,
      serviceSKU: selectedService[0].serviceSKU,
      serviceType: selectedService[0].isInClinicSKU ?? "nothing in the service",
      // servicePayload: selectedService[0],
      serviceImage: `https://1864597015.rsc.cdn77.org/Expert/Attachments/ServiceInventory/${selectedService[0].serviceId}.webp`,
      // venue: {
      //   centreAddress:
      // selectedBusiness?.businessAddress?.addressName === "" ||
      // selectedBusiness?.businessAddress?.addressName === null
      //   ? selectedBusiness?.businessAddress?.line1
      //   : selectedBusiness?.businessAddress?.addressName,
      // },
      venue: {
        id: userInfo.id ?? 0,
        userId: userInfo.id ?? 0,

        addressName:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        line1:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        line2:
          selectedBusiness?.businessAddress?.addressName === "" ||
          selectedBusiness?.businessAddress?.addressName === null
            ? selectedBusiness?.businessAddress?.line1
            : selectedBusiness?.businessAddress?.addressName,
        townCity: "qw",
        postalCode: "44010",
        state: "Islamabad Capital Territory",
        countryId: 188,
        latitude: 33.73323440551758,
        longitude: 73.08861541748047,
        addressnotes: "qwqw",
        isResidentialAddress: true,
        radius: 20,
        addressTypeValue: 1,
        createdBy: userInfo.id ?? 0,
        createdOn: "2023-11-03T09:14:33.703",
        modifiedBy: userInfo.firstName + " " + userInfo?.lastName,
        modifiedOn: userInfo.firstName + " " + userInfo?.lastName,
      },

      bookingLocation:
        selectedBusiness?.businessAddress?.addressName === "" ||
        selectedBusiness?.businessAddress?.addressName === null
          ? selectedBusiness?.businessAddress?.line1
          : selectedBusiness?.businessAddress?.addressName,
      businessId: selectedBusiness.id,
      businessName: selectedBusiness.name ? selectedBusiness.name : "",
      businessImage: `${process.env.REACT_APP_IMAGE_URL}/Business/${id}_0.jpeg`,
      providerId: slotData.resourceId,
      providerName:
        slotData?.resourceObject?.resourceTitle ??
        slotData?.provider?.resourceTitle,
      providerImage: `${process.env.REACT_APP_IMAGE_URL}/Serviceprovider/${slotData.resourceId}.jpeg`,
      bookingDate: slotData.bookingDate,
      bookingDuration: selectedService[0]?.duration,
      timeFrom: `${formatTime(slotData.timeFrom)}:00`,
      timeTo: `${formattedEndDate}:00`,
      genderPreferences: "both",
      quantity: selectedService[0].bookedSeats,
      serviceId: selectedService[0].serviceId
        ? selectedService[0].serviceId
        : 0,
      amountpayable:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      totalPrice:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,
      totalAmount:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,
      currency: "gbp",
      units: 1,
      perUnitPrice:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      totalDiscount:
        selectedService[0].actualPrice * selectedService[0].bookedSeats,

      discountPercentage: 0,
      discountType: "",
      createdDateTimeUTC: slotData.bookingDate,
      updatedDateTimeUTC: slotData.bookingDate,
      taxType: "simple",
      taxPercent: 0,
      totalTax: 0,
      isNotes: true,
      formId: [0],
      fcmTokken: "",

      vouchercode: {
        id: 318,
        title: "jjihoi",
        flat_off: 40,
        serviceId: selectedService[0].serviceId
          ? selectedService[0].serviceId
          : 0,
        percent_off: 0,
        voucherCode: "BEFDGDGABE",
        type: "e",
        countryId: 188,
        minPrice: 30,
        expiryDate: "2023-12-03T00:00:00",
        isExpired: false,
        isActive: true,
        isUsed: true,
        userId: 0,

        mainServiceId: 0,
        parentID: 0,
        createdFor: 9,
        assigneeId: 9,
        services: "",
      },
      createdBy: userInfo?.id ? userInfo?.id : 0,

      // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
      updatedBy: userInfo?.id ? userInfo?.id : 0,

      repeatType: "",
      repeatNumber: "",
      repeatDays: "",
      daySlots: "",
      repeatDates: "",
      repeatEndDate: "",
      paymentMethodId: "",
      cartId: "",
      chargeId: "",
      promoCode: "",
      walletPayment: false,
      walletPaymentAmount: 0,
      transectionId: "",
      // submitPurchaseOrder: true,

      threedsStatus: "",
      paymentStatus: false,
      currencySymbol: "£",

      returnUrl: "https://newexpert-preprod.findanexpert.net/",
      providersList: [
        {
          providerId: slotData.resourceId,
        },
      ],
      slotCaptured: false,
      totalStep: "5",
      dateJson: [
        {
          bookingDate: slotData.bookingDate,
          timeFrom: `${formatTime(slotData.timeFrom)}:00`,
          timeTo: `${formattedEndDate}:00`,
          slotCaptured: false,
        },
      ],
      address: {
        refAddressId: 141,
        purchaseOrderId: 141,
        billingAddress: {
          id: 4,
          createdBy: userInfo?.id ? userInfo?.id : 0,

          // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
          userId: 30,
          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
        },
        pickupAddress: [
          {
            id: 4,
            userId: userInfo?.id ? userInfo?.id : 0,
            createdBy: userInfo?.id ? userInfo?.id : 0,

            // createdBy: userInfo?.firstName + " " + userInfo?.lastName,

            addressName:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line1:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line2: "qqqw",
            townCity: "qw",
            postalCode: "44010",
            state: "Islamabad Capital Territory",
            countryId: 188,
            latitude: 33.73323440551758,
            longitude: 73.08861541748047,
            addressnotes: "qwqw",
            isResidentialAddress: true,
            radius: 20,
            addressTypeValue: 1,
            createdOn: "2023-11-03T09:14:33.703",
            modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
            modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
          },
        ],
        dropOffAddress: {
          id: 4,
          userId: 30,
          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdBy: userInfo?.id ? userInfo?.id : 0,

          // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
        },
        deliveryAddress: {
          id: 4,
          userId: 30,
          createdBy: userInfo?.id ? userInfo?.id : 0,
          // createdBy: 10,

          addressName:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line1:
            "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
          line2: "qqqw",
          townCity: "qw",
          postalCode: "44010",
          state: "Islamabad Capital Territory",
          countryId: 188,
          latitude: 33.73323440551758,
          longitude: 73.08861541748047,
          addressnotes: "qwqw",
          isResidentialAddress: true,
          radius: 20,
          addressTypeValue: 1,
          createdOn: "2023-11-03T09:14:33.703",
          modifiedBy: userInfo?.firstName + " " + userInfo?.lastName,
          modifiedOn: userInfo?.firstName + " " + userInfo?.lastName,
        },

        centerAddress: [
          {
            id: 4,
            userId: 30,
            addressName:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line1:
              "Evacuee Trust Complex, Agha Khan Road, F-5, Blue Area 44010, Pakistan",
            line2: "qqqw",
            townCity: "qw",
            postalCode: "44010",
            state: "Islamabad Capital Territory",
            countryId: 188,
            latitude: 33.73323440551758,
            longitude: 73.08861541748047,
            addressnotes: "qwqw",
            isResidentialAddress: true,
            radius: 20,
            addressTypeValue: 1,
            createdBy: userInfo?.id ? userInfo?.id : 0,

            // createdBy: userInfo?.firstName + " " + userInfo?.lastName,
            createdOn: "2023-11-03T09:14:33.703",
            modifiedBy:
              userInfo.firstName || userInfo.lastName
                ? userInfo.firstName + " " + userInfo.lastName
                : "",
            modifiedOn:
              userInfo.firstName || userInfo.lastName
                ? userInfo.firstName + " " + userInfo.lastName
                : "",
          },
        ],
        createdAt: "",
      },
      startTime: `${formatTime(slotData.timeFrom)}:00`,
      endTime: `${formattedEndDate}:00`,
    };

    await instance
      .patch("/salesorder_svc/pb/create/", data2)
      .then((res) => {
        customSnackBar("Booking Created Successfully");

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const data = {
          id: selectedBusiness?.id,
          userTimezone: userTimezone,
        };

        // const record={
        //   providerId: slotData.resourceId,
        //   bookingDateStart: slotData.bookingDate,
        //   is_business: true,
        //   bookingDateEnd:slotData.bookingDate,
        // timeZone: "Europe/London",
        //   businessId:selectedBusiness?.id
        // }

        // dispatch(getCalendarData(record));
        history.push(`/calendar`);
        dispatch(resetAllData());
        // window.location.reload();
      })
      .catch((err) => {
        customSnackBar(err.message);
        // dispatch(openToast({ message: err.message, status: "error" }));
      })
      .finally(() => setIsLoading(false));
  };
  const headingStyle = {
    color: "#B3B3B3",
    fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
  };

  const dateAndTimeStyle = {
    color: "#4D4D4D",
    fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
  };

  const portalActions = (action) => {
    if (action.title === "Notes") {
      setSelectSlotFlag("notes");
    } else {
      customSnackBar("Working On it");
    }
  };

  // Paymeny Options UI below

  const payByLinkTerminal = () => {
    return (
      <div
        className="mt-2 w-100 px-3"
        style={{
          borderRadius: "8px",
          border: "0.2px solid #FFD705",
          backgroundColor: "#FFFDF5",
        }}
      >
        <div className="d-flex w-100 justify-content-between">
          <div className="my-2">
            <span className="payment-for-customer-heading">
              Amount you have to pay
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <span className="ms-1 selected-service-amount">{valueToPay}</span>
          </div>
        </div>
        <hr
          className=""
          style={{
            color: "rgb(199 196 196)",
            margin: "0 auto",
            width: "100%",
          }}
        />
        <div className="row">
          <div className="col-12 mb-2">
            <div className="d-flex flex-column my-2">
              {" "}
              <span
                style={{
                  color: "#F2BD00",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Send £200 to
              </span>
              <div
                className="dropdown-container"
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="dropdown-header add-booking-custom-dropdown-header"
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #D7D7D7",
                    background: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0",
                    cursor: "pointer",
                    height: "40px", // Add cursor pointer
                  }}
                  onClick={() => {
                    setOpenSelectTerminal(!openSelectTerminal);
                  }}
                >
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      marginLeft: "10px",
                      fontWeight: 600,
                    }}
                  >
                    Terminal 1
                  </span>
                  <div
                    className={`dropdown-icon px-3 ${
                      openSelectTerminal ? "open" : ""
                    }`}
                    style={{
                      borderRadius: "0px 7px 7px 0px",
                      background: "#EBEBEB",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      style={{
                        transform: openValidType
                          ? "rotate(180deg)"
                          : "rotate(0deg)", // Rotate icon based on dropdown state
                      }}
                    >
                      <path
                        d="M1 1L5.1569 5L9 1"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {openSelectTerminal && (
                  <ul
                    className="dropdown-options"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",
                      position: "absolute",
                      minWidth: "100%",
                      top: "100%",
                      left: "0",
                      zIndex: "1",
                      border: "1px solid #D7D7D7",
                      marginTop: "5px",
                      borderRadius: "5px",
                      overflow: "hidden",
                      backgroundColor: "white",
                      // borderRadius: "8px",
                    }}
                  >
                    <li
                      onMouseDown={() => {}}
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        background: "#FFF",
                        padding: "4px 9px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Terminal 1
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const payNowCard = () => {
    return (
      <div
        className="mt-2 w-100 px-3"
        style={{
          borderRadius: "8px",
          border: "0.2px solid #FFD705",
          backgroundColor: "#FFFDF5",
        }}
      >
        <div className="d-flex w-100 justify-content-between">
          <div className="my-2">
            <span className="payment-for-customer-heading">
              Amount you have to pay
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <span className="ms-1 selected-service-amount">{valueToPay}</span>
          </div>
        </div>
        <hr
          style={{
            color: "rgb(199 196 196)",
            margin: "0 auto",
            width: "100%",
          }}
        />
        <div className="row">
          <div className="col-6">
            <div className="d-flex flex-column my-2">
              {" "}
              <span
                style={{
                  color: "#F2BD00",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Paid
              </span>
              <input
                className="px-3 py-1"
                style={{
                  borderRadius: "8px",
                  border: "0.2px solid #FFD705",
                  backgroundColor: "#FFF",
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  height: "40px",
                }}
                value={"£150"}
                placeholder="AMOUNT"
              />
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="d-flex flex-column my-2">
              {" "}
              <span
                style={{
                  color: "#F2BD00",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Balance
              </span>
              <input
                className="px-3 py-1"
                style={{
                  borderRadius: "8px",
                  border: "0.2px solid #FFD705",
                  backgroundColor: "#FFD705",
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  height: "40px",
                }}
                value={"£50"}
                placeholder="AMOUNT"
              />
            </div>
          </div>
        </div>

        {/* <div className="d-flex flex-column">
         
        </div>
        <div className="d-flex flex-column ms-3">
          <span
            style={{
              color: "#F2BD00",
              fontFamily: "Roboto",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            Balance
          </span>
          <div
            className="py-1"
            style={{
              borderRadius: "8px",
              backgroundColor: "#FFD705",
              color: "#795700",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            0
          </div>
        </div> */}
      </div>
    );
  };
  const payByLinkCard = () => {
    return (
      <div
        className="mt-2 w-100 px-3 d-flex "
        style={{
          borderRadius: "8px",
          border: "0.2px solid #FFD705",
          backgroundColor: "#FFFDF5",
        }}
      >
        <div className="row">
          <div className="col-12 my-2">
            <span className="payment-for-customer-heading">
              A payment will be sent to customer on
            </span>
          </div>
          <div className="col-6">
            {" "}
            <div
              className="d-flex align-items-center px-2"
              style={{
                border: "0.4px solid #FFD705",
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              <PhoneInput
                // disabled={}
                defaultMask="........."
                // renderStringAsFlag={" "}
                //  inputStyle={{color:'green'}}
                //  containerStyle={{backgroundColor:"#000"}}
                buttonStyle={{ borderRight: "1px solid #DCDCDC" }}
                specialLabel=""
                //  dropdownStyle={{height:'22px'}}
                inputStyle={{
                  width: "95%",
                  height: "10px",
                  color: " #616161",
                  border: "none",
                  borderRadius: "8px",
                  paddingLeft: "75px",
                }}
                // disableDropdown={true}
                showDropdown={false}
                country={"gb"}
                inputProps={{
                  onFocus: (e) => {
                    handlerFocus();
                    // e.target.style.backgroundColor = "white";
                  },
                  onBlur: (e) => {
                    handleBlur();
                    // e.target.style.backgroundColor = "";
                  },
                }}
                value={value}
                onChange={(e) => console.log(e)}
                placeholder="4423221321"
                // className="phone-input"
              />
            </div>
          </div>
          <div className="col-6">
            {" "}
            <div
              className="d-flex align-items-center px-2 py-1"
              style={{
                border: "0.4px solid #FFD705",
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              <input
                className="px-3"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  height: "29px",
                  overflow: "auto",
                  textOverflow: "ellipsis",
                }}
                value="Hameed@selteq.net"
              />
            </div>
          </div>
          <hr
            className="mt-3"
            style={{
              color: "rgb(199 196 196)",
              margin: "0 auto",
              width: "98%",
            }}
          />
          <div className="col-12 my-2">
            <span className="payment-for-customer-heading">
              Link will be valid till
            </span>
          </div>
          <div className="col-6 mb-3">
            <div
              className="dropdown-container"
              style={{
                position: "relative",
              }}
            >
              <div
                className="dropdown-header add-booking-custom-dropdown-header"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #D7D7D7",
                  background: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0",
                  cursor: "pointer",
                  height: "40px", // Add cursor pointer
                }}
                onClick={() => {
                  setOpenValidType(!openValidType);
                  setOpenValidTill(false);
                }}
              >
                <span
                  style={{
                    color: "#585858",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    marginLeft: "10px",
                    fontWeight: 600,
                  }}
                >
                  For
                </span>
                <div
                  className={`dropdown-icon px-3 ${
                    openValidType ? "open" : ""
                  }`}
                  style={{
                    borderRadius: "0px 7px 7px 0px",
                    background: "#EBEBEB",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{
                      transform: openValidType
                        ? "rotate(180deg)"
                        : "rotate(0deg)", // Rotate icon based on dropdown state
                    }}
                  >
                    <path
                      d="M1 1L5.1569 5L9 1"
                      stroke="#707070"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {openValidType && (
                <ul
                  className="dropdown-options"
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0",
                    position: "absolute",
                    minWidth: "100%",
                    top: "100%",
                    left: "0",
                    zIndex: "1",
                    border: "1px solid #D7D7D7",
                    marginTop: "5px",
                    borderRadius: "5px",
                    overflow: "hidden",
                    backgroundColor: "white",
                    // borderRadius: "8px",
                  }}
                >
                  <li
                    onMouseDown={() => {}}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      background: "#FFF",
                      padding: "4px 9px",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    For
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="col-6">
            <div
              className="dropdown-container"
              style={{
                position: "relative",
              }}
            >
              <div
                className="dropdown-header"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #D7D7D7",
                  background: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0",
                  cursor: "pointer",
                  height: "40px", // Add cursor pointer
                }}
                onClick={() => {
                  setOpenValidTill(!openValidTill);
                  setOpenValidType(false);
                }}
              >
                <span
                  style={{
                    color: "#585858",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    marginLeft: "10px",
                    fontWeight: 600,
                  }}
                >
                  Next 24 Hours
                </span>
                <div
                  className={`dropdown-icon px-3 ${
                    openValidTill ? "open" : ""
                  }`}
                  style={{
                    borderRadius: "0px 7px 7px 0px",
                    background: "#EBEBEB",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{
                      transform: openValidTill
                        ? "rotate(180deg)"
                        : "rotate(0deg)", // Rotate icon based on dropdown state
                    }}
                  >
                    <path
                      d="M1 1L5.1569 5L9 1"
                      stroke="#707070"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {openValidTill && (
                <ul
                  className="dropdown-options"
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0",
                    position: "absolute",
                    minWidth: "100%",
                    top: "100%",
                    left: "0",
                    zIndex: "1",
                    border: "1px solid #D7D7D7",
                    marginTop: "5px",
                    borderRadius: "5px",
                    overflow: "hidden",
                    backgroundColor: "white",
                    // borderRadius: "8px",
                  }}
                >
                  <li
                    onMouseDown={() => {}}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      background: "#FFF",
                      padding: "4px 9px",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Next 24 Hours
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const payLater = () => {
    return (
      <div
        className="mt-2 w-100 px-3"
        style={{
          borderRadius: "8px",
          border: "0.2px solid #FFD705",
          backgroundColor: "#FFFDF5",
        }}
      >
        <div className="d-flex w-100 justify-content-between">
          <div className="my-2">
            <span className="payment-for-customer-heading">
              Amount you have to pay
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <span className="ms-1 selected-service-amount">{valueToPay}</span>
          </div>
        </div>
        <hr
          className="mb-1"
          style={{
            color: "rgb(199 196 196)",
            margin: "0 auto",
            width: "100%",
          }}
        />
        <div className="row">
          <div className="col-12">
            <span
              style={{
                color: "#F2BD00",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              You can pay the amount
            </span>
          </div>
          <div className="col-6">
            <div className="d-flex flex-column my-2">
              {" "}
              <div
                className="dropdown-container"
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="dropdown-header add-booking-custom-dropdown-header"
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #D7D7D7",
                    background: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0",
                    cursor: "pointer",
                    height: "40px", // Add cursor pointer
                  }}
                  onClick={() => {
                    setOpenValidType(!openValidType);
                  }}
                >
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      marginLeft: "10px",
                      fontWeight: 600,
                    }}
                  >
                    With in
                  </span>
                  <div
                    className={`dropdown-icon px-3 ${
                      openValidType ? "open" : ""
                    }`}
                    style={{
                      borderRadius: "0px 7px 7px 0px",
                      background: "#EBEBEB",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      style={{
                        transform: openValidType
                          ? "rotate(180deg)"
                          : "rotate(0deg)", // Rotate icon based on dropdown state
                      }}
                    >
                      <path
                        d="M1 1L5.1569 5L9 1"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {openValidType && (
                  <ul
                    className="dropdown-options"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",
                      position: "absolute",
                      minWidth: "100%",
                      top: "100%",
                      left: "0",
                      zIndex: "1",
                      border: "1px solid #D7D7D7",
                      marginTop: "5px",
                      borderRadius: "5px",
                      overflow: "hidden",
                      backgroundColor: "white",
                      // borderRadius: "8px",
                    }}
                  >
                    <li
                      onMouseDown={() => {}}
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        background: "#FFF",
                        padding: "4px 9px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      With in
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-2">
            <div className="d-flex flex-column my-2">
              <div
                className="dropdown-container"
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="dropdown-header add-booking-custom-dropdown-header"
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #D7D7D7",
                    background: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0",
                    cursor: "pointer",
                    height: "40px", // Add cursor pointer
                  }}
                  onClick={() => {
                    setOpenValidTill(!openValidTill);
                  }}
                >
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      marginLeft: "10px",
                      fontWeight: 600,
                    }}
                  >
                    Next 24 hours
                  </span>
                  <div
                    className={`dropdown-icon px-3 ${
                      openValidTill ? "open" : ""
                    }`}
                    style={{
                      borderRadius: "0px 7px 7px 0px",
                      background: "#EBEBEB",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      transition: "transform 0.3s ease", // Add transition for smooth icon rotation
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      style={{
                        transform: openValidTill
                          ? "rotate(180deg)"
                          : "rotate(0deg)", // Rotate icon based on dropdown state
                      }}
                    >
                      <path
                        d="M1 1L5.1569 5L9 1"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {openValidTill && (
                  <ul
                    className="dropdown-options"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",
                      position: "absolute",
                      minWidth: "100%",
                      top: "100%",
                      left: "0",
                      zIndex: "1",
                      border: "1px solid #D7D7D7",
                      marginTop: "5px",
                      borderRadius: "5px",
                      overflow: "hidden",
                      backgroundColor: "white",
                      // borderRadius: "8px",
                    }}
                  >
                    <li
                      onMouseDown={() => {}}
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        background: "#FFF",
                        padding: "4px 9px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Next 24 hours
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex mt-2 mb-4 overflow-auto"
        style={{
          paddingLeft: "10%",
          paddingRight: "10%",
          height: "90vh !important",
        }}
      >
        <div className="pt-2" onClick={callBackHandler} role="button">
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>

        {/* PORTAL BELOW THIS */}

        <div
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "30px",
          }}
          className="container ms-4"
        >
          <div
            className="container pt-2 pb-2 px-3"
            style={{
              borderRadius: "8px",
              border: "0.6px solid #DCDCDC",
              background: "#FFF",
              position: "relative",
            }}
          >
            <div className="d-flex mt-2 justify-content-between align-items-center">
              <p style={{ fontSize: "12px" }} className="businessId mb-0">
                Order ID: {bookingDetails?.plexaarSalesOrderId}
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <p style={{ fontSize: "12px" }} className="businessId mb-0">
                  Quantity
                </p>
                <div
                  className="ms-3"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                  }}
                >
                  <div
                    onClick={(e) => {
                      decreaseQuantity(selectedService[0]);
                    }}
                    role="button"
                  >
                    <DecreasePackageQuantitySvg />
                  </div>
                  <div
                    className="mx-2"
                    style={{
                      color: "#4D4D4D",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {selectedService[0]?.bookedSeats
                      ? selectedService[0]?.bookedSeats
                      : 1}
                    {/* {quantity} */}
                  </div>
                  <div
                    onClick={(e) => {
                      increaseQuantity(selectedService[0]);
                    }}
                    role="button"
                  >
                    <IncreasePackageQuantitySvg />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div>
                {selectedService.map((vl, index) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between">
                        <div key={index} className="d-flex ">
                          {selectedService ? (
                            <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                              <Avatar
                                alt={vl?.serviceName}
                                src={serviceImageURL(vl?.serviceId)}
                                variant="rounded"
                                style={{
                                  borderRadius: 50,
                                  width: "30px",
                                  height: "30px",
                                  color: "#B5B5B5",
                                  background: "#F0F0F0",
                                }}
                              />
                            </div>
                          ) : (
                            <div className=" mt-2 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                              <Avatar
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  color: "#B5B5B5",
                                  background: "#F0F0F0",
                                  borderRadius: 50,
                                }}
                              >
                                S
                              </Avatar>
                            </div>
                          )}
                          <div className="d-flex mt-2 justify-content-between ">
                            <div
                              style={{
                                textAlign: "left",
                                textTransform: "capitalize",
                              }}
                              className=" justify-content-start"
                            >
                              <div
                                className={`d-flex ${
                                  vl &&
                                  vl.attributes &&
                                  vl.attributes.length === 0
                                    ? "pt-0"
                                    : "pt-0"
                                }`}
                              >
                                {/* Your content here */}

                                <div className="d-flex flex-column">
                                  <span className="m-0 mx-2 selected_servicename">
                                    {vl?.serviceName}{" "}
                                    <span className="selected_serviceduration">
                                      ({vl?.duration} mins)
                                    </span>
                                  </span>{" "}
                                  {vl.attributes &&
                                    vl.attributes[0] &&
                                    vl.attributes.length > 0 && (
                                      <span
                                        style={{
                                          fontSize: "8.5px",
                                          lineHeight: "10px",
                                        }}
                                        className="m-0 mx-2 selected_servicename"
                                      >
                                        {vl.attributes[0].value}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          className="mt-3 ms-0 mb-1"
                          style={{
                            color: "#c4c4c4",
                            margin: "15.6px 0 21.4px",
                          }}
                        />
                        <div className="d-flex justify-content-end align-items-center">
                          {selectedService[0]?.bookedSeats > 0 && (
                            <span className="ms-1 selected-service-amount">
                              {vl?.currency === null
                                ? "£"
                                : vl.currency === "USD"
                                ? "$"
                                : "£"}
                              {vl.actualPrice === null || vl.actualPrice === 0
                                ? `${1 * selectedService[0]?.bookedSeats}`
                                : vl.actualPrice *
                                  selectedService[0]?.bookedSeats}
                            </span>
                          )}
                        </div>
                      </div>

                      {isExpanded && (
                        <div className="pt-0">
                          <hr
                            className="mt-3 ms-0 mb-1"
                            style={{
                              color: "#c4c4c4",
                              margin: "15.6px 0 21.4px",
                            }}
                          />

                          <div className="mt-0">
                            <span
                              style={{ fontSize: "12px" }}
                              className="businessId"
                            >
                              Appointment Details
                            </span>
                            <div className="d-flex align-items-center my-1">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="30"
                                    height="30"
                                    rx="15"
                                    fill="#F0F0F0"
                                  />
                                  <path
                                    d="M20.625 12.1875V11.25C20.625 10.9185 20.4933 10.6005 20.2589 10.3661C20.0245 10.1317 19.7065 10 19.375 10H10.625C10.2935 10 9.97554 10.1317 9.74112 10.3661C9.5067 10.6005 9.375 10.9185 9.375 11.25V20C9.375 20.3315 9.5067 20.6495 9.74112 20.8839C9.97554 21.1183 10.2935 21.25 10.625 21.25H12.8125"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17.5 8.75V11.25"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.5 8.75V11.25"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.375 13.75H12.5"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M18.4375 18.4375L17.5 17.6562V16.25"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M21.25 17.5C21.25 18.4946 20.8549 19.4484 20.1517 20.1517C19.4484 20.8549 18.4946 21.25 17.5 21.25C16.5054 21.25 15.5516 20.8549 14.8483 20.1517C14.1451 19.4484 13.75 18.4946 13.75 17.5C13.75 16.5054 14.1451 15.5516 14.8483 14.8483C15.5516 14.1451 16.5054 13.75 17.5 13.75C18.4946 13.75 19.4484 14.1451 20.1517 14.8483C20.8549 15.5516 21.25 16.5054 21.25 17.5Z"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div className="d-flex px-2 selected-services">
                                  {formattedDate}
                                </div>
                              </div>

                              <div className="d-flex px-4 align-items-center selected-services">
                                <div className="d-flex align-items-center me-1"></div>
                                {formatTime(slotData.timeFrom)}{" "}-{" "}{formatTime(slotData.timeTo)}
                              </div>
                            </div>
                          </div>
                          <hr
                            className="mt-3 ms-0 mb-1"
                            style={{
                              color: "#c4c4c4",
                              margin: "15.6px 0 21.4px",
                            }}
                          />
                          <div className="mt-0">
                            <span
                              style={{ fontSize: "12px" }}
                              className="businessId"
                            >
                              Provider Name
                            </span>
                            <div className="d-flex justify-content-between align-items-center my-1">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="30"
                                    height="30"
                                    rx="15"
                                    fill="#F0F0F0"
                                  />
                                  <path
                                    d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div className="ms-2 selected-services">
                                  {slotData?.resourceObject?.resourceTitle ??
                                    slotData?.provider?.resourceTitle}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr
                            className="mt-3 ms-0 mb-1"
                            style={{
                              color: "#c4c4c4",
                              margin: "15.6px 0 21.4px",
                            }}
                          />

                          <div className="mt-0">
                            <span
                              style={{ fontSize: "12px" }}
                              className="businessId"
                            >
                              Customer Name
                            </span>
                            <div className="d-flex justify-content-between align-items-center my-1">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="30"
                                    height="30"
                                    rx="15"
                                    fill="#F0F0F0"
                                  />
                                  <path
                                    d="M14.5 15.125C16.2259 15.125 17.625 13.7259 17.625 12C17.625 10.2741 16.2259 8.875 14.5 8.875C12.7741 8.875 11.375 10.2741 11.375 12C11.375 13.7259 12.7741 15.125 14.5 15.125Z"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19.5 20.125C19.5 18.7989 18.9732 17.5271 18.0355 16.5895C17.0979 15.6518 15.8261 15.125 14.5 15.125C13.1739 15.125 11.9021 15.6518 10.9645 16.5895C10.0268 17.5271 9.5 18.7989 9.5 20.125"
                                    stroke="#B5B5B5"
                                    stroke-width="0.75"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div className="ms-2 selected-services">
                                  {customerData.customerFirstName}{" "}
                                  {customerData.customerLastName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            {/* {selectedService[0].attributes && ( */}
            <div
              style={{
                position: "absolute",
                bottom: "-18px",
                left: "50%",
                zIndex: "9999",
              }}
              className="d-flex justify-content-center"
            >
              <div
                // style={{
                //   background: "white",
                //   cursor: "pointer",
                //   border: " 0.6px solid rgb(220, 220, 220)",
                //   borderRadius: "50px",
                //   height: "35px",
                //   width: "35px",
                // }}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <div className="pt-1 px-2">
                  {!isExpanded ? (
                    <Tooltip title="Expand">
                      <ArrowDownYellowSvg />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Collap">
                      <ArrowUpYellowSvg />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            {/* // )} */}
          </div>

          <div
            className={`container mt-4 py-3 px-4 ${
              !bookingCreated ? "d-none" : ""
            }`}
            style={{
              borderRadius: "8px",
              border: "0.6px solid #DCDCDC",
              background: "#FFF",
              // marginLeft: "40px",
            }}
          >
            <div className="">
              <span className="portal-heading">Portal</span>
              <div className="w-100 row justify-content-between align-items-center">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {PortalData.map((val, key) => (
                    <div
                      className="d-flex flex-column  align-items-center justify-content-center rounded mx-2 portal-card-add-booking"
                      key={key}
                      onClick={() => portalActions(val)}
                    >
                      {val.img}
                      <p className="m-0 pt-2 select_service">{val.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr style={{ color: "rgb(196, 196, 196)" }} />
            {/* --------------------------------- PAYMENT -------------------------------- */}

            <div className="mt-2">
              <p className=" payment_heading p-0 m-0">Payment</p>
              <div className="mt-2">
                <div className="row  mb-3 px-3">
                  <div className="pos-relative col-12 col-md-3 pr-0 column-state">
                    <div
                      className="px-2 py-2 payment-methods w-100"
                      style={{
                        borderRadius: "10px",
                        border:
                          paymentMethod === "cash"
                            ? "none"
                            : "0.6px solid #F0F0F0",
                        opacity: paymentMethod === "cash" ? "none" : "0.8",
                        backgroundColor:
                          paymentMethod === "cash" ? "#FFD705" : "#F0F0F0",
                        color: paymentMethod === "cash" ? "#FFFFFF" : "#8A8A8A",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        width: "max-content",
                        textAlign: "center",
                      }}
                      onClick={() => setPaymentMethod("cash")}
                      role="button"
                    >
                      {paymentMethod === "cash" ? (
                        <PayByCashActiveSvg />
                      ) : (
                        <PayByCashDeActiveSvg />
                      )}
                      <span className="ms-3">Pay By Cash</span>
                    </div>
                    {paymentMethod === "cash" ? (
                      <div class="payment-choice-bottom-arrow"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pos-relative col-12 col-md-3 pr-0 column-state">
                    <div
                      className="px-2 py-2 w-100"
                      style={{
                        borderRadius: "10px",
                        border:
                          paymentMethod === "link"
                            ? "none"
                            : "0.6px solid #F0F0F0",
                        opacity: paymentMethod === "link" ? "none" : "0.8",
                        backgroundColor:
                          paymentMethod === "link" ? "#FFD705" : "#F0F0F0",
                        color: paymentMethod === "link" ? "#FFFFFF" : "#8A8A8A",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        width: "max-content",
                        textAlign: "center",
                      }}
                      onClick={() => setPaymentMethod("link")}
                      role="button"
                    >
                      {paymentMethod === "link" ? (
                        <PayByLinkActiveSvg />
                      ) : (
                        <PayByLinkDeActiveSvg />
                      )}
                      <span className="ms-3">Pay By Link</span>
                    </div>
                    {paymentMethod === "link" ? (
                      <div class="payment-choice-bottom-arrow"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pos-relative col-12 col-md-3 pr-0 column-state">
                    <div
                      onClick={() => {
                        setPaymentMethod("terminal");
                      }}
                      className="px-2 py-2 w-100"
                      style={{
                        borderRadius: "10px",
                        border:
                          paymentMethod === "terminal"
                            ? "none"
                            : "0.6px solid #F0F0F0",
                        opacity: paymentMethod === "terminal" ? "none" : "0.8",
                        backgroundColor:
                          paymentMethod === "terminal" ? "#FFD705" : "#F0F0F0",
                        color:
                          paymentMethod === "terminal" ? "#FFFFFF" : "#8A8A8A",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        width: "max-content",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      {paymentMethod === "terminal" ? (
                        <PayByTerminalActiveSvg />
                      ) : (
                        <PayByTerminalDeActiveSvg />
                      )}
                      <span className="ms-3">Pay By Terminal</span>
                    </div>
                    {paymentMethod === "terminal" ? (
                      <div class="payment-choice-bottom-arrow"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pos-relative col-12 col-md-3 pr-0 column-state">
                    <div
                      onClick={() => {
                        setPaymentMethod("later");
                      }}
                      className="px-2 py-2 w-100"
                      style={{
                        borderRadius: "10px",
                        border:
                          paymentMethod === "later"
                            ? "none"
                            : "0.6px solid #F0F0F0",
                        opacity: paymentMethod === "later" ? "none" : "0.8",
                        backgroundColor:
                          paymentMethod === "later" ? "#FFD705" : "#F0F0F0",
                        color:
                          paymentMethod === "later" ? "#FFFFFF" : "#8A8A8A",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        width: "max-content",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      {paymentMethod === "later" ? (
                        <PayByCashActiveSvg />
                      ) : (
                        <PayByCashDeActiveSvg />
                      )}
                      <span className="ms-3">Pay Later</span>
                    </div>
                    {paymentMethod === "later" ? (
                      <div class="payment-choice-bottom-arrow"></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  {paymentMethod === "cash"
                    ? payNowCard()
                    : paymentMethod === "link"
                    ? payByLinkCard()
                    : paymentMethod === "terminal"
                    ? payByLinkTerminal()
                    : paymentMethod === "later"
                    ? payLater()
                    : ""}
                </div>
              </div>
            </div>
          </div>

          <div className="py-2 w-100">
            <div className="w-100 row justify-content-center align-items-center mb-2 mt-4">
              <button
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#FFD705",
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontStyle: "normal",
                  borderRadius: "8px",
                  fontWeight: 500,
                  border: "none",
                  padding:"1%"
                }}
                className="col-6 my-2"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  !bookingCreated
                    ? handleSubmitOrder()
                    : handleUpdateOrder(submittedOrder);
                }}
              >
                {isLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : !bookingCreated ? (
                  "Create Order"
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
