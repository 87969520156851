import React, { useEffect } from "react";
import "./dashboardpages.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getallgenders,
  getuserdataafterlogin,
  resendotpmobile,
  sendgender,
  sendotpsecondary,
} from "../../Store/Auth/actions";
import SideNavone from "../../SideNavBar/index";
import Header from "../BusinessProfile/Header";
import InputTextField from "../BusinessProfile/TextField";
import { Button } from "@mui/material";

function Addgender() {
  const dispatch = useDispatch();
  let history = useHistory();
  const flag = true;
  // const getallgendersreducer = [
  //   { id: "male", name: "Male" },
  //   { id: "female", name: "Female" },
  //   { id: "transgender", name: "Other" },
  // ];
  // const [alluserdata, setAlluserdata] = useState("");
  // const [selectedOption, setSelectedOption] = useState("1");
  const alluserdataafterlogin = useSelector((state)=>state.Auth.userloginsuccessyasir);
  const [selectedGender, setSelectedGender] = useState("");

  const registersuccessId = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
 
  const getallgendersreducer = useSelector((state) => state.Auth?.allgenders);
  // const handleOptionChange = (event) => {
  //   setSelectedOption(parseInt(event.target.value));
  // };
  const handleGenderSelection = (genderId) => {
    setSelectedGender(genderId);
    // Call your save function here
    // For example: saveGender(genderId);
  };
  useEffect(() => {
    dispatch(getallgenders());
  }, []);

  useEffect(()=>{
    setSelectedGender(alluserdataafterlogin.genderId);
    // if(alluserdataafterlogin.genderId === 1){
    //   setSelectedGender("male");
    // }else if(alluserdataafterlogin.genderId === 2){
    //   setSelectedGender("female");
    // }else if(alluserdataafterlogin.genderId === 3) {
    //   setSelectedGender("transgender");
    // }
  },[alluserdataafterlogin]);

  const sendgenderrr = () => {
    dispatch(
      sendgender({
        id: registersuccessId,
        genderId: selectedGender,
        modifiedBy: registersuccessId,
        // history: history,
        // setIsLoading,
      })
    );
  };

  return (
    <>
      <SideNavone>
        <div>
          <Header flag={flag} heading={"Manage Profile"} />
        </div>

        <div className="p-md-4">
          <div>
            <p className=" ps-1 sub_text_color_account_alltext_show_actual">
              Gender
            </p>
          </div>
          <div className="card">
            <div className="row me-3">
              <div className="col-md-6 ">
                <div className="col-md-12 px-0 ">
                  <p className="mb-0 ms-2 sub_text_color_account_alltext">
                    Select Your Gender
                  </p>
                </div>
                <div className="d-flex me-4">
                  {getallgendersreducer?.map((gender) => (
                    <div
                      key={gender.id}
                      className="d-flex align-items-center me-4"
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        id={`genderRadio${gender.id}`}
                        value={gender.id}
                        checked={selectedGender === gender.id}
                        onChange={() => handleGenderSelection(gender.id)}
                      />
                      <label
                        className="sub_text_color_account_alltext_show_actual mt-2 ms-2"
                        htmlFor={`genderRadio${gender.id}`}
                      >
                        {gender.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
            <div className="p-2">
              <Button
                type="submit"
                sx={{
                  width: "150px",
                  backgroundColor: "#538dff",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                onClick={() => sendgenderrr()}
                // disabled={firstNameError || lastNameError}
                variant="contained"
              >
                {"Save & Continue"}
              </Button>
            </div>
          </div>
        </div>
      </SideNavone>
      {/* <SideNavone>
        <div className="p-4">
        <div className="col-md-12  card p-4" id="style-2">
          <h2>Gender</h2>

          <div className="col-md-12 px-0 pb-2 pt-3">
            <p className="mb-0 sub_text_color_account_alltext">gender</p>
          </div>
          <select
            className="form-control"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            {getallgendersreducer?.map((gender, index) => (
              <option key={gender.id} value={gender.id}>
                {gender.name}
              </option>
            ))}
          </select>
          <button className="btn btn-primary mt-4 px-5" onClick={sendgenderrr}>
            {" "}
            Save{" "}
          </button>
        </div>
        </div>
      </SideNavone> */}
    </>
  );
}

export default Addgender;
