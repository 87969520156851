import {
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_FAILURE,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,

} from "./actionType";

// Action creators
export const fetchDepartmentRequest = (id) => ({
  
  type: FETCH_DEPARTMENT_REQUEST,
  payload:id,
});

export const fetchDepartmentSuccess = (data) => {
  return {
    type: FETCH_DEPARTMENT_SUCCESS,
    payload: data,
  };
};

export const fetchDepartmentFailure = (error) => ({
  type: FETCH_DEPARTMENT_FAILURE,
  payload: error,
});

export const addDepartmentRequest = (data) => {
  return{
  type: ADD_DEPARTMENT_REQUEST,
  payload:data
  };
};
export const addDepartmentSuccess = (data) => {
  return{
  type: ADD_DEPARTMENT_SUCCESS,
  payload:data
  };
};

export const addDepartmentFailure = (error) => ({
  type: ADD_DEPARTMENT_FAILURE,
  payload: error,
});

export const updateDepartmentRequest = (data) => {
  return{
  type: UPDATE_DEPARTMENT_REQUEST,
  payload:data
  };
};

export const updateDepartmentSuccess = (data) => {
  return{
  type: UPDATE_DEPARTMENT_SUCCESS,
  payload: data,
}
}


export const updateDepartmentFailure = (error) => ({
  type: UPDATE_DEPARTMENT_FAILURE,
  payload: error,
});
export const deleteDepartmentRequest = (id) => {
  return{
  type: DELETE_DEPARTMENT_REQUEST,
  payload: id,
  }
};

export const deleteDepartmentSuccess = (id) => {

  return{
  type: DELETE_DEPARTMENT_SUCCESS,
  payload: id,
  }
}

export const deleteDepartmentFailure = (error) => ({
  type: DELETE_DEPARTMENT_FAILURE,
  payload: error,
});
