import SideNavone from "../../../../SideNavBar";
import ServiceGroup from "./ServiceGroup";
import CreateFormMobile from "../newService/components/CreateFormMobile";

const ServiceGroupContainer = () => {
  return (
    <>
      <SideNavone>
        <ServiceGroup />
        <CreateFormMobile />
      </SideNavone>
    </>
  );
};
export default ServiceGroupContainer;
