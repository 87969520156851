import React from "react";
const ProductDetail = ({ location }) => {
  const id =
    location &&
    location?.state &&
    location?.state?.BusinessId &&
    location?.state?.BusinessId?.name;
  return (
    <>
      <div className="card  pt-4">
        <div className="row">
          <div className="col-md-7 col-7">
            <div className="d-flex ms-3">
              <div className="d-flex">
                <img
                  className="image col-md-2 col-2"
                  src="../images/Custom Size29.jpg"
                  alt="no-data"
                />
                <div className="col-md-9 col-12">
                  {" "}
                  <h1 className=" calander mt-2 ms-1">
                    {" "}
                    {id && id.charAt(0).toUpperCase() + id.slice(1)}
                  </h1>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex">
                <div className=" ps-5 ms-5 pt-3 text">
                  That thing you couldn't do with standard automation? <br />
                  <div className="d-flex">
                    With &nbsp;{" "}
                    <strong className="strong">
                      {" "}
                      {id && id.charAt(0).toUpperCase() + id && id.slice(1)}
                    </strong>{" "}
                    &nbsp; you can!
                  </div>
                </div>

                {/* <div className="col-md-6 mt-4 pt-4 see col-6">See all Plans</div> */}
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex">
                <div className="ps-5 ms-5 mt-4 pt-4 see ">See all Plans</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center mt-3  me-0 col-4 ">
            <img
              className="image2 col-md-2"
              alt="no-data"
              src="../images/Untitled-1 2.png"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetail;
