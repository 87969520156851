// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepageHeroContainer {
  width: 100%;
  padding: 2.5vw 0;
  padding-top: 7rem;
  /* height: 95vh; */
  background: linear-gradient(258.67deg, #f3f3f3 24.47%, #ffffff 84.71%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepageHeroChild1 {
  width: 45%;
  margin-right: 1.17vw;
}
.homepageHeroChild2 {
  width: 40%;
}
.homepageHeroChild2 img {
  width: 100%;
  margin-left: 3.66vw;
}
.homepageHeroTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.61vw;
  color: #548dff;
  margin-bottom: 1.46vw;
}
.homepageHeroDescription {
  width: 33.52vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 153.19%;
  /* or 21px */
  margin-bottom: 2.19vw;
  color: #8d8d8d;
}
.homepageHeroChild1 button {
 
}
.homepagehero_button{ width: 18.59vw;
  height: 3.53vw;
  border: 0;
  background: #548dff;
  border-radius: 0.36vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.17vw;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 3.51vw;}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/WebHomePage/Hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,sEAAsE;EACtE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,UAAU;AACZ;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;AACA;;AAEA;AACA,sBAAsB,cAAc;EAClC,cAAc;EACd,SAAS;EACT,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,kBAAkB,CAAC","sourcesContent":[".homepageHeroContainer {\n  width: 100%;\n  padding: 2.5vw 0;\n  padding-top: 7rem;\n  /* height: 95vh; */\n  background: linear-gradient(258.67deg, #f3f3f3 24.47%, #ffffff 84.71%);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.homepageHeroChild1 {\n  width: 45%;\n  margin-right: 1.17vw;\n}\n.homepageHeroChild2 {\n  width: 40%;\n}\n.homepageHeroChild2 img {\n  width: 100%;\n  margin-left: 3.66vw;\n}\n.homepageHeroTitle {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 1.61vw;\n  color: #548dff;\n  margin-bottom: 1.46vw;\n}\n.homepageHeroDescription {\n  width: 33.52vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.02vw;\n  line-height: 153.19%;\n  /* or 21px */\n  margin-bottom: 2.19vw;\n  color: #8d8d8d;\n}\n.homepageHeroChild1 button {\n \n}\n.homepagehero_button{ width: 18.59vw;\n  height: 3.53vw;\n  border: 0;\n  background: #548dff;\n  border-radius: 0.36vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.17vw;\n  text-align: center;\n  color: #ffffff;\n  cursor: pointer;\n  margin-top: 3.51vw;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
