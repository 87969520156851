import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Autocomplete from "@mui/material/Autocomplete";
import PopupIndicator from "@mui/material/Popover";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Times from "./Times";
import dayjs from "dayjs";
import { weekOptions } from "../TimeSlot/helper";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";

const RepeatedDay = (props) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    activeWeekOptions,
    setActiveWeekOptions,
    setError,
    handleUpdateTimeSlots,
  } = props;
  const [isAllView, setIsAllView] = useState(false);
  const [activeWeek, setActiveWeek] = useState(false);
  const [selectedWeekOption, setSelectedWeekOption] = useState([
    weekOptions[0],
  ]); // Default to the first week option

  useEffect(() => {
    if (activeWeek) {
      const selectedWeek = weekOptions.find(
        (option) => option.id === activeWeek
      );
      setSelectedWeekOption((prevSelectedWeeks) => {
        if (!prevSelectedWeeks.some((week) => week.id === selectedWeek.id)) {
          return [...prevSelectedWeeks, selectedWeek];
        }
        return prevSelectedWeeks;
      });
    }
  }, [activeWeek]);

  function splitArrayIntoChunks(array, chunkSize) {
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }

  const arrayOfObjects = values.scheduleItems;
  const chunkSize = 7;
  const splitArray = splitArrayIntoChunks(arrayOfObjects, chunkSize);

  useEffect(() => {
    const startDate = dayjs(values.startDate);
    const endDate = startDate.add(7, "day");
    handleUpdateTimeSlots(startDate, endDate, values, setFieldValue);
    setActiveWeek(1);
  }, []);

  const handleChangeActiveWeek = (event, value) => {
    const startDate = dayjs(values.startDate);
    const endDate = startDate.add(value ? parseInt(value.days) : 1, "day");
    handleUpdateTimeSlots(startDate, endDate, values, setFieldValue, value);
    setActiveWeek((value && value.id) || null);
  };

  const handleSelectUnSelectSlot = (index, slot, setFieldValue) => {
    const selectedWeek = activeWeek || slot.week;
    const currentSlot = values.scheduleItems.find(
      (slot) => slot.week === selectedWeek || slot.active === true
    );
    setFieldValue(`scheduleItems.${index}`, {
      ...slot,
      startTime: slot.active === true ? [""] : currentSlot.startTime,
      endTime: slot.active === true ? [""] : currentSlot.endTime,
      active: slot.active === true ? slot.active === false : !slot.active,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4 ">
        <span className="schedule_text_mobile pt-3">
          {" "}
          <SyncOutlinedIcon
            className="text-primary"
            sx={{ fontSize: "18px" }}
          />
          Repeat
        </span>

        <div className="autocomplete">
          <Autocomplete
            disablePortal
            options={weekOptions}
            getOptionLabel={(option) => option.label}
            defaultValue={weekOptions[0]}
            components={{
              PopupIndicator: ArrowDropDownIcon,
            }}
            onChange={handleChangeActiveWeek}
            sx={{
              width: 150,
              backgroundColor: "#59F",
              borderRadius: "10px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    color: "white",
                    height: "30px",
                    fontSize: "12px",
                    paddingTop: "10px",
                  },
                }}
              />
            )}
          />
        </div>
      </div>

      {splitArray.map((weekDays, weekIndex) => (
        <div className="scroll-container card  mt-3" key={weekIndex}>
          <div className="card_mobile d-flex justify-content-between ">
            <div>
              <span className="schedule_text_mobile">Week {weekIndex + 1}</span>
          </div>
          <div className="  ms-3 me-3 d-flex justify-content-evenly">
            <div className="row pe-5 me-1">
              <div className="col-md-5 ">
              </div>
              <div className="col-md-7 ps-4 d-flex justify-content-evenly">
                <div className="col-md-1">
                  <div className="time">From</div>
                </div>
                <div className="col-md-6">
               <div className="time pe-5 ms-4">  &nbsp;&nbsp;&nbsp; To</div>
                </div>
              </div>
            </div>
          </div>
            {/* <div className="row ">
              <div className="col">
              <div className="col-md-5 ">
                <span className="schedule_text_mobile">Week {weekIndex + 1}</span>
              </div>
              <div className="col-md-7 ps-4 d-flex justify-content-evenly">
                <div className="col-md-1">
                  <div className="time">From</div>
                </div>
                <div className="col-md-6">
                  <div className="time ms-5 ps-4">To</div>
                </div>
              </div>
              </div>
            </div> */}
            {/* <div>
              <span className="schedule_text_mobile">Week {weekIndex + 1}</span>
            </div> */}
            {/* <div className="d-flex flex-wrap justify-content-between">
              <div className="time" style={{ paddingLeft: "350px" }}>
                From
              </div>
            </div>
            <div className="mr-4" style={{ paddingRight: "350px" }}>
              <div className="time">To</div>
            </div> */}
          </div>
          {/* <div className=" mt-3  ms-3 me-3 d-flex justify-content-evenly">
            <div className="d-flex  ">
              <div className="ms-5 pe-5 time">From</div>
            </div>
            <div className="mr-4">
              <div className="me-4 pe-4 time">To</div>
            </div>
          </div> */}
       
          {weekDays.map((slot, index) => (
            <div key={index}>
              <div>
                <div className="d-flex">
                  <div className="d-flex align-items-start justify-content-start flex-wrap mt-3">
                    <div className="card-text-mobile  mt-3 me-2">
                      <label
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={slot.active}
                          checked={slot.active}
                          onChange={() =>
                            handleSelectUnSelectSlot(index, slot, setFieldValue)
                          }
                          style={{
                            marginBottom: "10px",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        {dayjs(slot.date).format("dddd")}
                      </label>
                    </div>
                    <Times
                      values={values}
                      slot={slot}
                      scheduleIndex={index}
                      setError={setError}
                      setFieldValue={setFieldValue}
                      startTimeValues={values.scheduleItems[index].startTime}
                      startTimeTouched={
                        touched &&
                        touched.scheduleItems &&
                        touched.scheduleItems[index] &&
                        touched.scheduleItems[index].startTime
                      }
                      startTimeErrors={
                        errors &&
                        errors.scheduleItems &&
                        errors.scheduleItems[index] &&
                        errors.scheduleItems[index].startTime
                      }
                      endTimeValues={values.scheduleItems[index].endTime}
                      endTimeTouched={
                        touched &&
                        touched.scheduleItems &&
                        touched.scheduleItems[index] &&
                        touched.scheduleItems[index].endTime
                      }
                      endTimeErrors={
                        errors &&
                        errors.scheduleItems &&
                        errors.scheduleItems[index] &&
                        errors.scheduleItems[index].endTime
                      }
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RepeatedDay;
