import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, IconButton, Tooltip } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaRegEdit } from 'react-icons/fa';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const EventsGView = (props) => {

  return (
    <div className='container events_grid_container'>
      <div className='row'
      >
        {props?.data.length > 0 && props?.data.map(v => {
          return (
            <div className='col-sm-6 col-md-4 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
            >
              <Card className='w-100' sx={{ width: '95%', borderRadius: '15px', border: '1px solid lightgray' }}
              >
                <CardActionArea className='px-3 py-2'
                >
                  <CardContent className='px-0' >
                    <Typography variant="body2" color="text.secondary">
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                          <DateRangeOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                          <span className='ms-2'><b>12 Aug 2022</b></span>
                        </div>
                        <div className='d-flex'>
                          <span className='text-secondary py-1 px-3' style={{ borderRadius: '40px', background: '#f7f7f9' }}>Development</span>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-3'>
                        <div className='d-flex'>
                          <AccessTimeOutlinedIcon sx={{ fontSize: '18px' }} />
                          <span className='ms-2'><b>09:00 AM-06:00 PM</b></span>
                        </div>
                        <div className='d-flex'>
                          <AccessAlarmOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                          <span className='ms-2'><b>30 mins</b></span>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-3'>
                        <div className='d-flex'>
                          <AccessAlarmOutlinedIcon className='text-warning' sx={{ fontSize: '18px' }} />
                          <span className='ms-2 text-warning'>-00-14-59</span>
                        </div>
                        <div className='d-flex'>
                          <span className='ms-2 text-warning'>Pending</span>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-3'>
                        <div className='d-flex'>
                          <GroupOutlinedIcon className='text-secondary' sx={{ fontSize: '24px' }} />
                          <span className='ms-2'><b>12 Users</b></span>
                        </div>
                        <div className='d-flex'>
                          <ContentCopyOutlinedIcon className='text-primary' sx={{ fontSize: '18px' }} />
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-3'>
                        <button className='btn btn-danger' style={{ width: '40%', fontSize: '12px' }}>Cancel</button>
                        <button className='btn btn-primary' style={{ width: '40%', fontSize: '12px' }}>Update</button>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EventsGView