import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, IconButton, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaRegEdit } from 'react-icons/fa';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useSelector } from 'react-redux';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';

const BookedEventsGView = () => {
  const store = useSelector(state => state?.meetspaceReducer);

  return (
    <div className='container-fluid'>
      <div className='grid_view_row mt-4 px-5'>
        <div className='row mb-5'>
          {store?.allEventsList.map(v => {
            return (
              <div className='col-sm-6 col-md-4 col-xlg-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
              >
                <Card className='w-100' sx={{ maxWidth: 'auto', borderRadius: '15px', border: '1px solid lightgray' }}
                >
                  <CardActionArea className='px-3 pb-2 pt-3'
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex img_sec'>
                        <img className='rounded-circle' src='./meetspace/cl1.png' style={{ height: '50px', width: '50px' }} />
                        <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                          <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                          <p className='p-0 m-0 _id'>9ur484</p>
                        </span>
                      </div>

                      <div className="dropdown" style={{ positition: 'relative' }}>
                        <Tooltip title={`Action`}>
                          <IconButton aria-label="" size="large" className='p-3'
                            id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            sx={{ height: '30px', width: '30px', zIndex: '0' }}
                          >
                            <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                          </IconButton>
                        </Tooltip>
                        <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                          style={{ position: 'relative', zIndex: '4' }}
                        >
                          <div className='py-1'
                          >
                            <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                            <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                            >Rename</span>
                          </div>
                          <div className='py-1'
                          >
                            <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                            />
                            <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                            >Delete</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <CardContent className='px-0' >
                      <Typography variant="body2" color="text.secondary">
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex'>
                            <ComputerOutlinedIcon className='text-secondary' sx={{ fontSize: '24px' }} />
                            <span className='ms-2'>12 events</span>
                          </div>
                        </div>

                        <div className='d-flex justify-content-between'>
                          <span className='d-flex align-items-center mt-2'>
                            <div className='bg-primary' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                            <span className='ms-3'>Completed</span>
                          </span>
                          <span className=''>50</span>
                        </div>

                        <div className='d-flex justify-content-between'>
                          <span className='d-flex align-items-center mt-2'>
                            <div className='bg-warning' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                            <span className='ms-3'>Booked</span>
                          </span>
                          <span className=''>30</span>
                        </div>

                        <div className='d-flex justify-content-between'>
                          <span className='d-flex align-items-center mt-2'>
                            <div className='bg-danger' style={{ height: '15px', width: '15px', borderRadius: '20px' }}></div>
                            <span className='ms-3'>Cancelled</span>
                          </span>
                          <span className=''>20</span>
                        </div>

                        <div className='d-flex mt-3'>
                          <span className='bg-primary' style={{ height: '7px', width: '50%', borderRadius: '12px' }}></span>
                          <span className='bg-warning ms-1' style={{ height: '7px', width: '20%', borderRadius: '12px' }}></span>
                          <span className='bg-danger ms-1' style={{ height: '7px', width: '30%', borderRadius: '12px' }}></span>
                        </div>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BookedEventsGView;