import { Col, Row } from "react-bootstrap";
import PublishStatusServices from "../../../../Componenets/publishStatusServices/PublishStatusServices";
import ColorPicker from "react-pick-color";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";

const ServiceSettings = ({
  circleColor,
  visibleColorPicker,
  colorPickerRefs,
  handleColorChange,
  handleColorPicker,
  splitWords,
}) => {
  return (
    <Row className="dynamic_formContainer service-add-detail service-group-wrap service-settings px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head my-3">
            Please select colour for following states
          </h6>

          <div className="custom-border color-card">
            <ul className="pt-3 px-3">
              {Object.entries(circleColor).map(([state, stateColor]) => (
                <li
                  key={state}
                  className="pb-3 d-flex justify-content-between color-list"
                >
                  <div>{splitWords(state)}</div>
                  <div className="d-flex align-items-center">
                    {!visibleColorPicker[state] && (
                      <div onClick={(e) => handleColorPicker(state, e)}>
                        <CreateOutlinedIcon className="cursor-pointer" />
                      </div>
                    )}
                    &nbsp;
                    <div
                      className="color-circle"
                      style={{ background: stateColor }}
                    />
                  </div>
                  {visibleColorPicker[state] && (
                    <div ref={(ref) => (colorPickerRefs.current[state] = ref)}>
                      <ColorPicker
                        className="color-picker"
                        color={stateColor}
                        onChange={(color) => handleColorChange(color, state)}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="pt-3">
            <div className="d-flex justify-content-end gap-3 pt-5 mt-5">
              <button className="cancel-btn">Cancel</button>
              <button className="save-btn">Save</button>
            </div>
          </div>
        </div>
      </Col>
      <PublishStatusServices />
    </Row>
  );
};

export default ServiceSettings;
