import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import CryptoJS from "crypto-js";

import StaffListTable from "../../Componenets/Staff/StaffListTable";
import {
  useGetStaffsQuery,
  useSearchStaffListMutation,
} from "../../Feature/api/StaffApi";
import SideNavone from "../../SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SkeletonTable from "../BusinessProfile/SkeltonTableSimple/SkeltonTable";
import { ClipLoader } from "react-spinners";
import {
  getAllStaff,
  searchStaffList,
} from "../Dashboard/MainComponent/store/staffActions";
import {
  customSnackBar,
  isBusinessRolePermissionAllowed,
} from "../../utils/index";

const FacultyPage = () => {
  const business = useSelector((state) => state.Business?.selectedBusiness);
  const bussinessId = business?.id;
  var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const store = useSelector((state) => state?.staffReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [filterService, setFilterService] = useState([]);
  const [staffSortedData, setStaffSortedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userid = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const users = useSelector((state) => state.Auth?.userloginsuccessyasir);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [viewPermssion, setViewPermissions] = useState(null);
console.log(store,"STOEYEIEY")
  const permissionsToCheck = [
    "canViewBusinessStaffList",
    "canSeeStaffSchedule",
    "canViewStaffProfile",
    "canSeeStaffPreferences",
    "canUpdateStaffProfile",
    "canUpdateStaffPrefernces",
    "canCreateStaffForm",
    "canAddStaff",
  ];
  let permissions = useSelector((state) => state?.Auth?.permissionsModule);

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Staff",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    console.log("Permissions to check:", permissionsToCheck);
    if (permissions) {
      fetchPermissions();
    } else {
      console.log("user doesn'y have  permsiion");
    }
  }, [permissions]);
  console.log(business, "busiens");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          store?.filterText === "" &&
          store?.firstNameCustomer === "" &&
          store?.lastName === "" &&
          store?.email === "" &&
          store?.mobileNumber === ""
        ) {
          setIsLoading(true);

          await dispatch(
            getAllStaff({
              id: business?.id,
              userid: parseInt(userID),
              page,
              pageSize: itemsPerPage,
              setIsLoading,
            })
          );
          setIsLoading(false);
        } else {
          // if (store?.filterText.length > 0) {
          console.log(store, "STORE444");

          await dispatch(
            searchStaffList({
              id: business?.id,
              userid: parseInt(userid),
              text: store?.filterText,
              firstName: store?.firstNameCustomer
                ? store?.firstNameCustomer
                : "",
              lastName: store?.lastName ? store?.lastName : "",
              email: store?.email ? store?.email : "",
              number: store?.mobileNumber ? store?.mobileNumber : "",
            })
          );
          // }
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error:", error);
      }
    };
    // if(business!==null)
    // {
    fetchData();
    // }else{
    //   customSnackBar("Please Add business First")
    // }
  }, [
    business?.id,
    userID,
    dispatch,
    page,
    itemsPerPage,
    store?.filterText,
    store?.firstNameCustomer,
    store?.lastName,
    store?.email,
    store?.mobileNumber,
  ]);
  function encryptURL(currentURL) {
    return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP7Y8MP").toString();
  }
    
  
  const handleNavLinkClick = () => {
    const currentURL = window.location.href;
    const encryptedURL = encryptURL(currentURL);
    const encryptedUser = encryptURL(JSON.stringify(users));

    const redirectObject = {
      encryptedURL: encryptedURL,
      user: encryptedUser,
    };

    const redirectURL = `https://accounts.plexaar.com?redirectInfo=${encodeURIComponent(
      JSON.stringify(redirectObject)
    )}`;
    // Perform redirection
    window.location.href = redirectURL;
    // If you want to use history.push() for redirection uncomment the following line
    // history.push(redirectURL);
  };
  const handleAddClick = () => {
    history.push("/edit-faculty", { state: staffSortedData });
    // history.push(`staff-list/${bussinessId}/columns-list`);

    // navigate("/staff/edit-staff", { state: staffSortedData });
  };
  console.log(viewPermssion, "PERMSISISO");

  return (
    <>
      {/* {viewPermssion !== null && ( // Conditionally render SideNavone when permissions are not null */}

      <SideNavone
        permission={viewPermssion ? viewPermssion : permissionsToCheck}
        onClick={handleAddClick}
      >
        <Grid px={2} pt={3} pb={5}>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ClipLoader color="#548DFF" />
            </div>
          ) : (
            <>
              <Grid item sm={12} md={12} lg={12}>
                {business === null ? (
                  <div className="d-flex flex-column align-items-center justify-content-center vh-69">
                                                    <h5 className="m-0">Please Create Business first</h5>

                    <Button
                      style={{ backgroundColor: "#5599FF" }}
                      className="col-4 py-3 my-3"
                      variant="contained"
                      onClick={() => handleNavLinkClick()}
                    >
                      Add Business
                    </Button>
                  </div>
                ) : (
                  <StaffListTable
                  setIsLoading={setIsLoading}
                  page={page}
                    permission={viewPermssion}
                    staffData={filterService}
                    staffSortedData={staffSortedData}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    updateState={setStaffSortedData}
                  />
                )}
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                mt={6}
                mb={2}
              >
                <Box display="flex" justifyContent="space-between">
                  <Stack spacing={2} className="">
                    <Pagination
                      sx={{
                        ".css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root":
                          {
                            border: "none",
                            color: "#969696",
                            minWidth: "25px",
                            borderRadius: "0px",
                            position: 'static',
                          },
                        ".css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                          {
                            backgroundColor: "transparent",
                            color: "black",
                          },
                      }}
                      count={Math.ceil(store?.totalcount / itemsPerPage)}
                      page={page}
                      onChange={handleChange}
                      variant="outlined"
                      siblingCount={9}
                      boundaryCount={0}
                    />
                  </Stack>
                  <div>
                    <select
                      className="items_per_page_select"
                      onChange={(e) =>
                        setItemsPerPage(parseInt(e.target.value))
                      }
                      value={itemsPerPage}
                    >
                      <option disabled>Items Per page</option>
                      <option value={10} selected>
                        10
                      </option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    &nbsp;&nbsp;&nbsp;<span className="per-page-text">Per Page</span>
                  </div>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </SideNavone>
      {/* // )} */}
    </>
  );
};

export default FacultyPage;
