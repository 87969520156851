import { createSlice } from "@reduxjs/toolkit";
import {
  calculateDurationInMinutes,
  calculateEndTimeFromStartAndDuration,
  formatTime,
} from "../../../utils";

const initialState = {
  open: false,
  servicesData: [],
  slotsData: [],
  customerData: {},
  bookingData: {},
  selectedEvent: {},
  bookingId: 0,
  appointmentId: 0,
  plexarSaleOrderId: 0,
  appointmentdetail: {},
  eventsDuration: 0,
  slotFlag: false,
  isExpert: false,
  isPrivate: false,
  isAll: false,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    selectedServiceData: (state, action) => {
      state.servicesData = action.payload;
    },
    selectedSlotData: (state, action) => {
      state.slotsData = action.payload;
    },
    addCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
    singleBookingDetails: (state, action) => {
      state.bookingId = action.payload;
    },
    singleBookingAppoitmentDetails: (state, action) => {
      state.appointmentId = action.payload;
    },
    BookingDetails: (state, action) => {
      state.bookingData = action.payload;
    },
    SelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setEventDuration: (state, action) => {
      state.eventsDuration = action.payload;
    },
    slotsFlag: (state, action) => {
      state.slotFlag = action.payload;
    },
    addBookingTypeExpert: (state, action) => {
      state.isExpert = true;
      state.isPrivate = false;
      state.isAll = false;
    },
    addBookingTypePrivate: (state, action) => {
      state.isPrivate = true;
      state.isExpert = false;
      state.isAll = false;
    },
    addBookingTypeAll: (state, action) => {
      state.isPrivate = false;
      state.isExpert = false;
      state.isAll = true;
    },
    resetAllData: (state, action) => {
      state.servicesData = [];
      state.slotsData = [];
      state.customerData = {};
      state.bookingData = {};
      state.bookingId = 0;
      state.eventsDuration = 0;
      state.slotFlag = false;
      state.isExpert = false;
      state.isPrivate = false;
    },
    saleOrderDetails: (state, action) => {
      state.plexarSaleOrderId = action.payload;
    },
    appoitmentsDetails: (state, action) => {
      state.appointmentdetail = action.payload;
    },
    updateTitle: (state, action) => {
      return {
        ...state,
        slotsData: {
          ...state.slotsData,
          title: action.payload,
        },
      };
    },
    updateDescription: (state, action) => {
      return {
        ...state,
        slotsData: {
          ...state.slotsData,
          description: action.payload,
        },
      };
    },
    updateDetailsDuration: (state, action) => {
      // debugger;
      const { duration } = action.payload;
      const { timeFrom } = state.slotsData;
      const formattedStartTime = formatTime(timeFrom);
      const newTimeTo = calculateEndTimeFromStartAndDuration(
        formattedStartTime,
        duration
      );
      if (newTimeTo !== "NaN:NaN") {
        if (newTimeTo > "23:55") {
          const remainingDuration = calculateDurationInMinutes(
            formattedStartTime,
            "23:55"
          );
          return {
            ...state,
            slotsData: {
              ...state.slotsData,
              timeFrom: formattedStartTime,
              duration: remainingDuration,
              timeTo: "23:55",
            },
          };
        }
        return {
          ...state,
          slotsData: {
            ...state.slotsData,
            timeFrom: formattedStartTime,
            timeTo: newTimeTo,
            duration: duration,
          },
        };
      } else {
        return {
          ...state,
          slotsData: {
            ...state.slotsData,
            duration: duration,
          },
        };
      }

      // const timeTo = calculateEndTimeFromStartAndDuration(timeFrom, duration);
      // return {
      //   ...state,
      //   slotsData: {
      //     ...state.slotsData,
      //     duration,
      //     timeTo,
      //   },
      // };
    },
    updateStartTime: (state, action) => {
      const { startTime } = action.payload;
      const { duration } = state.slotsData;
      const formattedStartTime = formatTime(startTime);
      const newTimeTo = calculateEndTimeFromStartAndDuration(
        formattedStartTime,
        duration
      );
      // debugger;
      if (newTimeTo !== "NaN:NaN") {
        if (newTimeTo > "23:55") {
          const remainingDuration = calculateDurationInMinutes(
            formattedStartTime,
            "23:55"
          );
          return {
            ...state,
            slotsData: {
              ...state.slotsData,
              timeFrom: formattedStartTime,
              duration: remainingDuration,
              timeTo: "23:55",
            },
          };
        } else {
          return {
            ...state,
            slotsData: {
              ...state.slotsData,
              timeFrom: formattedStartTime,
              duration: duration,
              timeTo: newTimeTo,
            },
          };
        }
      }
      return {
        ...state,
        slotsData: {
          ...state.slotsData,
          timeFrom: formattedStartTime,
          // timeTo: newTimeTo,
        },
      };
    },
    updateEndTime: (state,action) => {
      const { endTime } = action.payload;
      const { timeFrom } = state.slotsData;
      const formattedStartTime = formatTime(timeFrom);
      const formattedEndTime = formatTime(endTime);
      const durationInMinutes = calculateDurationInMinutes(formattedStartTime, formattedEndTime);
      const maxDurationInMinutes = 1435;
      const newDuration = Math.min(durationInMinutes, maxDurationInMinutes);
      const newTimeTo = calculateEndTimeFromStartAndDuration(formattedStartTime, newDuration);
      if (newTimeTo !== "NaN:NaN") {
        if (newTimeTo > "23:55") {
          const remainingDuration = calculateDurationInMinutes(formattedStartTime, "23:55");
          return {
            ...state,
            slotsData: {
              ...state.slotsData,
              duration: remainingDuration,
              timeTo: "23:55",
            },
          };
        } else {
          return {
            ...state,
            slotsData: {
              ...state.slotsData,
              duration: newDuration,
              timeTo: newTimeTo,
            },
          };
        }
      }
    
      // Handle the case where calculation failed, perhaps due to invalid times
      return {
        ...state,
        slotsData: {
          ...state.slotsData,
          timeTo: formattedEndTime,
        },
      };
    },
    setTypeOfBooking: (state,action) => {
      return {
        ...state,
        slotsData: {
          ...state.slotsData,
          type: action.payload
        }
      };
    }
  },
});

export const {
  selectedServiceData,
  selectedSlotData,
  addCustomerData,
  singleBookingDetails,
  singleBookingAppoitmentDetails,
  BookingDetails,
  setEventDuration,
  resetAllData,
  slotsFlag,
  SelectedEvent,
  addBookingTypeExpert,
  addBookingTypePrivate,
  addBookingTypeAll,
  updateDetailsDuration,
  updateStartTime,
  saleOrderDetails,
  appoitmentsDetails,
  updateTitle,
  updateDescription,
  updateEndTime,
  setTypeOfBooking,
} = bookingSlice.actions;
export const bookingReducer = bookingSlice.reducer;
