import React, { useState, useEffect } from "react";
import Styles from "./InputField.module.css";
import QuestionSection from "../QuestionSection/QuestionSection";

const CheckBox = ({
  text,
  options,
  onInputChange,
  id,
  formId,
  inputType,
  section,
  handleUploadMedia,
  uploadedData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  function handleOptionSelection(option) {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
      setSelectedAnswers(
        selectedAnswers.filter((answer) => answer !== option.choiceText)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
      setSelectedAnswers([...selectedAnswers, option.choiceText]);
    }
  }

  const handleInputChange = () => {
    onInputChange(id, selectedAnswers, formId, text, inputType, section, 1);
  };

  useEffect(() => {
    handleInputChange();
  }, [selectedOptions]);

  return (
    <div>
      <div className={Styles.textQuestionContainer}>
        <p>{text}</p>
        <div className={Styles.radioButtonAlign}>
          {options.map((option, index) => (
            <label key={option.id} className={Styles.labelRadio}>
              <input
                className="checkBoxBtn"
                type="checkbox"
                value={option.id}
                onChange={() => handleOptionSelection(option)}
              />
              <p>{option.choiceText}</p>
            </label>
          ))}
        </div>
      </div>
      {options.map((item, index) => {
        const matchingOption = selectedOptions.find(
          (item2) => item.id === item2.id
        );
        if (matchingOption) {
          return (
            <QuestionSection
              key={item.id}
              questionsData={item.childs}
              onInputChange={onInputChange}
              formId={formId}
              section={section}
              handleUploadMedia={handleUploadMedia}
              uploadedData={uploadedData}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default CheckBox;
