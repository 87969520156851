export const UnavailableEventsCreation = (val, latestdate) => {
  const dateSplitting = latestdate?.split("-");
  const timeSplitting = val?.split(":");
  const date = new Date(
    dateSplitting[0],
    dateSplitting[1] - 1,
    dateSplitting[2],
    timeSplitting[0],
    timeSplitting[1],
    0
  );
  return date;
};
