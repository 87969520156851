import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../../utils/requests";
import { DOCUMENT_TO_URL_STAGING } from "../../../../Constants/DynamicFormConstants";

const VideoDocumentField = ({ handleChange, id, label, formData }) => {
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const videoRef = useRef(null);
  const videoUploadRef = useRef(null);

  const handleVideoChange = (e) => {
    const videoFile = e.target.files[0];
    setSelectedVideo(videoFile);
    playVideo(videoFile);
    handleUpload(videoFile);
  };

  const handleUpload = async (videoFile) => {
    if (!videoFile) {
      console.error("No video file selected.");
      return;
    }

    const formData = new FormData();
    var randomId = Math.floor(Math.random() * 1000000);

    formData.append("Id", randomId);
    formData.append("serviceName", "onboarding");
    formData.append("source", "Plexaar");
    formData.append("Category", "main");
    formData.append("FileType", "v"); // Adjust the file type accordingly
    formData.append("Files", videoFile);
    formData.append("CreatedBy", randomId);

    await post(DOCUMENT_TO_URL_STAGING, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure you use multipart/form-data for form data
      },
    })
      .then((response) => {
        setVideoUrl(response.data.paths[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const playVideo = (videoFile) => {
    if (!videoFile) {
      toast.error("No video file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const videoDataUrl = reader.result;
      videoRef.current.src = videoDataUrl;
      videoRef.current.pause();
    };

    reader.readAsDataURL(videoFile);
  };

  const handleUploadClick = () => {
    videoUploadRef.current.click();
  };

  useEffect(() => {
    if (videoUrl) handleChange(id, videoUrl);
    // eslint-disable-next-line
  }, [videoUrl]);

  return (
    <>
      <div className="float-label">
        <input
          type="file"
          accept="video/*"
          id={id}
          ref={videoUploadRef}
          className="document-field"
          onChange={handleVideoChange}
        />
        <div className="document-uploader">
          {selectedVideo && <p className="float-left">{selectedVideo.name}</p>}
          <button type="button" onClick={() => handleUploadClick()}>
            {selectedVideo ? "Re-Upload" : "Upload"}
          </button>
        </div>
        <label htmlFor={id}>{label}</label>
      </div>
      {selectedVideo && (
        <div>
          <video className="video-frame" ref={videoRef} controls />
        </div>
      )}
    </>
  );
};

export default VideoDocumentField;
