import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/bootstrap.css";
import SideNavBar from "../../../../SideNavBar";
// import "../AddBusiness/AddBusiness.scss";
import "../../../Dashboard/MainComponent/Stafflistview/staffview.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBusinessRequest } from "../../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import BannerProfile from "./bannerProfile";
import BusinessDetail from "./businessDetail";
import CardLink from "./cardsLink";
import BusinessOwner from "./BusinessOwner";
const BusinessProfile = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const newid = parseInt(id);
  const businesProfile = useSelector((state) => state.Business.businesProfile);
  const [showNewRole, setShowNewRole] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showIndustry, setShowIndustry] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    if (newid) {
      dispatch(getBusinessRequest({newid,setLoading}));
    }
  }, [newid]);
  const handleType = () => {
    setShowType(true);
  };
  const handleEditName = () => {
    setShowNewRole(true);
  };

  const handleIndustry = () => {
    setShowIndustry(true);
  };

  const handleWebsite = () => {
    setShowWebsite(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <SideNavBar>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader color="#548DFF" />
        </div>
      ) : businesProfile ? (
        <div
          className="padding_left_right hide_scrollbar pt-4 mt-2 "
          style={{
            height: "100vh",
            paddingBottom: "100px",
            overflow: "auto",
          }}
        >
          <BannerProfile isMobile={isMobile} businesProfile={businesProfile} />
          {/* Profile show section ------------------------------------------------------ */}
          <BusinessDetail
            businesProfile={businesProfile}
            handleEditName={handleEditName}
            handleType={handleType}
            isMobile={isMobile}
            handleWebsite={handleWebsite}
            handleIndustry={handleIndustry}
          />
          <CardLink isMobile={isMobile} businesProfile={businesProfile} />
          {businesProfile ? (
            <BusinessOwner isMobile={isMobile} businesProfile={businesProfile} />
          ) : null}
        </div>
      ) : (
        <ClipLoader color="#548DFF" size={30} />
      )}
    </SideNavBar>
  );
};
export default BusinessProfile;
