// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner_bannerContainer__joMXN {
  width: 100%;
  height: 12rem;
  background-color: #548dff;
  padding-top: 0.4rem;
  margin-top: 1.2rem;
}
.Banner_bannerText__549uY {
  font-family: Roboto;

  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.Banner_bannerText__549uY span {
  font-weight: 600;
}

.Banner_bannerText1__X0OZZ {
  font-family: Roboto;

  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
.Banner_footerBg__jkaLy {
  width: 100%;
  background-color: #000f23;
  padding-bottom: 0.1rem;
}
.Banner_terms__o67c9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  padding-top: 0.8rem;
}
.Banner_terms__o67c9 p {
  color: #fff;
  font-family: Roboto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 300;
  opacity: 0.8;
}
.Banner_cop__Dvdr4 {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 0.7rem;
  font-style: normal;
  opacity: 0.8;
  font-weight: 300;
  line-height: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/MobileHomePage/Banner/Banner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;;EAEnB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;;EAEnB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".bannerContainer {\n  width: 100%;\n  height: 12rem;\n  background-color: #548dff;\n  padding-top: 0.4rem;\n  margin-top: 1.2rem;\n}\n.bannerText {\n  font-family: Roboto;\n\n  color: #fff;\n  font-size: 1rem;\n  font-weight: 300;\n  text-align: center;\n  margin-top: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.bannerText span {\n  font-weight: 600;\n}\n\n.bannerText1 {\n  font-family: Roboto;\n\n  color: #fff;\n  text-align: center;\n  font-size: 0.9rem;\n  font-weight: 400;\n  margin-bottom: 2rem;\n}\n.footerBg {\n  width: 100%;\n  background-color: #000f23;\n  padding-bottom: 0.1rem;\n}\n.terms {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 2rem;\n  padding-top: 0.8rem;\n}\n.terms p {\n  color: #fff;\n  font-family: Roboto;\n  font-size: 0.8rem;\n  font-style: normal;\n  font-weight: 300;\n  opacity: 0.8;\n}\n.cop {\n  color: #fff;\n  text-align: center;\n  font-family: Roboto;\n  font-size: 0.7rem;\n  font-style: normal;\n  opacity: 0.8;\n  font-weight: 300;\n  line-height: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `Banner_bannerContainer__joMXN`,
	"bannerText": `Banner_bannerText__549uY`,
	"bannerText1": `Banner_bannerText1__X0OZZ`,
	"footerBg": `Banner_footerBg__jkaLy`,
	"terms": `Banner_terms__o67c9`,
	"cop": `Banner_cop__Dvdr4`
};
export default ___CSS_LOADER_EXPORT___;
