// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.joinusContainer {
  width: 100%;
  display: flex;
}
.joinusChild1 {
  width: 53%;
  height: 39.38vw;
  z-index: -2222;
}
.joinusChild1 img {
  height: 90%;
}
.joinusChild2 {
  width: 30%;
  margin-top: 3.29vw;
}
.joinusText {
  font-family: "Roboto";
  font-style: normal;
  font-size: 2.56vw;
  line-height: 3vw;
  font-weight: 400;

  color: #000000;
  display: flex;
  flex-direction: column;
}

.joinusBold {
  font-weight: 700;
}
.joinusInfo {
  margin-top: 1.83vw;
  margin-bottom: 3.66vw;
  width: 42.16vw;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 1.09vw;
  line-height: 1.46vw;
  /* or 133% */

  color: #545454;
}

.joinusBtn {
  width: 21.83vw;
  height: 4.09vw;

  background: #8bb2ff;
  border-radius: 0.36vw;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.31vw;
  line-height: 1.53vw;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/WebHomePage/Joinus/Joinus.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,UAAU;EACV,eAAe;EACf,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;;EAEhB,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;;EAEd,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;;EAEZ,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;;EAEd,mBAAmB;EACnB,qBAAqB;;EAErB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,4BAA4B;;EAE5B,kBAAkB;;EAElB,cAAc;EACd,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".joinusContainer {\n  width: 100%;\n  display: flex;\n}\n.joinusChild1 {\n  width: 53%;\n  height: 39.38vw;\n  z-index: -2222;\n}\n.joinusChild1 img {\n  height: 90%;\n}\n.joinusChild2 {\n  width: 30%;\n  margin-top: 3.29vw;\n}\n.joinusText {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-size: 2.56vw;\n  line-height: 3vw;\n  font-weight: 400;\n\n  color: #000000;\n  display: flex;\n  flex-direction: column;\n}\n\n.joinusBold {\n  font-weight: 700;\n}\n.joinusInfo {\n  margin-top: 1.83vw;\n  margin-bottom: 3.66vw;\n  width: 42.16vw;\n\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 1.09vw;\n  line-height: 1.46vw;\n  /* or 133% */\n\n  color: #545454;\n}\n\n.joinusBtn {\n  width: 21.83vw;\n  height: 4.09vw;\n\n  background: #8bb2ff;\n  border-radius: 0.36vw;\n\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.31vw;\n  line-height: 1.53vw;\n  /* identical to box height */\n\n  text-align: center;\n\n  color: #ffffff;\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
