export const BntLoader = () => {
    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center p-3"
            style={{ position: 'absolute', left: '0', top: '0', backgroundColor: 'smoke', opacity: '.4', backgroundColor: 'white' }}
        >
            <div className="spinner-border text-dark" role="status"
                style={{ height: '18px', width: '18px', position: 'absolute', opacity: '1' }}
            >
                {/* <span className="sr-only">Loading...</span> */}
            </div>
        </div>
    )
}