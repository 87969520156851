import React from 'react'
import { customSnackBar } from '../utils';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { ClickSvgs, MenuItemSvg, MenuSvgs } from '../Assets/svgs';

const HeaderButton = ({
    isMobile,
    location,
    providerDatas,
    handleMoreOptionsClick,
    permission,
    onClick,
    record,
    history,
    link,
    buttonText,
    getStaffFormData,
    dispatch,
    handleBusinesSelection,
    selectedBusines,
    handleMenuActionClick,
    openAction,
    getServiceFormData,
    anchorEl,
    handleMenuClose,
    getFormData,
    handleClick,
    typeOfBooking
}) => {
  console.log("-----------------------------------------------------------------------------------------------------");
  console.log(buttonText);
  console.log("-----------------------------------------------------------------------------------------------------");
  return (
<div className={`${!isMobile ? "pt-2 " : ""}`}>
              <div className={`${!isMobile ? "flex_in_calendex_bar1" : ""}`}>
                <div className={`${!isMobile ? "px-2" : "pt-2 pe-2"}`}>
                  {location.pathname.includes("/staff-profile-view/") ? (
                    <span>
                      {providerDatas?.providerId !== 0 ? (
                        <button
                          onClick={() => handleMoreOptionsClick()}
                          className="btn btn-warning button_styling px-3"
                        >
                          <img
                            className="img-fluid img_size_cal_menu2"
                            src="/images/calen.png"
                            alt="no-data"
                          />
                          &nbsp;&nbsp;<span className="pt-2"> Schedule</span>
                        </button>
                      ) : null}
                      <button
                        onClick={
                          () => customSnackBar("We are working on it")
                          // history.push(`/add-staff/${selectedBusines?.id}`)
                        }
                        className="btn btn-warning button_styling px-3 mx-2"
                      >
                        <img
                          className="img-fluid img_size_cal_menu2"
                          src="/images/cale.png"
                          alt="no-data"
                        />
                        &nbsp;&nbsp;<span className="pt-2"> Bookings</span>
                      </button>

                      <button
                        onClick={
                          () => customSnackBar("We are working on it")
                          // history.push(`/add-staff/${selectedBusines?.id}`)
                        }
                        className="btn btn-warning button_styling px-3 mx-2"
                      >
                        <img
                          className="img-fluid img_size_cal_menu2"
                          src="/images/msh.png"
                          alt="no-data"
                        />
                        &nbsp;&nbsp;<span className="pt-2"> Messages</span>
                      </button>
                      <button
                        onClick={
                          () => customSnackBar("We are working on it")
                          // history.push(`/add-staff/${selectedBusines?.id}`)
                        }
                        className="btn btn-warning button_styling px-3 mx-2"
                      >
                        <img
                          className="img-fluid img_size_cal_menu2"
                          src="/images/chat.png"
                          alt="no-data"
                        />
                        &nbsp;&nbsp;<span className="pt-2"> Services</span>
                      </button>
                    </span>
                  ) : location.pathname.includes("/team") ||
                    location.pathname.includes("/department") ||
                    location.pathname.includes("/business/") ||
                    location.pathname.includes("/users") ? (
                    <Button
                      sx={{
                        backgroundColor: "#538dff",
                        height: "30px",
                        borderRadius: isMobile ? "8px" : "4px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={() => {
                        const data = {
                          data: record,
                          actionState: "new",
                        };
                        history.push(link, { data });
                      }}
                    >
                      {/* {!isMobile ? ` +  ${buttonText}` : "Add"} */}
                      {isMobile ? "Add" : +buttonText}
                    </Button>
                  ) : null}
                  {location.pathname.includes("/staff-list") &&
                    (permission.canCreateStaffForm === true ||
                    permission.canCreateStaffForm === null ||
                    permission.canCreateStaffForm === undefined ? (
                      <>
                        <button
                          onClick={() => {
                            dispatch(getStaffFormData(selectedBusines?.id));
                            history.push("/staff-preview-page");
                          }}
                          className="btn btn-warning button_styling px-4 mx-2"
                        >
                          <img
                            src="/images/user-follow-line 1.png"
                            alt=""
                            className="text-primary"
                          />
                          Create Staff Form
                        </button>
                        {permission.canAddStaff === true ||
                        permission.canAddStaff === null ||
                        permission.canAddStaff === undefined ? (
                          <button
                            onClick={() => {
                              dispatch(getStaffFormData(selectedBusines?.id));
                              handleBusinesSelection();
                            }}
                            className="btn btn-warning button_styling px-4"
                          >
                            <img
                              className="img-fluid img_size_cal_menu"
                              src="/images/addcircle.png"
                              alt="no-data"
                            />{" "}
                            Add
                          </button>
                        ) : null}
                      </>
                    ) : null)}
                </div>
                {(location.pathname.includes("/staff-list") ||
                  location.pathname.includes("/our-faculty")) &&
                  (permission.canUpdateStaffPrefernces === true ||
                  permission.canUpdateStaffPrefernces === null ||
                  permission.canUpdateStaffPrefernces === undefined ? (
                    <div className="" onClick={onClick}>
                      <Tooltip placement="bottom" title="Add Prefrence">
                        <img
                          className="img-fluid img_size_cal_menu"
                          src="/images/menu.png"
                          alt="no-data"
                        />
                      </Tooltip>
                    </div>
                  ) : null)}
                {
                  (location.pathname.includes("/all-customer") ||
                    location.pathname.includes("/dynamic-services") ||
                    location.pathname.includes("/businesses") ||
                    location.pathname.includes("/business-departments") ||
                    location.pathname.includes("/business-users") ||
                    location.pathname.includes("/business-teams")) && (
                    // (permission === true ||
                    // permission === null ||
                    // permission === undefined ? (
                    <div className="d-flex align-items-center">
                      <div
                        onClick={(event) => handleMenuActionClick(event)}
                        style={{ cursor: "pointer" }}
                        className="ms-2"
                      >
                       <MenuSvgs/>
                      </div>
                      <div className="ms-2">
                        <MenuItemSvg/>
                      </div>
                      {openAction && (
                        <Menu
                          anchorEl={anchorEl}
                          //   open={open}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            sx={{
                              fontSize: "12px",
                              color: "#A9A9A9",
                              lineHeight: "14px",
                            }}
                          >
                            <Box
                              sx={{
                                borderRedius: "6px",
                              }}
                              component="div"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              Sort By
                            </Box>
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "16px",
                              color: "#4D4D4D",
                            }}
                          >
                            <Box
                              sx={{
                                borderRedius: "6px",
                                marginTop: "6px",
                              }}
                              component="div"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              Alphabetical order
                            </Box>
                          </MenuItem>

                          <MenuItem
                            sx={{
                              fontSize: "16px",
                              color: "#4D4D4D",
                            }}
                          >
                            <Box
                              component="div"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {/* <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> */}
                              Last Name
                            </Box>
                          </MenuItem>
                        </Menu>
                      )}
                      {location.pathname.includes("/dynamic-services") ? (
                        <button
                          style={{ height: "30px" }}
                          onClick={() => {
                            dispatch(getServiceFormData(selectedBusines?.id));
                            history.push("/services-preview-page");
                          }}
                          className="btn d-flex ms-2 btn-warning button_styling px-4"
                        >
                          <ClickSvgs/>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#553300",
                            }}
                          >
                            Create Service Form
                          </span>
                        </button>
                      ) : location.pathname.includes("/all-customer") ? (
                        <>
                          {(permission.canCreateCustomerForm === true ||
                            permission.canCreateCustomerForm === null ||
                            permission.canCreateCustomerForm === undefined) && (
                            <button
                              style={{ height: "30px" }}
                              onClick={() => {
                                dispatch(getFormData(selectedBusines?.id));
                                history.push("/preview-page");
                              }}
                              className="btn d-flex ms-2 btn-warning button_styling px-4"
                            >
                             <ClickSvgs/>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#553300",
                                }}
                              >
                                Create Form
                              </span>
                            </button>
                          )}
                        </>
                      ) : null}
                      {location.pathname.includes("/all-customer") ? (
                        <>
                          {(permission?.canAddNewCustomer === true ||
                            permission?.canAddNewCustomer === null ||
                            permission?.canAddNewCustomer === undefined) && (
                            <button
                              style={{ height: "30px" }}
                              onClick={() => {
                                dispatch(getFormData(selectedBusines?.id));
                                history.push("/view-page");
                              }}
                              className="btn d-flex ms-2 btn-warning button_styling px-4"
                            >
                             <ClickSvgs/>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#553300",
                                }}
                              >
                                Add
                              </span>
                            </button>
                          )}
                        </>
                      ) : location.pathname.includes("/dynamic-services") ? (
                        <button
                          style={{ height: "30px" }}
                          onClick={() => {
                            dispatch(getServiceFormData(selectedBusines?.id));
                            history.push("/create-new-service");
                          }}
                          className="btn d-flex ms-2 btn-warning button_styling px-4"
                        >
                         <ClickSvgs/>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#553300",
                            }}
                          >
                            Add
                          </span>
                        </button>
                      ) : null}

                      {permission?.canSeeCustomerPreferences === true ||
                      permission?.canSeeCustomerPreferences === null ||
                      permission?.canSeeCustomerPreferences === undefined ? (
                        <div className="ms-2" onClick={handleClick}>
                          <Tooltip placement="bottom" title="Add Prefrence">
                            <img
                              className="img-fluid img_size_cal_menu"
                              src="/images/menu.png"
                              alt="no-data"
                            />
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>
                  )
                  // ) : null
                  // )
                }
              </div>
            </div>  )
}

export default HeaderButton