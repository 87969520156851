import React from 'react'
import CreateForm from './components/CreateForm'
import CreateFormMobile from './components/CreateFormMobile'
import SideNavone from '../../../../SideNavBar'


const Create = () => {
  return (
    <>
      <SideNavone>
        <CreateForm />
        <CreateFormMobile />
      </SideNavone>
    </>
  )
}

export default Create