import React, { useEffect, useState } from "react";
import ContactUs from "../ContactUs/ContactUs";
import Hero from "../Hero/Hero";
import { HomePageContent, defaultData } from "../../static/Content";
import { useParams } from "react-router-dom";
import AutomateProcess from "../AutomateProcess/AutomateProcess";
import HrResponsibilities from "../HrResponsibilities/HrResponsibilities";
import NewsLatter from "../NewsLatter/NewsLatter";
import Footer from "../../../Calendex/components/Footer/Footer";
import { Header } from "../../../../Hero/Header";
import {
  HRWeb,
  HRMobile,
} from "../../../../../../Assets/components/HomePage/Assets";

const Home = () => {
  const [pageData, setPageData] = useState([]);
  const { id } = useParams();
  const { readMorePages } = HomePageContent;

  // Find the page object that matches the provided id
  useEffect(() => {
    if (id) {
      const filtered = readMorePages.filter((val) => val?.id === id);
      setPageData(filtered);
    } else {
      setPageData(defaultData);
    }
  }, [id]);

  return (
    <div>
      <Header
        title={"iReach"}
        heading={"Enrich Your Workforce with AI-Driven HRMS"}
        text={
          "Manage your workforce, streamline your HR operations, generate reports & gain valuable insights to help you make data-driven decisions."
        }
        imgWeb={HRWeb}
        imgMob={HRMobile}
      />
      <AutomateProcess pageData={pageData} />
      <ContactUs pageData={pageData} />
      <HrResponsibilities pageData={pageData} />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home;
