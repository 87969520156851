import React from "react";
import Styles from "./Reusable.module.css";

export const ReusableBtn = ({ text, color }) => {
  return (
    <div className={Styles.alignReusableBtn}>
      <a href="/login" style={{ width: "90%", textDecoration: "none" }}>
        <div className={Styles.reusableBtn} style={{ background: color }}>
          {text}
        </div>
      </a>
    </div>
  );
};
