import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  steps: [], // Array to store steps with IDs and statuses
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    markStepAsComplete(state, action) {
        state.steps = [...state.steps, ...action.payload];
    },
    markStepAsInProgress(state, action) {
        state.steps = [...state.steps,...action.payload];
      },  },
});

export const { markStepAsComplete,markStepAsInProgress } = onboardingSlice.actions;
export const onboardingReducer= onboardingSlice.reducer;
