import React, { useEffect } from 'react'
import DetailForm from './components/DetailForm';
import DetailFormMobile from './components/DetailFormMobile';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceToUpdate } from '../../store/servicesActions';
import ServiceLoader from '../ServiceLoader';

const Details = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const store = useSelector(state => state?.servicesReducer);

    useEffect(() => {
        if (id) {
            dispatch(getServiceToUpdate(id));
        }
    }, [id])

    return (
        <>
            {store?.isLoading && store?.serviceToUpdate.length === 0 ?
                <ServiceLoader />
                : <>
                    <DetailForm />
                    <DetailFormMobile />
                </>
            }
        </>
    )
}

export default Details;