import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";

import { IoArrowBackOutline } from "react-icons/io5";
import Day from "../TimeSlot/day";
import Button from "@mui/material/Button";
import Week from "./RepeatedDay";
import { useHistory, useLocation } from "react-router-dom";
import {
  postSchedule,
  postdateSchedule,
  updateSchedule,
} from "../../services/serviceProvider";
import InputDate from "../InputDate";
import { BiArrowBack } from 'react-icons/bi';

import { FormateDate, success, error } from "../../utils";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import dayjs from "dayjs";
import { SelectedDateContext } from "../../../../context";
import {
  defaultItem,
  editFormatdata,
  formateData,
  formateRepeatData,
  initialValuesofSlot,
  // timeSlotSchema,
  defaultItemDates,
} from "../TimeSlot/helper";
import * as Yup from "yup";
import RepeatDate from "./RepeatedDate";
import RepeatedDay from "./RepeatedDay";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Header from "../../../BusinessProfile/Header";
import ExpiryInputDate from "../InputDate/EpiryInputField";
import { goBack } from "connected-react-router";
import MobileInputDate from "../InputDate/mobileViewindex";

const TimeSlot = ({ route }) => {
  const location = useLocation();
  const history = useHistory();
  const flag = true;
  const [states, setState] = useContext(SelectedDateContext);
  const [activeWeekOptions, setActiveWeekOptions] = useState([]);
  const [activeMonthOptions, setActiveMonthOptions] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [businessId, setBusinessId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setBusinessId(urlParams.get("busiessId"));
    setProviderId(urlParams.get("providerId"));
  }, []);
  const date =
    (location.state &&
      location.state.appointmentData &&
      location.state.appointmentData.date &&
      location.state.appointmentData.date.startDate) ||
    "";
  const editData = (location.state && location.state.appoinment) || "";
  const day = moment(date).day();

  const week = Math.ceil(moment(date).date() / 7);
  const month = Math.ceil(moment(date).month() + 1);
  const action = location?.state?.actionState;
  //handle the state of repeatedDay
  const handleChangeRepeatedDays = (repeatedDays, setFieldValue, values) => {
    if (values.repeatedDates === true) {
      setFieldValue("repeatedDates", !values.repeatedDates);
    }
    if (repeatedDays === true) {
      const item = values.scheduleItems.find((slot) => slot.active);
      setFieldValue("scheduleItems", [item]);
      setFieldValue("startDate", item.date);
      setFieldValue("endDate", item.date);
      setFieldValue("repeatedDays", !repeatedDays);
    } else if (repeatedDays === false) {
      setFieldValue("repeatedDays", !repeatedDays);
    } else {
      setFieldValue("repeatedDays", !repeatedDays);
    }
  };

  //handle the state of repeatedDates
  const handleChangeRepeatedDates = (repeatedDates, setFieldValue, values) => {
    if (values.repeatedDays === true) {
      setFieldValue("repeatedDays", !values.repeatedDays);
    }
    if (repeatedDates === true) {
      const item = values.scheduleItems.find((slot) => slot.active);
      setFieldValue("scheduleItems", [item]);
      setFieldValue("startDate", item.date);
      setFieldValue("endDate", item.date);
      setFieldValue("repeatedDates", !repeatedDates);
    } else if (repeatedDates === false) {
      setFieldValue("repeatedDates", !repeatedDates);
    } else {
      setFieldValue("repeatedDates", !repeatedDates);
    }
  };

  //add timeSlot function
  const handleAddSlot = (setFieldValue, scheduleItems) => {
    const lastSlot = scheduleItems[scheduleItems.length - 1];
    const newSlotDate = new Date(lastSlot.date);
    newSlotDate.setDate(newSlotDate.getDate() + 1);

    const newSlot = {
      date: newSlotDate,
      day: moment(newSlotDate).day() === 0 ? 7 : moment(newSlotDate).day(),
      startTime: "",
      endTime: "",
    };

    setFieldValue("scheduleItems", [...scheduleItems, newSlot]);
  };

  //delete timeSlot function
  const handleDeleteSlot = (setFieldValue, scheduleItems, index) => {
    setFieldValue(
      "scheduleItems",
      scheduleItems.filter((slot, i) => i !== index)
    );
  };

  //timeslot creation
  const createSlot = (values, handles) => {
    setLoading(true);
    const data = formateData(values);
    const providerData = JSON.parse(localStorage.getItem("providerData"));
    const updatedData = {
      ...data,
      businessData: { businessId: parseInt(businessId) }, // Replace the "businessId" with the new value if needed
      providerType: providerData ? providerData.projectId : null, // Replace the "businessId" with the new value if needed
      providerId: parseInt(providerId), // Replace the "providerId" with the new value if needed
      providerName: providerData
        ? providerData.firstName + " " + providerData.lastName
        : "",
      scheduleItems: data.scheduleItems.map((item) => {
        if (item.day === 0) {
          return { ...item, day: 7 };
        }
        return item;
      }),
    };
    const jsonData = JSON.stringify(updatedData);
    postSchedule(jsonData).then((res) => {
      setLoading(false);
      if (res.code === 0) {
        success(res.message);
        setState([...states, values]);

        history.push("/addscheduler");
        handles.resetForm();
      } else {
        error(res.message);
        // handles.setValues(initialValuesofSlot());
      }
    });
  };
  //create repeatDate
  const createRepeatDate = (values, handles) => {
    const data = formateRepeatData(values);
    const providerData = JSON.parse(localStorage.getItem("providerData"));
    const updatedData = {
      ...data,
      businessData: { businessId: parseInt(businessId) }, // Replace the "businessId" with the new value if needed
      providerType: providerData.projectId, // Replace the "businessId" with the new value if needed
      providerId: parseInt(providerId), // Replace the "providerId" with the new value if needed
      providerName: providerData.firstName + " " + providerData.lastName,
    };
    const jsonData = JSON.stringify(updatedData);
    postdateSchedule(jsonData).then((res) => {
      setLoading(false);
      if (res.code === 0) {
        success(res.message);
        setState([...states, values]);
        history.push("/addscheduler");
        handles.resetForm();
      } else {
        error(res.message);
        // handles.setValues(initialValuesofSlot());
      }
    });
  };

  //timeslot updation
  const updateSlot = (values, handles) => {
    setLoading(true);
    const data = editFormatdata(values);
    const jsonData = JSON.stringify(data);
    updateSchedule(jsonData).then((res) => {
      setLoading(false);
      if (res.code === 0) {
        success(res.message);
        setState(
          states.map((slots) =>
            slots && slots.providerId === values.providerId ? values : slots
          )
        );
        history.push("/addscheduler");
        handles.resetForm();
      } else {
        error(res.message);
        handles.resetForm();
      }
    });
  };

  //function handle the submission form
  const handleSubmit = (values, handles) => {
    if (action === "edit") {
      updateSlot(values, handles);
    } else if (values.repeatedDays === true) {
      createSlot(values, handles);
    } else {
      createRepeatDate(values, handles);
    }
  };

  //repeateday functionailty
  const handleUpdateTimeSlots = (
    startDate,
    endDate,
    values,
    setFieldValue,
    errors,
    value
  ) => {
    const items = [];
    const prevItems = [];
    const days = [];

    values.scheduleItems &&
      values.scheduleItems.length > 0 &&
      values.scheduleItems.map((item) => {
        if (item && item.active && item.day && !days.includes(item.day)) {
          prevItems.push(item);
          days.push(item.day);
        }
        return item;
      });
    for (
      let currDate = startDate;
      currDate.isBefore(endDate);
      currDate = currDate.add(1, "day")
    ) {
      let currDay = dayjs(currDate).day();
      let loopIndex = items.length; // Assuming 'items' is the array where you store the loop items
      let currWeek = Math.floor(loopIndex / 7) + 1;

      let currMonth = dayjs(currDate).month() + 1;
      if (days.includes(currDay)) {
        let currItem = prevItems.find((item) => item.day === currDay);
        items.push({
          ...currItem,
          date: currDate,
          week: currWeek,
          month: currMonth,
          active: true,
        });
      } else {
        const item = defaultItem(currDay, currWeek, currDate, currMonth);
        items.push({
          ...item,
          date: currDate,
          week: currWeek,
          day: currDay,
          month: currMonth,
          active: false,
        });
      }
    }
    setFieldValue("scheduleItems", items);
  };

  //repeatedates functionailty
  const handleUpdateDatesTimeSlots = (
    startDate,
    endDate,
    values,
    setFieldValue,
    errors
  ) => {
    const items = [];
    const prevItems =
      values.scheduleItems && values.scheduleItems.length > 0
        ? values.scheduleItems
        : [];

    for (
      let currDate = startDate;
      currDate.isBefore(endDate);
      currDate = currDate.add(1, "day")
    ) {
      let currDayOfMonth = dayjs(currDate).date();
      // let currMonth = dayjs(currDate).month() + 1;
      let loopIndex = items.length; // Assuming 'items' is the array where you store the loop items
      let currMonth = Math.floor(loopIndex / 30) + 1;
      let currItem = prevItems.find(
        (item) => currDayOfMonth === dayjs(item.date).date()
      );
      if (currItem) {
        items.push({
          ...currItem,
          date: currDate,
          month: currMonth,
        });
      } else {
        const item = defaultItemDates(currDate, currMonth);
        items.push({
          ...item,
          date: currDate,
          month: currMonth,
          active: false,
        });
      }
    }
    setFieldValue("scheduleItems", items);
  };
  return (
    <div>
       <div className='row mobile_display'>
                <div className='col-12 d-flex align-items-center service_header'>
                    <div className='container py-1'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-3 ps-1'><BiArrowBack onClick={()=>{
                              history.push(goBack)
                            }} style={{ fontSize: '20px', color: 'gray' }} /></div>
                            <div className='col-7 ps-4'>
                                <h6 className='text-dark p-0'>Schedule 📅</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <div
        className=" ms-2 me-2 ps-1 pe-1 pt-4 hide_scrollbar mobile_display "
        style={{
          height: "100vh",
          overflow: "auto",
          paddingBottom: "180px",
        }}
      >
       
        <Formik
          initialValues={initialValuesofSlot(
            action,
            editData,
            location,
            date,
            day,
            week,
            month
          )}
          onSubmit={(values, handles) => {
            handleSubmit(values, handles);
          }}
          // validationSchema={timeSlotSchema}
        >
          {(options) => {
            const {
              touched,
              values,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
              prevScheduleItems,
              validateForm,
            } = options;
            return (
              <form onSubmit={handleSubmit} className=" ">
                <div className=" mt-3 card2 d-flex justify-content-between">
                  <div className="mt-2 ms-3">
                    <span className="schedule_text_mobile">Create Schedule</span>
                  </div>

                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="d-flex">
                      <div className="padding">
                        <div className="pe-3 pt-2">
                          <MobileInputDate
                            id="start-date"
                            // label="Start date"
                            name="startDate"
                            // minDate={dayjs(date)}
                            format="d MMM YYYY"
                            maxDate={dayjs(values.endDate)}
                            sx={{
                              width: "100%",
                              minWidth:"50px",
                              backgroundColor: "#fff",
                            }}
                            value={dayjs(values.startDate)}
                            helperText={touched.startDate && errors.startDate}
                            onChange={(newValue) => {
                              setFieldValue("startDate", FormateDate(newValue));
                            }}
                          />
                          {<p className="form-error">{errors.startDate}</p>}
                        </div>
                      </div>
                      {/* <div className="time">From</div> */}

                      <div className="mr-4">
                        <div className="">
                          <div className="pe-3 pt-2">
                            <MobileInputDate
                              id="end-date"
                              label="No Expiry"
                              name="endDate"
                              minDate={dayjs(values.startDate)}
                              // maxDate={
                              //   !values.repeatedDates
                              //     ? dayjs(values.startDate).add(30, "day")
                              //     : dayjs(values.startDate).add(365, "day")
                              // }
                              // value={dayjs(values.endDate)}
                              helperText={touched.endDate && errors.endDate}
                              sx={{
                                width: "100%",
                                minWidth:"50px",
                                backgroundColor: "#fff",
                              }}
                              onChange={(newValue) => {
                                // !values.repeatedDates
                                //   ? handleUpdateTimeSlots(
                                //       dayjs(values.startDate),
                                //       newValue,
                                //       values,
                                //       setFieldValue,
                                //       errors
                                //     )
                                //   : handleUpdateDatesTimeSlots(
                                //       dayjs(values.startDate),
                                //       newValue,
                                //       values,
                                //       setFieldValue,
                                //       errors
                                //     );
                                setFieldValue("endDate", FormateDate(newValue));
                              }}
                            />
                            {<p className="form-error">{errors.startDate}</p>}
                          </div>
                        </div>

                        {/* <ErrorMessage name="endDate" component="div" /> */}

                        {/* {<p className="form-error">{errors.endDate}</p>} */}
                      </div>
                    </div>

                    {/* <div className="">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {loading ? (
                            <ClipLoader color="#fffff" size={30} />
                          ) : action === "edit" ? (
                            "UPDATE"
                          ) : (
                            "SAVE"
                          )}
                        </Button>
                      </div> */}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="card2 ">
                    <div className="check-box">
                      <div className="checkboxs">
                        <label
                          style={{
                            fontSize: "12px",

                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={values.repeatedDays}
                            checked={values.repeatedDays}
                            onChange={(e) =>
                              handleChangeRepeatedDays(
                                values.repeatedDays,
                                setFieldValue,
                                values
                              )
                            }
                            style={{
                              margin: "3px 3px 10px 10px",
                            }}
                          />
                          <span className="spanText">Repeated Day</span>
                        </label>
                      </div>
                      <div className="checkboxs">
                        <label
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={values.repeatedDates}
                            checked={values.repeatedDates}
                            onChange={(e) =>
                              handleChangeRepeatedDates(
                                values.repeatedDates,
                                setFieldValue,
                                values
                              )
                            }
                            style={{
                              margin: "3px 3px 10px 10px",
                            }}
                          />
                          <span className="spanText">Repeated Date</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {!values.repeatedDays && !values.repeatedDates ? (
                  <Day
                    validateForm={validateForm}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    date={date}
                    editData={editData}
                    errors={errors}
                    onSubmit={handleSubmit}
                    handleAddSlot={handleAddSlot}
                    handleDeleteSlot={handleDeleteSlot}
                  />
                ) : values.repeatedDays && !values.repeatedDates ? (
                  <RepeatedDay
                    activeWeekOptions={activeWeekOptions}
                    setActiveWeekOptions={setActiveWeekOptions}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleUpdateTimeSlots={handleUpdateTimeSlots}
                    onSubmit={handleSubmit}
                  />
                ) : !values.repeatedDays && values.repeatedDates ? (
                  <RepeatDate
                    prevScheduleItems={prevScheduleItems}
                    activeMonthOptions={activeMonthOptions}
                    setActiveMonthOptions={setActiveMonthOptions}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleUpdateDatesTimeSlots={handleUpdateDatesTimeSlots}
                    values={values}
                    touched={touched}
                    errors={errors}
                    onSubmit={handleSubmit}
                  />
                ) : null}

<div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                    <div className="p-2">
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          history.goBack()
                        }}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="p-2">
                      <Button
                        type="submit"
                        sx={{
                          width: "150px",
                          backgroundColor: "#538dff",
                          height: "40px",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {loading ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : action === "edit" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
              </form>
            );
          }}
        </Formik>
      </div>

      {/* <div className="card px-3 py-2  min-h-[250px] card-cont">
        <div className="card-header position-relative d-flex align-items-center">
          <span className="text ">Time Slots</span>
          {action === "edit" ? (
            <>
              <div className="text2">
                <span className="spanDate">
                  {moment(editData.day).format("dddd")}
                </span>
                <span className="date text-primary">
                  ,{moment(editData.day).format("DD MMM")}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="text2">
                <span className="spanDate">{moment(date).format("dddd")}</span>
                <span className="date text-primary">
                  ,{moment(date).format("DD MMM")}
                </span>
              </div>
            </>
          )}
        </div>
        <Formik
          initialValues={initialValuesofSlot(
            action,
            editData,
            location,
            date,
            day,
            week,
            month
          )}
          onSubmit={(values, handles) => {
            handleSubmit(values, handles);
          }}
          validationSchema={timeSlotSchema}
        >
          {(options) => {
            const {
              touched,
              values,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
              prevScheduleItems,
              validateForm,
            } = options;
            return (
              <form onSubmit={handleSubmit} className="form ">
                <div className="card-body hide_scrollbar card-body-cont">
                  <div className="check-box">
                    <div className="checkboxs">
                      <label
                        style={{
                          fontSize: "12px",

                          display: "flex",
                          flexDirection: "row",
                          marginTop: "25px",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={values.repeatedDays}
                          checked={values.repeatedDays}
                          onChange={(e) =>
                            handleChangeRepeatedDays(
                              values.repeatedDays,
                              setFieldValue,
                              values
                            )
                          }
                          style={{
                            marginBottom: "20px",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        <p className="spanText">Repeated Day</p>
                      </label>
                    </div>
                    <div className="checkboxs">
                      <label
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "25px",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={values.repeatedDates}
                          checked={values.repeatedDates}
                          onChange={(e) =>
                            handleChangeRepeatedDates(
                              values.repeatedDates,
                              setFieldValue,
                              values
                            )
                          }
                          style={{
                            marginBottom: "20px",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        <p className="spanText">Repeated Date</p>
                      </label>
                    </div>
                  </div>
                  {!values.repeatedDays && !values.repeatedDates ? (
                    <Day
                      validateForm={validateForm}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      date={date}
                      editData={editData}
                      errors={errors}
                      onSubmit={handleSubmit}
                      handleAddSlot={handleAddSlot}
                      handleDeleteSlot={handleDeleteSlot}
                    />
                  ) : values.repeatedDays && !values.repeatedDates ? (
                    <RepeatedDay
                      activeWeekOptions={activeWeekOptions}
                      setActiveWeekOptions={setActiveWeekOptions}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleUpdateTimeSlots={handleUpdateTimeSlots}
                      onSubmit={handleSubmit}
                    />
                  ) : !values.repeatedDays && values.repeatedDates ? (
                    <RepeatDate
                      prevScheduleItems={prevScheduleItems}
                      activeMonthOptions={activeMonthOptions}
                      setActiveMonthOptions={setActiveMonthOptions}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleUpdateDatesTimeSlots={handleUpdateDatesTimeSlots}
                      values={values}
                      touched={touched}
                      errors={errors}
                      onSubmit={handleSubmit}
                    />
                  ) : null}
                </div>

                <div className="card-footer">
                  {(values.repeatedDays || values.repeatedDates) && (
                    <div className="d-flex flex-wrap pt-3 justify-content-between">
                      <div className="d-flex">
                        <div className="pe-3">
                          <InputDate
                            id="start-date"
                            label="Start date"
                            name="startDate"
                            // minDate={dayjs(date)}
                            maxDate={dayjs(values.endDate)}
                            sx={{
                              width: 180,
                              backgroundColor: "hwb(187 78% 5%)",
                            }}
                            defaultValue={new Date()}
                            helperText={touched.startDate && errors.startDate}
                            onChange={(newValue) => {
                              setFieldValue("startDate", FormateDate(newValue));
                            }}
                          />
                          {<p className="form-error">{errors.startDate}</p>}
                        </div>
                        <div className="mr-4">
                          <InputDate
                            id="end-date"
                            label="End date"
                            name="endDate"
                            minDate={dayjs(values.startDate)}
                            // maxDate={
                            //   !values.repeatedDates
                            //     ? dayjs(values.startDate).add(30, "day")
                            //     : dayjs(values.startDate).add(365, "day")
                            // }
                            value={dayjs(values.endDate)}
                            helperText={touched.endDate && errors.endDate}
                            sx={{
                              width: 180,
                              backgroundColor: "hwb(187 78% 5%)",
                            }}
                            onChange={(newValue) => {
                              // !values.repeatedDates
                              //   ? handleUpdateTimeSlots(
                              //       dayjs(values.startDate),
                              //       newValue,
                              //       values,
                              //       setFieldValue,
                              //       errors
                              //     )
                              //   : handleUpdateDatesTimeSlots(
                              //       dayjs(values.startDate),
                              //       newValue,
                              //       values,
                              //       setFieldValue,
                              //       errors
                              //     );
                              setFieldValue("endDate", FormateDate(newValue));
                            }}
                          />
                          <ErrorMessage name="endDate" component="div" />

                          {/* {<p className="form-error">{errors.endDate}</p>} */}
    </div>
    //                 </div>

    // <div className="">
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     type="submit"
    //   >
    //     {loading ? (
    //       <ClipLoader color="#fffff" size={30} />
    //     ) : action === "edit" ? (
    //       "UPDATE"
    //     ) : (
    //       "SAVE"
    //     )}
    //   </Button>
    // </div>
    //               </div>
    //             )}
    //           </div>
    //         </form>
    //       );
    //     }}
    //   </Formik>
    // </div> */}
  );
};

export default TimeSlot;
