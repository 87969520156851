import * as Yup from "yup";
export const initialValuesofCustomer = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    nationality:"",
    qualification:""
    // Add more fields and their initial values
  }
 export const validationSchemaofCustomer = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    mobile: Yup.number().required("Mobile is required"),
    // address1: Yup.string().required("Address Line 1 is required"),
    // nationality:Yup.string().required("Nationaility is required"),
    // Add more validations for other fields
  });