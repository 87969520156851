import Login from "../Pages/Auth/Login";
import Password from "../Pages/Auth/Password/Password";
import AddNumber from "../Pages/Auth/Addnumber/Addnumber";
import DeleteAccount from "../Pages/Auth/DeleteUser/index";
import ResetPassword from "../Pages/Auth/Resetpassword/Resetpassword";
import CreatePassword from "../Pages/Auth/Createpassword/Createpassword";
import ChangePassword from "../Pages/Auth/Createpassword/Changepassword";
import ForgetPassword from "../Pages/Auth/Forgetpassword/Forgetpassword";
import ForgetPasswordUser from "../Pages/Auth/Forgetpassword/ForgetPasswordUser";
import SignUpSeller from "../Pages/Seller/Auth/SignUpSeller/SignUpSeller";
import EmailVerfication from "../Pages/Auth/Emailverification/Emailverification";
import AddSecondaryEmail from "../Pages/Auth/Addsecondaryemail/Addsecondaryemail";
import VerifyPrimaryPhone from "../Pages/Auth/Mobileverification/Mobileverification";
import AddSecondaryMobile from "../Pages/Auth/Addsecondarymobile/Addsecondarymobile";
import ProfileInformation from "../Pages/Auth/ProfileInformation/ProfileInformation";
import VerifySecondaryMobile from "../Pages/Auth/Verifysecondarymobile/Verifysecondarymobile";
import SecondaryEmailVerify from "../Pages/Auth/Secondaryemailotpverify/Secondaryemailotpverify";
export const AuthRoutes = [
  {
    path: "/login",
    component: Login,
    ispublic: true,
    exact: true,
  },
  {
    path: "/delete_account",
    component: DeleteAccount,
    ispublic: true,
    exact: true,
  },
  {
    path: "/Password/:email",
    component: Password,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/ForgetPassword/:email",
    component: ForgetPasswordUser,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/Email-verification/:email",
    component: EmailVerfication,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/Profile-information",
    component: ProfileInformation,
    ispublic: false,
    exact: true,
  },
  {
    path: "/create-password",
    component: CreatePassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/reset-user-password",
    component: ChangePassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/add-number",
    component: AddNumber,
    ispublic: false,
    exact: true,
  },
  {
    path: "/verify-number/:number",
    component: VerifyPrimaryPhone,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-secondary-mobile-number",
    component: AddSecondaryMobile,
    ispublic: false,
    exact: true,
  },
  {
    path: "/verify-secondary-mobile-number",
    component: VerifySecondaryMobile,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-secondary-email",
    component: AddSecondaryEmail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-secondary-email-otp-verify",
    component: SecondaryEmailVerify,
    ispublic: false,
    exact: true,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUpSeller,
    ispublic: true,
    exact: true,
  },
];
