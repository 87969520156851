import React, { useState, useEffect } from "react";
// import Styles from "./Header.module.css";
import Styles from "../../../Assets/components/HomePage/Header.module.css";
import { headerLogo, signUp } from "../Assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  cal,
  user,
  layer,
  box,
  userInfo,
  house,
  setting,
  users,
  blCal,
  blBox,
  blhr,
  blLayer,
  color,
} from "../../../Assets/components/HomePage/Assets";
import { pie, layericon } from "../Assets";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StylesContext } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "Store/Auth/actions";
import { handleCalendexDomain } from "utils";
import { getCookiesData } from "utils/helper";

export const Header = () => {
  const history = useHistory();
  const auth = getCookiesData("authtication");
  var userDetail = useSelector((state) => state.Auth.userloginsuccessyasir);
  const tokenExists = localStorage.getItem("token");
  console.log(userDetail, "userDetailuserDetail");
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [logoutMenu, setLogoutMenu] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const encryptURL = (currentURL) => {
    return CryptoJS.AES.encrypt(currentURL, "secret_key").toString();
  };
  const toggleMenu = () => {
    setLogoutMenu(!logoutMenu);
  };
  const handleNavLinkClick = () => {
    const currentURL = window.location.href;
    const encryptedURL = encryptURL(currentURL);
    const redirectObject = {
      encryptedURL: encryptedURL,
      targetURL: "https://login.plexaar.com",
    };

    const redirectURL = `https://login.plexaar.com?redirectInfo=${encodeURIComponent(
      JSON.stringify(redirectObject)
    )}`;
    // Perform redirection
    window.location.href = redirectURL;
    // If you want to use history.push() for redirection uncomment the following line
    // history.push(redirectURL);
  };
  const handleLogout = async () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      // await localStorage.clear();
      dispatch(logout());
      // dispatch(addSelectedBusiness(null));
      history.push("/");
    } else {
      return;
    }
  };

  return (
    <>
      <div className={Styles.headerContainer}>
        <div className={Styles.logoContainer}>
          <Link to="/">
            <img src={headerLogo} alt="Plexaar" />
          </Link>
        </div>
        <div className={Styles.navBarItems}>
          <Link to="/" className={Styles.active}>
            Home
          </Link>
          <Link to="/Aboutus" className={Styles.item}>
            About us
          </Link>
          <Link to="" className={Styles.item}>
            Portfolio
          </Link>
          <Link to="" className={Styles.item}>
            Contact us
          </Link>
          <a
            href=""
            className={Styles.productsMenu}
            onMouseEnter={() => {
              setOpenMenu(true);
            }}
            onMouseLeave={() => {
              setOpenMenu(false);
            }}>
            <p className={Styles.item}>Products</p>{" "}
            <KeyboardArrowDownIcon color="black" />
            {openMenu && (
              <div
                className={Styles.productsList}
                onMouseEnter={() => {
                  setOpenMenu(true);
                }}
                onMouseLeave={() => {
                  setOpenMenu(false);
                }}>
                <div className={Styles.subMenuItem}>
                  {/* <img src={cal} alt="" />
                  <div onClick={() => handleCalendexDomain(auth, userDetail)}>
                    Calendex
                  </div> */}
                </div>

                <hr className={Styles.hrLine} />
                <div className={Styles.subMenuItem}>
                  <img src={user} alt="" />
                  <Link to="/hr-manager">iReach</Link>
                </div>
                <hr className={Styles.hrLine} />

                <div className={Styles.subMenuItem}>
                  <img src={layer} alt="" />
                  <Link to="/finance">Ondel</Link>
                </div>
                <hr className={Styles.hrLine} />

                <div className={Styles.subMenuItem}>
                  <img src={box} alt="" />
                  <Link to="/inventory">Accounts</Link>
                </div>
                <hr className={Styles.hrLine} />

                <div className={Styles.subMenuItem}>
                  <img src={pie} alt="" />
                  <Link to="/finance">Salesplex</Link>
                </div>
                <hr className={Styles.hrLine} />

                <div className={Styles.subMenuItem}>
                  <img src={layericon} alt="" />
                  <Link to="/finance">BuzzCom</Link>
                </div>
              </div>
            )}
          </a>
          {tokenExists ? (
            <div
              // className={`${Styles.userDropdown}  d-flex`}>
              className="d-flex">
              <span
                className={`${Styles.buttonLink} ${Styles.signupbtn} m-0 pe-2`}
                // onClick={handleNavLinkClick}
              >
                {userDetail.firstName} {userDetail.lastName}
              </span>
              <KeyboardArrowDownIcon
                color="black"
                onClick={toggleMenu}
                style={{ cursor: "pointer" }}
              />
              {/* Logout option */}
              {logoutMenu && (
                <div
                  className={Styles.logoutList}
                  onMouseEnter={() => {
                    setLogoutMenu(true);
                  }}
                  onMouseLeave={() => {
                    setLogoutMenu(false);
                  }}>
                  <div className={Styles.subMenuItem}>
                    <img src={cal} alt="" />
                    <span onClick={handleLogout}>Logout</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <button
              className={`${Styles.buttonLink} ${Styles.signupbtn} m-0 pe-2`}
              onClick={handleNavLinkClick}>
              Signup
            </button>
          )}
          <img
            src={signUp}
            alt=""
            className={`${Styles.signupimg} img-fluid`}
          />
        </div>
      </div>

      {/* Mobile Menu */}
      {toggle ? (
        <div className={Styles.mobileMenuExpand}>
          <div className={Styles.userDetail}>
            <div
              className={Styles.closeIcon}
              onClick={() => {
                setToggle(false);
              }}>
              <HighlightOffIcon style={{ fontSize: 45, cursor: "pointer" }} />
            </div>
            <img src={userInfo} alt="" />
          </div>

          <div className={Styles.menuItemsList}>
            <Link to="/">
              <img src={house} style={{ width: "1.12rem", height: "1.2rem" }} />
              <p>Home</p>
            </Link>
            <a>
              <Accordion
                expanded={expanded === "panel1"}
                sx={{ boxShadow: "none", margin: 0, padding: 0 }}
                onChange={handleChange("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ padding: 0, margin: 0 }}>
                  <img
                    src={setting}
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  />

                  <p>Products</p>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                  <div className={Styles.accordionSpacing}>
                    <Link to="/calendex">
                      <img
                        src={blCal}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>Calendex</p>
                    </Link>
                    <Link to="/inventory">
                      <img
                        src={blBox}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>Accounts</p>
                    </Link>
                    <Link to="/finance">
                      <img
                        src={blLayer}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>Salesplex</p>
                    </Link>
                    <Link to="/hr-manager">
                      <img
                        src={blhr}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>iReach</p>
                    </Link>
                    <Link to="/hr-manager">
                      <img
                        src={blBox}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>Ondel</p>
                    </Link>
                    <Link to="/hr-manager">
                      <img
                        src={blCal}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <p>BuzzCom</p>
                    </Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            </a>
            <Link to="">
              <img
                src={users}
                style={{ width: "1.37rem", height: "1.37rem" }}
              />

              <p>Accounts</p>
            </Link>
          </div>
          <button
            className={`${Styles.buttonLink} ${Styles.loginBtn}`}
            onClick={handleNavLinkClick}>
            Login
          </button>
          {/* <Link to={handleNavLinkClick}>
            <div className={Styles.loginBtn}>
              <p>Login</p>
            </div>
          </Link> */}
        </div>
      ) : (
        <div className={Styles.mobileMenu}>
          <Link to="/">
            <img src={headerLogo} alt="" />
          </Link>
          <div
            onClick={() => {
              setToggle(true);
            }}>
            <MenuIcon style={{ fontSize: 45, cursor: "pointer" }} />
          </div>
        </div>
      )}
    </>
  );
};
