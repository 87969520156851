// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  background-color: linear-gradient(90deg, #5599ff 25%, #99c2ff 50%, #d6e7ff 75%);
  /* background-size: 200% 100%; */
  animation: skeleton 1s infinite ease-in-out forwards alternate;
}
@keyframes skeleton {
  0% {
    /* background-position: -200% 0; */
      background-color: #ffffff;
  }
  100% {
    /* background-position: 200% 0; */
      background-color: #dedede;
  }
}
.container {
  width: 100%;
  margin: 0 auto;
}
.header {
  padding: 1rem;
  background-color: #f0f0f0;
  margin-bottom: 1rem;
}
.week-days {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #f0f0f0;
}
.day {
  flex: 1 1;
  padding: 0.5rem;
  text-align: center;
}
.timeslots {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  padding: 0.5rem;
}
.time {
  padding: 0.5rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Calendar/scheduler/components/skeleton.css"],"names":[],"mappings":"AAAA;EACE,+EAA+E;EAC/E,gCAAgC;EAChC,8DAA8D;AAChE;AACA;EACE;IACE,kCAAkC;MAChC,yBAAyB;EAC7B;EACA;IACE,iCAAiC;MAC/B,yBAAyB;EAC7B;AACF;AACA;EACE,WAAW;EACX,cAAc;AAChB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,SAAO;EACP,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;EACR,eAAe;AACjB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".skeleton {\n  background-color: linear-gradient(90deg, #5599ff 25%, #99c2ff 50%, #d6e7ff 75%);\n  /* background-size: 200% 100%; */\n  animation: skeleton 1s infinite ease-in-out forwards alternate;\n}\n@keyframes skeleton {\n  0% {\n    /* background-position: -200% 0; */\n      background-color: #ffffff;\n  }\n  100% {\n    /* background-position: 200% 0; */\n      background-color: #dedede;\n  }\n}\n.container {\n  width: 100%;\n  margin: 0 auto;\n}\n.header {\n  padding: 1rem;\n  background-color: #f0f0f0;\n  margin-bottom: 1rem;\n}\n.week-days {\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem;\n  background-color: #f0f0f0;\n}\n.day {\n  flex: 1;\n  padding: 0.5rem;\n  text-align: center;\n}\n.timeslots {\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  gap: 1px;\n  padding: 0.5rem;\n}\n.time {\n  padding: 0.5rem;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
