// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  width: 100%;
  height: auto;
  padding: 0.9em;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  color: #404145;
  margin-bottom: 1em;
  background: white;
  margin-top: 4em;
}

.nav span h5 {
  font-size: large;
  margin-left: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Calendar/assets/scheduler/css/components/navbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".nav {\n  width: 100%;\n  height: auto;\n  padding: 0.9em;\n  border-bottom: 1px solid #ddd;\n  display: flex;\n  align-items: center;\n  color: #404145;\n  margin-bottom: 1em;\n  background: white;\n  margin-top: 4em;\n}\n\n.nav span h5 {\n  font-size: large;\n  margin-left: 1em;\n  display: flex;\n  align-items: center;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
