/** @format */


export const FilterResources = (props) => {
  // console.log(
  //   props,
  //   "titles,resoucestitles,resoucestitles,resouces"
  // );

  // console.log(props.providerFilter);
  var data = [];
  if (props.providerFilter?.length !== 0 ) {
    data = props.resources.filter((val) => {
      // console.log(val, "datatatatata");

      return props.providerFilter.includes(val.resourceId);
    });
    // console.log(data, "datatatatata");
    return data;
  } else {
    data = props.resources;
    // console.log(data, "dwwwwatatatatata");
    return data;
  }
};
