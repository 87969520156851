
export const businessImageURL = (id) => {
    return process.env.PLEXAAR_BUSINESS_IMAGE_URL+`${id}.jpeg`;
}
export const providerImageURL =  (id) => {
    return process.env.PLEXAAR_PROVIDER_IMAGE_URL+`${id}.jpeg`;
}
export const serviceImageURL =  (id) => {
    return process.env.PLEXAAR_SERVICE_IMAGE_URL+`${id}.webp`;
} 
export const customerImageURL =  (id) => {
    return process.env.PLEXAAR_CUSTOMER_IMAGE_URL+`${id}.jpeg`;
} 
