// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerContainer {
  z-index: 999999 !important;
  margin-top: -5.12vw;
  width: 100%;
  height: 23.06vw;

  background: #548dff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bannerHeading {
  margin-top: 3.66vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 2.41vw;
  line-height: 3vw;
  text-align: center;
  margin-bottom: 0.73vw;

  color: #ffffff;
}
.bannerBoldHeading {
  font-weight: 500;
}
.bannerInfo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 1.09vw;
  line-height: 1.46vw;
  margin-bottom: 2.92vw;
  /* or 133% */

  text-align: center;

  color: #ffffff;
}
.bannerContainer button {
  width: 16.47vw;
  height: 4.09vw;
  background: #8bb2ff;
  border-radius: 0.36vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.31vw;
  line-height: 1.53vw;
  /* identical to box height */
  border: none;
  cursor: pointer;
  color: #ffffff;
}
.bannerContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/WebHomePage/Banner/Banner.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,WAAW;EACX,eAAe;;EAEf,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;;EAErB,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;;EAEZ,kBAAkB;;EAElB,cAAc;AAChB;AACA;EACE,cAAc;EACd,cAAc;EACd,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,4BAA4B;EAC5B,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".bannerContainer {\n  z-index: 999999 !important;\n  margin-top: -5.12vw;\n  width: 100%;\n  height: 23.06vw;\n\n  background: #548dff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.bannerHeading {\n  margin-top: 3.66vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 2.41vw;\n  line-height: 3vw;\n  text-align: center;\n  margin-bottom: 0.73vw;\n\n  color: #ffffff;\n}\n.bannerBoldHeading {\n  font-weight: 500;\n}\n.bannerInfo {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 1.09vw;\n  line-height: 1.46vw;\n  margin-bottom: 2.92vw;\n  /* or 133% */\n\n  text-align: center;\n\n  color: #ffffff;\n}\n.bannerContainer button {\n  width: 16.47vw;\n  height: 4.09vw;\n  background: #8bb2ff;\n  border-radius: 0.36vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.31vw;\n  line-height: 1.53vw;\n  /* identical to box height */\n  border: none;\n  cursor: pointer;\n  color: #ffffff;\n}\n.bannerContainer div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
