import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ChatNotes from "../../../Componenets/ChatNotes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
  // display: "flex",
};

export default function BasicModal({ open, handleClose, bookingData }) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          left:"15%",
          width: "70vw !important",
        }}
      >
        <Box sx={style}>
          <div className=" d-flex justify-content-between px-2">
            <Typography
              id="modal-modal-title"
              className="text-center"
              variant="h6"
              component="h2"
            >
              Create Notes
            </Typography>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                onClick={handleClose}
                role="button"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          {/* Add your custom content or components here */}
          {/* For example, you can uncomment the following line to include the ChatNotes component */}
          <ChatNotes flag={"modal"} bookingData={bookingData} />

          {/* <div className='d-flex'>
            <Button variant='contained' className='m-2' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' className='m-2' onClick={handleClose}>Submit</Button>
          </div> */}
        </Box>
      </Modal>
    </div>
  );
}
