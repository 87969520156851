import { createSlice } from '@reduxjs/toolkit';
import { getAllFolders, getFilesAndFoldersInFolder } from './actions';

const initialState = {
    allFolders: [],
    folderData: [],
    editFolder: null,
    isLoading: false,
    miniLoading: false,
    errorMessage: '',
}

const CreateSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        loadingAction: (state) => {
            state.isLoading = !state?.isLoading;
        },
        enableMiniLoader: (state) => {
            state.miniLoading = true;
        },
        disableMiniLoader: (state) => {
            state.miniLoading = false;
        },
        activeEditFolder: (state, action) => {
            state.editFolder = action.payload;
        },
        nullEditFolder: (state) => {
            state.editFolder = null;
        }
    },
    extraReducers(builder) {
        //Get all services
        builder.addCase(getAllFolders.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getAllFolders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allFolders = [...action.payload];
        })
        builder.addCase(getAllFolders.rejected, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload
        });

        //Get all services
        builder.addCase(getFilesAndFoldersInFolder.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getFilesAndFoldersInFolder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.folderData = [...action.payload];
        })
        builder.addCase(getFilesAndFoldersInFolder.rejected, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload
        })
    }
})

export const { loadingAction, enableMiniLoader, disableMiniLoader, activeEditFolder, nullEditFolder } = CreateSlice.actions;
export const documentReducer = CreateSlice.reducer;