// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Joinus_joinUsContainer__fwtRY {
  width: 100%;
  position: relative;
  margin-top: 40px;
}
.Joinus_joinUsContainer__fwtRY img {
  width: 100%;
}
.Joinus_joinUsTextContainer__ioWou {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  opacity: 0.85;
  background: #fff;
}
.Joinus_textBold__vDfxZ {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
}
.Joinus_textLight__SL9RR {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
}
.Joinus_infoText__\\+lalp {
  margin: 0 auto;
  text-align: center;
  width: 90%;
  color: #545454;
  font-size: 0.85rem;
  font-weight: 300;
  margin-top: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/MobileHomePage/Joinus/Joinus.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".joinUsContainer {\n  width: 100%;\n  position: relative;\n  margin-top: 40px;\n}\n.joinUsContainer img {\n  width: 100%;\n}\n.joinUsTextContainer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 4rem;\n  opacity: 0.85;\n  background: #fff;\n}\n.textBold {\n  font-size: 0.9rem;\n  font-weight: 700;\n  text-align: center;\n  margin: 0;\n  padding: 0;\n  margin-top: 1rem;\n}\n.textLight {\n  font-size: 0.9rem;\n  font-weight: 500;\n  text-align: center;\n  margin: 0;\n  padding: 0;\n}\n.infoText {\n  margin: 0 auto;\n  text-align: center;\n  width: 90%;\n  color: #545454;\n  font-size: 0.85rem;\n  font-weight: 300;\n  margin-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"joinUsContainer": `Joinus_joinUsContainer__fwtRY`,
	"joinUsTextContainer": `Joinus_joinUsTextContainer__ioWou`,
	"textBold": `Joinus_textBold__vDfxZ`,
	"textLight": `Joinus_textLight__SL9RR`,
	"infoText": `Joinus_infoText__+lalp`
};
export default ___CSS_LOADER_EXPORT___;
