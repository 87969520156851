import React, { useEffect } from "react";

const DropDownField = ({
  handleChange,
  id,
  label,
  contentObject,
  renderChildQuestions,
  answer,
  formData,
}) => {
  useEffect(() => {
    handleChange(id, answer);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="float-label">
        <select onChange={(e) => handleChange(id, e.target.value)}>
          <option value="" disabled selected>
            Select an option
          </option>
          {contentObject.options.map((option) => (
            <option
              key={option.id}
              value={option.choiceText}
              selected={formData[id] === option.choiceText}
            >
              {option.choiceText}
            </option>
          ))}
        </select>
        {contentObject.hasOption &&
        contentObject.options.find(
          (opt) => opt.haschild && opt.choiceText === formData[id]
        )
          ? renderChildQuestions(
              contentObject.options.find(
                (opt) => opt.haschild && opt.choiceText === formData[id]
              ).childs
            )
          : null}

        <label forhtml={"1"}>{label}</label>
      </div>
    </>
  );
};

export default DropDownField;
