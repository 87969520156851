import {
  FETCH_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_FAILURE,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
} from './actionType';
import produce from "immer";


const initialState = {
  rowData: [],
  data: [],
  record:null,
  loading: false,
  result:"",
  updatedRecord:"",
  error: null,
};

const Department = produce((state, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        rowData: action.payload,
      };
    case FETCH_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        rowData: [],
        data: [],
        record:null,
        error: action.payload,
      };
      case ADD_DEPARTMENT_REQUEST:
          state.record=action.payload;
        break;

      case ADD_DEPARTMENT_SUCCESS:
          state.result=action.payload;
          break;
      case UPDATE_DEPARTMENT_REQUEST:
            state.updatedRecord=action.payload;
            break;
     case DELETE_DEPARTMENT_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        rowData: state.rowData.filter((department) => department.id !== id),
        error: null,
      };
    }
    case DELETE_DEPARTMENT_FAILURE: {
      const { payload: error } = action;
      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
}, initialState);
export default Department;
