import React from "react";
import { useState } from "react";
import { FormBuilder } from "../form_builder/FormBuilder";
import { FormList } from "../form_list/FormList";
import { QuestionBuilder } from "../question_builder/QuestionBuilder";
import { QuestionList } from "./../question_list/QuestionList";
import SideNavone from '../../../../SideNavBar';
 const HomeLayout = () => {
  const [tab, setTab] = useState(1);

  const [childAddClickObject, setChildAddClickObject] = useState({});
  const [formBuilderQuestions, setFormBuilderQuestions] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formName, setFormName] = useState("");
  const [selectedSection, setSelectedSection] = useState({});
  const [removeFlag, setRemoveFlag] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <SideNavone>
    <div className="container pt-3">
      {/* <div className='row border mb-3'> */}
      <div
        className={`col-lg-2 col-md-2 p-2 text-center btn rounded-0 tab-button ${
          tab === 1 ? "active-tab" : ""
        }`}
        onClick={() => setTab(1)}
      >
        Question Builder
      </div>
      <div
        className={`col-lg-2 col-md-2 p-2 text-center btn rounded-0 tab-button ${
          tab === 2 ? "active-tab" : ""
        }`}
        onClick={() => setTab(2)}
      >
        Form Builder
      </div>
      <div
        className={`col-lg-2 col-md-2 p-2 text-center btn rounded-0 tab-button ${
          tab === 3 ? "active-tab" : ""
        }`}
        onClick={() => setTab(3)}
      >
        All Forms
        {/* </div> */}
      </div>
      <div
        className={`col-lg-2 col-md-2 p-2 text-center btn rounded-0 tab-button ${
          tab === 4 ? "active-tab" : ""
        }`}
        onClick={() => setTab(4)}
      >
        Q & A List
      </div>
      {tab === 1 ? (
        <QuestionBuilder setTab={setTab} />
      ) : tab === 2 ? (
        <FormBuilder
          setTab={setTab}
          childAddClickObject={childAddClickObject}
          setChildAddClickObject={setChildAddClickObject}
          formBuilderQuestions={formBuilderQuestions}
          setFormBuilderQuestions={setFormBuilderQuestions}
          sectionName={sectionName}
          setSectionName={setSectionName}
          sectionData={sectionData}
          setSectionData={setSectionData}
          showModal={showModal}
          setShowModal={setShowModal}
          refresh={refresh}
          setRefresh={setRefresh}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          formName={formName}
          setFormName={setFormName}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          removeFlag={removeFlag}
          setRemoveFlag={setRemoveFlag}
          loading={loading}
          setLoading={setLoading}
        />
      ) : tab === 3 ? (
        <div className="rounded p-1 bg-white">
          <FormList />
        </div>
      ) : tab === 4 ? (
        <div className="col-lg-12 col-md-12">
          <div className="rounded bg-white">
            <QuestionList
            // isRefresh={refreshQuestions} setRefresh={setRefreshQuestions}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
    </SideNavone>
  );
};


export default HomeLayout