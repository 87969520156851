export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";
export const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILURE = "FETCH_ROLE_FAILURE";
export const FETCH_PERMISSION_REQUEST = "FETCH_PERMISSION_REQUEST";
export const FETCH_PERMISSION_SUCCESS = "FETCH_PERMISSION_SUCCESS";
export const FETCH_PERMISSION_FAILURE = "FETCH_PERMISSION_FAILURE";
export const ADD_PERMISSION_REQUEST = "ADD_PERMISSION_REQUEST";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";
export const ALLOW_PERMISSION_REQUEST = "ALLOW_PERMISSION_REQUEST";
export const ALLOW_PERMISSION_SUCCESS = "ALLOW_PERMISSION_SUCCESS";
export const ALLOW_PERMISSION_FAILURE = "ALLOW_PERMISSION_FAILURE";
export const FETCH_SERVICE_ROLE = "FETCH_SERVICE_ROLE";
export const FETCH_SERVICE_ROLE_SUCCESS = "FETCH_SERVICE_ROLE_SUCCESS";
export const FETCH_SERVICE_ROLE_FAILURE = "FETCH_SERVICE_ROLE_FAILURE";
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';