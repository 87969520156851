import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { docs_base_url } from "../../Componenets/base_url";

export const getAllFolders = createAsyncThunk('all-folders', async () => {
    const response = await axios.get(`${docs_base_url}/createFolder/?ownerId=1&parentId=1`);
    return response?.data?.result;
});

export const getFilesAndFoldersInFolder = createAsyncThunk('get-folder-files-and-folders', async (fid) => {
    const response = await axios.get(`${docs_base_url}/getFileFolder/?folderId=${fid}`);
    return response?.data?.result[0]?.folder;
})
