import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { IconButton, Pagination, Stack, Tooltip } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { useHistory } from "react-router-dom";

const TableListMobile = ({ data, handleOpen }) => {
  const [columns, setColumns] = useState([
    { id: 1, name: "Business Name" },
    { id: 2, name: "Type" },
    { id: 3, name: "Industry" },
    { id: 4, name: "Designation" },
  ]);

  const dropdown_columns = [
    { id: 7, name: "Column 1", width: "100px" },
    { id: 8, name: "Column 2", width: "100px" },
    { id: 9, name: "Column 3", width: "100px" },
    { id: 10, name: "Column 4", width: "100px" },
  ];
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnId, setColumnId] = useState("");
  const [isDraggingRow, setIsDraggingRow] = useState(false);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [businessId, setBusinessId] = useState();
  const [rows, setRows] = useState([
    {
      id: 1,
      data: ["", "", "", "", ""],
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const history = useHistory();

  const addColumn = (val) => {
    const newColumn = {
      id: val?.id,
      name: val?.name,
    };
    setColumns([...columns, newColumn]);

    rows.forEach((row) => {
      row.data.push({
        id: val?.id,
        name: "",
      });
    });
    setRows([...rows]);
  };

  const handleRowDragStart = (e, rowIndex) => {
    e.dataTransfer.setData("text/plain", rowIndex);
    setDraggedRowIndex(rowIndex);
  };

  const handleRowDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowDrop = (e, targetRowIndex) => {
    const sourceRowIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
    updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
    setRows(updatedRows);
    setIsDraggingRow(false);
    setDraggedRowIndex(null);
  };

  const handleColumnDragStart = (e, columnIndex) => {
    e.dataTransfer.setData("text/plain", columnIndex);
  };

  const handleColumnDragOver = (e) => {
    e.preventDefault();
  };

  const handleColumnDrop = (e, targetColumnIndex) => {
    const sourceColumnIndex = parseInt(e.dataTransfer.getData("text/plain"));

    const updatedColumns = [...columns];
    const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
    updatedColumns.splice(targetColumnIndex, 0, movedColumn);
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = [...row.data];
      const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
      updatedData.splice(targetColumnIndex, 0, movedData);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  const handleRowCheckboxChange = (e, rowIndex) => {
    const rowId = rows[rowIndex].id;
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const deleteColumn = (columnIndex) => {
    const updatedColumns = columns.filter(
      (col, index) => index !== columnIndex
    );
    setColumns(updatedColumns);

    const updatedRows = rows.map((row) => {
      const updatedData = row.data.filter((_, index) => index !== columnIndex);
      return { ...row, data: updatedData };
    });
    setRows(updatedRows);
  };

  // Calculate the index range of data to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  useEffect(() => {
    if (listData) {
      setRows(() =>
        listData.map((v, i) => {
          return {
            id: v.id,
            serviceId: v?.serviceId,
            hasChild: v?.hasChild,
            data: [
              { id: 1, name: v?.name },
              { id: 2, businessType: null },
              { id: 3, industry: null },
              { id: 4, designation: v?.designation },
            ],
          };
        })
      );
    }
  }, [listData]);

  return (
    <>
      <div
        className="container p-0 hide_scrollbar position-relative pc_display"
        style={{ maxHeight: "75vh", overflow: "scroll" }}
      >
        <div className="row sticky_header">
          <div className="col-12 d-flex align-items-center">
            <h5 className="text-dark mt-3 mb-3 position-sticky">
              Business List
            </h5>
            <Tooltip title="Refresh List">
              <IconButton
                aria-label="delete"
                className="ms-2"
                onClick={() => setBusinessId()}
              >
                <CachedIcon
                  className=""
                  sx={{ fontSize: "30px", color: "#f5bf00" }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="row">
          <table
            className="table table_main_list1_mobile w-100"
            style={{
              marginBottom: "150px",
              // overflowX: 'clip'
            }}
          >
            <thead>
              <tr>
                <th></th>

                {columns.map((column, index) => {
                  if (index < 1) {
                    return (
                      <th id={`sticky_col${column?.id}`} key={column.id}>
                        {column.name}
                      </th>
                    );
                  }

                  return (
                    <th
                      key={column.id}
                      draggable
                      onDragStart={(e) => handleColumnDragStart(e, index)}
                      onDragOver={handleColumnDragOver}
                      onDrop={(e) => handleColumnDrop(e, index)}
                      onMouseOver={() => setColumnId(column.id)}
                      onMouseOut={() => setColumnId("")}
                      className={isDraggingRow ? "dragging-row" : ""}
                      style={{
                        width:
                          (column?.id == 2 && "150px") ||
                          (column?.id == 3 && "80px") ||
                          (column?.id == 4 && "280px") ||
                          (column?.id == 5 && "90px") ||
                          (column?.id == 6 && "80px"),
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        {column.name}

                        <div
                          className="dropdown float-right"
                          style={{
                            visibility:
                              columnId === column.id && column.id !== 0
                                ? "visible"
                                : "hidden",
                            zIndex: "9999",
                            positition: "relative",
                          }}
                        >
                          <button
                            className="btn bg-transparent float-right p-0 m-0"
                            id="dropdownMenuButton"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <MoreVertIcon
                              className="float-right"
                              sx={{ fontSize: "18px" }}
                            />
                          </button>
                          <div
                            className="dropdown-menu px-2 mt-2"
                            aria-labelledby="dropdownMenuButton"
                            onClick={() => deleteColumn(index)}
                          >
                            <DeleteForeverIcon
                              className="text-danger"
                              sx={{ fontSize: "18px" }}
                            />
                            <span
                              className="text-secondary ps-3"
                              style={{ fontSize: "12px" }}
                            >
                              Delete Column
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                  );
                })}
                <th className="text-center" style={{ width: "150px" }}>
                  Action
                </th>
                <th className="addcol_btn_th">
                  <div className="">
                    <button
                      className="btn bg-transparent p-0 m-0"
                      type="button"
                      onClick={() =>
                        history.push("/businessScheduler/columns-list")
                      }
                    >
                      <ControlPointIcon
                        sx={{ color: "gray", margin: "auto", fontSize: "20px" }}
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedRows.length > 0 &&
                paginatedRows.map((row, rowIndex) => {
                  return (
                    <tr
                      key={row.serviceId}
                      draggable
                      onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                      onDragOver={handleRowDragOver}
                      onDrop={(e) => handleRowDrop(e, rowIndex)}
                      className={
                        rowIndex === draggedRowIndex ? "dragging-row" : ""
                      }
                      style={{
                        textAlign: "start",
                      }}
                    >
                      <td>
                        <div className="form-check ps-4 text-center">
                          <input
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            className="form-check-input checkbox_btn"
                            checked={selectedRows.includes(row.id)}
                            onChange={(e) =>
                              handleRowCheckboxChange(e, rowIndex)
                            }
                            style={{
                              background:
                                rowIndex === draggedRowIndex ? " #D3D3D3" : "",
                            }}
                          />
                        </div>
                      </td>

                      {row?.data.length > 0 &&
                        row?.data.map((cellValue, columnIndex) => {
                          return (
                            <>
                              {cellValue.id == 1 && (
                                <td
                                  key={columnIndex}
                                  id={`sticky_col${columnIndex}`}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  // onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
                                >
                                  <span>{cellValue?.name} </span>
                                </td>
                              )}

                              {cellValue.id === 2 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    backgroundColor:
                                      rowIndex === draggedRowIndex
                                        ? "#D3D3D3"
                                        : "",
                                  }}
                                >
                                  <span>{cellValue?.businessType}</span>
                                </td>
                              )}

                              {cellValue.id === 3 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  // className='text-center'
                                  data-toggle={cellValue?.serviceUrl}
                                  data-placement="top"
                                >
                                  {cellValue?.industry}
                                </td>
                              )}

                              {cellValue.id === 4 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  {cellValue?.designation}
                                </td>
                              )}

                              {cellValue.id === 5 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}

                                  // className='text-center'
                                >
                                  <div className="form-check form-switch pt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="flexSwitchCheckDefault"
                                      checked={cellValue?.isActive}
                                    />
                                  </div>
                                </td>
                              )}

                              {cellValue.id === null && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                  // className='text-center'
                                ></td>
                              )}

                              {cellValue.id === 7 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c7
                                </td>
                              )}

                              {cellValue.id === 8 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c8
                                </td>
                              )}

                              {cellValue.id === 9 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c9
                                </td>
                              )}

                              {cellValue.id === 10 && (
                                <td
                                  key={columnIndex}
                                  style={{
                                    background:
                                      rowIndex === draggedRowIndex
                                        ? " #D3D3D3"
                                        : "",
                                  }}
                                >
                                  c10
                                </td>
                              )}
                            </>
                          );
                        })}

                      <td
                        className="text-center"
                        style={{ position: "relative" }}
                      >
                        <button
                          className="border-none add_btn btn-sm my-0 mx-0 py-1 px-3 bg-warning text-light rounded"
                          onClick={(e) => {
                            handleOpen(row.id);
                          }}
                        >
                          Add Scheduler
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row pc_display mt-3">
        <div className="col-12 d-flex justify-content-center">
          <select
            className="items_per_page_select"
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option disabled>Items Per page</option>
            <option value={10} selected>
              10
            </option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={rows?.length}>All</option>
          </select>

          <Stack spacing={2} className="">
            <Pagination
              count={Math.ceil(rows.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default TableListMobile;
