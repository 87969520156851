import { takeLatest, call, put, fork, all } from "redux-saga/effects";
import {
  fetchTeamSuccess,
  fetchTeamFailure,
  deleteTeamFailure,
  deleteTeamSuccess,
} from "./actions";
import {
  ADD_TEAM_FAILURE,
  UPDATE_TEAM_REQUEST,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  DELETE_TEAM_REQUEST,
  FETCH_TEAM_REQUEST,
  UPDATE_TEAM_FAILURE,
  UPDATE_TEAM_SUCCESS,
} from "./actionType";
import { push } from "connected-react-router";
import axiosConfig from "../../Routes/AxiosConfigg";
import { customSnackBar, error, success, warning } from "../../utils";
import { NO_DATA_FOUND, teamList } from "../../utils/constant";
import instance from "../../utils/utils";

function* fetchTeam(action) {
  const { payload: id } = action;
  try {
    // const response = yield axiosConfig.get(
    //   `${process.env.REACT_APP_BASE_URL}/business_svc/pb/team?departmentId=${id}`
    // );
    const response = yield call(
      instance.get,
     ` /business_svc/pb/team?departmentId=${id}`,
    );
    if (response.data.code === 0) {
      if (response.data.result.length === 0) {
        error(NO_DATA_FOUND);
        yield put(fetchTeamFailure(NO_DATA_FOUND));
      } else {
        // success(teamList.TEAM_FETCH);
        yield put(fetchTeamSuccess(response.data.result));
      }
    } else {
      yield put(fetchTeamFailure(response.data.message));
    }
  } catch (error) {
    error(error.response.data.message);

    yield put(fetchTeamFailure(error.message));
  }
}
function* addTeamSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.post("business_svc/pb/team/", data);
    const response = yield call(
      instance.post,
     ` /business_svc/pb/team/`,
     data
    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(teamList.TEAM_CREATE);
      yield put(push('/team'))
      yield put({ type: ADD_TEAM_SUCCESS, payload: response.data.result });
    } else if(response.data.error) {
      warning(response.data.message);
      yield put({ type: ADD_TEAM_FAILURE, payload: response.data.message });
    }else{
    }
  } catch (errors) {
    customSnackBar(errors.response.data.message);


    payload.setLoading(false);
    yield put({ type: ADD_TEAM_FAILURE, payload: "" });
  }
}

function* updateTeamSaga({ payload }) {
  try {
    payload.setLoading(true);
    const data = payload.data;
    // const response = yield axiosConfig.patch(
    //   `business_svc/pb/team/${payload.id}/`,
    //   data
    // );
    const response = yield call(
      instance.patch,
      `business_svc/pb/team/${payload.id}/`,
      data
    );
    payload.setLoading(false);
    if (response.data.code === 0) {
      // success(teamList.TEAM_UPDATED);
      yield put(push('/team'))
      // yield put({ type: UPDATE_TEAM_SUCCESS, payload:response.data.result });
    } else {
      customSnackBar(response.data.message);
      // yield put({ type: UPDATE_TEAM_FAILURE, payload: response.data.message });
    }
  } catch (errors) {
    payload.setLoading(false);
    customSnackBar(errors.response.data.message);
    yield put({ type: UPDATE_TEAM_FAILURE, payload: "" });
  }
}
function* deleteTeamSaga({ payload }) {
  try {
    // Call the delete API function passing the ID
    // const response = yield axiosConfig.delete(
    //   `business_svc/pb/team/${payload.id}/`
    // );
    const response = yield call(
      instance.delete,
      `business_svc/pb/team/${payload.id}/`,
    );
    if (response.data.code === 0) {
      // success(teamList.TEAM_DELETE);
      payload.setRowData(
        payload.rowData.filter((item) => item.id !== payload.id)
      );

      // yield put(deleteTeamSuccess(payload.id));
    } else {
      customSnackBar(response.data.message);
      yield put(deleteTeamFailure(response.message));
    }
  } catch (error) {
    customSnackBar(error.response.data.message);

    yield put(deleteTeamFailure(error.response.data.message));
  }
}
export function* fetchTeamBinder() {
  yield takeLatest(FETCH_TEAM_REQUEST, fetchTeam);
}

export function* addTeamWatcher() {
  yield takeLatest(ADD_TEAM_REQUEST, addTeamSaga);
}
export function* updateTeamWatcher() {
  yield takeLatest(UPDATE_TEAM_REQUEST, updateTeamSaga);
}
export function* deleteTeamWatcher() {
  yield takeLatest(DELETE_TEAM_REQUEST, deleteTeamSaga);
}

export default function* teamSaga() {
  yield all([
    fork(fetchTeamBinder),
    fork(addTeamWatcher),
    fork(updateTeamWatcher),
    fork(deleteTeamWatcher),
  ]);
}
