import React, { useRef } from "react";
import { QuestionList } from "../question_list/QuestionList";
import { useState, useEffect } from "react";
import HeadImage from "../../images/form-head-bg.jpeg";
import uuid from "react-uuid";
import { DropMCQQuestion } from "../widgets/DropMCQQuestion";
import { ModalQuestionList } from "../widgets/ModalQuestionList";
import { saveForm } from "../../service/APIService";
import {
  Autocomplete,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getQuestions } from "./../../service/APIService";
import Carousel from "react-elastic-carousel";

import "../../../../snackbar/snackbar.scss";
import { customSnackBar } from "../../../../utils";
import snackbar from "../../../../snackbar/snackbar";
snackbar.duration = 1000;

const handleDragStart = (e) => e.preventDefault();

export const FormBuilder = ({
  setTab,
  childAddClickObject,
  setChildAddClickObject,
  formBuilderQuestions,
  setFormBuilderQuestions,
  sectionName,
  setSectionName,
  setIsnameSame,
  isnameSame,
  sectionData,
  setSectionData,
  showModal,
  setShowModal,
  refresh,
  setRefresh,
  formTitle,
  setFormTitle,
  formName,
  setFormName,
  selectedSection,
  setSelectedSection,
  removeFlag,
  setRemoveFlag,
  loading,
  setLoading,
}) => {
  // const [childAddClickObject, setChildAddClickObject] = useState({});
  // const [formBuilderQuestions, setFormBuilderQuestions] = useState([]);
  // const [sectionName, setSectionName] = useState('');
  // const [sectionData, setSectionData] = useState();
  // const [showModal, setShowModal] = useState(false);
  // const [refresh, setRefresh] = useState(false);
  // const [formTitle, setFormTitle] = useState('');
  // const [formName, setFormName] = useState('');
  // const [selectedSection, setSelectedSection] = useState({});
  const [questionsList, setQuestionsList] = useState([]);
  const [questionsFilterList, setQuestionsFilterList] = useState([]);
  const [questionStatement, setQuestionStatement] = useState('')
  const [questionType, setQuestionType] = useState("");
  const [sectionDataLength, setSectiondataLength] = useState('');




  // const [loading, setLoading] = useState(false);
  let flag = false;

  useEffect(() => {
    getQuestions("").then(function (response) {
      setQuestionsList(response.data.results);
    });
  }, []);

  useEffect(() => {

    if (sectionData.length === 1) {
      setSelectedSection(sectionData[0]);
    } else if (sectionData.length > 0) {
      setSelectedSection(sectionData[sectionData.length - 1]);
    } else {
      setSelectedSection({});
      setSectionData([
        {
          section: `Section ${sectionData.length + 1}`,
          id: 1,
          questionList: [],
        },
      ]);
      setSectiondataLength(1)
    }
  }, [removeFlag, sectionDataLength]);


  function handleQuestions(param) {
    setFormBuilderQuestions(param);
  }

  async function sendData(data) {
    await saveForm(data);
    console
      .log("data ====> ", data)
      .then(function (response) {
        if (!response.data.error) {
          customSnackBar(response.data.message);
        } else {
          customSnackBar(response.data.message);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function cleanData() {
    setFormTitle("");
    setFormName("");
    setFormBuilderQuestions([]);
    setRefresh(!refresh);
  }
  function handleSaveForm() {
    if (!loading) {
      setLoading(true);
      if (formTitle === "") {
        customSnackBar("please fill all fields");
        setLoading(false);
        return;
      }
      var data = {
        title: formTitle,
        name: formName,
        sectionsList: sectionData,
        published: true
      };
      sendData(data);
      cleanData();
      setLoading(false);

      setSectionData([])
    }
    setTab(3)
  }

  function handleSection() {
    const secData = [
      ...sectionData,
      {
        section: `Section ${sectionData.length + 1}`,
        id: sectionData.length + 1,
        questionList: [],
      },
    ];
    setSectionData(secData);
    setSectiondataLength(sectionData.length + 1)
    setSectionName("");

    setTimeout(() => {
      document.getElementsByClassName('rec-arrow-right')[0].click()
    }, 500);


  }

  function handleSelectedSection(data) {
    setSelectedSection(data);
  }

  const removeSection = (e, id) => {
    e.preventDefault();
    setSectionData(sectionData.filter((ele) => ele.id !== id));
    setRemoveFlag(!removeFlag);
  };



  const data = ["one", "two", "three", "four"];

  let breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 6 },
    { width: 850, itemsToShow: 8 },
    { width: 1150, itemsToShow: 10 },
    { width: 1450, itemsToShow: 12 },
    { width: 1750, itemsToShow: 14 },
  ];

  function handleStatement(e) {
    let questionsListTemp = questionsList;
    let questionsFilterListTemp = questionsListTemp.filter((question, i) => (
      question.contentObject.questionText.toLowerCase().includes(e.target.value.toLowerCase())
    ))
    setQuestionsFilterList(questionsFilterListTemp);
    setQuestionStatement(e.target.value)
  }


  function moveToSelectedSection(e, section) {
    setSelectedSection(section)
    // section.questionList

  }

  return (
    <>
      <div
        className="modal fade modal-lg"
        id="addChildModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body">
              {/* <QuestionList isChildAdd={true} childList={childQuestions} setChildList={setChildQuestions} formQuestionList={formBuilderQuestions} childClickObj={childAddClickObject} /> */}
              {showModal ? (
                <ModalQuestionList
                  refresh={refresh}
                  setFormBuilderQuestions={handleQuestions}
                  formQuestionList={formBuilderQuestions}
                  clickedId={childAddClickObject}
                  showModal={showModal}
                  setModal={setShowModal}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  selectedSection={selectedSection}
                  setSelectedSection={handleSelectedSection}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3">
        <div
          className="row justify-content-center mb-3"
          style={{ background: "#f0f4f5" }}
        >
          <div
            className="col-lg-10 col-md-9 mb-5"
          // style={{ height: "100vh", overflowY: "scroll" }}
          >
            <div className="">
              <div className="bg-white py-4 px-3 mt-3 mb-2">
                <div className="d-flex">
                  <i
                    className="ri-file-list-2-line me-2"
                    style={{ color: "#db0406", fontSize: "22px" }}
                  ></i>
                  <h4 className="mt-2">Form Builder</h4>
                </div>
                <hr />
                <div className="rounded">
                  <input
                    type="text"
                    className="form-control mr-5 input_field_height"
                    value={formTitle}
                    onChange={(e) => setFormTitle(e.target.value)}
                    placeholder=" Form Title"
                  />
                </div>
              </div>
            </div>

            <Carousel itemsToShow={5} breakPoints={breakPoints} showEmptySlots pagination={false}>
              {sectionData.map((item, i) => (
                <div
                  key={i}
                  // onClick={(e) => setSelectedSection(item)}
                  onClick={(e) => moveToSelectedSection(e, item)}
                  className={`text-center btn rounded tab-button mb-2 me-0 ${item.id === selectedSection.id ? "active-tab" : "non-active"
                    }`}
                >
                  <div className="d-flex align-items-center">
                    <h6
                      className="me-3 text-danger"
                      onClick={(y) => removeSection(y, item.id)}
                    >
                      {i > 0 && <i className="fa-solid fa-xmark"></i>}
                    </h6>
                    <h6>
                      Section {item.id}
                      {/* {item.section.length > 10
                      ? `${item.section.slice(0, 10)}...`
                      : item.section} */}
                    </h6>
                  </div>
                </div>
              ))}
            </Carousel>



            {sectionData.length > 0 && (
              <>
                <div className="bg-white rounded">
                  <header className="theme_color_1 p-3">
                    Section {selectedSection.id}
                  </header>
                  <section className="px-4 pt-5 pb-3 mb-4">
                    <input
                      style={{ border: "1px solid lightGray" }}
                      placeholder=" Section Title"
                      className="w-100 px-3 py-4 rounded input_field_height"
                      value={`Section ${selectedSection.id}`}
                    ></input>

                    {/* <div className="text-end my-3">
                      <FormControlLabel
                        value=""
                        control={<Switch color="primary" />}
                        label="Must Required"
                        labelPlacement="start"
                      />
                    </div> */}

                    <div className="d-flex justify-content-between mt-3">
                      <div className="col-lg-8 col-md-8">
                        <div className="me-3">
                          <input
                            type="text"
                            onChange={(e) => handleStatement(e)}
                            value={questionStatement}
                            className="form-control py-3 input_field_height"
                            placeholder="What..."
                          />
                        </div>
                        <div>{questionsFilterList.map((e, i) => (
                          <div
                            onClick={() => {
                              if (Object.keys(selectedSection).length < 1) {
                                customSnackBar('Please Add or Select Section');
                                return;
                              }
                              let flag = true;
                              selectedSection?.questionList?.forEach(element => {
                                if (element.id === e.id) {
                                  customSnackBar("This question is already in Form, Cannot add same question again")
                                  flag = false;
                                }
                              })
                              if (flag) {
                                if ("options" in e.contentObject) {
                                  var temp_obj = e;
                                  temp_obj.isRequired = false
                                  temp_obj.contentObject.options.forEach(element => {
                                    element.id = `${temp_obj.id}.${element.id}`;
                                  });
                                  const temp_selected = selectedSection
                                  temp_selected.questionList = [...selectedSection.questionList, temp_obj]

                                  sectionData.forEach(element => {
                                    if (element.id === temp_selected.id) {
                                      element = temp_selected
                                    }
                                  })
                                  setSelectedSection(temp_selected)
                                  setSectionData([...sectionData])

                                  // setFormBuilderQuestions([...formBuilderQuestions,temp_obj])
                                } else {
                                  var obj = e
                                  obj.isRequired = false;
                                  const temp_selected = selectedSection
                                  temp_selected.questionList = [...selectedSection.questionList, obj]

                                  sectionData.forEach(element => {
                                    if (element.id === temp_selected.id) {
                                      element = temp_selected
                                    }
                                  })
                                  setSectionData([...sectionData])
                                  setSelectedSection(temp_selected)
                                  // setFormBuilderQuestions([...formBuilderQuestions,obj])
                                }

                              }
                              setQuestionsFilterList([])
                              setQuestionType(e?.contentObject?.questionType)
                              setQuestionStatement('')


                            }}
                            className=" border p-2 mt-0 me-3" style={{ cursor: "pointer" }}>
                            <div className="d-flex justify-content-between">
                              <div>{e?.contentObject.questionText}</div>
                              <div>Type: {e?.contentObject.questionType}</div>
                            </div>
                            {e?.contentObject.questionType === "MCQQuestion" ?
                              <div className="d-flex">
                                {e?.contentObject?.options?.map((option, i) => (
                                  <p className="pe-4 pt-2"><i className="ri-checkbox-blank-circle-fill" style={{ color: "lightGray" }}></i> {option.choiceText}</p>
                                ))}</div>
                              :
                              <></>
                            }
                          </div>
                        ))}</div>

                      </div>
                      <div className="form-floating col-lg-4 col-md-4 ">
                        <select
                          disabled={"disabled"}
                          // style={{background: "white"}}
                          className="form-select pt-2 input_field_height bg-white"
                          value={questionType}
                          onChange={(e) => {
                            setQuestionType(e.target.value);
                          }}
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option defaultValue>Question type</option>

                          {/* <option defaultValue>Click to select option</option> */}
                          <option value="SmallTextQuestion">Small Text</option>
                          <option value="LongTextQuestion">Long Text</option>
                          <option value="EmailQuestion">Email</option>
                          <option value="NumberQuestion">Number</option>
                          <option value="DropdownQuestion">Dropdown/Spinner</option>
                          <option value="MCQQuestion">MCQ(Multiple Choice)</option>
                          <option value="DateQuestion">Date</option>
                          <option value="ImageQuestion">Image</option>
                          <option value="DocumentQuestion">Document</option>
                          <option value="SignatureQuestion">Signature</option>
                          <option value="LabelQuestion">Label</option>
                          <option value="StatementQuestion">Statement</option>
                          <option value="VideoQuestion">Video</option>
                          <option value="AudioQuestion">Audio</option>
                        </select>
                        {/* <label htmlFor="floatingSelect">select question type</label> */}
                      </div>
                    </div>
                    {questionsFilterList.length === 0 && questionStatement !== "" &&
                      <button
                        className="btn_theme ms-0 mt-3 py-2"
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => setTab(1)}
                      >
                        <i className="ri-add-circle-line me-2"></i> Add Question
                      </button>}


                    <div className="mb-3">
                      {selectedSection?.questionList?.map((e, index) =>
                        // e.contentObject.questionType === "SmallTextQuestion" ||
                        // e.contentObject.questionType === "LongTextQuestion" ||
                        // e.contentObject.questionType === "NumberTextQuestion" ||
                        e?.contentObject?.questionType === "DropdownQuestion" || e?.contentObject?.questionType === "MCQQuestion" ? (
                          <DropMCQQuestion
                            setRefresh={setRefresh}
                            refresh={refresh}
                            key={uuid()}
                            e={e}
                            index={index}
                            formBuilderQuestions={formBuilderQuestions}
                            setFormBuilderQuestions={setFormBuilderQuestions}
                            setClickOption={setChildAddClickObject}
                            showModal={showModal}
                            setModal={setShowModal}
                            sectionData={sectionData}
                            setSectionData={setSectionData}
                            selectedSection={selectedSection}
                            setSelectedSection={handleSelectedSection}
                          />
                        ) : e?.contentObject?.questionType ===
                          "DateQuestion" ? (
                          <div
                            key={index}
                            className="rounded shadow p-3 bg-white my-2"
                          >
                            <div className="d-flex justify-content-between">
                              <h5>{e?.contentObject?.questionText}</h5>
                              <h5
                                className="btn  text-danger"
                                onClick={() => {
                                  setFormBuilderQuestions(
                                    (formBuilderQuestions) =>
                                      formBuilderQuestions.filter(
                                        (item, i) => i !== index
                                      )
                                  );
                                  selectedSection.questionList =
                                    selectedSection.questionList.filter(
                                      (item, i) => item.id !== e.id
                                    );
                                  handleSelectedSection(selectedSection);
                                  sectionData.forEach((element) => {
                                    if (element.id === selectedSection.id) {
                                      element = selectedSection;
                                    }
                                  });
                                  setSectionData([...sectionData]);
                                }}
                              >
                                <i className="fas fa-minus-square"></i>
                              </h5>
                            </div>

                            {e.contentObject.isDateLimit ? (
                              <div>
                                <h6>
                                  Start Date: {e.contentObject.startDate}
                                </h6>
                                <h6>End Date: {e.contentObject.endDate}</h6>
                              </div>
                            ) : (
                              <></>
                            )}

                            <p className="m-0 p-0 small">
                              Type: <b>{e.contentObject.questionType}</b>{" "}
                            </p>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="rounded shadow p-3 bg-white my-2"
                          >
                            <div className="d-flex justify-content-between">
                              <h5>{e?.contentObject?.questionText}</h5>
                              <h5
                                className="btn  text-danger"
                                onClick={() => {
                                  setFormBuilderQuestions(
                                    (formBuilderQuestions) =>
                                      formBuilderQuestions.filter(
                                        (item, i) => i !== index
                                      )
                                  );
                                  selectedSection.questionList =
                                    selectedSection.questionList.filter(
                                      (item, i) => item.id !== e.id
                                    );
                                  handleSelectedSection(selectedSection);
                                  sectionData.forEach((element) => {
                                    if (element.id === selectedSection.id) {
                                      element = selectedSection;
                                    }
                                  });
                                  setSectionData([...sectionData]);
                                }}
                              >
                                <i className="fas fa-minus-square"></i>
                              </h5>
                            </div>
                            <p className="m-0 p-0 small">
                              Type: <b>{e?.contentObject?.questionType}</b>{" "}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </section>
                </div>
              </>
            )}

            {/* <div className="mt-5">
            <QuestionList
                isRefresh={refresh}
                isFormBuilder={true}
                formBuilderQuestions={formBuilderQuestions}
                setFormBuilderQuestions={setFormBuilderQuestions}
                sectionData={sectionData}
                setSectionData={setSectionData}
                selectedSection={selectedSection}
                setSelectedSection={handleSelectedSection}
              />
              </div> */}


            {/* {sectionData.length > 0 &&
            selectedSection?.questionList?.length > 0 ? (
              <button
                className="btn btn-danger"
                onClick={() => handleSaveForm()}
              >
                {loading ? (
                  <div
                    className="spinner-border text-primary mt-3"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>Save</>
                )}
              </button>
            ) : (
              <></>
            )} */}
          </div>
        </div>
        <div className="d-flex bg-white justify-content-between px-5 py-3 fixed-bottom" style={{ margin: "0rem 2rem 0rem 1.5rem " }}>
          <button
            className="btn_theme"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleSection}
          >
            <i className="ri-add-circle-line me-2"></i> Add Section
          </button>

          <div className="d-flex">
            <button
              className="theme_color_1 border-0 rounded text-danger px-3 py-2"
              style={{ display: "flex", alignItems: "center" }}
            // onClick={handleSection}
            >
              Draft
            </button>
            <button
              className="btn_theme"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "2px 10px",
              }}
              onClick={handleSaveForm}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    </>
  );
};