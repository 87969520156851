import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { fetchIndustryRequest, updateBusinessSuccess } from "../../../../Store/Business/actions";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SideNavBar from "../../../../SideNavBar";

const EditIndustry = ({ set, handleTypeClose, setShowType }) => {
  const dispatch = useDispatch();
  const businessData = useSelector((state) => state.Business.businesProfile);
  const history = useHistory();
  const id = businessData.id;
  const typeList = useSelector((state) => state.Business.industryList);

  const [businessType, setBusinessType] = useState(""); // Initialize with an empty string
  const [loading, setLoading] = useState(false);
    const [selectedOptiosList, setSelectedOptionList] = useState("");
  const [nameError, setNameError] = useState("");

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }
    return "";
  };

  useEffect(() => {
    // setBusinessType(businessData?.industry?.name);
    const fetchData = async () => {
      await dispatch(fetchIndustryRequest());
      // After fetching industry list, set the initial value from business profile
    };
    fetchData();
  }, [dispatch, businessData]);

  useEffect(() => {
    if (
      typeList &&
      typeList.length>0 && businessData &&
      businessData.industry &&
      businessData.industry.id
    ) {
      setTimeout(() => {
        setBusinessType(businessData.industry.id);
      }, 300);
    }
}, [typeList,businessData?.industry?.id]);

    useEffect(() => {
        if (typeList) {
        setSelectedOptionList(typeList);
        }
    }, [typeList]);

  const handleTypeChange = (event) => {
    const value = event.target.value;
    setBusinessType(value);
    setNameError(validateName(value));
  };

  const handleSubmit = () => {
    const data = {
      industry: businessType,
    };
    const action = "editType";

    dispatch(updateBusinessSuccess({ data, setLoading, id, setShowType, action }));
  };

  return (
    <SideNavBar>
      <div
        className="padding_left_right hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Industry Type</label>
          <select
            type="text"
            className="form-control1 changecolor m-0  p-0 "
            id="businessType"
            name="businessType"
            value={businessType}
            onChange={handleTypeChange}
          >
            <option disabled value={""}>
              Select Industry Type
            </option>
            {selectedOptiosList &&
              selectedOptiosList.map((v, i) => {
                return <option key={i} value={v?.id}>{v?.name}</option>;
              })}
          </select>
        </div>
        <div className="w-100 d-flex mt-5 pt-5 flex-wrap justify-content-end ">
          <div className="pe-2">
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#c0c0c0",
                  color: "#ffffff",
                },
              }}
              onClick={() => {
                history.goBack();
              }}
              variant="contained"
              className=" btn btn-secondary padding_next_secondary_button"
            >
              {"Cancel"}
            </Button>
          </div>
          <div>
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#538dff",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={handleSubmit}
              variant="contained"
            >
              {loading ? <ClipLoader color="#fffff" size={30} /> : "Update"}
            </Button>
          </div>
        </div>
      </div>
    </SideNavBar>
  );
};

export default EditIndustry;