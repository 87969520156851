import React, { useEffect, useState } from 'react'

const InventoryTabMenu = ({ handleTabId }) => {
    const [tabId, setTabId] = useState(1);

    useEffect(() => {
        if (tabId) {
            handleTabId(tabId);
        }

    }, [tabId])

    return (
        <>
            <div className='row g-0 bg-transparent mt-3 mb-0 pc_display'>
                <div className='col-12 d-flex align-items-center bg-transparent'>
                    <div className="btn-group tab_menu">
                    <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(1)}
                            style={{ backgroundColor: tabId === 1 ? '#f5bf00' : '', color: tabId === 1 ? 'white' : '' }}
                        >Details</button>
                        <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(2)}
                            style={{ backgroundColor: tabId === 2 ? '#f5bf00' : '', color: tabId === 2 ? 'white' : '' }}
                        >Pricing</button>
                        <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(3)}
                            style={{ backgroundColor: tabId === 3 ? '#f5bf00' : '', color: tabId === 3 ? 'white' : '' }}
                        >Cost</button>
                        <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(4)}
                            style={{ backgroundColor: tabId === 4 ? '#f5bf00' : '', color: tabId === 4 ? 'white' : '' }}
                        >Attributes</button>
                        <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(5)}
                            style={{ backgroundColor: tabId === 5 ? '#f5bf00' : '', color: tabId === 5 ? 'white' : '' }}
                        >Packages</button>
                        <button type="button" className="btn me-sm-2 m-auto text-sm-50" onClick={() => setTabId(6)}
                            style={{ backgroundColor: tabId === 6 ? '#f5bf00' : '', color: tabId === 6 ? 'white' : '' }}
                        >Templates</button>
                    </div>
                </div>
            </div>

            <div className='row g-0 bg-transparent mobile_display'>
                <div className='col-12 d-flex align-items-center bg-transparent'>
                    <div className="btn-group tab_menu_mobile">
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 1 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(1)}
                        >Details</button>
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 2 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(2)}
                        >Pricing</button>
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 3 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(3)}
                        >Cost</button>
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 4 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(4)}
                        >Attributes</button>
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 5 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(5)}
                        >Packages</button>
                        <button type="button" className={`btn me-sm-2 m-auto text-sm-50 ${tabId === 6 ? 'active_tab_mobile' : ''}`} onClick={() => setTabId(6)}
                        >Templates</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InventoryTabMenu