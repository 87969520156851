import React, { useState } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';

const CreateSlotForm = () => {
    const [eventName, setEventName] = useState('');
    const [eventMethod, setEventMethod] = useState('')
    const [eventLink, setEventLink] = useState('')

    return (
        <div className='container slot_form_container'>
            <form className='slot_form'>
                <div className="row slot_form_row">
                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="ename" className="form-label">Event Name</label>
                            <input type="text" className="form-control" placeholder="Enter Event Name"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="method" className="form-label">Method</label>
                            <div className="input-group">
                                <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                    value={eventMethod}
                                    onChange={(e) => setEventMethod(e.target.value)}
                                >
                                    <option value="Meet">Meet</option>
                                    <option value="Teams">Teams</option>
                                    <option value="Skype">Skype</option>
                                    <option value="Zoom">Zoom</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row slot_form_row mt-3">
                    {eventMethod &&
                        <div className="col d-flex">
                            <div className='input_wrapper position-relative'>
                                <label for="meet-link" className="form-label">Meeting Link</label>
                                <input type="text" className="form-control" placeholder="Paste meeting link here..."
                                    value={eventLink}
                                    onChange={(e) => setEventLink(e.target.value)}
                                />
                                <span style={{ position: 'absolute', right: '20px', top: '30%' }}><RiFileCopyLine className='blue_theme' size='24px' /></span>
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}

export default CreateSlotForm