import React, { useState, useEffect } from "react";
import Switch from "./Switch";

const CustomTimePickerComponent = ({ onMouseDown, values, id,setFieldValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAm, setIsAm] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(values);
  const [valueForComparing, setValueForComparing] = useState("");
  const options = [
    "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
  ]
  // const options = [
  //   "12:00",
  //   "12:15",
  //   "12:30",
  //   "12:45",
  //   "01:00",
  //   "01:15",
  //   "01:30",
  //   "01:45",
  //   "02:00",
  //   "02:15",
  //   "02:30",
  //   "02:45",
  //   "03:00",
  //   "03:15",
  //   "03:30",
  //   "03:45",
  //   "04:00",
  //   "04:15",
  //   "04:30",
  //   "04:45",
  //   "05:00",
  //   "05:15",
  //   "05:30",
  //   "05:45",
  //   "06:00",
  //   "06:15",
  //   "06:30",
  //   "06:45",
  //   "07:00",
  //   "07:15",
  //   "07:30",
  //   "07:45",
  //   "08:00",
  //   "08:15",
  //   "08:30",
  //   "08:45",
  //   "09:00",
  //   "09:15",
  //   "09:30",
  //   "09:45",
  //   "10:00",
  //   "10:15",
  //   "10:30",
  //   "10:45",
  //   "11:00",
  //   "11:15",
  //   "11:30",
  //   "11:45",
  // ];

  // useEffect(() => {
  //   const list = values.split(":");
  //   if (list[2] === "am") {
  //     setIsAm(true);
  //   } else {
  //     setIsAm(false);
  //   }
  //   setValueForComparing(`${list[0]}:${list[1]}`);
  // }, [values]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const submittingTime = () => {
    // const value = `${valueForComparing} ${isAm ? "am" : "pm"}`;
    const value = `${valueForComparing}`;

    setIsOpen(false);
    onMouseDown(value, id);
  };

  const handleSwitchButton = () => {
    setIsAm(!isAm);


  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isOpen && !e.target.closest(".dropdown-container")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup function
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      className="dropdown-container"
      id={id}
      name={id}
      style={{
        position: "relative",
        display: "inline-block",
        border: "1px solid #D7D7D7",
        borderRadius: "7px",
      }}
    >
      <div
        className="dropdown-header"
        style={{
          borderRadius: "6px",
          borderRight: "0.6px solid #D7D7D7",
          background: "#FFF",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100px",
          padding: "0",
          cursor: "pointer", // Add cursor pointer
        }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            color: "#585858",
            fontFamily: "Inter",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 600,
            textAlign: "center",
            width: "100%",
          }}
        >
          {values}
        </div>
        <div
          className={`dropdown-icon px-2 ${isOpen ? "open" : ""}`}
          style={{
            borderRadius: "0px 7px 7px 0px",
            background: "#EBEBEB",
            height: "25px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              transition: "transform 0.3s ease",
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon based on dropdown state
            }}
          >
            <path
              d="M1 1L5.1569 5L9 1"
              stroke="#707070"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div
          className="dropdown-options"
          style={{
            padding: "0",
            margin: "0",
            position: "absolute",
            minWidth: "100px",
            maxHeight: "30vh",
            top: "100%",
            left: "0",
            zIndex: "1",
            border: "1px solid #D7D7D7",
            marginTop: "5px",
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          {/* <div className="d-flex justify-content-end"> */}
            {/* <Switch
              onColor="#fff"
              isOn={isAm}
              handleToggle={handleSwitchButton}
            /> */}
            {/* <button
              style={{
                backgroundColor: "#FFD705",
                color: "black",
                padding: "1px 15px",
                border: "none",
                fontSize: "10px",
              }}
              // onClick={submittingTime}
            >
              OK
            </button> */}
          {/* </div> */}
          <hr
            style={{
              margin: "0px 0 0 0",
              padding: "0",
            }}
          />
          <ul
            className="dropdown-options"
            style={{
              listStyle: "none",
              padding: "0",
              margin: "0",
              maxHeight: "25vh",
              overflow: "auto",
              // borderRadius: "8px",
            }}
          >
            {options.map((option, i) => (
              <li
                key={i}
              onClick={submittingTime}
                
                onMouseDown={() => {
                  setValueForComparing(option);
                }}
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  background: option === valueForComparing ? "#5599FF" : "#FFF",
                  color: option === valueForComparing ? "#fff" : "#000",
                  padding: "4px 9px",
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: "12px",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomTimePickerComponent;
