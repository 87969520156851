import ProfilePage from "../Pages/Dashboardpages/Profilepage";
import EditUserName from "../Pages/Dashboardpages/Editusername";
import EditProfileEmail from "../Pages/Dashboardpages/editprofileemail";
import ChangePassword from "../Pages/Dashboardpages/ChangeUserPassword";
import ShowAllNumberOfUsers from "../Pages/Dashboardpages/Showmobilenumbers";
import AddGender from "../Pages/Dashboardpages/addgender";
import AddDOB from "../Pages/Dashboardpages/Adddob";

export const ProfileRoutes = [
  {
    path: "/Profile",
    component: ProfilePage,
    ispublic: false,
    exact: true,
  },
  {
    path: "/change-user-password",
    component: ChangePassword,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-user-name",
    component: EditUserName,
    ispublic: false,
    exact: true,
  },
  {
    path: "/allemailsuser",
    component: EditProfileEmail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/show-all-numbers-of-users",
    component: ShowAllNumberOfUsers,
    ispublic: false,
    exact: true,
  },

  {
    path: "/addgenderuser",
    component: AddGender,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-dob-user",
    component: AddDOB,
    ispublic: false,
    exact: true,
  },
];
