import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Role from "./AddRole";
import { Formik, Form } from "formik";
import Services from "./AddServices";
import Header from "../Header";
import { ClipLoader } from "react-spinners";
import { userList } from "../../../utils/constant";
import { formateData, userInitialValue, validationSchema } from "./helper";
import {
  addUserRequest,
  fetchProductList,
  fetchRoleRequest,
  updateUserRequest,
} from "../../../Store/User/actions";
import AddUserInfo from "./addUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import SideNavone from "../../../SideNavBar";
import { error } from "../../../utils";
import MenuTabList from "../CustomTabs/MenuTabList";
import { MdAdd } from "react-icons/md";
import axios from "axios";
// import "../NewBusiness/AddBusiness/AddBusiness.scss";
import BusinessRole from "./AddBusinessRole";
import { selectRole, selectSwitch } from "./store/action";

const AddUser = () => {
  const flag = true;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const action =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.action;
  const editData =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.data;
  // const businessId =
  //   location &&
  //   location.state &&
  //   location.state.data &&
  //   location.state.data.data;
  const [showPermissions, setShowPermissions] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [showNewRole, setShowNewRole] = useState(false);
  const [showBusinessRole, setShowBusinessRole] = useState(false);
  const [showServices, setShowService] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // Track the selected service ID
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState("");
  const [businessData, setBusiness] = useState("");
  const [createdRoles, setCreatedRoles] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userRoleID, setUserRoleID] = useState([]);
  const [perData, setPerData] = useState("");
  const [data, setData] = useState([]);
  const service = useSelector((state) => state.User?.productList);
  const role = useSelector((state) => state.User?.roles);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  // const [selectedSwitch, setSelectedSwitch] = useState(null);

  const selectedRolese = useSelector((state) => state.roleReducer.selectedRole);
  const selectedSwitch = useSelector(
    (state) => state.roleReducer.selectedSwitch
  );
  const businessId = businessData.id;
  useEffect(() => {
    // Retrieve the data from local storage when the component mounts
    const business = localStorage.getItem("businessRecord");
    const userID = localStorage.getItem("userRoleID");
    if (business) {
      setBusiness(JSON.parse(business));
      setUserRole(JSON.parse(userID));
      // setUserRole(userRoleID.map((role) => role.id));
    }
  }, []);
  useEffect(() => {
    if(businessId){
    dispatch(fetchRoleRequest(businessId));
    dispatch(fetchProductList(businessId));
    }
  }, [businessId]);

  const addUser = (values, handles) => {
    const data = formateData(values, businessId, selectedRolese);
    if (selectedRolese.length < 0) {
      error("Create Role against Business");
    } else {
      dispatch(
        addUserRequest({
          data,
          setLoading,
          businessId,
        })
      ); // Dispatch the userRequest action
    }
  };
  const handleRoleCreated = (role) => {
    setCreatedRoles((prevRoles) => [...prevRoles, role]);
  };
  const updateUser = (values, handle) => {
    let id = editData.id;
    const data = formateData(values, businessId, selectedRolese, action);
    dispatch(updateUserRequest({ data, setLoading, id, businessId }));
  };
  const submitForm = (values, handles, result) => {
    if (action === "edit") {
      updateUser(values, handles, result);
    } else {
      addUser(values, handles);
    }
  };
  const handleAddNewRole = () => {
    setShowNewRole(true);
    setSelectedOptions([]);
  };
  const handleAddNewBusinessRole = () => {
    setShowBusinessRole(true);
  };
  const handleCloseBusinessRole = () => {
    setShowBusinessRole(false);
  };
  const handleCloseNewRole = () => {
    setShowNewRole(false);
  };

  const handleCloseSevices = () => {
    setShowService(false);
  };
  useEffect(() => {

    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.data.data
    ) {
      const result = location.state.data && location.state.data.data;

      const roles = [];
      result.BusinessRoles &&
        result.BusinessRoles.length > 0 &&
        result.BusinessRoles.map((obj) => {
          if (obj && obj.id && (obj.business || obj.business === null)) {
            roles.push({
              productId: obj.business,
              roleId: obj.id,
            });
          }
          return obj;
        });
      result.ServiceRoles &&
        result.ServiceRoles.length > 0 &&
        result.ServiceRoles.map((obj) => {
          if (obj && obj.id && obj.service && obj.service.id) {
            roles.push({
              productId: obj.service.id,
              roleId: obj.id,
            });
          }
          return obj;
        });


      setSelectedRole(roles);
      const rolesArr = roles.map((obj) => obj.roleId);
      dispatch(selectRole(rolesArr));
      const switchArr = roles.map((obj) => obj.productId);
      dispatch(selectSwitch(switchArr));
    }
    return () => {
      dispatch(selectRole([]));
      dispatch(selectSwitch([]));
    };
  }, [location.state]);

  /*   useEffect(() => {

    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.data.data
    ) {
      const result = location.state.data && location.state.data.data;

      const roles = [];
      result &&
        result.length > 0 &&
        result.map((obj) => {
          if (
            obj &&
            obj.id &&
            (obj.business || (obj.service && obj.service.id))
          ) {
            roles.push({
              productId:
                obj.service && obj.service.id
                  ? obj.service.id
                  : obj.business
                  ? obj.business
                  : "",
              roleId: obj.id,
            });
          }
          return obj;
        });

      setSelectedRole(roles);
      const rolesArr = roles.map((obj) => obj.roleId);
      dispatch(selectRole(rolesArr));
    }
    return () => {
      dispatch(selectRole([]));
    };
  }, [location.state]); */
  const handlePermissionClick = () => {
    setShowPermissions(!showPermissions);
  };
  const handleChangeCheckBox = (currentIndex, data) => {
    if (selectedSwitch.includes(data.id)) {
      dispatch(selectSwitch(selectedSwitch.filter((id) => id !== data.id)));
    } else {
      dispatch(selectSwitch([...selectedSwitch, data.id]));
    }
    setIsChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      newChecked[currentIndex] = !newChecked[currentIndex];

      if (newChecked[currentIndex]) {
        setSelectedOption(data);
        setPerData((prevPerData) => [...prevPerData, data]); // Append the data to perData
      } else {
        setSelectedOption((prevSelectedOption) => {
          // Remove the data from selectedOption
          if (prevSelectedOption === data) {
            return [];
          }
          return prevSelectedOption;
        });
        setPerData((prevPerData) =>
          prevPerData.filter((item) => item !== data)
        ); // Remove the data from perData
      }
      return newChecked;
    });
  };

  const handleRoles = (roleId, productId, setSelectedRole) => {

    const isProductExist = selectedRoles.find(
      (obj) => obj.productId && productId && obj.productId === productId
    );


    if (isProductExist) {
      const updatedSelectedRoles = selectedRoles.map((obj) => {
        if (obj.productId === productId && obj.roleId === roleId) {
          return { ...obj, roleId: "" };
        } else if (obj.productId === productId) {
          return { ...obj, roleId: roleId };
        } else return obj;
      });
      setSelectedRoles(updatedSelectedRoles);
      const rolesArr = updatedSelectedRoles.map((obj) => obj.roleId);

      setSelectedRole(rolesArr.includes(roleId) ? roleId : "");

      dispatch(selectRole(rolesArr));
    } else {
      // // Check the clicked role and add it to the array
      setSelectedRole(roleId);
      const updatedSelectedRoles = [
        ...selectedRoles,
        { roleId: roleId, productId: productId },
      ];
      setSelectedRoles(updatedSelectedRoles);
      dispatch(selectRole(updatedSelectedRoles.map((obj) => obj.roleId)));
    }
  };

  return (
    <>
      {showServices ? <Services onClose={handleCloseSevices} /> : null}
      {showNewRole ? (
        <Role
          businessData={businessId}
          service={data}
          action={action}
          selectedOptions={selectedOption}
          onClose={handleCloseNewRole}
          onRoleCreated={handleRoleCreated}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
      ) : null}
      {showBusinessRole ? (
        <BusinessRole
          businessData={businessId}
          service={data}
          action={action}
          selectedOptions={selectedOption}
          onClose={handleCloseBusinessRole}
          onRoleCreated={handleRoleCreated}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
      ) : null}
      {!showNewRole && !showServices && (
        <>
          <SideNavone>
            <Header
              flag={flag}
              heading={action === "edit" ? "Update User" : userList.CREATE_USER}
            />
            {/* <MenuTabList type="addUser" /> */}

            <div
              className="padding_left_right pt-4 hide_scrollbar "
              style={{
                height: "100vh",
                overflow: "auto",
                paddingBottom: "180px",
              }}
            >
              {" "}
              <div className="  w-100">
                <Formik
                  initialValues={userInitialValue(action, editData)}
                  onSubmit={(values) => {
                    submitForm(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {(options) => {
                    const { handleSubmit } = options;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <AddUserInfo
                          handleAddNewRole={handleAddNewBusinessRole}
                          role={role}
                          action={action}
                          userRoleID={userRoleID}
                          setUserRoleID={setUserRoleID}
                          handleRoles={handleRoles}
                          selectedRolese={selectedRolese}
                          selectedRole={selectedRole}
                          setSelectedRole={setSelectedRole}
                          {...options}
                        />
                        {/* {action === "edit" ? ( */}
                        <Services
                          service={service}
                          handleChangeCheckBox={handleChangeCheckBox}
                          isChecked={isChecked}
                          showNewRole={showNewRole}
                          role={role}
                          businessId={businessId}
                          perData={perData}
                          createdRoles={createdRoles}
                          selectedSwitch={selectedSwitch}
                          handleAddNewRole={handleAddNewRole}
                          showPermissions={showPermissions}
                          handlePermissionClick={handlePermissionClick}
                          selectedOption={selectedOption}
                          handleRoles={handleRoles}
                          selectedRole={selectedRole}
                          setSelectedRole={setSelectedRole}
                        />
                        {/* ) : null} */}

                        <div className="p-2 pt-3">
                          <div className="d-flex ">
                            <span className="span small">
                              You are Allowing the below user to access your
                              account to perform action on your behalf
                            </span>
                          </div>
                          <div className="d-flex  flex-wrap">
                            <label
                              style={{
                                fontSize: "13px",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={{}}
                                checked={{}}
                                className="lager"
                                onChange={() => {}}
                                // disabled={weekday === "Sunday"}
                                style={{
                                  marginBottom: "20px",
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              />
                              I agree to the{" "}
                              <span className="bold"> Term of the Service</span>
                            </label>
                          </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end  ">
                          <div className="p-2">
                            <Button
                              type="submit"
                              sx={{
                                width: "150px",
                                backgroundColor: "#538dff",
                                height: "40px",
                                borderRadius: "10px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleSubmit} // Update the onClick handler
                              variant="contained"
                              disabled={loading}
                            >
                              {loading ? (
                                <ClipLoader color="#fffff" size={30} />
                              ) : action === "edit" ? (
                                "Update"
                              ) : (
                                "Invite"
                              )}{" "}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </SideNavone>
        </>
      )}
    </>
  );
};

export default AddUser;
