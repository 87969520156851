import React, { useState } from 'react';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

const Topbar = ({ renderViewFunc }) => {
    const [view, setView] = useState(1);

    return (
        <div className='d-flex justify-content-between align-items-center topbar_main px-2'>
            <div className='sec1 d-flex'>
                <img src='./meetspace/moon1.png' className='topbar_logo' />
                <span className='d-flex align-items-center ms-5'>
                    <h4 className='p-0 m-0'>Events</h4>
                    <img src='./meetspace/calendar1.png' alt='' className='ms-2 h-50' />
                </span>
            </div>

            <div className='sec2 d-flex filter_sec'>
                <div className="input-group esearch_wrapper">
                    <div className="input-group-prepend">
                        <span className={`input-group-text esearch_span h-100`} id="basic-addon1"><SearchIcon sx={{ fontSize: '20px', color: '#aaaaaa' }} /></span>
                    </div>
                    <input type="text" className="form-control h-100 disable_box_shadow" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
                <div className="input-group efilter_wrapper me-3">
                    <div className="input-group-prepend">
                        <span className={`input-group-text efilter_span h-100`} id="basic-addon1"><img src='./meetspace/keyfilter1.png' style={{ height: '17px', width: 'auto' }} /></span>
                    </div>
                    <input type="text" className="form-control h-100 disable_box_shadow" placeholder="Filter" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
                <button className='btn btn-primary add_event_btn ms-2 p-1'>
                    <b>+ Create Event</b>
                </button>

                <div className='ms-4 me-3 d-flex align-items-center view_type'>
                    <Button variant={view === 2 ? `text` : ''} className='ms-3'
                        onClick={() => {
                            setView(2);
                            renderViewFunc(2);
                        }}
                    >
                        <WindowOutlinedIcon sx={{ fontSize: '20px' }} />
                    </Button>
                    <Button variant={view === 1 ? `text` : ''} className='ms-3'
                        onClick={() => {
                            setView(1);
                            renderViewFunc(1);
                        }}
                    >
                        <FormatListBulletedOutlinedIcon sx={{ fontSize: '20px' }} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Topbar