import React, { useState, useContext, useEffect } from "react";
import Hero from "../Hero/Hero";
import ContactUs from "../ContactUs/ContactUs";
import { HomePageContent } from "../../static/Content";
import { useParams } from "react-router-dom";
import AutomateProcess from "../AutomateProcess/AutomateProcess";
import HrResponsibilities from "../HrResponsibilities/HrResponsibilities";
import { Header } from "./../../../../Hero/Header";

const ReadMoreButtonsPages = ({ vol }) => {
  const [pageData, setPageData] = useState([]);
  const { readMorePages } = HomePageContent;
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const filtered = readMorePages.filter((val) => val?.id == id);
      setPageData(filtered);
    }
  }, [id]);

  return (
    <div>
      {/* <Hero pageData={pageData} /> */}
      <Header
        title={"iReach"}
        heading={"Enrich Your Workforce with AI-Driven HRMS"}
        text={
          "Manage your workforce, streamline your HR operations, generate reports & gain valuable insights to help you make data-driven decisions."
        }
      />
      <AutomateProcess pageData={pageData} />
      <ContactUs pageData={pageData} />
      <HrResponsibilities pageData={pageData} />
    </div>
  );
};

export default ReadMoreButtonsPages;
