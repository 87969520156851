import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomerDynamicForm } from '../../Pages/Dashboard/MainComponent/store/staffActions';
import SideNavone from '../../SideNavBar';

const DynamicFormCustomer=() =>{
    const dispatch=useDispatch()
  const store = useSelector((state) => state?.staffReducer);

    const business = useSelector((state) => state.Business?.selectedBusiness);
    const bussinessId = business?.id;
    const [dynamicForm,setDyanmicForm]=useState([])

    const [loading,setIsLoading]=useState(false)
    // const parsedResponse = JSON.parse(dynamicForm);

    useEffect(() => {
        const fetchData = async () => {
          try {
            
            setIsLoading(true);
    
              // Dispatch the async thunk and wait for it to complete
              await dispatch(
                getAllCustomerDynamicForm({
                  id: bussinessId,
                  
                  setIsLoading,
                })
              );
// setDyanmicForm(store)
              setIsLoading(false);
           
          } catch (error) {
            // Handle errors if needed
            console.error("Error:", error);
          } finally {
          }
        };
    
        fetchData();
      }, [
        bussinessId,
        dispatch,
        setIsLoading
       
      ]);
  return (
    <SideNavone>
    <div>DynamicFormCustomer</div>
    </SideNavone>
  )
}

export default DynamicFormCustomer