import React, { useState, useEffect } from "react";
import Styles from "./InputField.module.css";
import { AiOutlineAudio } from "react-icons/ai";
import { uploadMedia } from "../../axios/axios";
const AudioQuestion = ({
  text,
  onInputChange,
  id,
  formId,
  inputType,
  section,
  icon,
  title,
}) => {
  return (
    <div className={Styles.uploadContainer}>
      <p>{text}</p>
      <label htmlFor="uploadImage" className={Styles.uploadImage}>
        <div>{icon}</div>
        <p>Upload {title}</p>
      </label>
      <input type="file" id="uploadImage" hidden multiple accept="file" />
    </div>
  );
};

export default AudioQuestion;
