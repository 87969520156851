import { all } from "redux-saga/effects";
import authSaga from "./Auth/saga";
import AdviserSaga from "./Adviser/saga";
import businessSaga from "./Business/saga";
import departmentSaga from "./Department/saga";
import teamSaga from "./Team/saga";
import userSaga from "./User/saga";
import staffSaga from "./Staff/saga";

export default function* rootSaga() {
  yield all([authSaga(), AdviserSaga(),departmentSaga(), businessSaga(), teamSaga(), userSaga(),staffSaga()]);
}
