import React from "react";
import Header from "../HeaderAdmin/HeaderAdmin.js";
import SessionBooking from "../BookNextVisit/BookNextSession/BookNextSession.jsx";
import SideNavone from "../../../SideNavBar";

export default function sessionBooking() {
  return (
    <div>
      <SideNavone>
        <SessionBooking />
      </SideNavone>
    </div>
  );
}
