import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, IconButton, Tooltip } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaRegEdit } from 'react-icons/fa';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const EventsGView = (props) => {

  return (
    <div className='container events_grid_container'>
      <div className='row grid_view_row'
      >
        {props?.data.length > 0 && props?.data.map(v => {
          return (
            <div className='col-sm-6 col-md-4 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
            >
              <Card className='w-100' sx={{ width: '95%', borderRadius: '15px', border: '1px solid lightgray' }}
              >
                <CardActionArea className='px-3 py-2'
                >
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex flex-column img_sec'>
                      <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                      <span className='d-flex justify-content-center ms-2 name_sec'>
                        <GroupOutlinedIcon sx={{ height: '30px', width: '30px', color: '#6ca7ff' }} />
                        <span className='d-flex align-items-center ms-2'>
                          <p className='p-0 m-0' style={{ color: '#6ca7ff', fontSize: '12px' }}>9ur484</p>
                        </span>
                      </span>
                    </div>

                    <div className="dropdown" style={{ positition: 'relative' }}>
                      <Tooltip title={`Action`}>
                        <IconButton aria-label="" size="large" className='p-3'
                          id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          sx={{ height: '30px', width: '30px', zIndex: '0' }}
                        >
                          <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                        </IconButton>
                      </Tooltip>
                      <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                        style={{ position: 'relative', zIndex: '4' }}
                      >
                        <div className='py-1'
                        >
                          <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Rename</span>
                        </div>
                        <div className='py-1'
                        >
                          <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                          />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CardContent className='px-0' >
                    <Typography variant="body2" color="text.secondary">
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                          <AccessAlarmOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                          <span className='ms-2'>30 mins</span>
                        </div>
                        <div className='d-flex'>
                          <AccessTimeOutlinedIcon sx={{ fontSize: '18px' }} />
                          <span className='ms-2'>09:00 AM-06:00 PM</span>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EventsGView