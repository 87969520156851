import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, IconButton, Tooltip } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaRegEdit } from 'react-icons/fa';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useSelector } from 'react-redux';

const AllEventsGView = ({allEventsList}) => {
  return (
    <div className='container-fluid main_container mt-4'>
      <div className='row grid_view_row'
        style={{ maxHeight: '80vh', overflow: 'scroll' }}
      >
        {/* static code start */}
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl1.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl3.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl5.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl4.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl6.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
        >
          <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
          >
            <CardActionArea className='px-3 py-2'
            >
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex img_sec'>
                  <img className='rounded-circle' src='./meetspace/cl1.png' style={{ height: '50px', width: '50px' }} />
                  <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                    <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                    <p className='p-0 m-0 _id'>9ur484</p>
                  </span>
                </div>

                <div className="dropdown" style={{ positition: 'relative' }}>
                  <Tooltip title={`Action`}>
                    <IconButton aria-label="" size="large" className='p-3'
                      id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      sx={{ height: '30px', width: '30px', zIndex: '0' }}
                    >
                      <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                    </IconButton>
                  </Tooltip>
                  <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                    style={{ position: 'relative', zIndex: '4' }}
                  >
                    <div className='py-1'
                    >
                      <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Rename</span>
                    </div>
                    <div className='py-1'
                    >
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                      />
                      <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>

              <CardContent className='px-0' >
                <Typography variant="body2" color="text.secondary">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                      <span className='ms-2'>12 events</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>Active</p>
                      <div className="form-check form-switch switch_btn_div pt-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        // onChange={(e) => {
                        //   changeStatus(row?.serviceId, e.target.checked)
                        // }}
                        // checked={cellValue?.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        {/* static code end */}

        {allEventsList.map(v => {
          return (
            <div className='col-sm-6 col-md-3 col-12 pe-1 mb-3 mb-md-4 d-flex justify-content-center'
            >
              <Card className='w-100' sx={{ maxWidth: 345, borderRadius: '15px', border: '1px solid lightgray' }}
              >
                <CardActionArea className='px-3 py-2'
                >
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex img_sec'>
                      <img className='rounded-circle' src='./meetspace/cl6.png' style={{ height: '50px', width: '50px' }} />
                      <span className='d-flex flex-column justify-content-center ms-2 name_sec'>
                        <h6 className='p-0 m-0 _name'>Ayesha Riaz</h6>
                        <p className='p-0 m-0 _id'>9ur484</p>
                      </span>
                    </div>

                    <div className="dropdown" style={{ positition: 'relative' }}>
                      <Tooltip title={`Action`}>
                        <IconButton aria-label="" size="large" className='p-3'
                          id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          sx={{ height: '30px', width: '30px', zIndex: '0' }}
                        >
                          <MoreVertIcon className='' sx={{ fontSize: '20px', zIndex: '0' }} />
                        </IconButton>
                      </Tooltip>
                      <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                        style={{ position: 'relative', zIndex: '4' }}
                      >
                        <div className='py-1'
                        >
                          <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Rename</span>
                        </div>
                        <div className='py-1'
                        >
                          <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }}
                          />
                          <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                          >Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CardContent className='px-0' >
                    <Typography variant="body2" color="text.secondary">
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                          <TuneOutlinedIcon className='text-secondary' sx={{ fontSize: '18px' }} />
                          <span className='ms-2'>12 events</span>
                        </div>

                        <div className='d-flex flex-column'>
                          <p className='p-0 m-0'>Active</p>
                          <div className="form-check form-switch switch_btn_div pt-0">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                            // onChange={(e) => {
                            //   changeStatus(row?.serviceId, e.target.checked)
                            // }}
                            // checked={cellValue?.isActive}
                            />
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AllEventsGView