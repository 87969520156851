import AppointmentDetail from "../Calendar/scheduler/layouts/bookingDetails";
import OrderDetail from "../Calendar/scheduler/layouts/orderDetails";
import EventsDetail from "../Calendar/scheduler/layouts/eventDetails";
import UpdateBooking from "../Calendar/scheduler/layouts/updateBooking";
import AddPayment from "../Pages/Payment-react/AddPayment";
import EditEventAndBreak from "../Calendar/scheduler/bookingDetails/editeventandbreak";
import CreateEvent from "../Calendar/scheduler/layouts/CreateEvents";
import CreateBooking from "../Calendar/scheduler/layouts/createBooking";
import CreateTimeBlock from "../Calendar/scheduler/layouts/CreateTimeBlock";
// import CreateBreak from "../Calendar/scheduler/layouts/CreateBreaks";
import CreateBreak from "../Pages/Break";
import Notes from "../Pages/Notes";
import Chat from "../Pages/Chat";
// import AppointmentDetail from "../Calendar/scheduler/AppointmentDetails/AppointmentDetails";

export const BookingRoutes = [
  {
    path: "/scheduler/create_event",
    component: CreateEvent,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/create_break_event_timeblock",
    component: CreateBreak,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/create_timeblock",
    component: CreateTimeBlock,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/create_booking",
    component: CreateBooking,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/booking_details/:id",
    component: AppointmentDetail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/events_details/:id",
    component: EventsDetail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/edit_events",
    component: EditEventAndBreak,
    ispublic: false,
    exact: true,
  },
  {
    path: "/scheduler/booking_update/:id",
    component: UpdateBooking,
    ispublic: false,
    exact: true,
  }, 
  {
    path: "/scheduler/order_details",
    component: OrderDetail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-payment",
    component: AddPayment,
    ispublic: false,
    exact: true,
  },
  {
    path: "/note",
    component: Notes,
    ispublic: false,
    exact: true,
  },
  {
    path: "/chat",
    component: Chat,
    ispublic: false,
    exact: true,
  },
];
