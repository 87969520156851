import React, {useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendnewprimaryemailotp,
  sendotopemail,
} from "../../../Store/Auth/actions";
import "../Login/Login.scss";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Password(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState(null);

  const [time, setTime] = useState(60); // Initial time in seconds (1 minute)
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingresend, setIsLoadingresend] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendCount, setResendCount] = useState(0); // Track resend button clicks
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.Auth?.registersuccessId);
  const accountNumber = useSelector((state) => state.Auth?.forgetPassword?.result?.accountNumber);
  console.log(accountNumber,"accountNumberaccountNumber")
  let { email } = useParams();
  const history = useHistory();
  const otpInputRef = useRef(null); // Create a ref for the OTP input

  useEffect(() => {
    // otpInputRef.current.focus()
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setIsActive(false);
            return 60; // Reset the time to 1 minute
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isActive]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleSubmit = () => {
    dispatch(
      sendotopemail({
        otpCode: otp,
        email: email,
        userId: userid,
        setIsLoading,
        setOtp,
        accountNumber
      })
    );
  };

  const resetOpt = () => {
    setOtp(""); // Reset 'otp' to an empty value or any other desired initial value
  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);
  const resendotponcallprimaryemail = async() => {
    await handleReCaptchaVerify();
if(token){
    dispatch(
      resendnewprimaryemailotp({
        id: userid,
        type: 1,
        setIsActive,
        raptchaToken: token?token:"string",

        setIsLoadingresend,
      })
    );
    setResendCount((prevCount) => prevCount + 1); // Increment resend count
    // Adjust time based on resend count
    if (resendCount === 0) {
      setTime(60); // 1 minute for the first resend
    } else if (resendCount === 1) {
      setTime(300); // 5 minutes for the second resend
    } else if (resendCount >= 2) {
      setTime(3600); // 1 hour for subsequent resends
    }
  }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter" && otp.length === 6 && validateOTP(otp)) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const validateOTP = (otp) => {
    const otpRegex = /^[0-9]*$/;
    return otpRegex.test(otp);
  };

  return (
    <div className="px-0">
      <div className="text-center">
        <p className="mb-1 pt-4 having_problrm">Having problem?</p>
        {isActive ? (
          <p className="mb-0 resend_button_disabled">Resend Code</p>
        ) : (
          <p
            className="mb-0 resend_button"
            onClick={() => {
              resetOpt();
              resendotponcallprimaryemail(); // Call your function
            }}
          >
            Resend Code
          </p>
        )}
        {isLoadingresend ? (
          <div
            className="spinner-border spinner-sm text-primary"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : null}
        <p className=" having_problrm">
          {" "}
          {isActive ? <div>{formatTime()}</div> : null}{" "}
        </p>
      </div>
    </div>
  );
}

