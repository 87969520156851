import React, { useState } from "react";
import BusinessMap from "../../Business/BusinessMap";
import SideNavBar from "../../../../SideNavBar";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { formateUpdateBusinessAddress } from "../../Business/helper";
import { addBusinessAddressesRequest } from "../../../../Store/Business/actions";
import BusinessAddressForm from "./BusinessAddressEditForm";

const BusineeAddressMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
const [loading,setLoading]=useState(false)
  const [showNext, setNextStep] = useState(false);
  const handleNext = () => {
    setNextStep(true);

  };
  const handleNextClose = () => {
    setNextStep(false);
  };
  
  return (
    <SideNavBar>
      {showNext && (
        <BusinessAddressForm
          handleNextClose={handleNextClose}
         
        />
      )}
      <div
        className="ps-5 pe-5 hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <BusinessMap
        handleNext={handleNext}
        loading={loading}
         />
        
      </div>
    </SideNavBar>
  );
};

export default BusineeAddressMap;
