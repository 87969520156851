import React, { useEffect, useState } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, MenuItem, TextField } from "@mui/material";
import Select from '@mui/material/Select';

const OneToOneMeeting = () => {
    const [eventName, setEventName] = useState('');
    const [eventMethod, setEventMethod] = useState('');
    const [eventLink, setEventLink] = useState('');
    const [admin, setAdmin] = useState('');
    const [bookingLimit, setBookingLimit] = useState('');
    const [slotDuration, setSlotDuration] = useState('');
    const [slotBuffers, setSlotBuffers] = useState('');
    const [breaksArr, setBreaksArr] = useState(['0']);

    useEffect(() => {
        setBreaksArr(breaksArr);
    }, [breaksArr])

    return (
        <div className='container slot_form_container'>
            <form className='slot_form'>
                <div className="row slot_form_row">
                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="ename" className="form-label">Event Name</label>
                            <input type="text" className="form-control" placeholder="Enter Event Name"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="admin" className="form-label">User</label>
                            <div className="input-group">
                                <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                    value={admin}
                                    onChange={(e) => setAdmin(e.target.value)}
                                >
                                    <option value="1">Admin A</option>
                                    <option value="2">Admin B</option>
                                    <option value="3">Admin C</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row slot_form_row mt-3">
                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="blimit" className="form-label">Booking Limit</label>
                            <div className="input-group">
                                <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                    value={bookingLimit}
                                    onChange={(e) => setBookingLimit(e.target.value)}
                                >
                                    <option value="Meet">10</option>
                                    <option value="Teams">20</option>
                                    <option value="Skype">30</option>
                                    <option value="Zoom">40</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className='input_wrapper'>
                            <label for="method" className="form-label">Method</label>
                            <div className="input-group">
                                <select className="form-select form-select border-0" aria-label=".form-select-lg example"
                                    value={eventMethod}
                                    onChange={(e) => setEventMethod(e.target.value)}
                                >
                                    <option value="Meet">Meet</option>
                                    <option value="Teams">Teams</option>
                                    <option value="Skype">Skype</option>
                                    <option value="Zoom">Zoom</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row slot_form_row mt-3">
                    <div className="col d-flex">
                        <div className='input_wrapper position-relative'>
                            <label for="meet-link" className="form-label">Meeting Link</label>
                            <input type="text" className="form-control" placeholder="Paste meeting link here..." name="sku"
                                value={eventLink}
                                onChange={(e) => setEventLink(e.target.value)}
                            />
                            <span style={{ position: 'absolute', right: '20px', top: '30%' }}><RiFileCopyLine className='blue_theme' size='24px' /></span>
                        </div>
                    </div>
                </div>

                <div className="row slot_form_row mt-3">
                    <div className="col col-6">
                        <div className='input_wrapper'>
                            {/* <label for="admin" className="form-label"></label> */}
                            <div className="input-group labeless_input h-100 w-100">
                                <input type="date" className="form-control" placeholder="Paste meeting link here..." name="sku"
                                    value={eventLink}
                                    onChange={(e) => setEventLink(e.target.value)}
                                />

                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker className='h-100'/>
                                </LocalizationProvider> */}
                            </div>
                        </div>
                    </div>

                    <div className="col col-3">
                        <div className='input_wrapper mui_input_wrapper'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Start time"
                                    // value={parseTime(weekDay[index].businessStartTime)}
                                    // onChange={(newValue) =>
                                    //     handleChangeStartTime(index, newValue)
                                    // }
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="col col-3">
                        <div className='input_wrapper mui_input_wrapper'>
                            <LocalizationProvider dateAdapter={AdapterDateFns} className='h-100'>
                                <TimePicker
                                    label="End time"
                                    className='h-100'
                                    // value={parseTime(weekDay[index].businessStartTime)}
                                    // onChange={(newValue) =>
                                    //     handleChangeStartTime(index, newValue)
                                    // }
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div className="row slot_form_row mt-3">
                    <div className="col col-6">
                        <div className='input_wrapper mui_input_wrapper'>
                            <Select
                                className='w-100 h-100'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={slotDuration}
                                label="Slot Duration"
                                onChange={(e) => setSlotDuration(e.target.value)}
                            >
                                <MenuItem disabled>Slot Duration</MenuItem>
                                <MenuItem value={10} selected>10 min</MenuItem>
                                <MenuItem value={20}>20 min</MenuItem>
                                <MenuItem value={30}>30 min</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div className='input_wrapper mui_input_wrapper'>
                            <Select
                                className='w-100 h-100'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={slotBuffers}
                                label="Buffers"
                                onChange={(e) => setSlotBuffers(e.target.value)}
                            >
                                <MenuItem disabled>Buffers</MenuItem>
                                <MenuItem value={1} selected>Buffers 1</MenuItem>
                                <MenuItem value={2}>Buffers 2</MenuItem>
                                <MenuItem value={3}>Buffers 3</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>

                <div classSName="row slot_form_row">
                    <div className="col-12 mt-4">
                        <h5 >Add Breaks</h5>
                    </div>
                </div>

                {breaksArr.map((v, i) => {
                    return (
                        <>
                            <div className="row slot_form_row mt-3" key={i}>
                                <div className="col col-3">
                                    <div className='input_wrapper mui_input_wrapper'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                label="Start time"
                                                // value={parseTime(weekDay[index].businessStartTime)}
                                                // onChange={(newValue) =>
                                                //     handleChangeStartTime(index, newValue)
                                                // }
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <div className='input_wrapper mui_input_wrapper'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                label="Start time"
                                                // value={parseTime(weekDay[index].businessStartTime)}
                                                // onChange={(newValue) =>
                                                //     handleChangeStartTime(index, newValue)
                                                // }
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className='col d-flex align-items-center'>
                                    <button
                                        type='button'
                                        className='add_breaks_btn me-3'
                                        onClick={() => {
                                            let p = i + 1;
                                            breaksArr.splice(p, 0, (breaksArr.length + 1).toString());
                                            setBreaksArr([...breaksArr]);
                                        }}
                                    ><b>+</b></button>
                                    <button
                                        type='button'
                                        className='delete_breaks_btn'
                                        style={{ opacity: breaksArr.length == 1 ? '.5' : '1' }}
                                        onClick={() => {
                                            if (breaksArr.length !== 1) {
                                                breaksArr.splice(i, 1);
                                                setBreaksArr([...breaksArr]);
                                            }
                                        }}
                                    ><b>-</b></button>
                                </div>
                            </div>
                        </>
                    );
                })}

            </form>
        </div>
    )
}

export default OneToOneMeeting;