import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton, Pagination, Stack, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { FaRegEdit } from 'react-icons/fa';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { useHistory } from 'react-router-dom';

const NewList = ({ data, loading }) => {
    const [columns, setColumns] = useState([
        { id: 1, name: 'Department Name' },
        { id: 2, name: 'ID' },
        { id: 3, name: 'Number Of Teams' },
        { id: 4, name: 'Active' },
    ]);

    const dropdown_columns = [
        { id: 7, name: 'Column 1', width: '100px' },
        { id: 8, name: 'Column 2', width: '100px' },
        { id: 9, name: 'Column 3', width: '100px' },
        { id: 10, name: 'Column 4', width: '100px' },
    ];
    const [listData, setListData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [columnId, setColumnId] = useState('');
    const [isDraggingRow, setIsDraggingRow] = useState(false);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [businessId, setBusinessId] = useState();
    const [rows, setRows] = useState([
        {
            id: 1, data: ['', '', '', '', '', '']
        },
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const history = useHistory();
    const addColumn = (val) => {
        const newColumn = {
            id: val?.id,
            name: val?.name,
        };
        setColumns([...columns, newColumn]);

        rows.forEach((row) => {
            row.data.push({
                id: val?.id,
                name: '',
            });
        });
        setRows([...rows]);
    };

    const handleRowDragStart = (e, rowIndex) => {
        e.dataTransfer.setData('text/plain', rowIndex);
        setDraggedRowIndex(rowIndex);
    };

    const handleRowDragOver = (e) => {
        e.preventDefault();
    };

    const handleRowDrop = (e, targetRowIndex) => {
        const sourceRowIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const updatedRows = rows.filter((row, index) => index !== sourceRowIndex);
        updatedRows.splice(targetRowIndex, 0, rows[sourceRowIndex]);
        setRows(updatedRows);
        setIsDraggingRow(false);
        setDraggedRowIndex(null);
    };

    const handleColumnDragStart = (e, columnIndex) => {
        e.dataTransfer.setData('text/plain', columnIndex);
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
    };

    const handleColumnDrop = (e, targetColumnIndex) => {
        const sourceColumnIndex = parseInt(e.dataTransfer.getData('text/plain'));

        const updatedColumns = [...columns];
        const movedColumn = updatedColumns.splice(sourceColumnIndex, 1)[0];
        updatedColumns.splice(targetColumnIndex, 0, movedColumn);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = [...row.data];
            const movedData = updatedData.splice(sourceColumnIndex, 1)[0];
            updatedData.splice(targetColumnIndex, 0, movedData);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    const handleRowCheckboxChange = (e, rowIndex) => {
        const rowId = rows[rowIndex].id;
        if (e.target.checked) {
            setSelectedRows([...selectedRows, rowId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        }
    };

    const deleteColumn = (columnIndex) => {
        const updatedColumns = columns.filter((col, index) => index !== columnIndex);
        setColumns(updatedColumns);

        const updatedRows = rows.map((row) => {
            const updatedData = row.data.filter((_, index) => index !== columnIndex);
            return { ...row, data: updatedData };
        });
        setRows(updatedRows);
    };

    // Calculate the index range of data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRows = rows.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };


    useEffect(() => {
        if (data) {
            setListData(data);
        }
    }, [data]);

    useEffect(() => {
        if (listData.length > 0) {
            setRows(() => listData.map((v, i) => {
                return (
                    {
                        id: v.id,
                        data: [{ id: 1, name: v?.name }, { id: 2, bid: v?.id }, { id: 3, numTeams: v?.numTeams }, { id: 4, status: v?.business?.status }]
                    })
            }))
        }
    }, [listData]);


    return (
        <>
            <div className="container-fluid p-0 px-5 hide_scrollbar position-relative pc_display" style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <div className='row'>
                    <table className="table table_main_list w-100" style={{
                        marginBottom: '150px',
                        // overflowX: 'clip'
                    }}>
                        <thead>
                            <tr>
                                <th>
                                </th>

                                {columns.map((column, index) => {
                                    if (index < 1) {
                                        return (
                                            <th
                                                id={`sticky_col${column?.id}`}
                                                key={column.id}
                                            >{column.name}</th>
                                        );
                                    }

                                    return (
                                        <th
                                            key={column.id}
                                            draggable
                                            onDragStart={(e) => handleColumnDragStart(e, index)}
                                            onDragOver={handleColumnDragOver}
                                            onDrop={(e) => handleColumnDrop(e, index)}

                                            onMouseOver={() => setColumnId(column.id)}
                                            onMouseOut={() => setColumnId('')}
                                            className={isDraggingRow ? 'dragging-row' : ''}

                                            style={{
                                                width:
                                                    column?.id == 2 && '200px' ||
                                                    column?.id == 3 && '200px' ||
                                                    column?.id == 4 && '100px' ||
                                                    column?.id == 5 && '100px'
                                            }}
                                        >
                                            <div className='d-flex justify-content-center'>
                                                {column.name}
                                            </div>
                                            <div className="dropdown float-right" style={{ visibility: columnId === column.id && column.id !== 0 ? 'visible' : 'hidden', zIndex: '9999', position: 'absolute', right: '10px', top: '10px' }}>
                                                <button className="btn bg-transparent float-right p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <MoreVertIcon className='float-right' sx={{ fontSize: '18px' }} />
                                                </button>
                                                <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton" onClick={() => deleteColumn(index)}>
                                                    <DeleteForeverIcon className='text-danger' sx={{ fontSize: '18px' }} /><span className='text-secondary ps-3' style={{ fontSize: '12px' }}>Delete Column</span>
                                                </div>
                                            </div>
                                        </th>
                                    );
                                })}
                                <th style={{ width: '150px' }}>
                                    Action
                                </th>
                                <th className='addcol_btn_th'>
                                    <div className="">
                                        <button className="btn bg-transparent p-0 m-0" type="button"
                                            onClick={() => history.push('department/columns-list')}
                                        >
                                            <ControlPointIcon sx={{ color: 'gray', margin: 'auto', fontSize: '20px' }} />
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {paginatedRows.length > 0 && paginatedRows.map((row, rowIndex) => {
                                return (<tr
                                    key={row.serviceId}
                                    draggable
                                    onDragStart={(e) => handleRowDragStart(e, rowIndex)}
                                    onDragOver={handleRowDragOver}
                                    onDrop={(e) => handleRowDrop(e, rowIndex)}
                                    className={rowIndex === draggedRowIndex ? 'dragging-row' : ''}
                                >
                                    <td>
                                        <div className="form-check ps-4 text-center">
                                            <input type="checkbox" value="" id="flexCheckDefault"
                                                className="form-check-input checkbox_btn"
                                                checked={selectedRows.includes(row.id)}
                                                onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                                                style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                            />
                                        </div>
                                    </td>

                                    {row?.data.length > 0 && row?.data.map((cellValue, columnIndex) => {
                                        return (
                                            <>
                                                {cellValue.id == 1 &&
                                                    <td key={columnIndex}
                                                        id={`sticky_col${columnIndex}`}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    // onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <span>{cellValue?.name}</span>
                                                        </div>
                                                    </td>
                                                }

                                                {cellValue.id === 2 &&
                                                    <td key={columnIndex}
                                                        style={{ backgroundColor: rowIndex === draggedRowIndex ? '#D3D3D3' : '' }}
                                                    >
                                                        <span>{cellValue?.bid}</span>
                                                    </td>
                                                }

                                                {cellValue.id === 3 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        className='text-center'
                                                        data-toggle={cellValue?.serviceUrl} data-placement="top"
                                                    >
                                                        {cellValue?.numTeams}
                                                    </td>
                                                }

                                                {cellValue.id === 4 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        <div className="text-light text-center"
                                                        >
                                                            <div className="form-check form-switch switch_btn_div blue_theme_checkbox pt-1">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                                    checked={cellValue?.status == 'active' ?? false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                }

                                                {cellValue.id === 5 &&
                                                    <td key={columnIndex}
                                                        className='text-center p-0 m-0'
                                                    >


                                                    </td>
                                                }

                                                {cellValue.id === null &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                        className='text-center'

                                                    >
                                                    </td>
                                                }

                                                {cellValue.id === 7 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}

                                                    >
                                                        c7
                                                    </td>
                                                }

                                                {cellValue.id === 8 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        c8
                                                    </td>
                                                }

                                                {cellValue.id === 9 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        c9
                                                    </td>
                                                }

                                                {cellValue.id === 10 &&
                                                    <td key={columnIndex}
                                                        style={{ background: rowIndex === draggedRowIndex ? ' #D3D3D3' : '' }}
                                                    >
                                                        c10
                                                    </td>
                                                }

                                            </>
                                        )
                                    })}

                                    <td className='text-center'
                                        style={{ position: 'relative' }}
                                    >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div className="dropdown" style={{ positition: 'relative' }}>
                                                <button className="btn bt-info p-0 m-0" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    style={{ position: 'relative', zIndex: '0' }}
                                                >
                                                    <MoreVertIcon className='float' sx={{ fontSize: '18px', zIndex: '99' }} />
                                                </button>
                                                <div className="dropdown-menu px-2 mt-2" aria-labelledby="dropdownMenuButton"
                                                    style={{ position: 'relative', zIndex: '4' }}
                                                >
                                                    <div className='py-1'
                                                    >
                                                        <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                                                        <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                        >Delete</span>
                                                    </div>
                                                    <div className='py-1'
                                                    >
                                                        <FaRegEdit className='text-warning' sx={{ fontSize: '18px' }} />
                                                        <span className='text-secondary ps-3' style={{ fontSize: '12px' }}
                                                        >Edit</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div >
            </div>

            <div className='row pc_display blue_theme_pagination mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <select
                        className='items_per_page_select'
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option disabled>Items Per page</option>
                        <option value={10} selected>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={rows?.length}>All</option>
                    </select>

                    <Stack spacing={2} className=''>
                        <Pagination
                            count={Math.ceil(rows.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                        />
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default NewList;
