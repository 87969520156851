import { toast } from "react-toastify";
import { get, post } from "../../../../utils/requests";
import {
  getDynamicFormSuccessfully,
  storeConsentFormIdSuccessfully,
  submitDynamicFormsSuccessfully,
} from "../actionDispatches/formDispatch";
import {
  activateLoader,
  deactivateLoader,
} from "../actionDispatches/systemDispatch";
import instance from "../../../../utils/utils";
// import { BASE_URL } from "Pages/FormCreation/utils/Constants";
// const getFormsById = (response, params, customerId) => {
//   let api;
//   console.log("This is the res For FORMID", res);
//   return (dispatch) => {
//     dispatch(activateLoader());
//     console.log("IN");
//     if (params.section) {
//       api = `/genericform_svc/pb/get_form?section=${params.section}&formId=${response.data.result.consentForm.formIds[0]}&customerId=${customerId}`;
//     } else {
//       api = `/genericform_svc/pb/get_form?formId=${response.data.result.consentForm.formIds[0]}&customerId=${customerId}`;
//     }
//     instance
//       .get(api)
//       .then((res) => {
//         dispatch(getDynamicFormSuccessfully(res));
//       })
//       .catch((err) => {
//         toast.error(`${err.message}`);
//       })
//       .finally(() => {
//         dispatch(deactivateLoader());
//       });
//   };
// };

const baseURL =
  "https://plexaargateway-staging.findanexpert.net/genericform_svc/pb";

// Hitting end point to get data for dynamic forms
export const getDynamicForms = (params, customerId, serviceSKU) => {
  return (dispatch) => {
    dispatch(activateLoader());
    let api = `/serviceinventory_svc/pb/Service/GetServiceConsentForm?serviceSKU=${serviceSKU}`;
    instance
      .get(api)
      .then((response) => {
        console.log("TRIGGER .THEN");
        dispatch(testFunction(response, params, customerId));
        // dispatch((response, params, customerId) =>
        //   getFormsById(response, params, customerId)
        // );
      })
      .catch((err) => {
        toast.error(`${err.message}`);
      })
      .finally(() => {
        console.log("ENDED");
        dispatch(deactivateLoader());
      });
  };
};

const testFunction = (response, params, customerId) => {
  console.log("TEST FUNCTION HAS BEEN TRIGGERED");

  let api;
  console.log("This is the res For FORMID", response);
  return (dispatch) => {
    dispatch(activateLoader());
    console.log("IN");
    if (params.section) {
      api = `/genericform_svc/pb/get_form?section=${params.section}&formId=${response.data.result.consentForm.formIds[0]}&customerId=${customerId}`;
    } else {
      api = `/genericform_svc/pb/get_form?formId=${response.data.result.consentForm.formIds[0]}&customerId=${customerId}`;
    }
    instance
      .get(api)
      .then((res) => {
        dispatch(() => getDynamicFormSuccessfully(res));
        dispatch(storeConsentFormIdSuccessfully(response));
        console.log("FORM DATA HAS BEEN RETURNED");
      })
      .catch((err) => {
        toast.error(`${err.message}`);
      })
      .finally(() => {
        dispatch(deactivateLoader());
      });
  };
};

// Hitting end point to post data for dynamic forms
export const submitDynamicForms = (data) => {
  return (dispatch) => {
    dispatch(activateLoader());
    instance
      .post(`/genericform_svc/pb/add/answers/bulk/`, data)
      .then(() => {
        toast.success("Form submitted successfully");
        dispatch(submitDynamicFormsSuccessfully());
      })

      .catch((err) => {
        toast.error(`${err.response.data.message}`);
      })
      .finally(() => {
        dispatch(deactivateLoader());
      });
  };
};
