import { Padding } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const InsertStaffColumn = ({ data, updateData }) => {
  // console.log(data,updateData,"updateDataupdateData")
  const handleColumnClick = (columnName) => {
    // setCols((prev) => [...prev, columnName]);
    updateData((prevData) =>
      prevData.map((columnData) => {
        if (columnData.columnName === columnName) {
          return { ...columnData, isShown: !columnData.isShown };
        }
        return columnData;
      })
    );
  };
  return (
    <Grid
      container
      bgcolor="#FFF"
      border="#DCDCDC"
      //   px={2}
      sx={{ border: "1px solid #DCDCDC", borderRadius: "8px" }}
      xs={12}
    >
      {data.map((columnData, index) => {
        const { columnName, isShown } = columnData;
        const borderColor = isShown ? "#fff" : "#ffc720";
        const bgColor = isShown ? "#ffc720" : "#fff";
        const color = isShown ? "#000" : "#A4A4A4";

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            p={1}
            key={columnData.columnName}
          >
            <Box
              onClick={() => handleColumnClick(columnData.columnName)}
              sx={{
                border: `1px solid ${borderColor}`,
                borderRadius: "8px",
              }}
              component="div"
              bgcolor={bgColor}
              height="36px"
              minWidth="205px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography sx={{ color: color }}>
                {" "}
                {columnData.columnName}{" "}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InsertStaffColumn;
