import React, { useEffect, useRef, useState } from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import SelectInvite from './SelectInvite';
import CreateSlotForm from './CreateSlotForm';
import OneToOneMeeting from './OneToOneMeeting';


const MultiStepper = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [visitedSteps, setVisitedSteps] = useState([0]);

    const step2Validator = () => {
        // Implement your validation logic
        return true; // Return true for now
    };

    const step3Validator = () => {
        // Implement your validation logic
        return true; // Return true for now
    };

    const handleStepClick = (stepIndex) => {
        if (visitedSteps.includes(stepIndex)) {
            setCurrentStep(stepIndex);
        } else {
            setVisitedSteps([...visitedSteps, stepIndex]);
            setCurrentStep(stepIndex);
        }
    };

    const handleNext = (type) => {
        if (currentStep === 0) {
            setCurrentStep(1);
            setVisitedSteps([...visitedSteps, 1]);
        } else if (currentStep === 1 && step2Validator()) {
            setCurrentStep(2);
            setVisitedSteps([...visitedSteps, 2]);
        }
    };

    const handlePrevious = () => {
        if (currentStep === 2 && step3Validator()) {
            setCurrentStep(1);
        } else if (currentStep === 1) {
            setCurrentStep(0);
        }
    };

    const onFormSubmit = () => {
        // Handle form submission logic here
    };


    return (
        <div className='container-fluid multistep_container_outer pt-4'>
            <div className='container main_multistep_container pt-4'>
                <div className='row'>
                    <div className='col-12'>
                        <StepProgressBar
                            className='position-relative'
                            startingStep={currentStep}
                            onSubmit={onFormSubmit}
                            steps={[
                                {
                                    label: 'Event Type',
                                    // subtitle: '10%',
                                    name: 'Event Type',
                                    content: <SelectInvite handleNext={handleNext} />,
                                },
                                {
                                    label: 'Details',
                                    // subtitle: '50%',
                                    name: 'Details',
                                    content: <CreateSlotForm />,
                                },
                                {
                                    label: 'More',
                                    // subtitle: '100%',
                                    name: 'More',
                                    content: <OneToOneMeeting />,
                                }
                            ]}
                            stepClicked={handleStepClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiStepper;


