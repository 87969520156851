import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  date: moment().format(), // Store date as ISO string
  dateApi: moment().format("YYYY-MM-DD"),
};

const CreateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    nextdate: (state) => {
      state.date = moment(state.date).add(1, "days").format();
      state.dateApi = moment(state.dateApi).add(1, "days").format("YYYY-MM-DD");
    },
    prevdate: (state) => {
      state.date = moment(state.date).subtract(1, "days").format();
      state.dateApi = moment(state.dateApi)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    handlechange: (state, action) => {
      state.date = action.payload;
      state.dateApi = moment(action.payload).format("YYYY-MM-DD");
    },
    resetdate: (state) => {
      state.date = moment().format(); // Reset to the current date
      state.dateApi = moment().format("YYYY-MM-DD");
    },
    nextweekdate: (state) => {
      state.date = moment(state.date).add(7, "days").format();
      state.dateApi = moment(state.dateApi).add(7, "days").format("YYYY-MM-DD");
    },
    nextmonth: (state) => {
      state.date = moment(state.date).add(1, "month").format();
      state.dateApi = moment(state.dateApi)
        .add(1, "month")
        .format("YYYY-MM-DD");
    },
    prevmonth: (state) => {
      state.date = moment(state.date).subtract(1, "month").format();
      state.dateApi = moment(state.dateApi)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    },
    prevweekdate: (state) => {
      state.date = moment(state.date).subtract(7, "days").format();
      state.dateApi = moment(state.dateApi)
        .subtract(7, "days")
        .format("YYYY-MM-DD");
    },
  },
});

export const {
  prevmonth,
  nextmonth,
  nextdate,
  prevdate,
  handlechange,
  resetdate,
  nextweekdate,
  prevweekdate,
} = CreateSlice.actions;

export const selectData = (state) => state.CreateSlice.dateApi;
export const counterReducer = CreateSlice.reducer;
