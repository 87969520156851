import { Col, Form, Row, Table } from "react-bootstrap";
import SideMenuServices from "../../../../Componenets/sideMenuServices/SideMenuServices";
import ServiceCard from "../../../../Componenets/serviceCard/ServiceCard";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const ServicePricing = ({ priceTypeVal, handlePriceTypeVal }) => {
  return (
    <Row className="dynamic_formContainer service-add-detail service-pricing-wrap px-0 gap-5 m-3 pe-4">
      <SideMenuServices />

      <Col className="pe-5 ps-4" md={7}>
        <div className="pe-3">
          <ServiceCard />
          <h6 className="head select-service-type my-3">
            Select type for your attributes
          </h6>
          <div className="mb-2">
            <Form.Check
              className="form-check"
              type="checkbox"
              id="checkbox"
              label="Business Centre"
            />
          </div>
          <Row className="price-table-wrap mx-0">
            <Col className="pr-6px mt-12px" md={6}>
              <div className="input-group">
                <span className="input-label text-light-grey">Type</span>
                <input
                  className="input custom-border"
                  placeholder="Enter Type"
                  type="text"
                />
              </div>
            </Col>
            <Col className="pl-6px pr-6px mt-9px pricing-select" md={3}>
              <span className="text-light-grey select-label">Currency</span>
              <Select
                className="w-100 pricing-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      fontSize: "18px",
                      color: "#aaaaaa",
                      marginLeft: "-40px",
                    }}
                  />
                )}
                value={10}
              >
                <MenuItem value={10}>US Dollar</MenuItem>
                <MenuItem value={20}>Euro</MenuItem>
              </Select>
            </Col>
            <Col className="pl-6px mt-9px pricing-select" md={3}>
              <span className="text-light-grey select-label">Price Unit</span>
              <Select
                className="w-100 pricing-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      fontSize: "18px",
                      color: "#aaaaaa",
                      marginLeft: "-40px",
                    }}
                  />
                )}
                value={10}
              >
                <MenuItem value={10}>Per Hour</MenuItem>
                <MenuItem value={20}>Per Day</MenuItem>
              </Select>
            </Col>
            <Col className="mt-4 px-0" xs={12}>
              <Table className="pricing-table mb-0" striped hover size="sm">
                <thead>
                  <tr>
                    <th className="table-cell"></th>
                    <th className="desc-cell">Description</th>
                    <th className="sku-cell">SKU</th>
                    <th className="duration-cell">Duration</th>
                    <th className="price-type-cell">Price Type</th>
                    <th className="price-cell">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={`${priceTypeVal === 20 && "tr"}`}>
                    <td className="table-cell p-0 py-3">
                      <div className="td-div border-right">
                        <MoreVertIcon className="pe-2 cursor-pointer" />
                      </div>
                    </td>
                    <td className="text-truncate desc-cell">
                      <div className="td-div border-right">Hand</div>
                    </td>
                    <td className="sku-cell">
                      <div className="td-div border-right">4361652169</div>
                    </td>
                    <td className="duration-cell">
                      <div className="td-div border-right">60 mins</div>
                    </td>
                    <td className="price-type-cell px-0">
                      <Select
                        className="w-100 pricing-table-select border-right"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              fontSize: "18px",
                              color: "#aaaaaa",
                              marginLeft: "-45px",
                            }}
                          />
                        )}
                        value={priceTypeVal}
                        onChange={handlePriceTypeVal}
                      >
                        <MenuItem value={10}>Fixed Price</MenuItem>
                        <MenuItem value={20}>Variable prices</MenuItem>
                      </Select>
                    </td>
                    <td className="flex justify-content-between price-cell">
                      <div className="td-div">
                        <span className="cell-currency">£</span>
                        <span className="mx-3">
                          {priceTypeVal === 10 ? (
                            10
                          ) : (
                            <ArrowDownwardIcon className="p-0 down-arrow" />
                          )}
                        </span>
                        <span className="cell-time">Per Hour</span>
                      </div>
                    </td>
                  </tr>
                  {priceTypeVal === 20 && (
                    <tr>
                      <td className="variable-child">
                        <Row className="d-flex justify-content-end pb-3">
                          <Col md={5}>
                            <div className="variable-price-table-wrap">
                              <Table className="mb-0 variable-prices-table">
                                <thead>
                                  <tr>
                                    <th className="py-2 px-3">To</th>
                                    <th className="py-2 px-3">From</th>
                                    <th className="py-2 px-3">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="px-3">
                                      <div>1</div>
                                    </td>
                                    <td className="px-3">
                                      <div>20</div>
                                    </td>
                                    <td className="px-3">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span>67</span>
                                        <RemoveCircleOutlineIcon
                                          className="remove cursor-pointer"
                                          sx={{
                                            fontSize: "13px",
                                            color: "#B9B9B9",
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <div className="d-flex align-items-center add-new py-1">
                                <AddCircleOutlineIcon className="add-icon" />
                                <span>Add New</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          <div className="d-flex justify-content-end gap-3 mt-5">
            <button className="cancel-btn">Cancel</button>
            <button className="save-btn">Save</button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default ServicePricing;
