import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchBusinessFailure, fetchBusinessRequest } from '../../../Store/Business/actions';
import SideNavone from '../../../SideNavBar';

const BusinessList = () => {
    const all_business = useSelector((state) => state.Business?.data);
    var userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
    const error = useSelector((state) => state.Business?.error);
const dispatch= useDispatch();

    useEffect(() => {
        // setLoading(true)
        dispatch(fetchBusinessRequest(userID));
        if (error) {
            dispatch(fetchBusinessFailure(error));
        }
    }, [userID]);

    return (
        <SideNavone>
        <div className='container'>
            <div className='row mt-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <ul className="list-group w-75">
                        {all_business.length > 0 && all_business.map((v, i) => {
                            return (
                                <Link className='text-decoration-none' to={`/staff-list/${v.id}`}>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center bls_sec1'>
                                            <img src='/logo192.png' alt='No image'
                                                className='me-2'
                                                style={{ height: '30px', width: '30px' }}
                                            />
                                            <span>
                                                {v?.name}
                                            </span>
                                        </div>
                                        <div className="form-check d-flex align-items-center p-0 m-0">
                                            <span className='me-2'>Active</span>
                                            <input className="form-check-input m-0" type="radio" name={`flexRadio`} id={`flexRadio`} />
                                        </div>
                                    </li>
                                </Link>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        </SideNavone>
    )
}

export default BusinessList