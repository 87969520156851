import { AuthRoutes } from "./AuthRoutes";
import { ProfileRoutes } from "./ProfileRoutes";
import { BookingRoutes } from "./BookingRoutes";
import { BusinessRoute } from "./BusinessRoutes";
import { HomePageRoutes } from "./HomePageRoutes";
import { SubscriptionRoute } from "./SubscriptionRoute";
import { TeamAndDepartmentRoutes } from "./TeamandDeptRoutes";
import { meetSpaceRoutes } from "../Pages/MeetSpace/meetSpaceRoutes";
import { documentRoutes } from "../Pages/DocumentServices/documentRoutes";
import { servicesRoutes } from "../Pages/InventoryServices/servicesRoutes";
import Privacy from "../Pages/privacy";
import Notes from "../Pages/Notes/Notes";
import FAQ from "../Pages/PublicPages/Faq/Faq";
import OnBoard from "../Pages/OnBoarding/index";
import Maintainance from "../Pages/maintenance";
import PageNotFound from "../Pages/PageNotFound";
import Dashboard from "../Pages/Dashboards/index";
import Terms from "../Pages/PublicPages/Terms/Terms";
import EventListing from "../Pages/listing/event_listing";
import FacultyPage from "../Pages/FacultyPage/FacultyPage";
import AboutUs from "../Pages/PublicPages/Aboutus/Aboutus";
import EditFaculty from "../Pages/EditFaculty/EditFaculty";
import EditCustomer from "../Pages/EditCustomer/EditCustomer";
import EditService from "../Pages/EditService/EditService";
import EditBusiness from "../Pages/EditBusiness/EditBusiness";
import AddUser from "../Pages/BusinessProfile/User/AddUser";
import PaymentList from "../Pages/Payment-react/PaymentList";
import TimeSlot from "../Pages/schedulerModule/pages/TimeSlot";
import Calendar from "../Calendar/scheduler/layouts/scheduler";
import ManageUser from "../Pages/BusinessProfile/User/MangeUser";
import AddSchedular from "../Pages/schedulerModule/pages/Calender";
import StaffList from "../Pages/FacultyPage/FacultyPage";
// import StaffList from "../Pages/Dashboard/MainComponent/new-staff-list";
import ProvidersList from "../Pages/schedulerModule/pages/ProviderList";
import SchedularTimeSlots from "../Pages/schedulerModule/pages/TimeSlot";
import AddSchedularCalendar from "../Pages/schedulerModule/pages/Calender";
import AddStaff from "../Pages/Dashboard/MainComponent/add-staff-new/index";
import FormCreation from "../Pages/FormCreation/components/home/HomeLayout";
import AddPermission from "../Pages/BusinessProfile/Permission/AddPermission";
// import CustomerList from "../Pages/Dashboard/MainComponent/new-customer-list";
import CustomerList from "../Pages/CustomerNewPage/CusotomerNewPage";
import DynamicServices from "../Pages/DynamicServices/DynamicServices";
import CustomerForm from "../Componenets/Customer/DynamicFormCustomer";
import ManagePermission from "../Pages/BusinessProfile/Permission/ManagePermission";
import AddProvider from "../Pages/Dashboard/MainComponent/addstaffProvider/Addstaff";
import AnswerPage from "../Pages/QuestionAnswer/components/QuestionPage/QuestionPage";
import StaffListView from "../Pages/Dashboard/MainComponent/Stafflistview/Stafflistview";
import CustomerListView from "../Pages/Dashboard/MainComponent/Customer/CustomerView";
import AddPermssionForRole from "../Pages/BusinessProfile/Permission/AddPermissionForRole";
import StaffColumnList from "../Pages/Dashboard/MainComponent/new-staff-list/components/ColumnList";
import ProviderSchedular from "../Pages/schedulerModule/components/ProviderList/ViewSchedulerProvider";
import Calendars from "../Pages/schedulerModule/components/Calender/index";
import CustomerColumnList from "../Pages/Dashboard/MainComponent/new-customer-list/components/ColumnList";
import CustomerBookig from "../Pages/Dashboard/MainComponent/new-customer-list/components/CustomerBookig";
import SkeletonTable from "../Calendar/scheduler/components/skeleton/providerWidgetSkeleton";
import CalendarSkeleton from "../Calendar/scheduler/components/skeleton";
import CalendarSettings from "../Calendar/scheduler/components/CalendarSettings";
import App from "../Calendar/test";
import DynamicForm from "../Pages/DynamicForm";
import FieldsForm from "../Pages/DynamicForm/fieldsForm";
import PreviewPage from "../Pages/DynamicForm/previewPage";
import ViewPage from "../Pages/DynamicForm/viewPage";
import { exact } from "prop-types";
import DynamicFormView from "../Pages/DynamicForms/DynamicFormView";
import BookNextVisit from "../Calendar/scheduler/BookNextVisit/BookNextVisit";
import FormPreviewPage from "../Pages/DynamicForm/StaffFormCreator/FormPreviewPage";
import BookNextSession from "../Calendar/scheduler/layouts/bookingsession";
import ServicesPreviewPage from "../Pages/DynamicForm/ServicesFormCreator/ServicesPagePreview";
import EditBusinessTeam from "../Pages/EditBusinessTeam/EditBusinessTeam";
import EditBusinessUser from "../Pages/EditBusinessUser/EditBusinessUser";
import EditBusinessDepartments from "../Pages/EditBusinessDepartments/EditBusinessDepartments";
// import ServiceSettings from "../Pages/InventoryServices/services/serviceSettings";
import ServiceDetailContainer from "../Pages/InventoryServices/services/serviceDetail/ServiceDetailContainer";
import ServiceAttributesContainer from "../Pages/InventoryServices/services/serviceAttributes/ServiceAttributesContainer";
import ServicePricingContainer from "../Pages/InventoryServices/services/servicePricing/ServicePricingContainer";
import ServiceGroupContainer from "../Pages/InventoryServices/services/serviceGroup/ServiceGroupContainer";
import ServiceCostContainer from "../Pages/InventoryServices/services/serviceCost/ServiceCostContainer";
import ServiceSettingsContainer from "../Pages/InventoryServices/services/serviceSettings/ServiceSettingsContainer";
const routes = [

  ...HomePageRoutes,



  {
    path: "/Aboutus",
    component: AboutUs,
    ispublic: true,
    exact: true,
  },
  {
    path: "/terms",
    component: Terms,
    ispublic: true,
    exact: true,
  },
  {
    path: "/privacy",
    component: Privacy,
    ispublic: false,
    exact: true,
  },
  {
    path: "/faq",
    component: FAQ,
    ispublic: true,
    exact: true,
  },
 
  // {
  //   path: "/service/settings",
  //   component: ServiceSettings,
  //   ispublic: false,
  //   exact: true,
  // },

  // {
  //   path: "/testing",
  //   component: App,
  //   ispublic: false,
  //   exact: true,
  // },
  {
    path: "*",
    component: PageNotFound,
    ispublic: false,
  },
];

export default routes;
