// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nextgenContainer {
  background: #f5faff;

}
.nextgenHeadingText {
  padding-top: 3.12vw;
  margin-bottom: 3.66vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 2.04vw;
  line-height: 2.41vw;
  text-align: center;
  color: #000000;
}
.nextgenHeadingText span {
  font-weight: 700;
}
.nextgenLaptop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: -4vw;
  margin-top: -1.5vw;
}
.nextgenLaptop img {
  width: 60vw;
}
.nextGenGetStartedBtn {
  width: 24.67vw;
  height: 4.68vw;
  background: #548dff;
  border-radius: 0.36vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.31vw;
  line-height: 1.53vw;
  border: none;
  cursor: pointer;
  color: #ffffff;
}
.alignNextGenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5.5vw;
  padding-bottom: 3.66vw;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage/WebHomePage/NextGen/NextGen.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;;AAErB;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,cAAc;EACd,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".nextgenContainer {\n  background: #f5faff;\n\n}\n.nextgenHeadingText {\n  padding-top: 3.12vw;\n  margin-bottom: 3.66vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 2.04vw;\n  line-height: 2.41vw;\n  text-align: center;\n  color: #000000;\n}\n.nextgenHeadingText span {\n  font-weight: 700;\n}\n.nextgenLaptop {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-bottom: -4vw;\n  margin-top: -1.5vw;\n}\n.nextgenLaptop img {\n  width: 60vw;\n}\n.nextGenGetStartedBtn {\n  width: 24.67vw;\n  height: 4.68vw;\n  background: #548dff;\n  border-radius: 0.36vw;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.31vw;\n  line-height: 1.53vw;\n  border: none;\n  cursor: pointer;\n  color: #ffffff;\n}\n.alignNextGenBtn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 5.5vw;\n  padding-bottom: 3.66vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
