import React from "react";

import ChatNotes from "../../Componenets/ChatNotes";
import { Grid, Typography } from "@mui/material";
import SideNavone from "../../SideNavBar";

const Notes = () => {
  return (
    <SideNavone>
    <Grid
      container
      sx={{
        background: "#F3F3F3",
        
      }}
    >
      <Grid
        xs={12}
        item
        sx={{
          background: "#FFFFFF",
        }}
      >
        <Typography sx={{fontSize:"20px", fontWeight:"600", width:"100%", textAlign:'center', padding:"10px"}}>Create Booking Notes</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        my={2}
        mx={10}
        sx={{
          backgroundColor: "#FFFFFF",
        }}
        padding={2}
      >
        <ChatNotes/>
      </Grid>
    </Grid>
    </SideNavone>
  );
};

export default Notes;
