import React, { useEffect } from "react";
import StaffServiceForm from "./StaffServiceForm";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getServiceToUpdate,
  getServiceToUpdateExpert,
} from "../../store/servicesActions";
import { useDispatch } from "react-redux";
import SideNavone from "../../../../SideNavBar";

const BusinessService = () => {
  const { id } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("LOCATION", location);
    if (location.state.source === 2) {
      dispatch(getServiceToUpdateExpert(id));
    } else {
      dispatch(getServiceToUpdate(id));
    }
  }, []);

  return (
    <SideNavone>
      <StaffServiceForm
        sources={location.state.source}
        businessId={location.state.businessId}
      />
    </SideNavone>
  );
};

export default BusinessService;
