import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: "",
  status: "",
  openAlert: false,
  openMessage: "",
  openStatus: ""
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    handleOpenAlert: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    handleCloseAlert: (state) => {
      state.open = false;
    },
    hideAlert: (state, action) => {
      state.open = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    openToast: (state, action) => {
      state.openAlert = true;
      state.openMessage = action.payload.message;
      state.openStatus = action.payload.status;
    },
    closeToast: (state) => {
      state.openAlert = false;
    },
  },
});

export const { handleOpenAlert, handleCloseAlert, hideAlert,openToast ,closeToast} = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
